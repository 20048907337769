import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Button } from "../button/button";
import { ImageUploadStatus } from "../image-upload-status/image-upload-status";
import "./thumbnail.scss";
import { useAppSelector } from '../../../app/hooks';
import lodash from 'lodash';
import { useDispatch } from 'react-redux';
import { orderPreparationActions } from '../../../slices/order-preparation-slice';
import { NumUpDown } from '../input/num-up-down';
import { xmlActions } from '../../../xml/slice/xml-slice';

type Props = {
  thumbEle: globalThis.JSX.Element;
  label?: string;
  id?: string,
  uploadStatus?: { uploaded: boolean, uploading: boolean }
  callbackDelete?: () => void,
  selectColor?: string;
  isTitle?: boolean;
  index?: number,
  areaSelect?: (id: string, index: number) => void,
  callbackChangeIndex?: (select: number, target: number) => void,
  callbackMount?: (height: number) => void,
  xmlParams?: {
    kijshopCd: string,
    shopOrderId: string,
    orderId: string,
    outputCount?: Number,
  },
  type?: string,
  setType?: (v: string) => void,
  mouseDownStatus?:(v: boolean) => void,
} & React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;

export const Thumbnail = React.forwardRef((props: Props, ref: React.LegacyRef<HTMLDivElement>) => {
  const {
    thumbEle,
    label,
    uploadStatus,
    callbackDelete,
    id,
    selectColor,
    isTitle = true,
    areaSelect,
    index,
    callbackChangeIndex,
    callbackMount,
    xmlParams,
    type,
    setType,
    mouseDownStatus,
    ...defaultProps
  } = props;
  const {
    kijshopCd,
    shopOrderId,
    orderId,
    outputCount: _outputCount,
  } = xmlParams || {
    kijshopCd: '',
    shopOrderId: '',
    orderId: '',
    // outputCount: '',
  };
  const dispatch = useDispatch();
  // - State -
  const { selectedOrderId, selectedImageList, dragItemSelectIndex, dragItemTargetIndex, xml } = useAppSelector((state) => ({
    selectedOrderId: state.orderPreparation.selectedOrderId,
    selectedImageList: state.orderPreparation.selectedImageList,
    dragItemSelectIndex: state.orderPreparation.dragItem.selectIndex,
    dragItemTargetIndex: state.orderPreparation.dragItem.targetIndex,
    xml: state.xml[shopOrderId],
  }), lodash.isEqual);
  const [deleteBtn, setDeleteBtn] = useState(false);
  // -- 数量 --
  // const [amount, setAmount] = useState(0);
  const [outputCount, setOutputCount] = useState(_outputCount);
  const isSelect = Boolean(selectedImageList.find((v) => v === id));
  // - CallBack -
  const onMouseDown = useCallback((e) => {
    e.preventDefault();
    if (e.ctrlKey) {
      setType?.('ctrl');
      multiSelect();
    } else if (e.shiftKey) {
      setType?.('shift');
      if(areaSelect) areaSelect(id!, index!);
    } else {
      if (selectedImageList.length === 1) {
        setType?.('normal');
        dispatch(orderPreparationActions.setSelectedImageList( { orderId, selectID: id ? [id] : [] }));
      } else if (selectedImageList.length > 1) {
        setType?.('normal');
        (selectedImageList.find((v) => id)) ?
          dispatch(orderPreparationActions.setSelectedImageList(  { orderId, selectID: [...selectedImageList] }))
          : dispatch(orderPreparationActions.setSelectedImageList( { orderId, selectID: id ? [id] : [] }));
      }
    }
  },[selectedImageList, id, areaSelect]);

  const onClick = useCallback((e) => {
    if (selectedImageList.length && type === 'normal') {
      dispatch(orderPreparationActions.setSelectedImageList( { orderId, selectID: id ? [id] : [] }));
    }
    setType?.('');
  },[selectedImageList, id, areaSelect, type]);

  const onContextMenu = useCallback((e) => {
    e.preventDefault();
    // MacはctrlKey + click で右クリックと同じ扱いになるためこちらにも記載
    // 発注済みの場合も追記
    if (e.ctrlKey || isOrdering) {
      // multiSelect();
      return;
    }
    setDeleteBtn(!deleteBtn);
  },[deleteBtn, selectedImageList]);

  const multiSelect = useCallback(() => {
    const img = selectedImageList.find((v) => v === id);
    if (id) {
      if (!img) {
        const list = [...selectedImageList, id];
        dispatch(orderPreparationActions.setSelectedImageList({ orderId, selectID: list }));
      } else {
        const list = selectedImageList.filter((v) => v !== id);
        dispatch(orderPreparationActions.setSelectedImageList({ orderId, selectID: list }));
      }
    }
  }, [selectedImageList]);

  // 画像以外の部分をクリックで削除ボタンがなくなる処理
  const imgRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    document.body.addEventListener('click', e => {
      setDeleteBtn(false);
    });
    document.body.addEventListener('contextmenu', e => {
      if (imgRef.current) {
        if (!imgRef.current.contains(e.target as HTMLElement)) {
          setDeleteBtn(false);
        }
      }
    });
  }, []);
  const thumbRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (thumbRef.current && callbackMount) {
      callbackMount(thumbRef.current.getBoundingClientRect().height);
      const listener = () => {
        callbackMount(thumbRef.current?.getBoundingClientRect().height || 0);
      };
      window.addEventListener('resize', listener);
      return () => {
        window.removeEventListener('resize', listener);
      };
    }
  }, [thumbRef.current]);

  // ドラッグ用ハンドラ
  const handlerMouseDown = useCallback((e) => {
    if (!e.ctrlKey && !e.shiftKey && id && !selectedImageList.length) {
      dispatch(orderPreparationActions.setSelectedImageList({ orderId, selectID: [id] }));
    }
    dispatch(orderPreparationActions.setDragItem({
      selectIndex: index,
      targetIndex: -1,
    }));
    const listener = (e: MouseEvent) => {
      document.removeEventListener('mouseup', listener);
      dispatch(orderPreparationActions.setDragItem({
        selectIndex: -1,
        targetIndex: -1,
      }));
    };
    document.addEventListener('mouseup', listener);
  }, [index, areaSelect, multiSelect, selectedImageList, id]);
  const handlerMouseOver = useCallback(() => {
    if (orderId !== selectedOrderId) {
      return;
    }
    if (dragItemSelectIndex === -1 && dragItemTargetIndex !== -1 && dragItemSelectIndex !== index) {
      dispatch(orderPreparationActions.setDragItem({
        // selectIndex: -1,
        targetIndex: -1,
      }));
    }
    if (dragItemSelectIndex !== -1 && dragItemSelectIndex !== index) {
      dispatch(orderPreparationActions.setDragItem({
        targetIndex: index,
      }));
    }
  }, [dragItemSelectIndex, dragItemTargetIndex, orderId, selectedOrderId]);
  const handlerMouseUp = useCallback(() => {
    if (orderId !== selectedOrderId) {
      return;
    }
    if (mouseDownStatus) mouseDownStatus(false);
    if (dragItemSelectIndex !== -1 && dragItemTargetIndex !== -1) {
      callbackChangeIndex?.(dragItemSelectIndex, dragItemTargetIndex);
      dispatch(orderPreparationActions.setDragItem({
        selectIndex: -1,
        targetIndex: -1,
      }));
    }
  }, [dragItemSelectIndex, dragItemTargetIndex, callbackChangeIndex, selectedOrderId, orderId]);
  const handlerMouseOut = useCallback(() => {
    if (dragItemSelectIndex === -1 && dragItemTargetIndex !== -1 && dragItemSelectIndex !== index) {
      dispatch(orderPreparationActions.setDragItem({
        // selectIndex: -1,
        targetIndex: -1,
      }));
    } else if (dragItemSelectIndex !== -1 && dragItemSelectIndex === index) {
      dispatch(orderPreparationActions.setDragItem({
        targetIndex: -1,
      }));
    }
  }, [dragItemSelectIndex, dragItemTargetIndex]);

  const handlerChangeOutputValue = useCallback((v) => {
    if (Number(v) > 0 && Number(v) <= 99) {
      setOutputCount(v);
      dispatch(xmlActions.order({ kijshopCd, shopOrderId }).changeOutputCount(orderId, index || (index === 0 ? index : -1), v));
    }
  }, [index, orderId]);
  useEffect(() => {
    if (outputCount !== _outputCount) {
      setOutputCount(_outputCount);
    }
  }, [_outputCount]);
  // - memo -
  const isOrdering = useMemo(() => {
    return !!xml?.info?.xmlModel?.lnwOrder?.order?.[0]?.$?.orderDate
  }, [xml]);
  return (
    <div ref={imgRef} className={`thumbnail__wrap${(isSelect && orderId === selectedOrderId) ? ' selected' : ''}`}>
      <div
        {...defaultProps}
        className={`thumbnail${defaultProps.className ? ` ${defaultProps.className}` : ''}`}
        ref={thumbRef}
        onClick={onClick}
        onContextMenu={onContextMenu}
        style={{ color: selectColor ? selectColor : undefined }}
        onMouseDown={(e) => {
          if (isOrdering) return;
          onMouseDown(e);
          if (mouseDownStatus) mouseDownStatus(true);
          // e.preventDefault();
          e.stopPropagation();
          handlerMouseDown(e);
        }}
        onMouseOver={handlerMouseOver}
        onMouseUp={handlerMouseUp}
        onMouseOut={handlerMouseOut}
        // data-handler-id={handlerId}
      >
        <div className={`thumbnail__thumb`}>
          {thumbEle}
          {callbackDelete && deleteBtn ?
            <Button label="削除" onClick={()=> {
              callbackDelete();
              setDeleteBtn(false);
            }} className="thumbnail__thumb__btn"/>
            : <></>}
        </div>
        {label || outputCount ? (
          <div
            className={`thumbnail__label${outputCount ? ' countable' : ''}`}
            // レイアウト画面 > テンプレート編集 > 画像一覧 のサムネと挙動統一
          >
            <span title={isTitle ? `${label}` : undefined}>{label}</span>
            {outputCount ? (
              <div className="bordered_left_label_item">
                <span>数量</span>
                {isOrdering ?
                  <div>{outputCount}</div> :
                  <NumUpDown
                  value={String(outputCount)}
                  min={1}
                  max={999}
                  onChange={(e) => handlerChangeOutputValue(e.target.value)}
                  callback={(v) => handlerChangeOutputValue(v)}
                />}
              </div>
            ) : <></>}
         </div>
        ) : <></>}
        {uploadStatus ? <ImageUploadStatus uploaded={uploadStatus.uploaded} uploading={uploadStatus.uploading} />
          : (<></>)}
      </div>
    </div>
  );
});
