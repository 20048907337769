import {createSlice} from '@reduxjs/toolkit';

type LoadingSliceState = {
  display: boolean,
};

const initialState: LoadingSliceState = {
  display: false,
};

export const loadingSlice = createSlice({
  name: 'loading',
  initialState,
  reducers: {
    open: (state) => {
      state.display = true;
    },
    close: (state) => {
      state.display = false;
    },
  },
});

export const loadingActions = loadingSlice.actions;
export const loadingReducer = loadingSlice.reducer;
