import { ApiBaseFront } from '../api-base-front';

export type TUploadRequestParam = {
  files: {
    filename: string,
    body: string,
    is_create: '0' | '1'
  }[]
}

export class ApiUploadXml extends ApiBaseFront {
  constructor(
    kijShopCd: string,
    shopOrderId: string,
    param: TUploadRequestParam
  ) {
    super(
      'POST',
      `/api/v1/orderxml/${kijShopCd}/${shopOrderId}`,
      'JSON',
      param,
    );
  }
}