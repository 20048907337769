import { XmlClass } from '../xml-class';
import { IOrderPartsData } from '../../interface/order/i-order-parts-data';
import { XmlClassName } from '../../model/xml-class-name';
import { TOrderPartsDataMetaModel, TOrderPartsDataViewModel } from '../../model/order/xml-order-parts-data-model';
import { OrderPageXml } from './order-page-xml';

type TView = Partial<TOrderPartsDataViewModel>;
type TMeta = Partial<TOrderPartsDataMetaModel>;

export class OrderPartsDataXml extends XmlClass<IOrderPartsData, TView, TMeta, [string, string]> {

  // indexes!: [string, string];
  name: XmlClassName = 'order-parts-data';
  viewModel: TView = {};
  metaModel: TMeta = {};

  // order-parts-data に紐づく order-page
  orderPage?: OrderPageXml;

  constructor(shopOrderId: string, data: { version: string, indexes: [string | number, string | number] }) {
    super(shopOrderId, data);
  }
  protected init(): void {
    this.xmlUniqueName = this.xmlUniqueName || `PPM_${this.shopOrderId}_11_11_orderPartsData_${this.indexes[0]}_${this.indexes[1]}.xml`;
    this.xmlParentName = this.xmlParentName || `PPM_${this.shopOrderId}_11_11_orderParts_${this.indexes[0]}.xml`;
    this.rootTagModel = {
      ...this.rootTagModel,
      parentXPath: '/lnwOrderParts/orderParts',
      parentPath: this.xmlParentName,
    };
    this.docking({}, {});
  }

  protected docking(v: TView, m: TMeta): void {
    this.xmlModel = {
      lnwOrderPartsData: {
        $: { ...this.rootTagModel },
        data: [{
          $: {
            id: v.id,
            partsID: v.partsID,
            name: v.name,
            parentID: v.parentID || undefined,
            type: v.type || undefined,
          },
          cover: v.cover && [
            {
              $: {
                color: v.cover.color,
                colorName: v.cover.colorName,
              },
            },
          ],
          chronology: v.chronology && [
            {
              $: {
                chronologyID: v.chronology.chronologyID,
                chronologyName: v.chronology.chronologyName,
              },
            },
          ],
          infoSurface: v.infoSurface && [
            {
              $: {
                laminateID: v.infoSurface.laminateID,
                name: v.infoSurface.name,
              },
            },
          ],
          board: v.board && [
            {
              $: {
                componentID: v.board.componentID,
                componentName: v.board.componentName,
              },
            },
          ],
          leaf: v.leaf && [
            {
              $: {
                color: v.leaf.color,
                colorName: v.leaf.colorName,
                text1: v.leaf.text1,
                text2: v.leaf.text2,
              },
            },
          ],
          // additionalInfo: v.additionalInfo && [
          additionalInfo: v.additionalInfo?.[0] ? [(() => {
            const obj: { [key: string]: [''] } = {};
            v.additionalInfo?.forEach(((additional) => {
              obj[additional] = [''];
            }));
            return obj;
          })()] : undefined,
            // {
            //   jptg380254: [
            //     v.additionalInfo.jptg380254,
            //   ],
            // },
          // ],
          maxPageImageCount: v.maxPageImageCount ? [v.maxPageImageCount] : undefined,
          minPageImageCount: v.minPageImageCount ? [v.minPageImageCount] : undefined,
          pageCount: v.pageCount ? [v.pageCount] : undefined,
          minPageCount: v.minPageCount ? [v.minPageCount] : undefined,
          maxPageCount: v.maxPageCount ? [v.maxPageCount] : undefined,
          stepPageCount: v.stepPageCount ? [v.stepPageCount] : undefined,
          maxImageAdd: v.maxImageAdd ? [v.maxImageAdd] : undefined,
          minImageAdd: v.minImageAdd ? [v.minImageAdd] : undefined,
          inheritParent: v.inheritParent ? [v.inheritParent] : undefined,
        }],
        orderPage: m.path ? [
          {
            $: {
              path: m.path,
            },
          },
        ] : undefined,
      },
    };
  }

  protected split(x: IOrderPartsData): void {
    if (!x?.lnwOrderPartsData?.data || !x.lnwOrderPartsData?.$) {
      this.viewModel = {};
      this.metaModel = {};
      return;
    }
    this.viewModel = {
      // data: x.lnwOrderPartsData.data ? [...x.lnwOrderPartsData.data].map((v) => ({
      id: x.lnwOrderPartsData.data?.[0]?.$?.id || '',
      name: x.lnwOrderPartsData.data?.[0]?.$?.name || '',
      parentID: x.lnwOrderPartsData.data?.[0]?.$?.parentID || '',
      partsID: x.lnwOrderPartsData.data?.[0]?.$?.partsID || '',
      type: x.lnwOrderPartsData.data?.[0]?.$?.type || '',
      cover: x.lnwOrderPartsData.data?.[0]?.cover?.[0] && {
        color: x.lnwOrderPartsData.data?.[0]?.cover?.[0]?.$?.color || '',
        colorName: x.lnwOrderPartsData.data?.[0]?.cover?.[0]?.$?.colorName || '',
      },
      chronology: x.lnwOrderPartsData.data?.[0]?.chronology?.[0] && {
        chronologyID: x.lnwOrderPartsData.data?.[0]?.chronology?.[0]?.$?.chronologyID || '',
        chronologyName: x.lnwOrderPartsData.data?.[0]?.chronology?.[0]?.$?.chronologyName || '',
      },
      infoSurface: x.lnwOrderPartsData.data?.[0]?.infoSurface?.[0] && {
        laminateID: x.lnwOrderPartsData.data?.[0]?.infoSurface?.[0]?.$.laminateID || '',
        name: x.lnwOrderPartsData.data?.[0]?.infoSurface?.[0]?.$.name || '',
      },
      leaf: x.lnwOrderPartsData.data?.[0]?.leaf?.[0] && {
        color: x.lnwOrderPartsData.data?.[0]?.leaf?.[0]?.$?.color || '',
        colorName: x.lnwOrderPartsData.data?.[0]?.leaf?.[0]?.$?.colorName || '',
        text1: x.lnwOrderPartsData.data?.[0]?.leaf?.[0]?.$?.text1 || '',
        text2: x.lnwOrderPartsData.data?.[0]?.leaf?.[0]?.$?.text2 || '',
      },
      board: x.lnwOrderPartsData.data?.[0]?.board?.[0] && {
        componentID: x.lnwOrderPartsData.data?.[0]?.board?.[0]?.$?.componentID || '',
        componentName: x.lnwOrderPartsData.data?.[0]?.board?.[0]?.$?.componentName || '',
      },
      // additionalInfo: x.lnwOrderPartsData.data?.[0].additionalInfo?.[0] && {
      //   jptg380254: x.lnwOrderPartsData.data?.[0].additionalInfo?.[0]?.jptg380254?.[0] || '',
      // },
      additionalInfo: x.lnwOrderPartsData.data?.[0]?.additionalInfo?.[0] && Object.keys(x.lnwOrderPartsData.data[0].additionalInfo[0]).map((key) => key),
      pageCount: x.lnwOrderPartsData.data?.[0]?.pageCount?.[0],
      minPageCount: x.lnwOrderPartsData.data?.[0]?.minPageCount?.[0],
      maxPageCount: x.lnwOrderPartsData.data?.[0]?.maxPageCount?.[0],
      stepPageCount: x.lnwOrderPartsData.data?.[0]?.stepPageCount?.[0],
      maxPageImageCount: x.lnwOrderPartsData.data?.[0]?.maxPageImageCount?.[0],
      minPageImageCount: x.lnwOrderPartsData.data?.[0]?.minPageImageCount?.[0],
      maxImageAdd: x.lnwOrderPartsData.data?.[0]?.maxImageAdd?.[0],
      minImageAdd: x.lnwOrderPartsData.data?.[0]?.minImageAdd?.[0],
      inheritParent: x.lnwOrderPartsData.data?.[0]?.inheritParent?.[0],
      // })) : [],
    };
    this.metaModel = {
      path: x.lnwOrderPartsData.orderPage?.[0]?.$?.path,
    };
    this.rootTagModel = Object.assign(this.rootTagModel, x.lnwOrderPartsData.$);
  }

  changeIndexes(indexes: [string, string]) {
    this.indexes = indexes;
    this.xmlUniqueName = `PPM_${this.shopOrderId}_11_11_orderPartsData_${this.indexes[0]}_${this.indexes[1]}.xml`;
    this.xmlParentName = `PPM_${this.shopOrderId}_11_11_orderParts_${this.indexes[0]}.xml`;
    this.rootTagModel = {
      ...this.rootTagModel,
      parentPath: this.xmlParentName,
    };
  }

}
