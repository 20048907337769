import React, {
  ChangeEvent,
  ChangeEventHandler,
  DetailedHTMLProps,
  SelectHTMLAttributes,
  useCallback,
  useRef,
  useState,
} from 'react';
import { DynamicTooltip } from '../tooltip/dynamic-tooltip';
import './select.scss';

type SelectProps = {
  // - selectタグvalue -
  value: string,
  // - optionタグvalue,label -
  list: { value: string, label: string }[],
  // - 未選択状態有無 -
  addEmpty?: boolean,
  // - onChange -
  // onChange: ChangeEventHandler<HTMLSelectElement>,
  onChange?: (e: ChangeEvent<HTMLSelectElement>, label?: string) => void,
  tooltip?: { type: 'error' | 'normal', text: string[] },
} & Omit<DetailedHTMLProps<SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement>, 'onChange'>;

export const Select = (props: SelectProps) => {
  const { value, list, addEmpty, tooltip, onChange, disabled, ...defaultProps } = props;
  const handlerChangeValue = useCallback((e) => {
    if (onChange) {
      onChange(e, list.find((v) => v.value === e.target.value)?.label);
    }
  }, [list, onChange]);
  // - Ref -
  const ref = useRef<HTMLDivElement>(null);
  // - State -
  const [hover, setHover] = useState(false);

  return (
    <div
      className={`select${disabled ? ' disabled' : ''}`}
      onMouseOver={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      ref={ref}
    >
      <select
        value={value || (addEmpty ? '' : '-')}
        onChange={handlerChangeValue}
        disabled={disabled}
        {...defaultProps}
      >
        <option
          disabled
          hidden
          style={{ display: 'none' }}
          value="-"
        />
        {(addEmpty ? [{ value: '', label: '選択なし' }, ...list] : list).map((v, i) => (
          <option
            key={`select-component-options_${v.value}_${v.label}_${i}`}
            value={v.value}
          >
            {v.label}
          </option>
        ))}
      </select>
      {tooltip && hover ? (
        <DynamicTooltip
          messages={tooltip.text}
          relativeEle={ref.current}
          error={tooltip.type === 'error'}
        />
      ) : (<></>)}
    </div>
  );
};
