import { Button } from '../../ui/button/button';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Table } from '../../ui/table/table';
import { Checkbox } from '../../ui/input/checkbox';
import { dialogActions } from '../slice/dialog-slice';
import { CommonButton } from '../../ui/button/common-button';
import './guid-list.scss';
import { GuideType } from '../../pages/layout/toolbar/layout.trimming-controller';
import { UiManager } from '../../../layout-editor/manager/ui/ui.manager';
import { cloneDeep } from 'lodash';
import { useDidMount } from '../../../hooks/life-cycle';

type Props = {
  list: GuideType[],
  checks: number[],
  callback: (id: number, list: GuideType[]) => void,
  updateChecks: (checks: number[]) => void,
}
export const GuidList = (props: Props) => {
  // - Hooks -
  const dispatch = useDispatch();
  // - Props -
  const { list, checks, callback, updateChecks } = props;
  // - State -
  const [guidList, setGuidList] = useState<GuideType[]>([]);
  const [checkList, setCheckList] = useState<number[]>([]);
  // -Callback -
  const handlerClickCheck = useCallback((num: number) => {
    const list = cloneDeep(checkList);
    if (list.includes(num)) {
      list.splice(list.indexOf(num), 1);
    } else {
      list.push(num);
    }
    UiManager.ins.emit('r->l:show-select-guide', { list });
    setCheckList(list);
  }, [checkList]);

  useDidMount(() => {
    setGuidList(list)
    setCheckList(checks)
  })
  // -- ガイド削除ボタン押下 --
  const handlerClickDelete = useCallback((id: number) => {
    dispatch(dialogActions.pushMessage({
      title: 'ガイド線の削除',
      message: [`IDが${id+1}のものを削除します。元に戻りません。`, 'よろしいですか？'],
      buttons: [
        {
          label: 'いいえ',
          callback: () => dispatch(dialogActions.pop()),
        },
        {
          label: 'はい',
          callback: () => {
            const newList = guidList.filter((v) => guidList.indexOf(v) !== id)
            const _newChecks = cloneDeep(checkList.filter((v) => v !== id));
            const newChecks = _newChecks.map(v => {
              if (v > id) {
                return --v;
              }
              return v;
            });
            setGuidList(newList);
            setCheckList(newChecks);
            callback(id, newList);
            updateChecks(newChecks);
            dispatch(dialogActions.pop());
            // forceUpdate();
          },
        },
      ],
    }));
  }, [guidList, checkList]);
  // -- 閉じるボタン押下 --
  const handlerClickClose = useCallback(() => {
    dispatch(dialogActions.pop());
    updateChecks(checkList);
  }, [checkList]);
  // - テーブルbodyリスト -
  const tableBody = guidList.map((elm, index) => {
    return [
      <Checkbox
        onClick={() => {
          handlerClickCheck(index);
        }}
        checked={checkList.includes(index)}
        value={index}
      />,
      index + 1,
      elm.type === 'vertical' ? '縦' : '横',
      elm.position,
      <CommonButton
        onClick={() => handlerClickDelete(index)}
        commonType="delete"
        size="sm"
      />,
    ];
  });
  return (
    <div className="dialog_contents guid_list">
      <div className="dialog_contents__body">
        <Table
          head={['表示', 'ID', '方向', '位置(mm)', '']}
          body={tableBody}
        />
      </div>
      <div className="dialog_contents__footer">
        <Button
          label="閉じる"
          onClick={handlerClickClose}
        />
      </div>
    </div>
  );
};
