import { useEffect, useRef, useState } from 'react';
import { Tooltip, TooltipProps } from './tooltip';
import './tooltip.scss';

type DynamicTooltipProps = {
  relativeEle: HTMLElement | null,
  offset?: { x?: string, y?: string },
  limitEle?: HTMLElement | null,
} & TooltipProps;

/**
 * 帰属する要素に対して自動的に位置調整させるツールチップ
 * 
 * @param {string[]} messages ツールチップ内メッセージ。
 * @param {HTMLElement | null} relativeEle ツールチップが帰属する要素
 * @param {HTMLElement} limitEle 表示制限範囲にする要素。見切れ阻止などに利用想定。
 * @param {boolean} error エラーモードにするか
 * @param {"top" | "bottom"} balloonDirection 吹き出しのツノの向き(上下)。エラーモードにしていなければ不要。
 */
export const DynamicTooltip = (props: DynamicTooltipProps) => {
  const { messages, relativeEle, error, offset, limitEle, ...defaultProps } = props;
  // - State -
  const [openTop, setOpenTop] = useState(false);
  const [direction, setDirection] = useState<"right" | "left">("right");
  // - Ref -
  const ref = useRef<HTMLDivElement>(null);

  // - Effect -
  useEffect(() => {
    if (relativeEle) {
      relativeEle.style.position = "relative";
    }
  }, []);
  // -- 上設置か下設置か --
  useEffect(() => {
    if (!(ref.current && relativeEle)) { return; }
    const relEleRect = relativeEle.getBoundingClientRect();
    const refEleRect = ref.current.getBoundingClientRect();
    const refEleRight = relEleRect.x + refEleRect.width;
    const refEleBottom = relEleRect.y + relEleRect.height + refEleRect.height;
    if (limitEle) {
      const limEleRect = limitEle.getBoundingClientRect();
      const limEleRight = limEleRect.x + limEleRect.width;
      const limEleBottom = limEleRect.y + limEleRect.height;
      setOpenTop(limEleBottom < refEleBottom);
      setDirection((limEleRight > refEleRight) ? "right" : "left");
    } else {
      setOpenTop(window.innerHeight < refEleBottom);
      setDirection((window.innerWidth > refEleRight) ? "right" : "left");
    }
  }, [limitEle, relativeEle, ref.current]);

  return (
    <Tooltip
      {...defaultProps}
      messages={messages}
      error={error}
      balloonDirection={openTop ? "bottom" : "top"}
      className={`${openTop ? ' open_top' : ' open_bottom'}${` direction_${direction}`}${defaultProps.className ? ` ${defaultProps.className}` : ''}`}
      ref={ref}
      style={{ transform: `translate(${offset?.x || 0}, ${offset?.y || 0})`, ...defaultProps.style }}
    />
  );
};