
import { OrdersSliceState } from '../../slices/orders-slice';
import { OrdersDataState } from '../../slices/orders/orders-data-slice';
import { CreateOrderItemData } from './create-order-item-data';
import { VisibleConditionValueGetter } from '../../manager/visible-condition-value-getter';
import { checkEditable, checkOptional } from './item-detail-item-data';

const categorize = (v: string) => `商品情報-商品詳細-オプション-${v}`;
export const optionItemData = (order: OrdersSliceState, data: OrdersDataState, visibleCondition: VisibleConditionValueGetter): CreateOrderItemData[] => {
  // const display = genuineOrderDisplayItem(order, data);
  const displays = visibleCondition.optionOrderDisplay;
  const arr: CreateOrderItemData[] = [];
  displays.forEach((display, i) => {
    const {
      tag,
      item,
      quantity,
      cover,
      descriptionLocations,
    } = order.option[i];
    const laminate = cover?.laminate?.value;
    if (item?.value) {
      arr.push(
        {
          name: 'tag',
          value: tag?.value,
          editable: checkEditable(display.itemTag),
          optional: checkOptional(display.itemTag),
          display: Boolean(display.itemTag),
          categorize: categorize('商品タグ'),
        },
        {
          name: 'item',
          value: item?.value,
          editable: checkEditable(display.item),
          optional: checkOptional(display.item),
          display: Boolean(display.item),
          categorize: categorize('商品'),
        },
        {
          name: 'quantity',
          value: String(quantity),
          editable: checkEditable(display.quantity),
          optional: checkOptional(display.quantity),
          display: Boolean(display.quantity),
          categorize: categorize('数量'),
        },
        // {
        //   name: 'descriptionLocations',
        //   value: ,
        //   editable: checkEditable(display),
        //   optional: checkOptional(display),
        //   display: Boolean(display),
        //   categorize: categorize(''),
        // },
        {
          name: 'laminate',
          value: laminate,
          editable: checkEditable(display.laminate),
          optional: checkOptional(display.laminate),
          display: Boolean(display.laminate && data.option.optionLaminate[i]?.laminate?.length),
          categorize: categorize('表紙-ラミ選択'),
        },
      );
    }
  });
  return arr;
}
