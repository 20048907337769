import folderOpen from '../../../assets/img/icon/folder-open.svg';
import folderClose from '../../../assets/img/icon/folder-close.svg';
import './switch-icon.scss';
import React from 'react';
import iconArrowX from '../../../assets/img/icon/arrow-double.svg';


type SwitchIconProps = {
  isOn: boolean,
  switchEle?: {
    on: globalThis.JSX.Element,
    off: globalThis.JSX.Element,
  },
  tmplMode?: 'tree' | 'folder' | 'open_x',
}

export const SwitchIcon = React.forwardRef((props: SwitchIconProps, ref?: any) => {
  const { isOn, switchEle, tmplMode } = props;

  return (
    <div className={`switch_icon ${tmplMode ? tmplMode : ''}`} ref={ref}>
      {switchEle
        ? <>{isOn ? switchEle.on : switchEle.off}</>
        :
        <>
          {tmplMode === 'folder' ? <img src={isOn ? folderOpen : folderClose} alt='' /> : <></>}
          {tmplMode === 'tree' ? <div className={isOn ? 'open' : ''} /> : <></>}
          {tmplMode === 'open_x' ? <img src={iconArrowX} alt='' className={isOn ? '' : 'close'} /> : <></>}
        </>
      }
    </div>
  );
});
