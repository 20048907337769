import { UiManager } from '../../ui/ui.manager';
import { PanZoomManager } from '../../pan-zoom/pan-zoom.manager';
import { EditableEvent } from '../editable-event';
import { ImageController, initPictureFlags } from './image-controller';
import { FrameController } from '../frame/frame-controller';
import { EditableSideTool } from '../editable-side-tool';
import { ImageEditableTool } from './image-editable-tool';
import { LayoutDataManager } from '../../data/layout-data.manager';
import * as createjs from 'createjs-module';
import { CjBitmap, CjContainer, CjShape } from '../../../model/cj-factory';
import { CornerType, CjCorner } from '../../../model/cj-factory/cj-corner';
import { CjTool } from '../../../../utilities/cj-tool';
import { CJ_CONTAINER_NAME, CJ_DPOBJ_NAME, CJ_FOCUS_NAME } from '../../../model/cj-factory/cj-obj-name.collection';
import { FocusModeType } from '../../data/layout-data-type';
import { EditableImageManager } from '../../editable-image/editable-image.manager';
import { FitChecker } from '../../../../utilities/fit-checker';
import { EditableImage } from '../editable-image';
import { PosManager } from '../../pos/pos.manager';
import { Pos } from '../../../../models/pos';
import { ImageEditManager } from '../image-edit.manager';
import { TextEvent } from '../text/text-event';
import { TrimmingManager } from '../../trimming/trimming.manager';

export type TargetType = {
  id: string,
  photoId: string,
  pos: { x: number, y: number },
  scale: { x: number, y: number },
  reg: { x: number, y: number },
  rotation: number,
}

export class ImageEvent {
  private stage: CjContainer;

  private editableImageManager!: EditableImageManager;
  private panZoomManager!: PanZoomManager;
  private imageController!: ImageController;
  private frameController!: FrameController;
  private editableEvent!: EditableEvent;
  private editableSideTool!: EditableSideTool;
  private imageEditableTool!: ImageEditableTool;
  private layoutDataManager!: LayoutDataManager;
  private posManager!: PosManager;
  private imageEditManager!: ImageEditManager;
  private textEvent!: TextEvent;
  private trimmingManager!: TrimmingManager;

  private clicked: boolean = false;
  private transformed: boolean = false;
  activeFocus: CjContainer | null = null;
  targetFrame: CjContainer | null = null;
  isFocus: boolean = false;
  isExchange: boolean = false;
  isSelect: boolean = true;
  isMove: boolean = false;
  private isFastMove: boolean = true;
  private showToolTip: boolean = false;
  private checkCounter: number = 0;
  private delayNum: number = 2;
  frameMoveMode: boolean = true;
  corner: CjCorner | null = null;
  dragTarget: CjContainer | null = null;
  private dragPointX: number = 0;
  private dragPointY: number = 0;
  private cornerType: 'lt' | 'rt' | 'rb' | 'lb' = 'lt';
  private movingTarget: CjContainer | null = null;
  private previewImage: createjs.Bitmap | null = null;
  private timerId: NodeJS.Timeout | undefined;

  targetData: TargetType = {
    id: '',
    photoId: '',
    pos: { x: 0, y: 0 },
    scale: { x: 0, y: 0 },
    reg: { x: 0, y: 0 },
    rotation: 0,
  };

  imageInitData: {
    target: CjContainer,
    param: {
      x: number,
      y: number,
      movePos: { x: number, y: number },
      rotation: number,
      scaleX: number,
      scaleY: number,
      regX: number,
      regY: number,
    },
  }[] = [];

  constructor(stage: CjContainer) {
    this.stage = stage;
  }

  di(
    imageController: ImageController,
    frameController: FrameController,
    panZoomMng: PanZoomManager,
    editableEvent: EditableEvent,
    editableSideTool: EditableSideTool,
    imageEditableTool: ImageEditableTool,
    layoutDataManager: LayoutDataManager,
    editableMng: EditableImageManager,
    posMng: PosManager,
    imageEditMng: ImageEditManager,
    textEvent: TextEvent,
    trimmingMng: TrimmingManager,
  ): void {
    this.frameController = frameController;
    this.imageController = imageController;
    this.panZoomManager = panZoomMng;
    this.editableEvent = editableEvent;
    this.editableSideTool = editableSideTool;
    this.imageEditableTool = imageEditableTool;
    this.layoutDataManager = layoutDataManager;
    this.editableImageManager = editableMng;
    this.posManager = posMng;
    this.imageEditManager = imageEditMng;
    this.textEvent = textEvent;
    this.trimmingManager = trimmingMng;
  }

  initialize() {
  }

  destroy() {
    this.clicked = false;
    this.transformed = false;
    this.activeFocus = null;
    this.targetFrame = null;
    this.isFocus = false;
    this.isFastMove = true;
    this.isMove = false;
    this.frameMoveMode = true;
    this.corner = null;
    this.dragPointX = 0;
    this.dragPointY = 0;
    this.cornerType = 'lt';
    this.movingTarget = null;
    this.previewImage = null;
    this.imageInitData.length = 0;
  }

  update(stage: CjContainer): void {
    this.stage = stage;
  }

  // - 選択時の枠の四隅のアイコン作成 -
  async createCorner(
    imageBounds: createjs.Rectangle,
    type: FocusModeType,
  ): Promise<(CjCorner | CjShape)[]> {
    return new Promise((resolve) => {
      const Icons = [];
      if (type === 'scale') {
        Icons.push(new CjCorner('scale', 'rt', imageBounds));
        Icons.push(new CjCorner('scale', 'rb', imageBounds));
        Icons.push(new CjCorner('scale', 'lb', imageBounds));
        Icons.push(new CjCorner('scale', 'lt', imageBounds));
      } else if (type === 'rotate' || type === 'png') {
        Icons.push(new CjCorner('scale', 'rt', imageBounds));
        Icons.push(new CjCorner('rotate', 'rb', imageBounds));
        Icons.push(new CjCorner('scale', 'lb', imageBounds));
        Icons.push(new CjCorner('rotate', 'lt', imageBounds));
      } else {
        const corner: CjShape = new CjShape();
        corner.graphics.f('#A5B9F4').dc(imageBounds.x + imageBounds.width, imageBounds.y, 5);
        corner.graphics.f('#A5B9F4').dc(imageBounds.x + imageBounds.width, imageBounds.y + imageBounds.height, 5);
        corner.graphics.f('#A5B9F4').dc(imageBounds.x, imageBounds.y + imageBounds.height, 5);
        corner.graphics.f('#A5B9F4').dc(imageBounds.x, imageBounds.y, 5);
        Icons.push(corner);
      }
      for (const icon of Icons) {
        icon.name = CJ_FOCUS_NAME.corner;
      }
      resolve(Icons);
    });
  }

  // - 選択時の枠作成 -
  async createFocus(image: CjContainer, focusMode: FocusModeType) {
    let imageBounds = image.getBounds();
    // -- コンテナとシェイプ用意 --
    const container: CjContainer = new CjContainer();
    switch (focusMode) {
      case 'scale':
        container.name = CJ_CONTAINER_NAME.focus.scale;
        break;
      case 'rotate':
        const photo = await CjTool.checkLoad(image.getChildByName(CJ_DPOBJ_NAME.image) as CjBitmap);
        imageBounds = photo.getTransformedBounds();
        container.name = CJ_CONTAINER_NAME.focus.rotate;
        break;
      case 'png':
        container.name = CJ_CONTAINER_NAME.focus.rotate;
        break;
      case 'move' || 'none':
        container.name = CJ_CONTAINER_NAME.focus.move;
        break;
      default:
        break;
    }
    const focus: CjShape = new CjShape();
    focus.name = CJ_FOCUS_NAME.line;
    // -- 四隅にアイコン設置（右上、右下、左下、左上の順） --
    container.addChild(focus);
    if (focusMode === 'scale' || focusMode === 'rotate' || focusMode === 'png') {
      focus.graphics.s('#A5B9F4');
      focus.graphics.ss(3.5);
      focus.graphics.dr(imageBounds.x, imageBounds.y, imageBounds.width, imageBounds.height);
    } else {
      focus.graphics.s('#A5B9F4');
      focus.graphics.sd([10, 5], 0);
      focus.graphics.dr(imageBounds.x, imageBounds.y, imageBounds.width, imageBounds.height);
    }
    const corners = await this.createCorner(imageBounds, focusMode);
    for (const _corner of corners) {
      if (focusMode === 'move') {
        container.addChild(_corner);
      } else {
        const corner = _corner as CjCorner;
        corner.on('mousedown', (e: any) => {
          this.handleTransformStart(image, e);
        });
        if ((focusMode === 'rotate' && (corner.position === 'lt' || corner.position === 'rb'))
          || (focusMode === 'png' && (corner.position === 'lt' || corner.position === 'rb'))) {
          corner.on('pressmove', (e: any) => {
            this.handleRotateMove(image, e, corner.position);
          });
          corner.on('mouseover', (e: any) => {
            if (this.showToolTip) return;
            this.showToolTip = true;
            const pos = this.posManager.canvasToBrowser({
              // container の外側の座標だから container の座標を引いとく
              x: this.stage.stage.mouseX - ImageEditManager.STAGE_CONTAINER_X + 10,
              y: this.stage.stage.mouseY - ImageEditManager.STAGE_CONTAINER_Y + 10,
            });
            UiManager.ins.emit('l->r:show-tooltip',
              { type: 'rotateIcon', pos },
            );
          });
          corner.on('mouseout', (e) => {
            this.showToolTip = false;
            UiManager.ins.emit('l->r:show-tooltip', { type: 'none', pos: { x: 0, y: 0 } });
          });
        } else {
          corner.on('pressmove', (e: any) => {
            this.handleScaleMove(image, e);
          });
          corner.on('mouseover', (e) => {
            if (this.showToolTip) return;
            this.showToolTip = true;
            const pos = this.posManager.canvasToBrowser({
              // container の外側の座標だから container の座標を引いとく
              x: this.stage.stage.mouseX - ImageEditManager.STAGE_CONTAINER_X + 10,
              y: this.stage.stage.mouseY - ImageEditManager.STAGE_CONTAINER_Y + 10,
            });
            UiManager.ins.emit('l->r:show-tooltip',
              { type: 'scaleIcon', pos },
            );
          });
          corner.on('mouseout', (e) => {
            this.showToolTip = false;
            UiManager.ins.emit('l->r:show-tooltip', { type: 'none', pos: { x: 0, y: 0 } });
          });
        }
        corner.on('pressup', (e: any) => {
          this.handleTransformEnd(image, e);
        });
        container.addChild(corner);
      }
    }
    return container;
  }

  updateToolPos(focusMode: FocusModeType, target: CjContainer) {
    const isPng = this.layoutDataManager.selector('getFocusMode') === 'png';
    if (isPng) return;
    const defaultContainer = CjTool.getDefaultContainer(target);
    const imageAreaContainer = CjTool.getImageAreaContainer(target);
    const photoContainer = CjTool.getPhotoContainer(target);
    const scaleContainer = CjTool.getScaleContainer(target);
    const rotateContainer = CjTool.getRotateContainer(target);
    const defaultData = defaultContainer.getTransformedBounds();
    const isPhoto = CjTool.checkPhotoInFrame(target);
    const imageContainerData = (focusMode === 'rotate' || focusMode === 'dai') ? imageAreaContainer.getBounds() : undefined;
    const imageContainerTfData = (focusMode === 'rotate' || focusMode === 'dai') ? imageAreaContainer.getTransformedBounds() : undefined;
    const photoContainerData = (focusMode === 'rotate' || focusMode === 'dai') ? photoContainer.getBounds() : undefined;
    const photoContainerTfData = (focusMode === 'rotate' || focusMode === 'dai') ? photoContainer.getTransformedBounds() : undefined;
    const diffFramePos = {
      x: photoContainerTfData ? ((photoContainerTfData.width * scaleContainer.scaleX) - photoContainerTfData.width) / 2 : 0,
      y: photoContainerTfData ? ((photoContainerTfData.height * scaleContainer.scaleX) - photoContainerTfData.height) / 2 : 0,
    };
    if (CjTool.checkPhotoInFrame(target)) {
      photoContainer.rotation = rotateContainer.isRotate
        ? photoContainer.rotation + 90
        : photoContainer.rotation;
    }
    const framePos = CjTool.getFramePos(target);
    let pos: Pos = this.posManager.canvasToBrowser({
      x: (focusMode === 'rotate' || focusMode === 'dai')
        ? imageContainerData!.x + (photoContainerTfData!.x - photoContainer.movePos.x) + imageAreaContainer.movePos.x + (photoContainer.movePos.x * scaleContainer.scaleX) - diffFramePos.x - framePos.x
        : defaultData.x,
      y: (focusMode === 'rotate' || focusMode === 'dai')
        ? imageContainerData!.y - 30 + (photoContainerTfData!.y - photoContainer.movePos.y) + imageAreaContainer.movePos.y + (photoContainer.movePos.y * scaleContainer.scaleX) - diffFramePos.y - framePos.y
        : defaultData.y - 30,
    });
    // 写真枠が回転している場合
    if (focusMode === 'rotate' && rotateContainer.isRotate) {
      pos = this.posManager.canvasToBrowser({
        x: imageContainerData!.x + (photoContainerTfData!.y - photoContainer.movePos.y) - imageAreaContainer.movePos.y - (photoContainer.movePos.y * scaleContainer.scaleX) - diffFramePos.y + framePos.y,
        y: imageContainerData!.y + (photoContainerTfData!.x - photoContainer.movePos.x) + imageAreaContainer.movePos.x + (photoContainer.movePos.x * scaleContainer.scaleX) - diffFramePos.x - framePos.x,
      });
    }
    if (CjTool.checkPhotoInFrame(target)) {
      photoContainer.rotation = rotateContainer.isRotate
        ? photoContainer.rotation - 90
        : photoContainer.rotation;
    }
    UiManager.ins.emit('l->r:select', {
      image: target,
      pos,
      isImgDisabled: focusMode === 'dai' || imageAreaContainer.logoFlag ? true : !Boolean(isPhoto),
    });
  }

  // -- containerのfocus --
  handleFocus(target: CjContainer, focusMode: FocusModeType) {
    // --- すでに表示されているfocusの削除 ---
    this.layoutDataManager.dispatch('setFocusMode', focusMode);
    if (this.textEvent.isFocus && this.textEvent.targetFrame) {
      this.editableEvent.disFocused(this.textEvent.targetFrame);
    }
      if (target !== this.targetFrame) {
      UiManager.ins.emit('l->r:dis-select');
      if (this.targetFrame) {
        this.editableEvent.disFocused(this.targetFrame);
      }
    }
    this.isFocus = true;
    this.targetFrame = target;
    const rotateContainer = CjTool.getRotateContainer(target);
    const rotateLength = rotateContainer.numChildren;
    let focus;
    switch (focusMode) {
      case 'scale':
        focus = CjTool.getFocusContainer(target, CJ_CONTAINER_NAME.focus.scale);
        focus.visible = true;
        UiManager.ins.emit('l->r:change-image/frame', { mode: 'image-frame' });
        const imageArea = CjTool.getImageAreaContainer(target);
        imageArea && rotateContainer.setChildIndex(imageArea, rotateLength - 1);
        break;
      case 'rotate':
      case 'dai' :
        focus = CjTool.getFocusContainer(target, CJ_CONTAINER_NAME.focus.rotate);
        focus.visible = true;
        UiManager.ins.emit('l->r:change-image/frame', { mode: 'image' });
        const image = CjTool.getImageContainer(target);
        image && rotateContainer.setChildIndex(image, rotateLength - 1);
        break;
      // case 'dai':
      //   focus = CjTool.getFocusContainer(target, CJ_CONTAINER_NAME.focus.rotate);
      //   focus.visible = true;
      //   UiManager.ins.emit('l->r:change-image/frame', { mode: 'image' });
      //   const imageDai = CjTool.getImageContainer(target);
      //   imageDai && rotateContainer.setChildIndex(imageDai, rotateLength - 1);
      //   break;
      case 'move':
      case 'none':
        focus = CjTool.getFocusContainer(target, CJ_CONTAINER_NAME.focus.move);
        focus.visible = true;
        break;
      default:
        break;
    }
    this.activeFocus = focus ?? null;
    const editablePhoto = CjTool.getDefaultContainer(target);
    const frameLength = this.stage.numChildren;
    editablePhoto && this.stage.setChildIndex(editablePhoto, frameLength - 1);
    const imaContainer = CjTool.getImageAreaContainer(target);
    const imaData = CjTool.getImageAreaContainer(target).getTransformedBounds();
    // 写真枠移動のときのツールチップ表示
    const pos = this.posManager.canvasToBrowser({
      // container の外側の座標だから container の座標を引いとく
      x: imaData.x + imaData.width / 2 - 86 + imaContainer.movePos.x,
      y: imaData.y + imaData.height + imaContainer.movePos.y + 10,
    });
    if (focusMode === 'move') {
      UiManager.ins.emit('l->r:show-tooltip', {
        type: 'frameMove',
        pos,
      });
    }
    // --- ツールメニューの表示 ---
    this.updateToolPos(focusMode, target);
    // this.stage.stage.getChildByName(CJ_CONTAINER_NAME.baseMask).visible = false;
  }

  // -- PNG画像のフォーカス --
  handlePngFocus(target: CjContainer) {
    if (target !== this.targetFrame || this.textEvent.targetFrame) {
      UiManager.ins.emit('l->r:dis-select');
      this.editableEvent.disFocused(this.targetFrame!);
      this.textEvent.targetFrame && this.editableEvent.disFocused(this.textEvent.targetFrame);
    }
    // --- すでに表示されているfocusの削除 ---
    UiManager.ins.emit('l->r:dis-select');
    const targetData = target.getTransformedBounds();
    // --- ツールメニューの表示 ---
    const pos: Pos = this.posManager.canvasToBrowser({
      x: targetData.x,
      y: targetData.y - 30,
    });
    CjTool.checkLoad(target).then((v) => {
      UiManager.ins.emit('l->r:select', {
        image: v,
        pos,
        isImgDisabled: true,
      });
    }).finally(() => {
      this.targetFrame = target;
      let focus = target.getChildByName(CJ_CONTAINER_NAME.focus.rotate) as CjContainer;
      focus.visible = true;
      this.layoutDataManager.dispatch('setFocusMode', 'png');
      UiManager.ins.emit('l->r:change-image/frame', { mode: 'image' });
      this.activeFocus = focus ?? null;
      const frameLength = this.stage.numChildren;
      target && this.stage.setChildIndex(target, frameLength - 1);
    });
  }

  // -- 移動開始 --
  handleMoveStart(target: CjContainer, e: any, isPng?: boolean) {
    if (this.transformed) return;
    const mouseX = Math.round(e.stageX - ImageEditManager.STAGE_CONTAINER_X);
    const mouseY = Math.round(e.stageY - ImageEditManager.STAGE_CONTAINER_Y);
    this.dragPointX = mouseX;
    this.dragPointY = mouseY;
    UiManager.ins.emit('l->r:show-tooltip', { type: 'none', pos: { x: 0, y: 0 } });
    // --- pngの移動 ---
    if (isPng) {
      // --- drag地点の保持 ---
      // --- Redo用移動前座標の保持 ---
      this.targetData.pos = { x: target.x, y: target.y };
      return;
    }
    const focusMode = this.layoutDataManager.selector('getFocusMode');
    // --- 写真入れ替えモード ---
    if (focusMode === 'rotate' || focusMode === 'dai' && this.activeFocus) {
      if (!CjTool.checkPhotoInFrame(target)) return;
      const photo = CjTool.getPhotoContainer(target);
      // --- Redo用移動前座標の保持 ---
      this.targetData.pos = { x: Math.round(photo.x), y: Math.round(photo.y) };
    } else if (this.activeFocus) {
      // --- drag地点の保持 ---
      this.dragPointX -= target.x;
      this.dragPointY -= target.y;
      // --- Redo用移動前座標の保持 ---
      this.targetData.pos = { x: target.x, y: target.y };
    }
    // 動かすときにはツールチップ非表示
    UiManager.ins.emit('l->r:show-tooltip', { type: 'none', pos: { x: 0, y: 0 } });
  }

  // -- containerの移動 --
  handleMove(target: CjContainer, e: any, isPng?: boolean) {
    if (this.transformed) return;
    this.checkCounter++;
    this.isMove = true;
    this.dragTarget = target;
    const mouseX = Math.round(e.stageX - ImageEditManager.STAGE_CONTAINER_X);
    const mouseY = Math.round(e.stageY - ImageEditManager.STAGE_CONTAINER_Y);
    if (isPng) {
      target.x = this.targetData.pos.x + (mouseX - this.dragPointX);
      target.y = this.targetData.pos.y + (mouseY - this.dragPointY);
      return;
    }
    const focusMode = this.layoutDataManager.selector('getFocusMode');
    // --- 写真入れ替えモード ---
    if (!this.activeFocus && focusMode !== 'dai') {
      if (Math.abs(mouseX - this.dragPointX) > 50
        || Math.abs(mouseY - this.dragPointY) > 50) {
        this.movingTarget = e.currentTarget;
        const checkPhoto = CjTool.checkPhotoInFrame(target);
        if (!this.movingTarget || !checkPhoto) return;
        if (this.isFastMove) {
          const photo = CjTool.getPhoto(this.movingTarget);
          this.previewImage = photo.clone();
          this.stage.addChild(this.previewImage);
          this.previewImage.set({
            x: mouseX,
            y: mouseY,
            scaleX: photo.scaleX / 2,
            scaleY: photo.scaleY / 2,
            alpha: 0.5,
            name: 'preview',
          });
          this.isFastMove = false;
        } else {
          if (this.previewImage) {
            this.previewImage.x = mouseX;
            this.previewImage.y = mouseY;
          }
        }
      } else {
        this.movingTarget = null;
      }
      // --- 繰り返し範囲を編集中のページに絞る ---
      const containers: CjContainer[] = this.layoutDataManager.selector('getImageAreas', e.currentTarget.pageId);
      // const containers = _containers.filter((v) => v.photoAreaId !== e.currentTarget.photoAreaId);
      for (const container of containers) {
        // --- ハイライトの抽出 ---
        const highlight = CjTool.getHighlight(container);
        // --- hitTestの対象指定 ---
        const point = container.globalToLocal(Math.round(e.stageX), Math.round(e.stageY));
        if (container.hitTest(point.x, point.y)) {
          // ---- 枠にハイライト ----
          if ((this.targetFrame === container && container.isChecked) || !highlight) return;
          if (container.photoAreaId !== e.currentTarget.photoAreaId) {
            highlight.visible = true;
          }
          // ---- 写真をドロップできる ----
          container.isChecked = true;
          // defaultContainer.mouseEnabled = false;
          this.targetFrame = container;
        } else {
          // ---- 枠外でハイライトの削除 ----
          if (!highlight) return;
          highlight.visible = false;
          // ---- 写真をドロップできない ----
          container.isChecked = false;
          // defaultContainer.mouseEnabled = true;
          this.targetFrame = null;
        }
          // NOTE: かなり重くなるかもしれないので要注意
          // --- 複数ハイライト時一番上に絞る ---
          const _targets = containers.filter((elm) => elm.isChecked);
          const targets = _targets.filter(v => !isNaN(Number(v.depth)));
          const max = Math.max(...targets.map(v => v.depth));
          targets.forEach(v => {
            if (v.depth !== max) {
              CjTool.getHighlight(v).visible = false;
            }
          });
      }
      return;
    }
    // --- 写真の移動 ---
    if ((focusMode === 'rotate' || focusMode === 'dai') && this.activeFocus) {
      if (!CjTool.checkPhotoInFrame(target)) return;
      const photo = CjTool.getPhotoContainer(target);
      const scaleContainer = CjTool.getScaleContainer(target);
      const rotateContainer = CjTool.getRotateContainer(target);
      if (!rotateContainer.isRotate) {
        photo.x = this.targetData.pos.x + ((mouseX - this.dragPointX) / scaleContainer.scaleX);
        photo.y = this.targetData.pos.y + ((mouseY - this.dragPointY) / scaleContainer.scaleY);
      } else {
        photo.x = this.targetData.pos.x + ((mouseY - this.dragPointY) / scaleContainer.scaleY);
        photo.y = this.targetData.pos.y - ((mouseX - this.dragPointX) / scaleContainer.scaleX);
        // photo.movePos.x = mouseX - this.dragPointX;
        // photo.movePos.y = mouseY - this.dragPointY;
      }
      if (!(this.checkCounter % this.delayNum)) {
        const photoFrames: [] = this.layoutDataManager.selector('getEditors', photo.pageId);
        const isToast = FitChecker.checkImageFit(photo, photoFrames, this.stage);
        this.imageEditManager.isFit = isToast;
        UiManager.ins.emit('l->r:show-toast', { isToast, target });
      }
    } else if (focusMode === 'move' && this.activeFocus) {
      target.x = mouseX - this.dragPointX;
      target.y = mouseY - this.dragPointY;
    }
  }

  // -- containerの移動end --
  handleMoveEnd(container: CjContainer, e: any, isPng?: boolean) {
    if (!container || this.transformed || !this.isMove) {
      this.isExchange = false;
      this.isMove = false;
      this.dragTarget = null;
      this.checkCounter = 0;
      return;
    }
    this.isExchange = false;
    this.isMove = false;
    this.dragTarget = null;
    this.checkCounter = 0;
    const focusMode = this.layoutDataManager.selector('getFocusMode');
    switch (container.name) {
      case CJ_CONTAINER_NAME.editor:
        const guid = CjTool.getGuidContainer(this.stage);
        if (!guid) break;
        if (!CjTool.hitTest(container, guid)) {

          const targetBef = this.targetData.pos;
          const leftBef = Math.round(targetBef.x);
          const topBef = Math.round(targetBef.y);
          const targetAf = container;
          const leftAf = Math.round(targetAf.x);
          const topAf = Math.round(targetAf.y);

          if (container) {
            container.x = leftBef;
            container.y = topBef;
            container.movePos.x -= leftAf - leftBef;
            container.movePos.y -= topAf - topBef;
          }
          return;
        }
        break;
      default:
        break;
    }
    // --- 写真入れ替えモード完了 ---
    if (this.previewImage) {
      this.stage.removeChild(this.previewImage);
      this.previewImage = null;
      this.isFastMove = true;
    }
    if (!this.activeFocus && focusMode !== 'dai') {
      const _containers: CjContainer[] = this.layoutDataManager.selector('getImageAreas', e.currentTarget.pageId);
      const containers = _containers.filter((v) => v.photoAreaId !== e.currentTarget.photoAreaId);
      const _targets = containers.filter((elm) => elm.isChecked);
      let targetFrame;
      if (_targets.length > 1) {
        const targets = _targets.filter(v => !isNaN(Number(v.depth)));
        const max = Math.max(...targets.map(v => v.depth));
        targetFrame = targets.find(v => v.depth === max);
        targets.forEach(v => {
          if (v.depth !== max) {
            CjTool.getHighlight(v).visible = false;
          }
        });
      } else {
        targetFrame = _targets[0];
      }
      _containers.forEach(v => {
        CjTool.getHighlight(v).visible = false;
        v.isChecked = false;
      });
      const imageBox: { image: EditableImage, target: CjContainer }[] = [];
      if (!targetFrame || !this.movingTarget) return;
      const targetContainer = CjTool.getScaleContainer(targetFrame);
      // ---- ドラッグ地点の画像 ----
      const isPhotoA = CjTool.checkPhotoInFrame(this.movingTarget);
      // ---- ドロップ地点の画像 ----
      const isPhotoB = CjTool.checkPhotoInFrame(targetContainer);
      if (this.movingTarget.photoAreaId === targetContainer.photoAreaId || !isPhotoA) return;
      // ---- ドローワーに追加済みの画像リスト ----
      const editableList = this.editableImageManager.list;
      // ---- ドラッグ地点 ----
      const photoContainerA = CjTool.getPhotoContainer(this.movingTarget);
      const photoA = editableList.find((v) => v.id === photoContainerA.editableImageId);
      imageBox.push({ image: photoA!, target: targetContainer });
      // ---- ドロップ地点 ----
      const photoContainerB = CjTool.getPhotoContainer(targetContainer);
      if (isPhotoB) {
        this.isExchange = true;
        const photoB = editableList.find((v) => v.id === photoContainerB.editableImageId);
        imageBox.push({ image: photoB!, target: this.movingTarget });
        const imageAreaA = CjTool.getImageAreaContainer(imageBox[0].target);
        const imageAreaB = CjTool.getImageAreaContainer(imageBox[1].target);
        if (imageAreaA.logoFlag !== imageAreaB.logoFlag) {
          UiManager.ins.emit('l->r:push-dialog', {
            title: '確認',
            message: [
              `写真枠とロゴ枠の画像の入れ替えはできません。`,
              `それぞれの枠に個別に画像をドラッグドロップして配置してください。`,
            ],
            buttons: [
              {
                label: 'OK',
                callback: () => {
                  const highlightA = CjTool.getHighlight(imageAreaA);
                  const highlightB = CjTool.getHighlight(imageAreaB);
                  highlightA && (highlightA.visible = false);
                  highlightB && (highlightB.visible = false);
                },
              },
            ],
          });
          return;
        }
        this.editableEvent.action(
          'exchange',
          `${imageBox[0].image}と${imageBox[1].image}を交換`,
          () => {
            for (let i = 0; i < imageBox.length; i++) {
              this.imageController.createPhotoImage(imageBox[i].image, imageBox[i].target, {
                ...initPictureFlags,
                isExchange: true,
              });
            }
          },
          () => {
            this.imageController.createPhotoImage(imageBox[0].image, imageBox[1].target, {
              ...initPictureFlags,
              isExchange: true,
            });
            this.imageController.createPhotoImage(imageBox[1].image, imageBox[0].target, {
              ...initPictureFlags,
              isExchange: true,
            });
          },
        );
      } else {
        this.isExchange = true;
        const nowImageArea = CjTool.getImageAreaContainer(this.movingTarget);
        const nextImageArea = CjTool.getImageAreaContainer(targetContainer);
        if (nowImageArea.logoFlag !== nextImageArea.logoFlag) {
          UiManager.ins.emit('l->r:push-dialog', {
            title: '確認',
            message: [
              `写真枠とロゴ枠の画像の入れ替えはできません。`,
              `それぞれの枠に個別に画像をドラッグドロップして配置してください。`,
            ],
            buttons: [
              {
                label: 'OK',
                callback: () => {
                  const highlightA = CjTool.getHighlight(nowImageArea);
                  const highlightB = CjTool.getHighlight(nextImageArea);
                  highlightA && (highlightA.visible = false);
                  highlightB && (highlightB.visible = false);
                },
              },
            ],
          });
          return;
        }
        this.editableEvent.action(
          'exchange',
          `${imageBox[0].image}を移動`,
          () => {
            const container = CjTool.getRotateContainer(this.movingTarget!);
            const imgContainer = CjTool.getImageContainer(this.movingTarget!);
            CjTool.getMaskContainer(container).alpha = 1;
            this.layoutDataManager.dispatch('removeImage', imgContainer);
            container.removeChild(imgContainer);
            this.imageController.createPhotoImage(photoA!, targetContainer, {
              ...initPictureFlags,
              isExchangeOne: true,
            }, imgContainer);
          },
          () => {
            const container = CjTool.getRotateContainer(targetContainer);
            const imgContainer = CjTool.getImageContainer(targetContainer);
            CjTool.getMaskContainer(container).alpha = 1;
            this.layoutDataManager.dispatch('removeImage', imgContainer);
            container.removeChild(imgContainer);
            this.imageController.createPhotoImage(photoA!, this.movingTarget!, {
              ...initPictureFlags,
              isExchangeOne: true,
            });
          },
        );

      }
      return;
    }
    if ((focusMode === 'rotate' || focusMode === 'dai') && !CjTool.checkPhotoInFrame(container)) return;
    // --- ターゲット指定 ---
    const target = (focusMode === 'rotate' || focusMode === 'dai')
      ? CjTool.getPhotoContainer(container)
      : container;
    // --- Redo ---
    const targetBef = this.targetData.pos;
    const leftBef = targetBef.x;
    const topBef = targetBef.y;
    // --- Undo ---
    const targetAf = target;
    const leftAf = targetAf.x;
    const topAf = targetAf.y;
    // --- movePos ---
    const movePos = focusMode === 'move'
      ? CjTool.getImageAreaContainer(target).movePos
      : target.movePos;
    const prevBase = (focusMode === 'rotate' || focusMode === 'dai')
      ? CjTool.getDefaultContainer(target)
      : target;
    // --- fire ---
    this.editableEvent.action(
      'move',
      `left: ${leftBef} → ${leftAf}, top: ${topBef} → ${topAf} に移動`,
      () => {
        let prevTarget;
        if (target) {
          if (!this.isFocus) {
            const isPng = focusMode === 'png';
            if (isPng || focusMode !== 'move') {
              this.imageEditableTool.imageMode(prevBase, isPng);
            }
          }
          prevTarget = (focusMode === 'rotate' || focusMode === 'dai')
            ? CjTool.getPhotoContainer(prevBase)
            : prevBase;
          prevTarget.x = leftAf;
          prevTarget.y = topAf;
          movePos.x += leftAf - leftBef;
          movePos.y += topAf - topBef;
          if (focusMode === 'rotate' || focusMode === 'dai') {
            if (!(this.checkCounter % this.delayNum)) {
              const photoFrames: [] = this.layoutDataManager.selector('getEditors', prevTarget.pageId);
              const isToast = FitChecker.checkImageFit(prevTarget, photoFrames, this.stage);
              this.imageEditManager.isFit = isToast;
              UiManager.ins.emit('l->r:show-toast', { isToast, target });
            }
          }
        }
      },
      () => {
        let prevTarget;
        if (!this.isFocus) {
          const isPng = focusMode === 'png';
          if (isPng || focusMode !== 'move') {
            this.imageEditableTool.imageMode(prevBase, isPng);
          }
        }
        prevTarget = (focusMode === 'rotate' || focusMode === 'dai')
          ? CjTool.getPhotoContainer(prevBase)
          : prevBase;
        if (target) {
          prevTarget.x = leftBef;
          prevTarget.y = topBef;
          movePos.x -= leftAf - leftBef;
          movePos.y -= topAf - topBef;
          if (focusMode === 'rotate' || focusMode === 'dai') {
            if (!(this.checkCounter % this.delayNum)) {
              const photoFrames: [] = this.layoutDataManager.selector('getEditors', prevTarget.pageId);
              const isToast = FitChecker.checkImageFit(prevTarget, photoFrames, this.stage);
              this.imageEditManager.isFit = isToast;
              console.log(7)
              UiManager.ins.emit('l->r:show-toast', { isToast, target });
            }
          }
        }
      },
    );
    const containerData = container.getTransformedBounds();
    const pos = this.posManager.canvasToBrowser({
      // container の外側の座標だから container の座標を引いとく
      x: containerData.x + containerData.width / 2 - 86 + container.movePos.x,
      y: containerData.y + containerData.height + container.movePos.y + 10,
    });
    if (focusMode === 'move') {
      UiManager.ins.emit('l->r:show-tooltip', {
        type: 'frameMove',
        pos,
      });
    } else {
      this.updateToolPos(focusMode, target);
    }
  }

  // -- containerの拡縮start --
  handleTransformStart(target: CjContainer, e: any) {
    this.transformed = true;
    const focusMode = this.layoutDataManager.selector('getFocusMode');
    // --- drag地点の保持 ---
    const mouseX = Math.round(e.stageX - ImageEditManager.STAGE_CONTAINER_X);
    const mouseY = Math.round(e.stageY - ImageEditManager.STAGE_CONTAINER_Y);
    this.dragPointX = Math.round(mouseX);
    this.dragPointY = Math.round(mouseY);

    // --- コンテナ用意 ---
    let container;
    let area;
    let pointA;
    let pointB;
    let pointC;
    // ---クリック地点の角度算出 A:起点(枠の半分, 0) B:中心点（枠の半分, 枠の半分） C:クリック地点 ---
    if (focusMode === 'rotate' || focusMode === 'dai') {
      container = CjTool.getPhotoContainer(target);
      area = CjTool.getImageAreaContainer(target);
      const scaleContainer = CjTool.getScaleContainer(target);
      const areaData = area.getBounds();
      pointA = { x: areaData.x + areaData.width / 2 + (container.movePos.x * scaleContainer.scaleX) + area.movePos.x, y: (container.movePos.y * scaleContainer.scaleX) + area.movePos.y };
      pointB = {
        x: areaData.x + areaData.width / 2 + (container.movePos.x * scaleContainer.scaleX) + area.movePos.x,
        y: areaData.y + areaData.height / 2 + (container.movePos.y * scaleContainer.scaleX) + area.movePos.y,
      };
      pointC = { x: mouseX, y: mouseY };

      if (!CjTool.checkPhotoInFrame(target)) return;
      const photoContainer = CjTool.getPhotoContainer(target);
      // --- Redo用移動前座標の保持 ---
      this.targetData.rotation = photoContainer.rotation;
      this.targetData.pos = { x: photoContainer.x, y: photoContainer.y };
      this.targetData.scale = { x: photoContainer.scaleX, y: photoContainer.scaleY };
      this.targetData.reg = { x: photoContainer.regX, y: photoContainer.regY };
    } else if (focusMode === 'png') {
      container = target;
      const containerData = container.getBounds();
      pointA = { x: target.regX - containerData.width / 2 + target.movePos.x, y: 0 };
      pointB = {
        x: target.regX - containerData.width / 2 + target.movePos.x,
        y: target.regY - containerData.height / 2 + target.movePos.y,
      };
      pointC = { x: mouseX, y: mouseY };
      // --- Redo用移動前座標の保持 ---
      this.targetData.rotation = target.rotation;
      this.targetData.pos = { x: target.x, y: target.y };
      this.targetData.scale = { x: target.scaleX, y: target.scaleY };
      this.targetData.reg = { x: target.regX, y: target.regY };
    } else {
      container = CjTool.getScaleContainer(target);
      const containerData = container.getBounds();
      area = CjTool.getImageAreaContainer(target);
      pointA = { x: containerData.x + containerData.width / 2 + area.movePos.x, y: containerData.y + area.movePos.y };
      pointB = {
        x: containerData.x + containerData.width / 2 + area.movePos.x,
        y: containerData.y + containerData.height / 2 + area.movePos.y,
      };
      pointC = { x: mouseX, y: mouseY };

      // --- Redo用移動前座標の保持 ---
      this.targetData.pos = { x: container.x, y: container.y };
      this.targetData.scale = { x: container.scaleX, y: container.scaleY };
      this.targetData.reg = { x: container.regX, y: container.regY };
    }
    const radianA = Math.atan2(pointA.x - pointB.x, pointA.y - pointB.y);
    const radianB = Math.atan2(pointC.x - pointB.x, pointC.y - pointB.y);
    const angle = (radianA - radianB) / Math.PI * 180;
    // --- 角度からクリックされたコーナーを特定 ---
    if (angle >= 0 && angle < 90) {
      this.cornerType = 'rt';
    } else if (angle >= 90 && angle < 180) {
      this.cornerType = 'rb';
    } else if (angle >= 180 && angle < 270) {
      this.cornerType = 'lb';
    } else if (angle >= 270 && angle < 360) {
      this.cornerType = 'lt';
    }
    // --- png画像時の原点ずれによるコーナ情報のずれを抑制 ---
    if (focusMode === 'png') {
      const targetCorner = e.target.position
      const targetAngle = target.rotation < 0 ? target.rotation + 360 : target.rotation % 360
      switch (targetCorner) {
        case 'rt':
          if (targetAngle >= 315 || (targetAngle >= 0 && targetAngle < 45)) {
            this.cornerType = 'rt';
          } else if (targetAngle >= 45 && targetAngle < 135) {
            this.cornerType = 'rb';
          } else if (targetAngle >= 135 && targetAngle < 225) {
            this.cornerType = 'lb';
          } else if (targetAngle >= 225 && targetAngle < 315) {
            this.cornerType = 'lt';
          }
          break;
        case 'lb':
          if (targetAngle >= 315 || (targetAngle >= 0 && targetAngle < 45)) {
            this.cornerType = 'lb';
          } else if (targetAngle >= 45 && targetAngle < 135) {
            this.cornerType = 'lt';
          } else if (targetAngle >= 135 && targetAngle < 225) {
            this.cornerType = 'rt';
          } else if (targetAngle >= 225 && targetAngle < 315) {
            this.cornerType = 'rb';
          }
          break;
        default:
          break;
      }
    }
  }

  // -- containerの拡縮処理 --
  handleScaleMove(target: CjContainer, e: any) {
    this.checkCounter++;
    const focusMode = this.layoutDataManager.selector('getFocusMode');
    const isPng = this.layoutDataManager.selector('getFocusMode') === 'png';
    const imageArea = !isPng ? CjTool.getImageAreaContainer(target) : target;
    const scaleContainer = !isPng ? CjTool.getScaleContainer(imageArea) : target;
    let container;
    if (focusMode === 'rotate' || focusMode === 'dai') {
      container = CjTool.getPhotoContainer(target);
    } else if (focusMode === 'png') {
      container = target;
    } else {
      container = CjTool.getScaleContainer(target);
    }
    const mouseX = Math.round(e.stageX - ImageEditManager.STAGE_CONTAINER_X);
    const mouseY = Math.round(e.stageY - ImageEditManager.STAGE_CONTAINER_Y);
    const data = container.getTransformedBounds();
    const difX = (mouseX - this.dragPointX);
    const difY = (mouseY - this.dragPointY);
    const sizeX = container.originalBounds.width * (focusMode === 'rotate' || focusMode === 'dai' ? scaleContainer.scaleX : 1);
    const sizeY = container.originalBounds.height * (focusMode === 'rotate' || focusMode === 'dai' ? scaleContainer.scaleY : 1);
    let scaleX;
    let scaleY;
    switch (this.cornerType) {
      case 'lt':
        scaleX = (((sizeX * this.targetData.scale.x) + (difX * -2))) / sizeX;
        scaleY = (((sizeY * this.targetData.scale.y) + (difY * -2))) / sizeY;
        break;
      case 'rt':
        scaleX = (((sizeX * this.targetData.scale.x) + (difX * 2))) / sizeX;
        scaleY = (((sizeY * this.targetData.scale.y) + (difY * -2))) / sizeY;
        break;
      case 'rb':
        scaleX = (((sizeX * this.targetData.scale.x) + (difX * 2))) / sizeX;
        scaleY = (((sizeY * this.targetData.scale.y) + (difY * 2))) / sizeY;
        break;
      case 'lb':
        scaleX = (((sizeX * this.targetData.scale.x) + (difX * -2))) / sizeX;
        scaleY = (((sizeY * this.targetData.scale.y) + (difY * 2))) / sizeY;
        break;
      default:
        scaleX = this.targetData.scale.x;
        scaleY = this.targetData.scale.y;
        break;
    }
    let scale = scaleX > scaleY ? scaleX : scaleY;

    // 20%~400%の間でscaling
    const scaleMin = (imageArea.logoFlag && !target.logoFlag) ? (imageArea.initResizeScale ?? 1) * 0.2 : 0.2;
    const scaleMax = (imageArea.logoFlag && !target.logoFlag) ? (imageArea.initResizeScale ?? 1) * 4 : 4;
    if (scale < scaleMin) {
      scale = scaleMin;
    } else if (scale > scaleMax) {
      scale = scaleMax;
    }
    const corners = focusMode === 'png'
      ? CjTool.getCorner(container, true)
      : CjTool.getCorner(container);
    for (const corner of corners) {
      const cornerScale = corner.parent.parent.name === 'photo-container'
        ? 1 / scale / scaleContainer.scaleX
        : target.name === 'photo-container'
          ? corner.scaleX
          : 1 / scale;
      corner.scaleX = cornerScale;
      corner.scaleY = cornerScale;
    }
    container.set({
      scaleX: Math.floor(scale * 100) / 100,
      scaleY: Math.floor(scale * 100) / 100,
    });
    if (focusMode === 'rotate' || focusMode === 'dai') {
      if (!CjTool.checkPhotoInFrame(target)) return;
      const photo = CjTool.getPhotoContainer(target);
      if (!(this.checkCounter % this.delayNum)) {
        const photoFrames: [] = this.layoutDataManager.selector('getEditors', photo.pageId);
        const isToast = FitChecker.checkImageFit(photo, photoFrames, this.stage);
        this.imageEditManager.isFit = isToast;
        UiManager.ins.emit('l->r:show-toast', { isToast, target });
      }
    }
  }

  handleRotateMove(target: CjContainer, e: any, moveTo: CornerType) {
    const focusMode = this.layoutDataManager.selector('getFocusMode');
    this.checkCounter++;
    const container = focusMode === 'rotate'
      ? CjTool.getPhotoContainer(target)
      : target;
    const containerData = container.getBounds();
    const mouseX = Math.round(e.stageX - ImageEditManager.STAGE_CONTAINER_X);
    const mouseY = Math.round(e.stageY - ImageEditManager.STAGE_CONTAINER_Y);
    // --- A:ドラッグ起点 B：画像の中心 C：現在のマウス地点 ---
    let pointA;
    let pointB;
    const pointC = { x: mouseX, y: mouseY };

    if (focusMode === 'rotate' || focusMode === 'dai') {
      const area = CjTool.getImageAreaContainer(target);
      const areaData = area.getBounds();
      pointA = { x: this.dragPointX, y: this.dragPointY };
      pointB = {
        x: areaData.x + areaData.width / 2 + container.movePos.x + area.movePos.x,
        y: areaData.y + areaData.height / 2 + container.movePos.y + area.movePos.y,
      };
      if (!(this.checkCounter % this.delayNum)) {
        const photoFrames: [] = this.layoutDataManager.selector('getEditors', container.pageId);
        const isToast = FitChecker.checkImageFit(container, photoFrames, this.stage);
        this.imageEditManager.isFit = isToast;
        UiManager.ins.emit('l->r:show-toast', { isToast, target });
      }
    } else {
      pointA = { x: this.dragPointX, y: this.dragPointY };
      pointB = focusMode === 'png'
        ? {
          x: containerData.x + container.movePos.x,
          y: containerData.y + container.movePos.y,
        }
        : {
          x: containerData.x + containerData.width / 2 + container.movePos.x,
          y: containerData.y + containerData.height / 2 + container.movePos.y,
        };
    }
    const radianA = Math.atan2(pointA.x - pointB.x, pointA.y - pointB.y);
    const radianB = Math.atan2(pointC.x - pointB.x, pointC.y - pointB.y);
    const angle = (radianA - radianB) / Math.PI * 180;

    const nextRotate = this.targetData.rotation + angle;
    container.rotation = 0 > nextRotate ? 360 + nextRotate : nextRotate;
  }


  // -- containerの拡縮end --
  handleTransformEnd(target: CjContainer, e: any) {
    this.checkCounter = 0;

    const ratio = (_corner: CjCorner) => _corner.parent.parent.name === 'photo-container' ? CjTool.getImageAreaContainer(target).scaleX : 1;
    const focusMode = this.layoutDataManager.selector('getFocusMode');
    if (focusMode === 'rotate' || focusMode === 'png' || focusMode === 'dai') {
      const photoContainer = (focusMode === 'rotate' || focusMode === 'dai')
        ? CjTool.getPhotoContainer(target)
        : target;
      // --- Redo ---
      const rotateBef = Math.floor(this.targetData.rotation * 100) / 100;
      const scaleBef = Math.floor(this.targetData.scale.x * 100) / 100;
      // --- Undo ---
      const rotateAf = Math.floor(photoContainer.rotation * 100) / 100;
      const scaleAf = Math.floor(photoContainer.scaleX * 100) / 100;
      const prevBase = (focusMode === 'rotate' || focusMode === 'dai')
        ? CjTool.getDefaultContainer(target)
        : target;
      // --- fire ---
      this.editableEvent.action(
        'transform',
        `${rotateBef}から${rotateAf}度まで回転。${scaleBef}から${scaleAf}倍まで拡縮。`,
        () => {
          let prevTarget;
          if (!this.isFocus) {
            const isPng = focusMode === 'png';
            this.imageEditableTool.imageMode(prevBase, isPng);
          }
          prevTarget = (focusMode === 'rotate' || focusMode === 'dai')
            ? CjTool.getPhotoContainer(prevBase)
            : prevBase;
          prevTarget.rotation = rotateAf;
          prevTarget.scaleX = scaleAf;
          prevTarget.scaleY = scaleAf;
          prevTarget.addDifferPos.x = prevTarget.originalBounds.width * scaleAf - prevTarget.virtual.width;
          prevTarget.addDifferPos.y = prevTarget.originalBounds.height * scaleAf - prevTarget.virtual.height;
          if (!(this.checkCounter % this.delayNum)) {
            const photoFrames: [] = this.layoutDataManager.selector('getEditors', prevTarget.pageId);
            const isToast = FitChecker.checkImageFit(prevTarget, photoFrames, this.stage);
            this.imageEditManager.isFit = isToast;
            console.log(10)
            UiManager.ins.emit('l->r:show-toast', { isToast, target });
          }
          this.layoutDataManager.dispatch('setScale', prevTarget);
        },
        () => {
          if (!this.isFocus) {
            const isPng = focusMode === 'png';
            this.imageEditableTool.imageMode(prevBase, isPng);
          }
          let prevTarget;
          prevTarget = (focusMode === 'rotate' || focusMode === 'dai')
            ? CjTool.getPhotoContainer(prevBase)
            : prevBase;
          prevTarget.rotation = rotateBef;
          prevTarget.scaleX = scaleBef;
          prevTarget.scaleY = scaleBef;
          prevTarget.addDifferPos.x = prevTarget.originalBounds.width / scaleAf - prevTarget.virtual.width;
          prevTarget.addDifferPos.y = prevTarget.originalBounds.height / scaleAf - prevTarget.virtual.height;
          if (!(this.checkCounter % this.delayNum)) {
            const photoFrames: [] = this.layoutDataManager.selector('getEditors', prevTarget.pageId);
            const isToast = FitChecker.checkImageFit(prevTarget, photoFrames, this.stage);
            this.imageEditManager.isFit = isToast;
            UiManager.ins.emit('l->r:show-toast', { isToast, target });
          }
          this.layoutDataManager.dispatch('setScale', prevTarget);
        },
      );
    } else {
      const scaleContainer = CjTool.getScaleContainer(target);
      const corners = CjTool.getCorner(scaleContainer);

      // --- Redo ---
      const scaleBef = this.targetData.scale.x;
      // --- Undo ---
      const scaleAf = scaleContainer.scaleX;
      // --- fire ---
      this.editableEvent.action(
        'scale',
        `${scaleBef}から${scaleAf}倍まで拡縮。`,
        () => {
          scaleContainer.scaleX = scaleAf;
          scaleContainer.scaleY = scaleAf;
          for (const corner of corners) {
            const cornerScaleAf = 1 / (scaleContainer.scaleX * ratio(corner));
            corner.scaleX = cornerScaleAf;
            corner.scaleY = cornerScaleAf;
          }
          this.layoutDataManager.dispatch('setScale', scaleContainer);
        },
        () => {
          scaleContainer.scaleX = scaleBef;
          scaleContainer.scaleY = scaleBef;
          for (const corner of corners) {
            const cornerScaleBef = 1 / (this.targetData.scale.x * ratio(corner));
            corner.scaleX = cornerScaleBef;
            corner.scaleY = cornerScaleBef;
          }
          this.layoutDataManager.dispatch('setScale', scaleContainer);
        },
      );
      // this.imageEditableTool.frameMode(target);
    }
    this.updateToolPos(focusMode, target);
    this.transformed = false;
  }

  // マウスオーバーのイベント（ツールチップ表示）
  mouseOverEvent(e: MouseEvent, image: CjContainer) {
    let defaultPos = { x: this.stage.stage.mouseX, y: this.stage.stage.mouseY };
    this.timerId = setInterval(() => {
      if (defaultPos.x === this.stage.stage.mouseX
        && defaultPos.y === this.stage.stage.mouseY) {
        if (this.showToolTip) return;
        this.showToolTip = true;
        const photo = CjTool.checkPhotoInFrame(e.currentTarget as CjContainer);
        const pos = this.posManager.canvasToBrowser({
          // container の外側の座標だから container の座標を引いとく
          x: this.stage.stage.mouseX - ImageEditManager.STAGE_CONTAINER_X + 10,
          y: this.stage.stage.mouseY - ImageEditManager.STAGE_CONTAINER_Y + 10,
        });
        if (photo && !this.activeFocus && this.isSelect) {
          UiManager.ins.emit('l->r:show-tooltip', {
            type: 'imageHover',
            pos,
          });
        }
      } else {
        this.showToolTip = false;
        defaultPos = { x: this.stage.stage.mouseX, y: this.stage.stage.mouseY };
        const photo = CjTool.checkPhotoInFrame(image);
        if (photo && !this.activeFocus) {
          UiManager.ins.emit('l->r:show-tooltip', { type: 'none', pos: { x: 0, y: 0 } });
        }
      }
    }, 300);
  }

  // マウスアウトのイベント（ツールチップ表示）
  mouseOutEvent(e: MouseEvent, image: CjContainer) {
    this.timerId && clearInterval(this.timerId);
    this.showToolTip = false;
    const photo = CjTool.checkPhotoInFrame(image);
    if (photo && !this.activeFocus) {
      UiManager.ins.emit('l->r:show-tooltip', { type: 'none', pos: { x: 0, y: 0 } });
    }
  }

  // イメージのハンドラ設定
  setImageEvent(image: CjContainer, type: 'frame' | 'image' | 'dai' | 'none', focus?: FocusModeType) {
    if (type === 'dai') {
      const focusMode = this.layoutDataManager.selector('getFocusMode');
      image.on('click', (e: any) => {
        e.stopPropagation();
        this.trimmingManager.moveEnd();
        if (!this.isSelect) return;
        if (this.textEvent.isFocus) {
          this.textEvent.targetFrame && this.editableEvent.disFocused(this.textEvent.targetFrame);
          return;
        }
        if (this.clicked) {
          this.clicked = false;
          if (this.textEvent.isActive) return;
          const prevPhotoImage = CjTool.checkPhotoInFrame(image);
          if (prevPhotoImage) {
            if ((focusMode === 'scale' || focusMode === 'DAI')) {
              this.imageEditableTool.imageMode(image, false, true);
              UiManager.ins.emit('l->r:change-image/frame', { mode: 'image', isAdditional: true });
            }
          }
          return;
        }
        // --- シングルクリックの場合 ---
        this.clicked = true;
        // --- ダブルクリック受付時間 ---
        setTimeout(() => {
          // ---- ダブルクリックだとfalse ----
          if (this.clicked && focusMode !== 'rotate') {
            if (this.textEvent.isActive) return;
            UiManager.ins.emit('l->r:change-middle-frame', { id: Number(image.photoAreaId) });
          }
          this.clicked = false;
        }, 250);

      });
    }
    if (type === 'image') {
      CjTool.checkLoad(image).then((v) => {
        this.createFocus(v, focus ? focus : 'rotate')
          .then((container) => {
            container.visible = false;
            image.addChild(container);
          });
      });
      if (focus === 'png') {
        image.on('click', (e: any) => {
          e.stopPropagation();
          if (!this.isSelect || this.textEvent.isInsert) return;
          const corners = CjTool.getCorner(image, true);
          for (const corner of corners) {
            const scale = 1 / image.scaleX;
            corner.scaleX = scale;
            corner.scaleY = scale;
          }
          this.imageEditableTool.imageMode(image, true);
          UiManager.ins.emit('l->r:change-image/frame', { mode: 'image' });
        });
      }
    } else {
      const imageArea = CjTool.getImageAreaContainer(image);
      if (type === 'frame') {
        CjTool.checkLoad(imageArea).then((v) => {
          this.createFocus(v, 'scale')
            .then((container) => {
              container.visible = false;
              imageArea.addChild(container);
            });
          this.createFocus(v, 'move')
            .then((container) => {
              container.visible = false;
              imageArea.addChild(container);
            });
        });
        imageArea.removeAllEventListeners();
        // -- 枠の選択（フォーカスする） --
        imageArea.on('click', (e: any) => {
          e.stopPropagation();
          if (this.textEvent.isInsert) return;
          const _imageArea = CjTool.getImageAreaContainer(image);
          const photoContainer = CjTool.getPhotoContainer(image);
          const scaleContainer = CjTool.getScaleContainer(_imageArea);
          const corners = CjTool.getCorner(image);
          const ratio = (_target: CjCorner | CjShape) => _target.parent.parent.name === 'photo-container' ? scaleContainer.scaleX : 1;
          const parentScale = (_target: CjCorner | CjShape) => _target.parent.parent.name === 'photo-container' ? photoContainer.scaleX : scaleContainer.scaleX;

          for (const corner of corners) {
            const scale = 1 / parentScale(corner) / ratio(corner);
            corner.scaleX = scale;
            corner.scaleY = scale;
          }
          const focusMode = this.layoutDataManager.selector('getFocusMode');
          if (!this.isSelect) return;
          // if (focusMode !== 'scale' || !this.isSelect) return;
          // --- ダブルクリックの場合 ---
          if (this.clicked) {
            this.clicked = false;
            if (this.textEvent.isActive) return;
            const prevPhotoImage = CjTool.checkPhotoInFrame(imageArea);
            if (prevPhotoImage) {
              if (focusMode === 'scale' && (!this.targetFrame || this.targetFrame === imageArea)) {
                this.imageEditableTool.imageMode(imageArea);
                UiManager.ins.emit('l->r:change-image/frame', { mode: 'image' });
              }
            }
            return;
          }
          // --- シングルクリックの場合 ---
          this.clicked = true;
            UiManager.ins.emit('l->r:show-tooltip', { type: 'none', pos: { x: 0, y: 0 } });
          // --- ダブルクリック受付時間 ---
          setTimeout(() => {
            // ---- ダブルクリックだとfalse ----
            if (this.clicked) {
              if (this.textEvent.isActive) return;
              if (focusMode === 'scale' && !imageArea.logoFlag) {
                this.handleFocus(imageArea, 'scale');
              }
            }
            this.clicked = false;
          }, 250);
        });

        // '「ダブルクリック」でトリミング'のツールチップ表示
        imageArea.addEventListener('mouseover', (e: any) => this.mouseOverEvent(e, image));
        imageArea.addEventListener('mouseout', (e: any) => this.mouseOutEvent(e, image));
      }
      // -- DnDイベント設定 --
      image.addEventListener('mousedown', (e: any) => {

        const focusMode = this.layoutDataManager.selector('getFocusMode');
        if (focusMode === 'move') {
          this.handleMoveStart(image, e);
          return;
        }
        const isPhoto = CjTool.checkPhotoInFrame(image);
        if (this.clicked || focusMode === 'rotate' || !this.isSelect || this.isFocus || !isPhoto) return;
        if (focusMode === 'png' && this.targetFrame) {
          this.editableEvent.disFocused(this.targetFrame);
        }
        this.handleMoveStart(image, e);
      }, true);
      image.addEventListener('pressmove', (e: any) => {
        const focusMode = this.layoutDataManager.selector('getFocusMode');
        if (focusMode === 'move') {
          this.handleMove(image, e);
          return;
        }
        if (this.clicked || focusMode === 'rotate' || !this.isSelect || this.isFocus) return;
        if (focusMode === 'png' && this.targetFrame) {
          this.editableEvent.disFocused(this.targetFrame);
        }
        this.handleMove(image, e);
      }, true);
      image.addEventListener('pressup', (e: any) => {
        const focusMode = this.layoutDataManager.selector('getFocusMode');
        if (focusMode === 'move') {
          this.handleMoveEnd(image, e);
          return;
        }
        if (focusMode === 'rotate' || !this.isSelect || this.isFocus) return;
        if (focusMode === 'png' && this.targetFrame) {
          this.editableEvent.disFocused(this.targetFrame);
        }
        this.handleMoveEnd(image, e);
      }, true);
    }
  }
}
