import React from 'react';
import './wizard.scss';
import { WizardStepBar } from './step-bar-wizard';
import { WizardContentModel } from './wizard-content';

type Props = {
  title?: string,
  step: number
  children: React.ReactElement<WizardContentModel>[]
  // children: any
}

export const Wizard: React.FC<Props> = (props: Props) => {
  const { title, step, children } = props;
  const arrayChildren = React.Children.toArray(children);
  return (
      <div className="wizard">
        <div className="wizard__contents">
          <div className="wizard__contents__inner">
            {title && title.length > 0 ? (
              // タイトル
              <h1 className="wizard__contents__inner__title">
                {title}
              </h1>
            ) : null}
            {/* ステップバー */}
            <WizardStepBar
              currentStep={step}
              totalStep={arrayChildren.length}
              className={'wizard__contents__inner__step-bar'}
            />
            {arrayChildren[step - 1]}
          </div>
        </div>
      </div>
  );
};