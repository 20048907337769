import { RefObject, useEffect } from 'react';

const defaultOption: Option = {
  enableClassNameList: [],
  disableClassNameList: [],
  limitClassName: '',
};

type Option = {
  // ドラッグ可能なクラス名 (優先)
  enableClassNameList?: string[],
  // ドラッグ不可能なクラス名
  disableClassNameList?: string[],
  // ドラッグ限界 (エレメントのクラス名を指定)
  limitClassName?: string,
};

const useDraggable = (ref: RefObject<HTMLElement>, option?: Option) => {
  useEffect(() => {
    const opt = { ...defaultOption, ...option };
    const target = ref.current;
    if (!target) {
      return;
    }
    // - variable -
    // -- element --
    const limitElement = document.getElementsByClassName(opt.limitClassName || '')[0] || null;
    // -- flags --
    const flags = {
      enable: true,
      limit: false,
    };
    // -- pos --
    let offsetX = 0;
    let offsetY = 0;
    let currentX = 0;
    let currentY = 0;
    let initialX = 0;
    let initialY = 0;
    // - function -
    // -- check enable --
    const checkEnable = (ele: HTMLElement, enableClassList: string[] = [], disableClassList: string[] = []) => {
      let enable = true;
      disableClassList.forEach((className: string) => {
        enable = !ele.classList.contains(className);
      });
      enableClassList.forEach((className: string) => {
        enable = ele.classList.contains(className);
      });
      // console.group('debug');
      // console.log('ele.classList : ', ele.classList);
      // console.log('enable : ', enable);
      // console.groupEnd();
      return enable;
    };
    // -- limit --
    const checkLimit = (
      limitElement: Element | undefined,
      diff: { x: number, y: number },
    ) => {
      if (!limitElement) {
        return false;
      }
      const targetBoundingRect = target.getBoundingClientRect();
      const limitBoundingRect = limitElement.getBoundingClientRect();
      // console.group('debug');
      // console.log('limitBoundingRect : ', limitBoundingRect);
      // console.log('targetBoundingRect : ', targetBoundingRect);
      // console.log('diff : ', diff);
      // console.groupEnd();
      return (
        limitBoundingRect.left > targetBoundingRect.left + diff.x ||
        limitBoundingRect.top > targetBoundingRect.top + diff.y ||
        limitBoundingRect.right < targetBoundingRect.right + diff.x ||
        limitBoundingRect.bottom < targetBoundingRect.bottom + diff.y
      );
    };
    // -- handler --
    const onMousedown = (e: any) => {
      flags.enable = checkEnable(e.target, opt.enableClassNameList, opt.disableClassNameList);
      if (!flags.enable) {
        return;
      }
      if (e.type === 'touchstart') {
        initialX = e.touches[0].clientX - offsetX;
        initialY = e.touches[0].clientY - offsetY;
        window.addEventListener('touchmove', onMouseMove);
        window.addEventListener('touchend', onMouseUp);
      } else {
        initialX = e.clientX - offsetX;
        initialY = e.clientY - offsetY;
        window.addEventListener('mousemove', onMouseMove);
        window.addEventListener('mouseup', onMouseUp);
      }
    };
    const onMouseMove = (e: any) => {
      e.preventDefault();
      e.stopPropagation();
      if (!document.activeElement || document.activeElement?.tagName !== 'BODY') return;
      if (e.type === 'touchmove') {
        currentX = e.touches[0].clientX - initialX;
        currentY = e.touches[0].clientY - initialY;
      } else {
        currentX = e.clientX - initialX;
        currentY = e.clientY - initialY;
      }
      if (checkLimit(limitElement, { x: currentX - offsetX, y: currentY - offsetY })) {
        return;
      }
      offsetX = currentX;
      offsetY = currentY;
      target.style.transform = `translate(${currentX}px, ${currentY}px)`;
    };
    const onMouseUp = (e: any) => {
      initialX = currentX;
      initialY = currentY;
      if (e.type === 'touchend') {
        window.removeEventListener('touchmove', onMouseMove);
        window.removeEventListener('touchend', onMouseUp);
      } else {
        window.removeEventListener('mousemove', onMouseMove);
        window.removeEventListener('mouseup', onMouseUp);
      }
    };
    // - add event -
    target.addEventListener('mousedown', onMousedown);
    target.addEventListener('touchstart', onMousedown);
    return () => {
      // - remove event -
      target.removeEventListener('mousedown', onMousedown);
      target.removeEventListener('touchstart', onMousedown);
      window.removeEventListener('mouseup', onMouseUp);
      window.removeEventListener('mousemove', onMouseMove);
      window.removeEventListener('touchend', onMouseUp);
      window.removeEventListener('touchmove', onMouseMove);
    };
  }, [ref]);
};

export default useDraggable;
