import React, { useCallback, useEffect, useRef, useState } from 'react';
import './staff-table.scss';
import { DisplayElement } from '../../../models/display-element';
import { useDispatch } from 'react-redux';
import { dialogActions } from '../../dialog/slice/dialog-slice';
import { AddStaff, StaffAddParams } from '../../dialog/unique/add-staff';
import { useParams } from 'react-router-dom';
import { PathParams } from '../../../routes/routing-path';
import { apiActions } from '../../../slices/api-slice';
import {
  ApiGetStaffList,
  ApiPostStaffAdd,
  ApiPostStaffDisable,
  ApiPostStaffPrePasswordChange,
  ApiPostStaffUnlock,
  ApiPostStaffUpdate,
} from '../../../api/front/wizard/api-staff-management';
import { ResponseBase } from '../../../api/response-base';
import { StaffListResponse, StaffResponse } from '../../../models/api/front/staff-management';
import { Button } from '../../ui/button/button';
import { Table } from '../../ui/table/table';
import { EditStaff, StaffEditParams, StaffEditProps } from '../../dialog/unique/edit-staff';
import { ApiStaffPasswordReset } from '../../../api/front/wizard/api-staff-password-reset';
import useInterval from '../../../hooks/useInterval';
import { DateFormatter } from '../../../utilities/date-formatter';
import lockIcon from '../../../assets/img/icon/padlock_on_icon.svg';
import { Checkbox } from '../../ui/input/checkbox';
import { Input } from '../../ui/input/input';
import { useAppSelector } from '../../../app/hooks';
import lodash from 'lodash';
import { commonActions } from '../../../slices/common-slice';

// MEMO スタッフ一覧取得APIのレスポンスの名称に合わせてる
type TableHeadKey =
  'staffId'
  | 'name'
  | 'status'
  | 'authorityName'
  | 'mailaddress'
  | 'lastLogin'
  | 'loginFaild'
  | 'lockExpiredAt'
  | 'edit';
type SortHeadKey = 'staffId' | 'name' | 'status' | 'authorityName' | 'mailaddress' | 'lastLogin' | 'loginFaild' | 'lockExpiredAt';

type TableHeadData = {
  key: TableHeadKey,
  label: string,
};

type TimeRemainingListType = {
  id: string,
  time: string,
}
const tableHeadData: TableHeadData[] = [
  {
    key: 'staffId',
    label: 'スタッフID',
  },
  {
    key: 'name',
    label: '名前',
  },
  {
    key: 'status',
    label: 'ステータス',
  },
  {
    key: 'authorityName',
    label: '権限',
  },
  {
    key: 'mailaddress',
    label: 'メールアドレス',
  },
  {
    key: 'lastLogin',
    label: '最終ログイン',
  },
  {
    key: 'loginFaild',
    label: 'ログイン失敗回数',
  },
  {
    key: 'lockExpiredAt',
    label: 'ロック残り時間',
  },
  {
    key: 'edit',
    label: '',
  },
];
const TableHead = (props: { label: string, sort?: 'asc' | 'desc' }) => {
  const { label, sort } = props;
  return (
    <div>
      {label}
      {sort ? (
        <>
          {sort === 'asc' ? (' ▲') : ('')}
          {sort === 'desc' ? (' ▼') : ('')}
        </>
      ) : (<></>)}
    </div>
  );
};

type Props = {
  isAdmin: boolean,
}

export const StaffTable = (props: Props) => {
  const { masterAuthority } =  useAppSelector((state) => ({
    ...state.common.data,
  }), lodash.isEqual)
  const { isAdmin } = props;
  // - Hooks -
  const dispatch = useDispatch();
  const { kijshopCd } = useParams<PathParams>();
  // - 毎秒更新用 -
  useInterval(() => {
    if (viewData.length) {
      const list: TimeRemainingListType[] = [];
      viewData.forEach(v => {
        if (v.lockExpiredAt) {
          list.push({
            id: v.id,
            time: DateFormatter.getDiffTime(v.lockExpiredAt),
          })
        }
      })
      setTimeRemainingList(list);
    }
  }, 1000)
  // - State -
  // -- スタッフ一覧(加工しないデータ) --
  const [staffList, setStaffList] = useState<StaffResponse[]>([]);
  // -- 表示用データ --
  const [viewData, setViewData] = useState<StaffResponse[]>([]);
  // -- 絞り込みワード --
  const [searchWord, setSearchWord] = useState('');
  // -- ソート --
  const [sort, setSort] = useState<'asc' | 'desc'>('desc');
  // -- ソート対象 --
  const [sortTarget, setSortTarget] = useState<SortHeadKey>('staffId');
  // -- 残り時間 --
  const [timeRemainingList, setTimeRemainingList] = useState<TimeRemainingListType[]>([]);
  // -- 無効化ユーザーの表示フラグ --
  const [isValid, setIsValid] = useState(false);

  const tableHead = [...tableHeadData].map((v) => (
    <TableHead
      label={v.label}
      sort={(v.key && sortTarget === v.key) ? sort : undefined}
    />
  ));
  // - Effects -
  // -- 初回取得 --
  useEffect(() => {
    if (!isAdmin) return;
    getStaffList();
  }, [isValid, isAdmin]);
  // - Handlers -
  // -- スタッフ一覧取得API --
  const getStaffList = useCallback(() => {
    dispatch(apiActions.run(
      new ApiGetStaffList({
        kijshopCd,
      }),
      {
        onSuccess: (res: ResponseBase<StaffListResponse>) => {
          const staffResponse: StaffResponse[] = res.body.data?.staffList || [];
          const staffResponsesEnableUserData = staffResponse.filter((v) => {
            return v.validFlg
          })
          const list: TimeRemainingListType[] = []
          const targetList = isValid ? staffResponse : staffResponsesEnableUserData;
          targetList.forEach(v => {
            if (v.lockExpiredAt) {
              list.push({
                id: v.id,
                time: DateFormatter.getDiffTime(v.lockExpiredAt),
              })
            }
          })
          setTimeRemainingList(list);
          setStaffList(isValid ? staffResponse : staffResponsesEnableUserData);
          setViewData(isValid ? staffResponse : staffResponsesEnableUserData);
          searchAndSort(isValid ? staffResponse : staffResponsesEnableUserData, searchWord, sort, sortTarget);
        },
      },
    ));
  },[sortTarget, sort, searchWord, timeRemainingList, isValid]);
  // -- 絞り込み --
  const handlerChangeSearch = useCallback((value: string) => {
    setSearchWord(value);
    searchAndSort(staffList, value, sort, sortTarget);
  }, [searchWord, sort, sortTarget, viewData, staffList]);
  // -- ソート --
  const handlerClickTableColumn = useCallback((index) => {
    const data = tableHeadData[index];
    if (data.key === 'edit') return;
    if (data.key === sortTarget) {
      setSort(sort === 'asc' ? 'desc' : 'asc');
      searchAndSort(staffList, searchWord, sort === 'asc' ? 'desc' : 'asc', data.key);
    } else {
      setSort('desc');
      setSortTarget(data.key);
      searchAndSort(staffList, searchWord, 'desc', data.key);
    }
  }, [sort, sortTarget, viewData, staffList]);
  // -- レスポンスデータ→テーブルデータ変換 --
  const convertTableData = useCallback((staffList: StaffResponse[]): DisplayElement[][] => {
    return staffList.map((staff) => {
      const { name, status, authority, authorityName, mailaddress, staffId, lastLogin, loginFaild, lockExpiredAt, id, validFlg } = staff;
      const style = !validFlg ? { color: '#ff0000' } : {};
      const timeRemaining = timeRemainingList.find(v => (v.id === id && v.time));
      const auth = masterAuthority?.find(v => v.authority === authority);
      return [...[
        <div data-status={staffId} style={style}>
          <span title={staffId}>{staffId}</span>
        </div>,
        <div data-status={name} style={style}>
          <span title={name}>{name}</span>
        </div>,
        <div data-status={!validFlg ? '無効' : status ? '本登録' : '仮登録'} style={style}>
          <span>{!validFlg ? '無効' : status ? '本登録' : '仮登録'}</span>
        </div>,
        <div data-status={auth?.authority ?? 0} style={style}>
          <span>{auth?.authorityName ?? ''}</span>
        </div>,

        <div data-status={mailaddress} style={style}>
          <span title={mailaddress}>{mailaddress}</span>
        </div>,
        <div
          data-status={lastLogin}
          style={style}
        >
          <span>{lastLogin}</span>
        </div>,
        <div
          data-status={loginFaild}
          style={style}
        >
          <span>{loginFaild}</span>
        </div>,
        <div
          data-status={lockExpiredAt}
          style={style}
        >
          {timeRemaining && <img
            src={lockIcon}
            className="locked"
          />}
          <span>{timeRemaining ? timeRemaining.time : ''}</span>
        </div>,
        <div style={style}>
          <Button
            size={'sm'}
            label="編集"
            color={'light'}
            disabled={!validFlg}
            onClick={() => handlerClickEditStaff({
              name,
              status: status ? '本登録' : '仮登録',
              mailAddress: mailaddress,
              staffId,
              lastLoginDate: lastLogin,
              loginFailureCount: loginFaild,
              lockDate: lockExpiredAt,
              id,
              authority
            }, false)}
          />
        </div>,
        <div data-status={id}>
          <span>{id}</span>
        </div>,
      ]];
    });
  }, [timeRemainingList, masterAuthority]);

  // - Handlers -
  // -- スタッフ追加モーダル --
  // --- 新規作成ボタン ---
  const handlerClickStaffAddDialog = useCallback(() => {
    dispatch(dialogActions.push({
      title: 'スタッフ追加',
      element: <AddStaff
        handlerClickAddStaff={(props: StaffAddParams) => handlerClickAddStaff(props)}
        handlerClickClose={() => dispatch(dialogActions.pop())}
      />,
    }));
  }, [searchWord,sortTarget,sort]);
  // --- 追加ボタン ---
  const handlerClickAddStaff = useCallback((
    props: StaffAddParams,
  ) => {
    const { idType, id, mail, name, password, authority } = props;
    dispatch(apiActions.run(
      new ApiPostStaffAdd({
        kijshopCd,
        staffId: idType === 'mail' ? mail : id,
        name,
        password,
        mailaddress: mail,
        staffIdType: idType === 'mail',
        authority,
      }),
      {
        onSuccess: () => {
          dispatch(dialogActions.pop());
          getStaffList();
        },
        onBasicError: (error) => {
          dispatch(dialogActions.pushMessage({
            title: '確認',
            message: error.messages,
            buttons: [
              {
                label: 'OK',
                callback: () => {
                  dispatch(dialogActions.pop());
                },
              },
            ],
          }));
        },
      }
    ));
  }, [searchWord, sortTarget, sort]);
  // -- スタッフ編集モーダル --
  // --- スタッフダブルクリック または 編集ボタンクリック ---
  const handlerClickEditStaff = useCallback((v: DisplayElement[] | StaffEditProps, isDoubleClick: boolean) => {
    let data;
    if (isDoubleClick) {
      data = v as JSX.Element[];
      if(data[2].props['data-status'] === '無効') return;
    } else {
      data = v as StaffEditProps;
      if(data.status === '無効') return;
    }
    dispatch(dialogActions.push({
      title: 'スタッフ編集',
      element: <EditStaff
        kijshopCd={kijshopCd}
        data={data}
        handlerClickUnlock={(id: string) => handlerClickUnlock(id)}
        handlerClickResetPassword={(id: string) => handlerClickResetPassword(id)}
        handlerClickChangePrePassword={(id: string) => handlerClickChangePrePassword(id)}
        handlerClickInvalid={(id: string) => handlerClickInvalid(id)}
        handlerClickUpdateStaff={(props: StaffEditParams) => handlerClickUpdateStaff(props)}
        handlerClickClose={() => dispatch(dialogActions.pop())}
        isDoubleClick={isDoubleClick}
      />,
    }));
  }, [searchWord, sortTarget, sort]);
  // --- ロック解除ボタン ---
  const handlerClickUnlock = useCallback((id: string) => {
    dispatch(dialogActions.pushMessage({
      title: '確認',
      message: ['ロックを解除しますか？'],
      buttons: [
        {
          label: 'キャンセル',
          callback: () => {
            dispatch(dialogActions.pop());
          },
        },
        {
          label: 'OK',
          callback: () => {
            dispatch(apiActions.run(
              new ApiPostStaffUnlock(id, {
                kijshopCd,
              }),
              {
                onSuccess: () => {
                  dispatch(dialogActions.pop());
                  getStaffList();
                },
                onBasicError: (error) => {
                  dispatch(dialogActions.pushMessage({
                    title: '確認',
                    message: error.messages,
                    buttons: [
                      {
                        label: 'OK',
                        callback: () => {
                          dispatch(dialogActions.pop());
                        },
                      },
                    ],
                  }));
                }
              },
            ));
            dispatch(dialogActions.pop());
          },
        },
      ],
    }));
  }, []);
  // --- (本登録)パスワードリセットボタン ---
  const handlerClickResetPassword = useCallback((id: string) => {
    dispatch(dialogActions.pushMessage({
      title: '確認',
      message: ['登録済みのメールアドレスに再設定メールを送信します', '届いたメールから、新しいパスワードを再設定できます'],
      buttons: [
        {
          label: 'キャンセル',
          callback: () => {
            dispatch(dialogActions.pop());
          },
        },
        {
          label: 'OK',
          callback: () => {
            dispatch(apiActions.run(
              new ApiStaffPasswordReset(
                {
                  kijshopCd,
                  staffId: id,
                }),
              {
                onSuccess: () => {
                  console.log(id);
                },
              },
            ));
            dispatch(dialogActions.pop());
          },
        },
      ],
    }));
  }, []);
  // -- (仮登録)仮パスワード変更ボタン --
  const handlerClickChangePrePassword = useCallback((id: string) => {
    dispatch(dialogActions.push({
      title: '仮パスワード変更',
      element: <PrePasswordChangeDialog id={id} />,
      closeBtn: true,
    }));
  }, []);
  const PrePasswordChangeDialog = (props: {id:string}) => {
    // - State -
    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');
    const [isPasswordError, setIsPasswordError] = useState(false);
    const [isPasswordConfirmError, setIsPasswordConfirmError] = useState(false);
    const [tooltipItem, setTooltipItem] = useState<'password' | 'passwordConfirm' | 'none'>('none');
    const [isDisabledOk, setIsDisabledOk] = useState(false);
    // - Hooks-
    const dispatch = useDispatch();
    const handlerClickCancel = useCallback(() => {
      dispatch(dialogActions.pop());
    }, []);
    const handlerClickOk = useCallback(() => {
      dispatch(apiActions.run(
        new ApiPostStaffPrePasswordChange(props.id,{
          kijshopCd,
          password,
          password_confirmation: passwordConfirm,
        }),
        {
          onSuccess: () => {
            dispatch(dialogActions.popAll());
          },
          onBasicError: (error) => {
            dispatch(dialogActions.pushMessage({
              title: '確認',
              message: error.messages,
              buttons: [
                {
                  label: 'OK',
                  callback: () => {
                    dispatch(dialogActions.pop());
                  },
                },
              ],
            }));
          }
        }
      ))
    }, [password, passwordConfirm]);
    // -ref -
    const halfWidthAlphanumericRegExp = useRef(/^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9!@#$%^&*])[A-Za-z0-9!@#$%^&*]+$/).current;
    // - Handlers -
    // -- バリデーションチェック --
    const checkInputValidation = useCallback((type: 'password' | 'passwordConfirm', inputItem: string): boolean => {
      let result: boolean;
      switch (type) {
        case 'password':
          if (inputItem && (inputItem.match(halfWidthAlphanumericRegExp) && inputItem.length >= 8 && inputItem.length <= 15)) {
            result = true;
          } else {
            result = false;
          }
          break;
        case 'passwordConfirm':
          if (inputItem === password) {
            result = true;
          } else {
            result = false;
          }
          break;
        default:
          result = true;
          break;
      }
      return result;
    }, [password,passwordConfirm]);
    const handlerChangePassword = useCallback((e) => {
      setPassword(e.target.value);
    }, [password]);
    const handlerChangePasswordConfirm = useCallback((e) => {
      setPasswordConfirm(e.target.value);
    }, [passwordConfirm]);
    const handlerMouseOver = useCallback((type: 'password' | 'passwordConfirm' | 'none') => {
      setTooltipItem(type);
    }, []);
    useEffect(() => {
      setIsDisabledOk(checkInputValidation('password', password) && checkInputValidation('passwordConfirm', passwordConfirm));
    }, [password, passwordConfirm]);
    return (
      <div className="dialog_contents staff">
        <div className="staff__forms">
          <div className="label_input">
            <div className="label_input__label">新しい仮パスワード（8文字以上15文字以内）</div>
            <Input
              type={'password'}
              value={password}
              onChange={handlerChangePassword}
              onMouseOver={() => handlerMouseOver('password')}
              onMouseLeave={() => handlerMouseOver('none')}
              error={isPasswordError}
              tooltip={isPasswordError && tooltipItem === 'password' ? { messages: ['英大文字小文字かつ数字または記号含む8文字以上15文字以内'] } : undefined}
              onBlur={() => setIsPasswordError(!checkInputValidation('password', password))}
            />
            <div
              style={{
                fontSize: '0.8rem',
              }}
              className="label_input__label"
            >※英大文字と英小文字かつ数字または記号含む8文字以上15文字以内
            </div>
          </div>
          <div className="label_input">
            <div className="label_input__label">新しい仮パスワード（再入力）</div>
            <Input
              type={'password'}
              value={passwordConfirm}
              onChange={handlerChangePasswordConfirm}
              onMouseOver={() => handlerMouseOver('passwordConfirm')}
              onMouseLeave={() => handlerMouseOver('none')}
              error={isPasswordConfirmError}
              tooltip={isPasswordConfirmError && tooltipItem === 'passwordConfirm' ? { messages: ['パスワードが一致しません'] } : undefined}
              onBlur={() => setIsPasswordConfirmError(!checkInputValidation('passwordConfirm', passwordConfirm))}
            />
          </div>
        </div>
        <div className="dialog_contents__footer">
          <Button
            label="キャンセル"
            onClick={handlerClickCancel}
            style={{
              display: 'inline-block',
              textAlign: 'center',
            }}
          />
          <Button
            label="変更"
            onClick={handlerClickOk}
            style={{
              width: '4.4rem',
              display: 'inline-block',
              textAlign: 'center',
            }}
            disabled={!isDisabledOk}
          />
        </div>
      </div>

    );
  };
  // --- 無効ボタン ---
  const handlerClickInvalid = useCallback((id: string) => {
    dispatch(dialogActions.pushMessage({
      title: '確認',
      message: ['スタッフを無効にしますか？', '一度無効にすると、有効に戻すことはできません。'],
      buttons: [
        {
          label: 'キャンセル',
          callback: () => {
            dispatch(dialogActions.pop());
          },
        },
        {
          label: 'OK',
          callback: () => {
            dispatch(apiActions.run(
              new ApiPostStaffDisable(id,
                {
                  kijshopCd,
                  validFlg: false,
                }),
              {
                onSuccess: () => {
                  dispatch(dialogActions.pop());
                  getStaffList();
                },
                onBasicError: (error) => {
                  dispatch(dialogActions.pushMessage({
                    title: '確認',
                    message: error.messages,
                    buttons: [
                      {
                        label: 'OK',
                        callback: () => {
                          dispatch(dialogActions.pop());
                        },
                      },
                    ],
                  }));
                }
              },
            ));
            dispatch(dialogActions.pop());
          },
        },
      ],
    }));
  }, []);

  // --- 保存ボタン ---
  const handlerClickUpdateStaff = useCallback((
    props: StaffEditParams,
  ) => {
    const { id, kijshopCd, mailAddress, name, authority } = props;
    dispatch(apiActions.run(
      new ApiPostStaffUpdate(id, {
        kijshopCd,
        mailaddress: mailAddress,
        name,
        authority,
      }),
      {
        onSuccess: () => {
          dispatch(dialogActions.pop());
          getStaffList();
        },
        onBasicError: (error) => {
          dispatch(dialogActions.pushMessage({
            title: '確認',
            message: error.messages,
            buttons: [
              {
                label: 'OK',
                callback: () => {
                  dispatch(dialogActions.pop());
                },
              },
            ],
          }));
        }
      }
    ));
  }, [searchWord, sortTarget, sort]);
  // 検索とソート
  const searchAndSort = useCallback((data: StaffResponse[], searchWord: string, sort: 'asc' | 'desc', sortTarget: SortHeadKey) => {
    const searchResult = data.filter((v) => {
      const { name, mailaddress, staffId } = v;
      return name?.includes(searchWord) || mailaddress?.includes(searchWord) || staffId?.includes(searchWord);
    });
    searchResult.sort((a, b) => {
      const aData = a[sortTarget];
      const bData = b[sortTarget];
      if (aData > bData) {
        return sort === 'asc' ? 1 : -1;
      } else if (aData < bData) {
        return sort === 'asc' ? -1 : 1;
      } else {
        return 0;
      }
    });
    setViewData(searchResult);
  }, []);
  return (
    <div className="staff_table">
      <div className="staff_table__menu">
        <Button
          label="スタッフ追加"
          icon={<i className="fas fa-plus" />}
          color="warning"
          onClick={handlerClickStaffAddDialog}
        />
        <div className="staff_table__menu__checkbox">
          <Checkbox label="無効化されたスタッフの表示" onClick={() => {
            setIsValid(!isValid);
          }} />
        </div>
        <div className="staff_table__menu__search">
          <i className="fas fa-search" />
          <input
            type="text"
            onChange={(e) => handlerChangeSearch(e.target.value)}
          />
        </div>
      </div>
      <div className="staff_table__table">
        <Table
          head={tableHead}
          body={convertTableData(viewData)}
          minimumNumOfRows={10}
          // 非表示列(id)
          hiddenColumn={[9]}
          handlerClickColumn={handlerClickTableColumn}
          handlerDblClick={(i, v) => handlerClickEditStaff(v, true)}
        />
      </div>
    </div>
  );
};
