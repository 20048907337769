import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {Pos} from '../../../models/pos';

type ToolbarSliceStore = {
  display: boolean,
  position: Pos,
  list: {
    label: string,
    callback: () => void,
  }[],
};

const initialState: ToolbarSliceStore = {
  display: false,
  position: {
    x: 0,
    y: 0,
  },
  list: [],
};

export const toolbarSliceStore = createSlice({
  name: 'toolbar',
  initialState,
  reducers: {
    open: (state, action: PayloadAction<{
      position: Pos,
      list: {
        label: string,
        callback: () => void,
      }[],
    }>) => {
      state.display = true;
      state.position = action.payload.position;
      state.list = action.payload.list;
    },
    close: (state) => {
      state.display = false;
      state.list = [];
    },
  },
});

export const toolbarActions = toolbarSliceStore.actions;
export const toolbarReducer = toolbarSliceStore.reducer;
