import './cloud-folder.scss';
import React, { useCallback, useEffect, useState } from 'react';
import { apiActions } from '../../../slices/api-slice';
import { ApiNumberingOrder } from '../../../api/back/numbering-order/api-numbering-order';
import { ResponseBase } from '../../../api/response-base';
import { NumberingOrderResponse } from '../../../models/api/back/numbering-order';
import { xmlActions } from '../../../xml/slice/xml-slice';
import { ApiMetaShopOrderPost } from '../../../api/front/meta/api-meta-shop-order';
import { DateFormatter } from '../../../utilities/date-formatter';
import { push } from 'connected-react-router';
import { PathParams, RoutingPath } from '../../../routes/routing-path';
import { dialogActions } from '../../dialog/slice/dialog-slice';
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { commonActions } from '../../../slices/common-slice';
import logoImg from '../../../assets/img/logo/ppmcloud_01_y.png';

export const CloudFolder = () => {
  const { kijshopCd } = useParams<PathParams>();
  const search = useLocation<{ id: string, name: string }>();
  const dispatch = useDispatch();

  const [orderId, setOrderId] = useState('');
  const [orderName, setOrderName] = useState('');
  const createOrder = useCallback(() => {
    dispatch(apiActions.run(
      new ApiNumberingOrder({}),
      {
        onSuccess: (res: ResponseBase<NumberingOrderResponse>) => {
          const orderNo = res.body.data?.orderNo;
          if (orderNo) {
            dispatch(xmlActions.shopOrder({ kijshopCd, shopOrderId: orderNo, lastName: orderName }).create({
              success: () => {
                dispatch(apiActions.run(new ApiMetaShopOrderPost(
                    {
                      kijshopCd,
                      shopOrderId: orderNo,
                      data: {
                        shopOrderId: orderNo,
                        lastUpdateDate: DateFormatter.date2str(new Date(), 'YYYYMMDD_HHmmSS', '/'),
                        createDate: DateFormatter.date2str(new Date(), 'YYYYMMDD_HHmmSS', '/'),
                        lastName: orderName,
                        note: orderId,
                        status: '注文作成中',
                      },
                    },
                  ),
                  {
                    onSuccess: () => {
                      dispatch(push(RoutingPath.cnv.ordersPreparation({ kijshopCd: kijshopCd, shopOrderId: orderNo })));
                    },
                    onError: () => {
                    },
                  },
                  { ignoreSystemError: true },
                ));
              },
              error: () => {
              },
            }));
          } else {
            dispatch(dialogActions.pushMessage({
              title: 'エラー',
              message: ['注文の作成に失敗しました'],
              buttons: [{
                label: 'OK',
                callback: () => {
                  dispatch(dialogActions.pop());
                },
              }],
            }));
          }
        },
        onError: () => {
        },
      },
    ));
  }, [orderId, orderName]);

  useEffect(() => {
    dispatch(commonActions.getData(kijshopCd));
    const query2 = new URLSearchParams(search.search);
    setOrderId(query2.get('id') as string);
    setOrderName(query2.get('name') as string);
  }, [search]);

  useEffect(() => {
    if (kijshopCd && orderId && orderName) {
      // createOrder();
    }
  }, [kijshopCd, orderId, createOrder]);

  return <>
    <div className="container">
      <div className="wrapper">
        <img
          src={logoImg}
          alt=""
        />
        <div className="animation">
          <div className="circle"></div>
          <div className="circle"></div>
          <div className="circle"></div>
          <div className="shadow"></div>
          <div className="shadow"></div>
          <div className="shadow"></div>
        </div>
      </div>
    </div>
  </>;
};
