import {Input} from '../../ui/input/input';
import {Button} from '../../ui/button/button';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {useDispatch} from 'react-redux';
import {Checkbox} from '../../ui/input/checkbox';
import {favoriteTemplateActions, FavoriteTemplateItem} from '../../../slices/favorite-template-slice';
import {dialogActions} from '../slice/dialog-slice';
import { apiActions } from '../../../slices/api-slice';
// import { ApiFavoritePost } from '../../../api/front/favorite/api-favorite';
import './add-folder.scss';
import { ApiFavoriteFolderGet, ApiFavoriteFolderPost } from '../../../api/front/favorite-folder/api-favorite-folder';
import { ResponseBase } from '../../../api/response-base';
import { FavoriteFolderPostResponse, FavoriteFolderListItem, FavoriteFolderGetResponse } from '../../../models/api/front/favorite-folder';

type AddFolderProps = {
  // - 選択フォルダ含む含まない -
  isIncludeSelectFolder?: boolean,
  type: 'brand' | 'template',
  dialogType?: 'config' | 'save',
  kijshopCd: string,
  initValue?: string,
  usedNameList?: string[],
  level?: number,
  parentId?: string,
  indexes?: number,
  allFolders?: FavoriteFolderListItem[],
  getList?: () => void;
  list?: FavoriteFolderListItem[],
};


export const AddFolder = (props: AddFolderProps) => {
  const {isIncludeSelectFolder, type, dialogType, kijshopCd, initValue, usedNameList, level, parentId, indexes, allFolders, getList, list} = props;
  // - Hooks -
  const dispatch = useDispatch();
  // - State -
  // -- 入力値 --
  const [value, setValue] = useState(initValue || '');
  // -- 選択フォルダid --
  const [folderId, setFolderId] = useState('8');
  // - Callback -
  const checkFolder = useCallback((list: FavoriteFolderListItem[]) => {
    if (!parentId) {
      const filter = list.filter((v) => v.level === 1);
      return filter.find((v) => v.name === value);
    }
    const filter = list.filter((v) => v.parentId === parentId);
    return filter.find((v) => v.name === value);
  }, [value, parentId]);
  // -- 入力値更新 --
  const handlerChangeValue = useCallback((v) => {
    setValue(v);
  }, []);

  const getFolderList = useCallback(() => new Promise<FavoriteFolderListItem[]>((resolve) => {
    dispatch(apiActions.run(
      new ApiFavoriteFolderGet(
        {
          kijshopCd,
          kind: '1',
        }
      ),
      {
        onSuccess: (v: ResponseBase<FavoriteFolderGetResponse>) => resolve(v.body.data?.folderList ?? []),
        onError: () => resolve([]),
      }
    ))
  }), []);
  // -- OKボタン押下 --
  const handlerClickOk = useCallback(async() => {
    // dispatch(dialogActions.pop());
    if (type === 'brand') {
      if (list && list.find((v) => v.name === value && v.parentId === parentId)) {
        dispatch(dialogActions.pushMessage({
          title: '確認',
          message: [
            'すでに存在するフォルダと同じ名称での追加はできません。'
          ],
          buttons: [
            {
              label: 'はい',
              callback: () => {
                dispatch(dialogActions.pop());
              },
            },
          ],
        }));
        return;
      }
      dispatch(dialogActions.pop());
      dispatch(apiActions.run(
        new ApiFavoriteFolderPost(
          {
            kijshopCd,
            kind: '0',
            name: value,
            level: level ?? 1,
            parentId: parentId || undefined,
            // indexes: indexes ?? 1,
          },
        ),
        {
          onSuccess: () => {
            getList?.();
          },
          onError: () => {
            dispatch(dialogActions.pushMessage({
              title: '確認',
              message: ['フォルダの作成に失敗しました'],
              buttons: [{
                label: 'OK',
                callback: () => {
                  dispatch(dialogActions.pop());
                },
              }],
            }));
          },
        },
      ));
    } else {
      dispatch(dialogActions.pop());
      const folderList = await getFolderList();
      if (checkFolder(folderList)) {
        dispatch(dialogActions.pushMessage({
          title: '確認',
          message: [
            // '同一階層に同じ名称のフォルダがあります。',
            // '違う名称を設定してください。',
            'すでに存在するフォルダと同じ名称での追加はできません。'
          ],
          buttons: [
            {
              label: 'はい',
              callback: () => {
                dispatch(dialogActions.pop());
                getList?.();
              },
            },
          ],
        }));
      } else {
        // dispatch(favoriteTemplateActions.addFolder({id: value, name: value, type: 'directory', children: []}));
        if (checkFolder(folderList)) {
          dispatch(dialogActions.pushMessage({
            title: '確認',
            message: [
              // '同一階層に同じ名称のフォルダがあります。',
              // '違う名称を設定してください。',
              'すでに同じ名称のフォルダが存在します。'
            ],
            buttons: [
              {
                label: 'はい',
                callback: () => {
                  dispatch(dialogActions.pop());
                  console.log(getList)
                  getList?.();
                },
              },
            ],
          }));
          return;  
        }
        dispatch(apiActions.run(
          new ApiFavoriteFolderPost(
            {
              kijshopCd,
              kind: '1',
              name: value,
              level: level ?? 1,
              parentId: parentId ?? '',
              indexes: indexes ?? 1,
            }
          ),
          {
            onSuccess: (res: ResponseBase<FavoriteFolderPostResponse>) => {
              if (res.body.data) {
                dispatch(favoriteTemplateActions.addFolder(
                  {
                    id: res.body.data.folderId,
                    name: value,
                    type: 'directory',
                    children: [],
                    level: Number(level) ?? '1',
                    parentId: parentId ?? '',
                    indexes: Number(indexes) ?? '',
                  }
                ));
                getList?.();
              }
            },
          },
        ));
      }
    }
    // if (dialogType === 'config') {
    //   dispatch(dialogActions.push({
    //     title: 'お気に入り商品設定',
    //     element: <FavoriteBrandConfig kijshopCd={kijshopCd} />,
    //   }));
    // } else if (dialogType === 'save') {
    //   dispatch(dialogActions.push({
    //     title: 'お気に入り商品保存',
    //     element: <SaveFavoriteBrand kijshopCd={kijshopCd} />,
    //   }));
    // }
    // setFolderId((prev) => prev + 1);
  }, [value, folderId, getList, getFolderList, indexes]);
  // -- キャンセルボタン押下 --
  const handlerClickCancel = useCallback(() => {
    dispatch(dialogActions.pop());
  }, []);
  const ref = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (ref.current) {
      ref.current.focus();
    }
  }, [ref.current]);
  // -- keyEvent --
  useEffect(() => {
    const listener = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        handlerClickCancel();
      }
      if (e.key === 'Enter') {
        handlerClickOk();
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [value, folderId, getList, getFolderList, indexes]);
  return (
    <div className='dialog_contents add_folder'>
      <div className='dialog_contents__body add_folder__body'>
        <div className="top_label_ui">
          <div className="top_label_ui__label">
            フォルダ名を入力してください
          </div>
          <div className="top_label_ui__form">
            <Input
              ref={ref}
              value={value}
              onChange={(e) => handlerChangeValue(e.target.value)}
              maxLength={40}
            />
          </div>
        </div>
        {isIncludeSelectFolder ? (
          <Checkbox label="選択フォルダを含める" />
        ) : <div className="add_folder__message">※40文字以内で入力してください</div>}
      </div>
      <div className='dialog_contents__footer'>
        <Button
          label='キャンセル'
          onClick={handlerClickCancel}
        />
        <Button
          label='OK'
          disabled={!value.length}
          onClick={handlerClickOk}
        />
      </div>
    </div>
  );
};
