import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../app/hooks';
import * as lodash from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { ordersActions } from '../../../slices/orders-slice';
import { HowToOrder, ImgType } from '../../dialog/unique/how-to-order';
import { dialogActions } from '../../dialog/slice/dialog-slice';
import { Button } from '../../ui/button/button';
import iconHelp from '../../../assets/img/icon/help_icon.svg';
import { TextArea } from '../../ui/textarea/textarea';

export const MailRemarksOrder = () => {
  const dispatch = useDispatch();
  const { mailRemarks: _remarks } = useAppSelector((state) => ({
    ...state.order.mailRemarks,
  }), lodash.isEqual);
  // - State -
  // -- メール記載情報 --
  const [remarks, setRemarks] = useState(_remarks);
  // - Callback -
  // -- メール記載情報 --
  const handlerChangeRemarks = useCallback((v) => {
    setRemarks(v);
  }, []);
  const handlerBlurRemarks = useCallback((v) => {
    dispatch(ordersActions.setMailRemarks(v));
  }, []);

  const handlerClickHelp = useCallback((type: ImgType) => {
    dispatch(dialogActions.push({
      title: '説明',
      element: <HowToOrder imgType={type} />,
      closeBtn: true,
    }));
  }, []);

  // - Effect -
  // -- 更新確認 --
  useEffect(() => {
    if (remarks !== _remarks) {
      setRemarks(_remarks);
    }
  }, [_remarks]);

  return (
    <div className="order_category remarks_order">
      <div className="bottom_border_header order_category__header">
        メール記載情報
        <Button className="order_help order_category__header__tooltip" icon={<img src={iconHelp} alt='' />} onClick={() => handlerClickHelp('title-remark')} />
      </div>

      <div className="top_label_ui order_category__item order_category__item">
        <TextArea
          value={remarks}
          onChange={(e) => handlerChangeRemarks(e.target.value)}
          onBlur={(e) => handlerBlurRemarks(e.target.value)}
          maxLength={100}
        />
      </div>
    </div>
  );
};
