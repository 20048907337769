import { XmlClass } from './xml-class';
import { IDelivery } from '../interface/i-delivery';
import { XmlClassName } from '../model/xml-class-name';
import { TDeliveryMetaModel, TDeliveryViewModel } from '../model/delivery/xml-delivery-model';

type TView = Partial<TDeliveryViewModel>;
type TMeta = Partial<TDeliveryMetaModel>;

export class DeliveryXml extends XmlClass<IDelivery, TView, TMeta> {

  name: XmlClassName = 'delivery';

  viewModel: TView = {};
  metaModel: TMeta = {};

  protected init(): void {
    this.xmlUniqueName = this.xmlUniqueName || `PPM_${this.shopOrderId}_11_11_delivery.xml`;
    this.xmlParentName = this.xmlParentName || `PPM_${this.shopOrderId}_11_11.xml`;
    this.rootTagModel = {
      ...this.rootTagModel,
      parentXPath: '/lnwOrder/order/delivery',
      parentPath: this.xmlParentName,
    };
    this.docking({}, {});
  }

  protected docking(v: TView, m: TMeta): void {
    this.xmlModel = {
      lnwDelivery: {
        $: { ...this.rootTagModel },
        delivery: [
          {
            $: {
              count: '1',
            },
            data: [
              {
                $: {
                  id: '1',
                  // 売上先店舗表示フラグ
                  isBillingShopSelected: v.isBillingShopSelected,
                  // 売上先店舗コード
                  shopCode: v.shopCode,
                  // 配送先種（shop or customer）
                  type: v.type,
                },
                deliveryCustomerName: v.customerName ? [
                  {
                    $: {
                      // 個別配送先お客様名
                      name: v.customerName,
                    },
                  },
                ] : undefined,
                address: (v.address1 || v.address2 || v.prefecture || v.zipCode) ? [
                  {
                    $: {
                      // 住所1
                      address1: v.address1,
                      // 住所2
                      address2: v.address2,
                      // 都道府県
                      state: v.prefecture,
                      // 郵便番号
                      zipCode: v.zipCode,
                    },
                  },
                ] : undefined,
                phone: v.phoneNumber ? [
                  {
                    $: {
                      // 個別配送先電話番号
                      phoneNumber: v.phoneNumber,
                    },
                  },
                ] : undefined,
                mail: v.mailAddress !== undefined ? [
                  {
                    $: {
                      // 個別配送先メールアドレス
                      mailAddress: v.mailAddress,
                    },
                  },
                ] : undefined,
                deliveryTimeZone: (v.start || v.end || v.timeZone || v.dispTimeZone) ? [
                  {
                    $: {
                      // 配送開始時間
                      start: v.start,
                      // 配送終了時間
                      end: v.end,
                      // 配送時間コード
                      timeZone: v.timeZone,
                      // 配送時間文言
                      dispTimeZone: v.dispTimeZone,
                    },
                  },
                ] : undefined,
                deliveryFromName: (v.shopName || v.deliveryFormGUID) ? [
                  {
                    $: {
                      // GUID
                      GUID: v.deliveryFormGUID,
                      // 発送店舗名
                      name: v.shopName,
                    },
                  },
                ] : undefined,
              },
            ],
          },
        ],
        report: (v.orderFormGUID || v.formName || v.originalPath || v.importPath || v.pdfPath) ? [
          {
            $: {
              count: '1',
            },
            data: [
              {
                $: {
                  id: '1',
                },
                shipmentForm: [
                  {
                    $: {
                      // GUID
                      GUID: v.orderFormGUID,
                      // 発送帳票名
                      name: v.formName,
                      // 元ファイルのローカルパス
                      originalPath: v.originalPath,
                      // 登録後のローカルパス
                      importPath: v.importPath,
                      // PDFパス
                      path: v.pdfPath,
                    },
                  },
                ],
              },
            ],
          },
        ] : undefined,
      },
    };
  }

  protected split(x: IDelivery): void {
    if (!x?.lnwDelivery?.delivery || !x.lnwDelivery.$) {
      this.viewModel = {};
      this.metaModel = {};
      return;
    }
    this.viewModel = {
      isBillingShopSelected: x.lnwDelivery.delivery?.[0]?.data?.[0]?.$.isBillingShopSelected,
      shopCode: x.lnwDelivery.delivery?.[0]?.data?.[0]?.$.shopCode,
      type: x.lnwDelivery.delivery?.[0]?.data?.[0].$.type,
      customerName: x.lnwDelivery.delivery?.[0].data?.[0].deliveryCustomerName?.[0].$.name,
      zipCode: x.lnwDelivery.delivery?.[0].data?.[0].address?.[0].$.zipCode,
      prefecture: x.lnwDelivery.delivery?.[0].data?.[0].address?.[0].$.state,
      address1: x.lnwDelivery.delivery?.[0].data?.[0].address?.[0].$.address1,
      address2: x.lnwDelivery.delivery?.[0].data?.[0].address?.[0].$.address2,
      phoneNumber: x.lnwDelivery.delivery?.[0].data?.[0].phone?.[0].$.phoneNumber,
      mailAddress: x.lnwDelivery.delivery?.[0].data?.[0].mail?.[0].$.mailAddress,
      timeZone: x.lnwDelivery.delivery?.[0].data?.[0].deliveryTimeZone?.[0].$.timeZone,
      dispTimeZone: x.lnwDelivery.delivery?.[0].data?.[0].deliveryTimeZone?.[0].$.dispTimeZone,
      start: x.lnwDelivery.delivery?.[0].data?.[0].deliveryTimeZone?.[0].$.start,
      end: x.lnwDelivery.delivery?.[0].data?.[0].deliveryTimeZone?.[0].$.end,
      shopName: x.lnwDelivery.delivery?.[0]?.data?.[0].deliveryFromName?.[0]?.$.name,
      formName: x.lnwDelivery.report?.[0].data?.[0].shipmentForm?.[0].$.name,
      pdfPath: x.lnwDelivery.report?.[0].data?.[0].shipmentForm?.[0].$.path,
      originalPath: x.lnwDelivery.report?.[0].data?.[0]?.shipmentForm?.[0]?.$.originalPath,
      importPath: x.lnwDelivery.report?.[0].data?.[0]?.shipmentForm?.[0]?.$.importPath,
      deliveryFormGUID: x.lnwDelivery.delivery?.[0]?.data?.[0]?.deliveryFromName?.[0]?.$.GUID,
      orderFormGUID: x.lnwDelivery.report?.[0].data?.[0]?.shipmentForm?.[0]?.$.GUID
    };
    this.rootTagModel = Object.assign(this.rootTagModel, x.lnwDelivery.$);
  }
}
