import './wizard.scss';

type WizardStepBarProps = {
  currentStep: number
  totalStep: number
  className: string
}

type NumberProps = {
  type: 'past' | 'current' | 'future'
  number: number
  className: string
}

type BridgeProps = {
  type: 'past' | 'future'
  className: string
}

// 数字の丸
const StepBarNumber = (props: NumberProps) => {
  const { type, number, className } = props;
  return (
    <div className={`${className} ${type}`}>
      {number}
    </div>
  );
};

// 数字の丸の間の線
const StepBarBridge = (props: BridgeProps) => {
  const { type,className } = props;
  return (
    <div className={`${className} ${type}`} />
  );
};

export const WizardStepBar = (props: WizardStepBarProps) => {
  const { currentStep, totalStep, className } = props;
  const stepBar: JSX.Element[] = [];
  for (let i = 1; i <= totalStep; i++) {
    const circleType = i < currentStep ? 'past' : i === currentStep ? 'current' : 'future';
    const bridgeType = i <= currentStep ? 'past' : 'future';
    stepBar.push(
      i !== 1 ?
        <StepBarBridge
          key={`bridge-${i}`}
          type={bridgeType}
          className={`${className}__bridge`}
        /> : <div key={`bridge-${i}`}></div>,
      <StepBarNumber
        key={`circle-${i}`}
        type={circleType}
        number={i}
        className={`${className}__number-circle`}
      />,
    );
  }
  return (
    <div className={className}>
      {stepBar}
    </div>
  );
};