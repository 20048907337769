import { useState } from 'react';
import { Wizard } from '../../../ui/wizard/wizard';
import { Header } from '../../../ui/header/header';
import { Step1StaffMngPasswordChangeWizard } from './step1.staff-mng-password-change.wizard';
import { Step2StaffMngPasswordChangeWizard } from './step2.staff-mng-password-change.wizard';
import { Step3StaffMngPasswordChangeWizard } from './step3.staff-mng-password-change.wizard';

export const StaffMngPasswordChangeWizard = () => {
  // - State -
  const [step, setStep] = useState(1);
  const [password, setPassword] = useState('');

  return (
    <div className="page_base">
      <Header page="" />
      <Wizard step={step}>
        <Step1StaffMngPasswordChangeWizard
          password={password}
          setPassword={setPassword}
          setStep={setStep}
        />
        <Step2StaffMngPasswordChangeWizard
          password={password}
          setStep={setStep}
        />
        <Step3StaffMngPasswordChangeWizard setStep={setStep} />
      </Wizard>
    </div>
  );
};