import { ApiBaseFront } from '../api-base-front';

type ParamType = {
  kijshopCd: string,
  /* クラウドフォルダの注文ID */
  orderId: string,
  imageId: string,
  shopOrderId: string,
  kind?: number,
  selectId?: string,
}

export class ApiCopyCloudFolderImage extends ApiBaseFront {
  constructor(_param: ParamType) {
    const { kijshopCd, orderId, imageId, ...param } = _param;
    super(
      'POST',
      `/api/v1/c-folder/shop/${kijshopCd}/order/${orderId}/image/${imageId}/copy`,
      'JSON',
      param,
    );
  }
}
