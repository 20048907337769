import {Button} from '../../../ui/button/button';
import {useCallback, useState} from 'react';
import {Input} from '../../../ui/input/input';
import {useDispatch} from 'react-redux';
import {dialogActions} from '../../../dialog/slice/dialog-slice';
import './layout.search-template.scss';
import {NumUpDown} from '../../../ui/input/num-up-down';
import { layoutActions, LayoutItemData, TemplatesInfo } from '../../../../slices/layout-slice';
import { useAppSelector } from '../../../../app/hooks';
import * as lodash from 'lodash';
import { templateActions } from '../../../../slices/api/template-actions';
import { getTargetPathUrl } from '../all-template/layout.all-template';
import { XmlParser } from '../../../../manager/xml-parser';
import { useParams } from 'react-router-dom';
import { PathParams, RoutingPath } from '../../../../routes/routing-path';
import { push } from 'connected-react-router';

export const LayoutSearchTemplate = (props: {pageTypeIdArr: any[]}) => {
  const dispatch = useDispatch();
  const { kijshopCd, shopOrderId, orderId } = useParams<PathParams>();
  const {folderData, proNameId, albumSettingData, layoutQuery, templatesXmlArr, orderInfoData} = useAppSelector((state) => ({
    folderData: state.layout.folderList,
    proNameId: state.orderPreparation.currentOrder?.products?.productNameId,
    albumSettingData: state.layout.albumSettingData,
    layoutQuery: state.layout.queryParams,
    templatesXmlArr: state.layout.templatesXmlList,
    orderInfoData: state.xml[shopOrderId]?.orderInfo?.infoData,
  }), lodash.isEqual);
  const {pageTypeIdArr} = props;
  // - State -
  // -- 開閉状態 --
  const [isOpen, setIsOpen] = useState(true);
  // -- 合計 --
  const [sum, setSum] = useState('');
  // -- タテ --
  const [vertical, setVertical] = useState('');
  // -- sq --
  const [sq, setSq] = useState('');
  // -- ヨコ --
  const [horizon, setHorizon] = useState('');
  // -- 型番検索 --
  const [searchTypeNum, setSearchTypeNum] = useState('');
  // - Callback -
  // -- 開閉状態切り替え --
  const handlerChangeIsOpen = useCallback((v) => {
    setIsOpen(v);
  }, []);
  // -- 合計更新 --
  const handlerChangeSum = useCallback((v) => {
    if (Number(v) >= 0 && Number(v) <= 99 && (!vertical || !horizon || !sq)) {
      setSum(v);
      if (v && horizon && sq && Math.sign(Number(v) - (Number(horizon) + Number(sq))) !== -1) {
        setVertical(`${Number(v) - (Number(horizon) + Number(sq))}`);
      } else if (v && vertical && sq && Math.sign(Number(v) - (Number(vertical) + Number(sq))) !== -1) {
        setHorizon(`${Number(v) - (Number(vertical) + Number(sq))}`);
      } else if (v && vertical && horizon && Math.sign(Number(v) - (Number(vertical) + Number(horizon))) !== -1) {
        setSq(`${Number(v) - (Number(vertical) + Number(horizon))}`);
      }
    }
  }, [vertical, horizon, sq]);
  // -- タテ更新 --
  const handlerChangeVertical = useCallback((v) => {
    if (Number(v) >= 0 && Number(v) <= 99) {
      setVertical(v);
      if (v && horizon && sq) {
        setSum(`${Number(v) + Number(horizon) + Number(sq)}`);
      } else if (sum && v && sq && Math.sign(Number(sum) - (Number(v) + Number(sq))) !== -1) {
        setHorizon(`${Number(sum) - (Number(v) + Number(sq))}`);
      } else if (sum && v && horizon && Math.sign(Number(sum) - (Number(v) + Number(horizon))) !== -1) {
        setSq(`${Number(sum) - (Number(v) + Number(horizon))}`);
      }
    }
  }, [sum, horizon, sq]);
  // -- sq更新 --
  const handlerChangeSQ = useCallback((v) => {
    if (Number(v) >= 0 && Number(v) <= 99) {
      setSq(v);
      if (vertical && horizon && v) {
        setSum(`${Number(vertical) + Number(horizon) + Number(v)}`);
      } else if (sum && horizon && v && Math.sign(Number(sum) - (Number(horizon) + Number(v))) !== -1) {
        setVertical(`${Number(sum) - (Number(horizon) + Number(v))}`);
      } else if (sum && vertical && v && Math.sign(Number(sum) - (Number(vertical) + Number(v))) !== -1) {
        setHorizon(`${Number(sum) - (Number(vertical) + Number(v))}`);
      }
    }
  }, [sum, vertical, horizon]);
  // -- ヨコ更新 --
  const handlerChangeHorizon = useCallback((v) => {
    if (Number(v) >= 0 && Number(v) <= 99) {
      setHorizon(v);
      if (vertical && v && sq) {
        setSum(`${Number(vertical) + Number(v) + Number(sq)}`);
      } else if (sum && v && sq && Math.sign(Number(sum) - (Number(v) + Number(sq))) !== -1) {
        setVertical(`${Number(sum) - (Number(v) + Number(sq))}`);
      } else if (sum && vertical && v && Math.sign(Number(sum) - (Number(vertical) + Number(v))) !== -1) {
        setSq(`${Number(sum) - (Number(vertical) + Number(v))}`);
      }
    }
  }, [sum, vertical, sq]);
  // -- 型番検索更新 --
  const handlerChangeSearchTypeNum = useCallback((v) => {
    setSearchTypeNum(v);
  }, []);
  // -- 検索押下 --
  const handlerClickSearch = useCallback(() => {
    let pageCount = albumSettingData.pageCount;
    if (!pageCount) {
      pageCount= '6';
    }
    let typeArr = pageTypeIdArr;
    if (!typeArr.length) {
      return;
      // typeArr = ['jptg380012', 'jptg380013', 'jptg380014', 'jptg380015'];
    }
    if (layoutQuery) {
      const orderMethodId = orderInfoData?.find((v) => v.xml.metaModel.id === orderId)?.xml.viewModel.surrogateProcess?.id
      // orderMethodIdが取得できなかったら商品は破損していると認定する
      if (!orderMethodId) {
        dispatch(dialogActions.pushMessage({
          title: 'エラー',
          message: [
            '注文情報の取得に失敗しました',
          ],
          buttons: [{
            label: 'OK',
            callback: () => {
              dispatch(dialogActions.pop())
              dispatch(push(RoutingPath.cnv.ordersPreparation({kijshopCd, shopOrderId})));
            }
          }]
        }))
      }
      dispatch(templateActions.AllTemplate({
        productNameId: layoutQuery.productNameId,
        aspect: `${sum ? sum : 'null'},${vertical ? vertical : 'null'},${horizon ? horizon : 'null'},${sq ? sq : 'null'}`,
        path: searchTypeNum,
        boardInfoId: orderInfoData?.find((v) => v.xml.metaModel.id === orderId)?.xml.viewModel.boardInfo,
        orderMethodId: orderMethodId!,
      }, {
        onSuccess: async(res) => {
          dispatch(layoutActions.setTemplateList([]));
          if (res.body) {
            const result: LayoutItemData[] = [];
            for (const v of res.body.data.templateGroupData) {
              v.templateGroupDetailData.forEach((vv: any) => {
                // templates.xmlから対象を探す
                const parentTarget = templatesXmlArr.find((data) => data.$.path === vv.path);
                if (!parentTarget) return;
                // オーダー情報にないページ種類は省く
                const targetPageType = typeArr.find((type) => type === parentTarget.templateGroup[0].templateData[0].$.type);
                if (!targetPageType) return;
                vv.templateGroupDetailDataPath.forEach((vvv: any) => {
                  // テンプレートに対応するtemplatesの情報を探す
                  const target = parentTarget.templateGroup[0].templateData.find((data: any) => data.$.path === vvv);
                  // 表紙の場合にページ数が範囲内かチェックする
                  if (
                    (!target) ||
                    (parentTarget.$.name.indexOf('cover') !== -1 &&
                      target.cover &&
                      (Number(target.cover[0].$.minPageCount) > Number(pageCount) || Number(target.cover[0].$.maxPageCount) < Number(pageCount)))
                  ) {
                    return;
                  }
                  // 検索条件に当てはまれば表示するリストに追加する
                  const _sum = Number(target.count[0].$.tate) + Number(target.count[0].$.yoko) + Number(target.count[0].$.sq);
                  if (
                    (!searchTypeNum || vvv.indexOf(searchTypeNum) !== -1) &&
                    (!sum || sum === String(_sum)) &&
                    (!vertical || vertical === target.count[0].$.tate) &&
                    (!horizon || horizon === target.count[0].$.yoko) &&
                    (!sq || sq === target.count[0].$.sq)
                  ) {
                    const thumbnail = getTargetPathUrl(vvv + '/thumbnail.jpg');
                    const templateXmlUrl = getTargetPathUrl(vvv);
                    const templateName = vvv.indexOf('___') !== -1 ? vvv.substring(0, vvv.indexOf('___')).replace(`${vv.path}/`, '') : vvv.replace(`${vv.path}/`, '');
                    const templateItem: LayoutItemData = {
                      id: vv.path,
                      templateName,
                      thumbnail,
                      templateXmlUrl,
                      targetPath: vvv,
                    };
                    result.push(templateItem);
                  }
                });
              });
            }
            dispatch(layoutActions.setTemplateList(result));
          } else {
            dispatch(dialogActions.pushMessage({
              title: '検索結果',
              message: ['該当データがありません。'],
              buttons: [
                {
                  label: 'OK',
                  callback: () => {
                    dispatch(dialogActions.pop());
                  },
                },
              ],
            }));
          }
        },
      }));
    }
  }, [proNameId, sum, vertical, horizon, sq, searchTypeNum, layoutQuery,albumSettingData, templatesXmlArr, pageTypeIdArr]);
  // -- リセット押下 --
  const handlerClickReset = useCallback(() => {
    setSum('')
    setVertical('')
    setSq('')
    setHorizon('')
    setSearchTypeNum('')
  }, []);
  return (
    <div className="layout_search_template layout_category">
      <div className="layout_search_template__header layout_category__header">
        テンプレート検索
        <div
          className={`open_btn${isOpen ? ' minus_icon' : ' plus_icon'}`}
          onClick={() => handlerChangeIsOpen(!isOpen)}
        />
      </div>
      {isOpen ? (
        <div className="layout_search_template__body">
          <div>
            写真枚数（3箇所まで入力できます）
          </div>
          <div className="left_label_form">
            <span>合計</span>
            <NumUpDown
              value={sum}
              min={0}
              max={99}
              onChange={(e) => handlerChangeSum(e.target.value)}
              callback={num => handlerChangeSum(String(num))}
            />
          </div>
          <div className="left_label_form">
            <span>タテ</span>
            <NumUpDown
              value={vertical}
              min={0}
              max={99}
              onChange={(e) => handlerChangeVertical(e.target.value)}
              callback={num => handlerChangeVertical(String(num))}
            />
          </div>
          <div className="left_label_form">
            <span>SQ</span>
            <NumUpDown
              value={sq}
              min={0}
              max={99}
              onChange={(e) => handlerChangeSQ(e.target.value)}
              callback={num => handlerChangeSQ(String(num))}
            />
          </div>
          <div className="left_label_form">
            <span>ヨコ</span>
            <NumUpDown
              value={horizon}
              min={0}
              max={99}
              onChange={(e) => handlerChangeHorizon(e.target.value)}
              callback={num => handlerChangeHorizon(String(num))}
            />
          </div>
          <div className="left_label_form">
            <span>型番検索</span>
            <Input
              value={searchTypeNum}
              onChange={(e) => handlerChangeSearchTypeNum(e.target.value)}
            />
          </div>
          <div>
            絞り込み検索
            <Button
              label="検索"
              onClick={handlerClickSearch}
              size="sm"
            />
            <Button
              label="リセット"
              onClick={handlerClickReset}
              size="sm"
              color="danger"
            />
          </div>
        </div>
      ) : (<></>)}
    </div>
  );
};
