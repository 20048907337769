import {
  WizardBody,
  WizardBodyList,
  WizardButton,
  WizardButtonList,
  WizardContent,
  WizardMailNotice,
  WizardMailSentForPassword,
  WizardNotice,
} from '../../../ui/wizard/wizard-content';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { RoutingPath } from '../../../../routes/routing-path';

type Props = {
  setStep: (step: number) => void;
}

export const Step2StaffPasswordResetWizard = (props: Props) => {
  const { setStep } = props;
  // - Hooks -
  const dispatch = useDispatch();
  return (
    <WizardContent>
      <WizardBodyList>
        <WizardBody body={[`スタッフID  に`, '登録されているメールアドレスに確認メールを送信しました。']} />
        <WizardBody body={WizardMailSentForPassword} />
      </WizardBodyList>
      <WizardNotice body={WizardMailNotice} />
      <WizardButtonList>
        <WizardButton
          label="戻る"
          // onClick={() => setStep(1)}
          onClick={() => dispatch(push(RoutingPath.staffLogin))}
        />
      </WizardButtonList>
    </WizardContent>
  );
};