import { Button } from '../../ui/button/button';
import { Table } from '../../ui/table/table';
import { useDispatch } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import { dialogActions } from '../slice/dialog-slice';
import './lab-order.scss';
import { PathParams, RoutingPath } from '../../../routes/routing-path';
import { push } from 'connected-react-router';
import { ColorIcon } from '../../ui/color-icon/color-icon';
import { xmlActions } from '../../../xml/slice/xml-slice';
import { ErrorOrder, orderPreparationActions } from '../../../slices/order-preparation-slice';
import { LoadingPopup } from '../../ui/loading/loading-popup';

export type LabOrderProps = {
  kijshopCd: string,
  orderList: {
    createDate: string,
    lastName: string,
    shopOrderId: string,
    status: string,
    // size: string,
    cnt?: number,
  }[],
  callbackEndOrder: () => void,
  retry?: boolean,
};

export type OrderStatus = {
  shopOrderId: string,
  errorCode: string,
};

export const LabOrder = (props: LabOrderProps) => {
  const { kijshopCd, orderList, callbackEndOrder, retry } = props;
  // - Hooks -
  const dispatch = useDispatch();
  // - state -
  const [orderingMessage, setOrderingMessage] = useState('');
  // -- 発注ボタン押下 --
  const handlerClickOrder = useCallback(async () => {
    const enableOrderList: { createDate: string, lastName: string, shopOrderId: string, status: string, cnt: number }[] = [];
    orderList.forEach((v) => {
      if (v.status === 'ラボ発注可' || retry) {
        enableOrderList.push({
          createDate: v.createDate,
          lastName: v.lastName,
          shopOrderId: v.shopOrderId,
          status: v.status,
          cnt: v.cnt || 1,
        });
      }
    });
    if (!enableOrderList.length) {
      dispatch(dialogActions.pushMessage({
        title: '確認',
        message: ['発注できる注文がありません。'],
        buttons: [{
          label: 'OK',
          callback: () => {
            dispatch(dialogActions.pop());
          },
        }],
      }));
      return;
    }
    dispatch(dialogActions.pushMessage({
      title: '確認',
      message: [
        '発注処理を行います。よろしいですか？',
      ],
      buttons: [
        {
          label: 'いいえ',
          callback: () => {
            dispatch(dialogActions.pop());
          },
        },
        {
          label: 'はい',
          callback: async () => {
            dispatch(dialogActions.pop());
            const errorList: ErrorOrder[] = [];
            for (let i = 0; i < enableOrderList.length; i++) {
              setOrderingMessage(`ラボ発注を実施中です。（${i + 1}/${enableOrderList.length}）`);
              const orderData = enableOrderList[i];
              // errorList.push({
              //   ...orderData,
              //   errorStatus: {
              //     code: 'basic',
              //     message: 'テストメッセージ',
              //   },
              // });
              await new Promise<void>((resolve) => {
                dispatch(xmlActions.shopOrder({ kijshopCd, shopOrderId: orderData.shopOrderId }).labOrder({
                  success: () => {
                    resolve();
                  },
                  error: (e) => {
                    errorList.push({
                      ...orderData,
                      errorStatus: e ? {
                        code: e.code,
                        message: e.message,
                      } : {
                        code: 'xml',
                        message: ['XMLの取得に失敗しました'],
                      },
                      cnt: (orderData.cnt || 1) + 1,
                    });
                    resolve();
                  },
                }, true));
              });
            }
            dispatch(dialogActions.pop());
            setOrderingMessage('');
            if (errorList.length) {
              dispatch(orderPreparationActions.setErrorOrderList([...errorList]));
              dispatch(push(RoutingPath.cnv.uploadManager({ kijshopCd })));
            } else {
              dispatch(dialogActions.pushMessage({
                title: '確認',
                message: ['発注処理を行いました'],
                buttons: [{
                  label: 'OK',
                  callback: () => {
                    dispatch(dialogActions.pop());
                    callbackEndOrder();
                  },
                }],
              }));
            }
          },
        },
      ],
    }));
  }, [kijshopCd, orderList, retry]);

  // -- 閉じるボタン押下 --
  const handlerClickClose = useCallback(() => {
    dispatch(dialogActions.pop());
  }, []);

  const orderData = [...orderList].map((v) => [
    v.createDate || '',
    v.lastName || '',
    v.shopOrderId,
    <div className="order_status">
      <div className="order_status__inner">
        <ColorIcon color={v.status} />{v.status}
      </div>
    </div>,
    <div style={{ textAlign: 'center' }}>{v.status === 'ラボ発注可' ? '○' : '×'}</div>,
  ]);
  return (
    <div className='dialog_contents lab_order'>
      <div className='dialog_contents__body'>
        <Table
          head={['作成日時', 'お客様名', '注文番号', retry ? '状態' : 'ステータス', '発注可否']}
          body={orderData}
        />
      </div>
      <div className='dialog_contents__footer'>
        <Button
          label='キャンセル'
          onClick={handlerClickClose}
        />
        <Button
          label='発注'
          onClick={handlerClickOrder}
          color='warning'
          // disabled={!}
        />
      </div>
      {orderingMessage ? (<LoadingPopup label={orderingMessage} />) : (<></>)}
    </div>
  );
};
