import { ApiBaseFront } from '../api-base-front';

type Params = {
  kijshopCd: string,
};

export type OrderResult = {
  id: string;
  kijshopCd: string;
  folderId: string;
  name: string;
  createdAt: string;
  updatedAt: string;
}

export class ApiGetCloudFolderOrderResult extends ApiBaseFront {
  constructor(param: Params) {
    const { kijshopCd } = param;
    super(
      'GET',
      `/api/v1/c-folder/shop/${kijshopCd}/order/result`,
      'JSON',
      {},
    );
  }
}
