import { Input } from '../../ui/input/input';
import './add-edit-staff.scss';
import { Radiobutton } from '../../ui/input/radiobutton';
import { Button } from '../../ui/button/button';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import lodash from 'lodash';
import { useAppSelector } from '../../../app/hooks';
import { STAFF_AUTH } from '../../../collection/staff-auth-collection';

type IdType = 'mail' | 'id';

export type StaffAddParams = {
  name: string,
  password: string,
  mail: string,
  id: string,
  idType: IdType,
  authority: number,
}

type Props = {
  handlerClickClose: () => void,
  handlerClickAddStaff: (props: StaffAddParams) => void,
}

type InputErrorItem = 'name' | 'password' | 'mail' | 'id' | 'none';

export const AddStaff = (props: Props) => {
  const { handlerClickAddStaff, handlerClickClose } = props;
  const { masterAuthority } =  useAppSelector((state) => ({
    ...state.common.data,
  }), lodash.isEqual)
  // - State -
  // ラジオボタン切り替え
  const [idType, setIdType] = useState<IdType>('mail');
  const [authority, setAuthority] = useState(0);
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  const [mail, setMail] = useState('');
  const [id, setId] = useState('');
  // -- 氏名がバリデーションにかかっているか --
  const [isNameError, setIsNameError] = useState(false);
  // -- メールアドレスがバリデーションにかかっているか --
  const [isMailError, setIsMailError] = useState(false);
  // -- IDがバリデーションにかかっているか --
  const [isIdError, setIsIdError] = useState(false);
  // -- パスワードがバリデーションにかかっているか --
  const [isPasswordError, setIsPasswordError] = useState(false);
  // -- ツールチップ表示するもの --
  const [tooltipItem, setTooltipItem] = useState<InputErrorItem>('none');
  // -- 登録ボタンバリデーションフラグ --
  const [isDisabledAdd, setIsDisabledAdd] = useState(false);
  // - ref -
  // -- regExp --
  // - メールアドレス規表現 -
  const mailRegExp = useRef(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/).current;
  // - 半角英数字記号正規表現 -
  const halfWidthStringExp = useRef(/^[!-~]+$/).current;
  // - 大文字小文字かつ半角英数字または記号正規表現 -
  const halfWidthAlphanumericRegExp = useRef(/^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9!@#$%^&*])[A-Za-z0-9!@#$%^&*]+$/).current;
  // - Handlers -
  // - バリデーションチェック -
  const checkInputValidation = useCallback((type: InputErrorItem, inputItem: string): boolean  => {
    let result: boolean;
    switch (type) {
      case 'name':
        if (inputItem && inputItem.length < 255) {
          result = true;
        } else {
          result = false;
        }
        break;
      case 'password':
        if (inputItem && (inputItem.match(halfWidthAlphanumericRegExp) && inputItem.length >= 8 && inputItem.length <= 15)) {
          result = true;
        } else {
          result = false;
        }
        break;
      case 'mail':
        if (inputItem && (inputItem.match(mailRegExp))) {
          result = true;
        } else {
          result = false
        }
        break;
      case 'id':
        if (inputItem && (inputItem.match(halfWidthStringExp) && inputItem.length >= 6)) {
          result = true;
        } else {
          result = false
        }
        break;
      default:
        result = true;
        break;
    }
    return result;
  }, []);
  // -- IDを任意で指定するのラジオボタンを選択 --
  const handlerChooseTypeMail = useCallback(() => {
    setId('');
    setIdType('mail');
    setIsIdError(false);
  }, []);
  // -- メールアドレスをIDとして使用するのラジオボタンを選択 --
  const handlerChooseTypeId = useCallback(() => {
    setIdType('id');
  }, []);
  // -- 権限を任意で指定するのラジオボタンを選択 --
  const handlerChooseAuth = useCallback((v: string) => {
    setAuthority(Number(v));
  }, [authority]);
  const handlerChangeName = useCallback((e) => {
    setName(e.target.value);
    setIsNameError(!checkInputValidation('name', e.target.value))
  }, [name]);
  const handlerChangePassword = useCallback((e) => {
    setPassword(e.target.value);
  }, [password]);
  const handlerChangeMail = useCallback((e) => {
    setMail(e.target.value);
  }, [mail]);
  const handlerChangeId = useCallback((e) => {
    setId(e.target.value);
    setIsIdError(!checkInputValidation('id', e.target.value))
  }, [id]);
  const handlerMouseOver = useCallback((type: InputErrorItem) => {
    setTooltipItem(type);
  }, []);
// - effect -
  useEffect(() => {
    setIsDisabledAdd(checkInputValidation('name', name) &&
      checkInputValidation('password', password) &&
      checkInputValidation('mail', mail) &&
      (idType === 'id' ? checkInputValidation('id', id) : true));
  }, [name, password, mail, id, idType]);

  return (
    <div className="dialog_contents staff">
      <div className="staff__forms">
        <div className="label_input">
          <div className="label_input__label">名前</div>
          {/*autofill対応*/}
          <input
            type="text"
            name="dammy_id"
            style={{ position: 'absolute', top: '-50000px' }}
          />
          <input
            type="password"
            name="dammy_pass"
            style={{ position: 'absolute', top: '-50000px' }}
          />
          <Input
            value={name}
            onChange={handlerChangeName}
            onMouseOver={() => handlerMouseOver('name')}
            onMouseLeave={() => handlerMouseOver('none')}
            error={isNameError}
            tooltip={isNameError && tooltipItem === 'name' ? { messages: ['255文字以下'] } : undefined}
          />
        </div>
        <div className="label_input">
          <div className="label_input__label">仮パスワード（8文字以上15文字以内）</div>
          <Input
            type={'password'}
            value={password}
            onChange={handlerChangePassword}
            onMouseOver={() => handlerMouseOver('password')}
            onMouseLeave={() => handlerMouseOver('password')}
            error={isPasswordError}
            tooltip={isPasswordError && tooltipItem === 'password' ? { messages: ['英大文字小文字かつ数字または記号含む8文字以上15文字以内'] } : undefined}
            onBlur={() => setIsPasswordError(!checkInputValidation('password', password))}
          />
          <div className="label_input__attention">※英大文字と英小文字かつ数字または記号含む8文字以上15文字以内</div>
        </div>
        <div className="label_input">
          <div className="label_input__label">メールアドレス</div>
          <Input
            value={mail}
            onChange={handlerChangeMail}
            onMouseOver={() => handlerMouseOver('mail')}
            onMouseLeave={() => handlerMouseOver('mail')}
            error={isMailError}
            tooltip={isMailError && tooltipItem === 'mail' ? { messages: ['例: ppm@example.com'] } : undefined}
            onBlur={() => setIsMailError(!checkInputValidation('mail', mail))}
          />
        </div>
        <div className="label_input">
          <div className={`label_input__label choose_${idType}_label`}>スタッフID</div>
          <Input
            className={`choose_${idType}_input`}
            value={id}
            disabled={idType === 'mail'}
            onChange={handlerChangeId}
            onMouseOver={() => handlerMouseOver('id')}
            onMouseLeave={() => handlerMouseOver('id')}
            error={isIdError}
            tooltip={idType === 'id' && isIdError && tooltipItem === 'id' ? { messages: ['6文字以上'] } : undefined}
          />
        </div>
        <div>
          <div>
            <Radiobutton
              label="メールアドレスをIDとして使用する"
              value="mail"
              onClick={handlerChooseTypeMail}
              checkState={idType}
            />
            <Radiobutton
              label="スタッフIDを任意で指定する"
              value="id"
              onClick={handlerChooseTypeId}
              checkState={idType}
            />
          </div>
        </div>
        <div className="label_select">
          <div className={`label_select__label`}>権限</div>
          <div className="label_select__content">
            {masterAuthority?.filter(v => v.authority != STAFF_AUTH.ADMIN).map(v => (
                <Radiobutton
                  label={v.authorityName}
                  value={String(v.authority)}
                  onClick={() => handlerChooseAuth(String(v.authority))}
                  checkState={String(authority)}
                />))
            }
          </div>
        </div>
      </div>
      <div className="dialog_contents__footer">
        <Button
          label="キャンセル"
          onClick={handlerClickClose}
        />
        <Button
          label="追加"
          onClick={() => handlerClickAddStaff({ name, password, mail, id, idType, authority })}
          disabled={!isDisabledAdd}
        />
      </div>
    </div>
  );
};
