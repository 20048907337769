import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Button } from '../../../ui/button/button';
import { Image, ImageSizeType } from '../../../ui/image/image';
import { useDispatch } from 'react-redux';
import { apiActions } from '../../../../slices/api-slice';
import './layout.image-preview.scss';
import { SwitchIcon } from '../../../ui/switch-icon/switch-icon';
import { LoadingCircle } from '../../../ui/loading/loading-circle';
import * as lodash from 'lodash';
import { useAppSelector } from '../../../../app/hooks';
import { layoutActions } from '../../../../slices/layout-slice';
import { DummyImage } from '../../../../utilities/dummy-img';
import { AlbumPage } from '../../../../layout-editor/albam/albam';
import { UiManager } from '../../../../layout-editor/manager/ui/ui.manager';
import { EditableImage } from '../../../../layout-editor/manager/image-edit/editable-image';
import { useForceUpdate } from '../../../../hooks/use-force-update';
import imagesIcon from '../../../../assets/img/icon/images-solid.svg';
import bookOpenIcon from '../../../../assets/img/icon/book-open-solid.svg';
import { MenuType } from '../layout';
import { store } from '../../../../app/store';
import { dialogActions } from '../../../dialog/slice/dialog-slice';
import { useParams } from 'react-router-dom';
import { PathParams } from '../../../../routes/routing-path';
import { WarningIcon } from '../../../ui/warning-icon/warning-icon';
import { DomContentTooltip } from '../../../ui/tooltip/dom-content-tooltip';
import { useWillUnMount } from '../../../../hooks/life-cycle';
import { ErrorToastProps } from '../edit-template/layout.edit-template';


type LayoutListItemProps = {
  // - １ページ分のレイアウト情報 -
  layoutItem?: ApplyLayoutItem,
  // - 全画面表示にするか -
  isFullScreen?: boolean,
  // - 押下制御暫定対応 -
  isEmpty?: boolean,
  // -- 並び替え用のハンドラ --
  onDragItem?: (item: ApplyLayoutItem) => void,
  onDropPosItem?: (item: ApplyLayoutItem) => void,
  onHoverItem?: (item: ApplyLayoutItem) => void,
  resizeRate?: number,
  menuParam: MenuType,
  dummyImage?: { label: string, pageCount: number },
  onClickDelete?: () => void,
};
// - １ページ分のレイアウト表示用コンポネント -
const LayoutListItem = (props: LayoutListItemProps) => {
  const { layoutItem, isFullScreen, isEmpty, onDragItem, onDropPosItem, onHoverItem, resizeRate, menuParam, dummyImage, onClickDelete } = props;
  const { page, warningList,fitErrorList, fontErrorList } = useAppSelector((state) => ({
    fitErrorList: state.layout.fitErrorList,
    page: state.layout.page,
    warningList: state.layout.warningList,
    fontErrorList: state.layout.fontErrorList
  }), lodash.isEqual);
  const dispatch = useDispatch();
  //  - 幅に対する高さの比率 -
  const ratio = 0.67;
  //  - State -
  //  -- サムネ全体の高さ --
  const [itemHeight, setItemHeight] = useState(0);
  //  -- 高さいっぱいに表示するか --
  const [isFullHeight, setIsFullHeight] = useState(false);
  //  -- 幅いっぱいに表示するか --
  const [isFullWidth, setIsFullWidth] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [imgSize, setImageSize] = useState<ImageSizeType>();
  const [isClick, setIsClick] = useState(false);
  const [isShowWarningIconTooltip, setIsShowWarningIconTooltip] = useState(false);
  // -- Ref --
  const wrapEle = useRef<HTMLDivElement>(null);
  // -- Memo --
  const isWarningIcon = useMemo(() => warningList.find(v => v === `${layoutItem?.pageType} : ${layoutItem?.pageNum}`), [warningList]);
  const isFontWarningIcon = useMemo(() => fontErrorList.find(v => v === `${layoutItem?.pageType} : ${layoutItem?.pageNum}`), [fontErrorList]);
  // -- Callback --
  // -- 項目押下 --
  const handlerClickItem = useCallback(() => {
    if (isEmpty) {
      return;
    }
    // if (layoutItem?.album) {
    //   // UiManager.ins.emit('r->l:edit-album', { album: layoutItem.album });
    // }
    setIsClick(true);
    if (isClick) {
      if (layoutItem?.album) {
        UiManager.ins.emit('r->l:select-album', { album: layoutItem.album });
      }
      dispatch(layoutActions.setCurrentAlbumPage(layoutItem?.album || null));
      dispatch(layoutActions.setPage('edit'));
      dispatch(layoutActions.removeWarningList(`${layoutItem?.pageType} : ${layoutItem?.pageNum}`))
      dispatch(layoutActions.removeFontErrorList(`${layoutItem?.pageType} : ${layoutItem?.pageNum}`))
      setIsClick(false);
    } else {
      if (layoutItem?.album) {
        dispatch(layoutActions.setPreviewSelectItem({
          pageNum: layoutItem.pageNum.toString(),
          album: layoutItem.album,
        }));
        // UiManager.ins.emit('r->l:edit-album', { album: layoutItem.album });
      }
    }
    setTimeout(() => {
      setIsClick(false);
    }, 350);
    // dispatch(layoutActions.setCurrentAlbumPage(layoutItem.album || null));
  }, [isEmpty, layoutItem, isClick, menuParam]);
  const handlerDoubleClickItem = useCallback(() => {
    if (isEmpty) {
      return;
    }
    if (layoutItem?.album) {
      UiManager.ins.emit('r->l:select-album', { album: layoutItem.album });
    }
    dispatch(layoutActions.setCurrentAlbumPage(layoutItem?.album || null));
    dispatch(layoutActions.setPage('edit'));
  }, [isEmpty, layoutItem]);

  useEffect(() => {
    const wrapWidth = wrapEle.current?.getBoundingClientRect().width || 0;
    if (wrapWidth > 0) {
      setItemHeight(wrapWidth * ratio);
    }
  }, [isFullScreen]);

  useEffect(() => {
    if (imgSize) {
      const { width, height } = imgSize;
      if (width > 0 && height > 0) {
        const heightRatio = height / width;
        if (heightRatio > ratio) {
          setIsFullHeight(true);
          setIsFullWidth(false);
        } else {
          setIsFullHeight(false);
          setIsFullWidth(true);
        }
        setLoaded(true);
      }
    }
  }, [imgSize]);

  return (
    <div
      className="layout_preview_item__wrap"
      ref={wrapEle}
      // style={{ minHeight: itemHeight, height: itemHeight }}
      onClick={handlerClickItem}
      // onDoubleClick={handlerDoubleClickItem}
      tabIndex={-1}
      onDragStart={() => layoutItem ? onDragItem?.(layoutItem) : {}}
      onDrop={() => layoutItem ? onDropPosItem?.(layoutItem) : {}}
      onDragOver={() => layoutItem ? onHoverItem?.(layoutItem) : {}}
      onMouseEnter={() => isWarningIcon || isFontWarningIcon ? setIsShowWarningIconTooltip(true): {}}
      onMouseLeave={() => isWarningIcon || isFontWarningIcon ? setIsShowWarningIconTooltip(false): {}}
      style={page === 'list' ? { width: `${resizeRate ? 26 * resizeRate : 26}em` } : {}}
    >
      {layoutItem ? (
        <div className="layout_preview_item">
          <div className="layout_preview_item__inner">
            <div className="layout_preview_item__header">
              <div className="bordered_left_label_item">
                <span>{layoutItem.pageNum}</span>
              </div>
              <div className="bordered_left_label_item">
                <span><img
                  src={bookOpenIcon}
                  alt=""
                /></span>
                <div>{layoutItem.pageType}</div>
              </div>
              <div className="bordered_left_label_item">
                <span><img
                  src={imagesIcon}
                  alt=""
                /></span>
                <div>{layoutItem.imageNum}</div>
              </div>
            </div>
            <div className="layout_preview_item__body">
              {!!dummyImage &&
                <div className="layout_preview_item__dummy">
                  <span>{dummyImage.label}</span>
                  <span className="layout_preview_item__dummy__page"><span className="plus_icon" />{dummyImage.pageCount}</span>
                </div>
              }
              {!dummyImage && (<Image
                src={layoutItem.thumbnail}
                setSizeCallback={setImageSize}
              />)}
              <WarningIcon isShow={!!isWarningIcon || !!isFontWarningIcon} />
              {(!dummyImage && (page !== 'list')) &&
                <div
                  className="layout_preview_item__body__delete"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    onClickDelete?.();
                  }}
                />}

              {
                // getIsDuplicate(fitErrorList, layoutItem.album?.editableIDList) ?
                fitErrorList.find((v) => v === layoutItem?.album?.id) ?
                  <ErrorToast
                    text={['空白あり']}
                    className={'enter'}
                  /> : <></>
              }

              {loaded || !!dummyImage ? (<></>) : <LoadingCircle />}
              { (isWarningIcon || isFontWarningIcon) && isShowWarningIconTooltip &&
                <DomContentTooltip
                relativeEle={wrapEle.current}
                onClick={(e) => {
                  e.stopPropagation();
                }}
                content={(() => {
                  if (isWarningIcon)  {
                    return (
                      <div className={"layout_preview_item__warning-icon-tooltip"}>
                        <div>ページで問題が発生しています</div>
                        <div>レイアウト内容を確認してください</div>
                      </div>
                    )} else {
                    return (
                      <div className={"layout_preview_item__warning-icon-tooltip"}>
                        <div>入力したテキストに問題が発生しています</div>
                        <div>フォントを確認し再設定してください</div>
                      </div>
                    )}
                })()
                }
              />
              }
            </div>
          </div>
        </div>
      ) : (<></>)}
    </div>
  );
};

// - １ページ分のレイアウト情報 -
type ApplyLayoutItem = {
  pageNum: number | string,
  imageNum: number,
  pageType: string,
  thumbnail: string,
  album?: AlbumPage,
};
type LayoutListProps = {
  // - 横幅いっぱいに表示するか -
  isFullWidth?: boolean,
  thumbSizeRate?: number,
  menuParam: MenuType,
  viewPageType: string[],
  initCallback: (v: MenuType) => void,
};
// - Previewの判別および詳細情報 -
export type PreviewListType = {
  cover: PreviewType[],
  top: PreviewType[],
  page: PreviewType[],
  end: PreviewType[],
  option: PreviewType[],
}
export type PreviewType = {
  url: string,
  album: AlbumPage,
}
export const LayoutImagePreview = (props: LayoutListProps) => {
  const { isFullWidth = false, thumbSizeRate = 1, menuParam, initCallback, viewPageType } = props;
  const { kijshopCd, shopOrderId, orderId } = useParams<PathParams>();
  const { album, displayType, tempGroupName, deleteAlbum, masterPageTypeList, xml, isDraggingTemp } = useAppSelector((state) => ({
    album: state.layout.albumPages,
    displayType: state.layout.page,
    deleteAlbum: state.layout.deleteAlbumInfos,
    // pageCount: state.layout.albumSettingData.pageCount,
    tempGroupName: state.layout.templateGroupName,
    masterPageTypeList: state.layout.pageTypeList,
    xml: state.xml[shopOrderId],
    isDraggingTemp: state.layout.isDraggingTemplate,
  }), lodash.isEqual);
  // const album = [..._album.cover.albums, ..._album.top.albums, ..._album.page.albums, ..._album.endCover.albums];
  // const totalUseImageNum = [...album.cover.albums, ...album.top.albums, ...album.page.albums, ...album.endCover.albums].length;
  // const emptyCount = album.cover.page + album.top.page + album.endCover.page;
  // const realCount = [...album.cover.albums, ...album.top.albums, ...album.endCover.albums].length;
  // const totalPageNum = pageCount ? pageCount :
  //   album.top.page + album.page.page
  //     < (album.top.albums.length < album.top.page ? album.top.page : album.top.albums.length) + (album.page.albums.length * 2)
  //   ? (album.top.albums.length < album.top.page ? album.top.page : album.top.albums.length) + (album.page.albums.length * 2)
  //   : album.top.page + album.page.page;
  // const totalPageNum = [...album.top.albums, ...album.endCover.albums].length + (album.page.albums.length * (tempGroupName === 'DAI' ? 1 : 2));
  // -- Memo --
  const totalPageNum = useMemo(() => {
    const _album = {
      top: album.top,
      page: album.page,
      endCover: album.endCover,
    };
    return Object.values(_album).map((v) => v.albums.length * v.pageCount).reduce((a, b) => a + b);
  }, [album]);
  const targetXml = useMemo(() => {
    return xml?.orderInfo?.infoData?.find((v) => v.xml.metaModel.id === orderId) ?? null;
  }, [xml, orderId]);

  // (album.cover.page + album.top.page + album.page.page + album.endCover.page)
  //   < ((realCount < emptyCount ? emptyCount : realCount) + (album.page.albums.length * 2))
  // ? (realCount < emptyCount ? emptyCount : realCount) + (album.page.albums.length * 2)
  // : album.cover.page + album.top.page + album.page.page + album.endCover.page;
  const dispatch = useDispatch();
  const forceUpdate = useForceUpdate();
  // - State -
  // -- 二重矢印ボタンlabel --
  const [btnClassName, setBtnClassName] = useState('open');
  // -- レイアウト一覧の表示有無 --
  const [isOpen, setIsOpen] = useState(true);
  // -- ページ種リスト --
  const [pageTypeList, setPageTypeList] = useState<{ pageType: string }[]>();
  // -- 利用画像数 --
  const [useImageNum, setUseImageNum] = useState(0);
  // -- 並び替えでドラッグ中テンプレート --
  const [draggingItem, setDraggingItem] = useState<ApplyLayoutItem>();
  // -- 並び替えでホバー中のテンプレート --
  const [hoverItem, setHoverItem] = useState<ApplyLayoutItem>();
  const [coverPhotoNumList, setCoverPhotoNumList] = useState<{ album: AlbumPage, photoCount: number }[]>([]);
  const [topPhotoNumList, setTopPhotoNumList] = useState<{ album: AlbumPage, photoCount: number }[]>([]);
  const [pagePhotoNumList, setPagePhotoNumList] = useState<{ album: AlbumPage, photoCount: number }[]>([]);
  const [endPhotoNumList, setEndPhotoNumList] = useState<{ album: AlbumPage, photoCount: number }[]>([]);
  const [optionPhotoList, setOptionPhotoList] = useState<{ album: AlbumPage, photoCount: number }[]>([]);
  const [previewList, setPreviewList] = useState<PreviewListType>({
    cover: [],
    top: [],
    page: [],
    end: [],
    option: [],
  });
  // - Callback -
  // -- 二重矢印ボタン押下 --
  const handlerClickButton = useCallback(() => {
    setIsOpen(prev => !prev);
    setBtnClassName(prev => prev === 'open' ? '' : 'open');
  }, []);
  // - effect -
  useEffect(() => {
    UiManager.ins.on('l->r:change-use-image-num', onChangeImageList);
    // UiManager.ins.on('l->r:change-editable-image-list', onChangeImageList);
    UiManager.ins.on('l->r:count-photo', onGetPhotoCount);
    return () => {
      UiManager.ins.off('l->r:change-use-image-num', onChangeImageList);
      UiManager.ins.off('l->r:count-photo', onGetPhotoCount);
    };
  }, []);

  useEffect(() => {
    const optionList = masterPageTypeList.option.map((v) => v.pageTypeID);
    const opPrintList = masterPageTypeList.opPrint.map((v) => v.pageTypeID);
    UiManager.ins.on('r->l:preview:make', (p) => {
      setPreviewList((prev) => {
        if (p.album.isOption) {
          if (optionList.find((v) => v === p.album.typeID)
            || opPrintList.find(v => v === p.album.typeID)) {
            prev.option = prev.option.filter((pp) => pp.album.id !== p.album.id);
            prev.option = [ ...prev.option, { url: p.url, album: p.album }];
          }
        } else {
          if (masterPageTypeList.cover.find(v => v.pageTypeID === p.album.typeID)) {
            prev.cover = prev.cover.filter(pp => pp.album.id !== p.album.id);
            prev.cover = [...prev.cover, { url: p.url, album: p.album }];
          } else if (masterPageTypeList.top.find(v => v.pageTypeID === p.album.typeID)) {
            prev.top = prev.top.filter(pp => pp.album.id !== p.album.id);
            prev.top = [...prev.top, { url: p.url, album: p.album }];
          } else if (masterPageTypeList.page.find(v => v.pageTypeID === p.album.typeID)) {
            prev.page = prev.page.filter(pp => pp.album.id !== p.album.id);
            prev.page = [...prev.page, { url: p.url, album: p.album }];
          } else if (masterPageTypeList.endCover.find(v => v.pageTypeID === p.album.typeID)) {
            prev.end = prev.end.filter(pp => pp.album.id !== p.album.id);
            prev.end = [...prev.end, { url: p.url, album: p.album }];
          } else {
            prev.page = prev.page.filter(pp => pp.album.id !== p.album.id);
            prev.page = [...prev.page, { url: p.url, album: p.album }];
          }
        }
        p.callback(prev);
        return prev;
      });
      forceUpdate();
    });
    UiManager.ins.on('l->r:removePreview', (p) => {
      setPreviewList((prev) => {
        if (p.album.isOption) {
          if (optionList.find((v) => v === p.album.typeID)
            || (opPrintList.find((v) => v === p.album.typeID))) {
            prev.option = prev.option.filter((pp) => pp.album.id !== p.album.id);
          }
        } else {
          if (masterPageTypeList.cover.find(v => v.pageTypeID === p.album.typeID)) {
            prev.cover = prev.cover.filter(pp => pp.album.id !== p.album.id);
          } else if (masterPageTypeList.top.find(v => v.pageTypeID === p.album.typeID)) {
            prev.top = prev.top.filter(pp => pp.album.id !== p.album.id);
          } else if (masterPageTypeList.page.find(v => v.pageTypeID === p.album.typeID)) {
            prev.page = prev.page.filter(pp => pp.album.id !== p.album.id);
          } else if (masterPageTypeList.endCover.find(v => v.pageTypeID === p.album.typeID)) {
            prev.end = prev.end.filter(pp => pp.album.id !== p.album.id);
          } else {
            prev.page = prev.page.filter(pp => pp.album.id !== p.album.id);
          }
        }
        return prev;
      });
    })
    dispatch(apiActions.pageTypeList(
      {
        serviceId: '',  // カテゴリーID
        productTagId: '',  // 商品タグID
        coverTypeId: '',  // 表紙ID
        coverColorId: '',  // カバー色ID
        productNameId: '',  // 商品ID
        contentType: '',  // 親子フラグ
      },
      {
        onSuccess: (res) => {
          // ページ種リスト取得
          setPageTypeList([{ pageType: '表紙' }]);
        },
      },
    ));

    return () => {
      UiManager.ins.off('r->l:preview:make');
      UiManager.ins.off('l->r:removePreview')
    };
  }, [masterPageTypeList]);

  const onChangeImageList = useCallback((p: { type: 'increment' | 'decrement' }) => {
    switch (p.type) {
      case 'increment':
        setUseImageNum(prev => ++prev);
        break;
      case 'decrement':
        setUseImageNum(prev => --prev);
        break;
    }
  }, [useImageNum]);
  const onGetPhotoCount = useCallback((e: { album: AlbumPage, photoCount: number }) => {
    const optionList =  store.getState().layout.albumPages.option.map((v) => v.album?.id);
    if (optionList.find((v) => v === e.album?.id)) {
      setOptionPhotoList(prev => {
        prev = prev.filter(v => v.album.id !== e.album.id);
        prev.push(e);
        return prev;
      });
    } else if (e.album.type.indexOf('cover') !== -1) {
      setCoverPhotoNumList(prev => {
        prev = prev.filter(v => v.album.id !== e.album.id);
        prev.push(e);
        return prev;
      });
    } else if (e.album.type.indexOf('top') !== -1) {
      setTopPhotoNumList(prev => {
        prev = prev.filter(v => v.album.id !== e.album.id);
        prev.push(e);
        return prev;
      });
    } else if (e.album.type.indexOf('page') !== -1) {
      setPagePhotoNumList(prev => {
        prev = prev.filter(v => v.album.id !== e.album.id);
        prev.push(e);
        return prev;
      });
    } else if (e.album.type.indexOf('end') !== -1) {
      setEndPhotoNumList(prev => {
        prev = prev.filter(v => v.album.id !== e.album.id);
        prev.push(e);
        return prev;
      });
    } else {
      setPagePhotoNumList(prev => {
        prev = prev.filter(v => v.album.id !== e.album.id);
        prev.push(e);
        return prev;
      });
    }
  }, [coverPhotoNumList, topPhotoNumList, pagePhotoNumList, endPhotoNumList, album]);
  const handlerHoverItem = useCallback((item) => {
    if (hoverItem?.pageNum !== item?.pageNum) {
      setHoverItem(item);
    }
  }, [hoverItem]);

  const [pageNo, setPageNo] = useState<string[]>([]);

  useEffect(() => {
    const page = album.page.albums;
    const end = album.endCover.albums;
    for (const elm of page) {
      if (!pageNo.length) {
      const n = page[0].pageNo.split('-');
      const _pageNo = n.concat();
        setPageNo(_pageNo)
      }
      const _pageNo = page[0].pageNo.split('-');
      if (_pageNo.length === 2) {
        elm.pageNo = `${Number(pageNo[0] ?? _pageNo[0]) + (page.indexOf(elm) * 2)}-${Number(pageNo[1] ?? _pageNo[1]) + (page.indexOf(elm) * 2)}`;
      } else {
        elm.pageNo = `${Number(pageNo[0] ?? _pageNo[0]) + (page.indexOf(elm))}`;
      }
    }
    if (end.length) {
      end[0].pageNo = album.endCover.displayPageNo || Number(end[0].pageNo) > page.length + 2 ? end[0].pageNo : String(page.length + 2);
    }
    forceUpdate();
  }, [album]);

  const onClickDeleteAlbum = useCallback((album: AlbumPage) => {
    const targetPage = album.type.indexOf('page') !== -1
      ? `${album.displayName} ${album.pageNo}ページ`
      : album.displayName;
    dispatch(dialogActions.pushMessage({
      title: '確認',
      message: [`「${targetPage}」を削除します。`],
      buttons: [
        {
          label: 'キャンセル',
          callback: () => dispatch(dialogActions.pop()),
        },
        {
          label: 'OK',
          callback: () => {
            dispatch(dialogActions.pop());
            dispatch(layoutActions.removeWarningList(`${album.displayName} : ${album.pageNo}`))
            dispatch(layoutActions.removeFontErrorList(`${album.displayName} : ${album.pageNo}`))
            dispatch(layoutActions.removeAlbumPage({
              type: album.type,
              id: album.id,
              typeId: album.typeID,
            }));
            dispatch(layoutActions.setDeleteAlbumData([
              ...deleteAlbum,
              { templatePath: album.imageEditManager?.path?.ad ?? '', thumbnailID: '' }
            ]));
            UiManager.ins.emit('r->l:delete-album', { albumID: album.id });
          }
        }
      ]
    }))
  }, [deleteAlbum]);

  useEffect(() => {
    if (displayType === 'select' || isFullWidth) {
      initCallback({
        ...menuParam,
        ableArea: true,
        center: false,
      });
    }
  }, [displayType, isFullWidth]);

  return (
    <div
      className={`preview_sidebar layout__body__preview${isOpen ? '' : ' close'}${isFullWidth ? ' full_width' : ''}`}
      onClick={() => UiManager.ins.emit('r->l:dis-select')}
      onDragOver={(e) => e.preventDefault()}
      onDrop={(e) => {
        e.preventDefault();
        if (!draggingItem && isDraggingTemp) {
          dispatch(layoutActions.setIsDropTemplate(true));
        }
      }}
    >
      {!isFullWidth ? (
        <div className="preview_sidebar__header">
          {isOpen ? (
            <div className="preview_sidebar__header__info">
              <div className="bordered_left_label_item flex_grow_1">
                <span>総頁</span>
                <div>{totalPageNum}</div>
              </div>
              <div className="bordered_left_label_item flex_grow_1">
                <span>画像数</span>
                <div>{useImageNum}</div>
              </div>
            </div>
          ) : (<></>)}
          <div
            onClick={handlerClickButton}
            className="open_btn"
          >
            <Button
              className={btnClassName}
              color="dark"
              icon={<SwitchIcon
                isOn={isOpen}
                tmplMode="open_x"
              />}
            />
            {!isOpen ? (
              <span style={{ fontWeight: 'bold' }} />
            ) : <></>}
          </div>
        </div>
      ) : (<></>)}

      {isOpen || isFullWidth ? (
        <>
          <div className="preview_sidebar__list">
            {album.opPrint.map((v, i) => {
              if (!v.album) {
                return (
                  <LayoutListItem
                    menuParam={menuParam}
                    key={`layout-list-item-end_empty_option_dummy_${v.xml.xmlUniqueName}`}
                    layoutItem={{
                      // pageNum: 1,
                      // pageNum: (1 + (album.page.page * 2) + 1 < 2 + (album.page.albums.length * 2)) ? 2 + (album.page.albums.length * 2) : 1 + (album.page.page * 2) + 1,
                      pageNum: v.xml.viewModel.displayPageNo || '',
                      imageNum: 0,
                      pageType: v.xml.viewModel.displayPageType || '',
                      thumbnail: '',
                    }}
                    isFullScreen={isFullWidth}
                    isEmpty={true}
                    onDragItem={item => setDraggingItem(item)}
                    onDropPosItem={item => setDraggingItem(undefined)}
                    resizeRate={thumbSizeRate}
                    dummyImage={{ label: v.xml.viewModel.displayPageType || '', pageCount: 1 }}
                  />
                )
              } else {
                const _album = v.album;
                return (
                  <React.Fragment key={`layout-list-item-page_${i}`}>
                    {hoverItem?.album?.id === _album.id && draggingItem && draggingItem.pageNum > hoverItem.pageNum && displayType !== 'list' ?
                      <LayoutListItem
                        menuParam={menuParam}
                        resizeRate={thumbSizeRate}
                      /> : <></>}
                    <LayoutListItem
                      menuParam={menuParam}
                      // key={`layout-list-item-page_${i}`}
                      layoutItem={{
                        // pageNum: album.page.page > 1 ? `${2 + (i * 2)}-${3 + (i * 2)}` : String(i + 1),
                        pageNum: _album.pageNo,
                        imageNum: optionPhotoList.find((v) => v.album.id === _album.id)?.photoCount ?? 0,
                        // pageType: pageTypeData.find((vv) => v.type === vv.name)?.displayName || v.type,
                        pageType: _album.displayName,
                        thumbnail: previewList.option.find((preview) => preview.album.id === _album.id)?.url ?? _album.thumbnail.image,
                        // thumbnail: pageTmpUrl.find((p) => p.album.id === v.id)?.url || v.thumbnail.image,
                        album: _album,
                      }}
                      onClickDelete={() => onClickDeleteAlbum(_album)}
                      isFullScreen={isFullWidth}
                      onDragItem={(item) => setDraggingItem(item)}
                      onDropPosItem={(item) => setDraggingItem(undefined)}
                      onHoverItem={(item) => handlerHoverItem(item)}
                      resizeRate={thumbSizeRate}
                    />
                    {hoverItem?.album?.id === _album.id && draggingItem && draggingItem.pageNum < hoverItem.pageNum && displayType !== 'list' ?
                      <LayoutListItem
                        menuParam={menuParam}
                        resizeRate={thumbSizeRate}
                      /> : <></>}
                  </React.Fragment>
                )
              }
            })}
            {album.cover.albums.map((v, i) => (
              <LayoutListItem
                menuParam={menuParam}
                key={`layout-list-item-cover_${i}`}
                layoutItem={{
                  // pageNum: '・・・',
                  pageNum: album.cover.albums[i].pageNo,
                  imageNum: coverPhotoNumList.find((vv) => vv.album.id === v.id)?.photoCount || 0,
                  // pageType: pageTypeData.find((vv) => v.type === vv.name)?.displayName || v.type,
                  pageType: album.cover.albums[i].displayName,
                  // TODO : ↓ 仮実装
                  thumbnail: previewList.cover.find((p) => p.album.id === v.id)?.url || v.thumbnail.image,
                  // thumbnail: coverTmpUrl.find((p) => p.album.id === v.id)?.url || v.thumbnail.image,
                  album: v,
                }}
                onClickDelete={() => onClickDeleteAlbum(v)}
                isFullScreen={isFullWidth}
                onDragItem={item => setDraggingItem(item)}
                onDropPosItem={item => setDraggingItem(undefined)}
                resizeRate={thumbSizeRate}
              />
            ))}
            {viewPageType.find((v) => masterPageTypeList.cover.find((v2) => v2.pageTypeID === v)) && album.cover.page && !album.cover.albums.length ? (
            // {viewPageType.find((v) => COVER_LIST.find((v2) => v2.pageTypeID === v)) && album.cover.page && !album.cover.albums.length ? (
              <LayoutListItem
                menuParam={menuParam}
                layoutItem={{
                  pageNum: '***',
                  imageNum: 0,
                  pageType: '表紙',
                  thumbnail: '',
                }}
                isFullScreen={isFullWidth}
                isEmpty={true}
                onDragItem={item => setDraggingItem(item)}
                onDropPosItem={item => setDraggingItem(undefined)}
                resizeRate={thumbSizeRate}
                dummyImage={{ label: '頁-COVER', pageCount: (album.cover.page - album.cover.albums.length) }}
              />
            ) : (<></>)}
            {album.top.albums.map((v, i) => (
              <LayoutListItem
                menuParam={menuParam}
                key={`layout-list-item-top_${i}`}
                layoutItem={{
                  // pageNum: 1,
                  pageNum: album.top.albums[i].pageNo,
                  imageNum: topPhotoNumList.find((vv) => vv.album.id === v.id)?.photoCount || 0,
                  // pageType: pageTypeData.find((vv) => v.type === vv.name)?.displayName || v.type,
                  pageType: album.top.albums[i].displayName,
                  thumbnail: previewList.top.find((p) => p.album.id === v.id)?.url || v.thumbnail.image,
                  // thumbnail: topTmpUrl.find((p) => p.album.id === v.id)?.url || v.thumbnail.image,
                  album: v,
                }}
                isFullScreen={isFullWidth}
                onClickDelete={() => onClickDeleteAlbum(v)}
                onDragItem={item => setDraggingItem(item)}
                onDropPosItem={item => setDraggingItem(undefined)}
                resizeRate={thumbSizeRate}
              />
            ))}
            {viewPageType.find((v) => masterPageTypeList.top.find((v2) => v2.pageTypeID === v))  && album.top.page && !album.top.albums.length ? (
            // {viewPageType.includes('jptg380013') && album.top.page && !album.top.albums.length ? (
              <LayoutListItem
                menuParam={menuParam}
                layoutItem={{
                  pageNum: 1,
                  imageNum: 0,
                  pageType: '頁-TOP',
                  thumbnail: '',
                }}
                isFullScreen={isFullWidth}
                isEmpty={true}
                onDragItem={item => setDraggingItem(item)}
                onDropPosItem={item => setDraggingItem(undefined)}
                resizeRate={thumbSizeRate}
                dummyImage={{ label: '頁-TOP', pageCount: (album.top.page - album.top.albums.length) }}
              />
            ) : (<></>)}
            {album.page.albums.map((v, i) => {
              return (
                <React.Fragment key={`layout-list-item-page_${i}`}>
                  {hoverItem?.album?.id === v.id && draggingItem && draggingItem.pageNum > hoverItem.pageNum && displayType !== 'list' ?
                    <LayoutListItem
                      menuParam={menuParam}
                      resizeRate={thumbSizeRate}
                    /> : <></>}
                  <LayoutListItem
                    menuParam={menuParam}
                    // key={`layout-list-item-page_${i}`}
                    layoutItem={{
                      // pageNum: album.page.page > 1 ? `${2 + (i * 2)}-${3 + (i * 2)}` : String(i + 1),
                      pageNum: (() => {
                        return album.page.albums[i].pageNo;
                      })(),
                      imageNum: pagePhotoNumList.find((vv) => vv.album.id === v.id)?.photoCount || 0,
                      // pageType: pageTypeData.find((vv) => v.type === vv.name)?.displayName || v.type,
                      pageType: album.page.albums[i].displayName,
                      thumbnail: previewList.page.find((p) => p.album.id === v.id)?.url || v.thumbnail.image,
                      // thumbnail: pageTmpUrl.find((p) => p.album.id === v.id)?.url || v.thumbnail.image,
                      album: v,
                    }}
                    isFullScreen={isFullWidth}
                    onDragItem={(item) => setDraggingItem(item)}
                    onClickDelete={() => onClickDeleteAlbum(v)}
                    onDropPosItem={(item) => {
                      const target = album.page.albums.find((v) => v.id === item.album?.id);
                      if (target && draggingItem?.album) {
                        // 順番入れ替え
                        dispatch(layoutActions.sortAlbumPage({ dragItem: draggingItem.album, dropPosItem: target }));
                      }
                      setDraggingItem(undefined);
                    }}
                    onHoverItem={(item) => handlerHoverItem(item)}
                    resizeRate={thumbSizeRate}
                  />
                  {hoverItem?.album?.id === v.id && draggingItem && draggingItem.pageNum < hoverItem.pageNum && displayType !== 'list' ?
                    <LayoutListItem
                      menuParam={menuParam}
                      resizeRate={thumbSizeRate}
                    /> : <></>}
                </React.Fragment>
              );
            })}
            {viewPageType.find((v) => masterPageTypeList.page.find((v2) => {
              return v2.pageTypeID === v;
            }))  && album.page.page && album.page.albums.length < album.page.page ? (
            // {viewPageType.includes('jptg380014') && album.page.page && album.page.albums.length < album.page.page ? (
              <LayoutListItem
                menuParam={menuParam}
                layoutItem={{
                  // pageNum: album.page.page > 1 ? `${2 + (album.page.albums.length * 2)}-${1 + (album.page.page * 2)}` : String(album.page.page),
                  pageNum: (() => {
                    const isSingle = album.page.pageCount === 1;
                    const start = (((album.page.albums.length) * album.page.pageCount) + 1) + ((album.top.page * album.top.pageCount) || 0);return isSingle ? start : `${start}-${start + (album.page.pageCount - 1)}`;
                  })(),
                  imageNum: 0,
                  pageType: album.page.displayPageType ?? '頁',
                  thumbnail: '',
                }}
                isFullScreen={isFullWidth}
                isEmpty={true}
                onDragItem={item => setDraggingItem(item)}
                onDropPosItem={item => setDraggingItem(undefined)}
                resizeRate={thumbSizeRate}
                dummyImage={{ label: album.page.displayPageType === '頁' ? '頁-PAGE' : (album.page.displayPageType ?? '頁-PAGE'), pageCount: (album.page.page - album.page.albums.length) }}
              />
            ) : (<></>)}
            {/*{(targetXml && targetXml.xml.viewModel.goods && targetXml.xml.viewModel.goods.productType === 'jptg300164') || album.page.page && album.page.albums.length < album.page.page && album.page.displayPageType === 'ﾌﾟﾘﾝﾄ' ? (*/}
            {/*// {targetXml && targetXml.xml.viewModel.goods && targetXml.xml.viewModel.goods.productType === 'jptg300164' && album.page.page && album.page.albums.length < album.page.page && printList.includes(album.page.pageTypeId) ? (*/}
            {/*// {printList.find((v) => viewPageType.find((v2) => v2 === v)) && album.page.page && album.page.albums.length < album.page.page && printList.includes(album.page.pageTypeId) ? (*/}
            {/*  <LayoutListItem*/}
            {/*    menuParam={menuParam}*/}
            {/*    layoutItem={{*/}
            {/*      // pageNum: album.page.page > 1 ? `${2 + (album.page.albums.length * 2)}-${1 + (album.page.page * 2)}` : String(album.page.page),*/}
            {/*      // pageNum: album.page.page > 1 ? `${2 + (album.page.albums.length * 2)}-${1 + (album.page.page * 2)}` : String(album.page.page),*/}
            {/*      pageNum: (() => {*/}
            {/*        const isSingle = album.page.pageCount === 1;*/}
            {/*        const start = (((album.page.albums.length) * album.page.pageCount) + 1) + ((album.top.page * album.top.pageCount) || 0);*/}
            {/*        return isSingle ? start : `${start}-${start + (album.page.pageCount - 1)}`;*/}
            {/*      })(),*/}
            {/*      imageNum: 0,*/}
            {/*      pageType: 'ﾌﾟﾘﾝﾄ',*/}
            {/*      thumbnail: '',*/}
            {/*    }}*/}
            {/*    isFullScreen={isFullWidth}*/}
            {/*    isEmpty={true}*/}
            {/*    onDragItem={item => setDraggingItem(item)}*/}
            {/*    onDropPosItem={item => setDraggingItem(undefined)}*/}
            {/*    resizeRate={thumbSizeRate}*/}
            {/*    dummyImage={{ label: 'ﾌﾟﾘﾝﾄ', pageCount: (album.page.page - album.page.albums.length) }}*/}
            {/*  />*/}
            {/*) : (<></>)}*/}
            {album.endCover.albums.map((v, i) => (
              <LayoutListItem
                menuParam={menuParam}
                key={`layout-list-item-endCover_${i}`}
                layoutItem={{
                  // pageNum: (album.page.albums.length * 2) + 2 < (album.page.page * 2) + 2 ? (album.page.page * 2) + 2 : (album.page.albums.length * 2) + 2,
                  pageNum: (() => {
                    const topCount = album.top.page * album.top.pageCount;
                    const isOverPage = album.page.albums.length > album.page.page;
                    const over = (album.page.albums.length * album.page.pageCount) + topCount + 1;
                    const notOver = (album.page.page * album.page.pageCount) + topCount + 1;
                    return isOverPage ? over : notOver;
                  })(),
                  imageNum: endPhotoNumList.find((vv) => vv.album.id === v.id)?.photoCount || 0,
                  // pageType: pageTypeData.find((vv) => v.type === vv.name)?.displayName || v.type,
                  pageType: album.endCover.albums[i].displayName,
                  thumbnail: previewList.end.find((p) => p.album.id === v.id)?.url || v.thumbnail.image,
                  // thumbnail: endTmpUrl.find((p) => p.album.id === v.id)?.url || v.thumbnail.image,
                  album: v,
                }}
                onClickDelete={() => onClickDeleteAlbum(v)}
                isFullScreen={isFullWidth}
                onDragItem={item => setDraggingItem(item)}
                onDropPosItem={item => setDraggingItem(undefined)}
                resizeRate={thumbSizeRate}
              />
            ))}
            {viewPageType.find((v) => masterPageTypeList.endCover.find((v2) => v2.pageTypeID === v))  && album.endCover.page && !album.endCover.albums.length ? (
            // {viewPageType.includes('jptg380015') && album.endCover.page && !album.endCover.albums.length ? (
              <LayoutListItem
                menuParam={menuParam}
                layoutItem={{
                  // pageNum: 1,
                  // pageNum: (1 + (album.page.page * 2) + 1 < 2 + (album.page.albums.length * 2)) ? 2 + (album.page.albums.length * 2) : 1 + (album.page.page * 2) + 1,
                  pageNum: (() => {
                    const topCount = album.top.page * album.top.pageCount;
                    const isOverPage = album.page.albums.length > album.page.page;
                    const over = (album.page.albums.length * album.page.pageCount) + topCount + 1;
                    const notOver = (album.page.page * album.page.pageCount) + topCount + 1;
                    return isOverPage ? over : notOver;
                  })(),

                  imageNum: 0,
                  pageType: '頁-END',
                  thumbnail: '',
                }}
                isFullScreen={isFullWidth}
                isEmpty={true}
                onDragItem={item => setDraggingItem(item)}
                onDropPosItem={item => setDraggingItem(undefined)}
                resizeRate={thumbSizeRate}
                dummyImage={{ label: '頁-END', pageCount: (album.endCover.page - album.endCover.albums.length) }}
              />
            ) : (<></>)}
            {album.option.map((v, i) => {
              if (!v.album) {
                return (
                  <LayoutListItem
                  menuParam={menuParam}
                  key={`layout-list-item-end_empty_option_dummy_${v.xml.xmlUniqueName}`}
                  layoutItem={{
                    // pageNum: 1,
                    // pageNum: (1 + (album.page.page * 2) + 1 < 2 + (album.page.albums.length * 2)) ? 2 + (album.page.albums.length * 2) : 1 + (album.page.page * 2) + 1,
                    pageNum: v.xml.viewModel.displayPageNo || '',
                    imageNum: 0,
                    pageType: v.xml.viewModel.displayPageType || '',
                    thumbnail: '',
                  }}
                  isFullScreen={isFullWidth}
                  isEmpty={true}
                  onDragItem={item => setDraggingItem(item)}
                  onDropPosItem={item => setDraggingItem(undefined)}
                  resizeRate={thumbSizeRate}
                  dummyImage={{ label: v.xml.viewModel.displayPageType || '', pageCount: 1 }}
                />
                )
              } else {
                const _album = v.album;
                return (
                  <React.Fragment key={`layout-list-item-page_${i}`}>
                  {hoverItem?.album?.id === _album.id && draggingItem && draggingItem.pageNum > hoverItem.pageNum && displayType !== 'list' ?
                    <LayoutListItem
                      menuParam={menuParam}
                      resizeRate={thumbSizeRate}
                    /> : <></>}
                  <LayoutListItem
                    menuParam={menuParam}
                    // key={`layout-list-item-page_${i}`}
                    layoutItem={{
                      // pageNum: album.page.page > 1 ? `${2 + (i * 2)}-${3 + (i * 2)}` : String(i + 1),
                      pageNum: _album.pageNo,
                      imageNum: optionPhotoList.find((v) => v.album.id === _album.id)?.photoCount ?? 0,
                      // pageType: pageTypeData.find((vv) => v.type === vv.name)?.displayName || v.type,
                      pageType: _album.displayName,
                      thumbnail: previewList.option.find((preview) => preview.album.id === _album.id)?.url ?? _album.thumbnail.image,
                      // thumbnail: pageTmpUrl.find((p) => p.album.id === v.id)?.url || v.thumbnail.image,
                      album: _album,
                    }}
                    onClickDelete={() => onClickDeleteAlbum(_album)}
                    isFullScreen={isFullWidth}
                    onDragItem={(item) => setDraggingItem(item)}
                    onDropPosItem={(item) => setDraggingItem(undefined)}
                    onHoverItem={(item) => handlerHoverItem(item)}
                    resizeRate={thumbSizeRate}
                  />
                  {hoverItem?.album?.id === _album.id && draggingItem && draggingItem.pageNum < hoverItem.pageNum && displayType !== 'list' ?
                    <LayoutListItem
                      menuParam={menuParam}
                      resizeRate={thumbSizeRate}
                    /> : <></>}
                </React.Fragment>
                )
              }
            })}
          </div>
        </>
      ) : (<></>)}
    </div>
  );
};

const ErrorToast = (props: ErrorToastProps) => {
  const { text, className } = props;
  return (
    <>
      <div
        className={`error_toast ${className || ''}`}
        style={{padding: '5px', paddingRight: '10px'}}
      >
        <div
          className="error_toast__icon"
          style={{width:15, height:15, minWidth:15}}
        >
          <i
            className="fas fa-exclamation"
            style={{transform: 'translateX(0)', fontSize:12}}
          />
        </div>
        <div
          className="error_toast__txt"
          style={{fontSize: '8px', lineHeight: '15px'}}
        >
          {text.map((v, i) => (
            <div key={`error-toast-text-row_${i}`}>{v}</div>
          ))}
        </div>
      </div>
    </>
  );
};
