import './shop_list.scss';
import { Header } from '../../ui/header/header';
import { Table } from '../../ui/table/table';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button } from '../../ui/button/button';
import { Input } from '../../ui/input/input';
import { useDispatch } from 'react-redux';
import { dialogActions } from '../../dialog/slice/dialog-slice';
import { toHumanReadableByte } from '../../../utilities/tu-human-readable';
import iconInfo from '../../../assets/img/icon/info_black_24dp.svg';
import { ShopDetailDialog } from '../../dialog/unique/shop-detail-dialog';
import { apiActions } from '../../../slices/api-slice';
import { ApiGetCloudFolderShopList } from '../../../api/front/cloud-folder/api-get-cloud-folder-shop-list';
import { ResponseBase } from '../../../api/response-base';
import { useParams } from 'react-router-dom';
import { PathParams } from '../../../routes/routing-path';
import { useAppSelector } from '../../../app/hooks';
import * as lodash from 'lodash';
import { commonActions } from '../../../slices/common-slice';
import { localStorageActions } from '../../../slices/local-storage-slice';
import { CSVOutputFilter } from '../../dialog/unique/csv-output-filter';

export type Shop = {
  kijshopCd: string,                // 店舗番号
  shopName: string,                 // 店舗名
  mailaddress: string,              // メールアドレス
  staffLoginValidFlg: number,       // 店舗スタッフログイン有効フラグ
  orderExpiredDay: number,          // 保存期間
  totalSize: number,                // 使用サイズ(byte)
  downloadNum: number,              // ダウンロード回数
  downloadImageNum: number,         // ダウンロード画像枚数
  downloadSizeSum: number,          // ダウンロード容量合計サイズ
  monthDownloadSizeSum: number,     // 月間ダウンロード容量合計サイズ
  monthDownloadImageNum: number,    // 月間ダウンロード画像数
  monthDownloadNum: number,         // 月間ダウンロード回数
  uploadNum: number,                // アップロード回数
  uploadSizeSum: number,            // アップロード容量合計サイズ
  uploadImageNum: number,           // アップロード画像枚数
  monthUploadSizeSum: number,       // 月間アップロード容量合計サイズ
  monthUploadImageNum: number,      // 月間アップロード画像数
  monthUploadNum: number,           // 月間アップロード数
  orderNum: number,                 // 注文数
  staffNum: number,                 // スタッフ数
  createdAt: string,                // 作成日
  updatedAt: string,                // 更新日
}

const tableHeadData = [
  {
    key: 'kijshopCd',
    label: '得意先CD',
  },
  {
    key: 'shopName',
    label: '得意先名',
  },

  {
    key: 'staffNum',
    label: 'スタッフ数',
  },
  {
    key: 'orderNum',
    label: '注文数',
  },
  {
    key: 'orderExpiredDay',
    label: 'DL可能期間',
  },
  {
    key: 'totalSize',
    label: 'UPサイズ(現在)',
  },
  {
    key: 'monthDownloadNum',
    label: '月間DL回数',
  },
  {
    key: 'monthDownloadImageNum',
    label: '月間DL枚数',
  },
  {
    key: 'monthDownloadSizeSum',
    label: '月間DLサイズ',
  },
  {
    key: 'monthUploadNum',
    label: '月間UP回数',
  },
  {
    key: 'uploadImageNum',
    label: '月間UP枚数',
  },
  {
    key: 'monthUploadSizeSum',
    label: '月間UPサイズ',
  },
  {
    key: 'downloadNum',
    label: '合計DL回数',
  },
  {
    key: 'downloadImageNum',
    label: '合計DL枚数',
  },
  {
    key: 'downloadSizeSum',
    label: '合計DLサイズ',
  },
  {
    key: 'uploadNum',
    label: '合計UP回数',
  },
  {
    key: 'uploadImageNum',
    label: '合計UP枚数',
  },
  {
    key: 'uploadSizeSum',
    label: '合計UPサイズ',
  },
  {
    key: '',
    label: '',
  },
];
const TableHead = (props: { label: string, sort?: 'asc' | 'desc' }) => {
  const { label, sort } = props;
  return (
    <div>
      {label}
      {sort ? (
        <>
          {sort === 'asc' ? (' ▲') : ('')}
          {sort === 'desc' ? (' ▼') : ('')}
        </>
      ) : (<></>)}
    </div>
  );
};

export const ShopListPage = () => {
  const { kijshopCd } = useParams<PathParams>();
  const { master, storage } = useAppSelector((state) => ({
    master: state.common.data.masterShop,
    storage: state.storage,
  }), lodash.isEqual);

  const dispatch = useDispatch();

  const [list, setList] = useState<Shop[]>([]);
  const [displayList, setDisplayList] = useState<Shop[]>([]);
  // -- フィルター --
  const [search, setSearch] = useState('');
  // -- ソート --
  const [sort, setSort] = useState<'asc' | 'desc'>('desc');
  // -- ソート対象 --
  const [sortTarget, setSortTarget] = useState('kijshopCd');

  const tableHead = [...tableHeadData].map((v) => (
    <TableHead
      label={v.label}
      sort={(v.key && sortTarget === v.key) ? sort : undefined}
    />
  ));

  const getList = useCallback(() => {
    dispatch(apiActions.run(
      new ApiGetCloudFolderShopList({}),
      {
        onSuccess: (res: ResponseBase<Shop[]>) => {
          if (res.body.data) {
            setList(res.body.data);
            setDisplayList(res.body.data);
          }
        },
      },
    ));
  }, []);

  const handlerClickTableColumn = useCallback((index) => {
    const data = tableHeadData[index];
    if (data?.key) {
      if (data.key === sortTarget) {
        setSort(sort === 'asc' ? 'desc' : 'asc');
      } else {
        setSort('desc');
        setSortTarget(data.key);
      }
    }
  }, [sort, sortTarget]);

  const handlerClickSearch = useCallback(() => {
    const newList = list.filter((v) => {
      return v.kijshopCd.match(search.replaceAll(/[.*+?^${}()|[\]\\]/g, '\\$&'))
        || v.shopName.match(search.replaceAll(/[.*+?^${}()|[\]\\]/g, '\\$&'));
    });
    setDisplayList(newList);
  }, [list, search]);

  const handlerClickDetail = useCallback((_kijshopCd: string) => {
    dispatch(dialogActions.push({
      title: '詳細',
      element: <ShopDetailDialog
        kijshopCd={_kijshopCd}
        getList={getList}
      />,
    }));
  }, [dispatch]);

  const handlerClickCsvOutput = useCallback(() => {
    dispatch(dialogActions.push({
      title: 'CSV出力',
      element: <CSVOutputFilter search={search} />,
    }));
    return;
  }, [search]);

  const tableBody = useMemo(() => {
    return (displayList as Shop[]).sort((a: any, b: any) => {
        return sort === 'asc' ? a[sortTarget] < b[sortTarget] ? -1 : 1 : a[sortTarget] > b[sortTarget] ? -1 : 1;
      },
    ).map((shop, i) => ([
      shop.kijshopCd,
      shop.shopName,
      shop.staffNum + '人',
      shop.orderNum,
      shop.orderExpiredDay + '日',
      toHumanReadableByte(shop.totalSize),
      shop.monthDownloadNum,
      shop.monthDownloadImageNum,
      toHumanReadableByte(shop.monthDownloadSizeSum),
      shop.monthUploadNum,
      shop.monthUploadImageNum,
      toHumanReadableByte(shop.monthUploadSizeSum),
      shop.downloadNum,
      shop.downloadImageNum,
      toHumanReadableByte(shop.downloadSizeSum),
      shop.uploadNum,
      shop.uploadImageNum,
      toHumanReadableByte(shop.uploadSizeSum),
      <div style={i % 2 === 0 ? {backgroundColor: '#e0e0e0'}: {backgroundColor: 'white'}}>
      <Button
        label="詳細"
        icon={<img
          src={iconInfo}
          alt=""
        />}
        size="sm"
        color="light"
        onClick={() => handlerClickDetail(shop.kijshopCd)}
      />
      </div>,
    ]));
  }, [displayList, handlerClickDetail, sort, sortTarget]);

  useEffect(() => {
    getList();
  }, []);

  useEffect(() => {
    if (!master) {
      dispatch(commonActions.getData(kijshopCd));
    }
    dispatch(commonActions.checkBrowser());
    dispatch(commonActions.checkStaffManagementAuth(kijshopCd));
    if (storage.kijshopCd !== kijshopCd || storage.kijshopCd === '') {
      dispatch(localStorageActions.getLocalData(kijshopCd));
    }
  }, []);

  return (
    <>
      <div className="page_base">
        <Header page={''} />
        <div className="list_page_contents">
          <div className="shop_list_table">
            <div className="shop_list_table__menu_btn">
              <Button
                label="CSV出力"
                onClick={handlerClickCsvOutput}
                icon={<i className="fas fa-save" />}
                color="warning"
              />
            </div>
            <div className="shop_list_table__menu_btn">
              <Input
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
              <Button
                label={'検索'}
                onClick={handlerClickSearch}
              />
            </div>
            <div className={'shop_list_table__table'}>
              <Table
                head={tableHead}
                body={tableBody}
                minimumNumOfRows={10}
                handlerClickColumn={handlerClickTableColumn}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
