import WebFont from 'webfontloader';

/**
 *
 * ## WebFontLoader でできること
 * - 任意のタイミングでウェブフォントをダウンロードできる
 * - ウェブフォントのダウンロード完了・失敗等を検知できる
 * - 読み込んだウェブフォントはシステムフォントのように使用できる
 *   - DOM/SVG/Canvas どこでも使える
 *
 * ## 使い方
 *
 * ### 1. npm モジュールのインストール
 * - `$ npm i webfontloader`
 * - `$ npm i -D @types/webfontloader`
 *
 * ### 2. サーバへのフォントの設置
 * - CORS 検証等を行っていないので index.html と同じ場所推奨
 *
 * ### 3. フォント読み込み用 css の作成
 * 1. fonts.css (ファイル名は任意) を作成
 * 2. fonts.css 内に以下を記述する
 * 3. fonts.css をサーバへ設置 (index.html と同じ場所推奨)
 * 4. url('○○○') の中にはサーバに設置したフォントのURLを記載する (fonts.css からの相対パスも可能)
 * ```
 *   @font-face {font-family: 'A-OTF ゴシック MB101 Pr5';src: url('A-OTF-GothicMB101Pr5-Reg.otf');}
 *   @font-face {font-family: 'A-OTF A1 明朝 Std';src: url('A-OTF-A1MinchoStd-Bold.otf');}
 * ```
 *
 * ### 4. WebFontLoader でフォントを読み込み
 *
 */

type Param = {
};

export class WebFontLoader {

  constructor(param: Param) {
  }

  load(param: {
    familyName: string,
    css: string,
    onLoad?: () => void,
    onError?: () => void,
    timeout?: number,
    retry?: number,
  }) {
    const defaultParam = {
      onLoad: () => {
      },
      onError: () => {
      },
      retry: 0,
    };
    const { familyName, css, onLoad, onError, timeout, retry } = { ...defaultParam, ...param };
    WebFont.load({
      timeout,
      custom: {
        families: [
          familyName,
        ],
        urls: [
          css,
        ],
      },
      active: () => {
        onLoad();
      },
      fontloading: (familyName: string, fvd: string) => {
        // console.log('loading ... : ', familyName);
      },
      fontactive: (familyName: string, fvd: string) => {
        // console.log('active : ', familyName);
      },
      fontinactive: (familyName: string, fvd: string) => {
        if (retry) {
          console.log('[WebFontLoader] retry');
          this.load({ ...param, retry: retry - 1 });
          return;
        }
        onError();
      },
    });
  }

  loadAsync(param: { familyName: string, css: string }) {
    const { familyName, css } = param;
    return new Promise<void>((resolve, reject) => {
      this.load({
        familyName,
        css,
        onLoad: () => resolve(),
        onError: () => reject(),
      });
    });
  }

}

