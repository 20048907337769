import { ApiBaseFront } from '../api-base-front';
import {
  DescriptionInfoGetRequest,
  DescriptionInfoGetResponse,
} from '../../../models/api/front/description-info';
import { AppThunk } from '../../../app/store';
import { ordersDataActions } from '../../../slices/orders/orders-data-slice';
import { apiActions } from '../../../slices/api-slice';
import { ResponseBase } from '../../response-base';
import { IMasterProductItemInfo } from '../../../models/i-master-product-item-info';
import { ApiMasterXml } from '../../back/xml/api-master-xml';
import { XmlParser } from '../../../manager/xml-parser';

// 商品記載情報取得
export class ApiDescriptionInfoGet extends ApiBaseFront<DescriptionInfoGetResponse[]> {
  constructor(param: { id: string, kijshopCd: string }) {
    super(
      'GET',
      '/api/v1/iteminfo',
      'JSON',
      param,
    );
  }
}

const descriptionInfoGetFromGoods = (goodsId: string, masterProductItemInfo: IMasterProductItemInfo): DescriptionInfoGetResponse[] => {
  const arr: DescriptionInfoGetResponse[] = [];
  masterProductItemInfo.lnwMasterProductItemInfo.productItemInfoInput?.forEach((goodsData) => {
    goodsData.productItemInfoInputData?.forEach((data) => {
      if (data.data?.find((v) => v.$.goodsID === goodsId)) {
        arr.push({ type: data.$.type || '', option: data.$.option || '' });
      }
    });
  });
  return arr;
};

const descriptionInfoGetFromCategory = (categoryId: string, masterProductItemInfo: IMasterProductItemInfo): DescriptionInfoGetResponse[] => {
  const arr: DescriptionInfoGetResponse[] = [];
  masterProductItemInfo?.lnwMasterProductItemInfo?.productItemInfoInputCategory?.forEach((categoryData) => {
    categoryData.productItemInfoInputCategoryData?.forEach((data) => {
      if (data.data?.find((v) => v.$.categoryID === categoryId)) {
        arr.push({ type: data.$.type || '', option: data.$.option || '' });
      }
    });
  });
  return arr;
};

// 別途実行することが無いのでまとめる
export const apiDescriptionInfoThunk = (param: DescriptionInfoGetRequest, _masterProductItemInfo: IMasterProductItemInfo | null, callback?: () => void): AppThunk => async (dispatch) => {
  const { serviceId, productNameId, kijshopCd } = param;
  if (!serviceId || !productNameId) {
    dispatch(ordersDataActions.updateDescriptionInfo({
      descriptionInfo: [],
    }));
    callback?.();
    return;
  }
  const masterProductItemInfo = _masterProductItemInfo ? _masterProductItemInfo : await new ApiMasterXml('master-product-item-info')
    .do()
    .then(async (res) => await new XmlParser().parse<IMasterProductItemInfo>((res as ResponseBase<any>)?.body?.data).then((res) => res))
  // const descriptionInfoData = [...descriptionInfoGetFromGoods(productNameId, masterProductItemInfo), ...descriptionInfoGetFromCategory(serviceId, masterProductItemInfo)];
  const descriptionInfo: DescriptionInfoGetResponse[] = [];
  // descriptionInfoData.forEach((v) => {
  descriptionInfoGetFromGoods(productNameId, masterProductItemInfo).forEach((v) => {
    if (!descriptionInfo.find((data) => data.type === v.type)) {
      descriptionInfo.push(v);
    }
  });
  if (!descriptionInfo.length) {
    descriptionInfoGetFromCategory(serviceId, masterProductItemInfo).forEach((v) => {
      if (!descriptionInfo.find((data) => data.type === v.type)) {
        descriptionInfo.push(v);
      }
    });
  }
  dispatch(ordersDataActions.updateDescriptionInfo({
    descriptionInfo,
  }));
  callback?.();
};
