import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ProductDetailGetResponse} from "../models/api/front/product-detail";
import { AppThunk } from '../app/store';
import { ImageCheckPostResponse } from '../models/api/front/image-check';
import { MetaShopOrderGetResponse } from '../api/front/meta/api-meta-shop-order';
import { ImagesGetResponse } from '../api/front/images/api-images';
import { Orientation } from 'get-orientation/browser';

type ProductDetailWithImages = (ProductDetailGetResponse & { imagesCheck?: ImageCheckPostResponse[] });

export type ErrorOrder = { createDate: string, lastName: string, shopOrderId: string, status: string, errorStatus: { code: string, message: string[] }, cnt?: number };

type OrderPreparationSliceState = {
  currentOrder: ProductDetailWithImages | null,
  orderList: ProductDetailWithImages[],
  orderData: MetaShopOrderGetResponse | null,
  errorOrderList: ErrorOrder[],
  selectedOrderId: string,
  selectedImageList: string[],
  dragItem: {
    selectIndex: number,
    targetIndex: number,
  },
  kijshopCd: string,
  thumbImages: { orderId: string, images: { name: string, path: string }[] }[],
  /* お客様名更新確認用 */
  updatingCustomerName: boolean,
  registeringCustomerName: boolean,
  /* 発注帳票更新用 */
  updatingDesignation: boolean,
  /* パフォーマンス改善用 */
  selectedOrderIdArr: string[],
  selectedOrderIndexArr: number[],
  compImageList: ImagesGetResponse[],
  reset: boolean,
  /* 完成画像アップロード後の注文編集対策 */
  imageLessOrder: string[],
  /* 画像の回転方向と selectId を紐付け。サムネ画像で生成するので画像からではなくデータで判定 */
  orientationList: { selectId: string, orientation?: Orientation }[],
  isLaboOrderRetry: boolean,
};

const initialState: OrderPreparationSliceState = {
  currentOrder: null,
  orderList: [],
  orderData: null,
  errorOrderList: [],
  selectedOrderId: '',
  selectedImageList:[],
  dragItem: {
    selectIndex: -1,
    targetIndex: -1,
  },
  kijshopCd: '',
  thumbImages: [],
  updatingCustomerName: false,
  registeringCustomerName: false,
  updatingDesignation: false,
  selectedOrderIdArr: [],
  selectedOrderIndexArr: [],
  compImageList: [],
  reset: false,
  imageLessOrder: [],
  orientationList: [],
  isLaboOrderRetry: false,
};

const orderPreparationSlice = createSlice({
  name: 'order-preparation',
  initialState,
  reducers: {
    setRemakeOrderList: (state, action: PayloadAction<ProductDetailWithImages[]>) => {
      state.orderList = action.payload;
    },
    setOrder: (state, action: PayloadAction<ProductDetailWithImages | null>) => {
      state.currentOrder = action.payload;
    },
    setOrderData: (state, action: PayloadAction<MetaShopOrderGetResponse | null>) => {
      state.orderData = action.payload;
    },
    resetOrderData: (state) => {
      state.orderList = [];
    },
    setSelectedImageList: (state, action: PayloadAction<{ selectID: string[], orderId: string }>) => {
      state.selectedOrderId = action.payload.orderId;
      state.selectedImageList = [];
      state.selectedImageList = action.payload.selectID;
    },
    resetSelectedImageList: (state) => {
      state.selectedImageList = [];
    },
    setDragItem: (state, action: PayloadAction<Partial<{ selectIndex: number, targetIndex: number }>>) => {
      state.dragItem = Object.assign(state.dragItem, action.payload);
    },
    setErrorOrderList: (state, action: PayloadAction<ErrorOrder[]>) => {
      state.errorOrderList = action.payload;
    },
    resetErrorOrderList: (state) => {
      state.errorOrderList = [];
    },
    setKijshopCd: (state, action: PayloadAction<string>) => {
      state.kijshopCd = action.payload;
    },
    setCompImage: (state, action: PayloadAction<{ orderId: string, images: { name: string, path: string }[] }>) => {
      const index = state.thumbImages.findIndex((v) => v.orderId === action.payload.orderId);
      if (index === -1) {
        state.thumbImages.push(action.payload);
      } else {
        state.thumbImages[index] = action.payload;
      }
    },
    resetCompImages: (state) => {
      state.thumbImages = [];
    },
    setUpdatingCustomerName: (state, action: PayloadAction<boolean>) => {
      state.updatingCustomerName = action.payload;
    },
    setRegisteringCustomerName: (state, action: PayloadAction<boolean>) => {
      state.registeringCustomerName = action.payload;
    },
    setUpdatingDesignation: (state, action: PayloadAction<boolean>) => {
      state.updatingDesignation = action.payload;
    },
    /* パフォーマンス改善用 */
    setSelectedOrderId: (state, action: PayloadAction<{ id: string, index: number }>) => {
      const index = state.selectedOrderIdArr.findIndex((v) => v === action.payload.id);
      if (index === -1) {
        state.selectedOrderIdArr.push(action.payload.id);
        state.selectedOrderIndexArr.push(action.payload.index);
      } else {
        state.selectedOrderIdArr.splice(index, 1);
        state.selectedOrderIndexArr.splice(index, 1);
      }
    },
    setCompImageList: (state, action: PayloadAction<ImagesGetResponse[]>) => {
      state.compImageList = action.payload;
    },
    setImageLessOrder: (state, action: PayloadAction<string | undefined>) => {
      if (action.payload && !state.imageLessOrder.find((v) => v === action.payload)) {
        state.imageLessOrder.push(action.payload);
      }
    },
    clearImageLessOrder: (state, action: PayloadAction<string>) => {
      const index = state.imageLessOrder.findIndex((v) => v === action.payload);
      if (index !== -1) {
        state.imageLessOrder.splice(index, 1);
      }
    },
    setOrientationList: (state, action: PayloadAction<{ selectId: string, orientation: Orientation }>) => {
      if (state.orientationList.find((v) => v.selectId === action.payload.selectId)) {
        return;
      }
      state.orientationList.push(action.payload);
    },
    resetSelectedOrderId: (state) => {
      state.selectedOrderIdArr = [];
      state.selectedOrderIndexArr = [];
      state.compImageList = [];
      state.reset = !state.reset;
      state.imageLessOrder = [];
      state.orientationList = [];
    },
    setLaboOrderRetry: (state, action: PayloadAction<boolean>) => {
      state.isLaboOrderRetry = action.payload
    }
  },
});

const setOrderList = (param: ProductDetailGetResponse[], kijshopCd: string): AppThunk => async (dispatch) => {
  const arr = [...param];
  // await new Promise<void>((resolve) => {
  //   let cnt: number = 0;
  //   arr.forEach((v) => {
  //     if (v.orderMethod?.orderMethodId === '40') {
  //       cnt++;
  //       new ApiImageCheckPost({
  //         goodsId: v.products?.productNameId || '',
  //         page: Number(v.pageCount || 0),
  //         kijshopCd,
  //       })
  //         .do()
  //         .then((res: ResponseBase<ImageCheckPostResponse[]> | CustomError) => {
  //           if ((res as ResponseBase<ImageCheckPostResponse[]>)?.body?.data) {
  //             (v as any).imagesCheck = (res as ResponseBase<ImageCheckPostResponse[]>).body.data;
  //           }
  //           cnt--;
  //           if (!cnt) {
  //             resolve();
  //           }
  //         })
  //         .catch(resolve);
  //     }
  //   });
  //   if (!cnt) {
  //     resolve();
  //   }
  // });
  dispatch(orderPreparationActions.setRemakeOrderList(arr as ProductDetailWithImages[]));
};

export const orderPreparationActions = Object.assign(orderPreparationSlice.actions, { setOrderList });
export const orderPreparationReducer = orderPreparationSlice.reducer;
