import {
  WizardButton,
  WizardButtonList,
  WizardContent,
  WizardInput,
  WizardInputList,
} from '../../../ui/wizard/wizard-content';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { PathParams, RoutingPath } from '../../../../routes/routing-path';
import { useParams } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { apiActions } from '../../../../slices/api-slice';
import { ApiGetShopInfo, ApiPostShopMailAddressChangeSendMail } from '../../../../api/front/wizard/api-staff-management';
import { dialogActions } from '../../../dialog/slice/dialog-slice';
import { ResponseBase } from '../../../../api/response-base';
import { ShopInfoResponse } from '../../../../models/api/front/staff-management';

type Props = {
  setStep: (step: number) => void;
  setStep3DisplayMail: (mail: string) => void;
};
type TooltipType = 'newMailAddress' | 'none';
export const Step2StaffMngMailChangeWizard = (props: Props) => {
  const { setStep,setStep3DisplayMail } = props;
  // - Hooks -
  const dispatch = useDispatch();
  const { kijshopCd } = useParams<PathParams>();
  // - State -
  const [mailAddress, setMailAddress] = useState('');
  const [newMailAddress, setNewMailAddress] = useState('');
  const [isNewPasswordError, setIsNewPasswordError] = useState(false);
  const [showTooltip, setShowTooltip] = useState<TooltipType>('none');
  // - Effect -
  useEffect(() => {
    dispatch(apiActions.run(
      new ApiGetShopInfo(kijshopCd),
      {
        onSuccess: (res: ResponseBase<ShopInfoResponse>) => {
          setMailAddress(res.body.data?.mailaddress || '');
        },
        onError: () => {
          dispatch(dialogActions.pushMessage({
            title: 'エラー',
            message: ['店舗情報の取得に失敗しました。', 'システム設定画面に戻ります。'],
            buttons: [
              {
                label: 'OK',
                callback: () => {
                  dispatch(dialogActions.pop());
                  dispatch(push(RoutingPath.cnv.configSystem({ kijshopCd })));
                },
              },
            ],
          }));
        },
      },
    ));
  }, []);
  // - Handlers -
  const handlerValidation = useCallback(() => {
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const isEmailValid = newMailAddress !== '' && regex.test(newMailAddress);
    setIsNewPasswordError(!isEmailValid);
    return isEmailValid;
  },[newMailAddress]);
  const handlerChangeNewMailAddress = useCallback((value: string) => {
    setNewMailAddress(value);
  }, [newMailAddress]);
  const handlerToggleShowTooltip = useCallback((type: TooltipType) => {
    setShowTooltip(type);
  }, []);
  // -- キャンセルボタン --
  const handlerClickCancel = useCallback(() => {
    dispatch(push(RoutingPath.cnv.configSystem({ kijshopCd })));
  }, []);
  // -- メールを送信ボタン --
  const handlerClickSendMail = useCallback(() => {
    if (!handlerValidation()) {
      dispatch(dialogActions.pushMessage({
        title: '確認',
        message: ['入力内容に不備があります。'],
        buttons: [
          {
            label: 'OK',
            callback: () => {
              dispatch(dialogActions.pop());
            },
          },
        ],
      }));
      return;
    }
    dispatch(apiActions.run(
      new ApiPostShopMailAddressChangeSendMail({
        kijshopCd,
        old_mailaddress: mailAddress,
        mailaddress: newMailAddress,
      })
      , {
        onSuccess: () => {
          setStep3DisplayMail(newMailAddress);
          setStep(3);
        },
        onBasicError: (error) => {
          dispatch(dialogActions.pushMessage({
            title: '確認',
            message: error.messages,
            buttons: [
              {
                label: 'OK',
                callback: () => {
                  dispatch(dialogActions.pop());
                },
              },
            ],
          }));
        },
      }
    ))
  },[newMailAddress]);
  return (
    <WizardContent>
      <WizardInputList>
        <WizardInput
          label="スタッフ管理メールアドレス"
          value={mailAddress}
          disabled={true}
          isPassword={false}
          maxLength={100}
        />
        <WizardInput
          label="新しいスタッフ管理メールアドレス "
          value={newMailAddress}
          disabled={false}
          isPassword={false}
          maxLength={100}
          error={isNewPasswordError}
          tooltip={isNewPasswordError && showTooltip === 'newMailAddress' ?
            { messages: ['メールアドレスの形式が正しくありません'] } : undefined}
          onChange={(e) => handlerChangeNewMailAddress(e.target.value)}
          onMouseOver={() => handlerToggleShowTooltip('newMailAddress')}
          onMouseLeave={() => handlerToggleShowTooltip('none')}
        />
      </WizardInputList>
      <WizardButtonList>
        <WizardButton
          label="キャンセル"
          onClick={handlerClickCancel}
        />
        <WizardButton
          label="メールを送信"
          onClick={handlerClickSendMail}
        />
      </WizardButtonList>
    </WizardContent>
  );
};