import { DisplayObject } from 'createjs-module';
import { CjBitmap, CjContainer, CjShape, CjStage, CjText } from '../layout-editor/model/cj-factory';
import { CjCorner } from '../layout-editor/model/cj-factory/cj-corner';
import { Pos } from '../models/pos';
import {
  CJ_CONTAINER_NAME,
  CJ_DPOBJ_NAME, CJ_FOCUS_NAME,
} from '../layout-editor/model/cj-factory/cj-obj-name.collection';
import { ImageEditManager } from '../layout-editor/manager/image-edit/image-edit.manager';

type OriginPosType = 'photo' | 'text' | 'png'

export class CjTool {
  /**
   * getBoundsなどすぐに発火させるとnullになるのを止める
   * @param target 確認するobj
   * @response target type
   */
  static checkLoad<T extends DisplayObject>(target: T): Promise<T> {
    return new Promise((resolve, reject) => {
      const timerId = setInterval(() => {
        if (target.getBounds()) {
          clearInterval(timerId);
          resolve(target);
        }
      }, 100);
    });
  }

  /**
   * 写真が既にあるか判定
   * @param container
   */
  static checkPhotoInFrame(container: CjContainer): Boolean {
    return !!CjTool.getImageContainer(container);
  }

  /**
   * アウトラインが既にあるか判定
   * @param container
   */
  static checkOutline(container: CjContainer): Boolean {
    return !!CjTool.getOutline(container);
  }

  /**
   * 再起的にコンテナ内のIDを更新する処理
   * @param container
   */
  static updateClonePram(container: CjContainer) {
    if (!container.children || !container.children.length) return;
    for (const _elm of container.children) {
      const elm = _elm as CjContainer;
      elm.photoAreaId = container.photoAreaId;
      this.updateClonePram(elm);
    }
  }

  /**
   * 閾値の挙動が不安なため使用する三角関数のメソッドは別途定義
   * sin, cos で結果の期待値が 0 の時に発散してそう
   * ついでに角度算出用のメソッドも用意
   * */
  static math = {
    sin: (rad: number) => {
      return (Math.sin(rad - 0.000000000000001) + Math.sin(rad + 0.000000000000001)) / 2;
    },
    cos: (rad: number) => {
      return (Math.cos(rad - 0.000000000000001) + Math.cos(rad + 0.000000000000001)) / 2;
    },
    /** 矩形サイズに応じて中心からみた左上の角度を弧度法で返す */
    rotateOrigin: (width: number, height: number) => {
      /* 回転によって原点がどこに移動したかを算出 */
      /* 写真枠の中心からみた原点座標のラジアン */
      const radOrigin = Math.atan2(height, width);
      /* 写真枠の中心から見た原点座標の角度 */
      /* y=0 が始点になるため -90 して x=0 を始点とする */
      /*
       * radOrigin = 2 * Math.PI * (rotateOrigin - 90) / 360
       * rotateOrigin = (radOrigin * 360 / 2 / Math.PI) + 90
       */
      return 180 - ((radOrigin * 360 / 2 / Math.PI) + 90);
    },
  };
  /**
   * 回転によって移動した座標を算出する最小構成
   * 他の処理で使えるけど日和って一旦そのままに
   *
   * @param data.width { number } 横幅
   * @param data.height { number } 縦幅
   * @param data.rotate { number } 回転角
   *
   * @return {{ x: number, y: number }} 回転によって移動した原点座標
   * */
  static getMoveOrigin(data: { width: number, height: number, rotate: number }) {
    const { width, height, rotate } = data;
    /* 回転によって原点がどこに移動したかを算出 */
    const rotateOrigin = this.math.rotateOrigin(width, height);
    /* 回転によって移動する原点座標のラジアン */
    /* 移動は中心を元にするので中心から見た原点の回転角を引いておく */
    const rad = 2 * Math.PI * (((rotate - rotateOrigin) - 90) / 360);
    /* 回転による円移動の半径 */
    const r = Math.sqrt(Math.pow(width / 2, 2) + Math.pow(height / 2, 2));
    /* 中心からみた回転後の原点座標を返す */
    return { x: r * this.math.cos(rad) + width / 2, y: r * this.math.sin(rad) + height / 2 };
  }
  /**
   * @param data.origin { { x: number, y: number } } XML上の原点
   * @param data.size { { width: number, height: number } } サイズ
   * @param data.subMaskRect { { width: number, height: number } } subMaskRect の virtual
   * @param data.move { number } XML上の原点からの移動座標
   * @param data.rotate { number } 角度
   *
   * @return {{ x: number, y: number }} canvas上にある原点の座標
   * */
  static getImageOrigin(
    data: {
      origin: { x: number, y: number },
      size: { width: number, height: number },
      subMaskRect: { x: number, y: number, width: number, height: number },
      move?: { x: number, y: number },
      rotate: number,
      photoFrameRotate?: number,
    },
  ): { x: number, y: number } {
    const { origin, move, rotate = 0, size, subMaskRect, photoFrameRotate } = data;
    const { width, height } = size;
    /* 原点座標からどれだけ移動したかを算出 */
    /* 管理している座標は画像の向きと正対しての移動座標なので角度を考慮した水平方向の移動座標を出す */
    if (move) {
      if (photoFrameRotate) {
        const rad = ((photoFrameRotate % 90) / 180) * Math.PI;
        origin.x += move.x * this.math.cos(rad) - move.y * this.math.sin(rad);
        origin.y += move.x * this.math.sin(rad) + move.y * this.math.cos(rad);
      } else {
        origin.x += move.x;
        origin.y += move.y;
      }
    }
    const moveOrigin = this.getMoveOrigin({ width, height, rotate });
    /* 拡大率を持たないため subMaskRect のサイズを元に原点座標を考慮し回転後の座標を設定 */
    origin.x += moveOrigin.x - (width - subMaskRect.width) / 2 + subMaskRect.x;
    origin.y += moveOrigin.y - (height - subMaskRect.height) / 2 + subMaskRect.y;
    return origin;
  };
  static getPhotoContainerOrigin(data: {
    pictureRect?: { virtual?: { x?: string | number, y?: string | number, width?: string | number, height?: string | number }},
    subMaskRect?: { virtual?: { x?: string | number, y?: string | number, width?: string | number, height?: string | number }},
    rotate?: string | number,
    isCopy?: boolean,
  }): { x: number, y: number } {
    const { pictureRect, subMaskRect, rotate, isCopy } = data;
    const fanData = (data?: { virtual?: { width?: string | number, height?: string | number } }, _rotate?: string | number) => {
      const width = Number(data?.virtual?.width || 0);
      const height = Number(data?.virtual?.height || 0);
      const rotate = Number(_rotate || 0);
      const rotateOrigin = this.math.rotateOrigin(width, height);
      const rad = 2 * Math.PI * (((rotate - rotateOrigin) - 90) / 360);
      const r = Math.sqrt(Math.pow(width / 2, 2) + Math.pow(height / 2, 2));
      return { width, height, r, rad };
    };
    const frame = (() => {
      const { width, height, r, rad } = fanData(subMaskRect);
      return {
        x: r * this.math.cos(rad) + width / 2,
        y: r * this.math.sin(rad) + height / 2,
      };
    })();
    const photo = (() => {
      const { width, height, r, rad } = fanData(pictureRect);
      const subMaskData = {
        x: Number(subMaskRect?.virtual?.x || 0) - (isCopy ? 30 : 0),
        y: Number(subMaskRect?.virtual?.y || 0) - (isCopy ? 30 : 0),
        width: Number(subMaskRect?.virtual?.width || 0),
        height: Number(subMaskRect?.virtual?.height || 0),
      };
      return {
        x: -(r * Math.cos(rad) + (width - subMaskData.width) / 2 - subMaskData.x) - subMaskData.x,
        y: -(r * Math.sin(rad) + (height - subMaskData.height) / 2 - subMaskData.y) - subMaskData.y,
      };
    })();
    const photoDefault = (() => {
      const { width, height, r, rad } = fanData(pictureRect, rotate);
      const subMaskData = {
        x: Number(subMaskRect?.virtual?.x || 0) - (isCopy ? 30 : 0),
        y: Number(subMaskRect?.virtual?.y || 0) - (isCopy ? 30 : 0),
        width: Number(subMaskRect?.virtual?.width || 0),
        height: Number(subMaskRect?.virtual?.height || 0),
      };
      return {
        x: r * this.math.cos(rad) + width / 2 - (width - subMaskData.width) / 2 + subMaskData.x,
        y: r * this.math.sin(rad) + height / 2 - (height - subMaskData.height) / 2 + subMaskData.y,
      };
    })();
    return {
      x: photo.x - frame.x - (photoDefault.x - Number(pictureRect?.virtual?.x || 0)),
      y: photo.y - frame.y - (photoDefault.y - Number(pictureRect?.virtual?.y || 0)),
    };
  }

  /**
   * ターゲットのテンプレート上での左上の絶対座標を取得する
   * @param target 対象のContainer
   * @param type 対象が画像かテキストか
   */
  static getOriginPos = (target: CjContainer, type: OriginPosType) => {
    // const corners = CjTool.getFocusContainer(target, CJ_FOCUS_NAME.rotate);
    // const corner = corners.children.find((v: any) => (v?.position ?? '') === 'lt');
    let point: createjs.Point;
    switch (type) {
      case 'photo':
        const isRotate = CjTool.getRotateContainer(target).isRotate;
        isRotate && (target.rotation -= 90);
        point = target.localToGlobal(0, 0);
        isRotate && (target.rotation += 90);
        break;
      case 'text':
        const text = CjTool.getText(target);
        point = target.localToGlobal(0, 0);
        break;
      case 'png':
        const targetPhoto = target.getChildByName(CJ_DPOBJ_NAME.freeGraphic) as CjBitmap
        point = targetPhoto.localToGlobal(0, 0);
        break;
    }
    return {
      x: point!.x - ImageEditManager.STAGE_CONTAINER_X,
      y: point!.y - ImageEditManager.STAGE_CONTAINER_Y,
    };
  };

  /**
   * フレームの座標を計算して返す
   * @param target 対象のContainer
   */
  static getFramePos(target: CjContainer): Pos {
    const imageArea = CjTool.getImageAreaContainer(target);
    const arr = imageArea.children;
    const pos = { x: 0, y: 0 };
    for (const v of arr) {
      if (v.name === CJ_CONTAINER_NAME.frame) {
        const frameContainer = v as CjContainer;
        if (frameContainer.defaultPos.x < 0) {
          pos.x += frameContainer.defaultPos.x;
        }
        if (frameContainer.defaultPos.y < 0) {
          pos.y += frameContainer.defaultPos.y;
        }
      }
    }
    return pos;
  }

  /**
   * 親のコンテナ(editorContainer)を再起的に探す
   * @param target 探索開始コンテナ
   */
  static getDefaultContainer(target: DisplayObject): CjContainer {
    if (target.name === CJ_CONTAINER_NAME.editor) {
      return target as CjContainer;
    } else {
      return this.getDefaultContainer(target.parent);
    }
  }

  /**
   * ScaleContainerの取得
   * @param parentContainer
   */
  static getScaleContainer(parentContainer: CjContainer): CjContainer {
    const defaultContainer = this.getDefaultContainer(parentContainer);
    return defaultContainer.getChildByName(CJ_CONTAINER_NAME.scale) as CjContainer;
  }

  /**
   * RotateContainerの取得
   * @param parentContainer
   */
  static getRotateContainer(parentContainer: CjContainer): CjContainer {
    const defaultContainer = this.getScaleContainer(parentContainer);
    return defaultContainer.getChildByName(CJ_CONTAINER_NAME.rotate) as CjContainer;
  }

  /**
   * ImageAreaContainerの取得
   * @param parentContainer
   */
  static getImageAreaContainer(parentContainer: CjContainer): CjContainer {
    const defaultContainer = this.getDefaultContainer(parentContainer) ?? parentContainer;
    const container = this.getRotateContainer(defaultContainer);
    return container.getChildByName(CJ_CONTAINER_NAME.imageArea) as CjContainer;
  }

  /**
   * dummyFrameの取得
   * @param parentContainer
   */
  static getDummyFrame(parentContainer: CjContainer): CjContainer {
    const defaultContainer = this.getDefaultContainer(parentContainer) ?? parentContainer;
    const container = this.getImageAreaContainer(defaultContainer);
    return container.getChildByName(CJ_CONTAINER_NAME.dummyFrame) as CjContainer;
  }

  /**
   * dummyFrameの取得
   * @param parentContainer
   */
  static getDummyImage(parentContainer: CjContainer): CjShape {
    const defaultContainer = this.getDefaultContainer(parentContainer) ?? parentContainer;
    const container = this.getDummyFrame(defaultContainer);
    return container.getChildByName(CJ_DPOBJ_NAME.dummyRect) as CjShape;
  }

  /**
   * dummyLinesの取得
   * @param parentContainer
   */
  static getDummyCorners(parentContainer: CjContainer): CjShape[] {
    const defaultContainer = this.getDefaultContainer(parentContainer) ?? parentContainer;
    const container = this.getDummyFrame(defaultContainer);
    const corners: CjShape[] = [];
    corners.push(container.getChildByName(CJ_DPOBJ_NAME.dummyCornerA) as CjShape);
    corners.push(container.getChildByName(CJ_DPOBJ_NAME.dummyCornerB) as CjShape);
    corners.push(container.getChildByName(CJ_DPOBJ_NAME.dummyCornerC) as CjShape);
    corners.push(container.getChildByName(CJ_DPOBJ_NAME.dummyCornerD) as CjShape);
    return corners;
  }

  /**
   * ImageContainerの取得
   * @param parentContainer
   */
  static getImageContainer(parentContainer: CjContainer): CjContainer {
    const defaultContainer = this.getDefaultContainer(parentContainer);
    const container = this.getRotateContainer(defaultContainer);
    return container.getChildByName(CJ_CONTAINER_NAME.image) as CjContainer;
  }

  /**
   * PhotoContainerの取得
   * @param parentContainer
   */
  static getPhotoContainer(parentContainer: CjContainer): CjContainer {
    const defaultContainer = this.getDefaultContainer(parentContainer);
    const container = this.getImageContainer(defaultContainer);
    if (container) {
      return container.getChildByName(CJ_CONTAINER_NAME.photo) as CjContainer;
    } else {
      return container;
    }
  }

  /**
   * ImageContainerの取得
   * @param parentContainer
   */
  static getPhoto(parentContainer: CjContainer): CjBitmap {
    const defaultContainer = this.getDefaultContainer(parentContainer);
    const container = this.getPhotoContainer(defaultContainer);
    return container.getChildByName(CJ_DPOBJ_NAME.image) as CjBitmap;
  }

  /**
   * MaskContainerの取得
   * @param parentContainer
   */
  static getMaskContainer(parentContainer: CjContainer): CjContainer {
    const defaultContainer = this.getDefaultContainer(parentContainer);
    const container = this.getImageAreaContainer(defaultContainer);
    return container.getChildByName(CJ_CONTAINER_NAME.mask) as CjContainer;
  }

  /**
   * Textの取得
   * @param container
   */
  static getText(container: CjContainer): CjText {
    return container.getChildByName(CJ_DPOBJ_NAME.text) as CjText;
  }

  /**
   * outlineの取得
   * @param container
   */
  static getOutline(container: CjContainer): CjText {
    return container.getChildByName(CJ_DPOBJ_NAME.outline) as CjText;
  }

  /**
   * FreeGraphicの取得
   * @param container
   */
  static getFreeGraphic(container: CjContainer): CjBitmap {
    return container.getChildByName(CJ_DPOBJ_NAME.freeGraphic) as CjBitmap;
  }

  /**
   * TextFocusの取得
   * @param textContainer
   */
  static getTextFocus(textContainer: CjContainer): CjContainer {
    return textContainer.getChildByName(CJ_CONTAINER_NAME.focus.text) as CjContainer;
  }

  /**
   * Highlightの取得
   * @param parentContainer
   */
  static getHighlight(parentContainer: CjContainer): CjShape {
    const defaultContainer = this.getDefaultContainer(parentContainer);
    const container = this.getImageAreaContainer(defaultContainer);
    return container.getChildByName(CJ_DPOBJ_NAME.highlight) as CjShape;
  }

  /**
   * Highlightの取得
   * @param parentContainer
   */
  static getGrayFilm(parentContainer: CjContainer): CjShape {
    const defaultContainer = this.getDefaultContainer(parentContainer);
    const container = this.getImageAreaContainer(defaultContainer);
    return container.getChildByName(CJ_DPOBJ_NAME.grayFilm) as CjShape;
  }

  /**
   * CenterLineの取得
   * @param parentContainer
   */
  static getCenterLine(parentContainer: CjContainer): CjContainer {
    const defaultContainer = this.getDefaultContainer(parentContainer);
    const container = this.getImageAreaContainer(defaultContainer);
    return container.getChildByName(CJ_CONTAINER_NAME.centerLine) as CjContainer;
  }

  /**
   * AdditionalContainerの取得
   * @param stage
   */
  static getAdditional(stage: CjContainer): CjContainer {
    return stage.getChildByName(CJ_CONTAINER_NAME.additional) as CjContainer;
  }

  /**
   * CheckerContainerの取得
   * @param parentContainer
   */
  static getChecker(parentContainer: CjContainer): CjShape {
    const defaultContainer = this.getDefaultContainer(parentContainer);
    const container = this.getPhotoContainer(defaultContainer);
    return container.getChildByName(CJ_DPOBJ_NAME.checker) as CjShape;
  }

  /**
   * FrameContainerの取得
   * @param parentContainer
   * @param type
   * @param isPng
   */
  static getFocusContainer(parentContainer: CjContainer, type: string, isPng?: boolean): CjContainer {
    if (isPng) {
      return parentContainer.getChildByName(CJ_CONTAINER_NAME.focus.rotate) as CjContainer;
    }
    const defaultContainer = this.getDefaultContainer(parentContainer);
    if (type === CJ_CONTAINER_NAME.focus.rotate) {
      const container = this.getPhotoContainer(defaultContainer);
      return container?.getChildByName(type) as CjContainer;
    } else {
      const container = this.getImageAreaContainer(defaultContainer);
      return container?.getChildByName(type) as CjContainer;
    }
  }

  /**
   * @param parentContainer
   * @param isPng
   */
  static getFocusContainers(parentContainer: CjContainer, isPng?: boolean): CjContainer[] {
    const values = Object.values(CJ_CONTAINER_NAME.focus);
    const result: CjContainer[] = [];
    for (const value of values) {
      const focusContainer = this.getFocusContainer(parentContainer, value, isPng);
      if (!focusContainer) continue;
      result.push(focusContainer);
    }
    return result;
  }

  /**
   * @param container
   * @param isPng
   */
  static getCorner(container: CjContainer, isPng?: boolean): CjCorner[] {
    const result: CjCorner[] = [];
    const focusContainers = !isPng ? this.getFocusContainers(container) : [this.getFocusContainer(container, 'rotate', isPng)];
    for (const focusContainer of focusContainers) {
      if (!focusContainer) continue;
      for (const obj of focusContainer.children) {
        if (obj.name === CJ_FOCUS_NAME.corner && obj instanceof CjCorner) result.push(obj);
      }
    }
    return result;
  }

  static getFocusLine(container: CjContainer, isPng?: boolean): CjShape[] {
    const result: createjs.Shape[] = [];
    const focusContainers = !isPng ? this.getFocusContainers(container) : [this.getFocusContainer(container, 'rotate', isPng)];
    for (const focusContainer of focusContainers) {
      if (!focusContainer) continue;
      for (const obj of focusContainer.children) {
        if (obj.name === CJ_FOCUS_NAME.line && obj instanceof CjShape) result.push(obj);
      }
    }
    return result;
  }

  static getGuidContainer(stage: CjContainer): CjContainer {
    const find = stage?.children?.find?.((v) => v.name === CJ_CONTAINER_NAME.guide);
    return find as CjContainer;
  }

  static getFrameContainer(container: CjContainer): CjContainer {
    const imageArea = this.getImageAreaContainer(container);
    const find = imageArea?.children?.find?.((v) => v.name === CJ_CONTAINER_NAME.frame);
    return find as CjContainer;
  }

  static hitTest(
    origin: createjs.DisplayObject, target: createjs.DisplayObject, option?: {
      adjustOrigin?: Pos;
      adjustTarget?: Pos;
    }): boolean {
    const adjustOrigin = option?.adjustOrigin ?? { x: 0, y: 0 };
    const adjustTarget = option?.adjustTarget ?? { x: 0, y: 0 };
    const originBounds = origin.getTransformedBounds();
    const targetBounds = target.getTransformedBounds();
    const xLine = (
      originBounds.x + adjustOrigin.x >= targetBounds.x + targetBounds.width
      || originBounds.x - adjustOrigin.x + originBounds.width <= targetBounds.x
    );
    const yLine = (
      originBounds.y >= targetBounds.y + targetBounds.height
      || originBounds.y + originBounds.height <= targetBounds.y
    );
    return !(xLine || yLine);
  }
}
