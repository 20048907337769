import { ILayoutEditorManagerBase } from '../layout-editor.manager';
import {LogDecorator} from '@tenryu/log-decorator';
import {UiManager} from '../ui/ui.manager';

export class CommandBase {
  constructor(
    public name: string,
    public description: string,
    public redo: () => (Promise<void> | void),
    public undo: () => (Promise<void> | void),
  ) {
  }
}

export class CommandManager implements ILayoutEditorManagerBase {

  // redo/undo コマンドリスト
  private commandList: CommandBase[] = [];
  // 実行処理の index
  private index: number = 0;

  /** undo/redo 登録、redo 実行 */
  async do(command: CommandBase) {
    if (this.index < this.commandList.length) {
      this.commandList.splice(this.index, this.commandList.length - this.index);
    }
    this.commandList.push(command);
    await command.redo();
    this.index++;
    this.log(command, 'do');
    UiManager.ins.emit('r->l:command-list:get', { listLength: this.commandList.length, nowIndex: this.index});
  }

  register(command: CommandBase) {
    if (this.index < this.commandList.length) {
      this.commandList.splice(this.index, this.commandList.length - this.index);
    }
    this.commandList.push(command);
    this.index++;
  }

  /** redo 実行（index番目） */
  async redo() {
    if (this.index < this.commandList.length) {
      await this.commandList[this.index].redo();
      this.log(this.commandList[this.index], 'redo');
      this.index++;
    }
    UiManager.ins.emit('r->l:command-list:get', { listLength: this.commandList.length, nowIndex: this.index});
  }

  /** undo 実行（index - 1番目） */
  async undo() {
    if (this.index > 0) {
      this.index--;
      await this.commandList[this.index].undo();
      this.log(this.commandList[this.index], 'undo');
    }
    UiManager.ins.emit('r->l:command-list:get', { listLength: this.commandList.length, nowIndex: this.index});
  }

  clear() {
    this.commandList = [];
    this.index = 0;
    UiManager.ins.emit('r->l:command-list:get', { listLength: this.commandList.length, nowIndex: this.index});
  }

  destroy(): void {
    this.commandList = [];
    this.index = 0;
    UiManager.ins.off('r->l:undo');
    UiManager.ins.off('r->l:redo');
  }

  di(): void {
  }

  initialize(): void {
    this.commandList = [];
    this.index = 0;
    UiManager.ins.on('r->l:undo', () => this.undo());
    UiManager.ins.on('r->l:redo', () => this.redo());
  }

  private log(command: CommandBase, type: 'do' | 'redo' | 'undo') {
    LogDecorator.group(`run action - ${command?.name} -`);
    LogDecorator.log(command?.description);
    LogDecorator.group('command manager status');
    LogDecorator.log(`action: ${type}`);
    LogDecorator.log(`index : ${this.index + (type === 'undo' ? 1 : (type === 'do' ? -1 : 0))} → ${this.index + ((type === 'undo' || type === 'do') ? 0 : 1)}`);
    LogDecorator.groupEnd();
    LogDecorator.groupEnd();
  }
}
