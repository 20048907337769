// noinspection JSVoidFunctionReturnValueUsed

import { orderRequest, orderRequestOptional, ordersActions } from '../../orders-slice';
import {AppThunk} from '../../../app/store';
import { apiImageCheckThunk } from '../../../api/front/image-check/api-image-check';
import { apiOptionItemThunk } from '../../../api/back/create-order/option-item/api-option-item';

// ラミ選択
export const setLaminate = (value: string, label: string = ''): AppThunk => async (dispatch)  => {
  dispatch(ordersActions.updateGenuine({
    laminate: { value, label },
  }));
};
export const setGenineProcessing = (value: string, label: string = ''): AppThunk => async (dispatch)  => {
  dispatch(ordersActions.updateGenuine({
    genuineProcessing: { value, label },
  }));
};
// 箔押し文字1
export const setLeafText1 = (value: string): AppThunk => async (dispatch) => {
  dispatch(ordersActions.updateGenuine({
    leafText1: value,
  }));
};
// 箔押し文字2
export const setLeafText2 = (value: string): AppThunk => async (dispatch) => {
  dispatch(ordersActions.updateGenuine({
    leafText2: value,
  }));
};
// 見返し選択
export const setAdditional = (value: string, label: string = ''): AppThunk => async (dispatch)  => {
  dispatch(ordersActions.updateGenuine({
    additional: { value, label },
  }));
};
// 付加情報
export const setAdditionalMulti = (value: string): AppThunk => async (dispatch) => {
  dispatch(ordersActions.updateGenuine({
    additionalMulti: value,
  }));
};

// 年表選択
export const setChronology = (value: string, label: string): AppThunk => async (dispatch) => {
  dispatch(ordersActions.updateGenuine({
    chronologies: { value, label },
  }));
};

// ページ数
export const setPage = (value: number, agent: boolean): AppThunk => async (dispatch, getState)  => {
  const order = getState().order;
  const data = getState().orderData;
  const requestOptional = orderRequestOptional(order, getState().createOrder.visibleCondition, data);
  const request = orderRequest(order, getState().createOrder.visibleCondition, data);
  dispatch(ordersActions.updateGenuine({
    page: value,
  }));
  // -- オプション商品 --
  dispatch(apiOptionItemThunk({
    productNameId: request.productNameId,
    coverColorId: requestOptional.coverColorId,
    // coverId: requestOptional.coverColorId,
    coverId: data.data.productDetail.coverTypeId || undefined,
    orderMethodId: requestOptional.orderMethod,
    boardInfoId: requestOptional.boardInfoId,
    pageCountId1: (!data.option.optionPageCount || requestOptional.orderMethod !== '10') ? String(value) : undefined,
    pageCountId2: (data.option.optionPageCount && requestOptional.dtemplId) ? String(value) : undefined,
    agentMode: agent ? 'true' : undefined,
  }));
  // -- 完成画像枚数 --
  // if (request.orderMethod === '40') {
  //   dispatch(apiImageCheckThunk({
  //     goodsId: request.productNameId,
  //     page: value,
  //     kijshopCd,
  //   }));
  // }
};
