import { EndpaperRequest, EndpaperResponse } from '../../../../models/api/back/create-order/endpaper';
import {ResponseBase} from "../../../response-base";
import {AppThunk} from "../../../../app/store";
import {ordersDataActions} from "../../../../slices/orders/orders-data-slice";
import {apiActions} from "../../../../slices/api-slice";
import {ApiBaseBack} from "../../api-base-back";
import { ordersActions } from '../../../../slices/orders-slice';

export class ApiEndpaper extends ApiBaseBack<EndpaperResponse> {
  constructor(param: EndpaperRequest) {
    super(
      'GET',
      '/api/v1/additionalInfos',
      'JSON',
      param,
    );
  }
}

export const apiEndPaperThunk = (param: EndpaperRequest, callback?: () => void, isInit?: boolean, defaultElement?: string[]): AppThunk => (dispatch, getState) => {
  if (!param.productNameId || !param.parentFlg) {
    dispatch(ordersDataActions.updateGenuine({
      endpaper: { additionalInfos: [] },
    }));
    callback?.();
    return;
  }
  dispatch(apiActions.run(
    new ApiEndpaper(param),
    {
      onSuccess: (res: ResponseBase<EndpaperResponse>) => {
        callback?.();
        if (!isInit && param.productNameId !== getState().order.itemDetail.itemSelect.value) {
          return;
        }
        dispatch(ordersDataActions.updateGenuine({
          endpaper: { additionalInfos: res.body.data?.additionalInfos || [] },
        }));
        const checkValue = (v: string) => {
          // switch (v) {
          //   case 'jptg380123':
          //   case 'jptg380124':
          //   case 'jptg380163':
          //   case 'jptg380164':
          //   case 'jptg380217':
          //     return v;
          //   default:
          //     return '';
          // }
          return defaultElement?.find((ele) => ele === v) ? v : '';
        };
        const data = res.body.data?.additionalInfos?.find((v) => checkValue(v.additionalInfoId));
        if (data) {
          dispatch(ordersActions.genuine.setAdditionalMulti(data.additionalInfoId));
        }
        const additional = getState().order.genuine.additional;
        if (additional.value && res.body.data?.additionalInfos?.find((v) => v.additionalInfoId === additional.value)) {
          dispatch(ordersActions.genuine.setAdditional(additional.value, additional.label));
        }
      },
      onError: () => {
        dispatch(ordersDataActions.updateGenuine({
          endpaper: { additionalInfos: [] },
        }));
        callback?.();
      },
    },
    {
      apiName: 'end-paper',
      ignoreSystemError: true,
    },
  ))
};