import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Input } from '../../ui/input/input';
import { Pagination } from '../../ui/pagination/pagination';
import './contact-sheet.scss';
import { useParams } from 'react-router-dom';
import { PathParams } from '../../../routes/routing-path';
import { useAppSelector } from '../../../app/hooks';
import lodash from 'lodash';
import { Image } from '../../ui/image/image';
import { Thumbnail } from '../../ui/thumbnail/thumbnail';

type ContactSheetProps = {
  // - 画像リスト -
  // printImages: globalThis.JSX.Element[],
  images: { src: string, label: string }[],
  // - 全画像数 -
  totalImageNum: number,
  // - 全ページ数 -
  totalPageNum: number,
  // - 印刷ボタン押したか -
  isClickPrint: boolean,
  // - 表示ページ変更のハンドラ -
  onChangePageNation?: (pageNum: number) => void,
  // - スタイル指定 -
  style?: React.CSSProperties,
  // - プレビュー表示の際の現ページ -
  thisNum?: number,
}

export const ContactSheet = (props: ContactSheetProps) => {

  const { images, totalImageNum, totalPageNum, isClickPrint, onChangePageNation, style, thisNum } = props;
  const { kijshopCd, shopOrderId, orderId } = useParams<PathParams>();
  const BASE_SHEET_WIDTH = 636;  // XDデザイン参照
  const RATIO = 1.31;
  // const BASE_SHEET_WIDTH = 2894 / 3;  // A4幅 / 3（仮）
  const BASE_SHEET_HEIGHT = BASE_SHEET_WIDTH * RATIO;
  // - Ref -
  const outerRef = useRef<HTMLDivElement>(null);
  // - State -
  const xml = useAppSelector((state) => state.xml[shopOrderId], lodash.isEqual);
  const order = xml?.orderInfo?.infoData?.find((v) => v.xml.metaModel.id === orderId)?.xml;
  // -- 表示しているページ --
  const [activeNum, setActiveNum] = useState(thisNum ? thisNum : 1);
  // -- 入力欄から表示しようとしているページ --
  const [inputPage, setInputPage] = useState(activeNum);
  // -- シートエリアの高さとコンタクトシートの高さ（実値）の比率 --
  const [sheetScale, setSheetScale] = useState<number>();
  // -- アウター要素の比率がマージン考慮後のRATIOを超えているか --
  const [isReScaleOffset, setIsReScaleOffset] = useState<boolean>();

  // - Callback -
  // -- 表示するページ数変更 --
  const handlerChangePageNation = useCallback((num) => {
    setActiveNum(num);
    setInputPage(num);
    if (onChangePageNation) {
      onChangePageNation(num);
    }
  }, [onChangePageNation]);
  // -- 入力欄に表示されるページ数の更新 --
  const handlerChangePageNum = useCallback((v) => {
    setInputPage(v);
  }, []);
  // 入力欄に入力された値で決定する際の処理
  const determineInputPage = () => {
    if (inputPage > 0 && inputPage <= totalPageNum) {
      setActiveNum(inputPage);
      onChangePageNation && onChangePageNation(inputPage);
    } else {
      setInputPage(activeNum);
    }
  }
  // -- 入力欄でのEnter押下時処理 --
  const handlerEnterKeyPress = useCallback(() => {
    determineInputPage();
  }, [inputPage]);
  // -- 入力欄からカーソルを外した際の処理 --
  const handlerBlurInput = useCallback(() => {
    determineInputPage();
  }, [inputPage]);
  // -- リサイズ --
  const resize = useCallback(() => {
    if (!isClickPrint || !outerRef.current) { return }
    // 実際のコンタクトシートのサイズに対するブラウザ上のシートエリアの高さの比率
    const outerHeight = outerRef.current.getBoundingClientRect().height;
    const windowRate = window.innerHeight / window.innerWidth;
    const reScaleRate = (windowRate > RATIO + 0.08) ? (RATIO + 0.08) / windowRate : 1; // 0.08 ... マージン
    setSheetScale((Math.floor((outerHeight / BASE_SHEET_HEIGHT) * 10000) / 10000 - 0.04) * reScaleRate); // 0.04 ... マージン
    setIsReScaleOffset(reScaleRate < 1);
  }, [isClickPrint, outerRef.current]);
  // - Effect -
  // -- リサイズ処理登録・削除 --
  useEffect(() => {
    if (isClickPrint) {
      resize();
      outerRef.current?.scrollTo(0, 0);
      window.addEventListener('resize', resize);
    } else {
      window.removeEventListener('resize', resize);
    }
    return () => window.removeEventListener('resize', resize);
  }, [isClickPrint]);

  return (
    <div className="contact_sheet" style={style}>
      <div className={`contact_sheet__body__outer${isClickPrint ? ' preview' : ''}`} ref={outerRef}>
        <div
          className="contact_sheet__body__wrap"
          style={{
            width: BASE_SHEET_WIDTH,
            height: BASE_SHEET_HEIGHT,
            ...isClickPrint ? { width: BASE_SHEET_WIDTH } : {},
            ...sheetScale && isClickPrint ? { transform: `scale(${sheetScale}) translateY(${sheetScale > 0 ? `${-sheetScale}px` : `${1 - sheetScale}px`})` } : {}
          }}
        >
          <div className="contact_sheet__body">
            <div className="contact_sheet__body__info" >
              <div>コンタクトシート</div>
              <div>
                <div>{(xml?.info.metaModel.createDate)?.slice(0, 19) || ''}</div>
                <div>ページ：{activeNum}</div>
              </div>
              <div>
                <div>お客様名：{xml?.customer.viewModel.lastName || ''}</div>
                <div>受付番号：{xml?.info.metaModel.receptionSerialID || ''}</div>
                <div>注文番号：{shopOrderId}</div>
                <div /> {/* dummy */}
              </div>
              <div>
                <div>商品名：ー</div>
                <div>ラボ商品名：{order?.viewModel.item?.name || ''}</div>
                <div>ページ数：{totalPageNum}</div>
                <div>画像数：{totalImageNum}</div>
              </div>
            </div>
            <div className="contact_sheet__body__images" >
              {/*{printImages?.map((v, i) => (*/}
              {/*  <div key={`contact-sheet-image_${i}`} className="contact_sheet__body__images__item">*/}
              {/*    {v}*/}
              {/*  </div>*/}
              {/*))}*/}
              {images.map((v, i) => (
                <div key={`contact-sheet-image_${i}`} className="contact_sheet__body__images__item">
                  <Thumbnail label={v.label} thumbEle={<Image src={v.src} alt="" containerAspectRatio={1.5} />} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="contact_sheet__footer">
        <div>
          <Pagination
            totalPages={totalPageNum}
            onPageChange={(activePage) => handlerChangePageNation(activePage)}
            // activePageNum={activeNum}
            setActivePageNum={setActiveNum}
          />
        </div>
        <div className="contact_sheet__footer__current_page">
          ページ：
            <Input
            value={inputPage}
            onChange={(e) => handlerChangePageNum(e.target.value)}
            handlerEnter={handlerEnterKeyPress}
            onBlur={handlerBlurInput}
            type="number"
            min={1}
          />
        </div>
      </div>
    </div>
  );
};
