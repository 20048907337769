import * as lodash from 'lodash';
import { ColorCodeConverter } from '../../../../utilities/colorcode-converter';
import { UiManager } from '../../ui/ui.manager';
import { TextController } from './text-controller';
import { CjContainer, CjShape, CjStage, CjText } from '../../../model/cj-factory';
import { ImageEvent } from '../image/image-event';
import { CjShadow } from '../../../model/cj-factory/cj-shadow';
import { FontSizeList } from '../../../model/font-size-list';
import { CJ_CONTAINER_NAME, CJ_FOCUS_NAME } from '../../../model/cj-factory/cj-obj-name.collection';
import { CjTool } from '../../../../utilities/cj-tool';
import { EditableEvent } from '../editable-event';
import { PosManager } from '../../pos/pos.manager';
import { cloneDeep } from 'lodash';
import { LayoutDataManager } from '../../data/layout-data.manager';

export class TextEvent {
  private stage: CjContainer;
  private readonly pageId: string;


  private textController!: TextController;
  private imageEvent!: ImageEvent;
  private editableEvent!: EditableEvent;
  private posManager!: PosManager;
  private layoutDataManager!: LayoutDataManager;

  isFocus: boolean = false;
  isActive: boolean = false;
  isInsert: boolean = false;
  targetFrame: CjContainer | null = null;

  private clicked: boolean = false;
  private dragPointX: number = 0;
  private dragPointY: number = 0;

  constructor(stage: CjContainer, pageId: string) {
    this.stage = stage;
    this.pageId = pageId;
  }

  di(
    textController: TextController,
    imageEvent: ImageEvent,
    editableEvent: EditableEvent,
    posMng: PosManager,
    layoutDataMng: LayoutDataManager,
  ): void {
    this.textController = textController;
    this.imageEvent = imageEvent;
    this.editableEvent = editableEvent;
    this.posManager = posMng;
    this.layoutDataManager = layoutDataMng;
  }

  initialize(): void {
  }

  destroy(): void {
    this.isFocus = false;
    this.targetFrame = null;
    this.clicked = false;
    this.dragPointX = 0;
    this.dragPointY = 0;
  }

  update(stage: CjContainer): void {
    this.stage = stage;
  }

  setFocus(container: CjContainer, isRestore: boolean, callback?: () => void) {
    if (this.targetFrame || this.imageEvent.targetFrame || !isRestore) {
      this.editableEvent.disFocused(this.targetFrame!);
      this.imageEvent.targetFrame && this.editableEvent.disFocused(this.imageEvent.targetFrame);
    }
    if (!isRestore) {
      this.isFocus = true;
      this.targetFrame = container;
    }
    CjTool.checkLoad(container).then((v) => {
      const data = v.getTransformedBounds();

      const focusContainer = new CjContainer();
      focusContainer.name = CJ_CONTAINER_NAME.focus.text;
      const focus: CjShape = new CjShape();
      focus.name = CJ_FOCUS_NAME.line;
      focusContainer.addChild(focus);
      focus.graphics.s('#A5B9F4');
      focus.graphics.sd([10, 5], 0);
      // focus.graphics.beginStroke('#A5B9F4');
      // focus.graphics.setStrokeStyle(2);
      focus.graphics.dr(data.x - 10 - container.x, data.y - 10 - container.y, data.width + 20, data.height + 20);
      const corner: CjShape = new CjShape();
      corner.graphics.f('#A5B9F4').dc(data.x - 10 + data.width + 20 - container.x, data.y - 10 - container.y, 5);
      corner.graphics.f('#A5B9F4').dc(data.x - 10 + data.width + 20 - container.x, data.y - 10 + data.height + 20 - container.y, 5);
      corner.graphics.f('#A5B9F4').dc(data.x - 10 - container.x, data.y - 10 + data.height + 20 - container.y, 5);
      corner.graphics.f('#A5B9F4').dc(data.x - 10 - container.x, data.y - 10 - container.y, 5);
      corner.name = CJ_FOCUS_NAME.corner;
      focusContainer.addChild(corner);
      focusContainer.visible = !isRestore;
      v.addChild(focusContainer);
      callback?.();
    });
  }

  showFocus(container: CjContainer) {
    if (this.targetFrame || this.imageEvent.targetFrame) {
      this.editableEvent.disFocused(this.targetFrame!);
      this.imageEvent.targetFrame && this.editableEvent.disFocused(this.imageEvent.targetFrame);
    }
    this.updateFocus(container);
    this.isFocus = true;
    this.targetFrame = container;
    const textFocus = CjTool.getTextFocus(container);
    textFocus.visible = true;
  }

  updateFocus(container: CjContainer) {
    this.isFocus = true;
    this.targetFrame = container;
    const textFocus = CjTool.getTextFocus(container);
    const line = textFocus.getChildByName(CJ_FOCUS_NAME.line) as CjShape;
    const corner = textFocus.getChildByName(CJ_FOCUS_NAME.corner) as CjShape;
    line.graphics.clear();
    corner.graphics.clear();
    const data = container.getTransformedBounds();
    line.graphics.s('#A5B9F4');
    line.graphics.sd([10, 5], 0);
    line.graphics.dr(data.x - 10 - container.x, data.y - 10 - container.y, data.width + 20, data.height + 20);
    corner.graphics.f('#A5B9F4').dc(data.x - 10 + data.width + 20 - container.x, data.y - 10 - container.y, 5);
    corner.graphics.f('#A5B9F4').dc(data.x - 10 + data.width + 20 - container.x, data.y - 10 + data.height + 20 - container.y, 5);
    corner.graphics.f('#A5B9F4').dc(data.x - 10 - container.x, data.y - 10 + data.height + 20 - container.y, 5);
    corner.graphics.f('#A5B9F4').dc(data.x - 10 - container.x, data.y - 10 - container.y, 5);
    textFocus.rotation = -container.rotation;
    this.stage.stage.update();
  }

  textEditor(text: CjText, container: CjContainer) {
    // this.textController.textImage = text;
    // this.textController.backupText = lodash.cloneDeep(text);

    let textShadow: CjShadow;
    let shadowColor: string = '#000000';
    let shadowOffset: number = 10;
    let shadowAlpha: number = 0;
    if (text.shadow) {
      textShadow = text.shadow;
      shadowColor = textShadow.color ?? '#000000';
      shadowOffset = textShadow.offsetX ?? 10;
      shadowAlpha = ColorCodeConverter.rgbaToColorCode(shadowColor ?? 'rgba(0,0,0,0.65)').alpha;
    }
    // const fontLabel = text.fontData?.size ?? '24pt';
    const fontLabel = text.fontData?.size.replace('px', 'pt') ?? '24pt';
    const fontSize = FontSizeList.find((v) => v.label === fontLabel);

    const textBounds = container.getTransformedBounds();
    const pos = this.posManager.canvasToBrowser({
      x: container.x,
      y: container.y
    });
    const adjustedPos = this.posManager.canvasToBrowser({
      x: container.x,
      y: container.y + textBounds.height + 10 // 10 ... 微調整用
    });
    UiManager.ins.emit('l->r:layout-menu-disabled', { isDisabled: true });
    UiManager.ins.emit('l->r: post-text-pos', { pos });
    this.textController.backupText = cloneDeep(text);
    this.textController.backupTextContainer = cloneDeep(container);
    // --- テキストツール表示 ---
    UiManager.ins.emit('l->r:show-text-tool', {
      isOpen: true,
      textInfo: {
        id: text.textId ?? '',
        isBold: !!text.fontData?.isBold ?? false,
        isItalic: !!text.fontData?.isItalic ?? false,
        textColor: text.color,
        isOnDropper: false,
        fontType: text.options?.fontType ?? 'server',
        font: text.fontData?.family ?? '',
        // fontSize: text.fontData?.size.replace('pt', '') ?? '11',
        fontSize: text.fontData?.size.replace('px', '') ?? '11',
        textTransparency: Math.floor(Number(text.alpha) * 100) ?? 100,
        dropShadow: text.options?.isShadow ?? false,
        transparency: shadowAlpha ? Math.floor(Number(shadowAlpha) * 100) : 65,
        distance: shadowOffset,
        outlineWidth: text.options?.outline ?? 0,
        outlineColor: text.options?.outlineColor ?? '#000000',
        textAlign: this.textController.textAlignFormat(text.textAlign) ?? '0',
        textRotation: text.parent.rotation,
        textSelectRotation: text.parent.rotation,
        lineSpacingNum: Math.floor(Number(text.lineHeight) - text.getMeasuredLineHeight()),
        textAreaContents: text.text ?? '',
        height: textBounds.height,
        pos,
        adjustedPos: adjustedPos,
      },
    });
  }

  handleDown(container: CjContainer, e: any) {
    if (container !== this.targetFrame) return;
    this.dragPointX = this.stage.stage.mouseX - container.x;
    this.dragPointY = this.stage.stage.mouseY - container.y;
  }

  // -- containerの移動 --
  handleMove(container: CjContainer, e: any) {
    if (container !== this.targetFrame) return;
    if (!this.isFocus) return;
    // document.body.style.cursor = 'move';
    container.x = this.stage.stage.mouseX - this.dragPointX;
    container.y = this.stage.stage.mouseY - this.dragPointY;
  }

  handleUp(container: CjContainer, e: any) {
    if (container !== this.targetFrame) return;
    if (!this.isFocus) return;
  }

  // - textへのイベント設定 -
  setTextEvent(text: CjText, container: CjContainer) {
    text.on('click', (e: any) => {
      e.stopPropagation();
      if (this.isInsert) return;
      const frameLength = this.stage.numChildren;
      container && this.stage.setChildIndex(container, frameLength - 1);
      // --- ダブルクリックの場合 ---
      if (this.clicked) {
        this.showFocus(container);
        this.clicked = false;
        this.textEditor(text, container);
        return;
      }
      // --- シングルクリックの場合 ---
      this.clicked = true;
      // --- ダブルクリック受付時間 ---
      setTimeout(() => {
        // ---- ダブルクリックだとfalse ----
        if (this.clicked) {
          this.showFocus(container);
        }
        this.clicked = false;
      }, 250);
    });
    text.on('mousedown', (e) => {
      this.handleDown(container, e);
    });
    text.on('pressmove', (e) => {
      this.handleMove(container, e);
    });
    text.on('pressup', (e) => {
      this.handleUp(container, e);
    });
  }
}
