import { ILayoutEditorManagerBase, LayoutEditorManager } from '../layout-editor.manager';
import { UiManager } from '../ui/ui.manager';
import { PanZoomManager } from '../pan-zoom/pan-zoom.manager';
import { Ruler } from './ruler';
import { ImageEditManager } from '../image-edit/image-edit.manager';

export class RulerManager implements ILayoutEditorManagerBase {

  // - container -
  private container!: HTMLDivElement;
  // - manager -
  private uiManager!: UiManager;
  private panZoomManager!: PanZoomManager;
  // - event handler -
  private onMouseMove: (e: MouseEvent) => void = () => {};
  // - ins -
  private ruler!: Ruler;

  di(
    imageEditManager: ImageEditManager,
    uiManager: UiManager,
    panZoomManager: PanZoomManager,
  ): void {
    this.container = imageEditManager.getCanvasEle().parentElement as HTMLDivElement;
    this.uiManager = uiManager;
    this.panZoomManager = panZoomManager;
  }

  initialize(): void {
    this.ruler = new Ruler(this.container);
    this.ruler.create();
    this.activate();
  }

  activate(): void {
    this.uiManager.on('l->r:zoom:change', (e) => {
      this.ruler.pan(e.x, e.y);
      this.ruler.zoom(e.scale);
    });
    this.onMouseMove = (e) => {
      const x = e.clientX;
      const y = e.clientY;
      this.ruler.moveGuide(x, y);
    };
    this.container.addEventListener('mousemove', this.onMouseMove);
  }
  destroy(): void {
    // ホットリロード対策
    if (!this.uiManager) return;
    this.uiManager.off('l->r:zoom:change')
    this.container.removeEventListener('mousemove', this.onMouseMove);
    this.ruler.destroy();
  }

}
