import { OrdersSliceState } from '../../../slices/orders-slice';
import { OrdersDataState } from '../../../slices/orders/orders-data-slice';
import { VisibleConditionValueGetter } from '../../../manager/visible-condition-value-getter';

export type TOrderInfoDataViewModel = {
  // 配送情報ID
  deliveryData: string,
  // 工程ID
  executeProcess?: { [key: string]: [''] },
  // レタッチでのバグ再現用
  excuteProcess?: { [key: string]: [''] },
  // 注文方法ID（レタッチ:2010）
  surrogateProcess: Partial<{
    id: string,
    no?: string,
    name: string,
    // 工程ID
    data?: {
      [key: string]: [''],
    },
  }>,
  // 選択画像フォルダパス
  selectImageFolder: string,
  // 完成画像フォルダパス
  selectCompletionProductFolder: string,
  // カテゴリ
  category: {
    id: string,
    name: string,
  },
  // 商品
  item: {
    id: string,
    name: string,
  },
  // 商材
  goods: {
    goodsId: string,
    name: string,
    shortName?: string,
    productType?: string,
  },
  // 注文方法ID
  orderMethod: {
    id: string,
    name: string,
  },
  // オプション有効条件
  optionEnableCondition: {
    parentMinPageCount: string | undefined,
    parentMaxPageCount: string | undefined,
  },
  // デザインタイプ
  designType: {
    templateGroupId: string,
    templateId: string,
    name: string,
  },
  // 商品記載情報
  descriptionInfo: {
    name?: string,
    date?: string,
    location?: string,
    pageType?: { [key: string]: [''] }, // TODO
    /* ページ種ID */
  },
  // 備考
  remarks: string,
  // タグ
  productTagId: string,
  // 表紙
  coverId: string,
  // 表紙色
  coverColorId: string,
  // 部材選択
  boardInfo: string,
  // 部数（数量
  infoOutputCount: string,
  // 開き方向
  openDirection: string,
  //　特定商品のみ
  totalPageCount: string,
  // メール記載情報
  mailRemarks: string,
};

export type TOrderInfoDataMetaModel = {
  // order-info の id
  id: string,
  // 代理発注用店舗ID
  agentOrderShopCode: string,
  // 親商材のid
  parentId: string,
  // 要承認フラグ
  isNeedAgreement: string,
  // パス
  path: {
    orderPageBreak?: string,
    orderParts?: string,
  },
  // レイアウト完了フラグ
  layoutFinish?: string,
};

const convertOrderMethodIdToNo = (v: string) => {
  switch (v) {
    case '10':
      return '1';
    case '20':
      return '2';
    case '30':
      return '3';
    case '40':
      return '4';
    default:
      return undefined;
  }
};

// const optionDescriptionInfoInherit = (category: string, item: string, opCategory: string, orderMethod: string) => {
//   if (orderMethod === '30') {
//     switch (item) {
//       case 'jptg301626':
//       case 'jptg301627':
//       case 'jptg302732':
//       case 'jptg302737':
//         return true;
//       default:
//         return false;
//     }
//   }
//   if (orderMethod === '10' || orderMethod === '20') {
//     switch (item) {
//       case 'jptg300788':
//       case 'jptg303175':
//       case 'jptg303176':
//       case 'jptg301448':
//       case 'jptg301449':
//       case 'jptg302272':
//       case 'jptg302273':
//         return false;
//     }
//     switch (category) {
//       case 'jp0385':
//       case 'jp0333':
//       case 'jp0376':
//         return false;
//     }
//     return opCategory !== 'jp0503';
//   }
//   return false;
// };

// const descriptionInfoPageTypeInherit = (category: string) => {
//   switch (category) {
//     case 'jp0301':
//     case 'jp0332':
//     case 'jp0487':
//     case 'jp0488':
//       return true;
//     default:
//       return false;
//   }
// };

/* レイアウト時にも精査が必要なので export する(ハードコーディング箇所なのでコードに残す) */
export const descriptionInfoPageTypeInherit = (opCategory: string) => {
  switch (opCategory) {
    case 'jp0301':
    case 'jp0332':
    case 'jp0335':
    case 'jp0441':
    case 'jp0487':
    case 'jp0488':
      return true;
    default:
      return false;
  }
};

export const createOrderInfoDataViewModel = (order: OrdersSliceState, data: OrdersDataState, visibleCondition: VisibleConditionValueGetter): {
  parent: Partial<TOrderInfoDataViewModel>,
  options: {
    model: Partial<TOrderInfoDataViewModel>,
    optionIndex: number,
  }[],
} => {
  const {
    itemInfo,
    itemDetail,
    descriptionInfo,
    cover,
    genuine,
    option,
    remarks,
  } = order;
  // const itemDetailDisplay = itemDetailDisplayItem(order, data);
  const itemDetailDisplay = visibleCondition.itemDetailOrderDisplay;
  const surrogateData = data.data.processInfo.orderMethod?.find((v) => v.orderMethodId === itemDetail.orderMethod.value && v.productType.find((productType) => productType.productTypeId === data.data.productDetail.productTypeId)) || data.data.processInfo.orderMethod.find((v) => !v.productType.length);
  const descriptionName = descriptionInfo.name;
  const descriptionDate = descriptionInfo.date?.value;
  const descriptionLocation = descriptionInfo.photographLocation;
  const parent: Partial<TOrderInfoDataViewModel> = {
    // 配送情報ID(1固定)
    deliveryData: '1',
    // 工程ID(商材情報の executeProcess)
    executeProcess: data.data.productDetail.prePrint ? (() => {
      const obj: any = {};
      data.data.productDetail.prePrint?.forEach((v) => {
        obj[v.prePrintId] = [''];
      });
      return obj;
    })() : undefined,
    // 注文方法ID（レタッチ:2010, 他は注文方法通り）
    surrogateProcess: {
      id: itemDetail.orderMethod.value,
      no: surrogateData?.id || convertOrderMethodIdToNo(itemDetail.orderMethod.value),
      name: itemDetail.orderMethod.label,
      data: data.data.processInfo?.orderMethod ? (() => {
        const obj: any = {};
        // const no = convertOrderMethodIdToNo(itemDetail.orderMethod.value);
        // const prePrint = data.data.processInfo.orderMethod?.find((v) => v.orderMethodId === itemDetail.orderMethod.value && v.id === no)?.prePrint;
        surrogateData?.prePrint?.forEach((p) => {
          if(p.prePrintId && !obj[p.prePrintId]) {
            obj[p.prePrintId] = [''];
          }
        });
        return obj;
      })() : undefined,
    },
    // 選択画像フォルダパス(画像を選択しておまかせのフォルダパス、作成時は不要)
    // selectImageFolder: '',
    // 完成画像フォルダパス（完成画像入稿のフォルダパス、作成時は不要）
    // selectCompletionProductFolder: '',
    // カテゴリ
    category: {
      id: itemInfo.category.value,
      name: itemInfo.category.label,
    },
    // 部材
    boardInfo: itemDetailDisplay.board ? (itemDetail.boardSelect.value || undefined) : undefined,
    // 商品
    item: {
      id: itemDetail.itemSelect.value,
      name: itemDetail.itemSelect.label,
    },
    // 商材
    goods: {
      goodsId: itemDetail.itemSelect.value,
      name: itemDetail.itemSelect.label,
      shortName: data.data.productDetail.productShortName || '',
      productType: data.data.productDetail.productTypeId || undefined,
    },
    // 注文方法ID
    orderMethod: {
      id: itemDetail.orderMethod.value,
      name: itemDetail.orderMethod.label,
    },
    // 開き方向
    openDirection: data.data.productDetail?.openDirectionId || undefined,
    // オプション有効条件
    // optionEnableCondition: {
    //   parentMinPageCount: data.data.pageCount.minPageNum,
    //   parentMaxPageCount: data.data.pageCount.maxPageNum,
    // },
    // デザインタイプ
    designType: itemDetailDisplay.designType1 && itemDetail.designType1.value ? {
      templateGroupId: itemDetail.designType1.value,
      templateId: itemDetail.designType2.value,
      name: itemDetail.designType2.label,
    } : undefined,
    // 商品記載情報
    descriptionInfo: ((itemDetail.orderMethod.value === '20' || itemDetail.orderMethod.value === '30') || (descriptionName || (descriptionDate || descriptionDate === '') || descriptionLocation)) ? {
      name: itemDetail.orderMethod.value === '30' ? '' : (descriptionName || ((itemDetail.orderMethod.value === '20') ? '' : undefined)),
      date: itemDetail.orderMethod.value === '30' ? '' : ((descriptionDate || descriptionDate === '') ? descriptionInfo.date?.value: ((itemDetail.orderMethod.value === '20') ? '' : undefined)),
      location: itemDetail.orderMethod.value === '30' ? '' : (descriptionLocation || ((itemDetail.orderMethod.value === '20') ? '' : undefined)),
      // name: (descriptionInfo.name || descriptionInfo.name === '') ? descriptionInfo.name : undefined,
      // date: (descriptionInfo.date?.value || descriptionInfo.date?.value === '') ? descriptionInfo.date?.value : undefined,
      // location: (descriptionInfo.photographLocation || descriptionInfo.photographLocation === '') ? descriptionInfo.photographLocation : undefined,
      pageType: data.data.processInfo?.orderMethod ? (() => {
        const obj: any = {};
        order.descriptionInfo.descriptionLocations?.forEach((v) => {
          if (v) {
            obj[v] = [''];
          }
        });
        return obj;
      })() : ((itemDetail.orderMethod.value === '20' || itemDetail.orderMethod.value === '30') ? {} : undefined),
      /* ページ種ID */
    } : undefined,
    // 備考
    // remarks: order.itemDetail.orderMethod.value === '10' ? remarks.remarks.replace(/\n/g, '\\r\\n') : undefined,
    remarks: order.itemDetail.orderMethod.value === '10' ? remarks.remarks.replace(/\r\n/g, '\\r\\n').replace(/\n/g, '\\r\\n') : undefined,
    // メール記載情報
    mailRemarks: order.mailRemarks.mailRemarks.replace(/\r\n/g, '\\r\\n').replace(/\n/g, '\\r\\n'),
    // タグ
    productTagId: (data.itemInfo.tag.productTag.length && itemInfo.tag.value) || undefined,
    // 表紙
    coverId: (itemDetailDisplay.cover && itemDetail.coverSelect.value) || undefined,
    // 表紙色
    coverColorId: (itemDetailDisplay.coverColor && itemDetail.coverColorSelect.value) || undefined,
    // 部数（数量
    infoOutputCount: String(itemDetail.quantity),
    // 開き方向
    // openDirection: ,
    // 特定商品のみ
    totalPageCount: '',
  };
  const options: {
    model: Partial<TOrderInfoDataViewModel>,
    optionIndex: number,
  }[] = [];
  option.forEach((op, i) => {
    const optionDisplay = visibleCondition.optionOrderDisplay[i];
    if (optionDisplay?.item && op?.item?.value) {
      const productDetail = data.data.optionProductDetail[i];
      // const displayDescriptionInfo = optionDisplayItem(order, data, i).descriptionInfo;
      const displayDescriptionInfo = visibleCondition.optionOrderDisplay[i]?.description;
      options.push({
        model: {
          // 配送情報ID(1固定)
          deliveryData: '1',
          // カテゴリ
          category: (op?.item?.serviceId && op.item.serviceId !== itemInfo.category.value) ? {
            id: op.item.serviceId,
            name: op.item.serviceName,
          } : undefined,
          // 商品
          item: op.item && {
            id: op.item.value,
            name: op.item.label,
          },
          // 商材
          goods: (op.item || productDetail) && {
            goodsId: op.item.value,
            name: op.item.label,
            shortName: productDetail?.productShortName || undefined,
            productType: productDetail?.productTypeId || undefined,
          },
          // 開き方向
          openDirection: productDetail?.openDirectionId || undefined,
          // オプション有効条件
          optionEnableCondition: (productDetail?.optionMaxPageCount || productDetail?.optionMinPageCount) ? {
            parentMinPageCount: productDetail?.optionMinPageCount || undefined,
            parentMaxPageCount: productDetail?.optionMaxPageCount || undefined,
          } : undefined,
          // 工程ID(商材情報の executeProcess)
          executeProcess: productDetail?.prePrint ? (() => {
            const obj: any = {};
            productDetail.prePrint?.forEach((v) => {
              if (v.prePrintId) {
                obj[v.prePrintId] = [''];
              }
            });
            return obj;
          })() : {},
          // 商品記載情報
          descriptionInfo: ((productDetail?.item?.find((v) => v?.itemId) && (visibleCondition.createOrderDisplay.descriptionInfo || displayDescriptionInfo)) && (descriptionName || descriptionDate !== undefined || descriptionLocation)) || (itemDetail.orderMethod.value === '20' || itemDetail.orderMethod.value === '30') ? {
            name: itemDetail.orderMethod.value === '30' ? '' : (descriptionName || ((itemDetail.orderMethod.value === '20') ? '' : undefined)),
            date: itemDetail.orderMethod.value === '30' ? '' : (descriptionDate ?? ((itemDetail.orderMethod.value === '20') ? '' : undefined)),
            location: itemDetail.orderMethod.value === '30' ? '' : (descriptionLocation || ((itemDetail.orderMethod.value === '20') ? '' : undefined)),
            // name: (descriptionInfo.name || descriptionInfo.name === '') ? descriptionInfo.name : undefined,
            // date: (descriptionInfo.date?.value || descriptionInfo.date?.value === '') ? descriptionInfo.date?.value : undefined,
            // location: (descriptionInfo.photographLocation || descriptionInfo.photographLocation === '') ? descriptionInfo.photographLocation : undefined,
            pageType: op?.descriptionLocations?.length ? (() => {
              const obj: any = {};
              op.descriptionLocations?.forEach((v) => {
                if (v) {
                  obj[v] = [''];
                }
              });
              return obj;
            })() : (descriptionInfoPageTypeInherit(op?.item?.serviceId || itemInfo.category.value) || (productDetail?.item?.find((v) => v.inheritParent === 'true') && data.data.processInfo?.orderMethod) ? (() => {
              const obj: any = {};
              if (descriptionInfoPageTypeInherit(op?.item?.serviceId || itemInfo.category.value)) {
                order.descriptionInfo.descriptionLocations?.forEach((v) => {
                  if (v) {
                    obj[v] = [''];
                  }
                });
              }
              return obj;
            })() : ((itemDetail.orderMethod.value === '20' || itemDetail.orderMethod.value === '30') ? {} : undefined)),
          } : undefined,
          // タグ
          productTagId: op?.tag?.value,
          infoOutputCount: (visibleCondition.optionOrderDisplay[i].quantity?.max && op?.quantity) ?
            String(op.quantity) :
            visibleCondition.checkContentVisible(order, data, 'op01_outputCount3', visibleCondition.visibleData.op01_option.op01o_outputCount3, {index: i, hidden: true}) ? '1' : String(itemDetail.quantity),
          // 特定商品のみ
          totalPageCount: '',
        },
        optionIndex: i,
      });
    }
  });
  return {
    parent,
    options,
  };
};
