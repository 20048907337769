import { Radiobutton } from '../../../ui/input/radiobutton';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { dialogActions } from '../../../dialog/slice/dialog-slice';
import { push } from 'connected-react-router';
import { PathParams, RoutingPath } from '../../../../routes/routing-path';
import { useParams } from 'react-router-dom';
import { Input } from '../../../ui/input/input';
import { Button } from '../../../ui/button/button';
import './system-config.scss';
import { apiActions } from '../../../../slices/api-slice';
import { ApiGetShopInfo } from '../../../../api/front/wizard/api-staff-management';
import { ResponseBase } from '../../../../api/response-base';
import { ShopInfoResponse } from '../../../../models/api/front/staff-management';
import { webPagePath } from '../../../../models/web-page-path';
import iconHelp from '../../../../assets/img/icon/help.svg';

type Props = {
  // - スタッフ管理ON・OFFフラグ -
  isStaffMng: boolean,
};
export const ShopConfig = (props: Props) => {
  const { isStaffMng } = props;
  // - State -
  const [shopMail, setShopMail] = useState('');
  // - Hooks -
  const dispatch = useDispatch();
  const { kijshopCd } = useParams<PathParams>();
  // - Effects -
  useEffect(() => {
    if (!isStaffMng) return;
    dispatch(apiActions.run(
      new ApiGetShopInfo(kijshopCd),
      {
        onSuccess: (res: ResponseBase<ShopInfoResponse>) => {
          setShopMail(res.body.data?.mailaddress || '');
        },
      },
    ));
  }, [isStaffMng]);
  // - Callback -
  // -- スタッフ管理のON・OFF --
  const handlerChangeIsStaffMng = useCallback((v) => {
    if(isStaffMng) return;
    if (!Number(v)) {
      dispatch(dialogActions.push({
        title: '確認',
        element: <StaffMngEnableDialog />,
      }));
      return;
    }
  }, [isStaffMng]);
  // -- 店舗管理メールアドレス変更 --
  const handlerChangeShopMail = useCallback(() => {
    dispatch(push(RoutingPath.cnv.staffMngMailChange({ kijshopCd })));
  }, []);
  // -- パスワード変更 --
  const handlerChangePassword = useCallback(() => {
    dispatch(push(RoutingPath.cnv.staffMngPasswordChange({ kijshopCd })));
  }, []);

  // スタッフ管理機能有効確認モーダル
  const StaffMngEnableDialog = () => {
    const dispatch = useDispatch();
    const handlerClickCancel = useCallback(() => {
      dispatch(dialogActions.popAll());
    }, []);
    const handlerClickOk = useCallback(() => {
      dispatch(dialogActions.popAll());
      dispatch(push(RoutingPath.cnv.staffMngEnable({ kijshopCd })));
    }, []);
    const ref = useRef<HTMLAnchorElement>(null);
    useEffect(() => {
      if (ref.current) {
        ref.current.target = '_blank';
        ref.current.href = webPagePath.whatIsStaffManagement;
        ref.current.rel = 'noopener noreferrer';
      }
    }, []);
    return (
      <div>
        <div>
          スタッフ管理機能を有効にしますか？<br />
          次の設定を完了すると取り消せません。<br /><br />
          <a ref={ref}>
            {<img
              src={iconHelp}
              alt=""
              style={{
                width: '1rem',
                height: '1rem',
                verticalAlign: 'text-bottom',
                marginRight: '0.2rem',
              }}
            />}
            スタッフ管理機能とは
          </a>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginLeft: '4rem',
            marginRight: '4rem',
          }}
        >
          <div
            style={{
              marginTop: '1rem',
              textAlign: 'center',
            }}
          >
            <Button
              label="いいえ"
              onClick={handlerClickCancel}
              style={{
                display: 'inline-block',
                textAlign: 'left',
              }}
            />
          </div>
          <div
            style={{
              marginTop: '1rem',
              width: '4.4rem',
              textAlign: 'center',
            }}
          >
            <Button
              // いいえと同じ幅をもたせる
              label="はい"
              onClick={handlerClickOk}
              style={{
                width: '4.4rem',
                display: 'inline-block',
                textAlign: 'center',
              }}
            />
          </div>
        </div>
      </div>
    );
  };
  return (
    <div className="mt_2rem">
      <div className="bottom_border_header">
        店舗設定
      </div>
      <div className="top_label_ui">
        <div className="top_label_ui__label">
          スタッフ管理機能
        </div>
        <div className="radio_buttons_box">
          <Radiobutton
            value="0"
            onChange={(e) => handlerChangeIsStaffMng(e.target.value)}
            checkState={isStaffMng ? '0' : '1'}
            label="ON"
          />
          <Radiobutton
            value="1"
            onChange={(e) => handlerChangeIsStaffMng(e.target.value)}
            checkState={isStaffMng ? '0' : '1'}
            label="OFF"
            disabled={isStaffMng}
          />
        </div>
      </div>
      {isStaffMng && (
        <div>
          <div className="top_label_ui">
            <div className="top_label_ui__label">
              スタッフ管理メールアドレス
            </div>
            <div className="shop_management_email">
              <Input
                disabled
                value={shopMail}
              >
              </Input>
              <div className={'shop_management_email__button'}>
                <Button
                  label="変更"
                  size="sm"
                  onClick={handlerChangeShopMail}
                />
              </div>
            </div>
          </div>
          <div className="top_label_ui">
            <div className="top_label_ui__label">
              店舗用パスワード
            </div>
          </div>
          <div className={'shop_management_password'}>
            <Button
              label="変更"
              size="sm"
              onClick={handlerChangePassword}
            />
          </div>
        </div>
      )}
    </div>
  );
};
