import { Button } from '../../ui/button/button';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { RoutingPath } from '../../../routes/routing-path';
import { Input } from '../../ui/input/input';
import { LogDecorator } from '@tenryu/log-decorator';
import './login.scss';
import { apiActions } from '../../../slices/api-slice';
import loginBg from '../../../assets/img/bg/login_bg.jpg';
import loginLogo from '../../../assets/img/logo/ppmcloud_03_t.png';
import { dialogActions } from '../../dialog/slice/dialog-slice';
import { ResponseBase } from '../../../api/response-base';
import { LoginResponse } from '../../../models/api/back/login';
import iconEyeOn from '../../../assets/img/icon/eye_on.svg';
import iconEyeOff from '../../../assets/img/icon/eye_off.svg';
import chromeIcon from '../../../assets/img/icon/googlechrome_icon.svg';
import { debugActions } from '../../../slices/debug-slice';
import { commonActions } from '../../../slices/common-slice';
import { ApiBaseBack } from '../../../api/back/api-base-back';
import { SessionTokenManager } from '../../../manager/session-token-manager';
import { ApiBaseFront } from '../../../api/front/api-base-front';
import { TokenPostResponse } from '../../../api/front/token/api-token';
import { localStorageActions } from '../../../slices/local-storage-slice';
import { useAppSelector } from '../../../app/hooks';
import lodash from 'lodash';
import { ForgetPasswordDialog } from '../../ui/forget-password-dialog/forget-password-dialog';
import { Env } from '../../../models/env';
import iconHelpBlue from '../../../assets/img/icon/help_blue.svg';
import { webPagePath } from '../../../models/web-page-path';

// - ツールチップ表示するもの -
type InputErrorItem = 'store' | 'password' | 'machineId' | 'none';
// - 半角数値正規表現 -
const IntegerRegExp = /^[0-9]+/;
// - 大文字小文字半角英数字正規表現 -
const halfWidthAlphanumericRegExp = /^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\d)[a-zA-Z\d]+$/;
// - バリデーションチェックする -
const checkInputValidation = (type: 'store' | 'pass', inputItem: string): boolean => {
  let result: boolean;
  if (type === 'store') {
    if (!inputItem.match(IntegerRegExp) || inputItem.length !== 7) {
      result = false;
    } else {
      result = true;
    }
  } else {
    if (inputItem && (!inputItem.match(halfWidthAlphanumericRegExp) || inputItem.length < 16)) {
      result = true;
    } else {
      result = false;
    }
  }
  return result;
};

export const Login = () => {
  // - Effect -
  // -- ログまとめ --
  useEffect(() => {
    LogDecorator.group('<red>ログイン</red>');
    return () => LogDecorator.groupEnd();
  }, []);
  const { machineIdInitialValue, dialogs, isChrome, loginMode, isRedirect } = useAppSelector((state) => ({
    machineIdInitialValue: state.storage.machineId,
    dialogs: state.dialog.dialogs,
    isChrome: state.common.isChrome,
    loginMode: state.storage.loginMode,
    isRedirect: state.common.isRedirect,
  }), lodash.isEqual);
  // - Hooks -
  const dispatch = useDispatch();
  // - state -
  // -- 店舗ID --
  const [store, setStore] = useState('');
  // -- マシンID --
  const [machineId, setMachineId] = useState(machineIdInitialValue);
  // -- パスワード --
  const [password, setPassword] = useState('');
  // -- パスワード表示有無 --
  const [isDisplayPass, setIsDisplayPass] = useState(false);
  // -- xmlバージョンリクエスト情報 --
  const [xmlVersion, setXmlVersion] = useState('');
  const [xml, setXml] = useState('');
  const [version, setVersion] = useState('');
  // -- 店舗コードがバリデーションにかかっているか --
  const [isStoreError, setIsStoreError] = useState(false);
  // -- パスワードがバリデーションにかかっているか --
  const [isPasswordError, setIsPasswordError] = useState(false);
  // -- マシンIDがバリデーションにかかっているか --
  const [isMachineIdError, setIsMachineIdError] = useState(false);
  // -- ツールチップ表示するもの --
  const [tooltipItem, setTooltipItem] = useState<InputErrorItem>('none');
  // - Callback -
  // -- 店舗ID --
  const handlerChangeStore = useCallback((v) => {
    setStore(v);
  }, []);
  // -- マシンID --
  const handlerChangeMachineId = useCallback((v) => {
    if (isMachineIdError) {
      setIsMachineIdError(false);
    }
    setMachineId(v);
  }, [isMachineIdError]);
  // -- パスワード --
  const handlerChangePassword = useCallback((v) => {
    setPassword(v);
  }, []);
  // -- ログイン --
  const handlerClickLogin = useCallback(() => {
    console.log('login check...');
    if (!store || !password || !machineId) {
      dispatch(dialogActions.pushMessage({
        title: '確認',
        message: [
          '未入力の項目があります。',
        ],
        buttons: [
          {
            label: 'OK',
            callback: () => {
              console.log('login success!!');
              setIsStoreError(!checkInputValidation('store', store));
              setIsPasswordError(!checkInputValidation('pass', password));
              setIsMachineIdError(!machineId);
              dispatch(dialogActions.pop());
            },
          },
        ],
      }));
      return;
    }
    dispatch(apiActions.login(
      {
        kijshopCd: store,
        password,
      },
      {
        onSuccess: (res: ResponseBase<TokenPostResponse>) => {
          const sessionToken = res.body.data?.sessionId || '';
          const fSessionToken = res.body.data?.token || '';
          ApiBaseBack.session = sessionToken;
          ApiBaseFront.session = fSessionToken;
          new SessionTokenManager().save(sessionToken, fSessionToken);
          // dispatch(commonActions.setKijshopCd(res.body.data?.[0]?.kijshopCd || ''));
          dispatch(commonActions.getData(store));
          dispatch(localStorageActions.getLocalData(store));
          dispatch(localStorageActions.setData(store, 'shopName', res.body.data?.shopName || ''));
          dispatch(localStorageActions.setData(store, 'isAdmin', Boolean(res.body.data?.isAdmin) || false));
          dispatch(localStorageActions.setMachineId(machineId));
          dispatch(localStorageActions.setLoginMode('ADMIN'));
          dispatch(apiActions.versionCheck(
            {
              xmlVersion,
              xml,
              version,
            },
            {
              onSuccess: () => {
                dispatch(commonActions.getData(store));
                dispatch(localStorageActions.getLocalData(store));
                dispatch(push(RoutingPath.cnv.orders({ kijshopCd: store })));
              },
            },
          ));
        },
        onBasicError: (e: ResponseBase<LoginResponse>) => {
          setIsStoreError(!checkInputValidation('store', store));
          setIsPasswordError(!checkInputValidation('pass', password));
          dispatch(dialogActions.pushMessage({
            // title: '警告',
            // message: ['店舗IDもしくはパスワードが間違っています'],
            title: '確認',
            message: [
              '入力された情報は登録されていません。',
              '再度登録済みの情報を入力してください。',
            ],
            buttons: [
              {
                label: 'OK',
                callback: () => {
                  dispatch(dialogActions.pop());
                },
              },
            ],
          }));
        },
      },
    ));
  }, [store, password, machineId]);
  // -- パスワード表示有無 --
  const handlerClickDisplayPassword = useCallback(() => {
    if (!isChrome) return;
    setIsDisplayPass(prev => !prev);
  }, [isDisplayPass, isChrome]);

  const handlerClickForgetPass = useCallback(() => {
    dispatch(dialogActions.push({
      title: 'パスワードの種類を選択してください',
      element: <ForgetPasswordDialog />,
    }));
  }, []);
  const handlerClickStaffLogin = useCallback(() => {
    if (isChrome) {
      dispatch(push(RoutingPath.staffLogin));
    }
  }, [isChrome]);
  const handlerMouseOver = useCallback((type: InputErrorItem) => {
    setTooltipItem(type);
  }, []);
  // TODO 後で消す
  useEffect(() => {
    // ApiBaseBack.session = '';
    dispatch(debugActions.addEvent());
  }, []);
  useEffect(() => {
    if (!dialogs.length && (ApiBaseBack.session || ApiBaseFront.session)) {
      new SessionTokenManager().save('', '');
      window.location.reload();
    }
    // ローカルストレージからスタッフ情報を削除
    dispatch(localStorageActions.setStaffIdAndName({kijshopCd: '', id: '', name: ''}));
    dispatch(commonActions.checkBrowser());
    // MEMO 9/8のMTGでリダイレクトはしないことになったのでコメントアウト
    // if (!isRedirect) {
    //   if (loginMode === 'ADMIN') {
    //     dispatch(push(RoutingPath.login));
    //   } else {
    //     dispatch(push(RoutingPath.staffLogin));
    //   }
    //   dispatch(commonActions.setIsRedirect(true));
    // }
  }, []);
  return (
    <div className="login">
      <div className="login__inner">
        <div className="login__col1">
          <img
            src={loginBg}
            alt=""
            style={{ height: '100%', width: '100%', objectFit: 'cover' }}
          /> {/* リロード時対策でインライン指定 */}
          <img
            src={loginLogo}
            alt=""
          />
          <div className={'switch_login'}>
            <Button
              color={'blue_cyan'}
              label="スタッフIDでログインする"
              onClick={handlerClickStaffLogin}
              size={'lg'}
            />
          </div>
          <div className={'what_is_staff_management'}>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={webPagePath.whatIsStaffManagement} >
              <img
                src={iconHelpBlue}
                alt=""
              />
              スタッフ管理機能について
            </a>
          </div>
          <div className={'copywriter'}>&copy; LABONETWORK Co.,Ltd.</div>
        </div>
        <div className={'version version__admin'}>{Env.version ? `ver${Env.version}` : ''}</div>
        <div className="login__col2">
          <div className="login__col2__inner">
            <h1 className="login__col2__inner__header">
              店舗IDでログイン
            </h1>
            <div className="login__col2__inner__contents">
              <Input
                value={store}
                onChange={(e) => handlerChangeStore(e.target.value)}
                handlerEnter={handlerClickLogin}
                placeholder="店舗ID"
                uiSize="lg"
                maxLength={7}
                onMouseOver={() => handlerMouseOver('store')}
                onMouseLeave={() => handlerMouseOver('none')}
                error={isStoreError}
                tooltip={isStoreError && tooltipItem === 'store' ? { messages: ['半角数値7桁'] } : undefined}
                disabled={!isChrome}
              />
              <Input
                type={isDisplayPass ? '' : 'password'}
                value={password}
                onChange={(e) => {
                  handlerChangePassword(e.target.value);
                }}
                handlerEnter={handlerClickLogin}
                placeholder="パスワード"
                uiSize="lg"
                maxLength={15}
                onMouseOver={() => handlerMouseOver('password')}
                onMouseLeave={() => handlerMouseOver('none')}
                error={isPasswordError}
                tooltip={isPasswordError && tooltipItem === 'password' ?
                  { messages: ['大文字小文字を混ぜた半角英数字16字未満'] } : undefined}
                disabled={!isChrome}
              />
              <div className="machine-id">
                <Input
                  value={machineId}
                  onChange={(e) => handlerChangeMachineId(e.target.value)}
                  handlerEnter={handlerClickLogin}
                  placeholder="マシンID"
                  uiSize="lg"
                  maxLength={10}
                  onMouseOver={() => handlerMouseOver('machineId')}
                  onMouseLeave={() => handlerMouseOver('none')}
                  error={isMachineIdError}
                  tooltip={isMachineIdError && tooltipItem === 'machineId' ? { messages: ['任意の文字10文字以内'] } : undefined}
                  disabled={!isChrome}
                />
                <div
                  className="machine-id__help"
                  onClick={() => window.open('https://support.labonetwork.co.jp/knowledge-base/ppmcloud-049/')}
                >
                  マシンIDとは？
                </div>
              </div>
              <div
                className="login__pass_display"
                onClick={handlerClickDisplayPassword}
              >
                <img
                  src={isDisplayPass ? iconEyeOff : iconEyeOn}
                  alt=""
                />
                <span>{isDisplayPass ? 'パスワードを非表示にする' : 'パスワードを表示する'}</span>
              </div>
              <Button
                label="ログイン"
                onClick={handlerClickLogin}
                // disabled={!store || !password}
                disabled={!isChrome}
                color="warning"
                size="lg"
              />
              <p
                className="login__pass_remember"
                onClick={handlerClickForgetPass}
              >
                パスワードをお忘れの方はこちら
              </p>
            </div>
            <div className={'chrome__guide'}>
              <p>● PPMクラウドの推奨利用環境について</p>
              <p>&emsp;・動作対象ブラウザは【 Google Chrome 】です</p>
              <p>&emsp;&emsp;それ以外のブラウザでは、ご利用いただけません</p>
              <div className={'chrome__guide__link'}>
                <p>{'>'}&emsp;
                  <a href="https://support.google.com/chrome/answer/95346?hl=ja&co=GENIE.Platform%3DDesktop#zippy=">
                    Google Chromeの導入については、こちら
                  </a>
                </p>
                <img
                  src={chromeIcon}
                  alt=""
                  width={40}
                  height={40}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
