import { OrdersSliceState } from '../../../slices/orders-slice';
import { OrdersDataState } from '../../../slices/orders/orders-data-slice';
import { ProductDetailItemData, ProductDetailResponse } from '../../../models/api/back/create-order/product-detail';
import { VisibleConditionValueGetter } from '../../../manager/visible-condition-value-getter';
import { XmlStructureOrderInfoData } from '../xml-structure-model';

export type TOrderPartsDataMetaModel = {
  // order-page のパス
  path: string,
};

export type TOrderPartsDataViewModel = {
  // 連番
  id: string,
  // orderParts の Id（オプション）
  parentID: string,
  // 商材情報の商品ID(item.itemId)
  partsID: string,
  // 商材情報の商品名(item.itemName)
  name: string,
  // 本身の場合のみ main
  type?: string,
  // 表紙色選択
  cover: {
    color: string,
    colorName: string,
  },
  // 年表
  chronology: {
    chronologyID: string,
    chronologyName: string,
  },
  // ラミネート情報（商品自体では使用せず、表紙,本身,オプションのみ？）
  infoSurface: {
    laminateID: string,
    name: string,
  },
  // 表紙加工
  leaf: {
    color: string,
    colorName: string,
    text1?: string,
    text2?: string,
  },
  // 部材情報
  board: {
    componentID: string,
    componentName: string,
  },
  // 商品付加情報
  additionalInfo: string[],
  // ページ系
  pageCount: string,
  minPageCount: string,
  maxPageCount: string,
  stepPageCount: string,
  maxPageImageCount: string,
  minPageImageCount: string,
  maxImageAdd: string,
  minImageAdd: string,
  // 親商材の継承フラグ
  inheritParent: string,
};

// const leafColorInherit1 = (cover: string) => cover === 'jptg380066' || cover === 'jptg380067';
// const leafColorInherit2 = (category: string, item: string) => category === 'jp0441' || item === 'jptg303705';
// const leafColorInherit = (category: string, item: string, cover: string) => leafColorInherit1(cover) || leafColorInherit2(category, item);

// const referenceBoardInfo = (item: string) =>
//   item === 'jptg303586'
//   || item === 'jptg303587'
//   || item === 'jptg300979'
//   || item === 'jptg301763'
//   || item === 'jptg301764'
//   || item === 'jptg301765'
//   || item === 'jptg303295'
//   || item === 'jptg303632'
//   || item === 'jptg303633'
//   || item === 'jptg303762'
//   || item === 'jptg303834';
// const coverColorInherit1 = (category: string, item: string) =>
//   category === 'jp0441'
//   || item === 'jptg303705'
//   || item === 'jptg303902'
//   || item === 'jptg303903'
//   || item === 'jptg303904'
//   || item === 'jptg303913';
// const coverColorInherit2 = (item: string) =>
//   item === 'jptg301766'
//   || item === 'jptg301767'
//   || item === 'jptg301768'
//   || item === 'jptg301769';
// const coverColorInherit = (category: string, item: string, coverColor: string) => ((coverColor && coverColorInherit1(category, item)) || (referenceBoardInfo(item) && coverColorInherit2(item)));

// const coverColor2Check = (item: string) => {
//   switch (item) {
//     case 'jptg303586':
//     case 'jptg303587':
//     case 'jptg300979':
//     case 'jptg301763':
//     case 'jptg301764':
//     case 'jptg301765':
//     case 'jptg303295':
//     case 'jptg303632':
//     case 'jptg303633':
//     case 'jptg303762':
//     case 'jptg303834':
//     case 'jptg304074':
//     case 'jptg304007':
//     case 'jptg304043':
//     case 'jptg304132':
//     case 'jptg304185':
//       return false;
//     default:
//       return true;
//   }
// };

/* partsCount >= 1*/
// const coverBoardInherit1 = (opCategory: string) => {
//   switch (opCategory) {
//     case 'jp0301':
//     case 'jp0332':
//     case 'jp0335':
//     case 'jp0487':
//     case 'jp0488':
//       return true;
//     default:
//       return false;
//   }
// };
// const coverBoardInherit2 = (opItem: string) => {
//   switch (opItem) {
//     case 'jptg301766':
//     case 'jptg301767':
//     case 'jptg301768':
//     case 'jptg301769':
//       return true;
//     default:
//       return false;
//   }
// };
// const coverBoardInherit3 = (opItem: string) => {
//   switch (opItem) {
//     case 'jptg301452':
//     case 'jptg301453':
//     case 'jptg301674':
//     case 'jptg301675':
//     case 'jptg301676':
//     case 'jptg301677':
//     case 'jptg302287':
//     case 'jptg302288':
//     case 'jptg302289':
//     case 'jptg302290':
//     case 'jptg303636':
//       return true;
//     default:
//       return false;
//   }
// };
// const coverBoardInherit4 = (opCategory: string, ) => {
//   switch (opCategory) {
//     case 'jp0452':
//       return true;
//     default:
//       return false;
//   }
// };

// partsCount >= 2
// const coverBoardInherit1Parts2 = (opItem: string) => {
//   switch (opItem) {
//     case 'jptg301730':
//     case 'jptg301731':
//     case 'jptg301732':
//     case 'jptg301733':
//     case 'jptg301602':
//     case 'jptg301603':
//     case 'jptg301728':
//     case 'jptg301729':
//       return true;
//     default:
//       return false;
//   }
// };
// partsCount >= 3
// const coverBoardInherit1Part3 = (category: string) => {
//   switch (category) {
//     case 'jp0487':
//     case 'jp0488':
//       return true;
//     default:
//       return false;
//   }
// };
// 特殊店舗の最大ページ数対応
const formatMaxPageImageCount = (shopCode: string, genuineData: ProductDetailItemData) => {
  switch (shopCode) {
    case '0057288':
      switch (genuineData.itemId) {
        case 'jpit300001':
          return '10';
        default :
          return genuineData.maxPageImageCount;
      }
    default:
      return genuineData.maxPageImageCount;
  }
};


const coverBoardInherit = (category: string, coverColor: string, opCategory: string, opItem: string, _partsCount: string, order: OrdersSliceState, data: OrdersDataState, visibleCondition: VisibleConditionValueGetter) => {
  const partsCount = Number(_partsCount);
  const arr: boolean[] = [];
  if (partsCount >= 1) {
    arr.push(
      // Boolean(coverColor) && coverBoardInherit1(opCategory), // TODO
      Boolean(coverColor) && Boolean(visibleCondition.checkContentVisible(order, data, 'op01op1c_sap_coverColorReferenceBoardInfo', visibleCondition.visibleData.op01_option.op01o_parts1.op01op1c_sap_coverColorReferenceBoardInfo, { hidden: true })),
      // coverBoardInherit2(opItem), // TODO
      Boolean(visibleCondition.checkContentVisible(order, data, 'op01op1c_sap_coverColorReferenceBoardInfo2', visibleCondition.visibleData.op01_option.op01o_parts1.op01op1c_sap_coverColorReferenceBoardInfo, { hidden: true })),
      // coverBoardInherit3(opItem), // TODO
      Boolean(visibleCondition.checkContentVisible(order, data, 'op01op1_boardInfo', visibleCondition.visibleData.op01_option.op01o_parts1.op01op1_boardInfo, { hidden: true })),
    );
  }
  return Boolean(arr.find((v) => v));
};

const genuineBoardInherit = (category: string, opCategory: string, opItem: string, _partsCount: string, order: OrdersSliceState, data: OrdersDataState, visibleCondition: VisibleConditionValueGetter, opIndex?: number) => {
  const partsCount = Number(_partsCount);
  const arr: boolean[] = [];
  if (partsCount >= 2) {
    // arr.push(coverBoardInherit1Parts2(opItem)); // TODO
    arr.push(Boolean(visibleCondition.checkContentVisible(order, data, 'op01op2_boardInfo', visibleCondition.visibleData.op01_option.op01o_parts2.op01op2_boardInfo, {index: opIndex, hidden: true })))
  }
  if (partsCount >= 3) {
    arr.push(Boolean(visibleCondition.checkContentVisible(order, data, 'op01op3_boardInfoReference', visibleCondition.visibleData.op01_option.op01o_parts3?.op01op3_boardInfoReference, {index: opIndex, hidden: true })))
    // arr.push(coverBoardInherit1Part3(category)); // TODO
  }
  return Boolean(arr.find((v) => v));
};

// const genuineAdditionalInherit = (opCategory: string, opItem: string) => {
//   switch (opItem) {
//     case 'jptg303705':
//     case 'jptg303902':
//     case 'jptg303903':
//     case 'jptg303904':
//     case 'jptg303913':
//       return true;
//   }
//   return opCategory === 'jp0441';
// };

export const createOrderPartsDataViewModel = (shopCode: string, order: OrdersSliceState, data: OrdersDataState, productDetail: Partial<ProductDetailResponse>, option: boolean, visibleCondition: VisibleConditionValueGetter, optionData?: { optionIndex: number, parentOrderInfoStructure?: XmlStructureOrderInfoData }): {
  cover?: Partial<TOrderPartsDataViewModel>,
  genuine?: Partial<TOrderPartsDataViewModel>,
} => {
  const {
    itemDetail,
    cover,
    genuine,
  } = order;
  const { optionIndex, parentOrderInfoStructure } = optionData || { optionIndex: undefined, parentOrderInfoStructure: undefined };
  // const productDetail = data.data.productDetail;
  const coverData = productDetail.item?.find((v) => String(v.itemId) === (option ? data.option.cover[optionIndex || 0]?.itemId : data.cover.parts.itemId));
  const genuineData = productDetail.item?.find((v) => String(v.itemId) === (option ? data.option.genuine[optionIndex || 0]?.itemId : data.genuine.parts.itemId));
  // const itemDetailDisplay = itemDetailDisplayItem(order, data);
  // const coverDisplay = coverOrderDisplayItem(order, data);
  // const genuineDisplay = genuineOrderDisplayItem(order, data);
  const itemDetailDisplay = visibleCondition.itemDetailOrderDisplay;
  const coverDisplay = visibleCondition.coverOrderDisplay;
  const genuineDisplay = visibleCondition.genuineOrderDisplay;
  const op = option && optionIndex !== undefined ? order.option[optionIndex] : undefined;

  return {
    cover: coverData && (!option || coverData.inheritParent !== 'true' || parentOrderInfoStructure?.parts?.partsData?.find((v) => v.xml.xmlModel.lnwOrderPartsData.data?.[0]?.$.type !== 'main')) && {
      id: '1',
      partsID: coverData.itemId,
      name: coverData.itemName,
      parentID: (option && (coverData.inheritParent !== '' && coverData.inheritParent !== 'false')) ? '1' : undefined,
      // ラミ
      infoSurface: option ? (op?.cover?.laminate?.value ? {
        laminateID: op.cover.laminate.value,
        name: op.cover.laminate.label,
      } : undefined) : (coverDisplay.laminate ? {
        laminateID: cover.laminate.value,
        name: cover.laminate.label,
      } : undefined),
      // 色
      cover: option ? (
        // itemDetailDisplay.coverColor && coverColorInherit(op?.item?.serviceId || '', op?.item?.value || '', itemDetail.coverColorSelect.value) ? {
        itemDetailDisplay.coverColor && itemDetail.coverColorSelect.value && (visibleCondition.checkContentVisible(order, data, 'op01op1c_sap_coverColorReference', visibleCondition.visibleData.op01_option.op01o_parts1.op01op1c_sap_coverColorReference, { index: optionIndex, hidden: true })) ? {
          color: itemDetail.coverColorSelect.value,
          colorName: itemDetail.coverColorSelect.label,
        } : (coverDisplay.color && cover.color.value && visibleCondition.checkContentVisible(order, data, 'op01op1c_sap_coverColorReference2', visibleCondition.visibleData.op01_option.op01o_parts1.op01op1c_sap_coverColorReference2, { index: optionIndex, hidden: true }) ? {
          // visibleCondition.checkContentVisible(order, data, 'op01c_coverColor', visibleCondition.visibleData.op01_cover.op01c_coverColor)
          color: cover.color.value,
          colorName: cover.color.label,
        } : undefined)
      ) : (coverDisplay.color ? {
        color: cover.color.value,
        colorName: cover.color.label,
      } : ((itemDetailDisplay.coverColor && itemDetail.coverColorSelect.value) ? {
        /* 商品詳細の表紙色選択が選択されている場合はその値を使用　(masterProductSelectSql:1605,1616) */
        color: itemDetail.coverColorSelect.value,
        colorName: itemDetail.coverColorSelect.label,
      } : undefined)),
      // 部材
      // board: option ? (((itemDetailDisplay.coverColor && itemDetail.coverColorSelect.value) && ((visibleCondition.checkContentVisible(order, data, 'op01c_coverColorReferenceBoardInfo2', visibleCondition.visibleData.op01_option.op01o_parts1.op01op1c_sap_coverColorReferenceBoardInfo2) || visibleCondition.checkContentVisible(order, data, 'op01op1c_sap_coverColorReference', visibleCondition.visibleData.op01_option.op01o_parts1.op01op1c_sap_coverColorReference)) || visibleCondition.checkContentVisible(order, data, 'op01op1c_sap_coverColorReference2', visibleCondition.visibleData.op01_option.op01o_parts1.op01op1c_sap_coverColorReference2)))  ? {
      //   componentID: itemDetail.coverColorSelect.value,
      //   componentName: itemDetail.coverColorSelect.label,
      // // } : ((itemDetailDisplay.board && itemDetail.boardSelect.value) && coverBoardInherit(itemDetail.itemSelect.value, itemDetail.coverColorSelect.value, op?.item?.serviceId || '', op?.item?.value || '', op?.partsCount || '') ? {
      // // } : ((itemDetailDisplay.board && itemDetail.boardSelect.value) &&  ? {
      // //   componentID: itemDetail.boardSelect.value,
      // //   componentName: itemDetail.boardSelect.label,
      // } : (itemDetail.boardSelect.value && (coverBoardInherit(itemDetail.itemSelect.value, itemDetail.coverColorSelect.value, op?.item?.serviceId || '', op?.item?.value || '', op?.partsCount || '', order, data, visibleCondition)) ? {
      //   componentID: itemDetail.boardSelect.value,
      //   componentName: itemDetail.boardSelect.label,
      // } : undefined)) : coverDisplay.color ? {
      board: option ? (visibleCondition.checkContentVisible(order, data, 'op01op1c_sap_coverColorReferenceBoardInfo', visibleCondition.visibleData.op01_option.op01o_parts1.op01op1c_sap_coverColorReferenceBoardInfo, { index: optionIndex, hidden: true }) && itemDetailDisplay.coverColor ? {
        componentID: itemDetail.coverColorSelect.value,
        componentName: itemDetail.coverColorSelect.label,
      } : (visibleCondition.checkContentVisible(order, data, 'op01op1c_sap_coverColorReferenceBoardInfo2', visibleCondition.visibleData.op01_option.op01o_parts1.op01op1c_sap_coverColorReferenceBoardInfo2, { index: optionIndex, hidden: true }) && coverDisplay.color) ? {
        componentID: cover.color.value,
        componentName: cover.color.label,
      } : ((visibleCondition.checkContentVisible(order, data, 'op01op1_boardInfo', visibleCondition.visibleData.op01_option.op01o_parts1.op01op1_boardInfo, { index: optionIndex, hidden: true })) && itemDetail.boardSelect) ? {
        componentID: itemDetail.boardSelect.value,
        componentName: itemDetail.boardSelect.label,
      } : undefined) : coverDisplay.color ? {
        componentID: cover.color.value,
        componentName: cover.color.label,
      } : (itemDetailDisplay.coverColor && itemDetail.coverColorSelect.value ? {
        componentID: itemDetail.coverColorSelect.value,
        componentName: itemDetail.coverColorSelect.label,
      } : undefined),
      // 表紙加工
      // leaf: option ? (((visibleCondition.checkContentVisible(order, data, 'op01oc_leafColor1', undefined) || visibleCondition.checkContentVisible(order, data, 'op01oc_leafColor2', visibleCondition.visibleData.op01_option.op01o_parts1.op01oc_leafColor2)) && (data.cover.foilStamping.leafColor?.[0]?.leafColorId || data.cover.foilStamping.leafColor?.[0]?.leafColorName)) ? {
      leaf: option ?
        ((visibleCondition.checkContentVisible(order, data, 'op01oc_leafColor2', visibleCondition.visibleData.op01_option.op01o_parts1.op01oc_leafColor2, { index: optionIndex, hidden: true })
        && (data.cover.foilStamping.leafColor?.[0]?.leafColorId || data.cover.foilStamping.leafColor?.[0]?.leafColorName)) ?
          (!visibleCondition.checkContentVisible(order, data, 'op01op1c_sap_leafColor', visibleCondition.visibleData.op01_option.op01o_parts1.op01op1c_sap_leafColor, { index: optionIndex, hidden: true }) && visibleCondition.checkContentVisible(order, data, 'op01c_leafText1', visibleCondition.visibleData.op01_cover.op01c_leafText1)) ? undefined : {
        color: visibleCondition.checkContentVisible(order, data, 'op01op1c_sap_leafColor', visibleCondition.visibleData.op01_option.op01o_parts1.op01op1c_sap_leafColor, { index: optionIndex, hidden: true }) ? data.cover.foilStamping.leafColor?.[0]?.leafColorId || '' : cover.coverProcessing.value,
        colorName: visibleCondition.checkContentVisible(order, data, 'op01op1c_sap_leafColor', visibleCondition.visibleData.op01_option.op01o_parts1.op01op1c_sap_leafColor, { index: optionIndex, hidden: true }) ? data.cover.foilStamping.leafColor?.[0]?.leafColorName || '' : cover.coverProcessing.label,
        text1: visibleCondition.checkContentVisible(order, data, 'op01c_leafText1', visibleCondition.visibleData.op01_cover.op01c_leafText1) ? cover.leafText1 : undefined,
        text2: visibleCondition.checkContentVisible(order, data, 'op01c_leafText2', visibleCondition.visibleData.op01_cover.op01c_leafText2) ? cover.leafText2 : undefined,
      } : undefined) : (coverDisplay.leafColor ? (cover.coverProcessing?.value ? {
        color: cover.coverProcessing.value,
        colorName: cover.coverProcessing.label,
        text1: cover.leafText1 || undefined,
        text2: cover.leafText2 || undefined,
      } : undefined) : ((itemDetail?.coverColorSelect?.value && data.cover.foilStampingAfterProcessing.coverColorPostPrintRel?.[0]?.coverColorPostPrintRelId) ? {
        /* 後加工情報が有効な場合はその値を使用(masterProductSelectSql:1698) */
        color: data.cover.foilStampingAfterProcessing.coverColorPostPrintRel[0].coverColorPostPrintRelId,
        colorName: data.cover.foilStampingAfterProcessing.coverColorPostPrintRel[0].coverColorPostPrintRelName,
      } : undefined)),
      // ページ系
      pageCount: String((() => {
        const v = Math.max(...coverData.pageInfo.map((p) => Number(p.pageCount || 0)));
        if (v > 0 && v <= 9999) {
          return v;
        }
        return 0;
      })() || '') || undefined,
      maxPageImageCount: coverData.maxPageImageCount || undefined,
      minPageImageCount: coverData.minPageImageCount || undefined,
      maxImageAdd: coverData.maxImageCountAdd || undefined,
      minImageAdd: coverData.minImageCountAdd || undefined,
      inheritParent: coverData.inheritParent || undefined,
    },
    genuine: genuineData && (!option || genuineData.inheritParent !== 'true' || parentOrderInfoStructure?.parts?.partsData?.find((v) => v.xml.xmlModel.lnwOrderPartsData.data?.[0]?.$.type === 'main')) ? {
      id: coverData ? '2' : '1',
      partsID: genuineData.itemId,
      name: genuineData.itemName,
      type: !option ? 'main' : undefined,
      parentID: (option && (genuineData.inheritParent !== '' && genuineData.inheritParent !== 'false')) ? (coverData?.inheritParent === '' || coverData?.inheritParent === 'false' ? '1' : '2') : undefined,
      // 部材
      board: (!option && itemDetail.boardSelect.value && itemDetailDisplay.board ? {
          componentID: itemDetail.boardSelect.value,
          componentName: itemDetail.boardSelect.label,
        } : (genuineBoardInherit( itemDetail.itemSelect.value, op?.item?.serviceId || '', op?.item?.value || '', op?.partsCount || '', order, data, visibleCondition, optionIndex) && itemDetail.boardSelect.value ? {
          componentID: itemDetail.boardSelect.value,
          componentName: itemDetail.boardSelect.label,
        } : undefined)),

      // 表紙加工
      leaf: (!option && genuineDisplay.leafColor ? (genuine.genuineProcessing?.value ? {
          color: genuine.genuineProcessing.value,
          colorName: genuine.genuineProcessing.label,
          text1: genuine.leafText1 || undefined,
          text2: genuine.leafText2 || undefined,
        } : undefined) : undefined),


      // ラミ
      infoSurface: option ? (op?.genuine?.laminate?.value ? {
        laminateID: op.genuine.laminate.value,
        name: op.genuine.laminate.label,
      } : undefined) : (genuineDisplay.laminate ? {
        laminateID: genuine.laminate.value,
        name: genuine.laminate.label,
      } : undefined),
      // 見返し選択
      additionalInfo: !option ? (
        ((genuineDisplay.additional || genuineDisplay.additional2 || genuineDisplay.additional3) && genuine.additional.value) ? [genuine.additional.value] : ((genuineDisplay.additionalMulti && genuine.additionalMulti) ? [genuine.additionalMulti] : undefined)
      ) : (
        ((data.data.optionAdditional.find((v) => v?.itemId === op?.item.value))?.additionalInfos?.length) && (op?.partsCount && Number(op.partsCount) >= 2 && genuineDisplay.additional && genuine.additional.value && (visibleCondition.checkContentVisible(order, data, 'op01op1c_sap_coverColorReference', visibleCondition.visibleData.op01_option.op01o_parts1.op01op1c_sap_coverColorReference) || genuineData.inheritParent === 'true')) ?
          [genuine.additional.value] :
          ((data.data.optionAdditional.find((v) => v?.itemId === op?.item.value))?.additionalInfos?.length) && (op?.partsCount && Number(op.partsCount) >= 2 && genuineDisplay.additional && genuine.additional.value) ?
            [genuine.additional.value] :
            visibleCondition.checkContentVisible(order, data, 'op01op1b_sap_additionalInfo_single', visibleCondition.visibleData.op01_option.op01o_parts2.op01op1b_sap_additionalInfo_single, { index: optionIndex, hidden: true }) ?
              [genuine.additional.value] :
              undefined
      ),
      // 年表
      chronology: (!option && genuineDisplay.chronology && genuine.chronologies.value) ? {
        chronologyID: genuine.chronologies.value,
        chronologyName: genuine.chronologies.label,
      } : undefined,
      // ページ系
      pageCount: genuineDisplay.page ? String(genuine.page) : undefined,
      maxPageCount: !option ? data.data.pageCount.maxPageNum : undefined,
      minPageCount: !option ? data.data.pageCount.minPageNum : undefined,
      maxPageImageCount: formatMaxPageImageCount(shopCode, genuineData) || undefined,
      minPageImageCount: genuineData.minPageImageCount || undefined,
      maxImageAdd: genuineData.maxImageCountAdd || undefined,
      minImageAdd: genuineData.minImageCountAdd || undefined,
      stepPageCount: !option ? data.data.pageCount.stepPageNum : undefined,
      inheritParent: genuineData.inheritParent || undefined,
    } : undefined,
  };
};
