import { ApiBaseFront } from '../api-base-front';

export class ApiAllDeleteXml extends ApiBaseFront {
  constructor(kijshopCd: string, shopOrderId: string) {
    super(
      'DELETE',
      '/api/v1/orderxml',
      'JSON',
      {
        kijshopCd,
        shopOrderId,
      },
    );
  }
}