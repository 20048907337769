import * as lodash from 'lodash';
import { useEffect, useMemo } from 'react';
import { useAppSelector } from "../../../app/hooks";
import './connection-status-bar.scss';

export const ConnectionStatusBar = () => {
  const { connecting } = useAppSelector((state) => ({
    connecting: state.api.connecting,
  }), lodash.isEqual);

  const path = useMemo(() => {
    return window.location.href;
  }, [window.location.href]);

  const bgColor = useMemo(() => {
    const pathChildren = path.split('/');
    for (const p of pathChildren) {
      if (p.indexOf('layout') !== -1 || p.indexOf('retouch') !== -1) {
        return '#a8baf0';
      }
    }
    return '#7392F0';
  }, [path]);

  useEffect(() => {
    console.log({ bgColor });
  }, [bgColor]);

  return connecting ? (
    <div className="connection_status_bar" style={{ color: bgColor }} />
  ) : (<></>);

};