import { XmlClass } from './xml-class';
import { ISummary } from '../interface/i-summary';
import { XmlClassName } from '../model/xml-class-name';
import { XmlStructureModel } from '../model/xml-structure-model';

export class SummaryXml extends XmlClass<ISummary> {

  name: XmlClassName = 'summary';

  viewModel: any;
  metaModel: any;

  private xmlClass: Partial<XmlStructureModel> = {};

  /**
   * summary のみ他XMLを元に生成するため依存性注入
   * @param obj
   */
  di(obj: Partial<XmlStructureModel>) {
    this.xmlClass = {
      ...this.xmlClass,
      ...obj,
    };
  }

  protected init() {
    this.xmlUniqueName = this.xmlUniqueName || `PPM_${this.shopOrderId}_11_11_summary.xml`;
    this.xmlParentName = this.xmlParentName || `PPM_${this.shopOrderId}_11_11.xml`;
    this.rootTagModel = {
      ...this.rootTagModel,
      parentXPath: '/lnwOrder/order/summary',
      parentPath: this.xmlParentName,
    };
    this.docking();
  }

  protected docking() {
    if (!this.xmlClass) {
      return;
    }
    const {
      info,
      status,
      customer,
      shop,
      delivery,
      orderSelect,
      // orderInfoDataArr,
      orderInfo,
    } = this.xmlClass;
    this.xmlModel = {
      lnwSummary: {
        $: { ...this.rootTagModel },
        summary: [{
          // オーダー情報
          order: info && [{
            $: {
              ...(() => {
                const { orderGUID, ...obj } = info.xmlModel.lnwOrder.order?.[0].$ as any;
                return obj;
              })(),
            },
            // status
            status: status && [{ order: status.xmlModel.lnwStatus.order }],
            // customer
            customer: customer && customer.xmlModel.lnwCustomer.customer,
            // shop
            shop: shop && shop.xmlModel.lnwShop.shop,
            // delivery
            delivery: delivery && delivery.xmlModel.lnwDelivery.delivery,
            // orderSelect
            // inputGroup: orderSelect && orderSelect.xmlModel.lnwOrderSelect.inputGroup,
            // inputGroup: orderSelect && [],
            // orderSelect: orderSelect && orderSelect.xmlModel.lnwOrderSelect.orderSelect,
            orderSelect: orderSelect && [{
              $: orderSelect.xmlModel.lnwOrderSelect.orderSelect?.[0]?.$ || {},
              inputGroup: [''],
              data: orderSelect.xmlModel.lnwOrderSelect.orderSelect?.[0]?.data && [...orderSelect.xmlModel.lnwOrderSelect.orderSelect[0].data].map((orderSelect) => ({
                $: orderSelect.$.selectID ? { selectID: orderSelect.$.selectID } : {},
                selectFileName: orderSelect.selectFileName?.map((selectFileName) => ({
                  real: [{ $: { path: selectFileName.real?.[0]?.$.path } }],
                })),
                originalFileName: orderSelect.originalFileName?.map((originalFileName) => ({
                  real: [{ $: { path: originalFileName.real?.[0]?.$.path } }],
                })),
              })),
            }],
            // orderInfoData
            orderInfo: orderInfo?.infoData?.length ? [{
              $: {
                count: `${orderInfo?.infoData?.length || 0}`,
              },
              orderInfoData: [{
                $: {
                  count: `${orderInfo?.infoData?.length || 0}`,
                },
                data: orderInfo?.infoData && [...orderInfo?.infoData].map((orderInfo) => ({
                  $: {
                    id: orderInfo.xml.xmlModel.lnwOrderInfoData.data?.[0]?.$.id,
                    path: orderInfo.xml.xmlModel.lnwOrderInfoData.orderPageBreak?.[0]?.$.path,
                    parentID: orderInfo.xml.xmlModel.lnwOrderInfoData.data?.[0]?.$?.parentID,
                  },
                  orderPageBreak: orderInfo.pageBreak ? [''] : undefined,
                  deliveryData: orderInfo.xml.xmlModel.lnwOrderInfoData.data?.[0]?.deliveryData,
                  executeProcess: orderInfo.xml.xmlModel.lnwOrderInfoData.data?.[0]?.executeProcess,
                  surrogateProcess: orderInfo.xml.xmlModel.lnwOrderInfoData.data?.[0]?.surrogateProcess,
                  isNeedAgreement: orderInfo.xml.xmlModel.lnwOrderInfoData.data?.[0]?.isNeedAgreement,
                  category: orderInfo.xml.xmlModel.lnwOrderInfoData.data?.[0]?.category,
                  item: orderInfo.xml.xmlModel.lnwOrderInfoData.data?.[0]?.item,
                  goods: orderInfo.xml.xmlModel.lnwOrderInfoData.data?.[0]?.goods,
                  designType: orderInfo.xml.xmlModel.lnwOrderInfoData.data?.[0]?.designType,
                  infoOutputCount: orderInfo.xml.xmlModel.lnwOrderInfoData.data?.[0]?.infoOutputCount,
                  totalPageCount: orderInfo.xml.xmlModel.lnwOrderInfoData.data?.[0]?.totalPageCount,
                  openDirection: orderInfo.xml.xmlModel.lnwOrderInfoData.data?.[0]?.openDirection,
                  remarks: orderInfo.xml.xmlModel.lnwOrderInfoData.data?.[0]?.remarks,
                  orderParts: orderInfo.parts?.partsData?.length ? [{
                    $: {
                      count: `${orderInfo.parts?.partsData?.length || 0}`,
                    },
                    orderPartsData: orderInfo.parts?.partsData?.length ? [{
                      $: {
                        count: `${orderInfo.parts?.partsData?.length || 0}`,
                        // count: 'count',
                      },
                      data: orderInfo.parts?.partsData && [...orderInfo.parts?.partsData].map((orderParts, i) => ({
                        $: {
                          // id: orderParts.xml.xmlModel.lnwOrderPartsData.data?.[0]?.$?.id,
                          id: `${i + 1}`,
                          parentID: orderParts.xml.xmlModel.lnwOrderPartsData.data?.[0]?.$?.parentID,
                          partsID: orderParts.xml.xmlModel.lnwOrderPartsData.data?.[0]?.$?.partsID,
                          name: orderParts.xml.xmlModel.lnwOrderPartsData.data?.[0]?.$?.name,
                          type: orderParts.xml.xmlModel.lnwOrderPartsData.data?.[0]?.$?.type,
                        },
                        cover: orderParts.xml.xmlModel.lnwOrderPartsData.data?.[0]?.cover,
                        infoSurface: orderParts.xml.xmlModel.lnwOrderPartsData.data?.[0]?.infoSurface,
                        leaf: orderParts.xml.xmlModel.lnwOrderPartsData.data?.[0]?.leaf,
                        board: orderParts.xml.xmlModel.lnwOrderPartsData.data?.[0]?.board,
                        // additionalInfo: orderParts.xml.xmlModel.lnwOrderPartsData.data?.[0]?.additionalInfo,
                        maxPageImageCount: orderParts.xml.xmlModel.lnwOrderPartsData.data?.[0]?.maxPageImageCount,
                        minPageImageCount: orderParts.xml.xmlModel.lnwOrderPartsData.data?.[0]?.minPageImageCount,
                        pageCount: orderParts.xml.xmlModel.lnwOrderPartsData.data?.[0]?.pageCount,
                        maxImageAdd: orderParts.xml.xmlModel.lnwOrderPartsData.data?.[0]?.maxImageAdd,
                        minImageAdd: orderParts.xml.xmlModel.lnwOrderPartsData.data?.[0]?.minImageAdd,
                        orderPage: orderParts.page?.pageData?.length ? [{
                          $: {
                            count: `${orderParts.page?.pageData?.length || 0}`,
                          },
                          // data: {
                          //   $: {
                          //     id: '',
                          //     path: '',
                          //   },
                          // },
                          data: orderParts.page?.xml.xmlModel.lnwOrderPage.orderPage?.[0]?.data,
                          orderPageData: orderParts.page?.pageData?.length ? [{
                            $: {
                              count: `${orderParts.page?.pageData?.length || 0}`,
                            },
                            data: orderParts.page?.pageData && [...orderParts.page.pageData].map((orderPage) => ({
                              $: {
                                id: orderPage.xmlModel.lnwOrderPageData.data?.[0]?.$.id,
                                pageNo: orderPage.xmlModel.lnwOrderPageData.data?.[0]?.$.pageNo,
                                pageType: orderPage.xmlModel.lnwOrderPageData.data?.[0]?.$.pageType,
                                pageIndex: orderPage.xmlModel.lnwOrderPageData.data?.[0]?.$.pageIndex,
                                pageCount: orderPage.xmlModel.lnwOrderPageData.data?.[0]?.$.pageCount,
                                displayPageNo: orderPage.xmlModel.lnwOrderPageData.data?.[0]?.$.displayPageNo,
                                displayPageType: orderPage.xmlModel.lnwOrderPageData.data?.[0]?.$.displayPageType,
                                parentID: orderPage.xmlModel.lnwOrderPageData.data?.[0]?.$.parentID,
                              },
                              template: orderPage.xmlModel.lnwOrderPageData.data?.[0]?.template?.[0]?.$ ? [{
                                $: { path: orderPage.xmlModel.lnwOrderPageData.data?.[0]?.template?.[0]?.$.path, name: orderPage.xmlModel.lnwOrderPageData.data?.[0]?.template?.[0]?.$.name },
                              }] : undefined,
                              compositeFileName: (orderPage.xmlModel.lnwOrderPageData.data?.[0]?.compositeFileName?.[0]?.virtual?.[0]?.$?.path || orderPage.xmlModel.lnwOrderPageData.data?.[0]?.compositeFileName?.[0]?.real?.[0]?.$?.path) ? [{
                                virtual: orderPage.xmlModel.lnwOrderPageData.data?.[0]?.compositeFileName?.[0]?.virtual?.[0]?.$?.path ? [{ $: { path: orderPage.xmlModel.lnwOrderPageData.data[0].compositeFileName[0].virtual[0].$.path } }] : undefined,
                                real: orderPage.xmlModel.lnwOrderPageData.data?.[0]?.compositeFileName?.[0]?.real?.[0]?.$?.path ? [{ $: { path: orderPage.xmlModel.lnwOrderPageData.data[0].compositeFileName[0].real[0].$.path } }] : undefined,
                              }] : undefined,
                              pageOutputCount: orderPage.xmlModel.lnwOrderPageData.data?.[0]?.pageOutputCount,
                              orderPicture: orderPage.xmlModel.lnwOrderPageData.data?.[0]?.orderPicture ? orderPage.xmlModel.lnwOrderPageData.data?.[0]?.orderPicture.map((orderPicture) => ({
                                $: { count: orderPicture.$.count },
                                data: orderPicture.data?.map((data) => ({
                                  $: { ...data.$ },
                                  selectImage: data.selectImage,
                                  compositeFlag: data.compositeFlag,
                                  logoFileName: data.logoFileName,
                                  subMaskRect: data.subMaskRect,
                                  subMaskRotate: data.subMaskRotate,
                                  pictureRect: data.pictureRect,
                                  pictureRotate: data.pictureRotate,
                                  // レタッチ用に selectID のみかチェック
                                  zoom: (
                                    data.compositeFlag
                                    || data.subMaskRect
                                    || data.subMaskRotate
                                    || data.pictureRect
                                    || data.pictureRotate
                                  ) ? [''] : undefined,
                                })),
                              })) : undefined,
                              // orderFrame: orderPage.xmlModel.lnwOrderPageData.data?.[0]?.orderFrame,
                              orderText: orderPage.xmlModel.lnwOrderPageData.data?.[0]?.orderText,
                            })),
                          }] : undefined,
                        }] : undefined,
                      })),
                    }] : undefined,
                  }] : undefined,
                })),
              }],
            }] : undefined,
          }],
        }],
      }
    };
  }

  // 他のXMLからビルドするためパースは実装しない
  protected split(x: ISummary) {
  }

}