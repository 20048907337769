import { FavoriteFolderDeleteRequest, FavoriteFolderGetRequest, FavoriteFolderPostRequest } from "../../../models/api/front/favorite-folder";
import { ApiBaseFront } from "../api-base-front";

// お気に入りフォルダ一覧取得
export class ApiFavoriteFolderGet extends ApiBaseFront {
  constructor(param: FavoriteFolderGetRequest) {
    super(
      'GET',
      '/api/v1/favfolder',
      'JSON',
      param,
    );
  }
}

// お気に入りフォルダ追加・更新（更新の場合はid必要）
export class ApiFavoriteFolderPost extends ApiBaseFront {
  constructor(param: FavoriteFolderPostRequest, folderId?: string) {
    super(
      'POST',
      `/api/v1/favfolder${folderId ? `/${folderId}` : ''}`,
      'JSON',
      param,
    );
  }
}

// お気に入りフォルダ削除
export class ApiFavoriteFolderDelete extends ApiBaseFront {
  constructor(param: FavoriteFolderDeleteRequest, folderId: string) {
    super(
      'DELETE',
      `/api/v1/favfolder/${folderId}`,
      'JSON',
      param,
    );
  }
}
