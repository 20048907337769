import { EditableImage } from './editable-image';
import { EditableImageManager } from '../editable-image/editable-image.manager';
import { FrameController } from './frame/frame-controller';
import { ImageController } from './image/image-controller';
import { ImageEvent } from './image/image-event';
import { TextController } from './text/text-controller';
import { LayoutDataManager } from '../data/layout-data.manager';
import { CjContainer, CjShape, CjStage } from '../../model/cj-factory';
import { CJ_CONTAINER_NAME, CJ_DPOBJ_NAME } from '../../model/cj-factory/cj-obj-name.collection';
import { CjTool } from '../../../utilities/cj-tool';
import * as lodash from 'lodash';
import { EditableEvent } from './editable-event';
import { ListenerIdsType } from './image/image-editable-tool';
import { UiManager } from '../ui/ui.manager';
import { setAdditional } from '../../../slices/orders/genuine/genuine';
import { ImageEditManager } from './image-edit.manager';
import { store } from '../../../app/store';
import { AlbumManager } from '../../albam/albam';
import { cloneDeep, lowerFirst } from 'lodash';
import { AdditionalShowType } from '../../../components/pages/layout/toolbar/layout.toolbar';
import { LayoutInitType } from '../../../components/pages/layout/edit-template/layout.edit-template';
import { CjGraphics } from '../../model/cj-factory/cj-graphics';

type CenterLineType = {
  lengthLine: CjShape | null,
  sideLine: CjShape | null,
}

type CursorType = {
  default: string,
  hover: string,
  move: string,
  text: string,
}

export class EditableSideTool {
  private stage: CjContainer;
  private readonly pageId: string;

  private imageEditManager!: ImageEditManager;
  private editableImageManager!: EditableImageManager;
  private frameController!: FrameController;
  private imageController!: ImageController;
  private imageEvent!: ImageEvent;
  private textController!: TextController;
  private layoutDataManager!: LayoutDataManager;
  private editableEvent!: EditableEvent;
  private albumManager!: AlbumManager;

  private centerLines: CenterLineType[] = [];
  public cursors: CursorType = {
    default: '',
    hover: 'grab',
    move: 'grabbing',
    text: 'text',
  };
  private cursorListener: ListenerIdsType = {
    moveStart: undefined,
    moving: undefined,
    moveEnd: undefined,
  };

  private additionalShowType: AdditionalShowType = 'show';
  // mode: 'pan-zoom' | 'select' = 'pan-zoom';

  public dragPointX: number = 0;
  public dragPointY: number = 0;
  public isCenterLine: boolean = false;

  constructor(stage: CjContainer, pageId: string) {
    this.stage = stage;
    // this.fbCanvas = fbCanvas;
    this.pageId = pageId;
  }

  di(
    imageEditMng: ImageEditManager,
    frameController: FrameController,
    imageController: ImageController,
    editableMng: EditableImageManager,
    imageEvent: ImageEvent,
    textController: TextController,
    layoutDataManager: LayoutDataManager,
    editableEvent: EditableEvent,
    albumMng: AlbumManager,
  ): void {
    this.imageEditManager = imageEditMng;
    this.frameController = frameController;
    this.imageController = imageController;
    this.editableImageManager = editableMng;
    this.imageEvent = imageEvent;
    this.textController = textController;
    this.layoutDataManager = layoutDataManager;
    this.editableEvent = editableEvent;
    this.albumManager = albumMng;
  }

  initialize(): void {
  }

  destroy(): void {
    this.centerLines = [];
    this.dragPointX = 0;
    this.dragPointY = 0;
  }

  update(stage: CjContainer): void {
    this.stage = stage;
  }

  // - canvas 操作用ロック -
  lock() {
    if (!this.stage) return;
    this.imageEvent.isSelect = false;
  }

  unlock() {
    if (!this.stage) return;
    this.imageEvent.isSelect = true;
    // document.body.style.cursor = this.cursors.default;
  }

  // - 中心線の追加 -
  addCenterLine() {
    const imageAreas = this.layoutDataManager.selector('getImageAreas', this.pageId);
    if (!imageAreas) return;
    this.isCenterLine = true;
    imageAreas.forEach((imaCon: CjContainer) => {
      const line = CjTool.getCenterLine(imaCon);
      line.alpha = 1;
    });
  }

  // 中心線解除
  removeCenterLine() {
    const imageAreas = this.layoutDataManager.selector('getImageAreas', this.pageId);
    if (!imageAreas) return;
    this.isCenterLine = false;
    imageAreas.forEach((imaCon: CjContainer) => {
      const line = CjTool.getCenterLine(imaCon);
      line.alpha = 0;
    });
  }

  // - PNG画像追加 -
  async addImage(editableImage: EditableImage, file: File | null, freeGraphic?: any, isChangeCover?: boolean) {
    return new Promise<void>((resolve) => {
      // PNG画像生成
      const container = this.layoutDataManager.selector('getEditors', this.pageId) as CjContainer[];
      const pageType = container.length ? container[0].pageType : '';
      const newImage = new CjContainer(this.pageId);
      const pngImage = lodash.cloneDeep(editableImage.editable);
      const ratio = (this.imageEditManager.templateReal.w ?? 1) / (this.imageEditManager.templateVirtual.w ?? 1);

      pngImage.scaleX = 1 / ratio;
      pngImage.scaleY = 1 / ratio;
      const pngData = cloneDeep(pngImage.getTransformedBounds());
      newImage.originalBounds.width = pngData.width;
      newImage.originalBounds.height = pngData.height;
      let realPath;
      let virtualPath;
      if (!freeGraphic) {
        realPath = editableImage.name;
        virtualPath = editableImage.path;
      } else {
        realPath = freeGraphic.real.path;
        virtualPath = freeGraphic.virtual.path;
      }
      newImage.editableImageId = editableImage.id;
      newImage.realPath = realPath;
      newImage.virtualPath = virtualPath;
      newImage.pageType = pageType;
      newImage.addChild(pngImage);
      CjTool.checkLoad(newImage)
        .then((v) => {
          const pos = {
            width: this.imageEditManager.templateVirtual.w,
            height: this.imageEditManager.templateVirtual.h,
          };
          const pngData = v.getTransformedBounds();
          const scale = freeGraphic ? (Number(freeGraphic.virtual.width) * ratio) / pngImage.image.width : 1;

          pngImage.set({
            x: pos.width / 2,
            y: pos.height / 2,
            name: CJ_DPOBJ_NAME.freeGraphic,
          });
          v.set({
            x: pos.width / 2,
            y: pos.height / 2,
            // x: freeGraphic ? Number(freeGraphic.virtual.x ?? (pos.width / 2)) : pos.width / 2,
            // y: freeGraphic ? Number(freeGraphic.virtual.y ?? (pos.height / 2)) : pos.height / 2,
            regX: pos.width / 2 + pngData.width / 2,
            regY: pos.height / 2 + pngData.height / 2,
            name: CJ_CONTAINER_NAME.image,
            pageId: this.pageId,
            // real: {
            //   x: pos.width / 2,
            //   y: pos.height / 2,
            // },
            real: {
              x: pos.width / 2,
              y: pos.height / 2,
              width: pngData.width * ratio,
              height: pngData.height * ratio,
            },
            virtual: {
              x: pos.width / 2,
              y: pos.height / 2,
              width: pngData.width,
              height: pngData.height,
            },
            differPos: {
              x: freeGraphic ? pngData.width * scale - Number(freeGraphic.virtual.width) : 0,
              y: freeGraphic ? pngData.height * scale - Number(freeGraphic.virtual.height) : 0,
            },
            defaultPos: {
              x: pos.width / 2,
              y: pos.height / 2,
            },
            scaleX: 1,
            scaleY: 1,
            rotation: freeGraphic ? Number(freeGraphic.rotation) : 0,
          });

          v.originalBounds.x = cloneDeep(v.x);
          v.originalBounds.y = cloneDeep(v.y);
          if (freeGraphic) {
            v.movePos = {
              x: (Number(freeGraphic.virtual?.x) - v.virtual!.x + v.differPos!.x),
              y: (Number(freeGraphic.virtual?.y) - v.virtual!.y + v.differPos!.y),
            };
            const { x, y } = CjTool.getPhotoContainerOrigin({
              pictureRect: { virtual: freeGraphic.virtual },
              rotate: v.rotation,
            });
            v.x = x;
            v.y = y;
            v.originalBounds.x = cloneDeep(v.x);
            v.originalBounds.y = cloneDeep(v.y);
            v.scaleX = scale;
            v.scaleY = scale;
            v.virtual!.x = Number(freeGraphic.virtual.x ?? (pos.width / 2)) + v.differPos!.x / 2;
            v.virtual!.y = Number(freeGraphic.virtual.y ?? (pos.height / 2)) + v.differPos!.y / 2;
          }
          if (isChangeCover) {
            const prevImages = this.layoutDataManager.selector('getCoverFreeGraphics')
            const prevImage = prevImages.find((v: CjContainer) => v.editableImageId === editableImage.id)
            if (prevImage) {
              v.movePos = prevImage.movePos;
              v.x = prevImage.x;
              v.y = prevImage.y;
              v.originalBounds.x = prevImage.originalBounds.x;
              v.originalBounds.y = prevImage.originalBounds.y;
              v.scaleX = prevImage.scaleX;
              v.scaleY = prevImage.scaleY;
              v.virtual!.x = prevImage.virtual.x;
              v.virtual!.y = prevImage.virtual.y;
              v.rotation = prevImage.rotation;
            }
          }
          // 透過PNG対策として設置
          const hitArea = new CjShape();
          hitArea.graphics.f('#fff').dr(v.getBounds().x, v.getBounds().y, v.getBounds().width, v.getBounds().height)
          hitArea.alpha = 0.01;
          newImage.addChild(hitArea);
          this.imageEvent.setImageEvent(newImage, 'image', 'png');
          this.stage.addChild(newImage);
          this.layoutDataManager.dispatch('setFreeGraphic', newImage);
          this.editableEvent.action(
            'addPNG',
            `PNG画像(id: ${newImage.editableImageId})を追加。`,
            () => {
              this.stage.addChild(newImage);
              this.editableImageManager.toUse(newImage.editableImageId ?? '');
            },
            () => {
              this.stage.removeChild(newImage);
              this.layoutDataManager.dispatch("removeFreeGraphic", newImage)
              this.editableImageManager.toUnUse(newImage.editableImageId ?? '');
            },
          );
          resolve();
          freeGraphic || UiManager.ins.emit('l->r:wait-loading', { message: '' });
        });
    });
  }

  // - 使用中の画像を一時保存する -
  private async recordImage(prevEditors: CjContainer[], prevImages: EditableImage[]) {
    for (const prevEditor of prevEditors) {
      const photo = CjTool.getPhotoContainer(prevEditor);
      const prevImg = CjTool.getImageContainer(prevEditor);
      if (CjTool.checkPhotoInFrame(prevEditor)) {
        const img = this.editableImageManager.list.find((img) => img.flags.used && img.id === photo.editableImageId);
        img && prevImages.push(img);
        await this.layoutDataManager.dispatch('removeImage', prevImg);
        try {
          this.editableImageManager.toUnUse(prevImg.editableImageId ?? '');
        } catch {
        }
      }
    }
  }

  // - 中枠の回転 -
  async rotateAdditional() {
    const rotateTempData = this.albumManager.currentAlbum?.parseData.templateData.exTemplate[0].rotExchange[0];
    const rotateTempList = [
      rotateTempData.rot000[0].$,
      rotateTempData.rot090[0].$,
      rotateTempData.rot180[0].$,
      rotateTempData.rot270[0].$,
    ];
    const initData: LayoutInitType = {
      modeAbleArea: 'on',
      modeInOut: this.imageController.imageSetMode,
      display: this.isCenterLine,
      fitNum: this.imageController.fitNum,
      additionalShow: this.additionalShowType,
      modeCursor: 'select',
    };
    const activeTemp = rotateTempList.filter((v) => v.enable === '1');
    if (!activeTemp.length) return;
    const prevEditors = this.layoutDataManager.selector('getEditors', this.pageId);
    const prevImages: EditableImage[] = [];
    await this.recordImage(prevEditors, prevImages);
    const acquiredTemplateList: any[] = (this.albumManager.currentAlbum?.additionalParseData ?? []);
    const nowTemp = acquiredTemplateList.find(temp => temp.thumbnail === this.albumManager.currentAlbum?.thumbnail.templatePath);
    const targetTemp = activeTemp.find(temp => `${this.imageEditManager.getBaseUrl}/${temp.path}/thumbnail.jpg` === this.albumManager.currentAlbum?.thumbnail.templatePath);
    if (acquiredTemplateList.length > 1 && nowTemp) {
      const i = activeTemp.indexOf(targetTemp);
      const j = i + 1 > activeTemp.length - 1 ? 0 : i + 1;
      const nextTempPath = activeTemp[j].path;
      await this.layoutDataManager.dispatch('changeTemp', this.pageId);
      const nextTemp = acquiredTemplateList.find(temp => temp.thumbnail === `${this.imageEditManager.getBaseUrl}/${nextTempPath}/thumbnail.jpg`);
      if (!nextTemp || nextTemp === nowTemp) return;
      UiManager.ins.emit('l->r:wait-loading', { message: 'テンプレートを読み込んでいます...' });
      await this.imageEditManager.updateTemplate(nextTemp, prevImages, initData);
      UiManager.ins.emit('l->r:wait-loading', { message: '' });
    }
  }

  // - 中枠の形状変更 -
  async transformAdditional() {
    const shapeTemp = this.albumManager.currentAlbum?.parseData.templateData.exTemplate[0].shapeExchange[0].$;
    if (shapeTemp.enable === '0') return;
    const initData: LayoutInitType = {
      modeAbleArea: 'on',
      modeInOut: this.imageController.imageSetMode,
      display: this.isCenterLine,
      fitNum: this.imageController.fitNum,
      additionalShow: this.additionalShowType,
      modeCursor: 'select',
    };
    const prevEditors = this.layoutDataManager.selector('getEditors', this.pageId);
    const prevImages: EditableImage[] = [];
    await this.recordImage(prevEditors, prevImages);
    const acquiredTemplateList: any[] = (this.albumManager.currentAlbum?.additionalParseData ?? []);
    const nowTemp = acquiredTemplateList.find(temp => temp.thumbnail === this.albumManager.currentAlbum?.thumbnail.templatePath);
    if (acquiredTemplateList.length > 1 && nowTemp) {
      this.stage.removeAllChildren();
      await this.layoutDataManager.dispatch('changeTemp', this.pageId);
      const nextTemp = acquiredTemplateList.find(temp => temp.thumbnail === `${this.imageEditManager.getBaseUrl}/${shapeTemp.path}/thumbnail.jpg`);
      if (!nextTemp) return;
      UiManager.ins.emit('l->r:wait-loading', { message: 'テンプレートを読み込んでいます...' });
      await this.imageEditManager.updateTemplate(nextTemp, prevImages, initData);
      UiManager.ins.emit('l->r:wait-loading', { message: '' });
    }
  }

  // - 中枠の表示（表示→半透明→非表示のループ） -
  transparentAdditional(type: AdditionalShowType, callback: () => void) {
    const additional = this.layoutDataManager.selector('getAdditional', this.pageId);
    if (!this.imageEditManager.isDAI || !additional) {
      callback()
      return;
    };
    this.additionalShowType = type;
    switch (type) {
      case 'semi':
        additional.alpha = 0.5;
        break;
      case 'hide':
        additional.alpha = 0;
        break;
      case 'show':
        additional.alpha = 1;
        break;
      default:
        break;
    }
    this.stage.stage.update();
    callback()
  }
}
