import { useEffect } from 'react';
import { Env } from '../models/env';
import { ApiBaseFront } from '../api/front/api-base-front';
import { ApiBaseBack } from '../api/back/api-base-back';
import { SessionTokenManager } from '../manager/session-token-manager';
import { LoadingPopup } from '../components/ui/loading/loading-popup';
import { ApiBaseCloudFolder } from '../api/cf/api-base-cloud-folder';

type Props = {
  onInitialized: () => void,
};

const sleep = (ms: number) => new Promise<void>(resolve => {
  setTimeout(() => resolve(), ms);
});

export const InitializeContainer = (props: Props) => {
  const { onInitialized } = props;
  useEffect(() => {
    (async () => {
      // - 初期化処理 -
      console.log('[START] initialize ...');
      // -- env.json の取り込み --
      const noCacheStr = String(new Date().getTime());
      await fetch(`./env.json?no-cache=${noCacheStr}`, { mode: 'cors' })
        .then(res => res.json())
        .then(json => {
          Env.setJson(json);
        });
      // -- ApiBase(front/back) への値セット --
      // ApiBase.protocol = Env.api.back.protocol;
      // ApiBase.hostname = Env.api.back.host;
      ApiBaseBack.protocol = Env.api.back.protocol;
      ApiBaseBack.hostname = Env.api.back.host;
      ApiBaseFront.protocol = Env.api.front.protocol;
      ApiBaseFront.hostname = Env.api.front.host;
      ApiBaseCloudFolder.protocol = Env.api.cf.protocol;
      ApiBaseCloudFolder.hostname = Env.api.cf.host;
      // -- title の変更 --
      document.title = 'PPM クラウド';
      // - セッション -
      try {
        const { sToken, fSToken } = new SessionTokenManager().load();
        ApiBaseBack.session = sToken;
        ApiBaseFront.session = fSToken;
      } catch (e) {
        console.error(e);
        ApiBaseBack.session = '';
        ApiBaseFront.session = '';
      }
      // - 初期化処理の終了 -
      console.log('[FINISH] initialize !!');
      // - コールバック実行 -
      onInitialized();
      // - debug -
      // RestoreEditableImageManager.ins.restore({
      //   path: '0299999/0299999000046/00001/images/5H0A4421_1642335740.jpg',
      //   name: '5H0A4421.jpg',
      //   kijshopCd: '0299999',
      //   shopOrderId: '0299999000046',
      //   orderId: '00001',
      // });
    })();
  }, []);
  // return (<></>);
  return (
    <div style={{
      position: 'fixed',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      backgroundColor: '#383838',
    }}>
      <LoadingPopup label={'読み込み中です...'} />
    </div>
  );
};
