import {createSlice} from '@reduxjs/toolkit';
import {DialogData} from '../../../models/dialog-data';
import {ActionType} from '../../../models/action-type';
import {MessageDialog} from '../message-dialog';
import {AppThunk} from '../../../app/store';
import {sampleActions} from '../../../slices/sample-slice';
import { SeriousErrorDialog } from '../serious-error-dialog';

type DialogState = {
  dialogs: DialogData[],
  errorDialogStatus: '' | 'error' | 'unexpectedError',
};

const initialState: DialogState = {
  dialogs: [],
  errorDialogStatus: '',
};

const push: ActionType<DialogState, DialogData> = (state, action) => {
  state.dialogs.push(action.payload);
};

const pushMessage: ActionType<DialogState, {
  title: string,
  message: string[],
  remind?: {
    callback: (v: boolean) => void;
    label?: string;
    initCheck?: boolean;
  },
  buttons: {
    label: string,
    callback: () => void,
    options?: {
      waitActive?: number,
    },
  }[],
  id?: string,
}> = (state, payload) => {
  // NOTE: idが設定されていてすでに表示中であれば新しく表示しない(要するに重複制御だが後から追加したのでほとんど入ってはいない)
  if (state.dialogs.find(v => !!(payload.payload.id) && v.id === payload.payload.id)) {
    return;
  }
  state.dialogs.push({
    title: payload.payload.title,
    element: MessageDialog({
      message: payload.payload.message,
      buttons: payload.payload.buttons,
      remind: payload.payload.remind,
    }),
    id: payload.payload.id,
  });
};

const pop: ActionType<DialogState> = (state) => {
  state.dialogs.splice(state.dialogs.length - 1, 1);
};

const popAll: ActionType<DialogState> = (state) => {
  state.dialogs.splice(0, state.dialogs.length);
};

const setErrorDialogStatus: ActionType<DialogState, '' | 'error' | 'unexpectedError'> = (state, action) => {
  state.errorDialogStatus = action.payload;
};

export const dialogSlice = createSlice({
  name: 'dialog',
  initialState,
  reducers: {
    push,
    pushMessage,
    pop,
    popAll,
    setErrorDialogStatus,
  },
});

const asyncActions = {
  pushSystemError: (): AppThunk => async (dispatch, getState) => {
    const showError = getState().dialog.errorDialogStatus;
    if (showError) {
      return;
    }
    dispatch(dialogActions.setErrorDialogStatus('error'));
    dispatch(dialogActions.pushMessage({
      title: 'エラー',
      message: ['エラーが発生しました'],
      buttons: [
        {
          label: 'OK',
          callback: () => {
            dispatch(dialogActions.setErrorDialogStatus(''));
            dispatch(dialogActions.pop());
          },
        },
      ],
    }));
  },
  pushSeriousError: (): AppThunk => async (dispatch, getState) => {
    const showError = getState().dialog.errorDialogStatus;
    if (showError === 'unexpectedError') {
      return;
    }
    dispatch(dialogActions.setErrorDialogStatus('unexpectedError'));
    dispatch(dialogActions.push({
      title: 'エラー',
      element: SeriousErrorDialog(),
    }));
  },
};

export const dialogActions = Object.assign(dialogSlice.actions, asyncActions);
export const dialogReducer = dialogSlice.reducer;
