import './awaiting-ellipsis.scss';

type AwaitingEllipsisProps = {
  color?: string,
  size?: string,
};

export const AwaitingEllipsis = (props: AwaitingEllipsisProps) => {
  const { color = "currentColor", size="1em" } = props;
  const arr = new Array(3).fill('');
  return (
    <div className="awaiting_ellipsis">
      {arr.map((_, i) => (
        <div
          key={`awaiting-ellipsis-dot_${i}`}
          className={`awaiting_ellipsis_dot_${i}`}
          style={{ backgroundColor: color, width: size, height: size }}
        />
      ))}
    </div>
  );
}