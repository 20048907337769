import { useCallback, useEffect, useState } from 'react';
import { Input } from '../../../ui/input/input';
import { useAppSelector } from '../../../../app/hooks';
import * as lodash from 'lodash';
import { useParams } from 'react-router-dom';
import { PathParams } from '../../../../routes/routing-path';
import { useDispatch } from 'react-redux';
import { apiActions } from '../../../../slices/api-slice';
import { ApiLogin } from '../../../../api/back/login/api-login';
import { localStorageActions } from '../../../../slices/local-storage-slice';
import { useDidMount } from '../../../../hooks/life-cycle';

export const StoreInfoConfig = () => {
  const dispatch = useDispatch();
  const { shopName } = useAppSelector((state) => ({
    shopName: state.storage.data.shopName,
  }), lodash.isEqual);
  const { kijshopCd, shopOrderId, orderId } = useParams<PathParams>();
  useDidMount(() => {
    dispatch(localStorageActions.getLocalData(kijshopCd));
  });
  return (
    <div>
      <div className="bottom_border_header">
        店舗情報
      </div>
      <div className="top_label_ui">
        <div className="top_label_ui__label">
          店舗コード
        </div>
        <Input
          value={kijshopCd}
          className="width_sm"
          disabled
          // onChange={(e) => {}}
        />
      </div>
      {/* <div>
        注文番号最低値
        <Input
          type="number"
          value={minValue}
          onChange={(e) => handlerChangeMinValue(e.target.value)}
        />
      </div>
      <div>
        注文番号最大値
        <Input
          type="number"
          value={maxValue}
          onChange={(e) => handlerChangeMaxValue(e.target.value)}
        />
      </div> */}
      <div className="top_label_ui">
        <div className="top_label_ui__label">
          店舗名
        </div>
        <Input
          value={shopName}
          disabled
        />
      </div>
    </div>
  );
};
