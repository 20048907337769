import { XmlStructureModel, XmlStructureOrderInfoData, XmlStructureOrderPartsData } from "../../xml/model/xml-structure-model";
import { MetaShopOrderGetResponse } from '../../api/front/meta/api-meta-shop-order';
import { MetaOrderGetResponse } from '../../api/front/meta/api-meta-order';
import { store } from '../../app/store';

export type PicData = {
  pageId: string;
  photoAreaId: string;
  x: number;
  y: number;
  photoAreaX: number,
  photoAreaY: number,
  photoAreaZoom: number,
  isFit: boolean,
}
export type RestorePicData = {
  optionLevel: number;
  data: {
    partsId: string;
    picData: PicData[],
  }[];
};
export class LayoutXmlUtile {
  static getOptionInfoData = (orderId: string, xml?: XmlStructureModel | null):XmlStructureOrderInfoData[] =>{
    const result: XmlStructureOrderInfoData[] = [];
    for (const info of xml?.orderInfo?.infoData ?? []) {
      if (info.xml.metaModel.parentId === orderId) {
        for (const page of info.xml.orderPageDataArr ?? []) {
          if (!page.viewModel.parentID) {
            result.push(info);
          }
        }
      }
    }  
    return result;
  }

  static flatOptionParts = (infos: XmlStructureOrderInfoData[]): XmlStructureOrderPartsData[] => {
    const flatOpParts = infos.map((v) => v.parts?.partsData ?? []).flat();
    const resultList: XmlStructureOrderPartsData[] = [];
    for (const flatOpPart of flatOpParts) {
      if (resultList.find(v => v.xml.xmlUniqueName === flatOpPart.xml.xmlUniqueName)) continue;
      resultList.push(flatOpPart);
    }
      return resultList;
  }

  static isOption = (pageType: string, xml?: XmlStructureModel | null): boolean => {
    return !!store.getState().layout.pageTypeList.option.find((v) => v.pageTypeID === pageType);
  }
}