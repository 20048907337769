// noinspection JSVoidFunctionReturnValueUsed

import { orderRequest, orderRequestOptional, ordersActions, OrdersSliceState } from '../../orders-slice';
import {PayloadAction} from '@reduxjs/toolkit';
import {AppThunk} from '../../../app/store';

// ラミ選択
export const setLaminate = (value: string, label: string = ''): AppThunk => async (dispatch)  => {
  dispatch(ordersActions.updateCover({
    laminate: { value, label },
  }));
};
// 色
export const setColor = (value: string, label: string = ''): AppThunk => async (dispatch, getState) => {
  // const order = getState().order;
  // const request = orderRequest(order);
  // const requestOptional = orderRequestOptional(order)
  dispatch(ordersActions.updateCover({
    color: { value, label },
  }));
  // - 色更新時のアクション -
  // -- 表紙加工 --
  // dispatch(apiFoilStampingsThunk({
  //   serviceId: request.serviceId,
  //   productNameId: request.productNameId,
  //   coverColorId: value,
  //   // productTagId: string,
  //   // coverTypeId: string,
  // }));
};
// 表紙加工
export const setCoverProcessing = (value: string, label: string = ''): AppThunk => async (dispatch)  => {
  dispatch(ordersActions.updateCover({
    coverProcessing: { value, label },
  }));
};
// 箔押し文字1
export const setLeafText1 = (value: string): AppThunk => async (dispatch) => {
  dispatch(ordersActions.updateCover({
    leafText1: value,
  }));
};
// 箔押し文字2
export const setLeafText2 = (value: string): AppThunk => async (dispatch) => {
  dispatch(ordersActions.updateCover({
    leafText2: value,
  }));
};
// export const setCoverProcessing2 = (state: OrdersSliceState, action: PayloadAction<string>) => {
// };
