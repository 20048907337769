import { DetailedHTMLProps, TextareaHTMLAttributes, useCallback } from 'react';
import './textarea.scss';

type TextareaProps = {
  // - キーハンドラ -
  handlerEnter?: () => void,
} & DetailedHTMLProps<TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement>;

export const TextArea = (props: TextareaProps) => {
  const { handlerEnter, ...defaultProps } = props;
  // - Callback -
  // -- キー --
  const handlerKeyPress = useCallback((e) => {
    if (defaultProps.onKeyPress) {
      defaultProps.onKeyPress(e);
    }
    if (handlerEnter && (e as KeyboardEvent)?.key === 'Enter') {
      handlerEnter();
    }
  }, [handlerEnter]);
  return (
    <textarea
      {...defaultProps}
      onKeyPress={handlerKeyPress}
      className={`textarea ${defaultProps.className || ''}`}
    />
  );
};
