import { useMemo } from 'react';
import './color-icon.scss';

type StatusType = "注文作成中" | "注文作成済" | "レイアウト中" | "ラボ発注可" | "ラボ発注中" | "ラボ発注済";

type ColorIconProps = {
  color: "red" | "yellow" | "blue" | "green" | "light-green" | "pink" | "purple" | StatusType | string,
  shapeType?: "rect" | "circle",
}

export const ColorIcon = (props: ColorIconProps) => {
  const { color: _color, shapeType = "circle" } = props;

  // Memo
  const color = useMemo(() => {
    switch (_color) {
      case '注文作成中':
        return '#FCDB2C';
      case '注文作成済':
        return '#FF9912';
      case 'レイアウト中':
        return '#FF4817';
      case 'ラボ発注可':
        return "#70b2ee";
      // case "ラボ発注中":
      //   return "#937bea";
      // case "ラボ発注済":
      //   return "#55ff00";
      // case "yellow":
      //   return "#FCF356";
      // case "blue":
      //   return "#4CDEF2";
      // case "green":
      //   return "#A2EF59";
      // case "pink":
      //   return "#FF5BA0";
      // default:
      //   return _color;
    }
  }, [_color]);

  return (
    <>
      {_color !== "" ?
        <div className={`color_icon ${shapeType}`} style={{ background: color }} />
        : <></>}
    </>
  )
}