// noinspection JSVoidFunctionReturnValueUsed

import { orderRequest, orderRequestOptional, ordersActions, OrdersSliceState } from '../../orders-slice';
import {PayloadAction} from '@reduxjs/toolkit';
import {AppThunk} from '../../../app/store';
import { PageDataTypesResponseEntity } from '../../../models/api/back/create-order/page-data-types';

// 商品タグ
export const setItemTag = (value: string, index: number, label: string = ''): AppThunk => async (dispatch, getState)  => {
  const order = getState().order;
  const data = getState().orderData;
  const requestOptional = orderRequestOptional(order, getState().createOrder.visibleCondition, data);
  const request = orderRequest(order, getState().createOrder.visibleCondition, data);
  dispatch(ordersActions.updateOption({
    index,
    data: {
      tag: { value, label },
    },
  }));
  // - オプションタグ更新時のアクション -
  // -- オプション商品 --
};

// 商品選択
export const setItem = (value: string, index: number, label: string = '', serviceId: string = '', serviceName: string = ''): AppThunk => async (dispatch)  => {
  dispatch(ordersActions.updateOption({
    index,
    data: {
      item: { value, label, serviceId, serviceName },
    },
  }));
};

// 部材選択
export const setBoard = (value: string, index: number, label: string = ''): AppThunk => async (dispatch) => {
  dispatch(ordersActions.updateOption({
    index,
    data: {
      board: { value, label },
    },
  }));
};

// 数量
export const setQuantity = (value: number, index: number): AppThunk => async (dispatch)  => {
  dispatch(ordersActions.updateOption({
    index,
    data: {
      quantity: value,
    },
  }));
};

// ラミ選択
export const setLaminate = (value: string, index: number, label: string = ''): AppThunk => async (dispatch)  => {
  dispatch(ordersActions.updateOption({
    index,
    data: {
      cover: { laminate: { value, label } },
    },
  }));
};

// ラミ選択(本身)
export const setLaminateGenuine = (value: string, index: number, label: string = ''): AppThunk => async (dispatch)  => {
  dispatch(ordersActions.updateOption({
    index,
    data: {
      genuine: { laminate: { value, label } },
    },
  }));
};

// ラミ削除
export const removeLaminate = (index: number): AppThunk => async (dispatch)  => {
  dispatch(ordersActions.updateOption({
    index,
    data: {
      cover: { laminate: { value: '', label: '' } },
    },
  }));
};

// オプション記載情報
export const setDescriptionLocations = (value: string[], index: number): AppThunk => async (dispatch) => {
  dispatch(ordersActions.updateOption({
    index,
    data: {
      descriptionLocations: value,
    },
  }))
};

// パーツ数
export const setPartsCount = (value: string, index: number): AppThunk => async (dispatch) => {
  dispatch(ordersActions.updateOption({
    index,
    data: {
      partsCount: value,
    },
  }));
};
