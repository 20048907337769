import React, { DetailedHTMLProps, InputHTMLAttributes, Ref, useCallback, useEffect, useRef, useState } from 'react';
import { AngleIcon } from '../angle-icon/angle-icon';
import { DynamicTooltip } from '../tooltip/dynamic-tooltip';
import { Input } from './input';
import './num-up-down.scss';

type NumUpDownProps = {
  value?: string | number,
  onChange?: React.ChangeEventHandler<HTMLInputElement>,
  disabled?: boolean,
  callback?: (num: number, press?: boolean) => void,
  tooltip?: { type: 'error' | 'normal', text: string[] },
  isMinus?: boolean;
  isDecimal?: boolean;
  initNum?: number;
  isEmpty?: boolean;
} & DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

export const NumUpDown = React.forwardRef((props: NumUpDownProps, inputRef: any) => {
  const {
    isEmpty,
    initNum,
    isDecimal,
    isMinus,
    value,
    onChange,
    disabled = false,
    callback,
    tooltip,
    onFocus,
    ...defaultProps
  } = props;
  // - Ref -
  const ref = useRef<HTMLDivElement>(null);
  // - State -
  const [hover, setHover] = useState(false);
  const [text, setText] = useState<string | null>(null);
  const [inputEvent, setInputEvent] = useState<React.ChangeEvent<HTMLInputElement>>();

  const _onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setInputEvent(e);
    // 空許容
    if (e.target.value === '') {
      setText('');
      return;
    }
    // マイナス許容処理
    if (e.target.value.split('').includes('-')) {
      if (!isMinus || (String(defaultProps.min) && Number(defaultProps.min) >= 0)) return;
      const split = e.target.value.split('').filter((_, i) => i !== 0);
      if (split.includes('-')) return;
    }
    // 小数点許容処理
    if (e.target.value.split('').includes('.')) {
      if (!isDecimal) return;
      const split = e.target.value.split('').filter((v) => v === '.');
      const split2 = e.target.value.split('').filter((v) => v !== '-');
      if (split.length > 1) return;
      if (split2?.[0] === '.') return;
    }
    const splitDecimal = e.target.value.split('.');
    // 先頭0連打無効処理
    if (splitDecimal[0]) {
      const split = splitDecimal[0].split('').filter((v) => v !== '-');
      if (split.length > 1 && !Number(split.join(''))) return;
    }

    // min, max 処理
    // let result = e.target.value;
    // if (result.match('-') && result === '-') {
    //   result = '';
    // }
    // if (result.match('.')) {
    //   const split = result.split('.');
    //   if (!split?.[1] || !Number(split?.[1])) {
    //     result = split[0] ?? '';
    //   }
    // }
    //
    // if (String(defaultProps.max) && Number(result) > Number(defaultProps.max)) {
    //   setText(String(defaultProps.max));
    //   return;
    // }
    //
    // if (String(defaultProps.min) && Number(result) < Number(defaultProps.min)) {
    //   setText(String(defaultProps.min));
    //   return;
    // }
    const filterJoin = e.target.value.split('').filter((v) => v !== '.' && v !== '-').join('');
    if (isNaN(Number(filterJoin))) return;
    setText(e.target.value);
    // onChange?.(e);
  }, [onChange]);

  const _onBlur = useCallback((e: React.FocusEvent<HTMLInputElement>) => {
    let result = text ?? '';
    if (result.match('-') && result === '-') {
      result = '';
    }
    if (result.match('.')) {
      const split = result.split('.');
      if (!split?.[1] || !Number(split?.[1])) {
        result = split[0] ?? '';
      }
    }
    setText(null);

    result = result === '' ? (String(initNum ?? '')) : String(Number(result));
    if (inputEvent) onChange?.({
      ...inputEvent,
      target: {
        ...inputEvent.target,
        value: result,
      },
    });
    if (defaultProps.min || defaultProps.max) {
      if (defaultProps.max && Number(e.target.value) > defaultProps.max) {
        defaultProps.onBlur?.({ target: { value: defaultProps.max.toString() } } as any);
      } else if (defaultProps.min && Number(e.target.value) < defaultProps.min) {
        defaultProps.onBlur?.({ target: { value: defaultProps.min.toString() } } as any);
      } else {
        defaultProps.onBlur?.(e);
      }
    } else {
      defaultProps.onBlur?.(e);
    }
  }, [defaultProps.onBlur, text]);

  const [intervalID, setIntervalID] = useState<NodeJS.Timeout>();
  const [press, setPress] = useState(false);

  const longPress = (type: 'up' | 'down') => {
    setPress(true);
    if (intervalID) return;
    let _v = Number(text || (value || 0));
    let i = 0;
    const change = (result: number) => {
      if (defaultProps.max) {
        callback?.(Number(defaultProps.max) <= result ? Number(defaultProps.max) : result, true);
      } else if ((defaultProps.min !== undefined)) {
        callback?.(Number(defaultProps.min) >= result ? Number(defaultProps.min) : result, true);
      } else {
        callback?.(result || 0, true);
      }
    };
    const interval = setInterval(() => {
      if (i < 1 || i > 10) {
        if (type === 'up') {
          const num = defaultProps.max && _v >= defaultProps.max ? Number(defaultProps.max) : _v + (defaultProps.step ? Number(defaultProps.step) : 1);
          setText(num.toString());
          change(num);
          _v += (defaultProps.step ? Number(defaultProps.step) : 1);
        } else {
          const num = (defaultProps.min || defaultProps.min === 0) && _v <= defaultProps.min ? Number(defaultProps.min) : _v - (defaultProps.step ? Number(defaultProps.step) : 1);
          setText(num.toString());
          change(num);
          _v -= (defaultProps.step ? Number(defaultProps.step) : 1);
        }
      }
      i++;
      setIntervalID(interval);
    }, 50);
  };

  const pressUp = (type?: 'up' | 'down') => {
    if (!press) return;
    setPress(false);
    clearInterval(intervalID!);
    callback?.(Number(text!), false);
    setText(null);
    setIntervalID(undefined);
  };

  useEffect(() => {
    if (!press && intervalID) {
      setText(null);
      clearInterval(intervalID);
      setIntervalID(undefined);
    }
  }, [press, intervalID]);

  return (
    <div
      className={`num_up_down ${disabled ? 'disabled' : ''}`}
      onMouseOver={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      ref={ref}
    >
      <Input
        // type="number"
        value={text ?? String(value)}
        onFocus={(e) => {
          setText(String(value ?? ''));
          onFocus?.(e);
        }}
        // min={defaultProps.min ?? undefined}
        // max={defaultProps.max ?? undefined}
        // step={defaultProps.step ?? undefined}
        onChange={_onChange}
        disabled={disabled}
        onBlur={_onBlur}
        isInputInteger={false}
        type="tel"
        ref={inputRef}
        inputMode="numeric"
        onKeyPress={(e) => {
          // if (!/[0-9]/.test(e.key)) {
          //   e.preventDefault();
          // }
        }}
        onClick={(e) => e.stopPropagation()}
        isNumUpDown
      />
      <div className="num_up_down__handle">
        <AngleIcon
          direction="top"
          onMouseDown={() => longPress('up')}
          onMouseUp={() => pressUp('up')}
          onMouseOut={() => {
            if (press) pressUp('up');
          }}
          onDragLeave={() => {
            if (press) pressUp('up');
          }}
          onClick={(e) => {
            e.stopPropagation();
            // callback!(defaultProps.max && Number(value) === defaultProps.max ? Number(value) : Number(value) + (defaultProps.step ? 1 * Number(defaultProps.step) : 1));
          }}
        />
        <AngleIcon
          direction="bottom"
          onMouseOut={() => pressUp('down')}
          onMouseDown={() => longPress('down')}
          onDragLeave={() => {
            if (press) pressUp('down');
          }}
          onMouseUp={() => {
            if (press) pressUp('down');
          }}
          onClick={(e) => {
            e.stopPropagation();
            // callback!(defaultProps.min !== undefined && Number(value) === defaultProps.min ? Number(value) : Number(value) - (defaultProps.step ? 1 * Number(defaultProps.step) : 1));
          }}
        />
      </div>
      {tooltip && hover ? (
        <DynamicTooltip
          messages={tooltip.text}
          relativeEle={ref.current}
          error={tooltip.type === 'error'}
        />
      ) : (<></>)}
    </div>
  );
});