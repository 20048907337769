import {CaseReducer, CaseReducerWithPrepare, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AppThunk} from '../app/store';

type ActionType<State, Payload = undefined> = CaseReducer<State, { payload: Payload; type: string; }> | CaseReducerWithPrepare<State, PayloadAction<any, string, any, any>>;

type SampleSliceState = {
  hoge: string,
};

const initialState: SampleSliceState = {
  hoge: 'hoge',
};

const piyo: ActionType<SampleSliceState, { value: string } > = (state, action) => {
  state.hoge = action.payload.value;
}

export const sampleSlice = createSlice({
  name: 'sample',
  initialState,
  reducers: {
    hoge: (state, action: PayloadAction<string>) => {
      state.hoge = action.payload;
    },
    piyo,
  },
});

const asyncActions = {
  huga: (): AppThunk => async (dispatch) => {
    await new Promise((resolve) => {
      setTimeout(resolve, 1000);
    });
    dispatch(sampleActions.piyo({value: 'piyo'}));
  },
};

export const sampleActions = Object.assign(sampleSlice.actions, asyncActions);
export const sampleReducer = sampleSlice.reducer;
