import { ILayoutEditorManagerBase } from '../layout-editor.manager';
import { ImageEditManager } from '../image-edit/image-edit.manager';
import { UiManager } from '../ui/ui.manager';
import { PanZoomManager } from '../pan-zoom/pan-zoom.manager';
import { Pos } from '../../../models/pos';

export class PosManager implements ILayoutEditorManagerBase {

  // - mode -
  private isDev = true;
  // - manager -
  private uiManager!: UiManager;
  private panZoomManager!: PanZoomManager;
  // - canvas -
  private canvas!: HTMLCanvasElement;

  di(
    imageEditManager: ImageEditManager,
    uiManager: UiManager,
    panZoomManager: PanZoomManager,
  ): void {
    this.uiManager = uiManager;
    this.panZoomManager = panZoomManager;
    this.canvas = imageEditManager.getCanvasEle();
  }

  initialize(): void {
  }

  destroy(): void {
  }

  browserToCanvas(pos: Pos) {
    this.debug({
      method: 'browserToCanvas',
      originalPos: pos,
    });
    return pos;
  }

  canvasToBrowser(pos: Pos) {
    const scale = this.panZoomManager.scale;
    const canvasPos: Pos = {
      x: this.canvas.getBoundingClientRect().left,
      y: this.canvas.getBoundingClientRect().top,
    };
    const solvedPos: Pos = {
      x: canvasPos.x + (pos.x + ImageEditManager.STAGE_CONTAINER_X) * scale,
      y: canvasPos.y + (pos.y + ImageEditManager.STAGE_CONTAINER_Y) * scale,
    };
    // this.debug({
    //   method: 'canvasToBrowser',
    //   scale,
    //   canvasPos,
    //   originalPos: pos,
    // });
    return {...solvedPos, scale};
  }

  private debug(props: {
    method: string
    scale?: number,
    canvasPos?: Pos,
    originalPos?: Pos,
  }) {
    if (!this.isDev) {
      return;
    }
    const { originalPos, method, scale, canvasPos } = props;
    console.group('PosManager');
    console.log('method : ', method);
    console.log('scale : ', scale);
    console.log('canvasPos : ', canvasPos);
    console.log('pos : ', originalPos);
    console.log('ImageEditManager.STAGE_CONTAINER_ : ', ImageEditManager.STAGE_CONTAINER_X, ImageEditManager.STAGE_CONTAINER_Y);
    console.groupEnd();
  }
}
