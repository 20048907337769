import {createSlice} from '@reduxjs/toolkit';

type NewsListSliceState = {
  isClickNews: boolean,
  isSamePath: boolean,
};

const initialState: NewsListSliceState = {
  isClickNews: false,
  isSamePath: false,
};

export const newsListSlice = createSlice({
  name: 'news-list',
  initialState,
  reducers: {
    open: (state) => {
      state.isClickNews = true;
    },
    close: (state) => {
      state.isClickNews = false;
    },
    setIsSamePath: (state, action) => {
      state.isSamePath = action.payload;
    },
  },
});

export const newsListActions = newsListSlice.actions;
export const newsListReducer = newsListSlice.reducer;
