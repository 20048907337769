import { LogDecorator } from '@tenryu/log-decorator';
import { useEffect } from 'react';
import { LabOrderInfo } from './lab-order-info';
import { StoreInfoConfig } from './store-info-config';
import { SurrogateOrderModeConfig } from './surrogate-order-mode-config';
import './system-config.scss';
import { ShopConfig } from './shop-config';

type SystemConfigProps = {
  shopCode: string,
  // - 店舗コードの取得ハンドラ -
  getShopCode: (code: string) => void,
  isNeedAgreement: boolean,
  // - 校正承認の有無取得ハンドラ -
  getIsNeedAgreement: (state: boolean) => void,
  surrogateOrderMode: boolean | null,
  // - 代理発注モードの有無取得ハンドラ -
  getSurrogateOrderMode: (state: boolean) => void,
  // - スタッフ管理機能の有無 -
  isStaffManagement: boolean,
  getIsStaffManagement: (state: boolean) => void,
  // - スタッフログインの有無 -
  isStaffLogin: boolean,
};
export const SystemConfig = (props: SystemConfigProps) => {
  const {
    shopCode,
    getShopCode,
    isNeedAgreement,
    getIsNeedAgreement,
    surrogateOrderMode,
    getSurrogateOrderMode,
    isStaffManagement,
    // スタッフ管理機能の有無取得ハンドラ
    // 2023/07/19時点: スタッフ管理機能をON→OFFにできない仕様のため未使用。
    getIsStaffManagement,
    isStaffLogin,
  } = props;
  // - Effect -
  useEffect(() => {
    // -- ログまとめ --
    LogDecorator.group('<magenta>システム設定</magenta>');
    return () => LogDecorator.groupEnd();
  });
  return (
    <div className="system_config">
      <StoreInfoConfig />

      {/* <LabInfoConfig /> */}

      <LabOrderInfo
        isNeedAgreement={isNeedAgreement}
        getIsNeedAgreement={getIsNeedAgreement}
      />

      {/* <OrderInfoConfig />

      <LogInfoConfig />

      <ReceptionSlipOutputConfig />

      <ProcessManageInfoConfig />

      <PackageInfoConfig /> */}
      {
        (surrogateOrderMode !== null) ?
          <SurrogateOrderModeConfig
            surrogateOrderMode={surrogateOrderMode}
            getSurrogateOrderMode={getSurrogateOrderMode}
          />
          : <></>
      }
      {!isStaffLogin &&(
        <ShopConfig isStaffMng={isStaffManagement} />
        )
      }
    </div>
  );
};
