export const CJ_CONTAINER_NAME = {
  stage: 'stage-container',
  editor: 'editor-container',
  scale: 'scale-container',
  rotate: 'rotate-container',
  imageArea: 'imagearea-container',
  frame: 'frame-container',
  mask: 'mask-container',
  back: 'back-container',
  image: 'image-container',
  photo: 'photo-container',
  text: 'text-container',
  base: 'base-container',
  baseMask: 'basemask-container',
  guide: 'guide-container',
  overwrap: 'overwrap-container',
  additional: 'additional',
  dummyFrame: 'dummy-container',
  centerLine: 'centerline-container',
  focus: {
    scale: 'focus-scale-container',
    rotate: 'focus-rotate-container',
    move: 'focus-move-container',
    text: 'focus-text-container',
  },
};

export const CJ_DPOBJ_NAME = {
  image: 'image',
  dummyRect: 'dummyRect',
  dummyCornerA: 'dummyCornerA',
  dummyCornerB: 'dummyCornerB',
  dummyCornerC: 'dummyCornerC',
  dummyCornerD: 'dummyCornerD',
  highlight: 'highlight',
  grayFilm: 'grayFilm',
  additionalBase: 'additional-base',
  additionalWindow: 'additional-window',
  baseMaskWindow: 'basemask-window',
  text: 'text',
  outline: 'outline',
  checker: 'checker',
  freeGraphic: 'free-graphic',
};

export const CJ_BGIMAGE_NAME = {
  base: 'base',
  guide: 'guide',
  overwrap: 'overwrap',
  grayout: 'grayout',
  blackback: 'blackback',
};

export const CJ_FRAME_NAME = {
  frame: 'frame',
  mask: 'mask',
  back: 'back',
};
export const CJ_FOCUS_NAME = {
  corner: 'focus-corner',
  line: 'focus-line',
};