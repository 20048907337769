import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Input } from '../../ui/input/input';
import { Button } from '../../ui/button/button';
import { dialogActions } from '../slice/dialog-slice';
import { SendFormDesignationConfig } from './send-form-designation-config';
import './edit-form-designation.scss';
import { apiActions } from '../../../slices/api-slice';
import {
  ApiOrderFormEdit,
  ApiOrderFormFileGet,
  ApiOrderFormGet,
  ApiOrderFormPost,
} from '../../../api/front/order-form/api-order-form';
import { ApiCheckPdf } from '../../../api/back/check-pdf/api-check-pdf';
import { ResponseBase } from '../../../api/response-base';
import { OrderFormFileGetResponse, OrderFormGetResponse } from '../../../models/api/front/order-form';
import { deliveryActions } from '../../../slices/orders/delivery-slice';
import { useParams } from 'react-router-dom';
import { PathParams } from '../../../routes/routing-path';
import { useAppSelector } from '../../../app/hooks';
import lodash from 'lodash';
import { debugActions } from '../../../slices/debug-slice';

// - ボタン種類 -
type EditFormDesignationProps = {
  type: 'register' | 'edit',
  data?: OrderFormGetResponse,
  kijshopCd: string,
  nameList: {
    formDesignationName: string,
    fileName: string,
  }[],
};

export const EditFormDesignation = (props: EditFormDesignationProps) => {
  const { type, data, kijshopCd, nameList } = props;
  // - Hooks -
  const dispatch = useDispatch();
  const { debug } = useAppSelector((state) => ({
    debug: state.debug.debug,
  }), lodash.isEqual );
  // -State -
  // -- 帳票名 --
  const [formDesignationName, setFormDesignationName] = useState((data) ? data.name : '');
  // -- ファイル名 --
  const [fileName, setFileName] = useState((data) ? data.orgFilename : '');
  // -- apiに送るファイルデータ --
  const [fileData, setFileData] = useState<File>();
  // - Callback -
  // -- 帳票名更新 --
  const handlerChangeFormDesignationName = useCallback((v) => {
    setFormDesignationName(v);
  }, []);
  // -- ファイル更新 --
  const handlerChangeFile = useCallback((v) => {
    setFileName(v);
  }, []);
  // -- 参照ボタン押下 --
  const handlerClickReference = useCallback(() => {
    const inputElem = document.createElement('input');
    inputElem.type = 'file';
    if (!debug) {
      inputElem.accept = '.pdf';
    }
    inputElem.hidden = true;
    inputElem.onchange = (e: any) => {
      const file = e.target.files[0];
      //TODO e.target nullです
      dispatch(apiActions.run(
        new ApiCheckPdf({ fileName: e.target.files[0] }),
        {
          onSuccess: (res: ResponseBase<{ checkResult: string }>) => {
            if (res?.body?.data?.checkResult === '' || res?.body?.data?.checkResult === '0' || res?.body?.data?.checkResult === 'true') {
              setFileData(file);
            } else if (res?.body?.data?.checkResult === '61') {
              dispatch(dialogActions.pushMessage({
                title: '確認',
                message: [
                  '発送帳票ファイルが不正な形式です。',
                  'PDFファイルである必要があります。',
                ],
                buttons: [
                  {
                    label: 'OK',
                    callback: () => {
                      dispatch(dialogActions.pop());
                    },
                  },
                ],
              }));
            } else if (res?.body?.data?.checkResult === '62') {
              dispatch(dialogActions.pushMessage({
                title: '確認',
                message: [
                  '発送帳票ファイルが不正な形式です。',
                  'ページ数が5ページ以下である必要があります。',
                ],
                buttons: [
                  {
                    label: 'OK',
                    callback: () => {
                      dispatch(dialogActions.pop());
                    },
                  },
                ],
              }));
            } else {
              dispatch(dialogActions.pushMessage({
                title: '確認',
                message: [
                  '発送帳票ファイルのチェックに失敗しました。',
                  'ファイルが不正な形式である可能性があります。',
                ],
                buttons: [
                  {
                    label: 'OK',
                    callback: () => {
                      dispatch(dialogActions.pop());
                    },
                  },
                ],
              }));
            }
          },
          onError: () => {
            dispatch(dialogActions.pushMessage({
              title: '確認',
              message: [
                '発送帳票ファイルのチェックに失敗しました。',
                'ファイルが不正な形式である可能性があります。',
              ],
              buttons: [
                {
                  label: 'OK',
                  callback: () => {
                    dispatch(dialogActions.pop());
                  },
                },
              ],
            }));
          },
        },
        { ignoreSystemError: true },
      ));
    };
    document.body.appendChild(inputElem);
    inputElem.click();
    setTimeout(() => {
      document.body.removeChild(inputElem);
    });
  }, [debug]);
  // -- 編集or登録ボタン押下 --
  const handlerClickEdit = useCallback(() => {
    const checkFileName = nameList.map((v) => (v.fileName)).find((v) => v === fileName);
    const checkFormDesignationName = nameList.map((v) => (v.formDesignationName)).find((v) => v === formDesignationName);
    let messages = [];
    if (checkFormDesignationName) {
      messages.push('入力された発送帳票名はすでに存在します。','別の発送帳票名を入力してください。','');
    }
    if (checkFileName) {
      messages.push('入力された発送帳票ファイルはすでに登録されています。','別のファイルを入力してください。','');
    }
    if (messages.length) {
      dispatch(dialogActions.pushMessage({
        title: '確認',
        message: messages,
        buttons: [{
          label: 'OK',
          callback: () => dispatch(dialogActions.pop()),
        }]
      }));
      return;
    }
    if (type === 'register') {
      if (!formDesignationName || !fileData) {
        dispatch(dialogActions.pushMessage({
          title: '確認',
          message: [
            '以下の項目が入力されていません。',
            `${!formDesignationName ? '・帳票名' : ''}`,
            `${!fileData ? '・ファイル' : ''}`,
          ],
          buttons: [
            {
              label: 'OK',
              callback: () => {
                dispatch(dialogActions.pop());
              },
            },
          ],
        }));
      } else {
        dispatch(apiActions.run(
          new ApiOrderFormPost(
            kijshopCd,
            {
              name: formDesignationName,
              file: fileData,
            },
          ),
          {
            onSuccess: () => {
              dispatch(dialogActions.pop());
              dispatch(apiActions.run(
                new ApiOrderFormGet({ kijshopCd }),
                {
                  onSuccess: (res: ResponseBase<OrderFormGetResponse[]>) => {
                    dispatch(deliveryActions.setOrderFormState(res.body.data || []));
                  },
                },
              ));
            },
          },
        ));
      }
    } else {
      if (!formDesignationName || !fileName) {
        dispatch(dialogActions.pushMessage({
          title: '確認',
          message: [
            '以下の項目が入力されていません。',
            `${!formDesignationName ? '・帳票名' : ''}`,
            `${!fileName ? '・ファイル' : ''}`,
          ],
          buttons: [
            {
              label: 'OK',
              callback: () => {
                dispatch(dialogActions.pop());
              },
            },
          ],
        }));
      } else if (data?.name === formDesignationName && !fileData) {
        dispatch(dialogActions.pop());
      } else {
        dispatch(apiActions.run(
          new ApiOrderFormFileGet({
            kijshopCd: kijshopCd,
            orderfromId: data!.orderformId,
          }),
          {
            onSuccess: (res: Blob) => {
              if (res) {
                const getFile: File = new File([res], data!.orgFilename, { type: 'application/pdf' });
                dispatch(apiActions.run(
                  new ApiOrderFormEdit(
                    kijshopCd,
                    data!.orderformId,
                    {
                      name: formDesignationName,
                      file: (fileData) ? fileData : getFile,
                    },
                  ),
                  {
                    onSuccess: () => {
                      dispatch(apiActions.run(
                        new ApiOrderFormGet({ kijshopCd }),
                        {
                          onSuccess: (res: ResponseBase<OrderFormGetResponse[]>) => {
                            dispatch(deliveryActions.setOrderFormState(res.body.data || []));
                            dispatch(dialogActions.pop());
                          },
                          onError: () => dispatch(dialogActions.pop()),
                        },
                      ));
                    },
                    onError: () => dispatch(dialogActions.pop()),
                  },
                ));
              }
            },
            onError: () => dispatch(dialogActions.pop()),
          },
        ));
      }
    }
  }, [type, formDesignationName, fileData,fileName]);
  // -- キャンセルボタン押下 --
  const handlerClickCancel = useCallback(() => {
    dispatch(dialogActions.pop());
  }, []);

  useEffect(() => {
    if (fileData) {
      setFileName(fileData.name);
    } else {
      setFileName((data) ? data.orgFilename : '');
    }
  }, [fileData]);
  // TODO 後で消す
  useEffect(() => {
    dispatch(debugActions.addEvent());
  }, []);
  return (
    <div className="dialog_contents edit_form_designation">
      <div className="dialog_contents__body">
        <div className="edit_form_designation__item">
          <div className="edit_form_designation__item__label">
            帳票名
          </div>
          <Input
            value={formDesignationName}
            onChange={(e) => handlerChangeFormDesignationName(e.target.value)}
            maxLength={30}
          />
        </div>
        <div className="edit_form_designation__item">
          <div className="edit_form_designation__item__label">
            ファイル
            <span>(設定可能なファイルは、A4サイズのPDFです)</span>
          </div>
          <div className="edit_form_designation__item__form">
            <Input
              value={fileName}
              // onChange={(e) => handlerChangeFile(e.target.value)}
            />
            <Button
              label="参照"
              onClick={handlerClickReference}
            />
          </div>
        </div>
      </div>
      <div className="dialog_contents__footer">
        <Button
          label="キャンセル"
          onClick={handlerClickCancel}
        />
        <Button
          label={type === 'edit' ? '更新' : '登録'}
          onClick={handlerClickEdit}
        />
      </div>
    </div>
  );
};
