import {
  WizardBody,
  WizardBodyList,
  WizardButton,
  WizardButtonList,
  WizardContent,
} from '../../../ui/wizard/wizard-content';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { PathParams, RoutingPath } from '../../../../routes/routing-path';
import { useParams } from 'react-router-dom';

export const Step2StaffPasswordRegisterWizard = () => {
  // - Hooks -
  const dispatch = useDispatch();
  const { kijshopCd } = useParams<PathParams>();

  return (
    <WizardContent>
      <WizardBodyList>
        <WizardBody body={['パスワードを登録しました']} />
      </WizardBodyList>
      <WizardButtonList>
        <WizardButton
          label={'閉じる'}
          onClick={() => dispatch(push(RoutingPath.cnv.orders({ kijshopCd })))}
        />
      </WizardButtonList>
    </WizardContent>
  );
};
