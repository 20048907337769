import { createSlice } from '@reduxjs/toolkit';
import { ActionType } from '../models/action-type';
import { FavoriteFolderListItem } from '../models/api/front/favorite-folder';
import { FavoriteTemplateListItem } from '../models/api/front/favorite-template';
import { LayoutTemplateFolderData } from './layout-slice';
import { AppThunk } from '../app/store';
import { dialogActions } from '../components/dialog/slice/dialog-slice';
import { apiActions } from './api-slice';
import { ApiFavoriteFolderPost } from '../api/front/favorite-folder/api-favorite-folder';
import { rotation } from 'exifr';

export type FavoriteTemplateItem = {
	id: string,
	name: string,
	type: string,
	children: FavoriteTemplateItem[],
	parentId?: string,
	level?: number,
	indexes?: number,
	path?: string,
};

export type FavTemplateInfo = {
	id: string,
	dtemplId: string,
	dtemplName: string,
	filepath: string,
	folder1: string,
	folder2: string | undefined,
	folder3: string | undefined,
};

type FavoriteTemplateSliceState = {
	folderList: FavoriteTemplateItem[],
	selectFolder: FavoriteTemplateItem | null,
	templatesInfoList: FavoriteTemplateListItem[],
	draggingTemplate: string,
	draggingFavoriteTemplate: string,
	hoverFavoriteTemplate: string,
	openFavoriteTemplateList: FavoriteTemplateItem[],
	deleteTemplateId: string,
	draggingTempFolder: LayoutTemplateFolderData | null,
	getResponseFolderList: FavoriteFolderListItem[],
	updateFavoriteTemplate: boolean,
};

const initialState: FavoriteTemplateSliceState = {
	folderList: [
		// {
		// 	id: '1',
		// 	name: 'シーン',
		// 	type: 'directory',
		// 	children: [
		// 		{ id: '2', name: 'ウェディング', type: 'directory', children: [], parentId: 'シーン', level: 2, indexes: 1 },
		// 		{ id: '3', name: '成人式', type: 'directory', children: [], parentId: 'シーン', level: 2, indexes: 2 },
		// 	],
		// 	level: 1,
		// 	indexes: 1,
		// },
		// {
		// 	id: '4',
		// 	name: 'テーマ',
		// 	type: 'directory',
		// 	children: [
		// 		{ id: '5', name: 'ベーシック', type: 'directory', parentId: 'テーマ', children: [
		// 			{ id: '6', name: 'シンプル', type: 'directory', parentId: 'ベーシック', children: [], level: 3, indexes: 1 },
		// 		], level: 2, indexes: 1},
		// 	],
		// 	level: 1,
		// 	indexes: 2,
		// },
		// { id: '7', name: 'ファミリー', type: 'directory', children: [], level: 1, indexes: 3 },
	],
	selectFolder: null,
	templatesInfoList: [],
	draggingTemplate: '',
	draggingFavoriteTemplate: '',
	hoverFavoriteTemplate: '',
	openFavoriteTemplateList: [],
	deleteTemplateId: '',
	draggingTempFolder: null,
	getResponseFolderList: [],
	updateFavoriteTemplate: false,
};

const addFolder: ActionType<FavoriteTemplateSliceState, FavoriteTemplateItem> = (state, action) => {
	// state.folderList.push(action.payload);
	if (state.selectFolder) {
		const result: FavoriteTemplateItem[] = [];
		while (state.folderList.length > 0) {
			const row = state.folderList.shift();
			if(row?.children && row.children.length > 0 && row.id !== state.selectFolder.id) {
				const rowChildren: FavoriteTemplateItem[] = createChildrenArray(row, state.selectFolder, false, action.payload);
				row.children = rowChildren;
				result.push(row);
			} else if (row?.children?.length === 0 && row.id !== state.selectFolder.id) {
				result.push(row);
			} else {
				row?.children?.push(action.payload);
				if (row) {
					result.push(row);
				}
			}
		}
		state.folderList = Object.assign(state.folderList, result);
	} else {
		state.folderList.push(action.payload);
	}
};

const setFolderList: ActionType<FavoriteTemplateSliceState, FavoriteTemplateItem[]> = (state, action) => {
	state.folderList = action.payload;
};

const createChildrenArray = (
	searchRow: FavoriteTemplateItem,
	targetItem: FavoriteTemplateItem,
	isUpdate: boolean,
	pushChild?: FavoriteTemplateItem,
) => {
	let rowChildren: FavoriteTemplateItem[] = [];
	const children: FavoriteTemplateItem[] = [];
	searchRow.children?.forEach((childNode) => {
		if (childNode.id !== targetItem.id && childNode.children && childNode.children.length > 0) {
			rowChildren.push(childNode as FavoriteTemplateItem);
			children.push(childNode);
			createChildrenArray(childNode, targetItem, isUpdate, pushChild);
		} else if (childNode.id !== targetItem.id && childNode.children && childNode.children.length === 0) {
			rowChildren.push(childNode as FavoriteTemplateItem);
			children.push(childNode);
		} else if (isUpdate) {
			childNode = Object.assign(childNode, targetItem);
			rowChildren.push(childNode as FavoriteTemplateItem);
			children.push(childNode);
		} else if(pushChild) {
			childNode.children?.push(pushChild);
			rowChildren.push(childNode as FavoriteTemplateItem);
			children.push(childNode);
		}
		searchRow.children = children;
	});
	return rowChildren;
};

const changeName: ActionType<FavoriteTemplateSliceState, FavoriteTemplateItem> = (state, action) => {
	const result: FavoriteTemplateItem[] = [];
	while (state.folderList.length > 0) {
		let row = state.folderList.shift();
		if (row?.children && row.children.length > 0 && row.id !== action.payload.id) {
			const rowChildren: FavoriteTemplateItem[] = createChildrenArray(row, action.payload, true);
			row.children = rowChildren;
			result.push(row);
		} else if (row?.children?.length === 0 && row.id !== action.payload.id) {
			result.push(row);
		} else {
			row = Object.assign(row, action.payload);
			result.push(row);
		}
	}
	state.folderList = Object.assign(state.folderList, result);
};

const deleteFolder: ActionType<FavoriteTemplateSliceState, FavoriteTemplateItem> = (state, action) => {
	const result: FavoriteTemplateItem[] = [];
	while (state.folderList.length > 0) {
		const row = state.folderList.shift();
		if(row?.children && row.children.length > 0 && row.id !== action.payload.id) {
			const rowChildren: FavoriteTemplateItem[] = createChildrenArray(row, action.payload, false);
			row.children = rowChildren;
			result.push(row);
		} else if (row?.children?.length === 0 && row.id !== action.payload.id) {
			result.push(row);
		}
	}
	state.folderList = Object.assign(state.folderList, result);
};

const setSelectFolder: ActionType<FavoriteTemplateSliceState, FavoriteTemplateItem | null> = (state, action) => {
	state.selectFolder = action.payload;
};

const addTemplatesInfoList: ActionType<FavoriteTemplateSliceState, FavoriteTemplateListItem> = (state, action) => {
	state.templatesInfoList = [...state.templatesInfoList, action.payload];
};

const setDraggingTemplate: ActionType<FavoriteTemplateSliceState, string> = (state, action) => {
	state.draggingTemplate = action.payload;
};

const setDraggingFavoriteTemplate: ActionType<FavoriteTemplateSliceState, string> = (state, action) => {
	state.draggingFavoriteTemplate = action.payload;
}

const setHoverFavoriteTemplate: ActionType<FavoriteTemplateSliceState, string> = (state, action) => {
	state.hoverFavoriteTemplate = action.payload;
}

const setOpenFavoriteTemplateList: ActionType<FavoriteTemplateSliceState, FavoriteTemplateItem[]> = (state, action) => {
	const arr = [...state.openFavoriteTemplateList];
	action.payload.forEach((fav) => {
		const data = arr.find((v) => v.id === fav.id);
		if (data) {
			data.indexes = fav.indexes;
			data.level = fav.level;
			data.name = fav.name;
			data.children = fav.children;
		} else {
			arr.push(fav);
		}
	});
	state.openFavoriteTemplateList = arr;
}

const setCloseFavoriteTemplateList: ActionType<FavoriteTemplateSliceState, FavoriteTemplateItem[]> = (state, action) => {
	const arr = [...state.openFavoriteTemplateList];
	action.payload.forEach((fav) => {
		const index = arr.findIndex((v) => fav.id === v.id);
		if (index !== -1) {
			arr.splice(index, 1);
		}
	});
	state.openFavoriteTemplateList = arr;
}

const setDeleteTemplateId: ActionType<FavoriteTemplateSliceState, string> = (state, action) => {
	state.deleteTemplateId = action.payload;
};

const setDraggingTempFolder: ActionType<FavoriteTemplateSliceState, LayoutTemplateFolderData | null> = (state, action) => {
	state.draggingTempFolder = action.payload;
};

const setGetResponseFolderList: ActionType<FavoriteTemplateSliceState, FavoriteFolderListItem[]> = (state, action) => {
	state.getResponseFolderList = action.payload;
};

const updateFavoriteTemplate: ActionType<FavoriteTemplateSliceState, boolean> = (state, action) => {
	state.updateFavoriteTemplate = action.payload;
};

export const favoriteTemplateSlice = createSlice({
  name: 'favorite-template',
  initialState,
  reducers: {
		addFolder,
		setFolderList,
		changeName,
		deleteFolder,
		setSelectFolder,
		addTemplatesInfoList,
		setDraggingTemplate,
		setDraggingFavoriteTemplate,
		setHoverFavoriteTemplate,
		setOpenFavoriteTemplateList,
		setCloseFavoriteTemplateList,
		setDeleteTemplateId,
		setDraggingTempFolder,
		setGetResponseFolderList,
		updateFavoriteTemplate,
	},
});

const asyncAction = {
  changeFolderIndex: (
		kijshopCd: string,
		id: string,
		target: FavoriteTemplateItem,
		targetIndex: number,
		loading: (v: boolean) => void,
		side: string = 'top',
		callback?: () => void,
	): AppThunk => async (dispatch, getState) => {
		const apiRun = (list: FavoriteTemplateItem[], callback: () => void) => {
			list.forEach((v,i) => {
				dispatch(apiActions.run(
					new ApiFavoriteFolderPost({
						kijshopCd,
						name: v.name,
						kind: '1',
						level: v.level || 1,
						parentId: v.parentId,
						indexes: i,
						id: v.id,
					}, v.id),
					{
						onSuccess: callback,
						onError: callback,
					},
				));
			})
		}
		// 名前重複
		const nameCheck = () => {
			loading(false);
			dispatch(dialogActions.pushMessage({
				title: '確認',
				message: [
					'移動先に同一名称のフォルダが存在します',
				],
				buttons: [
					{
						label: 'はい',
						callback: () => dispatch(dialogActions.pop()),
					},
				],
			}));
		}
		// フォルダリスト
		const list = [...getState().favoriteTemplate.folderList];
		const _list = [...getState().favoriteTemplate.openFavoriteTemplateList];
		// 動かすデータ
    const draggingData = _list.find((v) => v.id === id);
    if ( !draggingData || draggingData.id === target.id) {
			loading(false);
			return;
		}
    if (side) {
      if (target.level === 1 && draggingData.level === 1) {
				// どちらも第一階層の場合
        const x = list.filter((v) => v.id !== draggingData.id);
        const z = (side === 'top') ? target.indexes! - 0.5 : target.indexes! + 0.5;
        const newArr = [...x,{...draggingData, indexes: z}];
        newArr.sort((a, b) => {
          if (a.indexes! > b.indexes!){
            return 1;
          } else {
            return -1;
          }
        })
        let cnt = newArr.length;
        const callbackEnd = () => {
          cnt--;
          if (!cnt) {
            callback?.();
          }
        };
				apiRun(newArr, callbackEnd);
      } else if (target.parentId){
				// ターゲットが第二階層以下の場合
        const parentId =  (target.level === 2) ? _list.find((v) => target.parentId === v.id)!.id : _list.find((v) => target.parentId === v.id)!.parentId;
				const parent =  (target.level === 2) ? list.find((v) => v.id === parentId) : list.find((v) => v.id === parentId)!.children.find((v) => v.id === target.parentId );
				if ((target.level && draggingData.children.filter((v) => v.type === 'directory').length) &&
					((target.level === 2 && draggingData.children.find((v) => v.children.filter((vv) => vv.type === 'directory').length)) || target.level === 3 ) ) {
					loading(false);
					dispatch(dialogActions.pushMessage({
						title: '確認',
						message: [
							'3階層以上になるフォルダ移動はできません',
						],
						buttons: [
							{
								label: 'はい',
								callback: () => dispatch(dialogActions.pop()),
							},
						],
					}));
					return;
				}
        let beforeArr = [];
        if (draggingData.level && draggingData.level > 1) {
           beforeArr = _list.find((v) => v.id === draggingData.parentId)!.children.filter((v) => v.id !== draggingData.id);
        } else {
					beforeArr = list.filter((v) => v.id !== draggingData.id);
				}
        const data = {
          ...draggingData,
          parentId: target.parentId,
          level: target.level,
          indexes: (side === 'top') ? target.indexes!  - 0.5 : target.indexes! + 0.5,
        }
				if (target.parentId !== draggingData.parentId) {
					if (parent!.children.find((v) => v.name === draggingData.name)) {
						nameCheck();
						return;
					}
					const newArr = [...parent!.children, data];
					newArr.sort((a, b) => {
						if (a.indexes! > b.indexes!) {
							return 1;
						} else {
							return -1;
						}
					});
					let cnt = beforeArr.length + newArr.length;
					const callbackEnd = () => {
						cnt--;
						if (!cnt) {
							callback?.();
						}
					};
					apiRun(beforeArr, callbackEnd);
					apiRun(newArr, callbackEnd);
				} else {
					const newArr = [...beforeArr, data];
					newArr.sort((a, b) => {
						if (a.indexes! > b.indexes!) {
							return 1;
						} else {
							return -1;
						}
					});
					let cnt = newArr.length;
					const callbackEnd = () => {
						cnt--;
						if (!cnt) {
							callback?.();
						}
					};
					apiRun(newArr, callbackEnd);
				}
			} else if (!target.parentId) {
				// ターゲットが第一階層　かつ　ドラッグデータが第二階層以下
				if (list.find((v) => v.name === draggingData.name)) {
					nameCheck();
					return;
				}
				const beforeArr: FavoriteTemplateItem[] = _list.find((v) => v.id === draggingData.parentId)!.children.filter((v) => v !== draggingData);
				const data = {
					...draggingData,
					level: target.level,
					parentId: '',
					indexes: (side === 'top') ? target.indexes!  - 0.5 : target.indexes! + 0.5,
				}
				const newArr = [...list, data];
				newArr.sort((a, b) => {
					if (a.indexes! > b.indexes!) {
						return 1;
					} else {
						return -1;
					}
				});
				let cnt = beforeArr.length + newArr.length;
				const callbackEnd = () => {
					cnt--;
					if (!cnt) {
						callback?.();
					}
				};
				apiRun(beforeArr, callbackEnd);
				apiRun(newArr, callbackEnd);
      } else if (!side) {
				// フォルダの中に入れる
        if (target.type !== 'directory') return;
        const level = target.level! + 1;
        if (level > 3) {
          return;
        } else if (draggingData.children.length > 0 && level > 2){
          return;
        }
        let beforArr = [];
        if (draggingData.level === 1) {
          beforArr = list.filter((v) => v.id !== draggingData.id);
        } else {
          const parentId = _list.find((v) => v.id === draggingData.parentId)!.id;
					const parent = list.find((v) => v.id === parentId);
          beforArr = parent!.children.filter((v) => v.id !== draggingData.id);
        }

        const data = {
          ...draggingData,
          parentId: target.id,
          level: level,
        }
        const newArr = [...target.children, data];
        let cnt = beforArr.length + newArr.length;
        const callbackEnd = () => {
          cnt--;
          if (!cnt) {
            callback?.();
          }
        };
				apiRun(beforArr, callbackEnd);
				apiRun(newArr, callbackEnd);
      }
    }
  }
};
export const favoriteTemplateActions = Object.assign(favoriteTemplateSlice.actions, asyncAction);
export const favoriteTemplateReducer = favoriteTemplateSlice.reducer;
