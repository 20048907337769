import { ButtonHTMLAttributes, DetailedHTMLProps, useEffect, useRef, useState } from 'react';
import { DisplayElement } from '../../../models/display-element';
import { DynamicTooltip } from '../tooltip/dynamic-tooltip';
import { EmphasisType, UiSize } from '../ui.type';
import './button.scss';

export type ButtonRectType = {
  width?: number,
  height?: number,
  top?: number,
  left?: number,
};

export type ButtonProps = {
  // - ボタンlabel -
  label?: DisplayElement;
  color?: EmphasisType;
  size?: UiSize;
  icon?: globalThis.JSX.Element;
  getRect?: (rect: ButtonRectType) => void,
  tooltip?: { type: "error" | "normal", text: string[], offset?: { x?: string, y?: string } },
} & DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement>;

export const Button = (props: ButtonProps) => {
  const { label, color = 'primary', size = 'md', icon, getRect, tooltip, ...defaultProps } = props;
  // - Ref -
  const ref = useRef<HTMLButtonElement>(null);
  // - State -
  const [hover, setHover] = useState(false);
  // - Effect -
  useEffect(() => {
    if (ref.current && getRect) {
      getRect({
        top: ref.current.offsetTop,
        left: ref.current.offsetLeft,
        width: ref.current.clientWidth,
        height: ref.current.clientHeight,
      })
    }
  }, [getRect, ref.current]);

  return (
    <button
      {...defaultProps}
      onKeyPress={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
      className={`button ${color} ${size} ${icon ? 'icon_button' : ''} ${defaultProps.className || ''}`}
      ref={ref}
      // ref={getRect ? ref : undefined}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {icon ? (
        <span className={`button__icon ${label ? '' : 'mr_0'}`}>{icon}</span>
      ) : <></>}
      {label ? <span className='button__label'>{label}</span>
        : <></>}
      <div className="button__hover_cover" />
      {tooltip && hover ? (
        <DynamicTooltip
          messages={tooltip.text}
          relativeEle={ref.current}
          error={tooltip.type === "error"}
          offset={tooltip.offset}
        />
      ) : (<></>)}
    </button >
  );
};
