
export type TXmlRootTagModel = {
  version: string,
  systemCode: string,
  systemVersion: string,
  systemversion: string,
  subVersion: string,
  subCode: string,
  uploadVersion: string,
  contentGroupID?: string,
  parentPath?: string,
  parentXPath?: string,
};

export const xmlRootTagModel: TXmlRootTagModel = {
  version: '3.0.0',
  systemversion: '3.0.0',
  systemCode: '01',
  subVersion: '3.0.0',
  systemVersion: '',
  subCode: '',
  uploadVersion: '',
};