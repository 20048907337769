import React, { DetailedHTMLProps, InputHTMLAttributes, useState, useCallback, useEffect, useRef } from 'react';
import {Select} from '../select/select';
import {Input} from './input';
import { DateFormatter } from '../../../utilities/date-formatter';
import { Tooltip } from '../tooltip/tooltip';
import { isNaN } from 'lodash';
import { useDispatch } from 'react-redux';
import { dialogActions } from '../../dialog/slice/dialog-slice';
import { ordersActions } from '../../../slices/orders-slice';
import { useAppSelector } from '../../../app/hooks';
import * as lodash from 'lodash';
import { getDate, getFormat } from './calendar';

type LayoutCalendarProps = {
  // - 日付取得 -
  getFormatDate: (date: string, formatType: string, initialType: string) => void,
  initValue: {value: string, formatType: string, initialType: string},
  callbackDate?: (v: string) => void,
} & DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

// - フォーマットされた日付リスト -
type FormatDate = {
  value: string,
  label: string,
}

// - 月（英語表記）リスト -
const enMonth = ['', 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
// - 日にち（英語表記）リスト -
const enDate = ['th', 'st', 'nd', 'rd'];
// - 文字タイプリスト -
const initialTypeList = [{value: '0', label: '先頭のみ大文字'}, {value: '1', label: '全て大文字'}];

export const LayoutCalendar = (props: LayoutCalendarProps) => {
  const {getFormatDate, initValue, callbackDate, ...defaultProps} = props;
  // const { dateValue, _formatType, _formatCase } = useAppSelector((state) => ({
  //   dateValue: state.order.descriptionInfo.date.value,
  //   _formatType: state.order.descriptionInfo.formatType,
  //   _formatCase: state.order.descriptionInfo.formatCase,
  // }), lodash.isEqual);
  const dispatch = useDispatch();
  // - State -
  // - 日付入力時のevent -
  const [eventInput, setEventInput] = useState<any>();
  // -- フォーマットされた日付リスト -
  const [formatDateList, setFormatDateList] = useState<FormatDate[]>([]);
  // - フォーマットタイプ -
  const [formatType, setFormatType] = useState(initValue.formatType);
  // - 文字タイプ -
  const [initialType, setInitialType] = useState(initValue.initialType);
  // -- 自由入力 --
  const [freeInputDate, setFreeInputDate] = useState(initValue.value);
  // フォーマットアラート
  const [alert, setAlert] = useState(false);
  // - Callback -
  // -- 日付入力 --
  const handleInputChange = useCallback((e, initType, fType) => {
    if (e?.target?.value) {
      setAlert(false);
      setEventInput(e);
      const fullDate = new Date(e.target.value);
      const year = fullDate.getFullYear();
      const month = fullDate.getMonth() + 1;
      const date = fullDate.getDate();
      const formatList: any[] = [];
      let enMonthList = enMonth;
      let enDateList = enDate;
      if(initType === '0') {
        enMonthList = enMonth;
        enDateList = enDate;
      } else {
        enMonthList = enMonth.map((v) => v.toUpperCase());
        enDateList = enDate.map((v) => v.toUpperCase());
      }
      if (!isNaN(year) && !isNaN(month) && !isNaN(date)) {
        const en1 = {
          value: '0',
          label: enMonthList[month] + ' ' +
            (date === 1 || date === 21 || date === 31 ? date + enDateList[1] :
              date === 2 || date === 22 ? date + enDateList[2] :
                date === 3 || date === 23 ? date + enDateList[3] : date + enDateList[0]) + ', ' +
            year
        }
        const en2 = {
          value: '1',
          label: enMonthList[month] + ' ' + date + ', ' + year
        }
        const en3 = {
          value: '2',
          label: enMonthList[month].substring(0, 3) + '. ' + date + ', ' + year
        }
        const en4 = {
          value: '3',
          label: (date === 1 || date === 21 || date === 31 ? date + enDateList[1] :
              date === 2 || date === 22 ? date + enDateList[2] :
                date === 3 || date === 23 ? date + enDateList[3] : date + enDateList[0]) + ' ' +
            enMonthList[month] + ', ' +
            year
        }
        const en5 = {
          value: '4',
          label: date + ' ' + enMonthList[month] + ', ' + year
        }
        const en6 = {
          value: '5',
          label: date + ' ' + enMonthList[month].substring(0, 3) + '. ' + year
        }
        const slash = {
          value: '6',
          label: year + '/' +
            (String(month).length === 1 ? ('0' + month) : month) + '/' +
            (String(date).length === 1 ? ('0' + date) : date),
        }
        const dot = {
          value: '7',
          label: year + '.' + month + '.' + date,
        }
        const ja1 = {
          value: '8',
          label: year + '年' + month + '月' + date + '日',
        }
        const ja2 = {
          value: '9',
          label:  new Intl.DateTimeFormat('ja-JP-u-ca-japanese', {era: 'long', year: 'numeric'}).format(fullDate) + month + '月' + date + '日',
        }
        const free = {
          value: '10',
          label: '自由入力'
        }
        if (fType === '1') {
          en1.label = en1.label.toUpperCase();
          en2.label = en2.label.toUpperCase();
          en3.label = en3.label.toUpperCase();
          en4.label = en4.label.toUpperCase();
          en5.label = en5.label.toUpperCase();
          en6.label = en6.label.toUpperCase();
        }
        formatList.push(en1, en2, en3, en4, en5, en6, slash, dot, ja1, ja2, free);
        if (e.currentTarget) {
          switch (fType) {
            case '0':
              setFreeInputDate(en1.label);
              break;
            case '1':
              setFreeInputDate(en2.label);
              break;
            case '2':
              setFreeInputDate(en3.label);
              break;
            case '3':
              setFreeInputDate(en4.label);
              break;
            case '4':
              setFreeInputDate(en5.label);
              break;
            case '5':
              setFreeInputDate(en6.label);
              break;
            case '6':
              setFreeInputDate(slash.label);
              break;
            case '7':
              setFreeInputDate(dot.label);
              break;
            case '8':
              setFreeInputDate(ja1.label);
              break;
            case '9':
              setFreeInputDate(ja2.label);
              break;
            case '10':
              setFreeInputDate(free.label);
              break;
          }
        }
        setFormatDateList(formatList);
      }
    }
  }, [initialType, freeInputDate, getFormatDate, formatDateList]);
  const handlerFocusOutDateInput = useCallback(() => {
    const inputDate = getDate(freeInputDate || '', formatType);
    if (!inputDate || !freeInputDate) {
      setAlert(false);
      return;
    }
    const year = inputDate.getFullYear();
    const month = inputDate.getMonth() + 1;
    const date = inputDate.getDate();
    if (isNaN(year) || isNaN(month) || isNaN(date)) {
      dispatch(dialogActions.pushMessage({
        title: '確認',
        message: [
          '入力形式が正しくありません。',
          // '商品情報ー商品詳細ー商品記載情報ー日付',
        ],
        buttons: [
          {
            label: 'OK',
            callback: () => {
              dispatch(dialogActions.pop());
            },
          },
        ],
      }));
      setAlert(true);
      return;
    }
    const formatList: any[] = [];
    let enMonthList = enMonth;
    let enDateList = enDate;
    const en1 = {
      value: '0',
      label: enMonthList[month] + ' ' +
        (date === 1 || date === 21 || date === 31 ? date + enDateList[1] :
          date === 2 || date === 22 ? date + enDateList[2] :
            date === 3 || date === 23 ? date + enDateList[3] : date + enDateList[0]) + ', ' +
        year
    }
    const en2 = {
      value: '1',
      label: enMonthList[month] + ' ' + date + ', ' + year
    }
    const en3 = {
      value: '2',
      label: enMonthList[month].substring(0, 3) + '. ' + date + ', ' + year
    }
    const en4 = {
      value: '3',
      label: (date === 1 || date === 21 || date === 31 ? date + enDateList[1] :
          date === 2 || date === 22 ? date + enDateList[2] :
            date === 3 || date === 23 ? date + enDateList[3] : date + enDateList[0]) + ' ' +
        enMonthList[month] + ', ' +
        year
    }
    const en5 = {
      value: '4',
      label: date + ' ' + enMonthList[month] + ', ' + year
    }
    const en6 = {
      value: '5',
      label: date + ' ' + enMonthList[month].substring(0, 3) + '. ' + year
    }
    const slash = {
      value: '6',
      label: year + '/' +
        (String(month).length === 1 ? ('0' + month) : month) + '/' +
        (String(date).length === 1 ? ('0' + date) : date),
    }
    const dot = {
      value: '7',
      label: year + '.' +
        (String(month).length === 1 ? ('0' + month) : month) + '.' +
        (String(date).length === 1 ? ('0' + date) : date),
    }
    const ja1 = {
      value: '8',
      label: new Intl.DateTimeFormat('ja-JP').format(inputDate)
    }
    const ja2 = {
      value: '9',
      label: new Intl.DateTimeFormat('ja-JP-u-ca-japanese', {era: 'long', year: 'numeric'})
        .format(inputDate) + month + '月' + date + '日',
    }
    const free = {
      value: '10',
      label: '自由入力'
    }
    if (initialType === '1') {
      en1.label = en1.label.toUpperCase();
      en2.label = en2.label.toUpperCase();
      en3.label = en3.label.toUpperCase();
      en4.label = en4.label.toUpperCase();
      en5.label = en5.label.toUpperCase();
      en6.label = en6.label.toUpperCase();
    }
    formatList.push(en1, en2, en3, en4, en5, en6, slash, dot, ja1, ja2, free);
    let flag = true;
    switch (formatType) {
      case '0':
        flag = freeInputDate === en1.label;
        // setFreeInputDate(en1.label);
        break;
      case '1':
        flag = freeInputDate === en2.label;
        break;
      case '2':
        flag = freeInputDate === en3.label;
        break;
      case '3':
        flag = freeInputDate === en4.label;
        break;
      case '4':
        flag = freeInputDate === en5.label;
        break;
      case '5':
        flag = freeInputDate === en6.label;
        break;
      case '6':
        flag = freeInputDate === slash.label;
        break;
      case '7':
        flag = freeInputDate === dot.label;
        break;
      case '8':
        flag = freeInputDate === ja1.label;
        break;
      case '9':
        const formatInputDate = getFormat(freeInputDate);
        if (formatInputDate.date) {
          const month = formatInputDate.date.getMonth() + 1;
          const date = formatInputDate.date.getDate();
          const format = new Intl.DateTimeFormat('ja-JP-u-ca-japanese', { era: 'long', year: 'numeric' })
            .format(formatInputDate.date) + month + '月' + date + '日';
          if (format) {
            callbackDate?.(format);
            setFreeInputDate(format);
            return format === ja2.label;
          }
        }
        flag = freeInputDate === ja2.label;
        break;
      }
      setAlert(!flag);
      if (flag) {
        setFormatDateList(formatList);
      } else {
        dispatch(dialogActions.pushMessage({
          title: '確認',
          message: [
            '入力形式が正しくありません。',
            // '商品情報ー商品詳細ー商品記載情報ー日付',
          ],
          buttons: [
            {
              label: 'OK',
              callback: () => {
                dispatch(dialogActions.pop());
              },
            },
          ],
        }));
      }
  }, [freeInputDate, initialType, formatType]);
  // -- フォーマットされた日付 --
  const handleFormatChange = useCallback((e) => {
    setAlert(false);
    setFormatType(e.target.value);
    // dispatch(ordersActions.descriptionInfo.setFormatType(e.target.value));
    if (e.target.value !== '10' && formatDateList[e.target.value]) {
      getFormatDate(formatDateList[e.target.value].label, e.target.value, initialType);
      setFreeInputDate(formatDateList[e.target.value].label);
    }
    if (e.target.value === '10') {
      getFormatDate(freeInputDate, e.target.value, initialType);
    }
  }, [formatDateList, freeInputDate, getFormatDate, handleInputChange, initialType]);
  // -- 文字タイプ --
  const handleInitialChange = useCallback((e) => {
    setAlert(false);
    setInitialType(e.target.value);
    handleInputChange(Object.assign(eventInput, { currentTarget: true }), e.target.value, formatType);
  }, [eventInput, formatType, handleInputChange]);
  // -- 自由入力 --
  const handlerFreeInputChange = useCallback((e) => {
    setFreeInputDate(e.target.value);
    getFormatDate(e.target.value, '10', '0');
  }, [getFormatDate]);

  const checkDate = useCallback((e: any, v: string) => {
    if (e.code === 'Enter') {
      const data = delete4byte(v);
      dispatch(ordersActions.descriptionInfo.setDate(data, alert));
      setFreeInputDate(data);
    }
  }, [freeInputDate]);

  const delete4byte = useCallback((v: string): string => {
    const stringToArr = (str: string) => str.match(/[\uD800-\uDBFF][\uDC00-\uDFFF]|[^\uD800-\uDFFF]/g) || [];
    const base = stringToArr(v);
    let txt: string = '';
    base.forEach((vv) => {
      const char = vv.charCodeAt(1);
      if (!char) {
        txt += vv;
      }
    });
    return txt;
  }, []);

  useEffect(() => {
    if (freeInputDate && !formatType && !initialType) {
      const data = getFormat(freeInputDate);
      setFormatType(data.formatType);
      setInitialType(data.initType || '0');
      handleInputChange({ target: { value: DateFormatter.date2str(new Date()) } } as any, '0', '0');
    }
  }, [freeInputDate, formatDateList]);
  useEffect(() => {
    callbackDate?.(freeInputDate);
  }, [freeInputDate, alert]);

  useEffect(() => {
    if ((initValue.value || initValue.value === '') && formatType !== '10') {
      const data = getFormat(initValue.value);
      setFormatType(data.formatType);
      setInitialType(data.initType);
      setFreeInputDate(initValue.value);
      if (data.date) {
        handleInputChange({ target: { value: DateFormatter.date2str(data.date) } } as any, data.initType, data.formatType);
      } else if (!data.date && (initValue.value || initValue.value === '')) {
        handleInputChange({ target: { value: DateFormatter.date2str(new Date()) } } as any, data.initType, data.formatType);
      }
    } else {
      handleInputChange({ target: { value: DateFormatter.date2str(new Date()) } } as any, '0', '0');
    }
  }, [initValue.value]);

  return (
    <>

      <div className="left_label_form item_album_date">
        <span>日付</span>
        <Input
          value={freeInputDate}
          onKeyUp={(e) => (formatType === '10') ? checkDate(e, freeInputDate || '') : null}
          onChange={formatType === '10' ? handlerFreeInputChange : (e) => handleInputChange(e, initialType, formatType)}
          customDateMode={formatType !== '10'}
          className={formatType === '10' ? 'custom_date' : ''}
          error={formatType !== '10' && alert}
          tooltip={formatType !== '10' && alert ? {
            messages: ['入力形式が正しくありません。'],
            error: true,
          } : undefined}
          onChangeTextArea={formatType !== '10' ? (v) => setFreeInputDate(v) : undefined}
          // onBlur={formatType !== '10' ? handlerFocusOutDateInput : undefined}
          onBlur={() => {
            if (formatType !== '10') {
              handlerFocusOutDateInput();
            } else {
              callbackDate?.(delete4byte(freeInputDate));
              setFreeInputDate(delete4byte(freeInputDate));
            }
          }}
          maxLength={30}
          date={getDate(freeInputDate || '', formatType)}
        />
      </div>

      <div className="left_label_form item_album_date_format">
        <span>日付形式</span>
        <div className="flex_box gap_3">
          <Select
            value={formatType}
            list={formatDateList}
            onChange={handleFormatChange}
          />
          <Select
            value={initialType}
            list={initialTypeList}
            onChange={handleInitialChange}
            disabled={
              formatType !== '0'
              && formatType !== '1'
              && formatType !== '2'
              && formatType !== '3'
              && formatType !== '4'
              && formatType !== '5'
            }
          />
        </div>
      </div>

    </>
  )
};

// import React, { DetailedHTMLProps, InputHTMLAttributes, useCallback, useEffect, useState, useRef } from 'react';
// import { Tooltip } from '../tooltip/tooltip';
// import { UiSize } from '../ui.type';
// import './input.scss';
