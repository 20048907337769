import { ActionType } from '../../models/action-type';
import { createSlice } from '@reduxjs/toolkit';
import { StoreGetResponse } from '../../models/api/front/store';
import { OrderFormGetResponse } from '../../models/api/front/order-form';

export type SendFormDesignation = {
  name: string,
  path: string,
  id: string,
  originalPath: string,
  importPath: string,
}

export type SendStore = {
  shopName: string,
  shippingShopId: string,
}

export type AddressState = {
  name: string,
  zipCodeBefore: string,
  zipCodeAfter: string,
  prefecture: string,
  address1: string,
  address2: string,
  tel: string,
  mail: string,
  deliveryTime: string,
  dispTimeZone: string,
  start: string,
  end: string,
  salesStore: string,
  sendStore: {
    shopName?: string,
    shippingShopId?: string
  },
  sendFormDesignation: {
    name?: string,
    path?: string,
    id?: string,
    originalPath?:string,
    importPath?: string,
  }
}

type DeliverySliceState = {
  address: AddressState,
  sendStore: StoreGetResponse[],
  orderForm: OrderFormGetResponse[],
}

const initialState: DeliverySliceState = {
  address: {
    name: '',
    zipCodeBefore: '',
    zipCodeAfter: '',
    prefecture: '',
    address1: '',
    address2: '',
    tel: '',
    mail: '',
    deliveryTime: '',
    dispTimeZone: '',
    start: '',
    end: '',
    salesStore: '',
    sendStore: {
      shopName: '',
      shippingShopId: '',
    },
    sendFormDesignation: {
      name: '',
      path: '',
      id: '',
      originalPath: '',
      importPath: '',
    },
  },
  sendStore: [],
  orderForm: [],
};
const setAddressState: ActionType<DeliverySliceState, Partial<AddressState>> = (state, action) => {
  state.address = Object.assign(state.address, action.payload);
};
const setSendStoreState: ActionType<DeliverySliceState, Partial<StoreGetResponse[]>> = (state, action) => {
  state.sendStore = [];
  state.sendStore = Object.assign(state.sendStore, action.payload);
};
const setOrderFormState: ActionType<DeliverySliceState, Partial<OrderFormGetResponse[]>> = (state, action) => {
  state.orderForm = [];
  state.orderForm = Object.assign(state.orderForm, action.payload);
};

export const deliverySlice = createSlice({
  name: 'delivery',
  initialState,
  reducers: {
    setAddressState,
    resetAddressState: (state) => {
      state.address = initialState.address;
    },
    setSendStoreState,
    resetSendStoreState: (state) => {
      state.sendStore = [];
    },
    setOrderFormState,
  },
});

export const deliveryActions = deliverySlice.actions;
export const deliveryReducer = deliverySlice.reducer;