import { PartsRequest, PartsResponse } from '../../../../models/api/back/create-order/parts';
import {AppThunk} from "../../../../app/store";
import {ordersDataActions} from "../../../../slices/orders/orders-data-slice";
import {apiActions} from "../../../../slices/api-slice";
import {ResponseBase} from "../../../response-base";
import { apiLaminatesCoverThunk, apiLaminatesGenuineThunk } from '../laminates/api-laminates';
import {ApiBaseBack} from "../../api-base-back";
import { ProcessInfoRequest, ProcessInfoResponse } from '../../../../models/api/back/create-order/process-info';

export class ApiProcessInfo extends ApiBaseBack<ProcessInfoResponse> {
  constructor(param: ProcessInfoRequest) {
    super(
      'GET',
      '/api/v1/processInfos',
      'JSON',
      param,
    );
  }
}

export const apiProcessInfoThunk = (param: ProcessInfoRequest, callback?: () => void, isInit?: boolean): AppThunk => async (dispatch,getState) => {
  // このAPIのレスポンスを元にラミネートを取得するためそちらも更新
  if (!param.orderMethodId) {
    dispatch(ordersDataActions.updateData({
      processInfo: { orderMethod: [] },
    }));
    callback?.();
    return;
  }
  dispatch(apiActions.run(
    new ApiProcessInfo(param),
    {
      onSuccess: (res: ResponseBase<ProcessInfoResponse>) => {
        callback?.();
        if (!isInit && param.orderMethodId !== getState().order.itemDetail.orderMethod.value) {
          return;
        }
        dispatch(ordersDataActions.updateData({
          processInfo: { orderMethod: res.body.data?.orderMethod || [] },
        }));
      },
      onError: () => {
        callback?.();
      },
    },
    {
      apiName: 'process-info',
      ignoreSystemError: true,
    },
  ));
};
