// connection.ts ver 3.5.0

import { ApiBase } from '../../api/api-base';
import { CustomError } from '../../models/custom-error';
import { ErrorMessageCollection } from '../../collection/error-message-collection';
import { LogDecorator } from '@tenryu/log-decorator';
import { XmlParser } from '../../manager/xml-parser';

type ConnectionOptions = {
  timeoutMS?: number,
};
class ConnectionClass {
  private defaultOptions: ConnectionOptions = {
    timeoutMS: 960000,
  };

  do<T>(
    request: ApiBase,
    options: ConnectionOptions = this.defaultOptions,
  ): Promise<T | CustomError> {
    let promise = null;
    switch (request.method) {
      case 'GET':
        promise = this.get(request, options);
        break;
      case 'POST':
        promise = this.post(request, options);
        break;
      case 'PUT':
        promise = this.post(request, options);
        break;
      case 'DELETE':
        promise = this.delete(request, options);
        break;
      default:
        throw new CustomError(
          'RangeError',
          [
            ErrorMessageCollection.COMMON_RANGE_ERROR_1.ja,
          ],
          request,
        );
    }
    return promise
      .catch((e) => {
        throw e;
      });
  }

  private post(
    request: ApiBase,
    options: ConnectionOptions = this.defaultOptions,
  ) {
    const opt = { ...this.defaultOptions, ...options };
    const url = `${request.createURL()}`;
    const headers = request.createHeader();
    const body = request.createFormData();
    return this.fetchTimeOut(
      url,
      {
        // credentials: 'include',
        method: request.method,
        mode: 'cors',
        headers,
        body,
      },
      opt.timeoutMS,
    )
      .then((res) => {
        if (!res?.ok) {
          const error = new CustomError(
            'NetworkError',
            [ErrorMessageCollection.COMMON_NETWORK_ERROR_1.ja],
            res,
          );
          throw error;
        }
        switch (request.responseType) {
          case 'JSON':
            return res.json()
              .then((json) => {
                this.log(true, request, res, json);
                return json;
              });
          case 'BLOB':
            return res.blob()
              .then((blob) => {
                const contentType = res.headers.get('Content-Type') || '';
                const contentTypeArr = contentType.replace(/\s/g, '').split(';');
                const contentDisposition = res.headers.get('Content-Disposition') || '';
                const contentDispositionArr = contentDisposition.replace(/\s/g, '').split(';');
                // ファイル名は Content-Disposition にエンコードされた状態で格納
                const nameItem = contentTypeArr.find((v) => v.indexOf('name') === 0) || contentDispositionArr.find((v) => v.indexOf('filename') === 0) || '';
                if ((contentTypeArr.length > 1 || contentDispositionArr.length) && nameItem) {
                  if (nameItem.indexOf('*=UTF-8\'\'') !== -1) {
                    const name = decodeURI(nameItem.split('*=UTF-8\'\'')[1]);
                    const file = new File([blob], name);
                    this.log(true, request, res, file);
                    return file;
                  }
                  const name = nameItem.split('=').length > 1 ? nameItem.split('=')[1] : '';
                  const file = new File([blob], name);
                  this.log(true, request, res, file);
                  return file;
                }
                const parsedBlob = request.parse(blob);
                this.log(true, request, res, parsedBlob);
                return parsedBlob;
              });
          default:
            return res.text()
              .then(async (text) => {
                const parsedText = await request.parse(text);
                this.log(true, request, res, parsedText);
                return parsedText;
              });
        }
      })
      .catch((err) => {
        if (err.object.constructor === Response) {
          this.log(false, request, err.object, err);
        } else {
          this.log(false, request, null, err);
        }
        throw err;
      });
  }

  private delete(
    request: ApiBase,
    options: ConnectionOptions = this.defaultOptions,
  ) {
    const opt = { ...this.defaultOptions, ...options };
    const url = `${request.createURL()}${request.createQueryParam()}`;
    const headers = request.createHeader();
    return this.fetchTimeOut(
      url,
      {
        // credentials: 'include',
        method: request.method,
        mode: 'cors',
        headers,
      },
      opt.timeoutMS,
    )
      .then((res) => {
        if (!res?.ok) {
          const error = new CustomError(
            'NetworkError',
            [ErrorMessageCollection.COMMON_NETWORK_ERROR_1.ja],
            res,
          );
          throw error;
        }
        switch (request.responseType) {
          case 'JSON':
            return res.json()
              .then((json) => {
                this.log(true, request, res, json);
                return json;
              });
          case 'BLOB':
            return res.blob()
              .then((blob) => {
                const contentType = res.headers.get('Content-Type') || '';
                const contentTypeArr = contentType.replace(/\s/g, '').split(';');
                const contentDisposition = res.headers.get('Content-Disposition') || '';
                const contentDispositionArr = contentDisposition.replace(/\s/g, '').split(';');
                // ファイル名は Content-Disposition にエンコードされた状態で格納
                const nameItem = contentTypeArr.find((v) => v.indexOf('name') === 0) || contentDispositionArr.find((v) => v.indexOf('filename') === 0) || '';
                if ((contentTypeArr.length > 1 || contentDispositionArr.length) && nameItem) {
                  if (nameItem.indexOf('*=UTF-8\'\'') !== -1) {
                    const name = decodeURI(nameItem.split('*=UTF-8\'\'')[1]);
                    const file = new File([blob], name);
                    this.log(true, request, res, file);
                    return file;
                  }
                  const name = nameItem.split('=').length > 1 ? nameItem.split('=')[1] : '';
                  const file = new File([blob], name);
                  this.log(true, request, res, file);
                  return file;
                }
                const parsedBlob = request.parse(blob);
                this.log(true, request, res, parsedBlob);
                return parsedBlob;
              });
          default:
            return res.text()
              .then(async (text) => {
                const parsedText = await request.parse(text);
                this.log(true, request, res, parsedText);
                return parsedText;
              });
        }
      })
      .catch((err) => {
        if (err.object.constructor === Response) {
          this.log(false, request, err.object, err);
        } else {
          this.log(false, request, null, err);
        }
        throw err;
      });
  }

  private get(
    request: ApiBase,
    options: ConnectionOptions = this.defaultOptions,
  ) {
    const opt = { ...this.defaultOptions, ...options };
    const url = `${request.createURL()}${request.createQueryParam()}`;
    const headers = request.createHeader();
    return this.fetchTimeOut(
      url,
      {
        // credentials: 'include',
        method: 'GET',
        mode: 'cors',
        headers,
      },
      opt.timeoutMS,
    )
      .then((res) => {
        if (!res.ok) {
          const error = new CustomError(
            'NetworkError',
            [ErrorMessageCollection.COMMON_NETWORK_ERROR_1.ja],
            res,
          );
          throw error;
        }
        switch (request.responseType) {
          case 'JSON':
            return res.json()
              .then((json) => {
                this.log(true, request, res, json);
                return json;
              });
          case 'BLOB':
            return res.blob()
              .then((blob) => {
                const contentType = res.headers.get('Content-Type') || '';
                const contentTypeArr = contentType.replace(/\s/g, '').split(';');
                const nameItem = contentTypeArr.find((v) => v.indexOf('name') === 0) || '';
                if (contentTypeArr.length > 1 && nameItem) {
                  const name = nameItem.split('=').length > 1 ? nameItem.split('=')[1] : '';
                  const file = new File([blob], name);
                  this.log(true, request, res, file);
                  return file;
                }
                const parsedBlob = request.parse(blob);
                this.log(true, request, res, parsedBlob);
                return parsedBlob;
              });
          case 'XML':
            const xmlParser = new XmlParser();
            return res.text()
              .then(async (text) => {
                const parsedXml = await xmlParser.parse(text);
                this.log(true, request, res, parsedXml);
                return parsedXml;
              });
          default:
            return res.text()
              .then(async (text) => {
                const parsedText = await request.parse(text);
                this.log(true, request, res, parsedText);
                return parsedText;
              });
        }
      })
      .catch((err) => {
        if (err.object?.constructor === Response) {
          this.log(false, request, err.object, err);
        } else {
          this.log(false, request, null, err);
        }
        throw err;
      });
  }

  private log(success: boolean, request: ApiBase, response: Response | null, body: any) {
    const debug = ((window as any).debug) ?? false;
    // if (!debug) {
    //   return;
    // }
    const title = success ? (
      `[<green>${response?.status || '---'}</green>] ${request.method} ${request.path}`
    ) : (
      `[<red>${response?.status || '---'}</red>] ${request.method} ${request.path}`
    );
    window.console.groupCollapsed(...LogDecorator(title));
    window.console.group(...LogDecorator('<gray>Request</gray>'));
    // window.console.log(...LogDecorator('<gray>request      :</gray>'), request);
    window.console.log(...LogDecorator('<gray>URL          :</gray>'), request.createURL());
    window.console.log(...LogDecorator('<gray>Param        :</gray>'), request.param);
    window.console.log(...LogDecorator('<gray>header       :</gray>'), request.header);
    window.console.groupEnd();
    window.console.group(...LogDecorator('<gray>Response</gray>'));
    if (response) {
      // window.console.log(...LogDecorator('<gray>response     :</gray>'), response);
      window.console.log(...LogDecorator('<gray>ok           :</gray>'), response.ok);
      window.console.log(...LogDecorator('<gray>headers      :</gray>'), response.headers);
      window.console.log(...LogDecorator('<gray>statusText   :</gray>'), response.statusText);
      window.console.log(...LogDecorator('<gray>api         :</gray>'), response.type);
    }
    if (body.constructor === CustomError) {
      window.console.log(...LogDecorator('<gray>Body         :</gray>'), body.type);
      window.console.log(...LogDecorator('<gray>             :</gray>'), body.messages);
      window.console.log(...LogDecorator('<gray>             :</gray>'), body.object);
    } else {
      if (debug) {
        window.console.log(...LogDecorator('<gray>Body         :</gray>'), body);
      }
    }
    window.console.groupEnd();
    window.console.groupEnd();
  }

  private fetchTimeOut(
    input: RequestInfo,
    init?: RequestInit,
    timeOut?: number,
  ): Promise<Response> {
    const TIME_MS = timeOut || 1000;
    const abortController = new AbortController();
    return new Promise((resolve, reject) => {
      const timerId = window.setTimeout(() => {
        abortController.abort();
        reject(
          new CustomError(
            'Timeout',
            [ErrorMessageCollection.COMMON_NETWORK_ERROR_2.ja],
          ),
        );
      }, TIME_MS);
      fetch(input, { ...init, signal: abortController.signal })
        .then((res) => resolve(res))
        .catch((err) => reject(
          new CustomError(
            'NetworkError',
            [ErrorMessageCollection.COMMON_NETWORK_ERROR_1.ja],
            err,
          ),
        ))
        .finally(() => clearTimeout(timerId));
    });
  }
}
export const Connection = new ConnectionClass();
