/**
 * エラー生成時に使うメッセージ一覧
 */
export const ErrorMessageCollection = {
  // ----- common -----
  // common - unexpected
  COMMON_UNEXPECTED_ERROR_1: {
    ja: '予期しないエラーが発生しました',
    en: 'An unexpected error has occurred !!',
  },
  // common - range
  COMMON_RANGE_ERROR_1: {
    ja: '実装されていないメソッドを使っています',
  },
  // common - network
  COMMON_NETWORK_ERROR_1: {
    ja: '通信でエラーが発生しました',
  },
  COMMON_NETWORK_ERROR_2: {
    ja: 'リクエストがタイム・アウトしました',
  },
};

export type TErrorMessageCollection = typeof ErrorMessageCollection;
