
const svgSize = 18;
const svgColor = '#1899FF';
const r = 5;
const svg = <svg xmlns="http://www.w3.org/2000/svg" x="0" y="0" width={svgSize} height={svgSize} viewBox={`0 0 ${svgSize} ${svgSize}`}>
  <g>
    <rect x="0" y="0" width={svgSize} height={svgSize} fill={svgColor} stroke={svgColor} strokeWidth="1" />
    <g transform={`translate(${svgSize / 2} ${svgSize / 2})`}>
      <circle cx="0" cy="0" r={r} fill="none" stroke="#FFFFFF" strokeWidth="1.2" strokeDasharray={`${(r * 2 * Math.PI / (r + 1)) * r} ${r * 2 * Math.PI / (r + 1)}`}/>
    </g>
    <g transform={`translate(${svgSize / 2} ${svgSize / 2})`}>
      {/*<path d={`M${svgSize / 2 - 1} 1L${svgSize / 2 - 1} ${svgSize / 2 - 1}L${svgSize / 2 + 1} ${svgSize / 2 / 2}Z`} fill="#FFFFFF" stroke="#FFFFFF" strokeWidth="0.5" />*/}
      <path d={`M-1.5 ${-svgSize / 2 + 1.5}L-1.5 -1.5L1.5 ${-svgSize / 2 / 2 -0.3}Z`} fill="#FFFFFF" stroke="#FFFFFF" strokeWidth="0.5" transform="rotate(45)"/>
    </g>
  </g>
</svg>;

export const SeriousErrorDialog = () => {
  return (
    <div>
      <div>
        ページの読み込みに失敗しました。<br />
        リロードボタンを押してください。
      </div>
      <div
        style={{ marginTop: '1rem' }}
      >
        <a href={window.location.href} onClick={() => window.location.reload()}>
          <div style={{ overflow: 'hidden' }}>
            <div style={{ float: 'left' }} >{svg}</div>
            <div style={{ float: 'left', marginTop: '0.1rem' }} >&nbsp;リロードする</div>
          </div>
        </a>
      </div>
    </div>
  );
};