import './sort-boundary.scss';

type SortBoundaryProps = {
  children: globalThis.JSX.Element,
  showSide?: "top" | "right" | "bottom" | "left",
  color?: { border: string, background: string },
  offset?: { x?: string, y?: string },
  lineLength?: string,
  className?: string,
}

/** 
 * ソート表示用の境界線コンポーネント
 * @param children 境界線を付与する要素
 * @param showSide 境界線付与対象の要素の上下左右どこに表示するか。指定しない場合表示なし。
 * @param color 色情報（border: 実線、background: 背景）
 * @param offset 初期位置からのオフセット
 * @param lineLength 線分の長さ
 * @param className クラス名
 */
export const SortBoundary = (props: SortBoundaryProps) => {
  const {
    children,
    showSide,
    color = { border: "#4b73e7", background: "#e4e9fa" },
    offset = { x: "0", y: "0" },
    lineLength,
    className,
  } = props;
  const borderColorStyle: React.CSSProperties = {
    backgroundColor: color.background,
    color: color.border,
    transform: `translate(${offset.x ? offset.x : "0"}, ${offset.y ? offset.y : "0"})`,
    width: (showSide === "top" || showSide === "bottom") ? lineLength ? lineLength : 'calc(100% + 4px)' : undefined,
    height: (showSide === "right" || showSide === "left") ? lineLength ? lineLength : 'calc(100% + 4px)' : undefined,
  };
  return (
    <div className={`sort_boundary${(showSide === "left" || showSide === "right") ? ' vertical' : ' horizontal'}${className?` ${className}`:''}`} >
      {!!showSide && <hr className={`sort_boundary__line ${showSide}`} style={borderColorStyle} />}
      <div className="sort_boundary__child" >{children}</div>
    </div>
  );
}