import {ApiBaseBack} from "../api-base-back";

export class ApiOrders extends ApiBaseBack {
  constructor(param: {}) {
    super(
      'GET',
      '/api/v1/orders',
      'JSON',
      param,
    );
  }
}