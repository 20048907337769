import { ApiBaseFront } from '../api-base-front';

type ParamType = {
  kijshopCd: string,
  orderId: string,
  kind: 1 | 2,
  thumbOnly?: 0 | 1,
}


export type CloudFolderFileInfo = {
  id: string,
  folderId: string,
  orgFilename: string,
  path: string,
  pathThumb: string,
  relativePath: string,
  isDeleted: 0 | 1,
}

export type CloudFolderGetFileList = {
  imageNum: number,
  fileNum: number,
  totalSize: number,
  fileList: CloudFolderFileInfo[],
}

export class ApiGetCloudFolderOrderFile extends ApiBaseFront {
  constructor(_param: ParamType) {
    const { kijshopCd, orderId, ...param } = _param;
    super(
      'GET',
      `/api/v1/c-folder/shop/${kijshopCd}/order/${orderId}/folder`,
      'JSON',
      param,
    );
  }
}
