import {
  WizardButton,
  WizardButtonList,
  WizardContent,
  WizardInput,
  WizardInputList,
} from '../../../ui/wizard/wizard-content';
import { useParams } from 'react-router-dom';
import { PathParams, RoutingPath } from '../../../../routes/routing-path';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { useCallback, useState } from 'react';
import { apiActions } from '../../../../slices/api-slice';
import { ApiPostStaffMngRegisterMail } from '../../../../api/front/wizard/api-staff-management';
import { dialogActions } from '../../../dialog/slice/dialog-slice';
import { webPagePath } from '../../../../models/web-page-path';
import iconHelp from '../../../../assets/img/icon/help.svg';
import { useAppSelector } from '../../../../app/hooks';
import { useDidMount } from '../../../../hooks/life-cycle';
import { localStorageActions } from '../../../../slices/local-storage-slice';

type Props = {
  setStep: (step: number) => void;
  setMailAddress: (mailAddress: string) => void;
}

type TooltipType = 'email' | 'none';
export const Step2StaffMngEnableWizard = (props: Props) => {
  const { setStep, setMailAddress } = props;
  const shopName =  useAppSelector((state) => state.storage.data.shopName);
  // - Hooks -
  const { kijshopCd } = useParams<PathParams>();
  const dispatch = useDispatch();
  // - State -
  const [email, setEmail] = useState('');
  const [isEmailError, setIsEmailError] = useState(false);
  const [showTooltip, setShowTooltip] = useState<TooltipType>('none');
  // - Handlers -
  const handlerValidation = useCallback(() => {
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const isEmailValid = email !== '' && regex.test(email);
    setIsEmailError(!isEmailValid);
    return isEmailValid;
  },[email]);
  const handlerChangeEmail = useCallback((value: string) => {
    setEmail(value);
  }, [email]);
  const handlerToggleShowTooltip = useCallback((type: TooltipType) => {
    setShowTooltip(type);
  }, []);
  // -- キャンセルボタン --
  const handlerClickCancel = useCallback(() => {
    dispatch(push(RoutingPath.cnv.configSystem({ kijshopCd })));
  }, []);
  // -- メール送信ボタン --
  const handlerClickSendMail = useCallback(() => {
    if (!handlerValidation()) {
      dispatch(dialogActions.pushMessage({
        title: '確認',
        message: ['入力内容に不備があります。'],
        buttons: [
          {
            label: 'OK',
            callback: () => {
              dispatch(dialogActions.pop());
            },
          },
        ],
      }))
      return;
    }
    // - Handler -
      dispatch(apiActions.run(
        new ApiPostStaffMngRegisterMail({
          kijshopCd,
          mailaddress: email,
          shopName: shopName,
        }),
        {
          onSuccess: () => {
            setMailAddress(email);
            setStep(3);
          },
          onBasicError: (error) => {
            dispatch(dialogActions.pushMessage({
              title: '確認',
              message: error.messages,
              buttons: [
                {
                  label: 'OK',
                  callback: () => {
                    dispatch(dialogActions.pop());
                  },
                },
              ],
            }))
          }
        }
      ))
  }, [email, shopName]);

  useDidMount(() => {
    if (!shopName) {
      dispatch(localStorageActions.getLocalData(kijshopCd));
    }
  })
  return (
    <WizardContent>
      <WizardInputList>
        <WizardInput
          label="スタッフ管理メールアドレス"
          value={email}
          disabled={false}
          isPassword={false}
          maxLength={100}
          descTop={'スタッフアカウント管理者のメールアドレスを入力してください'}
          descBottom={
            <>
              <img
                src={iconHelp}
                alt=""
                style={{
                  width: '1rem',
                  height: '1rem',
                  verticalAlign: 'text-bottom',
                  marginRight: '0.2rem',
                }}
              />
              スタッフ管理メールアドレスについて
            </>
          }
          descBottomUrl={webPagePath.differenceBetweenOrderStatusAndMailAddress}
          error={isEmailError}
          tooltip={isEmailError && showTooltip === 'email' ?
            { messages: ['メールアドレスの形式が正しくありません。'] } : undefined}
          onChange={(e) => handlerChangeEmail(e.target.value)}
          onMouseOver={() => handlerToggleShowTooltip('email')}
          onMouseLeave={() => handlerToggleShowTooltip('none')}
        />
      </WizardInputList>
      <WizardButtonList>
        <WizardButton
          label="キャンセル"
          onClick={() => handlerClickCancel()}
        />
        <WizardButton
          label="メールを送信"
          onClick={() => handlerClickSendMail()}
        />
      </WizardButtonList>
    </WizardContent>
  );
};
