import { ILayoutEditorManagerBase } from '../layout-editor.manager';
import { WebFontLoader } from './web-font-loader';
import { UiManager } from '../ui/ui.manager';
import { Env } from '../../../models/env';

export const loader = new WebFontLoader({});

export class WebFontManager implements ILayoutEditorManagerBase {

  private uiManager!: UiManager;

  constructor() {
  }

  di(uiManager: UiManager): void {
    this.uiManager = uiManager;
  }

  initialize() {
    this.uiManager.on('l->r:web-font:download', (e) => {
      this.loadFont(e.fontFamilyName)
        .then(() => {
          e.callback();
        }).catch((res) => {
        console.log(res);
          e.error(res)
      })
    });
    this.uiManager.on('l->r:web-font:get-list', (e) => {
      this.getFontList()
        .then((v) => {
          e.callback({ fonts: v });
        });
    });
  }

  destroy(): void {
    this.uiManager.off('l->r:web-font:download');
    this.uiManager.off('l->r:web-font:get-list');
  }

  loadFont(fontFamilyName: string) {
    const fileName = 'fonts.css';
    const fontsCssUrl = `${Env.api.font.protocol}://${Env.api.font.host}/${fileName}`;
    return new Promise<void>((resolve, reject) => {
      loader.load({
        retry: 3,
        timeout: Env.config.webfontTimeoutMs,
        familyName: fontFamilyName,
        css: fontsCssUrl,
        onLoad: () => resolve(),
        onError: () => reject(`フォントの読み込みに失敗しました。 : ${fontFamilyName}`),
      });
    });
  }

  getFontList(): Promise<string[]> {
    const fileName = 'fonts.json';
    const fontsJsonUrl = `${Env.api.font.protocol}://${Env.api.font.host}/${fileName}`;
    return fetch(fontsJsonUrl, {mode: 'cors'})
      .then((res) => res.json())
      .then((json) => {
        return json.map((v: any) => v.familyName);
      });
  }

}
