import JSZip from 'jszip';
import { ILayoutEditorManagerBase } from '../layout-editor.manager';
import { UiManager } from '../ui/ui.manager';
import { isEqual } from 'lodash';

export class ZipManager implements ILayoutEditorManagerBase {
  private uiManager!: UiManager;
  private static zips: {id: string, zip: JSZip}[] = [];

  private getXmlFile: (v: { file: any, path: string, callback: (p: {file: any}) => void }) => void = async(v: { file: any, path: string, callback: (p: {file: any}) => void }, id?: string) => {
    const file = await this.unzipAndGetFile(v.file, v.path, 'blob', id);
    v.callback({file: file})
  }

  initialize(): void {
    this.uiManager.on('l->r:zip-file:get', this.getXmlFile);
  }

  destroy(): void {
    this.uiManager.off('l->r:zip-file:get', this.getXmlFile);
  }

  di(
    uiManager: UiManager,
  ): void {
    this.uiManager = uiManager;
  }

  unzip(file: Blob, id?: string) {
    const zip = new JSZip();
    return zip.loadAsync(file);
  }

  unzipAndGetFile(zipFile: Blob, targetFilePath: string, type: 'text' | 'blob', id?: string) {
    const find = ZipManager.zips.find((v) => v.id === id);
    if (find) return find.zip.file(targetFilePath)?.async(type);
    return this.unzip(zipFile)
    .then((v) => {
        if (id) ZipManager.zips.push({ id, zip: v });
        return v.file(targetFilePath)?.async(type);
      });
  }
}
