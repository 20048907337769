import { ApiBaseBack } from '../api-base-back';

export class ApiCheckPdf extends ApiBaseBack<{ checkResult: string }> {
  constructor(param: { fileName: File }) {
    super(
      'POST',
      '/api/v1/checkPdf',
      'JSON',
      param,
    );
  }
}
