import './loading-circle.scss';

type LoadingCircleProps = {
  color?: string,
  dimmerTransparent?: boolean,
}

export const LoadingCircle = (props: LoadingCircleProps) => {
  const { color = '#fff', dimmerTransparent = false } = props;

  return (
    <div className="loading_circle__dimmer" style={{ backgroundColor: dimmerTransparent ? '#00000030' : '#c8c8c8' }}>
      <div
        className="loading_circle"
        style={{
          backgroundImage: `radial-gradient(${dimmerTransparent ? '#00000030' : '#c8c8c8'} 50%, transparent 53%),
        conic-gradient(${color} 0% 80%, transparent 80.5% 100%)`
        }}
      />
    </div >
  )
};
