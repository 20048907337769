/** ラボ発注が正常に完了しているかチェック機能 */
export class LaboOrderCompChecker {
  /**
   * 一定時間が経過しているかチェック
   * @param lastUpdate 最終更新時間
   */
  static checkTimeOut(lastUpdate: string) {
    const elapsedTime = Math.floor((Date.now() - (Date.parse(lastUpdate))))
    /* ミリ秒 -> 分に変換 */
    const m = 1000 * 60;
    /* 不都合ありと判断する経過時間を何分にするか指定 */
    const limit = 30
    return elapsedTime > m * limit
  }
}
