import React, { DetailedHTMLProps, InputHTMLAttributes, useEffect, useRef, useState } from "react";
import { DynamicTooltip } from "../tooltip/dynamic-tooltip";
import "./checkbox.scss";

type CheckProps = {
  label?: string;
  tooltip?: { type: "error" | "normal", text: string[] },
  styleType?: "0" | "1",
  stopPropagation?: boolean
} & DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

export const Checkbox = (props: CheckProps) => {
  const { label, tooltip, styleType = "1", stopPropagation = false, ...defaultProps } = props;
  // - Ref -
  const ref = useRef<HTMLDivElement>(null);
  // - State -
  const [hover, setHover] = useState(false);
  const [checked, setChecked] = useState(false);
  const [styleClassName, setStyleClassName] = useState('');

  useEffect(() => {
    setStyleClassName(` style_type_${styleType}`);
  }, []);
  useEffect(() => {
    if (defaultProps.checked !== undefined) {
      setChecked(defaultProps.checked);
    }
  }, [defaultProps]);

  return (
    // <div className="checkbox" title="">
    //   <label className={checked ? "checked" : ""}>
    //     <input
    //       {...defaultProps}
    //       type="checkbox"
    //       onChange={() => {
    //         setChecked(!checked);
    //       }}
    //     />
    //     {label && <span>{label}</span>}
    //   </label>
    // </div>

    <div
      className={`checkbox ${defaultProps.className || ''}`}
      onMouseOver={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      ref={ref}
      onClick={(e) => { stopPropagation && e.stopPropagation(); }}
    >
      <label className="checkbox__inner">
        <input
          {...defaultProps}
          hidden
          onClick={(e) => {
            if (defaultProps.onClick) {
              defaultProps.onClick(e);
            }
          }}
          type="checkbox"
          checked={checked}
          onChange={() => {
            setChecked(!checked);
          }}
        />
        <div className={`checkbox__form${checked ? ' checked' : ''}${styleClassName}`} />
        {label ?
          <span className="checkbox__label">
            {label}
          </span>
          : (<></>)
        }
      </label>
      {tooltip && hover ? (
        <DynamicTooltip messages={tooltip.text} relativeEle={ref.current} error={tooltip.type === "error"} />
      ) : (<></>)}
    </div>

  );
};
