import {ApiBaseBack} from "../api-base-back";

export class ApiTemplate extends ApiBaseBack {
  constructor(filePath: string, type: string, param: {}) {
    super(
      'GET',
      `/files/template/${filePath}`,
      type === 'templates' || type === 'templateXml' ? 'XML' : 'BLOB',
      param,
    );
  }
}
