import { Button } from '../../../ui/button/button';
import { useDispatch } from 'react-redux';
import { dialogActions } from '../../../dialog/slice/dialog-slice';
import { push } from 'connected-react-router';
import { PathParams, RoutingPath } from '../../../../routes/routing-path';
import { ChangeOrderPageNum } from '../../../dialog/unique/change-order-page-num';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import './layout.menu.scss';
import { Input } from '../../../ui/input/input';
import { Checkbox } from '../../../ui/input/checkbox';
import {
  AlbumData,
  AlbumSettingData,
  layoutActions,
  LayoutPageType,
  PageRequestMemo,
} from '../../../../slices/layout-slice';
import { useAppSelector } from '../../../../app/hooks';
import * as lodash from 'lodash';
import { TextArea } from '../../../ui/textarea/textarea';
import { UiManager } from '../../../../layout-editor/manager/ui/ui.manager';
import { useParams } from 'react-router-dom';
import { AlbumPage } from '../../../../layout-editor/albam/albam';
import { LayoutCalendar } from '../../../ui/input/layout-calendar';
import { AlbumLayoutType, MenuType } from '../layout';
import { CheckFinishType } from '../../../../layout-editor/manager/validation/validation.manager';
import { xmlActions } from '../../../../xml/slice/xml-slice';
import { store } from '../../../../app/store';
import { XmlStructureModel } from '../../../../xml/model/xml-structure-model';
import { TemplateLoaderManager } from '../../../../layout-editor/manager/template-loader/template-loader.manager';
import { cloneDeep } from 'lodash';
import { Env } from '../../../../models/env';

type MenuTypeInMenu = {
  inOut: 'in' | 'out';
  ableArea: boolean;
}

type LayoutMenuProps = {
  initMenu: MenuType,
  callbackMenu: (v: MenuTypeInMenu) => void;
  isLiteLayout: boolean;
}

type AlbumConfFormProps = {
  albumConfData: AlbumSettingData,
  posY?: number,
  albums: {
    cover: AlbumData;
    top: AlbumData;
    page: AlbumData;
    endCover: AlbumData;
  },
}
type PageRequestFormProps = {
  requestData: PageRequestMemo[],
  posY?: number,
  currentPage: AlbumPage | null,
}

const AlbumConfForm = (props: AlbumConfFormProps) => {
  const dispatch = useDispatch();
  const { pageDataTypesRes } = useAppSelector((state) => ({
    pageDataTypesRes: state.layout.pageDataTypesRes,
  }), lodash.isEqual);
  const { albumConfData, posY, albums } = props;
  const [name, setName] = useState(albumConfData.name);
  const [date, setDate] = useState(albumConfData.date);
  const [formatType, setFormat] = useState(albumConfData.formatType);
  const [initialType, setIniType] = useState(albumConfData.initialType);
  const [location, setLocation] = useState(albumConfData.location);
  const [pageTypes, setPageTypes] = useState(albumConfData.pageTypes);
  // memo
  const descriptionLocations = useMemo(() => {
    if (pageTypes.length) {
      return pageTypes;
    } else {
      const list = pageDataTypesRes.pageDataTypes.length ? [pageDataTypesRes.pageDataTypes[0].pageDataTypeId] : [];
      setPageTypes(list);
      return list
    }
  }, [pageTypes, pageDataTypesRes]);
  // const [coverCheck, setCoverCheck] = useState(albumConfData.showPage.cover);
  // const [topCheck, setTopCheck] = useState(albumConfData.showPage.top);
  const handlerChangeName = useCallback((v) => {
    setName(v);
  }, []);
  const handlerChangeDate = useCallback((v) => {
    setDate(v);
  }, []);
  const handlerChangeLocation = useCallback((v) => {
    setLocation(v);
  }, []);
  const handlerClickPageType = useCallback((v) => {
    const arr = [...pageTypes];
    const index = arr.findIndex((p) => p === v);
    if (index === -1) {
      arr.push(v);
    } else {
      if (arr.length > 1) {
        arr.splice(index, 1);
      } else {
        dispatch(dialogActions.pushMessage({
          title: "確認",
          message: ["記載箇所は1箇所以上設定してください"],
          buttons: [
            { label: "OK", callback: () => dispatch(dialogActions.pop()) }
          ]
        }))
      }
    }
    setPageTypes(arr);
  }, [pageTypes]);
  useEffect(() => {
    dispatch(layoutActions.setAlbumSettingData(
      {
        productName: albumConfData.productName,
        name,
        date,
        formatType,
        initialType,
        location,
        pageTypes,
        pageCount: albumConfData.pageCount,
        stepPageCount: albumConfData.stepPageCount,
        pageCountInfo: albumConfData.pageCountInfo,
      },
    ));
  }, [name, date, location, pageTypes, albumConfData, formatType, initialType]);

  useEffect(() => {
    UiManager.ins.emit('r->l:stop-keyboard-event', true);
    return (() => {
      UiManager.ins.emit('r->l:stop-keyboard-event', false);
    });
  }, []);
  return (
    <div
      className="main_menu_form"
      style={{ top: posY }}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <div className="main_menu_form__inner column_direction_wrap gap_3">
        <div className="flex_box gap_3">
          <div className="column_direction_wrap gap_3 flex_grow_1">
            <div className="flex_box gap_3">
              <div className="left_label_form flex_grow_1">
                {/* 商品名 */}
                <span>商品名</span>
                <Input
                  value={albumConfData.productName}
                  disabled={true}
                />
              </div>
              <div className="left_label_form flex_grow_1">
                {/* 名前 */}
                <span>名前</span>
                <Input
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
            </div>
            <div className="flex_box gap_3">
              {/* <div className="left_label_form item_album_date">
                日付
                <span>日付</span>
                <Input
                  type="date"
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                />
              </div>
              <div className="left_label_form item_album_date_format">
                日付形式
                <span>日付形式</span>
                <div className="flex_box gap_3">
                  <Select
                    value={'February 19th, 2021'}
                    list={[{value: '', label: ''}]}
                    onChange={() => {}}
                  />
                  <Select
                    value={'先頭のみ大文字'}
                    list={[{value: '', label: ''}]}
                    onChange={() => {}}
                  />
                </div>
              </div> */}
              {/* <Calendar getFormatDate={(date) => setDate(date)} /> */}
              <LayoutCalendar
                getFormatDate={(date, formatT, iniT) => {
                  setDate(date);
                  setFormat(formatT);
                  setIniType(iniT);
                }}
                initValue={{ value: date, formatType: formatType, initialType: initialType }}
                callbackDate={(v) => setDate(v)}
              />
            </div>
          </div>
          <div>
            <div className="left_label_form item_album_location">
              {/* 場所 */}
              <span>撮影場所</span>
              <TextArea
                value={location}
                onChange={(e) => setLocation(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="flex_box gap_3">
          <div className="left_label_form item_album_page">
            {/* ページ */}
            <span>ページ</span>
            <Input
              value={albumConfData.pageCount}
              disabled={true}
            />
          </div>
          <div className="left_label_form item_album_position">
            {/* 記載箇所 */}
            <span>記載箇所</span>
            <div className="input">
              {name || date || location ? (
                <>
                  {pageDataTypesRes.pageDataTypes.map((v, i) => (
                    <Checkbox
                      key={`layout-description-page-data-type_${i}_`}
                      label={v.pageDataTypeDisplayName}
                      checked={Boolean(descriptionLocations.find((p) => p === v.pageDataTypeId))}
                      onClick={() => handlerClickPageType(v.pageDataTypeId)}
                    />
                  ))}
                </>
              ) : (<></>)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const PageRequestForm = (props: PageRequestFormProps) => {
  const dispatch = useDispatch();
  const { requestData, posY, currentPage } = props;
  const { currentAlbum } = useAppSelector((v) => ({
    currentAlbum: v.layout.currentAlbumPage,
  }));
  // const [pageId, setPageId] = useState('');
  const [memo, setMemo] = useState(currentPage?.memo || '');
  const handlerChangeMemo = useCallback((v) => {
    setMemo(v);
    if (currentAlbum) currentAlbum.memo = v;
    dispatch(layoutActions.setCurrentAlbumPage(currentAlbum));
  }, []);
  useEffect(() => {
    UiManager.ins.emit('r->l:stop-keyboard-event', true);
    return (() => {
      UiManager.ins.emit('r->l:stop-keyboard-event', false);
    });
  }, []);
  return (
    <div
      className="main_menu_form"
      onClick={(e) => {
        e.stopPropagation();
      }}
      style={{ top: posY }}
    >
      <div className="main_menu_form__inner column_direction_wrap gap_3">
        <div className="left_label_form flex_grow_1">
          {/* メモ */}
          <span>メモ</span>
          <Input
            value={memo}
            onChange={(e) => handlerChangeMemo(e.target.value)}
          />
        </div>
      </div>
    </div>
  );
};

export const LayoutMenu = (props: LayoutMenuProps) => {
  const dispatch = useDispatch();
  const { kijshopCd, shopOrderId, orderId } = useParams<PathParams>();
  const {
    page,
    album,
    albumSetting,
    pageRequest,
    orderInfo,
    selectPreviewItem,
    allAlbum,
    deleteAlbum,
    templatesXmlArr,
    pageTypeList,
    xml
    // currentAlbum,
  } = useAppSelector((state) => ({
    page: state.layout.page,
    album: state.layout.currentAlbumPage,
    albumSetting: state.layout.albumSettingData,
    pageRequest: state.layout.pageRequestMemo,
    orderInfo: state.orderPreparation.currentOrder,
    selectPreviewItem: state.layout.previewSelectItem,
    allAlbum: state.layout.albumPages,
    deleteAlbum: state.layout.deleteAlbumInfos,
    templatesXmlArr: state.layout.templatesXmlList,
    pageTypeList: state.layout.pageTypeList,
    xml: state.xml[shopOrderId]
    // currentAlbum: state.layout.currentAlbumPage,
  }), lodash.isEqual);
  const { callbackMenu, initMenu, isLiteLayout } = props;
  const isGuide = album?.parseData.templateData?.additionalFrame ? album?.parseData.templateData.additionalFrame[0].$.enable : '';

  // - State -
  // -- レイアウト画面の表示タイプ --
  // const [layoutType, setLayoutType] = useState<'select' | 'list' | 'edit'>('edit');
  // -- 有効保障エリア --
  const [isShowArea, setIsShowArea] = useState(initMenu.ableArea);
  // -- 内接or外接ステート --
  const [layoutMode, setLayoutMode] = useState<'in' | 'ext'>(initMenu.inOut === 'in' ? initMenu.inOut : 'ext');
  // -- メインメニューの種類 --
  const [menuType, setMenuType] = useState<'album' | 'request' | 'delete' | 'change' | ''>('');
  // -- UIポップの表示位置 --
  const [popPosY, setPopPosY] = useState(0);
  // -- ひとつ前の画面 --
  const [prevPage, setPrevPage] = useState<LayoutPageType>(page);
  const [isAllDisabled, setIsAllDisabled] = useState(false);

  // - Ref -
  const ref = useRef<HTMLDivElement>(null);
  // - Callback -
  // -- アルバム設定 --
  const handlerClickAlbumConf = useCallback(() => {
    setMenuType('album');
  }, [album]);
  // -- ページ要望 --
  const handlerClickPageRequest = useCallback(() => {
    setMenuType('request');
  }, []);
  // -- ページ削除 --
  const handlerClickPageDelete = useCallback(() => {
    if (page === 'list' && !selectPreviewItem) {
      // dispatch(dialogActions.pushMessage({
      //   title: '確認',
      //   message: [
      //     '削除するテンプレートを選択してください。',
      //   ],
      //   buttons: [
      //     {
      //       label: 'OK',
      //       callback: () => {
      //         dispatch(dialogActions.pop());
      //       }
      //     }
      //   ]
      // }));
      return;
    }
    if (!album && !selectPreviewItem) return;
    let data = selectPreviewItem;
    if (album && page === 'edit') {
      // dispatch(layoutActions.removeAlbumPage({
      //   type: album.type,
      //   id: album.id,
      // }));
      if (album.id !== selectPreviewItem?.album.id) {
        let index = 0;
        if (album.type.indexOf('page') !== -1) {
          index = allAlbum.page.albums.indexOf(album);
        }
        data = {
          pageNum: `${2 + (index * 2)}-${3 + (index * 2)}`,
          album,
        };
      }
    }
    if (data) {
      // const displayName = pageTypeData.find((v) => v.name === data?.album.type);
      const targetPage = data.album.type.indexOf('page') !== -1
        ? `${data?.album.displayName} ${data.pageNum}ページ`
        : data?.album.displayName;
      dispatch(dialogActions.pushMessage({
        title: '確認',
        message: [
          `「${targetPage}」を削除します。`,
        ],
        buttons: [
          {
            label: 'キャンセル',
            callback: () => dispatch(dialogActions.pop()),
          },
          {
            label: 'OK',
            callback: () => {
              dispatch(dialogActions.pop());
              if (data) {
                dispatch(layoutActions.removeAlbumPage({
                  type: data.album.type,
                  id: data.album.id,
                  typeId: data.album.typeID,
                }));
                dispatch(layoutActions.setDeleteAlbumData([...deleteAlbum, {
                  templatePath: data?.album?.imageEditManager?.path?.ad || '',
                  thumbnailID: '',
                }]));
                dispatch(layoutActions.setTemplateList([]));
                UiManager.ins.emit('r->l:delete-album', { albumID: data.album?.id ?? '' });
              }
            },
          },
        ],
      }));
      dispatch(layoutActions.setPreviewSelectItem(null));
    }
    // setMenuType('delete');
  }, [album, selectPreviewItem, allAlbum, page]);

  const createImageBlob = (path: string) => {
    return new Promise<string>((resolve) => {
      fetch(
        path,
        { method: 'GET', mode: 'cors' },
      ).then((res) => {
        res.blob().then((v) => {
          const reader = new FileReader();
          reader.readAsDataURL(v);
          reader.onload = () => {
            if (typeof (reader.result) === 'string') {
              resolve(reader.result);
            } else {
              resolve('');
            }
          };
        });
      });
    });
  };

  /* 復元用テンプレートXML取得処理 */
  const onGetTemplateXml = useCallback(async (temp: any, album: AlbumPage) => {
    const urlBase = `${Env.api.back.protocol}://${Env.api.back.host}/files/template`;
    let templatesXmlData: { xml: any, pageType: string } = { xml: '', pageType: '' };
    let templateXmlData: any;
    let thumbnailData: string;
    let zipLevel: { isZip: boolean, level: number };
    const _name = temp.$.path.substr(0, temp.$.path.lastIndexOf('___')).split('/');
    const name = _name[_name.length - 1];
    const data = await TemplateLoaderManager.ins.onGetTargetFile(temp.$.path, 'templateXml', name);
    const id = temp.$.path.split('/').filter((v: any) => v).filter((_: any, i: number) => i <= 3).join('/');
    thumbnailData = `${urlBase}/${temp.$.path}/thumbnail.jpg`;
    zipLevel = { isZip: false, level: 0 };
    const memo = album.memo;

    if (data?.thumbnail) {
      thumbnailData = URL.createObjectURL(data.thumbnail as Blob);
    } else {
      data.thumbnail = await createImageBlob(thumbnailData);
    }
    if (data?.zipLevel) {
      zipLevel = data.zipLevel;
    }
    if (data?.template) {
      templateXmlData = data.template;
    }
    const result = {
      itemData: {
        id,
        templateName: data.templateName,
        thumbnail: data.thumbnail,
        templateXmlUrl: data.templateXmlUrl,
        targetPath: data.targetPath,
      },
      templates: templatesXmlData,
      template: templateXmlData,
      thumbnail: thumbnailData,
      zipLevel,
      isChange: data.isChange,
      indexes: album.indexes,
      thumbPath: data.thumbPath,
      kijshopCd,
      orderId,
      shopOrderId,
      memo,
      templateBlob: data.blob,
    };
    return result;
  }, [albumSetting, orderInfo, selectPreviewItem, allAlbum, album, page]);

  // -- ページ数変更 --
  const handlerClickChangePageNum = useCallback(async (isLayoutFinish?: boolean, data?: CheckFinishType | undefined, settingData?: AlbumSettingData) => {
    const _albumSetting = settingData ?? albumSetting;
    const prevPageCount = _albumSetting.pageCount;
    /* option側でのページ数制限を考慮する */
    const targetOpInfoData = (xml?.orderInfo?.infoData ?? []).filter(v => v.xml.metaModel.parentId === orderId && v.xml.viewModel.optionEnableCondition);
    const opMin = () => {
      const target = targetOpInfoData.sort((a, b) =>
        Number(b.xml.viewModel.optionEnableCondition?.parentMinPageCount) - Number(a.xml.viewModel.optionEnableCondition?.parentMinPageCount)
      )[0]?.xml.viewModel.optionEnableCondition;
      return target ? Number(target.parentMinPageCount) : null;
    }
    const opMax = () => {
      const target = targetOpInfoData.sort((a, b) =>
        Number(a.xml.viewModel.optionEnableCondition?.parentMaxPageCount) - Number(b.xml.viewModel.optionEnableCondition?.parentMaxPageCount)
      )[0]?.xml.viewModel.optionEnableCondition;
      return target ? Number(target.parentMaxPageCount) : null;
    }
    const pageMinCount = () => {
      const _opMin = opMin()
      return _opMin && _opMin > Number(_albumSetting.pageCountInfo.min)
        ? String(_opMin)
        : _albumSetting.pageCountInfo.min;
    }
    const pageMaxCount = () => {
      const _opMax = opMax()
      return _opMax && _opMax < Number(_albumSetting.pageCountInfo.max)
        ? String(_opMax)
        : _albumSetting.pageCountInfo.max;
    }

    setMenuType('change');
    let coverTemps;
    if (allAlbum.cover.albums.length) {
      const path = allAlbum.cover.albums[0].dirName;
      const groupPath = path.substr(0, path.lastIndexOf('/'));
      const group = templatesXmlArr.find(v => v.$.path === groupPath);
      coverTemps = group.templateGroup[0].templateData.filter((v: any) => v.$.path.substr(0, v.$.path.lastIndexOf('___')) === path);
    }

    if (_albumSetting.pageCountInfo.min === _albumSetting.pageCountInfo.max) {
      dispatch(dialogActions.pushMessage({
        title: 'チェックエラー',
        message: [
          '本商材はページ変更不可です。',
        ],
        buttons: [
          {
            label: 'OK',
            callback: () => {
              dispatch(dialogActions.pop());
            },
          },
        ],
      }));
    } else {
      dispatch(dialogActions.push({
        title: '注文ページ数変更',
        element:
          <ChangeOrderPageNum
            kijshopCd={kijshopCd}
            shopOrderId={shopOrderId}
            pageCount={isLayoutFinish ? (settingData?.pageCount ?? _albumSetting.pageCount) : _albumSetting.pageCount}
            onGetAfterNum={(num, isChange: boolean) => {
              dispatch(layoutActions.setAlbumSettingData(
                {
                  productName: _albumSetting.productName,
                  name: _albumSetting.name,
                  date: _albumSetting.date,
                  formatType: _albumSetting.formatType,
                  initialType: _albumSetting.initialType,
                  location: _albumSetting.location,
                  pageTypes: _albumSetting.pageTypes,
                  pageCount: num,
                  stepPageCount: _albumSetting.stepPageCount,
                  pageCountInfo: _albumSetting.pageCountInfo,
                },
              ));
              dispatch(dialogActions.pushMessage({
                title: '確認',
                message: isChange ?
                  [`ページ数は ${num} に変更されました。`,
                    `表紙の背幅が変更されましたので、再度表紙のレイアウトを行ってください。`]
                  : [
                    `ページ数は ${num} に変更されました。`,
                  ],
                buttons: [
                  {
                    label: 'はい',
                    callback: () => {
                      dispatch(dialogActions.pop());
                      if (isLayoutFinish && data) {
                        UiManager.ins.emit('l->r:wait-loading', { message: '情報の更新中です...' });
                      }
                      const onChanged = (xml: XmlStructureModel) => {
                        if (isLayoutFinish && data) {
                          UiManager.ins.emit('r->l:close:change-page-num', {
                            data: {
                              ...data,
                              xml,
                            },
                          });
                          UiManager.ins.emit('l->r:wait-loading', { message: '' });
                        }
                      };
                      if (Number(num) > Number(prevPageCount)) {
                        const addPageNum = (Number(num) - Number(prevPageCount)) / (data ? data.pageCount : allAlbum.page.pageCount);
                        // const addPageNum = (Number(num) - Number(prevPageCount)) / Number(_albumSetting.stepPageCount);
                        dispatch(xmlActions.layout({
                          kijshopCd,
                          shopOrderId,
                          orderId,
                        }).page().add(
                          addPageNum,
                          num,
                          store.getState().layout.albumPages.page,
                          store.getState().layout.albumPages.endCover,
                          onChanged,
                        ));
                      } else if (Number(num) < Number(prevPageCount)) {
                        const removePageNum = ((Number(prevPageCount)) - Number(num)) / (data ? data.pageCount : allAlbum.page.pageCount);
                        dispatch(xmlActions.layout({
                          kijshopCd,
                          shopOrderId,
                          orderId,
                        }).page().remove(
                          removePageNum,
                          num,
                          store.getState().layout.albumPages.page,
                          store.getState().layout.albumPages.endCover,
                          onChanged,
                        ));
                      } else {
                        throw new Error('ページ数の変更に失敗しました。');
                      }
                    },
                  },
                ],
              }));
            }}
            minPageCount={pageMinCount()}
            maxPageCount={pageMaxCount()}
            stepPageCount={_albumSetting.pageCountInfo.step}
            disabled={isLayoutFinish}
            totalPage={isLayoutFinish ? data?.totalPage : undefined}
            coverTemps={coverTemps}
            onDelete={(temp) => {
              let data = selectPreviewItem;
              if (allAlbum.cover.albums.length) {
                const target = allAlbum.cover.albums[0];
                if (target.id !== selectPreviewItem?.album.id) {
                  data = {
                    pageNum: `***`,
                    album: target,
                  };
                }
              }
              if (data) {
                UiManager.ins.emit('r->l:change-cover:start');
                dispatch(layoutActions.removeAlbumPage({
                  type: data.album.type,
                  id: data.album.id,
                  typeId: data.album.typeID,
                }));
                dispatch(layoutActions.setDeleteAlbumData([...deleteAlbum, {
                  templatePath: data?.album?.imageEditManager?.path?.ad || '',
                  thumbnailID: '',
                }]));
                dispatch(layoutActions.setTemplateList([]));
                UiManager.ins.emit('r->l:delete-album', { albumID: data.album?.id ?? '' });
                const _name = temp.$.path.substr(0, temp.$.path.lastIndexOf('___')).split('/');
                const name = _name[_name.length - 1];
                onGetTemplateXml(temp, data.album).then((v) => {
                  const prevAlbum = cloneDeep(data!.album);
                  const album = new AlbumPage(
                    v.template,
                    prevAlbum.id,
                    prevAlbum.typeID,
                    prevAlbum.type,
                    prevAlbum.displayName,
                    prevAlbum.pageNo,
                    { templatePath: v.thumbnail, image: v.itemData.thumbnail },
                    prevAlbum.isZip,
                    prevAlbum.imageEditManager,
                    prevAlbum.indexes,
                    prevAlbum.isOption,
                    prevAlbum.memo,
                    prevAlbum.additionalParseData,
                    v.templateBlob,
                  );
                  dispatch(layoutActions.addAlbumPage({
                    type: album.type,
                    album,
                  }));
                  UiManager.ins.emit('r->l:add-album', {
                    album: album, callback: () => {
                      dispatch(layoutActions.setCurrentAlbumPage(album));
                      dispatch(layoutActions.setPage('edit'));
                      UiManager.ins.emit('r->l:select-album', { album });
                    },
                  });
                });
              }
            }
            }
          />,
      }));
    }
  }, [albumSetting, orderInfo, selectPreviewItem, allAlbum, album, page, xml, orderId]);
  // -- 有効保障エリアON --
  const handlerClickValidSecurityAreaOn = useCallback(() => {
    setIsShowArea(true);
    callbackMenu({
      inOut: layoutMode === 'in' ? layoutMode : 'out',
      ableArea: true,
    });
    UiManager.ins.emit('r->l:image-guide-on/off', { mode: 'on' });
  }, [layoutMode, callbackMenu]);
  // -- 有効保障エリアOFF --
  const handlerClickValidSecurityAreaOff = useCallback(() => {
    setIsShowArea(false);
    callbackMenu({
      inOut: layoutMode === 'in' ? layoutMode : 'out',
      ableArea: false,
    });
    UiManager.ins.emit('r->l:image-guide-on/off', { mode: 'off' });
  }, [layoutMode]);
  // -- 内接 --
  const handlerClickInscribed = useCallback(() => {
    setLayoutMode('in');
    callbackMenu({
      inOut: 'in',
      ableArea: isShowArea,
    });
    UiManager.ins.emit('r->l:image-resize-in/out', { mode: 'in' });
  }, [isShowArea]);
  // -- 外接 --
  const handlerClickCircumscription = useCallback(() => {
    setLayoutMode('ext');
    callbackMenu({
      inOut: 'out',
      ableArea: isShowArea,
    });
    UiManager.ins.emit('r->l:image-resize-in/out', { mode: 'out' });
  }, [isShowArea]);
  // -- テンプレート選択 --
  const handlerClickSelectTemplate = useCallback(() => {
    // setLayoutType('select');
    // dispatch(layoutActions.setTemplateList([]));
    dispatch(layoutActions.setTemplatesInfoList([]));
    dispatch(layoutActions.setPage('select'));
  }, []);
  // -- レイアウト一覧 --
  const handlerClickLayoutList = useCallback(() => {
    // setLayoutType('list');
    setPrevPage(page);
    dispatch(layoutActions.setPage('list'));
  }, [page]);
  // -- 戻る --
  const handlerClickBack = useCallback(() => {
    // setLayoutType('select');
    const albums = [
      ...allAlbum.cover.albums,
      ...allAlbum.top.albums,
      ...allAlbum.page.albums,
      ...allAlbum.endCover.albums,
      ...allAlbum.option.map((v) => v.album),
    ];
    if (prevPage === 'edit' && !albums.find((v) => v?.id === album?.id)) {
      dispatch(layoutActions.setPage('select'));
    } else {
      dispatch(layoutActions.setPage(prevPage));
    }
  }, [prevPage, allAlbum]);
  // -- デザイン中止 --
  const handlerClickDesignCancel = useCallback(() => {
    dispatch(
      dialogActions.pushMessage({
        title: 'デザイン中止',
        message: [
          '編集を中断して注文概要画面に戻ります。 ',
          '全ての内容が保存されませんが、よろしいですか？',
        ],
        buttons: [
          {
            label: 'いいえ',
            callback: () => {
              dispatch(dialogActions.pop());
            },
          },
          {
            label: 'はい',
            callback: () => {
              dispatch(dialogActions.pop());
              dispatch(push(RoutingPath.cnv.ordersPreparation({ kijshopCd, shopOrderId })));
            },
          },
        ],
      }),
    );
  }, []);
  // - Effect -
  useEffect(() => {
    if (initMenu.ableArea) {
      setIsShowArea(initMenu.ableArea);
    }
  }, [initMenu.ableArea]);
  useEffect(() => {
    setPopPosY(ref.current?.getBoundingClientRect().bottom || 0);
  }, [ref]);
  useEffect(() => {
    UiManager.ins.on('l->r:open:change-page-num', async (v) => {
      await handlerClickChangePageNum(true, v.data, v.albumSetting);
      v.callback();
    });
    UiManager.ins.on('r->l:shortcut:change-page-num', () => {
      handlerClickChangePageNum();
    });
    UiManager.ins.on('r->l:shortcut:design-cancel', () => {
      handlerClickDesignCancel();
    });
    UiManager.ins.on('l->r:layout-menu-disabled', (v) => {
      setIsAllDisabled(v.isDisabled);
    });
    return () => {
      UiManager.ins.off('l->r:open:change-page-num');
      UiManager.ins.off('r->l:shortcut:change-page-num');
      UiManager.ins.off('r->l:shortcut:design-cancel');
      UiManager.ins.off('l->r:layout-menu-disabled');
    };
  }, []);

  return (
    <div
      className="layout_menu"
      ref={ref}
      onClick={() => UiManager.ins.emit('r->l:dis-select')}
    >
      <div className="main_menu">
        <Button
          label="アルバム設定"
          onClick={handlerClickAlbumConf}
          color="dark"
          disabled={page !== 'edit' || !isLiteLayout || isAllDisabled}
          size="sm"
        />
        <Button
          label="ページ要望"
          onClick={handlerClickPageRequest}
          color="dark"
          disabled={page !== 'edit' || !isLiteLayout || isAllDisabled}
          size="sm"
        />
        <Button
          label="ページ削除"
          onClick={handlerClickPageDelete}
          color="dark"
          disabled={page === 'select' || isAllDisabled}
          size="sm"
        />
        <Button
          label="ページ数変更"
          onClick={() => handlerClickChangePageNum()}
          color="dark"
          disabled={isAllDisabled}
          size="sm"
        />
        {/* アルバム設定 */}
        {menuType === 'album' ?
          <div
            className="main_menu_form__dimmer"
            onClick={() => setMenuType('')}
          >
            <AlbumConfForm
              albumConfData={albumSetting}
              posY={popPosY}
              albums={allAlbum}
            />
          </div>
          : <></>}
        {/* ページ要望 */}
        {menuType === 'request' ?
          <div
            className="main_menu_form__dimmer"
            onClick={() => setMenuType('')}
          >
            <PageRequestForm
              requestData={pageRequest}
              posY={popPosY}
              currentPage={album}
            />
          </div>
          : <></>}

      </div>
      <div>
        {
          page === 'edit' && isGuide !== '1' ?
            <div className="guarantee_area_switch">
              <span>有効保障エリア</span>
              <div>
                <Button
                  label="ON"
                  onClick={handlerClickValidSecurityAreaOn}
                  color={isShowArea ? 'primary' : 'dark'}
                  size="sm"
                  disabled={isAllDisabled}
                />
                <Button
                  label="OFF"
                  onClick={handlerClickValidSecurityAreaOff}
                  color={!isShowArea ? 'primary' : 'dark'}
                  size="sm"
                  disabled={isAllDisabled}
                />
              </div>
            </div> : <></>
        }
        <div className="layout_switch">
          <span>レイアウト</span>
          <div>
            <Button
              label="外接"
              onClick={handlerClickCircumscription}
              color={layoutMode === 'in' ? 'dark' : 'primary'}
              size="sm"
              tooltip={{ type: 'normal', text: ['クリックすると次のレイアウト時から', '外接でレイアウトされるように変更します。'] }}
              disabled={isAllDisabled}
            />
            <Button
              label="内接"
              onClick={handlerClickInscribed}
              color={layoutMode === 'in' ? 'primary' : 'dark'}
              size="sm"
              tooltip={{ type: 'normal', text: ['クリックすると次のレイアウト時から', '内接でレイアウトされるように変更します。'] }}
              disabled={isAllDisabled}
            />
          </div>
        </div>
      </div>

      <div>
        {page === 'edit' ?
          <Button
            label="テンプレート選択"
            onClick={handlerClickSelectTemplate}
            size="sm"
            disabled={isAllDisabled}
          /> : <></>}
        {page === 'edit' || page === 'select' ?
          <>
            <Button
              label="レイアウト一覧"
              onClick={handlerClickLayoutList}
              size="sm"
              disabled={isAllDisabled}
            />
            <Button
              label="デザイン中止"
              onClick={handlerClickDesignCancel}
              color="danger"
              size="sm"
              disabled={isAllDisabled}
            />
          </> : <></>}
        {page === 'list' ?
          <Button
            label="戻る"
            onClick={handlerClickBack}
            color="danger"
            className="btn_back"
            size="sm"
            disabled={isAllDisabled}
          /> : <></>}
      </div>

    </div>
  );
};
