import {
  WizardButton,
  WizardButtonList,
  WizardContent,
  WizardInput,
  WizardInputList,
} from '../../../ui/wizard/wizard-content';
import { useParams } from 'react-router-dom';
import { PathParams, RoutingPath } from '../../../../routes/routing-path';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { useCallback, useEffect, useState } from 'react';
import { apiActions } from '../../../../slices/api-slice';
import { ApiCheckTokenExpiry } from '../../../../api/front/wizard/api-check-token-expiry';
import { dialogActions } from '../../../dialog/slice/dialog-slice';
import { ApiPostStaffMngRegisterPassword } from '../../../../api/front/wizard/api-staff-management';

type Props = {
  setStep: (step: number) => void;
  token: string;
}
type TooltipType = 'password' | 'newPassword' | 'newPasswordConfirm' | 'none';
export const Step4StaffMngEnableWizard = (props: Props) => {
  const { setStep, token } = props;
  // - Hooks -
  const { kijshopCd } = useParams<PathParams>();
  const dispatch = useDispatch();
  // - Effects -
  useEffect(() => {
    if (token === '') {
      dispatch(push(RoutingPath.cnv.configSystem({ kijshopCd })));
    }
    dispatch(apiActions.run(
      new ApiCheckTokenExpiry({ token }),
      {
        onSuccess: (res) => {
          if(res.error.errorCode === 404){
            dispatch(dialogActions.pushMessage({
              title: 'エラー',
              message: ['トークンの有効期限が切れています。','システム設定画面に戻ります'],
              buttons: [
                {
                  label: 'OK',
                  callback: () => {
                    dispatch(dialogActions.pop());
                    dispatch(push(RoutingPath.cnv.configSystem({ kijshopCd })));
                  },
                },
              ],
            }));
          }
        },
      }),
    );
  }, []);
  // - State -
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordConfirm, setNewPasswordConfirm] = useState('');
  const [isPasswordError, setIsPasswordError] = useState(false);
  const [isNewPasswordError, setIsNewPasswordError] = useState(false);
  const [isNewPasswordConfirmError, setIsNewPasswordConfirmError] = useState(false);
  const [showTooltip, setShowTooltip] = useState<TooltipType>('none');
  // - Handlers -
  // -- Validation --
  const handlerValidation = useCallback(() => {
    // 大文字・小文字・数字 のうち２種類で7文字
    const regex1 = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[A-Za-z0-9]{7}$/;
    const regex2 = /^(?=.*[a-z])(?=.*[A-Z])[A-Za-z]{7}$/;
    const regex3 = /^(?=.*[a-z])(?=.*[0-9])[a-z0-9]{7}$/;
    const regex4 = /^(?=.*[A-Z])(?=.*[0-9])[A-Z0-9]{7}$/;
    const isPasswordValid = password !== '';
    const isNewPasswordValid = newPassword.length === 7 && (regex1.test(newPassword) || regex2.test(newPassword) || regex3.test(newPassword) || regex4.test(newPassword));
    const isNewPasswordConfirmValid = newPasswordConfirm.length === 7 && newPassword === newPasswordConfirm;
    setIsPasswordError(!isPasswordValid);
    setIsNewPasswordError(!isNewPasswordValid);
    setIsNewPasswordConfirmError(!isNewPasswordConfirmValid);
    return isPasswordValid && isNewPasswordValid && isNewPasswordConfirmValid;
  }, [password, newPassword, newPasswordConfirm]);
  const handlerChangePassword = useCallback((value: string) => {
    setPassword(value);
  }, [password]);
  const handlerChangeNewPassword = useCallback((value: string) => {
    setNewPassword(value);
  }, [newPassword]);
  const handlerChangeNewPasswordConfirm = useCallback((value: string) => {
    setNewPasswordConfirm(value);
  }, [newPasswordConfirm]);
  const handlerClickCancel = useCallback(() => {
    dispatch(push(RoutingPath.cnv.configSystem({ kijshopCd })));
  }, []);
  const handlerClickRegister = useCallback(() => {
    if (!handlerValidation()) {
      dispatch(dialogActions.pushMessage({
        title: '確認',
        message: ['入力内容に不備があります。'],
        buttons: [
          {
            label: 'OK',
            callback: () => {
              dispatch(dialogActions.pop());
            },
          },
        ],
      }));
      return;
    }
    dispatch(apiActions.run(
      new ApiPostStaffMngRegisterPassword(
        {
          token,
          old_password: password,
          password: newPassword,
          password_confirmation: newPasswordConfirm,
        },
      ),
      {
        onSuccess: () => {
          setStep(5);
        },
        onBasicError: (error) => {
          dispatch(dialogActions.pushMessage({
            title: '確認',
            message: error.messages,
            buttons: [
              {
                label: 'OK',
                callback: () => {
                  dispatch(dialogActions.pop());
                },
              },
            ],
          }));
        },
      },
    ));
  }, [password, newPassword, newPasswordConfirm]);
  const handlerToggleShowTooltip = useCallback((type: TooltipType) => {
    setShowTooltip(type);
  }, []);
  return (
    <WizardContent>
      <WizardInputList>
        <WizardInput
          label="店舗コード"
          value={kijshopCd}
          disabled={true}
          isPassword={false}
          maxLength={7}
        />
        <WizardInput
          label="これまでの店鋪用パスワードを入力してください"
          value={password}
          disabled={false}
          isPassword={true}
          maxLength={15}
          error={isPasswordError}
          tooltip={isPasswordError && showTooltip === 'password' ?
            { messages: ['パスワードが正しくありません。'] } : undefined}
          onChange={(e) => handlerChangePassword(e.target.value)}
          onMouseOver={() => handlerToggleShowTooltip('password')}
          onMouseLeave={() => handlerToggleShowTooltip('none')}
        />
        <WizardInput
          label="新しいパスワード（７文字）"
          value={newPassword}
          disabled={false}
          isPassword={true}
          maxLength={7}
          error={isNewPasswordError}
          tooltip={isNewPasswordError && showTooltip === 'newPassword' ?
            { messages: ['大文字、小文字、数字のうち2種類以上を含む7文字'] } : undefined}
          descBottom={'※大文字・小文字・数字から2種類以上で7文字'}
          onChange={(e) => handlerChangeNewPassword(e.target.value)}
          onMouseOver={() => handlerToggleShowTooltip('newPassword')}
          onMouseLeave={() => handlerToggleShowTooltip('none')}
        />
        <WizardInput
          label="新しいパスワード（再入力）"
          value={newPasswordConfirm}
          disabled={false}
          isPassword={true}
          maxLength={7}
          error={isNewPasswordConfirmError}
          tooltip={isNewPasswordConfirmError && showTooltip === 'newPasswordConfirm' ?
            { messages: ['パスワードが一致しません。'] } : undefined}
          onChange={(e) => handlerChangeNewPasswordConfirm(e.target.value)}
          onMouseOver={() => handlerToggleShowTooltip('newPasswordConfirm')}
          onMouseLeave={() => handlerToggleShowTooltip('none')}
        />
      </WizardInputList>
      <WizardButtonList>
        <WizardButton
          label="キャンセル"
          onClick={handlerClickCancel}
        />
        <WizardButton
          label="登録"
          onClick={handlerClickRegister}
        />
      </WizardButtonList>
    </WizardContent>
  );
};