import { RefObject, useEffect } from 'react';

export const useIntersectionObserver = (
  ref: RefObject<Element>,
  callback: (e: IntersectionObserverEntry) => void,
  option: IntersectionObserverInit,
) => {
  useEffect(() => {
    if (!ref.current) {
      return;
    }
    const io = new IntersectionObserver(
      (e) => {
        callback(e[0]);
      },
      option,
    );
    io.observe(ref.current);
    return () => {
      io.disconnect();
    };
  }, [ref, callback, option]);
};
