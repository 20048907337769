import { useCallback, useEffect, useRef } from 'react';
import './urgent-notice.scss';
import { useDispatch } from 'react-redux';
import { commonActions } from '../../../slices/common-slice';
import { useAppSelector } from '../../../app/hooks';
import * as lodash from 'lodash';
import { NewPage } from '../../../utilities/new-page';

type UrgentNoticeProps = {
  // message: string[],
  // open?: boolean,
  page: 'news' | 'order' | 'staff' | '',
  closeCallback?: () => void,
}

export const UrgentNotice = (props: UrgentNoticeProps) => {
  // const { message, open, closeCallback } = props;
  // const { message, closeCallback } = props;
  const { page } = props;
  const { emergencyAlert } = useAppSelector((state) => ({
    emergencyAlert: state.common.data.masterEmergencyAlert,
  }), lodash.isEqual);
  const dispatch = useDispatch();
  const openClassName = 'open';
  const closeClassName = 'close';
  // - Ref -
  const ref = useRef<HTMLDivElement>(null);
  // - Callback -
  const handlerClickClose = useCallback(() => {
    ref.current?.classList.remove(openClassName);
    ref.current?.classList.add(closeClassName);
    if (emergencyAlert) {
      dispatch(commonActions.setData({
        masterEmergencyAlert: { xml: emergencyAlert.xml, lock: true },
      }));
    }
    // closeCallback && closeCallback();
    // dispatch(commonActions.setEmergencyAlert({
    //   message: message,
    //   isLooked: '1',
    // }));
  }, [emergencyAlert]);
  const handlerClickAlert = useCallback(() => {
    const url = emergencyAlert?.xml?.lnwMasterEmergencyAlert.data?.[0]?.alert?.[0]?.$?.url;
    if (url) {
      NewPage.tab(url);
    }
  }, [emergencyAlert]);
  // - Effect -
  // useEffect(() => {
  //   console.log(open);
  //   if (open) {
  //     ref.current?.classList.add(openClassName);
  //   }
  //   return () => {
  //     ref.current?.classList.remove(closeClassName);
  //   }
  // }, [open]);
  useEffect(() => {
    if (emergencyAlert && page && (!emergencyAlert.lock || page === 'news')) {
      ref.current?.classList.add(openClassName);
    } else {
      ref.current?.classList.remove(closeClassName);
      ref.current?.classList.remove(openClassName);
    }
  }, [emergencyAlert, page]);

  return emergencyAlert?.xml?.lnwMasterEmergencyAlert.data?.[0].alert?.[0]._ ? (
    <div className="urgent_notice" ref={ref}>
      <div className="urgent_notice__inner">
        <div
          className="urgent_notice__title"
          onClick={handlerClickAlert}
          style={{ cursor: 'pointer' }}
        >
          {emergencyAlert?.xml?.lnwMasterEmergencyAlert.data[0].alert[0]._}
        </div>
        {/*<div className="urgent_notice__message">*/}
          {/*{message.map((v, i) => {*/}
          {/*  return (*/}
          {/*    <div key={`urgent_notice_message_row-${i}`}>{v}</div>*/}
          {/*  )*/}
          {/*})}*/}
        {/*</div>*/}
      </div>
      {/* {page === 'order' ? (<button className="urgent_notice__close" onClick={handlerClickClose} />) : (<></>)} */}
    </div>
  ) : (<></>);
}