import { XmlClass } from '../xml-class';
import { IOrderInfo } from '../../interface/order/i-order-info';
import { XmlClassName } from '../../model/xml-class-name';
import { TOrderInfoMetaModel } from '../../model/order/xml-order-info-model';
import { OrderInfoDataXml } from './order-info-data-xml';
import { OrderPageDataXml } from './order-page-data-xml';

type TMeta = Partial<TOrderInfoMetaModel>;

export class OrderInfoXml extends XmlClass<IOrderInfo, {}, TMeta> {

  name: XmlClassName = 'order-info';

  viewModel: {} = {};
  metaModel: TMeta = {};

  // order-info に紐づく order-info-data
  orderInfoDataArr: OrderInfoDataXml[] = [];
  orderInfoData(orderId: string | number): OrderInfoDataXml | null {
    const data = this.metaModel.path?.find((v) => Number(v.id) === Number(orderId));
    if (!data) {
      return null;
    }
    const orderInfoData = this.orderInfoDataArr.find((v) => v.xmlUniqueName === data.path);
    return orderInfoData || null;
  };

  // 注文がもつ全ての order-page-data（XML構造化のタイミングで外部から設置）
  allOrderPageData: OrderPageDataXml[] = [];

  protected init() {
    this.xmlUniqueName = this.xmlUniqueName || `PPM_${this.shopOrderId}_11_11_orderInfo.xml`;
    this.xmlParentName = this.xmlParentName || `PPM_${this.shopOrderId}_11_11.xml`;
    this.rootTagModel = {
      ...this.rootTagModel,
      parentXPath: '/lnwOrder/order/orderInfo',
      parentPath: this.xmlParentName,
    };
    this.docking({}, {});
  }

  protected docking(v: {}, m: TMeta) {
    // ここで xmlModel 作成
    this.xmlModel = {
      lnwOrderInfo: {
        $: { ...this.rootTagModel },
        orderInfo: [
          {
            $: {
              count: `${m.path?.length}`,
            },
            data: m.path?.map((data) => ({
              $: {
                id: data?.id,
                path: data?.path,
              },
            })),
          },
        ],
        parentSequence: [
          {
            $: {
              count: `${m.parentSequence?.length}`,
            },
            parentSequenceData: m.parentSequence?.map((data) => ({
              $: {
                id: data.id,
              },
            })),
          },
        ],
      },
    };
  }

  protected split(x: IOrderInfo) {
    if (!x?.lnwOrderInfo) {
      this.metaModel = {};
      return;
    }
    this.metaModel = {
      path: (x.lnwOrderInfo.orderInfo?.[0].data) ? x.lnwOrderInfo.orderInfo[0].data.map((v) => ({
        id: v.$.id!,
        path: v.$.path!,
      })) : undefined,
      parentSequence: (x.lnwOrderInfo.parentSequence?.[0].parentSequenceData) ?
        x.lnwOrderInfo.parentSequence?.[0].parentSequenceData?.map((v) => ({
          id: v.$.id!,
        })) : undefined,
    };
    this.rootTagModel = Object.assign(this.rootTagModel, x.lnwOrderInfo.$);
  }
}
