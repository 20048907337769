import { Tooltip, TooltipProps } from './tooltip';
import './tooltip.scss';

type StaticTooltipProps = {
  pos: { x: string, y: string }
} & TooltipProps;

/**
 * 座標を直接入力して位置調整させるツールチップ
 * 
 * @param {string[]} messages ツールチップ内メッセージ。
 * @param {{ x: number, y: number }} pos 座標
 * @param {boolean} error エラーモードにするか
 * @param {"top" | "bottom"} balloonDirection 吹き出しのツノの向き(上下)。エラーモードにしていなければ不要。
 */

export const StaticTooltip = (props: StaticTooltipProps) => {
  const { messages, error, pos, balloonDirection, ...defaultProps } = props;
  return (
    <Tooltip
      {...defaultProps}
      messages={messages}
      error={error}
      balloonDirection={balloonDirection}
      style={{
        top: pos.y,
        left: pos.x,
        position: 'fixed',
        ...defaultProps.style,
      }}
    />
  )
}