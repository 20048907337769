import {OrderMethodsRequest, OrderMethodsResponse} from '../../../../models/api/back/create-order/order-methods';
import {AppThunk} from "../../../../app/store";
import {ordersDataActions} from "../../../../slices/orders/orders-data-slice";
import {apiActions} from "../../../../slices/api-slice";
import {ResponseBase} from "../../../response-base";
import {ApiBaseBack} from "../../api-base-back";
import { ordersActions } from '../../../../slices/orders-slice';

export class ApiOrderMethods extends ApiBaseBack<OrderMethodsResponse> {
  constructor(param: OrderMethodsRequest) {
    super(
      'GET',
      '/api/v1/orderMethods',
      'JSON',
      param,
    );
  }
}

export const apiOrderMethodsThunk = (param: OrderMethodsRequest, callback: () => void, isInit: boolean, agent: boolean): AppThunk => async (dispatch, getState) => {
  if (!param.productNameId) {
    dispatch(ordersDataActions.updateItemDetail({
      orderMethods: { orderMethod: [] },
    }));
    callback?.();
    return;
  }
  dispatch(apiActions.run(
    new ApiOrderMethods(param),
    {
      onSuccess: (res: ResponseBase<OrderMethodsResponse>) => {
        callback?.();
        if (!isInit && param.productNameId !== getState().order.itemDetail.itemSelect.value) {
          return;
        }
        dispatch(ordersDataActions.updateItemDetail({
          orderMethods: { orderMethod: res.body.data?.orderMethod || [] },
        }));
        const orderMethod = getState().order.itemDetail.orderMethod;
        if (orderMethod.value && res.body.data?.orderMethod?.find((v) => v.orderMethodId === orderMethod.value)) {
          dispatch(ordersActions.itemDetail.setOrderMethod(orderMethod.value, orderMethod.label, agent));
        }
      },
      onError: () => {
        dispatch(ordersDataActions.updateItemDetail({
          orderMethods: { orderMethod: [] },
        }));
        callback?.();
      },
    },
    {
      apiName: 'order-methods',
      ignoreSystemError: true,
    },
  ))
};
