import { UiManager } from '../ui/ui.manager';
import * as lodash from 'lodash';
import { CommandBase, CommandManager } from '../command/command.manager';
import { FrameController } from './frame/frame-controller';
import { EditableImageManager } from '../editable-image/editable-image.manager';
import { PanZoomManager } from '../pan-zoom/pan-zoom.manager';
import { ImageController, initPictureFlags } from './image/image-controller';
import { ImageEditableTool } from './image/image-editable-tool';
import { TextController } from './text/text-controller';
import { TextEvent } from './text/text-event';
import { EditableSideTool } from './editable-side-tool';
import { ImageEvent } from './image/image-event';
import { LayoutDataManager } from '../data/layout-data.manager';
import { ImageEditManager, PathType } from './image-edit.manager';
import { CjContainer, CjCorner } from '../../model/cj-factory';
import { CJ_CONTAINER_NAME } from '../../model/cj-factory/cj-obj-name.collection';
import { CjTool } from '../../../utilities/cj-tool';
import { RulerManager } from '../ruler/ruler.manager';
import { FitChecker } from '../../../utilities/fit-checker';
import { AlbumPage, AlbumManager } from '../../albam/albam';
import { TrimmingManager } from '../trimming/trimming.manager';
import { store } from '../../../app/store';
import { PosManager } from '../pos/pos.manager';

export class EditableEvent {
  private stage: CjContainer;
  private path: PathType;
  private url: string;
  private pageId: string;


  private editableImageManager!: EditableImageManager;
  private commandManager!: CommandManager;
  private panZoomManager!: PanZoomManager;
  private frameController!: FrameController;
  private imageController!: ImageController;
  private imageEditableTool!: ImageEditableTool;
  private textController!: TextController;
  private textEvent!: TextEvent;
  private editableSideTool!: EditableSideTool;
  private imageEvent!: ImageEvent;
  private layoutDataManager!: LayoutDataManager;
  private rulerManager!: RulerManager;
  private trimmingManager!: TrimmingManager;
  private posManager!: PosManager;
  private albumManager!: AlbumManager;
  private imageEditManager!: ImageEditManager;

  listenerList: UiManager[] = [];
  private listenerId: any;
  private targetFrame: CjContainer | null = null;
  private firstTargetFrame: CjContainer | null = null;

  constructor(stage: CjContainer, path: PathType, url: string, pageId: string) {
    this.stage = stage;
    this.path = path;
    this.url = url;
    this.pageId = pageId;
  }

  di(
    frameController: FrameController,
    imageController: ImageController,
    commandMng: CommandManager,
    editableMng: EditableImageManager,
    panZoomMng: PanZoomManager,
    imageEditableTool: ImageEditableTool,
    textController: TextController,
    textEvent: TextEvent,
    editableSideTool: EditableSideTool,
    imageEvent: ImageEvent,
    layoutStoreMng: LayoutDataManager,
    rulerMng: RulerManager,
    trimmingMng: TrimmingManager,
    posMng: PosManager,
    albumManager: AlbumManager,
    imageEditMng: ImageEditManager,
  ): void {
    this.frameController = frameController;
    this.imageController = imageController;
    this.commandManager = commandMng;
    this.editableImageManager = editableMng;
    this.panZoomManager = panZoomMng;
    this.imageEditableTool = imageEditableTool;
    this.textController = textController;
    this.textEvent = textEvent;
    this.editableSideTool = editableSideTool;
    this.imageEvent = imageEvent;
    this.layoutDataManager = layoutStoreMng;
    this.rulerManager = rulerMng;
    this.trimmingManager = trimmingMng;
    this.posManager = posMng;
    this.albumManager = albumManager;
    this.imageEditManager = imageEditMng;
  }

  initialize(): void {
  }

  destroy(): void {
    this.listenerList = [];
    this.targetFrame = null;
  }

  update(stage: CjContainer, path: PathType, url: string): void {
    this.stage = stage;
    this.path = path;
    this.url = url;
  }

  // - 操作イベント設定 -
  async setEvent() {
    return new Promise<void>((resolve) => {
      this.listenerList = [
        UiManager.ins.on('r->l:dis-select', () => {
          if (this.textEvent.targetFrame) {
            this.disFocused(this.textEvent.targetFrame);
          } else if (this.imageEvent.targetFrame) {
            this.disFocused(this.imageEvent.targetFrame);
          }
        }),
        // 内接外接の切り替え
        UiManager.ins.on('r->l:image-resize-in/out', (v) => {
          this.imageController.imageSetMode = v.mode;
        }),
        // -- 有効保障エリア切り替え --
        UiManager.ins.on('r->l:image-guide-on/off', (v) => {
          if (v.mode === 'on') {
            CjTool.getGuidContainer(this.stage).visible = true
            this.albumManager.currentAlbum && (this.albumManager.currentAlbum.isGuide = true)
            this.sortContainer()
          } else {
            CjTool.getGuidContainer(this.stage).visible = false
            this.albumManager.currentAlbum && (this.albumManager.currentAlbum.isGuide = false)
          }
          this.layoutDataManager.dispatch('changeGuideMode', v.mode);
        }),
        // -- shift移動 --
        UiManager.ins.on('r->l:shift-move', (v) => {
          const currentFrame = this.imageEvent.targetFrame;
          const focusMode = this.layoutDataManager.selector('getFocusMode');
          if (!currentFrame || focusMode === 'scale' || focusMode === 'none') return;
          if (store.getState().dialog.dialogs.length) return;
          // if (v.type) {
          //   if (focusMode !== 'rotate') return;
          // }
          let target: CjContainer;
          switch (focusMode) {
            case 'rotate':
            case 'dai':
              target = CjTool.getPhotoContainer(currentFrame);
              break;
            case 'move':
            case 'scale':
              target = CjTool.getDefaultContainer(currentFrame);
              break;
            case 'png':
              target = currentFrame;
              break;
            default:
              target = currentFrame;
              break;
          }
          const movePos = focusMode === 'move'
            ? CjTool.getImageAreaContainer(target).movePos
            : target.movePos;

          const resetDialog = async (fnc?: () => void) => {
            await new Promise<void>((resolve) => {
              target.y += v.y;
              target.x += v.x;
              movePos.x += v.x;
              movePos.y += v.y;
              resolve();
            });

            UiManager.ins.emit('l->r:push-dialog', {
                title: '確認',
                message: [
                  // '画像を写真フレームの外にレイアウトすることはできません。',
                  '配置された位置が適切ではありません。',
                  '元の位置に戻します。',
                ],
                buttons: [
                  {
                    label: 'OK',
                    callback: () => {
                      target.y! -= (v.y + 1);
                      target.x! -= (v.x + 1);
                      movePos.x -= (v.x + 1);
                      movePos.y -= (v.y + 1);
                      fnc?.();
                    },
                  },
                ],
              },
            );
          };
          switch (currentFrame.name) {
            case CJ_CONTAINER_NAME.editor:
              const guid = CjTool.getGuidContainer(this.stage);
              if (!guid) break;
              if (!CjTool.hitTest(currentFrame, guid)) {
                resetDialog();
                return;
              }
              break;
            case CJ_CONTAINER_NAME.image:
              if (focusMode === 'png') break;
              const frameContainer = CjTool.getFrameContainer(target);
              if (!frameContainer) break;
              if (!CjTool.hitTest(currentFrame, frameContainer, {
                adjustOrigin: { x: CjCorner.maxIconSize / 2, y: CjCorner.maxIconSize / 2 },
              })) {
                resetDialog();
                return;
              }
              break;
            default:
              break;
          }
          const isRotate = (focusMode === 'rotate') ? CjTool.getRotateContainer(target).isRotate : false;
          const prevBase = (focusMode === 'rotate' || focusMode === 'dai')
            ? CjTool.getDefaultContainer(target)
            : target;

          this.action(
            'shift-move',
            `${v.x} ${v.y}移動`,
            () => {
              if (focusMode === 'rotate' || focusMode === 'dai') {
                this.imageEditableTool.imageMode(prevBase);
              }
              if (isRotate) {
                target.x += v.y;
                target.y -= v.x;
                movePos.x += v.y;
                movePos.y -= v.x;
              } else {
                target.x += v.x;
                target.y += v.y;
                movePos.x += v.x;
                movePos.y += v.y;
              }
              if (focusMode === 'rotate' || focusMode === 'png') {
                const photoFrames: [] = this.layoutDataManager.selector('getEditors', target.pageId);
                const isToast = FitChecker.checkImageFit(target, photoFrames, this.stage);
                this.imageEditManager.isFit = isToast;
                UiManager.ins.emit('l->r:show-toast', { isToast, target });
              }
            },
            () => {
              if (focusMode === 'rotate' || focusMode === 'dai') {
                this.imageEditableTool.imageMode(prevBase);
              }
              if (isRotate) {
                target.x -= v.y;
                target.y += v.x;
                movePos.x -= v.y;
                movePos.y += v.x;
              } else {
                target.x -= v.x;
                target.y -= v.y;
                movePos.x -= v.x;
                movePos.y -= v.y;
              }
              if (focusMode === 'rotate' || focusMode === 'png') {
                const photoFrames: [] = this.layoutDataManager.selector('getEditors', target.pageId);
                const isToast = FitChecker.checkImageFit(target, photoFrames, this.stage);
                console.log('%cFauna', 'color:green','', target, photoFrames, this.stage, isToast);
                this.imageEditManager.isFit = isToast;
                UiManager.ins.emit('l->r:show-toast', { isToast, target });
              }
            },
          );
          if (focusMode === 'rotate' || focusMode === 'dai') {
            const photoFrames: [] = this.layoutDataManager.selector('getEditors', target.pageId);
            const isToast = FitChecker.checkImageFit(target, photoFrames, this.stage);
            this.imageEditManager.isFit = isToast;
            UiManager.ins.emit('l->r:show-toast', { isToast, target });
          }
        }),
        UiManager.ins.on('r->l:resize', (v) => {
          const currentFrame = this.imageEvent.targetFrame;
          if (!currentFrame) return;
          const focusMode = this.layoutDataManager.selector('getFocusMode');
          if (focusMode !== 'rotate' && focusMode !== 'dai') return;
          const target = focusMode === 'rotate' || focusMode === 'dai'
            ? CjTool.getPhotoContainer(currentFrame)
            : CjTool.getDefaultContainer(currentFrame);
          this.action(
            'scale',
            `${v.scale} 拡大/縮小`,
            () => {
              target.scaleX += v.scale;
              target.scaleY += v.scale;
              const focusMode = this.layoutDataManager.selector('getFocusMode');
              if (focusMode !== 'rotate' && focusMode !== 'dai') {
                ImageController.updateImageView(target);
              }
            },
            () => {
              target.scaleX! -= v.scale;
              target.scaleY! -= v.scale;
              const focusMode = this.layoutDataManager.selector('getFocusMode');
              if (focusMode !== 'rotate' && focusMode !== 'dai') {
                ImageController.updateImageView(target);
              }
            },
          );
          if (focusMode === 'rotate' || focusMode === 'dai') {
            const photoFrames: [] = this.layoutDataManager.selector('getEditors', target.pageId);
            const isToast = FitChecker.checkImageFit(target, photoFrames, this.stage);
            this.imageEditManager.isFit = isToast;
            UiManager.ins.emit('l->r:show-toast', { isToast, target });
          }
        }),
        // -- 回転 --
        UiManager.ins.on('r->l:rotate', (v) => {
          if (!this.imageEvent.targetFrame) return;
          const focusMode = this.layoutDataManager.selector('getFocusMode');
          if (v.type) {
            if (focusMode !== 'rotate' && focusMode !== 'dai') return;
          }
          const target = this.imageEvent.targetFrame;
          const prevBase = (focusMode === 'rotate' || focusMode === 'dai')
            ? CjTool.getDefaultContainer(target)
            : target;
          this.action(
            'rotate',
            `${v.angle}度回転`,
            () => {
              if (focusMode === 'rotate' || focusMode === 'dai') {
                this.imageEditableTool.imageMode(prevBase);
              }
              this.imageEditableTool.rotate(v.angle, prevBase);
            },
            () => {
              if (focusMode === 'rotate' || focusMode === 'dai') {
                this.imageEditableTool.imageMode(prevBase);
              }
              this.imageEditableTool.rotate(-v.angle, prevBase);
            },
          );
        }),
        // -- 削除 --
        UiManager.ins.on('r->l:delete', () => {
          if (!this.imageEvent.targetFrame) return;
          const target = this.imageEvent.targetFrame;
          const focusMode = this.layoutDataManager.selector('getFocusMode');
          UiManager.ins.emit('l->r:push-dialog', {
            title: '確認',
            message: focusMode === 'png' || focusMode === 'rotate' || focusMode === 'dai' ? [
              `配置している写真を削除します。`,
              `実行する場合「はい」、キャンセルする場合「いいえ」を選択してください。`,
            ] : [
              `この写真枠を削除します。`,
              `実行する場合「はい」、キャンセルする場合「いいえ」を選択してください。`,
            ],
            buttons: [
              {
                label: 'いいえ',
                callback: () => {
                },
              },
              {
                label: 'はい',
                callback: () => {
                  if (focusMode === 'png') {
                    this.stage.removeChild(target);
                    this.editableImageManager.toUnUse(target.editableImageId ?? '');
                    UiManager.ins.emit('l->r:dis-select');
                    this.action(
                      'delete',
                      `${target.name} を削除`,
                      () => {
                        this.imageEditableTool.remove(target, focusMode);
                      },
                      () => {
                        this.imageEditableTool.repairer(target, focusMode, target);
                      },
                    );
                    return;
                  } else {
                    const container = CjTool.getDefaultContainer(target);
                    if (!this.stage) return;
                    if (focusMode === 'rotate' || focusMode === 'dai') {
                      const mask = CjTool.getMaskContainer(container);
                      mask.alpha = 1;
                      this.imageEvent.activeFocus = null;
                      this.imageEvent.targetFrame = null;
                      UiManager.ins.emit('l->r:dis-select');
                      this.layoutDataManager.dispatch('setFocusMode', 'scale');
                    }
                    const containers: CjContainer[] = this.layoutDataManager.selector('getImageAreas', this.pageId);
                    let count = 0;
                    for (const con of containers) {
                      const isPhoto = CjTool.checkPhotoInFrame(con);
                      if (isPhoto) {
                        count++;
                      }
                    }
                    count--;
                    const removeTarget = focusMode === 'rotate' || focusMode === 'png' || focusMode === 'dai' ? target : container;
                    const parent = focusMode === 'rotate' || focusMode === 'dai' ? CjTool.getRotateContainer(target) : target;
                    const album: AlbumPage = this.layoutDataManager.selector('getCurrentPage');
                    this.action(
                      'delete',
                      `${removeTarget.name} を削除`,
                      () => {
                        if (focusMode === 'rotate' || focusMode === 'dai') {
                          const image = CjTool.getImageContainer(target);
                          const index = this.albumManager.currentAlbum?.editableIDList.findIndex((v) => v === image.editableImageId);
                          if (index !== undefined && index > -1) this.albumManager.currentAlbum?.editableIDList.splice(index, 1);
                          const usedIdList = this.albumManager.getUsedEditableIDList();
                          try {
                            // if (image.editableImageId && !usedIdList.includes(image.editableImageId)) {
                            this.editableImageManager.toUnUse(image.editableImageId ?? '');
                            // }
                          } catch {
                          }
                        }
                        this.imageEditableTool.remove(removeTarget, focusMode, this.albumManager);
                        UiManager.ins.emit('l->r:count-photo', { album, photoCount: count });
                      },
                      () => {
                        this.imageEditableTool.repairer(removeTarget, focusMode, parent, this.albumManager);
                        UiManager.ins.emit('l->r:count-photo', { album, photoCount: count + 1 });
                      },
                    );
                  }
                },
              },
            ],
          });

        }),
        // -- 複製 --
        UiManager.ins.on('r->l:clone', () => {
          UiManager.ins.emit('l->r:push-dialog', {
            title: '確認',
            message: [
              `写真枠をコピーします。新しい写真枠は右下に重ねて作成されます。`,
              `実行する場合「はい」、キャンセルする場合「いいえ」を選択してください。`,
            ],
            buttons: [
              {
                label: 'いいえ',
                callback: () => {
                },
              },
              {
                label: 'はい',
                callback: () => {
                  const target = this.imageEditableTool.clone();
                  if (!target) return;
                  const containers: CjContainer[] = this.layoutDataManager.selector('getImageAreas', this.pageId);
                  let count = 0;
                  for (const con of containers) {
                    const isPhoto = CjTool.checkPhotoInFrame(con);
                    if (isPhoto) {
                      count++;
                    }
                  }
                  const album: AlbumPage = this.layoutDataManager.selector('getCurrentPage');
                  this.action(
                    'clone',
                    `${target?.name} を複製`,
                    () => {
                      this.imageEditableTool.repairer(target, 'scale', undefined, undefined,  { isCopy: true });
                      UiManager.ins.emit('l->r:count-photo', { album, photoCount: count + 1 });
                    },
                    () => {
                      this.imageEditableTool.remove(target, 'scale', undefined, { isUndo: true });
                      UiManager.ins.emit('l->r:count-photo', { album, photoCount: count });
                    },
                    // ));
                  );
                },
              },
            ],
          });
        }),
        UiManager.ins.on('r->l:move-mode', () => {
          this.imageEvent.frameMoveMode = true;
          this.imageEditableTool.move();
        }),

        // -- テキスト挿入開始 --
        UiManager.ins.on('r->l:insert-text:start', (v) => {
          UiManager.ins.emit('r->l:change-operation-cursor', { mode: 'text' });
          UiManager.ins.emit('l->r:layout-menu-disabled', { isDisabled: true });
          this.textEvent.isActive = true;
          this.textEvent.isInsert = true;
          this.listenerId = this.stage.addEventListener('click', (e: any) => {
            const pos = this.posManager.canvasToBrowser({
              // container の外側の座標だから container の座標を引いとく
              x: this.stage.stage.mouseX - ImageEditManager.STAGE_CONTAINER_X,
              y: this.stage.stage.mouseY - ImageEditManager.STAGE_CONTAINER_Y,
            });
            UiManager.ins.emit('l->r: post-text-pos', { pos });
            this.textEvent.isActive = false;
            this.textController.insertText(e, v.callback);
            window.setTimeout(() => this.textEvent.isInsert = false);
          }, true);
        }),
        // -- テキスト更新 --
        UiManager.ins.on('r->l:reload-text', (v) => {
          const textContainer = this.layoutDataManager.selector('getText', v.id);
          if (!textContainer) return;
          const text = CjTool.getText(textContainer as CjContainer);
          // this.textController.backupText = lodash.cloneDeep(text);
          this.textController.editText(v.id, v.text, v.color, v.alpha, v.rotation, v.textAlign, v.lineHeight, v.font, v.options, this.textController.backupText!, false, v.callback);
        }),
        // -- テキスト挿入終了 --
        UiManager.ins.on('r->l:insert-text:end', () => {
          this.textEvent.isInsert = false;
          this.textEvent.isActive = false;
          this.stage.removeEventListener('click', this.listenerId, true);
          UiManager.ins.emit('r->l:change-operation-mode', { mode: 'select' });
          UiManager.ins.emit('l->r:layout-menu-disabled', { isDisabled: false });
        }),
        // -- テキスト削除 --
        UiManager.ins.on('r->l:delete-text', (v) => {
          const textContainer = this.layoutDataManager.selector('getText', v.id);
          if (!textContainer) return;
          this.stage.removeChild(textContainer);
          this.action(
            'textDelete',
            `テキストを削除。`,
            () => {
              this.stage.removeChild(textContainer);
              this.textController.textBox = this.textController.textBox.filter(v => v.id !== textContainer.textId);
              this.layoutDataManager.dispatch('removeTextContainer', textContainer);
            },
            () => {
              this.stage.addChild(textContainer);
              this.textController.textBox.push(textContainer);
              this.layoutDataManager.dispatch('setTextContainer', textContainer);
            },
          );
        }),
        // -- テキスト編集完了 --
        UiManager.ins.on('r->l:finish-text-edit', (v) => {
          this.textController.finishTextEdit(v.id);
        }),
        // -- 中心線表示 --
        UiManager.ins.on('r->l:center-line', (v) => {
          if (v.display) {
            this.editableSideTool.addCenterLine();
          } else {
            this.editableSideTool.removeCenterLine();
          }
        }),
        // -- ルーラーの表示 --
        UiManager.ins.on(('r->l:ruler'), (v) => {
          if (v.display) {
            this.rulerManager.initialize();
          } else {
            this.rulerManager.destroy();
          }
        }),
        // -- 画像の追加 --
        UiManager.ins.on('r->l:add-editable-image', (v) => {
          this.editableSideTool.addImage(v.editableImage, v.file);
          this.editableImageManager.toUse(v.editableImage.id);
        }),
        // -- 操作モード切り替え（選択・手のひら） --
        UiManager.ins.on('r->l:change-operation-cursor', (v) => {
          if (v.mode === 'pan-zoom') {
            this.editableSideTool.lock();
          } else {
            this.editableSideTool.unlock();
          }
        }),
        // -- 初期化 --
        UiManager.ins.on('r->l:reset', () => {
          if (!this.imageEvent.targetFrame) return;
          const focusMode = this.layoutDataManager.selector('getFocusMode');
          const target = this.imageEvent.targetFrame;

          const photo = focusMode === 'png'
            ? target
            : CjTool.getPhotoContainer(target);
          const _photo = lodash.cloneDeep(photo);
          const _data = this.imageEvent.imageInitData.filter((v) => v.target.editableImageId === photo.editableImageId && v.target.photoAreaId === photo.photoAreaId);
          const data = _data[_data.length - 1];
          if (!data || !target) {
            return;
          }
          this.action(
            'reset',
            `初期化`,
            () => {
              photo.set({
                ...data.param,
              });
              this.imageEvent.updateToolPos('rotate', target);
              const photoFrames: [] = this.layoutDataManager.selector('getEditors', photo.pageId);
              const isFit = FitChecker.checkImageFit(photo, photoFrames, this.stage);
              this.imageEditManager.isFit = isFit;
              UiManager.ins.emit('l->r:show-toast', { isToast: isFit, target });
            },
            () => {
              photo.set({
                ..._photo,
              });
              this.imageEvent.updateToolPos('rotate', target);
              const photoFrames: [] = this.layoutDataManager.selector('getEditors', photo.pageId);
              const isFit = FitChecker.checkImageFit(photo, photoFrames, this.stage);
              this.imageEditManager.isFit = isFit;
              UiManager.ins.emit('l->r:show-toast', { isToast: isFit, target });
            },
          );
        }),
        // -- 写真/写真枠切り替え --
        UiManager.ins.on('r->l:change-image/frame', (v) => {
          const target = this.imageEvent.targetFrame;
          if (!target) return;
          if (v.mode === 'image') {
            this.imageEditableTool.imageMode(target);
          } else {
            this.imageEditableTool.frameMode(target);
          }
        }),
        // -- dragの検知 枠のハイライト --
        UiManager.ins.on('r->l:editable-image:drag:check', (v) => {
          if (this.imageEvent.activeFocus) return;
          // --- 繰り返し範囲を編集中のページに絞る ---
          const containers: CjContainer[] = this.layoutDataManager.selector('getImageAreas', this.pageId);
          for (const container of containers) {
            const defaultContainer = CjTool.getDefaultContainer(container);
            // --- ハイライトの抽出 ---
            const highlight = CjTool.getHighlight(container);
            // --- hitTestに必要な処理 ---
            const point = container.globalToLocal(v.x, v.y);
            if (container.hitTest(point.x, point.y)) {
              // ---- 枠にハイライト ----
              if ((this.targetFrame === container && container.isChecked) || !highlight) return;
              highlight.visible = true;
              // ---- 写真をドロップできる ----
              container.isChecked = true;
              defaultContainer.mouseEnabled = false;
              this.targetFrame = container;
            } else {
              // ---- 枠外でハイライトの削除 ----
              if (!highlight) return;
              highlight.visible = false;
              // ---- 写真をドロップできない ----
              container.isChecked = false;
              defaultContainer.mouseEnabled = true;
              this.targetFrame = null;
            }
            // NOTE: かなり重くなるかもしれないので要注意
            // --- 複数ハイライト時一番上に絞る ---
            const _targets = containers.filter((elm) => elm.isChecked);
            const targets = _targets.filter(v => !isNaN(Number(v.depth)));
            const max = Math.max(...targets.map(v => v.depth));
            targets.forEach(v => {
              if (v.depth !== max) {
                CjTool.getHighlight(v).visible = false;
              }
            });
          }
        }),

        UiManager.ins.on('r->l:editable-image:drop', () => {
          if (this.imageEvent.activeFocus) {
            this.imageEditManager.activateAllFocus();
            return;
          }
          const containers: CjContainer[] = this.layoutDataManager.selector('getImageAreas', this.pageId);
          let target: CjContainer | undefined;
          const _targets = containers.filter((elm) => elm.isChecked);
          if (_targets.length > 1) {
            const targets = _targets.filter(v => !isNaN(Number(v.depth)));
            const max = Math.max(...targets.map(v => v.depth));
            target = targets.find(v => v.depth === max);
            targets.forEach(v => {
              if (v.depth !== max) {
                CjTool.getHighlight(v).visible = false;
              }
            });
          } else {
            target = _targets[0];
          }
          // ここにロゴの場合アラート
          if (!target) {
            this.imageEditManager.activateAllFocus();
            return;
          }
          if (target.logoFlag) {
            const highlight = CjTool.getHighlight(target);
            UiManager.ins.emit('l->r:push-dialog', {
              title: '確認',
              message: [
                `対象の写真エリアはロゴタイプのため、画像一覧からの配置はできません。`,
                `ファイルを直接、ドラッグ＆ドロップで配置して下さい。`,
              ],
              buttons: [{
                label: 'OK', callback: () => {
                  this.imageEditManager.activateAllFocus();
                  highlight.visible = false;
                },
              }],
            });
            return;
          }
          target.isChecked = false;
          const targetContainer = CjTool.getScaleContainer(target);

          const dragImages = this.editableImageManager.dragging;
          const dragImage = this.editableImageManager.dragging[dragImages.length - 1];

          let count = 0;
          for (const con of containers) {
            const isPhoto = CjTool.checkPhotoInFrame(con);
            if (isPhoto) {
              count++;
            }
          }
          const album: AlbumPage = this.layoutDataManager.selector('getCurrentPage');

          this.action(
            'add-image',
            `${dragImage.name} を写真枠に追加`,
            () => {
              this.imageController.createPhotoImage(dragImage, targetContainer, initPictureFlags).then(() => {
                this.imageEditManager.activateAllFocus();
              });
              // -- アルバムにEditableID保管
              UiManager.ins.emit('l->r:count-photo', { album, photoCount: count + 1 });
            },
            () => {
              if (this.imageEvent.isFocus && target) {
                this.disFocused(target);
              }
              const index = this.albumManager.currentAlbum?.editableIDList.findIndex((v) => v === dragImage.id);
              if (index !== undefined && index > -1) this.albumManager.currentAlbum?.editableIDList.splice(index, 1);
              const container = CjTool.getRotateContainer(targetContainer);
              const image = CjTool.getImageContainer(targetContainer);
              container.removeChild(image);
              const mask = CjTool.getMaskContainer(targetContainer);
              mask.alpha = 1;
              try {
                // if (!usedIDList.includes(dragImage.id)) {
                this.editableImageManager.toUnUse(image.editableImageId ?? '');
                // }
              } catch {
              }
              UiManager.ins.emit('l->r:count-photo', { album, photoCount: count });
              UiManager.ins.emit('l->r:change-use-image-num', { type: 'decrement' });
            },
          );
        }),
        UiManager.ins.on('r->l:editable-image:drop:direct', async (v) => {
          UiManager.ins.emit('l->r:wait-loading', { message: '' });
          if (this.imageEvent.activeFocus) {
            this.imageEditManager.activateAllFocus();
            return;
          }
          const containers: CjContainer[] = this.layoutDataManager.selector('getImageAreas', this.pageId);
          const focusFrames = containers.filter((elm) => elm.isChecked);
          let focusFrame: CjContainer | null;
          if (focusFrames.length > 1) {
            const max = Math.max(...focusFrames.map(v => v.depth));
            focusFrame = focusFrames.find(v => v.depth === max) ?? null;
          } else {
            focusFrame = focusFrames[0];
          }
          if (!v.index) {
            this.firstTargetFrame = focusFrame;
          }
          const _otherFrames = containers.filter(elm => elm !== this.firstTargetFrame && !elm.logoFlag);
          const otherFrames = _otherFrames.sort((a, b) => {
            return Number(a.photoAreaId) - Number(b.photoAreaId)
          })
          const target = !v.index ? this.firstTargetFrame : otherFrames[v.index - 1];
          if (!target) {
            this.imageEditManager.activateAllFocus();
            return;
          }
          target.isChecked = false;
          const targetContainer = CjTool.getScaleContainer(target);
          let count = 0;
          for (const con of containers) {
            const isPhoto = CjTool.checkPhotoInFrame(con);
            if (isPhoto) {
              count++;
            }
          }
          this.imageController.createPhotoImage(v.editableImage, targetContainer, initPictureFlags).then((res) => {
            if (!res) {
              this.imageEditManager.activateAllFocus();
              v.callback?.();
            }
          });
        }),

        UiManager.ins.on('r->l:editable-image:drop:temp', (v) => {
          const containers: CjContainer[] = this.layoutDataManager.selector('getImageAreas', this.pageId);

          const targetContainer = CjTool.getScaleContainer(containers[v.index]);

          let count = 0;
          for (const con of containers) {
            const isPhoto = CjTool.checkPhotoInFrame(con);
            if (isPhoto) {
              count++;
            }
          }
          this.imageController.createPhotoImage(v.editableImage, targetContainer, {
            ...initPictureFlags,
            postCardType: v.postCardType,
            dropTemp: true,
          }).then(() => {
            v.callback();
          })
        }),
        UiManager.ins.on('r->l:rotate-additional', () => {
          this.editableSideTool.rotateAdditional();
        }),
        UiManager.ins.on('r->l:transform-additional', () => {
          this.editableSideTool.transformAdditional();
        }),
        UiManager.ins.on('r->l:transparent-additional', (v) => {
          this.editableSideTool.transparentAdditional(v.type, v.callback);
        }),
        UiManager.ins.on('r->l:change-middle-frame', (v) => {
          this.trimmingManager.changeFrame(v.id);
        }),
        UiManager.ins.on('r->l:check:open-trim-menu', (v) => {
          const containers = this.layoutDataManager.selector('getEditors', this.pageId);
          for (const container of containers) {
            if (CjTool.checkPhotoInFrame(container)) {
              this.trimmingManager.isOpen = v.isOpen;
              UiManager.ins.emit('l->r:open-trim-menu', { isOpen: v.isOpen });
              return;
            }
          }
          UiManager.ins.emit('l->r:push-dialog', {
            title: '確認',
            message: [
              `写真が存在しません。`,
              `写真を設定してから、トリミングパネルを開いてください。`,
            ],
            buttons: [
              {
                label: 'OK',
                callback: () => {
                  UiManager.ins.emit('l->r:error-close-trim-menu');
                  // UiManager.ins.emit('r->l:close-trim-menu');
                },
              },
            ],
          });
        }),
        UiManager.ins.on('r->l:trim:start', (v) => {
          this.trimmingManager.start(v.trimData);
        }),
        UiManager.ins.on('r->l:show-trim-line', () => {
          if (this.editableSideTool.isCenterLine) {
            this.editableSideTool.removeCenterLine();
          } else {
            this.editableSideTool.addCenterLine();
          }
          this.trimmingManager.showTrim();
        }),
        UiManager.ins.on('r->l:show-guide-line', (v) => {
          this.trimmingManager.showGuide(v.list);
        }),
        UiManager.ins.on('r->l:show-select-guide', (v) => {
          this.trimmingManager.selectGuide(v.list);
        }),
        UiManager.ins.on('r->l:create-guide:start', (v) => {
          if (this.trimmingManager.isSetGuide) {
            this.trimmingManager.changeVector(v.type);
          }
          this.trimmingManager.setGuide(v.type);
        }),
        UiManager.ins.on('r->l:delete-guide', (v) => {
          this.trimmingManager.deleteGuide(v.id);
        }),
        UiManager.ins.on('r->l:close-trim-menu', () => {
          this.trimmingManager.disFocusFrame();
        }),
        UiManager.ins.on('r->l:shortcut:save-layout:focus-out', () => {
          if (this.imageEvent.isMove && this.imageEvent.dragTarget) {
            const containers: CjContainer[] = this.layoutDataManager.selector('getImageAreas', this.pageId);
            containers.forEach(v => {
              CjTool.getHighlight(v).visible = false;
            })
          }
          if (this.trimmingManager) {
            if (this.trimmingManager.trimGuides) {
              this.trimmingManager.moveEnd();
            }
          }
          if (this.imageEvent.targetFrame) {
            this.disFocused(this.imageEvent.targetFrame, true);
          } else if (this.textEvent.targetFrame) {
            this.disFocused(this.textEvent.targetFrame);
          }
        }),
    ];
      resolve();
    });
  }

  cancelDraggingFunc() {
    const containers: CjContainer[] = this.layoutDataManager.selector('getEditors', this.pageId);
    containers.forEach(con => {
      const highlight = CjTool.getHighlight(con);
      const imgAreaCon = CjTool.getImageAreaContainer(con);
      highlight.visible = false;
      imgAreaCon.isChecked = false;
      con.mouseEnabled = true;
      this.targetFrame = null;
    });
  }

  /** -- 正しい順番にソートする --
   * --- 上 ---
   * 1. ベースマスク(実はテンプレート形にくりぬいた黒い枠を被せている)
   * 2. テキスト
   * 3. 台紙タイプ時の中台紙
   * 4. オーバーラップ(一番上にくる装飾)
   * 5. ガイド(遊候補所エリア)
   * 6. PNG画像
   * 7. 写真枠 & 写真
   * 8. 背景
   * --- 下 ---
   * */
  sortContainer() {
    const frameLength = this.stage.numChildren;
    const overWrap = this.stage.getChildByName(CJ_CONTAINER_NAME.overwrap);
    const guide = this.stage.getChildByName(CJ_CONTAINER_NAME.guide);
    const additional = this.stage.getChildByName(CJ_CONTAINER_NAME.additional);
    const base = this.stage.getChildByName(CJ_CONTAINER_NAME.base);
    const baseMask = this.stage.getChildByName(CJ_CONTAINER_NAME.baseMask);
    const pngs = this.layoutDataManager.selector('getFreeGraphics', this.pageId);
    // const png = this.stage.getChildByName(CJ_CONTAINER_NAME.image);
    const texts = this.layoutDataManager.selector('getTexts', this.pageId);
    additional && this.stage.setChildIndex(additional, frameLength - 1);
    overWrap && this.stage.setChildIndex(overWrap, frameLength - 1);
    guide && this.stage.setChildIndex(guide, frameLength - 1);
    // png && this.stage.setChildIndex(png, frameLength - 1);
    baseMask && this.stage.setChildIndex(baseMask, frameLength - 1);
    for (const png of pngs) {
      this.stage.setChildIndex(png, frameLength - 1);
    }
    for (const text of texts) {
      this.stage.setChildIndex(text, frameLength - 1);
    }
    base && this.stage.setChildIndex(base, 0);
  }

  disFocused(target: CjContainer, isDisFocus?: boolean): void {
    const focusMode = this.layoutDataManager.selector('getFocusMode');
    const frameLength = this.stage.numChildren;
    if (!target) return;
    if (focusMode === 'png') {
      if (this.imageEvent.activeFocus) {
        target.getChildByName(CJ_CONTAINER_NAME.focus.rotate).visible = false;
        UiManager.ins.emit('l->r:dis-select');
        UiManager.ins.emit('l->r:change-image/frame', { mode: 'image-frame' });
        this.imageEditableTool.removeEvt(target);
        this.imageEvent.activeFocus = null;
        this.imageEvent.targetFrame = null;
        this.layoutDataManager.dispatch('setFocusMode', 'scale');
        // ツールチップを非表示にする
        UiManager.ins.emit('l->r:show-tooltip', { type: 'none', pos: { x: 0, y: 0 } });
      }
      target && this.stage.setChildIndex(target, frameLength - 1);
      this.sortContainer();
      return;
    }
    if (this.imageEvent.activeFocus) {
      const isPhoto = CjTool.checkPhotoInFrame(target);
      if (isDisFocus) {
        this.sortContainer();
      }

      if (target.name === CJ_CONTAINER_NAME.editor && isDisFocus) {
        this.sortContainer();
      }
      if (target.name === CJ_CONTAINER_NAME.image && isDisFocus) {
        const frameContainer = CjTool.getFrameContainer(target) ?? CjTool.getImageAreaContainer(target);
        if (!frameContainer) return;
        if (!CjTool.hitTest(target, frameContainer, {
          adjustOrigin: { x: CjCorner.maxIconSize / 2, y: CjCorner.maxIconSize / 2 },
        })) {
          UiManager.ins.emit('l->r:push-dialog', {
            title: '確認',
            message: [
              '画像を写真フレームの外にレイアウトすることはできません。',
              '元の位置に戻します。',
            ],
            buttons: [
              {
                label: 'OK',
                callback: () => {
                  UiManager.ins.emit('r->l:reset');
                  this.disFocused(target);
                },
              },
            ],
          });
          return;
        }
      }
      this.imageEditManager.isDAI || (CjTool.getFocusContainer(target, CJ_CONTAINER_NAME.focus.scale).visible = false);
      this.imageEditManager.isDAI || (CjTool.getFocusContainer(target, CJ_CONTAINER_NAME.focus.move).visible = false);
      if (isPhoto) {
        CjTool.getFocusContainer(target, CJ_CONTAINER_NAME.focus.rotate).visible = false;
        CjTool.getImageContainer(target).mouseEnabled = false;
      }
      UiManager.ins.emit('l->r:dis-select');
      UiManager.ins.emit('l->r:change-image/frame', { mode: 'image-frame' });
      this.imageEditableTool.removeEvt(target);
      this.imageEvent.activeFocus = null;
      this.imageEvent.isFocus = false;
      this.imageEvent.targetFrame = null;
      this.layoutDataManager.dispatch('setFocusMode', 'scale');
      // ツールチップを非表示にする
      UiManager.ins.emit('l->r:show-tooltip', { type: 'none', pos: { x: 0, y: 0 } });
      const editor = CjTool.getDefaultContainer(target);
      const ima = CjTool.getImageAreaContainer(target);
      editor && this.stage.setChildIndex(editor, ima.depth || frameLength - 3);
      this.sortContainer();
      this.imageEvent.isFocus = false;
      if (this.trimmingManager) {
        this.trimmingManager.trimLines && this.stage.setChildIndex(this.trimmingManager.trimLines, frameLength - 1);
        this.trimmingManager.trimGuides && this.stage.setChildIndex(this.trimmingManager.trimGuides, frameLength - 1);
      }
      return;
    } else if (this.textEvent.isFocus) {
      const textFocus = CjTool.getTextFocus(target);
      textFocus.visible = false;
      this.textEvent.isFocus = false;
      this.textEvent.targetFrame = null;
      target && this.stage.setChildIndex(target, frameLength - 1);
      this.sortContainer();
      return;
    }
  }

  // undo/redo 設定
  action(
    name: string,
    description: string,
    redo: () => Promise<void> | void,
    undo: () => Promise<void> | void,
  ) {
    this.commandManager.do(new CommandBase(name, description, redo, undo));
  }
}
