import {Checkbox} from '../../ui/input/checkbox';
import {Button} from '../../ui/button/button';
import {Select} from '../../ui/select/select';
import {Input} from '../../ui/input/input';
import { useCallback } from 'react';
import {useDispatch} from 'react-redux';
import {dialogActions} from '../slice/dialog-slice';
import {ConfigApplying} from './config-applying';

export const InsertText = () => {
  const dispatch = useDispatch();
  const handlerClickDecision = useCallback(() => {
    dispatch(dialogActions.pop());
    dispatch(dialogActions.push({
      title: '設定適用中',
      element: <ConfigApplying />,
    }));
  }, []);
  const handlerClickCancel = useCallback(() => {
    dispatch(dialogActions.pop());
    dispatch(dialogActions.pushMessage({
      title: '確認',
      message: [
        '設定をキャンセルし画面を閉じます。',
        'よろしいですか？',
        `・9900014010013`
      ],
      buttons: [
        {
          label: 'いいえ',
          callback: () => {
            dispatch(dialogActions.pop());
            dispatch(dialogActions.push({
              title: 'テキストの挿入',
              element: <InsertText />,
            }));
          },
        },
        {
          label: 'はい',
          callback: () => {
            dispatch(dialogActions.pop());
          },
        },
      ],
    }));
  }, []);
  return (
    <div>
      <div>
        全てのページを対象にテキストの挿入、番号の振り直しを行います。
        <br />挿入するテキストの内容を指定し、「決定」ボタンを押してください。
        <br />チェックを外した項目は変更されません。
      </div>
      <div>
        テキストの指定
        <div>
          <Checkbox />通し番号/画像情報
          <div>
            <div>
              通し番号
              <Button label="なし" />
              <Button label="あり" />
            </div>
            <div>
              画像情報
              <Button label="なし" />
              <Button label="ファイル名" />
              <Button label="撮影日時" />
            </div>
            <div>
              日付と時間の指定
              <div>
                <div>
                  撮影日時
                  <Select value="" list={[{value: '', label: '日時と時間'}]} />
                </div>
                <div>
                  日付の書式
                  <Select value="" list={[{value: '', label: 'March 14th, 2001'}]} />
                  <Select value="" list={[{value: '', label: '先頭のみ大文字'}]} />
                </div>
                <div>
                  時間の書式
                  <Input />
                </div>
              </div>
            </div>
            <div>
              ページ番号
              <div>
                ページ番号
                <Button label="なし" />
                <Button label="あり" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        フォントの指定
        <div>
          <div>
            フォント名
            <Select value="" list={[]} />
          </div>
          <div>
            フォントサイズ
            <Button label="小" />
            <Button label="中" />
            <Button label="大" />
          </div>
          <div>
            文字色の変更
          </div>
        </div>
      </div>
      <div>
        <Button
          label="キャンセル"
          onClick={handlerClickCancel}
        />
        <Button
          label="決定"
          onClick={handlerClickDecision}
        />
      </div>
    </div>
  );
};
