// noinspection JSVoidFunctionReturnValueUsed

import {ordersActions, OrdersSliceState} from '../../orders-slice';
import {AppThunk} from '../../../app/store';

// 名前
export const setName = (value: string): AppThunk => async (dispatch)  => {
  dispatch(ordersActions.updateDescriptionInfo({
    name: value,
  }));
};

// 日付
export const setDate = (value: string, alert: boolean): AppThunk => async (dispatch)  => {
  dispatch(ordersActions.updateDescriptionInfo({
    date: { value, alert },
  }));
};

// 日付の形式（文字種類）
export const setFormatType = (value: string): AppThunk => async (dispatch)  => {
  dispatch(ordersActions.updateDescriptionInfo({
    formatType: value,
  }));
};

// 日付の形式（大文字/先頭のみ大文字）
export const setFormatCase = (value: string): AppThunk => async (dispatch)  => {
  dispatch(ordersActions.updateDescriptionInfo({
    formatCase: value,
  }));
};

// ふりがな
export const setDateKana = (value: string): AppThunk => async (dispatch) => {
  dispatch(ordersActions.updateDescriptionInfo({
    date: { value, alert: false },
  }));
};

// 撮影場所
export const setPhotographLocation = (value: string): AppThunk => async (dispatch)  => {
  dispatch(ordersActions.updateDescriptionInfo({
    photographLocation: value,
  }));
};

// コメント
export const setComment = (value: string): AppThunk => async (dispatch) => {
  dispatch(ordersActions.updateDescriptionInfo({
    photographLocation: value,
  }));
};

// 住所
export const setAddress = (value: string): AppThunk => async (dispatch) => {
  dispatch(ordersActions.updateDescriptionInfo({
    photographLocation: value,
  }));
};

// 記載場所
export const setDescriptionLocations = (value: string[]): AppThunk => async (dispatch)  => {
  dispatch(ordersActions.updateDescriptionInfo({
    descriptionLocations: value,
  }));
};
