import {
  WizardButton,
  WizardButtonList,
  WizardContent,
  WizardInput,
  WizardInputList,
} from '../../../ui/wizard/wizard-content';
import { useParams } from 'react-router-dom';
import { PathParams, RoutingPath } from '../../../../routes/routing-path';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { useCallback, useState } from 'react';
import { apiActions } from '../../../../slices/api-slice';
import { ApiPostShopPasswordChange } from '../../../../api/front/wizard/api-staff-management';
import { dialogActions } from '../../../dialog/slice/dialog-slice';

type Props = {
  password: string;
  setStep: (step: number) => void;
}
type TooltipType = 'newPassword' | 'newPasswordConfirm' | 'none';
export const Step2StaffMngPasswordChangeWizard = (props: Props) => {
  const { password, setStep } = props;
  // - Hooks -
  const { kijshopCd } = useParams<PathParams>();
  const dispatch = useDispatch();
  // - State -
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordConfirm, setNewPasswordConfirm] = useState('');
  const [isNewPasswordError, setIsNewPasswordError] = useState(false);
  const [isNewPasswordConfirmError, setIsNewPasswordConfirmError] = useState(false);
  const [showTooltip, setShowTooltip] = useState<TooltipType>('none');
  // - Handlers -
  const handlerValidation = useCallback(() => {
    // 大文字・小文字・数字 のうち２種類で7文字
    const regex1 = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[A-Za-z0-9]{7}$/;
    const regex2 = /^(?=.*[a-z])(?=.*[A-Z])[A-Za-z]{7}$/;
    const regex3 = /^(?=.*[a-z])(?=.*[0-9])[a-z0-9]{7}$/;
    const regex4 = /^(?=.*[A-Z])(?=.*[0-9])[A-Z0-9]{7}$/;
    const isPasswordValid = newPassword.length === 7 && (regex1.test(newPassword) || regex2.test(newPassword) || regex3.test(newPassword) || regex4.test(newPassword));
    const isPasswordConfirmValid = newPasswordConfirm !== '' && newPassword === newPasswordConfirm;
    setIsNewPasswordError(!isPasswordValid);
    setIsNewPasswordConfirmError(!isPasswordConfirmValid)
    return isPasswordValid && isPasswordConfirmValid;
  },[newPassword, newPasswordConfirm]);
  const handlerChangeNewPassword = useCallback((value: string) => {
    setNewPassword(value);
  }, [newPassword]);
  const handlerChangeNewPasswordConfirm = useCallback((value: string) => {
    setNewPasswordConfirm(value);
  }, [newPasswordConfirm]);
  const handlerToggleShowTooltip = useCallback((type: TooltipType) => {
    setShowTooltip(type);
  }, []);
  // -- キャンセルボタン --
  const handlerClickCancel = useCallback(() => {
    dispatch(push(RoutingPath.cnv.configSystem({ kijshopCd })));
  }, []);
  // -- 変更ボタン --
  const handlerClickChange = useCallback(() => {
    if(!handlerValidation()){
      dispatch(dialogActions.pushMessage({
        title: '確認',
        message: ['入力内容に不備があります'],
        buttons: [
          {
            label: 'OK',
            callback: () => {
              dispatch(dialogActions.pop());
            },
          },
        ],
      }));
      return;
    }
    dispatch(apiActions.run(
      new ApiPostShopPasswordChange({
        kijshopCd,
        old_password: password,
        password: newPassword,
        password_confirmation: newPasswordConfirm,
      }),
      {
        onSuccess: () => setStep(3),
        onBasicError: (error) => {
          dispatch(dialogActions.pushMessage({
            title: '確認',
            message: error.messages,
            buttons: [
              {
                label: '閉じる',
                callback: () => {
                  dispatch(dialogActions.pop());
                },
              },
            ],
          }));
        },
      },
    ));
  }, [newPassword, newPasswordConfirm]);
  return (
    <WizardContent>
      <WizardInputList>
        <WizardInput
          label="新しいパスワード（7文字）"
          value={newPassword}
          disabled={false}
          isPassword={true}
          maxLength={7}
          descBottom={'※大文字・小文字・数字から２種類以上で７文字'}
          error={isNewPasswordError}
          tooltip={isNewPasswordError && showTooltip === 'newPassword' ?
            { messages: ['大文字、小文字、数字のうち2種類以上を含む7文字'] } : undefined}
          onChange={(e) => handlerChangeNewPassword(e.target.value)}
          onMouseOver={() => handlerToggleShowTooltip('newPassword')}
          onMouseLeave={() => handlerToggleShowTooltip('none')}
        />
        <WizardInput
          label="新しいパスワード（再入力）"
          value={newPasswordConfirm}
          disabled={false}
          isPassword={true}
          maxLength={7}
          error={isNewPasswordConfirmError}
          tooltip={isNewPasswordConfirmError && showTooltip === 'newPasswordConfirm' ?
            { messages: ['パスワードが一致しません'] } : undefined}
          onChange={(e) => handlerChangeNewPasswordConfirm(e.target.value)}
          onMouseOver={() => handlerToggleShowTooltip('newPasswordConfirm')}
          onMouseLeave={() => handlerToggleShowTooltip('none')}
        />
      </WizardInputList>
      <WizardButtonList>
        <WizardButton
          label="キャンセル"
          onClick={handlerClickCancel}
        />
        <WizardButton
          label="変更"
          onClick={handlerClickChange}
        />
      </WizardButtonList>
    </WizardContent>
  );
};