import { Connection } from '../services/connection/connection';
import { CustomError } from '../models/custom-error';
import {ResponseBase} from './response-base';

export type HTTPMethod = 'GET' | 'POST' | 'PUT' | 'DELETE';
export type ResponseType = 'XML' | 'JSON' | 'BLOB';

export class ApiBase<TResponse = any> {

  static protocol: string | null = '';
  // static protocol: string | null = 'https';
  // static hostname: string | null = 'https://58.89.238.220';
  // static appId: string = '2';
  // static hostname: string | null = 'ppm-dev.labonetwork.jp';
  static hostname: string | null = '';
  // static hostname: string | null = 'https://labonetwork.co.jp';

  // 継承先で切り替える
  protected get hostname(): string {
    return ApiBase.hostname || this._hostname || '';
  }
  protected get protocol(): string {
    return ApiBase.protocol || this._protocol || '';
  }
  get header(): { [key: string]: string | number | undefined } {
    return this._header || {};
  }
  static isValidTimeout: boolean = true;
  static sessionTimeout: number = 0;
  static timeout: number = 0;

  constructor(
    public method: HTTPMethod,
    public path: string,
    public responseType: ResponseType,
    public param: { [key: string]: string | string[] | number | undefined | File | boolean | null},
    private _header?: { [key: string]: string | number | undefined },
    private _hostname?: string,
    private _protocol?: string,
  ) {}

  public createURL() {
    const protocol = this.protocol || ApiBase.protocol || '';
    const hostName = this.hostname || ApiBase.hostname || '';
    // エンドポイントによるキャッシュ制御
    const isAgainstCache = this.responseType === 'XML';
    return `${protocol}${protocol ? '://' : ''}${hostName}${this.path}${isAgainstCache ? this.againstCache() : ''}`;
  }

  protected againstCache() {
    const now = performance.now();
    return `?cache=${now}`;
  }

  public createFormData(): FormData {
    const formData = new FormData();
    const keys = Object.keys(this.param);
    const values: (string | string[] | number | undefined | File | boolean | null)[] = Object.values(this.param);
    for (let i = 0; i < keys.length; i += 1) {
      if (values[i] !== undefined) {
        formData.append(keys[i], values[i] as string);
      }
    }
    return formData;
  }

  public createJson() {
    return this.param;
  }

  public createQueryParam() {
    let queryParam = '?';
    const keys = Object.keys(this.param);
    const values = Object.values(this.param);
    if (!keys.length) {
      return '';
    }
    for (let i = 0; i < keys.length; i += 1) {
      if (values[i] !== undefined) {
        // console.log('values[i] : ', values[i]);
        queryParam += `${i ? '&' : ''}${keys[i]}=${values[i]}`;
      }
    }
    return queryParam;
  }

  public createHeader() {
    const header = new Headers();
    const keys = Object.keys(this.header || {});
    const values = Object.values(this.header || {});
    for (let i = 0; i < keys.length; i += 1) {
      if (values[i] !== undefined) {
        header.append(keys[i], values[i] as string);
      }
    }
    return header;
  }

  public parse(response: any): any {
    return response;
  }

  public do(): Promise<TResponse | CustomError> {
    return Connection.do<TResponse>(this)
      .then((v) => v)
      .catch((e) => {
        // Store.dispatch(SystemActions.connectingStatusChange(false));
        // Store.dispatch(DialogActions.clear());
        // Store.dispatch((DialogActions.pushFreeMessageDialog({
        //   title: 'ネットワークエラー',
        //   messages: (e as any).messages,
        //   buttons: [{ label: 'OK' }],
        // })));
        throw e;
      });
  }

  // 正常系判定
  static isSuccess(response: ResponseBase<any>): boolean {
    return (
      response !== null
      && (
        String(response?.error?.errorCode) === '200'
        || String(response?.error?.errorCode) === '404' // レコードなし
      )
    );
  }

  // APIに依存しないエラー判定（型の不備とネットワークエラー）
  static isIrregularError(response: ResponseBase<any>): boolean {
    return !response?.error?.errorCode || String(response.error.errorCode) === '500';
  }

  // セッションタイムアウト判定
  static isSessionTimeout(response: ResponseBase<any>): boolean {
    return String(response?.error?.errorCode) === '401' || String(response?.error?.errorCode) === '405';
  }

  // タイムアウト判定
  static isTimeout(response: ResponseBase<any>): boolean {
    return String(response?.error?.errorCode) === '408';
  }

}
