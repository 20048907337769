import React, { useCallback, useState } from 'react';
import { dialogActions } from '../../dialog/slice/dialog-slice';
import { push } from 'connected-react-router';
import { RoutingPath } from '../../../routes/routing-path';
import { Radiobutton } from '../input/radiobutton';
import { Button } from '../button/button';
import { useDispatch } from 'react-redux';

export const ForgetPasswordDialog = () => {
  // -- 店舗のパスワードを再発行したい/スタッフのパスワードを再発行したい
  const [isForgetShopPassword, setIsForgetShopPassword] = useState(true);
  const dispatch = useDispatch();
  const handlerChangeForgetPasswordType = useCallback((v) => {
    const radioValue = Number(v);
    if (!radioValue) {
      setIsForgetShopPassword(true);
    } else {
      setIsForgetShopPassword(false);
    }
  }, []);
  const handlerClickCancel = useCallback(() => {
    setIsForgetShopPassword(true);
    dispatch(dialogActions.popAll());
  }, []);
  const handlerClickNext = useCallback(() => {
    dispatch(dialogActions.popAll());
    if (isForgetShopPassword) {
      dispatch(push(RoutingPath.adminPasswordReset));
    } else {
      dispatch(push(RoutingPath.staffPasswordReset));
    }
  }, [isForgetShopPassword]);
  return (
    <div className="dialog_contents">
      <Radiobutton
        value="0"
        onChange={(e) => handlerChangeForgetPasswordType(e.target.value)}
        checkState={isForgetShopPassword ? '0' : '1'}
        label="店舗のパスワードを再発行したい"
      />
      <Radiobutton
        value="1"
        onChange={(e) => handlerChangeForgetPasswordType(e.target.value)}
        checkState={isForgetShopPassword ? '0' : '1'}
        label="スタッフのパスワードを再発行したい"
      />
      <div className="dialog_contents__footer">
        <Button
          label="キャンセル"
          onClick={handlerClickCancel}
        />
        <Button
          label="次へ"
          onClick={handlerClickNext}
        />
      </div>
    </div>
  );
};