import {Radiobutton} from '../../ui/input/radiobutton';
import {Input} from '../../ui/input/input';
import {Button} from '../../ui/button/button';
import {useCallback, useState} from 'react';
import {useDispatch} from 'react-redux';
import {dialogActions} from '../slice/dialog-slice';

export const ImageFrameEdit = () => {
  // - Hooks -
  const dispatch = useDispatch();
  // - State -
  // -- 回転 --
  const [rotate, setRotate] = useState('1');
  // -- 拡大縮小比率 --
  const [scaleRate, setScaleRate] = useState(100);
  // -- 回転更新 --
  const handlerChangeRotate = useCallback((v) => {
    setRotate(v);
  }, []);
  // -- 拡大縮小比率更新 --
  const handlerChangeScaleRate = useCallback((v) => {
    setScaleRate(v);
  }, []);
  // -- 決定ボタン押下 --
  const handlerClickDecide = useCallback(() => {
    dispatch(dialogActions.pop());
  }, []);
  return (
    <div>
      <div>
        <div>
          写真枠の回転、ズーム、移動編集を開始します。回転の有無
          及拡大縮小比率をパーセント値(20~400%)で設定してください。
          移動はこのダイアログを閉じた後に行います。
        </div>
        <div>
          処理を中止する場合、ESCキーを押すか、ダイアログ右上の閉じるアイコン
          (×)をクリックして閉じてください
        </div>
      </div>
      <div>
        <Radiobutton
          label="写真枠の回転は行わない"
          value="1"
          onChange={() => handlerChangeRotate('1')}
          checkState={rotate}
        />
        <Radiobutton
          label="写真枠を右方向に90度回転する"
          value="2"
          onChange={() => handlerChangeRotate('2')}
          checkState={rotate}
        />
      </div>
      <div>
        写真枠の拡大縮小比率(20~400%)
        <Input
          value={scaleRate}
          onChange={(e) => handlerChangeScaleRate(e.target.value)}
        />%
      </div>
      <div>
        <Button
          label="決定"
          onClick={handlerClickDecide}
        />
      </div>
    </div>
  );
};
