import { ErrorMessageCollection } from '../collection/error-message-collection';

export type ErrorType =
  // ----- 発生しないはず ------
  // 予期しないエラー
  'UnexpectedError'
  // 配列添字が想定外
  | 'RangeError'
  // 見つからん
  | 'NotFound'
  // 未定義
  | 'Undefined'
  // 時間切れ
  | 'Timeout'
  // ネットワークエラー
  | 'NetworkError'
  // パースエラー
  | 'ParseError'
  // ----- 発生する ------
  // バリデーションルール違反
  | 'ValidationError'
  | 'AppLinkError';

/**
 * エラークラス
 * エラー想定が必要な箇所で使用する
 * 理由が明確な場合は ErrorType を正しく設定すること
 *  明確な理由がない場合は UnexpectedError で OK
 */
export class CustomError extends Error {
  constructor(
    public type: ErrorType = 'UnexpectedError',
    public messages: string[] = [
      ErrorMessageCollection.COMMON_UNEXPECTED_ERROR_1.en,
      ErrorMessageCollection.COMMON_UNEXPECTED_ERROR_1.en,
    ],
    public object: any = {},
  ) {
    super();
  }
}
