import {Input} from '../../ui/input/input';
import {Button} from '../../ui/button/button';
import {useDispatch} from 'react-redux';
import { useCallback, useEffect, useRef, useState } from 'react';
import {dialogActions} from '../slice/dialog-slice';
import { favoriteBrandActions, FavoriteBrandItem } from '../../../slices/favorite-brand-slice';
import './change-favorite-brand-name.scss';
import { FavoriteBrandConfig } from './favorite-brand-config';
import { SaveFavoriteBrand } from './save-favorite-brand';
import { apiActions } from '../../../slices/api-slice';
// import { ApiFavoritePost } from '../../../api/front/favorite/api-favorite';
import { useParams } from 'react-router-dom';
import { PathParams } from '../../../routes/routing-path';
import { ApiFavoriteGet, ApiFavoritePostEdit, ApiFavoritePostRegister } from '../../../api/front/favorite/api-favorite';
import { ResponseBase } from '../../../api/response-base';
import { FavoriteGetResponse } from '../../../models/api/front/favorite';

type ChangeFavoriteBrandNameProps = {
  kijshopCd: string,
  // - 更新する商品 -
  target: FavoriteBrandItem,
  // - ボタン押下後に表示するダイアログの種類 -
  type: 'config' | 'save',
  callback?: {
    onSuccess: () => void,
    onError: () => void,
  },
};
export const ChangeFavoriteBrandName = (props: ChangeFavoriteBrandNameProps) => {
  const { kijshopCd, target, type, callback } = props;
  // - Hooks -
  const dispatch = useDispatch();
  // - State -
  // -- 名称 --
  const [name, setName] = useState(target.name);
  // - Callback -
  // -- 名称 --
  const handlerChangeName = useCallback((v) => {
    setName(v);
  }, []);
  // -- OKボタン押下 --
  const handlerClickOk = useCallback(async () => {
    if (!name) {
      // dispatch(dialogActions.pop());
      dispatch(dialogActions.pushMessage({
        title: '確認',
        message: [
          'お気に入り商品名称を入力してください。',
        ],
        buttons: [
          {
            label: 'OK',
            callback: () => {
              dispatch(dialogActions.pop());
              // dispatch(dialogActions.push({
              //   title: type === 'config' ? 'お気に入り商品設定' : 'お気に入り商品保存',
              //   element: type === 'config' ? <FavoriteBrandConfig kijshopCd={kijshopCd} /> : <SaveFavoriteBrand kijshopCd={kijshopCd} />,
              // }));
            },
          },
        ],
      }));
    } else {
      if (target.type === 'brand') {
        dispatch(apiActions.run(
          new ApiFavoriteGet({
            kijshopCd,
            folederId: target.parentId,
          }),
          {
            onSuccess: (res: ResponseBase<FavoriteGetResponse>) => {
              const arr = res?.body?.data?.favProductList || [];
              if (arr.find((v) => v.name === name)) {
                dispatch(dialogActions.pushMessage({
                  title: '確認',
                  message: [
                    '入力されたお気に入り商品名称は既に存在します。',
                    '別のお気に入り商品名称を入力してください。',
                  ],
                  buttons: [
                    {
                      label: 'はい',
                      callback: () => dispatch(dialogActions.pop()),
                    },
                  ],
                }));
              } else {
                dispatch(apiActions.run(
                  new ApiFavoritePostEdit({
                    kijshopCd,
                    folderId: target.parentId,
                    favProductId: target.id,
                    name,
                    level: target.level,
                  }, target.id),
                  {
                    onSuccess: callback?.onSuccess,
                    onError: callback?.onError,
                  },
                ));
              }
            },
            onError: callback?.onError,
          },
        ));
      } else {
        dispatch(dialogActions.pop());
        // dispatch(favoriteBrandActions.changeName({
        //   id: target.id,
        //   name: name,
        //   type: target.type,
        //   children: target.children,
        // }));
      }
    }
  }, [name, type, target]);
  // -- キャンセルボタン押下 --
  const handlerClickCancel = useCallback(() => {
    dispatch(dialogActions.pop());
    // dispatch(dialogActions.push({
    //   title: type === 'config' ? 'お気に入り商品設定' : 'お気に入り商品保存',
    //   element: type === 'config' ? <FavoriteBrandConfig kijshopCd={kijshopCd} /> : <SaveFavoriteBrand kijshopCd={kijshopCd} />,
    // }));
  }, [type]);
  const ref = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (ref.current) {
      ref.current.focus();
    }
  }, [ref.current]);
  return (
    <div className="dialog_contents change_favorite_brand_name">
      <div className="dialog_contents__body">
        <div>変更後のお気に入り商品名称を入力してください。</div>
        <div className="top_label_ui">
          <span>名称</span>
          <Input
            ref={ref}
            value={name}
            onChange={(e) => handlerChangeName(e.target.value)}
            maxLength={40}
          />
          <div className="top_label_ui__caution">※40文字以内で入力してください</div>
        </div>
      </div>
      <div className="dialog_contents__footer">
        <Button
          label="キャンセル"
          onClick={handlerClickCancel}
        />
        <Button
          label="OK"
          disabled={!name.length}
          onClick={handlerClickOk}
        />
      </div>
    </div>
  );
};
