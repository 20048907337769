import { PartsRequest, PartsResponse } from '../../../../models/api/back/create-order/parts';
import {AppThunk} from "../../../../app/store";
import {ordersDataActions} from "../../../../slices/orders/orders-data-slice";
import {apiActions} from "../../../../slices/api-slice";
import {ResponseBase} from "../../../response-base";
import { apiLaminatesCoverThunk, apiLaminatesGenuineThunk } from '../laminates/api-laminates';
import {ApiBaseBack} from "../../api-base-back";

// export class ApiParts extends ApiBaseBack<PartsResponse> {
//   constructor(param: PartsRequest) {
//     super(
//       'GET',
//       '/api/v1/parts',
//       'JSON',
//       param,
//     );
//   }
// }

export class ApiCoverParts extends ApiBaseBack<PartsResponse> {
  constructor(param: PartsRequest) {
    super(
      'GET',
      '/api/v1/coverParts',
      'JSON',
      param,
    );
  }
}

export class ApiBodyParts1 extends ApiBaseBack<PartsResponse> {
  constructor(param: PartsRequest) {
    super(
      'GET',
      '/api/v1/bodyParts1',
      'JSON',
      param,
    );
  }
}

export class ApiBodyParts2 extends ApiBaseBack<PartsResponse> {
  constructor(param: PartsRequest) {
    super(
      'GET',
      '/api/v1/bodyParts2',
      'JSON',
      param,
    );
  }
}

export const apiPartsThunk = (param: PartsRequest, callback?: (res?: ResponseBase<PartsResponse>[]) => void, coverId?: string): AppThunk => async (dispatch, getState) => {
  // このAPIのレスポンスを元にラミネートを取得するためそちらも更新
  if (!param.productNameId) {
    dispatch(ordersDataActions.updateData({
      parts: { items: [] },
    }));
    dispatch(ordersDataActions.updateCover({
      parts: { itemId: '' },
      laminate: { laminate: [] },
    }));
    dispatch(ordersDataActions.updateGenuine({
      parts: { itemId: '' },
      laminate: { laminate: [] },
    }));
    callback?.();
    return;
  }
  dispatch(apiActions.runAll([
      new ApiCoverParts(param),
      coverId ? new ApiBodyParts1(param) : new ApiBodyParts2(param),
    ], {
      onSuccess: (resArr: ResponseBase<PartsResponse>[]) => {
        callback?.(resArr);
        const arr = resArr.map((res) => res.body.data?.items?.[0]);
        if (arr[0]?.itemId) {
          dispatch(apiLaminatesCoverThunk({
            productNameId: param.productNameId,
            itemId: arr[0].itemId,
          }));
          dispatch(ordersDataActions.updateCover({
            parts: { itemId: arr[0].itemId },
          }));
        } else {
          dispatch(ordersDataActions.updateCover({
            parts: { itemId: '' },
            laminate: { laminate: [] },
          }));
        }
        if (arr[1]?.itemId) {
          dispatch(apiLaminatesGenuineThunk({
            productNameId: param.productNameId,
            itemId: arr[1].itemId,
          }));
          dispatch(ordersDataActions.updateGenuine({
            parts: { itemId: arr[1].itemId },
          }));
        } else {
          dispatch(ordersDataActions.updateGenuine({
            parts: { itemId: '' },
            laminate: { laminate: [] },
          }));
          // dispatch(apiActions.run(
          //   new ApiParts(param),
          //   {
          //     onSuccess: (res: ResponseBase<PartsResponse>) => {
          //       callback?.();
          //       if (param.productNameId !== getState().order.itemDetail.itemSelect.value) {
          //         return;
          //       }
          //       dispatch(ordersDataActions.updateData({
          //         parts: { items: res.body.data?.items || [] },
          //       }));
          //     },
          //     onError: () => {
          //       callback?.();
          //     },
          //   }
          // ));
        }
      }
    }
  ))
};
