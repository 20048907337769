import {
  WizardBody,
  WizardBodyList,
  WizardButton,
  WizardButtonList,
  WizardContent,
} from '../../../ui/wizard/wizard-content';
import { useParams } from 'react-router-dom';
import { PathParams, RoutingPath } from '../../../../routes/routing-path';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';

type Props = {
  setStep: (step: number) => void;
}

export const Step3StaffMngPasswordChangeWizard = (props: Props) => {
  const { setStep } = props;
  // - Hooks -
  const { kijshopCd } = useParams<PathParams>();
  const dispatch = useDispatch();
  return (
    <WizardContent>
      <WizardBodyList>
        <WizardBody body={['店舗のパスワードを変更しました']} />
      </WizardBodyList>
      <WizardButtonList>
        <WizardButton
          label="閉じる"
          onClick={() => dispatch(push(RoutingPath.cnv.configSystem({ kijshopCd })))}
        />
      </WizardButtonList>
    </WizardContent>
  );
};