import { ApiBaseFront } from '../api-base-front';

export type FileDetail = {
  id: string;
  orgFilename: string;
  path: string;
  pathThumb: string;
  relativePath: string;
  selectId: string;
  exifCreated: string,
  exifColorSpace: string,
  exifMake: string,
  exifModel: string,
  exifOrientation: number,
  width: number,
  height: number,
  hash: string,
  size: number,
  createAt: string,
};

type Params = {
  kijshopCd: string,
  orderId: string,
  folderId: string,
  thumbOnly?: 0 | 1,
};

export class ApiGetCloudFolderFileDetail extends ApiBaseFront {
  constructor(_param: Params) {
    const { kijshopCd, orderId, ...param } = _param;
    super(
      'GET',
      `/api/v1/c-folder/shop/${kijshopCd}/order/${orderId}/image`,
      'JSON',
      param,
    );
  }
}
