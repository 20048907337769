import { ApiBaseFront } from '../api-base-front';

type ParamType = {
  startDate?: string,
  endDate?: string,
}

export class ApiGetCloudFolderShopList extends ApiBaseFront {
  constructor(param: ParamType) {
    super(
      'GET',
      '/api/v1/admin/c-folder/shop',
      'JSON',
      param,
    );
  }
}
