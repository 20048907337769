
export class NewPage {
  static tab(href: string) {
    const a = document.createElement('a') as HTMLAnchorElement;
    a.target = '_blank';
    a.rel = 'noopener noreferrer';
    a.href = href;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
}
