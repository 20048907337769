// - フリーテキストの入力情報 -
import React, { CSSProperties, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import iconDropper from '../../../../assets/img/icon/dropper.svg';
import { Button } from '../../../ui/button/button';
import { Checkbox } from '../../../ui/input/checkbox';
import { ColorPicker } from '../../../ui/input/color-picker';
import { NumUpDown } from '../../../ui/input/num-up-down';
import { Select } from '../../../ui/select/select';
import { TextArea } from '../../../ui/textarea/textarea';
import './layout.free-text.scss';
import { UiManager } from '../../../../layout-editor/manager/ui/ui.manager';
import { FontSizeList } from '../../../../layout-editor/model/font-size-list';
import { TextRotationList } from '../../../../layout-editor/model/text-rotation-list';
import { TextAlignList } from '../../../../layout-editor/model/text-align-list';
import { ColorCodeConverter, RgbaData } from '../../../../utilities/colorcode-converter';
import { Radiobutton } from '../../../ui/input/radiobutton';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { dialogActions } from '../../../dialog/slice/dialog-slice';
import { ColorDropper } from './layout.color-dropper-dialog';
import { LocalFontLoaderErrorDialog } from './layout.local-font-loader-error-dialog';
import { AwaitingEllipsis } from '../../../ui/awaiting/awaiting-ellipsis';
import { DynamicTooltip } from '../../../ui/tooltip/dynamic-tooltip';
import useDraggable from '../../../../hooks/draggable';
import { Pos } from '../../../../models/pos';
import { Input } from '../../../ui/input/input';

// - フリーテキストの入力情報 -
export type TextInfo = {
  id: string,
  isBold: boolean,
  isItalic: boolean,
  textColor: string,
  isOnDropper: boolean,
  fontType: 'local' | 'server' | '',
  font: string,
  fontSize: string,
  textTransparency: number,
  dropShadow: boolean,
  transparency: number,
  distance: number,
  outlineWidth: number,
  outlineColor: string,
  textAlign: string,
  textRotation: number,
  textSelectRotation: number,
  lineSpacingNum: number,
  textAreaContents: string,
  height: number,
  pos?: Pos & { scale: number },
  adjustedPos?: Pos & { scale: number },
};
export type TextFont = {
  isBold: string,
  isItalic: string,
  size: string,
  family: string,
}
export type TextOption = {
  fontType: '' | 'local' | 'server',
  isShadow: boolean,
  shadowTransparency: string,
  offset: number,
  outlineColor: string,
  outline: number,
}
type FreeTextProps = {
  // - 入力情報を取得 -
  info: TextInfo;
  // onGetTextInfo: (info: TextInfo) => void,
  popCallback: () => void,
  style?: CSSProperties,
  onBlur?: boolean,
};
// - フリーテキスト表示 -
export const FreeText = (props: FreeTextProps) => {
  const { info, style, popCallback, onBlur } = props;
  const initFontList = useAppSelector((v) => v.layout.initFontList);
  const userAgent = window.navigator.userAgent.toLowerCase();
  // - dispatch -
  const dispatch = useAppDispatch();
  // - Ref -
  const container = useRef<HTMLDivElement>(null);
  const body = useRef<HTMLDivElement>(null);
  const localFontRadioButtonEle = useRef<HTMLInputElement>(null);
  // - Hooks -
  useDraggable(body);
  // useDraggable(container);
  // - State -
  // -- ID --
  const [id, setId] = useState(info.id);
  // -- 太字 --
  const [isBold, setIsBold] = useState(info.isBold);
  // -- イタリック体 --
  const [isItalic, setIsItalic] = useState(info.isItalic);
  // -- 文字色 --
  const [textColor, setTextColor] = useState(info.textColor);
  // -- スポイト --
  const [isOnDropper, setIsOnDropper] = useState(info.isOnDropper);
  // -- フォント --
  const [fontType, setFontType] = useState<'local' | 'server' | ''>(info.fontType);
  // -- フォント --
  const [font, setFont] = useState(info.font);
  // -- フォントリスト --
  const [fontList, setFontList] = useState<{ value: string, label: string }[]>(info.fontType === 'server' ? initFontList : []);
  // -- 文字サイズ --
  const [fontSize, setFontSize] = useState(info.fontSize + 'pt');
  const [fontSizeView, setFontSizeView] = useState(info.fontSize + 'pt');
  // -- 文字サイズリスト --
  const [fontSizeList, setFontSizeList] = useState<{ value: string, label: string }[]>([...FontSizeList]);
  // -- 文字透明度 --
  const [textTransparency, setTextTransparency] = useState(info.textTransparency);
  // -- ドロップシャドウ --
  const [dropShadow, setDropShadow] = useState(info.dropShadow);
  // -- 透明度 --
  const [transparency, setTransparency] = useState(info.transparency);
  // -- 距離 --
  const [distance, setDistance] = useState(info.distance);
  // -- 文字アウトライン幅 --
  const [outlineWidth, setOutlineWidth] = useState(info.outlineWidth);
  // -- 文字アウトライン色 --
  const [outlineColor, setOutlineColor] = useState(info.outlineColor);
  // -- 文字揃え --
  const [textAlign, setTextAlign] = useState(info.textAlign);
  // -- 文字揃えリスト --
  const [textAlignList, setTextAlignList] = useState([...TextAlignList]);
  // -- 文字回転 --
  const [textSelectRotation, setTextSelectRotation] = useState(String(info.textSelectRotation));
  // -- 文字回転 --
  const [textRotation, setTextRotation] = useState(String(info.textRotation));
  // -- 文字回転リスト --
  const [textRotationList, setTextRotationList] = useState([...TextRotationList]);
  // -- 行間調整 --
  const [lineSpacingNum, setLineSpacingNum] = useState(info.lineSpacingNum);
  // -- テキストエリア --
  const [textAreaContents, setTextAreaContents] = useState(info.textAreaContents);
  // -- テキスト反映ボタン制御フラグ --
  const [reloadFlag, setReloadFlag] = useState(false);
  // -- Chromeブラウザかの判定 --
  const [isChrome, setIsChrome] = useState(true);
  // -- ローカルフォントに関するアラートフラグ --
  const [localFontAlert, setLocalFontAlert] = useState(false);
  // -- テキストエリア以外の更新フラグ --
  const [isChange, setIsChange] = useState(false);
  // -- 更新ボタンを最後に押したときのテキスト --
  const [prevText, setPrevText] = useState('');
  // -- 調整後 top --
  const [fixedTop, setFixedTop] = useState(0);
  // -- 調整後 right --
  const [fixedRight, setFixedRight] = useState(0);
  // -- 初期状態か --
  const [isInit, setIsInit] = useState(true);
  // - Callback -
  // -- 太字 --
  const handlerClickBold = useCallback(() => {
    setIsBold(prev => !prev);
    setReloadFlag(true);
    setIsChange(true);
  }, []);
  // -- イタリック体 --
  const handlerClickItalic = useCallback(() => {
    setIsItalic(prev => !prev);
    setReloadFlag(true);
    setIsChange(true);
  }, []);
  // -- スポイト --
  // -- スポイトの終了 --
  const stopDropper = (): Promise<void> => new Promise((resolve, reject) => {
    UiManager.ins.emit('r->l:color-dropper:stop', {});
    document.body.removeEventListener('mousedown', selectDropper);
    setTimeout(() => {
      resolve();
      setReloadFlag(true);
      setIsChange(true);
    }, 500);
  });
  // -- スポイトでの色の確定 --
  const selectDropper = useCallback(async () => {
    await stopDropper();
    setIsOnDropper(false);
  }, []);
  // -- スポイトスタート --
  const handlerClickDropper = useCallback((v) => {
    setIsOnDropper(v);
    if (v) {
      document.body.addEventListener('mousedown', selectDropper);
      dispatch(dialogActions.push({
        title: '',
        element: <ColorDropper
          onChange={(e) => {
            const colorData: RgbaData = ColorCodeConverter.rgbaToColorCode(e.color);
            setTextColor(colorData.colorCode);
            setIsChange(true);
          }}
        />,
        onWillClose: () => {
          UiManager.ins.emit('r->l:color-dropper:stop', {});
        },
        className: 'dropper_dialog',
        closeBtn: true,
      }));
    }
  }, [textColor, setTextColor]);
  // -- フォント --
  const handlerChangeFontType = useCallback((v, type?: typeof fontType) => {
    const _fontType = type ?? fontType;
    if (_fontType === 'server') {
      dispatch(dialogActions.push({
        title: '',
        element: <div>フォントを読み込んでいます<AwaitingEllipsis
          color="#888"
          size="12px"
        /></div>,
        className: 'loading_font_dialog',
      }));
      UiManager.ins.emit('l->r:web-font:download', {
        fontFamilyName: v,
        callback: () => {
          dispatch(dialogActions.pop());
          setFont(v);
          setReloadFlag(true);
          setIsChange(true);
        },
        error: (msg: string) => {
          dispatch(dialogActions.pop());
          setReloadFlag(true);
          setIsChange(true);
          alert('フォントの読み込みに失敗しました。');
        },
      });
    } else {
      setFont(v);
      setReloadFlag(true);
      setIsChange(true);
    }
  }, [fontType]);
  // -- 文字サイズ --
  const handlerChangeTextSize = useCallback((v) => {
    const font = fontSizeList.find((w) => w.value === v)!.label;
    setFontSize(font);
    setFontSizeView(font);
    setReloadFlag(true);
    setIsChange(true);
  }, []);
  const handlerBlurFontSize = useCallback((v) => {
    const fixText = v.replace(/[Ａ-Ｚａ-ｚ０-９]/g, (s: string) => {
      return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
    }).replace(/[^0-9]/gi, '');
    if (Number(fixText) < 1) {
      setFontSize('1' + 'pt');
      setFontSizeView('1' + 'pt');
    } else if (Number(fixText) > 600) {
      setFontSize('600' + 'pt');
      setFontSizeView('600' + 'pt');
    } else {
      setFontSize(fixText + 'pt');
      setFontSizeView(fixText + 'pt');
    }
    setReloadFlag(true);
    setIsChange(true);
  }, []);
  // フォントサイズが選択肢の中から選ばれていたら値を入れる。
  const selectFontSize = useMemo(() => {
    return fontSizeList.find((v) => v.label === fontSize);

  }, [fontSizeList, fontSize])
  // -- 文字透明度 --
  const handlerChangeTextTransparency = useCallback((v) => {
    setTextTransparency(v);
    setReloadFlag(true);
    setIsChange(true);
  }, []);
  // -- 削除ボタン --
  const handlerClickDelete = useCallback(() => {
    dispatch(dialogActions.pushMessage({
      title: '確認',
      message: ['この文字情報を削除します。よろしいですか？'],
      buttons: [
        {
          label: 'いいえ',
          callback: () => {
            dispatch(dialogActions.pop());
          },
        },
        {
          label: 'はい',
          callback: () => {
            UiManager.ins.emit('r->l:delete-text', ({ id }));
            setReloadFlag(false);
            setIsChange(false);
            popCallback && popCallback();
            dispatch(dialogActions.pop());
          },
        },
      ],
    }));

  }, [id]);
  // -- ドロップシャドウ --
  const handlerClickDropShadow = useCallback(() => {
    setDropShadow(prev => !prev);
    setReloadFlag(true);
    setIsChange(true);
  }, []);
  // -- 透明度 --
  const handlerChangeTransparency = useCallback((v) => {
    setTransparency(v);
    setReloadFlag(true);
    setIsChange(true);
  }, []);
  // -- 距離 --
  const handlerChangeDistance = useCallback((v) => {
    setDistance(v);
    setReloadFlag(true);
    setIsChange(true);
  }, []);
  // -- 文字アウトライン幅 --
  const handlerChangeOutlineWidth = useCallback((v) => {
    setOutlineWidth(v);
    setReloadFlag(true);
    setIsChange(true);
  }, []);
  // -- 文字揃え --
  const handlerChangeTextAlign = useCallback((v) => {
    switch (v) {
      case 'left':
        setTextAlign('0');
        break;
      case 'center':
        setTextAlign('1');
        break;
      case 'right':
        setTextAlign('2');
        break;

    }
    setReloadFlag(true);
    setIsChange(true);
  }, []);
  // -- 文字回転 --
  const handlerChangeTextRotation = useCallback((v) => {
    setTextRotation(v);
    setReloadFlag(true);
    setIsChange(true);
    switch (v) {
      case 0:
        setTextSelectRotation('0');
        break;
      case 45:
        setTextSelectRotation('45');
        break;
      case 90:
        setTextSelectRotation('90');
        break;
      case 135:
        setTextSelectRotation('135');
        break;
      case 180:
        setTextSelectRotation('180');
        break;
      case 225:
        setTextSelectRotation('225');
        break;
      case 270:
        setTextSelectRotation('270');
        break;
      case 315:
        setTextSelectRotation('315');
        break;
      case 360:
        setTextSelectRotation('0');
        break;
    }
  }, [textRotation, textSelectRotation]);
  // -- 文字回転選択 --
  const handlerChangeTextSelectRotation = useCallback((v) => {
    setTextSelectRotation(v);
    setTextRotation(v);
    setReloadFlag(true);
    setIsChange(true);
  }, [textRotation, textSelectRotation]);
  // -- 行間調整 --
  const handlerChangeLineSpacing = useCallback((v) => {
    setLineSpacingNum(v);
    setReloadFlag(true);
    setIsChange(true);
  }, []);
  // -- テキストエリア --
  const handlerChangeTextareaContents = useCallback((v) => {
    setTextAreaContents(v);
    setReloadFlag(true);
  }, []);
  // -- 決定ボタン --
  const handlerClickDecision = useCallback(() => {
    UiManager.ins.emit(
      'r->l:reload-text',
      {
        id,
        text: textAreaContents,
        color: textColor,
        alpha: textTransparency / 100,
        rotation: Number(textRotation),
        textAlign: textAlignList[Number(textAlign)].value,
        lineHeight: lineSpacingNum,
        font: {
          isBold: isBold ? 'bold' : '',
          isItalic: isItalic ? 'italic' : '',
          // size: fontSize,
          size: fontSize.replace('pt', 'px'),
          family: fontList.find((v) => v.value === font)?.label || fontList[0]?.label || '',
        },
        options: {
          fontType,
          isShadow: dropShadow,
          shadowTransparency: `rgba(0, 0, 0, ${transparency / 100})`,
          offset: distance,
          outlineColor: outlineColor,
          outline: outlineWidth,
        },
        callback: () => {
          setPrevText(textAreaContents);
          setIsInit(false);
        },
        // options: {
        //   fontWeight: isBold ? 'bold' : '',
        //     fontFamily: fontList.find((v) => v.value === font)?.label || fontList[0]?.label || '',
        //     fontType,
        //     fontStyle: isItalic ? 'italic' : '',
        //     fill: textColor,
        //     fontSize: Number(fontSizeList[fontSize].label.replace('pt', '')),
        //     opacity: 1 - (textTransparency / 100),
        //     angle: Number(textRotation),
        //     textAlign,
        //     isShadow: dropShadow,
        //     shadowTransparency: `rgba(0, 0, 0, ${1 - transparency / 100})`,
        //     offset: distance,
        //     strokeWidth: outlineWidth,
        //     stroke: outlineColor,
        //     lineHeight: lineSpacingNum <= 0 ? 1 : lineSpacingNum,
        // },
      },
    );
  }, [
    id, textAreaContents, font, fontList, fontSize, textColor, isBold, isItalic, transparency, textTransparency,
    outlineWidth, outlineColor, distance, textAlign, textRotation, lineSpacingNum, dropShadow, fontType, isInit,
  ]);

  const loadLocalFont = useCallback(() => {
    UiManager.ins.emit(
      'l->r:font:check-support',
      {
        callback: (e: { supported: boolean }) => {
          if (!e.supported) {
            dispatch(dialogActions.push({
              title: 'ローカルフォント読み込みエラー',
              element: (
                <LocalFontLoaderErrorDialog
                  reloadCallback={() => {
                    dispatch(dialogActions.pushMessage({
                      title: '確認',
                      message: [
                        `ブラウザが更新されます。`,
                        `保存されていないレイアウト情報は全て失われます。`,
                      ],
                      buttons: [
                        {
                          label: `キャンセル`,
                          callback: () => dispatch(dialogActions.pop()),
                        },
                        {
                          label: `OK`,
                          callback: () => window.location.reload(),
                        },
                      ],
                    }));
                  }}
                  callback={() => {
                  }}
                  closeCallback={() => {
                    setFontType('server');
                    dispatch(dialogActions.pop());
                  }}
                />
              ),
              onWillClose: () => {
              },
              className: 'local-font-loader-error-dialog',
              closeBtn: true,
            }));
            setFontList([]);
          } else {
            // ---- フォントアクセス ----
            UiManager.ins.emit(
              'l->r:font:get-list',
              {
                callback: (e: { fonts: string[] }) => {
                  const fontList = e.fonts.map((v, i) => ({ value: v, label: v }));

                  const _font = fontList.find((v) => v.label === font) ?? (fontList[0] ?? { value: '', label: '' });
                  if (fontList.length) setFont(_font.label);
                  setFontList(fontList);
                },
              },
            );
          }
        },
      },
    );
  }, []);

  // -- Life Cycle --
  // --- did mount --
  useEffect(() => {
    if (fontType === 'local') {
      loadLocalFont();
    } else if (fontType === 'server') {
      // ---- フォントアクセス ----
      UiManager.ins.emit(
        'l->r:web-font:get-list',
        {
          callback: (e: { fonts: string[] }) => {
            const _fontList = e.fonts.map((v, i) => ({ value: v, label: v }));
            setFontList(_fontList);
            const _font = fontList.find((v) => v.label === font) ?? (fontList[0] ?? { value: '', label: '' });
            if (fontList.length) setFont(_font.label);
          },
        },
      );
    } else {
      setFontList([]);
    }
  }, [fontType]);

  // ---- edit用のデータセット ----
  useEffect(() => {
    setId(info.id);
    setIsBold(info.isBold);
    setIsItalic(info.isItalic);
    setTextColor(info.textColor);
    setIsOnDropper(info.isOnDropper);
    setFont(info.font);
    setFontSize(info.fontSize + 'pt');
    setFontSizeView(info.fontSize + 'pt');
    setFontType(info.fontType);
    setTextTransparency(info.textTransparency);
    setDropShadow(info.dropShadow);
    setTransparency(info.transparency);
    setDistance(info.distance);
    setOutlineWidth(info.outlineWidth);
    setOutlineColor(info.outlineColor);
    setTextAlign(info.textAlign);
    setTextRotation(String(info.textRotation));
    setTextSelectRotation(String(info.textSelectRotation));
    setLineSpacingNum(info.lineSpacingNum);
    setTextAreaContents(info.textAreaContents);
    setPrevText(info.textAreaContents);
    if (info.textAreaContents.length) {
      setIsInit(false);
    } else {
      setIsInit(true);
    }
  }, [info]);
  useEffect(() => {
    // ---- Chromeブラウザかの判定処理 ----
    setIsChrome(userAgent.indexOf('chrome') != -1);
    // ---- 初期位置見切れ対応 ----
    const bodyRect = body.current?.getBoundingClientRect();
    const bodyBottom = (bodyRect?.y || 0) + (bodyRect?.height || 0);
    const bodyRight = (bodyRect?.x || 0) + (bodyRect?.width || 0);
    if (bodyRect) {
      if (bodyBottom > window.innerHeight) {
        setFixedTop((info.pos?.y ?? bodyRect.y) - bodyRect.height - (info.height ? 7 : Number(info.fontSize) * 1.25));  // 7 ... 微調整用
      }
      if (bodyRight > window.innerWidth) {
        setFixedRight(Number(info.fontSize));
      }
    }
    UiManager.ins.emit('r->l:stop-keyboard-event', true);
    return (() => {
      UiManager.ins.emit('r->l:stop-keyboard-event', false);
    });
  }, []);
  // -- テキストエリアボタン以外の更新処理 --
  useEffect(() => {
    if (!isInit && isChange && prevText.length) {
      UiManager.ins.emit(
        'r->l:reload-text',
        {
          id,
          text: textAreaContents,
          color: textColor,
          alpha: textTransparency / 100,
          rotation: Number(textRotation),
          textAlign: textAlignList[Number(textAlign)].value,
          lineHeight: lineSpacingNum,
          font: {
            isBold: isBold ? 'bold' : '',
            isItalic: isItalic ? 'italic' : '',
            // size: fontSize,
            size: fontSize.replace('pt', 'px'),
            family: fontList.find((v) => v.value === font)?.label || fontList[0]?.label || '',
          },
          options: {
            fontType,
            isShadow: dropShadow,
            shadowTransparency: `rgba(0, 0, 0, ${transparency / 100})`,
            offset: distance,
            outlineColor: outlineColor,
            outline: outlineWidth,
          },
        },
      );
    }
  }, [
    id, font, fontList, fontSize, fontSizeView, textColor, isBold, isItalic, transparency, textTransparency,
    outlineWidth, outlineColor, distance, textAlign, textRotation, lineSpacingNum, dropShadow, fontType,
    isChange, prevText, isInit,
  ]);

  return (
    <div
      ref={container}
      className="free_text_ui__dimmer"
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        (!isOnDropper && onBlur) && popCallback();
      }}
    >
      <div
        className="free_text_ui"
        style={{
          ...style,
          top: fixedTop ? fixedTop : style?.top,
          ...fixedRight ? { left: 'auto' } : {},
          right: fixedRight ? fixedRight : style?.right,
        }}
        onClick={(e) => {
          e.stopPropagation();
          // e.preventDefault();
        }}
        ref={body}
      >
        <div className="row1">
          <div className="row1__col1">
            <Button
              icon={<i className="fas fa-bold" />}
              onClick={handlerClickBold}
              color="light"
              className="btn_bold"
              tooltip={{ type: 'normal', text: ['文字列の太さを設定/解除します'] }}
            />
            <Button
              icon={<i className="fas fa-italic" />}
              onClick={handlerClickItalic}
              color="light"
              className="btn_italic"
              tooltip={{ type: 'normal', text: ['文字列の斜体を設定/解除します'] }}
            />
            <ColorPicker
              selectColor={textColor}
              onGetColor={(color) => {
                setTextColor(color);
                setReloadFlag(true);
                setIsChange(true);
              }}
              tooltip={{ type: 'normal', text: ['文字列の色を設定します'] }}
            />
            <Button
              onClick={() => handlerClickDropper(!isOnDropper)}
              color="light"
              icon={<img
                src={iconDropper}
                alt=""
              />}
            />
          </div>
          <div className="row1__col2">
            <div className="left_label_form">
              <span>フォント選択</span>
              <div>
                <Radiobutton
                  label="サーバー"
                  checkState={'server'}
                  value={fontType}
                  onChange={() => {
                    setFontType('server');
                    setReloadFlag(true);
                  }}
                />
                <div className="local_font_wrap">
                  <Radiobutton
                    label="ローカル"
                    checkState={'local'}
                    value={fontType}
                    onChange={() => {
                      if (isChrome) {  // スタイルでpointer-eventを無効にするとフォーカスが効かずアラートも出ない
                        setFontType('local');
                        setReloadFlag(true);
                      }
                    }}
                    onMouseEnter={() => {
                      if (!isChrome) {
                        setLocalFontAlert(true);
                      }
                    }}
                    onMouseLeave={() => {
                      if (!isChrome) {
                        setLocalFontAlert(false);
                      }
                    }}
                    style={isChrome ? {} : { opacity: 0.3 }}
                    ref={localFontRadioButtonEle}
                  />
                  {localFontAlert ? <DynamicTooltip
                      messages={['「ローカルフォント」はChromeのみで使用できます']}
                      relativeEle={localFontRadioButtonEle.current}
                      className="local_font_alert"
                    />
                    : (<></>)}
                </div>
              </div>
              <div>
                <Select
                  value={font}
                  list={fontList}
                  onChange={(e) => handlerChangeFontType(e.target.value)}
                  tooltip={{ type: 'normal', text: ['文字列のフォントを設定します'] }}
                />
                <div className="font_size">
                  <Input
                    value={fontSizeView}
                    onChange={(e) => setFontSizeView(e.target.value)}
                    onBlur={(e) => handlerBlurFontSize(e.target.value)}
                    hoverTooltip={{ messages: ['フォントのサイズを1 ~ 600pointの半角で設定します'], error: false }}
                  />
                  <Select
                    value={selectFontSize ? selectFontSize.value : fontSize}
                    list={selectFontSize ? fontSizeList : [{ value: fontSize, label: fontSize }, ...fontSizeList]}
                    onChange={(e) => handlerChangeTextSize(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row2">
          <div className="row2__col1">
            <div className="left_label_form">
              <span>文字透明度</span>
              <NumUpDown
                value={textTransparency}
                min={0}
                max={100}
                onBlur={(e) => handlerChangeTextTransparency(e.target.value)}
                callback={num => handlerChangeTextTransparency(num)}
                tooltip={{ type: 'normal', text: ['文字列の透明度を0（透明）～100（非透明）で設定します'] }}
              />
              <span className="form_unit">%</span>
            </div>
          </div>
          <div className="row2__col2">
            <Checkbox
              label="ドロップシャドウ"
              onClick={handlerClickDropShadow}
              checked={dropShadow}
              tooltip={{ type: 'normal', text: ['文字列のドロップシャドウを設定/解除します'] }}
            />
            <div className="left_label_form">
              <span>透明度</span>
              <NumUpDown
                disabled={!dropShadow}
                value={transparency}
                min={0}
                max={100}
                onBlur={(e) => handlerChangeTransparency(e.target.value)}
                callback={num => handlerChangeTransparency(num)}
                tooltip={{ type: 'normal', text: ['ドロップシャドウの透明度を設定します'] }}
              />
              <span className="form_unit">%</span>
            </div>

            <div className="left_label_form">
              <span>距離</span>
              <NumUpDown
                disabled={!dropShadow}
                value={distance}
                min={0}
                onBlur={(e) => handlerChangeDistance(e.target.value)}
                callback={num => handlerChangeDistance(num)}
                tooltip={{ type: 'normal', text: ['文字列とシャドウの距離を設定します'] }}
              />
            </div>
          </div>
          <div className="row2__col3">
            <div className="left_label_form">
              <span>行間調整</span>
              <NumUpDown
                value={String(lineSpacingNum)}
                min={-50}
                max={100}
                isMinus
                onBlur={(e) => handlerChangeLineSpacing(e.target.value)}
                callback={num => handlerChangeLineSpacing(num)}
                tooltip={{ type: 'normal', text: ['行間を-50～100で設定します'] }}
              />
            </div>
          </div>
        </div>

        <div className="row3">
          <div className="row3__col1">
            <div className="left_label_form">
              <span>文字揃え</span>
              <Select
                value={textAlignList[Number(textAlign)].value}
                list={textAlignList}
                onChange={(e) => handlerChangeTextAlign(e.target.value)}
                tooltip={{ type: 'normal', text: ['文字揃えを設定します'] }}
              />
            </div>
          </div>
          <div className="row3__col2">
            <div className="left_label_form">
              <span>文字回転</span>
              <Select
                value={textSelectRotation}
                list={textRotationList}
                onChange={(e) => handlerChangeTextSelectRotation(e.target.value)}
              />
              <NumUpDown
                value={textRotation}
                min={0}
                max={360}
                onBlur={(e) => handlerChangeTextRotation(e.target.value)}
                callback={num => handlerChangeTextRotation(num)}
                tooltip={{ type: 'normal', text: ['文字の回転を0度～360度で設定します'] }}
              />
              <span className="form_unit">度</span>
            </div>
          </div>
          <div className="row3__col3">
            <div className="left_label_form">
              <span>文字アウトライン幅</span>
              <NumUpDown
                value={outlineWidth}
                min={0}
                onBlur={(e) => handlerChangeOutlineWidth(e.target.value)}
                callback={num => handlerChangeOutlineWidth(num)}
                tooltip={{ type: 'normal', text: ['文字列のアウトライン幅を設定します'] }}
              />
            </div>
            <div className="left_label_form">
              <span>文字アウトライン色</span>
              <ColorPicker
                selectColor={outlineColor}
                onGetColor={(color) => {
                  setReloadFlag(true);
                  setIsChange(true);
                  setOutlineColor(color);
                }}
                tooltip={{ type: 'normal', text: ['文字列のアウトライン色を設定します'] }}
              />
            </div>
          </div>
        </div>

        <div className="row4">
          <TextArea
            value={textAreaContents}
            onChange={(e) => handlerChangeTextareaContents(e.target.value)}
            style={{
              fontFamily: font,
            }}
          />
        </div>

        <div className="free_text_ui__footer">
          <div className="free_text_ui__footer__left">
            <Button
              label="閉じる"
              onClick={() => {
                UiManager.ins.emit('r->l:finish-text-edit', { id });
                popCallback && popCallback();
              }}
            />
          </div>
          <div className="free_text_ui__footer__right">
            <Button
              label="削除"
              onClick={() => {
                handlerClickDelete();
                // popCallback && popCallback();
              }}
              color="danger"
              tooltip={{ type: 'normal', text: ['設定した文字列を削除します'] }}
            />
            <Button
              label="反映"
              disabled={!textAreaContents.length || !reloadFlag}
              onClick={() => {
                handlerClickDecision();
                setReloadFlag(false);
              }}
              tooltip={{ type: 'normal', text: ['入力した文字列をページ内のテキストボックス', 'にコピーします'] }}
            />
          </div>

        </div>
      </div>
    </div>
  );
};
