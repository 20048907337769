import {
  StoreGetResponse,
  StorePostRequest,
  StorePostResponse,
  StorePutRequest,
} from '../../../models/api/front/store';
import { ApiBaseFront } from '../api-base-front';

// 発送店舗一覧取得
export class ApiStoreGet extends ApiBaseFront<StoreGetResponse[]> {
  constructor(param: { kijshopCd: string }) {
    super(
      'GET',
      `/api/v1/store/${param.kijshopCd}`,
      'JSON',
      // param,
      {},
    );
  }
}

// 発送店舗新規登録
export class ApiStorePost extends ApiBaseFront<StorePostResponse[]> {
  constructor(kijshopCd: string, param: StorePostRequest) {
    super(
      'POST',
      `/api/v1/store/${kijshopCd}`,
      'JSON',
      param,
    );
  }
}

// 発送店舗修正
export class ApiStoreEdit extends ApiBaseFront {
  constructor(kijshopCd: string, shippingShopId: string, param: StorePutRequest) {
    super(
      'POST',
      `/api/v1/store/${kijshopCd}/${shippingShopId}`,
      'JSON',
      param,
    );
  }
}

// 発送店舗削除
export class ApiStoreDelete extends ApiBaseFront {
  constructor(kijshopCd: string, shippingShopId: string, param: {}) {
    super(
      'DELETE',
      `/api/v1/store/${kijshopCd}/${shippingShopId}`,
      'JSON',
      param,
    );
  }
}
