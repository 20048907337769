import {Button} from '../../../ui/button/button';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {useDispatch} from 'react-redux';
import {dialogActions} from '../../../dialog/slice/dialog-slice';
import {push} from 'connected-react-router';
import { PathParams, RoutingPath } from '../../../../routes/routing-path';
import { useParams } from 'react-router-dom';
import { RetouchGetResponse, SimpleRetouchInfo } from '../../../../api/back/retouch/api-retouch';
import { xmlActions } from '../../../../xml/slice/xml-slice';
import { useAppSelector } from '../../../../app/hooks';
import * as lodash from 'lodash';
import { UiManager } from '../../../../layout-editor/manager/ui/ui.manager';
import { EditableImage } from '../../../../layout-editor/manager/image-edit/editable-image';
import { OrderSelectXml } from '../../../../xml/class/order/order-select-xml';
import { XmlStructureModel } from '../../../../xml/model/xml-structure-model';
import { orderLockActions } from '../../../../slices/lock-order-slice';
import { LoadingPopup } from '../../../ui/loading/loading-popup';

export type ParentRetouchInfo = {
  itemId: string,
  productName: string,
  productNameId: string,
  productShortName: string,
  productTypeId: string,
};

export type ChildRetouchInfo = (SimpleRetouchInfo & {selectID: string[]})[]

type Props = {
  retouchInfo?: RetouchGetResponse;
  xml: XmlStructureModel | null,
  callbackSave: (v: boolean) => void,
  imageCount: number,
}
export const RetouchFooter = (props: Props) => {
  const { retouchInfo, xml, callbackSave, imageCount } = props;
  const dispatch = useDispatch();
  // - Store -
  const { kijshopCd, shopOrderId, orderId } = useParams<PathParams>();
  const { imageCheckList } = useAppSelector((state) => ({
    imageCheckList: state.retouch.imageCheckItemList,
  }), lodash.isEqual);
  // -- 保存時の startDate 設定用 --
  const [startDate] = useState(new Date());
  const [saving, setSaving] = useState(false);

  // -- レイアウト完了 --
  const handlerClickComplete = useCallback(() => {
    if (!imageCount) {
      dispatch(dialogActions.pushMessage({
        title: '確認',
        message: [
          '画像が指定されていません。',
          '画像を１枚以上指定してください。',
        ],
        buttons: [{
          label: 'OK',
          callback: () => {
            dispatch(dialogActions.pop());
          },
        }],
      }));
      return;
    }
    dispatch(orderLockActions.check(
      kijshopCd, shopOrderId,{
        yes: () => {
          dispatch(dialogActions.pushMessage({
            title: '確認',
            message: [
              '処理を完了し、データを保存します。',
              'よろしいですか？',
            ],
            buttons: [
              {
                label: 'いいえ',
                callback: () => {
                  dispatch(dialogActions.pop());
                },
              },
              {
                label: 'はい',
                callback: () => {
                  dispatch(dialogActions.pop());
                  setSaving(true);
                  callbackSave(true);
                  const targetId: string[] = [];
                  imageCheckList.forEach((v) => {
                    targetId.push(...v.checkList);
                  });
                  if (!targetId.length && !retouchInfo) {
                    dispatch(dialogActions.pop());
                    dispatch(push(RoutingPath.cnv.ordersPreparation({ kijshopCd, shopOrderId })));
                    return;
                  } else {
                    let result: ChildRetouchInfo = [];
                    new Promise<void>((resolve) => {
                      UiManager.ins.emit('l->r:post-editable-image-list', {
                        callback: (e: { list: EditableImage[] }) => {
                          Promise.all([e.list.map((v) => new Promise<void>((_resolve) => {
                            const isExist = imageCheckList.find((vv) => vv.id === v.id);
                            if (v.selectId && isExist) {
                              for (const vv of isExist.checkList) {
                                // isExist.checkList.forEach((vv) => {
                                const _id = result.find((vvv) => vvv.productNameId === vv);
                                if (_id) {
                                  _id.selectID.push(v.selectId);
                                } else {
                                  const data = retouchInfo?.[0].simpleRetouchInfo.find((vvv) => vvv.productNameId === vv);
                                  if (data) {
                                    result.push({ ...data, selectID: [v.selectId] });
                                  }
                                }
                              }
                            }
                            _resolve();
                          }))]).then(() => resolve());
                        },
                      });
                    }).then(() => {
                      // if (result) {
                      const retouchItems: ChildRetouchInfo = [];
                      retouchInfo?.[0].simpleRetouchInfo.forEach((v) => {
                        const data = result.find((res) => res.retouchProductNameId === v.retouchProductNameId);
                        if (data) {
                          retouchItems.push(data);
                        }
                      });
                      result.forEach((data) => {
                        if (!retouchItems.find((v) => v.retouchProductNameId === data.retouchProductNameId)) {
                          retouchItems.push(data);
                        }
                      });
                      dispatch(xmlActions.retouch({ kijshopCd, shopOrderId }).create({
                        parentRetouchItem: {
                          itemId: retouchInfo?.[0].itemId || 'jpit300242',
                          productName: retouchInfo?.[0].productName || 'レタッチ',
                          productNameId: retouchInfo?.[0].productNameId || 'jptg300179',
                          productShortName: retouchInfo?.[0].productShortName || 'ﾚﾀｯﾁ',
                          productTypeId: retouchInfo?.[0].productTypeId || 'jptg300180',
                        },
                        retouchItems,
                        infoOutputCount: '1',  // 数量
                        onSuccess: () => {
                          callbackSave(false);
                          setSaving(false);
                          dispatch(push(RoutingPath.cnv.ordersPreparation({ kijshopCd, shopOrderId })));
                        },
                        startDate,
                      }));
                      // }
                    });
                  }
                },
              },
            ],
          }));
        },
        no:() => {
          dispatch(orderLockActions.setId(''));
          dispatch(push(RoutingPath.cnv.orders({ kijshopCd })));
        },
      }));
  }, [retouchInfo, imageCheckList, xml, kijshopCd, shopOrderId, dispatch, imageCount]);

  return (
    <div className="retouch_footer">
      {/* <div className="retouch_footer__info"> */}
        {/* <div>
          <div>【商品名】{brandName}</div>
          <div>【テンプレートグループ】{templateGroup}</div>
          <div>【名前】{name}</div>
        </div>
        <div>
          <div>【日付】{date}</div>
          <div>【場所】{place}</div>
          <div>【ページ】{page}</div>
          <div>【注文番号】{orderNum}</div>
        </div>
      </div>
      <div className="retouch_footer__size">
        <span>表示サイズ</span>
        <Button
          onClick={handlerClickZoomIn}
          size="sm"
          color="dark"
          label="＋"
        />
        <Button
          onClick={handlerClickZoomOut}
          size="sm"
          color="dark"
          label="－"
        />
      </div> */}
      <div className="retouch_footer__operation">
        <Button
          label="レタッチ完了"
          onClick={handlerClickComplete}
          size="sm"
        />
      </div>
      {saving ? (<LoadingPopup label="データを保存中です..." />) : (<></>)}
    </div>
  );
};
