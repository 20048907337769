import { ApiBaseFront } from '../api-base-front';

export class ApiDeleteXml extends ApiBaseFront {
  constructor(kijShopCd: string, shopOrderId: string, fileName: string[]) {
    super(
      'POST',
      `/api/v1/orderxml/${kijShopCd}`,
      'JSON',
      {
        path: fileName.map((v) => `${kijShopCd}/${shopOrderId}/${v}`),
      },
    );
  }
}
