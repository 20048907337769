import { XmlParser } from '../../../manager/xml-parser';
import { Env } from '../../../models/env';
import { ILayoutEditorManagerBase } from '../layout-editor.manager';
import { ZipManager } from '../zip/zip.manager';
import { store } from '../../../app/store';
import { LayoutTemplateFolderData } from '../../../slices/layout-slice';

export class TemplateLoaderManager implements ILayoutEditorManagerBase {

  private static _ins: TemplateLoaderManager;
  // private zipManager!: ZipManager;

  private urlBase = `${Env.api.back.protocol}://${Env.api.back.host}/files/template`;

  private constructor() {
  }

  static get ins() {
    if (!this._ins) {
      this._ins = new TemplateLoaderManager();
    }
    return this._ins;
  }

  initialize(): void {
  }

  destroy(): void {
  }

  di(
    // zipManager: ZipManager,
  ): void {
    // this.zipManager = zipManager;
  }

  private getDefaultTemplatesXml(url: string) {
    const xmlParser = new XmlParser();
    return fetch(url + '/templates.xml', { mode: 'cors' })
      .then(async (v) => {
        const textData = await v.text();
        const parsedXml: any = await xmlParser.parse(textData);
        return parsedXml.lnwMasterTemplate.templateGroup[0].templateGroupData[0].templateGroup[0].templateGroupData.map((v: any) => v);
      });
  }

  private async getTemplateXml(url: string, templateName: string, isChange?: boolean) {
    const xmlParser = new XmlParser();
    const result = await this.getTemplateZip(url, 0, templateName, isChange);
    if (result?.template) return result;
    return fetch(url + '/template.xml', { mode: 'cors' })
      .then(async (v) => {
        if (v.ok) {
          const textData = await v.text();
          const parsedXml = await xmlParser.parse(String(textData));
          return {
            template: parsedXml,
            isChange: !!isChange,
          };
        } else {
          return {
            template: undefined,
            isChange: !!isChange,
          };
        }
      });
  }

  static checkNextPath(path: string, type: string): Promise<{ path: string, isChange: boolean }> {
    // - 対象となるテンプレート一覧 -
    const alertList = store.getState().layout.alertPathList;
    const masterTempList = store.getState().layout.folderList;
    let checkedAlertList = alertList.find(v => v.pageType === type);
    const getList = (): LayoutTemplateFolderData[] => {
      const list: LayoutTemplateFolderData[][] = [];
      masterTempList.forEach((v) => {
        if (v.children.length) {
          v.children.forEach(v2 => {
            if (v2.children.length) {
              list.push(v2.children);
            }
          })
        }
      })
      return list.flat();
    }
    const tempList = type ? getList().filter(v => v.pageType === type) : getList();
    if (!checkedAlertList) {
      checkedAlertList = { pageType: '', pathData: [] };
      for (const alertPathDatum of alertList) {
        checkedAlertList.pathData.push(...alertPathDatum.pathData);
      }
    }

    // - 特殊なパス判定( ___ : 背幅のある表紙 _-_ : 台紙 ) -
    const isCover = Boolean(path.indexOf('___') > 0);
    const isAdditional = Boolean(path.indexOf('_-_') > 0);
    // - テンプレートに変更があったかの確認 -
    const check = (str: string[], joinWord: string) => {
      let result;
      let isChange = false;
      const prevTempList = tempList.filter(v => {
        return str[0].indexOf(v.id) >= 0
      });
      if (checkedAlertList && checkedAlertList.pathData.length && str.length) {
        // - 一覧から特殊な部分を除いたパスで探索 -
        const targetList = checkedAlertList.pathData.filter(v => {
          // -- 現在のxml上のパスでマスタから取得 --
          const isPrevTemp = prevTempList.map(v => v.children).flat().find(v => v.id.indexOf(str[0]) >= 0);
          if (isPrevTemp) {
            return false;
          }
          if (v.indexOf(str[0]) >= 0) {
            return true;
          }
          // -- なかった際は一度以上更新されている可能性が高いので末尾の大文字アルファベットを剥奪して再度チェック --
          if (/[A-Z]$/.test(str[0])) {
            const baseStr = str[0].slice(0, -1)
            return v.indexOf(baseStr) >= 0;
          }
          // -- それでもない時はそんなテンプレートはこの世にもうない --
          return false;
        });
        // - 特殊なパスを追加探索 -
        const target = targetList.find(v => {
          const path = v.split(joinWord);
          if (path.length > 1 && str.length > 1) {
            return path[1].indexOf(str[1]) >= 0;
          } else {
            return undefined;
          }
        })
        if (target) {
          // -- 一覧から正式なパスを取得 --
          const i = checkedAlertList.pathData.indexOf(target);
          result = checkedAlertList.pathData[i];
          // -- 取得したパスに変更があったか比較 --
          if (result !== path) {
            isChange = true;
          }
        } else {
          result = str.join(joinWord);
        }
      } else {
        result = str.join(joinWord);
      }
      return Promise.resolve({ path: result, isChange });
    };
    let str;
    switch (true) {
      case isCover:
        str = path.split('___');
        return check(str, '___');
      case isAdditional:
        str = path.split('_-_');
        return check(str, '_-_');
      default:
        str = [path, ''];
        return check(str, '');
    }
    // console.log(str);
    // const endString = str[0][str[0].length - 1];
    // console.log(endString);
    // const next = String.fromCharCode(endString.charCodeAt(0) + 1);
    // console.log(next);
    // if (endString >= 'A' && endString <= 'Y') {
    //   str[0] = `${str[0].slice(0, -1)}${next}`;
    //   return Promise.resolve(str.join('___'));
    // }
    // if (endString === 'Z') {
    //   return Promise.resolve();
    // }
    // str[0] = `${path}A`;
    // return Promise.resolve(str.join('___'));
  }

  private getTemplateZip(_url: string, count: number, templateName: string, isChange?: boolean): Promise<any> {
    if (count > 2) {
      // this.checkNextPath(_url).then((v) => {
      //   if (!v) {
      return Promise.resolve();
      //   } else {
      //     return this.getTemplateXml(v, templateName, true);
      //   }
      // })
    }
    // return Promise.resolve();}
    const xmlUrlArr = _url.split('/');
    const url = count === 0
      ? _url
      : xmlUrlArr.filter((v, i) => i < xmlUrlArr.length - count).join('/');
    return fetch(url + '/template.zip', { mode: 'cors' })
      .then(async (vv) => {
        // return vv.blob();
        if (vv.ok) {
          const blob = await vv.blob();
          return this.getZipData(blob, templateName, count, isChange);
        } else {
          throw new Error();
        }
      })
      .catch(async () => {
        return this.getTemplateZip(_url, count + 1, templateName);
      });
  }

  private async getZipData(blob: Blob, templateName: string, count: number, isChange?: boolean) {
    let templateXml;
    let templatesXml;
    let thumbnail;
    const manager = new ZipManager();
    const xmlParser = new XmlParser();
    const templateData = await manager.unzipAndGetFile(blob, 'template.xml', 'text');
    if (templateData) {
      const parsed = await xmlParser.parse(String(templateData));
      templateXml = parsed;
    }
    const templatesData = await manager.unzipAndGetFile(blob, 'templates.xml', 'text');
    if (templatesData) {
      const parsedTemplatesxml: any = await xmlParser.parse(String(templatesData));
      const arr = parsedTemplatesxml.lnwMasterTemplate.templateGroup[0].templateGroupData[0].templateGroup[0].templateGroupData.map((v: any) => v);
      templatesXml = arr;
    }

    const tempNameArr = templateName.split('_');
    const typeName = tempNameArr[tempNameArr.length - 1].match(/^[A-Z]+/);
    const searchName = `${typeName?.[0]}/${templateName}`;
    const templateXmlData = await manager.unzipAndGetFile(blob, `${searchName}/template.xml`, 'text');
    if (templateXmlData) {
      const parsed = await xmlParser.parse(String(templateXmlData));
      templateXml = parsed;
    }
    const thumbnailBlob = await manager.unzipAndGetFile(blob, `${searchName}/thumbnail.jpg`, 'blob');
    if (thumbnailBlob) {
      thumbnail = thumbnailBlob;
    }

    return {
      blob: blob,
      templates: templatesXml,
      template: templateXml,
      thumbnail: thumbnail,
      zipLevel: { isZip: true, level: count },
      isChange: !!isChange,
    };
  }

  async onGetTargetFile(path: string, type: 'templates' | 'templateXml', templateName: string) {
    const url = `${this.urlBase}/${path.split('/').filter(v => v).join('/')}`;
    switch (type) {
      case 'templates':
        return this.getDefaultTemplatesXml(url);
      case 'templateXml':
        return this.getTemplateXml(url, templateName);
    }
  }

}
