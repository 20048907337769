import {
  WizardButton,
  WizardButtonList,
  WizardContent,
  WizardInput,
  WizardInputList,
} from '../../../ui/wizard/wizard-content';
import { push } from 'connected-react-router';
import { RoutingPath } from '../../../../routes/routing-path';
import { useDispatch } from 'react-redux';
import { useCallback, useState } from 'react';
import { apiActions } from '../../../../slices/api-slice';
import { ApiStaffPasswordReset } from '../../../../api/front/wizard/api-staff-password-reset';
import { dialogActions } from '../../../dialog/slice/dialog-slice';

type Props = {
  setStep: (step: number) => void;
}

type TooltipType = 'shopCd' | 'staffId' | 'none';

export const Step1StaffPasswordResetWizard = (props: Props) => {
  const { setStep } = props;
  // - Hooks -
  const dispatch = useDispatch();
  // - State -
  const [shopCd, setShopCd] = useState('');
  const [staffId, setStaffId] = useState('');
  const [isShopCdError, setIsShopCdError] = useState(false);
  const [isStaffIdError, setIsStaffIdError] = useState(false);
  const [showTooltip, setShowTooltip] = useState<TooltipType>('none');
  // - Handlers -
  // -- バリデーション --
  const handlerValidation = useCallback(() => {
    const isShopCdInvalid = shopCd === '' || shopCd.length !== 7;
    const isStaffIdInvalid = staffId === '' || staffId.length < 6;

    setIsShopCdError(isShopCdInvalid);
    setIsStaffIdError(isStaffIdInvalid);

    return !(isShopCdInvalid || isStaffIdInvalid);
  }, [shopCd, staffId]);

  // -- キャンセルボタン --
  const handlerClickCancel = useCallback(() => {
    dispatch(push(RoutingPath.staffLogin));
  }, []);
  const handlerChangeShopCd = useCallback((value: string) => {
    setShopCd(value);
  }, [shopCd]);
  const handlerToggleShowTooltip = useCallback((type: TooltipType) => {
    setShowTooltip(type);
  }, []);
  // -- メールを送信ボタン --
  const handlerClickSendMail = useCallback(() => {
    if (!handlerValidation()) {
      dispatch(dialogActions.pushMessage({
        title: '確認',
        message: ['入力内容に不備があります。'],
        buttons: [
          {
            label: 'OK',
            callback: () => {
              dispatch(dialogActions.pop());
            },
          },
        ],
      }));
      return;
    }
    dispatch(apiActions.run(
      new ApiStaffPasswordReset(
        {
          kijshopCd: shopCd,
          staffId: staffId,
        },
      ),
      {
        onSuccess: (res) => {
            if(res.error.errorCode === 404){
              dispatch(dialogActions.pushMessage({
                title: '確認',
                message: res.messages,
                buttons: [
                  {
                    label: 'OK',
                    callback: () => {
                      dispatch(dialogActions.pop());
                    },
                  },
                ],
              }));
              return;
            }
            setStep(2);
          },
          onBasicError: (res) => {
            dispatch(dialogActions.pushMessage({
              title: '確認',
              message: res.messages,
              buttons: [
                {
                  label: 'OK',
                  callback: () => {
                    dispatch(dialogActions.pop());
                  },
                },
              ],
            }));
          },
        },
      ));
    }, [shopCd,staffId]);
    return (
      <WizardContent>
        <WizardInputList>
          <WizardInput
            label="店舗コード"
            value={shopCd}
            disabled={false}
            isPassword={false}
            maxLength={7}
            error={isShopCdError}
            tooltip={isShopCdError && showTooltip === 'shopCd' ?
              { messages: ['半角数値7桁'] } : undefined}
            onChange={(e) => handlerChangeShopCd(e.target.value)}
            onMouseOver={() => handlerToggleShowTooltip('shopCd')}
            onMouseLeave={() => handlerToggleShowTooltip('none')}
          />
          <WizardInput
            label="スタッフID"
            value={staffId}
            disabled={false}
            isPassword={false}
            maxLength={50}
            error={isStaffIdError}
            tooltip={isStaffIdError && showTooltip === 'staffId' ?
              { messages: ['6桁以上'] } : undefined}
            onChange={(e) => setStaffId(e.target.value)}
            onMouseOver={() => handlerToggleShowTooltip('staffId')}
            onMouseLeave={() => handlerToggleShowTooltip('none')}
          />
        </WizardInputList>
        <WizardButtonList>
          <WizardButton
            label="キャンセル"
            onClick={handlerClickCancel}
          />
          <WizardButton
            label="メールを送信"
            onClick={handlerClickSendMail}
          />
        </WizardButtonList>
      </WizardContent>
    );
  }
;