import { ApiBaseFront } from '../api-base-front';
import { ApiBase } from '../../api-base';
import { ApiBaseBack } from '../../back/api-base-back';

export type ImagesPostRequest = {
  kijshopCd: string,
  shopOrderId: string,
  /*
   * 1: 完成画像
   * 2: サムネイル
   * 3: ラボ発注時点での使用画像
   * 4: ロゴ、テキスト
   * 5: png画像
   * 6: アップロード画像
   * */
  kind: string,
  filename: string,
  data: File,
  /* upload 画像用リクエスト */
  hash?: string,
  dataThumb?: string, // base64文字列
  exifCreated?: string,
  exifColorSpace?: string,
  exifMake?: string,
  exifModel?: string,
  exifOrientation?: number,
  width?: number,
  height?: number,
};

export type ImagesPostResponse = {
  orgFilename: string,
  filename: string,
};
export class ApiImagesPost extends ApiBaseFront<ImagesPostResponse> {
  constructor(param: ImagesPostRequest) {
    super(
      'POST',
      '/api/v1/images/upload',
      'JSON',
      param,
    );
  }
}

export type ImagesGetOneRequest = {
  kijshopCd: string,
  path: string,
};
// bodyに直接blobが入るパターンが対応できないのでこれだけ直接ApiBaseを参照
export class ApiImagesGetOne extends ApiBase {
  get header(): { [key: string]: string | number | undefined } {
    if (ApiBaseFront.session) {
      return {
        'X-Session-Id': ApiBaseBack.session,
        'X-M-Session-Id': ApiBaseFront.session,
      };
    }
    return {};
  }
  protected get hostname(): string {
    return ApiBaseFront.hostname || '';
  };
  protected get protocol(): string {
    return ApiBaseFront.protocol || '';
  }
  constructor(param: ImagesGetOneRequest) {
    super(
      'GET',
      '/api/v1/images/download',
      'BLOB',
      param,
    );
  }
  public createHeader() {
    const header = new Headers();
    const keys = Object.keys(this.header || {});
    const values = Object.values(this.header || {});
    for (let i = 0; i < keys.length; i += 1) {
      if (values[i] !== undefined && keys[i] !== 'Content-Type') {
        header.append(keys[i], values[i] as string);
      }
    }
    return header;
  }
}

export type ImagesDeleteRequest = {
  kijshopCd: string,
  shopOrderId: string,
  kind: string,
  filename: string,
};
export class ApiImagesDelete extends ApiBaseFront {
  constructor(param: ImagesDeleteRequest) {
    super(
      'DELETE',
      '/api/v1/images',
      'JSON',
      param,
    );
  }
}

export type ImagesGetRequest = {
  kijshopCd: string,
  shopOrderId: string,
  kind: string,
};
export type ImagesGetResponse = {
  // fileList: {
  orgFilename: string,
  filename: string,
  path: string,
  hash?: string,
  exifColorSpace: string,
  exifCreated: string,
  exifMake: string,
  exifModel: string,
  exifOrientation: number,
  height: number,
  pathThumb: string,
  width: number,
  id: string,
  selectId: string,
  cfImageId?: string | null,
  // }[],
};
export class ApiImagesGet extends ApiBaseFront<ImagesGetResponse[]> {
  constructor(param: ImagesGetRequest) {
    super(
      'GET',
      '/api/v1/images',
      'JSON',
      param,
    );
  }
}

export type ImagesCopyPostRequest = {
  kijshopCd: string,
  shopOrderId: string,
  srcKind: string,
  dstKind: string,
  srcFilename: string,
  dstFilename: string,
};
export class ApiImagesCopyPost extends ApiBaseFront {
  constructor(param: ImagesCopyPostRequest) {
    super(
      'POST',
      '/api/v1/images/copy',
      'JSON',
      param,
    );
  }
}

type ImagesUpdatePostRequest = {
  kijshopCd: string,
  shopOrderId: string,
  images: {
    id: string,
    selectId: string,
  }[],
};

export class ApiImagesUpdatePost extends ApiBaseFront {
  constructor(param: ImagesUpdatePostRequest) {
    super(
      'POST',
      '/api/v1/images/meta/update',
      'JSON',
      param,
    );
  }
}
