import {DetailedHTMLProps, InputHTMLAttributes, useRef, useState, useCallback} from 'react';
import {Button} from '../button/button';

type FileProps = {
  // - ファイル取得ハンドラ -
  getFiles: (file: FileList) => void,
} & DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

export const FileSelect = (props: FileProps) => {
  const {getFiles, ...defaultProps} = props;
  // - Ref -
  const fileRef = useRef<HTMLInputElement>(null);
  // - State -
  // -- ファイル --
  const [files, setFiles] = useState<FileList[]>([]);
  // - Callback -
  // -- 参照ボタン --
  const handleClick = useCallback(() => {
    fileRef.current?.click();
  }, []);
  // -- ファイル --
  const handleChange = useCallback((e) => {
    if (e.target.files) {
      setFiles([...files, e.target.files]);
      getFiles(e.target.files);
    }
  }, [files]);

  return (
    <>
    <input
      hidden
      type="file"
      ref={fileRef}
      onChange={handleChange}
    />
    <input
      {...defaultProps}
      readOnly
      value={files[files.length - 1]?.item(0)?.name || ""}
    />
    <Button
      label="参照"
      onClick={handleClick}
    />
    </>
  )
};
