import {ResponseCallback} from "../../api/response-callback";
import {AppThunk} from "../../app/store";
import {ApiOrders} from '../../api/back/orders/api-orders';
import {apiActions} from '../api-slice';
import {ApiCategories} from '../../api/back/create-order/categories/api-categories';

type WayToOrderRequest = {
  productNameId: string,
};

type LabOrderRequest = {
  shopOrderId: string,
  dataPath: string,
};

type PageTypeListRequest = {
  serviceId: string,
  productTagId: string,
  coverTypeId: string,
  coverColorId: string,
  productNameId: string,
  contentType: string,
};

type CoverListRequest = {
  serviceId: string,
  productTagId: string,
};

type FilterListRequest = {
  productNameId: string,
};

type BrandTagListRequest = {
  serviceId: string,
};

type ParentBoardInfoListRequest = {
  productNameId: string,
};

type ChildrenBoardInfoListRequest = {
  productNameId: string,
};

type BrandListRequest = {
  serviceId: string,
  productTagId: string,
  coverTypeId: string,
  coverColorId: string,
};

type DesignType1ListRequest = {
  productNameId: string,
  boardInfo: string,
};

type DesignType2ListRequest = {
  productNameId: string,
  hpsTpPathName: string,
  boardInfo: string,
};

type CoverRamiListRequest = {
  serviceId: string,
  productTagId: string,
  coverTypeId: string,
  coverColorId: string,
  productNameId: string,
  ItemId: string,
};

type GenuineRamiListRequest = {
  serviceId: string,
  productTagId: string,
  coverTypeId: string,
  coverColorId: string,
  productNameId: string,
  itemId: string,
};

type OptionRamiListRequest = {
  serviceId: string,
  productTagId: string,
  coverTypeId: string,
  coverColorId: string,
  boardInfoId: string,
  productNameId: string,
  orderMethodId: string,
  pageCountId: string,
  optionProductNameId: string,
  itemId: string,
};

type ParentLeafColorListRequest = {
  productNameId: string,
  coverColorId: string,
};

type ChildrenLeafColorListRequest = {
  productNameId: string,
};

export const orderActions = {
  orderList: (
    response?: ResponseCallback,
  ): AppThunk => async (dispatch) => {
    dispatch(apiActions.run(new ApiOrders({}), {
      onSuccess: (res) => {
        // console.log('res: ', res);
        response?.onSuccess(res);
      },
      onBasicError: () => {},
    }));
  },
  wayToOrder: (
    request: WayToOrderRequest,
    response: ResponseCallback,
  ): AppThunk => async (dispatch) => {
    response.onSuccess();
  },
  labOrder: (
    request: LabOrderRequest,
    response: ResponseCallback,
  ): AppThunk => async (dispatch) => {
    response.onSuccess();
  },
  pageTypeList: (
    request: PageTypeListRequest,
    response: ResponseCallback,
  ): AppThunk => async (dispatch) => {
    response.onSuccess();
  },
  categoryList: (
    response:ResponseCallback,
  ): AppThunk => async (dispatch) => {
    response.onSuccess();
  },
  coverList: (
    request: CoverListRequest,
    response: ResponseCallback,
  ): AppThunk => async (dispatch) => {
    response.onSuccess();
  },
  filterList: (
    request: FilterListRequest,
    response: ResponseCallback,
  ): AppThunk => async (dispatch) => {
    response.onSuccess();
  },
  brandTagList: (
    request: BrandTagListRequest,
    response: ResponseCallback,
  ): AppThunk => async (dispatch) => {
    response.onSuccess();
  },
  parentBoardInfoList: (
    request: ParentBoardInfoListRequest,
    response: ResponseCallback,
  ): AppThunk => async (dispatch) => {
    response.onSuccess();
  },
  childrenBoardInfoList: (
    request: ChildrenBoardInfoListRequest,
    response: ResponseCallback,
  ): AppThunk => async (dispatch) => {
    response.onSuccess();
  },
  brandList: (
    request: BrandListRequest,
    response: ResponseCallback,
  ): AppThunk => async (dispatch) => {
    response.onSuccess();
  },
  designType1List: (
    request: DesignType1ListRequest,
    response: ResponseCallback,
  ): AppThunk => async (dispatch) => {
    response.onSuccess();
  },
  designType2List: (
    request: DesignType2ListRequest,
    response: ResponseCallback,
  ): AppThunk => async (dispatch) => {
    response.onSuccess();
  },
  coverRamiList: (
    request: CoverRamiListRequest,
    response:ResponseCallback,
  ): AppThunk => async (dispatch) => {
    response.onSuccess();
  },
  genuineRamiList: (
    request: GenuineRamiListRequest,
    response: ResponseCallback,
  ): AppThunk => async (dispatch) => {
    response.onSuccess();
  },
  optionRamiList: (
    request: OptionRamiListRequest,
    response: ResponseCallback,
  ): AppThunk => async (dispatch) => {
    response.onSuccess();
  },
  parentLeafColorList: (
    request: ParentLeafColorListRequest,
    response: ResponseCallback,
  ): AppThunk => async (dispatch) => {
    response.onSuccess();
  },
  childrenLeafColorList: (
    request: ChildrenLeafColorListRequest,
    response: ResponseCallback,
  ): AppThunk => async (dispatch) => {
    response.onSuccess();
  },
};
