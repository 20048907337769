import { XmlParser } from '../../manager/xml-parser';
import { throws } from 'assert';
import { XmlClassName } from '../model/xml-class-name';
import { TXmlRootTagModel, xmlRootTagModel } from '../model/xml-root-tag-model';

type TypeIndexes = {
  indexes?: (number | string)[],
}
type TypeName = {
  uniqueName: string,
  parentName: string,
};
type Props = {
  version: string,
} & (TypeIndexes);

export abstract class XmlClass<TXmlParseModel, TView = any, TMeta = any, TIndexes extends string[] = string[]> {

  // 注文番号
  shopOrderId: string;
  // ファイル末尾のindex
  _indexes!: TIndexes;
  set indexes(v: TIndexes) {
    this._indexes = this.paddingIndex(v) as TIndexes;
  }
  get indexes(): TIndexes {
    return this._indexes;
  }
  // XML名（.xml まで含める）
  xmlUniqueName!: string;
  // 親XML名（.xml まで含める）
  xmlParentName!: string;
  // 取得、もしくは作成したXML文字列
  xml: string = '<xml />';
  // XML文字列をパースしたデータ
  xmlModel!: TXmlParseModel;
  // ルートタグのステータス
  rootTagModel: TXmlRootTagModel = xmlRootTagModel;

  // XMLごとに名称を設定し、FactoryでClass作成時に参照する
  abstract name: XmlClassName;
  // フロントで扱うデータ
  abstract viewModel: TView;
  // 画面には出ないがXML作成には必要になるデータ
  abstract metaModel: TMeta;

  /**
   * 初期化処理実行
   * @param shopOrderId
   * @param data
   */
  constructor(shopOrderId: string, props: Props) {
    this.shopOrderId = shopOrderId;
    this.rootTagModel.systemVersion = props.version;
    this.rootTagModel.uploadVersion = props.version;
    if ((props as TypeIndexes).indexes) {
      const indexes = (props as TypeIndexes).indexes;
      if (indexes) {
        this._indexes = this.paddingIndex(indexes) as TIndexes;
      }
    }
    // if ((props as TypeName).uniqueName) {
    //   this.xmlUniqueName = (props as TypeName).uniqueName;
    //   this.xmlParentName = (props as TypeName).parentName;
    // }
    this.init();
  }

  /**
   * 命名
   * rootTagModel 設定
   * 最低限の構成からなる xml を生成
   * @protected
   */
  protected abstract init(): void;
  /**
   * viewModel と metaModel (rootTagModel) から xmlModel を作成する
   * @param v viewModel
   * @param m metaModel
   * @protected
   */
  protected abstract docking(v: TView, m: TMeta): void;
  /**
   * xmlModel から viewModel と metaModel (rootTagModel) を作成する
   * @param x xmlModel
   * @protected
   */
  protected abstract split(x: TXmlParseModel): void;

  /**
   * xml をパースして xmlModel を生成し、viewModel, metaModel (rootTagModel) を作成する
   * データは非同期で代入されるので注意
   * @param xml {string} XML文字列
   */
  async parse(xml: string = this.xml) {

    return new XmlParser()
      .parse<TXmlParseModel>(xml)
      .then((res) => {
        this.xml = xml;
        this.xmlModel = res;
        this.split(res as any);
      })
      .catch((e) => throws(e));
  }

  /**
   * viewModel と metaModel (rootTagModel) から xmlModel を作成して xml を生成する
   * @param v viewModel
   * @param m metaModel
   */
  build(v: TView = this.viewModel, m: TMeta = this.metaModel) {
    this.docking(v, m);
    this.viewModel = v;
    this.metaModel = m;
    this.xml = new XmlParser().build(this.cloneIgnoreUndefined(this.xmlModel)).replace(/&#xD;/g, '');
  }

  /**
   * ファイルID更新時の処理
   */
  changeIndexes(indexes: string[]) {}

  // ファイル名下3桁
  private paddingIndex(indexes: (string | number)[]): string[] {
    return [...indexes].map((index, i) => {
      if (String(index).length > 3 || index < 0) {
        console.error('index num error: ', i, ': ', index);
        return String(index);
      }
      if (Number(index) < 10) {
        return `00${Number(index)}`;
      }
      if (Number(index) < 100) {
        return `0${Number(index)}`;
      }
      return String(index);
    });
  }

  // ビルド用にオブジェクトから undefined を排除（null は残す）
  private cloneIgnoreUndefined(obj: any) {
    if (typeof obj !== 'object') {
      return obj;
    }
    return this.loop(obj);
  };
  // undefined 排除用ループ処理
  private loop(obj: any): any {
    if (Array.isArray(obj)) {
      return [...obj].map((v) => this.loop(v));
    } else if (obj && typeof obj === 'object') {
      const _obj: any = {};
      Object.keys(obj).forEach((key) => {
        if (obj[key] !== undefined) {
          _obj[key] = this.loop(obj[key]);
        }
      });
      return _obj;
    }
    return obj;
  };

}
