import { DesignType2Request, DesignType2Response } from '../../../../models/api/back/create-order/design-type2';
import {AppThunk} from "../../../../app/store";
import {ordersDataActions} from "../../../../slices/orders/orders-data-slice";
import {apiActions} from "../../../../slices/api-slice";
import {ResponseBase} from "../../../response-base";
import {ApiBaseBack} from "../../api-base-back";

export class ApiDesignType2 extends ApiBaseBack<DesignType2Response> {
  constructor(param: DesignType2Request) {
    super(
      'GET',
      '/api/v1/designType2',
      'JSON',
      param,
    );
  }
}

export const apiDesignType2Thunk = (param: DesignType2Request, callback?: () => void, isInit?: boolean): AppThunk => (dispatch,getState) => {
  if (!param.productNameId || !param.hpsTppathNamePath) {
    dispatch(ordersDataActions.updateItemDetail({
      designType2: { hpsTppath: [] },
    }));
    callback?.();
    return;
  }
  dispatch(apiActions.run(
    new ApiDesignType2(param),
    {
      onSuccess: (res: ResponseBase<DesignType2Response>) => {
        callback?.();
        if (!isInit && param.productNameId !== getState().order.itemDetail.itemSelect.value) {
          return;
        }
        if(!isInit && param.hpsTppathNamePath !== getState().order.itemDetail.designType1.value) {
          return;
        }
        dispatch(ordersDataActions.updateItemDetail({
          designType2: { hpsTppath: res.body.data?.hpsTppath || [] },
        }));
      },
      onError: () => {
        dispatch(ordersDataActions.updateItemDetail({
          designType2: { hpsTppath: [] },
        }));
        callback?.();
      },
    },
    {
      apiName: 'design-type2',
      ignoreSystemError: true,
    },
  ));
};
