import bgHowToOrder from '../../../assets/img/bg/how_to_order.svg';
import favorite from '../../../assets/img/helpBtn/favorite.png';
import titleCategory from '../../../assets/img/helpBtn/title-category.png';
import titleItem from '../../../assets/img/helpBtn/title-item.png';
import titleGenuine from '../../../assets/img/helpBtn/title-genuine.png';
import titleCover from '../../../assets/img/helpBtn/title-cover.png';
import titleOption from '../../../assets/img/helpBtn/title-option.png';
import titleDescription from '../../../assets/img/helpBtn/title-descriptionInfo.png';
import titleRemark from '../../../assets/img/helpBtn/title-remark.png';
import categoryCategory from '../../../assets/img/helpBtn/category-category.png';
import categoryTag from '../../../assets/img/helpBtn/category-tag.png';
import itemCover from '../../../assets/img/helpBtn/item-cover.png';
import itemCoverColor from '../../../assets/img/helpBtn/item-coverColor.png';
import itemItem from '../../../assets/img/helpBtn/item-item.png';
import itemBoard from '../../../assets/img/helpBtn/item-board.png';
import itemOrder from '../../../assets/img/helpBtn/item-order.png';
import itemDesign1 from '../../../assets/img/helpBtn/item-design1.png';
import itemDesign2 from '../../../assets/img/helpBtn/item-design2.png';
import itemQuantity from '../../../assets/img/helpBtn/item-quantity.png';
import descriptionName from '../../../assets/img/helpBtn/description-name.png';
import descriptionName2 from '../../../assets/img/helpBtn/description-name2.png';
import descriptionName3 from '../../../assets/img/helpBtn/description-name3.png';
import descriptionKana from '../../../assets/img/helpBtn/description-kana.png';
import descriptionDate from '../../../assets/img/helpBtn/description-date.png';
import descriptionDateFormat from '../../../assets/img/helpBtn/description-dateFormat.png';
import descriptionLocation from '../../../assets/img/helpBtn/description-location.png';
import descriptionComment from '../../../assets/img/helpBtn/description-comment.png';
import descriptionAddress from '../../../assets/img/helpBtn/description-address.png';
import descriptionPlace from '../../../assets/img/helpBtn/description-place.png';
import coverLaminate from '../../../assets/img/helpBtn/cover-laminate.png';
import coverColor from '../../../assets/img/helpBtn/cover-color.png';
import coverProcess from '../../../assets/img/helpBtn/cover-process.png';
import coverLeafText1 from '../../../assets/img/helpBtn/cover-leafText1.png';
import coverLeafText2 from '../../../assets/img/helpBtn/cover-leafText2.png';
import genuineLaminate from '../../../assets/img/helpBtn/genuine-laminate.png';
import genuineAdditional from '../../../assets/img/helpBtn/genuine-additional.png';
import genuineAdditional2 from '../../../assets/img/helpBtn/genuine-additional2.png';
import genuineTape from '../../../assets/img/helpBtn/genuine-tape.png';
import genuineChronology from '../../../assets/img/helpBtn/genuine-chronology.png';
import genuinePage from '../../../assets/img/helpBtn/genuine-page.png';
import optionTitle from '../../../assets/img/helpBtn/option-title.png';
import optionCover from '../../../assets/img/helpBtn/option-cover.png';
import optionDescription from '../../../assets/img/helpBtn/option-description.png';
import optionTag from '../../../assets/img/helpBtn/option-tag.png';
import optionItem from '../../../assets/img/helpBtn/option-item.png';
import optionQuantity from '../../../assets/img/helpBtn/option-quantity.png';
import optionLaminate from '../../../assets/img/helpBtn/option-laminate.png';
import optionPlace from '../../../assets/img/helpBtn/option-place.png';
import optionAdd from '../../../assets/img/helpBtn/option-add.png';

// TODO 画像の種類に合わせて増やす
export type ImgType = 'favorite'
  | 'title-category'
  | 'title-item'
  | 'title-genuine'
  | 'title-cover'
  | 'title-option'
  | 'title-descriptionInfo'
  | 'title-remark'
  | 'category-category'
  | 'category-tag'
  | 'item-cover'
  | 'item-coverColor'
  | 'item-item'
  | 'item-board'
  | 'item-order'
  | 'item-design1'
  | 'item-design2'
  | 'item-quantity'
  | 'description-name'
  | 'description-name2'
  | 'description-name3'
  | 'description-kana'
  | 'description-date'
  | 'description-dateFormat'
  | 'description-location'
  | 'description-comment'
  | 'description-address'
  | 'description-place'
  | 'cover-laminate'
  | 'cover-color'
  | 'cover-process'
  | 'cover-leafText1'
  | 'cover-leafText2'
  | 'genuine-laminate'
  | 'genuine-additional'
  | 'genuine-additional2'
  | 'genuine-tape'
  | 'genuine-chronology'
  | 'genuine-page'
  | 'option-title'
  | 'option-cover'
  | 'option-description'
  | 'option-tag'
  | 'option-item'
  | 'option-quantity'
  | 'option-laminate'
  | 'option-place'
  | 'option-add';

type HowToOrderProps = {
  imgType: ImgType,
}

export const HowToOrder = (props: HowToOrderProps) => {
  const { imgType } = props;
  const img = () => {
    let src = '';
    switch (imgType) {
      case 'favorite':
        src = favorite;
        break;
      case 'title-category':
        src = titleCategory;
        break;
      case 'title-item':
        src = titleItem;
        break;
      case 'title-genuine':
        src = titleGenuine;
        break;
      case 'title-cover':
        src = titleCover;
        break;
      case 'title-option':
        src = titleOption;
        break;
      case 'title-descriptionInfo':
        src = titleDescription;
        break;
      case 'title-remark':
        src = titleRemark;
        break;
      case 'category-category':
        src = categoryCategory;
        break;
      case 'category-tag':
        src = categoryTag;
        break;
      case 'item-cover':
        src = itemCover;
        break;
      case 'item-coverColor':
        src = itemCoverColor;
        break;
      case 'item-item':
        src = itemItem;
        break;
      case 'item-board':
        src = itemBoard;
        break;
      case 'item-order':
        src = itemOrder;
        break;
      case 'item-design1':
        src = itemDesign1;
        break;
      case 'item-design2':
        src = itemDesign2;
        break;
      case 'item-quantity':
        src = itemQuantity;
        break;
      case 'description-name':
        src = descriptionName;
        break;
      case 'description-name2':
        src = descriptionName2;
        break;
      case 'description-name3':
        src = descriptionName3;
        break;
      case 'description-kana':
        src = descriptionKana;
        break;
      case 'description-date':
        src = descriptionDate;
        break;
      case 'description-dateFormat':
        src = descriptionDateFormat;
        break;
      case 'description-location':
        src = descriptionLocation;
        break;
      case 'description-comment':
        src = descriptionComment;
        break;
      case 'description-address':
        src = descriptionAddress;
        break;
      case 'description-place':
        src = descriptionPlace;
        break;
      case 'cover-laminate':
        src = coverLaminate;
        break;
      case 'cover-color':
        src = coverColor;
        break;
      case 'cover-process':
        src = coverProcess;
        break;
      case 'cover-leafText1':
        src = coverLeafText1;
        break;
      case 'cover-leafText2':
        src = coverLeafText2;
        break;
      case 'genuine-laminate':
        src = genuineLaminate;
        break;
      case 'genuine-additional':
        src = genuineAdditional;
        break;
      case 'genuine-additional2':
        src = genuineAdditional2;
        break;
      case 'genuine-tape':
        src = genuineTape;
        break;
      case 'genuine-chronology':
        src = genuineChronology;
        break;
      case 'genuine-page':
        src = genuinePage;
        break;
      case 'option-title':
        src = optionTitle;
        break;
      case 'option-cover':
        src = optionCover;
        break;
      case 'option-description':
        src = optionDescription;
        break;
      case 'option-tag':
        src = optionTag;
        break;
      case 'option-item':
        src = optionItem;
        break;
      case 'option-quantity':
        src = optionQuantity;
        break;
      case 'option-laminate':
        src = optionLaminate;
        break;
      case 'option-place':
        src = optionPlace;
        break;
      case 'option-add':
        src = optionAdd;
        break;
    }
    return <img
      src={src}
      alt=""
    />;
  };

  return (
    <div className="dialog_contents">
      <div className="dialog_contents__body">
        {img()}
      </div>
    </div>
  );
};
