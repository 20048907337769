import { EditableImage } from '../editable-image';
import { AlbumBase, AlbumManager, AlbumPage } from '../../../albam/albam';
import { EditableImageManager } from '../../editable-image/editable-image.manager';
import { PanZoomManager } from '../../pan-zoom/pan-zoom.manager';
import { FrameController } from '../frame/frame-controller';
import { ImageEvent } from './image-event';
import { ImageEditableTool } from './image-editable-tool';
import * as lodash from 'lodash';
import { LayoutDataManager } from '../../data/layout-data.manager';
import * as createjs from 'createjs-module';
import { CjBitmap, CjContainer, CjShape } from '../../../model/cj-factory';
import { CjTool } from '../../../../utilities/cj-tool';
import { CJ_CONTAINER_NAME, CJ_DPOBJ_NAME } from '../../../model/cj-factory/cj-obj-name.collection';
import { UiManager } from '../../ui/ui.manager';
import { EditableEvent } from '../editable-event';
import { ImageEditManager } from '../image-edit.manager';
import { store } from '../../../../app/store';
import { apiActions } from '../../../../slices/api-slice';
import { ApiImagesGetOne } from '../../../../api/front/images/api-images';
import { OrderPageDataXml } from '../../../../xml/class/order/order-page-data-xml';
import { EditableSideTool } from '../editable-side-tool';
import { FitChecker } from '../../../../utilities/fit-checker';
import { cloneDeep, difference, isEqual } from 'lodash';
import { XmlStructureOrderInfoData } from '../../../../xml/model/xml-structure-model';
import {
  POSTCARD_ID,
  PostCardType,
  RIGHT_ROTATE_LIST,
} from '../../../../components/pages/layout/select-template/layout.select-template';

export const initPictureFlags = {
  isRotate: false,
  isRestore: false,
  isClone: false,
  isPostCard: false,
  isUpdate: false,
  isExchange: false,
  isExchangeOne: false,
  isDirectTemp: false,
  postCardType: undefined,
  dropTemp: false,
  isChangeCover: false,
};

export type PictureFlagParams = {
  isRotate: boolean,
  isRestore: boolean,
  isClone: boolean,
  isUpdate: boolean,
  isExchange: boolean,
  isExchangeOne: boolean,
  isDirectTemp: boolean,
  postCardType: PostCardType,
  dropTemp: boolean,
  isChangeCover: boolean,
}

export class ImageController {
  public fitNum = 5;
  public imageSetMode: 'in' | 'out' = 'out';
  public images: EditableImage[] = [];
  public orderPage?: OrderPageDataXml;
  public orderInfo?: XmlStructureOrderInfoData;
  public static saveBlob: { blob: Blob, url: string } | null = null;

  private stage: CjContainer;
  private readonly pageId: string;

  private albumManager!: AlbumManager;
  private editableImageManager!: EditableImageManager;
  private panZoomManager!: PanZoomManager;
  private frameController!: FrameController;
  private imageEvent!: ImageEvent;
  private imageEditableTool!: ImageEditableTool;
  private layoutDataManager!: LayoutDataManager;
  private editableEvent!: EditableEvent;
  private editableSideTool!: EditableSideTool;
  private imageEditManager!: ImageEditManager;

  constructor(stage: CjContainer, pageId: string) {
    this.stage = stage;
    this.pageId = pageId;
  }

  initialize(): void {
  }

  destroy(): void {
    this.images = [];
  }

  update(stage: CjContainer): void {
    this.stage = stage;
  }

  di(
    frameController: FrameController,
    albumMng: AlbumManager,
    editableMng: EditableImageManager,
    panZoomMng: PanZoomManager,
    imageEvent: ImageEvent,
    imageEditableTool: ImageEditableTool,
    layoutDataManager: LayoutDataManager,
    editableEvent: EditableEvent,
    editableSideTool: EditableSideTool,
    imageEditMng: ImageEditManager,
  ): void {
    this.frameController = frameController;
    this.albumManager = albumMng;
    this.editableImageManager = editableMng;
    this.panZoomManager = panZoomMng;
    this.imageEvent = imageEvent;
    this.imageEditableTool = imageEditableTool;
    this.layoutDataManager = layoutDataManager;
    this.editableEvent = editableEvent;
    this.editableSideTool = editableSideTool;
    this.imageEditManager = imageEditMng;
  }

  // Imageの作成
  static async createImageAsync(src: string, targetFile: string, name: string, zip: { isZip: boolean, level: number } | undefined, _blob?: Blob, isExChange?: boolean): Promise<CjContainer> {
    return new Promise((resolve) => {
      if (!zip?.isZip) {
        fetch(
          `${src}/${targetFile}`,
          { method: 'GET', mode: 'cors' },
        ).then((res) => {
          res.blob().then((v) => {
            const url = window.URL.createObjectURL(v);
            const image = new CjBitmap(url);
            setTimeout(() => {
              URL.revokeObjectURL(url);
            });
            image.name = name;
            const imageContainer = new CjContainer();
            imageContainer.name = `${name}-container`;
            imageContainer.addChild(image);
            resolve(imageContainer);
          });
        });
      } else {
        let srcPath = src;
        if (src.indexOf('___') !== -1 && zip.level > 0) {
          srcPath = src.substring(0, src.indexOf('___'));
        }
        const xmlUrlArr = srcPath.split('/');
        const urlPath = zip.level === 0
          ? srcPath
          : zip.level === 1
            ? xmlUrlArr.filter((v, i) => i < xmlUrlArr.length - 1).join('/')
            : xmlUrlArr.filter((v, i) => i < xmlUrlArr.length - 2).join('/');
        if (_blob && (!this.saveBlob || (this.saveBlob?.url !== `${urlPath + '/template.zip'}`)) && !isExChange) this.saveBlob = {
          url: `${urlPath + '/template.zip'}`,
          blob: _blob,
        };
        if (this.saveBlob && this.saveBlob?.url === `${urlPath + '/template.zip'}`) {
          const tempNameArr = xmlUrlArr[xmlUrlArr.length - 1].split('_');
          const typeName = tempNameArr[tempNameArr.length - 1].match(/^[A-Z]+/);
          const searchName = `${typeName?.[0]}/${xmlUrlArr[xmlUrlArr.length - 1]}`;
          UiManager.ins.emit('l->r:zip-file:get', {
            file: this.saveBlob.blob,
            path: zip.level === 0 ? targetFile : `${searchName}/${targetFile}`,
            callback: (vvv) => {
              const url = window.URL.createObjectURL(vvv.file);
              const image = new CjBitmap(url);
              setTimeout(() => {
                URL.revokeObjectURL(url);
              });
              image.name = name;
              const imageContainer = new CjContainer();
              imageContainer.name = `${name}-container`;
              imageContainer.addChild(image);
              resolve(imageContainer);
            },
            id: `${urlPath + '/template.zip'}`,
          });

        } else {
          fetch(urlPath + '/template.zip', { method: 'GET', mode: 'cors' })
            .then(async (v) => {
              const blob = await v.blob();
              this.saveBlob = {
                url: `${urlPath + '/template.zip'}`,
                blob: blob,
              };
              const tempNameArr = xmlUrlArr[xmlUrlArr.length - 1].split('_');
              const typeName = tempNameArr[tempNameArr.length - 1].match(/^[A-Z]+/);
              const searchName = `${typeName?.[0]}/${xmlUrlArr[xmlUrlArr.length - 1]}`;
              UiManager.ins.emit('l->r:zip-file:get', {
                file: blob,
                path: zip.level === 0 ? targetFile : `${searchName}/${targetFile}`,
                callback: (vvv) => {
                  const url = window.URL.createObjectURL(vvv.file);
                  const image = new CjBitmap(url);
                  setTimeout(() => {
                    URL.revokeObjectURL(url);
                  });
                  image.name = name;
                  const imageContainer = new CjContainer();
                  imageContainer.name = `${name}-container`;
                  imageContainer.addChild(image);
                  resolve(imageContainer);
                },
                id: `${urlPath + '/template.zip'}`,
              });
            });
        }
      }
    });
  }

  // -- 画像のマスクを再設定する(直接操作しない処理で利用(Redo・Undoなど)) --
  static updateImageView(image: CjContainer) {
    const target = CjTool.getImageContainer(image);
    target.filters = [];
    target.uncache();
    // const mask = CjTool.getMaskContainer(target);
    const _mask = CjTool.getMaskContainer(target);
    const mask = _mask.getChildByName('mask').clone();
    const maskData = _mask.getBounds();
    mask.set({ alpha: 0, x: 0, y: 0 });
    mask.cache(0, 0, maskData.width, maskData.height);
    target.filters = [new createjs.AlphaMaskFilter(mask.cacheCanvas as HTMLCanvasElement)];
    target.cache(0, 0, maskData.width, maskData.height);
  }

  // - 写真の生成 -
  async createPhotoImage(editableImage: EditableImage, target: CjContainer, flags: PictureFlagParams, exChangePrevImg?: CjContainer) {
    // async createPhotoImage(editableImage: EditableImage, target: CjContainer, isRotate?: boolean, isRestore?: boolean, isClone?: boolean, isPostCard?: boolean, isDaishi?: boolean, isExchange?: boolean, isDirectTemp?: boolean) {
    if (!editableImage || !target.photoAreaId) return;
    if (flags.isExchange) {
      const image = CjTool.getImageContainer(target);
      this.layoutDataManager.dispatch('removeImage', image);
    }
    // --- 同じ写真がないかチェック ---
    const useIDList = this.albumManager.getUsedEditableIDList();
    const isLogo = CjTool.getImageAreaContainer(target)?.logoFlag;
    if (useIDList.includes(editableImage.id) && !flags.isRestore && !flags.isClone && !flags.isExchange && !flags.isExchangeOne && !flags.isDirectTemp && !isLogo && !flags.isUpdate && !flags.dropTemp) {
      const isAdd = await this.duplicateWarningPhoto();
      if (!isAdd) {
        CjTool.getHighlight(target).visible = false;
        const containers: CjContainer[] = this.layoutDataManager.selector('getImageAreas', this.pageId);
        let count = 0;
        for (const con of containers) {
          const isPhoto = CjTool.checkPhotoInFrame(con);
          if (isPhoto) {
            count++;
          }
        }
        const album: AlbumPage = this.layoutDataManager.selector('getCurrentPage');
        UiManager.ins.emit('l->r:count-photo', { album, photoCount: count });
        return true;
      }
    }
    editableImage.photoAreaId = target.photoAreaId;

    const imageArea = CjTool.getImageAreaContainer(target);
    const scaleContainer = CjTool.getScaleContainer(target);
    const orderPicture = this.orderPage?.viewModel.orderPicture;
    const targetPic = orderPicture?.data?.find((pic) => {
      return (pic.selectID === editableImage.selectId && pic.subMaskID === editableImage.photoAreaId) || (pic.selectID === 'null' && pic.logoFileName?.realPath === editableImage.path);
    });
    const orderFrame = this.orderPage?.viewModel.orderFrame;
    // -- 参照切り --
    const photoImage = lodash.cloneDeep(editableImage.editable);
    // -- ハイライトを透明に戻す --
    const highlight = CjTool.getHighlight(target);
    if (highlight) highlight.visible = false;
    // -- マスクを作成 --
    const container = CjTool.getRotateContainer(target);
    // const prevRotate = cloneDeep(container.rotation);
    // container.rotation = container.isRotate ? 90 : 0;
    const maskContainer = CjTool.getMaskContainer(target);
    const mask = maskContainer.getChildByName('mask').clone();
    mask.set({ alpha: 0, x: 0, y: 0 });
    // -- コンテナ用意 --
    const imageContainer = new CjContainer();
    const photoContainer = new CjContainer();
    // -- RealRect設置 --
    if (targetPic?.pictureRect?.real?.height !== undefined && targetPic?.pictureRect?.real?.width !== undefined) {
      editableImage.realWidth = targetPic?.pictureRect?.real?.width;
      editableImage.realHeight = targetPic?.pictureRect?.real?.height;
    } else {
      const realRect = { realHeight: editableImage.realWidth, realWidth: editableImage.realHeight };
      if (!realRect) return;
    }
    imageContainer.addChild(photoContainer);
    photoContainer.addChild(photoImage);
    // -- 境界情報を取得 --
    const _frameData = await CjTool.checkLoad(maskContainer)
    const frameData = _frameData.getTransformedBounds();
    // const frameData = maskContainer.getTransformedBounds();
    // -- 写真が既にある場合、差し替える --
    const isPrevPhoto = CjTool.checkPhotoInFrame(target);
    const isPrev = isPrevPhoto;
    const prevPhotoImage = CjTool.getImageContainer(target);
    // -- 既に画像があった場合 --
    if (isPrevPhoto) {
      // --- 削除する ---
      const container = CjTool.getRotateContainer(target);
      const index = this.albumManager.currentAlbum?.editableIDList.findIndex((v) => v === prevPhotoImage.editableImageId);
      if (index !== undefined && index > -1) {
        this.albumManager.currentAlbum?.editableIDList.splice(index, 1);
      }
      container.removeChild(prevPhotoImage);
      // this.layoutDataManager.dispatch('removeImage', prevPhotoImage);
      if (!flags.isClone) {
        this.editableImageManager.toUnUse(prevPhotoImage.editableImageId ?? '');
      }
    } else if (flags.isExchangeOne && exChangePrevImg) {
      this.editableImageManager.toUnUse(exChangePrevImg.editableImageId ?? '');
      const index = this.albumManager.currentAlbum?.editableIDList.findIndex((v) => v === exChangePrevImg.editableImageId);
      if (index !== undefined && index > -1) this.albumManager.currentAlbum?.editableIDList.splice(index, 1);

    }
    // --- 対象の写真枠 ---
    // const containers: CjContainer[] = this.layoutDataManager.selector('getScaleContainers', this.pageId);
    // for (const _container of containers) {
    //   const image = CjTool.getImageContainer(_container);
    //   // ---- 画像一覧のチェックを外す ----
    //   if (image && image.editableImageId === editableImage.id && !isRestore) {

    // const container = CjTool.getRotateContainer(_container);
    // const mask = CjTool.getMaskContainer(_container);
    // mask.alpha = 1;
    // container.removeChild(image);
    // this.layoutDataManager.dispatch('removeImage', image);
    // try {
    //   this.editableImageManager.toUnUse(prevPhotoImage.editableImageId ?? '');
    // } catch {
    // }
    //   }
    // }

    this.albumManager.currentAlbum?.editableIDList.push(editableImage.id);
    // -- 追加した画像にチェック --
    this.editableImageManager.toUse(editableImage.id);
    // -- マスク情報 --
    const _maskData = await CjTool.checkLoad(mask)
    const maskData = _maskData.getBounds();
    // const maskData = mask.getBounds();
    const isPostCdRotate = ((Number(editableImage.realWidth) > Number(editableImage.realHeight) && maskData.width < maskData.height) || (Number(editableImage.realWidth) < Number(editableImage.realHeight) && maskData.width > maskData.height));
    let maskH;
    let maskW;
    if (flags.isRotate) {
      // --- 写真回転時 ---
      maskH = !container.isRotate ? maskData.height : maskData.width;
      maskW = !container.isRotate ? maskData.width : maskData.height;
    } else if (flags.postCardType) {
      maskH = isPostCdRotate ? maskData.width : maskData.height;
      maskW = isPostCdRotate ? maskData.height : maskData.width;
    } else {
      // --- 写真挿入時 ---
      maskH = container.isRotate ? maskData.width : maskData.height;
      maskW = container.isRotate ? maskData.height : maskData.width;
    }

    // -- リサイズ（写真フィット機能込み） --
    const scale = await this.resizeImage(imageArea, photoImage, maskH!, maskW!);
    const containerData = imageContainer.getTransformedBounds();

    // -- 画像情報 --
    const photoH = containerData.height;
    const photoW = containerData.width;
    const scaleY = scale;
    const scaleX = scale;
    // -- 回転対応用に変数用意 --
    let _maskH;
    let _maskW;
    // -- 写真を枠の中心に --
    if (flags.isRotate) {
      // --- 写真回転時 ---
      _maskH = container.isRotate ? maskW : maskH;
      _maskW = container.isRotate ? maskH : maskW;
    } else if (flags.postCardType) {
      _maskH = maskData.height;
      _maskW = maskData.width;
    } else {
      // --- 写真挿入時 ---
      _maskH = !container.isRotate ? maskH : maskW;
      _maskW = !container.isRotate ? maskW : maskH;
    }

    // const y = 0
    // const x = 0
    const y = await this.centeringImage(photoH, _maskH!, scaleY);
    const x = await this.centeringImage(photoW, _maskW!, scaleX);

    imageContainer.set({
      pageId: this.pageId,
      photoAreaId: editableImage.photoAreaId,
      editableImageId: editableImage.id,
      name: CJ_CONTAINER_NAME.image,
      x: maskContainer.x * 2,
      y: maskContainer.y * 2,
      regX: maskContainer.x,
      regY: maskContainer.y,
    });
    photoImage.set({
      // x: -x,
      // y: -y,
      name: CJ_DPOBJ_NAME.image,
    });

    const photoData = photoContainer.getTransformedBounds();
    photoContainer.set({
      pageId: this.pageId,
      photoAreaId: editableImage.photoAreaId,
      editableImageId: editableImage.id,
      copyFlag: false,
      deleteFlag: false,
      setMode: this.imageSetMode,
      real: {
        x: (imageArea.virtual?.x! - x) * (this.imageEditManager.templateReal.w / this.imageEditManager.templateVirtual.w),
        y: (imageArea.virtual?.y! - y) * (this.imageEditManager.templateReal.w / this.imageEditManager.templateVirtual.w),
        width: Number(editableImage.virtualWidth) * scale * (this.imageEditManager.templateReal.w / this.imageEditManager.templateVirtual.w),
        height: Number(editableImage.virtualHeight) * scale * (this.imageEditManager.templateReal.w / this.imageEditManager.templateVirtual.w),
      },
      virtual: {
        x: imageArea.virtual?.x! - x,
        y: imageArea.virtual?.y! - y,
        width: Number(editableImage.virtualWidth) * scale,
        height: Number(editableImage.virtualHeight) * scale,
      },
      selectImageSize: {
        width: editableImage.realWidth,
        height: editableImage.realHeight,
      },
      // 写真に連番？
      photoSeqNo: 0,
      // orderSelect.xmlから取得
      realPath: editableImage.path,
      selectID: editableImage.selectId,
      name: CJ_CONTAINER_NAME.photo,
      // x: - x + a/2,
      // y: - y + b/2,
      x: (photoData.width / 2) * scale - x,
      y: (photoData.height / 2) * scale - y,
      regX: (photoData.width / 2),
      regY: (photoData.height / 2),
      differPos: {
        x: targetPic ? (photoImage.image.width * scale) - Number(targetPic?.pictureRect?.virtual?.width) : 0,
        y: targetPic ? (photoImage.image.height * scale) - Number(targetPic?.pictureRect?.virtual?.height) : 0,
      },
    });
    photoContainer.originalBounds.width = photoData.width;
    photoContainer.originalBounds.height = photoData.height;
    photoContainer.originalBounds.x = cloneDeep(photoContainer.x);
    photoContainer.originalBounds.y = cloneDeep(photoContainer.y);
    photoContainer.defaultPos.x = cloneDeep(photoContainer.x);
    photoContainer.defaultPos.y = cloneDeep(photoContainer.y);
    if (flags.isClone) {
      imageContainer.defaultPos.x = cloneDeep(photoContainer.x);
      imageContainer.defaultPos.y = cloneDeep(photoContainer.y);
    }

    photoContainer.scaleY = scale;
    photoContainer.scaleX = scale;

    if (flags.postCardType) {
      const angle = flags.postCardType === 'left' ? 270 : 90;
      photoContainer.rotation = isPostCdRotate ? angle : 0;
      imageArea.originalRotate = isPostCdRotate ? angle : 0;
      imageArea.postCardType = isPostCdRotate ? flags.postCardType : undefined;
    } else {
      photoContainer.rotation = container.isRotate ? 270 : 0;
    }
    // -- チェッカーの生成 --
    const checker = new CjShape();
    checker.graphics.f('red').dr(0, 0, photoData.width, photoData.height);
    checker.set({
      name: CJ_DPOBJ_NAME.checker,
      alpha: 0,
      mouseEnabled: false,
    });
    photoContainer.addChild(checker);

    // -- 画像の追加 --
    this.imageEvent.setImageEvent(photoContainer, 'image');
    imageContainer.mouseEnabled = false;
    container.addChild(imageContainer);
    imageArea.isComposited = true;
    const containerLength = container.numChildren;
    imageArea && container.setChildIndex(imageArea, containerLength - 1);
    const photoFrames: [] = this.layoutDataManager.selector('getEditors', photoContainer.pageId);

    // -- ページ数変更に伴う背表紙変更時 --
    if (flags.isChangeCover) {
      const prevCoverList = this.layoutDataManager.selector('getPrevCover') as CjContainer[];
      if (prevCoverList && prevCoverList.length) {
        const defaultContainer = CjTool.getDefaultContainer(container);
        const target = prevCoverList.find(v => v.photoAreaId === defaultContainer.photoAreaId);
        if (target) {
          const targetPhoto = CjTool.getPhotoContainer(target);
          photoContainer.x = targetPhoto.x;
          photoContainer.y = targetPhoto.y;
          photoContainer.movePos = targetPhoto.movePos;
          photoContainer.movePos = targetPhoto.movePos;
          photoContainer.scaleX = targetPhoto.scaleX;
          photoContainer.scaleY = targetPhoto.scaleY;
          photoContainer.rotation = targetPhoto.rotation;
        }
      }
    }
    // -- 復元時の座標調整ロジック --
    if (flags.isRestore) {
      const categoryId = this.orderInfo?.xml.viewModel.category?.id;
      const itemId = this.orderInfo?.xml.viewModel.item?.id;
      // const isPostCard = (categoryId === 'jp0455' || categoryId === 'jp0443');
      const isPostCardCategory = POSTCARD_ID.category.some(v => v === categoryId);
      const isPostCardItem = POSTCARD_ID.item.some(v => v === itemId);
      const isRightRotateCategory = RIGHT_ROTATE_LIST.category.some(v => v === categoryId);
      const isRightRotateItem = RIGHT_ROTATE_LIST.item.some(v => v === itemId);
      const submaskRotate = () => {
        if (isPostCardCategory || isPostCardItem) {
          if ((Number(targetPic?.subMaskRotate ?? 0) - 90) >= 0) {
            imageArea.postCardType = (isRightRotateCategory || isRightRotateItem) ? 'right' : 'left';
            // imageArea.postCardType = categoryId === 'jp0455' ? 'left' : 'right';
            const angle = imageArea.postCardType === 'left' ? 90 : -90;
            return (Number(imageArea.originalRotate) + angle) % 360;
          }
        }
        return Number(targetPic?.subMaskRotate) ?? 0;
      };
      photoContainer.set({
        scaleX: Number(targetPic?.pictureRect?.virtual?.width) / Number(editableImage.virtualWidth),
        scaleY: Number(targetPic?.pictureRect?.virtual?.width) / Number(editableImage.virtualWidth),
        real: {
          x: Number(targetPic?.pictureRect?.real?.x),
          y: Number(targetPic?.pictureRect?.real?.y),
          width: Number(targetPic?.pictureRect?.real?.width),
          height: Number(targetPic?.pictureRect?.real?.height),
        },
        virtual: {
          x: Number(targetPic?.pictureRect?.virtual?.x) - (photoContainer.differPos!.x / 2),
          y: Number(targetPic?.pictureRect?.virtual?.y) - (photoContainer.differPos!.y / 2),
          width: Number(targetPic?.pictureRect?.virtual?.width),
          height: Number(targetPic?.pictureRect?.virtual?.height),
        },
        rotation: (Number(targetPic?.pictureRotate ?? 0) - submaskRotate()),
      });

      const isRotate = CjTool.getRotateContainer(photoContainer).isRotate;
      const movePos = {
        x:
          // ? Number(targetPic?.pictureRect?.virtual?.y) - CjTool.getOriginPos(photoContainer, 'photo')!.y
           Number(targetPic?.pictureRect?.virtual?.x) - CjTool.getOriginPos(photoContainer, 'photo')!.x,
        y:
          // ? Number(targetPic?.pictureRect?.virtual?.x) - CjTool.getOriginPos(photoContainer, 'photo')!.x
          Number(targetPic?.pictureRect?.virtual?.y) - CjTool.getOriginPos(photoContainer, 'photo')!.y,
      };
      const diffScaleX = (photoContainer.virtual.width - (photoContainer.virtual.width * scaleContainer.scaleX)) / 2;
      const diffScaleY = (photoContainer.virtual.height - (photoContainer.virtual.height * scaleContainer.scaleY)) / 2;
      const restorePos = this.albumManager.currentAlbum?.restorePicData?.find((v) => v.photoAreaId === photoContainer.photoAreaId);
      // if (restorePos) {
      //   photoContainer.x = restorePos.x;
      //   photoContainer.y = restorePos.y;
      //   photoContainer.isFit = restorePos.isFit;
      //   if (!restorePos.isFit) {
      //     checker.alpha = 0.2;
      //     this.imageEditManager.isFit = true;
      //     UiManager.ins.emit('l->r:show-toast', { isToast: true });
      //   }
      // } else {
        const getPhotoContainerOrigin = (data: {
          pictureRect?: { virtual?: { x?: string, y?: string, width?: string, height?: string } },
          subMaskRect?: { virtual?: { x?: string, y?: string, width?: string, height?: string } },
          rotate?: string,
          isCopy?: boolean,
          photoFrameRotate?: string,
        }): { x: number, y: number } => {
          const { pictureRect, subMaskRect, rotate, isCopy, photoFrameRotate } = data;
          const fanData = (data?: { virtual?: { width?: string, height?: string }}, _rotate?: string) => {
            const width = Number(data?.virtual?.width);
            const height = Number(data?.virtual?.height);
            const rotate = Number(_rotate || 0);
            /* 写真枠の中心から見た原点座標の角度 */
            const rotateOrigin = CjTool.math.rotateOrigin(width, height);
            /* 回転によって移動する原点座標のラジアン */
            const rad = 2 * Math.PI * (((rotate - rotateOrigin) - 90) / 360);
            /* 回転による円移動の半径 */
            const r = Math.sqrt(Math.pow(width / 2, 2) + Math.pow(height / 2, 2));
            return { width, height, r, rad };
          };
          /* 写真枠 */
          const frame = (() => {
            const { width, height, r, rad } = fanData(subMaskRect);
            return {
              x: r * CjTool.math.cos(rad) + width / 2,
              y: r * CjTool.math.sin(rad) + height / 2,
            };
          })();
          /* 回転前画像 */
          const photo = (() => {
            const { width, height, r, rad } = fanData(pictureRect);
            const subMaskData = {
              x: Number(subMaskRect?.virtual?.x) - (isCopy ? 30 : 0),
              y: Number(subMaskRect?.virtual?.y) - (isCopy ? 30 : 0),
              width: Number(subMaskRect?.virtual?.width),
              height: Number(subMaskRect?.virtual?.height),
            };
            return {
              x: -(r * CjTool.math.cos(rad) + (width - subMaskData.width) / 2 - subMaskData.x) - subMaskData.x,
              y: -(r * CjTool.math.sin(rad) + (height - subMaskData.height) / 2 - subMaskData.y) - subMaskData.y,
            };
          })();
          /* 画像 */
          const photoDefault = (() => {
            const { width, height, r, rad } = fanData(pictureRect, rotate);
            const subMaskData = {
              x: Number(subMaskRect?.virtual?.x) - (isCopy ? 30 : 0),
              y: Number(subMaskRect?.virtual?.y) - (isCopy ? 30 : 0),
              width: Number(subMaskRect?.virtual?.width),
              height: Number(subMaskRect?.virtual?.height),
            };
            return {
              x: r * CjTool.math.cos(rad) + width / 2 - (width - subMaskData.width) / 2 + subMaskData.x,
              y: r * CjTool.math.sin(rad) + height / 2 - (height - subMaskData.height) / 2 + subMaskData.y,
            };
          })();
          /* 移動座標 */
          const move = (() => {
            const _move = {
              x: Number(pictureRect?.virtual?.x) - photoDefault.x,
              y: Number(pictureRect?.virtual?.y) - photoDefault.y,
            };
            if (!photoFrameRotate) {
              return _move;
            }
            const rad = ((Number(photoFrameRotate || 0) % 90) / 180) * Math.PI;
            const sin = CjTool.math.sin(rad);
            const cos = CjTool.math.cos(rad);
            /* XML から出す移動座標 _move は水平, 垂直方向に対してで、算出したいのは画像の向きに対する移動座標なので
             * 求める x, y はそれぞれ
             * _move.x = x * cos - y * sin
             * _move.y = x * sin + y * cos
             * {
             *   x1: (_move.x + y * sin) / cos,
             *   x2: (_move.y - y * cos) / sin,
             *   y1: -(_move.x - x * cos) / sin,
             *   y2: (_move.y - x * sin) / cos,
             * }
             * となり、y1 = y2 で x について解いて
             * -(_move.x - x * cos) / sin = (_move.y - x * sin) / cos
             * x * cos * cos + x * sin * sin = _move.y * sin + _move.x * cos
             * x * (cos * cos + sin * sin) = _move.y * sin + _move.x * cos;
             * x = (_move.y * sin + _move.x * cos) / (cos * cos + sin * sin)
             * */
            const x = (_move.y * sin + _move.x * cos) / (cos * cos + sin * sin);
            const y = (_move.y - x * sin) / cos;
            return { x, y };
          })();
          return {
            x: photo.x - frame.x + move.x,
            y: photo.y - frame.y + move.y,
          };
        };
        const pos = getPhotoContainerOrigin({
          pictureRect: targetPic?.pictureRect,
          subMaskRect: targetPic?.subMaskRect,
          rotate: targetPic?.pictureRotate,
          isCopy: Boolean(Number(targetPic?.copyFlag)),
          photoFrameRotate: targetPic?.subMaskRotate,
        });
      photoContainer.x = pos.x;
      photoContainer.y = pos.y;
      const isToast = FitChecker.checkImageFit(photoContainer, photoFrames, this.stage);
        this.imageEditManager.isFit = isToast;
        UiManager.ins.emit('l->r:show-toast', { isToast, target });
      // }
      // photoContainer.movePos = movePos;
      photoContainer.originalBounds.x = cloneDeep(photoContainer.x);
      photoContainer.originalBounds.y = cloneDeep(photoContainer.y);
    } else {
      const isToast = FitChecker.checkImageFit(photoContainer, photoFrames, this.stage);
      this.imageEditManager.isFit = isToast;
      UiManager.ins.emit('l->r:show-toast', { isToast, target });
    }
    // -- マスクの反映処理 --
    maskContainer.alpha = 0;
    if (!imageArea.logoFlag) {
      mask.cache(0, 0, frameData.width, frameData.height);
      imageContainer.filters = [new createjs.AlphaMaskFilter(mask.cacheCanvas as HTMLCanvasElement)];
      imageContainer.cache(0, 0, frameData.width, frameData.height);
    }
    // if (!flags.isUpdate) {
    this.layoutDataManager.dispatch('setImageContainer', { image: imageContainer, pageId: this.pageId });
    // }
    if (imageArea.logoFlag) {
      editableImage.kind = '4';
      editableImage.flags.used = true;
      // store.dispatch(apiActions.run(
      //   new ApiImagesGet({
      //     kijshopCd: ImageEditManager.kijshopCd,
      //     shopOrderId: ImageEditManager.shopOrderId,
      //     kind: '4',
      //   }),
      //   {
      //     onSuccess: (res) => {
      //       const length = res.body.data.length;
      //       const index = padding(length + 1, 5);
      //       const fileName = `ER_PPM_${ImageEditManager.shopOrderId}_${index}.jpg`;
      //       photoContainer.realPath = fileName;
      //       // UiManager.ins.emit(
      //       //   'r->l:add-image-logo',
      //       //   {
      //       //     file,
      //       //     kijshopCd: ImageEditManager.kijshopCd,
      //       //     shopOrderId: ImageEditManager.shopOrderId,
      //       //     orderId: ImageEditManager.orderId || null,
      //       //     callback: (p: { editableImage: EditableImage }) => {
      //       //       p.editableImage.flags.used = true;
      //       //     },
      //       //   },
      //       // );
      //     },
      //   },
      // ));
    }
    if (!flags.isRotate && !flags.isExchange && !flags.isExchangeOne && !flags.isUpdate && !flags.isClone) {
      const containers: CjContainer[] = this.layoutDataManager.selector('getImageAreas', this.pageId);
      let count = 0;
      for (const con of containers) {
        const isPhoto = CjTool.checkPhotoInFrame(con);
        if (isPhoto) {
          count++;
        }
      }
      const album: AlbumPage = this.layoutDataManager.selector('getCurrentPage');
      UiManager.ins.emit('l->r:count-photo', { album, photoCount: count });
      if (!isPrev) {
        UiManager.ins.emit('l->r:change-use-image-num', { type: 'increment' });
      }
    }

  }

  // - 画像を中心に配置する -
  async centeringImage(imageSize: number, maskSize: number, imageScale: number) {
    if (!maskSize || imageSize * imageScale === maskSize) return 0;
    // return (imageSize - maskSize) / 2;
    return (imageSize * imageScale - maskSize) / 2;
  }

  // - 初期サイズの変更 -
  async resizeImage(imageArea: CjContainer, image: CjBitmap | CjContainer, minHeight: number, minWidth: number) {
    // -- フレームへの収まり具合で処理を分岐 --
    if (!minHeight || !minWidth) return 0;
    const imageData = image.getBounds();
    const fitNum = this.fitNum * 2;
    const scaleX = (minWidth + fitNum) * image.scaleX / imageData.width!;
    const scaleY = (minHeight + fitNum) * image.scaleY / imageData.height!;

    if (imageArea.logoFlag) {
      const resizeScale = scaleY < scaleX ? scaleY : scaleX;
      imageArea.initResizeScale = resizeScale;
      return resizeScale;
    }

    if (this.imageSetMode === 'in') {
      // 外接
      // image.scaleY = (scaleY < scaleX ? scaleY : scaleX);
      // image.scaleX = (scaleY < scaleX ? scaleY : scaleX);
      return (scaleY < scaleX ? scaleY : scaleX);
    } else {
      // 内接
      // image.scaleY = (scaleY > scaleX ? scaleY : scaleX);
      // image.scaleX = (scaleY > scaleX ? scaleY : scaleX);
      return (scaleY > scaleX ? scaleY : scaleX);
    }
  }

  async restore() {
    // if (this.albumManager.currentAlbum?.restoreMount) {
    //   const xml = store.getState().xml[this.imageEditManager.shopOrderId];
    //   const orderInfo = xml?.orderInfo?.infoData?.find((info) => info.xml.metaModel.id === this.imageEditManager.orderId);
    //   const orderParts = orderInfo?.parts?.partsData;
    //   const orderPages = orderParts?.map((elm) => elm.page?.pageData).flat();
    //   const currentAlbumPath = this.albumManager.currentAlbum?.indexes;
    //   const orderPage = orderPages?.find((page) => isEqual(page?._indexes, currentAlbumPath));
    //   this.orderPage = orderPage;
    // }
    await this.restorePhoto();
    await this.restorePNG();
    await this.restoreLogo();
    this.stage.stage.update();
  }

  async getImage(v: any) {
    return new Promise<any>((resolve) => {
      const path = `${this.imageEditManager.kijshopCd}/${this.imageEditManager.shopOrderId}/FreeGraphics/${v.selectFileName?.real.path}`;
      store.dispatch(apiActions.run(
        new ApiImagesGetOne({
          kijshopCd: this.imageEditManager.kijshopCd,
          path,
        }),
        {
          onSuccess: (blob: Blob) => {
            const name = v.originalFileName.real.path ?? '';
            const file = new File(
              [blob],
              name,
              {
                lastModified: new Date().getTime(),
                type: blob.type,
              },
            );
            resolve({ file, selectID: v.selectID ?? '', path, image: v });
          },
        },
      ));
    });
  };

  private async restorePNG() {
    return new Promise<void>(async (resolve) => {
      const freeGraphics = this.orderPage?.viewModel.freeGraphic?.data;
      if (!freeGraphics || !freeGraphics.length) {
        resolve();
        return;
      }
      const list = freeGraphics.filter((img) => this.editableImageManager.list.find((png) => (img.virtual?.path === png.path) && (img.real?.path === png.name)))
        .map((img) => {
          const find = this.editableImageManager.list.find((png) => (img.virtual?.path === png.path) && (img.real?.path === png.name));
          return {
            editable: find!,
            freeGraphic: img,
          };
        });
      if (!list.length) {
        resolve();
        return;
      }
      ;
      await Promise.all(list.map(async (img) => await this.editableSideTool.addImage(
        img.editable,
        img.editable.original,
        img.freeGraphic,
        true,
      )));
      const containers: CjContainer[] = this.layoutDataManager.selector('getImageAreas', this.pageId);
      let count = 0;
      for (const con of containers) {
        const isPhoto = CjTool.checkPhotoInFrame(con);
        if (isPhoto) {
          count++;
        }
      }
      const album: AlbumPage = this.layoutDataManager.selector('getCurrentPage');
      UiManager.ins.emit('l->r:count-photo', { album, photoCount: count });
      resolve();
    });
  }

  private async restoreLogo() {
    const orderPicture = this.orderPage?.viewModel.orderPicture?.data?.find((v) => v.logoFileName?.realPath);
    const editorContainers = this.layoutDataManager.selector('getEditors', this.pageId);
    const targetPic = this.editableImageManager.list.find((editableImage) => (editableImage.path === orderPicture?.logoFileName?.realPath));
    if (targetPic) {
      targetPic.photoAreaId = orderPicture?.subMaskID || '';
      const targetFrame = (editorContainers as CjContainer[]).find((frame) => frame.photoAreaId === targetPic?.photoAreaId);
      targetFrame && await this.createPhotoImage(targetPic, targetFrame, { ...initPictureFlags, isRestore: true });
      const containers: CjContainer[] = this.layoutDataManager.selector('getImageAreas', this.pageId);
      let count = 0;
      for (const con of containers) {
        const isPhoto = CjTool.checkPhotoInFrame(con);
        if (isPhoto) {
          count++;
        }
      }
      const album: AlbumPage = this.layoutDataManager.selector('getCurrentPage');
      UiManager.ins.emit('l->r:count-photo', { album, photoCount: count });
    }
  }

  private async restorePhoto() {
    const orderPicture = this.orderPage?.viewModel.orderPicture;
    if (orderPicture) {
      orderPicture.data = orderPicture.data?.filter((v) => v.deleteFlag !== '1');
    }
    const editorContainers = this.layoutDataManager.selector('getEditors', this.pageId);
    for (const picture of orderPicture?.data ?? []) {
      const targetPic = this.editableImageManager.list.filter(EditableImageManager.filterShopOrder(
        this.imageEditManager.kijshopCd,
        this.imageEditManager.shopOrderId,
      )).find((editableImage) => (editableImage.selectId === picture.selectID) && (editableImage.kind === '6'));
      if (targetPic) {
        targetPic.photoAreaId = picture.subMaskID || '';
        const targetFrame = (editorContainers as CjContainer[]).find((frame) => frame.photoAreaId === targetPic?.photoAreaId);
        if (targetFrame) {
          await this.createPhotoImage(targetPic, targetFrame, { ...initPictureFlags, isRestore: true });
        }
        const containers: CjContainer[] = this.layoutDataManager.selector('getImageAreas', this.pageId);
        let count = 0;
        for (const con of containers) {
          const isPhoto = CjTool.checkPhotoInFrame(con);
          if (isPhoto) {
            count++;
          }
        }
        const album: AlbumPage = this.layoutDataManager.selector('getCurrentPage');
        UiManager.ins.emit('l->r:count-photo', { album, photoCount: count });
      }
    }
  }

  private duplicateWarningPhoto = (): Promise<boolean> => new Promise((resolve) => {
    UiManager.ins.emit('l->r:push-dialog', {
      title: '確認',
      message: [
        '同一のファイルがすでに使用されています。',
        'このまま同一の画像ファイルを配置してもよい場合は「はい」、中止する場合は「いいえ」をクリックしてください。',
      ],
      buttons: [
        { label: 'いいえ', callback: () => resolve(false) },
        { label: 'はい', callback: () => resolve(true) },
      ],
    });
  });

  private async getRealRect(editableImage: EditableImage) {
    return new Promise<undefined | { realHeight?: string, realWidth?: string }>((resolve) => {
      this.editableImageManager.getRealSize(editableImage.id)
        .then((v) => {
          resolve(v);
        })
        .catch((e) => {
          UiManager.ins.emit('l->r:push-dialog', {
            title: '確認',
            message: [`${e}`],
            buttons: [
              {
                label: 'OK', callback: () => {
                },
              },
            ],
          });
          resolve(undefined);
        });
    });
  }
}
