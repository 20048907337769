import { CjContainer } from '../layout-editor/model/cj-factory';
import { CjTool } from './cj-tool';

type posType = {
  x: number,
  y: number,
  w: number,
  h: number,
}
/**
 * 写真フィットチェック機能
 * 実行時は画像のコンテナを渡す
 * デバッグしたいときはStageも一緒に渡す
 */
export class FitChecker {
  // -- 写真FIT機能 --
  static checkImageFit(target: CjContainer, photoFrames: CjContainer[], stage?: CjContainer) {
    // --- fitしなかった写真の配列 ---
    const nonFitImages: CjContainer[] = [];
    // --- 写真枠を全てチェック ---
    for (const photoFrame of photoFrames) {
      // ---- 写真がなければスキップ ----
      if (!CjTool.checkPhotoInFrame(photoFrame)) continue;
      if (CjTool.getImageAreaContainer(photoFrame)?.logoFlag) continue;
      // ---- corner: 枠の角 checker: 写真の範囲 photo: 写真のコンテナ ----
      const corners = CjTool.getDummyCorners(photoFrame);
      const checker = CjTool.getChecker(photoFrame);
      const photo = CjTool.getPhotoContainer(photoFrame);
      // ---- 判定用にフラグを立てる ----
      photo.isFit = true;
      // ---- 枠の角と写真で当たり判定による内包チェック ----
      for (const corner of corners) {
        const pos = corner.graphics.command as posType;
        const _point = corner.localToLocal(pos.x, pos.y, checker);
        const point = {..._point, x: Math.round(_point.x), y: Math.round(_point.y)}
        const isHit = checker.hitTest(point.x, point.y);
        // ----- 内包していない場合、即時終了 -----
        if (!isHit) {
          photo.isFit = false;
          break;
        }
      }
      // ---- checkerのON・OFF ----
      if (photo.isFit) {
        checker.alpha = 0;
      } else {
        checker.alpha = 0.2;
        // ----- Fitしなかった写真をプッシュ -----
        nonFitImages.push(photo);
      }
    }
    // --- トースト用のフラグを返す ---
    return Boolean(nonFitImages.length);
  }
}
