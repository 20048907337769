import { ApiBaseFront } from '../api-base-front';
import { RequestKijshopcdOnly } from '../../../models/api/front/staff-management';

export class ApiGetStaffNameList extends ApiBaseFront {
  constructor(param: RequestKijshopcdOnly) {
    super(
      'GET',
      '/api/v1/staff/name',
      'JSON',
      param,
    );
  };
}
