export interface RgbaData {
  colorCode: string;
  alpha: number;
}

// カラーコードをrgbに変換する関数
export class ColorCodeConverter {

  static colorCodeToRgb(colorCode: string): string {
    // 先頭に#が含まれている場合は除外
    if (colorCode.split('')[0] === '#') {
      colorCode = colorCode.substring(1);
    }

    // カラーコードが省略されている場合は6桁に戻す
    if (colorCode.length === 3) {
      const codeAry = colorCode.split('');
      colorCode = codeAry[0] + codeAry[0] + codeAry[1] + codeAry[1] + codeAry[2] + codeAry[2];
    }

    // カラーコードが6桁でない場合（強制的に黒にする）
    if (colorCode.length !== 6) {
      return 'rgb(0, 0, 0)';
    }
    const r = parseInt(colorCode.substring(0, 2), 16);
    const g = parseInt(colorCode.substring(2, 4), 16);
    const b = parseInt(colorCode.substring(4, 6), 16);
    return `rgba(${r}, ${g}, ${b})`;
  }

  // rgbをカラーコードに変換して返す
  static rgbToColorCode(rgb: string): string {
    const colorAry = rgb.match(/(?<=\().*?(?=\))/);
    const rgbaAry = colorAry![0].split(',');
    return '#' + rgbaAry.map(value => ('0' + Number(value).toString(16)).slice(-2)).join('');
  };

  // rgbaをカラーコードとalphaに変換しオブジェクトで返す
  static rgbaToColorCode(rgba: string): RgbaData {
    const colorAry = rgba.match(/(?<=\().*?(?=\))/);
    const rgbaAry = colorAry![0].split(',');
    const alpha = Number(rgbaAry.pop());
    const colorCode = '#' + rgbaAry.map(value => ('0' + Number(value).toString(16)).slice(-2)).join('');
    return { colorCode, alpha };
  };

  // カラーコードのゼロをサプレスする
  static suppressZero = (str: string) => {
    let i = 0;
    while (str.charAt(i) === '0') i++;
    return (str.slice(i) || '0');
  };

  // カラーコードにゼロをパディングする
  static paddingZero = (str: string) => {
    return str.padStart(6, '0')
  };
}