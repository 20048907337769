import {
  WizardButton,
  WizardButtonList,
  WizardContent,
  WizardInput,
  WizardInputList,
} from '../../../ui/wizard/wizard-content';
import { useParams } from 'react-router-dom';
import { PathParams, RoutingPath } from '../../../../routes/routing-path';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { useCallback, useState } from 'react';
import { apiActions } from '../../../../slices/api-slice';
import { dialogActions } from '../../../dialog/slice/dialog-slice';

type Props = {
  setStep: (step: number) => void;
};
export const Step1StaffMngMailChangeWizard = (props: Props) => {
  const { setStep } = props;
  // - Hooks -
  const { kijshopCd } = useParams<PathParams>();
  const dispatch = useDispatch();
  // - State -
  const [password, setPassword] = useState('');
  const [isPasswordError, setIsPasswordError] = useState(false);
  // - Handlers -
  const handlerValidation = useCallback(() => {
    // 大文字・小文字・数字 のうち２種類で7文字
    const regex1 = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[A-Za-z0-9]{7}$/;
    const regex2 = /^(?=.*[a-z])(?=.*[A-Z])[A-Za-z]{7}$/;
    const regex3 = /^(?=.*[a-z])(?=.*[0-9])[a-z0-9]{7}$/;
    const regex4 = /^(?=.*[A-Z])(?=.*[0-9])[A-Z0-9]{7}$/;
    const isPasswordValid = password.length === 7 && (regex1.test(password) || regex2.test(password) || regex3.test(password) || regex4.test(password));
    setIsPasswordError(!isPasswordValid);
    return isPasswordValid;
  },[password]);
  const handlerChangePassword = useCallback((value: string) => {
    setPassword(value);
  }, [password]);
  // -- キャンセルボタン --
  const handlerClickCancel = useCallback(() => {
    dispatch(push(RoutingPath.cnv.configSystem({ kijshopCd })));
  },[]);
  // -- 次へボタン --
  const handlerClickNext = useCallback(() => {
    if (!handlerValidation()) {
      dispatch(dialogActions.pushMessage({
        title: '確認',
        message: ['入力内容に不備があります。'],
        buttons: [
          {
            label: 'OK',
            callback: () => {
              dispatch(dialogActions.pop());
            },
          },
        ],
      }));
      return;
    }
    dispatch(apiActions.login({
      kijshopCd,
      password,
    }, {
      onSuccess: () => setStep(2),
      onBasicError: (error) => {
        setIsPasswordError(true);
        dispatch(dialogActions.pushMessage({
          title: '確認',
          message: error.messages,
          buttons: [
            {
              label: 'OK',
              callback: () => {
                dispatch(dialogActions.pop());
              },
            },
          ],
        }));
      },
    }));
  },[password]);
  return (
    <WizardContent>
      <WizardInputList>
        <WizardInput
          label="店舗コード"
          value={kijshopCd}
          disabled={true}
          isPassword={false}
          maxLength={7}
        />
        <WizardInput
          label="パスワード"
          value={password}
          disabled={false}
          isPassword={true}
          maxLength={7}
          error={isPasswordError}
          onChange={(e) => handlerChangePassword(e.target.value)}
        />
      </WizardInputList>
      <WizardButtonList>
        <WizardButton
          label="キャンセル"
          onClick={handlerClickCancel}
        />
        <WizardButton
          label="次へ"
          onClick={handlerClickNext}
        />
      </WizardButtonList>
    </WizardContent>
  );
};