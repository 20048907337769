import {ApiBase, HTTPMethod, ResponseType} from '../api-base';
import { ApiBaseBack } from '../back/api-base-back';
import { ResponseBase } from '../response-base';
// import { ApiBaseBack } from '../back/api-base-back';

// front api は命名にメソッドをつける
export class ApiBaseCloudFolder<TResponseBody = any> extends ApiBase<ResponseBase<TResponseBody>> {

  private static _session: string;
  static set session(value: string) {
    this._session = value;
  }
  static get session(): string {
    return this._session;
  }

  protected get hostname(): string {
    return ApiBaseCloudFolder.hostname || '';
  };

  protected get protocol(): string {
    return ApiBaseCloudFolder.protocol || '';
  }

  get header(): { [key: string]: string | number | undefined } {
    if (ApiBaseCloudFolder._session) {
      return {
        'X-Session-Id': ApiBaseBack.session,
        'X-M-Session-Id': ApiBaseCloudFolder._session,
      };
    }
    return {};
  }

  constructor(
    method: HTTPMethod,
    path: string,
    responseType: ResponseType,
    param: Object,
    header?: { [key: string]: string | number | undefined },
    hostname?: string,
    protocol?: string,
  ) {
    const keyValueArr = (obj: Object, parentKey?: string): { key: string, value: any }[] => {
      const arr: any[] = [];
      Object.keys(obj).forEach((key, i) => {
        const value = (obj as any)[key];
        if (key === '[]') {
          arr.push({ key: parentKey ? `${parentKey}[]` : key, value });
        } else if (typeof value === 'string' || typeof value === 'number' || !value) {
          arr.push({key: parentKey ? `${parentKey}[${key}]` : key, value});
        } else if (Array.isArray(value)) {
          const data = keyValueArr(value, `${ parentKey ? parentKey : key }`);
          arr.push(...[...data].map((v) => ({ key: v.key, value: v.value })));
        } else if (value instanceof File) {
          arr.push({ key: parentKey ? `${parentKey}[${key}]` : key, value });
        }  else {
          const data = keyValueArr(value, parentKey ? `${parentKey}[${key}]` : key);
          arr.push(...[...data].map((v) => ({ key: v.key, value: v.value })));
        }
      });
      return arr;
    };
    const object: any = {};
    const arr = keyValueArr(param);
    arr.forEach((v) => {
      object[v.key] = v.value;
    });
    super(
      method,
      path,
      responseType,
      object,
      header,
      hostname,
      protocol,
    );
  }

  public createHeader() {
    const header = new Headers();
    const keys = Object.keys(this.header || {});
    const values = Object.values(this.header || {});
    for (let i = 0; i < keys.length; i += 1) {
      if (values[i] !== undefined && keys[i] !== 'Content-Type') {
        header.append(keys[i], values[i] as string);
      }
    }
    return header;
  }

}

export class ApiBaseCloudFolderImageGetOne extends ApiBase {

  private static _session: string;
  static set session(value: string) {
    this._session = value;
  }
  static get session(): string {
    return this._session;
  }

  protected get hostname(): string {
    return ApiBaseCloudFolder.hostname || '';
  };

  protected get protocol(): string {
    return ApiBaseCloudFolder.protocol || '';
  }

  get header(): { [key: string]: string | number | undefined } {
    if (ApiBaseCloudFolder.session) {
      return {
        'X-Session-Id': ApiBaseBack.session,
        'X-M-Session-Id': ApiBaseCloudFolder.session,
      };
    }
    return {};
  }

  constructor(
    method: HTTPMethod,
    path: string,
    responseType: ResponseType,
    param: Object,
    header?: { [key: string]: string | number | undefined },
    hostname?: string,
    protocol?: string,
  ) {
    const keyValueArr = (obj: Object, parentKey?: string): { key: string, value: any }[] => {
      const arr: any[] = [];
      Object.keys(obj).forEach((key, i) => {
        const value = (obj as any)[key];
        if (key === '[]') {
          arr.push({ key: parentKey ? `${parentKey}[]` : key, value });
        } else if (typeof value === 'string' || typeof value === 'number' || !value) {
          arr.push({key: parentKey ? `${parentKey}[${key}]` : key, value});
        } else if (Array.isArray(value)) {
          const data = keyValueArr(value, `${ parentKey ? parentKey : key }`);
          arr.push(...[...data].map((v) => ({ key: v.key, value: v.value })));
        } else if (value instanceof File) {
          arr.push({ key: parentKey ? `${parentKey}[${key}]` : key, value });
        }  else {
          const data = keyValueArr(value, parentKey ? `${parentKey}[${key}]` : key);
          arr.push(...[...data].map((v) => ({ key: v.key, value: v.value })));
        }
      });
      return arr;
    };
    const object: any = {};
    const arr = keyValueArr(param);
    arr.forEach((v) => {
      object[v.key] = v.value;
    });
    super(
      method,
      path,
      responseType,
      object,
      header,
      hostname,
      protocol,
    );
  }

  public createHeader() {
    const header = new Headers();
    const keys = Object.keys(this.header || {});
    const values = Object.values(this.header || {});
    for (let i = 0; i < keys.length; i += 1) {
      if (values[i] !== undefined && keys[i] !== 'Content-Type') {
        header.append(keys[i], values[i] as string);
      }
    }
    return header;
  }

}
