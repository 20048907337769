import { useState } from 'react';
import { Wizard } from '../../../ui/wizard/wizard';
import { Step1StaffPasswordRegisterWizard } from './step1.staff-password-register.wizard';
import { Step2StaffPasswordRegisterWizard } from './step2.staff-password-register.wizard';

const TITLE = 'スタッフ パスワード登録';

export const StaffPasswordRegisterWizard = () => {
  // - State -
  const [step, setStep] = useState(1);
  return (
    <Wizard
      title={TITLE}
      step={step}
    >
      <Step1StaffPasswordRegisterWizard setStep={setStep} />
      <Step2StaffPasswordRegisterWizard />
    </Wizard>
  );
}