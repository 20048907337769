import { PanZoomManager } from './pan-zoom/pan-zoom.manager';
import { CommandManager } from './command/command.manager';
import { EditableImageManager } from './editable-image/editable-image.manager';
import {UiManager} from './ui/ui.manager';
import {ImageEditManager} from './image-edit/image-edit.manager';
import { KeyboardManager } from './keyboard/keyboard.manager';
import {AlbumManager} from '../albam/albam';
import { ImageProcessorManager } from './image-processor/image-processor.manager';
import { FontManager } from './font/font.manager';
import { ColorDropperManager } from './color-dropper/color-dropper.manager';
import { WebFontManager } from './web-font/web-font.manager';
import { RestoreEditableImageManager } from './restore-editable-image/restore-editable-image.manager';
import { LayoutDataManager } from './data/layout-data.manager';
import { PreviewManager } from './preview/preview.manager';
import { ZipManager } from './zip/zip.manager';
import { RulerManager } from './ruler/ruler.manager';
import { ValidationManager } from './validation/validation.manager';
import { AdditionalManager } from './additional/additional.manager';
import { TextImageManager } from './text-image/text-image.manager';

export interface ILayoutEditorManagerBase {
  initialize(): void;
  destroy(): void;
  di(...manager: ILayoutEditorManagerBase[]): void;
}

export class LayoutEditorManager implements ILayoutEditorManagerBase {

  // container: HTMLDivElement;

  private manager: {
    album: AlbumManager,
    // colorDropper: ColorDropperManager,
    // command: CommandManager,
    editableImage: EditableImageManager,
    font: FontManager,
    // imageEdit: ImageEditManager,
    imageProcessor: ImageProcessorManager,
    keyboard: KeyboardManager,
    layoutData: LayoutDataManager,
    panZoom: PanZoomManager,
    preview: PreviewManager,
    restoreEditableImage: RestoreEditableImageManager,
    // ruler: RulerManager,
    ui: UiManager,
    webFont: WebFontManager,
    zip: ZipManager,
    valid: ValidationManager,
    additional: AdditionalManager,
    text: TextImageManager,
  };

  constructor() {
    // layout.edit-template.tsx
    // this.container = container;
    this.manager = {
      album: new AlbumManager(),
      // colorDropper: new ColorDropperManager(),
      // command: new CommandManager(),
      editableImage: EditableImageManager.ins,
      font: FontManager.ins,
      // imageEdit: new ImageEditManager(container),
      imageProcessor: ImageProcessorManager.ins,
      keyboard: new KeyboardManager(),
      layoutData: new LayoutDataManager(),
      panZoom: new PanZoomManager(),
      preview: new PreviewManager(),
      restoreEditableImage: RestoreEditableImageManager.ins,
      // ruler: new RulerManager(),
      ui: UiManager.ins,
      webFont: new WebFontManager(),
      zip: new ZipManager(),
      valid: new ValidationManager(),
      additional: new AdditionalManager(),
      text: new TextImageManager(),
    };
    // - di -
    this.manager.album.di(this.manager.ui, this.manager.editableImage, this.manager.panZoom, this.manager.layoutData, this.manager.preview, this.manager.additional);
    // this.manager.colorDropper.di(this.manager.imageEdit, this.manager.ui);
    // this.manager.command.di();
    this.manager.editableImage.di();
    this.manager.font.di(this.manager.ui);
    // this.manager.imageEdit.di(this.manager.ui, this.manager.command, this.manager.album, this.manager.editableImage, this.manager.panZoom, this.manager.layoutData, this.manager.preview, this.manager.ruler);
    this.manager.imageProcessor.di();
    this.manager.keyboard.di(this.manager.ui);
    this.manager.layoutData.di(this.manager.ui, this.manager.album, this.manager.editableImage, this.manager.preview, this.manager.text);
    this.manager.panZoom.di(this.manager.ui, this.manager.album);
    this.manager.preview.di(this.manager.ui, this.manager.layoutData);
    this.manager.restoreEditableImage.di();
    // this.manager.ruler.di(this, this.manager.ui, this.manager.panZoom);
    this.manager.ui.di();
    this.manager.webFont.di(this.manager.ui);
    this.manager.zip.di(this.manager.ui);
    this.manager.valid.di(this.manager.ui, this.manager.layoutData, this.manager.editableImage);
    this.manager.additional.di()
  }

  di() {}

  initialize() {
    Object.values<ILayoutEditorManagerBase>(this.manager)
      .forEach((manager) => {
        manager.initialize();
      });
  }

  destroy() {
    Object.values<ILayoutEditorManagerBase>(this.manager)
      .forEach((manager) => {
        if (manager === this.manager.editableImage) {
          (manager as EditableImageManager).leaveFromLayoutPage();
        } else {
          manager.destroy();
        }
      });
  }

}
