export class Ruler {

  /** 定規の幅 */
  private static WIDTH = 20;
  /** 定規のメモリの長さ : 短い方 */
  private static STROKE_WIDTH_S = 5;
  /** 定規のメモリの長さ : 長い方 */
  private static STROKE_WIDTH_L = 20;
  /** 短いメモリの初期間隔 */
  private static DEFAULT_STROKE_SPAN_S = 10;
  /** 短いメモリの初期幅 */
  private static DEFAULT_DASH_STROKE_SPAN = 1;
  /** 定規の長さのベース */
  private static LINE_LENGTH_BASE = 1000;

  // - svg element -
  private rulerSvgX: SVGSVGElement | null = null;
  private rulerSvgY: SVGSVGElement | null = null;
  private rulerSvgPtX: DOMPoint | null = null;
  private rulerSvgPtY: DOMPoint | null = null;
  // -- group --
  private rulerGroupX: SVGGElement | null = null;
  private rulerGroupY: SVGGElement | null = null;
  // -- line small --
  private rulerLineSX: SVGLineElement | null = null;
  private rulerLineSY: SVGLineElement | null = null;
  // -- line large --
  private rulerLineLX: SVGLineElement | null = null;
  private rulerLineLY: SVGLineElement | null = null;
  // -- line guide --
  private guideLineX: SVGLineElement | null = null;
  private guideLineY: SVGLineElement | null = null;

  constructor(
    private container: HTMLDivElement,
  ) {
  }

  /**
   * DOM の生成
   * 起点になるメソッド
   */
  create() {
    this.rulerSvgX = this.createRulerX();
    this.rulerSvgY = this.createRulerY();
    this.rulerSvgPtX = this.rulerSvgX.createSVGPoint();
    this.rulerSvgPtY = this.rulerSvgY.createSVGPoint();
    this.container.appendChild(this.rulerSvgX);
    this.container.appendChild(this.rulerSvgY);
  }
  /**
   * DOM の削除
   * cleanup処理
   */
  destroy() {
    if (!this.rulerSvgX || !this.rulerSvgY) return;
    this.container.removeChild(this.rulerSvgX);
    this.container.removeChild(this.rulerSvgY);
    this.rulerSvgX = null;
    this.rulerSvgY = null;
    this.rulerSvgPtX = null;
    this.rulerSvgPtY = null;
  }

  /**
   * ズーム時に呼び出すメソッド
   * @param scale
   */
  zoom(scale: number) {
    if (!(this.rulerLineSX && this.rulerLineSY && this.rulerLineLX && this.rulerLineLY)) {
      throw new Error('line not found ;(');
    }
    this.setAttributeLineS(this.rulerLineSX, 'x', scale);
    this.setAttributeLineS(this.rulerLineSY, 'y', scale);
    this.setAttributeLineL(this.rulerLineLX, 'x', scale);
    this.setAttributeLineL(this.rulerLineLY, 'y', scale);
  }


  /**
   * パン時に呼び出すメソッド
   * @param clientX
   * @param clientY
   */
  pan(clientX: number, clientY: number) {
    if (!(this.rulerGroupX && this.rulerGroupY)) {
      throw new Error('rulerGroup not found ;(');
    }
    if (!(this.rulerSvgX && this.rulerSvgY && this.rulerSvgPtX && this.rulerSvgPtY)) {
      throw new Error('rulerGroup not found ;(');
    }
    const x = this.pointClientToSvg(this.rulerSvgX, this.rulerSvgPtX, clientX, clientY).x;
    const y = this.pointClientToSvg(this.rulerSvgY, this.rulerSvgPtY, clientX, clientY).y;
    this.rulerGroupX.setAttributeNS(null, 'transform', `translate(${x}, 0)`);
    this.rulerGroupY.setAttributeNS(null, 'transform', `translate(0, ${y})`);
  }

  moveGuide(clientX: number, clientY: number) {
    if (!(this.rulerSvgX && this.rulerSvgY && this.rulerSvgPtX && this.rulerSvgPtY)) {
      throw new Error('rulerGroup not found ;(');
    }
    const x = this.pointClientToSvg(this.rulerSvgX, this.rulerSvgPtX, clientX, clientY).x;
    const y = this.pointClientToSvg(this.rulerSvgY, this.rulerSvgPtY, clientX, clientY).y;
    this.guideLineX?.setAttributeNS(null, 'transform', `translate(${x}, 0)`);
    this.guideLineY?.setAttributeNS(null, 'transform', `translate(0, ${y})`);
  }

  /**
   * X軸の定規生成
   * @private
   */
  private createRulerX() {
    const svg = this.createSvg('x');
    // - group -
    const g = document.createElementNS('http://www.w3.org/2000/svg', 'g');
    svg.appendChild(g);
    // - line -
    const lineS = this.createLineS('x');
    g.appendChild(lineS);
    const lineL = this.createLineL('x');
    g.appendChild(lineL);
    // - line zero -
    const lineZero = this.createLineZero('x');
    g.appendChild(lineZero);
    // - guide -
    const guide = this.createGuideLine('x');
    svg.appendChild(guide);
    // -- debug --
    const circle = document.createElementNS('http://www.w3.org/2000/svg', 'circle');
    circle.setAttributeNS(null, 'cx', '0');
    circle.setAttributeNS(null, 'cy', '0');
    circle.setAttributeNS(null, 'r', '4');
    circle.setAttributeNS(null, 'fill', 'transparent');
    // circle.setAttributeNS(null, 'fill', 'red');
    g.appendChild(circle);
    // - save to private member -
    this.rulerGroupX = g;
    this.rulerLineSX = lineS;
    this.rulerLineLX = lineL;
    this.guideLineX = guide;
    return svg;
  }

  /**
   * Y軸の定規生成
   * @private
   */
  private createRulerY() {
    const svg = this.createSvg('y');
    // - group -
    const g = document.createElementNS('http://www.w3.org/2000/svg', 'g');
    svg.appendChild(g);
    // -- line --
    const lineS = this.createLineS('y');
    g.appendChild(lineS);
    const lineL = this.createLineL('y');
    g.appendChild(lineL);
    // - line zero -
    const lineZero = this.createLineZero('y');
    g.appendChild(lineZero);
    // -- guide --
    const guide = this.createGuideLine('y');
    svg.appendChild(guide);
    // -- debug --
    const circle = document.createElementNS('http://www.w3.org/2000/svg', 'circle');
    circle.setAttributeNS(null, 'cx', '0');
    circle.setAttributeNS(null, 'cy', '0');
    circle.setAttributeNS(null, 'r', '4');
    circle.setAttributeNS(null, 'fill', 'transparent');
    // circle.setAttributeNS(null, 'fill', 'red');
    g.appendChild(circle);
    // - save to private member -
    this.rulerGroupY = g;
    this.rulerLineSY = lineS;
    this.rulerLineLY = lineL;
    this.guideLineY = guide;
    return svg;
  }

  /**
   * 元になる svg タグを生成
   * @param type
   * @private
   */
  private createSvg(type: 'x' | 'y') {
    const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    // - style -
    svg.setAttributeNS(
      null,
      'style',
      'background-color: #383838;' +
      'position: absolute;' +
      'left: 0;' +
      'top: 0;',
    );
    // - id -
    svg.id = type === 'x' ? 'ruler-x' : 'ruler-Y';
    svg.classList.add(type === 'x' ? 'ruler_x' : 'ruler_y');
    // - attribute -
    svg.setAttributeNS(null, 'width', type === 'x' ? '100%' : `${Ruler.WIDTH}px`);
    svg.setAttributeNS(null, 'height', type === 'x' ? `${Ruler.WIDTH}px` : '100%');
    return svg;
  }

  private createLineZero(type: 'x' | 'y') {
    const lineZero = document.createElementNS('http://www.w3.org/2000/svg', 'line');
    switch (type) {
      case 'x':
        lineZero.setAttributeNS(null, 'x1', '0');
        lineZero.setAttributeNS(null, 'y1', '0');
        lineZero.setAttributeNS(null, 'x2', '0');
        lineZero.setAttributeNS(null, 'y2', `${Ruler.STROKE_WIDTH_L}`);
        break;
      case 'y':
        lineZero.setAttributeNS(null, 'x1', '0');
        lineZero.setAttributeNS(null, 'y1', '0');
        lineZero.setAttributeNS(null, 'x2', `${Ruler.STROKE_WIDTH_L}`);
        lineZero.setAttributeNS(null, 'y2', '0');
        break;
    }
    lineZero.setAttributeNS(null, 'stroke-width', '3');
    // lineZero.setAttributeNS(null, 'stroke', '#888');
    lineZero.setAttributeNS(null, 'stroke', 'gold');
    return lineZero;
  }

  /**
   * メモリ内の線 (短い方 : small) を生成
   * @param type
   * @private
   */
  private createLineS(type: 'x' | 'y') {
    // - line -
    const lineS = document.createElementNS('http://www.w3.org/2000/svg', 'line');
    this.setAttributeLineS(lineS, type, 1);
    return lineS;
  }

  /**
   * 短い線のアトリビュート設定用メソッド
   * 生成時の他に zoom 時にアトリビュートを変更する際にも使用する
   * @param lineS
   * @param type
   * @param scale
   * @private
   */
  private setAttributeLineS(lineS: SVGLineElement, type: 'x' | 'y', scale: number) {
    // - const -
    const centerPos = Ruler.WIDTH - Ruler.STROKE_WIDTH_S / 2;
    const solvedSpanS = Math.round(Ruler.DEFAULT_STROKE_SPAN_S * scale);
    const solvedSpanL = solvedSpanS * 10;
    // 波線の開始位置がずれないように定規の長さを倍数で設定
    const lineLength = solvedSpanL * Ruler.LINE_LENGTH_BASE;
    // - line -
    lineS.setAttributeNS(null, 'x1', type === 'x' ? `${-lineLength}` : `${centerPos}`);
    lineS.setAttributeNS(null, 'y1', type === 'x' ? `${centerPos}` : `${-lineLength}`);
    lineS.setAttributeNS(null, 'x2', type === 'x' ? `${lineLength}` : `${centerPos}`);
    lineS.setAttributeNS(null, 'y2', type === 'x' ? `${centerPos}` : `${lineLength}`);
    lineS.setAttributeNS(null, 'stroke-width', `${Ruler.STROKE_WIDTH_S}`);
    lineS.setAttributeNS(null, 'stroke', '#888');
    lineS.setAttributeNS(null, 'stroke-dasharray', `${Ruler.DEFAULT_DASH_STROKE_SPAN} ${solvedSpanS - Ruler.DEFAULT_DASH_STROKE_SPAN}`);
    return lineS;
  }

  /**
   * メモリ内の線 (長い方 : large) を生成
   * @param type
   * @private
   */
  private createLineL(type: 'x' | 'y') {
    // - line -
    const lineL = document.createElementNS('http://www.w3.org/2000/svg', 'line');
    this.setAttributeLineL(lineL, type, 1);
    return lineL;
  }

  /**
   * 長い線のアトリビュート設定用メソッド
   * 生成時の他に zoom 時にアトリビュートを変更する際にも使用する
   * @param lineL
   * @param type
   * @param scale
   * @private
   */
  private setAttributeLineL(lineL: SVGLineElement, type: 'x' | 'y', scale: number) {
    // - const -
    const centerPos = Ruler.WIDTH - Ruler.STROKE_WIDTH_L / 2;
    const solvedSpanS = Math.round(Ruler.DEFAULT_STROKE_SPAN_S * scale);
    const solvedSpanL = solvedSpanS * 10;
    // 波線の開始位置がずれないように定規の長さを倍数で設定
    const lineLength = solvedSpanL * Ruler.LINE_LENGTH_BASE;
      // - line -
    lineL.setAttributeNS(null, 'x1', type === 'x' ? `${-lineLength}` : `${centerPos}`);
    lineL.setAttributeNS(null, 'y1', type === 'x' ? `${centerPos}` : `${-lineLength}`);
    lineL.setAttributeNS(null, 'x2', type === 'x' ? `${lineLength}` : `${centerPos}`);
    lineL.setAttributeNS(null, 'y2', type === 'x' ? `${centerPos}` : `${lineLength}`);
    lineL.setAttributeNS(null, 'stroke-width', `${Ruler.STROKE_WIDTH_L}`);
    lineL.setAttributeNS(null, 'stroke', '#888');
    lineL.setAttributeNS(null, 'stroke-dasharray', `${Ruler.DEFAULT_DASH_STROKE_SPAN} ${solvedSpanL - Ruler.DEFAULT_DASH_STROKE_SPAN}`);
    return lineL;
  }

  /**
   * ガイド生成
   * @param type
   * @private
   */
  private createGuideLine(type: 'x' | 'y') {
    const guideMargin = 5;
    const line = document.createElementNS('http://www.w3.org/2000/svg', 'line');
    line.setAttributeNS(null, 'x1', '0');
    line.setAttributeNS(null, 'y1', '0');
    line.setAttributeNS(null, 'x2', type === 'x' ? '0' : `${Ruler.WIDTH - guideMargin}`);
    line.setAttributeNS(null, 'y2', type === 'x' ? `${Ruler.WIDTH - guideMargin}` : '0');
    line.setAttributeNS(null, 'stroke', '#ffffffb0');
    // line.setAttributeNS(null, 'stroke', 'skyblue');
    line.setAttributeNS(null, 'stroke-width', '2');
    return line;
  }

  /**
   * ブラウザ座標をSVG座標に変換
   * @param svg
   * @param pt
   * @param clientX
   * @param clientY
   * @private
   */
  private pointClientToSvg(svg: SVGSVGElement, pt: DOMPoint, clientX: number, clientY: number) {
    pt.x = clientX;
    pt.y = clientY;
    const { x, y } = pt.matrixTransform(svg.getScreenCTM()?.inverse());
    return { x, y };
  }

}
