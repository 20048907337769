import './shop-detail.scss';
import { useDispatch } from 'react-redux';
import React, { useCallback, useEffect, useState } from 'react';
import { Button } from '../../ui/button/button';
import { dialogActions } from '../slice/dialog-slice';
import { Input } from '../../ui/input/input';
import { apiActions } from '../../../slices/api-slice';
import { ApiGetCloudFolderShopDetail } from '../../../api/front/cloud-folder/api-get-cloud-folder-shop-detail';
import { ResponseBase } from '../../../api/response-base';
import { ApiEditCloudFolderShop } from '../../../api/front/cloud-folder/api-edit-cloud-folder-shop';

type ShopDetail = {
  kijshopCd: string,
  shopName: string,
  mailaddress: string,
  staffLoginValidFlg: number,
  orderExpiredDay: number,
  totalSize: number,
  downloadNum: number,
  downloadSizeSum: number,
  uploadNum: number,
  uploadSizeSum: number,
  orderNum: number,
  staffNum: number,
  createdAt: string,
  updatedAt: string,
};

type Props = {
  kijshopCd: string,
  getList: () => void,
}

export const ShopDetailDialog = (props: Props) => {
  const { kijshopCd, getList } = props;
  const dispatch = useDispatch();

  const [data, setData] = useState<ShopDetail>();

  const [downloadExpiredDay, setDownloadExpiredDay] = useState('');

  const handlerClickUpdate = useCallback(() => {
    dispatch(apiActions.run(
      new ApiEditCloudFolderShop({ kijshopCd, orderExpiredDay: Number(downloadExpiredDay) }),
      {
        onSuccess: () => {
          dispatch(dialogActions.pushMessage({
            title: '完了',
            message: ['更新しました。'],
            buttons: [
              {
                label: 'OK',
                callback: () => {
                  getList();
                  dispatch(dialogActions.popAll());
                },
              },
            ],
          }));
        },
      },
    ));
  }, [dispatch, downloadExpiredDay]);

  const handlerClickClose = useCallback(() => {
    dispatch(dialogActions.pop());
  }, [dispatch]);


  const onKeyUp = (e: any) => {
    if (e.code === 'Enter') {
      onEnterPress();
    }
  };
  const onEnterPress = () => {
    const reg = RegExp(/^[0-9]+$/);
    if (!reg.test(downloadExpiredDay)) {
      const txt = downloadExpiredDay.replace(/[^0-9]/g, '');
      if (txt === '' || Number(txt) < 1) {
        setDownloadExpiredDay('1');
      } else {
        setDownloadExpiredDay(txt);
      }
    }
  };

  useEffect(() => {
    dispatch(apiActions.run(
      new ApiGetCloudFolderShopDetail({ kijshopCd }),
      {
        onSuccess: (res: ResponseBase<ShopDetail>) => {
          if (res.body.data) {
            setData(res.body.data);
            setDownloadExpiredDay(String(res.body.data.orderExpiredDay));
          }
        },
      },
    ));
  }, []);

  return (
    <>
      <div className={'dialog_contents'}>
        <div className={'info'}>
          <span className={'info__label'}>
            店舗番号
          </span>
          {data?.kijshopCd}
        </div>
        <div className={'info'}>
          <span className={'info__label'}>
            店舗名
          </span>
          {data?.shopName}
        </div>
        <div className={'info'}>
          <span className={'info__label'}>
            スタッフ人数
          </span>
          {data?.staffNum}&nbsp;人
        </div>
        <div className={'info'}>
          <span className={'info__label'}>
            注文数
          </span>
          {data?.orderNum}&nbsp;件
        </div>

        <div className={'download_expired'}>
          <span className={'download_expired__label'}>ダウンロード可能期間</span>
          <div className={'download_expired__input'}>
            <Input
              value={downloadExpiredDay}
              type={'number'}
              min={1}
              maxLength={3}
              handlerEnter={onEnterPress}
              onKeyUp={(e) => onKeyUp(e)}
              onChange={(e) => setDownloadExpiredDay(e.target.value)}
              onBlur={onEnterPress}
            />
            日
          </div>
        </div>
      </div>
      <div className={'dialog_contents__footer shop_detail__footer'}>
        <Button
          label={'閉じる'}
          color={'light'}
          onClick={handlerClickClose}
        />
        <Button
          label={'更新'}
          onClick={handlerClickUpdate}
        />
      </div>
    </>
  );
};
