import { Wizard } from '../../../ui/wizard/wizard';
import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Step1StaffPasswordResetWizard } from './step1.staff-password-reset.wizard';
import { Step2StaffPasswordResetWizard } from './step2.staff-password-reset.wizard';
import { Step3StaffPasswordResetWizard } from './step3.staff-password-reset.wizard';
import { Step4StaffPasswordResetWizard } from './step4.staff-password-reset.wizard';

const TITLE = 'スタッフ パスワード再発行';

export const StaffPasswordResetWizard = () => {
  // - Hooks -
  const { mailToken } = useParams<{ mailToken: string }>();
  const location = useLocation();
  // - State -
  const [step, setStep] = useState(0);
  const [token, setToken] = useState('');

  // - effect -
  useEffect(() => {
    const isToken = new URLSearchParams(location.search).has('token');
    if (isToken) {
      setStep(3);
      setToken(new URLSearchParams(location.search).get('token') || '');
      return;
    }
    setStep(1);
  }, []);

  return (
    <Wizard
      title={TITLE}
      step={step}
    >
      <Step1StaffPasswordResetWizard setStep={setStep}/>
      <Step2StaffPasswordResetWizard setStep={setStep}/>
      <Step3StaffPasswordResetWizard
        setStep={setStep}
        token={token}
      />
      <Step4StaffPasswordResetWizard />
    </Wizard>
  );
};