import { Button } from '../../ui/button/button';
import { useDispatch } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import { Table } from '../../ui/table/table';
import { Checkbox } from '../../ui/input/checkbox';
import { dialogActions } from '../slice/dialog-slice';
import './order-delete-confirm.scss';
import { apiActions } from '../../../slices/api-slice';
import { ApiOrderLockCheck, OrderLockCheckResponse } from '../../../api/front/lock-order/api-lock-orders';
import { ResponseBase } from '../../../api/response-base';
import { ApiAllDeleteXml } from '../../../api/front/xml/api-all-delete-xml';
import { ApiMetaShopOrderDelete } from '../../../api/front/meta/api-meta-shop-order';
import { LoadingPopup } from '../../ui/loading/loading-popup';
import { convertErrorStatus } from '../../pages/upload-manager/upload-manager';
import { ErrorOrder } from '../../../slices/order-preparation-slice';

type OrderStatusNameProps = {
  label: string,
  error?: boolean,
}
const OrderStatusName = (props: OrderStatusNameProps) => {
  const { label, error } = props;

  return (
    <span style={{ color: error ? '#D70B24' : 'currentcolor' }}>{label}</span>
  )
}

type OrderDeleteConfirmProps = {
  targetOrder: ErrorOrder[],
  isUploadManager?: boolean,
  kijshopCd: string,
  callbackDelete: () => void,
}
export const OrderDeleteConfirm = (props: OrderDeleteConfirmProps) => {
  const { targetOrder, isUploadManager, kijshopCd, callbackDelete } = props;
  // - Hooks -
  const dispatch = useDispatch();
  // - State -
  // -- テーブルbody --
  const [connectingMessage, setConnectingMessage] = useState('');
  // - Callback -
  // -- はいボタン押下 --
  const handlerClickYes = useCallback(() => {
    setConnectingMessage('注文を削除しています...');
    let lockList: { shopOrderId: string, lockUser: string }[] = [];
    let unlockList: string[] = [];
    Promise.all(targetOrder.map((v) =>
      new Promise<void>((resolve) => {
        dispatch(apiActions.run(
          new ApiOrderLockCheck({ kijshopCd, shopOrderId: v.shopOrderId }),
          {
            onSuccess: (res: ResponseBase<OrderLockCheckResponse>) => {
              const data = res.body.data;
              if (data && data.name !== null) {
                lockList.push({ shopOrderId: v.shopOrderId, lockUser: data.name });
              } else {
                unlockList.push(v.shopOrderId);
              }
              resolve();
            },
            onError: () => {
              unlockList.push(v.shopOrderId);
              resolve();
            },
          },
        ));
      }),
    )).then(() => {
      dispatch(apiActions.runAll(
        [...[...unlockList].map((id) => new ApiAllDeleteXml(kijshopCd, id))],
        {
          onSuccess: () => {
            dispatch(dialogActions.pop());
            setConnectingMessage('');
            if (lockList.length) {
              dispatch(dialogActions.pushMessage({
                title: '確認',
                message: [
                  '下記の注文は現在編集中のため、削除できませんでした。', '',
                  ...lockList.map((v) => `・ ${v.shopOrderId}（${v.lockUser}）`),
                ],
                buttons: [{
                  label: 'OK',
                  callback: () => {
                    dispatch(dialogActions.pop());
                    callbackDelete();
                  },
                }],
              }));
            } else {
              callbackDelete();
            }
          },
          onError: () => {
            setConnectingMessage('');
            dispatch(dialogActions.pop());
            dispatch(dialogActions.pushMessage({
              title: 'エラー',
              message: ['注文の削除に失敗しました'],
              buttons: [{
                label: 'OK',
                callback: () => {
                  dispatch(dialogActions.pop());
                },
              }],
            }));
          },
        },
      ));
    }).catch(() => {
    });

  }, []);
  // -- いいえボタン押下 --
  const handlerClickNo = useCallback(() => {
    dispatch(dialogActions.pop());
  }, []);
  const tableHead = ['作成日時', 'お客様名', '注文番号', '状態'];
  // - Effect -
  // -- チェックした注文情報をbodyに入れる --
  // -- Upload Managerの場合はサイズの項目を追加してsetする --
  return (
    <div className="dialog_contents order_delete_confirm">
      <div className="dialog_contents__body order_delete_confirm__body">
          以下のオーダーを削除します。よろしいですか？
          <Table
            head={tableHead}
            body={targetOrder.map((v) => [v.createDate, v.lastName, v.shopOrderId, convertErrorStatus(v.errorStatus.code)])}
          />
      </div>
      <div className="dialog_contents__footer">
        <Button
          label="いいえ"
          onClick={handlerClickNo}
        />
        <Button
          label="はい"
          onClick={handlerClickYes}
        />
      </div>
      {connectingMessage ? (<LoadingPopup label={connectingMessage} />) : (<></>)}
    </div>
  );
};
