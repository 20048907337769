import { useEffect, useState } from 'react';
import { Wizard } from '../../../ui/wizard/wizard';
import { Header } from '../../../ui/header/header';
import { Step1StaffMngMailChangeWizard } from './step1.staff-mng-mail-change.wizard';
import { Step2StaffMngMailChangeWizard } from './step2.staff-mng-mail-change.wizard';
import { Step3StaffMngMailChangeWizard } from './step3.staff-mng-mail-change.wizard';
import { Step4StaffMngMailChangeWizard } from './step4.staff-mng-mail-change.wizard';
import { useLocation } from 'react-router-dom';

export const StaffMngMailChangeWizard = () => {
  // - Hooks -
  const location = useLocation();
  // - State -
  const [step, setStep] = useState(0);
  const [token, setToken] = useState('');
  const [step3DisplayMail, setStep3DisplayMail] = useState('');
  // - effect -
  useEffect(() => {
    const isToken = new URLSearchParams(location.search).has('token');
    // クエリパラメータにtokenがある場合は、step4へ遷移
    if (isToken) {
      setStep(4);
      setToken(new URLSearchParams(location.search).get('token') || '');
      return;
    }
    setStep(1);
  }, []);
  return (
    <div className="page_base">
      <Header page="" />
      <Wizard step={step}>
        <Step1StaffMngMailChangeWizard setStep={setStep} />
        <Step2StaffMngMailChangeWizard setStep={setStep} setStep3DisplayMail={setStep3DisplayMail} />
        <Step3StaffMngMailChangeWizard
          setStep={setStep}
          step3DisplayMail={step3DisplayMail}
        />
        <Step4StaffMngMailChangeWizard token={token} />
      </Wizard>
    </div>
  );
};