import { ApiBaseFront } from '../api-base-front';

type DomainCheckRequest = {
  kijshopCd: string,
  mail_address: string,
}
export class ApiDomainCheck extends ApiBaseFront {
  constructor(param: DomainCheckRequest) {
    super(
      'POST',
      '/api/v1/domain/check',
      'JSON',
      param
    );
  }
}