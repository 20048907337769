import { LogDecorator } from '@tenryu/log-decorator';
import { push } from 'connected-react-router';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { PathParams, RoutingPath } from '../../../routes/routing-path';
import { Button } from '../../ui/button/button';
import { Header } from '../../ui/header/header';
import { Thumbnail } from '../../ui/thumbnail/thumbnail';
import { ContactSheet } from './contact-sheet';
import './print-order-image.scss';
import { DummyImage } from '../../../utilities/dummy-img';
import { Image } from '../../ui/image/image';
import { useParams } from 'react-router-dom';
import { orderLockActions } from '../../../slices/lock-order-slice';
import { EditableImage } from '../../../layout-editor/manager/image-edit/editable-image';
import { EditableImageManager } from '../../../layout-editor/manager/editable-image/editable-image.manager';
import { UiManager } from '../../../layout-editor/manager/ui/ui.manager';
import { UuidGenerator } from '../../../utilities/uuid-generator';
import { apiActions } from '../../../slices/api-slice';
import { RestoreEditableImageManager } from '../../../layout-editor/manager/restore-editable-image/restore-editable-image.manager';
import { useAppSelector } from '../../../app/hooks';
import lodash from 'lodash';
import { ApiImagesGet, ApiImagesGetOne, ImagesGetResponse } from '../../../api/front/images/api-images';
import { dialogActions } from '../../dialog/slice/dialog-slice';
import { ResponseBase } from '../../../api/response-base';
import { LoadingPopup } from '../../ui/loading/loading-popup';

// - 1ページ分に表示する画像の枚数制限 -
const displayImageNum = 20;

type PrintPageProps = {
  // - 画像のリスト -
  allImages: { src: string, label: string }[],
  // - 印刷ボタンの押下状態 -
  isClickPrint: boolean,
  // - プレビュー表示を戻すハンドラ -
  onClosePreview: () => void,
}
// プレビュー表示のコンポネント
const PrintPage = (props: PrintPageProps) => {
  const { allImages, isClickPrint, onClosePreview } = props;
  const insertRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (isClickPrint) {
      setTimeout(() => {
        window.print();
      }, 300);
    }
  }, []);
  const imagesArr: { src: string, label: string }[][] = [];
  let cnt = 0;
  allImages.forEach((images) => {
    if (!imagesArr[cnt]) {
      imagesArr[cnt] = [];
    }
    imagesArr[cnt].push(images);
    if (imagesArr[cnt].length === displayImageNum) {
      cnt++;
    }
  });
  return (
    <div
      ref={insertRef}
      onClick={() => onClosePreview()}
      className="print_page"
    >
      {imagesArr.map((v, i) => (
        <ContactSheet
          key={`print-page-num_${i}`}
          images={v}
          totalImageNum={allImages.length}
          totalPageNum={Math.ceil(allImages.length / displayImageNum)}
          isClickPrint={true}
          style={{ pageBreakAfter: 'always' }}
          thisNum={i + 1}
        />
      ))}
    </div>
  );
};

export const PrintOrderImage = () => {

  const { kijshopCd, shopOrderId, orderId } = useParams<PathParams>();
  const dispatch = useDispatch();

  // - State -
  const xml = useAppSelector((state) => state.xml[shopOrderId], lodash.isEqual);
  // -- 印刷ボタン押下状態 --
  const [isClickPrint, setIsClickPrint] = useState(false);
  // -- プレビュー表示か --
  const [isPreview, setIsPreview] = useState(false);

  const [allImages, setAllImages] = useState<{ src: string, label: string }[]>([]);
  const [images, setImages] = useState<{ src: string, label: string }[]>([]);
  const [loading, setLoading] = useState(false);

  // - Effect -
  useEffect(() => {
    const orderInfoData = xml?.orderInfo?.infoData?.find((v) => v.xml.metaModel.id === orderId);
    const optionOrderInfoData = orderInfoData?.xml.optionOrderInfoDataXml;
    const orderMethod = orderInfoData?.xml.viewModel.orderMethod?.id;
    const orderSelect = xml?.orderSelect;
    const getUploadImages = () => {
      return new Promise<ImagesGetResponse[]>((resolve) => {
        dispatch(apiActions.run(
          new ApiImagesGet({ kijshopCd, shopOrderId, kind: '6' }),
          {
            onSuccess: (res: ResponseBase<ImagesGetResponse[]>) => {
              resolve(res?.body?.data || []);
            },
            onError: () => {
              dispatch(dialogActions.pushMessage({
                title: '確認',
                message: ['画像の読み込みに失敗しました。'],
                buttons: [{
                  label: 'OK',
                  callback: () => {
                    dispatch(dialogActions.pop());
                    resolve([]);
                  },
                }],
              }));
            },
          },
          { ignoreSystemError: true },
        ));
      });
    };
    const getThumbImages = (arr: { path: string, label: string, index: number }[]) => {
      const imageArr: { src: string, label: string, index: number }[] = [];
      let cnt = arr.length;
      if (!cnt) {
        setLoading(false);
      }
      const checkFinished = () => {
        cnt--;
        if (!cnt) {
          setLoading(false);
          // APIで配列の順番が変わっているため並び替える
          imageArr.sort((a, b) => {
            if (a.index < b.index) {
              return -1;
            } else {
              if (a.index === b.index && !a.label.match(/[0-9]/)) {
                return -1;
              }
              return 1;
            }
          });
          setAllImages(imageArr);
          setImages(imageArr.filter((_, i) => i < displayImageNum));
        }
      };
      arr.forEach((v) => {
        dispatch(apiActions.run(
          new ApiImagesGetOne({ kijshopCd, path: v.path }),
          {
            onSuccess: (blob: Blob) => {
              const reader = new FileReader();
              reader.readAsDataURL(blob);
              reader.onload = () => {
                imageArr.push({ src: reader.result as string, label: v.label, index: v.index });
                checkFinished();
              };
            },
            onError: () => {
              checkFinished();
            },
          },
          { ignoreSystemError: true },
        ));
      });
    };
    if (orderInfoData && orderMethod) {
      const arr: { path: string, label: string, index: number }[] = [];
      /* 画像を選択しておまかせ */
      if (orderMethod === '10') {
        setLoading(true);
        getUploadImages()
          .then((res) => {
            orderInfoData.pageBreak?.viewModel.data?.image?.forEach((v, i) => {
              const selectImage = orderSelect?.xmlModel.lnwOrderSelect.orderSelect?.[0]?.data?.find((data) => data.$.selectID === v.selectID);
              const uploadImage = res.find((data) => (data.selectId && data.selectId === v.selectID) || (selectImage && selectImage.selectFileName?.[0]?.real?.[0]?.$?.path === data.filename));
              if (uploadImage) {
                arr.push({
                  path: `${uploadImage.pathThumb}/${uploadImage.filename}`,
                  label: v.path,
                  index: i,
                });
              } else if (selectImage?.selectFileName?.[0]?.real?.[0]?.$?.path) {
                arr.push({
                  path: selectImage.selectFileName[0].real[0].$.path.indexOf('Real/01/') === -1
                    ? `${kijshopCd}/upload/${shopOrderId}/${selectImage.selectFileName[0].real[0].$.path}`
                    : `${kijshopCd}/${shopOrderId}/${selectImage.selectFileName[0].real[0].$.path}`,
                  label: v.path,
                  index: i,
                });
              }
            });
            getThumbImages(arr);
          });
      }
      /* 簡易/完全レイアウトして入稿 */
      if (orderMethod === '20' || orderMethod === '30') {
        setLoading(true);
        orderInfoData.xml.orderParts?.orderPartsDataArr?.forEach((partsData) => {
          const pageDataArr = [...(partsData.orderPage?.orderPageDataArr || [])];
          pageDataArr.forEach((pageData, index) => {
            if (pageData.xmlModel.lnwOrderPageData.data?.[0]?.compositeFileName?.[0]?.virtual?.[0]?.$.path) {
              arr.push({
                path: `${kijshopCd}/${shopOrderId}/${pageData.xmlModel.lnwOrderPageData.data?.[0]?.compositeFileName?.[0]?.virtual?.[0]?.$.path}`,
                label: `${(pageData.xmlModel.lnwOrderPageData.data?.[0]?.$.displayPageNo !== '***') ? pageData.xmlModel.lnwOrderPageData.data?.[0]?.$.displayPageNo + ' ' : ''}${pageData.xmlModel.lnwOrderPageData.data?.[0]?.$.displayPageType}`,
                index: index,
              });
            }
          });
        });
        if (optionOrderInfoData && optionOrderInfoData.length) {
          optionOrderInfoData.forEach((v) => {
            v.orderParts?.orderPartsDataArr.forEach((vv) => {
              vv.orderPage?.orderPageDataArr.forEach((vvv) => {
                if (vvv.xmlModel.lnwOrderPageData.data?.[0].compositeFileName) {
                  arr.push({
                    path: `${kijshopCd}/${shopOrderId}/${vvv.xmlModel.lnwOrderPageData.data?.[0].compositeFileName[0].virtual?.[0].$.path}`,
                    label: `${vvv.viewModel.displayPageType}`,
                    index: arr.length,
                  });
                }
              });
            });
          });
        }
        getThumbImages(arr);
      }
      /* 完成画像を入稿(オリジナル画像を直接読み込み) */
      if (orderMethod === '40') {
        setLoading(true);
        orderInfoData.xml.orderParts?.orderPartsDataArr?.forEach((partsData) => {
          const pageDataArr = [...(partsData.orderPage?.orderPageDataArr || [])];
          pageDataArr.forEach((pageData, index) => {
            if (pageData.xmlModel.lnwOrderPageData.data?.[0]?.compositeFileName?.[0]?.real?.[0]?.$.path) {
              arr.push({
                path: `${kijshopCd}/${shopOrderId}/${pageData.xmlModel.lnwOrderPageData.data?.[0]?.compositeFileName?.[0]?.real?.[0]?.$.path}`,
                label: `${(pageData.xmlModel.lnwOrderPageData.data?.[0]?.$.displayPageNo !== '***') ? pageData.xmlModel.lnwOrderPageData.data?.[0]?.$.displayPageNo + ' ' : ''}${pageData.xmlModel.lnwOrderPageData.data?.[0]?.$.displayPageType}`,
                index: index,
              });
            }
          });
        });
        if (optionOrderInfoData && optionOrderInfoData.length) {
          optionOrderInfoData.forEach((v) => {
            v.orderParts?.orderPartsDataArr.forEach((vv) => {
              vv.orderPage?.orderPageDataArr.forEach((vvv) => {
                if (vvv.xmlModel.lnwOrderPageData.data?.[0].compositeFileName) {
                  arr.push({
                    path: `${kijshopCd}/${shopOrderId}/${vvv.xmlModel.lnwOrderPageData.data?.[0].compositeFileName[0].real?.[0].$.path}`,
                    label: `${vvv.viewModel.displayPageType}`,
                    index: arr.length,
                  });
                }
              });
            });
          });
        }
        getThumbImages(arr);
      }
    }
  }, [xml]);
  // -- ログまとめ --
  useEffect(() => {
    LogDecorator.group('<green>注文画像印刷</green>');
    return () => LogDecorator.groupEnd();
  }, []);
  // - Callback -
  // -- 印刷ボタン --
  const handlerClickPrint = useCallback(() => {
    setIsClickPrint(true);
    setIsPreview(true);
    window.onafterprint = () => {
      setIsClickPrint(false);
      setIsPreview(false);
    };
  }, []);
  // - 表示ページ変更 -
  const handlerChangePageNation = useCallback((num) => {
    setImages(allImages.filter((_, i) => i >= ((num - 1) * displayImageNum) && i < (num * displayImageNum)));
  }, [allImages]);
  return isClickPrint ? (
    <PrintPage
      allImages={allImages}
      isClickPrint={true}
      onClosePreview={() => {
      }}
    />
  ) : (
    <>
      <div className="print_order_image column_direction_wrap">
        <Header />
        <div className="print_order_image__body px_2rem">
          <div className="print_order_image__body__menu flex_space_between mt_1rem mb_5">
            <div className="flex_box">
              <Button
                label="印刷"
                color="light"
                onClick={handlerClickPrint}
              />
              <Button
                label={isPreview ? '元に戻す' : '画面に合わせる'}
                color="light"
                onClick={() => setIsPreview(!isPreview)}
              />
            </div>
            <Button
              label="戻る"
              onClick={() => dispatch(push(RoutingPath.cnv.ordersPreparation({ kijshopCd, shopOrderId })))}
            />
          </div>

          <div className="print_order_image__body__list">
            <ContactSheet
              // printImages={fixPrintImages}
              images={images}
              totalImageNum={allImages.length}
              totalPageNum={Math.ceil(allImages.length / displayImageNum)}
              isClickPrint={isPreview}
              onChangePageNation={(pageNum) => handlerChangePageNation(pageNum)}
            />
          </div>

        </div>
      </div>
      {loading ? (<LoadingPopup label="画像を読み込み中です..." />) : (<></>)}
    </>
  );
};
