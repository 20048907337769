import React, { useState } from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { Routes } from './routes/routes';
import './App.css';
import './App.scss';
import { history } from './app/store';
import {Dialog} from './components/dialog/dialog';
import {Toolbar} from './components/toolbar/toolbar';
import { InitializeContainer } from './containers/initialize';
import { ConnectionStatusBar } from './components/ui/connection-status-bar/connection-status-bar';

const App = () => {

  const [initialized, setInitialized] = useState(false);

  return initialized ? (
    <>
      <ConnectedRouter history={history} >
        <Routes />
      </ConnectedRouter>
      <Dialog />
      <Toolbar />
      <ConnectionStatusBar />
      {/* <LoadingBar /> */}
    </>
  ) : (
    <InitializeContainer onInitialized={() => {
      setInitialized(true);
    }} />
  );
}

export default App;
