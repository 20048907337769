import * as createjs from 'createjs-module';
import { Pos } from '../../../models/pos';

export class CjShape extends createjs.Shape {
  public mode?: 'lock' | 'edit'
  public vector?: 'vertical' | 'horizontal'
  public originPos?: Pos
  public isNonInit?: boolean
  constructor() {
    super();
  }
}