import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { EditableImage } from '../../../layout-editor/manager/image-edit/editable-image';
import {
  RestoreEditableImageManager
} from '../../../layout-editor/manager/restore-editable-image/restore-editable-image.manager';
import "./folder-drop-zone.scss";
import { UiManager } from '../../../layout-editor/manager/ui/ui.manager';
import { PathParams } from '../../../routes/routing-path';
import { apiActions } from '../../../slices/api-slice';
import { Image as ImageComponent } from "../image/image";
import { Thumbnail } from "../thumbnail/thumbnail";
import { ImageCheckPostResponse } from '../../../models/api/front/image-check';
import { dialogActions } from '../../dialog/slice/dialog-slice';
import { xmlActions } from '../../../xml/slice/xml-slice';
import { OrderPageDataXml } from '../../../xml/class/order/order-page-data-xml';
import { ApiImagesGet, ApiImagesGetOne } from '../../../api/front/images/api-images';
import { ApiImageCheckPost } from '../../../api/front/image-check/api-image-check';
import { LoadingPopup } from '../loading/loading-popup';
import { XmlStructureOrderInfoData } from '../../../xml/model/xml-structure-model';
import { OrderInfoDataXml } from '../../../xml/class/order/order-info-data-xml';
import { ResponseBase } from '../../../api/response-base';
import { ApiFilters } from '../../../api/back/create-order/filters/api-filters';
import { FiltersResponse } from '../../../models/api/back/create-order/filters';
import { orderPreparationActions } from '../../../slices/order-preparation-slice';
import { useAppSelector } from '../../../app/hooks';
import * as lodash from 'lodash';
import { ImageProcessorManager } from '../../../layout-editor/manager/image-processor/image-processor.manager';
import { getOrientation, Orientation } from 'get-orientation/browser';
import { ExifConverter } from '../../../utilities/exif-converter';

type FolderDropZoneProps = {
  onGetTotalNum: (num : number) => void,
  orderId: string | null;
  page: number,
  imagesCheck: ImageCheckPostResponse[],
  orderInfoDataArr: XmlStructureOrderInfoData[],
  orderPageDataArr: OrderPageDataXml[],
  orderInfoData: OrderInfoDataXml,
  ordering: boolean,
  optionImagesCheck: {
    orderInfoDataId: string,
    checkRes: ImageCheckPostResponse[],
  }[],
  callbackChangeImageNum: (num: number) => void,
};

type ThumbProps = {
  src: string;
  label: string;
}

// ページ数でのバリデーション
const scrutinyFile = (list: File[], page: number ) => {
  const useFiles: File[] = [];
  const exclusionFiles: File[] = [...list];
  const checkName = (file: File, name: string) => {
    const arr = file.name.split('.');
    return arr.length === 2 && arr[0] === name;
  };
  const initIndex = exclusionFiles.findIndex((v) => checkName(v, '0000'));
  if (initIndex !== -1) {
    useFiles.push(exclusionFiles[initIndex]);
    exclusionFiles.splice(initIndex, 1);
  }
  const firstIndex = exclusionFiles.findIndex((v) => checkName(v, '0001'));
  if (firstIndex !== -1) {
    useFiles.push(exclusionFiles[firstIndex]);
    exclusionFiles.splice(firstIndex, 1);
  }
  for (let i: number = 2; i < page; i += 2) {
    const pageBf = i < 10 ? `0${i}` : i;
    const pageAf = i + 1 < 10 ? `0${i + 1}` : i;
    const index = exclusionFiles.findIndex((v) => checkName(v, `${pageBf}${pageAf}`));
    if (index !== -1) {
      useFiles.push(exclusionFiles[index]);
      exclusionFiles.splice(index, 1);
    }
  }
  const endIndex = exclusionFiles.findIndex((v) => checkName(v, `${page < 10 ? `0${page}` : page}00`));
  if (endIndex !== -1) {
    useFiles.push(exclusionFiles[endIndex]);
    exclusionFiles.splice(endIndex, 1);
  }
  return {
    useFiles,
    exclusionFiles,
  };
};

// const pageNameConverter = (fileName: string) => {
//   const name = fileName.substr(0, 4);
//   if (!name || name.length !== 4) {
//     return fileName;
//   }
//   if (name === '0000') {
//     return '表紙';
//   }
//   const bf = Number(name.substr(0, 2));
//   const af = Number(name.substr(2, 2));
//   if (!bf && !af) {
//     return fileName;
//   }
//   if (!bf) {
//     return `${af}頁`;
//   }
//   if (!af) {
//     return `${bf}頁`;
//   }
//   return `${bf}-${af}頁`;
// };

export const FolderDropZone = (props: FolderDropZoneProps) => {
  const dispatch = useDispatch();
  // const { onGetTotalNum, orderId, page, imagesCheck, orderInfoDataArr, orderPageDataArr, ordering, optionImagesCheck, callbackChangeImageNum } = props;
  const { onGetTotalNum, orderId, page, orderInfoDataArr, orderPageDataArr, ordering, callbackChangeImageNum, orderInfoData } = props;
  // - Params -
  const { kijshopCd, shopOrderId } = useParams<PathParams>();
  const { compImages, xml, selectedOrderIdArr, pageTypeList, reset } = useAppSelector((state) => ({
    compImages: state.orderPreparation.thumbImages,
    xml: state.xml[shopOrderId]?.orderInfo?.infoData?.[Number(orderId) - 1],
    selectedOrderIdArr: state.orderPreparation.selectedOrderIdArr,
    pageTypeList: state.common.pageTypeList,
    reset: state.orderPreparation.reset,
  }), lodash.isEqual);
  const [thumbPropsList, setThumbPropsList] = useState<ThumbProps[]>([]);
  const [imageDataList, setImageDataList] = useState<{name: string, path: string, orientation?: Orientation}[]>([...(compImages.find((v) => v.orderId === orderId)?.images || [])]);
  // - State -
  const [isDragAccept, setIsDragAccept] = useState(false);
  const [isDragReject, setIsDragReject] = useState(false);
  const [uploadComplete, setUploadComplete] = useState(true);
  const [connecting, setConnecting] = useState(false);
  const [checking, setChecking] = useState(false);
  const [failToLoad, setFailToLoad] = useState(false);
  const [init, setInit] = useState(true);
  // - Callback -
  const handleDragOver = useCallback((e) => {
    e.stopPropagation();
    e.preventDefault();
    setIsDragAccept(true);
  }, []);
  const handleDragLeave = useCallback((e) => {
    e.stopPropagation();
    e.preventDefault();
    setIsDragReject(false);
    setIsDragAccept(false);
  }, []);
  const handleDrop = useCallback((e: React.DragEvent<HTMLDivElement>, orderId: string | null) => {
    e.stopPropagation();
    e.preventDefault();
    const items = e.dataTransfer.items;
    const entry = items[0]?.webkitGetAsEntry();
    if (!entry || entry.isFile) {
      return;
    }
    let useMaterials: { materialFilterValue: string }[] = [];
    const list: { file: File }[] = [];
    const checkFileName = (name1: string, name2: string) => {
      return (name1.split('.').length === 2
        && name1.split('.').length === name2.split('.').length
        && name1.split('.')[0] === name2.split('.')[0]
        && name1.split('.')[name1.split('.').length - 1].toLowerCase() === name2.split('.')[name2.split('.').length - 1].toLowerCase());
    };
    const getEx = (v: string) => (v.split('.').length === 2 && v.split('.')[1]) || '';
    const traverseFileTree = async (entry: any, ignoreDirectory?: boolean) => {
      if (entry.isFile) {
        const file: File = await new Promise((resolve) => {
          entry.file((file: File) => {
            resolve(file);
          });
        });
        if (getEx(file.name).toLowerCase() === 'jpg' || getEx(file.name).toLowerCase() ===  'png') {
          list.push({ file });
        }
      } else if (!ignoreDirectory && entry.isDirectory) {
        const directoryReader = entry.createReader();
        const readEntry = (reader: any) => {
          return  new Promise<any[]>((resolve) => {
            directoryReader.readEntries((entries: any) => {
              resolve(entries);
            });
          });
        };
        const readEntries = async (reader: any) => {
          const result: any[] = [];
          for (;;) {
            const arr: any[] = await readEntry(reader);
            if (!arr.length) {
              break;
            }
            result.push(...arr);
          }
          return result;
        };
        const entries: any = await readEntries(directoryReader);
        for (let i: number = 0; i < entries.length; i++) {
          await traverseFileTree(entries[i], true);
        }
      }
    };
    const getFileSize = (file: File) => {
      return new Promise<{ width: number, height: number }>((resolve: (v: { width: number, height: number }) => void) => {
        const image = new Image();
        image.onload = () => {
          ImageProcessorManager.loadExif(file)
            .then((res) => {
              const size = (() => {
                /* Exif情報からサイズを正しく取得できているときはそのまま返す */
                if (res.width && res.height) {
                  return {width: res.width, height: res.height};
                  /* Exif情報からサイズが取れなかった場合、回転情報を参考に実画像の高さと幅を設定して返す */
                } else {
                  return ExifConverter.getExifSize(res.orientation, image);
                }

              })()
              URL.revokeObjectURL(image.src);
              resolve(size);
            });
        };
        image.onerror = () => {
          resolve({ width: 0, height: 0 });
        };
        image.src = URL.createObjectURL(file);
      });
    }
    const checkSize = async (file: File, check: ImageCheckPostResponse): Promise<{ result: boolean, file: { name: string, size: { width: number, height: number} }, checkSize: { width: number, height: number}}> => {
      const size = await getFileSize(file);
      return ({
        result: size.width === Number(check[0].width) && size.height === Number(check[0].height), // TODO check
        file: { name: file.name, size },
        checkSize: { width: Number(check[0].width), height: Number(check[0].height) }, // TODO check
      });
    };
    const checkAllSize = async (list: { file: File, checkData: ImageCheckPostResponse, materials?: { materialId: string, materialName: string } }[]): Promise<{ result: boolean, file: { name: string, size: { width: number, height: number} }, checkSize: { width: number, height: number}}> => {
      const checkArr = list.map((v) => v.checkData);
      const fileArr = list.map((v) => v.file);
      let size!: { width: number, height: number };
      let checkSize!: { width: number, height: number };
      let file!: File;
      for (let i = 0; i < fileArr.length; i++) {
        file = fileArr[i];
        size = await getFileSize(file);
        // const index = checkArr.findIndex((v) => (v[0].fileName.indexOf('*.') !== -1 ? (file.name.match(v[0].fileName)) : file.name === v[0].fileName) && Number(v[0].width) === size.width && Number(v[0].height) === size.height);
        // const index = checkArr.findIndex((v) => (v[0].fileName.indexOf('*.') !== -1 || file.name === v[0].fileName) && Number(v[0].width) === size.width && Number(v[0].height) === size.height);
        const index = (checkArr[0][0].fileName.indexOf('*.') !== -1 || checkFileName(file.name, checkArr[0][0].fileName)) && Number(checkArr[0][0].width) === size.width && Number(checkArr[0][0].height) === size.height ? 0 : -1;
        if (index !== -1) {
          checkArr.splice(index, 1);
          continue;
        }
        checkSize = {
          width: Number(checkArr?.[0]?.[0]?.width),
          height: Number(checkArr?.[0]?.[0]?.height),
        };
        break;
      }
      return ({
        result: !checkArr.length,
        file: { name: file.name, size },
        checkSize,
      });
    };
    // const materials: { id: string, materialId: string, materialName: string }[] = [];
    const optionImages: { orderInfoDataId: string, file: File, materials?: { materialId: string, materialName: string } }[] = [];
    let imagesCheck: ImageCheckPostResponse[] = [];
    let isFreeImage = false;
    const getErrorMessage = () => {
      return new Promise<string[]>(async (resolve: (errorMessage: string[]) => void) => {
        // バリデーション
        const errorMessage: string[] = [];
        const imageList: { file: File, checkData: ImageCheckPostResponse, materials?: { materialId: string, materialName: string } }[] = [];
        const exList = [...list.sort((a, b) => a.file.name > b.file.name ? 1 : -1)];
        const optionErrorMessage: string[] = [];
        // const optionImageList: { file: File, checkData: ImageCheckPostResponse }[] = [];
        useMaterials = (orderInfoData.viewModel.item?.id && orderInfoData.viewModel.boardInfo)
          ? await new ApiFilters({ productNameId: orderInfoData.viewModel.item?.id })
            .do()
            .then((res) => (res as ResponseBase<FiltersResponse>)?.body?.data?.filters?.find((v) => v.tagKeyword === orderInfoData.viewModel.boardInfo)?.materials || [])
            .catch(() => [])
          : [];
        imagesCheck = await (() => {
          return new Promise<ImageCheckPostResponse[]>((resolve) => {
            dispatch(apiActions.run(
              new ApiImageCheckPost({
                kijshopCd,
                goodsId: orderInfoData.viewModel.item?.id || '',
                page: Number(orderInfoData?.orderParts?.orderPartsDataMain()?.viewModel.pageCount || 0),
              }),
              {
                onSuccess: (res: ResponseBase<ImageCheckPostResponse[]>) => {
                  const arr: ImageCheckPostResponse[] = [];
                  arr[0] = [];
                  const data = res.body.data;
                  if (data?.[0]?.[0]?.fileName?.split('.')[0] === '*') {
                    let copyIndex = data[0].length - 1;
                    let endIndex = 999;
                    const pageDataArr = orderInfoData.orderParts?.orderPartsDataArr?.flatMap((partsData) => partsData?.orderPage?.orderPageDataArr || []) || [];
                    /* 表紙 */
                    if (pageTypeList.cover.find((v) => v.pageTypeID === pageDataArr[0]?.viewModel.pageType) && data[0].length - 1 > copyIndex) {
                    // if (COVER_LIST.find((v) => v.pageTypeID === pageDataArr[0]?.viewModel.pageType) && data[0].length - 1 > copyIndex) {
                      copyIndex++;
                    }
                    /* TOP */
                    if (pageTypeList.top.find((v) => v.pageTypeID === pageDataArr[0]?.viewModel.pageType) && data[0].length - 1 > copyIndex) {
                    // if (pageDataArr[0]?.viewModel.pageType === 'jptg380013' && data[0].length - 1 > copyIndex) {
                      copyIndex++;
                    }
                    /* END */
                    if (pageTypeList.cover.find((v) => v.pageTypeID === pageDataArr[pageDataArr.length - 1]?.viewModel.pageType)) {
                    // if (pageDataArr[pageDataArr.length - 1]?.viewModel.pageType === 'jptg380015') {
                      endIndex = pageDataArr.length - 1;
                    }
                    for (let i = 0; i < data[0].length; i++) {
                      if (data[0][i].fileName.split('.')[0] === '*') {
                        data[0][i].fileName = `.${data[0][i].fileName}`;
                      }
                    }
                    arr[0].push(data[0][0]);
                    while (arr[0].length < pageDataArr.length) {
                      arr[0].push(data[0][copyIndex]);
                      if (arr[0].length === endIndex) {
                        arr[0].push(data[0][data[0].length - 1]);
                      }
                    }
                    isFreeImage = true;
                  } else {
                    res.body.data?.forEach((imageCheckArr, i) => {
                      arr[i] = [];
                      imageCheckArr.forEach((imageCheck) => {
                        if (!arr[i].find((v) => checkFileName(v.fileName, imageCheck.fileName))) {
                          arr[i].push(imageCheck);
                        }
                      });
                    });
                  }
                  resolve(arr);
                },
                onError: () => {
                  resolve([]);
                },
              },
            ));
          });
        })();
        if (!imagesCheck.length) {
          resolve(['エラーが発生しました。']);
          return;
        }
        const optionImagesCheck = await (() => {
          return new Promise<{
            orderInfoDataId: string,
            checkRes: ImageCheckPostResponse[],
          }[]>((resolve) => {
            if (!orderInfoData.optionOrderInfoDataXml.length) {
              resolve([]);
              return;
            }
            const checkResArr: {
              orderInfoDataId: string,
              checkRes: ImageCheckPostResponse[],
            }[] = [];
            let cnt = orderInfoData.optionOrderInfoDataXml.length;
            orderInfoData.optionOrderInfoDataXml.forEach((v) => {
              const getOpErrorMsg = (retryCount: number = 0) => {
                dispatch(apiActions.run(
                  new ApiImageCheckPost({
                    kijshopCd,
                    goodsId: v.viewModel.item?.id || '',
                    page: Number(v?.orderParts?.orderPartsDataMain()?.viewModel.pageCount) || 99,
                  }),
                  {
                    onSuccess: (opRes: ResponseBase<ImageCheckPostResponse[]>) => {
                      cnt--;
                      const data = opRes.body.data;
                      if (data?.[0]?.length) {
                        checkResArr.push({
                          orderInfoDataId: v.metaModel.id || '',
                          checkRes: data,
                        });
                      }
                      if (!cnt) {
                        resolve(checkResArr);
                      }
                    },
                    onError: () => {
                      if (retryCount < 3) {
                        getOpErrorMsg(retryCount + 1);
                      } else {
                          resolve([]);
                          dispatch(dialogActions.pushSystemError());
                      }
                    },
                  },
                  {
                    ignoreSystemError: true,
                  },
                ));
              }
              getOpErrorMsg();
              // dispatch(apiActions.run(
              //   new ApiImageCheckPost({
              //     kijshopCd,
              //     goodsId: v.viewModel.item?.id || '',
              //     page: Number(v?.orderParts?.orderPartsDataMain()?.viewModel.pageCount) || 99,
              //   }),
              //   {
              //     onSuccess: (opRes: ResponseBase<ImageCheckPostResponse[]>) => {
              //       cnt--;
              //       const data = opRes.body.data;
              //       if (data?.[0]?.length) {
              //         checkResArr.push({
              //           orderInfoDataId: v.metaModel.id || '',
              //           checkRes: data,
              //         });
              //       }
              //       if (!cnt) {
              //         resolve(checkResArr);
              //       }
              //     },
              //     onError: () => {
              //       // TODO リトライ
              //
              //     },
              //   },
              //   {
              //     ignoreSystemError: true,
              //   },
              // ));
            });
          });
        })();
        let appendedDataErrorMessage: string = '';
        // let optionPageNameArr: string[] = [];
        // if (xml?.xml.optionOrderInfoDataXml.length){
        //   xml?.xml.optionOrderInfoDataXml.forEach((v) => {
        //     v.orderParts?.orderPartsDataArr.forEach((vv) => {
        //       vv.orderPage?.orderPageDataArr.forEach((vvv) => {
        //         optionPageNameArr.push((vvv.viewModel.displayPageNo && vvv.viewModel.displayPageNo !== '***') ? vvv.viewModel.displayPageType + vvv.viewModel.displayPageNo + 'ページ' : vvv.viewModel.displayPageType || '');
        //       })
        //     })
        //   });
        // }
        // optionPageNameArr = optionPageNameArr.filter((v) => v !== '' && (v !== '表紙' ? v.match(/[0-9]/): true));
        const getOptionPageName = (id: string): string => {
          if (xml?.xml.optionOrderInfoDataXml.length) {
            const data = xml?.xml.optionOrderInfoDataXml.find((v) => v.metaModel.id === id);
            if (data) {
              return data?.orderParts?.orderPartsDataArr?.[0].orderPage?.orderPageDataArr?.[0].viewModel.displayPageType || 'オプション';
            } else {
              return 'オプション';
            }
          } else {
            return 'オプション';
          }
        }
        optionImagesCheck.forEach((checkDataArr) => {
          checkDataArr.checkRes.forEach((imageArr) => {
            imageArr.forEach((v, i) => {
              if (v.appendedPageData) {
                v.appendedPageData.forEach((appendedData) => {
                  const index = exList.findIndex((image) => (!useMaterials.length || useMaterials.find((m) => m.materialFilterValue === appendedData.material)) && checkFileName(image.file.name, appendedData.fileName));
                  if (index !== -1) {
                    const file = exList[index];
                    optionImages.push({ orderInfoDataId: checkDataArr.orderInfoDataId, file: file.file, materials: { materialId: appendedData.material, materialName: appendedData.material} });
                    exList.splice(index, 1);
                  } else {
                    // appendedDataErrorMessage = `頁${i + 1}ページの完成画像が見つかりませんでした。`
                    optionErrorMessage.push(`頁${i + 1}ページの完成画像が見つかりませんでした。`);
                  }
                });
              } else {
                // const index = exList.findIndex((image) => v.fileName.indexOf('*.') !== -1 ? (image.file.name.match(v.fileName)) : image.file.name === v.fileName);
                const index = exList.findIndex((image) => v.fileName.indexOf('*.') !== -1 || checkFileName(image.file.name, v.fileName));
                if (index === -1) {
                  if (isFreeImage) {
                    appendedDataErrorMessage = `ﾌﾟﾘﾝﾄ${i + 1}ページの完成画像が見つかりませんでした。`
                  } else {
                    optionErrorMessage.push(`${getOptionPageName(checkDataArr.orderInfoDataId)}の完成画像『${v.fileName}』が見つかりませんでした。`);
                  }
                } else {
                  const file = exList[index];
                  optionImages.push({ orderInfoDataId: checkDataArr.orderInfoDataId, file: file.file });
                  imageList.push({ file: exList[index].file, checkData: [v] });
                  exList.splice(index, 1);
                }
              }
            });
          });
        });
        // const imageArr = imagesCheck.find((data) => list.find((v) => data.find((d) => d.fileName.indexOf('*.') !== -1 ? v.file.name.match(d.fileName) : v.file.name === d.fileName))) || imagesCheck[0];
        const imageArr = imagesCheck.find((data) => list.find((v) => data.find((d) => d.fileName.indexOf('*.') !== -1 || checkFileName(v.file.name, d.fileName)))) || imagesCheck[0];
        if (optionImagesCheck.length) {
          optionImagesCheck.forEach((optionCheck) => {
            // const index = exList.findIndex((data) => optionCheck.checkRes.find((option) => option.find((v) => v.fileName.indexOf('*.') !== -1 ? data.file.name.match(v.fileName) :  data.file.name === v.fileName)));
            const index = exList.findIndex((data) => optionCheck.checkRes.find((option) => option.find((v) => v.fileName.indexOf('*.') !== -1 ||  checkFileName(data.file.name, v.fileName))));
            if (index !== -1) {
              exList.splice(index, 1);
            }
          });
        }
        let parentAppendedDataErrorMessage = '';
        let pageNameArr: string[] = [];
        xml?.parts?.partsData?.forEach((v) => {
          v.page?.pageData?.forEach((vv) => {
            pageNameArr.push((vv.viewModel.displayPageNo && vv.viewModel.displayPageNo !== '***') ? vv.viewModel.displayPageType + vv.viewModel.displayPageNo + 'ページ' : vv.viewModel.displayPageType || '');
          })
        });
        pageNameArr = pageNameArr.filter((v) => v !== '' && (v.match(/表紙/) ? true: v.match(/[0-9]/)));
        imageArr.forEach((v, i) => {
          if (!parentAppendedDataErrorMessage) {
            if (v.appendedPageData) {
              const index = exList.findIndex((file) => {
                const material = v.appendedPageData?.find((data) => (!useMaterials.length || useMaterials.find((m) => m.materialFilterValue === data.material)) && checkFileName(file.file.name, data.fileName));
                if (material) {
                  // materials.push({ id: `${materials.length + 1}`, materialId: material.material, materialName: material.material });
                  imageList.push({ file: file.file, checkData: [v], materials: { materialId: material.material, materialName: material.material } });
                  return true;
                }
                return false;
              });
              if (index === -1) {
                // parentAppendedDataErrorMessage = `頁${i + 1}ページの完成画像が見つかりませんでした。`
                errorMessage.push(`頁${i + 1}ページの完成画像が見つかりませんでした。`);
              } else {
                // imageList.push({ file: exList[index].file, checkData: [v] });
                exList.splice(index, 1);
              }
            } else {
              const index = exList.findIndex((file) => v.fileName.indexOf('*.') !== -1 || checkFileName(file.file.name, v.fileName));
              if (index === -1) {
                if (isFreeImage) {
                  parentAppendedDataErrorMessage = `ﾌﾟﾘﾝﾄ${i + 1}ページの完成画像が見つかりませんでした。`
                } else {
                  // console.group('error: ', v.fileName);
                  // console.log(lodash.cloneDeep(exList));
                  // console.groupEnd();
                  errorMessage.push(`${pageNameArr[i]}の完成画像『${v.fileName}』が見つかりませんでした。`);
                }
              } else {
                imageList.push({ file: exList[index].file, checkData: [v] }); // TODO check
                exList.splice(index, 1);
              }
            }
          }
          // if (!currentErrorMessage.length && !currentExList.length && currentImageList.length && !imageList.length) {
          //   exList.splice(0, exList.length);
          //   imageList.push(...optionImageList, ...currentImageList);
          //   materials.push(...currentMaterials);
          //   errorMessage.splice(0, errorMessage.length);
          // }
        });
        if (parentAppendedDataErrorMessage) {
          appendedDataErrorMessage = parentAppendedDataErrorMessage;
        }
        errorMessage.push(...optionErrorMessage);
        // imagesCheck?.[0]?.forEach((v) => {
        //   const index = exList.findIndex((file) => file && v.fileName.indexOf('*.') !== -1 ? file.file.name.match(v.fileName) : file.file.name === v.fileName);
        //   if (index !== -1) {
        //     exList.splice(index, 1);
        //   }
        // });
        if (appendedDataErrorMessage) {
          resolve([appendedDataErrorMessage]);
          return;
        }
        if (!errorMessage.length && !exList.length) {
          Promise
            .all(isFreeImage ? [checkAllSize(imageList)] : [...imageList].map((v) => checkSize(v.file, v.checkData)))
            .then((resArr) => {
              let flag = true;
              resArr.forEach((res) => {
                if (flag && !res.result) {
                  flag = false;
                  resolve([
                    `画像のサイズが${res.checkSize.width}×${res.checkSize.height}ではありません`,
                    '',
                    `下記の画像のサイズは${res.file.size.width}×${res.file.size.height}になっています。`,
                    `${res.file.name}`,
                  ]);
                }
              });
              if (flag) {
                resolve([]);
              }
            });
        } else {
          const message = errorMessage.length ? [
            ...errorMessage,
          ] : [
            '下記の使用されないファイルが含まれています。',
            '',
            ...[...exList].map((v) => `・${v.file.name}`),
          ];
          resolve(message);
        }
      });
    };
    setChecking(true);
    traverseFileTree(entry)
      .then(getErrorMessage)
      .then((message) => {
        setChecking(false);
        if ([...list].length === 0) {
          dispatch(dialogActions.pushMessage({
            title: '確認',
            message: ['指定された画像フォルダの中身が空です。','完成画像ファイルの入った一つのフォルダをドラッグドロップしてください。'],
            buttons: [
              {
                label: 'OK',
                callback: () => {
                  dispatch(dialogActions.pop());
                },
              },
            ],
          }));
          return;
        }
        if (message.length) {
          dispatch(dialogActions.pushMessage({
            title: '確認',
            message,
            buttons: [
              {
                label: 'はい',
                callback: () => {
                  dispatch(dialogActions.pop());
                },
              },
            ],
          }));
        } else {
          // xml 更新
          const _images: { file: File }[] = [...list].filter((v) => getEx(v.file.name)).map((v) => v);
          if (optionImages.length) {
            optionImages.forEach((option) => {
              const index = _images.findIndex((v) => checkFileName(v.file.name, option.file.name));
              if (index !== -1) {
                _images.splice(index, 1);
              }
            });
          }
          // images.sort((a, b) => imagesCheck[0].findIndex((v) => v.fileName === a.name) - imagesCheck[0].findIndex((v) => v.fileName === b.name)); // TODO check
          const arr = _images.map((v) => {
            let materials!: { materialId: string, materialName: string };
            const index = imagesCheck[0].findIndex((v2) => {
              const _materials = v2.appendedPageData?.find((v3) => (!useMaterials.length || useMaterials.find((m) => m.materialFilterValue === v3.material)) && checkFileName(v3.fileName, v.file.name))
              if (_materials) {
                materials = { materialId: _materials.material, materialName: _materials.material };
                return true;
              }
              if (isFreeImage || checkFileName(v2.fileName, v.file.name)) {
                return true;
              }
              return false;
            });
            return { index: index, file: v, materials };
          });
          if (!isFreeImage) {
            arr.sort((a, b) => {
              if (a.index > b.index) {
                return 1;
              } else {
                return -1
              }
            });
          }
          const images: { file: File, materials: { materialId: string, materialName: string } }[] = arr.map((v) => ({ file: v.file.file, materials: v.materials }));
          let rgbFlg: boolean = false;
          setUploadComplete(false);
          setConnecting(true);
          // window.onbeforeunload = () => {
          //   return 'データの更新中です';
          // };
          dispatch(xmlActions.uploadImage({ kijshopCd, shopOrderId }).comp(
            orderId || '',
            images,
            {
              success: (images) => {
                setImageDataList([]);
                let cnt = images.length;
                UiManager.ins.emit(
                  'r->l:add-image-comp',
                  {
                    // files: [...useFiles].map((v) => v),
                    files: images,
                    kijshopCd,
                    shopOrderId,
                    orderId: orderId || null,
                    callback: (e) => {
                      cnt--;
                      if (!cnt) {
                        window.onbeforeunload = null;
                        // setTimeout(() => {
                        setConnecting(false);
                        setUploadComplete(true); // TODO
                        setFailToLoad(false);
                        dispatch(orderPreparationActions.clearImageLessOrder(orderId || orderInfoData.metaModel.id || ''));
                        // }, 2000);
                        // dispatch(apiActions.run(
                        //   new ApiImagesGet({
                        //     kijshopCd,
                        //     shopOrderId,
                        //     kind: '1',
                        //   }),
                        //   {
                        //     onSuccess: (res) => {
                        //       dispatch(orderPreparationActions.setCompImageList((res as ResponseBase<ImagesGetResponse[]>)?.body?.data || []));
                        //     },
                        //   },
                        // ));
                      }
                      if (!rgbFlg && String(e.editableImage.exif.colorSpace) !== '1') {
                        rgbFlg = true;
                        dispatch(dialogActions.pushMessage({
                          title: '確認',
                          message: !e.editableImage.exif.colorSpace ? [
                            'Exifヘッダ情報に色空間の情報がありません。',
                            '色空間がsRGBでない場合、色が変わる可能性があります。',
                          ] : [
                            'sRGB以外の色空間が指定されています。',
                            '色空間がsRGBでない場合、色が変わる可能性があります。',
                          ],
                          buttons: [{
                            label: 'OK',
                            callback: () => {
                              dispatch(dialogActions.pop());
                            },
                          }],
                        }));
                      }
                      // EditableImageManager.ins.toUse(e.editableImage.id);
                    },
                  },
                );
              },
              error: () => {
                setConnecting(false);
                window.onbeforeunload = null;
              },
            },
            optionImages,
          ));
          setIsDragReject(false);
          setIsDragAccept(false);
        }
      });
  // }, [thumbPropsList, kijshopCd, shopOrderId, page, imagesCheck, optionImagesCheck]);
  }, [thumbPropsList, kijshopCd, shopOrderId, page, orderPageDataArr]);
  const onChangeThumbPropsList = useCallback((editableImages: EditableImage[]) => {
    const list = [
      ...thumbPropsList,
      ...editableImages.map((v) => ({ src: v.thumbnailBase64, label: v.name })),
    ];
    list.sort((a, b) => a.label > b.label ? 1 : -1);
    setThumbPropsList(list);
  }, [thumbPropsList]);
  // - Effect -
  useEffect(() => {
    onGetTotalNum(thumbPropsList.length);
  }, [thumbPropsList]);
  // -- did mount --
  // useEffect(() => {
  //   const tmpHandler = (e: { list: EditableImage[] }) => {
  //     onChangeThumbPropsList(e.list);
  //   };
  //   UiManager.ins.on('l->r:change-editable-image-list', tmpHandler);
  //   return () => {
  //     UiManager.ins.off('l->r:change-editable-image-list', tmpHandler);
  //   };
  // }, []);
  // useEffect(() => {
  //   if (init) {
  //     return;
  //   }
  //   if (orderId) {
  //     if (orderId) {
  //       const id = UuidGenerator.create();
  //       dispatch(apiActions.addConnect({ id, name: 'RestoreEIM restoreAll()'}));
  //       RestoreEditableImageManager.ins.restoreAll(kijshopCd, shopOrderId, orderId)
  //         .then(() => {
  //           dispatch(apiActions.removeConnect(id));
  //         });
  //     }
  //   }
  // }, [orderId, init]);
  useEffect(() => {
    if (init) {
      return;
    }
    if (uploadComplete && !imageDataList.length) {
      setUploadComplete(false);
      let cnt = 0;
      const filterOrderPageDataArr = orderPageDataArr.filter((v) => v.viewModel.compositeFileName !== undefined);
      setImageDataList(filterOrderPageDataArr.map((v, i) => {
        if (v.viewModel.compositeFileName?.realPath) {
          const getOriginalImage = () => {
            dispatch(apiActions.run(
              new ApiImagesGetOne({
                kijshopCd,
                path: `${kijshopCd}/${shopOrderId}/${v.viewModel.compositeFileName?.realPath}`,
              }),
              {
                onSuccess: (res) => {
                  let orientation!: Orientation;
                  const reader = new FileReader();
                  getOrientation(res)
                    .then((o) => {
                      orientation = o;
                      reader.readAsDataURL(res);
                    });
                  reader.onload = () => {
                    cnt--;
                    setImageDataList((prev) => {
                      prev[i].path = reader.result as string;
                      prev[i].orientation = orientation;
                      if (!cnt && orderId) {
                        setTimeout(() => {
                          dispatch(orderPreparationActions.setCompImage({ orderId, images: [...prev] }));
                        });
                      }
                      return [...prev];
                    });
                  };
                },
              },
            ));
          };
          cnt++;
          dispatch(apiActions.run(
            new ApiImagesGetOne({
              kijshopCd,
              path: `${kijshopCd}/uploadThumb/${shopOrderId}/${v.viewModel.compositeFileName?.realPath.replace('Comp_Real/', '')}`,
            }),
            {
              onSuccess: (res: Blob) => {
                if (res.type === 'application/json') {
                  getOriginalImage();
                } else {
                  let orientation!: Orientation;
                  const reader = new FileReader();
                  new ApiImagesGetOne({
                    kijshopCd,
                    path: `${kijshopCd}/${shopOrderId}/${v.viewModel.compositeFileName?.realPath}`,
                  })
                    .do()
                    .then((originRes: Blob) => {
                      return getOrientation(originRes)
                        .then((o) => {
                          orientation = o;
                          reader.readAsDataURL(res);
                        });
                    })
                    .catch(() => {
                      reader.readAsDataURL(res);
                    });
                  reader.onload = () => {
                    cnt--;
                    setImageDataList((prev) => {
                      if (prev[i]){
                        prev[i].path = reader.result as string;
                        prev[i].orientation = orientation;
                      }
                      if (!cnt && orderId) {
                        setTimeout(() => {
                          dispatch(orderPreparationActions.setCompImage({ orderId, images: [...prev] }));
                        });
                      }
                      return [...prev];
                    });
                  };
                }
              },
            },
          ));
          return { name: v.viewModel.displayPageNo === '***' ? (v.viewModel.displayPageType || '') : `${v.viewModel.displayPageNo || ''}頁`, path: '' };
        }
        return null;
      }).filter((v) => !!v) as { name: string, path: string }[]);
    }
  }, [orderInfoDataArr, orderPageDataArr, uploadComplete, init]);
  useEffect(() => {
    callbackChangeImageNum(imageDataList.length);
  }, [imageDataList.length]);
  useEffect(() => {
    if (init && selectedOrderIdArr.find((v) => v === orderInfoData?.metaModel?.id)) {
      setInit(false);
    }
  }, [selectedOrderIdArr]);
  useEffect(() => {
    if (!init) {
      setInit(true);
      setImageDataList([]);
      setUploadComplete(true);
    }
  }, [reset]);

  return selectedOrderIdArr.find((v) => v === orderInfoData?.metaModel?.id) ? (
    <div className="folder_drop_zone">
      <div className="folder_drop_zone__contents">
        {ordering ? (<></>) : (
          <div className="folder_drop_zone__drop__wrap">
            <div
              className={`folder_drop_zone__drop ${isDragAccept ? "drag_accept" : ""} ${isDragReject ? "drag_reject" : ""}`}
              onDragOver={handleDragOver}
              onDrop={(e) => handleDrop(e, props.orderId)}
              onDragLeave={handleDragLeave}
            >
              {/*<span>「レイアウト」すると生産画像が表示されます。</span>*/}
              <span>ここに完成画像フォルダをドラック＆ドロップしてください。</span>
            </div>
          </div>
        )}
        {/* アップロードした画像のリスト */}
        {/*{imageDataList.filter((v) => v.path).length > 0 ? (*/}
        {imageDataList.length > 0 ? (
        // {imageDataList.length > 0 ? (
          <div className="folder_drop_zone__list">
            <div className="folder_drop_zone__list__thumbs">
              {/*{imageDataList.filter((v) => v.path).map((v, i) => (*/}
              {imageDataList.map((v, i) => (
              // {imageDataList.map((v, i) => (
                <div
                  key={`folder-drop-zone-image_${v?.path || ''}_${i}`}
                  className="folder_drop_zone__list__thumb"
                >
                  <div className="folder_drop_zone__list__thumb__label">
                    {/*<span>{pageNameConverter(v.name)}</span>*/}
                    <span>{v?.name || ''}</span>
                  </div>
                  {/*{v?.path ? (*/}
                    <Thumbnail
                      key={`complete-image-thumbnail_${orderId}_${i}_${v?.path || ''}`}
                      // thumbEle={<ImageComponent src={v.path} alt="" containerAspectRatio={1.5} />}
                      thumbEle={v?.path ? <ImageComponent src={v.path} alt="" containerAspectRatio={1.5} orientation={v.orientation} /> : <></>}
                      xmlParams={{
                        kijshopCd,
                        shopOrderId,
                        orderId: orderInfoData.metaModel.id || '',
                        outputCount: orderInfoData.viewModel.goods?.productType === 'jptg300164'
                        && orderInfoData.orderPageDataArr.length > i
                          ? Number(orderInfoData.orderPageDataArr[i]?.viewModel.pageOutputCount) : undefined,
                      }}
                      index={i}
                      // label={v.label}
                    />
                  {/*) : (<div></div>)}*/}
                </div>
              ))}
            </div>
          </div>
        ) : <></>}
      </div>
      {connecting ? (<LoadingPopup label={'データを保存中です...'} />) : (<></>)}
      {checking ? (<LoadingPopup label={'画像を確認中です...'} />) : (<></>)}
    </div>
  ) : (<></>);
};

FolderDropZone.defaultProps = {
  orderId: null,
};
