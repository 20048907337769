import {
  WizardButton,
  WizardButtonList,
  WizardContent,
  WizardInput,
  WizardInputList,
} from '../../../ui/wizard/wizard-content';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { RoutingPath } from '../../../../routes/routing-path';
import { apiActions } from '../../../../slices/api-slice';
import { ApiCheckTokenExpiry } from '../../../../api/front/wizard/api-check-token-expiry';
import { dialogActions } from '../../../dialog/slice/dialog-slice';
import { ApiPasswordResetting } from '../../../../api/front/wizard/api-staff-password-reset';

type Props = {
  setStep: (step: number) => void;
  token: string,
}
type TooltipType = 'password' | 'passwordConfirm' | 'none';

export const Step3AdminPasswordResetWizard = (props: Props) => {
  const { setStep, token } = props;
  // - Hooks -
  const dispatch = useDispatch();
  // - State -
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [isPasswordError, setIsPasswordError] = useState(false);
  const [isPasswordConfirmError, setIsPasswordConfirmError] = useState(false);
  const [showTooltip, setShowTooltip] = useState<TooltipType>('none');
  // - Handlers -
  // -- バリデーション --
  const handlerValidation = useCallback(() => {
    // 大文字・小文字・数字 のうち２種類で7文字
    const regex1 = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[A-Za-z0-9]{7}$/;
    const regex2 = /^(?=.*[a-z])(?=.*[A-Z])[A-Za-z]{7}$/;
    const regex3 = /^(?=.*[a-z])(?=.*[0-9])[a-z0-9]{7}$/;
    const regex4 = /^(?=.*[A-Z])(?=.*[0-9])[A-Z0-9]{7}$/;
    const isPasswordValid = password.length === 7 && (regex1.test(password) || regex2.test(password) || regex3.test(password) || regex4.test(password));
    const isPasswordConfirmValid = passwordConfirm.length === 7 && password === passwordConfirm;
    setIsPasswordError(!isPasswordValid);
    setIsPasswordConfirmError(!isPasswordConfirmValid);
    return isPasswordValid && isPasswordConfirmValid;
  }, [password, passwordConfirm]);
  // 変更ボタン
  const handleOnClickChange = useCallback(() => {
    if (!handlerValidation()) {
      dispatch(dialogActions.pushMessage({
        title: '確認',
        message: ['入力内容に不備があります。'],
        buttons: [
          {
            label: 'OK',
            callback: () => {
              dispatch(dialogActions.pop());
            },
          },
        ],
      }));
      return;
    }
    dispatch(apiActions.run(
      new ApiPasswordResetting({
        userType: false,
        token,
        password,
        password_confirmation: passwordConfirm,
      }),
      {
        onSuccess: () => {
          setStep(4);
        },
        onBasicError: (error) => {
          dispatch(dialogActions.pushMessage({
            title: '確認',
            message: error.messages,
            buttons: [
              {
                label: 'OK',
                callback: () => {
                  dispatch(dialogActions.pop());
                },
              },
            ],
          }));
        },
      }
    ))
  }, [password, passwordConfirm]);
  const handlerChangePassword = useCallback((value: string) => {
    setPassword(value);
  }, []);
  const handlerChangePasswordConfirm = useCallback((value: string) => {
    setPasswordConfirm(value);
  }, []);
  const handlerToggleShowTooltip = useCallback((type: TooltipType) => {
    setShowTooltip(type);
  }, []);
  // - Effects -
  useEffect(() => {
    if (token === '') {
      dispatch(push(RoutingPath.login));
    }
    dispatch(apiActions.run(
      new ApiCheckTokenExpiry({ token }),
      {
        onSuccess: (res) => {
          if (res.error.errorCode === 404) {
            dispatch(dialogActions.pushMessage({
              title: 'エラー',
              message: ['トークンの有効期限が切れています。', 'ログイン画面に戻ります'],
              buttons: [
                {
                  label: 'OK',
                  callback: () => {
                    dispatch(dialogActions.pop());
                    dispatch(push(RoutingPath.login));
                  },
                },
              ],
            }));
          }
        },
      }),
    );
  },[]);
  return (
    <WizardContent>
      <WizardInputList>
        <WizardInput
          label="新しいパスワード（７文字）"
          value={password}
          disabled={false}
          isPassword={true}
          maxLength={7}
          error={isPasswordError}
          descBottom={'※大文字・小文字・数字から２種類以上で７文字'}
          tooltip={isPasswordError && showTooltip === 'password' ?
            { messages: ['大文字、小文字、数字のうち2種類以上を含む7文字'] } : undefined}
          onChange={(e) => handlerChangePassword(e.target.value)}
          onMouseOver={() => handlerToggleShowTooltip('password')}
          onMouseLeave={() => handlerToggleShowTooltip('none')}
        />
        <WizardInput
          label="新しいパスワード（再入力）"
          value={passwordConfirm}
          disabled={false}
          isPassword={true}
          maxLength={7}
          error={isPasswordConfirmError}
          tooltip={isPasswordConfirmError && showTooltip === 'passwordConfirm'?
            { messages: ['パスワードが一致しません'] } : undefined}
          onChange={(e) => handlerChangePasswordConfirm(e.target.value)}
          onMouseOver={() => handlerToggleShowTooltip('passwordConfirm')}
          onMouseLeave={() => handlerToggleShowTooltip('none')}
        />
      </WizardInputList>
      <WizardButtonList>
        <WizardButton
          label="変更"
          onClick={handleOnClickChange}
        />
      </WizardButtonList>
    </WizardContent>
  );
};