import { RequestKijshopcdOnly } from '../../../models/api/front/staff-management';
import { ApiBaseFront } from '../api-base-front';

export class ApiShopPasswordReset extends ApiBaseFront {
  constructor(param: RequestKijshopcdOnly) {
    super(
      'POST',
      '/api/v1/shop/mail/reset_password',
      'JSON',
      param,
    );
  };
}