import {
  WizardBody,
  WizardBodyList,
  WizardButton,
  WizardButtonList,
  WizardContent,
} from '../../../ui/wizard/wizard-content';
import { useParams } from 'react-router-dom';
import { PathParams, RoutingPath } from '../../../../routes/routing-path';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { useEffect } from 'react';
import { apiActions } from '../../../../slices/api-slice';
import { ApiCheckTokenExpiry } from '../../../../api/front/wizard/api-check-token-expiry';
import { dialogActions } from '../../../dialog/slice/dialog-slice';
import { ApiPostShopMailAddressChange } from '../../../../api/front/wizard/api-staff-management';

type Props = {
  token: string;
};

export const Step4StaffMngMailChangeWizard = (props: Props) => {
  const { token } = props;
  // - Hooks -
  const { kijshopCd } = useParams<PathParams>();
  const dispatch = useDispatch();
  // - Effects -
  useEffect(() => {
    if (token === '') {
      dispatch(push(RoutingPath.cnv.configSystem({ kijshopCd })));
    }
    dispatch(apiActions.run(
      new ApiCheckTokenExpiry({ token }),
      {
        onSuccess: (res) => {
          dispatch(apiActions.run(
            new ApiPostShopMailAddressChange({ token }),
            {
              onSuccess: () => {
                console.log('mail address change success');
              },
              onBasicError:() => {
                dispatch(dialogActions.pushMessage({
                  title: 'エラー',
                  message: ['トークンの有効期限が切れています。','システム設定画面に戻ります'],
                  buttons: [
                    {
                      label: 'OK',
                      callback: () => {
                        dispatch(dialogActions.pop());
                        dispatch(push(RoutingPath.cnv.configSystem({ kijshopCd })));
                      },
                    },
                  ],
                }));
              }
            }
          ))
          if(res.error.errorCode === 404){
            dispatch(dialogActions.pushMessage({
              title: 'エラー',
              message: ['トークンの有効期限が切れています。','システム設定画面に戻ります'],
              buttons: [
                {
                  label: 'OK',
                  callback: () => {
                    dispatch(dialogActions.pop());
                    dispatch(push(RoutingPath.cnv.configSystem({ kijshopCd })));
                  },
                },
              ],
            }));
          }
        },
      }),
    );
  }, []);
  return (
    <WizardContent>
      <WizardBodyList>
        <WizardBody body={['スタッフ管理メールアドレスを変更しました']} />
      </WizardBodyList>
      <WizardButtonList>
        <WizardButton
          label="閉じる"
          onClick={() => dispatch(push(RoutingPath.cnv.configSystem({ kijshopCd })))}
        />
      </WizardButtonList>
    </WizardContent>
  );
};