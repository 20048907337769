import {
  FavoriteGetFolderResponse,
  FavoriteGetResponse, FavoritePostEditRequest,
  FavoritePostRegisterRequest,
} from '../../../models/api/front/favorite';
import { ApiBaseFront } from "../api-base-front";

export class ApiFavoriteFolderListGet extends ApiBaseFront<FavoriteGetFolderResponse> {
  constructor(param: { kijshopCd: string }) {
    super(
      'GET',
      `/api/v1/favfolder`,
      'JSON',
      { ...param, kind: '0' },
    );
  }
}

// お気に入り商品一覧取得
export class ApiFavoriteGet extends ApiBaseFront<FavoriteGetResponse[]> {
  constructor(param: { kijshopCd: string, folederId: string }) {
    super(
      'GET',
      `/api/v1/favorite`,
      'JSON',
      { ...param, kind: '0' },
    );
  }
}

export type FavoriteDataGetResponse = {
  xmlList: {
    filename: string,
    xml: string,
  }[],
};
// お気に入り商品情報取得
export class ApiFavoriteDataGet extends ApiBaseFront<FavoriteDataGetResponse> {
  constructor(_param: { kijshopCd: string, favProductId: string }) {
    const { favProductId, ...param } = _param;
    super(
      'GET',
      `/api/v1/favorite/${favProductId}`,
      'JSON',
      param,
    );
  }
}

// お気に入り商品追加/更新
export class ApiFavoritePostRegister extends ApiBaseFront {
  constructor(param: FavoritePostRegisterRequest) {
    super(
      'POST',
      '/api/v1/favorite',
      'JSON',
      param,
    );
  }
}

// お気に入り商品更新
export class ApiFavoritePostEdit extends ApiBaseFront {
  constructor(param: FavoritePostEditRequest, bookmarkId: string) {
    super(
      'POST',
      `/api/v1/favorite/${bookmarkId}`,
      'JSON',
      { ...param, kind: '0' },
    );
  }
}

// // お気に入り商品名更新
// export class ApiFavoritePut extends ApiBaseFront {
//   constructor(bookmarkId: string, param: FavoritePutRequest) {
//     super(
//       'PUT',
//       `/api/v1/favorite/${bookmarkId}`,
//       'JSON',
//       param,
//     );
//   }
// }

// お気に入り商品削除
export class ApiFavoriteDelete extends ApiBaseFront {
  constructor(bookmarkId: string, param: { kijshopCd: string }) {
    super(
      'DELETE',
      `/api/v1/favorite/${bookmarkId}`,
      'JSON',
      { ...param, kind: '0' },
    );
  }
}

export type FavoriteCopyPostRequest = {
  favProductId: string,
  kijshopCd: string,
  shopOrderId: string,
}

// お気に入り商品画像設置
export class ApiFavoriteCopyPost extends ApiBaseFront {
  constructor(_param: FavoriteCopyPostRequest) {
    const { favProductId, ...param } = _param;
    super(
      'POST',
      `/api/v1/favorite/${favProductId}/copy`,
      'JSON',
      param,
    );
  }
}
