import { AppThunk } from '../../../../app/store';
import { ordersDataActions } from '../../../../slices/orders/orders-data-slice';
import { apiActions } from '../../../../slices/api-slice';
import { ResponseBase } from '../../../response-base';
import { ApiBaseBack } from '../../api-base-back';
import { ProductDetailRequest, ProductDetailResponse } from '../../../../models/api/back/create-order/product-detail';
import { apiCoverCoverColorThunk } from '../cover-colors/api-cover-colors';
import { apiPartsThunk } from '../parts/api-parts';
import { apiOptionItemThunk } from '../option-item/api-option-item';
import { orderRequest, orderRequestOptional } from '../../../../slices/orders-slice';
import { PartsResponse } from '../../../../models/api/back/create-order/parts';

export class ApiProductDetail extends ApiBaseBack<ProductDetailResponse> {
  constructor(param: ProductDetailRequest) {
    super(
      'GET',
      '/api/v1/productDetail',
      'JSON',
      param,
    );
  }
}

export const apiProductDetailThunk = (param: ProductDetailRequest, callback: (res?: ResponseBase<PartsResponse>[] ) => void, isInit: boolean, agent: boolean): AppThunk => async (dispatch, getState) => {
  if (!param.productNameId || !param.serviceId || !param.parentFlg) {
    dispatch(ordersDataActions.updateData({
      productDetail: {},
    }));
    callback?.();
    return;
  }
  dispatch(apiActions.run(
    new ApiProductDetail(param),
    {
      onSuccess: (res: ResponseBase<ProductDetailResponse>) => {
        if (!isInit && param.productNameId !== getState().order.itemDetail.itemSelect.value) {
          return;
        }
        if (!isInit && param.serviceId !== getState().order.itemInfo.category.value) {
          return;
        }
        dispatch(ordersDataActions.updateData({
          productDetail: res.body.data,
        }));
        dispatch(apiPartsThunk({ productNameId: param.productNameId }, callback, res.body.data?.coverTypeId));
        if (res.body.data?.coverTypeId) {
          dispatch(apiCoverCoverColorThunk(
            {
              serviceId: param.serviceId,
              productNameId: param.productNameId,
              coverTypeId: res.body.data.coverTypeId,
            },
            // callback,
            () => {},
            isInit,
          ));
          const order = getState().order;
          const data = getState().orderData;
          const requestOptional = orderRequestOptional(order, getState().createOrder.visibleCondition, data);
          dispatch(apiOptionItemThunk({
            productNameId: param.productNameId,
            coverColorId: requestOptional.coverColorId,
            // coverId: requestOptional.coverColorId,
            coverId: data.data.productDetail.coverTypeId || undefined,
            orderMethodId: requestOptional.orderMethod,
            boardInfoId: requestOptional.boardInfoId,
            pageCountId1: (!data.option.optionPageCount || requestOptional.orderMethod !== '10') ? String(order.genuine.page) : undefined,
            pageCountId2: (data.option.optionPageCount && order.itemDetail.designType2.value) ? String(order.genuine.page) : undefined,
            agentMode: agent ? 'true' : undefined,
          }));
          // } else {
          //   callback?.();
        }
      },
      onError: () => {
        callback?.();
      },
    },
    {
      apiName: 'product-detail',
      ignoreSystemError: true,
    },
  ));
};

export const apiOptionProductDetailThunk = (param: ProductDetailRequest, index: number, callback?: () => void): AppThunk => async (dispatch, getState) => {
  const optionProductDetail = getState().orderData.data.optionProductDetail;
  if (!param.productNameId || !param.serviceId || !param.parentFlg) {
    optionProductDetail[index] = null;
    dispatch(ordersDataActions.updateData({
      optionProductDetail,
    }));
    callback?.();
    return;
  }
  dispatch(apiActions.run(
    new ApiProductDetail(param),
    {
      onSuccess: (res: ResponseBase<ProductDetailResponse>) => {
        optionProductDetail[index] = res.body.data || null;
        dispatch(ordersDataActions.updateData({
          optionProductDetail,
        }));
        // if (res.body.data?.coverTypeId) {
        //   dispatch(apiCoverCoverColorThunk(
        //     {
        //       serviceId: param.serviceId,
        //       productNameId: param.productNameId,
        //       coverTypeId: res.body.data.coverTypeId,
        //     },
        //     callback,
        //   ));
        // } else {
        //   callback?.();
        // }
        callback?.();
      },
      onError: () => {
        callback?.();
      },
    },
    // {
    //   apiName: 'product-detail',
    //   ignoreSystemError: true,
    // },
  ));
};
