
export const processId = {
  // 注文作成
  order: 'jptg380039',
  // レイアウト
  layout: 'jptg380040',
  // 合成
  composite: 'jptg380041',
  // ラボ発注
  labOrder: 'jptg380042',
  // レイアウト確認
  layoutConfirm: 'jptg380076',
  // 予約
  // reserve: '',
  // 画像差し替え
  // replace: '',
};
