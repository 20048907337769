import { Table } from '../../ui/table/table';
import { Button } from '../../ui/button/button';
import { useDispatch } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import { dialogActions } from '../slice/dialog-slice';
import iconDelete from '../../../assets/img/icon/delete.svg';
import './preset-list.scss';
import { useAppSelector } from '../../../app/hooks';
import * as lodash from 'lodash';
import { layoutActions } from '../../../slices/layout-slice';
import { Checkbox } from '../../ui/input/checkbox';
import { apiActions } from '../../../slices/api-slice';
import { localStorageActions } from '../../../slices/local-storage-slice';
import { cloneDeep } from 'lodash';
import { useDidMount } from '../../../hooks/life-cycle';

type PresetListProps = {
  kijshopCd: string,
}
export const PresetList = (props: PresetListProps) => {
  const { kijshopCd } = props;
  // - Hooks -
  const { presetList } = useAppSelector((state) => ({
    presetList: state.storage.data.presetList,
  }), lodash.isEqual);
  const dispatch = useDispatch();
  // - State -
  const [selectPreset, setSelectPreset] = useState(NaN);
  // - Callback -
  const handlerClickCheck = useCallback((num) => {
    const newValue = (Number(num) === selectPreset) ? NaN : Number(num);
    setSelectPreset(newValue);
  }, [selectPreset]);

  // -- 閉じるボタン押下 --
  const handlerClickClose = useCallback(() => {
    dispatch(dialogActions.pop());
  }, []);
  // -- 呼び出しボタン押下 --
  const handlerClickSet = useCallback(() => {
    if (isNaN(selectPreset)) return;
    dispatch(layoutActions.setPreset(presetList[selectPreset]));
    dispatch(dialogActions.pop());
  }, [selectPreset, presetList]);
  // -- 削除ボタン押下 --
  const handlerClickDelete = useCallback((id: number) => {
    dispatch(dialogActions.pushMessage({
      title: 'プリセットの削除',
      message: [`IDが${id + 1}のものを削除します。元に戻りません。`, ' よろしいですか？'],
      buttons: [
        {
          label: 'いいえ', callback: () => {
            dispatch(dialogActions.pop());
          },
        },
        {
          label: 'はい', callback: () => {
            const list = cloneDeep(presetList);
            list.splice(id, 1);
            dispatch(localStorageActions.setData(kijshopCd,'presetList',cloneDeep(list)));
            dispatch(dialogActions.pop());
          },
        },
      ],
    }));
  }, [presetList]);

  useEffect(() => {
    if (!presetList.length) {
      dispatch((dialogActions.pushMessage({
        title: '確認',
        message: [
          `プリセットリストがまだ登録されていません。`,
        ],
        buttons: [
          {
            label: 'OK',
            callback: () => {
              dispatch(dialogActions.popAll());
            },
          },
        ],
      })));
    }
  });

  // - テーブルbodyリスト -
  const body = presetList.map((elm, index) => {
    return [
      <Checkbox
        onClick={(e) => handlerClickCheck(e.currentTarget.value)}
        checked={selectPreset === index}
        value={index}
      />,
      index + 1,
      elm.name,
      elm.windowSize,
      elm.humanScale,
      elm.topSpace,
      elm.bottomSpace,
      <Button
        label="削除"
        icon={<img
          src={iconDelete}
          alt=""
        />}
        onClick={() => handlerClickDelete(presetList.indexOf(elm))}
        color="light"
        size="sm"
      />,
    ];
  });
  return (
    <div className="dialog_contents preset_list">
      <div className="dialog_contents__body">
        <Table
          head={['選択', 'ID', '名前', '中窓高(mm)', '人寸(mm)', '上空き比', '下空き比', '']}
          body={body}
        />
      </div>
      <div>
        <div className="dialog_contents__footer">
          <Button
            label="閉じる"
            onClick={handlerClickClose}
          />
          <Button
            label="呼び出す"
            onClick={handlerClickSet}
          />
        </div>
      </div>
    </div>
  );
};
