import { LoginRequest, LoginRequestForStaff } from '../../models/api/back/login';
import { ResponseCallback } from '../../api/response-callback';
import { AppThunk } from '../../app/store';
import { push } from 'connected-react-router';
import { RoutingPath } from '../../routes/routing-path';
import { ApiLogin } from '../../api/back/login/api-login';
import { apiActions } from '../api-slice';
import { ApiTokenDelete, ApiTokenPost, ApiTokenPostForStaff } from '../../api/front/token/api-token';
import { localStorageActions } from '../local-storage-slice';

// ログイン系API
export const authActions = {
  login: (
    request: LoginRequest,
    response: ResponseCallback,
  ): AppThunk => async (dispatch) => {
    // dispatch(apiActions.run(new ApiLogin(request), response));
    dispatch(apiActions.run(new ApiTokenPost(request), response));
  },
  logout: (request: { kijshopCd: string }): AppThunk => async (dispatch) => {
    // dispatch(apiActions.run(new ApiLogout({}), {
    dispatch(apiActions.run(new ApiTokenDelete(request), {
      onSuccess: () => {
        // ApiBaseBack.session = '';
        // ApiBaseFront.session = '';
        dispatch(push(RoutingPath.login));
      },
    }));
  },
  staffLogin: (
    request: LoginRequestForStaff,
    response: ResponseCallback,
  ): AppThunk => async (dispatch) => {
    dispatch(apiActions.run(new ApiTokenPostForStaff(request), response));
  },
  staffLogout: (request: { kijshopCd: string }): AppThunk => async (dispatch) => {
    dispatch(apiActions.run(new ApiTokenDelete(request), {
      onSuccess: () => {
        dispatch(push(RoutingPath.staffLogin));
      },
    }));
  },
};
