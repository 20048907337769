export type CloudFolderOrderDetailViewModel = {
  /* セレクト希望枚数 */
  select: boolean,
  /* 備考 */
  remark: boolean,
  /* 色補正 */
  colorFix: boolean,
  /* 1次レタッチオプション */
  firstRetouchOption: boolean,
  /* 結果の画像DL */
  resultDownload: boolean,
  /* 商品使用画像 */
  usageImage: boolean,
  /* 商品使用画像レタッチオプション */
  usageImageRetouchOption: boolean,
}


export const VIEW_MODELS: { category: number, model: CloudFolderOrderDetailViewModel}[] = [
  {
    category: 0,
    model: {
      select: false,
      resultDownload: false,
      colorFix: false,
      firstRetouchOption: false,
      usageImage: false,
      usageImageRetouchOption: false,
      remark: false,
    },
  },
  {
    category: 1,
    model: {
      select: false,
      resultDownload: true,
      colorFix: true,
      firstRetouchOption: true,
      usageImage: true,
      usageImageRetouchOption: true,
      remark: false,
    },
  },
  {
    category: 2,
    model: {
      select: true,
      resultDownload: false,
      colorFix: false,
      firstRetouchOption: false,
      usageImage: false,
      usageImageRetouchOption: false,
      remark: true,
    },
  },
  {
    category: 3,
    model: {
      select: false,
      resultDownload: false,
      colorFix: false,
      firstRetouchOption: false,
      usageImage: false,
      usageImageRetouchOption: false,
      remark: false,
    },
  },
  {
    category: 4,
    model: {
      select: false,
      resultDownload: false,
      colorFix: false,
      firstRetouchOption: false,
      usageImage: false,
      usageImageRetouchOption: false,
      remark: false,
    },
  },
];

export const RESULT_DOWNLOAD_SELECT_LIST = [
  { value: '0', label: 'しない' },
  { value: '1', label: 'する' },
];

export const USAGE_IMAGE_SELECT_LIST = [
  { value: '1', label: '枚数を選択' },
  { value: '2', label: 'ラボにおまかせ' },
  { value: '3', label: 'すべて使う' },
];
