import objectHash from 'object-hash';

// - MEMO -
// 5H0A4421.jpg
// ↓ devUtils で sha1
// a8547701931d4e9924ef439711490fe5c5582ede

type HashAlgorithm = 'sha1' | 'md5';

class HashGeneratorClass {

  async generate(
    file: File,
    option?: {
      algorithm?: HashAlgorithm,
      binary?: boolean,
    },
  ) {
    const alg = option?.algorithm ?? 'sha1';
    if (option?.binary) {
      const binaryStr = await this.readBinaryStrAsync(file);
      return this.hash(binaryStr, alg);
    }
    return this.hash(file, alg);
  }

  private hash(value: Object, algorithm: HashAlgorithm) {
    switch (algorithm) {
      case 'sha1':
        return objectHash(value, { algorithm: 'sha1' });
      case 'md5':
        return objectHash(value, { algorithm: 'md5' });
      default:
        return objectHash(value, { algorithm: 'sha1' });
    }
  }

  private readBinaryStrAsync(blob: Blob): Promise<string> {
    const reader = new FileReader();
    reader.readAsBinaryString(blob);
    return new Promise((resolve) => {
      reader.onload = () => {
        resolve(reader.result as string);
      };
    });
  }

}

export const HashGenerator = new HashGeneratorClass();
