import { useDispatch } from 'react-redux';
import { Button } from '../ui/button/button';
import React, { useCallback } from 'react';
import { FavoriteBrandConfig } from '../dialog/unique/favorite-brand-config';
import { ChangeFavoriteBrandName } from '../dialog/unique/change-favorite-brand-name';
import { DetailSearch } from '../dialog/unique/detail-search';
import { EditPreset } from '../dialog/unique/edit-preset';
import { DeliveryAddressInfoInput } from '../dialog/unique/delivery-address-info-input';
import { ChangeOrderPageNum } from '../dialog/unique/change-order-page-num';
import { SendFormDesignationConfig } from '../dialog/unique/send-form-designation-config';
import { EditFormDesignation } from '../dialog/unique/edit-form-designation';
import { SendStoreConfig } from '../dialog/unique/send-store-config';
import { EnvConfig } from '../dialog/unique/env-config';
import { GuidList } from '../dialog/unique/guid-list';
import { ReorderConfirm } from '../dialog/unique/reorder-confirm';
import { RegisterDeliveryStore } from '../dialog/unique/register-delivery-store';
import { SaveFavoriteBrand } from '../dialog/unique/save-favorite-brand';
import { ImageFrameEdit } from '../dialog/unique/image-frame-edit';
import { AddFolder } from '../dialog/unique/add-folder';
import { RepairDeliveryStore } from '../dialog/unique/repair-delivery-store';
import { OrderDeleteConfirm } from '../dialog/unique/order-delete-confirm';
import { ChangeFolderName } from '../dialog/unique/change-folder-name';
import { InsertText } from '../dialog/unique/insert-text';
import { ConfigApplying } from '../dialog/unique/config-applying';
import { LabOrder } from '../dialog/unique/lab-order';
import { dialogActions } from '../dialog/slice/dialog-slice';
import { LoadingFavoriteBrand } from '../dialog/unique/loading-favorite-brand';
import { SelectFormDesignation } from '../dialog/unique/select-form-designation';
import { RegisterPreset } from '../dialog/unique/register-preset';
import { PresetList } from '../dialog/unique/preset-list';
import { HowToOrder } from '../dialog/unique/how-to-order';
import { Checkbox } from '../ui/input/checkbox';
import { SelectImgLayoutOrder } from '../dialog/unique/select-img-layout-order';
import { LocalFontLoaderErrorDialog } from '../pages/layout/toolbar/layout.local-font-loader-error-dialog';

export const DebugDialog = () => {
  // - Hooks -
  const dispatch = useDispatch();
  // - Callback -
  // -- ダイアログ開く --
  const handlerOpenDialog = useCallback((component: JSX.Element, title: string, closeBtn?: boolean, onBlurPop?: boolean) => {
    dispatch(dialogActions.push({
      title,
      element: component,
      closeBtn,
      onBlurPop,
    }));
  }, []);
  const kijshopCd = '0299999';
  return (
    <div style={{ height: '100%', overflow: 'auto' }}>
      <div>
        <Button
          label="ローカルフォント読み込みエラー"
          onClick={() => handlerOpenDialog(<LocalFontLoaderErrorDialog
            closeCallback={() => { }}
            reloadCallback={() => { }}
            warningMessage={['キャンセルすると、デザインを中止します。']}
          />, '画像レイアウト順選択')}
        />
      </div>
      <br />

      <div>
        <Button
          label="画像レイアウト順選択"
          onClick={() => handlerOpenDialog(<SelectImgLayoutOrder onClickDetermine={() => { }} />, '画像レイアウト順選択')}
        />
      </div>

      <br />
      <div>
        <Button
          label="お気に入り商品設定"
          onClick={() => handlerOpenDialog(<FavoriteBrandConfig kijshopCd={kijshopCd} />, 'お気に入り商品設定')}
        />
      </div>

      <br />
      <div>
        <Button
          label="お気に入り商品名称変更"
          onClick={() => handlerOpenDialog(<ChangeFavoriteBrandName kijshopCd={kijshopCd} target={{ id: '100', name: 'TEST', type: 'folder', level: 1, parentId: '', children: [] }} type='config' />, 'お気に入り商品名称変更')}
        />
      </div>

      <br />
      <div>
        <Button
          label="お気に入り商品読み込み"
          onClick={() => handlerOpenDialog(<LoadingFavoriteBrand />, 'お気に入り商品読み込み')}
        />
      </div>

      {/*<br />*/}
      {/*<div>*/}
      {/*  <Button*/}
      {/*    label="お知らせ"*/}
      {/*    onClick={() => handlerOpenDialog(, 'お知らせ')}*/}
      {/*  />*/}
      {/*</div>*/}

      <br />
      <div>
        <Button
          label="詳細検索"
          onClick={() => handlerOpenDialog(<DetailSearch
            handlerClickSearch={(searchWords) => {
              console.log(searchWords);
              dispatch(dialogActions.pop());
            }}
            handlerClickClose={() => dispatch(dialogActions.pop())}
            searchParam={{
              date: { from: '', to: '' },
              orderId: '',
              customerName: '',
              dateType: 'create',
            }}
          />, '詳細検索')}
        />
      </div>

      <br />
      <div>
        <Button
          label="プリセット編集、削除"
          onClick={() => handlerOpenDialog(<EditPreset />, 'プリセット編集、削除')}
        />
      </div>

      <br />
      <div>
        <Button
          label='プリセットリスト'
          onClick={() => handlerOpenDialog(<PresetList kijshopCd={kijshopCd} />, 'プリセットリスト')}
        />
      </div>

      <br />
      <div>
        <Button
          label='発送帳票を選択'
          onClick={() => handlerOpenDialog(<SelectFormDesignation kijshopCd={kijshopCd} shopOrderId={''} />, '発送帳票を選択')}
        />
      </div>

      <br />
      <div>
        <Button
          label="注文ページ数変更"
          onClick={() => handlerOpenDialog(<ChangeOrderPageNum kijshopCd={kijshopCd} shopOrderId="" />, '注文ページ数変更')}
        />
      </div>

      <br />
      <div>
        <Button
          label="発送帳票設定"
          onClick={() => handlerOpenDialog(<SendFormDesignationConfig kijshopCd={kijshopCd} shopOrderId={''} />, '発送帳票設定')}
        />
      </div>

      <br />
      <div>
        <Button
          label="配送先情報入力"
          onClick={() => handlerOpenDialog(<DeliveryAddressInfoInput
            kijshopCd={''}
            shopOrderId={''}
            orderId={''} />, '配送先情報入力')}
        />
      </div>

      <br />
      <div>
        <Button
          label='プリセットの登録'
          onClick={() => handlerOpenDialog(<RegisterPreset kijshopCd={kijshopCd} windowSize={'0'} humanScale={'0'} topSpace={'0'} bottomSpace={'0'} />, 'プリセットの登録')}
        />
      </div>

      {/** 登録と編集で同じコンポネント */}
      <br />
      <div>
        <Button
          label="発送帳票登録"
          onClick={() => handlerOpenDialog(<EditFormDesignation type="register" kijshopCd={kijshopCd} nameList={[]} />, '発送帳票登録')}
        />
      </div>

      <br />
      <div>
        <Button
          label="発送店舗設定"
          onClick={() => handlerOpenDialog(<SendStoreConfig kijshopCd={kijshopCd} />, '発送店舗設定')}
        />
      </div>

      <br />
      <div>
        <Button
          label="環境設定"
          onClick={() => handlerOpenDialog(<EnvConfig />, '環境設定')}
        />
      </div>

      <br />
      <div>
        <Button
          label="ガイドリスト"
          onClick={() => handlerOpenDialog(<GuidList list={[]} checks={[]} callback={() => { }} updateChecks={() => { }} />, 'ガイドリスト')}
        />
      </div>

      {/** 再発注するオーダーの選択 */}
      <br />
      <div>
        <Button
          label="確認"
          onClick={() => handlerOpenDialog(<ReorderConfirm target={[]} />, '確認')}
        />
      </div>

      <br />
      <div>
        <Button
          label="発送店舗登録"
          onClick={() => handlerOpenDialog(<RegisterDeliveryStore kijshopCd={kijshopCd} />, '発送店舗登録')}
        />
      </div>

      <br />
      {/*<div>*/}
      {/*  <Button*/}
      {/*    label="お気に入り商品保存"*/}
      {/*    onClick={() => handlerOpenDialog(<SaveFavoriteBrand kijshopCd="" />, 'お気に入り商品保存')}*/}
      {/*  />*/}
      {/*</div>*/}

      <br />
      <div>
        <Button
          label="写真枠の回転、ズーム、移動開始"
          onClick={() => handlerOpenDialog(<ImageFrameEdit />, '写真枠の回転、ズーム、移動開始')}
        />
      </div>

      <br />
      <div>
        <Button
          label="フォルダ追加"
          onClick={() => handlerOpenDialog(<AddFolder kijshopCd="" type={'brand'} />, 'フォルダ追加')}
        />
      </div>

      <br />
      <div>
        <Button
          label='説明'
          onClick={() => handlerOpenDialog(<HowToOrder imgType={'title-category'}/>, '説明', true)}
        />
      </div>

      <br />
      <div>
        <Button
          label="発送店舗修正"
          onClick={() => handlerOpenDialog(<RepairDeliveryStore kijshopCd={kijshopCd} storeData={{ shippingShopId: '', shopName: '' }} />, '発送店舗修正')}
        />
      </div>

      <br />
      <div>
        <Button
          label="発送帳票修正"
          onClick={() => handlerOpenDialog(<EditFormDesignation type='edit' kijshopCd={kijshopCd} nameList={[]} />, '発送帳票編集')}
        />
      </div>

      {/** 「選択フォルダを含める」チェックボックス表示用 */}
      <br />
      <div>
        <Button
          label="フォルダ追加"
          onClick={() => handlerOpenDialog(<AddFolder kijshopCd={kijshopCd} isIncludeSelectFolder={true} type={'template'} />, 'フォルダ追加')}
        />
      </div>

      {/** オーダー削除確認 */}
      <br />
      <div>
        {/*<Button*/}
        {/*  label="確認"*/}
        {/*  onClick={() => handlerOpenDialog(<OrderDeleteConfirm targetOrder={[{orderStatus: null, orderStatusName: '通信エラー', ppmOrderStatus: 'TEST', shopOrderId:'9900014010017'}]} />, '確認')}*/}
        {/*/>*/}
      </div>

      <br />
      <div>
        <Button
          label="フォルダ名変更"
          onClick={() => handlerOpenDialog(
            <ChangeFolderName kijshopCd="" targetFolder={{ id: '2', name: 'お気に入り２', type: 'directory', children: [], level: 1, indexes: 1 }} />,
            'フォルダ名変更'
          )}
        />
      </div>

      <br />
      <div>
        <Button
          label="テキストの挿入"
          onClick={() => handlerOpenDialog(<InsertText />, 'テキストの挿入')}
        />
      </div>

      <br />
      <div>
        <Button
          label="設定適用中"
          onClick={() => handlerOpenDialog(<ConfigApplying />, '設定適用中')}
        />
      </div>

      <br />
      <div>
        {/*<Button*/}
        {/*  label="ラボ発注"*/}
        {/*  onClick={() => handlerOpenDialog(<LabOrder kijshopCd={kijshopCd} orderList={[]} callbackEndOrder={() => {}} />, 'ラボ発注')}*/}
        {/*/>*/}
      </div>

    </div>
  );
};
