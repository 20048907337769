import {CoversRequest, CoversResponse} from '../../../../models/api/back/create-order/covers';
import {AppThunk} from "../../../../app/store";
import {ordersDataActions} from "../../../../slices/orders/orders-data-slice";
import {apiActions} from "../../../../slices/api-slice";
import {ResponseBase} from "../../../response-base";
import {ApiBaseBack} from "../../api-base-back";
import { ordersActions } from '../../../../slices/orders-slice';

export class ApiCovers extends ApiBaseBack<CoversResponse> {
  constructor(param: CoversRequest) {
    super(
      'GET',
      '/api/v1/covers',
      'JSON',
      param,
    );
  }
}

export const apiCoversThunk = (param: CoversRequest, callback: () => void, isInit: boolean, agent: boolean): AppThunk => async (dispatch, getState) => {
  if (!param.serviceId) {
    dispatch(ordersDataActions.updateItemDetail({
      cover: { coverType: [] },
    }));
    callback?.();
    return;
  }
  dispatch(apiActions.run(
    new ApiCovers(param),
    {
      onSuccess: (res: ResponseBase<CoversResponse>) => {
        callback?.();
        if(!isInit && param.serviceId !== getState().order.itemInfo.category.value){
          return;
        }
        dispatch(ordersDataActions.updateItemDetail({
          cover: { coverType: res.body.data?.coverType || [] },
        }));
        const cover = getState().order.itemDetail.coverSelect;
        if (cover.value && res.body.data?.coverType?.find((v) => v.coverTypeId === cover.value)) {
          dispatch(ordersActions.itemDetail.setCoverSelect(cover.value, cover.label, agent));
        }
      },
      onError: () => {
        dispatch(ordersDataActions.updateItemDetail({
          cover: { coverType: [] },
        }));
        callback?.();
      },
    },
    {
      apiName: 'cover',
      ignoreSystemError: true,
    },
  ));
};
