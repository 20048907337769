import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {CategoriesResponse} from '../../models/api/back/create-order/categories';
import {TagsResponse} from '../../models/api/back/create-order/tags';
import {ItemsResponse} from '../../models/api/back/create-order/items';
import {CoversResponse} from '../../models/api/back/create-order/covers';
import {BoardInfosResponse} from '../../models/api/back/create-order/board-infos';
import {OrderMethodsResponse} from '../../models/api/back/create-order/order-methods';
import {DesignType1Response} from '../../models/api/back/create-order/design-type1';
import {CoverColorsResponse} from '../../models/api/back/create-order/cover-colors';
import { DesignType2Response } from '../../models/api/back/create-order/design-type2';
import { LaminatesResponse } from '../../models/api/back/create-order/laminates';
import { EndpaperResponse } from '../../models/api/back/create-order/endpaper';
import {FiltersResponse} from "../../models/api/back/create-order/filters";
import {
  FoilStampingsCoverColorResponse,
  FoilStampingsResponse,
} from '../../models/api/back/create-order/foil-stampings';
import {OptionPartsResponse} from "../../models/api/back/create-order/option-parts";
import {OptionSetInfoResponse} from "../../models/api/back/create-order/option-set-info";
import {PageCountResponse} from "../../models/api/back/create-order/page-count";
import {PageDataTypesResponse} from "../../models/api/back/create-order/page-data-types";
import {PartsResponse} from "../../models/api/back/create-order/parts";
import {BoardOptionInfosResponse} from "../../models/api/back/create-order/board-option-infos";
import { OptionItemResponse } from '../../models/api/back/create-order/option-item';
import { OptionTagResponse } from '../../models/api/back/create-order/option-tag';
import { ProductDetailResponse } from '../../models/api/back/create-order/product-detail';
import { ChronologiesResponse } from '../../models/api/back/create-order/chronologies';
import { DescriptionInfoGetResponse, DescriptionPartInfoGetResponse } from '../../models/api/front/description-info';
import { OptionProductGroupRulesResponse } from '../../models/create-order/option-product-group-rules';
import * as lodash from 'lodash';
import { ProcessInfoResponse } from '../../models/api/back/create-order/process-info';

type ItemInfo = {
  category: CategoriesResponse,
  tag: TagsResponse,
};

type ItemDetail = {
  cover: CoversResponse,
  coverColor: CoverColorsResponse,
  item: ItemsResponse,
  board: BoardInfosResponse,
  orderMethods: OrderMethodsResponse,
  designType1: DesignType1Response,
  designType2: DesignType2Response,
};

type DescriptionInfo = {
  descriptionInfo: DescriptionInfoGetResponse[],
  pageDataType: PageDataTypesResponse,
};

type Cover = {
  parts: { itemId: string },
  laminate: LaminatesResponse,
  color: CoverColorsResponse,
  foilStamping: FoilStampingsResponse,
  foilStampingAfterProcessing: FoilStampingsCoverColorResponse,
};

type Genuine = {
  parts: { itemId: string },
  laminate: LaminatesResponse,
  endpaper: EndpaperResponse,
  foilStamping: FoilStampingsResponse,
  foilStampingAfterProcessing: FoilStampingsCoverColorResponse,
  chronologies: ChronologiesResponse,
};

type Option = {
  optionParts: OptionPartsResponse,
  optionSetInfo: OptionSetInfoResponse,
  optionTag: OptionTagResponse,
  optionItem: OptionItemResponse,
  optionLaminate: LaminatesResponse[],
  optionBoard: BoardOptionInfosResponse,
  optionProductGroupRules: OptionProductGroupRulesResponse,
  optionProductList: OptionItemResponse,
  optionPageCount: PageCountResponse | null,
  cover: { itemId: string }[],
  genuine: { itemId: string }[],
};

type Remarks = {
};

type Data = {
  filter: FiltersResponse,
  pageCount: PageCountResponse,
  parts: PartsResponse,
  productDetail: Partial<ProductDetailResponse>,
  optionProductDetail: (ProductDetailResponse | null)[],
  optionAdditional: ((EndpaperResponse & { itemId: string }) | null)[],
  processInfo: ProcessInfoResponse,
};

export type OrdersDataState = {
  itemInfo: ItemInfo,
  itemDetail: ItemDetail,
  descriptionInfo: DescriptionInfo,
  cover: Cover,
  genuine: Genuine,
  option: Option,
  data: Data,
};

const initialState: OrdersDataState = {
  itemInfo: {
    category: {
      service: [],
    },
    tag: {
      productTag: [],
    },
  },
  itemDetail: {
    item: {
      products: [],
    },
    cover: {
      coverType: [],
    },
    coverColor: {
      coverColor: [],
    },
    board: {
      boardInfo: [],
    },
    orderMethods: {
      orderMethod: [],
    },
    designType1: {
      hpsTppath: [],
    },
    designType2: {
      hpsTppath: [],
    }
  },
  descriptionInfo: {
    descriptionInfo: [],
    // part: [],
    pageDataType: {
      pageDataTypes: [],
    },
  },
  cover: {
    parts: {
      itemId: '',
    },
    laminate: {
      laminate: [],
    },
    foilStamping: {
      leafColor: [],
    },
    color: {
      coverColor: [],
    },
    foilStampingAfterProcessing: {
      coverColorPostPrintRel: [],
    },
  },
  genuine: {
    parts: {
      itemId: '',
    },
    laminate: {
      laminate: [],
    },
    foilStamping: {
      leafColor: [],
    },
    endpaper: {
      additionalInfos: [],
    },
    chronologies: {
      chronologies: [],
    },
    foilStampingAfterProcessing: {
      coverColorPostPrintRel: [],
    },
  },
  option: {
    optionParts: {
      items: [],
    },
    optionSetInfo: {
      optionProductName: [],
    },
    optionTag: {
      optionProductTag: [],
    },
    optionItem: {
      optionProduct: [],
    },
    // optionLaminate: {
    //   laminate: [],
    // },
    optionLaminate: [],
    optionBoard: {
      boardInfo: [],
    },
    optionProductGroupRules: {
      optionGroupRule: [],
    },
    optionProductList: {
      optionProduct: [],
    },
    optionPageCount: null,
    cover: [],
    genuine: [],
  },
  data: {
    filter: {
      filters: [],
    },
    pageCount: {
      maxPageNum: '',
      minPageNum: '',
      stepPageNum: '',
    },
    parts: {
      items: [],
    },
    productDetail: {},
    optionProductDetail: [],
    optionAdditional: [],
    processInfo: {
      orderMethod: [],
    },
  },
};

const assign:<T>(target: T, source: Partial<T>) => T = (target, source) => Object.assign<typeof target, typeof source>(target, source);

const ordersDataSlice = createSlice({
  name: 'orders-data',
  initialState,
  reducers: {
    updateItemInfo: (state, action: PayloadAction<Partial<ItemInfo>>) => {
      state.itemInfo = assign<ItemInfo>(state.itemInfo, action.payload);
    },
    updateItemDetail: (state, action: PayloadAction<Partial<ItemDetail>>) => {
      state.itemDetail = assign<ItemDetail>(state.itemDetail, action.payload);
    },
    updateDescriptionInfo: (state, action: PayloadAction<Partial<DescriptionInfo>>) => {
      state.descriptionInfo = Object.assign(state.descriptionInfo, action.payload);
    },
    updateCover: (state, action: PayloadAction<Partial<Cover>>) => {
      state.cover = assign<Cover>(state.cover, action.payload);
    },
    updateGenuine: (state, action: PayloadAction<Partial<Genuine>>) => {
      state.genuine = assign<Genuine>(state.genuine, action.payload);
    },
    updateOption: (state, action: PayloadAction<Partial<Option>>) => {
      const payload = { ...action.payload };
      if (payload.cover) {
        payload.cover.forEach((v, i) => {
          if (v?.itemId) {
            state.option.cover[i] = v;
          }
        });
        payload.cover = state.option.cover;
      }
      if (payload.genuine) {
        payload.genuine.forEach((v, i) => {
          if (v?.itemId) {
            state.option.genuine[i] = v;
          }
        });
        payload.genuine = state.option.genuine;
      }
      state.option = assign<Option>(state.option, payload);
    },
    // setRemarks: (state, action: PayloadAction<string>) => {
    //   // state.remarks.remarks = action.payload;
    // },
    updateData: (state, action: PayloadAction<Partial<Data>>) => {
      state.data = assign<Data>(state.data, action.payload);
    },
    setOptionProductDetail: (state, action: PayloadAction<{ data: ProductDetailResponse | null, index: number }>) => {
      state.data.optionProductDetail[action.payload.index] = action.payload.data;
    },
    setOptionAdditional: (state, action: PayloadAction<{ data: (EndpaperResponse & { itemId: string }) | null, index: number }>) => {
      state.data.optionAdditional[action.payload.index] = action.payload.data;
    },
    removeOptionData: (state, action: PayloadAction<string>) => {
      const indexAdditional = state.data.optionAdditional.findIndex((v) => v?.itemId === action.payload);
      const indexDetail = state.data.optionProductDetail.findIndex((v) => v?.productNameId === action.payload);
      if (indexAdditional !== -1) {
        state.data.optionAdditional.splice(indexAdditional, 1);
      }
      if (indexDetail !== -1) {
        state.data.optionProductDetail.splice(indexDetail, 1);
      }
    },
    setOptionLaminate: (state, action: PayloadAction<{ laminate: LaminatesResponse, index: number }>) => {
      state.option.optionLaminate[action.payload.index] = action.payload.laminate;
    },
    removeOptionLaminate: (state, action: PayloadAction<number>) => {
      if (state.option.optionLaminate[action.payload]) {
        state.option.optionLaminate.splice(action.payload, 1);
      }
    },
    resetData: (state) => {
      const {
        itemInfo,
        itemDetail,
        descriptionInfo,
        cover,
        genuine,
        option,
        data,
      } = initialState;
      const clone = lodash.cloneDeep;
      state.itemInfo = clone(itemInfo);
      state.itemDetail = clone(itemDetail);
      state.descriptionInfo = clone(descriptionInfo);
      state.cover = clone(cover);
      state.genuine = clone(genuine);
      state.option = clone(option);
      state.data = clone(data);
    },
  },
});

export const ordersDataActions = ordersDataSlice.actions;
export const ordersDataReducer = ordersDataSlice.reducer;
