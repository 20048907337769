export const FontSizeList = [
  { value: '0', label: '6pt' },
  { value: '1', label: '8pt' },
  { value: '2', label: '9pt' },
  { value: '3', label: '10pt' },
  { value: '4', label: '11pt' },
  { value: '5', label: '12pt' },
  { value: '6', label: '14pt' },
  { value: '7', label: '16pt' },
  { value: '8', label: '18pt' },
  { value: '9', label: '20pt' },
  { value: '10', label: '22pt' },
  { value: '11', label: '24pt' },
  { value: '12', label: '26pt' },
  { value: '13', label: '28pt' },
  { value: '14', label: '36pt' },
  { value: '15', label: '48pt' },
  { value: '16', label: '72pt' },
]