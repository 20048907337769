import { ApiBaseFront } from '../api-base-front';

export class ApiGetCloudFolderOrder extends ApiBaseFront {
  constructor(param: {}) {
    super(
      'GET',
      '/api/v1/admin/c-folder/order',
      'JSON',
      param,
    );
  }
}
