import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ActionType } from '../models/action-type';
import { AppThunk } from '../app/store';
import { apiActions } from './api-slice';
import {
  ApiOrderLockCheck,
  ApiOrderLock,
  ApiOrderUnlock,
  OrderLockCheckResponse,
} from '../api/front/lock-order/api-lock-orders';
import { ResponseBase } from '../api/response-base';
import { dialogActions } from '../components/dialog/slice/dialog-slice';
import { ApiGetStaffNameList } from '../api/front/staff/api-staff-name';
import { GetStaffNameResponse } from '../models/api/front/staff-management';

type Callback = {
  yes: () => void,
  no: () => void,
}
type LockOrderSliceState = {
  lockOrderId: string,
}
const initialState: LockOrderSliceState = {
  lockOrderId: '',
};

const setId: ActionType<LockOrderSliceState, Partial<string>> =
  (state, action) => {
    state.lockOrderId = action.payload;
  };

const lockActions = {
  check: (
    kijshopCd: string,
    shopOrderId: string,
    callback: Callback,
  ): AppThunk => async (dispatch, getState) => {
    const machineId = getState().storage.machineId;
    // const staffName = getState().storage.staffIdAndName.name;
    // const futureEditor = staffName ? staffName : machineId;
    // console.log(staffName);
    // console.log('getState().storage: ',getState().storage);
    // console.log('machineId: ',machineId);
    // console.log('staffName: ',staffName);
    // console.log('check: ',futureEditor);
    dispatch(apiActions.run(
      new ApiOrderLockCheck({ kijshopCd: kijshopCd, shopOrderId: shopOrderId }),
      {
        onSuccess: (res: ResponseBase<OrderLockCheckResponse>) => {
          if (res.body.data) {
            const currentEditor = res.body.data.name;
            if (!currentEditor) {
              dispatch(orderLockActions.lock(kijshopCd, shopOrderId, callback.yes));
            } else if (currentEditor === machineId) {
              callback.yes();
            } else {
              dispatch(orderLockActions.viewDialog(
                kijshopCd, shopOrderId, currentEditor, callback,
              ));
            }
          }
        },
      },
    ));
  },

  viewDialog: (
    kijshopCd: string,
    shopOrderId: string,
    editor: string,
    callback: Callback,
  ): AppThunk => async (dispatch) => {
    const showMessage = (editor: string) => {
      dispatch(dialogActions.pushMessage({
        title: '確認',
        message: [
          `注文番号：${shopOrderId} は、`,
          `"${editor}"さんが編集中の可能性があります。`,
          '',
          `編集する場合は、"${editor}"さんに確認をしてから実行してください。`,
        ],
        buttons: [
          {
            label: '編集しない',
            callback: () => {
              dispatch(dialogActions.pop());
              callback.no();
            },
          },
          {
            label: '編集する',
            callback: () => {
              dispatch(dialogActions.pop());
              dispatch(orderLockActions.forceLock(kijshopCd, shopOrderId, callback.yes));
            },
            options: {
              // waitActive: 1500,
            },
          },
        ],
      }));
    }

    dispatch(apiActions.run(
      new ApiGetStaffNameList({kijshopCd}), {
        onSuccess: (res: ResponseBase<GetStaffNameResponse>) => {
          if (res.body.data) {
            const nameList = res.body.data.staffNameList;
            const staff = nameList.find(v => v.staffId === editor);
            const featureEditor = staff ? staff.name : editor;
            showMessage(featureEditor);
          }
        },
        onError: () => {
          showMessage(editor);
        }
      }
    ))
  },

  lock: (
    kijshopCd: string,
    shopOrderId: string,
    callback: () => void,
  ): AppThunk => async (dispatch, getState) => {
    const machineId = getState().storage.machineId;
    // const staffName = getState().storage.staffIdAndName.name;
    // スタッフ名が取得できる場合はスタッフログイン中のため、スタッフ名をロック者とする
    // const futureEditor = staffName ? staffName : machineId;
    dispatch(apiActions.run(
      new ApiOrderLock({ kijshopCd: kijshopCd, shopOrderId: shopOrderId, name: machineId }),
      {
        onSuccess: () => {
          console.log(machineId);
          console.log(`【 ${shopOrderId} 】 ロック`);
          callback();
        },
        onError: () => console.log(`【 ${shopOrderId} 】 ロック失敗`),
      },
    ));
  },

  forceLock: (
    kijshopCd: string,
    shopOrderId: string,
    callback: () => void,
  ): AppThunk => async (dispatch) => {
    dispatch(apiActions.run(
      new ApiOrderUnlock({ kijshopCd: kijshopCd, shopOrderId: shopOrderId }),
      {
        onSuccess: () => {
          console.log(`【 ${shopOrderId} 】 強制アンロック`);
          dispatch(orderLockActions.lock(kijshopCd, shopOrderId, callback));
        },
        onError: () => console.log(`【 ${shopOrderId} 】 アンロック失敗`),
      },
    ));
  },

  unlock: (
    kijshopCd: string,
    shopOrderId: string,
  ): AppThunk => async (dispatch) => {
    dispatch(apiActions.run(
      new ApiOrderUnlock({ kijshopCd: kijshopCd, shopOrderId: shopOrderId }),
      {
        onSuccess: () => console.log(`【 ${shopOrderId} 】 アンロック`),
        onError: () => console.log(`【 ${shopOrderId} 】 アンロック失敗`),
      },
    ));
  },
};

export const lockOrdersSlice = createSlice({
  name: 'lockOrders',
  initialState,
  reducers: { setId },
});

export const orderLockActions = Object.assign(lockOrdersSlice.actions, lockActions);
export const orderLockReducer = lockOrdersSlice.reducer;
