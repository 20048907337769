import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Input } from '../../ui/input/input';
import { Button } from '../../ui/button/button';
import { useDispatch } from 'react-redux';
import { dialogActions } from '../slice/dialog-slice';
import { SendFormDesignationConfig } from './send-form-designation-config';
import { OrderFormGetResponse } from '../../../models/api/front/order-form';
import { useAppSelector } from '../../../app/hooks';
import { deliveryActions } from '../../../slices/orders/delivery-slice';
import { xmlActions } from '../../../xml/slice/xml-slice';
import { localStorageActions } from '../../../slices/local-storage-slice';
import { loadingActions } from '../../../slices/loading-slice';
import { LoadingPopup } from '../../ui/loading/loading-popup';
import { orderPreparationActions } from '../../../slices/order-preparation-slice';
import lodash from 'lodash';

type SelectFormDesignationProps = {
  // - 選択した帳票の情報 -
  selectData?: OrderFormGetResponse,
  kijshopCd: string,
  shopOrderId: string
}
export const SelectFormDesignation = (props: SelectFormDesignationProps) => {
  const { kijshopCd, shopOrderId } = props;
  const dispatch = useDispatch();
  // - State -
  const { selectData, xml, storage, isProxyMode } = useAppSelector((state) => ({
    selectData: state.delivery.address.sendFormDesignation,
    xml: state.xml[shopOrderId],
    storage: state.storage.data.sendFormDesignation,
    isProxyMode: Boolean(state.xml[shopOrderId]?.info.metaModel.dpNumber),
  }), lodash.isEqual);
  // -- 発注帳票 --
  const [formDesignation, setFormDesignation] = useState('');
  // - Callback -
  // -- 発注帳票更新 --
  const handlerChangeDesignation = useCallback((v) => {
    setFormDesignation(v);
  }, []);
  // -- リスト呼び出しボタン押下 --
  const handlerClickCallList = useCallback(() => {
    dispatch(dialogActions.push({
      title: '発送帳票設定',
      element: <SendFormDesignationConfig
        kijshopCd={kijshopCd}
        shopOrderId={shopOrderId}
      />,
    }));
  }, []);

  // PDF登録処理
  const handlerClickOK = useCallback(() => {
    if (xml) {
      dispatch(orderPreparationActions.setUpdatingDesignation(true))
      if (xml.delivery.viewModel.formName !== selectData.name && xml.delivery.viewModel.pdfPath !== selectData.path) {
        xml.delivery.viewModel.formName = selectData.name;
        xml.delivery.viewModel.pdfPath = selectData.path;
        xml.delivery.viewModel.orderFormGUID = selectData.id;
        xml.delivery.viewModel.originalPath = selectData.originalPath;
        xml.delivery.viewModel.importPath = selectData.importPath;
        xml.delivery.build();
        dispatch(xmlActions.shopOrder({ kijshopCd, shopOrderId }).update(xml, () => {
          dispatch(orderPreparationActions.setUpdatingDesignation(false))
        }));
      } else {
        dispatch(orderPreparationActions.setUpdatingDesignation(false))
      }
    }
      dispatch(localStorageActions.setData(
        kijshopCd,
        'sendFormDesignation',
        {
          name: selectData.name || '',
          path: selectData.path || '',
          id: selectData.id || '',
          originalPath: selectData.originalPath || '',
          importPath: selectData.importPath || '',
        }));
      dispatch(dialogActions.pop());
  }, [xml, selectData]);

  // セットした値を削除
  const handlerClickClear = useCallback(() => {
    dispatch(deliveryActions.setAddressState({
      sendFormDesignation: {
        name: '',
        path: '',
        id: '',
        originalPath: '',
        importPath: '',
      },
    }));
    setFormDesignation('');
    if (xml) {
      xml.delivery.viewModel.formName = '';
      xml.delivery.viewModel.pdfPath = '';
      xml.delivery.viewModel.orderFormGUID = '';
      xml.delivery.viewModel.originalPath = '';
      xml.delivery.viewModel.importPath = '';

      xml.delivery.build();
      dispatch(xmlActions.shopOrder({ kijshopCd, shopOrderId }).update(xml));
    }
    dispatch(localStorageActions.setData(
      kijshopCd,
      'sendFormDesignation',
      {
        name: '',
        path: '',
        id: '',
        originalPath: '',
        importPath: '',
      }));
  }, [xml]);

  // 登録済の場合、state、もしくはxmlから名前を呼び出す。
  useEffect(() => {
    if (selectData.name && selectData.name === formDesignation) return;
    if (selectData.name && selectData.name !== formDesignation) {
      setFormDesignation(selectData.name);
    } else if (xml?.delivery.viewModel.formName && xml.delivery.viewModel.formName !== formDesignation) {
      setFormDesignation(xml.delivery.viewModel.formName);
      dispatch(deliveryActions.setAddressState({
        sendFormDesignation: {
          name: xml.delivery.viewModel.formName,
          path: xml.delivery.viewModel.pdfPath,
          id: xml.delivery.viewModel.orderFormGUID,
          originalPath: xml.delivery.viewModel.originalPath,
          importPath: xml.delivery.viewModel.importPath,
        },
      }));
    } else {
      if ((isProxyMode || (!isProxyMode && storage.name !== '指定なし')) && selectData.path !== storage.path) {
        dispatch(deliveryActions.setAddressState({
          sendFormDesignation: {
            name: storage.name,
            path: storage.path,
            id: storage.id,
            originalPath: storage.originalPath,
            importPath: storage.importPath,
          },
        }));
      } else {
        dispatch(localStorageActions.setData(
          kijshopCd,
          'sendFormDesignation',
          {
            name: '',
            path: '',
            id: '',
            originalPath: '',
            importPath: '',
          }));
      }
    }
  }, [selectData, xml]);

  return (
    <div className="dialog_contents select_form_designation">
      <div className="dialog_contents__body">
        <div style={{ lineHeight: 2 }}>発送帳票</div>
        <div className="flex_box" style={{ gap: 8 }}>
          <Input
            value={formDesignation}
            onChange={(e) => handlerChangeDesignation(e.target.value)}
          />
          <Button
            label="リスト呼び出し"
            onClick={handlerClickCallList}
            style={{ minWidth: '8em' }}
          />
        </div>
      </div>
      <div className="dialog_contents__footer">
        <Button
          label="クリア"
          onClick={handlerClickClear}
        />
        <Button
          label="OK"
          onClick={handlerClickOK}
        />
      </div>
    </div>
  );
};
