import { XmlClass } from '../xml-class';
import { IOrderParts } from '../../interface/order/i-order-parts';
import { XmlClassName } from '../../model/xml-class-name';
import { TOrderPartsMetaModel } from '../../model/order/xml-order-parts-model';
import { OrderPartsDataXml } from './order-parts-data-xml';

type TMeta = Partial<TOrderPartsMetaModel>;

export class OrderPartsXml extends XmlClass<IOrderParts, {}, TMeta, [string]> {

  // indexes!: [string];

  name: XmlClassName = 'order-parts';
  viewModel: {} = {};
  metaModel: TMeta = {};

  // order-parts に紐づく order-parts-data
  orderPartsDataArr: OrderPartsDataXml[] = [];
  orderPartsData(partsNo: string | number): OrderPartsDataXml | null {
    const data = this.metaModel.paths?.find((v) => Number(v.id) === Number(partsNo));
    if (!data) {
      return null;
    }
    const orderPartsData = this.orderPartsDataArr.find((v) => v.xmlUniqueName === data.path);
    return orderPartsData || null;
  };

  orderPartsDataMain(): OrderPartsDataXml | null {
    const orderPartsData = this.orderPartsDataArr.find((v) => v.viewModel.type === 'main');
    return orderPartsData || null;
  };

  constructor(shopOrderId: string, data: { version: string, indexes: [string | number] }) {
    super(shopOrderId, data);
  }
  protected init(): void {
    this.xmlUniqueName = this.xmlUniqueName || `PPM_${this.shopOrderId}_11_11_orderParts_${this.indexes[0]}.xml`;
    this.xmlParentName = this.xmlParentName || `PPM_${this.shopOrderId}_11_11_orderInfoData_${this.indexes[0]}.xml`;
    this.rootTagModel = {
      ...this.rootTagModel,
      parentXPath: '/lnwOrderInfoData/orderParts',
      parentPath: this.xmlParentName,
    };
    this.docking({}, {});
  }

  protected docking(v: {}, m: TMeta): void {
    this.xmlModel = {
      lnwOrderParts: {
        $: { ...this.rootTagModel },
        orderParts: [
          {
            $: {
              count: `${m.paths?.length}`,
            },
            data: m.paths?.map((data, index) => ({
              $: {
                id: data.id || `${index + 1}`,
                path: data.path,
              },
            })),
          },
        ],
      },
    };
  }

  protected split(x: IOrderParts): void {
    if (!x?.lnwOrderParts?.orderParts || !x.lnwOrderParts.$) {
      this.metaModel = {};
      return;
    }
    this.metaModel = {
      paths: x.lnwOrderParts.orderParts?.[0]?.data?.map((v) => ({
        id: v?.$?.id || '',
        path: v?.$?.path || '',
      })),
    };
    this.rootTagModel = Object.assign(this.rootTagModel, x.lnwOrderParts.$);
  }

  changeIndexes(indexes: [string]) {
    this.indexes = indexes;
    this.xmlUniqueName = `PPM_${this.shopOrderId}_11_11_orderParts_${this.indexes[0]}.xml`;
    this.xmlParentName = `PPM_${this.shopOrderId}_11_11_orderInfoData_${this.indexes[0]}.xml`;
    this.rootTagModel = {
      ...this.rootTagModel,
      parentPath: this.xmlParentName,
    };
  }
}
