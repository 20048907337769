import {Table} from '../../ui/table/table';
import {useCallback, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {Checkbox} from '../../ui/input/checkbox';
import {Button} from '../../ui/button/button';
import {dialogActions} from '../slice/dialog-slice';
import { DisplayElement } from '../../../models/display-element';

type ReorderConfirmProps = {
  target: DisplayElement[][],
}
export const ReorderConfirm = (props: ReorderConfirmProps) => {
  const {target} = props;
  // - Hooks -
  const dispatch = useDispatch();
  // - State -
  // -- テーブルbody --
  const [tableBody, setTableBody] = useState<DisplayElement[][]>([]);
  // - Callback -
  // -- 再発注ボタン押下 --
  const handlerClickReorder = useCallback(() => {
    dispatch(dialogActions.pop());
  }, []);
  // -- キャンセルボタン押下 --
  const handlerClickCancel = useCallback(() => {
    dispatch(dialogActions.pop());
  }, []);
  const tableHead = ['選択', '受付日', 'お客様名', '注文番号', 'サイズ', '状態'];
  // - Effect -
  // -- targetにサイズの項目を追加してbodyにset --
  useEffect(() => {
    const sizeIndex = tableHead.indexOf('サイズ');
    setTableBody(target.map((v) => {
      const data = v.concat();
      if (data.length === 5) {
        data.splice(sizeIndex, 0, '-');
      }
      return data;
    }));
  }, []);
  return (
    <div>
      <div>
        以下のオーダーから再発注するオーダーを選択してください。
        <br />エラーが発生したオーダーのみ再発注可能です。
      </div>
      <div>
        <Table
          head={tableHead}
          body={tableBody}
        />
      </div>
      <div>
        <Button
          label="キャンセル"
          onClick={handlerClickCancel}
        />
        <Button
          label="再発注"
          onClick={handlerClickReorder}
        />
      </div>
    </div>
  );
};
