import {
  WizardBody,
  WizardBodyList,
  WizardButton,
  WizardButtonList,
  WizardContent,
  WizardMailNotice, WizardMailSentForEmail,
  WizardNotice,
} from '../../../ui/wizard/wizard-content';
import { push } from 'connected-react-router';
import { PathParams, RoutingPath } from '../../../../routes/routing-path';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

type Props = {
  setStep: (step: number) => void;
  mailAddress: string;
}
export const Step3StaffMngEnableWizard = (props: Props) => {
  const { setStep, mailAddress } = props;
  // - Hooks -
  const dispatch = useDispatch();
  const { kijshopCd } = useParams<PathParams>();
  return (
    <WizardContent>
      <WizardBodyList>
        <WizardBody body={[mailAddress,'　','確認メールを送信しました']} />
        <WizardBody body={WizardMailSentForEmail} />
      </WizardBodyList>
      <WizardNotice body={WizardMailNotice} />
      <WizardButtonList>
        <WizardButton
          label="戻る"
          // onClick={() => setStep(2)}
          onClick={() => dispatch(push(RoutingPath.cnv.configSystem({ kijshopCd })))}
        />
      </WizardButtonList>
    </WizardContent>
  );
};