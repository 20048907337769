
import { ApiBaseFront } from '../api-base-front';
import { ImageCheckPostRequest, ImageCheckPostResponse } from '../../../models/api/front/image-check';
import { AppThunk } from '../../../app/store';
import { ordersActions } from '../../../slices/orders-slice';
import { apiActions } from '../../../slices/api-slice';
import { ResponseBase } from '../../response-base';

export class ApiImageCheckPost extends ApiBaseFront<ImageCheckPostResponse[]> {
  constructor(param: ImageCheckPostRequest) {
    super(
      'POST',
      '/api/v1/piccheck',
      'JSON',
      param,
    );
  }
}

// xmlにデータを持たない場合は削除(store に持たないので使わないはず)
export const apiImageCheckThunk = (param: ImageCheckPostRequest, callback?: () => void): AppThunk => async (dispatch) => {
  if (!param.goodsId) {
    // dispatch(ordersActions.updateOrderInfo({
    //   imageNum: {
    //     min: 0,
    //     max: 0,
    //   },
    // }));
    return;
  }
  dispatch(apiActions.run(
    new ApiImageCheckPost(param),
    {
      onSuccess: (res: ResponseBase<ImageCheckPostResponse[]>) => {
        callback?.();
        dispatch(ordersActions.updateOrderInfo({
          imageNum: {
            min: res.body.data?.[0]?.length || 0,
            max: res.body.data?.[0]?.length || 0,
          },
        }));
      },
      onError: () => {
        callback?.();
      },
    },
    {
      apiName: 'image-check',
      ignoreSystemError: true,
    },
  ));
};