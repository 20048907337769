import {BoardOptionInfosRequest, BoardOptionInfosResponse} from '../../../../models/api/back/create-order/board-option-infos';
import {ResponseBase} from "../../../response-base";
import {AppThunk} from "../../../../app/store";
import {ordersDataActions} from "../../../../slices/orders/orders-data-slice";
import {apiActions} from "../../../../slices/api-slice";
import {ApiBaseBack} from "../../api-base-back";
import { ordersActions } from '../../../../slices/orders-slice';

export class ApiBoardOptionInfos extends ApiBaseBack<BoardOptionInfosResponse> {
  constructor(param: BoardOptionInfosRequest) {
    super(
      'GET',
      '/api/v1/optionBoardInfos',
      'JSON',
      param,
    );
  }
}

export const apiBoardOptionInfosThunk = (param: BoardOptionInfosRequest, callback: () => void, isInit: boolean, agent: boolean): AppThunk => async (dispatch, getState) => {
  if (!param.productNameId) {
    dispatch(ordersDataActions.updateOption({
      optionBoard: { boardInfo: [] },
    }));
    callback?.();
    return;
  }
  dispatch(apiActions.run(
    new ApiBoardOptionInfos(param),
    {
      onSuccess: (res: ResponseBase<BoardOptionInfosResponse>) => {
        callback?.();
        if (!isInit && param.productNameId !== getState().order.itemDetail.itemSelect.value) {
          return;
        }
        dispatch(ordersDataActions.updateOption({
          optionBoard: { boardInfo: res.body.data?.boardInfo || [] },
        }));
        const board = getState().order.itemDetail.boardSelect;
        if (board.value && res.body.data?.boardInfo?.find((v) => v.boardInfoId === board.value)) {
          dispatch(ordersActions.itemDetail.setBoardSelect(board.value, board.label, agent));
        }
      },
      onError: () => {
        dispatch(ordersDataActions.updateOption({
          optionBoard: { boardInfo: [] },
        }));
        callback?.();
      },
    },
    {
      apiName: 'board-option',
      ignoreSystemError: true,
    },
  ));
};
