import { TextFont, TextInfo, TextOption } from '../../../../components/pages/layout/toolbar/layout.free-text';
import * as lodash from 'lodash';
import { LayoutDataManager } from '../../data/layout-data.manager';
import { CjContainer, CjShape, CjStage, CjText } from '../../../model/cj-factory';
import { CjShadow } from '../../../model/cj-factory/cj-shadow';
import { CjGraphics } from '../../../model/cj-factory/cj-graphics';
import { TextEvent } from './text-event';
import { CJ_CONTAINER_NAME, CJ_DPOBJ_NAME, CJ_FOCUS_NAME } from '../../../model/cj-factory/cj-obj-name.collection';
import { EditableEvent } from '../editable-event';
import { CjTool } from '../../../../utilities/cj-tool';
import { UuidGenerator } from '../../../../utilities/uuid-generator';
import { ILayoutEditorManagerBase } from '../../layout-editor.manager';
import { PosManager } from '../../pos/pos.manager';
import { ImageEditManager } from '../image-edit.manager';
import { OrderPageDataXml } from '../../../../xml/class/order/order-page-data-xml';
import { FontSizeList } from '../../../model/font-size-list';
import { UiManager } from '../../ui/ui.manager';
import { dialogActions } from '../../../../components/dialog/slice/dialog-slice';
import { store } from '../../../../app/store';
import {
  LocalFontLoaderErrorDialog,
} from '../../../../components/pages/layout/toolbar/layout.local-font-loader-error-dialog';
import { push } from 'connected-react-router';
import { RoutingPath } from '../../../../routes/routing-path';
import { layoutActions } from '../../../../slices/layout-slice';
import { ColorCodeConverter } from '../../../../utilities/colorcode-converter';
import { cloneDeep } from 'lodash';

type TextType = {
  id: string,
  text: string,
  y: number,
  x: number,
}

export class TextController {
  private stage: CjContainer;
  private readonly pageId: string;
  public orderPage?: OrderPageDataXml;

  private textEvent!: TextEvent;
  private layoutDataManager!: LayoutDataManager;
  private editableEvent!: EditableEvent;
  private posManager!: PosManager;

  private textData: TextType = {
    id: '',
    text: '',
    y: 0,
    x: 0,
  };

  fontType: '' | 'local' | 'server' = 'local';

  // textContainer?: CjContainer;
  // textImage?: CjText;
  backupText?: CjText;
  backupTextContainer?: CjContainer;
  outlineData?: CjText;
  shadow?: CjShadow;

  textMode: 'add' | 'reload' = 'add';
  textBox: CjContainer[] = [];

  constructor(stage: CjContainer, pageId: string) {
    this.stage = stage;
    this.pageId = pageId;
  }

  di(
    textEvent: TextEvent,
    layoutDataManager: LayoutDataManager,
    editableEvent: EditableEvent,
    posManager: PosManager,
  ): void {
    this.textEvent = textEvent;
    this.layoutDataManager = layoutDataManager;
    this.editableEvent = editableEvent;
    this.posManager = posManager;
  }

  initialize(): void {
  }

  destroy(): void {
    this.textData = { id: '', text: '', x: 0, y: 0 };
    this.fontType = 'local';
    // this.textContainer = undefined;
    // this.textImage = undefined;
    this.backupText = undefined;
    this.backupTextContainer = undefined;
    this.outlineData = undefined;
    this.shadow = undefined;
    this.textMode = 'add';
    this.textBox = [];
  }

  update(stage: CjContainer): void {
    this.stage = stage;
  }

  textAlignFormat(textAlign: string) {
    switch (textAlign) {
      case 'left':
        return '0';
      case 'center':
        return '1';
      case 'right':
        return '2';
      default:
        return null;
    }
  };

  // - テキストモード -
  insertText(e: any, callback: (isOpen: boolean, info: TextInfo) => void) {
    const pos = this.posManager.browserToCanvas({
      x: e.stageX - ImageEditManager.STAGE_CONTAINER_X,
      y: e.stageY - ImageEditManager.STAGE_CONTAINER_Y,
    });
    this.textData.y = pos.y;
    this.textData.x = pos.x;
    this.addText().then((res) => {
      callback(
        true,
        {
          id: res.textId ?? '',
          isBold: false,
          isItalic: false,
          textColor: '#808080',
          isOnDropper: false,
          fontType: 'server',
          font: '0',
          fontSize: '24',
          textTransparency: 100,
          dropShadow: false,
          transparency: 65,
          distance: 7,
          outlineWidth: 0,
          outlineColor: '#ff0000',
          textAlign: '0',
          textRotation: 0,
          textSelectRotation: 0,
          lineSpacingNum: 1,
          textAreaContents: '',
          height: 0,
        });
    });
    document.body.style.cursor = '';
    // this.fbCanvas?.off('mouse:up')
  }

  // - テキストの挿入 -
  async addText() {
    return new Promise<CjContainer>((resolve) => {
      // await new Promise<void>((resolve) => {
      const newText = new CjText();
      newText.set({
        textId: `text:${UuidGenerator.create()}`,
        // y: this.textData.y,
        // x: this.textData.x,
        name: CJ_DPOBJ_NAME.text,
        originPos: {
          y: this.textData.y,
          x: this.textData.x,
        },
      });
      // フォント読み込み用遅延
      setTimeout(() => {
        const container = this.layoutDataManager.selector('getEditors', this.pageId) as CjContainer[];
        const pageType = container.length ? container[0].pageType : '';
        const textContainer = new CjContainer(this.pageId, undefined, undefined, undefined, newText.textId);
        textContainer.name = CJ_CONTAINER_NAME.text;
        textContainer.pageType = pageType;
        textContainer.addChild(newText);
        // textContainer.mouseEnabled = false;
        textContainer.set({
          y: this.textData.y,
          x: this.textData.x,
        });
        // this.textImage = newText;
        // this.textEvent.setFocus(textContainer);
        // this.textEvent.setTextEvent(newText, textContainer);
        this.stage.addChild(textContainer);
        this.layoutDataManager.dispatch('setTextContainer', textContainer);
        this.backupText = cloneDeep(newText);
        this.backupTextContainer = cloneDeep(textContainer);
        this.textBox.push(textContainer);
        resolve(textContainer);
      });
    });
  }

  // FIXME: Undo時に最終的にはテキストを消す。アウトラインとシャドウ併用時挙動に不具合あり
  // - テキストの挿入 -
  async editText(id: string, text: string, color: string, alpha: number, rotation: number, textAlign: string, lineHeight: number, font: TextFont, options: TextOption, backupText: CjText, isRestore?: boolean, callback?: () => void) {
    const textContainer = this.layoutDataManager.selector('getText', id);
    if (!textContainer) return;
    const textData = CjTool.getText(textContainer as CjContainer);
    if (!textData) return;
    const step = textData.text ? 'edit' : 'create';
    // -- テキスト装飾 --
    textData.fontData = font;
    textData.options = options;
    textData.font = `${font.isBold} ${font.isItalic} ${font.size} ${font.family}`.trim();
    textData.text = text;
    textData.alpha = alpha;
    textContainer.rotation = rotation;
    textData.color = color;
    textData.textAlign = textAlign;
    textData.lineHeight = textData.getMeasuredLineHeight() + Number(lineHeight);
    const textBounds = textData.getBounds();
    switch (textAlign) {
      case 'center':
        textData.x = textBounds.width / 2;
        textData.margin = textBounds.width / 2;
        break;
      case 'right':
        textData.x = textBounds.width;
        textData.margin = textBounds.width;
        break;
      default:
        textData.x = 0;
        textData.margin = 0;
        break;
    }
    // -- アウトライン設定時は複製して重ねる --
    if (options.outline) {
      if (CjTool.checkOutline(textContainer)) {
        const prevOutline = CjTool.getOutline(textContainer);
        textContainer.removeChild(prevOutline);
      }
      const outlineText = textData.clone();
      outlineText.mouseEnabled = false;
      outlineText.name = CJ_DPOBJ_NAME.outline;
      outlineText.outline = Number(options.outline);
      outlineText.color = options.outlineColor;
      textContainer.addChild(outlineText);

      if (options.isShadow) {
        this.shadow = new CjShadow(options.shadowTransparency, Number(options.offset), Number(options.offset), 0);
        outlineText.shadow = this.shadow;
      } else {
        (outlineText.shadow as createjs.Shadow | null) = null;
      }
  
    } else {
      if (CjTool.checkOutline(textContainer)) {
        const prevOutline = CjTool.getOutline(textContainer);
        textContainer.removeChild(prevOutline);
      }
    }
    if (options.isShadow) {
      this.shadow = new CjShadow(options.shadowTransparency, Number(options.offset), Number(options.offset), 0);
      textData.shadow = this.shadow;
    } else {
      (textData.shadow as createjs.Shadow | null) = null;
    }
    // textContainer.rotation = rotation;
    const textTfBounds = textData.getTransformedBounds();
    const hitArea = new CjShape();
    hitArea.set({
      x: hitArea.x,
      y: hitArea.y,
      graphics: new CjGraphics().f('#FFFFFF').dr(-(textData.margin), 0, textTfBounds.width, textTfBounds.height),
    });
    textData!.hitArea = hitArea;
    this.fontType = options.fontType;
    if (step === 'create') {
      this.textEvent.setFocus(textContainer, !!isRestore, () => {
        this.textEvent.setTextEvent(textData, textContainer);
        this.editableEvent.action(
          'addText',
          `テキストを追加。`,
          () => {
            this.stage.addChild(textContainer);
            textContainer.fontName = font.family;
            textContainer.bold = font.isBold;
            textContainer.italic = font.isItalic;
            textContainer.textSize = font.size;
            textContainer.horizontalAlignment = this.textAlignFormat(textAlign);
            textContainer.borderSize = Number(options.outline);
            textContainer.borderColor = options.outlineColor;
            textContainer.rotation = rotation;
            textContainer.dropShadow = {
              enable: options.isShadow,
              transparence: options.shadowTransparency,
              virtualSize: Number(options.offset),
            };
            this.textEvent.updateFocus(textContainer);
            this.layoutDataManager.dispatch('setTextContainer', textContainer);
          },
          () => {
            this.stage.removeChild(textContainer!);
            this.textBox = this.textBox.filter(v => v.id !== textContainer.textId);
            this.layoutDataManager.dispatch('removeTextContainer', textContainer);
          },
        );
        callback?.();
      });
    } else {
      if (!textData || !textContainer) return;
      textData.fontData = font;
      textData.options = options;
      textData.font = `${font.isBold} ${font.isItalic} ${font.size} ${font.family}`.trim();
      textContainer.fontName = font.family;
      textContainer.bold = font.isBold;
      textContainer.italic = font.isItalic;
      textContainer.textSize = font.size;
      textData.text = text;
      textData.alpha = alpha;
      textContainer.rotation = rotation;
      textData.color = color;
      textData.textAlign = textAlign;
      textContainer.horizontalAlignment = this.textAlignFormat(textAlign);
      textData.lineHeight = textData.getMeasuredLineHeight() + Number(lineHeight);
      textContainer.lineHeight = Number(lineHeight);
      // -- アウトライン設定時は複製して重ねる --
      if (options.outline) {
        if (CjTool.checkOutline(textContainer)) {
          const prevOutline = CjTool.getOutline(textContainer);
          textContainer.removeChild(prevOutline);
        }
        const outlineText = textData.clone();
        outlineText.mouseEnabled = false;
        outlineText.name = CJ_DPOBJ_NAME.outline;
        outlineText.outline = options.outline;
        textContainer.borderSize = options.outline;
        outlineText.color = options.outlineColor;
        textContainer.borderColor = options.outlineColor;
        textContainer.addChild(outlineText);
      } else {
        if (CjTool.checkOutline(textContainer)) {
          const prevOutline = CjTool.getOutline(textContainer);
          textContainer.removeChild(prevOutline);
        }
      }
      if (options.isShadow) {
        textContainer.dropShadow = {
          enable: options.isShadow,
          transparence: options.shadowTransparency,
          virtualSize: options.offset,
        };
        this.shadow = new CjShadow(options.shadowTransparency, options.offset, options.offset, 0);
        textData.shadow = this.shadow;
      } else {
        (textData.shadow as createjs.Shadow | null) = null;
        textContainer.dropShadow = {
          enable: false,
          transparence: 'rgba(0,0,0,0.65)',
          virtualSize: 7,
        };
      }
      const textTfBounds = textData.getTransformedBounds();
      const hitArea = new CjShape();
      hitArea.set({
        x: hitArea.x,
        y: hitArea.y,
        graphics: new CjGraphics().f('#FFFFFF').dr(-(textData.margin), 0, textTfBounds.width, textTfBounds.height),
      });
      textData.hitArea = hitArea;
      this.fontType = options.fontType;
      this.textEvent.updateFocus(textContainer);
      this.layoutDataManager.dispatch('setTextContainer', textContainer);
    }
  }

  finishTextEdit(id: string) {
    const textContainer = this.layoutDataManager.selector('getText', id);
    if (!textContainer) return;
    const textData = CjTool.getText(textContainer as CjContainer);
    const step = textData.text ? 'edit' : 'create';
    if (!textData || step === 'create') {
      this.stage.stage.removeChild(textContainer);
      this.textBox = this.textBox.filter(v => v.id !== textContainer.textId);
      this.layoutDataManager.dispatch('removeTextContainer', textContainer);
      return;
    }
    if (!this.backupText || !this.backupText.text) {
      this.backupText = cloneDeep(textData);
      this.backupTextContainer = cloneDeep(textContainer);
      return;
    }
    const prevText = cloneDeep(textData);
    const prevTextContainer = cloneDeep(textContainer);
    this.editableEvent.action(
      'editText',
      `テキストを編集`,
      () => {
        if (!textData || !textContainer) return;
        textData.fontData = prevText.fontData;
        textData.options = prevText.options;
        textData.font = prevText.font;
        textContainer.fontName = prevTextContainer.fontName;
        textContainer.bold = prevTextContainer.bold;
        textContainer.italic = prevTextContainer.italic;
        textContainer.textSize = prevTextContainer.textSize;
        textData.text = prevText.text;
        textData.alpha = prevText.alpha;
        textContainer.rotation = prevTextContainer.rotation;
        textData.color = prevText.color;
        textData.textAlign = prevText.textAlign;
        textContainer.horizontalAlignment = prevTextContainer.horizontalAlignment;
        textData.lineHeight = prevText.lineHeight;
        textContainer.lineHeight = prevTextContainer.lineHeight;
        // -- アウトライン設定時は複製して重ねる --
        if (prevText.options && prevText.options?.outline) {
          if (CjTool.checkOutline(textContainer)) {
            const prevOutline = CjTool.getOutline(textContainer);
            textContainer.removeChild(prevOutline);
          }
          const outlineText = textData.clone();
          outlineText.mouseEnabled = false;
          outlineText.name = CJ_DPOBJ_NAME.outline;
          outlineText.outline = prevText.options.outline;
          textContainer.borderSize = prevText.options.outline;
          outlineText.color = prevText.options.outlineColor;
          textContainer.borderColor = prevText.options.outlineColor;
          textContainer.addChild(outlineText);
        } else {
          textContainer.borderSize = 0
          textContainer.borderColor = '#ff0000'
          if (CjTool.checkOutline(textContainer)) {
            const prevOutline = CjTool.getOutline(textContainer);
            textContainer.removeChild(prevOutline);
          }
        }
        if (prevText.options && prevText.options.isShadow) {
          textContainer.dropShadow = {
            enable: prevText.options.isShadow,
            transparence: prevText.options.shadowTransparency,
            virtualSize: prevText.options.offset,
          };
          this.shadow = new CjShadow(prevText.options.shadowTransparency, prevText.options.offset, prevText.options.offset, 0);
          textData.shadow = this.shadow;
        } else {
          (textData.shadow as createjs.Shadow | null) = null;
          textContainer.dropShadow = {
            enable: false,
            transparence: 'rgba(0,0,0,0.65)',
            virtualSize: 7,
          };
        }
        const textTfBounds = textData.getTransformedBounds();
        const hitArea = new CjShape();
        hitArea.set({
          x: hitArea.x,
          y: hitArea.y,
          graphics: new CjGraphics().f('#FFFFFF').dr(-(textData.margin), 0, textTfBounds.width, textTfBounds.height),
        });
        textData.hitArea = hitArea;
        this.fontType = prevText.options?.fontType ?? '';
        this.textEvent.updateFocus(textContainer);
        this.layoutDataManager.dispatch('setTextContainer', textContainer);
      },
      () => {
        if (!textData || !textContainer || !this.backupText || !this.backupTextContainer) return;
        textData.fontData = this.backupText.fontData;
        textData.options = this.backupText.options;
        textData.font = this.backupText.font;
        textData.text = this.backupText.text;
        textData.alpha = this.backupText.alpha;
        textContainer.fontName = this.backupTextContainer.fontName;
        textContainer.bold = this.backupTextContainer.bold;
        textContainer.italic = this.backupTextContainer.italic;
        textContainer.textSize = this.backupTextContainer.textSize;
        textContainer.rotation = this.backupTextContainer.rotation;
        textData.color = this.backupText.color;
        textData.textAlign = this.backupText.textAlign;
        textData.lineHeight = this.backupText.lineHeight;
        textContainer.horizontalAlignment = this.backupTextContainer.horizontalAlignment;
        textContainer.lineHeight = this.backupTextContainer.lineHeight;
        if (this.backupText.options?.outline) {
          if (CjTool.checkOutline(textContainer)) {
            const prevOutline = CjTool.getOutline(textContainer);
            textContainer.removeChild(prevOutline);
          }
          const outlineText = textData.clone();
          outlineText.mouseEnabled = false;
          outlineText.name = CJ_DPOBJ_NAME.outline;
          outlineText.outline = this.backupText.options.outline;
          outlineText.color = this.backupText.options.outlineColor;
          textContainer.borderSize = this.backupText.options.outline;
          textContainer.borderColor = this.backupText.options.outlineColor;
          textContainer.addChild(outlineText);
        } else {
          textContainer.borderSize = 0
          textContainer.borderColor = '#ff0000'
          if (CjTool.checkOutline(textContainer)) {
            const prevOutline = CjTool.getOutline(textContainer);
            textContainer.removeChild(prevOutline);
          }
        }
        if (this.backupText.options?.isShadow) {
          this.shadow = new CjShadow(this.backupText.options!.shadowTransparency, this.backupText.options!.offset, this.backupText.options!.offset, 0);
          textData.shadow = this.shadow;
          textContainer.dropShadow = {
            enable: this.backupText.options.isShadow,
            transparence: this.backupText.options.shadowTransparency,
            virtualSize: this.backupText.options.offset,
          };
        } else {
          (textData.shadow as createjs.Shadow | null) = null;
          this.shadow = undefined;
          textContainer.dropShadow = {
            enable: false,
            transparence: 'rgba(0,0,0,0.65)',
            virtualSize: 7,
          };
          if (CjTool.checkOutline(textContainer)) {
            const prevOutline = CjTool.getOutline(textContainer);
            (prevOutline.shadow as createjs.Shadow | null) = null;
          }
        }
        this.fontType = textData.options?.fontType ?? 'local';
        this.layoutDataManager.dispatch('setTextContainer', textContainer);
      },
    );
  }

  async restoreDo(orderText: any, backup: CjText) {
    return new Promise<void>(async (resolve) => {
      UiManager.ins.emit('l->r:web-font:get-list', {
        callback: async (p) => {
          const isServer = p.fonts.includes(orderText.text.fontName);
          const textAlign = () => {
            switch (orderText.text.horizontalAlignment) {
              case '0':
                return 'left';
              case '1':
                return 'center';
              case '2':
                return 'right';
              default:
                return 'left';
            }
          };
          if (isServer) {
            UiManager.ins.emit('l->r:web-font:download', {
              fontFamilyName: orderText.text.fontName,
              callback: () => {
                this.editText(
                  backup.textId ?? '',
                  orderText.text.text,
                  (orderText.text.fontColor && '#' + ColorCodeConverter.paddingZero(orderText.text.fontColor.toUpperCase())),
                  (Number(orderText.text.transparence) / 100),
                  Number(orderText.textRotate),
                  textAlign(),
                  Number(orderText.text.lineHeight),
                  {
                    isBold: Boolean(Number(orderText.text.bold)) ? 'bold' : '',
                    isItalic: Boolean(Number(orderText.text.italic)) ? 'italic' : '',
                    size: orderText.text.textSize + 'px',
                    family: orderText.text.fontName,
                  },
                  {
                    fontType: 'server',
                    isShadow: orderText.text.dropShadow.enable === 'true',
                    shadowTransparency: `rgba(0, 0, 0, ${Number(orderText.text.dropShadow.transparence) / 100})`,
                    offset: Number(orderText.text.dropShadow.virtualSize),
                    outlineColor: orderText.text.borderColor && '#' + ColorCodeConverter.paddingZero(orderText.text.borderColor.toUpperCase()),
                    outline: Number(orderText.text.borderSize),
                  },
                  backup!,
                  true,
                );
                resolve();
              },
              error: (msg: string) => {
                alert('フォントの読み込みに失敗しました。');
              },
            });
          } else {
            const fontName = await this.checkFont(orderText.text.fontName);
            this.editText(
              backup.textId ?? '',
              orderText.text.text,
              (orderText.text.fontColor && '#' + ColorCodeConverter.paddingZero(orderText.text.fontColor.toUpperCase())),
              (Number(orderText.text.transparence) / 100),
              Number(orderText.textRotate),
              textAlign(),
              Number(orderText.text.lineHeight),
              {
                isBold: Boolean(Number(orderText.text.bold)) ? 'bold' : '',
                isItalic: Boolean(Number(orderText.text.italic)) ? 'italic' : '',
                size: orderText.text.textSize + 'px',
                family: fontName,
              },
              {
                fontType: 'local',
                isShadow: orderText.text.dropShadow.enable === 'true',
                shadowTransparency: `rgba(0, 0, 0, ${Number(orderText.text.dropShadow.transparence) / 100})`,
                offset: Number(orderText.text.dropShadow.virtualSize),
                outlineColor: orderText.text.borderColor && '#' + ColorCodeConverter.paddingZero(orderText.text.borderColor.toUpperCase()),
                outline: Number(orderText.text.borderSize),
              },
              backup!,
              true,
            );
            resolve();
          }
        },
      });
    });
  }

  async checkFont(fontName: string) {
    return new Promise<string>((resolve) => {
      UiManager.ins.emit(
        'l->r:font:check-support',
        {
          callback: (e: { supported: boolean }) => {
            if (!e.supported) {
              store.dispatch(dialogActions.push({
                title: 'ローカルフォント読み込みエラー',
                element: LocalFontLoaderErrorDialog({
                  callback: () => {
                    store.dispatch(dialogActions.pop());
                    UiManager.ins.emit('l->r:back-preparation');
                  },
                  closeCallback: () => {
                    store.dispatch(dialogActions.pop());
                    UiManager.ins.emit('l->r:back-preparation');
                  },
                  reloadCallback: async () => {
                    store.dispatch(dialogActions.pop());
                    this.checkFont(fontName).then((v) => resolve(v));
                  },
                  warningMessage: ['キャンセルすると、デザインを中止します。'],
                }),
                onWillClose: () => {
                  store.dispatch(dialogActions.pop());
                  UiManager.ins.emit('l->r:back-preparation');
                },
                className: 'local-font-loader-error-dialog',
                closeBtn: true,
              }));
              return;
            } else {
              // ---- フォントアクセス ----
              UiManager.ins.emit(
                'l->r:font:get-list',
                {
                  callback: (e: { fonts: string[] }) => {
                    if (e.fonts.includes(fontName)) {
                      resolve(fontName);
                    } else {
                      resolve(e.fonts[0]);
                      store.dispatch(layoutActions.pushUnFindFontList(fontName));
                    }
                  },
                },
              );
            }
          },
        },
      );
    });
  }

  async restoreText() {
    if (this.orderPage && this.orderPage.viewModel.orderText) {
      for (const orderText of this.orderPage?.viewModel.orderText?.data || []) {
        this.textData.y = Number(orderText.textRect?.virtual?.y ?? 0);
        this.textData.x = Number(orderText.textRect?.virtual?.x ?? 0);
        const textContainer = await this.addText();
        const text = CjTool.getText(textContainer);
        const backup = lodash.cloneDeep(text);
        await this.restoreDo(orderText, backup);
      }
    }
  }

  async changeCoverDo(textContainer: CjContainer, backup: CjText) {
    return new Promise<void>(async (resolve) => {
      UiManager.ins.emit('l->r:web-font:get-list', {
        callback: async (p) => {
          const text = textContainer.getChildByName(CJ_DPOBJ_NAME.text) as CjText;
          const outline = textContainer.getChildByName(CJ_DPOBJ_NAME.outline) as createjs.Text;
          const isServer = p.fonts.includes(textContainer.fontName ?? '');
          if (isServer) {
            UiManager.ins.emit('l->r:web-font:download', {
              fontFamilyName: textContainer.fontName ?? '',
              callback: () => {
                this.editText(
                  backup.textId ?? '',
                  text.text,
                  text.color,
                  text.alpha,
                  textContainer.rotation,
                  text.textAlign,
                  Math.floor(Number(text.lineHeight) - text.getMeasuredLineHeight()),
                  text.fontData!,
                  {
                    fontType: 'server',
                    isShadow: !!textContainer.dropShadow?.enable,
                    shadowTransparency: textContainer.dropShadow?.transparence ?? '100',
                    offset: Number(textContainer.dropShadow?.virtualSize ?? 1),
                    outlineColor: outline?.color ?? '#ff0000',
                    outline: outline?.outline ?? 0,
                  },
                  backup!,
                  true,
                );
                resolve();
              },
              error: (msg: string) => {
                alert('フォントの読み込みに失敗しました。');
                resolve();
              },
            });
          } else {
            const fontName = await this.checkFont(textContainer.fontName ?? '');
            this.editText(
              backup.textId ?? '',
              text.text,
              text.color,
              text.alpha,
              textContainer.rotation,
              text.textAlign,
              Math.floor(Number(text.lineHeight) - text.getMeasuredLineHeight()),
              {
                isBold: text.fontData?.isBold ?? '',
                isItalic: text.fontData?.isItalic ?? '',
                size: text.fontData?.size ?? '24px',
                family: fontName,
              },
              {
                fontType: 'local',
                isShadow: !!textContainer.dropShadow?.enable,
                shadowTransparency: textContainer.dropShadow?.transparence ?? '100',
                offset: Number(textContainer.dropShadow?.virtualSize ?? 1),
                outlineColor: outline?.color ?? '#ff0000',
                outline: outline?.outline ?? 0,
              },
              backup!,
              true,
            );
            resolve();
          }
        },
      });
    });
  }

  async changeCover(_textContainer: CjContainer) {
        this.textData.x = _textContainer.x;
        this.textData.y = _textContainer.y;
        const textContainer = await this.addText();
        const text = CjTool.getText(textContainer);
        const backup = lodash.cloneDeep(text);
        await this.changeCoverDo(_textContainer, backup);
  }

}
