import { ApiBaseFront } from '../api-base-front';

type ParamType = {
  kijshopCd: string,
  orderExpiredDay: number,
  shopName?: string,
  staffLoginValidFlg?: 0 | 1,
}

export class ApiEditCloudFolderShop extends ApiBaseFront {
  constructor(_param: ParamType) {
    const { kijshopCd, ...param } = _param;
    super(
      'POST',
      `/api/v1/admin/c-folder/shop/${kijshopCd}`,
      'JSON',
      param,
    );
  }

}
