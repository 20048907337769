import {
  WizardBody,
  WizardBodyList,
  WizardButton,
  WizardButtonList,
  WizardContent,
} from '../../../ui/wizard/wizard-content';
import { useParams } from 'react-router-dom';
import { PathParams, RoutingPath } from '../../../../routes/routing-path';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';

export const Step5StaffMngEnableWizard = () => {
  // - Hooks -
  const { kijshopCd } = useParams<PathParams>();
  const dispatch = useDispatch();
  return (
    <WizardContent>
      <WizardBodyList>
        <WizardBody body={['スタッフ認証機能が有効になりました','　','今後の店鋪ログインは、新しく設定した店鋪用パスワードを利用ください']} />
      </WizardBodyList>
      <WizardButtonList>
        <WizardButton
          label="閉じる"
          onClick={() => dispatch(push(RoutingPath.cnv.configSystem({ kijshopCd })))}
        />
      </WizardButtonList>
    </WizardContent>
  );
};