import {
  TContentData,
  TVisibleCondition,
  TVisibleConditionBodyData,
  TVisibleContent,
} from '../models/t-visible-condition';
import { PartialOrderSliceState } from '../slices/orders-slice';
import { OrdersDataState } from '../slices/orders/orders-data-slice';
import lodash from 'lodash';
import { DescriptionInfoGetResponse } from '../models/api/front/description-info';

type ItemDetailOrderDisplay = {
  cover: TContentData | undefined,
  coverColor: TContentData | undefined,
  item: TContentData | undefined,
  board: TContentData | undefined,
  orderMethod: TContentData | undefined,
  designType1: TContentData | undefined,
  designType2: TContentData | undefined,
  quantity: (TContentData & { max: number }) | undefined,
};

type DescriptionInfoOrderDisplay = {
  name: TContentData | undefined,
  nameRestrict: TContentData | undefined,
  nameNenga: TContentData | undefined,
  date: TContentData | undefined,
  dateKana: TContentData | undefined,
  location: TContentData | undefined,
  locationHeight: TContentData | undefined,
  comment: TContentData | undefined,
  locationAddress: TContentData | undefined,
};

type CoverOrderDisplay = {
  laminate: TContentData | undefined,
  color: TContentData | undefined,
  leafColor: TContentData | undefined,
  leafText1: TContentData | undefined,
  leafText2: TContentData | undefined,
};

type GenuineOrderDisplay = {
  laminate: TContentData | undefined,
  leafColor: TContentData | undefined,
  leafText1: TContentData | undefined,
  leafText2: TContentData | undefined,
  additional: TContentData | undefined,
  additional2: TContentData | undefined,
  additional3: TContentData | undefined,
  additionalMulti: TContentData | undefined,
  chronology: TContentData | undefined,
  page: TContentData | undefined,
};

type OptionOrderDisplay = {
  itemTag: TContentData | undefined,
  item: TContentData | undefined,
  quantity: (TContentData & { max?: number }) | undefined,
  laminate: TContentData | undefined,
  descriptionInfo: TContentData | undefined,
  description: boolean,
};

export class VisibleConditionValueGetter {

  private preOrder!: PartialOrderSliceState;
  private preData!: OrdersDataState;

  _createOrderDisplay = {
    descriptionInfo: false,
    cover: false,
    genuine: false,
    option: false,
    remarks: false,
    mailRemarks: false,
  };
  get createOrderDisplay(): {
    descriptionInfo: boolean,
    cover: boolean,
    genuine: boolean,
    option: boolean,
    remarks: boolean,
    mailRemarks: boolean,
  } {
    return this._createOrderDisplay;
  }

  /* コンポネント更新判定用に order, data の比較を行う */
  checkOrderDifference(order: PartialOrderSliceState, data: OrdersDataState) {
    if (!lodash.isEqual({ order, data }, { order: this.preOrder, data: this.preData })) {
      this.preOrder = order;
      this.preData = data;
      this.getCreateOrderDisplay(order, data);
    }
  }

  /* 商品詳細 */
  private _itemDetailOrderDisplay!: ItemDetailOrderDisplay;
  get itemDetailOrderDisplay(): ItemDetailOrderDisplay {
    return this._itemDetailOrderDisplay || {};
  }
  /* 商品記載情報 */
  private _descriptionInfoOrderDisplay!: DescriptionInfoOrderDisplay;
  get descriptionInfoOrderDisplay(): DescriptionInfoOrderDisplay {
    return this._descriptionInfoOrderDisplay || {};
  }
  /* 表紙 */
  private _coverOrderDisplay!: CoverOrderDisplay;
  get coverOrderDisplay(): CoverOrderDisplay {
    return this._coverOrderDisplay || {};
  }
  /* 本身 */
  private _genuineOrderDisplay!: GenuineOrderDisplay;
  get genuineOrderDisplay(): GenuineOrderDisplay {
    return this._genuineOrderDisplay || {};
  }
  /* オプション */
  private _optionOrderDisplay: OptionOrderDisplay[] = [];
  get optionOrderDisplay(): OptionOrderDisplay[] {
    return this._optionOrderDisplay;
  }

  visibleData!: TVisibleConditionBodyData;
  kijshopCd!: string;
  di(data: Partial<{ visibleCondition: TVisibleCondition, kijshopCd: string }>) {
    if (data?.visibleCondition?.data?.[0]) {
      this.visibleData = data.visibleCondition.data[0];
    }
    if (data?.kijshopCd) {
      this.kijshopCd = data.kijshopCd;
    }
  }

  /* 各親項目の表示判定 */
  /* 子要素の表示判定が後に呼ばれるのを期待する動作として精査はここでのみ行い結果を保持する */
  getCreateOrderDisplay(order: PartialOrderSliceState, data: OrdersDataState | undefined = undefined) {
    this.preOrder = order;
    if (data) {
      this.preData = data;
    }
    this._itemDetailOrderDisplay = this.getItemDetailDisplay(order, data);
    this._descriptionInfoOrderDisplay = this.getDescriptionInfoDisplay(order, data);
    this._coverOrderDisplay = this.getCoverDisplay(order, data);
    this._genuineOrderDisplay = this.getGenuineDisplay(order, data);
    this._optionOrderDisplay = order.option?.map((_, i) => this.getOptionOrderDisplay(order, data, i)) || [];
    const checkKey = (obj: any) => Object.keys(obj).find((key) => obj[key]);
    const itemDetail = checkKey(this._itemDetailOrderDisplay) && this.visibleData?.o_productInfo_01?.visibleCondition && this.visibleData.o_productInfo_01.visibleCondition.map((v) => this.checkVisible(order, data, 'o_productInfo_01', v)).find((v) => v);
    const descriptionInfo = checkKey(this._descriptionInfoOrderDisplay) && this.visibleData?.op01_productItemInfo?.visibleCondition && this.visibleData.op01_productItemInfo.visibleCondition.map((v) => this.checkVisible(order, data, 'op01_productItemInfo', v)).find((v) => v);
    const cover = checkKey(this._coverOrderDisplay) && this.visibleData?.op01_cover?.visibleCondition && this.visibleData.op01_cover.visibleCondition.map((v) => this.checkVisible(order, data, 'op01_cover', v)).find((v) => v);
    const genuine = checkKey(this._genuineOrderDisplay) && this.visibleData?.op01_body?.visibleCondition && this.visibleData.op01_body.visibleCondition.map((v) => this.checkVisible(order, data, 'op01_body', v)).find((v) => v);
    const option = this.visibleData?.op01_option?.visibleCondition && this.visibleData.op01_option.visibleCondition.map((v) => this.checkVisible(order, data, 'op01_option', v)).find((v) => v);
    const remarks = this.visibleData?.o_remarks?.visibleCondition && this.visibleData.o_remarks.visibleCondition.map((v) => this.checkVisible(order, data, 'o_remarks', v)).find((v) => v);
    const mailRemarks = this.visibleData?.o_mail_remarks?.visibleCondition && this.visibleData.o_mail_remarks.visibleCondition.map((v) => this.checkVisible(order, data, 'o_mail_remarks', v)).find((v) => v);
    this._createOrderDisplay = {
      descriptionInfo: Boolean(descriptionInfo),
      cover: Boolean(cover),
      genuine: Boolean(genuine),
      option: Boolean(option),
      remarks: Boolean(remarks),
      mailRemarks: Boolean(mailRemarks),
    };
    return {
      itemInfo: true,
      itemDetail,
      descriptionInfo,
      cover,
      genuine,
      option,
      remarks,
      mailRemarks,
    };
  }

  /* 商品情報の表示判定 */
  private getItemInfoDisplay(order: PartialOrderSliceState, data: OrdersDataState) {
    return {
      category: true,
      tag: this.visibleData?.orderInfo?.op01_item_tag?.visibleCondition && this.checkContentVisible(order, data, 'op01_item_tag', this.visibleData.orderInfo.op01_item_tag),
    };
  }

  /* 商品詳細の表示判定 */
  private getItemDetailDisplay(order: PartialOrderSliceState, data: OrdersDataState | undefined = undefined): ItemDetailOrderDisplay {
    const itemDetail = this.visibleData?.o_productInfo_01;
    const outputCount1 = this.checkContentVisible(order, data, 'op01_outputCount', itemDetail?.op01_outputCount);
    const outputCount2 = this.checkContentVisible(order, data, 'op01_outputCount2', itemDetail?.op01_outputCount2);
    const outputCount3 = this.checkContentVisible(order, data, 'op01_outputCount3', itemDetail?.op01_outputCount3);
    return {
      cover: this.checkContentVisible(order, data, 'op01_cover_pre_select', itemDetail?.op01_cover_pre_select),
      coverColor: this.checkContentVisible(order, data, 'op01_coverColor_pre_select', itemDetail?.op01_coverColor_pre_select),
      item: this.checkContentVisible(order, data, 'op01_item', itemDetail?.op01_item),
      board: this.checkContentVisible(order, data, 'op01_boardInfo_pre_select', itemDetail?.op01_boardInfo_pre_select),
      orderMethod: this.checkContentVisible(order, data, 'o_surrogateProcess1_orderFlow_full', itemDetail?.o_surrogateProcess1_orderFlow_full),
      designType1: this.checkContentVisible(order, data, 'op01_designType1', itemDetail?.op01_designType1),
      designType2: this.checkContentVisible(order, data, 'op01_designType2', itemDetail?.op01_designType2),
      // quantity: this.checkContentVisible(order, data, 'op01_outputCount', itemDetail?.op01_outputCount)
      //   || this.checkContentVisible(order, data, 'op01_outputCount2', itemDetail?.op01_outputCount2)
      //   || this.checkContentVisible(order, data, 'op01_outputCount3', itemDetail?.op01_outputCount3),
      quantity: outputCount1 ? ({ ...outputCount1, max: 9999 }) :
        outputCount2 ? ({ ...outputCount2, max: 1}) :
          outputCount3 ? ({ ...outputCount3, max: 9 }) : undefined,

    };
  }

  /* 商品記載情報の表示判定 */
  private getDescriptionInfoDisplay(order: PartialOrderSliceState, data: OrdersDataState | undefined = undefined): DescriptionInfoOrderDisplay {
    const description = this.visibleData?.op01_productItemInfo;
    return {
      name: this.checkContentVisible(order, data, 'op01p_name', description?.op01p_name),
      nameRestrict: this.checkContentVisible(order, data, 'op01p_name_restrict', description?.op01p_name_restrict),
      nameNenga: this.checkContentVisible(order, data, 'op01p_name_nenga', description?.op01p_name_nenga),
      date: this.checkContentVisible(order, data, 'op01p_date', description?.op01p_date),
      dateKana: this.checkContentVisible(order, data, 'op01p_date_kana', description?.op01p_date_kana),
      location: this.checkContentVisible(order, data, 'op01p_location', description?.op01p_location),
      locationHeight: this.checkContentVisible(order, data, 'op01p_location_height', description?.op01p_location_height),
      comment: this.checkContentVisible(order, data, 'op01p_location_comment', description?.op01p_location_comment),
      locationAddress: this.checkContentVisible(order, data, 'op01p_location_address', description?.op01p_location_address),
    };
  }

  /* 表紙の表示判定 */
  private getCoverDisplay(order: PartialOrderSliceState, data: OrdersDataState | undefined = undefined): CoverOrderDisplay {
    const cover = this.visibleData?.op01_cover;
    return {
      laminate: this.checkContentVisible(order, data, 'op01c_laminate', cover?.op01c_laminate)
        || this.checkContentVisible(order, data, 'op01c_laminate2', cover?.op01c_laminate2),
      color: this.checkContentVisible(order, data, 'op01c_coverColor', cover?.op01c_coverColor)
        || this.checkContentVisible(order, data, 'op01c_coverColor2', cover?.op01c_coverColor2),
      leafColor: this.checkContentVisible(order, data, 'op01c_leafColor', cover?.op01c_leafColor)
        || this.checkContentVisible(order, data, 'op01c_leafColor2', cover?.op01c_leafColor2)
        || this.checkContentVisible(order, data, 'op01c_leafColor3', cover?.op01c_leafColor3),
      leafText1: this.checkContentVisible(order, data, 'op01c_leafText1', cover?.op01c_leafText1),
      leafText2: this.checkContentVisible(order, data, 'op01c_leafText2', cover?.op01c_leafText2),
    };
  }

  /* 本身の表示判定 */
  private getGenuineDisplay(order: PartialOrderSliceState, data: OrdersDataState | undefined = undefined): GenuineOrderDisplay {
    const genuine = this.visibleData?.op01_body;
    return {
      laminate: this.checkContentVisible(order, data, 'op01b_laminate', genuine?.op01b_laminate),
      leafColor: this.checkContentVisible(order, data, 'op01b_leafColor', genuine?.op01b_leafColor) || this.checkContentVisible(order, data, 'op01b_leafColor2', genuine?.op01b_leafColor2) ,
      leafText1: this.checkContentVisible(order, data, 'op01b_leafText1', genuine?.op01b_leafText1),
      leafText2: this.checkContentVisible(order, data, 'op01b_leafText2', genuine?.op01b_leafText2),
      additional: this.checkContentVisible(order, data, 'op01b_additionalInfo_single', genuine?.op01b_additionalInfo_single),
      additional2: this.checkContentVisible(order, data, 'op01b_additionalInfo_single2', genuine?.op01b_additionalInfo_single2),
      additional3: this.checkContentVisible(order, data, 'op01b_additionalInfo_single3', genuine?.op01b_additionalInfo_single3),
      additionalMulti: this.checkContentVisible(order, data, 'op01b_additionalInfo_multi', genuine?.op01b_additionalInfo_multi),
      chronology: this.checkContentVisible(order, data, 'op01b_chronology', genuine?.op01b_chronology),
      page: this.checkContentVisible(order, data, 'op01b_pageCount1', genuine?.op01b_pageCount1)
        || this.checkContentVisible(order, data, 'op01b_pageCount2', genuine?.op01b_pageCount2),
    };
  }

  /* オプションの表示条件 */
  private getOptionOrderDisplay(order: PartialOrderSliceState, data: OrdersDataState | undefined = undefined, index: number): OptionOrderDisplay {
    const option = this.visibleData?.op01_option;
    const outputCount1 = this.checkContentVisible(order, data, 'op01o_outputCount', option?.op01o_outputCount, { index });
    const outputCount2 = this.checkContentVisible(order, data, 'op01o_outputCount2', option?.op01o_outputCount2, { index });
    const outputCount3 = this.checkContentVisible(order, data, 'op01o_outputCount3', option?.op01o_outputCount3, { index });
    return {
      itemTag: this.checkContentVisible(order, data, 'op01o_item_tag', option?.op01o_item_tag, { index }),
      item: this.checkContentVisible(order, data, 'op01o_item', option?.op01o_item, { index }),
      // quantity: this.checkContentVisible(order, data, 'op01o_outputCount', option?.op01o_outputCount, { index })
      //   || this.checkContentVisible(order, data, 'op01o_outputCount2', option?.op01o_outputCount2, { index })
      //   || this.checkContentVisible(order, data, 'op01o_outputCount3', option?.op01o_outputCount3, { index }),
      quantity: outputCount1 ? ({ ...outputCount1, max: 9999 }) :
        outputCount2 ? outputCount2 :
          outputCount3 ? ({ ...outputCount3, max: 1 }) : undefined,
      laminate: (option?.op01o_parts1?.visibleCondition?.map((v) => this.checkVisible(order, data, 'op01o_parts1', v))?.find((v) => v) || undefined) && this.checkContentVisible(order, data, 'op01op1_laminate3', option?.op01o_parts1?.op01op1_laminate3, { index }),
      // descriptionInfo: (option?.op01o_productItemInfo?.visibleCondition?.map((v) => this.checkVisible(order, data, 'op01o_productItemInfo', v))?.find((v) => v) || undefined) && this.checkContentVisible(order, data, 'op01op_targetReference', option?.op01o_productItemInfo?.op01op_targetReference, { index }),
      descriptionInfo: (option?.op01o_productItemInfo?.visibleCondition?.map((v) => this.checkVisible(order, data, 'op01o_productItemInfo', v))?.find((v) => v) || undefined) && option?.op01o_productItemInfo?.op01op_targetReference,
      description: Boolean(option?.op01o_description?.visibleCondition?.map((v) => this.checkVisible(order, data, 'op01o_description', v))?.find((v) => v)),
    };
  }

  /* API系の項目チェック */
  private checkExist(
    order: PartialOrderSliceState,
    data: OrdersDataState | undefined = undefined,
    contentId: string,
    visibleConditions: TVisibleContent,
    index?: number,
  ) {
    if (visibleConditions.targetID === 'PPM_masterProductItemInfo.xml') {
      const itemInfo = data?.descriptionInfo.descriptionInfo.find((v) => (visibleConditions.logic === 'type' && v.type === visibleConditions.value) || (visibleConditions.logic === 'option' && v.option === visibleConditions.value));
      if (!itemInfo || !visibleConditions.visibleCondition?.length) {
        return itemInfo
      }
      const checkItemInfoVisible = (item: DescriptionInfoGetResponse, visibleCondition: TVisibleContent[]): TVisibleContent | undefined => {
        return visibleCondition.find((v) => (v.logic === 'type' && v.value === item.type) || (v.logic === 'option' && v.value === item.option) || (v.visibleCondition?.length && checkItemInfoVisible(item, v.visibleCondition)));
      };
      return checkItemInfoVisible(itemInfo, visibleConditions.visibleCondition);
    }
    if (visibleConditions.targetID === 'PPM_masterProduct.xml') {
      /* 表紙は確定で返す */
      if (contentId === 'op01o_parts1') {
        // return Number(partsCount) >= 1;
        return true;
      }
      const serviceId = this.optionOrderDisplay[index || 0]?.item && (order.option?.[index || 0]?.item?.serviceId || order.itemInfo?.category?.value);
      const goodsId = order.option?.[index || 0]?.item?.value;
      const partsCount = data?.data.productDetail.itemCount;
      // const partsCount = this.masterData?.lnwMasterProduct?.data?.[0]?.goods?.[0]?.goodsData?.find((goods) => serviceId && goods?.$?.categoryID === serviceId && goods?.$?.goodsID === goodsId)?.parts?.[0]?.$?.count;
      if (partsCount && Number(partsCount)) {
        if (contentId === 'op01o_parts2') {
          return Number(partsCount) >= 2;
        }
        if (contentId === 'op01o_parts3') {
          return Number(partsCount) >= 3;
        }
      }
    }
    if (visibleConditions.targetID === '/api/v1/tags') {
      return data?.itemInfo.tag.productTag.length;
    }
    if (visibleConditions.targetID === '/api/v1/covers') {
      return data?.itemDetail.cover.coverType.length;
    }
    if (visibleConditions.targetID === '/api/v1/coverColors') {
      if (contentId === 'op01_coverColor_pre_select' || contentId === 'op01c_coverColorReferenceBoardInfo') {
        return data?.itemDetail.coverColor.coverColor.length;
      }
      if (contentId === 'op01c_coverColor' || contentId === 'op01c_coverColor2') {
        return data?.cover.color.coverColor.length;
      }
    }
    if (visibleConditions.targetID === '/api/v1/products') {
      return Boolean(order.itemInfo?.category?.value);
      // return data?.itemDetail.item.products.length;
    }
    if (visibleConditions.targetID === '/api/v1/boardInfos') {
      return data?.itemDetail.board.boardInfo.length;
    }
    if (visibleConditions.targetID === '/api/v1/orderMethods') {
      return data?.itemDetail.orderMethods.orderMethod.length;
    }
    if (visibleConditions.targetID === '/api/v1/pageDataTypes') {
      return data?.descriptionInfo.pageDataType.pageDataTypes.length;
    }
    if (visibleConditions.targetID === '/api/v1/laminates') {
      if (contentId === 'op01c_laminate' || contentId === 'op01c_laminate2') {
        return data?.cover.laminate.laminate.length;
      }
      if (contentId === 'op01b_laminate') {
        return data?.genuine.laminate.laminate.length;
      }
      if (contentId === 'op01op1_laminate3') {
        return true; // APIの取得条件が他データに依存するためデータ有無の判定はコンポネントで行う
        // return order?.option?.[index || 0]?.cover?.laminate?.value;
        // return data?.option?.optionLaminate?.[index || 0]?.laminate?.length;
      }
    }
    if (visibleConditions.targetID === '/api/v1/leafColors') {
      // --- 2023.08.28 kurokawa 台紙プリント箔押し対応 ---
      if (contentId === 'op01b_leafColor' || contentId === 'op01b_leafColor2') {
        return data?.genuine.foilStamping.leafColor.length;
      } else {
        return data?.cover.foilStamping.leafColor.length;
      }

    }
    if (visibleConditions.targetID === '/api/v1/chronologies') {
      return data?.genuine.chronologies.chronologies.length;
    }
    if (visibleConditions.targetID === '/api/v1/additionalInfos') {
      return data?.genuine.endpaper.additionalInfos.length;
    }
    if (visibleConditions.targetID === '/api/v1/optionProducts') {
      return data?.option.optionItem.optionProduct.length;
    }
    if (visibleConditions.targetID === '/api/v1/optionTags') {
      return data?.option.optionTag.optionProductTag.length;
    }
    if (visibleConditions.targetID === '') {
      return '';
    }
  }

  /* 各タグの値取得 */
  private getValue(order: PartialOrderSliceState, data: OrdersDataState | undefined = undefined, content: TVisibleContent, index?: number) {
    if (content.targetID === 'o_category') {
      return order.itemInfo?.category?.value;
    }
    if (content.targetID === 'o_isCategorySap') {
      // return order.orderInfo?.proFlg ? 'true' : '';
      return this.checkContentVisible(order, data, 'o_isCategorySap', this.visibleData?.orderInfo.o_isCategorySap, { hidden: true });
      // return this.checkVisible(order, data, 'o_isCategorySap', this.visibleData.);
    }
    if (content.targetID === 'o_isNotCategorySap') {
      return this.checkContentVisible(order, data, 'o_isNotCategorySap', this.visibleData?.orderInfo.o_isNotCategorySap, { hidden: true });
      // return order.orderInfo?.proFlg ? '' : 'true';
    }
    if (content.targetID === 'op01_cover_pre_select') {
      return this.itemDetailOrderDisplay.cover && order.itemDetail?.coverSelect?.value;
    }
    if (content.targetID === 'op01c_coverColorReferenceBoardInfo' || content.targetID === 'op01c_coverColorReference') {
      return this.itemDetailOrderDisplay.cover && order.itemDetail?.coverColorSelect?.value;
    }
    if (content.targetID === 'op01_item' || content.targetID === 'op01_goods_id') {
      return this.itemDetailOrderDisplay.item && data?.itemDetail.item.products.length && order.itemDetail?.itemSelect?.value;
    }
    if (content.targetID === 'op01_isNotOrderedModifySapFlowVersion') {
      // TODO
      return this.checkContentVisible(order, data, 'op01_isNotOrderedModifySapFlowVersion', this.visibleData?.o_productInfo_01.op01_isNotOrderedModifySapFlowVersion, { hidden: true });
    }
    if (content.targetID === 'op01_boardInfo_pre_select' || content.targetID === 'op01b_boardInfoReference') {
      return this.itemDetailOrderDisplay.board && order.itemDetail?.boardSelect?.value;
    }
    if (content.targetID === 'op01_isNotOrderedModifyBoardInfoFlowVersion') {
      // TODO
      return this.checkContentVisible(order, data, 'op01_isNotOrderedModifyBoardInfoFlowVersion', this.visibleData?.o_productInfo_01.op01_isNotOrderedModifyBoardInfoFlowVersion, { hidden: true });
    }
    if (content.targetID === 'op01_designType1') {
      return this.itemDetailOrderDisplay.designType1 && order.itemDetail?.designType1?.value;
    }
    if (content.targetID === 'op01_designType2') {
      return this.itemDetailOrderDisplay.designType2 && order.itemDetail?.designType2?.value;
    }
    if (content.targetID === 'o_surrogateProcess1') {
      return this.itemDetailOrderDisplay.orderMethod && order.itemDetail?.orderMethod?.value;
    }
    if (content.targetID === 'op01_isSelectedMustContent') {
      // TODO
      return this.checkContentVisible(order, data, 'op01_isSelectedMustContent', this.visibleData.o_productInfo_01.op01_isSelectedMustContent, { hidden: true });
    }
    if (content.targetID === 'op01_outputCount' || content.targetID === 'op01o_outputCount2') {
      return this.itemDetailOrderDisplay.quantity && order.itemDetail?.quantity;
    }
    if (content.targetID === 'op01p_name' || content.targetID === 'op01p_name_restrict' || content.targetID === 'op01p_name_nenga') {
      return order.descriptionInfo?.name;
    }
    if (content.targetID === 'op01p_date' || content.targetID === 'op01p_date_kana') {
      return order.descriptionInfo?.date?.value;
    }
    if (content.targetID === 'op01p_location' || content.targetID === 'op01p_location_height' || content.targetID === 'op01p_location_comment' || content.targetID === 'op01p_location_address') {
      return order.descriptionInfo?.photographLocation;
    }
    if (content.targetID === 'op01_cover_id') {
      // TODO
      // return data.data.productDetail.coverTypeId || (this.itemDetailOrderDisplay.cover && order?.itemDetail?.coverSelect?.value);
      return data?.data.productDetail.coverTypeId;
    }
    // if (content.targetID === 'op01c_coverColor_laminate_rel_info') {
    //   // TODO
    //   return this.checkContentVisible(order, data, 'op01c_coverColor_laminate_rel_info', this.visibleData.o_productInfo_01.op01c_coverColor_laminate_rel_info);
    // }
    // op01c_coverColor_laminate_rel_info 自動ラミ選択情報
    if (content.targetID === 'op01c_coverColor' || content.targetID === 'op01c_coverColor2' || content.targetID === 'op01c_coverColorReferenceBoardInfo2') {
      return this.coverOrderDisplay.color && order.cover?.color?.value;
    }
    // --- 2023.08.28 kurokawa 台紙プリント箔押し対応 ---
    if (content.targetID === 'op01c_leafColor' || content.targetID === 'op01c_leafColor2') {
      return this.coverOrderDisplay.leafColor && order.cover?.coverProcessing?.value;
    }
    if (content.targetID === 'op01c_leafText1') {
      return this.coverOrderDisplay.leafText1 && order.cover?.leafText1;
    }
    if (content.targetID === 'op01c_leafText2') {
      return this.coverOrderDisplay.leafText2 && order.cover?.leafText2;
    }
    if (content.targetID === 'op01b_leafColor' || content.targetID === 'op01b_leafColor2') {
      return this.genuineOrderDisplay.leafColor && order.genuine?.genuineProcessing?.value;
    }
    if (content.targetID === 'op01b_leafText1') {
      return this.genuineOrderDisplay.leafText1 && order.genuine?.leafText1;
    }
    if (content.targetID === 'op01b_leafText2') {
      return this.genuineOrderDisplay.leafText2 && order.genuine?.leafText2;
    }
    if (content.targetID === 'op01b_laminate') {
      return this.genuineOrderDisplay.laminate && order.genuine?.laminate?.value;
    }
    if (content.targetID === 'op01b_additionalInfo_single') {
      return this.genuineOrderDisplay.additional && order.genuine?.additional?.value;
    }
    if (content.targetID === 'op01b_additionalInfo_single2') {
      return this.genuineOrderDisplay.additional2 && order.genuine?.additional?.value;
    }
    if (content.targetID === 'op01b_additionalInfo_single3') {
      return this.genuineOrderDisplay.additional3 && order.genuine?.additional?.value;
    }
    if (content.targetID === 'op01b_additionalInfo_multi') {
      return this.genuineOrderDisplay.additionalMulti && order.genuine?.additionalMulti;
    }
    if (content.targetID === 'op01b_chronology') {
      return this.genuineOrderDisplay.chronology && order.genuine?.chronologies?.value;
    }
    if (content.targetID === 'op01b_pageCount1' || content.targetID === 'op01b_pageCount2') {
      return this.genuineOrderDisplay.page && order.genuine?.page;
    }
    if (content.targetID === 'op01o_item') {
      return this.optionOrderDisplay[index || 0]?.item && order.option?.[index || 0]?.item?.value;
    }
    if (content.targetID === 'op01o_cover_id') {
      return this.optionOrderDisplay[index || 0]?.item && data?.data.optionProductDetail[index || 0]?.coverTypeId;
    }
    if (content.targetID === 'op01o_outputCount' || content.targetID === 'op01o_outputCount3') {
      return this.optionOrderDisplay[index || 0]?.quantity && order.option?.[index || 0]?.quantity;
    }
    if (content.targetID === 'op01op1_laminate3') {
      return this.optionOrderDisplay[index || 0]?.laminate && order.option?.[index || 0]?.cover?.laminate?.value;
    }
    if (content.targetID === 'op01o_category') {
      return this.optionOrderDisplay[index || 0]?.item && (order.option?.[index || 0]?.item?.serviceId || order.itemInfo?.category?.value);
    }
    if (content.targetID === 'op01op_targetReference') {
      // TODO
      return this.optionOrderDisplay[index || 0]?.descriptionInfo;
    }
    if (content.targetID === 'op01_product_type') {
      return data?.data.productDetail.productTypeId;
    }
    if (content.targetID === 'o_shopCode') {
      // TODO
      return this.kijshopCd;
    }
    if (content.targetID === '') {
      return '';
    }
  }

  /* 表示判定 */
  checkContentVisible(order: PartialOrderSliceState, data: OrdersDataState | undefined = undefined, contentId: string, content: TContentData, options?: Partial<{ index: number, hidden: boolean }>): TContentData | undefined {
    if (!content) {
      return content;
    }
    if (!options?.hidden && (content.level === '' || content.modifiable === '')) {
      return undefined;
    }
    return !content.visibleCondition?.length || Boolean(content.visibleCondition.find((v) => this.checkVisible(order, data, contentId, v, options))) ? (content || {}) : undefined;
  }

  /* 表示判定処理 */
  private checkVisible(order: PartialOrderSliceState, data: OrdersDataState | undefined = undefined, contentId: string, visibleContent: TVisibleContent, options?: Partial<{ index: number, hidden: boolean }>): boolean {
    const flag = (() => {
      if (visibleContent.targetID.includes('.xml')) {
        return Boolean(this.checkExist(order, data, contentId, visibleContent, options?.index));
      }
      if (visibleContent.targetID.includes('/api/v1/')) {
        return !Boolean(this.checkExist(order, data, contentId, visibleContent, options?.index));
      }
      return (this.getValue(order, data, visibleContent, options?.index) || '') === visibleContent.value;
    })();
    return (visibleContent.logic === 'match' ? flag : visibleContent.logic === 'unmatch' ? !flag : flag) && (!visibleContent.visibleCondition?.length || Boolean(visibleContent.visibleCondition.map((v) => this.checkVisible(order, data, contentId, v, options)).find((v) => v)));
  }

}
