import React, { DetailedHTMLProps, InputHTMLAttributes, useCallback, useEffect, useState, useRef } from 'react';
import { DynamicTooltip } from '../tooltip/dynamic-tooltip';
import { UiSize } from '../ui.type';
import './input.scss';
import { DateFormatter } from '../../../utilities/date-formatter';

export type InputProps = {
  // - キーハンドラ -
  handlerEnter?: () => void,
  // - 整数か少数か -
  isInputInteger?: boolean,
  uiSize?: UiSize,
  options?: {
    isInteger?: boolean,
  },
  error?: boolean,
  tooltip?: { messages: string[], error?: boolean },
  hoverTooltip?: { messages: string[], error?: boolean },
  customDateMode?: boolean,
  onChangeTextArea?: (v: string) => void,
  isNumUpDown?: boolean,
  // - 文字数制限でのトリミング -
  trimLength?: number,
  // - カレンダーの選択日用 -
  date?: Date | null,
} & DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

// - 英数字正規表現 -
const azRegExp = /[0-9a-zA-Z]/;
// - 「.」正規表現 -
const dotRegExp = /\.+/g;
// - 「+-」正規表現 -
const pmRegExp = /[+-]/;
// - 浮動小数点正規表現 -
const floatRegExp = /^[+-]?[0-9a-zA-Z]+\.[0-9a-zA-Z]+([eE][+-]?[0-9a-zA-Z]+)?$/;
// - 全角正規表現 -
const fullWidthRegExp = /[^\x00-\x7E]+/g;
// - 記号正規表現 -
const signalRegExp = /[!-\-/:-@[-`{-~]+/g;

export const Input = React.forwardRef((props: InputProps, ref: React.LegacyRef<HTMLInputElement>) => {
  const {
    value,
    handlerEnter,
    uiSize,
    isInputInteger,
    type,
    options,
    error,
    tooltip,
    hoverTooltip,
    customDateMode,
    onChangeTextArea,
    isNumUpDown,
    trimLength,
    date,
    ...defaultProps
  } = props;
  // - State -
  const [inputText, setInputText] = useState(value as string);
  const [hover, setHover] = useState(false);
  // - Ref -
  const wrapEle = useRef<HTMLDivElement>(null);
  // - Callback -
  // -- キー --
  const handlerKeyPress = useCallback((e) => {
    if (defaultProps.onKeyPress) {
      defaultProps.onKeyPress(e);
    }
    if (handlerEnter && (e as KeyboardEvent)?.key === 'Enter') {
      handlerEnter();
    }
  }, [handlerEnter, value]);
  // - 入力値 -
  const handleChange = useCallback((e) => {
    if (isInputInteger && e.target.value.indexOf('.') !== -1 && !isNumUpDown) {
      return;
    }
    if (defaultProps.onChange) {
      defaultProps.onChange(e);
    }
    onChangeTextArea && onChangeTextArea(e.target.value);
    const str = e.target.value;
    if (!defaultProps.onChange && str.match(pmRegExp) && !isNumUpDown) {
      setInputText(str.replace(pmRegExp, ''));
    } else if (str.match(azRegExp)) {
      setInputText(str);
    } else {
      setInputText(str.replace('', ''));
    }
  }, [inputText, setInputText, onChangeTextArea]);
  const handleChangeDatePicker = useCallback((e) => {
    if (isInputInteger && e.target.value.indexOf('.') !== -1) {
      return;
    }
    if (defaultProps.onChange) {
      defaultProps.onChange(e);
    }
    const str = e.target.value;
    if (!defaultProps.onChange && str.match(pmRegExp)) {
      setInputText(str.replace(pmRegExp, ''));
    } else if (str.match(azRegExp)) {
      setInputText(str);
    } else {
      setInputText(str.replace('', ''));
    }
  }, [defaultProps?.onChange]);
  // - フォーカスが外れた -
  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    // -- 文字数制限でオーバーした文字をトリミング --
    if (trimLength) {
      e.target.value = e.target.value.substr(0, trimLength);
    }
    if (defaultProps.onBlur) {
      defaultProps.onBlur(e);
    } else if (defaultProps.onChange) {
      defaultProps.onChange(e);
    } else {
      if (isInputInteger && String(inputText).match(floatRegExp)) {
        setInputText(String(inputText).replace(dotRegExp, ''));
      } else if (String(inputText).match(fullWidthRegExp)) {
        setInputText(String(inputText).replace(fullWidthRegExp, ''));
      } else if (String(inputText).match(signalRegExp)) {
        setInputText(String(inputText).replace(signalRegExp, ''));
      }
    }
  };
  useEffect(() => {
    if (inputText !== String(value)) {
      setInputText(value as string);
    }
  }, [value]);
  const cal = useRef<HTMLInputElement>(null);

  return (
    <div
      className={`input ${uiSize}${error ? ' error' : ''}${defaultProps.disabled ? ' disabled' : ''}${customDateMode ? ' custom_date' : ''} ${defaultProps.className || ''}`}
      ref={wrapEle}
      onMouseOver={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {customDateMode ? <>
          <input
            {...defaultProps}
            type={type}
            onKeyPress={handlerKeyPress}
            onChange={handleChange}
            onBlur={handleBlur}
            value={type === 'number' ? (String(value) ? Number(value) : '') : value ? value : inputText}
            className="input__form"
            placeholder={defaultProps.placeholder}
            ref={ref}
          />
          <i className="fas fa-calendar">
            <input
              value={date ? DateFormatter.date2str(date, 'YYYYMMDD', '-') : undefined}
              type="date"
              ref={cal}
              onChange={handleChangeDatePicker}
            />
          </i>
        </>
        : <input
          {...defaultProps}
          type={type}
          onKeyPress={handlerKeyPress}
          onChange={(e) => {
            handleChange(e);
          }}
          onBlur={handleBlur}
          value={isNumUpDown ? value : (type === 'number' ? (String(value) ? Number(value) : '') : value ? value : inputText)}
          className="input__form"
          placeholder={defaultProps.placeholder}
          ref={ref}
        />
      }
      {tooltip ? (
        <DynamicTooltip
          messages={tooltip.messages}
          relativeEle={wrapEle.current}
          error={tooltip.error ?? true}
        />
      ) : (<></>)}
      {!tooltip && hoverTooltip && hover ? (
        <DynamicTooltip
          messages={hoverTooltip.messages}
          relativeEle={wrapEle.current}
          error={hoverTooltip.error ?? true}
        />
      ) : (<></>)}
    </div>
  );
});

Input.defaultProps = { uiSize: 'md' };

