import { Button } from '../../ui/button/button';
import { useDispatch } from 'react-redux';
import { dialogActions } from '../slice/dialog-slice';
import { useEffect, useState } from 'react';

type PdfPreviewProps = {
  file: File,
}
export const PdfPreview = (props: PdfPreviewProps) => {
  const { file } = props;
  const dispatch = useDispatch();
  const [url, setUrl] = useState('');
  useEffect(() => {
    setUrl(URL.createObjectURL(file));
  }, [file]);
  useEffect(() => {
    return () => {
      URL.revokeObjectURL(url);
    };
  }, []);
  return (
    <div className="dialog_contents ">
      <div className="dialog_contents__body ">
        <iframe
          width="420"
          height="630"
          src={url}
        />
      </div>
      <div className="dialog_contents__footer">
        <Button
          label={'閉じる'}
          onClick={() => dispatch(dialogActions.pop())}
        />
      </div>
    </div>
  );
};