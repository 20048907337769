import { XmlStructureModel } from '../xml/model/xml-structure-model';
import { store } from '../app/store';
import { apiActions } from '../slices/api-slice';
import { ApiUploadXml } from '../api/front/xml/api-upload-xml';
import { UiManager } from '../layout-editor/manager/ui/ui.manager';
import { dialogActions } from '../components/dialog/slice/dialog-slice';
import { layoutActions } from '../slices/layout-slice';
import { AlbumPage } from '../layout-editor/albam/albam';
import { ApiImagesGetOne } from '../api/front/images/api-images';

export class ThumbChecker {

  static check = async (xml: XmlStructureModel, kijshopCd: string, shopOrderId: string, orderId: string, isComp: boolean = false, albums: AlbumPage[] = [] ): Promise<void> => new Promise(async (resolve) => {
    const optionList = xml.orderInfo?.infoData?.filter((v) => v.xml.metaModel.parentId === orderId)
      .filter((v) => v.parts?.partsData?.filter((v2) => v2.page?.pageData?.filter?.((v3) => !v3.viewModel.parentID).length).length);
    const optionParts = (optionList?.map((v) => v.parts?.partsData ?? []) ?? []).flat();
    const orderInfo = xml.orderInfo?.infoData?.find((infoData) => infoData.xml.metaModel.id === orderId);
    if (!orderInfo) {
      resolve();
      return;
    }
    ;
    if (!isComp && orderInfo.xml.metaModel.layoutFinish !== '3') {
      resolve();
      return;
    }
    const orderParts = orderInfo.parts?.partsData?.concat(optionParts);
    const orderPages = orderParts?.map((elm) => elm.page?.pageData).flat();
    const readThumb = (imageData: string): Promise<boolean> => new Promise((resolve) => {
      const img = new Image();
      img.src = imageData
      img.onload = () => resolve(true);
      img.onerror = () => resolve(false);
    });
    if (orderPages) {
      const messages: string[] = []
      for (const album of albums) {
        const isCheck = await readThumb(album.thumbnail.image)
        if (!isCheck) {
          const errorThumbPage = orderPages.find(orderPage => orderPage?._indexes.toString() === album.indexes?.toString());
          if (errorThumbPage) {
            const isPage = store.getState().layout.pageTypeList.page.find(v => v.pageTypeID === errorThumbPage.viewModel.pageType);
            store.dispatch(layoutActions.setWarningList(`${errorThumbPage.viewModel.displayPageType} : ${errorThumbPage.viewModel.displayPageNo}`));
            let message: string;
            if (isPage) {
              message = `・ページ種: ${errorThumbPage.viewModel.displayPageType ?? '頁'} ページ: ${errorThumbPage.viewModel.displayPageNo ?? '-'}`;
            } else {
              message = `・ページ種: ${errorThumbPage.viewModel.displayPageType ?? 'その他'}`;
            }
            messages.push(message);
          }
        }
      }
      if (messages.length || isComp) {
        orderInfo.xml.metaModel.layoutFinish = '3';
        orderInfo.xml.build();
        const files: {
          filename: string,
          body: string,
          is_create: '0' | '1'
        }[] = [{
          filename: orderInfo.xml.xmlUniqueName,
          body: orderInfo.xml.xml,
          is_create: '0',
        }];
        store.dispatch(apiActions.run(new ApiUploadXml(kijshopCd, shopOrderId, { files }), {
          onSuccess: () => {
            if (isComp) {
              store.dispatch(layoutActions.setIsSelectIdError(true));
              resolve();
            } else {
              messages.unshift(``);
              messages.unshift(`下記のページを確認してください。`);
              messages.unshift(`サムネイルの生成に失敗したページがあります。`);
              store.dispatch(dialogActions.pushMessage({
                title: '確認',
                message: messages,
                buttons: [
                  {
                    label: 'OK',
                    callback: () => {
                      store.dispatch(dialogActions.pop());
                      resolve()
                    },
                  },
                ],
              }));
            }
          },
        }));
      } else {
        resolve();
      }
    } else {
      resolve();
    }
  })
}
