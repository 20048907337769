import { ApiBaseFront } from '../api-base-front';
import { ResponseBase } from '../../response-base';

export type MetaOrderPostRequest = {
  kijshopCd: string,
  shopOrderId: string,
  orderId: string,
  data: {
    orderId: string,
    note: string,
    checkChangeTemplateList: string,
    imageSortType: string,
    pictureData: string,
  },
};

export class ApiMetaOrderPost extends ApiBaseFront {
  constructor(_param: MetaOrderPostRequest) {
    const { kijshopCd, shopOrderId, orderId, ...param } = _param;
    super(
      'POST',
      `/api/v1/meta/order/${kijshopCd}/${shopOrderId}/${orderId}`,
      'JSON',
      param,
    );
  }
}

export type MetaOrderGetResponse = {
  orderId: string,
  note: string,
  checkChangeTemplateList: string,
  imageSortType: string,
  pictureData: string,
};

export class ApiMetaOrderGet extends ApiBaseFront<MetaOrderGetResponse> {
  constructor(param: { kijshopCd: string, shopOrderId: string, orderId: string }) {
    const { kijshopCd, shopOrderId, orderId } = param;
    super(
      'GET',
      `/api/v1/meta/order/${kijshopCd}/${shopOrderId}/${orderId}`,
      'JSON',
      {},
    );
  }
}

export class ApiMetaOrderDelete extends ApiBaseFront {
  constructor(param: { kijshopCd: string, shopOrderId: string, orderId: string }) {
    const { kijshopCd, shopOrderId, orderId } = param;
    super(
      'DELETE',
      `/api/v1/meta/order/${kijshopCd}/${shopOrderId}/${orderId}`,
      'JSON',
      {},
    );
  }
}

