import { Radiobutton } from '../../../ui/input/radiobutton';
import { useCallback, useState } from 'react';

type LabOrderInfoProps = {
  // - 校正承認有無 -
  isNeedAgreement: boolean,
  // - 校正承認の有無取得ハンドラ -
  getIsNeedAgreement: (state: boolean) => void,
};
export const LabOrderInfo = (props: LabOrderInfoProps) => {
  const {isNeedAgreement, getIsNeedAgreement} = props;
  // - Callback -
  // -- 校正認証有無 --
  const handlerChangeIsNeedAgreement = useCallback((v) => {
    getIsNeedAgreement(v === '0');
    console.log('aaaaaaaaaa');
  }, []);
  return (
    <div className="mt_2rem">
      <div className="bottom_border_header">
        ラボ発注情報
      </div>
      <div className="top_label_ui">
        <div className="top_label_ui__label">
          校正承認有無（初期値）
        </div>
        <div className="radio_buttons_box">
          <Radiobutton
            value="0"
            onChange={(e) => handlerChangeIsNeedAgreement(e.target.value)}
            checkState={(isNeedAgreement) ? '0' : '1'}
            label="有り"
          />
          <Radiobutton
            value="1"
            onChange={(e) => handlerChangeIsNeedAgreement(e.target.value)}
            checkState={(isNeedAgreement) ? '0' : '1'}
            label="無し"
          />
        </div>
      </div>
    </div>
  );
};
