import { Button } from '../button/button';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { PathParams, RoutingPath } from '../../../routes/routing-path';
import { ConfigHeaderItems } from './config-header-items';
import './header.scss';
import iconMail from '../../../assets/img/icon/mail.svg';
import iconMailBadge from '../../../assets/img/icon/mail_badge.svg';
import iconAdmin from '../../../assets/img/icon/admin.svg';
import iconHelp from '../../../assets/img/icon/help.svg';
import iconUser from '../../../assets/img/icon/user.svg';
import logo from '../../../assets/img/logo/ppmcloud_header_logo.png';
import cloudFolder from '../../../assets/img/icon/cloud_folder.svg';
import { newsListActions } from '../../../slices/news-list-slice';
import { useLocation } from 'react-router';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../../app/hooks';
import lodash from 'lodash';
import { NewPage } from '../../../utilities/new-page';
import { webPagePath } from '../../../models/web-page-path';
import { dialogActions } from '../../dialog/slice/dialog-slice';
import { UrgentNotice } from './urgent-notice';
import { orderPreparationActions } from '../../../slices/order-preparation-slice';
import { Env } from '../../../models/env';
import { apiActions } from '../../../slices/api-slice';
import { ApiMasterXml } from '../../../api/back/xml/api-master-xml';
import { ResponseBase } from '../../../api/response-base';
import { commonActions } from '../../../slices/common-slice';

export const Header = (props: { page?: 'news' | 'order' | 'staff' | '' }) => {
  const { page } = props;
  const { kijshopCd, shopOrderId, orderId } = useParams<PathParams>();
  const {
    isNewsList,
    isCreateOrderPage,
    emergencyAlert,
    updatingCustomerName,
    news,
    isAdmin,
  } = useAppSelector((state) => ({
    isNewsList: state.newsList.isClickNews,
    isCreateOrderPage: state.createOrder.pageStatus === 'in',
    // emergencyAlert: state.common.emergencyAlert,
    emergencyAlert: state.common.data.masterEmergencyAlert,
    updatingCustomerName: state.orderPreparation.updatingCustomerName,
    news: state.common.news,
    isAdmin: state.storage.data.isAdmin,
  }), lodash.isEqual);
  // - Hooks -
  const dispatch = useDispatch();
  // - Location -
  // -- pathname --
  const pathname = useLocation().pathname;
  // - State -
  // -- 設定ボタンリスト表示 --
  const [displayConfig, setDisplayConfig] = useState(false);
  const [displayConfig2, setDisplayConfig2] = useState(false);
  // -- 注文一覧かどうか --
  const [isOrdersPage, setIsOrdersPage] = useState(false);
  // -- コンフィグボタン表示制御 --
  const [isShowConfig, setIsShowConfig] = useState(false);
  // -- お知らせバッジ --
  const [badge, setBadge] = useState(false);
  // - Callback -
  // -- ホームボタン --
  const handlerClickHome = useCallback(() => {
    const routing = () => {
      if (isNewsList) {
        dispatch(newsListActions.close());
      }
      if (isOrdersPage) {
        dispatch(newsListActions.setIsSamePath(true));
      }
      if (page !== 'order') {
        console.log('updating: ', updatingCustomerName);
        if (updatingCustomerName) {
          dispatch(orderPreparationActions.setRegisteringCustomerName(true));
        } else {
          dispatch(push(RoutingPath.cnv.orders({ kijshopCd })));
        }
      } else {
        window.location.reload();
      }
    };
    if (isCreateOrderPage) {
      dispatch(dialogActions.pushMessage({
        title: '確認',
        message: ['保存していない内容は失われます。終了してもよろしいですか？'],
        buttons: [
          {
            label: 'いいえ',
            callback: () => {
              dispatch(dialogActions.pop());
            },
          },
          {
            label: 'はい',
            callback: () => {
              dispatch(dialogActions.pop());
              routing();
            },
          },
        ],
      }));
    } else {
      routing();
    }
  }, [isNewsList, isOrdersPage, isCreateOrderPage, updatingCustomerName]);
  // -- 注文管理アプリ説明シンク起動 --
  const handlerCLickAppInfoLink = useCallback(() => {
    window.open('https://cs.labonetwork.co.jp/ppmcloud/readme/about-order-data-management-application/');
  }, []);
  // -- お知らせボタン --
  const handlerClickNews = useCallback(() => {
    const routing = () => {
      if (isOrdersPage && isNewsList) {
        dispatch(newsListActions.setIsSamePath(true));
      }
      dispatch(newsListActions.open());
      dispatch(push(RoutingPath.cnv.orders({ kijshopCd })));
      setBadge(false);
    };
    if (isCreateOrderPage) {
      dispatch(dialogActions.pushMessage({
        title: '確認',
        message: ['保存していない内容は失われます。終了してもよろしいですか？'],
        buttons: [
          {
            label: 'いいえ',
            callback: () => {
              dispatch(dialogActions.pop());
            },
          },
          {
            label: 'はい',
            callback: () => {
              dispatch(dialogActions.pop());
              setBadge(false);
              routing();
            },
          },
        ],
      }));
    } else {
      setBadge(false);
      routing();
    }
  }, [isOrdersPage, isNewsList, isCreateOrderPage]);
  // -- 注文管理ボタン --
  const handlerClickAdmin = useCallback(() => {
    NewPage.tab(Env?.admin?.order);
  }, []);
  // -- 設定ボタン --
  const handlerClickConfig = useCallback(() => {
    setTimeout(() => {
      setDisplayConfig(true);
    }, 50);
  }, []);
  // -- ヘルプボタン --
  const handlerClickHelp = useCallback(() => {
    NewPage.tab(webPagePath.help);
  }, []);
  // -- システム管理 --
  const handlerClickSystem = useCallback(() => {
    setTimeout(() => {
      setDisplayConfig2(true);
    }, 50);
  }, []);
  // - Effect -
  // -- 設定ボタンリスト表示 --
  useEffect(() => {
    const handlerClickDisplay = () => {
      setDisplayConfig(false);
      setDisplayConfig2(false);
      document.removeEventListener('click', handlerClickDisplay);
    };
    if (displayConfig || displayConfig2) {
      document.addEventListener('click', handlerClickDisplay);
    } else if (!displayConfig && !displayConfig2) {
      document.removeEventListener('click', handlerClickDisplay);
    }
    return () => {
      document.removeEventListener('click', handlerClickDisplay);
    }
  }, [displayConfig, displayConfig2]);

  // -- パス判別 --
  useEffect(() => {
    const pathFactor = pathname.split('/');
    const pageName = pathFactor[pathFactor.length - 1];
    setIsOrdersPage(pageName === 'orders');
    setIsShowConfig(pageName === 'orders' || pageName === 'c-folder-order' || pageName === 'shop');
  }, []);

  useEffect(() => {
    if (news === '') {
      dispatch(apiActions.run(
        new ApiMasterXml('news'),
        {
          onSuccess: (res: ResponseBase<any>) => {
            dispatch(commonActions.setNews(res.body.data.slice(0, 50)));
          },
        },
      ));
    } else {
      const local = localStorage.getItem('news');
      if (page === 'news') {
        setBadge(false);
      } else {
        if (!local || news !== local) {
          setBadge(true);
          localStorage.removeItem('news');
        } else {
          setBadge(false);
        }
      }
    }
  }, [news, pathname, page]);

  return (
    <div className="header">
      <UrgentNotice
        // message={emergencyAlert.message}
        page={page || ''}
      />
      <div className="header__main">
        <div
          className="header__logo"
          onClick={handlerClickHome}
        >
          <img
            src={logo}
            alt=""
          />
        </div>
        <div className="header__menu">
          <Button
            label="注文データ管理アプリについて"
            onClick={handlerCLickAppInfoLink}
            color="light"
          />
          <div className={'news_btn'}>
            <Button
              label="お知らせ"
              onClick={handlerClickNews}
              color="light"
              icon={<img
                src={iconMail}
                alt=""
              />}
            />
            {
              // バッジ表示
              badge ?
                <img
                  src={iconMailBadge}
                  alt=""
                  className={'badge'}
                />
                : <></>
            }
          </div>
          {isOrdersPage ? (
            <Button
              label="注文管理"
              onClick={handlerClickAdmin}
              color="light"
              icon={<img
                src={iconAdmin}
                alt=""
              />}
            />
          ) : (<></>)}
          {
            isAdmin ?
              <>
                <Button
                  label="クラウドフォルダ"
                  onClick={handlerClickSystem}
                  color="light"
                  icon={<img
                    src={cloudFolder}
                    alt=""
                    className="cloud_folder_icon"
                  />}
                />
                {displayConfig2 ? (
                  <ConfigHeaderItems type={'system'} />
                ) : (<></>)}
              </> : <></>
          }
          <Button
            label="ヘルプ"
            onClick={handlerClickHelp}
            color="light"
            icon={<img
              src={iconHelp}
              alt=""
            />}
          />
          {isShowConfig ?
            <>
              <Button
                onClick={handlerClickConfig}
                color="light"
                icon={<img
                  src={iconUser}
                  alt=""
                />}
              />
              {displayConfig ? (
                <ConfigHeaderItems type={'normal'} />
              ) : (<></>)}
            </>
            : (<></>)}
        </div>
      </div>
    </div>
  );
};
