import {FiltersRequest, FiltersResponse} from '../../../../models/api/back/create-order/filters';
import {ResponseBase} from "../../../response-base";
import {AppThunk} from "../../../../app/store";
import {ordersDataActions} from "../../../../slices/orders/orders-data-slice";
import {apiActions} from "../../../../slices/api-slice";
import {ApiBaseBack} from "../../api-base-back";

export class ApiFilters extends ApiBaseBack<FiltersResponse> {
  constructor(param: FiltersRequest) {
    super(
      'GET',
      '/api/v1/filters',
      'JSON',
      param,
    );
  }
}

export const apiFiltersThunk = (param: FiltersRequest, callback?: () => void, isInit?: boolean): AppThunk => async (dispatch, getState) => {
  if (!param.productNameId) {
    dispatch(ordersDataActions.updateData({
      filter: { filters: [] }
    }));
    callback?.();
    return;
  }
  dispatch(apiActions.run(
    new ApiFilters(param),
    {
      onSuccess: (res: ResponseBase<FiltersResponse>) => {
        callback?.();
        if (!isInit && param.productNameId !== getState().order.itemDetail.itemSelect.value) {
          return;
        }
        dispatch(ordersDataActions.updateData({
          filter: { filters: res.body.data?.filters || [] },
        }));
      },
      onError: () => {
        callback?.();
      },
    },
    {
      apiName: 'filter',
      ignoreSystemError: true,
    },
  ));
};
