import { ApiBaseBack } from '../../api-base-back';
import {
  OptionProductGroupRulesRequest,
  OptionProductGroupRulesResponse,
} from '../../../../models/create-order/option-product-group-rules';
import { AppThunk } from '../../../../app/store';
import { ordersDataActions } from '../../../../slices/orders/orders-data-slice';
import { apiActions } from '../../../../slices/api-slice';
import { ResponseBase } from '../../../response-base';
import { ApiOptionItem } from '../option-item/api-option-item';
import { OptionItemResponse } from '../../../../models/api/back/create-order/option-item';

export class ApiOptionProductGroupRules extends ApiBaseBack<OptionProductGroupRulesResponse> {
  constructor(param: OptionProductGroupRulesRequest) {
    super(
      'GET',
      '/api/v1/optionProductGroupRules',
      'JSON',
      param,
    );
  }
}

export const apiOptionProductGroupRulesThunk = (param: OptionProductGroupRulesRequest, callback?: () => void): AppThunk => async (dispatch) => {
  if (!param.productNameId) {
    dispatch(ordersDataActions.updateOption({
      optionProductGroupRules: { optionGroupRule: [] },
    }));
    callback?.();
    return;
  }
  dispatch(apiActions.run(
    new ApiOptionProductGroupRules(param),
    {
      onSuccess: (res: ResponseBase<OptionProductGroupRulesResponse>) => {
        callback?.();
        dispatch(ordersDataActions.updateOption({
          optionProductGroupRules: { optionGroupRule: res.body.data?.optionGroupRule || [] },
        }));
      },
      onError: () => {
        callback?.();
      },
    },
  ));
  // dispatch(apiActions.run(
  //   new ApiOptionItem(param),
  //   {
  //     onSuccess: (res: ResponseBase<OptionItemResponse>) => {
  //       console.log(res.body.data?.optionProduct);
  //       dispatch(ordersDataActions.updateOption({
  //         optionProductList: { optionProduct: res.body.data?.optionProduct || [] },
  //       }));
  //     },
  //   },
  // ));
};
