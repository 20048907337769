import {ApiBaseBack} from "../api-base-back";

type FileType =
  'news'
  | 'master'
  | 'master-product'
  | 'master-shop'
  | 'masterEmergencyAlert'
  | 'master-product-no-judge-item'
  | 'master-product-item-info'
  | 'visibleCondition'
  | 'master-page-type';
type FileName =
  'PPM_masterAlert'
  | 'PPM_master'
  | 'PPM_masterProduct'
  | 'PPM_masterShop'
  | 'PPM_masterEmergencyAlert'
  | 'PPM_masterProductNoJudgeItem'
  | 'PPM_masterProductItemInfo'
  | 'PPM-CLOUD_masterProductSelectSql'
  | 'PPM_masterPageType'

const getFileName = (type: FileType): FileName => {
  switch (type) {
    case 'news':
      return 'PPM_masterAlert';
    case 'master':
      return 'PPM_master';
    case 'master-product':
      return 'PPM_masterProduct';
    case 'master-shop' :
      return 'PPM_masterShop';
    case 'masterEmergencyAlert':
      return 'PPM_masterEmergencyAlert';
    case 'master-product-no-judge-item':
      return 'PPM_masterProductNoJudgeItem';
    case 'master-product-item-info':
      return 'PPM_masterProductItemInfo';
    case 'visibleCondition':
      return 'PPM-CLOUD_masterProductSelectSql';
    case 'master-page-type' :
      return 'PPM_masterPageType'
  }
};

export class ApiMasterXml extends ApiBaseBack {
  constructor(fileName: FileType, param: {} = {}) {
    super(
      'GET',
      `/api/v1/xml/${getFileName(fileName)}`,
      'JSON',
      param,
    );
  }
}
