import { Input } from '../../ui/input/input';
import { Button } from '../../ui/button/button';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { dialogActions } from '../slice/dialog-slice';
import { layoutActions } from '../../../slices/layout-slice';
import { useAppSelector } from '../../../app/hooks';
import { localStorageActions } from '../../../slices/local-storage-slice';

type Props = {
  kijshopCd: string,
  windowSize: string,
  humanScale: string,
  topSpace: string,
  bottomSpace: string,
}

export const RegisterPreset = (props: Props) => {
  // - Hooks -
  const dispatch = useDispatch();
  // - Props -
  const { kijshopCd, windowSize, humanScale, topSpace, bottomSpace } = props;
  const { presetList } = useAppSelector((v) => ({
    presetList: v.storage.data.presetList,
  }));
  // - State -
  // -- 名称 --
  const [name, setName] = useState('');
  // - Callback -
  // -- 名称更新 --
  const handlerChangePreset = useCallback((v) => {
    setName(v);
  }, []);
  // -- OKボタン押下 --
  const handlerClickOk = useCallback(() => {
    const validName = Boolean(presetList.find(v => v.name === name));
    const validWord = () => {
      switch (true) {
        case (name.includes('<')):
          return true;
        case (name.includes('"')):
          return true;
        default:
          return false;
      }
    };
    if (validName) {
      dispatch((dialogActions.pushMessage({
        title: '確認',
        message: [
          `既に同じ名前が登録されています。`,
          `名前を変えてください。`,
        ],
        buttons: [
          {
            label: 'OK',
            callback: () => {
              dispatch(dialogActions.pop());
            },
          },
        ],
      })));
    } else if (validWord()) {
      dispatch((dialogActions.pushMessage({
        title: '確認',
        message: [
          `プリセット名に「＜」、「”」の文字は使用できません。`,
          `削除してもう一度登録してください。`,
        ],
        buttons: [
          {
            label: 'OK',
            callback: () => {
              dispatch(dialogActions.pop());
            },
          },
        ],
      })));
    } else {
      dispatch(localStorageActions.setData(kijshopCd, 'presetList', presetList.concat([{
        name,
        windowSize,
        humanScale,
        topSpace,
        bottomSpace,
      }])));
      dispatch(dialogActions.pop());
    }
  }, [name, windowSize, humanScale, topSpace, bottomSpace, presetList]);
  // -- キャンセルボタン押下 --
  const handlerClickCancel = useCallback(() => {
    dispatch(dialogActions.pop());
  }, []);
  return (
    <div className="dialog_contents">
      <div
        className="dialog_contents__body flex_direction_column"
        style={{ margin: '0 32px' }}
      >
        <div className="lh_2">
          名称
        </div>
        <Input
          value={name}
          onChange={(e) => handlerChangePreset(e.target.value)}
          style={{ width: '100%', maxWidth: 320 }}
        />
        <div className="lh_2 alert_message fs_12">
          ※プリセット名に「＜」と「”」の文字は使用できません。
        </div>
      </div>
      <div className="dialog_contents__footer">
        <Button
          label="キャンセル"
          onClick={handlerClickCancel}
        />
        <Button
          label="OK"
          onClick={handlerClickOk}
        />
      </div>
    </div>
  );
};
