import { ApiBaseFront } from '../api-base-front';
import { ResetStaffPasswordRequest, ResettingPasswordRequest } from '../../../models/api/front/staff-management';

export class ApiStaffPasswordReset extends ApiBaseFront {
  constructor(param: ResetStaffPasswordRequest) {
    super(
      'POST',
      '/api/v1/staff/mail/reset_password',
      'JSON',
      param,
    );
  };
}

export class ApiPasswordResetting extends ApiBaseFront {
  constructor(param: ResettingPasswordRequest) {
    super(
      'POST',
      '/api/v1/common/mail/chgpassword',
      'JSON',
      {
        userType: param.userType ? 1 : 0,
        token: param.token,
        password: param.password,
        password_confirmation: param.password_confirmation,
      }
    );
  };
}