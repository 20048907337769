import * as createjs from 'createjs-module';
import { TextFont, TextOption } from '../../../components/pages/layout/toolbar/layout.free-text';

export class CjText extends createjs.Text {
  originPos = {x: 0, y: 0}
  margin = 0;
  textId?: string;
  fontData?: TextFont;
  options?: TextOption;
  constructor() {
    super();
  }
}