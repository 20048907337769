import { useAppSelector } from '../../app/hooks';
import * as lodash from 'lodash';
import './dialog.scss';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { dialogActions } from './slice/dialog-slice';

export const Dialog = () => {
  const dispatch = useDispatch();
  // - Hooks -
  const { dialogs } = useAppSelector((state) => ({
    dialogs: state.dialog.dialogs,
  }), lodash.isEqual);

  /* state */
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const [onBlurPop, setOnBlurPop] = useState<boolean>(false);
  const [dimmerEleArr, setDimmerEleArr] = useState<HTMLDivElement[]>([]);
  /* ref */
  const dialogEle = useRef<HTMLDivElement>(null);
  const dimmerEle = useRef<HTMLDivElement>(null);

  /* effect */
  useEffect(() => {
    setIsOpened(dialogs.length > 0);
  }, [dialogs.length]);

  useEffect(() => {
    if (dialogs.length) {
      setOnBlurPop(dialogs[dialogs.length - 1].onBlurPop || false);
    }
    const arr = [...dimmerEleArr];
    if (dimmerEle.current) {
      arr[dialogs.length - 1] = dimmerEle.current;
    }
    const dimmerArr: HTMLDivElement[] = [];
    dialogs.forEach((_, i) => {
      dimmerArr[i] = arr[i];
    });
    setDimmerEleArr(dimmerArr);
  }, [dialogs.length]);

  return (
    <>
      {dialogs.map((v, i) => (
        <div
          className={`dialog ${(i === dialogs.length - 1 && isOpened) ? 'dimmer' : ''} ${isOpened ? 'backDialog' : ''}`}
          key={`dialog-element_${i}`}
          onClick={(e) => {
            e.stopPropagation();
            // if (onBlurPop && (e.target === dimmerEle.current)) {
            if (onBlurPop && (e.target === dimmerEleArr[i])) {
              dispatch(dialogActions.pop());
              if (v.onWillClose) {
                v.onWillClose();
              }
            }
          }}
          // ref={isOpened ? dimmerEle : null}
          ref={dimmerEle}
        >
          <div
            className={`dialog__body ${v.className || ''}`}
            ref={dialogEle}
          >
            <div className="dialog__body__header">
              {v.title}
              {v.closeBtn ? <div
                className="close_icon"
                onClick={() => {
                  if (v.onWillClose) {
                    v.onWillClose();
                  }
                  dispatch(dialogActions.pop());
                }}
              /> : (<></>)}
            </div>
            <div className="dialog__body__contents">
              {v.element}
            </div>
          </div>
        </div>
      ))}
    </>
  );
};
