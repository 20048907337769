import * as xml2js from 'xml2js';

export class XmlParser {
  parse<T>(source: string): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      xml2js.parseString(source, ((err, result) => {
        if (err) {
          reject(err);
        }
        resolve(result);
      }));
    });
  }

  build(source: any) {
    const builder = new xml2js.Builder({
      headless: true,
    });
    const xml = builder.buildObject(source);
    return xml;
  }
}
