import {
  WizardButton,
  WizardButtonList,
  WizardContent,
  WizardInput,
  WizardInputList,
} from '../../../ui/wizard/wizard-content';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { RoutingPath } from '../../../../routes/routing-path';
import { apiActions } from '../../../../slices/api-slice';
import { ApiCheckTokenExpiry } from '../../../../api/front/wizard/api-check-token-expiry';
import { dialogActions } from '../../../dialog/slice/dialog-slice';
import { ApiPasswordResetting } from '../../../../api/front/wizard/api-staff-password-reset';

type Props = {
  setStep: (step: number) => void;
  token: string;
}
type TooltipType = 'password' | 'passwordConfirm' | 'none';
export const Step3StaffPasswordResetWizard = (props: Props) => {
  const { setStep, token } = props;
  // - Hooks -
  const dispatch = useDispatch();
  // - State -
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [isPasswordError, setIsPasswordError] = useState(false);
  const [isPasswordConfirmError, setIsPasswordConfirmError] = useState(false);
  const [showTooltip, setShowTooltip] = useState<TooltipType>('none');
  // - Handlers -
  const handlerChangePassword = useCallback((value: string) => {
    setPassword(value);
  }, []);
  const handlerChangePasswordConfirm = useCallback((value: string) => {
    setPasswordConfirm(value);
  }, []);
  const handlerToggleShowTooltip = useCallback((type: TooltipType) => {
    setShowTooltip(type);
  }, []);
  // - Effects -
  useEffect(() => {
    if(token === ''){
      dispatch(push(RoutingPath.staffLogin));
    }
    dispatch(apiActions.run(
      new ApiCheckTokenExpiry({ token }),
      {
        onSuccess: (res) => {
          if (res.error.errorCode === 404) {
            dispatch(dialogActions.pushMessage({
              title: 'エラー',
              message: ['トークンの有効期限が切れています。', 'ログイン画面に戻ります'],
              buttons: [
                {
                  label: 'OK',
                  callback: () => {
                    dispatch(dialogActions.pop());
                    dispatch(push(RoutingPath.staffLogin));
                  },
                },
              ],
            }));
          }
        },
      }
    ))
  }, []);
  // - Handlers -
  // -- バリデーション --
  const handlerValidation = useCallback(() => {
    // 大小英文字かつ数字または記号含む8文字以上15文字以内
    const regex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9!@#$%^&*])[A-Za-z0-9!@#$%^&*]{8,15}$/;
    const isPasswordError = password === '' || !regex.test(password);
    const isPasswordConfirmError = passwordConfirm === '' || password !== passwordConfirm || !regex.test(passwordConfirm);
    setIsPasswordError(isPasswordError);
    setIsPasswordConfirmError(isPasswordConfirmError);
    return !isPasswordError && !isPasswordConfirmError;
  },[password, passwordConfirm]);
  const handlerClickChange = useCallback(() => {
    if (!handlerValidation()){
      dispatch(dialogActions.pushMessage({
        title: '確認',
        message: ['入力内容に不備があります。'],
        buttons: [
          {
            label: 'OK',
            callback: () => {
              dispatch(dialogActions.pop());
            },
          },
        ],
      }));
      return;
    }
    dispatch(apiActions.run(
      new ApiPasswordResetting({
        userType: true,
        token,
        password,
        password_confirmation: passwordConfirm,
      }),
      {
        onSuccess: () => {
          setStep(4);
        },
        onBasicError: (error) => {
          dispatch(dialogActions.pushMessage({
            title: '確認',
            message: error.messages,
            buttons: [
              {
                label: 'OK',
                callback: () => {
                  dispatch(dialogActions.pop());
                },
              },
            ],
          }));
        },
      },
    ));
  }, [password, passwordConfirm]);
  return (
    <WizardContent>
      <WizardInputList>
        <WizardInput
          label="新しいパスワード（8文字以上15文字以内）"
          value={password}
          disabled={false}
          isPassword={true}
          maxLength={15}
          error={isPasswordError}
          descBottom={'※英大文字と英小文字かつ数字または記号含む8文字以上15文字以内'}
          tooltip={isPasswordError && showTooltip === 'password' ?
            { messages: ['大小英文字かつ数字または記号含む8文字以上15文字以内'] } : undefined}
          onChange={(e) => handlerChangePassword(e.target.value)}
          onMouseOver={() => handlerToggleShowTooltip('password')}
          onMouseLeave={() => handlerToggleShowTooltip('none')}
        />
        <WizardInput
          label="新しいパスワード（再入力）"
          value={passwordConfirm}
          disabled={false}
          isPassword={true}
          maxLength={15}
          error={isPasswordConfirmError}
          tooltip={isPasswordConfirmError && showTooltip === 'passwordConfirm' ?
            { messages: ['パスワードが一致しません'] } : undefined}
          onChange={(e) => handlerChangePasswordConfirm(e.target.value)}
          onMouseOver={() => handlerToggleShowTooltip('passwordConfirm')}
          onMouseLeave={() => handlerToggleShowTooltip('none')}
        />
      </WizardInputList>
      <WizardButtonList>
        <WizardButton
          label="変更"
          onClick={handlerClickChange}
        />
      </WizardButtonList>

    </WizardContent>
  );
};