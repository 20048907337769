import { ILayoutEditorManagerBase } from '../layout-editor.manager';
import EventEmitter from 'eventemitter3';
import { UploadRequest } from './upload-request';
import { ConnectCloudFolderRequest } from './connect-cloud-folder-request';

type EventType = {
  'change-queue': (e: {}) => void,
};

export class UploadManager extends EventEmitter<EventType> implements ILayoutEditorManagerBase {

  // 30件アップロードの時間
  private static MAX_QUEUE_THREAD = 1; // サーバー側でエラーが発生するためフロント側で同時のアップロードは回避
  // private static MAX_QUEUE_THREAD = 3; // 42s
  // private static MAX_QUEUE_THREAD = 5; // 15s
  // private static MAX_QUEUE_THREAD = 10; // 15s
  // private static MAX_QUEUE_THREAD = 15; // 15s
  // private static MAX_QUEUE_THREAD = 100; // 15s

  private static _ins: UploadManager;
  private initialized = false;
  private requestCounter = 0;
  private requestFinishCounter = 0;
  private requestQueue: (UploadRequest | ConnectCloudFolderRequest)[] = [];
  private busyCounter = 0;

  private constructor() {
    super();
  }

  static get ins() {
    if (UploadManager._ins) {
      return UploadManager._ins;
    }
    UploadManager._ins = new UploadManager();
    return UploadManager._ins;
  }

  di(): void {
  }

  initialize() {
    if (this.initialized) {
      // console.error('すでに初期化済みです !!');
      return;
    }
    this.requestCounter = 0;
    this.requestFinishCounter = 0;
    this.requestQueue = [];
    this.busyCounter = 0;
    this.addEvent();
  }

  destroy(): void {
  }

  enqueue(...requests: (UploadRequest | ConnectCloudFolderRequest)[]) {
    this.requestCounter += requests.length;
    this.requestQueue.push(...requests);
    this.emit('change-queue', {});
  }

  dequeue(request: UploadRequest | ConnectCloudFolderRequest) {
    const index = this.requestQueue.findIndex((v) => v === request);
    if (index !== -1) {
      this.requestQueue.splice(index, 1);
      this.emit('change-queue', {});
    }
  }

  private async do(request: UploadRequest | ConnectCloudFolderRequest) {
    await request.upload();
    return request;
  }

  private onChangeQueue() {
    const targetRequestIndex = this.requestQueue.findIndex((v) => !v.busy);
    const que = this.requestQueue;
    const busy = this.busyCounter;
    if (!this.requestQueue.length || targetRequestIndex === -1) {
      console.log('実行する UploadRequest がありません。: ', this.requestQueue);
      this.requestCounter = 0;
      this.requestFinishCounter = 0;
      return;
    }
    // エラーが発生するため同時に走らせないように変更
    if (this.busyCounter >= UploadManager.MAX_QUEUE_THREAD) {
      return;
    }
    this.busyCounter += 1;
    const startTime = window.performance.now();
    const targetRequest = this.requestQueue[targetRequestIndex];
    console.group(`[upload] (${this.requestQueue.length}) request start ...`);
    this.do(targetRequest)
      .then((request) => {
        console.log('que success: ', busy - 1);
        this.requestFinishCounter += 1;
        this.busyCounter -= 1;
        const totalTime = window.performance.now() - startTime;
        console.log(`[upload] finish (${totalTime} ms)`);
        console.groupEnd();
        this.dequeue(request);
      });
  }

  private addEvent() {
    this.on('change-queue', () => {
      this.onChangeQueue();
    });
  }

}

UploadManager.ins.initialize();
