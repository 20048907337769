export const toHumanReadableByte = (byte: number) => {
  const oneKB = 1024;
  if (byte < oneKB) {
    return `${byte} B`;
  }
  if (byte < oneKB * oneKB) {
    return `${(byte / oneKB).toFixed(2)} KB`;
  }
  if (byte < oneKB * oneKB * oneKB) {
    return `${(byte / oneKB / oneKB).toFixed(2)} MB`;
  }
  return `${(byte / oneKB / oneKB / oneKB).toFixed(2)} GB`;
};

export const toHumanReadableSecond = (second: number) => {
  return toHumanReadableMilliSecond(second * 1000);
};

export const toHumanReadableMilliSecond = (ms: number) => {
  if (!ms || ms === Infinity) {
    return '---';
  }
  const y = Math.floor(ms / (1000 * 60 * 60 * 24 * 365));
  const d = Math.floor(ms / (1000 * 60 * 60 * 24) - y * 365);
  const h = Math.floor(ms / (1000 * 60 * 60) - y * 365 * 24 - d * 24);
  const m = Math.floor(ms / (1000 * 60) - y * 365 * 24 * 60 - d * 24 * 60 - h * 60);
  const s = Math.floor(ms / (1000) - y * 365 * 24 * 60 * 60 - d * 24 * 60 * 60 - h * 60 * 60 - m * 60);
  const arr = [y, d, h, m, s];
  const unit = ['y', 'd', 'h', 'm', 's'];
  const findIndex = arr.findIndex((v) => v > 0);
  return arr
    .slice(findIndex)
    .map((v, i) => `${v}${unit[findIndex + i]}`)
    .filter((v, i) => i < 3)
    .join(' ');
};
