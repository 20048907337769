import { OrdersSliceState } from '../../../slices/orders-slice';
import { OrdersDataState } from '../../../slices/orders/orders-data-slice';
import { ProductDetailPageData } from '../../../models/api/back/create-order/product-detail';
import { IMasterProduct } from '../../../models/i-master-product';
import { store } from '../../../app/store';
import { PageDataTypesResponseEntity } from '../../../models/api/back/create-order/page-data-types';

export type TOrderPageDataViewModel = {
  displayPageNo: string,
  displayPageType: string,
  id: string,
  pageCount: string,
  pageIndex: string,
  pageNo: string,
  pageType: string,
  parentID: string,
  template: Partial<{
    adPath: string,
    name: string,
    path: string,
    shapeFlag: string,
    scaleEditorFlag?: string,
    real: Partial<{
      height: string,
      width: string,
    }>,
    virtual: Partial<{
      height: string,
      width: string,
    }>,
  }>,
  compositeFileName: {
    realPath?: string,
    virtualPath?: string,
    displayPath?: string,
    sheetPath?: string,
  },
  originalCompositeFileName: {
    realPath?: string,
  }
  pageOutputCount: string,
  pageNoOverlap: Partial<{
    left: string,
    right: string,
  }>,
  orderFrame: Partial<{
    id: string,
    copyFlag: string,
    deleteFlag: string,
    real: Partial<{
      x: string,
      y: string,
    }>,
    virtual: Partial<{
      x: string,
      y: string,
    }>,
    frameRotate: string,
    zoom: string,
  }>[],
  orderPicture: {
    photoSeqCount?: string,
    data?: Partial<{
      id: string,
      contactNaiFlag?: string,
      cantactNaiFlag?: string,
      copyFlag: string,
      deleteFlag: string,
      depth: string,
      frame: string,
      logoFlag: string,
      mask: string,
      maskname: string,
      orgsubMaskID: string,
      photoSeqNo: string,
      selectID?: string | null,
      subMaskID: string,
      compositeFlag: string[],
      selectImage?: {
        height?: string,
        width?: string,
      },
      logoFileName?: {
        realPath?: string,
      },
      subMaskRect: {
        real?: {
          height?: string,
          width?: string,
          x?: string,
          y?: string,
        },
        virtual?: {
          height?: string,
          width?: string,
          x?: string,
          y?: string,
        },
      },
      subMaskRotate: string,
      pictureRect: {
        real?: {
          height?: string,
          width?: string,
          x?: string,
          y?: string,
        },
        virtual?: {
          height?: string,
          width?: string,
          x?: string,
          y?: string,
        },
      },
      pictureRotate: string,
      zoom: string[],
    }>[],
  },
  orderText: Partial<{
    data: Partial<{
      text: Partial<{
        text: string,
        fontName: string,
        fontColor: string,
        borderColor: string,
        transparence: string,
        bold: string,
        italic: string,
        underLine: string,
        strikeOut: string,
        lineHeight: string,
        contactType: string,
        contactPageNoPos: string,
        horizontalAlignment: string,
        textSize: string,
        borderSize: string,
        dropShadow: Partial<{
          enable: string,
          transparence: string,
          virtualSize: string,
        }>,
        part: Partial<{
          text_alpha: string,
          text_bold: string,
          text_endPos: string,
          text_fontColor: string,
          text_fontName: string,
          text_italic: string,
          text_size: string,
          text_startPos: string,
        }>
      }>,
      textRect: Partial<{
        real: Partial<{
          height: string,
          width: string,
          x: string,
          y: string,
        }>,
        virtual: Partial<{
          height: string,
          width: string,
          x: string,
          y: string,
        }>,
      }>,
      textRotate: string,
    }>[]
  }>,
  freeGraphic?: Partial<{
    data: Partial<{
      real: Partial<{
        path: string,
        height: string,
        width: string,
        x: string,
        y: string,
      }>,
      virtual: Partial<{
        path: string,
        height: string,
        width: string,
        x: string,
        y: string,
      }>,
      rotation: string
    }>[]
  }>,
  orderTextImage: Partial<{
    data: Partial<{
      path: string,
      height: string,
      width: string,
      x: string,
      y: string,
    }>[],
  }>,

  additionalFrame: Partial<{
    dpi: string,
    enable: string,
    frameID: string,
    name: string,
    rotate: string,
    type: string,
    color: Partial<{
      r: string,
      g: string,
      b: string,
    }>,
    real: Partial<{
      height: string,
      width: string,
      x: string,
      y: string,
    }>,
    virtual: Partial<{
      height: string,
      width: string,
      x: string,
      y: string
    }>,
    rotatable: Partial<{
      r90: string,
      r180: string,
      r270: string,
    }>,
    windows: Partial<{
      sizeID: string,
      sizeName: string,
      circle: string,
      default: string,
      rect: string,
      rotation: string,
      real: Partial<{
        height: string,
        width: string,
        x: string,
        y: string,
      }>,
      virtual: Partial<{
        height: string,
        width: string,
        x: string,
        y: string,
      }>,
    }>[],
  }>,
  additionalHole: Partial<{
    windows: Partial<{
      nakamadoHeight: string,
      jinsunHeight: string,
      upperSpace: string,
      bottomSpace: string,
    }>[]
  }>,
  materials: {
    id?: string,
    name?: string,
    material: {
      materialID?: string,
      materialName?: string,
      shape?: string
    }[]
  }
};

const checkPageType = (v: string): 'page' | 'end' | 'other' => {
  switch (v) {
    case 'jptg380013': // 頁-TOP
    case 'jptg380015': // 頁-END
      return 'end';
    case 'jptg380014': // 頁
    case 'jptg380054': // プリント
      return 'page';
    default:
      return 'other';
  }
};

export const createOrderPageData = (partsData: ProductDetailPageData[], page: number, option: boolean, inheritParent: string | undefined): Partial<TOrderPageDataViewModel>[] => {
  // const pageType = masterProduct?.lnwMasterProduct.data?.[0].pageType?.[0].pageTypeData;
  let fixPage = 0;
  let pageNo = 1;
  partsData.forEach((v) => {
    if (v.pageChange !== 'true') {
      fixPage += Number(v.pageCount);
    }
  });
  const arr: Partial<TOrderPageDataViewModel>[] = [];
  partsData.forEach((v) => {
    const displayPageType = partsData.find((p) => p.pageType === v.pageType)?.displayPageType || '';
    const createData = () => {
      const pageType = checkPageType(v.pageType);
      arr.push({
        id: `${arr.length + 1}`,
        pageNo: `${pageNo}`,
        pageIndex: v.pageIndex,
        pageCount: v.pageCount,
        pageType: v.pageType,
        displayPageType,
        // displayPageNo: option ? '***' : pageType === 'page' ? (Number(v.pageCount) !== 1 ? `${pageNo}-${(pageNo - 1) + Number(v.pageCount)}` : `${pageNo}`) : pageType === 'end' ? `${pageNo}` : '***',
        displayPageNo: option ? '***' : (pageType === 'page' || pageType === 'end') ? (Number(v.pageCount) !== 1 ? `${pageNo}-${(pageNo - 1) + Number(v.pageCount)}` : `${pageNo}`) : '***',
        parentID: (option && inheritParent !== '' && inheritParent !== 'false') ? `${arr.length + 1}` : undefined,
        pageOutputCount: '1',
      });
      pageNo += Number(v.pageCount);
    };
    if (page > arr.length) {
      if (v.pageChange === 'true' && page > partsData.length ) {
        for (let i = 0; i < page - fixPage; i += Number(v.pageCount)) {
          createData();
        }
      } else {
        createData();
      }
    }
  });
  return arr;
}
