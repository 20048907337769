import {
  WizardBody,
  WizardBodyList,
  WizardButton,
  WizardButtonList,
  WizardContent, WizardMailSentForPassword,
  WizardNotice,
} from '../../../ui/wizard/wizard-content';
import { WizardMailNotice } from '../../../ui/wizard/wizard-content';
import { push } from 'connected-react-router';
import { RoutingPath } from '../../../../routes/routing-path';
import { useDispatch } from 'react-redux';

type Props = {
  setStep: (step: number) => void;
}
export const Step2AdminPasswordResetWizard = (props: Props) => {
  const { setStep } = props;
  // - Hooks -
  const dispatch = useDispatch();
  return (
    <WizardContent>
      <WizardBodyList>
        <WizardBody body={['スタッフ管理メールアドレスに確認メールを送信しました。']} />
        <WizardBody body={WizardMailSentForPassword} />
      </WizardBodyList>
      <WizardNotice body={WizardMailNotice} />
      <WizardButtonList>
        <WizardButton
          label="戻る"
          // onClick={() => setStep(1)}
          onClick={() => dispatch(push(RoutingPath.login))}

        />
      </WizardButtonList>
    </WizardContent>
  );
};