import { TextArea } from '../../ui/textarea/textarea';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../app/hooks';
import * as lodash from 'lodash';
import { ordersActions, OrdersSliceState } from '../../../slices/orders-slice';
import { Button } from '../../ui/button/button';
import iconHelp from '../../../assets/img/icon/help_icon.svg';
import { HowToOrder, ImgType } from '../../dialog/unique/how-to-order';
import { dialogActions } from '../../dialog/slice/dialog-slice';

const textCheck = (item: string) => {
  switch (item) {
    case 'jptg301470':
    case 'jptg301471':
    case 'jptg301472':
    case 'jptg301473':
    case 'jptg301474':
    case 'jptg301475':
      return true;
    default:
      return false;
  }
};

const displayItems = (order: OrdersSliceState) => ({
  text: Boolean(textCheck(order.itemDetail.itemSelect.value)),
});

export const RemarksOrder = () => {
  const dispatch = useDispatch();
  const { remarks: _remarks, order } = useAppSelector((state) => ({
    ...state.order.remarks,
    order: state.order,
  }), lodash.isEqual);
  // - State -
  // -- 備考 --
  const [remarks, setRemarks] = useState(_remarks);
  // - Callback -
  // -- 備考 --
  const handlerChangeRemarks = useCallback((v) => {
    setRemarks(v);
  }, []);
  const handlerBlurRemarks = useCallback((v) => {
    const data = delete4byte(v);
    setRemarks(data);
    dispatch(ordersActions.setRemarks(data));
  }, []);

  const handlerClickHelp = useCallback((type: ImgType) => {
    dispatch(dialogActions.push({
      title: '説明',
      element: <HowToOrder imgType={type} />,
      closeBtn: true,
    }));
  }, []);

  const delete4byte = useCallback((v: string): string => {
    const stringToArr = (str: string) => str.match(/[\uD800-\uDBFF][\uDC00-\uDFFF]|[^\uD800-\uDFFF]/g) || [];
    const base = stringToArr(v);
    let txt: string = '';
    base.forEach((vv) => {
      const char = vv.charCodeAt(1);
      if (!char) {
        txt += vv;
      }
    });
    return txt;
  }, []);

  const check4byte = useCallback((e: any, v: string) => {
    if (e.code === 'Enter') {
      const data = delete4byte(v);
      setRemarks(data);
    }
  }, []);

  // - Effect -
  // -- 更新確認 --
  useEffect(() => {
    if (remarks !== _remarks) {
      setRemarks(_remarks);
    }
  }, [_remarks]);
  const display = displayItems(order);
  return (
    <div className="order_category remarks_order">
      <div className="bottom_border_header order_category__header">
        備考
        <Button
          className="order_help order_category__header__tooltip"
          icon={<img
            src={iconHelp}
            alt=""
          />}
          onClick={() => handlerClickHelp('title-remark')}
        />

      </div>

      <div className="remarks_order__caution">
        <div>・各ページのデザイン指示内容を記入してください。</div>
        <div>・表紙カットの指定、トリミング指示などがある場合、ファイル名にてご指示ください。</div>
        <div>例）DSC_001.jpgを表紙に使用</div>
        <div>・納期指定、急ぎ要望などデザイン以外のご要望は、本項目ではお受けできません。</div>
      </div>

      {display.text ? (
        <div className="remarks_order__caution">
          <div
            style={{ color: 'red' }}
          >
            ・身長メーターがあるデザインに関しましては備考欄に身長を入力ください。余白の多い写真を入稿ください。
          </div>
        </div>
      ) : (<></>)}

      <div className="top_label_ui order_category__item order_category__item">
        <div className="top_label_ui__label">
          備考（500文字以内）
        </div>
        <TextArea
          value={remarks}
          onChange={(e) => handlerChangeRemarks(e.target.value)}
          onKeyUp={(e) => check4byte(e, remarks)}
          onBlur={(e) => handlerBlurRemarks(e.target.value)}
          maxLength={500}
        />
      </div>
    </div>
  );
};
