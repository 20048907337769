
import { OptionPartsRequest, OptionPartsResponse } from '../../../../models/api/back/create-order/option-parts';
import {AppThunk} from "../../../../app/store";
import {ordersDataActions} from "../../../../slices/orders/orders-data-slice";
import {apiActions} from "../../../../slices/api-slice";
import {ResponseBase} from "../../../response-base";
import {ApiBaseBack} from "../../api-base-back";
import { OptionTagRequest, OptionTagResponse } from '../../../../models/api/back/create-order/option-tag';

export class ApiOptionTag extends ApiBaseBack<OptionTagResponse> {
  constructor(param: OptionTagRequest) {
    super(
      'GET',
      '/api/v1/optionTags',
      'JSON',
      param,
    );
  }
}

export const apiOptionTagThunk = (param: OptionTagRequest, callback?: () => void, isInit?: boolean): AppThunk => async (dispatch, getState) => {
  if (!param.productNameId || !param.orderMethodId) {
    dispatch(ordersDataActions.updateOption({
      optionTag: { optionProductTag: [] },
    }));
    callback?.();
    return;
  }
  dispatch(apiActions.run(
    new ApiOptionTag({ ...param, agentMode:(getState().systemConfig.metaData.surrogateOrder) ? 'true' : undefined }),
    {
      onSuccess: (res: ResponseBase<OptionTagResponse>) => {
        callback?.();
        if (!isInit && param.productNameId !== getState().order.itemDetail.itemSelect.value) {
          return;
        }
        if (!isInit && param.orderMethodId !== getState().order.itemDetail.orderMethod.value) {
          return;
        }
        dispatch(ordersDataActions.updateOption({
          optionTag: { optionProductTag: res.body.data?.optionProductTag || [] },
        }));
      },
      onError: () => {
        callback?.();
      },
    },
    {
      apiName: 'option-tag',
      ignoreSystemError: true,
    },
  ))
};
