import { useMemo } from 'react';
import './color-icon.scss';
import { CloudFolderStatus } from '../../pages/c-folder-order-list/c-folder-order-list';

type ColorIconProps = {
  status: number,
}

export const CLOUD_FOLDER_STATUS_LIST: CloudFolderStatus[] = [
  { value: 0, label: 'アップロード待ち', color: '#ffcccc' },
  { value: 1, label: 'アップロード中', color: '#EC89B0' },
  { value: 2, label: '目的設定待ち', color: '#c889ec' },
  { value: 3, label: '再アップロード中', color: '#EC89B0' },
  { value: 4, label: 'ラボ発注可', color: '#6DA9E6' },
  { value: 5, label: 'ラボ処理中', color: '#F9D549' },
  { value: 6, label: 'ラボアップロード中', color: '#6DA9E64D' },
  { value: 7, label: 'ダウンロード可(ラボ画像)', color: '#389A32' },
  { value: 8, label: 'ダウンロード中(ラボ画像)', color: '#389A324D' },
  { value: 9, label: 'データ処理完了', color: '#F59332' },
  { value: 10, label: 'PPM-Cloud連携中', color: '#F14E28' },
  { value: 99, label: 'キャンセル', color: '#FF0000' },
];

export const CFolderColorIcon = (props: ColorIconProps) => {
  const { status } = props;

  // Memo
  const color = useMemo(() => {
    const _status = CLOUD_FOLDER_STATUS_LIST.find(v => v.value === status);
    if (_status) {
      return _status.color;
    } else {
      return '#FFF';
    }
  }, [status]);

  return (
    <>
      {status >= 0 ?
        <div
          className={`color_icon circle`}
          style={{ background: color }}
        />
        : <></>}
    </>
  );
};
