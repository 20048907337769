import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { dialogActions } from '../../../dialog/slice/dialog-slice';
import ppmFavicon from '../../../../assets/img/logo/ppmfavicon.png';
import './layout.header.scss';
import { Button } from '../../../ui/button/button';
import { useAppSelector } from '../../../../app/hooks';
import * as lodash from 'lodash';
import { NewPage } from '../../../../utilities/new-page';
import { webPagePath } from '../../../../models/web-page-path';
import { UiManager } from '../../../../layout-editor/manager/ui/ui.manager';
import { MenuType } from '../layout';
import { useParams } from 'react-router-dom';
import { PathParams } from '../../../../routes/routing-path';
import { commonActions } from '../../../../slices/common-slice';

type MenuInfoProps = {
  duplicateCheckedList: string[];
}

const MenuInfo = (props: MenuInfoProps) => {
  const { duplicateCheckedList } = props;
  // TODO: テンプレート変更履歴チェック処理の追加
  const dispatch = useDispatch();
  // - Memo -
  const message = useMemo(() => {
    return duplicateCheckedList.length
      ? ['使用していたテンプレートが更新されました。',
        '以前の編集状態が初期化されていますので、レイアウト内容を確認してください。',
        '',
        '【該当のページ】'].concat(duplicateCheckedList).concat(['', '※画面右上にある「テンプレート変更情報の表示」からご確認いただけます。'])
      : ['テンプレートの変更情報はありません。'];
  }, [duplicateCheckedList]);
  // - Callback -
  // -- 変更情報表示ハンドラ --
  const handlerClickShowTemplateUpdateInfo = useCallback(() => {
    dispatch(
      dialogActions.pushMessage({
        title: 'テンプレート変更情報の表示',
        message,
        buttons: [
          {
            label: 'OK',
            callback: () => {
              dispatch(dialogActions.pop());
            },
          },
        ],
      }),
    );
  }, [duplicateCheckedList]);

  // ショートカットキー
  useEffect(() => {
    UiManager.ins.on('r->l:shortcut:show-temp-update', () => {
      handlerClickShowTemplateUpdateInfo();
    });
    return () => {
      UiManager.ins.off('r->l:shortcut:show-temp-update');
    };
  }, []);
  return (
    !!duplicateCheckedList.length ?
      <Button
        label="テンプレート変更情報の表示"
        color="dark"
        onClick={handlerClickShowTemplateUpdateInfo}
        className="btn_template_change_info"
        size="sm"
      /> : <></>
  );
};

// - 表示サイズショートカット -
type ShortcutType = ';' | '+' | '-' | '7' | '6' | '5' | '4' | '3' | '2' | '1';
// - 表示サイズ変更メニュー（上部）リスト -
const bigOrSmallSizeList: { label: string, shortcut: ShortcutType, callback: () => void }[] = [
  { label: '大きく', shortcut: '+', callback: () => UiManager.ins.emit('r->l:zoom:lv:up', {}) },
  { label: '小さく', shortcut: '-', callback: () => UiManager.ins.emit('r->l:zoom:lv:down', {}) },
];
// - 表示サイズ変更メニュー（下部）リスト -
const percentageSizeList: { label: string, shortcut: ShortcutType, callback: () => void }[] = [
  { label: '400%', shortcut: '7', callback: () => UiManager.ins.emit('r->l:zoom', { lv: 6 }) },
  { label: '200%', shortcut: '6', callback: () => UiManager.ins.emit('r->l:zoom', { lv: 5 }) },
  { label: '100%', shortcut: '5', callback: () => UiManager.ins.emit('r->l:zoom', { lv: 4 }) },
  { label: '75%', shortcut: '4', callback: () => UiManager.ins.emit('r->l:zoom', { lv: 3 }) },
  { label: '50%', shortcut: '3', callback: () => UiManager.ins.emit('r->l:zoom', { lv: 2 }) },
  { label: '25%', shortcut: '2', callback: () => UiManager.ins.emit('r->l:zoom', { lv: 1 }) },
  { label: '10%', shortcut: '1', callback: () => UiManager.ins.emit('r->l:zoom', { lv: 0 }) },
];
// - 表示サイズ変更 -
const SizeChangeMenu = () => {
  const [selectType, setSelectType] = useState<ShortcutType>('5');
  // - effect -
  useEffect(() => {
    const onZoomLvChange = (e: { lv: number }) => {
      setSelectType(String(e.lv + 1) as any);
    };
    UiManager.ins.emit('l->r:zoom:lv:get', { callback: onZoomLvChange });
    UiManager.ins.on('l->r:zoom:lv:change', onZoomLvChange);
    return () => {
      UiManager.ins.off('l->r:zoom:lv:change', onZoomLvChange);
    };
  }, []);
  return (
    <div className="pull_down_menu_list child_list">
      <div>
        {bigOrSmallSizeList.map((v) => (
          <div
            key={`size-change-menu_${v.shortcut}`}
            onClick={() => {
              v.callback();
            }}
            className={`pull_down_menu_list__item${selectType === v.shortcut ? ' selected' : ''}`}
          >
            {selectType === v.shortcut ? <span className="check_icon" /> : (<></>)}
            <span>{v.label}</span>
            <span>Ctrl+{v.shortcut}</span>
          </div>
        ))}
      </div>
      <div>
        {percentageSizeList.map((v) => (
          <div
            key={`size-change-menu_${v.shortcut}`}
            onClick={() => {
              v.callback();
            }}
            className={`pull_down_menu_list__item${selectType === v.shortcut ? ' selected' : ''}`}
          >
            {selectType === v.shortcut ? <span className="check_icon" /> : (<></>)}
            <span>{v.label}</span>
            <span>Ctrl+{v.shortcut}</span>
          </div>
        ))}
      </div>
    </div>
  );
};
// - 写真枠フィット設定の値 -
export type FrameFitType = '5' | '10' | '20';
// - 写真枠フィット設定メニューリスト -
const frameFitNumList: FrameFitType[] = ['5', '10', '20'];
// - 写真枠フィット設定 -
type FrameFitConfigMenuProps = {
  callback: (fitNumber: FrameFitType) => void;
}
const FrameFitConfigMenu = (props: FrameFitConfigMenuProps) => {
  const { callback } = props;
  const [selectType, setSelectType] = useState<FrameFitType>('5');
  const handlerClickMenu = useCallback((v: FrameFitType) => {
    setSelectType(v);
    callback(v);
    UiManager.ins.emit('l->r:frame-fit:push', { fitNum: Number(v) });
  }, []);
  useEffect(() => {
    const onFitNumChange = (e: { fitNum: number }) => {
      setSelectType(String(e.fitNum) as any);
    };
    UiManager.ins.emit('l->r:frame-fit:get', { callback: onFitNumChange });
    UiManager.ins.on('l->r:frame-fit:change', onFitNumChange);
    return () => {
      UiManager.ins.off('l->r:frame-fit:change', onFitNumChange);
    };
  }, []);
  return (
    <div className="pull_down_menu_list child_list">
      {frameFitNumList.map((v) => (
        <div
          key={`frame-fit-config-menu_${v}`}
          onClick={() => handlerClickMenu(v)}
          className={`pull_down_menu_list__item${selectType === v ? ' selected' : ''}`}
        >
          {selectType === v ? <span className="check_icon" /> : (<></>)}
          <span>{v}</span>
        </div>
      ))}
    </div>
  );
};
// - Shiftキー設定の値 -
type ShiftKeyType = '5' | '10' | '15' | '20';
// - Shiftキー設定メニューリスト -
const shiftKeyNumList: ShiftKeyType[] = ['5', '10', '15', '20'];
// - 写真編集Shiftキー設定 -
const ShiftKeyConfigMenu = () => {
  const [selectType, setSelectType] = useState<ShiftKeyType>('5');
  const handlerClickMenu = useCallback((v: ShiftKeyType) => {
    setSelectType(v);
    UiManager.ins.emit('l->r:shift-move:push', { shiftNum: Number(v) });
  }, []);
  useEffect(() => {
    const onShiftNumChange = (e: { shiftNum: number }) => {
      setSelectType(String(e.shiftNum) as any);
    };
    UiManager.ins.emit('l->r:shift-move:get', { callback: onShiftNumChange });
    UiManager.ins.on('l->r:shift-move:change', onShiftNumChange);
    return () => {
      UiManager.ins.off('l->r:shift-move:change', onShiftNumChange);
    };
  }, []);
  return (
    <div className="pull_down_menu_list child_list">
      {shiftKeyNumList.map((v) => (
        <div
          key={`shift-key-config-menu_${v}`}
          onClick={() => handlerClickMenu(v)}
          className={`pull_down_menu_list__item${selectType === v ? ' selected' : ''}`}
        >
          {selectType === v ? <span className="check_icon" /> : (<></>)}
          <span>{v}</span>
        </div>
      ))}
    </div>
  );
};

type EditOpenType = 'none' | 'zoom' | 'frame-fit' | 'guide' | 'shift-key-conf';
type MenuEditProps = {
  callbackMenu: (fitNumber: FrameFitType) => void
}
const MenuEdit = (props: MenuEditProps) => {
  const { callbackMenu } = props;
  // - State -
  // -- メニュー表示状態管理 --
  const [openType, setOpenType] = useState<EditOpenType>('none');
  // - Callback -
  // -- 表示メニュー更新 --
  const handlerChangeOpenType = useCallback((v: EditOpenType) => {
    setOpenType(v);
  }, []);
  return (
    <div className="pull_down_menu_list menu_edit">
      <div
        className={`pull_down_menu_list__item${openType === 'zoom' ? ' selected' : ''}`}
        onMouseOver={() => handlerChangeOpenType('zoom')}
      >
        表示サイズ変更
        {openType === 'zoom' ? (<SizeChangeMenu />) : (<></>)}
      </div>
      <div
        className={`pull_down_menu_list__item${openType === 'frame-fit' ? ' selected' : ''}`}
        onMouseOver={() => handlerChangeOpenType('frame-fit')}
      >
        写真枠フィット設定
        {openType === 'frame-fit' ? <FrameFitConfigMenu callback={callbackMenu} /> : <></>}
      </div>
      <div
        className={`pull_down_menu_list__item${openType === 'shift-key-conf' ? ' selected' : ''}`}
        onMouseOver={() => handlerChangeOpenType('shift-key-conf')}
      >
        写真編集Shiftキー設定
        {openType === 'shift-key-conf' ? <ShiftKeyConfigMenu /> : <></>}
      </div>
    </div>
  );
};


type OpenType = 'none' | 'file' | 'edit' | 'window' | 'info' | 'conf' | 'help';

type LayoutHeaderProps = {
  duplicateCheckedList: string[];
  initMenu: MenuType,
  callbackMenu: (v: MenuType) => void;
}

export const LayoutHeader = (props: LayoutHeaderProps) => {
  const [disabled, setIsDisabled] = useState(false);
  const { duplicateCheckedList, initMenu, callbackMenu } = props;
  const { kijshopCd, shopOrderId } = useParams<PathParams>();
  const { pageType, xml, masterShopList } = useAppSelector((state) => ({
    pageType: state.layout.page,
    xml: state.xml[shopOrderId],
    masterShopList: state.common.data.masterShop?.lnwMasterShop.shop?.[0].shopData,
  }), lodash.isEqual);
  const dispatch = useDispatch();
  // - State
  // -- お客様名、配送店舗 --
  const [name, setName] = useState('');
  const [delivery, setDelivery] = useState('');
  // -- どのメニューを開いているか --
  const [openType, setOpenType] = useState<OpenType>('none');
  // - Callback -
  // -- 開閉状態のメニュー変更用 --
  const handlerChangeOpenType = useCallback((v: OpenType) => {
    if (disabled) return;
    if (v === 'help') {
      NewPage.tab(webPagePath.help);
    }
    setOpenType(v);
  }, [disabled]);

  useEffect(() => {
    UiManager.ins.on('l->r:layout-menu-disabled', (e) => {
      setIsDisabled(e.isDisabled);
    });
    dispatch(commonActions.getData(kijshopCd));
    return (() => {
      UiManager.ins.off('l->r:layout-menu-disabled');
    });
  }, []);

  useEffect(() => {
    setName(xml?.customer.viewModel.lastName ? xml?.customer.viewModel.lastName + ' 様' : '');
    if (xml?.delivery.viewModel.type === 'shop') {
      const shopCode = xml.delivery.viewModel.shopCode;
      const shopName = masterShopList?.find((v) => v.$.shopCode === shopCode)?.name?.[0].$.lastName;
      setDelivery(shopName || '');
    } else if (xml?.delivery.viewModel.type === 'customer') {
      setDelivery('個別配送');
    }
  }, [xml, masterShopList]);

  return (
    <div
      className="layout_header"
      onClick={() => UiManager.ins.emit('r->l:dis-select')}
    >
      <div className="layout_header__left">
        <div className="layout_header__logo">
          <img
            src={ppmFavicon}
            alt=""
          />
        </div>

        {/* メニューリスト閉じる用背景 */}
        {openType === 'none' ? (<></>) : <div
          className="pull_down_menu_list__dimmer"
          onClick={() => {
            handlerChangeOpenType('none');
          }}
        />}
        {pageType === 'edit' ? (
          <div className="layout_header__menu">
            <span onClick={() => handlerChangeOpenType('edit')}>
              編集画面設定
            </span>
            {openType === 'edit' ? (
              <MenuEdit
                callbackMenu={(v) => {
                  callbackMenu({
                    ...initMenu,
                    fitType: v,
                  });
                }}
              />
            ) : (<></>)}
          </div>
        ) : (<></>)}

        <div className="layout_header__menu menu_help">
          <span
            onClick={() => handlerChangeOpenType('help')}
          >
            ヘルプ
          </span>
        </div>
        {pageType === 'edit' ? <></> : <div className="layout_header__space" />}
        <div>
          <div>【お客様名】&nbsp;{name}</div>
          <div>【配送店舗】&nbsp;{delivery}</div>
        </div>
      </div>
      <div className="layout_header__right">
        <MenuInfo duplicateCheckedList={duplicateCheckedList} />
      </div>
    </div>
  );
};
