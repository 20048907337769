// noinspection JSVoidFunctionReturnValueUsed

import { orderRequest, orderRequestOptional, ordersActions } from '../../orders-slice';
import { AppThunk } from '../../../app/store';
import { apiOrderMethodsThunk } from '../../../api/back/create-order/order-methods/api-order-methods';
import { apiItemsThunk } from '../../../api/back/create-order/items/api-items';
import { apiCoverColorThunk } from '../../../api/back/create-order/cover-colors/api-cover-colors';
import { apiPartsThunk } from '../../../api/back/create-order/parts/api-parts';
import { apiOptionPartsThunk } from '../../../api/back/create-order/option-parts/api-option-parts';
import { apiOptionSetInfoThunk } from '../../../api/back/create-order/option-set-info/api-option-set-info';
import { apiFiltersThunk } from '../../../api/back/create-order/filters/api-filters';
import { apiBoardInfosThunk } from '../../../api/back/create-order/board-info/api-board-infos';
import { apiBoardOptionInfosThunk } from '../../../api/back/create-order/board-option-info/api-board-option-infos';
import { ApiDesignType1, apiDesignType1Thunk } from '../../../api/back/create-order/design-type1/api-design-type1';
import { apiDesignType2Thunk } from '../../../api/back/create-order/design-type2/api-design-type2';
import { apiPageCountThunk } from '../../../api/back/create-order/page-count/api-page-count';
import { apiEndPaperThunk } from '../../../api/back/create-order/endpaper/api-endpaper';
import {
  apiFoilStampingsCoverColorThunk,
  apiFoilStampingsCoverThunk, apiFoilStampingsGenuineThunk,
} from '../../../api/back/create-order/foil-stampings/api-foil-stampings';
import { apiProductDetailThunk } from '../../../api/back/create-order/product-detail/api-product-detail';
import { apiOptionTagThunk } from '../../../api/back/create-order/option-tag/api-option-tag';
import { apiOptionItemThunk } from '../../../api/back/create-order/option-item/api-option-item';
import { apiChronologiesThunk } from '../../../api/back/create-order/chronologies/api-chronologies';
import { apiDescriptionInfoThunk } from '../../../api/front/description-info/api-description-info';
import { apiImageCheckThunk } from '../../../api/front/image-check/api-image-check';
import {
  apiOptionProductGroupRulesThunk,
} from '../../../api/back/create-order/option-product-group-rules/api-option-product-group-rules';
import { apiProcessInfoThunk } from '../../../api/back/create-order/process-info/api-process-info';
import { apiPageDataTypesThunk } from '../../../api/back/create-order/page-data-types/api-page-data-types';
import { apiActions } from '../../api-slice';
import { ApiAllTemplate } from '../../../api/back/template/api-all-template';
import { dialogActions } from '../../../components/dialog/slice/dialog-slice';
import { VisibleConditionValueGetter } from '../../../manager/visible-condition-value-getter';

// 表紙選択
export const setCoverSelect = (value: string, label: string = '', agent: boolean): AppThunk => async (dispatch, getState) => {
  const order = getState().order;
  const data = getState().orderData;
  const visibleCondition = getState().createOrder.visibleCondition;
  const display = visibleCondition.itemDetailOrderDisplay;
  const requestOptional = orderRequestOptional(order, getState().createOrder.visibleCondition, data);
  const request = orderRequest(order, getState().createOrder.visibleCondition, data);
  dispatch(ordersActions.updateItemDetail({
    coverSelect: { value, label },
  }));
  // - 表紙更新時のアクション -
  // -- 商品 --
  if (
    !visibleCondition?.checkContentVisible(order, data, 'o_isCategorySap', visibleCondition.visibleData.orderInfo.o_isCategorySap, { hidden: true })
    || value
  ) {
    dispatch(apiItemsThunk({
      serviceId: request.serviceId,
      productTagId: requestOptional.productTagId,
      // カバー選択が表示されていない時はデータ上可能でもカバーでは絞り込まない
      coverTypeId: display.cover ? value || undefined : undefined,
      // coverColorId: value,
      // coverTypeId: getState().createOrder.visibleCondition?.checkContentVisible(order, data, 'o_isCategorySap', undefined) ? value || undefined : undefined,
      // coverColorId: requestOptional.coverColorId,
    }));
  }
  // -- カバー色 --
  dispatch(apiCoverColorThunk(
    {
      serviceId: requestOptional.serviceId,
      coverTypeId: visibleCondition.checkContentVisible(order, data, 'o_isCategorySap', visibleCondition.visibleData?.orderInfo.o_isCategorySap, { hidden: true }) ? value : undefined,
      // coverTypeId: order.orderInfo.proFlg ? (value || undefined) : undefined,
      productTagId: requestOptional.productTagId,
      // productNameId: requestOptional.productNameId,
    },
    () => {},
    false,
    '',
    agent,
  ));
  // -- 箔押し --
  // dispatch(apiFoilStampingsThunk({
  //   // serviceId: request.serviceId,
  //   productNameId: request.productNameId,
  //   // coverColorId: request.coverColorId,
  //   // productTagId: requestOptional.productTagId,
  //   // coverTypeId: value || undefined,
  // }));
};

// 表紙色選択
export const setCoverColorSelect = (value: string, label: string = '', agent: boolean): AppThunk => async (dispatch, getState) => {
  const order = getState().order;
  const data = getState().orderData;
  const visibleCondition = getState().createOrder.visibleCondition;
  const requestOptional = orderRequestOptional(order, visibleCondition, data);
  const request = orderRequest(order, visibleCondition, data);
  dispatch(ordersActions.updateItemDetail({
    coverColorSelect: { value, label },
  }));
  // - 表紙色更新時のアクション -
  // -- 商品 --
  if (
    !visibleCondition?.checkContentVisible(order, data, 'o_isCategorySap', visibleCondition.visibleData.orderInfo.o_isCategorySap, { hidden: true })
    || requestOptional.coverTypeId
  ) {
    dispatch(apiItemsThunk({
      serviceId: request.serviceId,
      productTagId: requestOptional.productTagId,
      coverTypeId: requestOptional.coverTypeId,
      coverColorId: visibleCondition.checkContentVisible(order, data, 'o_isCategorySap', visibleCondition.visibleData?.orderInfo.o_isCategorySap, { hidden: true }) ? value || undefined : undefined,
    }));
  }
  // // -- 箔押し --
  // dispatch(apiFoilStampingsThunk({
  //   productNameId: request.productNameId,
  // }));
  // -- 後加工情報 --
  dispatch(apiFoilStampingsCoverColorThunk({
    coverColorId: value,
  }));
  // -- オプション商品 --
  dispatch(apiOptionItemThunk({
    productNameId: request.productNameId,
    coverColorId: value || undefined,
    // coverId: requestOptional.coverColorId,
    coverId: data.data.productDetail.coverTypeId || undefined,
    orderMethodId: requestOptional.orderMethod,
    boardInfoId: requestOptional.boardInfoId,
    pageCountId1: (!data.option.optionPageCount || requestOptional.orderMethod !== '10') ? String(order.genuine.page) : undefined,
    pageCountId2: (data.option.optionPageCount && requestOptional.dtemplId) ? String(order.genuine.page) : undefined,
    agentMode: agent ? 'true' : undefined,
  }));
};

// 商品選択
export const setItemSelect = (value: string, label: string = '', kijshopCd: string, agent: boolean, visibleCondition: VisibleConditionValueGetter): AppThunk => async (dispatch, getState) => {
  const order = getState().order;
  const data = getState().orderData;
  const masterProductItemInfo = getState().common.data.masterProductItemInfo;
  const requestOptional = orderRequestOptional(order, getState().createOrder.visibleCondition, data);
  const request = orderRequest(order, getState().createOrder.visibleCondition, data);
  if (order?.itemDetail?.itemSelect?.value && order.itemDetail.itemSelect.value !== value) {
    dispatch(ordersActions.updateGenuine({
      page: undefined,
    }));
  }
  dispatch(ordersActions.updateItemDetail({
    itemSelect: { value, label },
  }));
  // - 商品更新時のアクション -
  // -- 注文方法取得 --
  if (value !== order.itemDetail.itemSelect.value) {
    dispatch(apiOrderMethodsThunk(
      {
        productNameId: value,
      },
      () => {},
      false,
      agent,
    ));
  }
  // -- カバー色 --
  // dispatch(apiCoverColorThunk({
  //   serviceId: requestOptional.serviceId,
  //   coverTypeId: requestOptional.coverTypeId,
  //   productNameId: value || undefined,
  //   productTagId: requestOptional.productTagId,
  // }));
  // -- パーツ --
  // dispatch(apiPartsThunk({
  //   productNameId: value,
  // }));
  // -- オプションパーツ --
  dispatch(apiOptionPartsThunk({
    productNameId: value,
  }));
  // -- セット情報 --
  dispatch(apiOptionSetInfoThunk({
    productNameId: value,
  }));
  // -- フィルター --
  dispatch(apiFiltersThunk({
    productNameId: value,
  }));
  // -- ページ数 --
  dispatch(apiPageCountThunk(
    {
      // productFlg: request.productFlg,
      productFlg: '1',
      productNameId: value,
      dtemplId: requestOptional.dtemplId,
    },
    () => {},
    false,
    agent,
  ));
  // -- 部材（親） --
  dispatch(apiBoardInfosThunk({
    productNameId: value,
  }));
  // -- 部材（子） --
  dispatch(apiBoardOptionInfosThunk(
    {
      productNameId: value,
    },
    () => {},
    false,
    agent,
  ));
  // -- デザインタイプ１ --
  dispatch(apiDesignType1Thunk(
    {
      productNameId: value,
      boardInfoId: requestOptional.boardInfoId,
    },
    () => {},
  ));
  // -- デザインタイプ２ --
  dispatch(apiDesignType2Thunk({
    productNameId: value,
    hpsTppathNamePath: request.hpsTppathNamePath,
    boardInfoId: requestOptional.boardInfoId,
  }));
  // -- 見返し --
  dispatch(apiEndPaperThunk(
    {
      productNameId: value,
      parentFlg: request.parentFlg,
    },
    () => {},
    false,
    visibleCondition.visibleData?.op01_body?.op01b_additionalInfo_multi?.defaultElement,
  ));
  // -- 年表選択 --
  dispatch(apiChronologiesThunk({
    productNameId: value,
  }));
  // console.log(data.cover.parts.itemId);
  // console.log(data.genuine.parts.itemId);
  // if (data.cover.parts.itemId || data.genuine.parts.itemId) {
  //   // -- 箔押し --
  //   dispatch(apiFoilStampingsThunk({
  //     // serviceId: request.serviceId,
  //     productNameId: value,
  //     // coverColorId: request.coverColorId,
  //     // productTagId: requestOptional.productTagId,
  //     // coverTypeId: requestOptional.coverTypeId,
  //   }));
  // }
  // -- 商材取得 --
  dispatch(apiProductDetailThunk(
    {
      productNameId: value,
      serviceId: request.serviceId,
      parentFlg: '1',
    },
    (resArr) => {
      const arr = resArr?.map((res) => res.body.data?.items?.[0]);
      const coverId = arr && arr[0] ? arr[0].itemId : data.cover.parts.itemId;
      const genuineId = arr && arr[1] ? arr[1].itemId : data.genuine.parts.itemId;
      if (coverId) {
        // -- 箔押し --
        dispatch(apiFoilStampingsCoverThunk({
          // serviceId: request.serviceId,
          productNameId: value,
          itemId: coverId,
          // coverColorId: request.coverColorId,
          // productTagId: requestOptional.productTagId,
          // coverTypeId: requestOptional.coverTypeId,
        }));
      }if (genuineId) {
        // -- 箔押し --
        dispatch(apiFoilStampingsGenuineThunk({
          // serviceId: request.serviceId,
          productNameId: value,
          itemId: genuineId,
          // coverColorId: request.coverColorId,
          // productTagId: requestOptional.productTagId,
          // coverTypeId: requestOptional.coverTypeId,
        }));
      }
    },
    false,
    agent,
  ));
  // -- オプション タグ --
  dispatch(apiOptionTagThunk({
    productNameId: value,
    orderMethodId: request.orderMethod,
  }));
  // -- オプション 商品 --
  // dispatch(apiOptionItemThunk({
  //   productNameId: value,
  // }));
  if (value !== order.itemDetail.itemSelect.value) {
    dispatch(apiOptionItemThunk({
      productNameId: value,
      coverColorId: requestOptional.coverColorId,
      // coverId: requestOptional.coverColorId,
      coverId: data.data.productDetail.coverTypeId || undefined,
      orderMethodId: requestOptional.orderMethod,
      boardInfoId: requestOptional.boardInfoId,
      pageCountId1: (!data.option.optionPageCount || requestOptional.orderMethod !== '10') ? String(order.genuine.page) : undefined,
      pageCountId2: (data.option.optionPageCount && requestOptional.dtemplId) ? String(order.genuine.page) : undefined,
      agentMode: agent ? 'true' : undefined,
    }));
  }
  // -- 商品記載情報 --
  dispatch(apiDescriptionInfoThunk({
    serviceId: request.serviceId,
    productNameId: value,
    kijshopCd,
  }, masterProductItemInfo));
  // -- オプション商品ルール --
  dispatch(apiOptionProductGroupRulesThunk({
    productNameId: value,
  }));
  // ページ種
  dispatch(apiPageDataTypesThunk({
    productNameId: value,
    parentFlg: '1',
    serviceId: request.serviceId,
  }));
  // -- 完成画像枚数 --
  // if (request.orderMethod === '40') {
  //   dispatch(apiImageCheckThunk({
  //     goodsId: value,
  //     page: order.genuine.page,
  //     kijshopCd,
  //   }));
  // }
  // -- ラミネート --
  // パーツ取得APIのレスポンスを参照するためここでは叩かない
  // -- 付加情報初期値 --
  // if (
  //   value === 'jptg380123'
  //   || value === 'jptg380124'
  //   || value === 'jptg380163'
  //   || value === 'jptg380164'
  //   || value === 'jptg380217'
  // ) {
  //   dispatch(ordersActions.genuine.setAdditionalMulti(value));
  // }
};

// 部材（親）
export const setBoardSelect = (value: string, label: string = '', agent: boolean): AppThunk => async (dispatch, getState) => {
  const order = getState().order;
  const data = getState().orderData;
  const requestOptional = orderRequestOptional(order, getState().createOrder.visibleCondition, data);
  const request = orderRequest(order, getState().createOrder.visibleCondition, data);
  const options = getState().order.option;
  dispatch(ordersActions.updateItemDetail({
    boardSelect: { value, label },
  }));
  // - 部材更新時のアクション -
  // -- デザインタイプ１ --
  dispatch(apiDesignType1Thunk({
    productNameId: request.productNameId,
    boardInfoId: value || undefined,
  }));
  // -- デザインタイプ２ --
  dispatch(apiDesignType2Thunk({
    productNameId: request.productNameId,
    hpsTppathNamePath: request.hpsTppathNamePath,
    boardInfoId: value || undefined,
  }));
  // -- オプション商品 --
  dispatch(apiOptionItemThunk(
    {
      productNameId: request.productNameId,
      coverColorId: requestOptional.coverColorId,
      // coverId: requestOptional.coverColorId,
      coverId: data.data.productDetail.coverTypeId || undefined,
      orderMethodId: requestOptional.orderMethod,
      boardInfoId: value || undefined,
      pageCountId1: (!data.option.optionPageCount || requestOptional.orderMethod !== '10') ? String(order.genuine.page) : undefined,
      pageCountId2: (data.option.optionPageCount && requestOptional.dtemplId) ? String(order.genuine.page) : undefined,
      agentMode: agent ? 'true' : undefined,
    },
    (res) => {
      let flag = false;
      options.forEach((op) => {
        if (!flag && op?.item?.value && res?.optionProduct?.length && !res.optionProduct.find((v) => v.optionProductNameId === op.item.value)) {
          flag = true;
        }
      });
      if (flag) {
        dispatch(dialogActions.pushMessage({
          title: '確認',
          message: [
            '部材が変更されたため選択済みのオプションがリセットされました。',
            'あらためてオプションを選んでください。',
          ],
          buttons: [{
            label: 'OK',
            callback: () => {
              dispatch(dialogActions.pop());
            },
          }],
        }));
      }
    },
  ));
};

// 注文方法
export const setOrderMethod = (value: string, label: string = '', agent: boolean): AppThunk => async (dispatch, getState) => {
  const order = getState().order;
  const data = getState().orderData;
  const requestOptional = orderRequestOptional(order, getState().createOrder.visibleCondition, data);
  const request = orderRequest(order, getState().createOrder.visibleCondition, data);
  dispatch(ordersActions.updateItemDetail({
    orderMethod: { value, label },
  }));
  // - 注文方法更新時のアクション -
  // -- オプション 商品 --
  dispatch(apiOptionItemThunk({
    productNameId: request.productNameId,
    coverColorId: requestOptional.coverColorId,
    // coverId: requestOptional.coverColorId,
    coverId: data.data.productDetail.coverTypeId || undefined,
    orderMethodId: value || undefined,
    boardInfoId: requestOptional.boardInfoId,
    pageCountId1: (!data.option.optionPageCount || value !== '10') ? String(order.genuine.page) : undefined,
    pageCountId2: (data.option.optionPageCount && order.itemDetail.designType2.value) ? String(order.genuine.page) : undefined,
    agentMode: agent ? 'true' : undefined,
  }));
  // -- オプション タグ --
  dispatch(apiOptionTagThunk({
    productNameId: request.productNameId,
    orderMethodId: value,
  }));
  // -- 工程情報 --
  dispatch(apiProcessInfoThunk({
    orderMethodId: value,
  }));
  // -- 完成画像枚数 --
  // if (value === '40') {
  //   dispatch(apiImageCheckThunk({
  //     goodsId: request.productNameId,
  //     page: order.genuine.page,
  //   }));
  // }
};

// デザインタイプ1
export const setDesignType1 = (value: string, label: string = ''): AppThunk => async (dispatch, getState) => {
  const order = getState().order;
  const data = getState().orderData;
  const requestOptional = orderRequestOptional(order, getState().createOrder.visibleCondition, data);
  const request = orderRequest(order, getState().createOrder.visibleCondition, data);
  dispatch(ordersActions.updateItemDetail({
    designType1: { value, label },
  }));
  // - デザインタイプ1更新時のアクション -
  // -- デザインタイプ2 --
  dispatch(apiDesignType2Thunk({
    productNameId: request.productNameId,
    hpsTppathNamePath: value,
    boardInfoId: requestOptional.boardInfoId,
  }));
};
// デザインタイプ2
export const setDesignType2 = (value: string, label: string = '', agent: boolean): AppThunk => async (dispatch, getState) => {
  const order = getState().order;
  const data = getState().orderData;
  const requestOptional = orderRequestOptional(order, getState().createOrder.visibleCondition, data);
  const request = orderRequest(order, getState().createOrder.visibleCondition, data);
  dispatch(ordersActions.updateItemDetail({
    designType2: { value, label },
  }));
  // - デザインタイプ2更新時のアクション -
  // -- ページ数 --
  dispatch(apiPageCountThunk(
    {
      productNameId: request.productNameId,
      productFlg: '2',
      dtemplId: value,
    },
    () => {},
    false,
    agent,
  ));
};
// 数量
export const setQuantity = (value: number): AppThunk => async (dispatch) => {
  dispatch(ordersActions.updateItemDetail({
    quantity: value,
  }));
};
