import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button } from '../../ui/button/button';
import { Input } from '../../ui/input/input';
import { Radiobutton } from '../../ui/input/radiobutton';
import './detail-search.scss';
import { SearchParam } from '../../pages/order-list/order-table';
import { NumInput } from '../../ui/input/numInput';

type DetailSearchProps = {
  // - 検索するハンドラ -
  handlerClickSearch: (value: SearchParam) => void,
  // - 閉じるハンドラ -
  handlerClickClose: () => void,
  searchParam: SearchParam,
};
export const DetailSearch = (props: DetailSearchProps) => {
  // - Hooks -
  const dispatch = useDispatch();
  const { handlerClickSearch, handlerClickClose, searchParam } = props;
  // - State -
  // -- 日時のモード 作成 or 更新 -
  const [dateType, setDateType] = useState<'create' | 'update'>(searchParam.dateType);
  // -- 日時（開始） -
  const [dateFrom, setDateFrom] = useState(searchParam.date.from);
  // -- 日時（終了） --
  const [dateTo, setDateTo] = useState(searchParam.date.to);
  // -- 注文番号 --
  const [orderNumber, setOrderNumber] = useState(searchParam.orderId);
  // -- お客様名 --
  const [customerName, setCustomerName] = useState(searchParam.customerName);
  // - Callback -
  // -- 日時（開始） --
  const handlerChangeCreateDateStart = useCallback((e) => {
    setDateFrom(e.target.value);
  }, []);
  // -- 日時（終了） --
  const handlerChangeCreateDateEnd = useCallback((e) => {
    setDateTo(e.target.value);
  }, []);
  // -- 注文番号 --
  const handlerChangeOrderNumber = useCallback((e) => {
    setOrderNumber(e.target.value);
  }, []);
  const [backNum, setBackNum] = useState(0);
  // -- お客様名 --
  const handlerChangeCustomerName = useCallback((e) => {
    setCustomerName(e.target.value);
  }, []);

  const onKeyUp = (e: any) => {
    if (e.code === 'Enter') {
      onEnterPress();
    }
  };

  const onEnterPress = () => {
    const reg = RegExp(/^[0-9]+$/);
    if (!reg.test(orderNumber)) {
      setOrderNumber(orderNumber.replace(/[^0-9]/g, ''));
    }
  };

  return (
    <div className="dialog_contents detail_search">
      <div className="dialog_contents__body detail_search__body">
        <div>検索内容を入力してください</div>

        <div className="detail_search__forms">
          <div className="detail_search__forms__date">
            <div className="detail_search__forms__date__mode radio_buttons_box">
              <Radiobutton
                label="作成日時"
                value="create"
                onClick={() => setDateType('create')}
                checkState={dateType}
              />
              <Radiobutton
                label="更新日時"
                value="update"
                onClick={() => setDateType('update')}
                checkState={dateType}
              />
            </div>
            <div className="date_start_end">
              <Input
                type="date"
                value={dateFrom}
                onChange={handlerChangeCreateDateStart}
              />
              <div className="date_start_end__tilde" />
              <Input
                type="date"
                value={dateTo}
                onChange={handlerChangeCreateDateEnd}
              />
            </div>
          </div>

          <div className="top_label_ui">
            <div className="top_label_ui__label">お客様名</div>
            <Input
              value={customerName}
              onChange={handlerChangeCustomerName}
            />
          </div>
          <div className="top_label_ui order_number">
            <div className="top_label_ui__label">注文番号</div>
            <Input
              value={orderNumber}
              maxLength={13}
              handlerEnter={onEnterPress}
              onKeyUp={(e) => onKeyUp(e)}
              onChange={(e) => setOrderNumber(e.target.value)}
              onBlur={onEnterPress}
            />
          </div>
        </div>
      </div>

      <div className="dialog_contents__footer">
        <Button
          label="閉じる"
          onClick={handlerClickClose}
        />
        <Button
          label="検索"
          onClick={() => handlerClickSearch({
            // createDate: {start: createDateStart, end: createDateEnd},
            // updateDate: {start: updateDateStart, end: updateDateEnd},
            date: { from: dateFrom, to: dateTo },
            orderId: orderNumber,
            customerName: customerName,
            dateType: dateType,
          })}
        />
      </div>
    </div>
  );
};
