import { Orientation } from 'get-orientation/browser';

export type ExifSizeType = {
  width: number,
  height: number,
}

/**
 * Exif関係のデータを用途に合わせて変換する
 */
export class ExifConverter {
  /**
   * Exifのサイズ情報(幅と高さ)を取得する
   * @param orientation Exifデータから取得した回転情報
   * @param image 対象の画像
   */
  static getExifSize(orientation: Orientation, image: HTMLImageElement): ExifSizeType {
    /* 回転情報を参考に実画像の高さと幅を設定して返す */
    switch (orientation) {
      /* 画像の縦横サイズが元画像の状態から変更されていない場合、実画像と同じものとして設定する */
      case Orientation.TOP_LEFT:
      case Orientation.TOP_RIGHT:
      case Orientation.BOTTOM_RIGHT:
      case Orientation.BOTTOM_LEFT:
        return {width: image.naturalWidth, height: image.naturalHeight};
      /* 画像の縦横サイズが元画像の状態から変更されている場合、実画像の高さと幅を入れ替える */
      case Orientation.LEFT_TOP:
      case Orientation.RIGHT_TOP:
      case Orientation.RIGHT_BOTTOM:
      case Orientation.LEFT_BOTTOM:
        return {width: image.naturalHeight, height: image.naturalWidth};
      /* 不測の事態の際は実画像を正とする(処理的には入らない) */
      default:
        return {width: image.naturalWidth, height: image.naturalHeight};
    }
  }
}
