import {configureStore, ThunkAction, Action, combineReducers, getDefaultMiddleware} from '@reduxjs/toolkit';
import { createHashHistory } from 'history';
import {connectRouter, routerMiddleware} from 'connected-react-router';
import {sampleReducer} from '../slices/sample-slice';
import {sidebarReducer} from '../slices/sidebar-slice';
import {loadingReducer} from '../slices/loading-slice';
import {favoriteTemplateReducer} from '../slices/favorite-template-slice';
import {layoutReducer} from '../slices/layout-slice';
import {toolbarReducer} from '../components/toolbar/slice/toolbar-slice';
import {dialogReducer} from '../components/dialog/slice/dialog-slice';
import {ordersReducer} from '../slices/orders-slice';
import { newsListReducer } from '../slices/news-list-slice';
import { favoriteBrandReducer } from '../slices/favorite-brand-slice';
import {ordersDataReducer} from '../slices/orders/orders-data-slice';
import { retouchReducer } from '../slices/retouch-slice';
import {apiReducer} from "../slices/api-slice";
import {debugReducer} from "../slices/debug-slice";
import {commonReducer} from "../slices/common-slice";
import {orderPreparationReducer} from "../slices/order-preparation-slice";
import { systemConfigReducer } from '../slices/system-config-slice';
import { createOrderReducer } from '../slices/create-order-slice';
import { xmlReducer } from '../xml/slice/xml-slice';
import { deliveryReducer } from '../slices/orders/delivery-slice';
import { orderLockReducer } from '../slices/lock-order-slice';
import { localStorageReducer } from '../slices/local-storage-slice';
import { staffReducer } from '../slices/staff-slice';

export const history = createHashHistory();

const reducer = combineReducers({
  router: connectRouter(history),
  sample: sampleReducer,
  // デバッグ用
  debug: debugReducer,
  // ユーザーデータ等
  common: commonReducer,
  // api（通信）
  api: apiReducer,
  // 注文
  order: ordersReducer,
  // 注文概要
  orderPreparation: orderPreparationReducer,
  // 注文作成用データ
  orderData: ordersDataReducer,
  // 注文作成画面
  createOrder: createOrderReducer,
  // ダイアログ
  dialog: dialogReducer,
  // 右クリックメニュー
  toolbar: toolbarReducer,
  // 読み込み中
  loading: loadingReducer,
  // サイドバー
  sidebar: sidebarReducer,
  // 配送先情報
  delivery: deliveryReducer,
  // お気に入りテンプレート
  favoriteTemplate: favoriteTemplateReducer,
  // レイアウト
  layout: layoutReducer,
  // お知らせ一覧
  newsList: newsListReducer,
  // お気に入り商品
  favoriteBrand: favoriteBrandReducer,
  // レタッチ
  retouch: retouchReducer,
  // システム設定
  systemConfig: systemConfigReducer,
  // xml
  xml: xmlReducer,
  // ロックされている注文データリスト
  orderLock:orderLockReducer,
  // 初期値用ローカルストレージ保持データ
  storage: localStorageReducer,
  // スタッフ
  staff: staffReducer,
});

const customizeMiddleWare = getDefaultMiddleware({
  serializableCheck: false,
});

export const store = configureStore({
  reducer,
  middleware() {
    return customizeMiddleWare
      .concat(routerMiddleware(history));
  }
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
