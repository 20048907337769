import { Input } from '../../ui/input/input';
import React, { useCallback, useState } from 'react';
import { Shop } from '../../pages/shop-list/shop-list';
import { Button } from '../../ui/button/button';
import { dialogActions } from '../slice/dialog-slice';
import { useDispatch } from 'react-redux';
import { apiActions } from '../../../slices/api-slice';
import { ApiGetCloudFolderShopList } from '../../../api/front/cloud-folder/api-get-cloud-folder-shop-list';
import { ResponseBase } from '../../../api/response-base';
import { toHumanReadableByte } from '../../../utilities/tu-human-readable';

type Props = {
  search: string,
}
export const CSVOutputFilter = (props: Props) => {
  const { search } = props;
  const dispatch = useDispatch();

  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const handlerCloseDialog = useCallback(() => {
    dispatch(dialogActions.pop());
  }, []);

  const handlerClickOutput = useCallback(() => {
    const param: { startDate?: string, endDate?: string } = {};
    if (startDate) {
      param.startDate = startDate;
    }
    if (endDate) {
      param.endDate = endDate;
    }
    dispatch(dialogActions.pop());
    dispatch(apiActions.run(
      new ApiGetCloudFolderShopList(param),
      {
        onSuccess: (res: ResponseBase<Shop[]>) => {
          if (res.body.data) {
            const list: Shop[] = [];
            if (search !== '') {
              list.push(...res.body.data.filter(item =>
                item.kijshopCd.match(search) || item.shopName.match(search),
              ));
            } else {
              list.push(...res.body.data);
            }
            list.sort((a, b) => a.kijshopCd < b.kijshopCd ? -1 : 1);
            let baseText = '';
            const label1 = '得意先CD';
            const label2 = '得意先名';
            const label3 = 'スタッフ数';
            const label4 = '注文数';
            const label5 = 'DL可能期間';
            const label6 = 'UPサイズ(現在)';
            const label7 = '月間DL回数';
            const label8 = '月間DL枚数';
            const label9 = '月間DLサイズ';
            const label10 = '月間UP回数';
            const label11 = '月間UP枚数';
            const label12 = '月間UPサイズ';
            const label13 = '合計DL回数';
            const label14 = '合計DL枚数';
            const label15 = '合計DLサイズ';
            const label16 = '合計UP回数';
            const label17 = '合計UP枚数';
            const label18 = '合計UPサイズ';
            if (startDate || endDate) {
              baseText += `${label1},${label2},${label3},${label4},${label5},${label6},${label13},${label14},${label15},${label16},${label17},${label18}\n`;
            } else {
              baseText += `${label1},${label2},${label3},${label4},${label5},${label6},${label7},${label8},${label9},${label10},${label11},${label12},${label13},${label14},${label15},${label16},${label17},${label18}\n`;
            }
            list.forEach((v) => {
              const val1 = v.kijshopCd;
              const val2 = v.shopName;
              const val3 = v.staffNum;
              const val4 = v.orderNum;
              const val5 = v.orderExpiredDay + '日';
              const val6 = toHumanReadableByte(v.totalSize);
              const val7 = v.monthDownloadNum;
              const val8 = v.monthDownloadImageNum;
              const val9 = toHumanReadableByte(v.monthDownloadSizeSum);
              const val10 = v.monthUploadNum;
              const val11 = v.monthUploadImageNum;
              const val12 = toHumanReadableByte(v.monthDownloadSizeSum);
              const val13 = v.downloadNum;
              const val14 = v.downloadImageNum;
              const val15 = toHumanReadableByte(v.downloadSizeSum);
              const val16 = v.uploadNum;
              const val17 = v.uploadImageNum;
              const val18 = toHumanReadableByte(v.uploadSizeSum);
              if (startDate || endDate) {
                baseText += `="${val1}",${val2},${val3},${val4},${val5},${val6},${val13},${val14},${val15},${val16},${val17},${val18}\n`;
              } else {
                baseText += `="${val1}",${val2},${val3},${val4},${val5},${val6},${val7},${val8},${val9},${val10},${val11},${val12},${val13},${val14},${val15},${val16},${val17},${val18}\n`;
              }
            });
            const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
            const blob = new Blob([bom, baseText], { type: 'text/csv;charset=utf-8;' });
            const link = document.createElement('a');
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', 'shop-list.csv');
            link.click();
          }
        },
      },
    ));
  }, [search, startDate, endDate]);

  return (
    <div className="dialog_contents">
      <div>
        <div>集計期間</div>
        <div className={'cloud-folder-search-order__date__input'}>
          <div>
            <Input
              type={'date'}
              value={startDate}
              max={endDate}
              onChange={(e) => setStartDate(e.target.value)}
              onBlur={() => {
                if (endDate && startDate > endDate) setStartDate(endDate);
              }}
            />
          </div>
          〜
          <div>
            <Input
              type={'date'}
              value={endDate}
              min={startDate}
              onChange={(e) => setEndDate(e.target.value)}
              onBlur={() => {
                if (startDate && startDate > endDate) setEndDate(startDate);
              }}
            />
          </div>
        </div>
      </div>
      <div className={'dialog_contents__footer'}>
        <Button
          label="キャンセル"
          onClick={handlerCloseDialog}
          color={'light'}
        />
        <Button
          label="OK"
          onClick={handlerClickOutput}
        />
      </div>
    </div>
  );
};
