import { XmlClass } from '../xml-class';
import { XmlClassName } from '../../model/xml-class-name';
import { IOrderPageData } from '../../interface/order/i-order-page-data';
import { TOrderPageDataViewModel } from '../../model/order/xml-order-page-data-model';

type TView = Partial<TOrderPageDataViewModel>;

export class OrderPageDataXml extends XmlClass<IOrderPageData, TView, {}, [string, string, string]> {

  // indexes!: [string, string, string];

  name: XmlClassName = 'order-page-data';
  viewModel: TView = {};
  metaModel: {} = {};

  constructor(shopOrderId: string, data: { version: string, indexes: [string | number, string | number, string | number] }) {
    super(shopOrderId, data);
  }

  protected init(): void {
    this.xmlUniqueName = this.xmlUniqueName || `PPM_${this.shopOrderId}_11_11_orderPageData_${this.indexes[0]}_${this.indexes[1]}_${this.indexes[2]}.xml`;
    this.xmlParentName = this.xmlParentName || `PPM_${this.shopOrderId}_11_11_orderPage_${this.indexes[0]}_${this.indexes[1]}.xml`;
    this.rootTagModel = {
      ...this.rootTagModel,
      contentGroupID: undefined, // TODO
      parentXPath: '/lnwOrderPage/orderPage',
      parentPath: this.xmlParentName,
    };
    this.docking({}, {});
  }

  protected docking(v: TView, m: {}): void {
    this.xmlModel = {
      lnwOrderPageData: {
        $: { ...this.rootTagModel },
        data: [
          {
            $: {
              id: v.id,
              pageNo: v.pageNo,
              pageIndex: v.pageIndex,
              pageCount: v.pageCount,
              pageType: v.pageType,
              parentID: v.parentID,
              displayPageType: v.displayPageType,
              displayPageNo: v.displayPageNo,
            },
            template: v.template && [
              {
                $: {
                  adPath: v.template?.adPath,
                  name: v.template?.name,
                  path: v.template?.path,
                  shapeFlag: v.template?.shapeFlag,
                  tempChangeFlag: '0',
                  tempReverseFlag: '0',
                },
                scaleEditor: v.template?.scaleEditorFlag ? [
                  {
                    $: {
                      enable: v.template?.scaleEditorFlag,
                    },
                  },
                ] : undefined,
                size: (v.template?.real || v.template?.virtual) && [
                  {
                    real: v.template?.real && [
                      {
                        $: {
                          height: v.template?.real?.height,
                          width: v.template?.real?.width,
                        },
                      },
                    ],
                    virtual: [
                      {
                        $: {
                          height: v.template?.virtual?.height,
                          width: v.template?.virtual?.width,
                        },
                      },
                    ],
                  },
                ],
              },
            ],
            compositeFileName: v.compositeFileName && [
              {
                real: v.compositeFileName.realPath !== undefined ? [
                  {
                    $: {
                      path: v.compositeFileName.realPath,
                    },
                  },
                ] : undefined,
                virtual: v.compositeFileName?.virtualPath !== undefined ? [
                  {
                    $: {
                      path: v.compositeFileName?.virtualPath,
                    },
                  },
                ] : undefined,
                display: v.compositeFileName?.displayPath !== undefined ? [
                  {
                    $: {
                      path: v.compositeFileName?.displayPath,
                    },
                  },
                ] : undefined,
                sheet: v.compositeFileName?.sheetPath !== undefined ? [
                  {
                    $: {
                      path: v.compositeFileName?.sheetPath,
                    },
                  },
                ] : undefined,
              },
            ],
            originalCompositeFileName: v.originalCompositeFileName && [
              {
                real: [
                  {
                    $: {
                      path: v.originalCompositeFileName.realPath,
                    },
                  },
                ],
              },
            ],
            pageOutputCount: v.pageOutputCount ? [v.pageOutputCount] : undefined,
            pageNoOverlap: v.pageNoOverlap && [
              {
                $: {
                  left: v.pageNoOverlap?.left,
                  right: v.pageNoOverlap?.right,
                },
              },
            ],
            orderFrame: v.orderFrame && [
              {
                $: {
                  count: `${v.orderFrame?.length || 0}`,
                },
                data: v.orderFrame?.map((data, i) => ({
                  $: {
                    id: data.id ?? `${i + 1}`,
                    copyFlag: data.copyFlag,
                    deleteFlag: data.deleteFlag,
                  },
                  frameRect: (data.real || data.virtual) && [
                    {
                      real: data.real && [
                        {
                          $: {
                            height: '0',
                            width: '0',
                            x: data.real?.x,
                            y: data.real?.y,
                          },
                        },
                      ],
                      virtual: data.virtual && [
                        {
                          $: {
                            height: '0',
                            width: '0',
                            x: data.virtual?.x,
                            y: data.virtual?.y,
                          },
                        },
                      ],
                    },
                  ],
                  frameRotate: data.frameRotate !== undefined ? [
                    data.frameRotate,
                  ] : undefined,
                  zoom: data.zoom !== undefined ? [
                    data.zoom,
                  ] : undefined,
                })),
              },
            ],
            orderPicture: v.orderPicture && [
              {
                $: {
                  count: `${v.orderPicture?.data?.length}`,
                  photoSeqCount: v.orderPicture?.photoSeqCount,
                },
                data: v.orderPicture?.data?.map((data, i) => ({
                    $: {
                      contactNaiFlag: data?.contactNaiFlag,
                      cantactNaiFlag: data?.cantactNaiFlag,
                      copyFlag: data?.copyFlag,
                      deleteFlag: data?.deleteFlag,
                      depth: data?.depth,
                      frame: data?.frame,
                      framename: data.frame !== undefined ? '' : undefined, // から文字
                      id: `${i + 1}`,
                      logoFlag: data?.logoFlag,
                      mask: data?.mask,
                      maskname: data?.maskname,
                      orgsubMaskID: data?.orgsubMaskID,
                      photoSeqNo: data?.photoSeqNo,
                      selectID: data?.selectID,
                      subMaskID: data?.subMaskID,
                    },
                    selectImage: data.selectImage ? [
                      {
                        realSize: [
                          {
                            $: {
                              height: (data?.selectImage) ? data.selectImage.height : '',
                              width: (data?.selectImage) ? data.selectImage.width : '',
                            },
                          },
                        ],
                      },
                    ] : undefined,
                    compositeFlag: data.compositeFlag ? data.compositeFlag : undefined,
                    logoFileName: data?.logoFileName?.realPath ? [
                      {
                        real: [
                          {
                            $: {
                              path: data?.logoFileName?.realPath ?? '',
                            },
                          },
                        ],
                        virtual: [
                          {
                            $: {
                              path: '', //　から文字
                            },
                          },
                        ],
                      },
                    ] : undefined,
                    subMaskRect: (data?.subMaskRect?.real || data?.subMaskRect?.virtual) ? [
                      {
                        real: data?.subMaskRect?.real ? [
                          {
                            $: {
                              height: data?.subMaskRect?.real?.height,
                              width: data?.subMaskRect?.real?.width,
                              x: data?.subMaskRect?.real?.x,
                              y: data?.subMaskRect?.real?.y,
                            },
                          },
                        ] : undefined,
                        virtual: data?.subMaskRect?.virtual ? [
                          {
                            $: {
                              height: data?.subMaskRect?.virtual?.height,
                              width: data?.subMaskRect?.virtual?.width,
                              x: data?.subMaskRect?.virtual?.x,
                              y: data?.subMaskRect?.virtual?.y,
                            },
                          },
                        ] : undefined,
                      },
                    ] : undefined,
                    subMaskRotate: data?.subMaskRotate ? [
                      data.subMaskRotate,
                    ] : undefined,
                    pictureRect: (data?.pictureRect?.real || data?.pictureRect?.virtual) ? [
                      {
                        real: data?.pictureRect?.real ? [
                          {
                            $: {
                              height: data?.pictureRect?.real?.height,
                              width: data?.pictureRect?.real?.width,
                              x: data?.pictureRect?.real?.x,
                              y: data?.pictureRect?.real?.y,
                            },
                          },
                        ] : undefined,
                        virtual: data?.pictureRect?.virtual ? [
                          {
                            $: {
                              height: data?.pictureRect?.virtual?.height,
                              width: data?.pictureRect?.virtual?.width,
                              x: data?.pictureRect?.virtual?.x,
                              y: data?.pictureRect?.virtual?.y,
                            },
                          },
                        ] : undefined,
                      },
                    ] : undefined,
                    pictureRotate: data?.pictureRotate ? [
                      data.pictureRotate,
                    ] : undefined,
                    zoom: data.zoom ? data.zoom : undefined,
                  }),
                ),
              },
            ],
            orderText: v.orderText?.data ? [
              {
                $: {
                  count: `${v.orderText?.data?.length}`,
                },
                data: v.orderText?.data.map((data, i) => ({
                  $: {
                    id: `${i + 1}`,
                    type: 'text',
                  },
                  text: data.text && [
                    {
                      $: {
                        antiareasing: '0',
                        bold: data.text?.bold,
                        borderColor: data.text?.borderColor,
                        contactPageNoPos: data.text?.contactPageNoPos,
                        contactType: data.text?.contactType,
                        fontColor: data.text?.fontColor,
                        fontName: data.text?.fontName,
                        horizontalAlignment: data.text?.horizontalAlignment,
                        italic: data.text?.italic,
                        lineHeight: data.text?.lineHeight,
                        photoSeqNo: '-1',
                        strikeOut: data.text?.strikeOut,
                        text: data.text?.text,
                        transparence: data.text?.transparence,
                        underLine: data.text?.underLine,
                        verticalAlignment: '0',
                      },
                      textSize: [
                        {
                          real: [
                            {
                              $: {
                                size: '0',
                              },
                            },
                          ],
                          virtual: data.text && [
                            {
                              $: {
                                size: data.text?.textSize,
                              },
                            },
                          ],
                        },
                      ],
                      borderSize: [
                        {
                          real: [
                            {
                              $: {
                                size: '0',
                              },
                            },
                          ],
                          virtual: [
                            {
                              $: {
                                size: data.text?.borderSize,
                              },
                            },
                          ],
                        },
                      ],
                      dropShadow: [
                        {
                          $: {
                            enable: data.text?.dropShadow?.enable,
                            // color: '808080',
                            transparence: data.text?.dropShadow?.transparence,
                          },
                          interval: [
                            {
                              real: [
                                {
                                  $: {
                                    size: '0',
                                  },
                                },
                              ],
                              virtual: data.text?.dropShadow && [
                                {
                                  $: {
                                    size: data.text?.dropShadow?.virtualSize,
                                  },
                                },
                              ],
                            },
                          ],
                        },
                      ],
                      part: data.text?.part ? [
                        {
                          $: {
                            text: '', // から文字
                            text_alpha: data.text?.part?.text_alpha,
                            text_bold: data.text?.part?.text_bold,
                            text_endPos: data.text?.part?.text_endPos,
                            text_fontColor: data.text?.part?.text_fontColor,
                            text_fontName: data.text?.part?.text_fontName,
                            text_italic: data.text?.part?.text_italic,
                            text_size: data.text?.part?.text_size,
                            text_startPos: data.text?.part?.text_startPos,
                          },
                        },
                      ] : undefined,
                    },
                  ],
                  image: [
                    {
                      $: {
                        count: '', //から文字
                      },
                    },
                  ],
                  textRect: (data.textRect?.real || data.textRect?.virtual) && [
                    {
                      real: data.textRect?.real && [
                        {
                          $: {
                            height: data.textRect?.real?.height,
                            width: data.textRect?.real?.width,
                            x: data.textRect?.real?.x,
                            y: data.textRect?.real?.y,
                          },
                        },
                      ],
                      virtual: data.textRect?.virtual && [
                        {
                          $: {
                            height: data.textRect?.virtual?.height,
                            width: data.textRect?.virtual?.width,
                            x: data.textRect?.virtual?.x,
                            y: data.textRect?.virtual?.y,
                          },
                        },
                      ],
                    },
                  ],
                  textRotate: data.textRotate !== undefined ? [
                    data.textRotate,
                  ] : undefined,
                  zoom: [
                    '1',
                  ],
                })),
              },
            ] : undefined,
            freeGraphic: v.freeGraphic && [
              {
                $: {
                  count: `${v.freeGraphic?.data?.length}`,
                },
                freeGraphicData: v.freeGraphic?.data?.map((data, i) => ({
                  $: {
                    id: `${i + 1}`,
                    index: `${i}`,
                  },
                  real: data.real && [
                    {
                      $: {
                        height: data.real?.height,
                        path: data.real?.path,
                        width: data.real?.width,
                        x: data.real?.x,
                        y: data.real?.y,
                      },
                    },
                  ],
                  virtual: data.virtual && [
                    {
                      $: {
                        height: data.virtual?.height,
                        path: data.virtual?.path,
                        width: data.virtual?.width,
                        x: data.virtual?.x,
                        y: data.virtual?.y,
                      },
                    },
                  ],
                  rotation: data.rotation !== undefined ? [
                    data.rotation,
                  ] : undefined,
                })),
              },
            ],
            orderTextImage: v.orderTextImage && [
              {
                $: {
                  count: `${v.orderTextImage?.data?.length}`,
                },
                data: v.orderTextImage?.data?.map((data, i) => ({
                  $: {
                    id: `${i + 1}`, // 1start
                  },
                  real: [
                    {
                      $: {
                        height: data.height,
                        path: data.path,
                        width: data.width,
                        x: data.x,
                        y: data.y,
                      },
                    },
                  ],
                  virtual: [
                    {
                      $: {
                        height: '',  // から文字
                        width: '',  // から文字
                        path: '',  // から文字
                        x: '',    // から文字
                        y: '',   // から文字
                      },
                    },
                  ],
                })),
              },
            ],
            additionalFrame: v.additionalFrame && [
              {
                $: {
                  name: v.additionalFrame?.name,
                  type: v.additionalFrame?.type,
                  dpi: v.additionalFrame?.dpi,
                  frameID: v.additionalFrame?.frameID,
                  enable: v.additionalFrame?.enable,
                  rotate: v.additionalFrame?.rotate,
                },
                color: v.additionalFrame?.color && [
                  {
                    $: {
                      r: v.additionalFrame?.color?.r,
                      g: v.additionalFrame?.color?.g,
                      b: v.additionalFrame?.color?.b,
                    },
                  },
                ],
                additionalFrameSize: (v.additionalFrame?.real || v.additionalFrame?.virtual) && [
                  {
                    real: v.additionalFrame?.real && [
                      {
                        $: {
                          height: v.additionalFrame?.real?.height,
                          width: v.additionalFrame?.real?.width,
                          x: v.additionalFrame?.real?.x,
                          y: v.additionalFrame?.real?.y,
                        },
                      },
                    ],
                    virtual: v.additionalFrame?.virtual && [
                      {
                        $: {
                          height: v.additionalFrame?.virtual?.height,
                          width: v.additionalFrame?.virtual?.width,
                          x: v.additionalFrame?.virtual?.x,
                          y: v.additionalFrame?.virtual?.y,
                        },
                      },
                    ],
                  },
                ],
                rotatable: v.additionalFrame?.rotatable && [
                  {
                    $: {
                      r90: v.additionalFrame?.rotatable.r90,
                      r180: v.additionalFrame?.rotatable.r180,
                      r270: v.additionalFrame?.rotatable.r270,
                    },
                  },
                ],
                windows: v.additionalFrame?.windows && [
                  {
                    $: {
                      count: `${v.additionalFrame?.windows.length}`,
                    },
                    window: v.additionalFrame?.windows.map((window, i) => ({
                      $: {
                        id: `${i + 1}`,
                        sizeID: window.sizeID,
                        sizeName: window.sizeName,
                      },
                      type: [
                        {
                          $: {
                            circle: window.circle,
                            default: window.default,
                            rect: window.rect,
                            rotation: window.rotation,
                          },
                        },
                      ],
                      windowSize: (window.real || window.virtual) && [
                        {
                          real: window.real && [
                            {
                              $: {
                                height: window.real.height,
                                width: window.real.width,
                                x: window.real.x,
                                y: window.real.y,
                              },
                            },
                          ],
                          virtual: window.virtual && [
                            {
                              $: {
                                height: window.virtual.height,
                                width: window.virtual.width,
                                x: window.virtual.x,
                                y: window.virtual.y,
                              },
                            },
                          ],
                        },
                      ],
                    })),
                  },
                ],
              },
            ],
            additionalHole: v.additionalHole && [
              {
                windows: v.additionalHole?.windows && [
                  {
                    $: {
                      count: `${v.additionalHole?.windows.length}`,
                    },
                    window: v.additionalHole?.windows.map((window, i) => ({
                      $: {
                        id: `${i + 1}`,
                      },
                      name: '',
                      nakamadoHeight: window.nakamadoHeight !== undefined ? [
                        window.nakamadoHeight,
                      ] : undefined,
                      jinsunHeight: window.jinsunHeight !== undefined ? [
                        window.jinsunHeight,
                      ] : undefined,
                      upperSpace: window.upperSpace !== undefined ? [
                        window.upperSpace,
                      ] : undefined,
                      bottomSpace: window.bottomSpace !== undefined ? [
                        window.bottomSpace,
                      ] : undefined,
                    })),
                  },
                ],
              },
            ],
            materials: v.materials && [
              {
                $: {
                  count: `${v.materials?.material.length}`,
                  id: v.materials?.id,
                  name: v.materials?.name,
                },
                material: v.materials?.material.map((material, i) => ({
                  $: {
                    id: `${i + 1}`,
                    materialId: material.materialID,
                    materialName: material.materialName,
                    shape: material.shape,
                  },
                })),
              },
            ],
          },
        ],
      },
    };
  }


  protected split(x: IOrderPageData): void {
    if (!x?.lnwOrderPageData?.data || !x.lnwOrderPageData.$) {
      this.viewModel = {};
      this.metaModel = {};
      return;
    }
    const data = x.lnwOrderPageData.data?.[0];
    this.viewModel = {
      displayPageNo: data?.$?.displayPageNo,
      displayPageType: data?.$?.displayPageType,
      id: data?.$?.id,
      pageCount: data?.$?.pageCount,
      pageIndex: data?.$?.pageIndex,
      pageNo: data?.$?.pageNo,
      pageType: data?.$?.pageType,
      parentID: data?.$?.parentID,
      template: data?.template ? {
        adPath: data.template?.[0]?.$?.adPath || '',
        name: data.template?.[0]?.$?.name || '',
        path: data.template?.[0].$?.path || '',
        shapeFlag: data.template?.[0].$?.shapeFlag || '',
        scaleEditorFlag: data.template?.[0].scaleEditor?.[0]?.$?.enable || undefined,
        real: {
          height: data.template?.[0]?.size?.[0]?.real?.[0]?.$?.height || '',
          width: data.template?.[0]?.size?.[0]?.real?.[0]?.$?.width || '',
        },
        virtual: {
          height: data.template?.[0]?.size?.[0]?.virtual?.[0]?.$?.height || '',
          width: data.template?.[0]?.size?.[0]?.virtual?.[0]?.$?.width || '',
        },
      } : undefined,
      compositeFileName: data?.compositeFileName?.[0] ? {
        realPath: data?.compositeFileName?.[0]?.real?.[0]?.$?.path,
        virtualPath: data?.compositeFileName?.[0]?.virtual?.[0]?.$?.path,
        displayPath: data?.compositeFileName?.[0]?.display?.[0]?.$?.path,
        sheetPath: data?.compositeFileName?.[0]?.sheet?.[0]?.$?.path,
      } : undefined,
      originalCompositeFileName: data?.originalCompositeFileName?.[0] && {
        realPath: data?.originalCompositeFileName?.[0]?.real?.[0]?.$?.path || '',
      },
      pageOutputCount: data?.pageOutputCount?.[0],
      pageNoOverlap: data?.pageNoOverlap ? {
        left: data?.pageNoOverlap?.[0]?.$?.left || '',
        right: data?.pageNoOverlap?.[0]?.$?.right || '',
      } : undefined,
      orderFrame: data?.orderFrame?.[0]?.data?.length ? [...data.orderFrame[0].data].map((frame) => ({
        id: frame.$?.id || '',
        copyFlag: frame.$?.copyFlag || '',
        deleteFlag: frame.$?.deleteFlag || '',
        real: frame.frameRect?.[0].real?.[0].$ && {
          x: frame.frameRect?.[0].real?.[0].$?.x || '',
          y: frame.frameRect?.[0].real?.[0].$?.y || '',
        },
        virtual: frame.frameRect?.[0].virtual?.[0].$ && {
          x: frame.frameRect?.[0].virtual?.[0].$?.x || '',
          y: frame.frameRect?.[0].virtual?.[0].$?.y || '',
        },
        frameRotate: frame.frameRotate?.[0] || '',
        zoom: frame.zoom?.[0] || '',
      })) : undefined,
      orderPicture: data?.orderPicture?.[0] ? {
        data: data?.orderPicture?.[0]?.data ? [...data.orderPicture[0].data].map((pic) => ({
            id: pic.$?.id || '',
            contactNaiFlag: pic.$?.contactNaiFlag,
            cantactNaiFlag: pic.$?.cantactNaiFlag,
            copyFlag: pic.$?.copyFlag,
            deleteFlag: pic.$?.deleteFlag,
            depth: pic.$?.depth,
            frame: pic.$?.frame,
            logoFlag: pic.$?.logoFlag,
            mask: pic.$?.mask,
            maskname: pic.$?.maskname,
            orgsubMaskID: pic.$?.orgsubMaskID,
            photoSeqNo: pic.$?.photoSeqNo,
            selectID: pic.$?.selectID,
            subMaskID: pic.$?.subMaskID,
            compositeFlag: pic.compositeFlag || undefined,
            selectImage: pic.selectImage?.[0]?.realSize?.[0]?.$ ? {
              width: pic.selectImage?.[0]?.realSize?.[0]?.$?.width,
              height: pic.selectImage?.[0]?.realSize?.[0]?.$?.height,
            } : undefined,
            logoFileName: pic.logoFileName?.[0]?.real?.[0]?.$?.path ? {
              realPath: pic.logoFileName?.[0]?.real?.[0]?.$?.path || '',
            } : undefined,
            subMaskRect: (pic.subMaskRect?.[0]?.real?.[0]?.$ || pic.subMaskRect?.[0]?.virtual?.[0]?.$) ? {
              real: pic.subMaskRect?.[0]?.real?.[0]?.$ ? {
                x: pic.subMaskRect?.[0]?.real?.[0]?.$?.x,
                y: pic.subMaskRect?.[0]?.real?.[0]?.$?.y,
                width: pic.subMaskRect?.[0]?.real?.[0]?.$?.width,
                height: pic.subMaskRect?.[0]?.real?.[0]?.$?.height,
              } : undefined,
              virtual: pic.subMaskRect?.[0]?.virtual?.[0]?.$ ? {
                x: pic.subMaskRect?.[0]?.virtual?.[0]?.$?.x,
                y: pic.subMaskRect?.[0]?.virtual?.[0]?.$?.y,
                width: pic.subMaskRect?.[0]?.virtual?.[0]?.$?.width,
                height: pic.subMaskRect?.[0]?.virtual?.[0]?.$?.height,
              } : undefined,
            } : undefined,
            subMaskRotate: pic.subMaskRotate?.[0],
            pictureRect: (pic.pictureRect?.[0]?.real?.[0]?.$ || pic.pictureRect?.[0]?.virtual?.[0]?.$) ? {
              real: pic.pictureRect?.[0]?.real?.[0]?.$ ? {
                x: pic.pictureRect?.[0]?.real?.[0]?.$?.x,
                y: pic.pictureRect?.[0]?.real?.[0]?.$?.y,
                width: pic.pictureRect?.[0]?.real?.[0]?.$?.width,
                height: pic.pictureRect?.[0]?.real?.[0]?.$?.height,
              } : undefined,
              virtual: pic.pictureRect?.[0]?.virtual?.[0]?.$ ? {
                x: pic.pictureRect?.[0]?.virtual?.[0]?.$?.x,
                y: pic.pictureRect?.[0]?.virtual?.[0]?.$?.y,
                width: pic.pictureRect?.[0]?.virtual?.[0]?.$?.width,
                height: pic.pictureRect?.[0]?.virtual?.[0]?.$?.height,
              } : undefined,
            } : undefined,
            pictureRotate: pic.pictureRotate?.[0],
            zoom: pic.zoom || undefined,
          }),
        ) : [],
        photoSeqCount: data?.orderPicture?.[0]?.$?.photoSeqCount,
      } : undefined,
      orderText: data?.orderText ? {
        data: data?.orderText?.[0]?.data ? [...data.orderText[0].data].map((text) => ({
          // text: text?.text?.[0].$?.text || '',
          text: text?.text?.[0] && {
            bold: text?.text?.[0].$?.bold,
            borderColor: text?.text?.[0].$?.borderColor || '',
            contactPageNoPos: text?.text?.[0].$?.contactPageNoPos,
            contactType: text?.text?.[0].$?.contactType,
            fontColor: text?.text?.[0].$?.fontColor,
            fontName: text?.text?.[0].$?.fontName,
            horizontalAlignment: text?.text?.[0].$?.horizontalAlignment,
            italic: text?.text?.[0].$?.italic,
            lineHeight: text?.text?.[0].$?.lineHeight,
            strikeOut: text?.text?.[0].$?.strikeOut,
            transparence: text?.text?.[0].$?.transparence,
            underLine: text?.text?.[0].$?.underLine,
            text: text?.text?.[0].$?.text,
            textSize: text?.text?.[0]?.textSize?.[0]?.virtual?.[0]?.$?.size,
            borderSize: text?.text?.[0]?.borderSize?.[0]?.virtual?.[0]?.$?.size,
            dropShadow: text?.text?.[0]?.dropShadow?.[0]?.$ && {
              enable: text?.text?.[0]?.dropShadow?.[0]?.$?.enable,
              transparence: text?.text?.[0]?.dropShadow?.[0]?.$?.transparence,
              virtualSize: text?.text?.[0]?.dropShadow?.[0]?.interval?.[0]?.virtual?.[0].$?.size,
            },
            part: text?.text?.[0]?.part?.[0].$ && {
              text_alpha: text?.text?.[0]?.part?.[0].$?.text_alpha,
              text_bold: text?.text?.[0]?.part?.[0].$?.text_bold,
              text_endPos: text?.text?.[0]?.part?.[0].$?.text_endPos,
              text_fontColor: text?.text?.[0]?.part?.[0].$?.text_fontColor,
              text_fontName: text?.text?.[0]?.part?.[0].$?.text_fontName,
              text_italic: text?.text?.[0]?.part?.[0].$?.text_italic,
              text_size: text?.text?.[0]?.part?.[0].$?.text_size,
              text_startPos: text?.text?.[0]?.part?.[0].$?.text_startPos,
            },
          },
          textRect: {
            real: text.textRect?.[0]?.real?.[0]?.$ && {
              x: text.textRect?.[0]?.real?.[0]?.$?.x,
              y: text.textRect?.[0]?.real?.[0]?.$?.y,
              width: text.textRect?.[0]?.real?.[0]?.$?.width,
              height: text.textRect?.[0]?.real?.[0]?.$?.height,
            },
            virtual: text.textRect?.[0]?.virtual?.[0]?.$ && {
              x: text.textRect?.[0]?.virtual?.[0]?.$?.x,
              y: text.textRect?.[0]?.virtual?.[0]?.$?.y,
              width: text.textRect?.[0]?.virtual?.[0]?.$?.width,
              height: text.textRect?.[0]?.virtual?.[0]?.$?.height,
            },
          },
          textRotate: text.textRotate?.[0],
        })) : [],
      } : undefined,
      freeGraphic: data?.freeGraphic ? {
        data: data.freeGraphic[0]?.freeGraphicData ? [...data.freeGraphic[0].freeGraphicData].map((graphic) => ({
          real: graphic.real?.[0]?.$ && {
            x: graphic.real?.[0]?.$?.x,
            y: graphic.real?.[0]?.$?.y,
            width: graphic.real?.[0]?.$?.width,
            height: graphic.real?.[0]?.$?.height,
            path: graphic.real?.[0]?.$?.path,
          },
          virtual: graphic.virtual?.[0]?.$ && {
            x: graphic.virtual?.[0]?.$?.x,
            y: graphic.virtual?.[0]?.$?.y,
            width: graphic.virtual?.[0]?.$?.width,
            height: graphic.virtual?.[0]?.$?.height,
            path: graphic.virtual?.[0]?.$?.path,
          },
          rotation: graphic.rotation?.[0],
        })) : [],
      } : undefined,
      orderTextImage: data?.orderTextImage ? {
        data: data?.orderTextImage?.[0]?.data ? [...data.orderTextImage[0].data].map((image) => ({
          x: image.real?.[0]?.$?.x,
          y: image.real?.[0]?.$?.y,
          width: image.real?.[0]?.$?.width,
          height: image.real?.[0]?.$?.height,
          path: image.real?.[0]?.$?.path,
        })) : [],
      } : undefined,
      additionalFrame: data?.additionalFrame?.[0] ? {
        name: data?.additionalFrame?.[0]?.$?.name,
        type: data?.additionalFrame?.[0]?.$?.type,
        dpi: data?.additionalFrame?.[0]?.$?.dpi,
        frameID: data?.additionalFrame?.[0]?.$?.frameID,
        enable: data?.additionalFrame?.[0]?.$?.enable,
        rotate: data?.additionalFrame?.[0]?.$?.rotate,
        color: data?.additionalFrame?.[0]?.color?.[0]?.$ && {
          r: data?.additionalFrame?.[0]?.color?.[0]?.$?.r,
          g: data?.additionalFrame?.[0]?.color?.[0]?.$?.g,
          b: data?.additionalFrame?.[0]?.color?.[0]?.$?.b,
        },
        real: data?.additionalFrame?.[0]?.additionalFrameSize?.[0]?.real?.[0]?.$ && {
          x: data?.additionalFrame?.[0]?.additionalFrameSize?.[0]?.real?.[0]?.$?.x,
          y: data?.additionalFrame?.[0]?.additionalFrameSize?.[0]?.real?.[0]?.$?.y,
          width: data?.additionalFrame?.[0]?.additionalFrameSize?.[0]?.real?.[0]?.$?.width,
          height: data?.additionalFrame?.[0]?.additionalFrameSize?.[0]?.real?.[0]?.$?.height,
        },
        virtual: data?.additionalFrame?.[0]?.additionalFrameSize?.[0]?.virtual?.[0]?.$ && {
          x: data?.additionalFrame?.[0]?.additionalFrameSize?.[0]?.virtual?.[0]?.$?.x,
          y: data?.additionalFrame?.[0]?.additionalFrameSize?.[0]?.virtual?.[0]?.$?.y,
          width: data?.additionalFrame?.[0]?.additionalFrameSize?.[0]?.virtual?.[0]?.$?.width,
          height: data?.additionalFrame?.[0]?.additionalFrameSize?.[0]?.virtual?.[0]?.$?.height,
        },
        rotatable: data?.additionalFrame?.[0]?.rotatable?.[0]?.$ && {
          r90: data?.additionalFrame?.[0]?.rotatable?.[0]?.$?.r90,
          r180: data?.additionalFrame?.[0]?.rotatable?.[0]?.$?.r180,
          r270: data?.additionalFrame?.[0]?.rotatable?.[0]?.$?.r270,
        },
        windows: data?.additionalFrame?.[0]?.windows?.[0]?.window?.length ? [...(data?.additionalFrame?.[0]?.windows?.[0]?.window || [])].map((win) => ({
          sizeID: win.$?.sizeID,
          sizeName: win.$?.sizeName,
          circle: win.type?.[0]?.$?.circle,
          default: win.type?.[0]?.$?.default,
          rect: win.type?.[0]?.$?.rect,
          rotation: win.type?.[0]?.$?.rotation,
          real: win.windowSize?.[0]?.real?.[0].$ && {
            x: win.windowSize?.[0]?.real?.[0].$?.x,
            y: win.windowSize?.[0]?.real?.[0].$?.y,
            width: win.windowSize?.[0]?.real?.[0].$?.width,
            height: win.windowSize?.[0]?.real?.[0].$?.height,
          },
          virtual: win.windowSize?.[0]?.virtual?.[0].$ && {
            x: win.windowSize?.[0]?.virtual?.[0].$?.x,
            y: win.windowSize?.[0]?.virtual?.[0].$?.y,
            width: win.windowSize?.[0]?.virtual?.[0].$?.width,
            height: win.windowSize?.[0]?.virtual?.[0].$?.height,
          },
        })) : [],
      } : undefined,
      additionalHole: data?.additionalHole?.[0]?.windows?.[0]?.window?.length ? {
        windows: [...data.additionalHole[0].windows[0].window].map((win) => ({
          nakamadoHeight: win.nakamadoHeight?.[0],
          jinsunHeight: win.jinsunHeight?.[0],
          upperSpace: win.upperSpace?.[0],
          bottomSpace: win.bottomSpace?.[0],
        })),
      } : undefined,
      materials: data?.materials?.[0] ? {
        id: data?.materials?.[0]?.$?.id,
        name: data?.materials?.[0]?.$?.name,
        material: [...(data.materials[0].material || [])].map((material) => ({
          materialID: material.$?.materialId,
          materialName: material.$?.materialName,
          shape: material.$?.shape,
        })),
      } : undefined,
    };
    this.metaModel = {
      parentPath: x.lnwOrderPageData.$?.parentPath,
      parentXPath: x.lnwOrderPageData.$?.parentXPath,
      contentGroupID: x.lnwOrderPageData.$?.contentGroupID,
    };
    this.rootTagModel = Object.assign(this.rootTagModel, x.lnwOrderPageData.$);
  }

  changeIndexes(indexes: [string, string, string]) {
    this.indexes = indexes;
    this.xmlUniqueName = `PPM_${this.shopOrderId}_11_11_orderPageData_${this.indexes[0]}_${this.indexes[1]}_${this.indexes[2]}.xml`;
    this.xmlParentName = `PPM_${this.shopOrderId}_11_11_orderPage_${this.indexes[0]}_${this.indexes[1]}.xml`;
    this.rootTagModel = {
      ...this.rootTagModel,
      parentPath: this.xmlParentName,
    };
  }

}
