import { Button } from '../../../ui/button/button';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { dialogActions } from '../../../dialog/slice/dialog-slice';
import { ChangeOrderPageNum } from '../../../dialog/unique/change-order-page-num';
import { push } from 'connected-react-router';
import { PathParams, RoutingPath } from '../../../../routes/routing-path';
import { UiManager } from '../../../../layout-editor/manager/ui/ui.manager';
import { useParams } from 'react-router-dom';
import * as lodash from 'lodash';
import { useAppSelector } from '../../../../app/hooks';
import { layoutActions } from '../../../../slices/layout-slice';
import { xmlActions } from '../../../../xml/slice/xml-slice';
import { XmlStructureModel, XmlStructureOrderInfoData } from '../../../../xml/model/xml-structure-model';
import iconAdjust2Screen from '../../../../assets/img/icon/adjust2screen.svg';
import { orderLockActions } from '../../../../slices/lock-order-slice';
import { getFormat } from '../../../ui/input/calendar';
import { DateFormatter } from '../../../../utilities/date-formatter';

type Props = {
  xml: XmlStructureModel | null,
  targetXml: XmlStructureOrderInfoData | null,
  clickDisplaySize?: (isUp: boolean) => void,
};
export const LayoutFooter = (props: Props) => {
  const { xml, targetXml, clickDisplaySize } = props;
  const dispatch = useDispatch();
  const {
    albumSettingData,
    orderInfo,
    tempGroup,
    albumDatas,
    pageDataTypesRes,
    currentAlbum,
  } = useAppSelector((state) => ({
    albumSettingData: state.layout.albumSettingData,
    orderInfo: state.orderPreparation.currentOrder,
    tempGroup: state.layout.templateGroupName,
    albumDatas: state.layout.albumPages,
    pageDataTypesRes: state.layout.pageDataTypesRes,
    currentAlbum: state.layout.currentAlbumPage,
  }), lodash.isEqual);
  const { kijshopCd, shopOrderId, orderId } = useParams<PathParams>();
  // - Store -
  const {
    brandName,
    templateGroup,
    name,
    date,
    place,
    pageTypes,
    page,
    orderNum,
  } = {
    // brandName: orderInfo?.products?.productName,
    // templateGroup: orderInfo?.productTag?.productTagId,
    brandName: albumSettingData.productName,
    templateGroup: tempGroup,
    name: albumSettingData.name,
    date: albumSettingData.date,
    place: albumSettingData.location,
    pageTypes: albumSettingData.pageTypes,
    page: albumSettingData.pageCount,
    orderNum: shopOrderId,
  };
  const startDate = useMemo(() => DateFormatter.date2str(new Date(), 'YYYYMMDD_HHmmSS', '/'), []);
  const [isInitAlbumInfo, setIsInitAlbumInfo] = useState(false);
  const [checkedDescription, setCheckedDescription] = useState(false);
  const [isClick, setIsClick] = useState(false);
  // - Callback -
  // -- 拡大 --
  const handlerClickZoomIn = useCallback(() => {
    if (clickDisplaySize) {
      clickDisplaySize(true);
    } else {
      if (!currentAlbum) return;
      UiManager.ins.emit('r->l:zoom:lv:up', {});
    }
  }, [clickDisplaySize, currentAlbum]);
  // -- 縮小 --
  const handlerClickZoomOut = useCallback(() => {
    if (clickDisplaySize) {
      clickDisplaySize(false);
    } else {
      if (!currentAlbum) return;
      UiManager.ins.emit('r->l:zoom:lv:down', {});
    }
  }, [clickDisplaySize, currentAlbum]);
  // -- フィット --
  const handlerClickZoomFit = useCallback(() => {
    if (!currentAlbum) return;
    UiManager.ins.emit('r->l:zoom:fit', {});
  }, [currentAlbum]);
  // -- 一時保存 --
  const handlerClickSaveTemp = useCallback(() => {
    /* 記載箇所入力チェック */
    if ((name || date || place) && !pageTypes.length && pageDataTypesRes.pageDataTypes.length) {
      dispatch(dialogActions.pushMessage({
        title: '書き出しエラー',
        message: ['商品記載箇所のチェックが選択されていません。'],
        buttons: [{
          label: 'OK',
          callback: () => {
            dispatch(dialogActions.pop());
          },
        }],
      }));
      return;
    }
    dispatch(orderLockActions.check(kijshopCd, shopOrderId, {
      yes: () => {
        xml && UiManager.ins.emit('r->l:valid:save-layout', { xml, kijshopCd, shopOrderId, orderId, startDate });
      },
      no: () => {
        dispatch(orderLockActions.setId(''));
        dispatch(push(RoutingPath.cnv.orders({ kijshopCd })));
      },
    }));
  }, [xml, name, date, place, pageTypes, pageDataTypesRes]);
  // -- レイアウト完了 --
  const handlerClickComplete = useCallback(() => {
    /* 商品記載情報更新チェック */
    const orderInfoData = xml?.orderInfo?.infoData?.find((v) => v.xml.metaModel.id === orderId);
    const {
      name: xmlName,
      date: xmlDate,
      location: xmlLocation,
      pageType: xmlPageType,
    } = orderInfoData?.xml.viewModel.descriptionInfo || { name: '', date: '', location: '', pageType: [] };
    if (
      !checkedDescription
      && (
        ((name || xmlName) && name !== xmlName)
        || ((date || xmlDate) && date !== xmlDate)
        || ((place || xmlLocation) && place !== xmlLocation)
      )
    ) {
      dispatch(dialogActions.pushMessage({
        title: '書き出し確認',
        message: [
          '商品記載情報が変更されています。変更して実行しますか？',
          '変更する場合は、再度[レイアウト完了]をしてください。',
        ],
        buttons: [
          {
            label: 'いいえ',
            callback: () => {
              dispatch(dialogActions.pop());
            },
          },
          {
            label: 'はい',
            callback: () => {
              dispatch(dialogActions.pop());
              setCheckedDescription(true);
            },
          },
        ],
      }));
      return;
    }
    /* 記載箇所入力チェック */
    if ((name || date || place) && !pageTypes.length && pageDataTypesRes.pageDataTypes.length) {
      dispatch(dialogActions.pushMessage({
        title: '書き出しエラー',
        message: ['商品記載箇所のチェックが選択されていません。'],
        buttons: [{
          label: 'OK',
          callback: () => {
            dispatch(dialogActions.pop());
          },
        }],
      }));
      return;
    }
    dispatch(orderLockActions.check(kijshopCd, shopOrderId, {
      yes: () => xml && UiManager.ins.emit('r->l:valid:complete-layout', {
        xml,
        kijshopCd,
        shopOrderId,
        orderId,
        startDate,
      }),
      no: () => {
        dispatch(orderLockActions.setId(''));
        dispatch(push(RoutingPath.cnv.orders({ kijshopCd })));
      },
    }));
  }, [xml, checkedDescription, name, date, place, pageTypes, pageDataTypesRes]);

  // ショートカットキー
  useEffect(() => {
    if (xml) {
      UiManager.ins.on('r->l:shortcut:save-layout', () => {
        handlerClickSaveTemp();
      });
    }
    return () => {
      UiManager.ins.off('r->l:shortcut:save-layout');
    };
  }, [xml]);

  useEffect(() => {
    if (targetXml) {
      if (isInitAlbumInfo) {
        dispatch(layoutActions.setAlbumSettingData({
          ...albumSettingData,
          pageCount: targetXml.parts?.partsData?.find((v) => v.xml.viewModel.type === 'main')?.xml.viewModel.pageCount || '',
        }));
        return;
      }
      setIsInitAlbumInfo(true);
      let objKey: string[] = [];
      if (targetXml.xml.viewModel.descriptionInfo?.pageType) {
        objKey = Object.keys(targetXml.xml.viewModel.descriptionInfo?.pageType);
      }
      let isShowCover = false;
      let isShowTop = false;
      for (const obj of objKey) {
        if (obj === albumDatas.cover.pageTypeId) {
          isShowCover = true;
        } else if (obj === albumDatas.top.pageTypeId) {
          isShowTop = true;
        }
      }
      const date = getFormat(targetXml.xml.viewModel.descriptionInfo?.date!);
      dispatch(layoutActions.setAlbumSettingData(
        {
          productName: targetXml.xml.viewModel.goods?.name || '',
          name: targetXml.xml.viewModel.descriptionInfo?.name || '',
          date: targetXml.xml.viewModel.descriptionInfo?.date || '',
          formatType: date.formatType || '',
          initialType: date.initType || '',
          location: targetXml.xml.viewModel.descriptionInfo?.location || '',
          pageTypes: Object.keys(targetXml.xml.viewModel.descriptionInfo?.pageType || {}).map((key) => key),
          pageCount: targetXml.parts?.partsData?.find((v) => v.xml.viewModel.type === 'main')?.xml.viewModel.pageCount || '',
          stepPageCount: targetXml.parts?.partsData?.find((v) => v.xml.viewModel.type === 'main')?.xml.viewModel.stepPageCount || '',
          pageCountInfo: {
            step: targetXml.parts?.partsData?.find((v) => v.xml.viewModel.type === 'main')?.xml.viewModel.stepPageCount || '',
            max: targetXml.parts?.partsData?.find((v) => v.xml.viewModel.type === 'main')?.xml.viewModel.maxPageCount || '',
            min: targetXml.parts?.partsData?.find((v) => v.xml.viewModel.type === 'main')?.xml.viewModel.minPageCount || '',
          },
          // productName: orderInfo?.products?.productName ? orderInfo?.products?.productName : '',
          // name: '',
          // date: orderInfo?.date ? orderInfo.date : '',
          // location: '',
          // pageCount: orderInfo?.pageCount ? orderInfo?.pageCount : '',
        },
      ));
    }
  }, [targetXml, albumDatas]);
  return (
    <div
      className="layout_footer"
      onClick={() => UiManager.ins.emit('r->l:dis-select')}
    >
      <div className="layout_footer__info">
        <div>
          <div>【商品名】{brandName}</div>
          <div>【テンプレートグループ】{templateGroup}</div>
          <div>【名前】{name}</div>
        </div>
        <div>
          <div>【日付】{date}</div>
          <div>【場所】{place}</div>
          <div>【ページ】{page}</div>
          <div>【注文番号】{orderNum}</div>
        </div>
      </div>

      <div className="layout_footer__size">
        <span>表示サイズ</span>
        <Button
          onClick={handlerClickZoomIn}
          size="sm"
          color="dark"
          label="＋"
          disabled={(!clickDisplaySize && !currentAlbum)}
        />
        <Button
          onClick={handlerClickZoomOut}
          size="sm"
          color="dark"
          label="－"
          disabled={(!clickDisplaySize && !currentAlbum)}
        />
        {clickDisplaySize ? (
          <></>
        ) : (
          <Button
            onClick={handlerClickZoomFit}
            size="sm"
            color="dark"
            icon={<img src={iconAdjust2Screen} />}
            className="btn_adjust"
            disabled={!currentAlbum}
          />
        )}
      </div>

      <div className="layout_footer__operation">
        <Button
          label="一時保存"
          onClick={() => {
            if (!isClick) {
              handlerClickSaveTemp();
              setIsClick(true);
            }
            window.setTimeout(() => {
              setIsClick(false);
            }, 500);
          }}
          size="sm"
          color="secondary"
        />
        <Button
          label="レイアウト完了"
          onClick={() => {
            if (!isClick) {
              handlerClickComplete();
              setIsClick(true);
            }
            window.setTimeout(() => {
              setIsClick(false);
            }, 500);
          }}
          size="sm"
        />
      </div>
    </div>
  );
};
