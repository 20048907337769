import { store } from '../../../app/store';
import { ILayoutEditorManagerBase } from '../layout-editor.manager';
import { UiManager } from '../ui/ui.manager';

export class KeyboardManager implements ILayoutEditorManagerBase {
  private uiManager!: UiManager;
  // TODO shiftNumの外出し(headerの設定と紐づける)
  private shiftNum = 5;
  private middleFrame = 1;
  private handlerKeyPress!: (e: KeyboardEvent) => void;
  private isStopEvent: boolean = false;
  private isSelectMode: boolean = false;
  private isTrimMenu: boolean = false;

  private onShiftNumGet: (v: { callback: (p: { shiftNum: number }) => void }) => void = (v: { callback: (p: { shiftNum: number }) => void }) => {
    v.callback({ shiftNum: this.shiftNum });
  };
  private changeShiftNum: (v: { shiftNum: number }) => void = (v: { shiftNum: number }) => {
    this.shiftNum = v.shiftNum;
    this.uiManager.emit('l->r:shift-move:change', { shiftNum: v.shiftNum });
  };

  initialize() {
    this.handlerKeyPress = (e) => this.keyHandler(e);
    window.addEventListener('keydown', this.handlerKeyPress);
    this.uiManager.on('l->r:shift-move:push', this.changeShiftNum);
    this.uiManager.on('l->r:shift-move:get', this.onShiftNumGet);
    this.uiManager.on('r->l:stop-keyboard-event', this.changeKeyBoardEvent);
    this.uiManager.on('l->r:shortcut:change-operation-mode-reset', (v) => {
      this.isSelectMode = v.isSelect;
    });
    this.uiManager.on('r->l:shortcut:trim-flag-reset', (v) => {
      this.isTrimMenu = v.isTrimMenu;
    });
  }

  destroy(): void {
    window.removeEventListener('keydown', this.handlerKeyPress);
    this.uiManager.off('l->r:shift-move:push', this.changeShiftNum);
    this.uiManager.off('l->r:shift-move:get', this.onShiftNumGet);
    this.uiManager.off('r->l:stop-keyboard-event', this.changeKeyBoardEvent);
    this.uiManager.off('l->r:shortcut:change-operation-mode-reset');
  }

  di(uiManager: UiManager): void {
    this.uiManager = uiManager;
  }

  private changeKeyBoardEvent = (e: boolean) => {
    this.isStopEvent = e;
  };

  private keyHandler(e: KeyboardEvent) {
    if (e.ctrlKey) {
      if (e.key === 'z') {
        e.preventDefault();
        this.uiManager.emit('r->l:undo');
      }
      if (e.key === 'y') {
        e.preventDefault();
        this.uiManager.emit('r->l:redo');
      }
      if (e.key === 'n') {
        e.preventDefault();
        this.uiManager.emit('r->l:shortcut:change-page-num');
      }
      if (e.key === 'q') {
        e.preventDefault();
        this.uiManager.emit('r->l:shortcut:design-cancel');
      }
      if (e.key === 't') {
        e.preventDefault();
        this.uiManager.emit('r->l:shortcut:show-temp-update');
      }
      if (e.key === 's') {
        e.preventDefault();
        this.uiManager.emit('r->l:shortcut:save-layout:focus-out');
        this.uiManager.emit('r->l:shortcut:save-layout');
      }
      if (e.key === 'p') {
        e.preventDefault();
        this.uiManager.emit('r->l:shortcut:add-image-png');
      }
      // 表示サイズ変更
      if (e.key === ';') {
        e.preventDefault();
        this.uiManager.emit('r->l:zoom:lv:up', {});
      }
      if (e.key === '+') {
        e.preventDefault();
        this.uiManager.emit('r->l:zoom:lv:up', {});
      }
      if (e.key === '-') {
        e.preventDefault();
        this.uiManager.emit('r->l:zoom:lv:down', {});
      }
      if (e.key === '7') {
        e.preventDefault();
        this.uiManager.emit('r->l:zoom', { lv: 6 });
      }
      if (e.key === '6') {
        e.preventDefault();
        this.uiManager.emit('r->l:zoom', { lv: 5 });
      }
      if (e.key === '5') {
        e.preventDefault();
        this.uiManager.emit('r->l:zoom', { lv: 4 });
      }
      if (e.key === '4') {
        e.preventDefault();
        this.uiManager.emit('r->l:zoom', { lv: 3 });
      }
      if (e.key === '3') {
        e.preventDefault();
        this.uiManager.emit('r->l:zoom', { lv: 2 });
      }
      if (e.key === '2') {
        e.preventDefault();
        this.uiManager.emit('r->l:zoom', { lv: 1 });
      }
      if (e.key === '1') {
        e.preventDefault();
        this.uiManager.emit('r->l:zoom', { lv: 0 });
      }

      // 写真編集モード中の回転
      if (store.getState().layout.page === 'edit' && !this.isStopEvent) {
        // 写真編集モード中移動と拡縮
        if (e.key === 'ArrowUp') {
          e.preventDefault();
          this.uiManager.emit('r->l:resize', { scale: +0.01 });
        }
        if (e.key === 'ArrowDown') {
          e.preventDefault();
          this.uiManager.emit('r->l:resize', { scale: -0.01 });
        }
        if (e.key === 'ArrowRight') {
          e.preventDefault();
          this.uiManager.emit('r->l:rotate', { angle: +1, type: 'image' });
        }
        if (e.key === 'ArrowLeft') {
          e.preventDefault();
          this.uiManager.emit('r->l:rotate', { angle: -1, type: 'image' });
        }
        if (e.shiftKey) {
          // 写真編集モード中移動と拡縮
          if (e.key === 'ArrowUp') {
            e.preventDefault();
            this.uiManager.emit('r->l:resize', { scale: +(0.01 * this.shiftNum) });
          }
          if (e.key === 'ArrowDown') {
            e.preventDefault();
            this.uiManager.emit('r->l:resize', { scale: -(0.01 * this.shiftNum) });
          }
          if (store.getState().layout.page === 'edit' && !this.isStopEvent) {
            if (e.key === 'ArrowRight') {
              e.preventDefault();
              this.uiManager.emit('r->l:rotate', { angle: +this.shiftNum, type: 'image' });
            }
            if (e.key === 'ArrowLeft') {
              e.preventDefault();
              this.uiManager.emit('r->l:rotate', { angle: -this.shiftNum, type: 'image' });
            }
          }
        }
      }
    } else if (e.metaKey) {
      // 写真編集モード中の回転
      if (store.getState().layout.page === 'edit' && !this.isStopEvent) {
        // 写真編集モード中移動と拡縮
        if (e.key === 'ArrowUp') {
          e.preventDefault();
          this.uiManager.emit('r->l:resize', { scale: +0.01 });
        }
        if (e.key === 'ArrowDown') {
          e.preventDefault();
          this.uiManager.emit('r->l:resize', { scale: -0.01 });
        }
        if (e.key === 'ArrowRight') {
          e.preventDefault();
          this.uiManager.emit('r->l:rotate', { angle: +1, type: 'image' });
        }
        if (e.key === 'ArrowLeft') {
          e.preventDefault();
          this.uiManager.emit('r->l:rotate', { angle: -1, type: 'image' });
        }
        if (e.shiftKey) {
          // 写真編集モード中移動と拡縮
          if (e.key === 'ArrowUp') {
            e.preventDefault();
            this.uiManager.emit('r->l:resize', { scale: +(0.01 * this.shiftNum) });
          }
          if (e.key === 'ArrowDown') {
            e.preventDefault();
            this.uiManager.emit('r->l:resize', { scale: -(0.01 * this.shiftNum) });
          }
          if (store.getState().layout.page === 'edit' && !this.isStopEvent) {
            if (e.key === 'ArrowRight') {
              e.preventDefault();
              this.uiManager.emit('r->l:rotate', { angle: +this.shiftNum, type: 'image' });
            }
            if (e.key === 'ArrowLeft') {
              e.preventDefault();
              this.uiManager.emit('r->l:rotate', { angle: -this.shiftNum, type: 'image' });
            }
          }
        }
      }
    } else if (e.shiftKey) {
      if (e.key === 'ArrowUp') {
        e.preventDefault();
        this.uiManager.emit('r->l:shift-move', { x: 0, y: -(this.shiftNum), type: 'image' });
      }
      if (e.key === 'ArrowRight') {
        e.preventDefault();
        this.uiManager.emit('r->l:shift-move', { x: this.shiftNum, y: 0, type: 'image' });
      }
      if (e.key === 'ArrowDown') {
        e.preventDefault();
        this.uiManager.emit('r->l:shift-move', { x: 0, y: this.shiftNum, type: 'image' });
      }
      if (e.key === 'ArrowLeft') {
        e.preventDefault();
        this.uiManager.emit('r->l:shift-move', { x: -(this.shiftNum), y: 0, type: 'image' });
      }

      // 写真の拡縮率5%
      if (e.keyCode === 187) {
        e.preventDefault();
        this.uiManager.emit('r->l:resize', { scale: +(0.01 * this.shiftNum) });
      }
      if (e.keyCode === 189) {
        e.preventDefault();
        this.uiManager.emit('r->l:resize', { scale: -(0.01 * this.shiftNum) });
      }
    }  else if (store.getState().layout.page === 'edit' && !this.isStopEvent) {
      // 写真枠・写真の編集モード中移動
      if (e.key === ' ') {
        e.preventDefault();
        if (e.ctrlKey || e.shiftKey) return;
        const mode = this.isSelectMode ? 'select' : 'pan-zoom';
        this.uiManager.emit('r->l:shortcut:change-operation-mode', { mode });
        this.isSelectMode = !this.isSelectMode;
      }
      if (e.key === 'F1') {
        e.preventDefault();
        this.uiManager.emit('r->l:shortcut:trim:start');
      }
      if (e.key === 'F2') {
        e.preventDefault();
        this.uiManager.emit('r->l:shortcut:show-trim-line');
      }
      if (e.key === 'F4') {
        e.preventDefault();
        this.uiManager.emit('r->l:shortcut:show-guide-line');
      }
      if (e.key === 'F5') {
        e.preventDefault();
      }
      if (e.key === 'F6') {
        e.preventDefault();
      }
      if (e.key === 'F7') {
        e.preventDefault();
        const isOpen = !this.isTrimMenu;
        if (isOpen) {
          this.uiManager.emit('r->l:shortcut:open-trim-menu');
        } else {
          this.uiManager.emit('r->l:shortcut:close-trim-menu');
        }
      }
      if (e.key === 'Tab') {
        e.preventDefault();
        this.uiManager.emit('r->l:shortcut:change-middle-frame', {
          id: this.middleFrame, callback: (num) => {
            this.middleFrame = num;
          },
        });
      }
      if (e.key === 'ArrowUp') {
        e.preventDefault();
        if (e.ctrlKey || e.shiftKey) return;
        this.uiManager.emit('r->l:shift-move', { x: 0, y: -1 });
      }
      if (e.key === 'ArrowRight') {
        e.preventDefault();
        if (e.ctrlKey || e.shiftKey) return;
        this.uiManager.emit('r->l:shift-move', { x: +1, y: 0 });
      }
      if (e.key === 'ArrowDown') {
        e.preventDefault();
        if (e.ctrlKey || e.shiftKey) return;
        this.uiManager.emit('r->l:shift-move', { x: 0, y: +1 });
      }
      if (e.key === 'ArrowLeft') {
        e.preventDefault();
        if (e.ctrlKey || e.shiftKey) return;
        this.uiManager.emit('r->l:shift-move', { x: -1, y: 0 });
      }
      if (e.key === 'Enter') {
        e.preventDefault();
        this.uiManager.emit('l->r:dis-select');
        this.uiManager.emit('r->l:dis-select');
      }

      // 写真の拡縮率1%
      if (e.keyCode === 187) {
        e.preventDefault();
        this.uiManager.emit('r->l:resize', { scale: +0.01 });
      }
      if (e.keyCode === 189) {
        e.preventDefault();
        this.uiManager.emit('r->l:resize', { scale: -0.01 });
      }
    }
  }
}
