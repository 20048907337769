
import { useEffect, useRef, useState } from 'react';

type Control = {
  start: () => void;
  stop: () => void;
};

type State = 'RUNNING' | 'STOPPED';

type Fnc = () => void;

/**
 * 拾い物のsetIntervalHook
 * refを用いているのでunmount時にしかclearIntervalされないのが強み
 * @param fnc 実行する関数
 * @param interval 秒数
 * @param autostart 自動でスタートするかを設定(デフォはON)
 */
export const useInterval = (fnc: Fnc, interval: number, autostart = true): [State, Control] => {
  const onUpdateRef = useRef<Fnc>();
  const [state, setState] = useState<State>('STOPPED');
  const start = () => {
    setState('RUNNING');
  };
  const stop = () => {
    setState('STOPPED');
  };
  useEffect(() => {
    onUpdateRef.current = fnc;
  }, [fnc]);
  useEffect(() => {
    if (autostart) {
      setState('RUNNING');
    }
  }, [autostart]);
  useEffect(() => {
    let timerId: NodeJS.Timeout | undefined;
    if (state === 'RUNNING') {
      timerId = setInterval(() => {
        onUpdateRef.current?.();
      }, interval);
    } else {
      timerId && clearInterval(timerId);
    }
    return () => {
      timerId && clearInterval(timerId);
    };
  }, [interval, state]);
  return [state, { start, stop }];
};

export default useInterval;
