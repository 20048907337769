import { DetailedHTMLProps, TableHTMLAttributes, useEffect, useState } from 'react';
import { DisplayElement } from '../../../models/display-element';
import './table.scss';
import { useAppSelector } from '../../../app/hooks';
import * as lodash from 'lodash';

type TableProps = {
  // - headリスト -
  head: DisplayElement[],
  // - bodyリスト -
  body: DisplayElement[][],
  // - 塗りつぶし行 -
  selected?: number | number[],
  // - 塗りつぶし時ハンドラ -
  handlerSelect?: (index: number) => void,
  // - 最低行数 -
  minimumNumOfRows?: number,
  // - 差し込み要素 -
  insertPage?: {
    index: number,
    element: JSX.Element,
  }[],
  // - TableコンポネントのonClickを発火させたくないbodyのindex -
  excludedIndex?: number,
  // - テーブル行のホバー時カーソルをポインターにするか -
  cursorPointer?: boolean,
  // - 非表示列 表示したくないけどデータは必要な場合 -
  hiddenColumn?: number[],
  // - ヘッダー項目押下ハンドラ -
  handlerClickColumn?: (index: number, v: DisplayElement) => void,
  // - ダブルクリックハンドラ -
  handlerDblClick?: (index: number, obj: DisplayElement[]) => void,
} & DetailedHTMLProps<TableHTMLAttributes<HTMLTableElement>, HTMLTableElement>;

/* パフォーマンス向上のために致し方なしにここだけ store と紐付ける */
const InsertDataTr = (props: { col: number, b: DisplayElement, index: number }) => {
  const { selectedIndexArr } = useAppSelector((state) => ({
    selectedIndexArr: state.orderPreparation.selectedOrderIndexArr,
  }), lodash.isEqual);
  const { col, b, index } = props;
  const [display, setDisplay] = useState(Boolean(selectedIndexArr.find((v) => v === index)));
  useEffect(() => {
    setDisplay(Boolean(selectedIndexArr.find((v) => v === index)));
  }, [selectedIndexArr]);
  return (
    <>
      <tr
        style={{ height: 'auto' }}
      >
        <td
          colSpan={col}
          style={{ height: 'auto', display: display ? '' : 'none' }}
          className={'insert_data'}
        >
          {b}
        </td>
      </tr>
      <tr style={{ display: 'none' }} />
    </>
  );
};

export const Table = (props: TableProps) => {
  const {
    head,
    body: _body,
    selected: _selected,
    handlerSelect,
    minimumNumOfRows,
    insertPage,
    className,
    excludedIndex,
    cursorPointer,
    hiddenColumn,
    handlerClickColumn,
    handlerDblClick,
    ...defaultProps
  } = props;

  // - テーブルbodyリスト -
  const body = minimumNumOfRows && minimumNumOfRows > _body.length ? ([...new Array(minimumNumOfRows)].map((_, i) => [...(_body[i] || [])])) : [..._body];
  // - 差し込み -
  if (insertPage) {
    insertPage.forEach((v) => {
      body.splice(v.index, 0, [v.element]);
    });
  }
  const selected = _selected !== undefined ? (Array.isArray(_selected) ? _selected : [_selected]) : [];

  // - 着色用ダミー<td>配列 -
  const dummyTdArray = new Array(head.length).fill(null);
  return (
    <div className={`table ${className || ''}`}>
      <table
        {...defaultProps}
      >
        <thead>
        <tr>
          {head.map((v, i) => (
            <th
              key={`table-head_${i}`}
              className={`table__head_${i}`}
              onClick={() => {
                if (handlerClickColumn) {
                  handlerClickColumn(i, v);
                }
              }}
            >
              {v}
            </th>
          ))}
        </tr>
        </thead>
        <tbody>
        {body.map((b, i) => (
          insertPage?.find((v) => v.index === i) ? (
            <InsertDataTr
              key={`table-body_insert_${i}`}
              col={head.length}
              b={b[0]}
              index={i}
            />
          ) : <tr
            key={insertPage ? insertPage.find((v) => v.index === i) ? `table-body_insert_${i}` : insertPage.find((v) => v.index < i) ? `table-body_${i - (() => {
              let cnt = 0;
              insertPage.forEach((v) => {
                if (v.index < i) {
                  cnt++;
                }
              });
              return cnt;
            })()}` : `table-body_${i}` : `table-body_${i}`}
            // className={`${cursorPointer && b.length && !insertPage?.find((v) => v.index === i) ? 'clickable' : ''}${selected.find((v, index) => v + index === i ) !== undefined ? ' selected_row' : ''}`}
            className={`${cursorPointer && b.length && !insertPage?.find((v) => v.index === i) ? 'clickable' : ''}${selected.find((v, index) => v + (insertPage ? v : index) === i) !== undefined ? ' selected_row' : ''}`}
            onDoubleClick={() => handlerDblClick?.(i, b)}
            style={insertPage?.find((v) => v.index === i) ? { height: 'auto' } : {}}
          >
            {b.length ?
              b.map((d, j) => (
                <td
                  key={`table-data_${j}`}
                  colSpan={insertPage ? insertPage.find((v) => v.index === i) ? head.length : 1 : 1}
                  className={`table__data_${j} ${insertPage ? insertPage.find((v) => v.index === i) ? 'insert_data' : '' : ''}`}
                  hidden={hiddenColumn?.find((v) => v === j) !== undefined}
                  onClick={() => {
                    if (excludedIndex !== j) {
                      if (insertPage?.find((v) => v.index === i)) {
                        return;
                      }
                      if (handlerSelect) {
                        handlerSelect(insertPage ? insertPage.find((v) => v.index < i) ? i - (() => {
                          let cnt = 0;
                          insertPage.forEach((v) => {
                            if (v.index < i) {
                              cnt++;
                            }
                          });
                          return cnt;
                        })() : i : i);
                      }
                    }
                  }}
                >
                  {d}
                </td>
              )) : dummyTdArray.map((v, i) => (
                <td key={`dummy-td_${i}`} /> /*  空の <tr> にも着色させるため */
              ))
            }
          </tr>
        ))}
        </tbody>
      </table>
    </div>
  );
};
