import { ApiBaseFront } from '../../front/api-base-front';

type Params = {
  kijshopCd: string,
  orderId: string,
  path: string,
};

export class ApiGetCloudFolderFileDownload extends ApiBaseFront {
  constructor(_param: Params) {
    super(
      'GET',
      `/api/v1/c-folder/shop/${_param.kijshopCd}/image/download`,
      'BLOB',
      {
        path: _param.path,
      },
    );
  }
}
