/* 画像アップロードの採番対策に後から作成したため内部的な汎用性をある程度捨てて専用に設置 */
export class SelectIdQueueManager {

  private queue: Promise<void>[] = [];
  private static _ins: SelectIdQueueManager;

  private constructor() {
  }

  static get ins() {
    if (SelectIdQueueManager._ins) {
      return SelectIdQueueManager._ins;
    }
    SelectIdQueueManager._ins = new SelectIdQueueManager();
    return SelectIdQueueManager._ins;
  }

  /**
   * enqueueとdequeueを行いつつ処理を実行する
   * @param action 登録する処理
   */
  async do(action: () => Promise<void>) {
    const prm = (async (): Promise<void> => {
      if (this.queue.length) {
        await this.queue[this.queue.length - 1];
      }
      return await action();
    })();
    this.queue.push(prm);
    const result = await prm;
    this.queue.splice(0, 1);
    return result;
  }

  /**
   * 裁判処理が終わっていない画像数を取得
   */
  get getUnRegisteredCount() {
    return this.queue.length;
  }

}
