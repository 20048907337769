const DefaultEnvJson: EnvJson = {
  version: '',
  'build-no': '',
  mode: 'develop',
  config: {
    webfontTimeoutMs: 10000,
  },
  api: {
    front: {
      protocol: '',
      host: '',
    },
    back: {
      protocol: '',
      host: '',
    },
    font: {
      protocol: '',
      host: '',
    },
    image: {
      protocol: '',
      host: '',
    },
    cf: {
      protocol: '',
      host: '',
    },
  },
  admin: {
    order: '',
  },
};

interface EnvJson {
  version: string,
  'build-no': string,
  mode: 'develop' | 'staging' | 'product',
  config: {
    webfontTimeoutMs: number,
  },
  api: {
    front: {
      protocol: string,
      host: string,
    },
    back: {
      protocol: string,
      host: string,
    },
    font: {
      protocol: string,
      host: string,
    },
    image: {
      protocol: string,
      host: string,
    },
    cf: {
      protocol: string,
      host: string,
    }
  },
  admin: {
    order: string,
  },
}

class EnvClass implements EnvJson {

  version: string;
  'build-no': string;
  mode: 'develop' | 'staging' | 'product';
  config: { webfontTimeoutMs: number };
  api: {
    front: { protocol: string; host: string },
    back: { protocol: string; host: string },
    font: { protocol: string; host: string },
    image: { protocol: string; host: string },
    cf: { protocol: string; host: string },
  };
  admin: {
    order: string,
  };

  constructor() {
    this.version = DefaultEnvJson.version;
    this['build-no'] = DefaultEnvJson['build-no'];
    this.mode = DefaultEnvJson.mode;
    this.config = DefaultEnvJson.config;
    this.api = DefaultEnvJson.api;
    this.admin = DefaultEnvJson.admin;
  }

  setJson(json: any) {
    const keys = window.Object.keys(this);
    keys.forEach((key) => {
      if (!json.hasOwnProperty(key)) {
        throw new Error(`env.json に ${key} が定義されていません !!`);
      }
      this[key as keyof this] = json[key];
    });
  }

}

export const Env = new EnvClass();

export type Env = typeof Env;
