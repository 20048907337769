import { useCallback, useState } from 'react';
import { InfoXml } from '../../xml/class/info-xml';
import { CustomerXml } from '../../xml/class/customer-xml';
import { DeliveryXml } from '../../xml/class/delivery-xml';
import { ShopXml } from '../../xml/class/shop-xml';
import { StatusXml } from '../../xml/class/status-xml';
import { SummaryXml } from '../../xml/class/summary-xml';
import { OrderInfoDataXml } from '../../xml/class/order/order-info-data-xml';
import { OrderInfoXml } from '../../xml/class/order/order-info-xml';
import { OrderPageBreakXml } from '../../xml/class/order/order-page-break-xml';
import { OrderPageDataXml } from '../../xml/class/order/order-page-data-xml';
import { OrderPageXml } from '../../xml/class/order/order-page-xml';
import { OrderPartsDataXml } from '../../xml/class/order/order-parts-data-xml';
import { OrderPartsXml } from '../../xml/class/order/order-parts-xml';
import { OrderSelectXml } from '../../xml/class/order/order-select-xml';
import { Button } from '../ui/button/button';
import { TextArea } from '../ui/textarea/textarea';
import { XmlClass } from '../../xml/class/xml-class';
import { Input } from '../ui/input/input';

const shopOrderId = '0299999000000';

const version = '3.9.2';

const xml = [
  new InfoXml(shopOrderId, { version }),
  new CustomerXml(shopOrderId, { version }),
  new DeliveryXml(shopOrderId, { version }),
  new ShopXml(shopOrderId, { version }),
  new StatusXml(shopOrderId, { version }),
  new OrderSelectXml(shopOrderId, { version }),
  new OrderInfoDataXml(shopOrderId, { version, indexes: [1] }),
  new OrderInfoXml(shopOrderId, { version }),
  new OrderPageBreakXml(shopOrderId, { version, indexes: [1] }),
  new OrderPageDataXml(shopOrderId, { version, indexes: [1, 1, 1] }),
  new OrderPageXml(shopOrderId, { version, indexes: [1, 1] }),
  new OrderPartsDataXml(shopOrderId, { version, indexes: [1, 1] }),
  new OrderPartsXml(shopOrderId, { version, indexes: [1] }),
  new SummaryXml(shopOrderId, { version, indexes: [1, 1] }),
];

export const DebugXml = () => {
  const [inputValue, setInputValue] = useState('');
  const [outputValue, setOutputValue] = useState('');
  const [viewModel, setViewModel] = useState('');
  const [metaModel, setMetaModel] = useState('');
  const [currentXml, setCurrentXml] = useState<XmlClass<any>>(xml[0]);
  const handlerChangeXml = useCallback((v: XmlClass<any>) => {
    setCurrentXml(v);
    setInputValue(v.xml);
    setFileName(v.name);
  }, []);
  const handlerClickConvert = useCallback(() => {
    if (currentXml.name === 'summary') {
      (currentXml as SummaryXml).di({
        info: xml[0] as InfoXml,
        customer: xml[1] as CustomerXml,
        delivery: xml[2] as DeliveryXml,
        shop: xml[3] as ShopXml,
        status: xml[4] as StatusXml,
        orderSelect: xml[5] as OrderSelectXml,
        // orderInfoDataArr: [{
        //   data: xml[6] as OrderInfoDataXml,
        //   orderPartsDataArr: [{
        //     data: xml[11] as OrderPartsDataXml,
        //     orderPage: xml[10] as OrderPageXml,
        //     orderPageData: [xml[9]] as OrderPageDataXml[],
        //   }],
        // }],
      });
    }
    currentXml
      .parse(inputValue)
      .then(() => {
        currentXml.build();
        setOutputValue(currentXml.xml);
        setViewModel(JSON.stringify(currentXml.viewModel || {}));
        setMetaModel(JSON.stringify(currentXml.metaModel || {}));
      });
  }, [inputValue, currentXml]);
  const handlerClickBuild = useCallback(() => {
    try {
      const view = JSON.parse(viewModel);
      const meta = JSON.parse(metaModel);
      currentXml.build(view, meta);
      setOutputValue(currentXml.xml);
    } catch (e) {
      console.error('parse error!!');
    }
  }, [viewModel, metaModel, currentXml]);
  const [fileName, setFileName] = useState<string>(xml[0].name);
  const handlerClickDownload = useCallback(() => {
    const blob = new Blob([outputValue], { type: 'application/xml' });
    const a = document.createElement('a');
    a.href = URL.createObjectURL(blob);
    a.download = `${fileName}.xml`;
    a.click();
  }, [outputValue, fileName]);
  return (
    <div
      style={{ display: 'flex' }}
    >
      <div
        style={{
          width: '15%',
        }}
      >
        {xml.map((v, i) => (
          <div
            key={`debug-xml-parse_${v.name}_${i}`}
            style={{
              marginTop: '10px',
              marginLeft: '10px',
            }}
          >
            <Button
              label={v.name}
              onClick={() => handlerChangeXml(v)}
              style={{
                opacity: currentXml.name === v.name ? '0.5' : '1',
              }}
            />
          </div>
        ))}
      </div>

      <div
        style={{
          width: '80%',
          marginTop: '10px',
        }}
      >
        <div>
          <TextArea
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            style={{
              height: '300px',
            }}
          />
        </div>
        <div
          style={{
            marginTop: '5px',
            marginBottom: '5px',
            display: 'flex',
          }}
        >
          <div>
            <Button
              label="parse → build"
              onClick={handlerClickConvert}
            />
          </div>
          <div
            style={{
              display: 'flex',
              marginTop: '4px',
              marginLeft: '20px',
            }}
          >
            <Input
              value={fileName}
              onChange={(e) => setFileName(e.target.value)}
              style={{
                display: 'flex',
              }}
            />
            <div
              style={{
                display: 'flex',
                marginTop: '10px',
              }}
            >
              .xml
            </div>
          </div>
          <div
            style={{
              marginTop: '2px',
              marginLeft: '5px',
            }}
          >
            <Button
              label="download"
              onClick={handlerClickDownload}
              disabled={!fileName || !outputValue}
            />
          </div>
        </div>
        <div>
          <TextArea
            value={outputValue}
            onChange={() => {}}
            style={{
              height: '300px',
            }}
          />
        </div>
        <div
          style={{
            marginTop: '10px',
          }}
        >
          Model
          <div
            style={{
              display: 'flex',
              marginTop: '5px',
            }}
          >
            <div
              style={{
                width: '40%',
                height: '300px',
                marginLeft: '5px',
              }}
            >
              ViewModel
              <TextArea
                value={viewModel}
                onChange={(e) => setViewModel(e.target.value)}
                style={{
                  height: '300px',
                }}
              />
            </div>
            <div
              style={{
                width: '40%',
                marginLeft: '5px',
              }}
            >
              MetaModel
              <TextArea
                value={metaModel}
                onChange={(e) => setMetaModel(e.target.value)}
                style={{
                  height: '300px',
                }}
              />
            </div>
          </div>
          <div
            style={{
              marginTop: '5px',
              marginLeft: '5px',
            }}
          >
            <Button
              label="build"
              onClick={handlerClickBuild}
            />
          </div>
        </div>
      </div>

    </div>
  );
};
