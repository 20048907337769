import {DetailedHTMLProps, InputHTMLAttributes, useEffect} from 'react';
import './radiobutton.scss';

type RadiobuttonProps = {
  // - ラベル -
  label?: string,
  checkState?: string,
} & DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

export const Radiobutton = (props: RadiobuttonProps) => {
  const {label, checkState, ...defaultProps} = props;

  return (
  // return label ? (
    <div
      onClick={(e) => {
        (e as any).target.value = defaultProps.value;
        defaultProps.onClick?.(e as any);
        defaultProps.onChange?.(e as any);
      }}
      style={{cursor: 'default', ...defaultProps.style}}
      className={`radio ${defaultProps.className || ''} ${defaultProps.disabled ? 'disabled' : ''}`}
      tabIndex={-1}
      onFocus={defaultProps.onFocus}
      onBlur={defaultProps.onBlur}
      onMouseEnter={defaultProps.onMouseEnter}
      onMouseLeave={defaultProps.onMouseLeave}
    >
      <label className={checkState ? checkState === defaultProps.value ? 'checked' : '' : ''}>
        <input
          {...defaultProps}
          type="radio"
          checked={checkState ? checkState === defaultProps.value : defaultProps.checked}
          className="radio__form"
          onChange={() => {}}
        />
        {label && label}
      </label>
    </div>
  )
  // : (
  //   <input
  //     {...defaultProps}
  //     type="radio"
  //     checked={checkState ? checkState === defaultProps.value : defaultProps.checked}
  //     className="radio__form"
  //   />
  // );
};
