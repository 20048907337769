import {ApiBaseBack} from "../api-base-back";
import {NumberingOrderResponse} from "../../../models/api/back/numbering-order";

export class ApiNumberingOrder extends ApiBaseBack<NumberingOrderResponse> {
  constructor(param: {}) {
    super(
      'GET',
      '/api/v1/latestOrderNo',
      'JSON',
      param,
    );
  }
}