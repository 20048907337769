import React, { useCallback } from 'react';
import { Button } from '../../ui/button/button';

export const TABLE_FILTER = {
  ALL: 'all',
  NOT_ORDERED: 'not-ordered',
  PROCESSING: 'processing',
  PROCESSED: 'processed',
  COMPLETED: 'completed',
  NOT_COMPLETED: 'not-completed',
} as const;

export type CloudFolderTableFilter = typeof TABLE_FILTER[keyof typeof TABLE_FILTER];

const FilterType = [
  { value: TABLE_FILTER.ALL, label: 'すべて' },
  { value: TABLE_FILTER.NOT_ORDERED, label: '未発注' },
  { value: TABLE_FILTER.PROCESSING, label: 'ラボ作業中' },
  { value: TABLE_FILTER.PROCESSED, label: '納品データ' },
  { value: TABLE_FILTER.COMPLETED, label: '完了' },
  { value: TABLE_FILTER.NOT_COMPLETED, label: '完了以外' },
];

type Props = {
  selectFilter: CloudFolderTableFilter,
  setStatusFilter: (type: CloudFolderTableFilter) => void,
  handlerClickSearch: () => void,
}

export const StatusFilter = (props: Props) => {
  const { selectFilter, setStatusFilter, handlerClickSearch } = props;

  const handlerChangeStatusFilter = useCallback((value: CloudFolderTableFilter) => {
    setStatusFilter(value);
  }, [setStatusFilter]);

  return (
    <>
      <div className={'status_filter'}>
        {
          FilterType.map((filter) => (
            <div
              key={`cloud-folder-filter-${filter.value}`}
              className={'status_filter__content' + (filter.value === selectFilter ? ' status_filter__select' : '')}
              onClick={() => handlerChangeStatusFilter(filter.value)}
            >
              {filter.label}
            </div>
          ))
        }
        <div
          className={'status_filter__search_btn'}
        >
          <Button
            label="詳細の検索"
            onClick={handlerClickSearch}
            icon={<i className="fas fa-search" />}
            className="search_detail"
          />
        </div>
      </div>
    </>
  );
};
