import { XmlFactory } from "../../factory/xml-factory";
import { TOrderInfoDataViewModel } from "../order/xml-order-info-data-model";
import { XmlStructureModel, XmlStructureOrderInfoData, XmlStructureOrderParts, XmlStructureOrderPartsData, XmlStructureOrderPage } from '../xml-structure-model';
import { cloneDeep } from 'lodash';
import { OrderInfoDataXml } from "../../class/order/order-info-data-xml";
import { OrderPartsDataXml } from "../../class/order/order-parts-data-xml";
import { OrderPartsXml } from "../../class/order/order-parts-xml";
import { ChildRetouchInfo } from "../../../components/pages/retouch/footer/retouch.footer";
import { OrderPageDataXml } from '../../class/order/order-page-data-xml';
import { SimpleRetouchInfo } from "../../../api/back/retouch/api-retouch";
import { OrderPageXml } from '../../class/order/order-page-xml';
import { XmlClass } from '../../class/xml-class';

export const DefaultRetouchView = (infoOutputCount: string):Partial<TOrderInfoDataViewModel> => ({
  item: {
    id: 'jptg300179',
    name: 'レタッチ'
  },
  deliveryData: '1',
  infoOutputCount,
  goods: {
    goodsId: 'jptg300179',
    name: 'レタッチ',
    shortName: 'ﾚﾀｯﾁ',
    productType: 'jptg300180'
  },
  category: {
    id: 'jp0379',
    name: 'レタッチ'
  },
  surrogateProcess: {
    id: '2010',
    name: 'レタッチ',
    data: { jptg380179: [''] },
  },
  executeProcess: {
    jptg380179: [''],
  },
});

type Param = {
    infoOutputCount: string;
    kijshopCd: string;
    shopOrderId: string;
    structure: XmlStructureModel;
    infoData?: XmlStructureOrderInfoData[];
    checkList: ChildRetouchInfo;
}

type ChildParam = {
  info: OrderInfoDataXml;
  parts: OrderPartsXml;
  partsData: OrderPartsDataXml[];
  page: OrderPageXml;
  pageData: OrderPageDataXml[];
}

export class XMLRetouchModel {
  public pushFiles: {
    filename: string,
    body: string,
    is_create: '0' | '1',
  }[] = [];
  public deleteFiles: XmlClass<any>[] = [];
  public lastIndex: number = 0;
  structure!: XmlStructureModel;
  private partsData!: OrderPartsDataXml;
  private parts!: OrderPartsXml;
  private info!: XmlStructureOrderInfoData;
  private child: ChildParam[] = [];

  constructor (private param: Param) {
    this.deleteFiles = this.createDeleteRetouchFiles();
    this.structure = this.organizeStructure();
    this.lastIndex = Math.max(...(this.structure.orderInfo?.infoData?.map((v) => Number(v.xml.metaModel.id) || 0) ?? [0])) + 1;
    if (param.checkList.length) {
      this.partsData = this.createPartsData();
      this.partsData.build();
      this.parts = this.createParts();
      this.parts.build();
      this.info = this.createInfo();
      this.info.xml.build();
      this.structure.orderInfo?.xml.build();
      this.child = this.createChild();
      this.createStructure();
      this.structure.orderInfo?.xml.build();
      this.createPushFiles();
    } else {
      /* 削除時に orderInfo の更新が必要なため pushFiles に push する（更新は別途行われている） */
      const orderInfo = this.structure.orderInfo?.xml;
      if (orderInfo) {
        this.pushFiles.push({
          filename: orderInfo.xmlUniqueName,
          body: orderInfo.xml,
          is_create: '0',
        });
      }
    }
  }


  private createDeleteRetouchFiles = () => {
    const delFiles: XmlClass<any>[] = [];
    const filter = this.param.infoData?.filter((v) => (v.xml.rootTagModel.contentGroupID === 'orderRetouch')) ?? [];
    delFiles.push(...filter.map((v) => v.xml));
    for (const info of filter) {
      if (info.parts) delFiles.push(info.parts.xml);
      for (const partsData of info.parts?.partsData ?? []) {
        delFiles.push(partsData.xml);
        if (partsData.page) delFiles.push(partsData.page.xml);
        for (const page of partsData.page?.pageData ?? []) {
          delFiles.push(page);
        }
      } 
    }
    return cloneDeep(delFiles);
  }
  
  private createDeleteFiles = (origin: XmlStructureModel) => {
    const structure = cloneDeep(origin);
    if (!structure.orderInfo?.infoData) return;
    structure.orderInfo.infoData.forEach((v, i) => {
      if (v.xml.metaModel.id !== `${i + 1}`) {
        this.deleteFiles.push(v.xml);
        if (v.pageBreak) this.deleteFiles.push(v.pageBreak);
        if (v.parts) this.deleteFiles.push(v.parts.xml);
        v.parts?.partsData?.forEach((v2) => {
          this.deleteFiles.push(v2.xml);
          if (v2.page) this.deleteFiles.push(v2.page.xml);
          v2.page?.pageData?.forEach((v3) => {
            this.deleteFiles.push(v3);
          })
        })
      }
    })
  }

  private createPushFiles = () => {
    this.pushFiles.push(
     {
      filename: this.info.xml.xmlUniqueName,
      body: this.info.xml.xml,
      is_create: '1',
     },
     ...[this.parts, this.partsData].map((v) => ({
        filename: v.xmlUniqueName,
        body: v.xml,
        is_create: '1' as '1',
      }))
    );
    if (this.structure.orderInfo?.infoData) {
        this.pushFiles.push({
          filename: this.structure.orderInfo.xml.xmlUniqueName,
          body: this.structure.orderInfo.xml.xml,
          is_create: '0',
        })      
    }
    if (this.child.length) {
      for (const child of this.child) {
        this.pushFiles.push({
          filename: child.info.xmlUniqueName,
          body: child.info.xml,
          is_create: '1',
        })
        this.pushFiles.push({
          filename: child.page.xmlUniqueName,
          body: child.page.xml,
          is_create: '1',
        })
        this.pushFiles.push({
          filename: child.pageData[0].xmlUniqueName,
          body: child.pageData[0].xml,
          is_create: '1',
        })
        this.pushFiles.push({
          filename: child.parts.xmlUniqueName,
          body: child.parts.xml,
          is_create: '1',
        })
        this.pushFiles.push({
          filename: child.partsData[0].xmlUniqueName,
          body: child.partsData[0].xml,
          is_create: '1',
        })
      }
    }
  }

  private organizeStructure = () => {
    const structure = cloneDeep(this.param.structure);
    if (!structure.orderInfo) return this.param.structure;
    const deleteName = this.deleteFiles.map((v) => v.xmlUniqueName);
    const deleteSequence = this.deleteFiles.map((v) => v.metaModel.id);
    if (this.deleteFiles.length) {
      structure.orderInfo.xml.metaModel.path = structure.orderInfo.xml.metaModel.path?.filter((v) => !deleteName.includes(v.path));
      structure.orderInfo.xml.metaModel.parentSequence = structure.orderInfo.xml.metaModel.parentSequence?.filter((v) => !deleteSequence.includes(v.id));
      structure.orderInfo.infoData = structure.orderInfo.infoData?.filter((v) => !deleteName.includes(v.xml.xmlUniqueName));
    }
    structure.orderInfo.xml.build();
    return this.numberingStructure(structure);
  }

  private numberingStructure = (origin: XmlStructureModel) => {
    const structure = cloneDeep(origin);
    const deleteOirigin = cloneDeep(origin);
    this.createDeleteFiles(deleteOirigin);
    if (!origin.orderInfo?.infoData) return origin;
    if (!structure.orderInfo?.infoData) return origin;
    const parentSequences: {id: string}[] = [];
    const paths: {id: string, path: string}[] = [];
    const infos: XmlStructureOrderInfoData[] = [];
    /* parentId 精査用 */
    const parentIdSetArr: {
      bf: string,
      af: string,
    }[] = [];
    /* ステータス更新用 */
    const idSetArr: {
      bf: string,
      af: string,
    }[] = [];
    // origin.orderInfo.infoData.forEach((info, i) => {
    for (let i = 0; i < origin.orderInfo.infoData.length; i++) {
      const _info = cloneDeep(origin.orderInfo.infoData[i]);

      const newIndex = `${i + 1}`;
      const infoPath: {
        orderPageBreak: string | undefined,
        orderParts: string | undefined,
      } = {
        orderPageBreak: undefined,
        orderParts: undefined,
      }
      if (_info.xml.metaModel.id !== newIndex) {
        idSetArr.push({
          bf: _info.xml.metaModel.id || '',
          af: newIndex,
        });
        if (!_info.xml.metaModel.parentId) {
          parentIdSetArr.push({
            bf: _info.xml.metaModel.id || '',
            af: newIndex,
          });
        }
        _info.xml.metaModel.id = newIndex;
        _info.xml.changeIndexes([newIndex]);
        if (_info.pageBreak) {
          _info.pageBreak.changeIndexes([newIndex]);
          _info.pageBreak.build();
          this.pushFiles.push({
            filename: _info.pageBreak.xmlUniqueName,
            body: _info.pageBreak.xml,
            is_create: '1',
          });
          infoPath.orderPageBreak = _info.pageBreak.xmlUniqueName;
        }
        if (_info.parts?.partsData) {
          const partsDataPath: {id: string, path: string}[] = []
          _info.parts.xml.changeIndexes([newIndex]);
          _info.parts.partsData.forEach((partsData, i2) => {
            const _partsData = cloneDeep(partsData);
            const childIndex = `${i2 + 1}`;
            partsData.xml.changeIndexes([newIndex, childIndex]);
            partsData.page?.xml.changeIndexes([newIndex, childIndex]);
            if (partsData.page?.pageData) {
              const pageDataPath: {id: string, path: string}[] = [];
              partsData.page.pageData.forEach((pageData, i3) => {
                pageData.changeIndexes([newIndex, childIndex, `${i3 + 1}`]);
                pageData.build();
                this.pushFiles.push({
                  filename: pageData.xmlUniqueName,
                  body: pageData.xml,
                  is_create: '1',
                })
                pageDataPath.push({id: `${i3 + 1}`, path: pageData.xmlUniqueName});
              });
              partsData.page.xml.metaModel.paths = pageDataPath;
              partsData.page.xml.build();
              this.pushFiles.push({
                filename: partsData.page.xml.xmlUniqueName,
                body: partsData.page.xml.xml,
                is_create: '1',
              })
              partsData.xml.metaModel.path = partsData.page.xml.xmlUniqueName;
              partsData.xml.build();
              this.pushFiles.push({
                filename: partsData.xml.xmlUniqueName,
                body: partsData.xml.xml,
                is_create: '1',
              })
              partsDataPath.push({id: childIndex, path: partsData.xml.xmlUniqueName});
            }
          })
          if (_info.parts) {
            _info.parts.xml.metaModel.paths = partsDataPath;
            _info.parts.xml.build();
            this.pushFiles.push({
              filename: _info.parts.xml.xmlUniqueName,
              body: _info.parts.xml.xml,
              is_create: '1',
            })
            infoPath.orderParts = _info.parts.xml.xmlUniqueName;
          }
        }
        _info.xml.metaModel.path = infoPath;
        _info.xml.build();
        this.pushFiles.push({
          filename: _info.xml.xmlUniqueName,
          body: _info.xml.xml,
          is_create: '1',
        });
      }
      paths.push({id:newIndex, path: _info.xml.xmlUniqueName});
      infos.push(_info);
      if (!_info.xml.metaModel.parentId) {
        parentSequences.push({id: _info.xml.metaModel.id || ''});
      }
    }
    /* parentId 設定 */
    infos.forEach((orderInfoData) => {
      if (orderInfoData.xml.metaModel.parentId) {
        const data = parentIdSetArr.find((v) => orderInfoData.xml.metaModel.parentId === v.bf);
        if (data) {
          orderInfoData.xml.metaModel.parentId = data.af;
          orderInfoData.xml.build();
          const index = this.pushFiles.findIndex((v) => v.filename === orderInfoData.xml.xmlUniqueName);
          if (index !== -1) {
            this.pushFiles[index].body = orderInfoData.xml.xml;
          }
        }
      }
    });
    if (idSetArr.length) {
      for (let i = idSetArr.length - 1; i >= 0; i--) {
        const data = idSetArr[i];
        const statusData = structure.status.metaModel.orderInfoData?.find((v) => v.id === data?.bf);
        if (statusData) {
          statusData.id = data.af;
        }
      }
      structure.status.build();
      this.pushFiles.push({
        filename: structure.status.xmlUniqueName,
        body: structure.status.xml,
        is_create: '0',
      });
    }
    structure.orderInfo.xml.metaModel.path = paths;
    structure.orderInfo.xml.metaModel.parentSequence = parentSequences;
    structure.orderInfo.infoData = infos;
    structure.orderInfo.xml.build();
    return structure;
  }

  private createStructure = () => {
    if (!this.structure.orderInfo?.infoData) return;
    this.structure.orderInfo.xml.metaModel.path?.push({id: this.info.xml.metaModel.id ?? '', path: this.info.xml.xmlUniqueName});
    this.structure.orderInfo.xml.metaModel.path?.push(...(this.child.map((v) => ({id: v.info.metaModel.id ?? '', path: v.info.xmlUniqueName}))));
    this.structure.orderInfo.xml.metaModel.parentSequence?.push({id: this.info.xml.metaModel.id ?? ''});
  }

  private createPartsData = () => {
    const partsData = XmlFactory.createFromIndex({
      shopOrderId: this.param.shopOrderId,
      data: { name: 'order-parts-data', indexes: [this.lastIndex, 1] },
    });
    partsData.viewModel = {
      id: '1',
      name: 'レタッチ',
      partsID: 'jpit300242',
      pageCount: '1',
    }
    
    return partsData;
  }

  private createParts = () => {
    const parts = XmlFactory.createFromIndex({
      shopOrderId: this.param.shopOrderId,
      data: {name: 'order-parts', indexes: [this.lastIndex]}
    });
    parts.viewModel = {
      id: '1',
    }

    const partsData = this.createPartsData();
    parts.metaModel = {
      paths: [
        {
          id: '1',
          path: partsData.xmlUniqueName,
        }
      ]
    }
    return parts;
  }

  private createInfo = () => {
    const _base =  XmlFactory.createFromIndex({
      shopOrderId: this.param.shopOrderId,
      data: { name: 'order-info-data', indexes: [this.lastIndex] },
    });
    _base.viewModel = DefaultRetouchView(this.param.infoOutputCount);
    _base.orderParts = this.createParts();
    _base.metaModel = {
      id: `${this.lastIndex}`,
      path: {
        orderParts: _base.orderParts.xmlUniqueName,
      },
    };
    _base.rootTagModel.contentGroupID = 'orderRetouch';

    return {
      xml: _base,
      parts: {
        xml: _base.orderParts,
        partsData: [{xml: this.createPartsData()}],
      }
  };
  }

  private createChild = () => {
    const result: ChildParam[] = this.param.checkList.map((v, i) => {
      const index = this.lastIndex + 1 + i;
      const pageData = this.createChildPageData({data: v, parentIndex: index});
      pageData.build();
      const page = this.createChildPage({index, pageDataPath: [pageData.xmlUniqueName]});
      page.build();
      const partsData = this.createChildPartsData({index, pagePath: page.xmlUniqueName, data: v});
      partsData.build();
      const parts = this.createChildParts(index, [partsData.xmlUniqueName]);
      parts.build();
      const info = this.createChildInfo({index, data: v, partsPath: parts.xmlUniqueName});
      info.build();
      return {
        info,
        parts,
        partsData: [partsData],
        page,
        pageData: [pageData],
      }
    })
    return result;
  }

  private createChildInfo = (param: {data: SimpleRetouchInfo & {selectID: string[]}, index: number, partsPath: string }) => {
    const {data, index, partsPath} = param;
    const xml = XmlFactory.createFromIndex({
      shopOrderId: this.param.shopOrderId,
      data: {name: 'order-info-data', indexes: [index]}
    });
    xml.metaModel = {
      id: `${index}`,
      parentId: `${this.info.xml.metaModel.id ?? ''}`,
      path: {
        orderParts: partsPath,
      },
    };
    xml.viewModel = {
      deliveryData: '1',
      infoOutputCount: this.param.infoOutputCount,
      item: {
        id: data.productNameId,
        name: data.productName,
      },
      goods: {
        goodsId: data.productNameId,
        name: data.productName,
        shortName: data.productShortName,
      },
    };
    xml.rootTagModel.contentGroupID = 'orderRetouch';

    return xml;
  }

  private createChildParts = (index: number, partsDataPath: string[]) => {
    const xml = XmlFactory.createFromIndex({
      shopOrderId: this.param.shopOrderId,
      data: {name: 'order-parts', indexes: [index]}
    });
    xml.viewModel = {
      id: '1',
    }
    xml.metaModel = {
      paths: partsDataPath.map((v, i) => ({id: `${i + 1}`, path: v})),
    }

    return xml;
  }

  private createChildPartsData = (param: { index: number, pagePath: string, data: SimpleRetouchInfo & {selectID: string[]} }) => {
    const { index, pagePath, data } = param;
    const xml = XmlFactory.createFromIndex({
      shopOrderId: this.param.shopOrderId,
      data: { name: 'order-parts-data', indexes: [index, '1'] },
    });
    xml.viewModel = {
      id: '1',
      partsID: data.itemOptpriceId,
      name: data.itemOptpriceName,
      pageCount: '1',
    }
    xml.metaModel = {
      path: pagePath,
    }
    return xml;
  }

  private createChildPage = (param: { index: number, pageDataPath: string[] }) => {
    const { index, pageDataPath } = param;
    const xml = XmlFactory.createFromIndex({
      shopOrderId: this.param.shopOrderId,
      data: { name: 'order-page', indexes: [index, '1'] },
    });
    xml.metaModel = {
      paths: pageDataPath.map((v, i) => ({id: `${i + 1}`, path: v})),
    }
    xml.viewModel = {
      id: '1',
    }
    return xml;
  }

  private createChildPageData = (param: { parentIndex: number, data: SimpleRetouchInfo & {selectID: string[]} }) => {
    const { parentIndex, data } = param;
    const xml = XmlFactory.createFromIndex({
      shopOrderId: this.param.shopOrderId,
      data: { name: 'order-page-data', indexes: [parentIndex, '1', '1'] },
    });
    const pageData = data.selectID.map((v) => this.createPageData(v));
    xml.viewModel = {
      id: '1',
      orderPicture: {
        // photoSeqCount: '',
        data: pageData,
      },
      pageOutputCount: '1',
    }
    return xml;
  }

  private createPageData = (selectID: string) => ({
    // contactNaiFlag: '',
    // copyFlag: '',
    // deleteFlag: '',
    // depth: '',
    // frame: '',
    // logoFlag: '',
    // mask: '',
    // maskname: '',
    // orgsubMaskID: '',
    // photoSeqNo: '',
    selectID,
    // subMaskID: '',
    // selectImage?: {
    //   height: '',
    //   width: '',
    // },
    // logoFileName: {
    //   realPath: '',
    // },
    // subMaskRect: {
    //   real: {
    //     height: '',
    //     width: '',
    //     x: '',
    //     y: '',
    //   },
    //   virtual: {
    //     height: '',
    //     width: '',
    //     x: '',
    //     y: '',
    //   },
    // },
    // subMaskRotate: '',
    // pictureRect: {
    //   real: {
    //     height: '',
    //     width: '',
    //     x: '',
    //     y: '',
    //   },
    //   virtual: {
    //     height: '',
    //     width: '',
    //     x: '',
    //     y: '',
    //   },
    // },
    // pictureRotate: '',
  });
}