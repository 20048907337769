import { ApiBaseFront } from '../api-base-front';
import { RequestMailTokenOnly } from '../../../models/api/front/staff-management';

export class ApiCheckTokenExpiry extends ApiBaseFront {
  constructor(param: RequestMailTokenOnly) {
    super(
      'POST',
      '/api/v1/mail/check_expiry_token',
      'JSON',
      param
    );
  };
}