import './c-folder-file-select.scss';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { dialogActions } from '../slice/dialog-slice';
import { Button } from '../../ui/button/button';
import { apiActions } from '../../../slices/api-slice';
import folderOpen from '../../../assets/img/icon/folder-open-black.svg';
import folderClose from '../../../assets/img/icon/folder-close-black.svg';
import {
  ApiGetCloudFolderOrderResult,
  OrderResult,
} from '../../../api/front/cloud-folder/api-get-cloud-folder-order-result';
import {
  ApiGetCloudFolderFileDetail,
  FileDetail,
} from '../../../api/front/cloud-folder/api-get-cloud-folder-file-detail';
import { ResponseBase } from '../../../api/response-base';
import { useDidMount } from '../../../hooks/life-cycle';
import { useAppDispatch } from '../../../app/hooks';
import { ApiGetCloudFolderFileDownload } from '../../../api/cf/cloud-folder/api-get-cloud-folder-file-download';
import loading from '../../ui/image/loading.gif';
import { RefObject } from 'react';
import { EditableImage } from '../../../layout-editor/manager/image-edit/editable-image';
import { UiManager } from '../../../layout-editor/manager/ui/ui.manager';
import { EditableImageManager } from '../../../layout-editor/manager/editable-image/editable-image.manager';
import { ImageUploadStatus } from '../../ui/image-upload-status/image-upload-status';
import { StaticTooltip } from '../../ui/tooltip/static-tooltip';
import { Checkbox } from '../../ui/input/checkbox';
import { isCtrlOrCmdPressed } from '../../../utilities/is-ctrl-or-cmd-pressed';
import { Input } from '../../ui/input/input';
import { ApiImagesGet, ImagesGetResponse } from '../../../api/front/images/api-images';

type Props = {
  kijshopCd: string,
  shopOrderId: string,
  orderId: string,
  callback: (param: { data: (FileDetail & { file: File | null, orderId: string })[] }) => void,
  isLayout?: boolean,
}

type HiddenComponentProps = {
  hidden: boolean,
  children?: React.ReactNode | React.ReactNode[],
}

const HiddenComponent = (props: HiddenComponentProps) => {
  const {
    hidden,
    children,
  } = props;

  if (hidden) return <></>;
  return (
    <>
      {children}
    </>
  );
};


type NestedFileDetail = {
  name: string;
  files: FileDetail[];
  children: NestedFileDetail[];
  relativePath: string;
};

const buildNestedStructure = (array: FileDetail[]): NestedFileDetail => {
  const root: NestedFileDetail = { name: '', files: [], children: [], relativePath: '' };
  array.forEach(file => {
    const pathParts = file.relativePath.split('/');
    let current = root;
    pathParts.forEach((part, index) => {
      let child = current.children.find(c => c.name === part);
      if (!child) {
        child = { name: part, files: [], children: [], relativePath: pathParts.slice(0, index + 1).join('/') };
        current.children.push(child);
      }
      current = child;
      if (index === pathParts.length - 1) {
        current.files.push(file);
      }
    });
  });
  return root;
};

type OrderNameProps = {
  name: string,
}
const OrderName = (props: OrderNameProps) => {
  const { name } = props;
  const [hover, setHover] = useState(false);
  return (
    <div
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {name}
      {
        name.length >= 10 && hover ?
          <StaticTooltip
            messages={[name]}
            pos={{ x: '20', y: '20' }}
          />
          : <></>
      }
    </div>
  );
};

const FolderView = (props: {
  index: number,
  folder: NestedFileDetail,
  select: string,
  onSelect: (v: string) => void,
  onSelectFolderImage: (ignore: boolean, ...images: (FileDetail & { orderId: string })[]) => void,
  fileList: FileDetail[];
  selectImg: FileDetail[]
  _key: string,
  orderId: string,
}) => {
  const {
    index,
    folder,
    select,
    onSelect,
    onSelectFolderImage,
    _key,
    fileList,
    selectImg,
    orderId,
  } = props;

  const [open, setOpen] = useState(true);
  const [dummyCheck, setDummyCheck] = useState(true);
  const folderImages = useMemo(() => {
    const result = fileList.filter((v) => v.relativePath === folder.relativePath);
    return result;
  }, [folder, fileList]);

  const handlerClick = useCallback(() => {
    if (select === folder.relativePath) {
      setOpen(!open);
    } else {
      onSelect(folder.relativePath);
    }
  }, [open, select, onSelect, folder]);

  const [hover, setHover] = useState(false);

  const isCheckFolder = useMemo(() => {
    if (!folderImages.length) return dummyCheck;
    return folderImages.every((v) => selectImg.find((img) => img.id === v.id));
  }, [selectImg, folderImages, dummyCheck]);

  const handlerClickCheckBox = useCallback(() => {
    if (!folderImages.length) {
      setDummyCheck(!dummyCheck);
    } else {
      onSelectFolderImage(isCheckFolder, ...folderImages.map((v) => ({ orderId, ...v })));
    }
  }, [
    dummyCheck,
    folderImages,
    isCheckFolder,
    orderId,
    onSelectFolderImage,
  ]);

  return (
    <div
      className={'file_select__folder_wrap'}
      key={_key + 'child'}
    >
      <div style={{ paddingLeft: index * 10 }} className="file_select__folder_wrap__cnt">
        <div style={{ minWidth: '14px', height: '14px' }} className="check_box_wrap">
          <Checkbox
            checked={isCheckFolder}
            onClick={handlerClickCheckBox}
            onChange={() => {}}
          />
        </div>
        <div
          className={'file_select__list_area__folder_name ' + (select === folder.relativePath ? 'active' : '')}
          onClick={handlerClick}
        >
          <img
            src={open || !folder.children.length ? folderOpen : folderClose}
            alt=""
          />
          <div
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
          >
            {folder.name}
            {
              folder.name.length >= 10 && hover ?
                <StaticTooltip
                  messages={[folder.name]}
                  pos={{ x: '20', y: '20' }}
                />
                : <></>
            }
          </div>
        </div>
      </div>
      {
        open ?
          <div className={'folder_name__children'}>
            {
              folder.children.map((_folder: NestedFileDetail) => (
                <FolderView
                  index={index + 1}
                  folder={_folder}
                  fileList={fileList}
                  select={select}
                  selectImg={selectImg}
                  onSelect={onSelect}
                  orderId={orderId}
                  onSelectFolderImage={onSelectFolderImage}
                  key={`folder-nest-list-${_folder.relativePath}`}
                  _key={`folder-nest-list-${_folder.relativePath}`}
                />
              ))
            }
          </div>
          : <></>
      }
    </div>
  );
};

export const CFolderFileSelectDialog = (props: Props) => {
  const { kijshopCd, callback, shopOrderId, orderId, isLayout } = props;
  const dispatch = useDispatch();
  const [editableImageList, setEditableImageList] = useState<EditableImage[]>([]);
  const [orderResultList, setOrderResultList] = useState<OrderResult[]>([]);
  const [selectOrderResult, setSelectOrderResult] = useState<OrderResult>();
  const [_selectRelativePath, setSelectRelativePath] = useState<string>('');
  const [fileDetails, setFileDetails] = useState<FileDetail[]>([]);
  const [selectImg, setSelectImg] = useState<(FileDetail & { orderId: string })[]>([]);
  const [prevIndex, setPrevIndex] = useState(0);
  const [inputSearchValue, setInputSearchValue] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const imgCnt = useRef<HTMLDivElement>(null);
  const fileList = useRef<{ id: string, file: File }[]>([]);

  const folderNestList = useMemo(() => {
    return buildNestedStructure(fileDetails ?? []).children
  }, [fileDetails]);

  const isSingleFolder = useMemo(() => {
    return !!((folderNestList.length === 1) && !folderNestList[0]?.children.length);
  }, [folderNestList]);

  const selectRelativePath = useMemo(() => {
    if (isSingleFolder) return folderNestList[0].relativePath ?? '';
    return _selectRelativePath;
  }, [isSingleFolder, _selectRelativePath, folderNestList]);

  const handlerCLickClose = useCallback(() => {
    dispatch(dialogActions.pop());
  }, []);

  const viewOrderList = useMemo(() => {
    if (!searchValue) return orderResultList;
    const reg = new RegExp(searchValue.replaceAll(/[.*+?^${}()|[\]\\]/g, '\\$&'));
    const result = orderResultList.filter((v) => v.name.match(reg));
    if (selectOrderResult && result.find((v) => v.id !== selectOrderResult.id)) {
      setSelectOrderResult(undefined);
      setSelectImg([]);
      setSelectRelativePath('');
      setFileDetails([]);
    }
    return result;
  }, [orderResultList, selectOrderResult, searchValue]);

  const handleClickSubmit = useCallback(() => {
    if (!selectImg.length) return;
    let requestFiles = selectImg.map((fileData) => {
      const target = fileList.current.find((v) => v.id === fileData.id);
      return {
        ...fileData,
        file: target?.file ?? null,
      };
    });
    if (isLayout) {
      dispatch(apiActions.run(new ApiImagesGet({ kijshopCd, shopOrderId: shopOrderId, kind: '6' }), {
        onSuccess: (res: ResponseBase<ImagesGetResponse[]>) => {
          const imgList = (res?.body?.data ?? []).filter((v) => v.cfImageId);
          requestFiles = requestFiles.filter((img) => {
            return !(imgList.find((v) => (v.cfImageId === img.id) ));
          });
          callback({
            data: requestFiles,
          });
          dispatch(dialogActions.pop());
        }
      }));
    }
    if (!isLayout) {
      callback({
        data: requestFiles,
      });
      dispatch(dialogActions.pop());
    }
  }, [selectImg, callback, dispatch, isLayout, kijshopCd, shopOrderId]);

  const getOrderResult = useCallback(() => {
    dispatch(apiActions.run(
      new ApiGetCloudFolderOrderResult({ kijshopCd }),
      {
        onSuccess: (res: ResponseBase<OrderResult[]>) => {
          setOrderResultList(res.body.data ?? []);
        },
      },
    ));
  }, []);

  const getFileDetail = useCallback((orderResult: OrderResult) => {
    if (orderResult === selectOrderResult) return;
    setSelectOrderResult(orderResult);
    dispatch(apiActions.run(
      new ApiGetCloudFolderFileDetail({
        kijshopCd,
        orderId: orderResult.id,
        folderId: orderResult.folderId,
        thumbOnly: 1,
      }),
      {
        onSuccess: (res: ResponseBase<FileDetail[]>) => {
          setFileDetails(res.body.data ?? []);
          setSelectImg((res.body.data ?? []).map((v) => ({ orderId: orderResult.id, ...v })));
        },
      },
    ));
  }, [selectOrderResult]);

  const handlerSelectOrder = useCallback((order: OrderResult) => {
    if (selectOrderResult === order) return;
    setPrevIndex(0);
    setSelectOrderResult(order);
    setFileDetails([]);
    getFileDetail(order);
    setSelectRelativePath('');
    setSelectImg([]);
  }, [selectOrderResult]);

  const handlerSelectFolder = useCallback((path: string) => {
    if (selectRelativePath === path) return;
    setPrevIndex(0);
    imgCnt.current?.scrollTo({ top: 0 });
    setSelectRelativePath(path);
  }, [selectRelativePath]);

  const selectFolderImages = useMemo(() => {
    if (!orderResultList || !selectRelativePath) return [];
    return fileDetails.filter((v) => v.relativePath === selectRelativePath);
  }, [orderResultList, selectRelativePath, fileDetails]);

  const handlerSelectImage = useCallback((e: React.MouseEvent<HTMLDivElement>, image: FileDetail) => {
    if (!selectOrderResult) {
      throw new Error('order が 選択されていません!!');
    }
    // indexの取得
    const targetIndex = fileDetails.findIndex((v) => v.id === image.id);
    if (targetIndex < 0) return;
    const rangeList: [number, number] = [targetIndex, prevIndex];
    // 選択から前にいくか後ろにいくかを前→後ろに統一する
    rangeList.sort((a, b) => a > b ? 1 : -1);
    setPrevIndex(targetIndex);
    // 該当フォルダ内ファイルの絞り込み
    const targetFolderImages = fileDetails.filter((v) => v.relativePath === image.relativePath);
    
    // Win Mac Ctrl↔︎Metaの切り替え
    const isCtrl = isCtrlOrCmdPressed(e);
    // Shift + Ctrl クリック
    if (e.shiftKey && isCtrl){
      const addFiles = fileDetails.slice(rangeList[0], rangeList[1] + 1);
      const result = selectImg.filter((v) => !addFiles.find((img) => img.id === v.id));
      setSelectImg([...result, ...addFiles.map((v) => ({ ...v, orderId: selectOrderResult.id }))]);
      return;
    }

    // Ctrl クリック
    if (isCtrl) {
      const target = selectImg.find((v) => v.id === image.id);
      if (target) {
        const result = selectImg.filter((v) => (v.id !== image.id));
        setSelectImg([...result]);
      } else {
        setSelectImg([...selectImg, { ...image, orderId: selectOrderResult.id }])
      }
      return;
    }

    // Shift クリック
    if (e.shiftKey) {
      const addFiles = fileDetails.slice(rangeList[0], rangeList[1] + 1);
      const result = selectImg.filter((v) => {
        const target = targetFolderImages.find((img) => img.id === v.id);
        return !target;
      });
      setSelectImg([...result, ...addFiles.map((v) => ({ ...v, orderId: selectOrderResult.id }))]);
      return;
    }
    // 単クリック
    {
      const result = selectImg.filter((v) => {
        const target = targetFolderImages.find((img) => img.id === v.id);
        return !target;
      });
      setSelectImg([...result, { ...image, orderId: selectOrderResult.id }]);
      return;
    }
  }, [
    selectImg,
    selectOrderResult,
    fileDetails,
    prevIndex,
  ]);

  const handlerEnterKeyPressSearch = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
    if ((e.key === 'Enter') && !e.nativeEvent.isComposing) {
      setSearchValue(inputSearchValue)
    }
  }, [inputSearchValue]);

  const handlerSelectFolderImage = useCallback((ignore: boolean, ...images: (FileDetail & { orderId: string })[]) => {
    if (!selectOrderResult) return;
    const filterData = selectImg.filter((v) => !images.find((img) => (v.id === img.id) && (img.orderId === v.orderId)));
    if (!ignore) {
      filterData.push(...images);
    }
    setSelectImg(filterData);
  }, [selectImg]);
  useEffect(() => {
    getOrderResult();
    const onChangeEditableImageList = (e: { list: EditableImage[] }) => {
      setEditableImageList(e.list.filter(EditableImageManager.filter(kijshopCd, shopOrderId, orderId)).filter((v) => v.kind === '6'));
    };
    UiManager.ins.on('l->r:change-editable-image-list', onChangeEditableImageList);
    UiManager.ins.emit('l->r:post-editable-image-list', {
      callback: (e: { list: EditableImage[] }) => {
        const resultList: EditableImage[] = [];
        for (const image of e.list ?? []) {
          if (image.shopOrderId === shopOrderId && image.kijshopCd === kijshopCd && image.kind === '6') {
            if (!resultList.find((v) => v.selectId === image.selectId)) {
              resultList.push(image);
            }
          }
        }
        setEditableImageList(resultList);
      },
    });
    return () => {
      UiManager.ins.off('l->r:change-editable-image-list', onChangeEditableImageList);
    };
  }, []);

  return (
    <>
      <div className={'dialog__contents c_folder_select_dialog'}>
        <div className="search_cnt">
          <Input
            value={inputSearchValue}
            onChange={(e) => setInputSearchValue(e.target.value)}
            onKeyDown={handlerEnterKeyPressSearch}
          />
          <Button label="検索" onClick={() => setSearchValue(inputSearchValue)} />
        </div>
        <div className={'file_select'}>
          <div className={'file_select__list_area'}>
            {
              viewOrderList.map((item, i) => (
                <div
                  key={`order-result-${item.id}`}
                  onClick={() => handlerSelectOrder(item)}
                  className={'file_select__list_area__order_name ' + (selectOrderResult === item ? 'active' : '')}
                >
                  <img
                    src={selectOrderResult === item ? folderOpen : folderClose}
                    alt=""
                  />
                  <OrderName name={item.name} />
                </div>
              ))
            }
          </div>
          <div className={'file_select__list_area'}>
            <HiddenComponent hidden={!selectOrderResult || isSingleFolder}>
              {
                folderNestList.map((item, i) => (
                  <FolderView
                    index={1}
                    folder={item}
                    orderId={selectOrderResult?.id ?? ''}
                    selectImg={selectImg}
                    fileList={fileDetails}
                    select={selectRelativePath}
                    onSelect={handlerSelectFolder}
                    onSelectFolderImage={handlerSelectFolderImage}
                    key={`folder-nest-list-${item.relativePath}`}
                    _key={`folder-nest-list-${item.relativePath}`}
                  />
                ))
              }
            </HiddenComponent>
          </div>
          <div
            className={'file_select__test'}
            ref={imgCnt}
          >
            <HiddenComponent hidden={!selectRelativePath}>
              {
                selectFolderImages.map((item, i) => (
                  <ImageComponent
                    key={`file_test_${i}`}
                    onSelect={handlerSelectImage}
                    isSelected={selectImg.some((v) => v.id === item.id)}
                    data={item}
                    kijshopCd={kijshopCd}
                    orderId={selectOrderResult?.id ?? ''}
                    fileList={fileList}
                    editableImageList={editableImageList}
                  />
                ))
              }
            </HiddenComponent>
          </div>
        </div>
      </div>
      <div className={'dialog_contents__footer file_select_footer'}>
        <Button
          label={'閉じる'}
          onClick={handlerCLickClose}
          color={'light'}
        />
        <Button
          label={'選択'}
          onClick={handleClickSubmit}
        />
      </div>
    </>
  );
};

type ImageProps = {
  data: FileDetail,
  onSelect: (e: React.MouseEvent<HTMLDivElement>, v: FileDetail & { orderId: string }) => void,
  isSelected: boolean,
  kijshopCd: string,
  // CloudFolder OrderID
  orderId: string,
  fileList: RefObject<{ id: string, file: File }[]>,
  editableImageList: EditableImage[],
}

const ImageComponent = (props: ImageProps) => {
  const {
    data,
    isSelected,
    onSelect,
    kijshopCd,
    orderId,
    fileList,
    editableImageList,
  } = props;
  const dispatch = useAppDispatch();
  const [src, setSrc] = useState('');
  const init = useRef<boolean>(false);
  const cnt = useRef<HTMLDivElement>(null);
  const idRef = useRef(data.id);

  const editableImage = useMemo(() => {
    const target = editableImageList.find((v) => v.cfImageId === data.id);
    return target ?? null;
  }, [data, editableImageList]);

  const onSelectImg = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    if (!src) return;
    if (editableImage) return;
    onSelect(e, { ...data, orderId });
  }, [data, src, orderId, onSelect, editableImage]);

  useEffect(() => {
    idRef.current = data.id;
    const target = fileList.current?.find((info) => info.id === data.id);
    if (target) {
      const url = URL.createObjectURL(target.file);
      setSrc(url);
      return;
    }
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((v) => {
        if (v.isIntersecting && !init.current) {
          init.current = true;
          dispatch(apiActions.run(new ApiGetCloudFolderFileDownload({
            kijshopCd,
            orderId,
            path: `${data.pathThumb}/${data.orgFilename}`,
          }), {
            onSuccess: (res: Blob) => {
              const file = new File([res], data.orgFilename);
              fileList.current?.push({
                id: data.id,
                file,
              });
              if (idRef.current === data.id) {
                const url = URL.createObjectURL(res);
                setSrc(url);
              }
            },
          }));
        }
      });
    });
    if (cnt.current) {
      observer.observe(cnt.current);
    }


    return () => {
      observer.disconnect();
      init.current = false;
      setSrc('');
      if (src) {
        URL.revokeObjectURL(src);
      }
    };
  }, [data]);

  return (
    <div
      className={`image ${isSelected ? 'active' : ''} ${!src ? 'loading' : ''} ${!src ? '' : 'clickable'}`}
      onClick={onSelectImg}
      ref={cnt}
    >
      <div className="image__wrap">
        <img
          src={src || loading}
          alt=""
        />
        {editableImage && <ImageUploadStatus
          uploaded={editableImage.flags.uploaded}
          uploading={editableImage.flags.uploading}
        />}
      </div>
      <label>{data.orgFilename}</label>
    </div>
  );
};
