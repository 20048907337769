import {ItemsRequest, ItemsResponse} from '../../../../models/api/back/create-order/items';
import {AppThunk} from "../../../../app/store";
import {ordersDataActions} from "../../../../slices/orders/orders-data-slice";
import {apiActions} from "../../../../slices/api-slice";
import {ResponseBase} from "../../../response-base";
import {ApiBaseBack} from "../../api-base-back";

export class ApiItems extends ApiBaseBack<ItemsResponse> {
  constructor(param: ItemsRequest) {
    super(
      'GET',
      '/api/v1/products',
      'JSON',
      param,
    );
  }
}

export const apiItemsThunk = (param: ItemsRequest, callback?: () => void, isInit?: boolean): AppThunk => async (dispatch, getState) => {
  if (!param.serviceId) {
    // dispatch(ordersDataActions.updateItemDetail({
    //   item: { products: [] },
    // }));
    callback?.();
    return;
  }
  dispatch(apiActions.run(
    new ApiItems(param),
    {
      onSuccess: (res: ResponseBase<ItemsResponse>) => {
        callback?.();
        if(!isInit && getState().order.itemInfo.category.value !== param.serviceId){
          return;
        }
        dispatch(ordersDataActions.updateItemDetail({
          item: { products: res.body.data?.products || [] },
        }));
      },
      onError: () => {
        callback?.();
      },
    },
    {
      apiName: 'items',
      ignoreSystemError: true,
    },
  ));
};
