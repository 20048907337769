import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PresetData } from './layout-slice';
import { SendFormDesignation, SendStore } from './orders/delivery-slice';
import { AppThunk } from '../app/store';
import { RetouchSliceState } from './retouch-slice';

const keys = {
  machineId: 'machine-id',
  staffInfo: 'staff-info',
  shopName: 'shop-name',
  deliveryType: 'deliver-type',
  deliveryStore: 'deliver-store',
  category: 'category',
  presetList: 'preset-list',
  sendFormDesignation: 'send-form-designation',
  sendStore: 'send-store',
  loginMode: 'login-mode',
};

type Type =
  'shopName'
  | 'deliveryType'
  | 'deliveryStore'
  | 'category'
  | 'presetList'
  | 'sendStore'
  | 'sendFormDesignation'
  | 'retouchMenu'
  | 'isAdmin'

type RetouchMenu = Omit<RetouchSliceState, 'retouchItemList' | 'imageCheckItemList'>

type LoginMode = 'ADMIN' | 'STAFF';

type LocalStorageData = {
  // ログイン店舗
  shopName: string,
  // 配送方法
  deliveryType: string,
  // 配送店舗
  deliveryStore: string,
  // カテゴリ
  category: string,
  // プリセットリスト
  presetList: PresetData[],
  // 配送先情報
  // - 発送帳票
  sendFormDesignation: SendFormDesignation,
  // - 発送店舗
  sendStore: SendStore,
  // - レタッチメニュー
  retouchMenu: RetouchMenu,
  // - システム管理者 -
  isAdmin: boolean,
};

type LocalStorageSliceState = {
  // マシンID
  machineId: string,
  // スタッフID & スタッフ名
  staffInfo: { kijshopCd: string, id: string, name: string },
  // 店舗コード
  kijshopCd: string,
  // データ
  data: LocalStorageData,
  // 最終ログイン時(staff or admin)
  loginMode: LoginMode,
}

const initialState: LocalStorageSliceState = {
  kijshopCd: '',
  machineId: localStorage.getItem(keys.machineId) || '',
  staffInfo: JSON.parse(localStorage.getItem(keys.staffInfo) || '{}'),
  loginMode: localStorage.getItem(keys.loginMode) === 'STAFF' ? 'STAFF' : 'ADMIN',
  data: {
    shopName: '',
    deliveryType: '',
    deliveryStore: '',
    category: '',
    presetList: [],
    retouchMenu: {
      bubbleType: 'list',
      sortType: 'asc-name',
      showMenuType: 'retouch',
    },
    sendFormDesignation: {
      name: '',
      path: '',
      id: '',
      originalPath: '',
      importPath: '',
    },
    sendStore: {
      shopName: '',
      shippingShopId: '',
    },
    isAdmin: false,
  },
};

export const localStorageSlice = createSlice({
  name: 'localStorage',
  initialState,
  reducers: {
    setMachineId: (state, action: PayloadAction<string>) => {
      state.machineId = action.payload;
      localStorage.setItem(keys.machineId, action.payload);
    },
    setStaffIdAndName: (state, action: PayloadAction<{ kijshopCd: string, id: string, name: string }>) => {
      state.staffInfo = action.payload;
      localStorage.setItem(keys.staffInfo, JSON.stringify(action.payload));
    },
    setLoginMode: (state, action: PayloadAction<LoginMode>) => {
      state.loginMode = action.payload;
      localStorage.setItem(keys.loginMode, action.payload);
    },
    setShopCode: (state, action: PayloadAction<string>) => {
      state.kijshopCd = action.payload;
    },
    // setAllData: (state, action: PayloadAction<LocalStorageData>) => {
    //   state.data = action.payload;
    // },
    setName: (state, action: PayloadAction<string>) => {
      state.data.shopName = action.payload;
    },
    setDeliveryType: (state, action: PayloadAction<string>) => {
      state.data.deliveryType = action.payload;
    },
    setDeliveryStore: (state, action: PayloadAction<string>) => {
      state.data.deliveryStore = action.payload;
    },
    setCategory: (state, action: PayloadAction<string>) => {
      state.data.category = action.payload;
    },
    setPresetList: (state, action: PayloadAction<PresetData[]>) => {
      state.data.presetList = action.payload || state.data.presetList;
    },
    setSendFormDesignation: (state, action: PayloadAction<SendFormDesignation>) => {
      state.data.sendFormDesignation = action.payload || state.data.sendFormDesignation;
    },
    setSendStore: (state, action: PayloadAction<SendStore>) => {
      state.data.sendStore = action.payload || state.data.sendStore;
    },
    setRetouchMenu: (state, action: PayloadAction<RetouchMenu>) => {
      state.data.retouchMenu = action.payload || state.data.retouchMenu;
    },
    setIsAdmin: (state, action: PayloadAction<boolean>) => {
      state.data.isAdmin = action.payload || state.data.isAdmin;
    },
  },
});

type ValType<T> =
  T extends 'shopName' | 'deliveryType' | 'deliveryStore' | 'category' ? string :
    T extends 'presetList' ? PresetData[] :
      T extends 'sendStore' ? SendStore :
        T extends 'sendFormDesignation' ? SendFormDesignation :
          T extends 'retouchMenu' ? RetouchMenu :
            T extends 'isAdmin' ? boolean :
              never;

const storageActions = {
  getLocalData: (kijshopCd: string): AppThunk => async (dispatch, getState) => {
    const localData = localStorage.getItem(kijshopCd);
    const data = localData ? JSON.parse(localData) as LocalStorageData : null;
    dispatch(localStorageActions.setShopCode(kijshopCd));
    if (!data) {
      const setData: LocalStorageData = initialState.data;
      localStorage.setItem(kijshopCd, JSON.stringify(setData));
    } else {
      dispatch(localStorageActions.setName(data.shopName));
      dispatch(localStorageActions.setDeliveryType(data.deliveryType));
      dispatch(localStorageActions.setDeliveryStore(data.deliveryStore));
      dispatch(localStorageActions.setCategory(data.category));
      dispatch(localStorageActions.setPresetList(data.presetList));
      dispatch(localStorageActions.setSendStore(data.sendStore));
      dispatch(localStorageActions.setSendFormDesignation(data.sendFormDesignation));
      dispatch(localStorageActions.setRetouchMenu(data.retouchMenu));
      dispatch(localStorageActions.setIsAdmin(data.isAdmin));
    }
  },
  setData: <T extends Type>(kijshopCd: string, type: T, value: ValType<T>): AppThunk => async (dispatch, getState) => {
    let data: LocalStorageData = getState().storage.data;
    switch (type) {
      case 'shopName' :
        data = { ...data, shopName: value as ValType<'shopName'> };
        dispatch(localStorageActions.setName(value as ValType<'shopName'>));
        break;
      case 'deliveryType':
        data = { ...data, deliveryType: value as string };
        dispatch(localStorageActions.setDeliveryType(value as ValType<'deliveryType'>));
        break;
      case 'deliveryStore':
        data = { ...data, deliveryStore: value as string };
        dispatch(localStorageActions.setDeliveryStore(value as ValType<'deliveryStore'>));
        break;
      case 'category':
        data = { ...data, category: value as string };
        dispatch(localStorageActions.setCategory(value as ValType<'category'>));
        break;
      case 'presetList':
        data = { ...data, presetList: value as PresetData[] };
        dispatch(localStorageActions.setPresetList(value as ValType<'presetList'>));
        break;
      case 'sendStore':
        data = { ...data, sendStore: value as SendStore };
        dispatch(localStorageActions.setSendStore(value as ValType<'sendStore'>));
        break;
      case 'sendFormDesignation':
        data = { ...data, sendFormDesignation: value as SendFormDesignation };
        dispatch(localStorageActions.setSendFormDesignation(value as ValType<'sendFormDesignation'>));
        break;
      case 'isAdmin':
        data = { ...data, isAdmin: value as ValType<'isAdmin'> };
        dispatch(localStorageActions.setIsAdmin(value as ValType<'isAdmin'>));
        break;
      case 'retouchMenu':
        data = { ...data, retouchMenu: value as RetouchMenu };
        dispatch(localStorageActions.setRetouchMenu(value as ValType<'retouchMenu'>));
        break;
    }
    localStorage.setItem(kijshopCd, JSON.stringify(data));
    // dispatch(localStorageActions.setAllData(data));
  },
};

export const localStorageActions = Object.assign(localStorageSlice.actions, storageActions);
export const localStorageReducer = localStorageSlice.reducer;
