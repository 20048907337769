import { Input } from '../../ui/input/input';
import { Button } from '../../ui/button/button';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { dialogActions } from '../slice/dialog-slice';
import { StoreGetResponse } from '../../../models/api/front/store';
import { apiActions } from '../../../slices/api-slice';
import { ApiStoreEdit, ApiStoreGet } from '../../../api/front/store/api-store';
import { deliveryActions } from '../../../slices/orders/delivery-slice';
import { useAppSelector } from '../../../app/hooks';
import lodash from 'lodash';
import { ResponseBase } from '../../../api/response-base';

type RepairDeliveryStoreProps = {
  kijshopCd: string
  storeData: StoreGetResponse,
};
export const RepairDeliveryStore = (props: RepairDeliveryStoreProps) => {
  const { kijshopCd, storeData } = props;
  // - Hooks -
  const dispatch = useDispatch();
  // - State -
  const shopData = useAppSelector((state) => {
    return state.delivery.sendStore;
  }, lodash.isEqual);
  // -- 発送店舗 --
  const [deliveryStore, setDeliveryStore] = useState(storeData.shopName);
  // - Callback -
  // -- 発送店舗更新 --
  const handlerChangeDeliveryStore = useCallback((v) => {
    setDeliveryStore(v);
  }, []);
  // -- 更新ボタン押下 --
  const handlerClickUpdate = useCallback(() => {
    if (!deliveryStore) {
      dispatch(dialogActions.pushMessage({
        title: '確認',
        message: [
          '発送店舗名が入力されていません。',
        ],
        buttons: [
          {
            label: 'OK',
            callback: () => {
              dispatch(dialogActions.pop());
            },
          },
        ],
      }));
    } else {
      dispatch(apiActions.run(
        new ApiStoreEdit(
          kijshopCd,
          storeData.shippingShopId,
          {
            shopName: deliveryStore,
          },
        ),
        {
          onSuccess: () => {
            dispatch(apiActions.run(
              new ApiStoreGet({ kijshopCd }),
              {
                onSuccess: (res: ResponseBase<StoreGetResponse[]>) => {
                  if (res.body.data) {
                    dispatch(deliveryActions.setSendStoreState(res.body.data));
                    dispatch(dialogActions.pop());
                  }
                },
              },
            ));
          },
        },
      ));
    }
  }, [deliveryStore, storeData]);
  // -- キャンセルボタン押下 --
  const handlerClickCancel = useCallback(() => {
    dispatch(dialogActions.pop());
  }, []);
  return (
    <div className="dialog_contents">
      <div className="dialog_contents__body" style={{ margin: '8px 32px' }}>
        <div className="lh_2">発送店舗名</div>
        <Input
          value={deliveryStore}
          onChange={(e) => handlerChangeDeliveryStore(e.target.value)}
        />
      </div>
      <div className="dialog_contents__footer">
        <Button
          label="キャンセル"
          onClick={handlerClickCancel}
        />
        <Button
          label="登録"
          onClick={handlerClickUpdate}
        />
      </div>
    </div>
  );
};
