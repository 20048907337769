export class WindowsImgBugFix {

  /**
   * Windows でのDnDで download.jpg が自動で追加される問題をチェックする
   * 問題ないなら true バグ発生時は false
   * @param files
   */
  static check(files: File[]) {
    // ドロワーからの DnD チェック
    // * files.length が 1
    // * exif がない ← 非同期は詰むので廃止
    // * 名前が download.jpg
    if (files.length === 1) {
      const file = files[0];
      // const exif = await ImageProcessorManager.loadExifOrigin(file);
      // if (!exif && file.name === 'download.jpg') {
      if (file.name === 'download.jpg') {
        return false;
      }
    }
    return true;
  }

}
