import {LoadingBar} from '../../ui/loading/loading-bar';
import {Button} from '../../ui/button/button';
import {useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {dialogActions} from '../slice/dialog-slice';

export const ConfigApplying = () => {
  const dispatch = useDispatch();
  const handlerClickCancel = useCallback(() => {
    dispatch(dialogActions.pop());
  }, []);
  return (
    <div>
      <div>
        <LoadingBar />
        <Button
          label="中止"
          onClick={handlerClickCancel}
        />
        <br />設定を適用中です...
      </div>
      <div>
        処理ーページ数(1/3)
        <br />本処理中は、他のボタンやメニューまたは、画面を操作しないでください。
      </div>
    </div>
  );
};