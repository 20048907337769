import { ApiBaseFront } from '../api-base-front';
import { StaffAuthResponse } from '../../../models/api/front/staff-auth';

export class ApiStaffAuthGet extends ApiBaseFront<StaffAuthResponse[]> {
  constructor(param: { kijshopCd: string }) {
    super(
      'GET',
      '/api/v1/staff/authority',
      'JSON',
      param,
    );
  }
}
