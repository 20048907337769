import * as lodash from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  ApiFavoriteDataGet,
  ApiFavoriteDelete,
  ApiFavoriteFolderListGet,
  ApiFavoriteGet,
} from '../../../api/front/favorite/api-favorite';
import { ResponseBase } from '../../../api/response-base';
import { useAppSelector } from '../../../app/hooks';
import folderClose from '../../../assets/img/icon/folder-close-black.svg';
import folderOpen from '../../../assets/img/icon/folder-open-black.svg';
import {
  FavoriteGetFolderResponse,
  FavoriteGetResponse, FavoriteProductListItem,
} from '../../../models/api/front/favorite';
import { apiActions } from '../../../slices/api-slice';
import { favoriteBrandActions, FavoriteBrandItem } from '../../../slices/favorite-brand-slice';
import { toolbarActions } from '../../toolbar/slice/toolbar-slice';
import { Button } from '../../ui/button/button';
import { SwitchIcon } from '../../ui/switch-icon/switch-icon';
import { dialogActions } from '../slice/dialog-slice';
import { AddFolder } from './add-folder';
import { ChangeFavoriteBrandName } from './change-favorite-brand-name';
import './favorite-brand-config.scss';
import { ApiFavoriteFolderDelete, ApiFavoriteFolderPost } from '../../../api/front/favorite-folder/api-favorite-folder';
import { FavoriteFolderListItem } from '../../../models/api/front/favorite-folder';
import { FavoriteTemplateItem } from '../../../slices/favorite-template-slice';
import { Input } from '../../ui/input/input';
import { LoadingPopup } from '../../ui/loading/loading-popup';
import { folder } from 'jszip';

const getChildrenName = (content: FavoriteBrandItem) => {
  let str = content.name;
  content.children.forEach((v) => {
    str += v.name;
    v.children.forEach((c) => {
      str += getChildrenName(c);
    })
  });
  return str;
};

type ChangeFolderNameProps = {
  kijshopCd: string,
  // - 更新するフォルダ -
  targetFolder: FavoriteTemplateItem,
  level?: number,
  usedNameList?: string[],
  parentId?: string,
  indexes?: number,
  callback?: {
    onSuccess: () => void,
    onError: () => void,
  },
}

export const ChangeFolderName = (props: ChangeFolderNameProps) => {
  const { kijshopCd, targetFolder, level, parentId, indexes, usedNameList, callback } = props;
  // - Hooks -
  const dispatch = useDispatch();
  // - State -
  // -- フォルダ名 --
  const [name, setName] = useState(targetFolder.name);
  // - Callback -
  // -- フォルダ名更新 --
  const handlerChangeName = useCallback((v) => {
    setName(v);
  }, []);
  // -- OKボタン押下 --
  const handlerClickOk = useCallback(async () => {
    if (targetFolder) {
      if (targetFolder.name === name) {
        return;
      }
      const arr: FavoriteFolderListItem[] = await new ApiFavoriteFolderListGet({ kijshopCd })
        .do()
        .then((res: any) => res?.body?.data?.folderList || [])
        .catch(() => []);
      const isUsed = arr.find((v) => v.name === name && v.parentId === targetFolder.parentId);
      if (!isUsed) {
        dispatch(dialogActions.pop());
        dispatch(apiActions.run(
          new ApiFavoriteFolderPost(
            {
              id: targetFolder.id,
              kijshopCd,
              name,
              kind: '0',
              level: targetFolder.level || 1,
              parentId: targetFolder.parentId,
              indexes: targetFolder.indexes,
            },
            targetFolder.id,
          ),
          {
            onSuccess: callback?.onSuccess,
            onError: callback?.onError,
          },
        ));
      } else {
        dispatch(dialogActions.pushMessage({
          title: '確認',
          message: [
            'すでに存在するフォルダと同じ名称での変更はできません。'
          ],
          buttons: [
            {
              label: 'はい',
              callback: () => dispatch(dialogActions.pop()),
            },
          ],
        }));
      }
    }
  }, [name, targetFolder, usedNameList]);
  // -- キャンセルボタン押下 --
  const handlerClickCancel = useCallback(() => {
    dispatch(dialogActions.pop());
  }, []);
  const ref = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (ref.current) {
      ref.current.focus();
    }
  }, [ref.current]);
  return (
    <div className='dialog_contents change_folder_name'>
      <div className='dialog_contents__body flex_direction_column' style={{margin: '8px 32px'}}>
        <div className='lh_2'>
          新しい{targetFolder.type === 'folder' ? 'フォルダ' : '商品'}名を入力してください。
        </div>
        <Input
          ref={ref}
          value={name}
          onChange={(e) => handlerChangeName(e.target.value)}
          style={{width: 300}}
          maxLength={40}
        />
      </div>
      <div className='dialog_contents__footer'>
        <Button
          label='キャンセル'
          onClick={handlerClickCancel}
        />
        <Button
          label='OK'
          disabled={!name.length}
          onClick={handlerClickOk}
        />
      </div>
    </div>
  );
};

type ChildContentProps = {
  kijshopCd: string,
  // - ひとつの表示項目 -
  content: FavoriteBrandItem,
  // - 下位層のインデント -
  layerIndent?: number,
  // - 層の深度 -
  layerDepth?: number
  // - 下位層なしアイテム用インデント(ツリーアイコン幅) -
  noChildIndent?: number,
  // - ツリーアイコン幅設定用コールバック(値の共有用) -
  setTreeIconWidth?: (width: number) => void,
  // - お気に入り商品保存ダイアログか -
  isSaveDialog?: boolean,
  // - 識別用ID -
  id?: string,
  // - 選択中IDの設定 -
  callbackSelectedContentID?: (id: string) => void,
  selected?: boolean,
  selectedId?: string,
  // callbackGetData?: (content: FavoriteBrandItem) => void,
  callbackChangeOpenCount: (num: number) => void,
  callbackCreateData: () => void,
  folderList: FavoriteFolderListItem[],
  favoriteDataList: FavoriteBrandItem[],
  parentId?: string,
  openFolders: string[],
  openOperation: (id: string, operate: 'open' | 'close') => void,
};

// - １項目分の表示 -
const ChildContent = (props: ChildContentProps) => {
  const {
    kijshopCd,
    content: _content,
    layerIndent,
    layerDepth = 0,
    setTreeIconWidth,
    noChildIndent,
    id,
    callbackSelectedContentID,
    selected: _selected,
    selectedId: _selectedId,
    callbackChangeOpenCount,
    callbackCreateData,
    folderList,
    favoriteDataList,
    parentId,
    openFolders,
    openOperation,
  } = props;
  const dispatch = useDispatch();
  const treeIconWidth = noChildIndent;

  // - State -
  // -- 第2階層以下表示の有無 --
  const [isOpen, setIsOpen] = useState(false);
  const [content, setContent] = useState(_content);

  // - Ref -
  // -- ツリー構造用アイコンEle --
  const treeIconEle = useRef<HTMLDivElement>(null);
  // -- フォルダーアイコンEle --
  const folderIconEle = useRef<HTMLDivElement>(null);
  const [selectedId, setSelectedId] = useState(_selectedId);
  useEffect(() => {
    if (selectedId !== _selectedId) {
      setSelectedId(_selectedId);
    }
  }, [_selectedId]);
  const [selected, setSelected] = useState(_selected);
  useEffect(() => {
    if (selected !== _selected) {
      setSelected(_selected);
    }
  }, [_selected]);
  const [dataList, setDataList] = useState<FavoriteBrandItem[]>(content.children);
  useEffect(() => {
    if (dataList.length !== content.children.length) {
      setDataList(content.children);
    }
    if (!isOpen && content.children.find((v) => v.id === selectedId)) {
      setIsOpen(true);
    }
  }, [content]);
  useEffect(() => {
    if (content.type === 'folder' && openFolders.find((v) => v === content.id)) {
      setIsOpen(true);
    }
  },[]);

  // - Callback -
  // -- フォルダ押下 --
  const handlerClickContent = useCallback((selectId) => {
    setIsOpen(prev => !prev);
    openOperation(content.id, (isOpen) ? 'close' : 'open');
    callbackSelectedContentID && callbackSelectedContentID(selectId || '');
    dispatch(favoriteBrandActions.setSelectBrand({ ...content, level: (content.type === 'folder') ? layerDepth : layerDepth - 1, parentId: parentId || '' }));
  }, [content, callbackSelectedContentID]);
  // -- フォルダ追加メニュー --
  const handlerClickAddFolder = useCallback(() => {
    dispatch(toolbarActions.close());
    const findData = (data: FavoriteBrandItem, level: number) => {
      if (data.id === selectedId) {
        return { id: data.type === 'brand' ? data.parentId : data.id, level: level };
      } else {
        const fav = data.children.find((v) => findData(v, level + 1));
        if (fav) {
          level = level > fav.level ? level : fav.level;
          return { id: data.type === 'brand' ? data.parentId : data.id, level: level };
        }
      }
      return undefined;
    };
    let level = 0;
    favoriteDataList.forEach((v) => {
      const data = findData(v, 1);
      if (data) {
        level = data.level;
      }
    });
    if (layerDepth >= 3) {
      dispatch(dialogActions.pushMessage({
        title: '確認',
        message: [
          '3階層以上になるフォルダ追加はできません',
        ],
        buttons: [
          {
            label: 'はい',
            callback: () => dispatch(dialogActions.pop()),
          },
        ],
      }));
      return;
    }
    dispatch(dialogActions.push({
      title: 'フォルダ追加',
      element: <AddFolder
        kijshopCd={kijshopCd}
        type={'brand'}
        getList={callbackCreateData}
        parentId={content.id}
        list={folderList}
        level={content.level || (level ? level + 1 : undefined)}
      />,
    }));
  }, [content, folderList, favoriteDataList, selectedId]);
  //   -- フォルダ名更新メニュー --
  const handlerClickChangeFolderName = useCallback(() => {
    dispatch(toolbarActions.close());
    if (content.type === 'brand') {
      dispatch(dialogActions.push({
        title: 'お気に入り商品名称変更',
        element: <ChangeFavoriteBrandName
          kijshopCd={kijshopCd}
          target={{ ...content, parentId: parentId || '', level: layerDepth -1 }}
          type={'save'}
          callback={{
            onSuccess: () => {
              dispatch(dialogActions.pop());
              callbackCreateData();
            },
            onError: () => {
              dispatch(dialogActions.pop());
              dispatch(dialogActions.pushMessage({
                title: '確認',
                message: ['更新に失敗しました'],
                buttons: [{
                  label: 'OK',
                  callback: () => {
                    dispatch(dialogActions.pop());
                  },
                }],
              }));
            },
          }}
        />
      }));
    } else {
      dispatch(dialogActions.push({
        title: 'フォルダ名変更',
        element: <ChangeFolderName
          kijshopCd={kijshopCd}
          targetFolder={content}
          callback={{
            onSuccess: () => {
              callbackCreateData();
            },
            onError: () => {
              dispatch(dialogActions.pushMessage({
                title: '確認',
                message: ['更新に失敗しました'],
                buttons: [{
                  label: 'OK',
                  callback: () => {
                    dispatch(dialogActions.pop());
                  },
                }],
              }));
            },
          }}
        />
      }));
    }
  }, [content]);
  // -- フォルダ削除メニュー --
  const handlerClickDeleteFolder = useCallback(() => {
    dispatch(toolbarActions.close());
    dispatch(dialogActions.pushMessage({
      title: content.type === 'folder' ? 'フォルダ削除' : '商品削除',
      message: [
        `${content.name}を削除します。`,
      ],
      buttons: [
        {
          label: 'キャンセル',
          callback: () => {
            dispatch(dialogActions.pop());
          },
        },
        {
          label: 'OK',
          callback: () => {
            dispatch(dialogActions.pop());
            // dispatch(favoriteBrandActions.deleteFolder(content));
            if (content.type === 'brand') {
              dispatch(apiActions.run(
                new ApiFavoriteDelete(content.id, { kijshopCd }),
                {
                  onSuccess: () => {
                    dispatch(favoriteBrandActions.setSelectBrand(undefined));
                    callbackCreateData();
                    // moveToConfigDialog();
                  },
                },
              ));
            } else {
              // dispatch(favoriteBrandActions.deleteFolder(selectBrand));
              dispatch(apiActions.run(
                new ApiFavoriteFolderDelete({
                  kijshopCd,
                  id: content.id,
                  kind: '0',
                }, content.id),
                {
                  onSuccess: (res) => {
                    // dispatch(dialogActions.pop());
                    callbackCreateData();
                  },
                  onError: () => {
                    dispatch(dialogActions.pushMessage({
                      title: '確認',
                      message: ['削除に失敗しました。'],
                      buttons: [{
                        label: 'OK',
                        callback: () => {
                          dispatch(dialogActions.pop());
                        },
                      }],
                    }));
                  },
                },
              ));
              // moveToConfigDialog();
            }
          },
        },
      ],
    }));
  }, [content]);
  // -- 右クリック --
  const handlerRightClick = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    dispatch(toolbarActions.open({
      position: {
        x: e.clientX,
        y: e.clientY,
      },
      list: [
        {
          label: 'フォルダ追加',
          callback: handlerClickAddFolder,
        },
        {
          label: content.type === 'folder' ? 'フォルダ名変更' : '商品名変更',
          callback: handlerClickChangeFolderName,
        },
        {
          label: content.type === 'folder' ? 'フォルダ削除' : '商品削除',
          callback: handlerClickDeleteFolder,
        },
      ],
    }));
  }, [content, handlerClickAddFolder, handlerClickChangeFolderName, handlerClickDeleteFolder]);

  const handlerChangeOpenCount = useCallback((v) => {
    callbackChangeOpenCount(v + (isOpen ? dataList.length : 0));
  }, [callbackChangeOpenCount, dataList.length]);

  // -- アイコン幅 --
  useEffect(() => {
    const tiw = treeIconEle.current?.getBoundingClientRect().width || 0;
    if (tiw && tiw !== treeIconWidth) {
      setTreeIconWidth && setTreeIconWidth(tiw);
    }
  }, []);

  useEffect(() => {
    if (id && content.name && content.type === 'folder') {
      dispatch(apiActions.run(
        new ApiFavoriteGet({ kijshopCd, folederId: id }),
        {
          onSuccess: (res: ResponseBase<FavoriteGetResponse>) => {
            setDataList((prev) => [...prev, ...[...(res.body.data?.favProductList || [])].sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1).filter((v) => !prev.find((fav) => fav.id === v.favProductId)).map((v) => ({
              id: v.favProductId,
              name: v.name,
              type: 'brand',
              children: [] as FavoriteBrandItem[],
            }))] as FavoriteBrandItem[]);
            setContent((prev) => ({
              ...prev,
              children: [
                ...prev.children,
                ...[...(res.body.data?.favProductList || [])].sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1).filter((v) => !prev.children.find((fav) => fav.id === v.favProductId)).map((v) => ({
                  id: v.favProductId,
                  name: v.name,
                  type: 'brand',
                  children: [] as FavoriteBrandItem[],
                })),
              ] as FavoriteBrandItem[],
            }));
          },
        },
      ));
    }
  }, [id]);

  useEffect(() => {
    callbackChangeOpenCount(isOpen ? dataList.length : 0);
  }, [isOpen, dataList.length]);
  return (
    <>
      <div
        className={`favorite_brand_config__table__body__row${content.id === selectedId ? ' selected' : ''}`}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          handlerClickContent(content.id);
        }}
        onContextMenu={handlerRightClick}
        style={{ ...layerIndent ? { paddingLeft: `calc(${layerIndent * layerDepth}px + 0.5em)` } : {} }}
      >
        <div className="favorite_brand_config__table__body__row__icon" style={content.children.length ? {} : { paddingLeft: noChildIndent }}>
          {content.children.length ? (
            <SwitchIcon isOn={isOpen} tmplMode="tree" ref={treeIconEle} />
          ) : (<></>)}
          {content.name && content.type === 'folder' ? (
            <SwitchIcon
              isOn={isOpen}
              tmplMode="folder"
              ref={folderIconEle}
              switchEle={{ on: <img src={folderOpen} alt="" />, off: <img src={folderClose} alt="" /> }}
            />
          ) : (<></>)}
        </div>
        <div className="favorite_brand_config__table__body__row__data">{content.name.replaceAll(' ', '\u00A0')}</div>
      </div>
      {isOpen ?
        // content.children.map((v, i) => <ChildContent
        //   kijshopCd={kijshopCd}
        //   key={`favorite-brand-config_${i}_${v.id}`}
        //   id={String(v.id)}
        //   content={v} layerIndent={treeIconWidth}
        //   layerDepth={layerDepth + 1}
        //   noChildIndent={noChildIndent}
        //   // 階層構造なしにつき選択中(selected)props未設定
        // />)
        dataList.map((v, i) => <ChildContent
          kijshopCd={kijshopCd}
          key={`favorite-brand-config_${i}_${v.id}_${v.name}_${getChildrenName(v)}`}
          id={String(v.id)}
          content={v}
          layerIndent={treeIconWidth}
          layerDepth={layerDepth + 1}
          noChildIndent={noChildIndent}
          callbackSelectedContentID={(v) => {
            callbackSelectedContentID?.(v);
          }}
          selected={selectedId === v.id}
          selectedId={selectedId}
          callbackChangeOpenCount={handlerChangeOpenCount}
          callbackCreateData={callbackCreateData}
          folderList={folderList}
          favoriteDataList={favoriteDataList}
          parentId={id}
          openFolders={openFolders}
          openOperation={openOperation}
        />)
        : (<></>)}
    </>
  );
};

type FavoriteBrandConfigProps = {
  kijshopCd: string,
  // - 閉じるボタンの表示名 -
  closeButtonLabel?: string,
  // - 閉じるボタンcallback -
  closeButtonCallback?: () => void,
  // - OKボタンcallback -
  okButtonCallback?: (id: string) => void,
  // - お気に入り商品保存ダイアログか -
  isSaveDialog?: boolean,
  // - OKボタン押下フラグ -
  disabled?: boolean,
};
export const FavoriteBrandConfig = (props: FavoriteBrandConfigProps) => {
  const { kijshopCd, closeButtonLabel, closeButtonCallback, okButtonCallback, isSaveDialog, disabled: _disabled } = props;
  // - Hooks -
  const dispatch = useDispatch();
  const { brandList, selectBrand, brandDataList } = useAppSelector((state) => ({
    brandList: state.favoriteBrand.brandList,
    selectBrand: state.favoriteBrand.selectBrand,
    brandDataList: state.favoriteBrand.brandDataList,
  }), lodash.isEqual);

  // - State -
  const [treeIconWidth, setTreeIconWidth] = useState(0);
  const [selectedId, setSelectedId] = useState('');
  const [folderList, setFolderList] = useState<FavoriteFolderListItem[]>([]);
  const [favoriteDataList, setFavoriteDataList] = useState<FavoriteBrandItem[]>([]);
  const [disabled, setDisabled] = useState(_disabled);
  const [childrenOpenCountArr, setChildrenOpenCountArr] = useState<number[]>([]);
  const [loading, setLoading] = useState(false);
  const [loadingInit, setLoadingInit] = useState(true);
  useEffect(() => {
    setDisabled(_disabled);
  }, [_disabled]);

  // - 他のダイアログ表示時に再度お気に入り商品設定ダイアログ表示する -
  const moveToConfigDialog = () => {
    dispatch(dialogActions.pop());
    dispatch(dialogActions.push({
      title: 'お気に入り商品設定',
      element: <FavoriteBrandConfig kijshopCd={kijshopCd} />,
      className:"favorite_brand_config__ancestor"
    }));
  };

  // - Callback -
  // -- 閉じるボタン押下 --
  const handlerClickClose = useCallback(() => {
    dispatch(dialogActions.pop());
    dispatch(favoriteBrandActions.setSelectBrand(undefined));
  }, []);
  // -- 削除ボタン --
  const handlerCLickDelete = useCallback(() => {
    if (selectBrand) {
      // dispatch(dialogActions.pop());
      dispatch(dialogActions.pushMessage({
        title: '確認',
        message: [
          `お気に入り商品名「${selectBrand.name}」のお気に入り商品情報を削除します。`,
          'よろしいですか？'
        ],
        buttons: [
          {
            label: 'いいえ',
            callback: () => {
              dispatch(dialogActions.pop());
              // moveToConfigDialog();
            },
          },
          {
            label: 'はい',
            callback: () => {
              dispatch(dialogActions.pop());
              if (selectBrand.type === 'brand') {
                dispatch(apiActions.run(
                  new ApiFavoriteDelete(selectBrand.id, { kijshopCd }),
                  {
                    onSuccess: () => {
                      dispatch(favoriteBrandActions.setSelectBrand(undefined));
                      handlerCreateListData();
                      // moveToConfigDialog();
                    },
                  },
                ));
              } else {
                // dispatch(favoriteBrandActions.deleteFolder(selectBrand));
                dispatch(apiActions.run(
                  new ApiFavoriteFolderDelete({
                    kijshopCd,
                    id: selectBrand.id,
                    kind: '0',
                  }, selectBrand.id),
                  {
                    onSuccess: (res) => {
                      // dispatch(dialogActions.pop());
                      handlerCreateListData();
                    },
                    onError: () => {
                      dispatch(dialogActions.pushMessage({
                        title: '確認',
                        message: ['削除に失敗しました。'],
                        buttons: [{
                          label: 'OK',
                          callback: () => {
                            dispatch(dialogActions.pop());
                          },
                        }],
                      }));
                    },
                  },
                ));
                // moveToConfigDialog();
              }
            },
          },
        ],
      }));
    }
  }, [selectBrand]);
  // -- フォルダ作成ボタン --
  const handlerClickAddFolder = useCallback(() => {
    // dispatch(dialogActions.pop());
    const findData = (data: FavoriteBrandItem, level: number): { id: string, level: number } | undefined => {
      if (data.id === selectedId) {
        return { id: data.type === 'brand' ? data.parentId : data.id, level: level };
      } else {
        let fav!: { id: string, level: number } | undefined;
        data.children.forEach((v) => {
          if (!fav) {
            fav = findData(v, level + 1);
          }
        });
        // const fav: { id: string, level: number } | undefined = data.children.find((v) => findData(v, level + 1));
        if (fav) {
          level = level > fav.level ? level : fav.level;
          // level = fav.level;
          return { id: data.type === 'brand' ? data.parentId : data.id, level: level };
          // return { id: data.type === 'brand' ? data.parentId : data.id, level: fav.level };
        }
      }
      return undefined;
    };
    let level = 0;
    favoriteDataList.forEach((v) => {
      const data = findData(v, 1);
      if (data) {
        level = data.level;
      }
    });
    if (level >= 3) {
      dispatch(dialogActions.pushMessage({
        title: '確認',
        message: [
          '3階層以上になるフォルダ追加はできません',
        ],
        buttons: [
          {
            label: 'はい',
            callback: () => dispatch(dialogActions.pop()),
          },
        ],
      }));
      return;
    }
    dispatch(dialogActions.push({
      title: 'フォルダ追加',
      element: <AddFolder
        isIncludeSelectFolder={false}
        // type={'brand'}
        type={'brand'}
        kijshopCd={kijshopCd}
        parentId={selectedId}
        level={level ? level + 1 : undefined}
        getList={handlerCreateListData}
        list={folderList}
      />
    }));
  }, [selectedId, selectBrand, folderList]);
  // -- 名称変更ボタン --
  const handlerClickChangeName = useCallback(() => {
    if (selectBrand) {
      // dispatch(dialogActions.pop());
      if (selectBrand.type === 'brand') {
        dispatch(dialogActions.push({
          title: 'お気に入り商品名称変更',
          element: <ChangeFavoriteBrandName
            kijshopCd={kijshopCd}
            target={selectBrand}
            type={isSaveDialog ? 'save' : 'config'}
            callback={{
              onSuccess: () => {
                dispatch(dialogActions.pop());
                handlerCreateListData();
              },
              onError: () => {
                dispatch(dialogActions.pop());
                dispatch(dialogActions.pushMessage({
                  title: '確認',
                  message: ['更新に失敗しました'],
                  buttons: [{
                    label: 'OK',
                    callback: () => {
                      dispatch(dialogActions.pop());
                    },
                  }],
                }));
              },
            }}
          />
        }));
      } else {
        dispatch(dialogActions.push({
          title: 'フォルダ名変更',
          element: <ChangeFolderName
            kijshopCd={kijshopCd}
            targetFolder={selectBrand}
            callback={{
              onSuccess: () => {
                handlerCreateListData();
              },
              onError: () => {
                // dispatch(dialogActions.pop());
                dispatch(dialogActions.pushMessage({
                  title: '確認',
                  message: ['更新に失敗しました'],
                  buttons: [{
                    label: 'OK',
                    callback: () => {
                      dispatch(dialogActions.pop());
                    },
                  }],
                }));
              },
            }}
          />
        }));
      }
    }
  }, [isSaveDialog, selectBrand, folderList]);
  // -- OKボタン --
  const handlerClickOk = useCallback(() => {
    if (selectBrand) {
    }
  }, [selectBrand, brandDataList]);

  // -- お気に入り商品フォルダ一覧取得 --
  const handlerCreateListData = useCallback((content?: FavoriteBrandItem) => {
    setLoading(true);
    dispatch(apiActions.run(
      new ApiFavoriteFolderListGet({ kijshopCd }),
      {
        onSuccess: async (res: ResponseBase<FavoriteGetFolderResponse>) => {
          // setFolderList(res.body.data?.folderList || []);
          const data = [...(res.body.data?.folderList || [])].sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);
          setFolderList([...data]);
          const arr: FavoriteBrandItem[] = [];
          const findParent = (child: FavoriteFolderListItem, parentArr: FavoriteBrandItem[]) => {
            let flag = false;
            const parent = parentArr.find((v) => v.id === child.parentId);
            if (parent && !parent.children.find((v) => v.id === child.folderId)) {
              flag = true;
              parent.children.push({
                id: child.folderId,
                name: child.name,
                type: 'folder',
                level: child.level,
                parentId: parent.id,
                children: [],
              });
            } else {
              parentArr.forEach((v) => {
                flag = flag || findParent(child, v.children);
              });
            }
            return flag;
          };
          let len = data.length;
          const childArr: FavoriteBrandItem[] = [];
          while (data.length) {
            data.forEach((v) => {
              if (!v.parentId) {
                arr.push({
                  id: v.folderId,
                  name: v.name,
                  type: 'folder',
                  level: v.level,
                  parentId: '',
                  children: [],
                });
              } else {
                if (findParent(v, arr)) {
                  childArr.push({
                    id: v.folderId,
                    name: v.name,
                    type: 'folder',
                    level: v.level,
                    parentId: '',
                    children: [],
                  });
                }
              }
            });
            arr.forEach((v) => {
              const index = data.findIndex((d) => d.folderId === v.id);
              if (index !== -1) {
                data.splice(index, 1);
              }
            });
            childArr.forEach((v) => {
              const index = data.findIndex((d) => d.folderId === v.id);
              if (index !== -1) {
                data.splice(index, 1);
              }
            });
            // data.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);
            if (len === data.length) {
              break;
            }
            len = data.length;
          }
          // arr.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);
          setFavoriteDataList(lodash.cloneDeep(arr));
          let cnt = arr.length;
          const loadingEnd = () => {
            cnt--;
            if (cnt < 1) {
              setLoading(false);
              setLoadingInit(false);
            }
          };
          loadingEnd();
          arr.forEach((v) => {
            dispatch(apiActions.run(
              new ApiFavoriteGet({ kijshopCd, folederId: v.id }),
              {
                onSuccess: (res: ResponseBase<FavoriteGetResponse>) => {
                  loadingEnd();
                  if (res.body.data?.favProductList?.[0]) {
                    setFavoriteDataList((prev) => {
                      const list = [...prev].sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);
                      const dataIndex = list.findIndex((p) => p.id === v.id);
                      if (dataIndex !== -1) {
                        const data = lodash.cloneDeep(list[dataIndex]);
                        const arr = [...(res.body.data?.favProductList || [])].sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);
                        arr.forEach((v) => {
                          if (!data.children.find((c) => c.id === v.favProductId)) {
                            data.children.push({
                              id: v.favProductId,
                              name: v.name,
                              type: 'brand',
                              level: v.level,
                              parentId: data.id,
                              children: [],
                            } as FavoriteBrandItem);
                          }
                        });
                        // data.children.sort((a, b) => (a.type === 'folder' && a.type !== b.type ? 1 : (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)))
                        list[dataIndex] = data;
                      }
                      return list;
                    });
                  }
                },
                onError: loadingEnd,
              },
            ));
          });
        },
        onError: () => {
          setLoading(false);
        },
      },
    ));
  }, []);
  const handlerChangeOpenCount = useCallback((v, i) => {
    const arr = [...childrenOpenCountArr];
    arr[i] = v;
    setChildrenOpenCountArr(arr);
  }, [childrenOpenCountArr]);
  // - Effect -
  useEffect(() => {
    handlerCreateListData();
  }, []);
  const totalCount = (() => {
    let num = 0;
    childrenOpenCountArr.forEach((v) => {
      num += v || 0;
    });
    return num + favoriteDataList.length;
  })();

  // フォルダの開閉状態を保存
  const [openFolders, setOpenFolders] = useState<string[]>([]);
  const openOperation = (id: string ,operate: 'open' | 'close') => {
    if (operate === 'open') {
      setOpenFolders([...openFolders,id]);
    } else {
      const arr = openFolders.filter((v) => v !== id);
      setOpenFolders([...arr]);
    }
  }

  return (
    <div className="dialog_contents favorite_brand_config">
      <div className="dialog_contents__body favorite_brand_config__table">
        <div className="favorite_brand_config__table__inner">
          <div className="favorite_brand_config__table__header">お気に入り商品名</div>
          <div className="favorite_brand_config__table__body">
            {favoriteDataList.map((v, i) => (
              <ChildContent
                key={`favorite-brand-config_${i}_${v.id}_${v.name}_${getChildrenName(v)}_${loading}`}
                kijshopCd={kijshopCd}
                content={v}
                // id={`${i}_${v.id}`}
                id={String(v.id)}
                setTreeIconWidth={setTreeIconWidth}
                noChildIndent={treeIconWidth}
                isSaveDialog={isSaveDialog}
                callbackSelectedContentID={setSelectedId}
                selected={selectedId === v.id}
                selectedId={selectedId}
                // callbackGetData={handlerCreateListData}
                callbackChangeOpenCount={(num) => handlerChangeOpenCount(num, i)}
                callbackCreateData={handlerCreateListData}
                folderList={folderList}
                favoriteDataList={favoriteDataList}
                layerDepth={1}
                openFolders={openFolders}
                openOperation={openOperation}
              />
            ))}
            {totalCount < 9 ? ([...new Array(9 - totalCount)].map((_, i) => (
              <ChildContent
                key={`favorite-brand-config_${i}_dummy`}
                kijshopCd={kijshopCd}
                content={{
                  id: '',
                  name: '',
                  type: 'brand',
                  level: 1,
                  parentId: '',
                  children: [],
                }}
                id={`${i}_dummy`}
                setTreeIconWidth={setTreeIconWidth}
                noChildIndent={treeIconWidth}
                isSaveDialog={isSaveDialog}
                callbackSelectedContentID={setSelectedId}
                selected={false}
                callbackChangeOpenCount={() => {}}
                callbackCreateData={handlerCreateListData}
                folderList={folderList}
                favoriteDataList={favoriteDataList}
                openFolders={openFolders}
                openOperation={openOperation}
              />
            ))) : (<></>)}
            <ChildContent
              key={`favorite-brand-config_dummy`}
              kijshopCd={kijshopCd}
              content={{
                id: '',
                name: '',
                type: 'brand',
                level: 1,
                parentId: '',
                children: [],
              }}
              id={`dummy`}
              setTreeIconWidth={setTreeIconWidth}
              noChildIndent={treeIconWidth}
              isSaveDialog={isSaveDialog}
              callbackSelectedContentID={setSelectedId}
              selected={false}
              callbackChangeOpenCount={() => {}}
              callbackCreateData={handlerCreateListData}
              folderList={folderList}
              favoriteDataList={favoriteDataList}
              openFolders={openFolders}
              openOperation={openOperation}
            />
          </div>
        </div>
      </div>
      <div className="dialog_contents__footer">
        <div className="dialog_contents__footer__left">
          <Button
            label={closeButtonLabel ? closeButtonLabel : '閉じる'}
            onClick={closeButtonCallback ? closeButtonCallback : handlerClickClose}
          />
        </div>
        <div className="dialog_contents__footer__center">
          <Button
            label="削除"
            color="danger"
            onClick={handlerCLickDelete}
            disabled={!selectBrand}
          />
          <Button
            label="フォルダ追加"
            onClick={handlerClickAddFolder}
          // disabled
          />
          <Button
            label="名称変更"
            onClick={handlerClickChangeName}
            disabled={!selectBrand}
          />
        </div>
        <div className="dialog_contents__footer__right">
          {isSaveDialog || okButtonCallback ? (
            <Button
              label="OK"
              onClick={okButtonCallback ? () => okButtonCallback(selectedId) : handlerClickOk}
              disabled={!selectBrand || (!isSaveDialog && (!okButtonCallback || selectBrand.type !== 'brand')) || disabled}
            />
          ) : (<></>)}
        </div>

      </div>
      {loadingInit ? <LoadingPopup key={`fav-loading`} /> : <></>}
      {!loadingInit && loading ? <LoadingPopup key={`fav-loading`} label={'更新中です'} /> : <></>}
    </div>
  );
};
