import { OrdersSliceState } from '../../slices/orders-slice';
import { OrdersDataState } from '../../slices/orders/orders-data-slice';
import { CreateOrderItemData } from './create-order-item-data';
import { VisibleConditionValueGetter } from '../../manager/visible-condition-value-getter';
import { checkEditable, checkOptional } from './item-detail-item-data';

const categorize = (v: string) => `商品情報-商品詳細-本身-${v}`;
export const genuineItemData = (order: OrdersSliceState, data: OrdersDataState, visibleCondition: VisibleConditionValueGetter): CreateOrderItemData[] => {
  // const display = genuineOrderDisplayItem(order, data);
  const display = visibleCondition.genuineOrderDisplay;
  const {
    laminate: _laminate,
    additional: _additional,
    additionalMulti,
    chronologies,
    page,
    genuineProcessing: _genuineProcessing,
    leafText1,
    leafText2,
  } = order.genuine;
  const laminate = _laminate.value;
  const additional = _additional.value;
  const genuineProcessing = _genuineProcessing.value;
  return ([
    {
      // ラミ選択
      // ラミ選択情報があり(写真アルバムプロフラグが有効もしくはカテゴリが特定の値もしくは商品IDが特定の値の場合)表示
      name: 'laminate',
      value: laminate,
      editable: checkEditable(display.laminate),
      optional: checkOptional(display.laminate),
      display: Boolean(display.laminate),
      categorize: categorize('ラミ選択'),
    },
    {
      // 表紙加工
      // 後加工情報が有効かつカバーIDが特定の値の場合に表示
      //  L 商品IDが選択された場合に後加工情報を取得
      //  L 商品選択が選択された場合にカバーIDを取得
      // 自動表紙加工情報がある場合に内部的に分岐
      //  L 表紙色情報が設定された時に取得？（SQLの条件句にop01_coverColor_pre_select）
      name: 'leafColor',
      value: genuineProcessing,
      editable: checkEditable(display.leafColor),
      optional: checkOptional(display.leafColor),
      // optional: isOptionalLeafColor(order.itemDetail.coverSelect.value),
      display: Boolean(display.leafColor),
      categorize: categorize('加工情報'),
    },
    {
      // 箔押し文字1
      // 表紙加工が特定の値の場合に表示。表紙加工記載の最初の条件のみ適用
      name: 'leafText1',
      value: leafText1,
      editable: checkEditable(display.leafText1),
      optional: checkOptional(display.leafText1),
      display: Boolean(display.leafText1),
      categorize: categorize('箔押し文字1'),
      validation: (v) => v.length <= 24,
    },
    {
      // 箔押し文字2
      // 表紙加工が特定の値の場合に表示。表紙加工記載の最初の条件のみ適用
      name: 'leafText2',
      value: leafText2,
      editable: checkEditable(display.leafText2),
      optional: checkOptional(display.leafText2),
      display: Boolean(display.leafText2),
      categorize: categorize('箔押し文字2'),
      validation: (v) => v.length <= 24,
    },
    // {
    //   // 年表
    //   // カテゴリが jp0328 の場合に表示
    //   name: 'leafColor',
    //   editable: true,
    //   optional: false,
    //   display: display.leafColor,
    //   categorize: categorize('年表'),
    // },
    // {
    //   // 部材選択
    //   // 部材選択情報がありカテゴリが jp0325 ではなく(非フロー変更版注文有無が有効もしくは部材選択情報が無い場合)表示
    //   name: 'board',
    //   editable: true,
    //   optional: false,
    //   display: display.board,
    //   categorize: categorize('部材選択'),
    // },
    {
      // 見返し選択
      // 商品付加オプション情報があり商品種が jptg300162 でなくカテゴリと商品IDが特定の値で無い場合に表示
      //  L 商品IDがある場合に商品付加オプション情報を取得
      name: 'additional',
      value: additional,
      editable: checkEditable(display.additional),
      optional: checkOptional(display.additional),
      display: Boolean(display.additional),
      categorize: categorize('見返し選択'),
    },
    {
      // 台紙貼り
      // 商品付加オプション情報がありカテゴリが jp0491 ではなく(商品種が jptg300162 もしくはカテゴリが jp0392 もしくは商品IDが jptg301599 の場合)表示
      name: 'additional2',
      value: additional,
      editable: checkEditable(display.additional2),
      optional: checkOptional(display.additional2),
      display: Boolean(display.additional2),
      categorize: categorize('台紙貼り'),
    },
    {
      // テープ選択
      // 商品付加オプション情報がありカテゴリが jp0491 の場合に表示
      name: 'additional3',
      value: additional,
      editable: checkEditable(display.additional3),
      optional: checkOptional(display.additional3),
      display: Boolean(display.additional3),
      categorize: categorize('テープ選択'),
    },
    {
      // 付加情報
      // 商品IDが特定の値の場合にデフォルトでチェック？
      // 商品付加オプション情報がありカテゴリが jp0328 の場合に表示
      name: 'additionalMulti',
      value: additional,
      editable: checkEditable(display.additionalMulti),
      optional: checkOptional(display.additionalMulti),
      display: Boolean(display.additionalMulti),
      categorize: categorize('付加情報'),
    },
    {
      // 年表選択
      // 年表情報がある場合に表示
      //  L 商品IDがある場合に年表情報を取得
      name: 'chronology',
      value: chronologies.value,
      editable: checkEditable(display.chronology),
      optional: checkOptional(display.chronology),
      display: Boolean(display.chronology),
      categorize: categorize('年表選択'),
    },
    {
      // ページ数
      // カテゴリが jp0310 でなく(注文方法選択結果が10(画像を選択しておまかせ)でないもしくはページ数（条件用）が0の場合)に表示
      // デザインタイプ2が選択されていてページ数（条件用）が存在する場合にも表示（内部的に分岐）
      name: 'page',
      value: String(page),
      editable: checkEditable(display.page),
      optional: checkOptional(display.page),
      display: Boolean(display.page),
      categorize: categorize('ページ数'),
    },
  ]);
}
