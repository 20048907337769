import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '../app/store';
import { apiActions } from './api-slice';
import { ApiMasterXml } from '../api/back/xml/api-master-xml';
import { XmlParser } from '../manager/xml-parser';
import { IMasterShop } from '../models/i-master-shop';
import { systemConfigActions } from './system-config-slice';
import { IMasterEmergencyAlert } from '../models/i-master-emergency-alert';
import { XmlFactory } from '../xml/factory/xml-factory';
import { OrdersResponse } from '../models/api/back/orders';
import { ApiOrders } from '../api/back/orders/api-orders';
import { ResponseBase } from '../api/response-base';
import { IMasterProductNoJudgeItem } from '../models/i-master-product-no-judge-item';
import { IMasterProductItemInfo } from '../models/i-master-product-item-info';
import { dialogActions } from '../components/dialog/slice/dialog-slice';
import { TVisibleCondition } from '../models/t-visible-condition';
import { createOrderActions } from './create-order-slice';
import { PageTypeListData } from './layout-slice';
import { ApiCheckStaffLogin, ApiCheckStaffMngEnabled } from '../api/front/wizard/api-staff-management';
import { CheckStaffLoginResponse } from '../models/api/front/staff-management';
import { localStorageActions } from './local-storage-slice';
import { TMasterAuthority } from '../models/t-master-authority';
import { ApiStaffAuthGet } from '../api/front/staff-auth/api-staff-auth';
import { StaffAuthResponse } from '../models/api/front/staff-auth';

type Data = {
  // バージョン
  version: string,
  // master-product
  // masterProduct: IMasterProduct | null,
  // master-shop
  masterShop: IMasterShop | null,
  // master-emergency-alert
  masterEmergencyAlert: {
    xml: IMasterEmergencyAlert,
    lock: boolean,
  } | null,
  // master-no-judge-item
  masterProductNoJudgeItem: IMasterProductNoJudgeItem | null,
  masterProductItemInfo: IMasterProductItemInfo | null,
  createOrderVisibleData: TVisibleCondition | null,
  masterAuthority: TMasterAuthority[] | null;
};

type TEmergencyAlert = {
  message: string[],
  isLooked: '0' | '1',
}

type CommonSliceState = {
  // 店舗コード
  // kijshopCd: string,
  // 店舗名
  shopName: string,
  // 注文番号
  // shopOrderId: string,
  // お客様名
  customerName: string,
  // orderId（front api で採番）
  customerOrderId: string,
  // masterShopIdList
  masterShopIdList: string[],
  // 固定データ
  data: Data,
  emergencyAlert: TEmergencyAlert,
  // ラボ発注中フラグ
  orderingShopId: string,
  // ラボ発注以降の注文一覧
  orderedList: OrdersResponse | null,
  // masterPageType
  pageTypeList: PageTypeListData,
  // ブラウザチェック
  browserCheck: boolean,
  isChrome: boolean,
  // お知らせ冒頭
  news: string,
  // スタッフ管理機能ON/OFF
  isStaffManagementAuth: boolean,
  // リダイレクト済みかどうか
  isRedirect: boolean,
  // スタッフ情報
  staffInfo: CheckStaffLoginResponse | null,
};

const initialState: CommonSliceState = {
  // kijshopCd: '',
  shopName: '',
  // shopOrderId: '',
  customerName: '',
  customerOrderId: '',
  masterShopIdList: [],
  data: {
    version: '',
    // masterProduct: null,
    masterShop: null,
    masterEmergencyAlert: null,
    masterProductNoJudgeItem: null,
    masterProductItemInfo: null,
    createOrderVisibleData: null,
    masterAuthority: null,
  },
  emergencyAlert: {
    message: [],
    isLooked: '0',
  },
  orderingShopId: '',
  orderedList: null,
  pageTypeList: {
    cover: [],
    top: [],
    page: [],
    endCover: [],
    option: [],
    opPrint: [],
  },
  browserCheck: false,
  isChrome: false,
  news: '',
  isStaffManagementAuth: false,
  isRedirect: false,
  staffInfo: {
    id: '',
    staffId: '',
    staffIdType: 0,
    name: '',
    mailaddress: '',
    status: false,
    statusName: '',
    lastLogin: null,
    loginFaild: 0,
    lockExpiredAt: null,
    authority: 0,
    authorityName: '',
    validFlg: false,
  },
};

export const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    // setKijshopCd: (state, action: PayloadAction<string>) => {
    //   state.kijshopCd = action.payload;
    // },
    setShopName: (state, action: PayloadAction<string>) => {
      state.shopName = action.payload;
    },
    // setShopOrderId: (state, action: PayloadAction<string>) => {
    //   state.shopOrderId = action.payload;
    // },
    setCustomerName: (state, action: PayloadAction<string>) => {
      state.customerName = action.payload;
    },
    setCustomerOrderId: (state, action: PayloadAction<string>) => {
      state.customerOrderId = action.payload;
    },
    setData: (state, action: PayloadAction<Partial<Data>>) => {
      if (action.payload.version) {
        XmlFactory.version = action.payload.version;
      }
      state.data = Object.assign(state.data, action.payload);
    },
    setIdList: (state, action: PayloadAction<string[]>) => {
      state.masterShopIdList = action.payload;
    },
    setEmergencyAlert: (state, action: PayloadAction<TEmergencyAlert>) => {
      state.emergencyAlert = action.payload;
    },
    setOrderingShopId: (state, action: PayloadAction<string>) => {
      state.orderingShopId = action.payload;
    },
    setOrderedList: (state, action: PayloadAction<OrdersResponse>) => {
      state.orderedList = action.payload;
    },
    setCreateOrderVisibleData: (state, action: PayloadAction<TVisibleCondition>) => {
      state.data.createOrderVisibleData = action.payload;
    },
    setPageTypeList: (state, action: PayloadAction<any[]>) => {
      action.payload.forEach((v) => {
        switch (v.$.type) {
          case 'op_print':
            state.pageTypeList.opPrint = [...state.pageTypeList.opPrint, {pageTypeID: v.$.pageTypeID, type: v.$.type}];
            break;
          case 'cover':
          case 'frame':
            state.pageTypeList.cover = [...state.pageTypeList.cover, {pageTypeID: v.$.pageTypeID, type: v.$.type}];
            break;
          case 'top':
            state.pageTypeList.top = [...state.pageTypeList.top, {pageTypeID: v.$.pageTypeID, type: v.$.type}];
            break;
          case 'page':
            state.pageTypeList.page = [...state.pageTypeList.page, {pageTypeID: v.$.pageTypeID, type: v.$.type}];
            break;
          case 'end':
            state.pageTypeList.endCover = [...state.pageTypeList.endCover, {pageTypeID: v.$.pageTypeID, type: v.$.type}];
            break;
          case  'option':
            state.pageTypeList.option = [...state.pageTypeList.option, {pageTypeID: v.$.pageTypeID, type: v.$.type}];
            break;
          default :
            state.pageTypeList.page = [...state.pageTypeList.page, {pageTypeID: v.$.pageTypeID, type: v.$.type}];
            state.pageTypeList.option = [...state.pageTypeList.option, {pageTypeID: v.$.pageTypeID, type: v.$.type}];
        }
      });
    },
    setBrowserCheck: (state, action: PayloadAction<boolean>) => {
      state.browserCheck = action.payload;
    },
    setIdChrome: (state, action: PayloadAction<boolean>) => {
      state.isChrome = action.payload;
    },
    setNews: (state, action: PayloadAction<string>) => {
      state.news = action.payload;
    },
    setIsStaffManagementAuth: (state, action: PayloadAction<boolean>) => {
      state.isStaffManagementAuth = action.payload;
    },
    setIsRedirect: (state, action: PayloadAction<boolean>) => {
      state.isRedirect = action.payload;
    },
    setStaffInfo: (state, action: PayloadAction<CheckStaffLoginResponse>) => {
      state.staffInfo = action.payload;
    }
  },
});

type Target = {
  version?: boolean,
  // masterProduct?: boolean,
  masterShop?: boolean,
  masterEmergencyAlert?: boolean,
  orderedList?: boolean
};

const asyncAction = {
  getData: (kijshopCd: string, target?: Target): AppThunk => async (dispatch, getState) => {
    const { version, masterShop, masterEmergencyAlert, masterProductNoJudgeItem, masterProductItemInfo, createOrderVisibleData, masterAuthority } = getState().common.data;
    const tVersion = target ? target.version : true;
    // const tMasterProduct = target ? target.masterProduct : true;
    const tMasterShop = target ? target.masterShop : true;
    const tMasterEmergencyAlert = target ? target.masterEmergencyAlert : true;
    const tOrderedList = target ? target.orderedList : true;
    const orderedList = getState().common.orderedList;
    const pageTypeList = getState().common.pageTypeList;
    if (!version && tVersion) {
      dispatch(apiActions.run(
        new ApiMasterXml('master'),
        {
          onSuccess: (res) => {
            if (res?.body?.data) {
              new XmlParser()
                .parse<any>((res.body.data))
                .then((result) => {
                  if (result?.lnwMaster?.$?.version) {
                    dispatch(commonActions.setData({
                      version: result.lnwMaster.$.version,
                    }));
                  } else {
                    dispatch(dialogActions.pushSeriousError());
                  }
                })
                .catch(() => {
                  dispatch(dialogActions.pushSeriousError());
                });
            } else {
              dispatch(dialogActions.pushSeriousError());
            }
          },
          onError: () => {
            dispatch(dialogActions.pushSeriousError());
          },
        },
      ));
    }
    // if (!masterProduct && tMasterProduct) {
    //   dispatch(apiActions.run(
    //     new ApiMasterXml('master-product'),
    //     {
    //       onSuccess: (res) => {
    //         if (res?.body?.data) {
    //           new XmlParser()
    //             .parse<IMasterProduct>((res.body.data))
    //             .then((result) => {
    //               dispatch(commonActions.setData({
    //                 masterProduct: result,
    //               }));
    //               dispatch(createOrderActions.diVisibleConditionValueGetter({ masterData: result, kijshopCd }));
    //             });
    //         }
    //       },
    //       onError: () => {
    //         dispatch(dialogActions.pushSeriousError());
    //       },
    //     },
    //   ));
    // }
    if (!masterShop && tMasterShop) {
      dispatch(apiActions.run(
        new ApiMasterXml('master-shop'),
        {
          onSuccess: (res) => {
            if (res.body.data) {
              new XmlParser()
                .parse<IMasterShop>((res.body.data))
                .then((result) => {
                  dispatch(commonActions.setData({
                    masterShop: result,
                  }));
                  const list = result.lnwMasterShop.shop?.[0].shopData;
                  let masterShopIdList: string[] = [];
                  list?.forEach((v) => {
                    if (v.$.shopCode) {
                      masterShopIdList.push(v.$.shopCode.toString());
                    }
                  });
                  dispatch(commonActions.setIdList(masterShopIdList));
                  dispatch(systemConfigActions.get(kijshopCd));
                })
                .catch(() => {
                  dispatch(commonActions.setIdList([]));
                });
            } else {
              dispatch(commonActions.setIdList([]));
              // dispatch(systemConfigActions.get(kijshopCd));
            }
          },
          onError: () => {
            dispatch(dialogActions.pushSeriousError());
          },
        },
      ));
    }
    if (!masterEmergencyAlert && tMasterEmergencyAlert) {
      dispatch(apiActions.run(
        new ApiMasterXml('masterEmergencyAlert'),
        {
          onSuccess: (res) => {
            if (res.body.data) {
              new XmlParser()
                .parse<IMasterEmergencyAlert>(res.body.data)
                .then((result) => {
                  const start = new Date(result?.lnwMasterEmergencyAlert?.data?.[0]?.alert?.[0]?.$.startDate || '');
                  const end = new Date(result?.lnwMasterEmergencyAlert?.data?.[0]?.alert?.[0]?.$.endDate || '');
                  const current = new Date();
                  if (start < current && current < end) {
                    dispatch(commonActions.setData({
                      masterEmergencyAlert: { xml: result, lock: false },
                    }));
                  }
                });
            }
          },
          onError: (e) => {
            // console.group('PPM_masterEmergencyAlert');
            // console.error(e);
            // console.groupEnd();
          },
        },
        { ignoreSystemError: true }
      ));
    }
    if (!masterProductNoJudgeItem) {
      dispatch(apiActions.run(
        new ApiMasterXml('master-product-no-judge-item'),
        {
          onSuccess: (res) => {
            if (res?.body?.data) {
              new XmlParser()
                .parse<IMasterProductNoJudgeItem>(res.body.data)
                .then((result) => {
                  dispatch(commonActions.setData({
                    masterProductNoJudgeItem: result,
                  }));
                });
            }
          },
          onError: () => {
            dispatch(dialogActions.pushSeriousError());
          },
        },
      ));
    }
    if (!masterProductItemInfo) {
      dispatch(apiActions.run(
        new ApiMasterXml('master-product-item-info'),
        {
          onSuccess: (res) => {
            if (res?.body?.data) {
              new XmlParser()
                .parse<IMasterProductItemInfo>(res.body.data)
                .then((result) => {
                  dispatch(commonActions.setData({
                    masterProductItemInfo: result,
                  }));
                });
            }
          },
          onError: () => {
            dispatch(dialogActions.pushSeriousError());
          },
        },
      ));
    }
    if (!pageTypeList || !pageTypeList.cover.length) {
      dispatch(apiActions.run(
        new ApiMasterXml('master-page-type'),
        {
          onSuccess: (res) => {
            new XmlParser()
              .parse<any>((res.body.data))
              .then((result) => {
                dispatch(commonActions.setPageTypeList(result.lnwMasterPageType.pageType[0].pageTypeData))
              })
          },
          onError: () => {
            dispatch(dialogActions.pushSeriousError());
          },
        }
      ))
    }
    if (!orderedList && tOrderedList) {
      dispatch(apiActions.run(
        new ApiOrders({}),
        {
          onSuccess: (res: ResponseBase<OrdersResponse>) => {
            if (res?.body?.data?.order) {
              dispatch(commonActions.setOrderedList({
                order: res.body.data.order,
              }));
            } else if (String(res?.error?.errorCode) === '404') {
              dispatch(commonActions.setOrderedList({
                order: [],
              }));
            }
          },
          onError: () => {},
        },
      ));
    }
    if (!createOrderVisibleData) {
      // NOTE: PPM-CLOUD_masterProductSelectSql.jsonを変更する時はpublic直下の同ファイルを編集してここで読み込む
      // fetch('./PPM-CLOUD_masterProductSelectSql.json')
      //   .then((res) => res.json())
      //   .then((res) => {
      //     dispatch(commonActions.setCreateOrderVisibleData(res));
      //     dispatch(createOrderActions.diVisibleConditionValueGetter({ visibleCondition: res, kijshopCd }));
      //   })
      //   .catch();
      dispatch(apiActions.run(
        new ApiMasterXml('visibleCondition'),
        {
          onSuccess: (res) => {
            if (res?.body?.data) {
              const result = JSON.parse(res.body.data);
              dispatch(commonActions.setCreateOrderVisibleData(result));
              dispatch(createOrderActions.diVisibleConditionValueGetter({ visibleCondition: result, kijshopCd }));
            }
          },
          onError: () => {
            dispatch(dialogActions.pushSeriousError());
          },
        },
        { ignoreSystemError: true },
      ));
    }
    if (!masterAuthority) {
      const getStaffInfo = () => new Promise<boolean>((resolve) => {
        dispatch(commonActions.getStaffLoginInfo(kijshopCd, (isStaff) => resolve(isStaff)));
      });
      const isStaffLogin = await getStaffInfo();
      if (isStaffLogin) return;
      dispatch(apiActions.run(
        new ApiStaffAuthGet({ kijshopCd }),
        {
          onSuccess: (res: ResponseBase<StaffAuthResponse[]>) => {
            if (res?.body?.data) {
              dispatch(commonActions.setData({
                masterAuthority: [...res.body.data],
              }))
            }
          },
          onError: () => {
            dispatch(dialogActions.pushSeriousError());
          },
        }
      ))
    }
  },
  checkBrowser: (): AppThunk => async (dispatch, getState) => {
    if (!getState().common.browserCheck) {
      let browser = '';
      const agent = window.navigator.userAgent.toLowerCase();
      if (agent.indexOf('msie') !== -1 || agent.indexOf('trident') !== -1) {
        browser = 'Internet Explorer';
      } else if (agent.indexOf('edg') !== -1 || agent.indexOf('edge') !== -1) {
        browser = 'Microsoft Edge';
      } else if (agent.indexOf('opr') !== -1 || agent.indexOf('opera') !== -1) {
        browser = 'Opera';
      } else if (agent.indexOf('chrome') !== -1) {
        browser = 'Chrome';
      } else if (agent.indexOf('safari') !== -1) {
        browser = 'Safari';
      } else if (agent.indexOf('firefox') !== -1) {
        browser = 'FireFox';
      }
      if (browser !== 'Chrome') {
        dispatch(dialogActions.pushMessage({
          title: '警告',
          message: [
            'このページの推奨のブラウザは【 Chrome 】です。',
            `現在ご利用のブラウザ（${browser}）は、動作保証の対象外です`,
          ],
          buttons: [{
            label: '確認',
            callback: () => dispatch(dialogActions.pop()),
          }],
        }));
      } else {
        dispatch(commonActions.setIdChrome(true));
      }
      dispatch(commonActions.setBrowserCheck(true));
    }
  },
  checkStaffManagementAuth: (kijshopCd: string): AppThunk => async (dispatch) => {
    dispatch(apiActions.run(
      new ApiCheckStaffMngEnabled({ kijshopCd }),
      {
        onSuccess: (res) => {
          if (res.error.errorCode === 404) {
            dispatch(commonActions.setIsStaffManagementAuth(false));
            return;
          }
          dispatch(commonActions.setIsStaffManagementAuth(true));
        },
      },
    ));
  },
  // 自分のスタッフ情報を取得
  getStaffLoginInfo: (kijshopCd: string, callback?: (isStaff: boolean) => void): AppThunk => async (dispatch) => {
    dispatch(apiActions.run(
      // - 自身のスタッフ情報を取得 -
      // スタッフ場合: errorCode=200→staffIdがあればstaffInfoをセット, なければログアウトしてスタッフログイン画面に戻す
      // 管理者の場合: errorCode=404→staffInfoは空のまま
      new ApiCheckStaffLogin({ kijshopCd }),
      {
        onSuccess: (res: ResponseBase<CheckStaffLoginResponse>) => {
          // スタッフログイン判定
          if (String(res?.error?.errorCode) === '200') {
            if(res?.body?.data?.staffId && res?.body?.data?.name) {
              dispatch(commonActions.setStaffInfo(res.body.data));
              dispatch(localStorageActions.setStaffIdAndName({kijshopCd, id: res.body.data.staffId, name: res.body.data.name}));
              callback?.(true);
            } else {
              // staffIdがなければスタッフログイン画面に戻す
              dispatch(apiActions.staffLogout({ kijshopCd }));
              callback?.(false);
              return;
            }
          }
          // 店舗管理者判定
          if(String(res?.error?.errorCode) === '404') {
            dispatch(localStorageActions.setStaffIdAndName({kijshopCd, id: '', name: ''}));
            callback?.(false);
          }
        },
        // エラー
        onBasicError: () => {
          dispatch(localStorageActions.setStaffIdAndName({kijshopCd, id: '', name: ''}));
          callback?.(false);
        }
      },
    ));
  }
};

export const commonActions = Object.assign(commonSlice.actions, asyncAction);
export const commonReducer = commonSlice.reducer;
