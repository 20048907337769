import './c-folder-order-list.scss';
import { useDispatch } from 'react-redux';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { dialogActions } from '../../dialog/slice/dialog-slice';
import { Button } from '../../ui/button/button';
import iconInfo from '../../../assets/img/icon/info_black_24dp.svg';
import { Header } from '../../ui/header/header';
import { Table } from '../../ui/table/table';
import { CFolderColorIcon } from '../../ui/color-icon/c-forder-color-icon';
import { PurposeFilter } from './purpose-filter';
import { CloudFolderTableFilter, StatusFilter } from './status-filter';
import { CFolderOrderDetailDialog } from '../../dialog/unique/c-folder-order-detail-dialog';
import { apiActions } from '../../../slices/api-slice';
import { ApiGetCloudFolderOrder } from '../../../api/front/cloud-folder/api-get-cloud-folder-order';
import { ResponseBase } from '../../../api/response-base';
import { commonActions } from '../../../slices/common-slice';
import { useParams } from 'react-router-dom';
import { PathParams } from '../../../routes/routing-path';
import { useAppSelector } from '../../../app/hooks';
import * as lodash from 'lodash';
import { localStorageActions } from '../../../slices/local-storage-slice';
import { CloudFolderOrder, CloudFolderOrderDetail } from '../../../models/cloud-folder-order';
import {
  RESULT_DOWNLOAD_SELECT_LIST,
  USAGE_IMAGE_SELECT_LIST,
} from '../../../models/cloud-folder-order-detail-view-model';
import { ApiRetouchGet, RetouchGetResponse } from '../../../api/back/retouch/api-retouch';
import { CloudFolderSearch, CloudFolderSearchOrder } from '../../dialog/unique/cloud-folder-search-order';
import { ApiGetCloudFolderOrderDetail } from '../../../api/front/cloud-folder/api-get-cloud-folder-order-detail';
import { ApiGetPurpose, PurposeInfo, ResponseGetPurpose } from '../../../api/back/purpose/api-get-purpose';

export type CloudFolderStatus = { value: number, label: string, color: string };

const tableHeadData = [
  {
    key: 'id',
    label: '注文番号',
  },
  {
    key: 'shopName',
    label: '得意先名',
  },
  {
    key: 'status',
    label: 'ステータス',
  },
  {
    key: 'name',
    label: '注文名',
  },
  {
    key: 'purposeName',
    label: '目的',
  },
  {
    key: 'arrivalDate',
    label: '入荷日',
  },
  {
    key: 'deliveryDate',
    label: '納期',
  },
  {
    key: 'imageNum',
    label: '入稿枚数',
  },
  {
    key: '',
    label: '',
  },
];

const TableHead = (props: { label: string, sort?: 'asc' | 'desc' }) => {
  const { label, sort } = props;
  return (
    <div>
      {label}
      {sort ? (
        <>
          {sort === 'asc' ? (' ▲') : ('')}
          {sort === 'desc' ? (' ▼') : ('')}
        </>
      ) : (<></>)}
    </div>
  );
};

export const CFolderOrderList = () => {
  const { kijshopCd } = useParams<PathParams>();
  const { master, storage } = useAppSelector((state) => ({
    master: state.common.data.masterShop,
    storage: state.storage,
  }), lodash.isEqual);

  const dispatch = useDispatch();

  const [orderList, setOrderList] = useState<CloudFolderOrder[]>([]);
  // -- 目的リスト --
  const [purposeList, setPurposeList] = useState<PurposeInfo[]>([]);
  // -- フィルター --
  const [statusFilter, setStatusFilter] = useState<CloudFolderTableFilter>('not-completed');
  const [selectPurpose, setSelectPurpose] = useState<string[]>([]);

  // -- ソート --
  const [sort, setSort] = useState<'asc' | 'desc'>('desc');
  // -- ソート対象 --
  const [sortTarget, setSortTarget] = useState('id');

  const [retouchMenuList, setRetouchMenuList] = useState<{ value: string, label: string }[]>([]);

  const tableHead = [...tableHeadData].map((v) => (
    <TableHead
      label={v.label}
      sort={(v.key && sortTarget === v.key) ? sort : undefined}
    />
  ));

  const getList = useCallback(() => {
    dispatch(apiActions.run(
      new ApiGetCloudFolderOrder({}),
      {
        onSuccess: (res: ResponseBase<CloudFolderOrder[]>) => {
          if (res.body.data) {
            setOrderList(res.body.data);
          }
        },
      },
    ));
  }, []);

  const handlerClickTableColumn = useCallback((index) => {
    const data = tableHeadData[index];
    if (data?.key) {
      if (data.key === sortTarget) {
        setSort(sort === 'asc' ? 'desc' : 'asc');
      } else {
        setSort('desc');
        setSortTarget(data.key);
      }
    }
  }, [sort, sortTarget]);

  const [searchCondition, setSearchCondition] = useState<CloudFolderSearch>({});

  const handlerClickSearchDialog = useCallback(() => {
    dispatch(dialogActions.push({
      title: '詳細の検索',
      element: <CloudFolderSearchOrder
        searchCondition={searchCondition}
        setSearchCondition={setSearchCondition}
      />,
    }));
  }, [searchCondition]);

  const handlerClickDetail = useCallback((_kijshopCd: string, orderId: string) => {
    dispatch(apiActions.run(
      new ApiGetCloudFolderOrderDetail({ kijshopCd: _kijshopCd, orderId }),
      {
        onSuccess: (res: ResponseBase<CloudFolderOrderDetail>) => {
          if (res.body.data) {
            dispatch(dialogActions.push({
              title: '詳細',
              element: <CFolderOrderDetailDialog
                orderData={res.body.data}
                retouchList={retouchMenuList}
              />,
            }));
          }
        },
      },
    ));
  }, [dispatch]);

  const getRetouchMenu = useCallback(() => {
    dispatch(apiActions.run(
      new ApiRetouchGet({}),
      {
        onSuccess: (res: ResponseBase<RetouchGetResponse>) => {
          const list = res.body.data?.[0].simpleRetouchInfo || [];
          setRetouchMenuList(list.map((v) => ({ value: v.retouchProductNameId, label: v.retouchProductName })));
        },
      },
    ));
  }, []);

  const filter = useCallback((list: CloudFolderOrder[]): CloudFolderOrder[] => {
    const searchList = list.filter((v) => {
      let flg = true;
      const sc = searchCondition;
      if (searchCondition.type === 'arrival') {
        if (sc.startDate && new Date(sc.startDate.replaceAll('-', '/')) > new Date(v.arrivalDate.split(' ')[0])
          || (sc.startDate && !v.arrivalDate)) flg = false;
        if (sc.endDate && new Date(sc.endDate.replaceAll('-', '/')) < new Date(v.arrivalDate.split(' ')[0])
          || (sc.endDate && !v.arrivalDate)) flg = false;
      }
      if (sc.type === 'delivery') {
        if (sc.startDate && new Date(sc.startDate.replaceAll('-', '/')) > new Date(v.deliveryDate.split(' ')[0])
          || (sc.startDate && v.deliveryDate)) flg = false;
        if (sc.endDate && new Date(sc.endDate.replaceAll('-', '/')) < new Date(v.deliveryDate.split(' ')[0])
          || (sc.endDate && v.deliveryDate)) flg = false;
      }
      if (sc.name && !v.name.match(sc.name.replaceAll(/[.*+?^${}()|[\]\\]/g, '\\$&'))) flg = false;
      if (sc.id && !v.id.match(sc.id)) flg = false;
      return flg;
    });
    const statusFilterArr = searchList.filter((v) => {
      switch (statusFilter) {
        case 'all':
          return true;
        case 'not-ordered':
          return v.status < 5;
        case 'processing':
          return v.status === 5 || v.status === 6;
        case 'processed':
          return v.status === 7 || v.status === 8;
        case 'completed':
          return v.status >= 9;
        case 'not-completed':
          return v.status < 9;
      }
    });
    return statusFilterArr.filter((v) => {
      return selectPurpose.some((purpose) => {
        return v.purpose === purpose;
      });
    });
  }, [statusFilter, selectPurpose, searchCondition]);

  const handlerClickCsvOutput = useCallback(() => {
    const list = filter(orderList).sort((a, b) => a.id < b.id ? -1 : 1);
    const ONE_STOP_CODE = 'jptg304515';
    let baseText = '得意先コード,得意先名,注文番号,ステータス,注文名(お客様名),目的,入荷日,納期,納品日,納品枚数,入稿枚数,セレクト希望枚数 最小,セレクト希望枚数 最大,１次セレクト結果のDL,1次セレクト結果のレタッチオプション,商品使用画像,商品に使用する枚数 最小,商品に使用する枚数 最大,商品使用画像のレタッチオプション,備考\n';
    list.forEach((v) => {
      const shopCode = v.kijshopCd;
      const shopName = v.shopName;
      const orderId = v.id;
      const status = v.statusName;
      const orderName = v.name;
      const purpose = v.purposeName;
      const arrivalDate = v.arrivalDate.split(' ')[0];
      const deliveryDate = v.deliveryDate ? v.deliveryDate.split(' ')[0] : '';
      const deliveredDate = v.deliveredDate ? v.deliveredDate.split(' ')[0] : '';
      const deliveredNum = v.resultImageNum || '';
      const fileNum = v.fileNum;
      const resultDownload = v.purpose === ONE_STOP_CODE ? RESULT_DOWNLOAD_SELECT_LIST.find((v2) => v2.value === String(v.isSelectedDownload))?.label : '';
      const selectMin = String(v.minSelectedNum || '');
      const selectMax = String(v.maxSelectedNum || '');
      const usageImg = USAGE_IMAGE_SELECT_LIST.find((v2) => v2.value === String(v.useImageKind))?.label || '';
      const usageMin = v.useImageKind === 1 ? String(v.minUseImageNum || '') : '';
      const usageMax = v.useImageKind === 1 ? String(v.maxUseImageNum || '') : '';
      const firstRetouch = retouchMenuList.find((v2) => v2.value === v.cullingRetouchOptId)?.label || '';
      const usageRetouch = retouchMenuList.find((v2) => v2.value === v.useImageRetouchOptId)?.label || '';
      const remarks = v.remarks.includes('\n') ? `"${v.remarks.replace(/"/g, '""')}"` : v.remarks;

      baseText += `="${shopCode}",${shopName},="${orderId}",${status},${orderName},${purpose},${arrivalDate},${deliveryDate},${deliveredDate},${deliveredNum},${fileNum},${selectMin},${selectMax},${resultDownload},${firstRetouch},${usageImg},${usageMin},${usageMax},${usageRetouch},${remarks}\n`;
    });
    const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
    const blob = new Blob([bom, baseText], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', 'order-list.csv');
    link.click();
  }, [orderList, filter]);

  const tableBody = useMemo(() => {
    return filter(orderList).sort((a: any, b: any) => {
        return sort === 'asc' ? a[sortTarget] < b[sortTarget] ? -1 : 1 : a[sortTarget] > b[sortTarget] ? -1 : 1;
      },
    ).map((order) => ([
      order.id,
      order.shopName,
      <div className={'status_view'}>
        <CFolderColorIcon status={order.status} />
        {' ' + order.statusName}
      </div>,
      order.name,
      order.purposeName || '未設定',
      order.arrivalDate,
      order.deliveryDate,
      order.imageNum + ' 枚',
      <Button
        label="詳細"
        icon={<img
          src={iconInfo}
          alt=""
        />}
        size="sm"
        color="light"
        onClick={() => handlerClickDetail(order.kijshopCd, order.id)}
      />,
    ]));
  }, [orderList, handlerClickDetail, sort, sortTarget, statusFilter, selectPurpose, searchCondition]);

  useEffect(() => {
    getList();
    getRetouchMenu();
  }, []);

  useEffect(() => {
    if (!master) {
      dispatch(commonActions.getData(kijshopCd));
    }
    dispatch(commonActions.checkBrowser());
    dispatch(commonActions.checkStaffManagementAuth(kijshopCd));
    if (storage.kijshopCd !== kijshopCd || storage.kijshopCd === '') {
      dispatch(localStorageActions.getLocalData(kijshopCd));
    }

    dispatch(apiActions.run(
      new ApiGetPurpose({}),
      {
        onSuccess: (res: ResponseBase<ResponseGetPurpose>) => {
          console.log(res.body.data);
          const list = res.body.data?.products || [];
          setPurposeList(list);
          setSelectPurpose([...list.map((v) => v.purposeId), '']);
        },
      },
    ));
  }, []);

  return (
    <>
      <div className="page_base">
        <Header page={''} />
        <div className="list_page_contents">
          <div className="c_order_list_table">
            <div className="c_order_list_table__menu_btn">
              <div>
                <StatusFilter
                  selectFilter={statusFilter}
                  setStatusFilter={setStatusFilter}
                  handlerClickSearch={handlerClickSearchDialog}
                />
              </div>
            </div>
            <div className="c_order_list_table__menu_btn">
              <div>
                <PurposeFilter
                  purposeList={purposeList}
                  selectPurpose={selectPurpose}
                  setPurposeFilter={setSelectPurpose}
                />
              </div>
              <div>
                <Button
                  label="CSV出力"
                  onClick={handlerClickCsvOutput}
                  icon={<i className="fas fa-save" />}
                  color="warning"
                />
              </div>
            </div>
            <div className={'c_order_list_table__table'}>
              <Table
                head={tableHead}
                body={tableBody}
                minimumNumOfRows={10}
                handlerClickColumn={handlerClickTableColumn}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
