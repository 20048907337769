import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Wizard } from '../../../ui/wizard/wizard';
import { Header } from '../../../ui/header/header';
import { Step1StaffMngEnableWizard } from './step1.staff-mng-enalbe.wizard';
import { Step2StaffMngEnableWizard } from './step2.staff-mng-enalbe.wizard';
import { Step3StaffMngEnableWizard } from './step3.staff-mng-enalbe.wizard';
import { Step4StaffMngEnableWizard } from './step4.staff-mng-enalbe.wizard';
import { Step5StaffMngEnableWizard } from './step5.staff-mng-enalbe.wizard';

export const StaffMngEnableWizard = () => {
  // - Hooks -
  const location = useLocation();
  // - State -
  const [step, setStep] = useState(0);
  const [token, setToken] = useState('');
  const [mailAddress, setMailAddress] = useState('');
  // - effect -
  useEffect(() => {
    const isToken = new URLSearchParams(location.search).has('token');
    // クエリパラメータにtokenがある場合は、step4へ遷移
    if (isToken) {
      setStep(4);
      setToken(new URLSearchParams(location.search).get('token') || '');
      return;
    }
    setStep(1);
  }, []);

  return (
    <div className="page_base">
      <Header page="" />
      <Wizard step={step}>
        <Step1StaffMngEnableWizard setStep={setStep} />
        <Step2StaffMngEnableWizard
          setStep={setStep}
          setMailAddress={setMailAddress}
        />
        <Step3StaffMngEnableWizard
          setStep={setStep}
          mailAddress={mailAddress}
        />
        <Step4StaffMngEnableWizard
          setStep={setStep}
          token={token}
        />
        <Step5StaffMngEnableWizard />
      </Wizard>
    </div>
  );
};