import './c-folder-order-detail.scss';
import { Button } from '../../ui/button/button';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { CFolderColorIcon } from '../../ui/color-icon/c-forder-color-icon';
import { toHumanReadableByte } from '../../../utilities/tu-human-readable';
import { useDispatch } from 'react-redux';
import { dialogActions } from '../slice/dialog-slice';
import { apiActions } from '../../../slices/api-slice';
import { ResponseBase } from '../../../api/response-base';
import {
  ApiGetCloudFolderOrderFile, CloudFolderFileInfo, CloudFolderGetFileList,
} from '../../../api/front/cloud-folder/api-get-cloud-folder-order-file';
import { CloudFolderOrderDetail } from '../../../models/cloud-folder-order';
import {
  CloudFolderOrderDetailViewModel,
  RESULT_DOWNLOAD_SELECT_LIST, USAGE_IMAGE_SELECT_LIST, VIEW_MODELS,
} from '../../../models/cloud-folder-order-detail-view-model';
import {
  ApiGetCloudFolderPurposeDetail,
  CloudFolderPurposeDetail,
} from '../../../api/front/cloud-folder/api-get-cloud-folder-purpose-detail';
import fileThumbnail from '../../../assets/img/icon/file_thumbnail.svg';
import loading from '../../ui/image/loading.gif';
import { ApiGetCloudFolderFileDownload } from '../../../api/cf/cloud-folder/api-get-cloud-folder-file-download';


type Props = {
  orderData: CloudFolderOrderDetail,
  retouchList: { label: string, value: string }[],
};

export const CFolderOrderDetailDialog = (props: Props) => {
  const { orderData, retouchList } = props;
  const dispatch = useDispatch();

  const handlerClickClose = useCallback(() => {
    dispatch(dialogActions.popAll());
  }, []);

  const [kind, setKind] = useState(1);
  const [folderList1, setFolderList1] = useState<CloudFolderFileInfo[]>([]);
  const [folderList2, setFolderList2] = useState<CloudFolderFileInfo[]>([]);

  const [viewControl, setViewControl] = useState<CloudFolderOrderDetailViewModel>(VIEW_MODELS[0].model);
  const [folderInfo, setFolderInfo] = useState<{ fileNum: number, size: number }>({ fileNum: 0, size: 0 });

  const getFileList = useCallback((kind: 1 | 2) => {
    dispatch(apiActions.run(
      new ApiGetCloudFolderOrderFile({ kijshopCd: orderData.kijshopCd, orderId: orderData.id, kind }),
      {
        onSuccess: (res: ResponseBase<CloudFolderGetFileList>) => {
          if (res.body.data) {
            if (kind === 1) {
              setFolderList1(res.body.data?.fileList);
            } else {
              setFolderList2(res.body.data?.fileList);
            }
            setFolderInfo({ fileNum: res.body.data.fileNum, size: res.body.data.totalSize });
          }
        },
      },
    ));
  }, []);

  useEffect(() => {
    if (orderData.purpose) {
      dispatch(apiActions.run(
        new ApiGetCloudFolderPurposeDetail({ kijshopCd: orderData.kijshopCd, purposeId: orderData.purpose }),
        {
          onSuccess: (res: ResponseBase<CloudFolderPurposeDetail>) => {
            const detail = res.body.data;
            if (detail) {
              const viewModel = VIEW_MODELS.find((v) => v.category === detail.categoryId);
              if (viewModel) {
                setViewControl(viewModel.model);
              }
            }
          },
        },
      ));
    }
    getFileList(1);
    // if (orderData.status >= 7) {
    //   getFileList(2);
    // }

  }, []);

  return (
    <>
      <div className="dialog_contents">
        <div className={'order-detail'}>

          <div className={'order-detail__left'}>
            <div className={'order-detail__info'}>
              <div className={'order-detail__info__label'}>注文番号</div>
              <div>{orderData.id}</div>
            </div>

            <div className={'order-detail__info'}>
              <div className={'order-detail__info__label'}>注文名（お客様名）</div>
              <div>{orderData.name}</div>
            </div>
            <div className={'order-detail__info'}>
              <div className={'order-detail__info__label'}>目的</div>
              <div>{orderData.purposeName || '未設定'}</div>
            </div>
            {
              viewControl.resultDownload ?
                <div className={'order-detail__info'}>
                  <div className={'order-detail__info__label'}>１次セレクト結果の画像DL</div>
                  <div>{RESULT_DOWNLOAD_SELECT_LIST.find((v) => v.value === String(orderData.isSelectedDownload))?.label}</div>
                </div> : <></>
            }
            {
              viewControl.select ||
              viewControl.resultDownload && orderData.isSelectedDownload === 1
                ?
                <div className={'order-detail__info'}>
                  <div className={'order-detail__info__label'}>セレクト希望枚数</div>
                  <div>{orderData.minSelectedNum}〜{orderData.maxSelectedNum}枚にセレクト</div>
                </div> : <></>
            }
            {
              viewControl.firstRetouchOption ?
                <div className={'order-detail__info'}>
                  <div className={'order-detail__info__label'}>1次セレクト結果のレタッチオプション（有料）</div>
                  <div>{retouchList.find((v) => v.value === orderData.cullingRetouchOptId)?.label || 'なし'}</div>
                </div> : <></>
            }
            {
              viewControl.usageImage ?
                <div className={'order-detail__info'}>
                  <div className={'order-detail__info__label'}>商品使用画像</div>
                  <div>{USAGE_IMAGE_SELECT_LIST.find((v) => v.value === String(orderData.useImageKind))?.label}</div>
                </div> : <></>
            }
            {
              viewControl.usageImage && orderData.useImageKind === 1 ?
                <div className={'order-detail__info'}>
                  <div className={'order-detail__info__label'}>商品に使う枚数</div>
                  <div>{orderData.minUseImageNum}〜{orderData.maxUseImageNum}枚にセレクト</div>
                </div> : <></>
            }
            {
              viewControl.usageImageRetouchOption ?
                <div className={'order-detail__info'}>
                  <div className={'order-detail__info__label'}>商品使用画像のレタッチオプション（有料）</div>
                  <div>{retouchList.find((v) => v.value === orderData.useImageRetouchOptId)?.label || 'なし'}</div>
                </div> : <></>
            }
            <div className={'order-detail__info'}>
              <div className={'order-detail__info__label'}>ステータス</div>
              <div className={'order-detail__info__status'}>
                <CFolderColorIcon status={orderData.status} />
                {orderData.statusName}
              </div>
            </div>
            {
              orderData.resultFileNum ?
                <div className={'order-detail__info'}>
                  <div className={'order-detail__info__label'}>納品数</div>
                  <div>{orderData.resultFileNum}枚</div>
                </div> : <></>
            }
            {
              viewControl.remark ?
                <div className={'order-detail__info'}>
                  <div className={'order-detail__info__label'}>備考</div>
                  <div>{orderData.remarks}</div>
                </div> : <></>
            }
          </div>
          <div className={'order-detail__right'}>
            <div className={'order-detail__file_info'}>
              <div>作成日 ：{orderData.createdAt}</div>
              <div>作成者 ：{orderData.uploadUserName}</div>
              <div>アップロード数：{folderInfo.fileNum}枚({toHumanReadableByte(folderInfo.size)})</div>
            </div>
            <div className={'order-detail__img_list'}>
              {
                orderData && folderList1 ?
                  <>{
                    folderList1.map((data) => (
                      <div key={`file-thumb-list-${data.id}`}>
                        <ImageComponent
                          data={data}
                          kijshopCd={orderData?.kijshopCd || ''}
                          orderId={orderData?.id || ''}
                        />
                      </div>
                    ))
                  }
                  </> : <></>
              }
            </div>
          </div>
        </div>
      </div>
      <div className="dialog_contents__footer c_order_detail__footer">
        <Button
          label={'閉じる'}
          color={'light'}
          onClick={handlerClickClose}
        />
      </div>
    </>
  );
};

type ImageProps = {
  data: CloudFolderFileInfo,
  kijshopCd: string,
  orderId: string,
}

export const ImageComponent = (props: ImageProps) => {
  const {
    data,
    kijshopCd,
    orderId,
  } = props;
  const dispatch = useDispatch();
  const [src, setSrc] = useState('');
  const init = useRef<boolean>(false);
  const cnt = useRef<HTMLDivElement>(null);
  const idRef = useRef(data.id);

  useEffect(() => {
    idRef.current = data.id;
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((v) => {
        if (v.isIntersecting && !init.current) {
          init.current = true;
          if (!data.pathThumb) {
            setSrc(fileThumbnail);
            return;
          }
          dispatch(apiActions.run(new ApiGetCloudFolderFileDownload({
              kijshopCd,
              orderId,
              path: `${data.pathThumb}/${data.orgFilename}`,
            }),
            {
              onSuccess: (res: Blob) => {
                if (idRef.current === data.id) {
                  const url = URL.createObjectURL(res);
                  setSrc(url);
                }
              },
            }));
        }
      });
    });
    if (cnt.current) {
      observer.observe(cnt.current);
    }

    return () => {
      observer.disconnect();
      init.current = false;
      setSrc('');
      if (src) {
        URL.revokeObjectURL(src);
      }
    };
  }, [data]);

  return (
    <div
      className={`image ${!src ? 'loading' : ''} ${!src ? '' : 'clickable'}`}
      ref={cnt}
    >
      <div className="image__wrap">
        <img
          src={src || loading}
          alt=""
        />
      </div>
      <label>{data.orgFilename}</label>
    </div>
  );
};
