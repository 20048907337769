import { TagsRequest, TagsResponse } from '../../../../models/api/back/create-order/tags';
import { AppThunk } from '../../../../app/store';
import { ordersDataActions } from '../../../../slices/orders/orders-data-slice';
import { apiActions } from '../../../../slices/api-slice';
import { ResponseBase } from '../../../response-base';
import { ApiBaseBack } from '../../api-base-back';
import { ordersActions } from '../../../../slices/orders-slice';

export class ApiTags extends ApiBaseBack<TagsResponse> {
  constructor(param: TagsRequest) {
    super(
      'GET',
      '/api/v1/tags',
      'JSON',
      param,
    );
  }
}

export const apiTagsThunk = (param: TagsRequest, callback: () => void, isInit: boolean, agent: boolean): AppThunk => async (dispatch, getState) => {
  if (!param.serviceId) {
    dispatch(ordersDataActions.updateItemInfo({
      tag: { productTag: [] },
    }));
    // dispatch(ordersActions.updateItemInfo({
    //   tag: undefined,
    // }));
    callback?.();
    return;
  }
  dispatch(apiActions.run(
    new ApiTags(param),
    {
      onSuccess: (res: ResponseBase<TagsResponse>) => {
        callback?.();
        if (!isInit && param.serviceId !== getState().order.itemInfo.category.value) {
          return;
        }
        dispatch(ordersDataActions.updateItemInfo({
          tag: { productTag: res.body.data?.productTag || [] },
        }));
        const tag = getState().order.itemInfo.tag;
        if (tag.value && res.body.data?.productTag?.find((v) => v.productTagId === tag.value)) {
          dispatch(ordersActions.itemInfo.setTag(tag.value, tag.label, agent));
        }
        // if (!res.body.data?.productTag?.length) {
        //   dispatch(ordersActions.updateItemInfo({
        //     tag: undefined,
        //   }));
        // }
      },
      onError: () => {
        callback?.();
      },
    },
    {
      apiName: 'tags',
      ignoreSystemError: true,
    },
  ));
};
