import { EditableImage } from '../image-edit/editable-image';
import { ResponseBase } from '../../../api/response-base';
import { CustomError } from '../../../models/custom-error';
import { ApiImagesPost, ImagesPostResponse } from '../../../api/front/images/api-images';
import { ApiCopyCloudFolderImage } from '../../../api/front/cloud-folder/api-copy-cloud-folder-image';

export class ConnectCloudFolderRequest {

  static MAX_RETRY = 3;

  request: ApiImagesPost;
  busy = false;

  private retryCounter = 0;
  private readonly onStart: () => void;
  private readonly onSuccess: (path: string) => void;
  private readonly onError: (msg: string) => void;

  constructor(
    editableImage: EditableImage,
    orderId: string,
    onStart: () => void,
    onSuccess: (path: string) => void,
    onError: (msg: string) => void,
  ) {
    this.onStart = onStart;
    this.onSuccess = onSuccess;
    this.onError = onError
    const requestData = {
      shopOrderId: editableImage.shopOrderId,
      kind: 6,
      kijshopCd: editableImage.kijshopCd,
      orderId: orderId,
      imageId: editableImage.id,
    };
    this.request = new ApiCopyCloudFolderImage(requestData);
  }

  async upload() {
    try {
      this.busy = true;
      this.onStart();
      const res = await this.request.do();
      if (res instanceof CustomError) {
        console.log('[upload] error ... : ', res);
        await this.retry();
      } else {
        if (String(res.error.errorCode) === '400') {
          console.error('error: 400');
          await this.retry();
        } else {
          const path = (res as ResponseBase<ImagesPostResponse>).body.data?.filename || '';
          console.log('[upload] success : ', path);
          this.onSuccess(path);
        }
      }
    } catch (e) {
      console.log('[upload] error ... : ', e);
      await this.retry();
    }
  }

  private async retry() {
    if (this.retryCounter >= ConnectCloudFolderRequest.MAX_RETRY) {
      this.onError('画像のアップロードに失敗しました。');
      return;
    }
    this.retryCounter += 1;
    console.log('[upload] retry : ', this.retryCounter);
    await this.upload();
  }

}
