import * as lodash from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../../app/hooks';
import { AlbumPage } from '../../../../layout-editor/albam/albam';
import {
  AcquiredTempInfo,
  AlbumDatas,
  layoutActions,
  LayoutItemData,
  TemplatesInfo,
} from '../../../../slices/layout-slice';
import { Image } from '../../../ui/image/image';
import './layout.select-template.scss';
import { UiManager } from '../../../../layout-editor/manager/ui/ui.manager';
import { UuidGenerator } from '../../../../utilities/uuid-generator';
import { dialogActions } from '../../../dialog/slice/dialog-slice';
import { TemplateLoaderManager } from '../../../../layout-editor/manager/template-loader/template-loader.manager';
import { useIntersectionObserver } from '../../../../hooks/intersection-observer';
import { useParams } from 'react-router-dom';
import { PathParams } from '../../../../routes/routing-path';
import { XmlStructureOrderPartsData } from '../../../../xml/model/xml-structure-model';
import { TOrderPageDataViewModel } from '../../../../xml/model/order/xml-order-page-data-model';
import { favoriteTemplateActions } from '../../../../slices/favorite-template-slice';
import { toolbarActions } from '../../../toolbar/slice/toolbar-slice';
import { store } from '../../../../app/store';
import { xmlActions } from '../../../../xml/slice/xml-slice';
import { EditableImage } from '../../../../layout-editor/manager/image-edit/editable-image';
import { SelectImgLayoutOrder } from '../../../dialog/unique/select-img-layout-order';
import { cloneDeep } from 'lodash';
import { getTargetPathUrl } from '../all-template/layout.all-template';
import { apiActions } from '../../../../slices/api-slice';
import { ApiFavoriteTemplateDelete } from '../../../../api/front/favorite-template/api-favorite-template';
import { WarningIcon } from '../../../ui/warning-icon/warning-icon';
import { DomContentTooltip } from '../../../ui/tooltip/dom-content-tooltip';

// 流し込み対応商品(categoryIDとitemIDで処理が違うので別でまとめる)
export const POSTCARD_ID = {
  category: [
    /* ポストカード */
    'jp0443',
    /* ポストカードブック */
    'jp0455',
    /* データサービス(Add 2022.11.17) */
    'jp0689',
  ],
  item: [
    /* ランコレ専用 ポストカードブックT SC(2017 販売終了) */
    // 'jptg302678',
    /* ランコレ専用 ポストカードブックY SC(2017 販売終了) */
    // 'jptg302679',
    /* HAGAKIプラスT */
    'jptg302915',
    /* HAGAKIプラスY */
    'jptg302917',
    /* DJ DVD書込み 無補正 */
    'jptg303604',
    /* TAW DVD書込み 無補正 */
    'jptg303637',
    /* TAW DVD書込み */
    'jptg303773',
    /* cotowaフォトカード(Add 2024.04.17) */
    'jptg304455',
    /* cotowaフォトカード 裏面(Add 2024.04.17) */
    'jptg304456',
  ],
};
// 流し込み時に右回転になる商品
export const RIGHT_ROTATE_LIST = {
  category: [
    /* ポストカード */
    'jp0443',
  ],
  item: [],
}

type LayoutItemDataProps = {
  parent: HTMLElement | undefined,
  tempInfo: TemplatesInfo[],
  isDropTemp: boolean,
  album: AlbumDatas,
  templatesXmlArr: any[],
  acquiredList: AcquiredTempInfo[],
  style?: React.CSSProperties,
  pageTypeIdArr: any[],
} & LayoutItemData;

export type ImgUploadSortParam = 'name' | 'date'
export type ImgUploadHighLowParam = 'high' | 'low'
export type ClickItemPram = {
  isDropDirect?: boolean,
  images?: EditableImage[],
  isDragTemp?: boolean,
}

export type PostCardType = 'left' | 'right' | undefined;

const LayoutItem = (props: LayoutItemDataProps) => {
  const {
    id,
    templateName,
    templateXmlUrl,
    thumbnail,
    targetPath,
    tempInfo,
    isDropTemp,
    album,
    templatesXmlArr,
    acquiredList,
    parent,
    favTempId,
    style,
    pageTypeIdArr,
  } = props;
  const { kijshopCd, shopOrderId, orderId } = useParams<PathParams>();
  const {
    xml,
    layoutList,
    deleteAlbumList,
    dragTemp,
    albumInfo,
    albumSetting,
    folderList,
    templateXmlArr,
    pageTypeList,
    selectFolder,
   } = useAppSelector((state) => ({
    xml: state.xml[shopOrderId],
    layoutList: state.layout.layoutList,
    deleteAlbumList: state.layout.deleteAlbumInfos,
    dragTemp: state.favoriteTemplate.draggingTemplate,
    albumInfo: state.layout.albumPages,
    albumSetting: state.layout.albumSettingData,
    folderList: state.layout.folderList,
    templateXmlArr: state.layout.templatesXmlList,
    pageTypeList: state.layout.pageTypeList,
    selectFolder: state.favoriteTemplate.selectFolder,
  }), lodash.isEqual);
  const dispatch = useDispatch();
  // -- Ref --
  const ref = useRef<HTMLDivElement>(null);
  const sort = useRef<ImgUploadSortParam>('name');
  const highLow = useRef<ImgUploadHighLowParam>('high');
  const wrapEle = useRef<HTMLDivElement>(null);
  // - State -
  const [ready, setReady] = useState(false);
  const [focus, setFocus] = useState(false);
  const [vertical, setVertical] = useState('0');
  const [horizon, setHorizon] = useState('0');
  const [sq, setSq] = useState('0');
  const [pageTypeID, setPageTypeID] = useState('');
  const [pageType, setPageType] = useState('');
  const [displayName, setDisplayName] = useState('');
  const [pageNo, setPageNo] = useState('');
  const [isDragging, setIsDragging] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  const [isClick, setIsClick] = useState(false);
  const [isUnMount, setIsUnMount] = useState(false);
  const [newThumbnail, setNewThumbnail] = useState(thumbnail);
  const [blob, setBlob] = useState<Blob | undefined>(undefined);
  const [zipLevel, setZipLevel] = useState({ isZip: false, level: 0 });
  const [templateXml, setTemplateXml] = useState<any>();
  const [isShowWarningIconTooltip, setIsShowWarningIconTooltip] = useState(false);
  // const [sort, setSort] = useState<ImgUploadSortParam>('name');
  // const [highLow, setHighLow] = useState<ImgUploadHighLowParam>('high');
  const groupChildren = useMemo<any[]>(() => {
    if (templateName) {
      const parentTarget = cloneDeep(templateXmlArr.find((v) => v.templateGroup.find((v2: any) => v2.templateData.find((v3: any) => (v3.$.path.indexOf(templateName) > -1) && pageTypeIdArr.includes(v3.$.type)))));
      if (!parentTarget) return [];
      if (parentTarget.$.name.indexOf('cover') !== -1) {
        parentTarget.templateGroup[0].templateData = parentTarget.templateGroup[0].templateData.filter((v: any) => {
          if (v.cover) {
            const min = Number(v.cover[0].$.minPageCount) || 0;
            const max = Number(v.cover[0].$.maxPageCount) || 0;
            return !(min > (Number(albumSetting.pageCount)) || max < (Number(albumSetting.pageCount)));
          } else {
            return true;
          }
        })
      }
      return (parentTarget.templateGroup[0].templateData as any[]).map((v) => ({
        id: v.$.path,
        templateName: (() => {
          const _name = v.$.path.split('/')[v.$.path.split('/').length - 1];
          return _name.indexOf('___') !== -1 ? _name.substring(0, _name.indexOf('___')) : _name;
        })(),
        thumbnail: getTargetPathUrl(v.$.path + '/thumbnail.jpg'),
        templateXmlUrl: getTargetPathUrl(parentTarget.$.path),
        targetPath: v.$.path,
      }));
    } else {
      return [];
    }
  }, [albumSetting, pageTypeIdArr, templateName, templateXmlArr]);

  // - Callback -
  // -- 右クリックメニュー（お気に入りテンプレート用）--
  const handlerClickContextMenu = useCallback((e) => {
    e.preventDefault();
    if (favTempId) {
      dispatch(toolbarActions.open({
        position: {
          x: e.clientX,
          y: e.clientY,
        },
        list: [
          {
            label: 'テンプレート削除',
            callback: () => {
              dispatch(toolbarActions.close());
              if (favTempId && selectFolder) {
                dispatch(favoriteTemplateActions.setDeleteTemplateId(favTempId));
                dispatch(apiActions.run(
                  new ApiFavoriteTemplateDelete({
                    id: favTempId,
                    kijshopCd,
                    folderId: selectFolder.id,
                  }, favTempId),
                  {
                    onSuccess: () => {
                      dispatch(layoutActions.setTemplateList(layoutList.filter((v) => v.favTempId !== favTempId)));
                      dispatch(favoriteTemplateActions.updateFavoriteTemplate(true));
                    },
                    onError: () => {
                      dispatch(dialogActions.pushMessage({
                        title: '確認',
                        message: ['テンプレートの削除に失敗しました。'],
                        buttons: [{
                          label: 'OK',
                          callback: () => {
                            dispatch(dialogActions.pop());
                          },
                        }],
                      }));
                    },
                  },
                  { ignoreSystemError: true },
                ))
              }
            },
          },
        ],
      }));
    }
  }, [favTempId, selectFolder, layoutList]);

    // -- テンプレートがないときA-Zをつけて検索
    const getTemplateXML = useCallback<any>(async (targetPath: string, templateName: string) => {
      const nextPathData = await TemplateLoaderManager.checkNextPath(targetPath, pageTypeID);
      const data = await TemplateLoaderManager.ins.onGetTargetFile(nextPathData.path, 'templateXml', templateName);
      if (data) return data;
      return undefined;
    }, [pageTypeID]);
    // -- DropEventHandler(Local -> template) --
    const sortUpload = useCallback((list: EditableImage[]) => {
      switch (sort.current) {
        case 'name':
          if (highLow.current === 'high') {
            return list.concat().sort((a, b) => a.name > b.name ? 1 : -1);
          } else {
            return list.concat().sort((a, b) => a.name < b.name ? 1 : -1);
          }
        case 'date':
          if (highLow.current === 'high') {
            return list.concat().sort((a, b) => a.exif.createDate > b.exif.createDate ? 1 : -1);
          } else {
            return list.concat().sort((a, b) => a.exif.createDate < b.exif.createDate ? 1 : -1);
          }
        default:
          return list;
      }
    }, [highLow, sort]);

  // -- template.xml or zipの取得処理 --
  const onGetXml = useCallback(async (loadData?: {
    targetPath: string,
    templateName: string,
    thumbnail: string,
  }) => {
    const _data = loadData ?? {
      targetPath: targetPath,
      templateName: templateName,
      thumbnail: thumbnail,
    }
    return new Promise<any>(async(resolve) => {
      let templatesXmlData: { xml: any, pageType: string } = { xml: '', pageType: '' };
      let templateXmlData: any;
      let thumbnailData: string;
      let zipLevel: { isZip: boolean, level: number };
      const data = await getTemplateXML(_data.targetPath, _data.templateName);
      thumbnailData = _data.thumbnail;
      zipLevel = { isZip: false, level: 0 };
      if (data?.templates) {
        const target = data?.templates.find((v: any) => v.$.path === id);
        if (target) {
          const dd = target.templateGroup[0].templateData.find((v: any) => v.$.path === _data.targetPath);
          if (dd) {
            templatesXmlData = { xml: dd, pageType: target.$.name };
          }
        }
      }
      if (data?.thumbnail) {
        thumbnailData = URL.createObjectURL(data.thumbnail as Blob);
      }
      if (data?.zipLevel) {
        zipLevel = data.zipLevel;
      }
      if (data?.template) {
        templateXmlData = data.template;
      }
      const result = {
        itemData: {
          id,
          templateName: _data.templateName,
          thumbnail: _data.thumbnail,
          templateXmlUrl,
          targetPath: _data.targetPath,
        },
        templates: templatesXmlData,
        template: templateXmlData,
        thumbnail: thumbnailData,
        zipLevel,
        blob: data.blob,
      };
      dispatch(layoutActions.addAcquiredTemplateList(result));
      resolve(result);
      if (!loadData) {
        setZipLevel(zipLevel);
        setNewThumbnail(thumbnailData);
        setTemplateXml(templateXmlData);
      }
    });
  }, [ready, targetPath, templateName, thumbnail, id, getTemplateXML]);


  // -- アルバムに追加する処理 --
  const onSetAlbum = useCallback(async (param: ClickItemPram) => {
    const isDropTemplate = store.getState().layout.isDropTemplate
    if (!param.isDropDirect) {
      UiManager.ins.emit('l->r:wait-loading2', {message: 'テンプレートを生成しています...'});
    }
    const data = await onGetXml();
    const isOption = !!albumInfo.option.find((v) => v.xml.viewModel.pageType === pageTypeID)
      || !!albumInfo.opPrint.find((v) => v.xml.viewModel.pageType === pageTypeID);
    const albums = [albumInfo.top, albumInfo.page, albumInfo.endCover];
    const isOverPageCount = (() => {
      const targetAlbum = albums.find((v) => v.pageTypeId === pageTypeID);
      const count =
      (albumInfo.top.albums.length * albumInfo.top.pageCount || 0)
      + (albumInfo.page.albums.length * albumInfo.page.pageCount || 0)
      + (albumInfo.endCover.albums.length * albumInfo.endCover.pageCount || 0);
      // return count >= Number(albumSetting.pageCountInfo.max || 0);
      return ((count + (targetAlbum?.pageCount ?? 0)) > Number(albumSetting.pageCountInfo.max || 0) && count <= Number(albumSetting.pageCountInfo.max || 0));
    })();
    // --- 総ページ数をオーバーしていないかをチェック ---
    const warningOverPage = () => new Promise<void>((resolve) => {
      const buttons = [
        { label: 'OK', callback: () => {
          dispatch(dialogActions.pop());
          resolve();
        }},
      ];
      const message = ['総ページ数の上限を超えました。'];
      dispatch(dialogActions.pushMessage({
        title: '確認',
        message,
        buttons,
      }))
    })
    if (!data.template) {
      dispatch(dialogActions.pushMessage({
        title: '確認',
        message: ['選択されたテンプレートのデータが不正のため使用できません。'],
        buttons: [{
          label: 'OK', callback: () => {
            dispatch(dialogActions.pop());
            UiManager.ins.emit('l->r:wait-loading2', {message: ''});
          },
        }],
      }));
      return;
    }
    if (!isOption && !param.isDropDirect) {
      if (isOverPageCount) {
        warningOverPage();
      }
    }
    const additionalDatas: any[] = [];
    if (data.template.templateData.additionalFrame?.length) {
      for (const child of groupChildren) {
        const childXml = await onGetXml({
          ...child,
        })
        additionalDatas.push(childXml);
      }
    }
    const album = new AlbumPage(
      data.template,
      `layout-order:${UuidGenerator.create()}`,
      pageTypeID,
      pageType,
      displayName,
      pageNo,
      { templatePath: thumbnail, image: data.thumbnail },
      data.zipLevel,
      undefined,
      undefined,
      isOption,
      undefined,
      additionalDatas.length ? additionalDatas : undefined,
      data.blob,
    );
    dispatch(layoutActions.setDeleteAlbumData(cloneDeep(deleteAlbumList.filter((v) => v.thumbnailID !== album.imageEditManager?.path.ad))));
    dispatch(layoutActions.addAlbumPage({
      type: (isOption && !albumInfo.opPrint.find((v) => v.xml.viewModel.pageType === pageTypeID)) ? 'option' : pageType,
      album,
    }));
    return await new Promise<void>((resolve) => {
      UiManager.ins.emit('r->l:add-album', {
        album: album, callback: () => {
          if (!isDropTemplate && !param.isDragTemp) {
            dispatch(layoutActions.setCurrentAlbumPage(album));
            dispatch(layoutActions.setPage('edit'));
            if (param.isDropDirect) {
              UiManager.ins.emit('r->l:select-album', {
                album, callback: () => {
                  const timerId = window.setInterval(() => {
                    if (album.imageEditManager?.isSetEvent) {
                      album.imageEditManager.isSetEvent = false;
                      const target = xml?.orderInfo?.infoData?.find((v) => v.xml.metaModel.id === orderId);
                      const categoryId = target?.xml.viewModel.category?.id;
                      const itemId = target?.xml.viewModel.item?.id;
                      const isPostCardCategory = Boolean(POSTCARD_ID.category.find((id) => id === categoryId));
                      const isPostCardItem = Boolean(POSTCARD_ID.item.find((id) => id === itemId));
                      const isRightRotateCategory = Boolean(RIGHT_ROTATE_LIST.category.find((id) => id === categoryId));
                      const isRightRotateItem = Boolean(RIGHT_ROTATE_LIST.item.find((id) => id === itemId));
                      for (const image of param.images!) {
                        UiManager.ins.emit('r->l:editable-image:drop:temp', {
                          editableImage: image,
                          index: param.images?.indexOf(image)!,
                          postCardType: (isPostCardCategory || isPostCardItem)
                            // 特定の商品のみ右90度回転になるよう分岐
                            ? (isRightRotateCategory || isRightRotateItem)
                              ? 'right'
                              : 'left'
                            : undefined,
                          callback: () => {
                            clearInterval(timerId);
                            resolve();
                          }
                        });
                      }
                      // UiManager.ins.emit('l->r:wait-loading', { message: '' });
                    }
                  }, 300);
                },
              });
            } else {
              UiManager.ins.emit('r->l:select-album', { album });
              resolve();
              // UiManager.ins.emit('l->r:wait-loading', { message: '' });
            }
          } else {
            resolve();
          }
          // UiManager.ins.emit('l->r:wait-loading', { message: '' });
        },
      });
    });
  }, [templateXml, pageTypeID, pageType, thumbnail, newThumbnail, zipLevel, isDropTemp, album, deleteAlbumList, albumSetting, albumInfo, onGetXml, groupChildren, blob]);
  // -- アルバムに追加する前のチェック（ダイアログ表示など） --
  const handlerClickItem = useCallback(async (param: ClickItemPram) => {
    const target = xml?.orderInfo?.infoData?.find((v) => v.xml.metaModel.id === orderId);
    const categoryId = target?.xml.viewModel.category?.id;
    const isAdditional = (categoryId === 'jp0333' || categoryId === 'jp0376');
    if ((isAdditional && !album.option.find((v) => v.album?.typeID === pageTypeID))
      || (album.opPrint.find((v) => v.album?.typeID === pageTypeID) && album.opPrint.filter(v => !v.album).length)) {
      await onSetAlbum({ ...param });
      return;
    } else if (
      // (pageType.indexOf('cover') !== -1 && album.cover.albums.length) ||
      // (pageType.indexOf('top') !== -1 && album.top.albums.length) ||
      // (pageType.indexOf('end') !== -1 && album.endCover.albums.length) ||
      (album.cover.albums.find((v) => v.typeID === pageTypeID)) ||
      (album.top.albums.find((v) => v.typeID === pageTypeID)) ||
      (album.endCover.albums.find((v) => v.typeID === pageTypeID)) ||
      (album.option.find((v) => v.album?.typeID === pageTypeID)) ||
      album.opPrint.find((v) => v.album?.typeID === pageTypeID)
    ) {
      dispatch(dialogActions.pushMessage({
        title: 'Type重複エラー',
        message: [
          `「${displayName}」は既に掲載されています。`,
        ],
        buttons: [
          {
            label: 'OK',
            callback: () => {
              dispatch(dialogActions.pop());
            },
          },
        ],
      }));
    } else {
      if (pageType.indexOf('page') !== -1 && isSelected && !param.isDropDirect) {
        dispatch(dialogActions.pushMessage({
          title: '重複エラー',
          message: [
            '同じテンプレートが登録されています',
            'さらに追加いたしますか？',
          ],
          buttons: [
            {
              label: 'キャンセル',
              callback: () => {
                dispatch(dialogActions.pop());
              },
            },
            {
              label: 'OK',
              callback: () => {
                dispatch(dialogActions.pop());
                onSetAlbum({ ...param }).then(() => { return });
              },
            },
          ],
        }));
      } else if (!isSelected) {
        await onSetAlbum({ ...param });
        return;
      } else if (param.isDropDirect) {
        await onSetAlbum({ ...param });
        return;
      }
    }
  }, [pageType, album, isSelected, templateXml, isDropTemp, onSetAlbum, pageTypeID, albumSetting, albumInfo]);
  // - 流し込み処理の起点 -
  const photoInFrame = (list: EditableImage[], photoAreaCount: number, loopLimit: number, loopCount: number) => {
    const timeoutId = window.setTimeout(async () => {
      // 流し込み時の残枚数チェック
      if (loopLimit <= loopCount) {
        /* 最終ページのサムネイル生成を待つ */
        setTimeout(() => {
          clearTimeout(timeoutId!);
          UiManager.ins.emit('l->r:wait-loading', { message: '' });
          // dispatch(layoutActions.setTemplateList([]));
          dispatch(layoutActions.setTemplatesInfoList([]));
          dispatch(layoutActions.setPage('select'));
        }, 1000);
      } else {
        UiManager.ins.emit('l->r:wait-loading', { message: `画像を配置しています...(${loopCount}/${loopLimit})` });
        const targetIndex = list.length < photoAreaCount ? list.length : photoAreaCount;
        const images = list.slice(0, targetIndex);
        list.splice(0, targetIndex);
        await handlerClickItem({ isDropDirect: true, images });
        loopCount++;
        // -- 全ての写真が配置されるまで再起する --
        photoInFrame(list, photoAreaCount, loopLimit, loopCount);
      }
      // }, 2500);
    });
  };
  // - 流し込み時のアップロード処理 -
  const uploadImage = useCallback(async(_files: File[], data: any) => {
    const files = Array.from(_files).filter((v) => /\.(jpe?g)$/i.test(v.name)).filter((v) => v.name !== 'thumbnail.jpg');
    const photoAreaCount = data.template.templateData.photoarea.length;
    const orderSelect = xml?.orderSelect?.metaModel.imageData ?? [];
    let loopLimit = Math.ceil(files.length / photoAreaCount);
    const loopCount = 0;
    const editableImages: EditableImage[] = [];
    const isMulchPage = pageTypeList.page.find((v) => v.pageTypeID === pageTypeID)
    if (!isMulchPage) {
      loopLimit = 1;
    }
    let exifFlg = false;
    dispatch(dialogActions.pop());
    UiManager.ins.emit(
      'r->l:add-image',
      {
        files: files,
        kijshopCd: kijshopCd,
        shopOrderId: shopOrderId,
        orderId: orderId || null,
        samePushIgnore: true,
        selectIdList: [...(orderSelect.map((v) => ({
          path: v.selectFileName?.real.path || '',
          selectID: v.selectID || '',
        })))],
        onCreatedEditableImage: (e) => {
            editableImages.push(e.editableImage);
            if (editableImages.length === files.length) {
              const list = sortUpload(editableImages);
              photoInFrame(list, photoAreaCount, loopLimit, loopCount);
            }
            if (String(e.editableImage.exif.colorSpace) !== '1' && !exifFlg && !store.getState().layout.isStopExifWarning) {
              exifFlg = true;
              dispatch(dialogActions.pushMessage({
                title: '確認',
                message: !e.editableImage.exif.colorSpace ? [
                  'Exifヘッダ情報に色空間の情報がありません。',
                  '色空間がsRGBでない場合、色が変わる可能性があります。',
                ] : [
                  'sRGB以外の色空間が指定されています。',
                  '色空間がsRGBでない場合、色が変わる可能性があります。',
                ],
                buttons: [{
                  label: 'OK',
                  callback: () => {
                    dispatch(dialogActions.pop());
                  },
                }],
                remind: {
                  callback: (v) => dispatch(layoutActions.setIsStopExifWarning(v)),
                },
              }));
            }
        },
        onUploaded: (e) => {
          if (e.sameImage) return;
          dispatch(xmlActions.uploadImage({
            kijshopCd: kijshopCd,
            shopOrderId: shopOrderId,
          }).layout(orderId || '').add(e.editableImage, (res) => {
            store.dispatch(layoutActions.setSaveSelect(res));
          }));
        },
      },
    );

  }, [templateXml, pageType, xml, pageTypeID, pageTypeList, album, isSelected]);
  // - テンプレートへの画像直接流し込み -
  const onDrop = useCallback(async(e) => {
    e.preventDefault();
    if (dragTemp) return;
    let overPageCount = 0;
    const enableFileNames = ['thumbnail.jpg', 'download.jpg', 'download.png'];
    const _files = Object.values(e.dataTransfer.files).filter((v: any) => !enableFileNames.includes(v.name));
    const files = (Array.from(e.dataTransfer.files).filter((v: any) => /\.(jpe?g)$/i.test(v.name)).filter((v: any) => v.name !== 'thumbnail.jpg') as File[]);
    const data = await onGetXml();
    const isOption = !!albumInfo.option.find((v) => v.xml.viewModel.pageType === pageTypeID)
      || !!albumInfo.opPrint.find((v) => v.xml.viewModel.pageType === pageTypeID);
    const albums = [albumInfo.top, albumInfo.page, albumInfo.endCover];
    // -- ページ数がオーバーしていないか確認 --
    const isOverPageCount = (() => {
      const targetAlbum = albums.find((v) => v.pageTypeId === pageTypeID);
      const currentAreaCount = data.template.templateData.photoarea.length;
      const count =  (albumInfo.page.albums.length * albumInfo.page.pageCount || 0);
      const topCount = albumInfo.top.pageTypeId ? 1 : 0;
      const endCount = albumInfo.endCover.pageTypeId ? 1 : 0;
      const addAlbumCount = Math.ceil(_files.length / currentAreaCount);
      const totalCount = (count + (targetAlbum?.pageCount ?? 0) * addAlbumCount + topCount + endCount);
      const maxPageCount = Number(albumSetting.pageCountInfo.max || 0);
      const isOver = (totalCount > maxPageCount);
      overPageCount = isOver ? totalCount - maxPageCount : 0;
      return isOver;
    })();
    // -- 流し込みページ数オーバー警告ダイアログ --
    const warningOverPage = () => new Promise<void>((resolve) => {
      const buttons = [
        { label: 'OK', callback: () => {
          dispatch(dialogActions.pop());
          resolve();
        }},
      ];
      const message = [
        '流し込み処理後のページ数が最大ページ数を超えてしまうため、処理を中止しました。',
        '画像の数をご確認の上、再度ドラッグ＆ドロップを行なってください。',
        `超えるページ数：${overPageCount}`
    ];
      dispatch(dialogActions.pushMessage({
        title: '流し込みエラー',
        message,
        buttons,
      }))
    })
    if (_files.length) {
      const isPage = (albumInfo.page.pageTypeId === pageTypeID)
      if (isOverPageCount && isPage && !isOption) {
        warningOverPage();
        return;
      }
      // -- 複製する枚数 --
      dispatch(dialogActions.push({
        title: '画像レイアウト順選択',
        element: <SelectImgLayoutOrder
          onClickDetermine={() => {
            dispatch(dialogActions.pop());
            UiManager.ins.emit('l->r:wait-loading', { message: '画像を読み込んでいます...' });
            uploadImage(files, data);
          }}
          setSort={(v) => {
            sort.current = v === '名前順' ? 'name' : 'date';
          }}
          setHighLow={(v) => {
            highLow.current = v === '昇順' ? 'high' : 'low';
          }}
        />,
        closeBtn: true,
      }));
    }
  }, [templateXml, dragTemp, uploadImage, albumInfo, pageTypeID, pageType, onGetXml, album, isSelected, pageTypeList]);
  // - Effect -
  // -- DnDで追加するとき --
  useEffect(() => {
    if (isDropTemp && isDragging) {
      // isDropTempを変える
      dispatch(layoutActions.setIsDropTemplate(false));
      // isDraggingを変える
      handlerClickItem({isDragTemp: true});
    }
  }, [isDropTemp, isDragging]);
  // -- すでに選択済みのテンプレートかどうかチェック --
  useEffect(() => {
    const optionTypeList = albumInfo.option.map((v) => v.xml.viewModel.pageType ?? '');
    const optionPrintTypeList = albumInfo.opPrint.map((v) => v.xml.viewModel.pageType ?? '');
    const temp = templatesXmlArr.find((v) => v.$.path === id);
    const isOption = !!optionPrintTypeList.find(v => v === temp.templateGroup[0].templateData[0].$.type)
      || (!!optionTypeList.find((v) => (v === temp?.templateGroup[0].templateData[0].$.type)
        || !!(temp?.templateGroup?.find((v2: any) => v2.templateData?.find((v3: any) => v3.$.type === v)))
      ));
    let target;
    if (!pageTypeID || !pageTypeList) return;
    if (pageTypeList.opPrint.find(v => v.pageTypeID === pageTypeID)) {
      target = album.opPrint.find((v) => v.album?.thumbnail.templatePath === thumbnail);
    }else if (pageTypeList.cover.find(v => v.pageTypeID === pageTypeID)) {
      target = album.cover.albums.find((v) => v.thumbnail.templatePath === thumbnail);
    } else if (pageTypeList.top.find(v => v.pageTypeID === pageTypeID)) {
      target = album.top.albums.find((v) => v.thumbnail.templatePath === thumbnail);
    } else if (pageTypeList.page.find(v => v.pageTypeID === pageTypeID) && !isOption) {
      target = album.page.albums.find((v) => v.thumbnail.templatePath === thumbnail);
    } else if (pageTypeList.endCover.find(v => v.pageTypeID === pageTypeID)) {
      target = album.endCover.albums.find((v) => v.thumbnail.templatePath === thumbnail);
    } else if (pageTypeList.option.find(v => v.pageTypeID === pageTypeID)) {
      target = album.option.find((v) => v.album?.thumbnail.templatePath === thumbnail);
    } else {
      target = album.page.albums.find((v) => v.thumbnail.templatePath === thumbnail);
    }
    if (target) {
      setFocus(true);
      setIsSelected(true);
    } else {
      setFocus(false);
      setIsSelected(false);
    }
  }, [album, pageType, pageTypeID, pageTypeList, thumbnail, albumInfo]);
  const count = useRef(0)
  // -- 写真枠数などの情報の設定をする --
  useEffect(() => {
    const optionTypeList = albumInfo.option.map((v) => v.xml.viewModel.pageType ?? '');
    const optionPrintTypeList = albumInfo.opPrint.map((v) => v.xml.viewModel.pageType ?? '');
    const target = templatesXmlArr.find((v) => v.$.path === id);
    const isOption = !!optionPrintTypeList.find(v => v === target.templateGroup[0].templateData[0].$.type)
    || (!!optionTypeList.find((v) => (v === target?.templateGroup[0].templateData[0].$.type)
      || !!(target?.templateGroup?.find((v2: any) => v2.templateData?.find((v3: any) => v3.$.type === v)))
    ));
    const optionList = xml?.orderInfo?.infoData?.filter((info) => info.xml.metaModel.parentId === orderId) ?? [];
    // --- apiのレスポンスと実際にS3に設置されているxmlのデータの引き当て ---
    const data = target.templateGroup[0].templateData.find((v: any) => v.$.path === targetPath);
    const orderInfo = !isOption ? xml?.orderInfo?.infoData?.find((info) => info.xml.metaModel.id === orderId)
      : optionList.find((v) => v.parts?.partsData?.find(
        (v2) => v2.page?.pageData?.find(
          (v3) => {
            const group = target.templateGroup?.find((v4: any) => v4.templateData?.find((v5: any) => v5.$.type === v3.viewModel.pageType));
            const typeFind = group?.templateData?.find((v5: any) => v5.$.type === v3.viewModel.pageType);
            return (v3.viewModel.pageType === target.$.type || typeFind);
          })),
      );
    const partsData: XmlStructureOrderPartsData[] | undefined = orderInfo?.parts?.partsData;
    const pageData: Partial<TOrderPageDataViewModel>[] = [];
    if (!partsData) return;
    for (const v of partsData) {
      if (!v || !v.page || !v.page.pageData) continue;
      for (const vv of v.page?.pageData) {
        pageData.push(vv.viewModel);
      }
    }
    // NOTE: テンプレートの更新タイミングでの関係で更新日に限りelseに入る可能性あり
    if (data) {
      setVertical(data.count[0].$.tate);
      setHorizon(data.count[0].$.yoko);
      setSq(data.count[0].$.sq);
      const pageTypeId = target.templateGroup[0].templateData[0].$.type;
      const pageT = target.$.name;
      setPageType(pageT);
      const _pageType = (() => {
        const opPrint = pageTypeList.opPrint.find((v) => v.pageTypeID === pageTypeId && isOption);
        if (opPrint) { return opPrint }
        const cover = pageTypeList.cover.find((v) => v.pageTypeID === pageTypeId);
        if (cover) { return cover }
        const top = pageTypeList.top.find((v) => v.pageTypeID === pageTypeId);
        if (top) { return top }
        const page = pageTypeList.page.find((v) => v.pageTypeID === pageTypeId && !isOption);
        if (page) { return page }
        const end = pageTypeList.endCover.find((v) => v.pageTypeID === pageTypeId);
        if (end) { return end }
        const option = pageTypeList.option.find((v) => v.pageTypeID === pageTypeId && isOption);
        if (option) { return option }
      })();
      if (!_pageType) return;
      const page = pageData.find((v) => v.pageType === _pageType!.pageTypeID && !isOption);
      const option = pageData.find((v) => v.pageType === _pageType!.pageTypeID && isOption);
      setDisplayName(page ? page.displayPageType : (option?.displayPageType ?? pageT));
      setPageTypeID(page ? page.pageType : (option?.pageType ?? data.$.type));
      setPageNo(page ? page.displayPageNo! : (option?.displayPageNo ?? '***'));
    } else {
      dispatch(dialogActions.pushMessage({
        title: '確認',
        message: ['更新予定のテンプレートが含まれています。', '対象のテンプレートは明日から選択できるようになります。'],
        buttons: [{
          label: 'OK',
          callback: () => dispatch(dialogActions.pop()),
        }],
        id: 'template_update_error_msg',
      }));
    }
  }, [id, targetPath, templatesXmlArr, xml, albumInfo]);
  // -- 遷移後にstateを更新しないようにする --
  useEffect(() => {
    setIsUnMount(false);
    return () => setIsUnMount(true);
  }, []);
  // -- すでにtemplate.xmlを取得しているかチェック（していなければ新たに取得する）--
  useEffect(() => {
    const isSelected = acquiredList.find((v) => v.itemData.targetPath === targetPath);
    if (isSelected) {
      setZipLevel(isSelected.zipLevel);
      setNewThumbnail(isSelected.thumbnail);
      setTemplateXml(isSelected.template);
    } else {
      // onGetXml();
    }
  }, [thumbnail, targetPath, acquiredList]);
  useEffect(() => {
  }, [ready]);
  // - Hooks -
  useIntersectionObserver(
    ref,
    (e) => {
      if (e.isIntersecting) {
        setReady(true);
      }
    },
    {
      root: parent,
      rootMargin: '0px 0px 500px 0px',
    },
  );
  return (
    <>
    <div
      ref={ref}
      className="layout_item__wrap"
      tabIndex={-1}
      // onDoubleClick={handlerClickItem}
      onMouseDown={() => {
        setIsClick(true);
        if (isClick) {
          handlerClickItem({});
          setIsClick(false);
        } else {
          setFocus(false);
        }
        setTimeout(() => {
          if (!isUnMount) {
            setIsClick(false);
          }
        }, 350);
      }}
      onMouseUp={() => {
        isSelected ? setFocus(true) : setFocus(false);
      }}
      onDragStart={() => {
        setIsDragging(true);
        dispatch(favoriteTemplateActions.setDraggingTemplate(targetPath));
        dispatch(layoutActions.setIsDraggingTemplate(true));
      }}
      onMouseOut={() => {
        if (isSelected && !focus && !isDragging) {
          setFocus(true);
        }
      }}
      onDragEnd={() => {
        setIsDragging(false);
        dispatch(favoriteTemplateActions.setDraggingTemplate(''));
        dispatch(layoutActions.setIsDraggingTemplate(false));
        if (isSelected) {
          setFocus(true);
        }
      }}
      onDragOver={(e) => {
        e.preventDefault();
      }}
      onDrop={onDrop}
      onContextMenu={handlerClickContextMenu}
      style={style}
    >
    {!displayName &&
    <div className="layout_item__warning_icon"
         onMouseDown={(e) => {e.stopPropagation()}}
         onMouseUp={(e) => {e.stopPropagation()}}
         onDragStart={(e) => {e.stopPropagation()}}
         onDragEnd={(e) => {e.stopPropagation()}}
         onDragOver={(e) => {e.stopPropagation()}}
         onMouseEnter={() => setIsShowWarningIconTooltip(true)}
         onMouseLeave={() => setIsShowWarningIconTooltip(false)}
         ref={wrapEle}
    >
      <WarningIcon
        isShow={true}
        style={{
          zIndex: 1,
          width: 'calc(100% + 4px)',
          height: 'calc(100% + 4px)',
          margin: '-2px -2px',
        }} />
      {isShowWarningIconTooltip && <DomContentTooltip
        relativeEle={ref.current}
        onClick={(e) => {
          e.stopPropagation();
        }}
        content={
          <div className="layout_item__warning_icon__tooltip">
            <div>このテンプレートは明日から選択できるようになります</div>
          </div>
        }
      />}
    </div>
    }
      <div className="layout_item">
        <div className="layout_item__thumb">
          <Image
            src={ready ? newThumbnail : ''}
            containerAspectRatio={1.67}
            useDragDummy={true}
            dummyScale={0.92}
            // onDragOver={(e) => {
            //   e.preventDefault();
            // }}
            // onDrop={onDrop}
          />
          {!ready && <div
            className="loading_popup__dots"
            style={{ fontSize: 7, animationDuration: `${1}s`, WebkitAnimationDuration: `${1}s` }}
          />}
        </div>
        <div className="layout_item__info">
          <div className="layout_item__info__tag">
            {`${templateName.substr(0, 3)}/${templateName.substr(templateName.length - 3, 3)}`}
          </div>
          <div className="layout_item__info__detail">
            [{displayName}]写真×{Number(vertical) + Number(horizon) + Number(sq)}=縦×{vertical}/横×{horizon}/SQ×{sq}
          </div>
        </div>
      </div>
      {focus ?
        <div className="layout_item__cover"><i className="fas fa-check-circle"></i></div>
        : (<></>)}
    </div>
    </>
  );
};

type LayoutSelectTemplateProps = {
  thumbSizeRate?: number,
  pageTypeIdArr: any[],
};
export const LayoutSelectTemplate = (props: LayoutSelectTemplateProps) => {
  const { thumbSizeRate, pageTypeIdArr } = props;
  // - hooks -
  const dispatch = useDispatch()
  // - Ref -
  const ref = useRef<HTMLDivElement>(null);
  // - Store -
  const {
    layoutList,
    templatesInfo,
    isDropTemp,
    album,
    acquiredList,
    templatesXmlArr,
  } = useAppSelector((state) => ({
    layoutList: state.layout.layoutList,
    templatesInfo: state.layout.templatesInfoList,
    isDropTemp: state.layout.isDropTemplate,
    album: state.layout.albumPages,
    acquiredList: state.layout.acquiredTemplateList,
    templatesXmlArr: state.layout.templatesXmlList,
  }), lodash.isEqual);

  const [prevGetList, setPrevGetList] = useState(acquiredList);

  useEffect(() => {
    dispatch(layoutActions.setIsDropTemplate(false));
    setPrevGetList(acquiredList);
  }, [layoutList]);

  return (
    <div
      ref={ref}
      className="layout_select_template"
      // style={{ gridTemplateColumns: `repeat(auto-fit, minmax(${thumbSizeRate ? 18 * thumbSizeRate : 18}rem, 1fr)` }}
    >
      {layoutList.map((v, i) => (
        <LayoutItem
          key={`layout-select-template-item_${v.thumbnail}_${i}`}
          parent={ref.current?.parentElement || undefined}
          {...v}
          pageTypeIdArr={pageTypeIdArr}
          tempInfo={templatesInfo}
          isDropTemp={isDropTemp}
          album={album}
          templatesXmlArr={templatesXmlArr}
          acquiredList={prevGetList}
          style={{ width: `${thumbSizeRate ? 18 * thumbSizeRate : 18}rem` }}
        />
      ))}
    </div>
  );
};
