import {CoverColorsRequest, CoverColorsResponse} from '../../../../models/api/back/create-order/cover-colors';
import {AppThunk} from "../../../../app/store";
import {ResponseBase} from "../../../response-base";
import {apiActions} from "../../../../slices/api-slice";
import {ordersDataActions} from "../../../../slices/orders/orders-data-slice";
import {ApiBaseBack} from "../../api-base-back";
import { ordersActions } from '../../../../slices/orders-slice';

export class ApiCoverColors extends ApiBaseBack<CoverColorsResponse> {
  constructor(param: CoverColorsRequest) {
    super(
      'GET',
      '/api/v1/coverColors',
      'JSON',
      param,
    );
  }
}

export const apiCoverColorThunk = (param: CoverColorsRequest, callback: () => void, init: boolean, coverColorId: string, agent: boolean): AppThunk => (dispatch, getState) => {
  dispatch(apiActions.run(
    new ApiCoverColors(param),
    {
      onSuccess: (res: ResponseBase<CoverColorsResponse>) => {
        callback?.();
        if (!init && (param.serviceId !== getState().order.itemInfo.category.value
          || param.coverTypeId !== getState().order.itemDetail.coverSelect.value)) {
          return;
        }
        dispatch(ordersDataActions.updateItemDetail({
          coverColor: { coverColor: res.body.data?.coverColor || [] },
        }));
        if (coverColorId) {
          const data = res.body.data?.coverColor?.find((v) => v.coverColorId === coverColorId);
          if (data) {
            dispatch(ordersActions.itemDetail.setCoverColorSelect(data.coverColorId, data.coverColorName, agent));
          }
        } else {
          const coverColor = getState().order.itemDetail.coverColorSelect;
          if (coverColor.value && res.body.data?.coverColor?.find((v) => v.coverColorId === coverColor.value)) {
            dispatch(ordersActions.itemDetail.setCoverColorSelect(coverColor.value, coverColor.label, agent));
          }
        }
      },
      onError: () => {
        dispatch(ordersDataActions.updateItemDetail({
          coverColor: { coverColor: [] },
        }));
        callback?.();
      },
    },
    {
      apiName: 'cover-color',
      ignoreSystemError: true,
    },
  ));
};

export const apiCoverCoverColorThunk = (param: CoverColorsRequest, callback?: () => void, isInit?: boolean): AppThunk => (dispatch, getState) => {
  dispatch(apiActions.run(
    new ApiCoverColors(param),
    {
      onSuccess: (res: ResponseBase<CoverColorsResponse>) => {
        callback?.();
        if (!isInit && param.serviceId !== getState().order.itemInfo.category.value) {
          return;
        }
        if (!isInit && param.coverTypeId !== getState().orderData.data.productDetail.coverTypeId) {
          return;
        }
        dispatch(ordersDataActions.updateCover({
          color: { coverColor: res.body.data?.coverColor || [] },
        }));
        const color = getState().order.cover.color;
        if (color.value && res.body.data?.coverColor?.find((v) => v.coverColorId === color.value)) {
          dispatch(ordersActions.cover.setColor(color.value, color.label));
        }
      },
      onError: () => {
        dispatch(ordersDataActions.updateCover({
          color: { coverColor: [] },
        }));
        callback?.();
      },
    },
    {
      apiName: 'cover-cover-color',
      ignoreSystemError: true,
    },
  ));
};
