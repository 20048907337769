import { Select } from '../../ui/select/select';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../app/hooks';
import * as lodash from 'lodash';
import { ordersActions, OrdersSliceState } from '../../../slices/orders-slice';
import { OrdersDataState } from '../../../slices/orders/orders-data-slice';
import { Input } from '../../ui/input/input';
import { createDebugList } from '../../../slices/debug-slice';
import { dialogActions } from '../../dialog/slice/dialog-slice';
import { LogDecorator } from '@tenryu/log-decorator';
import { useParams } from 'react-router-dom';
import { PathParams } from '../../../routes/routing-path';
import { Button } from '../../ui/button/button';
import iconHelp from '../../../assets/img/icon/help_icon.svg';
import { HowToOrder, ImgType } from '../../dialog/unique/how-to-order';

export const leafTextValidation = (text: string) => ({
  length: text.length <= 24,
  mismatch: (() => {
    for (let i: number = 0; i < text.length; i++) {
      // if (!text[i].match(/[0-9a-zA-Z]+['.,/!\-&:;@?]/)) {
      if (!text[i].match(/^[a-zA-Z0-9!/:;?@&'.,\s-]/)) {
        return text[i];
      }
    }
    return '';
  })(),
});

export const isOptionalLeafColor = (cover: string) => {
  switch (cover) {
    case 'jptg380043':
    case 'jptg380025':
    case 'jptg381587':
    case 'jptg381674':
    case 'jptg381679':
    case 'jptg380066':
    case 'jptg381723':
    case 'jptg381788':
      return true;
    default:
      return false;
  }
};

export const CoverOrder = () => {
  const dispatch = useDispatch();
  const { orderId } = useParams<PathParams>();
  const {
    debug,
    laminate,
    color,
    coverProcessing,
    leafText1,
    leafText2,
    order,
    data,
    laminateRes,
    foilStampingRes,
    colorRes,
    visibleCondition,
  } = useAppSelector((state) => ({
    debug: state.debug.debug,
    ...state.order.cover,
    laminateRes: state.orderData.cover.laminate.laminate,
    foilStampingRes: state.orderData.cover.foilStamping.leafColor,
    colorRes: state.orderData.cover.color.coverColor,
    order: state.order,
    data: state.orderData,
    visibleCondition: state.createOrder.visibleCondition,
  }), lodash.isEqual);
  // - State -
  const [alertLeafText1, setAlertLeafText1] = useState(false);
  const [alertLeafText2, setAlertLeafText2] = useState(false);
  // - Ref -
  const refLeaf1 = useRef<HTMLInputElement>(null);
  const refLeaf2 = useRef<HTMLInputElement>(null);
  // - Callback -
  // -- ラミ選択 --
  const handlerChangeSelectRami = useCallback((v, label) => {
    dispatch(ordersActions.cover.setLaminate(v, label));
  }, []);
  // -- 色 --
  const handlerChangeColor = useCallback((v, label) => {
    dispatch(ordersActions.cover.setColor(v, label));
  }, []);
  // -- 表紙加工 --
  const handlerChangeCoverRetouch = useCallback((v, label) => {
    dispatch(ordersActions.cover.setCoverProcessing(v, label));
  }, []);
  // -- 箔押し文字1 --
  const handlerChangeLeafText1 = useCallback((v) => {
    dispatch(ordersActions.cover.setLeafText1(v));
  }, []);
  // -- 箔押し文字2 --
  const handlerChangeLeafText2 = useCallback((v) => {
    dispatch(ordersActions.cover.setLeafText2(v));
  }, []);
  // -- ヘルプボタン --
  const handlerClickHelp = useCallback((type: ImgType) => {
    dispatch(dialogActions.push({
      title: '説明',
      element: <HowToOrder imgType={type}/>,
      closeBtn: true,
    }));
  }, []);
  // -- 箔押し文字精査 --
  const handlerBlurLeafText1 = useCallback(() => {
    const validation = leafTextValidation(leafText1);
    if (!validation.length) {
      dispatch(dialogActions.pushMessage({
        title: '確認',
        message: ['箔押し文字の文字数は1行最大24文字です。'],
        buttons: [{
          label: 'OK',
          callback: () => {
            dispatch(dialogActions.pop());
            setTimeout(() => {
              refLeaf1?.current?.focus();
            });
          },
        }],
      }));
      setAlertLeafText1(true);
    } else if (validation.mismatch) {
      dispatch(dialogActions.pushMessage({
        title: '確認',
        message: [`一行目に使用不可能な文字『${validation.mismatch}』が存在します。`],
        buttons: [{
          label: 'OK',
          callback: () => {
            dispatch(dialogActions.pop());
            setTimeout(() => {
              refLeaf1?.current?.focus();
            });
          },
        }],
      }));
      setAlertLeafText1(true);
    } else {
      setAlertLeafText1(false);
    }
  }, [leafText1]);
  const handlerBlurLeafText2 = useCallback(() => {
    const validation = leafTextValidation(leafText2);
    if (!validation.length) {
      dispatch(dialogActions.pushMessage({
        title: '確認',
        message: ['箔押し文字の文字数は1行最大24文字です。'],
        buttons: [{
          label: 'OK',
          callback: () => {
            dispatch(dialogActions.pop());
            setTimeout(() => {
              refLeaf2?.current?.focus();
            });
          },
        }],
      }));
      setAlertLeafText2(true);
    } else if (validation.mismatch) {
      dispatch(dialogActions.pushMessage({
        title: '確認',
        message: [`二行目に使用不可能な文字『${validation.mismatch}』が存在します。`],
        buttons: [{
          label: 'OK',
          callback: () => {
            dispatch(dialogActions.pop());
            setTimeout(() => {
              refLeaf2?.current?.focus();
            });
          },
        }],
      }));
      setAlertLeafText2(true);
    } else {
      setAlertLeafText2(false);
    }
  }, [leafText2]);
  // - Effect -
  // -- リスト値精査 --
  useEffect(() => {
    if (laminate.value && laminateRes.length && !laminateRes.find((v) => v.laminateId === laminate.value) && !orderId) {
      dispatch(ordersActions.cover.setLaminate(''));
    }
  }, [laminateRes]);
  // useEffect(() => {
  //   if (coverProcessing.value && foilStampingRes.length && !foilStampingRes.find((v) => v.leafColorId === coverProcessing.value) && !orderId) {
  //     dispatch(ordersActions.cover.setCoverProcessing(''));
  //   }
  // }, [foilStampingRes]);
  useEffect(() => {
    if (color.value && colorRes.length && !colorRes.find((v) => v.coverColorId === color.value) && !orderId) {
      dispatch(ordersActions.cover.setColor(''));
    }
  }, [colorRes]);
  // - masterProductSelectSql.jsonのデータを取得 -
  visibleCondition.checkOrderDifference(order, data);
  const display = visibleCondition.coverOrderDisplay;
  // - リストデータ -
  const laminateList = [...laminateRes].map((v) => ({ value: v.laminateId, label: v.laminateName }));
  const foilList = [...foilStampingRes].map((v) => ({ value: v.leafColorId, label: v.leafColorName }));
  const colorList = [...colorRes].map((v) => ({ value: v.coverColorId, label: v.coverColorName }));
  if (debug) {
    createDebugList(laminateList);
    createDebugList(foilList);
  }
  const select = useAppSelector((state) => {
    return state.order.cover;
  }, lodash.isEqual);
  useEffect(() => {
    const laminate = laminateList[0];
    if (display.laminate && (!select || laminate) && select.laminate.value !== laminate.value) {
      if (laminateList.length === 1) {
        dispatch(ordersActions.cover.setLaminate(laminate.value, laminate.label));
      }
    }
  }, [laminateList]);

  useEffect(() => {
      const color = colorList[0];
      if (display.color && (!select || color) && select.color.value !== color.value) {
        if (colorList.length === 1) {
          dispatch(ordersActions.cover.setColor(color.value, color.label));
        }
      }
    },
    [colorList],
  );
  //   useEffect(() => {
  //   const foil = foilList[0];
  //   if (!select || foil && select.coverProcessing.value !== foil.value) {
  //     if(foilList.length === 1) {
  //       dispatch(ordersActions.cover.setCoverProcessing(foil.value, foil.label));
  //     }
  //   }
  // },
  //   [foilList]
  // );

  // NOTE: 表紙加工はパターンが多いが必須でない時もあるので違う商品で表紙加工が開かれた時は即更新する
  useEffect(() => {
    if (foilList.length && !foilList.find(v => v.value === coverProcessing.value)) {
      dispatch(ordersActions.cover.setCoverProcessing('', ''));
    }
  }, [foilList]);

  // - 表示条件 -
  // const display = displayItems(order, data);
  // const display = visibleCondition.getCoverDisplay(order, data);

  // - 箔押し文字バリデーション -
  const leafText1Validation = leafTextValidation(leafText1);
  const leafText1ErrorMessage: string[] = leafText1Validation.length && !leafText1Validation.mismatch
    ? []
    : (
      !leafText1Validation.length ? [
        '箔押しの文字数は一行最大24文字です。',
      ] : [
        `一行目に使用不可能な文字『${leafText1Validation.mismatch}』が存在します。`,
      ]
    );
  const leafText2Validation = leafTextValidation(leafText2);
  const leafText2ErrorMessage: string[] = leafText2Validation.length && !leafText2Validation.mismatch
    ? []
    : (
      !leafText2Validation.length ? [
        '箔押しの文字数は一行最大24文字です。',
      ] : [
        `二行目に使用不可能な文字『${leafText2Validation.mismatch}』が存在します。`,
      ]
    );
  if (debug) {
    // console.group('表紙');
    LogDecorator.group(`<magenta>表紙</magenta>`);
    console.group('ラミ選択');
    console.log('表示: ', display.laminate);
    console.log('データ: ', laminateRes);
    console.groupEnd();
    console.group('色');
    console.log('表示: ', display.color);
    console.log('データ: ', colorRes);
    console.groupEnd();
    console.group('表紙加工');
    console.log('表示: ', display.leafColor);
    console.log('データ: ', foilStampingRes);
    console.groupEnd();
    console.group('箔押し文字');
    console.log('表示: ', display.leafText1);
    console.groupEnd();
    console.groupEnd();
  }

  return (
    <div className="order_category">
      <div className="bottom_border_header order_category__header">
        表紙
        <Button
          className="order_help order_category__header__tooltip"
          icon={<img src={iconHelp} alt="" />}
          onClick={() => handlerClickHelp('title-cover')}
        />
      </div>

      {display.laminate ? (
        <div className="top_label_ui order_category__item">
          <div className="top_label_ui__label">
            ラミ選択
          </div>
          <div className="top_label_ui__form">
            <Select
              value={laminate.value}
              list={laminateList}
              onChange={(e, label) => handlerChangeSelectRami(e.target.value, label)}
            />
            <Button
              className="order_help"
              icon={<img src={iconHelp} alt="" />}
              onClick={() => handlerClickHelp('cover-laminate')}
            />
          </div>
        </div>
      ) : (<></>)}

      {display.color ? (
        <div className="top_label_ui order_category__item">
          <div className="top_label_ui__label">
            色
          </div>
          <div className="top_label_ui__form">
            <Select
              value={color.value}
              list={colorList}
              onChange={(e, label) => handlerChangeColor(e.target.value, label)}
            />
            <Button
              className="order_help"
              icon={<img src={iconHelp} alt="" />}
              onClick={() => handlerClickHelp('cover-color')}
            />
          </div>
        </div>
      ) : (<></>)}

      {display.leafColor ? (
        <div className="top_label_ui order_category__item">
          <div className="top_label_ui__label">
            表紙加工
          </div>
          <div className="top_label_ui__form">
            <Select
              value={coverProcessing.value}
              list={foilList}
              onChange={(e, label) => handlerChangeCoverRetouch(e.target.value, label)}
              // addEmpty={isOptionalLeafColor(order.itemDetail.coverSelect.value)}
              addEmpty={(order.itemDetail.coverSelect.value || data.data.productDetail.coverTypeId || '') !== 'jptg380067'}
            />
            <Button
              className="order_help"
              icon={<img src={iconHelp} alt="" />}
              onClick={() => handlerClickHelp('cover-process')}
            />
          </div>
        </div>
      ) : (<></>)}

      {display.leafText1 ? (
        <div className="top_label_ui order_category__item">
          <div className="top_label_ui__label">
            箔押し文字1
          </div>
          <div className="top_label_ui__form">
            <Input
              value={leafText1}
              onChange={(e) => handlerChangeLeafText1(e.target.value)}
              error={Boolean(leafText1ErrorMessage.length) && alertLeafText1}
              tooltip={(leafText1ErrorMessage.length && alertLeafText1) ? {
                messages: leafText1ErrorMessage,
              } : undefined}
              onBlur={handlerBlurLeafText1}
              ref={refLeaf1}
            />
            <Button
              className="order_help"
              icon={<img src={iconHelp} alt="" />}
              onClick={() => handlerClickHelp('cover-leafText1')}
            />
          </div>
        </div>
      ) : (<></>)}

      {display.leafText2 ? (
        <div className="top_label_ui order_category__item">
          <div className="top_label_ui__label">
            箔押し文字2
          </div>
          <div className="top_label_ui__form">
            <Input
              value={leafText2}
              onChange={(e) => handlerChangeLeafText2(e.target.value)}
              error={Boolean(leafText2ErrorMessage.length) && alertLeafText2}
              tooltip={(leafText2ErrorMessage.length && alertLeafText2) ? {
                messages: leafText2ErrorMessage,
              } : undefined}
              onBlur={handlerBlurLeafText2}
              ref={refLeaf2}
            />
            <Button
              className="order_help"
              icon={<img src={iconHelp} alt="" />}
              onClick={() => handlerClickHelp('cover-leafText2')}
            />
          </div>
        </div>
      ) : (<></>)}

    </div>
  );
};
