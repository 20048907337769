
import { DesignType1Request, DesignType1Response } from '../../../../models/api/back/create-order/design-type1';
import {AppThunk} from "../../../../app/store";
import {ordersDataActions} from "../../../../slices/orders/orders-data-slice";
import {apiActions} from "../../../../slices/api-slice";
import {ResponseBase} from "../../../response-base";
import {ApiBaseBack} from "../../api-base-back";

export class ApiDesignType1 extends ApiBaseBack<DesignType1Response> {
  constructor(param: DesignType1Request) {
    super(
      'GET',
      '/api/v1/designType1',
      'JSON',
      param,
    );
  }
}

export const apiDesignType1Thunk = (param: DesignType1Request, callback?: () => void, isInit?: boolean): AppThunk => (dispatch,getState) => {
  if (!param.productNameId) {
    dispatch(ordersDataActions.updateItemDetail({
      designType1: { hpsTppath: [] },
    }));
    callback?.();
    return;
  }
  dispatch(apiActions.run(
    new ApiDesignType1(param),
    {
      onSuccess: (res: ResponseBase<DesignType1Response>) => {
        callback?.();
        if(!isInit && param.productNameId !== getState().order.itemDetail.itemSelect.value) {
          return;
        }
        dispatch(ordersDataActions.updateItemDetail({
          designType1: { hpsTppath: res.body.data?.hpsTppath || [] },
        }));
      },
      onError: () => {
        dispatch(ordersDataActions.updateItemDetail({
          designType1: { hpsTppath: [] },
        }));
        callback?.();
      },
    },
    {
      apiName: 'design-type1',
      ignoreSystemError: true,
    },
  ));
};
