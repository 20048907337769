import { Button, ButtonProps } from "./button"
import iconReturn from '../../../assets/img/icon/return.svg';
import iconSave from '../../../assets/img/icon/save.svg';
import iconDelete from '../../../assets/img/icon/delete.svg';

type CommonButtonProps = {
  commonType: 'return' | 'save' | 'delete',
} & ButtonProps;

export const CommonButton = (props: CommonButtonProps) => {
  const { commonType, ...defaultProps } = props;
  return (
    <>
      {commonType === 'return' ? <Button
        {...defaultProps}
        label={defaultProps.label || '保存しないで終了'}
        size={defaultProps.size || 'md'}
        color={defaultProps.color || 'light'}
        icon={<img src={iconReturn} alt="" />}
        style={{ ...defaultProps.style, border: 'none' }}
        onClick={defaultProps.onClick}
      /> : <></>}
      {commonType === 'save' ? <Button
        {...defaultProps}
        label={defaultProps.label || '保存して終了'}
        size={defaultProps.size || 'md'}
        color={defaultProps.color || 'warning'}
        icon={<img src={iconSave} alt="" />}
        style={{ ...defaultProps.style }}
        onClick={defaultProps.onClick}
      /> : <></>}
      {commonType === 'delete' ? <Button
        {...defaultProps}
        label={defaultProps.label || '削除'}
        size={defaultProps.size || 'md'}
        color={defaultProps.color || 'light'}
        icon={<img src={iconDelete} alt="" />}
        style={{ ...defaultProps.style }}
        onClick={defaultProps.onClick}
      /> : <></>}
    </>
  )
}