import { Select } from '../../ui/select/select';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { apiActions } from '../../../slices/api-slice';
import { ordersActions, OrdersSliceState } from '../../../slices/orders-slice';
import iconDelete from '../../../assets/img/icon/delete.svg';
import { Button } from '../../ui/button/button';
import { useAppSelector } from '../../../app/hooks';
import * as lodash from 'lodash';
import { ordersDataActions, OrdersDataState } from '../../../slices/orders/orders-data-slice';
import { createDebugList } from '../../../slices/debug-slice';
import { ApiOptionItem } from '../../../api/back/create-order/option-item/api-option-item';
import { ResponseBase } from '../../../api/response-base';
import { OptionItemResponse } from '../../../models/api/back/create-order/option-item';
import { NumUpDown } from '../../ui/input/num-up-down';
import { ApiLaminates } from '../../../api/back/create-order/laminates/api-laminates';
import { LaminatesResponse } from '../../../models/api/back/create-order/laminates';
import { ApiBoardOptionInfos } from '../../../api/back/create-order/board-option-info/api-board-option-infos';
import { BoardOptionInfosResponse } from '../../../models/api/back/create-order/board-option-infos';
import {
  ApiOptionParts1,
  ApiOptionParts2, ApiOptionParts3,
} from '../../../api/back/create-order/option-parts/api-option-parts';
import { OptionPartsResponse } from '../../../models/api/back/create-order/option-parts';
import { LogDecorator } from '@tenryu/log-decorator';
import { Checkbox } from '../../ui/input/checkbox';
import { ApiProductDetail } from '../../../api/back/create-order/product-detail/api-product-detail';
import { ProductDetailResponse } from '../../../models/api/back/create-order/product-detail';
import { useParams } from 'react-router-dom';
import { PathParams } from '../../../routes/routing-path';
import { ApiPageDataTypes } from '../../../api/back/create-order/page-data-types/api-page-data-types';
import {
  PageDataTypesResponse,
  PageDataTypesResponseEntity,
} from '../../../models/api/back/create-order/page-data-types';
import { dialogActions } from '../../dialog/slice/dialog-slice';
import iconHelp from '../../../assets/img/icon/help_icon.svg';
import { HowToOrder, ImgType } from '../../dialog/unique/how-to-order';
import { ApiEndpaper } from '../../../api/back/create-order/endpaper/api-endpaper';
import { EndpaperResponse } from '../../../models/api/back/create-order/endpaper';
import { store } from '../../../app/store';

const descriptionInfoOpItemCheck1 = (item: string) => {
  switch (item) {
    case 'jptg301626':
    case 'jptg301627':
    case 'jptg302732':
    case 'jptg302737':
      return true;
    default:
      return false;
  }
};

const descriptionInfoOpItemCheck2 = (item: string) => {
  switch (item) {
    case 'jptg300788':
    case 'jptg303175':
    case 'jptg303176':
    case 'jptg301448':
    case 'jptg301449':
    case 'jptg302272':
    case 'jptg302273':
      return false;
    default:
      return true;
  }
};

const descriptionInfoCategoryCheck = (category: string) => {
  switch (category) {
    case 'jp0385':
    case 'jp0333':
    case 'jp0376':
      return false;
    default:
      return true;
  }
};

const descriptionInfoOpCategoryCheck = (category: string) => {
  switch (category) {
    case 'jp0503':
      return false;
    default:
      return true;
  }
};

export const optionDescriptionLocationEditableCheck = (opServiceId?: string, descriptionName?: string, descriptionDate?: string, descriptionLocation?: string, orderMethod?: string, opItem?: string, serviceId?: string) => Boolean((opServiceId || serviceId) && (descriptionName || descriptionDate || descriptionLocation) && ((orderMethod === '30' && descriptionInfoOpItemCheck1(opItem || '')) || (descriptionInfoOpItemCheck2(opItem || '') && descriptionInfoCategoryCheck(serviceId || '') && descriptionInfoOpCategoryCheck(opServiceId || serviceId || '') && (orderMethod === '10' || orderMethod === '20'))));

// const displayItems = (order: OrdersSliceState, data: OrdersDataState, index?: number) => ({
//   // 商品タグ
//   // オプション商品タグ情報とオプション商品選択情報がある場合に表示
//   //  L 商品選択が選択された場合にオプション商品タグ情報を取得？（SQLの条件句に op-1_item）
//   //  L 必須選択項目選択有無がある場合にオプション商品選択情報を取得
//   itemTag: Boolean(data.option.optionTag.optionProductTag.length && data.option.optionItem.optionProduct.length),
//   // 商品選択
//   // オプション商品選択情報がある場合に表示
//   item: Boolean(data.option.optionItem.optionProduct.length),
//   // 部材選択
//   // (オプションの）部材選択情報がありカテゴリが jp0452, jp0487 or jp0488 の場合表示
//   //  L (オプションの)商品IDが存在する場合に部材選択情報を取得
//   //    L 商品選択が選択された場合に商品IDを取得？（SQLの条件句に op01o_item）
//   board: Boolean((order.itemInfo.category.value === 'jp0452' || order.itemInfo.category.value === 'jp0487' || order.itemInfo.category.value === 'jp0488')),
//   // 数量
//   // 1.(オプションの)商品選択が特定の値でなくオプションレタッチフラグが有効でない場合に表示
//   //  L (オプションの)商品IDが更新されたタイミングで取得？(SQLの条件句に op01o_goods_id)
//   //    L (オプションの)商品選択が選択されたタイミングで取得？（SQLの条件句に op01o_item）
//   // 2.(オプションの)商品選択が特定の値の場合に表示
//   // 3.(オプションの)商品選択が選択されており((オプションの)商品選択が特定の値もしくはオプションレタッチ商品フラグが有効でない場合)表示
//   //  L (オプションの)商品IDが更新されたタイミングで取得？(SQLの条件句に op01o_goods_id)
//   quantity: Boolean(order.option[index || 0]?.item?.value && checkQuantity(order.option[index || 0]?.item?.value, 1)),
//   // ラミ選択
//   // 表紙選択が、パース数が1以上で表示
//   // (写真アルバムプロフラグが有効かつ(オプションの)カテゴリが jp0441 でない場合)もしくは(カテゴリが特定の値もしくは(オプションの)商品選択が特定の値もしくは（オプションの）カテゴリが jp0303 の値の場合)表示
//   laminate: /* Boolean(data.option.optionParts.items.length) && */ Boolean(order.option[index || 0]?.item?.value && ((order.orderInfo.proFlg && order.option[index || 0].item?.serviceId !== 'jp0441') || (laminateCategoryCheck(order.itemInfo.category.value) || laminateOpItemCheck(order.option[index || 0]?.item?.value) || order.option[index || 0]?.item?.serviceId === 'jp0303'))),
//   // 商品記載情報
//   // (注文方法が30(完全レイアウトして入稿)かつ（オプションの）商品が特定の値)もしくは（(オプションの)商品が特定の値でないかつ(オプションの)カテゴリが特定の値でないかつカテゴリが特定の値でないかつ注文方法が10もしくは20）
//   descriptionInfo: optionDescriptionLocationEditableCheck(order.option[index || 0]?.item?.serviceId, order.descriptionInfo?.name, order.descriptionInfo?.date?.value, order.descriptionInfo?.photographLocation, order.itemDetail.orderMethod.value, order.option[index || 0]?.item?.value, order.itemInfo.category.value),
//   // コメント
//   // 注文方法が40(完成画像を入稿)でなく(ページデータ数（条件用）が存在し←pageCountありの状態)オプションのカテゴリが jp0332, ip0335 もしくは jp0441 である場合に表示
//   description: Boolean(order.itemDetail.orderMethod.value !== '40' && (order.option[index || 0]?.item?.serviceId === 'jp0332' || order.option[index || 0]?.item?.serviceId === 'jp0335' || order.option[index || 0]?.item?.serviceId === 'jp0441')),
// });

// const descriptionInfoDisplay = (info: DescriptionInfoGetResponse[], category: string) => ({
//   // 名前
//   // 親商品非継承情報が有効かつオプション名前情報がある場合に表示
//   name: Boolean(info.find((v) => v.type === 'name' && v.option === '')),
//   // 名前（半角英数）
//   // 親商品非継承情報が有効かつオプション名前（半角英数）情報がある場合に表示
//   nameHalf: Boolean(info.find((v) => v.type === 'name' && v.option === 'restrict')),
//   // 日付
//   // 親商品非継承情報が有効かつオプション日付情報がある場合に表示
//   date: Boolean(info.find((v) => v.type === 'date' && v.option === '')),
//   // 場所
//   // 親商品非継承情報が有効かつオプション場所情報がある場合に表示
//   location: Boolean(info.find((v) => v.type === 'location' && v.option === '')),
//   // 場所（身長）
//   // 親商品非継承情報が有効かつオプション身長情報がある場合に表示
//   locationHeight: Boolean(info.find((v) => v.type === 'location' && v.option === 'height')),
//   // コメント
//   // 親商品非継承情報が有効かつオプションコメント情報がある場合に表示
//   comment: Boolean(info.find((v) => v.type === 'location' && v.option === 'address')),
//   // 記載箇所
//   // オプションのカテゴリが特定の値でなくいずれかの項目が入力されている場合に表示
//   descriptionLocation: Boolean(descriptionLocationOpCategoryCheck(category)),
// });

// export const optionDisplayItem = (order: OrdersSliceState, data: OrdersDataState, index: number) => displayItems(order, data, index);

// export const optionDisplay = (order: OrdersSliceState, data: OrdersDataState) => {
//   return displayItems(order, data).item;
// };

type OptionOrderProps = {
  // - オプション数 -
  index: number,
  importFav: boolean,
};

// const checkQuantity = (flag: boolean, _quantity: string | number) => {
//   const quantity = Number(_quantity);
//   if (quantity < 1 || !Number.isInteger(quantity)) {
//     return false;
//   }
//   // switch (item) {
//   //   case 'jptg301282':
//   //   case 'jptg301407':
//   //   case 'jptg302536':
//   //   case 'jptg302538':
//   //   case 'jptg302770':
//   //   case 'jptg302771':
//   //   case 'jptg302833':
//   //   case 'jptg302834':
//   //   case 'jptg302835':
//   //   case 'jptg302836':
//   //   case 'jptg302837':
//   //   case 'jptg302916':
//   //   case 'jptg302918':
//   //   case 'jptg303002':
//   //   case 'jptg303018':
//   //   case 'jptg303088':
//   //   case 'jptg303089':
//   //   case 'jptg303296':
//   //   case 'jptg303591':
//   //   case 'jptg303625':
//   //   case 'jptg303626':
//   //   case 'jptg303627':
//   //   case 'jptg303656':
//   //   case 'jptg303623':
//   //   case 'jptg303660':
//   //   case 'jptg303752':
//   //   case 'jptg303763':
//   //   case 'jptg303915':
//   //   case 'jptg303959':
//   //   case 'jptg303960':
//   //   case 'jptg303974':
//   //   case 'jptg303975':
//   //   case 'jptg304008':
//   //   case 'jptg304044':
//   //   case 'jptg304177':
//   //     return false;
//   //   default:
//   //     return quantity <= 9999;
//   // }
//   return flag ? quantity <= 9999 : false;
// };

const checkQuantity = (v: string | number, max: number) => {
  return !(Number(v) < 1 || !Number.isInteger(Number(v))) && Number(v) <= max;
};

type CoverProps = {
  index: number,
  partsData: string,
};
const Cover = (props: CoverProps) => {
  const dispatch = useDispatch();
  const { index, partsData } = props;
  const { productNameId, laminate, serviceId, option } = useAppSelector((state) => ({
    // productNameId: state.order.itemDetail.itemSelect?.value,
    productNameId: state.order.option?.[index]?.item?.value,
    serviceId: state.order.option?.[index]?.item?.serviceId,
    laminate: state.order.option[index]?.cover?.laminate,
    option: state.order.option
  }), lodash.isEqual);
  const [laminateList, setLaminateList] = useState<{ value: string, label: string }[]>([]);
  const handlerChangeSelectRami = useCallback((v, label) => {
    dispatch(ordersActions.option.setLaminate(v, index, label));
  }, [index]);
  // -- ヘルプボタン --
  const handlerClickHelp = useCallback((type: ImgType) => {
    dispatch(dialogActions.push({
      title: '説明',
      element: <HowToOrder imgType={type} />,
      closeBtn: true,
    }));
  }, []);
  useEffect(() => {
    if (!productNameId || !partsData) {
      return;
    }
    dispatch(apiActions.run(
      new ApiLaminates({ productNameId, itemId: partsData }),
      {
        onSuccess: (res: ResponseBase<LaminatesResponse>) => {
          const arr = res.body.data?.laminate || [];
          dispatch(ordersDataActions.setOptionLaminate({
            laminate: { laminate: res?.body?.data?.laminate || [] },
            index,
          }));
          setLaminateList(arr.map((v) => ({ value: v.laminateId, label: v.laminateName })));
          if (arr.length === 1) {
            dispatch(ordersActions.option.setLaminate(arr[0].laminateId, index, arr[0].laminateName));
          }
        },
        onError: () => {
          dispatch(ordersDataActions.setOptionLaminate({
            laminate: { laminate: [] },
            index,
          }));
          setLaminateList([]);
        },
      },
    ));
    return () => {
      if (index <= store.getState().order.option.length - 1) {
        dispatch((ordersActions.option.removeLaminate(index)));
        dispatch((ordersDataActions.removeOptionLaminate(index)));
      }
    }
  }, [productNameId, partsData, serviceId]);
  useEffect(() => {
    if (laminate && laminate.value && !laminate.label && laminateList.length) {
      const data = laminateList.find((v) => v.value === laminate?.value);
      if (data) {
        dispatch(ordersActions.option.setLaminate(data.value, index, data.label));
      }
    }
  }, [laminate, laminateList, index]);
  return laminateList.length ? (
    <div className="order_category__secondary">
      <div className="order_category">
        <div className="bottom_border_header order_category__header">
          表紙
          <Button
            className="order_help order_category__header__tooltip"
            icon={<img src={iconHelp} alt='' />}
            onClick={() => handlerClickHelp('option-cover')}
          />
        </div>
        <div className="top_label_ui order_category__item">
          <div className="top_label_ui__label">
            ラミ選択
          </div>
          <div className="top_label_ui__form">
            <Select
              value={laminate?.value || ''}
              list={laminateList}
              onChange={(e, label) => handlerChangeSelectRami(e.target.value, label)}
            />
            <Button
              className="order_help"
              icon={<img src={iconHelp} alt='' />}
              onClick={() => handlerClickHelp('option-laminate')}
            />

          </div>
        </div>
      </div>
    </div>
  ) : (<></>);
};

type DescriptionInfoProps = {
  index: number,
  serviceId: string,
  productNameId: string,
  pageDataType: {
    pageDataTypeId: string,
    pageDataTypeName: string,
    pageDataTypeDisplayName: string,
    sortNo: string,
  }[],
  productDetail?: ProductDetailResponse,
};
const DescriptionInfo = (props: DescriptionInfoProps) => {
  const { kijshopCd } = useParams<PathParams>();
  const { index, serviceId, productNameId, pageDataType, productDetail } = props;
  const { name, date, photographLocation, descriptionLocations } = useAppSelector((state) => ({
    ...state.order.descriptionInfo,
    descriptionLocations: state.order.option[index]?.descriptionLocations || undefined,
  }), lodash.isEqual);
  const dispatch = useDispatch();
  const handlerChangeDescriptionLocation = useCallback((v) => {
    const arr = [...(descriptionLocations || [])];
    const arrIndex = arr.findIndex((d) => d === v);
    if (arrIndex === -1) {
      arr.push(v);
    } else {
      arr.splice(arrIndex, 1);
    }
    dispatch(ordersActions.option.setDescriptionLocations(arr, index));
  }, [descriptionLocations, index]);

  // -- ヘルプボタン --
  const handlerClickHelp = useCallback((type: ImgType) => {
    dispatch(dialogActions.push({
      title: '説明',
      element: <HowToOrder imgType={type} />,
      closeBtn: true,
    }));
  }, []);
  const display = pageDataType.length && (name || date?.value || photographLocation);
  useEffect(() => {
    if (display && !descriptionLocations) {
      handlerChangeDescriptionLocation(pageDataType[0]?.pageDataTypeId || '');
    }
  }, [display]);
  // const display = descriptionInfoDisplay(itemInfo, serviceId);
  return display ? (
    <div className="order_category__secondary">
      <div className="order_category">
        <div className="bottom_border_header order_category__header">
          商品記載情報
          <span className="order_category__header__caution">( ※半角と全角を混在して入力されますと文字情報がサンプルと異なる場合があります。)</span>
          <Button
            className="order_help order_category__header__tooltip"
            icon={<img src={iconHelp} alt='' />}
            onClick={() => handlerClickHelp('option-description')}
          />
        </div>

        {/*<div className="top_label_ui order_category__item">*/}
        {/*  <div className="top_label_ui__label">*/}
        {/*    名前*/}
        {/*  </div>*/}
        {/*  <div className="top_label_ui__form">*/}
        {/*    <Input*/}
        {/*    />*/}
        {/*    <Button className="order_help" icon={<img src={iconHelp} />} onClick={() => {}} />*/}
        {/*  </div>*/}
        {/*</div>*/}

        {/*<>*/}
        {/*  <Calendar getFormatDate={() => {}} />*/}
        {/*</>*/}

        {/*<div className="top_label_ui order_category__item">*/}
        {/*  <div className="top_label_ui__label">*/}
        {/*    撮影場所*/}
        {/*  </div>*/}
        {/*  <div className="top_label_ui__form">*/}
        {/*    <Input*/}
        {/*    />*/}
        {/*    <Button className="order_help" icon={<img src={iconHelp} />} onClick={() => {}} />*/}
        {/*  </div>*/}
        {/*</div>*/}

        <div className="top_label_ui order_category__item">
          <div className="top_label_ui__label">
            記載箇所
          </div>
          <div className="top_label_ui__form">
            <div className="item_description_place">
              {pageDataType.map((v, i) => (
                <Checkbox
                  key={`description-part-checkbox_${v.pageDataTypeId}_${i}`}
                  label={v.pageDataTypeDisplayName}
                  checked={Boolean(descriptionLocations?.find((d) => d === v.pageDataTypeId))}
                  onClick={() => handlerChangeDescriptionLocation(v.pageDataTypeId)}
                />
              ))}
            </div>
            <Button
              className="order_help"
              icon={<img src={iconHelp} alt='' />}
              onClick={() => handlerClickHelp('option-place')}
            />
          </div>
        </div>
      </div>
    </div>
  ) : (<></>);
};

export const OptionOrder = (props: OptionOrderProps) => {
  const { index, importFav } = props;
  const { kijshopCd, shopOrderId, orderId } = useParams<PathParams>();
  const {
    debug,
    tag,
    item,
    board,
    quantity: _quantity,
    edit,
    tagRes,
    itemRes,
    // laminateRes,
    // partsRes,
    // setInfoRes,
    order,
    data,
    isProxyMode,
    layoutFinish,
    option,
    optionProductRules,
    currentOrder,
    visibleCondition,
  } = useAppSelector((state) => ({
    debug: state.debug.debug,
    ...state.order.option[index],
    tagRes: state.orderData.option.optionTag.optionProductTag,
    itemRes: state.orderData.option.optionItem.optionProduct,
    // laminateRes: state.orderData.option.optionLaminate.laminate,
    partsRes: state.orderData.option.optionParts.items,
    setInfoRes: state.orderData.option.optionSetInfo.optionProductName,
    order: state.order,
    data: state.orderData,
    // isProxyMode: state.systemConfig.metaData.surrogateOrder,
    isProxyMode: Boolean(state.xml[shopOrderId]?.info.metaModel.dpNumber),
    option: state.order.option,
    optionProductRules: state.orderData.option.optionProductGroupRules.optionGroupRule,
    currentOrder: state.orderPreparation.currentOrder,
    visibleCondition: state.createOrder.visibleCondition,
  }), lodash.isEqual);
  const dispatch = useDispatch();
  // - State -
  const [quantity, setQuantity] = useState(String(Number(_quantity) || 1));
  const [quantityFocus, setQuantityFocus] = useState(false);
  const [searchItemList, setSearchItemList] = useState<{ value: string, label: string, serviceId: string, serviceName: string }[]>([...itemRes].map((v) => ({
    value: v.optionProductNameId,
    label: v.optionProductName,
    serviceId: v.optionServiceId,
    serviceName: v.optionServiceName,
  })));
  const [boardList, setBoardList] = useState<{ value: string, label: string }[]>([]);
  const [partsData, setPartsData] = useState('');
  const [productDetail, setProductDetail] = useState<ProductDetailResponse>();
  const [pageDataType, setPageDataType] = useState<PageDataTypesResponseEntity[]>([]);
  // -- お気に入り商品読み込み用フラグ --
  const [loadHandler, setLoadHandler] = useState(false);
  // - Callback -
  // -- 商品タグ --
  const handlerChangeBrandTag = useCallback((v, label) => {
    dispatch(ordersActions.option.setItemTag(v, index, label));
    dispatch(apiActions.run(
      new ApiOptionItem({
        productNameId: order.itemDetail.itemSelect.value,
        coverColorId: order.itemDetail.coverColorSelect.value || undefined,
        coverId: data.data.productDetail.coverTypeId || undefined,
        orderMethodId: order.itemDetail.orderMethod.value || undefined,
        boardInfoId: order.itemDetail.boardSelect.value || undefined,
        pageCountId1: !data.option.optionPageCount || order.itemDetail.orderMethod.value !== '10' ? String(order.genuine.page) : undefined,
        pageCountId2: data.option.optionPageCount && order.itemDetail.designType2.value ? String(order.genuine.page) : undefined,
        productTagId: v || undefined,
        agentMode: (isProxyMode) ? 'true' : undefined,
      }),
      {
        onSuccess: (res: ResponseBase<OptionItemResponse>) => {
          setSearchItemList((res.body.data?.optionProduct || []).map((v) => ({
            value: v.optionProductNameId,
            label: v.optionProductName,
            serviceId: v.optionServiceId,
            serviceName: v.optionServiceName,
          })));
          if ((!orderId && !importFav) && option[index]?.item && !(res.body.data?.optionProduct || []).find((v) => v.optionProductNameId === option[index].item.value)) {
            dispatch(ordersActions.option.setItem('', index, ''));
          }
        },
        onError: (e) => {
          setSearchItemList([]);
          if (!orderId && !importFav) {
            dispatch(ordersActions.option.setItem('', index, ''));
          }
        },
      },
    ));
  }, [order, data, index, importFav]);
  useEffect(() => {
    if (tag?.value) {
      dispatch(apiActions.run(
        new ApiOptionItem({
          productNameId: order.itemDetail.itemSelect.value,
          coverColorId: order.itemDetail.coverColorSelect.value || undefined,
          coverId: data.data.productDetail.coverTypeId || undefined,
          orderMethodId: order.itemDetail.orderMethod.value || undefined,
          boardInfoId: order.itemDetail.boardSelect.value || undefined,
          pageCountId1: !data.option.optionPageCount || order.itemDetail.orderMethod.value !== '10' ? String(order.genuine.page) : undefined,
          pageCountId2: data.option.optionPageCount && order.itemDetail.designType2.value ? String(order.genuine.page) : undefined,
          productTagId: tag.value,
          agentMode: (isProxyMode) ? 'true' : undefined,
        }),
        {
          onSuccess: (res: ResponseBase<OptionItemResponse>) => {
            setSearchItemList((res.body.data?.optionProduct || []).map((v) => ({
              value: v.optionProductNameId,
              label: v.optionProductName,
              serviceId: v.optionServiceId,
              serviceName: v.optionServiceName,
            })));
            if (!orderId && !importFav) {
              dispatch(ordersActions.option.setItem('', index, ''));
            }
          },
          onError: () => {
            setSearchItemList([]);
            if (!orderId && !importFav) {
              dispatch(ordersActions.option.setItem('', index, ''));
            }
          },
        },
      ));
    }
  }, [
    order.itemDetail.coverColorSelect.value,
    data.data.productDetail.coverTypeId,
    order.itemDetail.orderMethod.value,
    order.itemDetail.boardSelect.value,
    data.option.optionPageCount || order.itemDetail.orderMethod.value !== '10' ? String(order.genuine.page) : undefined,
    data.option.optionPageCount && order.itemDetail.designType2.value ? String(order.genuine.page) : undefined,
  ]);
  visibleCondition.checkOrderDifference(order, data);
  const display = visibleCondition.optionOrderDisplay[index] || {};
  // -- 商品選択 --
  const handlerChangeSelectBrand = useCallback((v, label) => {
    if (order.option.find((op, i) => op?.item?.value && op?.item?.value === v && i !== index)) {
      dispatch(dialogActions.pushMessage({
        title: '確認',
        message: [
          '商品情報ー商品詳細ーオプションー商品選択',
          `${label}は、`,
          '既に選択されています。',
        ],
        buttons: [{
          label: 'OK',
          callback: () => {
            dispatch(dialogActions.pop());
          },
        }],
      }));
      return;
    }
    const optionProductRule = optionProductRules.find((data) => data.optionProductName.find((rule) => rule.optionProductNameId === v));
    if (optionProductRule) {
      const selectedRule = option.find((op, i) => i !== index && optionProductRules.find((data) => data.optionProductName.find((v) => v.optionProductNameId === op.item?.value)));
      if (selectedRule) {
        dispatch(dialogActions.pushMessage({
          title: '確認',
          message: [
            '商品情報ー商品詳細ーオプションー商品選択',
            `${label}と`,
            `${selectedRule.item.label}を`,
            '同時に注文することはできません。',
          ],
          buttons: [{
            label: 'OK',
            callback: () => {
              dispatch(dialogActions.pop());
            },
          }],
        }));
        return;
      }
    }
    setLoadHandler(true);
    const searchData = searchItemList.find((listData) => listData.value === v);
    const resData = itemRes.find((listData) => listData.optionProductNameId === v);
    const serviceId = searchData?.serviceId || resData?.optionServiceId;
    const serviceName = searchData?.serviceName || resData?.optionServiceName;
    dispatch(ordersActions.option.setItem(
      v,
      index,
      label,
      serviceId,
      serviceName,
    ));
    if (v) {
      if (!checkQuantity( 1, display.quantity?.max || 0)) {
        if (visibleCondition.checkContentVisible(order, data, 'op01o_outputCount3', visibleCondition.visibleData.op01_option.op01o_parts2.op01op2_pageCount3)) {
          setQuantity('1');
          dispatch(ordersActions.option.setQuantity(1, index));
        } else {
          const q = order.itemDetail.quantity;
          setQuantity(String(q));
        }
        // switch (v) {
        //   case 'jptg303018':
        //   case 'jptg303002':
        //   case 'jptg304168':
        //   case 'jptg304169':
        //   case 'jptg304170':
        //   case 'jptg304171':
        //   case 'jptg304172':
        //   case 'jptg304173':
        //     setQuantity('1');
        //     dispatch(ordersActions.option.setQuantity(1, index));
        //     break;
        //   default:
        //     const q = order.itemDetail.quantity;
        //     setQuantity(String(q));
        //     dispatch(ordersActions.option.setQuantity(q, index));
        // }
      }
      dispatch(apiActions.run(
        new ApiOptionParts1({ productNameId: v }),
        {
          onSuccess: (res: ResponseBase<OptionPartsResponse>) => {
            setPartsData(res.body.data?.items?.[0]?.itemId || '');
            const ary = [...data.option.cover];
            ary[index] = { itemId: res.body.data?.items?.[0]?.itemId || '' };
            dispatch(ordersDataActions.updateOption(
              {
                cover: ary,
              }));
            if (serviceId === 'jp0441' && res.body.data?.items?.[0]?.itemId) {
              dispatch(apiActions.run(
                new ApiLaminates({
                  itemId: res.body.data?.items?.[0]?.itemId,
                  productNameId: v,
                }),
                {
                  onSuccess: (laminateRes: ResponseBase<LaminatesResponse>) => {
                    const data = laminateRes?.body?.data?.laminate?.[0];
                    if (data) {
                      dispatch(ordersActions.option.setLaminate(data.laminateId, index, data.laminateName));
                    }
                  },
                },
                { ignoreSystemError: true },
              ));
            }
          },
          onError: () => {
            setPartsData('');
          },
        },
        {
          ignoreSystemError: true,
        },
      ));
      const checkPartsCount = (partsCount: string | undefined) => {
        // const partsCount = productDetail?.itemCount;
        if (partsCount) {
          dispatch(ordersActions.option.setPartsCount(partsCount, index));
          if (Number(partsCount) >= 3) {
            dispatch(apiActions.run(
              new ApiOptionParts3({ productNameId: v }),
              {
                onSuccess: (res) => {
                  const ary = [...data.option.genuine];
                  ary[index] = { itemId: res.body.data?.items?.[0]?.itemId || '' };
                  dispatch(ordersDataActions.updateOption(
                    {
                      genuine: ary,
                    }));
                  if (serviceId === 'jp0441' && res.body.data?.items?.[0]?.itemId) {
                    dispatch(apiActions.run(
                      new ApiLaminates({
                        itemId: res.body.data?.items?.[0]?.itemId,
                        productNameId: v,
                      }),
                      {
                        onSuccess: (laminateRes: ResponseBase<LaminatesResponse>) => {
                          const data = laminateRes?.body?.data?.laminate?.[0];
                          if (data) {
                            dispatch(ordersActions.option.setLaminateGenuine(data.laminateId, index, data.laminateName));
                          }
                        },
                      },
                      { ignoreSystemError: true },
                    ));
                  }
                },
              },
            ));
          } else if (Number(partsCount) >= 2) {
            dispatch(apiActions.run(
              new ApiOptionParts2({ productNameId: v }),
              {
                onSuccess: (res) => {
                  const ary = [...data.option.genuine];
                  ary[index] = { itemId: res.body.data?.items?.[0]?.itemId || '' };
                  dispatch(ordersDataActions.updateOption(
                    {
                      genuine: ary,
                    }));
                  if (serviceId === 'jp0441' && res.body.data?.items?.[0]?.itemId) {
                    dispatch(apiActions.run(
                      new ApiLaminates({
                        itemId: res.body.data?.items?.[0]?.itemId,
                        productNameId: v,
                      }),
                      {
                        onSuccess: (laminateRes: ResponseBase<LaminatesResponse>) => {
                          const data = laminateRes?.body?.data?.laminate?.[0];
                          if (data) {
                            dispatch(ordersActions.option.setLaminateGenuine(data.laminateId, index, data.laminateName));
                          }
                        },
                      },
                      { ignoreSystemError: true },
                    ));
                  }
                },
              },
            ));
          }
        }
      }
      // 商材取得
      dispatch(apiActions.run(
        new ApiProductDetail({
          productNameId: v,
          serviceId: resData?.optionServiceId || '',
          parentFlg: '2',
        }),
        {
          onSuccess: (res: ResponseBase<ProductDetailResponse>) => {
            dispatch(ordersDataActions.setOptionProductDetail({
              data: res.body.data || null,
              index,
            }));
            setProductDetail(res.body.data);
            checkPartsCount(res.body.data?.itemCount);
          },
        },
      ));
      // ページ種
      dispatch(apiActions.run(
        new ApiPageDataTypes({
          productNameId: v,
          serviceId: resData?.optionServiceId || '',
          parentFlg: '2',
        }),
        {
          onSuccess: (res: ResponseBase<PageDataTypesResponse>) => {
            setPageDataType(res.body.data?.pageDataTypes || []);
          },
        },
      ));
      // 付加情報
      dispatch(apiActions.run(
        new ApiEndpaper({
          productNameId: v,
          parentFlg: '2',
        }),
        {
          onSuccess: (res: ResponseBase<EndpaperResponse>) => {
            dispatch(ordersDataActions.setOptionAdditional({
              data: res?.body?.data ? { ...res.body.data, itemId: v } : null,
              index,
            }));
          },
        },
      ))
    }
  }, [index, order, searchItemList, itemRes, option, optionProductRules, visibleCondition]);
  const handlerChangeBoard = useCallback((v, label) => {
    dispatch(ordersActions.option.setBoard(v, index, label));
  }, [index]);
  // -- 数量 --
  const handlerChangeNum = useCallback((v, max: number) => {
    if (!String(v)) {
      setQuantity(v);
    } else if (checkQuantity(v, max)) {
      setQuantity(v);
      if (!quantityFocus) {
        dispatch(ordersActions.option.setQuantity(v, index));
      }
    }
  }, [order, item?.value, quantityFocus, visibleCondition]);
  const handlerBlurNum = useCallback((v: string, max: number) => {
    setQuantityFocus(false);
    if (Number(v) && checkQuantity(v, max)) {
      dispatch(ordersActions.option.setQuantity(Number(v), index));
      setQuantity(v);
    } else {
      dispatch(ordersActions.option.setQuantity(1, index));
      setQuantity('1');
    }
  }, [quantity, index, visibleCondition]);
  // -- 削除 --
  const handlerClickDelete = useCallback(() => {
    dispatch(dialogActions.pushMessage({
      title: '確認',
      message: edit ? [
        '編集を行った関連する情報も削除されます。',
        '削除してよろしいですか？',
      ] : [
        '削除します。',
        'よろしいですか？',
      ],
      buttons: [
        {
          label: 'いいえ',
          callback: () => {
            dispatch(dialogActions.pop());
          },
        },
        {
          label: 'はい',
          callback: () => {
            dispatch(dialogActions.pop());
            dispatch(ordersActions.deleteOptionThunk(index));
            const coverList = [...data.option.cover];
            const genuineList = [...data.option.genuine];
            coverList.splice(index, 1);
            genuineList.splice(index, 1);
            dispatch(ordersDataActions.updateOption({
              cover: coverList,
              genuine: genuineList,
            }));
          },
        },
      ],
    }));
  }, [index, edit, data]);
  useEffect(() => {
    if (Number(_quantity) && Number(_quantity) !== Number(quantity)) {
      setQuantity(String(_quantity));
    }
  }, [_quantity]);
  useEffect(() => {
    if (item?.value) {
      dispatch(apiActions.run(
        new ApiBoardOptionInfos({ productNameId: item.value }),
        {
          onSuccess: (res: ResponseBase<BoardOptionInfosResponse>) => {
            setBoardList((res.body.data?.boardInfo || []).map((v) => ({
              value: v.boardInfoId,
              label: v.boardInfoName,
            })));
          },
        },
      ));
    } else {
      setBoardList([]);
    }
  }, [item?.value]);
  useEffect(() => {
    if (item?.value && item?.serviceId && !orderId) {
      dispatch(apiActions.run(
        new ApiOptionParts1({ productNameId: item.value }),
        {
          onSuccess: (res: ResponseBase<OptionPartsResponse>) => {
            setPartsData(res.body.data?.items?.[0]?.itemId || '');
            const ary = [...data.option.cover];
            ary[index] = { itemId: res.body.data?.items?.[0]?.itemId || '' };
            dispatch(ordersDataActions.updateOption(
              {
                cover: ary,
              }));
          },
          onError: () => {
            setPartsData('');
          },
        },
        {
          ignoreSystemError: true,
        },
      ));
      // 商材取得
      dispatch(apiActions.run(
        new ApiProductDetail({
          productNameId: item.value,
          serviceId: item?.serviceId || '',
          parentFlg: '2',
        }),
        {
          onSuccess: (res: ResponseBase<ProductDetailResponse>) => {
            dispatch(ordersDataActions.setOptionProductDetail({
              data: res.body.data || null,
              index,
            }));
            setProductDetail(res.body.data);
          },
        },
      ));
    }
    if (orderId && item) {
      handlerChangeSelectBrand(item.value, item.label);
    }
  }, []);
  useEffect(() => {
    if (tagRes.length && tag?.value && !tagRes.find((v) => v.optionProductTagId === tag.value) && (!orderId && !importFav)) {
      dispatch(ordersActions.option.setItemTag('', index, ''));
    }
  }, [tagRes]);
  useEffect(() => {
    if (itemRes.length && item?.value && !itemRes.find((v) => v.optionProductNameId === item.value) && (!orderId && !importFav)) {
      dispatch(ordersActions.option.setItem('', index, ''));
    }
  }, [itemRes]);
  useEffect(() => {
    if (tag?.value) {
      handlerChangeBrandTag(tag.value, tag.label);
    }
  }, []);
  useEffect(() => {
    if (!tag?.value) {
      setSearchItemList([...itemRes].map((v) => ({
        value: v.optionProductNameId,
        label: v.optionProductName,
        serviceId: v.optionServiceId,
        serviceName: v.optionServiceName,
      })));
    }
  }, [itemRes]);
  // const display = displayItems(order, data, index);
  // const display = visibleCondition.getOptionOrderDisplay(order, data, index);
  useEffect(() => {
    if (display.quantity && !_quantity) {
      dispatch(ordersActions.option.setQuantity(1, index));
    }
  }, [display.quantity]);
  // -- ヘルプボタン --
  const handlerClickHelp = useCallback((type: ImgType) => {
    dispatch(dialogActions.push({
      title: '説明',
      element: <HowToOrder imgType={type} />,
      closeBtn: true,
    }));
  }, []);
  useEffect(() => {
    if (!loadHandler && item?.value && item?.label) {
      handlerChangeSelectBrand(item.value, item.label);
    } else {
      setLoadHandler(false);
    }
    if (item?.value) {
      dispatch(apiActions.run(
        new ApiOptionParts1({ productNameId: item.value }),
        {
          onSuccess: (res: ResponseBase<OptionPartsResponse>) => {
            setPartsData(res.body.data?.items?.[0]?.itemId || '');
          },
          onError: () => {
            setPartsData('');
          },
        },
        {
          ignoreSystemError: true,
        },
      ));
    }
  }, [item?.value]);
  // - リストデータ -
  const _tagList = [...tagRes].map((v) => ({ value: v.optionProductTagId, label: v.optionProductTagId }));
  const tagList = [..._tagList];
  // const itemList = searchItemList.length ? [{ value: '', label: '選択なし' }, ...searchItemList] : [{ optionProductNameId: '', optionProductName: '選択なし' }, ...itemRes].map((v) => ({ value: v.optionProductNameId, label: v.optionProductName }));
  const itemList = [...searchItemList];
  // const laminateList = [{ laminateId: '', laminateName: '選択なし' }, ...laminateRes].map((v) => ({ value: v.laminateId, label: v.laminateName }));
  if (debug) {
    createDebugList(tagList);
    createDebugList(itemList);
    // createDebugList(laminateList);
  }
  // 商品記載情報用
  // const product = productDetail?.item?.find((v) => v.itemName === item.label);
  // const descriptionInfoDisplay = Boolean(product && product);
  if (debug) {
    // console.group(`オプション${index + 1}`);
    LogDecorator.group(`<magenta>オプション${index + 1}</magenta>`);
    console.group('絞り込み条件');
    console.log('表示: ', display.itemTag);
    console.log('データ: ', tagRes);
    console.groupEnd();
    console.group('商品選択');
    console.log('表示: ', display.item);
    console.log('データ: ', itemRes);
    console.groupEnd();
    // console.group('部材選択');
    // console.log('表示: ', display.board);
    // console.log('データ: ', boardList);
    // console.groupEnd();
    console.group('数量');
    console.log('表示: ', display.quantity);
    console.groupEnd();
    console.group('ラミ選択');
    console.log('表示: ', display.laminate);
    console.groupEnd();
    console.group('コメント');
    console.log('表示: ', display.description);
    console.groupEnd();
    console.groupEnd();
  }

  return (
    <div className="order_category option_order">
      <div className="bottom_border_header order_category__header">
        オプション{index + 1}
        <Button
          className="order_help order_category__header__tooltip"
          icon={<img src={iconHelp}  alt='' />}
          onClick={() => handlerClickHelp('title-option')}
        />
      </div>

      <div
        className="flex_box flex_space_between"
        style={{ gap: '0.5em' }}
      >
        <div>
          <div className="top_label_ui order_category__item">
            <div className="top_label_ui__label">絞り込み条件</div>
            <div className="top_label_ui__form">
              <Select
                value={tag?.value}
                list={tagList}
                onChange={(e, label) => handlerChangeBrandTag(e.target.value, label)}
                addEmpty={true}
                disabled={!importFav && layoutFinish === '1'}
              />
              <Button
                className="order_help"
                icon={<img src={iconHelp} alt='' />}
                onClick={() => handlerClickHelp('option-tag')}
              />
            </div>
          </div>

          <div className="top_label_ui order_category__item">
            <div className="top_label_ui__label">商品選択</div>
            <div className="top_label_ui__form">
              <Select
                value={item?.value || ''}
                list={itemList}
                onChange={(e, label) => handlerChangeSelectBrand(e.target.value, label)}
                addEmpty={true}
                disabled={!importFav && layoutFinish === '1'}
              />
              <Button
                className="order_help"
                icon={<img src={iconHelp} alt='' />}
                onClick={() => handlerClickHelp('option-item')}
              />
            </div>
          </div>

          {/*{display.board ? (*/}
          {/*  <div className="top_label_ui order_category__item">*/}
          {/*    <div className="top_label_ui__label">部材選択</div>*/}
          {/*    <div className="top_label_ui__form">*/}
          {/*      <Select*/}
          {/*        value={board?.value || ''}*/}
          {/*        list={boardList}*/}
          {/*        onChange={(e, label) => handlerChangeBoard(e.target.value, label)}*/}
          {/*        required={false}*/}
          {/*      />*/}
          {/*      <Button*/}
          {/*        className="order_help"*/}
          {/*        icon={<img src={iconHelp} />}*/}
          {/*        onClick={() => handlerClickHelp()}*/}
          {/*      />*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*) : (<></>)}*/}

          {display.quantity?.max ? (
            <div className="top_label_ui order_category__item">
              <div className="top_label_ui__label">数量</div>
              <div className="top_label_ui__form">
                <NumUpDown
                  value={quantity}
                  min={1}
                  callback={(num) => handlerChangeNum(String(num), display.quantity?.max || 0)}
                  onBlur={(e) => handlerBlurNum(e.target.value, display.quantity?.max || 0)}
                  onFocus={() => setQuantityFocus(true)}
                />
                <Button
                  className="order_help"
                  icon={<img src={iconHelp} alt='' />}
                  onClick={() => handlerClickHelp('option-quantity')}
                />
              </div>
            </div>
          ) : (<></>)}
        </div>

        <Button
          label="削除"
          onClick={handlerClickDelete}
          icon={<img src={iconDelete} alt="" />}
          color="light"
          style={{ margin: '0.75rem 0.75rem 0 0', minWidth: '6em' }}
          disabled={layoutFinish === '1'}
        />

      </div>

      {/* 表紙項目 */}
      {display.laminate ? (
        <Cover
          key={`option-cover-${index}-${partsData}`}
          partsData={partsData}
          index={index}
        />
      ) : (<></>)}

      {display.descriptionInfo && productDetail?.item?.find((v) => v.inheritParent === '' || v.inheritParent === 'false') ? (
        <DescriptionInfo
          index={index}
          productNameId={item.value}
          serviceId={item.serviceId}
          pageDataType={pageDataType}
          productDetail={productDetail}
        />
      ) : (<></>)}

      {display.description ? (
        <div className="top_label_ui__label">
          ※ミニブックの表紙画像は、主商材の表紙画像と同一となります
        </div>
      ) : (<></>)}

    </div>
  );
};
