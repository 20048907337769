import {Button} from '../../ui/button/button';
import {useCallback, useState} from 'react';
import {useDispatch} from 'react-redux';
import {Input} from '../../ui/input/input';
import {dialogActions} from '../slice/dialog-slice';

export const EnvConfig = () => {
  // - Hooks -
  const dispatch = useDispatch();
  // - State -
  // -- 実行するファイル --
  const [actionFile, setActionFile] = useState('');
  // -- 同時レタッチ可能画像数 --
  const [retouchImageNum, setRetouchImageNum] = useState(1);
  // -- 拡大率設定 --
  const [scaleConf, setScaleConf] = useState(10);
  // - Callback -
  // -- 実行するファイル更新 --
  const handlerChangeActionFile = useCallback((v) => {
    setActionFile(v);
  }, []);
  // -- 同時レタッチ可能画像数更新 --
  const handlerChangeRetouchImageNum = useCallback((v) => {
    setRetouchImageNum(v);
  }, []);
  // -- 拡大率設定更新 --
  const handlerChangeScaleConf = useCallback((v) => {
    setScaleConf(v);
  }, []);
  // -- 参照ボタン押下 --
  const handlerClickReference = useCallback(() => {}, []);
  // -- 完了ボタン押下 --
  const handlerClickComplete = useCallback(() => {
    dispatch(dialogActions.pop());
  }, []);
  // -- キャンセルボタン押下 --
  const handlerClickCancel = useCallback(() => {
    dispatch(dialogActions.pop());
  }, []);
  return (
    <div>
      <div>
        <div>
          レタッチソフト設定
        </div>
        <div>
          実行するファイル
          <Input
            value={actionFile}
            onChange={(e) => handlerChangeActionFile(e.target.value)}
          />
          <Button
            label="参照"
            onClick={handlerClickReference}
          />
        </div>
        <div>
          同時レタッチ可能画像数
          <Input
            value={retouchImageNum}
            onChange={(e) => handlerChangeRetouchImageNum(e.target.value)}
          />
        </div>
      </div>
      <div>
        <div>
          画像最大拡大率
        </div>
        <div>
          拡大率設定
          <Input
            value={scaleConf}
            onChange={(e) => handlerChangeScaleConf(e.target.value)}
          />倍
        </div>
        <div>
          ※画像の実寸×設定値倍（最大16倍）まで拡大可能です。<br />
          ※0~16の値を設定可能です。0に設定した場合、拡大及び各祝を行いません。
        </div>
      </div>
      <div>
        <Button
          label="キャンセル"
          onClick={handlerClickCancel}
        />
        <Button
          label="完了"
          onClick={handlerClickComplete}
        />
      </div>
    </div>
  );
};
