import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AppThunk} from "../app/store";

type DebugSliceState = {
  debug: boolean,
};

const initialState: DebugSliceState = {
  debug: false,
};

const debugSlice = createSlice({
  name: 'debug',
  initialState,
  reducers: {
    setDebug: (state, action: PayloadAction<boolean>) => {
      console.log(`debug mode: ${action.payload}`);
      state.debug = action.payload;
    },
  },
});

const addEvent = (): AppThunk => async (dispatch) => {
  if (!(window as any).debug) {
    (window as any).debug = (value?: any) => {
      dispatch(debugActions.setDebug(Boolean(value)));
    };
  }
};

export const debugActions = Object.assign(debugSlice.actions, { addEvent });
export const debugReducer = debugSlice.reducer;

export const createDebugList = (list: { value: string, label: string }[]) => {
  list.forEach((v) => {
    v.label = `${v.value}　:　　${v.label}`;
  });
  list.sort((a, b) => a.value > b.value ? 1 : -1);
  return [...list];
};