
import {ApiBaseBack} from "../api-base-back";

export class ApiOrderRegister extends ApiBaseBack {
  constructor(param: { shopOrderId: string }) {
    super(
      'POST',
      '/api/v1/orderRegist',
      'JSON',
      param,
    );
  }
}