import './image-upload-status.scss';

type ImageUploadStatusProps = {
  uploaded: boolean,
  uploading: boolean,
}

export const ImageUploadStatus = (props: ImageUploadStatusProps) => {
  const { uploaded, uploading } = props;
  return (
    <div className="image_upload_status" >
      {uploaded ? <i className="fas fa-cloud-upload-alt image_upload_status__uploaded" />
        : uploading ? <i className="fas fa-arrow-up image_upload_status__uploading"></i>
          : ''}
    </div>
  );
}