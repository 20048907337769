import { ILayoutEditorManagerBase } from '../layout-editor.manager';
import { CjContainer, CjShape, CjStage } from '../../model/cj-factory';
import { ImageEditManager } from '../image-edit/image-edit.manager';
import { UiManager } from '../ui/ui.manager';
import { PosManager } from '../pos/pos.manager';
import { LayoutDataManager } from '../data/layout-data.manager';
import { CjTool } from '../../../utilities/cj-tool';
import { AlbumManager } from '../../albam/albam';
import { ImageController } from '../image-edit/image/image-controller';
import { cloneDeep } from 'lodash';
import { CjGraphics } from '../../model/cj-factory/cj-graphics';
import { Pos } from '../../../models/pos';
import { store } from '../../../app/store';
import { FitChecker } from '../../../utilities/fit-checker';

export type TrimType = {
  topLine: number,
  bottomLine: number,
  windowSize: number,
  humanScale: number,
  topSpace: number,
  bottomSpace: number,
};

export class TrimmingManager implements ILayoutEditorManagerBase {
  // - manager -
  private uiManager!: UiManager;
  private posManager!: PosManager;
  private albumManager!: AlbumManager;
  private layoutDataManager!: LayoutDataManager;
  private imageEditManager!: ImageEditManager;
  // - canvas -
  private stage: CjContainer | null;
  // - timer -
  private timerId = -1;
  // - data -
  private pageId: string = '';
  public  isOpen: boolean = false;
  public trimLines: CjContainer | null = null;
  public trimGuides: CjContainer | null = null;
  private targetFrame: CjContainer | null = null;
  private checkList: number[] | null = null;
  private trimData: TrimType = {
    topLine: 0,
    bottomLine: 0,
    windowSize: 0,
    humanScale: 0,
    topSpace: 0,
    bottomSpace: 0,
  };
  private mode: 'trim:1st' | 'trim:2nd' | 'guide' | null = null;
  private vector: 'vertical' | 'horizontal' = 'vertical';
  private showTrimFlag: boolean = false;
  private showGuideFlag: boolean = false;
  private positionNum: number = 0;

  private draggingLine: CjShape | null = null;
  public isSetGuide: boolean = false;

  constructor() {
    this.stage = null;
  }

  di(
    uiManager: UiManager,
    posManager: PosManager,
    albumManager: AlbumManager,
    imageEditManager: ImageEditManager,
  ) {
    this.uiManager = uiManager;
    this.posManager = posManager;
    this.albumManager = albumManager;
    this.imageEditManager = imageEditManager;
  }

  initialize() {
    this.uiManager.on('r->l:set-trim-guide-check-list', (elm) => this.checkList = elm.list);
    this.uiManager.on('r->l:get-trim-guide-check-list', (elm) => elm.callback(this.checkList));
  }

  destroy() {
    this.stage?.removeEventListener('mousedown', this.lock);
    this.trimGuides?.removeAllChildren();
    UiManager.ins.off('r->l:set-trim-guide-check-list');
    UiManager.ins.off('r->l:get-trim-guide-check-list');
    this.checkList = null;
  }

  // - trimmingManager起動 -
  boot(stage: CjContainer, layoutDataMng: LayoutDataManager, pageId: string) {
    this.stage = stage;
    this.layoutDataManager = layoutDataMng;
    this.pageId = pageId;
    this.stage.addEventListener('mousedown', this.lock);
    this.trimGuides = new CjContainer();
    // stage.addChild(this.trimGuides);
  }

  // - trimmingManager再起動 -
  reboot(stage: CjContainer) {
    this.destroy();
    this.stage = stage;
    this.stage.addEventListener('mousedown', this.lock);
    this.trimGuides && this.stage.addChild(this.trimGuides);
    // this.layoutDataManager = layoutDataMng;
    // this.pageId = pageId;
    // this.trimGuides = new CjContainer();
    // stage.addChild(this.trimGuides);
  }

  // - 対象frame変更 -
  changeFrame(id: number) {
    const base = this.layoutDataManager.selector('getImageArea', { pageId: this.pageId, photoAreaId: String(id) });
    if (base) this.targetFrame = base;
    this.focusFrame();
  }

  focusFrame() {
    if (!this.targetFrame) return;
    this.disFocusFrame();
    const frames = this.layoutDataManager.selector('getImageAreas', this.pageId);
    const nonTargetFrames = frames.filter((elm: any) => elm.photoAreaId !== this.targetFrame?.photoAreaId);
    nonTargetFrames.forEach((v: CjContainer) => {
      CjTool.getGrayFilm(v).visible = true;
    });
  }

  disFocusFrame() {
    const frames = this.layoutDataManager.selector('getImageAreas', this.pageId);
    frames.forEach((v: CjContainer) => {
      CjTool.getGrayFilm(v).visible = false;
    });
  }

  // - trimmingStart -
  start(data: { id: number, windowSize: number, humanScale: number, topSpace: number, bottomSpace: number }) {
    // PosDebugger.posChecker(this.stage!);
    if (this.mode) return;
    if (!data.windowSize || !data.humanScale) {
      UiManager.ins.emit('l->r:push-dialog', {
        title: '確認',
        message: [
          `中枠高、または人寸の値が無効です。`,
        ],
        buttons: [
          {
            label: 'OK',
            callback: () => {
            },
          },
        ],
      });
      return;
    }
    if (this.trimLines) {
      const stage = this.getCjCanvas();
      stage.removeChild(this.trimLines);
    }
    this.trimLines = new CjContainer();
    this.trimData = {
      ...this.trimData,
      windowSize: data.windowSize,
      humanScale: data.humanScale,
      topSpace: data.topSpace,
      bottomSpace: data.bottomSpace,
    };
    this.layoutDataManager.dispatch('setTrimData', { id: data.id, data: this.trimData });
    this.setLine();
  }

  // - trimLineの用意 -
  setLine() {
    if (!this.trimLines) return;
    this.mode = this.mode === 'trim:1st' ? 'trim:2nd' : 'trim:1st';
    const stage = this.getCjCanvas();
    const lineS = this.createLineW();
    // const lineS = this.createTrim();
    this.trimLines.addChild(lineS);
    stage.addChild(this.trimLines);
    this.move(lineS);
  }

  // - trimmingEnd -
  end() {
    if (!this.trimLines) return;
    if (this.showTrimFlag) {
      this.trimLines.children.forEach((line) => {
        line.visible = true;
      });
    } else {
      this.trimLines.children.forEach((line) => {
        line.visible = false;
      });
    }
    this.trimData = {
      ...this.trimData,
      topLine: this.trimLines.children[0].y,
      bottomLine: this.trimLines.children[1].y,
    };
    this.mode = null;
    this.action();
  }

  // - trimmingロジック -
  action() {
    if (!this.targetFrame || !CjTool.checkPhotoInFrame(this.targetFrame)) return;
    const photo = CjTool.getPhotoContainer(this.targetFrame);
    const photoData = photo.getBounds();
    const photoTfData = photo.getTransformedBounds();
    const photoFrames: [] = this.layoutDataManager.selector('getEditors', photo.pageId);
    const trimLines = {
      top: this.trimData.topLine,
      bottom: this.trimData.bottomLine,
    };
    // -- 中窓データ --
    // const window = this.layoutDataManager.getAdditionalWindow(this.targetFrame.photoAreaId!, this.stage!) as CjShape;
    const window = this.layoutDataManager.getAdditionalWindow(this.targetFrame.photoAreaId!, this.pageId);
    const _windowData = window.data.windowSize[0].virtual[0].$;
    const windowData = {
      x: Number(_windowData.x),
      y: Number(_windowData.y),
      w: Number(_windowData.width),
      h: Number(_windowData.height),
    };

    // -- まず基本の拡大率 ( 窓高 / トリミング幅 ) --
    const trimHeight = trimLines.bottom - trimLines.top;
    const differenceScale = windowData.h / trimHeight;
    const differenceMove = trimLines.top - windowData.y;
    const trimScale = this.trimData.humanScale / this.trimData.windowSize;
    // console.log('wy', windowData.y);
    // console.log('wh', windowData.h);
    // console.log('th', trimHeight);
    // console.log('ds', differenceScale);
    // console.log('result', trimHeight * differenceScale);
    // console.log(trimHeight * differenceScale - trimHeight);
    // console.log('LINE_Y', lineY);
    // console.log('df', differenceMove);

    // -- trimMenuの拡大率 ( 人寸高 / 中窓高 ) --
    // console.log('ph1', photoData.height);

    // photo.set({
    //   scaleX: photo.scaleX * 2,
    //   scaleY: photo.scaleY * 2,
    // });

    // -- 4000px以上でエラー --
    if (photoTfData.height * differenceScale > ImageEditManager.IMAGE_MAX_SIZE) {
      UiManager.ins.emit('l->r:push-dialog', {
        title: '確認',
        message: [
          `画像が拡大の最大値を超えました。上下線をもっと離して指定してください。`,
        ],
        buttons: [
          {
            label: 'OK',
            callback: () => {
            },
          },
        ],
      });
      return;
    }

    // -- 上部余白値 --
    const ratio = {
      window: Math.round((this.trimData.windowSize / this.trimData.humanScale) * 100) / 100,
      human: 1,
    }
    const _marginTop = (ratio.window) / ((this.trimData.topSpace) + (this.trimData.bottomSpace)) * this.trimData.topSpace;
    // -- 未入力時のNaN回避 --
    const marginTop = isNaN(_marginTop) ? 0 : _marginTop;
    // console.log('marginTop', marginTop);
    // console.log((windowData.h - trimHeight) * marginTop);

    const differenceWindow = windowData.y - photo.parent.regY;
    const picScale = photo.scaleX * differenceScale * trimScale;
    const differencePicScale = Math.round((picScale / photo.scaleY) * 100) / 100;
    // -- 初期位置をwindowTopにあわせる --
    photo.y = (photo.y - differenceMove);
    // -- スケール差異文 余白調整 --
    photo.y = photo.y * differencePicScale - ((differenceWindow * differencePicScale) - differenceWindow) + ((windowData.h - (trimHeight * differencePicScale)) * (marginTop * trimScale));
    photo.movePos.y = (photo.movePos.y - differenceMove);
    photo.movePos.y = photo.movePos.y * differencePicScale - ((differenceWindow * differencePicScale) - differenceWindow) + ((windowData.h - (trimHeight * differencePicScale)) * (marginTop * trimScale));
    // -- trimming処理 ( 拡縮 ) --
    photo.set({
      scaleX: photo.scaleX * differenceScale * trimScale,
      scaleY: photo.scaleY * differenceScale * trimScale,
    });

    // -- トリミングラインの位置調整 --
    if (this.trimLines) {
      this.trimLines.children[0].y = windowData.y + ((windowData.h - trimHeight) * (marginTop * trimScale));
      this.trimLines.children[1].y = this.trimLines.children[0].y + (trimHeight * differenceScale * trimScale);
    }

    photo.trimFlag = true;
    if (this.stage) {
      const isToast = FitChecker.checkImageFit(photo, photoFrames, this.stage);
      this.imageEditManager.isFit = isToast;
      UiManager.ins.emit('l->r:show-toast', { isToast, target: this.stage });
    }
    ImageController.updateImageView(photo);
    this.uiManager.emit('l->r:trim:end');
  }

  // - ガイドの生成 -
  async setGuide(type: 'vertical' | 'horizontal', pos?: number) {
    if (this.mode) return;
    if (!this.trimGuides) return;
    if (pos === undefined) {
      const isRstore = await this.restoreGuide();
      if (!isRstore && !this.showGuideFlag) {
        this.showGuideFlag = true;
        for (const num of this.checkList ?? this.trimGuides.children.map((v, i) => i)) {
          this.trimGuides.children[num].visible = true;
        }
      }
    }
    this.mode = 'guide';
    this.isSetGuide = true;
    const stage = this.getCjCanvas();
    this.draggingLine = type === 'vertical' ? this.createLineH() : this.createLineW();
    this.trimGuides.addChild(this.draggingLine);
    stage.addChild(this.trimGuides);
    this.draggingLine.mode = 'edit';
    this.draggingLine.vector = this.vector;
    const hitArea = new CjShape();
    hitArea.set({
      x: hitArea.x,
      y: hitArea.y,
      graphics: new CjGraphics().s('white').ss(10).mt(0, 0).lt(
        (this.vector === 'horizontal' ? (this.imageEditManager.templateVirtual.w) : 0),
        (this.vector === 'horizontal' ? 0 : (this.imageEditManager.templateVirtual.h)),
      ),
    });
    this.draggingLine.hitArea = hitArea;
    if (pos === undefined) {
      this.move(this.draggingLine, type);
    } else {
      if (type === 'vertical') {
        this.draggingLine.x = pos;
      } else {
        this.draggingLine.y = pos;
      }

      this.lock(undefined, this.draggingLine);
    }
  }

  // - ガイドの中止 -
  changeVector(type: 'vertical' | 'horizontal') {
    if (!this.trimGuides) return;
    if (!this.draggingLine) return;
    const guideLength = this.trimGuides.numChildren;
    this.trimGuides.removeChildAt(guideLength - 1);
    this.isSetGuide = false;
    this.mode = null;
  }

  // - 横のガイド作成 -
  createTrim() {
    this.vector = 'horizontal';
    const line = new CjShape();
    if (!this.stage) {
      throw Error('stageが存在しません');
    }
    line.graphics
      .setStrokeStyle(1)
      .beginStroke('#B6B1C6')
      .moveTo(0, 0).lineTo(this.imageEditManager.templateVirtual.w, 0);
    return line;
  }

  // - 横のガイド作成 -
  createLineW() {
    this.vector = 'horizontal';
    const line = new CjShape();
    if (!this.stage) {
      throw Error('stageが存在しません');
    }
    line.graphics
      .setStrokeStyle(1)
      .beginStroke('blue')
      .moveTo(0, 0).lineTo(this.imageEditManager.templateVirtual.w, 0);
    return line;
  }

  // - 縦のガイド作成 -
  createLineH() {
    this.vector = 'vertical';
    const line = new CjShape();
    if (!this.stage) {
      throw Error('stageが存在しません');
    }
    line.graphics
      .setStrokeStyle(1)
      .beginStroke('blue')
      .moveTo(0, 0).lineTo(0, this.imageEditManager.templateVirtual.h);
    return line;
  }

  // - canvas取得 -
  private getCjCanvas() {
    if (!this.stage) {
      throw Error('stageが存在しません');
    } else {
      return this.stage;
    }
  }

  // - ガイドの移動イベント -
  move(line: CjShape, type?: 'vertical' | 'horizontal', _pos?: number) {
    if (line.originPos) {
      const pos = {
        x: (this.stage!.stage.mouseX - ImageEditManager.STAGE_CONTAINER_X) - (line.originPos.x),
        y: (this.stage!.stage.mouseY - ImageEditManager.STAGE_CONTAINER_Y) - (line.originPos.y),
      };
      if (type === 'vertical') {
        this.positionNum = Math.round(pos.x);
        line.x = this.positionNum;
      } else {
        this.positionNum = Math.round(pos.y);
        line.y = this.positionNum;
      }
    } else {
      this.timerId = window.setInterval(() => {
        const pos = {
          x: this.stage!.stage.mouseX - ImageEditManager.STAGE_CONTAINER_X,
          y: this.stage!.stage.mouseY - ImageEditManager.STAGE_CONTAINER_Y,
        };
        if (type === 'vertical') {
          this.positionNum = Math.round(pos.x);
          line.x = _pos ?? this.positionNum;
        } else {
          this.positionNum = Math.round(pos.y);
          line.y = _pos ?? this.positionNum;
        }
      });
    }
  }

  private changeColor(target: CjShape, color: string) {
    target.graphics.clear();
    const prev = cloneDeep(target);
    target.graphics.ss(1).s(color).mt(0, 0).lt(
      (target.vector === 'horizontal' ? this.imageEditManager.templateVirtual.w : 0),
      (target.vector === 'horizontal' ? 0 : this.imageEditManager.templateVirtual.h),
    );
    if (target.vector === 'vertical') {
      target.x = prev.x;
    } else {
      target.y = prev.y;
    }
  }

  private changeMode(e: any, mode: 'edit' | 'lock', _target?: CjShape) {
    const target = _target ?? e.target as CjShape;
      target.mode = mode;
    if (mode === 'edit') {
      this.changeColor(target, 'red');
    } else {
      this.changeColor(target, 'black');
    }
    this.trimGuides?.children.forEach(v => {
      if (v !== target) {
        const child = v as CjShape;
        child.mode = 'lock';
        this.changeColor(child, 'black');
      }
    });
  }
  moveEnd() {
    this.trimGuides?.children.forEach(v => {
        const child = v as CjShape;
        child.mode = 'lock';
        child.originPos = undefined;
        this.changeColor(child, 'black');
    });
  }

  // - ラインの固定 -
  private lock = (e: any, _target?: CjShape) => {
    window.clearInterval(this.timerId);
    switch (this.mode) {
      case 'trim:1st':
        this.setLine();
        break;
      case 'trim:2nd':
        this.end();
        break;
      case 'guide':
        if (this.isSetGuide) {
          this.changeMode(e, 'lock', _target);
          // this.draggingLine?.graphics.clear();
          // this.draggingLine?.graphics.ss(1).s('black').mt(0, 0).lt(
          //   (this.vector === 'horizontal' ? this.imageEditManager.templateVirtual.w : 0),
          //   (this.vector === 'horizontal' ? 0 : this.imageEditManager.templateVirtual.h),
          // );
          // if (this.vector === 'vertical') {
          //   this.draggingLine!.x = this.positionNum;
          // } else {
          //   this.draggingLine!.y = this.positionNum;
          // }
          // this.draggingLine?.off('click', this.changeMode);
          // this.changeColor(this.draggingLine!, 'black')
          this.draggingLine?.on('click', (e: any) => {
            e.stopPropagation()
            const target = e.target as CjShape;
            if (target.isNonInit) {
              this.changeMode(e, 'edit');
            } else {
              target.isNonInit = true;
            }
          });
          this.draggingLine?.on('mousedown', (e: any) => {
            e.stopPropagation()
            const target = e.target as CjShape;
            target.originPos = {
              x: this.stage!.stage.mouseX - ImageEditManager.STAGE_CONTAINER_X - target.x,
              y: this.stage!.stage.mouseY - ImageEditManager.STAGE_CONTAINER_Y - target.y,
            };
          });
          this.draggingLine?.on('pressmove', (e: any) => {
            e.stopPropagation()
            const target = e.target as CjShape;
            if (target.mode === 'edit') {
              this.move(target, target.vector);
            }
          });
          if (!_target) {
            this.uiManager.emit('l->r:create-guide:end', { type: this.vector, positionNum: this.positionNum });
          }
        }
        this.mode = null;
        this.isSetGuide = false;
        break;
      default:
        break;
    }
  };

  // - 天地線の表示・非表示 -
  showTrim() {
    this.showTrimFlag = !this.showTrimFlag;
    if (!this.trimLines) return;
    if (this.showTrimFlag) {
      this.trimLines.children.forEach((line) => {
        line.visible = true;
      });
    } else {
      this.trimLines.children.forEach((line) => {
        line.visible = false;
      });
    }
  }

  // - ガイドの表示・非表示 -
  async showGuide(list: number[]) {
    this.showGuideFlag = !this.showGuideFlag;
    if (!this.trimGuides) return;
    this.trimGuides.children.forEach((guide) => {
      guide.visible = false;
    });
    const isRstore = await this.restoreGuide();
    if (isRstore) return;
    if (this.showGuideFlag) {
      for (const num of list) {
        this.trimGuides.children[num].visible = true;
      }
    }
  }

  private async restoreGuide() {
    if (!this.trimGuides) return false;
    const _list = store.getState().layout.trimmingGuideList ?? [];
    if (this.trimGuides.children.length !== _list.length) {
      this.showGuideFlag = true;
      for (const data of _list) {
        this.setGuide(data.type, data.position);
      }
      return true;
    } else {
      return false;
    }
  }

  // - 選択中のガイドの表示・非表示 -
  selectGuide(list: number[]) {
    if (!this.trimGuides) return;
    this.trimGuides.children.forEach((guide) => {
      guide.visible = false;
    });
    for (const num of list) {
      this.trimGuides.children[num].visible = true;
    }
  }

  // - ガイドの削除 -
  deleteGuide(id: number) {
    if (!this.trimGuides) return;
    const target = this.trimGuides.children[id];
    this.trimGuides.removeChild(target);
  }
}
