import * as createjs from 'createjs-module';
import { CjBitmap } from './cj-bitmap';
import { CjContainer } from './cj-container';
import iconArrowFrame from '../../../assets/img/icon/arrow_frame_layout.svg';
import iconRotateFrame from '../../../assets/img/icon/rotate_frame_layout.svg';

export type CornerType = 'rt' | 'rb' | 'lb' | 'lt' | undefined;
export class CjCorner extends CjBitmap {
  readonly type: 'scale' | 'rotate';
  readonly position: CornerType;
  readonly iconSize: number;
  static readonly maxIconSize = 28;
  private iconData: {
    x: number,
    y: number,
    rotation: number,
  };

  constructor(
    type: 'scale' | 'rotate',
    position: CornerType,
    data: createjs.Rectangle,
  ) {
    if (type === 'scale') {
      super(iconArrowFrame);
      this.iconSize = 24;
    } else {
      super(iconRotateFrame);
      this.iconSize = 28;
    }
    switch (position) {
      case 'rt':
        this.iconData = {
          x: data.x + data.width,
          y: data.y,
          rotation: 0,
        };
        break;
      case 'rb':
        this.iconData = {
          x: data.x + data.width,
          y: data.y + data.height,
          rotation: type === 'scale' ? 90 : 180,
        };
        break;
      case 'lb':
        this.iconData = {
          x: data.x,
          y: data.y + data.height,
          rotation: 180,
        };
        break;
      case 'lt':
        this.iconData = {
          x: data.x,
          y: data.y,
          rotation: type === 'scale' ? 270 : 0,
        };
        break;
      default:
        this.iconData = {
          x: 0,
          y: 0,
          rotation: 0,
        };
        break;
    }
    super.set({
      x: this.iconData.x,
      y: this.iconData.y,
      rotation: this.iconData.rotation,
      regY: this.iconSize / 2,
      regX: this.iconSize / 2,
    });
    this.type = type;
    this.position = position;
  }

  // static async createCornerAsync(
  //   x: number,
  //   y: number,
  //   rotation: number,
  //   container: CjContainer,
  //   type: 'scale' | 'rotate',
  //   position: 'rt' | 'rb' | 'lb' | 'lt',
  // ): Promise<CjCorner> {
  //   // FIXME: iconSizeを固定値から変更する
  //   const iconSize: number = 12;
  //   return new Promise((resolve) => {
  //     const corner = new CjCorner(type, position);
  //     container.addChild(corner);
  //     corner.set({
  //       x,
  //       y,
  //       rotation,
  //       regY: iconSize,
  //       regX: iconSize,
  //     });
  //     resolve(corner);
  //   });
  // }
}