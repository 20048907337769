import { ILayoutEditorManagerBase } from '../layout-editor.manager';
import { EditableImage } from '../image-edit/editable-image';
import { UiManager } from '../ui/ui.manager';

type AddMode =
  'list' |
  'dragging'
  ;

export class EditableImageManager implements ILayoutEditorManagerBase {

  private static _ins: EditableImageManager;

  list: EditableImage[];
  dragging: EditableImage[];
  flags: {
    dragging: boolean
  };
  private uiManager!: UiManager;
  private initialized: boolean = false;

  private constructor() {
    this.list = [];
    this.dragging = [];
    this.flags = {
      dragging: false,
    };
  }

  static get ins() {
    if (EditableImageManager._ins) {
      return EditableImageManager._ins;
    }
    EditableImageManager._ins = new EditableImageManager();
    return EditableImageManager._ins;
  }

  static filter(kijshopCd: string, shopOrderId: string, orderId: string) {
    return (v: EditableImage) => (
      v.kijshopCd === kijshopCd
      && v.shopOrderId === shopOrderId
      && v.orderId === orderId
    );
  }

  static filterShopOrder(kijshopCd: string, shopOrderId: string, kind: string = '6') {
    return (v: EditableImage) => (
      v.kijshopCd === kijshopCd
      && v.shopOrderId === shopOrderId
      && v.kind === kind
    );
  }

  push(mode: AddMode, image: EditableImage) {
    switch (mode) {
      case 'list':
        /* 画像の重複を防ぐために selectID でフィルタリング */
        // if (image.selectId && this.list.find((v) => v.selectId === image.selectId && v.shopOrderId === image.shopOrderId)) {
        //   console.log('生成済の画像');
        //   break;
        // }
        this.list.push(image);
        this.uiManager.emit('l->r:change-editable-image-list', { list: this.list });
        this.uiManager.emit('l->r:pushed-editable-image-list', { list: this.list });
        break;
      case 'dragging':
        this.dragging.push(image);
        break;
    }
  }

  pop(mode: AddMode): EditableImage {
    switch (mode) {
      case 'list':
        const popImage = this.list.pop();
        if (!popImage) {
          throw new Error('pop できませんでした !!');
        }
        this.uiManager.emit('l->r:change-editable-image-list', { list: this.list });
        return popImage;
      case 'dragging':
        throw new Error('未実装');
    }
  }

  delete(mode: AddMode, editableImageId: string): void {
    switch (mode) {
      case 'list':
        const findIndex = this.list.findIndex((v) => v.id === editableImageId);
        if (findIndex === -1) throw new Error(`id : ${editableImageId} は見つかりません !!`);
        this.list.splice(findIndex, 1);
        this.uiManager.emit('l->r:change-editable-image-list', { list: this.list });
      break;
      case 'dragging': 
      throw new Error('未実装');
    }
  }

  clear(mode: AddMode) {
    switch (mode) {
      case 'list':
        this.list = [];
        this.uiManager.emit('l->r:change-editable-image-list', { list: this.list });
        break;
      case 'dragging':
        this.dragging = [];
        break;
    }
  }

  destroy(): void {
    this.list = [];
    this.dragging = [];
    this.uiManager.off('l->r:post-editable-image-list');
    this.uiManager.off('r->l:editable-image:drag:start');
    this.uiManager.off('r->l:editable-image:drag:end');
  }

  leaveFromLayoutPage(): void {
    this.list.forEach((v) => {
      v.flags.used = false;
      v.useCount = 0;
    });
  }

  di(): void {
  }

  initialize() {
    if (this.initialized) {
      // console.error('すでに初期化済みです !!');
      return;
    }
    // - di -
    this.uiManager = UiManager.ins;
    this.addEvent();
    this.initialized = true;
  }

  toUploaded(editableImageId: string, path: string, bool: boolean = true) {
    const editableImage: EditableImage | undefined = this.list.find((v) => v.id === editableImageId);
    if (!editableImage) {
      throw new Error(`id : ${editableImageId} は見つかりません !!`);
    }
    editableImage.flags.uploaded = bool;
    editableImage.path = path;
    this.uiManager.emit('l->r:change-editable-image-list', { list: this.list });
  }

  toUploading(editableImageId: string, bool: boolean = true) {
    const editableImage: EditableImage | undefined = this.list.find((v) => v.id === editableImageId);
    if (!editableImage) {
      throw new Error(`id : ${editableImageId} は見つかりません !!`);
    }
    editableImage.flags.uploading = bool;
    this.uiManager.emit('l->r:change-editable-image-list', { list: this.list });
  }

  toUse(editableImageId: string) {
    const editableImage: EditableImage | undefined = this.list.find((v) => v.id === editableImageId);
    if (!editableImage) {
      throw new Error(`id : ${editableImageId} は見つかりません !!`);
    }
    editableImage.useCount++;
    editableImage.flags.used = editableImage.useCount > 0;
    this.uiManager.emit('l->r:change-editable-image-list', { list: this.list });
  }

  toUnUse(editableImageId: string) {
    const editableImage: EditableImage | undefined = this.list.find((v) => v.id === editableImageId);
    if (!editableImage) {
      throw new Error(`id : ${editableImageId} は見つかりません !!`);
    }
    editableImage.useCount--;
    editableImage.flags.used = editableImage.useCount > 0;
    this.uiManager.emit('l->r:change-editable-image-list', { list: this.list });
  }

  toCcd(editableImageId: string, flag: boolean, isPack: boolean) {
    const editableImage: EditableImage | undefined = this.list.find((v) => v.id === editableImageId);
    if (!editableImage) {
      throw new Error(`id : ${editableImageId} は見つかりません !!`);
    }
    if (isPack) {
      editableImage.flags.retouchCcdPack = flag;
    } else {
      editableImage.flags.retouchCcd = flag;
    }
    this.uiManager.emit('l->r:change-editable-image-list', { list: this.list });
  }

  orderCheck(kijshopCd: string, shopOrderId: string) {
    // - 注文の絞り込み -
    const didFilterList = this.list.filter((v) => v.kijshopCd === kijshopCd && v.shopOrderId === shopOrderId);
    // - 使用済みのやつ -
    const usedList = didFilterList.filter((v) => v.flags.used);
    // - 未アップロードのやつ -
    const notUploadedList = usedList.filter((v) => !v.flags.uploaded);
    console.group('order-check');
    console.log(`[総]: ${didFilterList}`)
    console.log(`[済]: ${usedList}`)
    console.log(`[未]: ${notUploadedList}`)
    console.groupEnd();
    return {
      check: !notUploadedList.length,
      total: usedList.length,
      yet: notUploadedList.length,
    };
  }

  async getRealSize(editableImageId: string) {
    return new Promise<undefined | {realHeight?: string, realWidth?: string}>((resolve, reject) => {
      const editableImage: EditableImage | undefined = this.list.find((v) => v.id === editableImageId);
      if (!editableImage) {
        throw new Error(`id : ${editableImageId} は見つかりません !!`);
      }
      resolve({ realHeight: editableImage.realHeight, realWidth: editableImage.realWidth });
    })
  }

  initUse() {
    this.list.forEach((v) => {
      v.flags.used = false;
    })
  }

  private addEvent() {
    this.uiManager.on('l->r:post-editable-image-list', (e) => {
      e.callback({ list: this.list });
    });
    this.uiManager.on('r->l:editable-image:drag:start', (e) => {
      this.push('dragging', e.editableImage);
      this.flags.dragging = true;
    });
    this.uiManager.on('r->l:editable-image:drag:end', (e) => {
      this.clear('dragging');
      this.flags.dragging = false;
    });
  }
}

EditableImageManager.ins.initialize();
