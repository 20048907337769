import { ILayoutEditorManagerBase } from '../manager/layout-editor.manager';
import { UiManager } from '../manager/ui/ui.manager';
import { XmlParser } from '../../manager/xml-parser';
import { store } from '../../app/store';
import { LayoutDataManager } from '../manager/data/layout-data.manager';
import { ImageEditManager } from '../manager/image-edit/image-edit.manager';
import { ColorDropperManager } from '../manager/color-dropper/color-dropper.manager';
import { RulerManager } from '../manager/ruler/ruler.manager';
import { CommandManager } from '../manager/command/command.manager';
import { EditableImageManager } from '../manager/editable-image/editable-image.manager';
import { PanZoomManager } from '../manager/pan-zoom/pan-zoom.manager';
import { PreviewManager } from '../manager/preview/preview.manager';
import { PosManager } from '../manager/pos/pos.manager';
import { TrimmingManager } from '../manager/trimming/trimming.manager';
import { AdditionalManager } from '../manager/additional/additional.manager';
import { LayoutInitType } from '../../components/pages/layout/edit-template/layout.edit-template';
import { layoutActions } from '../../slices/layout-slice';
import { PicData, RestorePicData } from '../model/layout.xml.utile';

export interface AlbumBase {
  realWidth: number,
  realHeight: number,
  virtualWidth: number,
  virtualHeight: number,
  photoArea: AreaBase[],
  bgImages: BgImage[],
}

export type OrderParam = {
  kijshopCd: string,
  shopOrderId: string,
  orderId: string,
}

export interface BackBase {
  realWidth: number,
  realHeight: number,
  realX: number,
  realY: number,
  virtualWidth: number,
  virtualHeight: number,
  virtualX: number,
  virtualY: number,
}

export interface BgImage {
  name: string,
  type: 'base' | 'guide' | 'overwrap'
}

export interface FrameBase {
  id: number,
  name: string,
  blendMode: string,
  realWidth: number,
  realHeight: number,
  realX: number,
  realY: number,
  virtualWidth: number,
  virtualHeight: number,
  virtualX: number,
  virtualY: number,
}

export interface AreaBase {
  id: string,
  logo: boolean,
  depth: number,
  rotation: number,
  frameEditFlag: boolean,
  realWidth: number,
  realHeight: number,
  realX: number,
  realY: number,
  virtualWidth: number,
  virtualHeight: number,
  virtualX: number,
  virtualY: number,
  back: { flag: boolean, name: string, backData: BackBase | undefined },
  mask: { flag: boolean, name: string },
  photoFrame: { flag: boolean, count: number, frame: FrameBase[] },
  orgsubMaskID: string,
}

export class AlbumPage {
  // parseData!: any;
  public editableIDList: string[] = [];
  public restoreMount: boolean = false;
  constructor(
    public parseData: any,
    public id: string,
    // public type: 'cover' | 'top' | 'page' | 'end-cover',
    public typeID: string,
    public type: string,
    public displayName: string,
    public pageNo: string,
    public thumbnail: { templatePath: string, image: string },
    public isZip: { isZip: boolean, level: number },
    public imageEditManager: ImageEditManager | null = null,
    public indexes?: string[],
    public isOption: boolean = false,
    public memo: string = '',
    public additionalParseData?: any,
    public templateBlob?: Blob,
    public restorePicData?: PicData[],
    public isChange: boolean = false,
    public pageDataId: string = '1',
    public isGuide: boolean = true,
  ) {
    // new XmlParser()
    //   .parse(xml)
    //   .then((res) => {
    //     this.parseData = res;
    //   });
  }

  // 先頭末尾のスラッシュなし
  get dirName(): string {
    return this.parseData?.templateData?.compositeFileName?.[0]?.real?.[0].$.path;
  };
}

export class AlbumManager implements ILayoutEditorManagerBase {

  private container!: HTMLDivElement;

  public pages: AlbumPage[];
  public add: (page: AlbumPage, fnc?: () => void, restoreData?: OrderParam) => Promise<void>;
  public remove: (id: string, isDelete?: boolean) => void;
  public activate: (page: AlbumPage, kijshopCd: string, shopOrderId: string, orderId: string, initData: LayoutInitType) => Promise<void>;
  public deactivate: (page: AlbumPage, isRestore?: boolean, isDelete?: boolean, isComp?: boolean) => void;
  public currentAlbum: AlbumPage | null = null;
  private targetPage?: AlbumPage;

  private uiManager!: UiManager;
  private commandManager!: CommandManager;
  private editableImageManager!: EditableImageManager;
  private panZoomManager!: PanZoomManager;
  private layoutDataManager!: LayoutDataManager;
  private previewManager!: PreviewManager;
  private colorDropper!: ColorDropperManager;
  private rulerManager!: RulerManager;
  private posManager!: PosManager;
  private trimmingManager!: TrimmingManager;
  private additionalManager!: AdditionalManager;

  constructor() {
    // this.container = container;
    this.pages = [];
    this.add = async (v: AlbumPage, fnc?:() => void, restoreData?: OrderParam) => {
      this.currentAlbum = v;
      // console.log(this.currentAlbum);
      v.imageEditManager = new ImageEditManager();
      v.imageEditManager.di(
        this.uiManager,
        this.commandManager,
        this,
        this.editableImageManager,
        this.panZoomManager,
        this.layoutDataManager,
        this.previewManager,
        this.rulerManager,
        this.posManager,
        this.trimmingManager,
        this.additionalManager,
      );
      v.imageEditManager.initStage(restoreData).then(() => {
        this.uiManager.emit(
          'r->l:preview:make',
          {
            url: v.thumbnail.image,
            album: v,
            callback: (list) => {
              this.layoutDataManager.dispatch('setPreviewList', { list, target: v });
            },
          },
        );
        window.setTimeout(() => {
          fnc?.();
        }, 0);
      });
      this.pages.push(v);
    };
    this.commandManager = new CommandManager();
    this.rulerManager = new RulerManager();
    this.colorDropper = new ColorDropperManager();
    this.posManager = new PosManager();
    this.trimmingManager = new TrimmingManager();
    this.additionalManager = new AdditionalManager();
    this.remove = (v: string, isDelete?: boolean) => {
      const index = this.pages.findIndex((page) => page.id === v);
      if (index !== -1) {
        if (this.pages[index]) {
          for (const editableId of this.pages[index].editableIDList) {
            this.uiManager.emit('l->r:change-use-image-num', { type: 'decrement' });
            this.editableImageManager.toUnUse(editableId);
          }
          this.pages[index].imageEditManager?.destroy();
        }
          this.deactivate(this.pages[index], false, isDelete)
          this.layoutDataManager.dispatch('removeEditorContainer', this.pages[index].id);
          this.layoutDataManager.dispatch('removePreviewList', this.pages[index].id);
          this.uiManager.emit('l->r:removePreview', {album: this.pages[index]})
          this.currentAlbum = null;
          this.targetPage = undefined;
          store.dispatch(layoutActions.setCurrentAlbumPage(null));
        this.pages.splice(index, 1);
      }
    };
    this.activate = async(page: AlbumPage, kijshopCd: string, shopOrderId: string, orderId: string, initData: LayoutInitType) => {
      return new Promise<void>((resolve) => {
        this.targetPage = this.pages.find((elm) => elm.id === page.id);
        if (this.targetPage) this.currentAlbum = this.targetPage;
        if (!this.targetPage || !this.targetPage.imageEditManager) return;
        this.targetPage.imageEditManager?.activateStage(this.container, kijshopCd, shopOrderId, orderId, initData).then(() => resolve());
        this.colorDropper.di(this.targetPage.imageEditManager, this.uiManager, this);
        this.rulerManager.di(this.targetPage.imageEditManager, this.uiManager, this.panZoomManager);
        this.posManager.di(this.targetPage.imageEditManager, this.uiManager, this.panZoomManager);
        this.trimmingManager.di(this.uiManager, this.posManager, this, this.targetPage.imageEditManager);
        this.commandManager.di()
        this.uiManager.emit('l->r:change-template');
      })
    };
    this.deactivate = (page: AlbumPage, isRestore?: boolean, isDelete?: boolean, isComp?: boolean) => {
      const targetPage = this.pages.find((elm) => elm.id === page.id);
      targetPage?.imageEditManager?.setCanvasData()
      if (targetPage?.imageEditManager?.getCanvasEle() && !isRestore && !isDelete) {
          UiManager.ins.emit('r->l:transparent-additional', {type: 'show', callback: () => {}});
          targetPage.imageEditManager?.stage?.stage.update();
          this.previewManager.makePreviewImage(targetPage?.imageEditManager?.getCanvasEle(), targetPage);
        }
        if (targetPage) {
          targetPage.restoreMount = false;
          if (!isComp) {
            targetPage.imageEditManager?.deactivateStage(this.container);
          }
        }
      // this.prevPage = null;
    };
    // this.currentAlbum = store.getState().layout.currentAlbumPage;
  }

  initialize() {
    this.uiManager.on('r->l:add-album', async (e) => {
      await this.add(e.album, e.callback, e.restoreData);
    });
    this.uiManager.on('r->l:select-album', (e) => {
      this.currentAlbum = e.album;
      this.layoutDataManager.dispatch('setCurrentPage', e.album);
      e.callback && e.callback();
      // this.activate(e.album);
    });
    this.uiManager.on('r->l:delete-album', (e) => {
      if (this.currentAlbum?.id === e.albumID || this.pages.find((v) => v.id === e.albumID)) {
        this.remove(e.albumID, true);
      }
    })
    this.uiManager.on('r->l:container:didmount', (e) => {
      this.container = e.container;
      if (e.album) this.currentAlbum = e.album;
      this.currentAlbum && this.activate(this.currentAlbum, e.kijshopCd, e.shopOrderId, e.orderId, e.initData).then(() => e.callback?.());
      this.commandManager.initialize();
      this.rulerManager.initialize();
      this.colorDropper.initialize();
      this.trimmingManager.initialize();
    });
    this.uiManager.on('r->l:container:willunmount', (e) => {
      const target = e.albumPage;
      target && this.deactivate(target, e.isRestore, false, e.isComp);
      this.commandManager.destroy();
      this.colorDropper.destroy();
      this.rulerManager.destroy();
      this.trimmingManager.destroy();
    });
  }

  destroy(): void {
    this.currentAlbum && UiManager.ins.emit('r->l:container:willunmount', { albumPage: this.currentAlbum });
    this.uiManager.off('r->l:container:didmount');
    this.uiManager.off('r->l:container:willunmount');
    this.uiManager.off('r->l:add-album');
    this.uiManager.off('r->l:select-album');
    this.uiManager.off('r->l:delete-album');
  }

  di(
    uiMng: UiManager,
    editableMng: EditableImageManager,
    panZoomMng: PanZoomManager,
    LayoutDataMng: LayoutDataManager,
    previewMng: PreviewManager,
    additionalMng: AdditionalManager,
  ): void {
    this.uiManager = uiMng;
    this.editableImageManager = editableMng;
    this.panZoomManager = panZoomMng;
    this.layoutDataManager = LayoutDataMng;
    this.previewManager = previewMng;
    this.additionalManager = additionalMng;
  }

  getUsedEditableIDList(): string[] {
    return ([] as string[]).concat(...this.pages.map((v) => v.editableIDList));
  }

}
