import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { dialogActions } from '../../../dialog/slice/dialog-slice';
import { GuidList } from '../../../dialog/unique/guid-list';
import { PresetList } from '../../../dialog/unique/preset-list';
import { RegisterPreset } from '../../../dialog/unique/register-preset';
import { Button } from '../../../ui/button/button';
import { NumUpDown } from '../../../ui/input/num-up-down';
import './layout.trimming-controller.scss';
import * as lodash from 'lodash';
import { cloneDeep } from 'lodash';
import { useAppSelector } from '../../../../app/hooks';
import { UiManager } from '../../../../layout-editor/manager/ui/ui.manager';
import { layoutActions } from '../../../../slices/layout-slice';
import { store } from '../../../../app/store';
import useDraggable from '../../../../hooks/draggable-for-trimming';

type TrimmingControllerProps = {
  kijshopCd: string,
  closeCallback: () => void,
};
export type GuideType = {
  type: 'vertical' | 'horizontal',
  position: number,
}

// let guideList: GuideType[] = [];

export const TrimmingController = (props: TrimmingControllerProps) => {
  const { closeCallback, kijshopCd } = props;
  const { album, preset, presetList, guideList } = useAppSelector((state) => ({
    album: state.layout.currentAlbumPage,
    preset: state.layout.preset,
    presetList: state.storage.data.presetList,
    guideList: state.layout.trimmingGuideList,
  }), lodash.isEqual);

  const dispatch = useDispatch();
  const editEle = document.getElementsByClassName('layout__body__edit')[0];
  const editEleRect = editEle.getBoundingClientRect();
  // const editEleResizeObserver = new ResizeObserver(() => { });
  // - Ref -
  const ref = useRef<HTMLDivElement>(null);
  const jinsunRef = useRef<any>(null);
  const nakamadoRef = useRef<any>(null);
  // - Hooks -
  useDraggable(ref, { enableClassNameList: ['draggable'], limitClassName: 'layout__body__edit' });
  // - State -
  // -- position: fixed; における top --
  const [posTop, setPosTop] = useState(editEleRect.top);
  // -- position: fixed; における left --
  const [posLeft, setPosLeft] = useState(0);
  // -- チラつき防止用フラグ --
  const [display, setDisplay] = useState(false);
  // -- 選択中の中枠 --
  const [selectedMiddleFrame, setSelectedMiddleFrame] = useState(1);
  // -- 中枠高 --
  const [windowSize, setWindowSize] = useState(preset?.windowSize ?? '1');
  // -- 人寸高 --
  const [humanScale, setHumanScale] = useState(preset?.humanScale ?? '1');
  // -- 上の余白比率 --
  const [topSpace, setTopSpace] = useState(preset?.topSpace ?? '0');
  // -- 下の余白比率 --
  const [bottomSpace, setBottomSpace] = useState(preset?.bottomSpace ?? '0');
  // -- ガイドのリスト --
  // const [guideList, setGuideList] = useState<GuideType[]>([]);
  // -- チェックのついたガイドのリスト --
  const [checkList, setCheckList] = useState<number[]>([]);
  // -- trimming中か --
  const [isTrimming, setIsTrimming] = useState(false);
  // - Effect -
  useEffect(() => {
    const list = store.getState().layout.trimmingGuideList;
    const _album = store.getState().layout.currentAlbumPage;
    UiManager.ins.emit('r->l:get-trim-guide-check-list', { callback: (v) => {
      if (!v && list) {
        const check = list.map((v, i) => i);
        setCheckList(check);
      } else {
        if (v) setCheckList(v);
      }
    }})
    UiManager.ins.emit('r->l:change-middle-frame', { id: 1 });
    UiManager.ins.on('r->l:shortcut:change-middle-frame', (v) => {
      const _nextFrame = v.id + 1;
      let nextFrame;
      if (_nextFrame > album?.parseData.templateData.additionalFrame[0].windows[0].window.length) {
        nextFrame = 1;
      } else {
        nextFrame = _nextFrame;
      }
      changeMiddleFrame(nextFrame);
      v.callback(nextFrame);
    });
    UiManager.ins.on('r->l:shortcut:trim:start', () => {
      handlerClickTrim();
    });
    UiManager.ins.on('r->l:shortcut:show-trim-line', () => {
      showTrimLine();
    });
    UiManager.ins.on('r->l:shortcut:close-trim-menu', () => {
      closeCallback();
    });
    UiManager.ins.on('l->r:create-guide:end', (v) => {
      addGuide(v.type, v.positionNum);
    });
    UiManager.ins.on('l->r:change-middle-frame', (v) => {
      changeMiddleFrame(v.id);
    });
    UiManager.ins.on('l->r:trim:end', () => {
      setIsTrimming(false);
    });
    return () => {
      UiManager.ins.off('l->r:create-guide:end');
      UiManager.ins.off('l->r:change-middle-frame');
      UiManager.ins.off('l->r:shortcut:change-operation-mode-reset');
      UiManager.ins.off('r->l:shortcut:trim:start');
      UiManager.ins.off('r->l:shortcut:show-trim-line');
      UiManager.ins.off('r->l:shortcut:close-trim-menu');
      UiManager.ins.off('l->r:trim:end');
    };
  }, []);
  useEffect(() => {
    UiManager.ins.on('r->l:shortcut:show-guide-line', () => {
      showGuideLine();
    });
    return () => {
      UiManager.ins.off('r->l:shortcut:show-guide-line');
    };
  }, [checkList]);
  useEffect(() => {
    const funcPressF12 = (e: KeyboardEvent) => {
      if (e.key === 'F12') {
        e.preventDefault();
        handlerClickPresetList();
      }
    };
    window.addEventListener('keydown', funcPressF12);
    return () => {
      window.removeEventListener('keydown', funcPressF12);
    };
  }, [presetList]);


  // -- top, left初期化 --
  useEffect(() => {
    setPosLeft(editEleRect.x + editEleRect.width - (ref.current?.getBoundingClientRect().width || 0) - 8); // -8(px) スクロールバー幅分
    ref.current !== null && setDisplay(true);
  }, [editEle, ref.current]);

  useEffect(() => {
    if (!preset) return;
    setWindowSize(preset.windowSize);
    setHumanScale(preset.humanScale);
    setTopSpace(preset.topSpace);
    setBottomSpace(preset.bottomSpace);
  }, [preset]);

  // - Callback -
  const changeMiddleFrame = useCallback((num: number) => {
    UiManager.ins.emit('r->l:change-middle-frame', { id: num });
    setSelectedMiddleFrame(num);
  }, [selectedMiddleFrame]);
  const showTrimLine = useCallback(() => {
    UiManager.ins.emit('r->l:show-trim-line');
  }, []);
  const showGuideLine = useCallback(() => {
    UiManager.ins.emit('r->l:show-guide-line', { list: checkList });
  }, [checkList]);

  // -- 「プリセットリスト」押下 --
  const handlerClickPresetList = useCallback(() => {
    if (!presetList.length) {
      dispatch((dialogActions.pushMessage({
        title: '確認',
        message: [
          `プリセットリストがまだ登録されていません。`,
        ],
        buttons: [
          {
            label: 'OK',
            callback: () => {
              dispatch(dialogActions.pop());
            },
          },
        ],
      })));
    } else {
      dispatch(dialogActions.push({
        title: 'プリセットリスト',
        element: <PresetList kijshopCd={kijshopCd} />,
      }));
    }
  }, [presetList]);
  // -- 「プリセットリストに登録」押下 --
  const handlerClickResistPreset = useCallback(() => {
    let errorMessage = '';
    if (!Number(windowSize)) {
      errorMessage = '中窓高';
    } else if (!Number(humanScale)) {
      errorMessage = '人寸';
    }
    if (errorMessage) {
      dispatch(dialogActions.pushMessage({
        title: '確認',
        message: [`${errorMessage}の値は1以上で設定してください。`],
        buttons: [
          {
            label: 'OK',
            callback: () => {
              if (errorMessage === '中窓高') {
                nakamadoRef.current.focus();
              }
              if (errorMessage === '人寸') {
                jinsunRef.current.focus();
              }
              dispatch(dialogActions.pop());
            },
          },
        ],
      }));
      return;
    }
    dispatch(dialogActions.push({
      title: 'プリセットの登録',
      element: <RegisterPreset
        kijshopCd={kijshopCd}
        windowSize={windowSize}
        humanScale={humanScale}
        topSpace={topSpace}
        bottomSpace={bottomSpace}
      />,
    }));
  }, [windowSize, humanScale, topSpace, bottomSpace]);
  // -- 「リスト」押下 --
  const handlerClickList = useCallback(() => {
    dispatch(dialogActions.push({
      title: 'ガイドリスト',
      element: <GuidList
        list={guideList}
        checks={checkList}
        callback={(num: number, list: GuideType[]) => {
          UiManager.ins.emit('r->l:delete-guide', { id: num });
          // setGuideList(list);
          if (album) {
            dispatch(layoutActions.setTrimmingGuideList([...list]));
          }
          // guideList = [...list];
        }}
        updateChecks={(checks: number[]) => {
          const list = cloneDeep(checks);
          UiManager.ins.emit('r->l:set-trim-guide-check-list', { list });
          setCheckList(list);
        }
        }
      />,
    }));
  }, [guideList, checkList, album]);
  const handlerClickTrim = useCallback(() => {
    let errorMessage = '';
    if (!Number(windowSize)) {
      errorMessage = '中窓高';
    } else if (!Number(humanScale)) {
      errorMessage = '人寸';
    }
    if (errorMessage) {
      dispatch(dialogActions.pushMessage({
        title: '確認',
        message: [`${errorMessage}の値は1以上で設定してください。`],
        buttons: [
          {
            label: 'OK',
            callback: () => {
              if (errorMessage === '中窓高') {
                nakamadoRef.current.focus();
              }
              if (errorMessage === '人寸') {
                jinsunRef.current.focus();
              }
              dispatch(dialogActions.pop());
            },
          },
        ],
      }));
      return;
    }
    setIsTrimming(true);
    UiManager.ins.emit('r->l:trim:start', {
      trimData: {
        id: selectedMiddleFrame,
        windowSize: Number(windowSize || 0),
        humanScale: Number(humanScale || 0),
        topSpace: Number(topSpace || 0),
        bottomSpace: Number(bottomSpace || 0),
      },
    });
  }, [windowSize, humanScale, topSpace, bottomSpace, selectedMiddleFrame, nakamadoRef, jinsunRef]);
  const setVerticalLine = useCallback(() => {
    UiManager.ins.emit('r->l:create-guide:start', { type: 'vertical' });
  }, []);
  const setHorizontalLine = useCallback(() => {
    UiManager.ins.emit('r->l:create-guide:start', { type: 'horizontal' });
  }, []);
  const addGuide = useCallback((type: 'vertical' | 'horizontal', position: number) => {
    const _album = store.getState().layout.currentAlbumPage;
    const _list = store.getState().layout.trimmingGuideList ?? [];
    const list = [..._list, { type, position }];
    if (!album) return;
    dispatch(layoutActions.setTrimmingGuideList(list));
    let resultCheckList: number[] = [];
    setCheckList((prev) => {
      resultCheckList = [...prev, list.length - 1];
      return resultCheckList;
    });
    UiManager.ins.emit('r->l:set-trim-guide-check-list', { list: resultCheckList });
  }, [album, checkList]);


  return (
    <div
      className="trimming_controller"
      ref={ref}
      style={{
        ...!display && { display: 'none' },
      }}
      onClick={() => UiManager.ins.emit('r->l:dis-select')}
    >
      <div className="trimming_controller__grab draggable">
        <div className="trimming_controller__grab__icon draggable" />
      </div>
      <div className="trimming_controller__body">
        <div className="trimming_controller__body__top">
          <div className="trimming_controller__form">
            <span>選択中の中枠</span>
            <NumUpDown
              value={selectedMiddleFrame}
              disabled={isTrimming}
              min={1}
              max={album?.parseData.templateData.additionalFrame[0].windows[0].window.length}
              onBlur={(e) => setSelectedMiddleFrame(Number(e.target.value))}
              callback={(num) => changeMiddleFrame(num)}
            />
          </div>
          <Button
            label="プリセットリスト"
            color="dark"
            disabled={isTrimming}
            onClick={handlerClickPresetList}
          />
        </div>
        <div className="horizon_divider" />
        <div className="trimming_controller__body__middle">
          <Button
            label="プリセットリストに登録"
            color="dark"
            disabled={isTrimming}
            onClick={handlerClickResistPreset}
          />
          <div className="trimming_controller__body__middle__forms">
            <div className="trimming_controller__form">
              <span>中枠高（ｍｍ）</span>
              <NumUpDown
                type="number"
                disabled={isTrimming}
                value={windowSize}
                min={0}
                max={9999}
                ref={nakamadoRef}
                onBlur={(e) => setWindowSize(e.target.value)}
                isEmpty
                callback={num => setWindowSize(String(num))}
                // disabled
              />
            </div>
            <div className="trimming_controller__form">
              <span>人寸高（ｍｍ）</span>
              <NumUpDown
                type="number"
                disabled={isTrimming}
                value={humanScale}
                ref={jinsunRef}
                min={0}
                max={9999}
                isEmpty
                onBlur={(e) => setHumanScale(e.target.value)}
                callback={num => setHumanScale(String(num))}
                // disabled
              />
            </div>
            <div className="trimming_controller__form">
              <span>上の余白比率</span>
              <NumUpDown
                disabled={isTrimming}
                type="number"
                value={topSpace}
                min={0}
                max={99}
                onBlur={(e) => setTopSpace(e.target.value)}
                callback={num => setTopSpace(String(num))}
                // disabled
              />
            </div>
            <div className="trimming_controller__form">
              <span>下の余白比率</span>
              <NumUpDown
                disabled={isTrimming}
                type="number"
                value={bottomSpace}
                min={0}
                max={99}
                onBlur={(e) => setBottomSpace(e.target.value)}
                callback={num => setBottomSpace(String(num))}
                // disabled
              />
            </div>
          </div>
          <Button
            label="人寸トリミング実行"
            color="warning"
            disabled={isTrimming}
            onClick={() => handlerClickTrim()}
          />
        </div>
        <div className="horizon_divider" />
        <div className="trimming_controller__body__bottom">
          <div className="trimming_controller__body__bottom__row">
            <Button
              label="天地線・中心線の表示"
              color="dark"
              disabled={isTrimming}
              onClick={showTrimLine}
            />
          </div>
          <div className="trimming_controller__body__bottom__row">
            <Button
              label="ガイドの表示"
              color="dark"
              disabled={isTrimming}
              onClick={showGuideLine}
            />
            <Button
              label="リスト"
              color="dark"
              disabled={isTrimming}
              onClick={handlerClickList}
            />
          </div>
          <div className="trimming_controller__body__bottom__row">
            <Button
              label="新規ガイド（縦）"
              color="dark"
              disabled={isTrimming}
              onClick={setVerticalLine}
            />
          </div>
          <div className="trimming_controller__body__bottom__row">
            <Button
              label="新規ガイド（横）"
              color="dark"
              disabled={isTrimming}
              onClick={setHorizontalLine}
            />
          </div>
          <div className="trimming_controller__body__bottom__row">
            <Button
              label="閉じる"
              color="dark"
              disabled={isTrimming}
              onClick={() => closeCallback()}
            />
          </div>
        </div>
      </div>

    </div>
  );
};
