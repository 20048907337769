import { ApiBaseFront } from '../api-base-front';

export class ApiReceptionSerialIdGet extends ApiBaseFront<{ receptionSerialID: string }> {
  constructor(kijshopCd: string) {
    super(
      'GET',
      '/api/v1/order/receptionSerial/issue',
      'JSON',
      { kijshopCd },
    );
  }
}