import { XmlClass } from '../xml-class';
import { IOrderPage } from '../../interface/order/i-order-page';
import { XmlClassName } from '../../model/xml-class-name';
import { TOrderPageMetaModel } from '../../model/order/xml-order-page-model';
import { OrderPageDataXml } from './order-page-data-xml';

type TMeta = Partial<TOrderPageMetaModel>;

export class OrderPageXml extends XmlClass<IOrderPage, {}, TMeta, [string, string]> {

  // indexes!: [string, string];

  name: XmlClassName = 'order-page';
  viewModel: {} = {};
  metaModel: TMeta = {};

  // order-page に紐づく order-page-data
  orderPageDataArr: OrderPageDataXml[] = [];
  orderPageData(pageNo: string | number): OrderPageDataXml | null {
    const data = this.metaModel.paths?.find((v) => Number(v.id) === Number(pageNo));
    if (!data) {
      return null;
    }
    const orderPageData = this.orderPageDataArr.find((v) => v.xmlUniqueName === data.path);
    return orderPageData || null;
  }

  constructor(shopOrderId: string, data: { version: string, indexes: [string | number, string | number] }) {
    super(shopOrderId, data);
  }
  protected init(): void {
    this.xmlUniqueName = this.xmlUniqueName || `PPM_${this.shopOrderId}_11_11_orderPage_${this.indexes[0]}_${this.indexes[1]}.xml`;
    this.xmlParentName = this.xmlParentName || `PPM_${this.shopOrderId}_11_11_orderPartsData_${this.indexes[0]}_${this.indexes[1]}.xml`;
    this.rootTagModel = {
      ...this.rootTagModel,
      parentXPath: '/lnwOrderPartsData/orderPage',
      parentPath: this.xmlParentName,
    };
    this.docking({}, {});
  }

  protected docking(v: {}, m: TMeta): void {
    this.xmlModel = {
      lnwOrderPage: {
        $: { ...this.rootTagModel },
        orderPage: [
          {
            $: {
              count: `${m.paths?.length}`,
            },
            data: m.paths?.map((data, index) => ({
              $: {
                id: data.id || `${index + 1}`,
                path: data.path,
              },
            })),
          },
        ],
      },
    };
  }

  protected split(x: IOrderPage): void {
    if (!x?.lnwOrderPage?.orderPage || !x.lnwOrderPage.$) {
      this.metaModel = {};
      return;
    }
    this.metaModel = {
      paths: x.lnwOrderPage.orderPage?.[0]?.data?.map((v) => ({
        id: v.$.id || '',
        path: v.$.path || '',
      })),
    };
    this.rootTagModel = Object.assign(this.rootTagModel, x.lnwOrderPage.$);
  }

  changeIndexes(indexes: [string, string]) {
    this.indexes = indexes;
    this.xmlUniqueName = `PPM_${this.shopOrderId}_11_11_orderPage_${this.indexes[0]}_${this.indexes[1]}.xml`;
    this.xmlParentName = `PPM_${this.shopOrderId}_11_11_orderPartsData_${this.indexes[0]}_${this.indexes[1]}.xml`;
    this.rootTagModel = {
      ...this.rootTagModel,
      parentPath: this.xmlParentName,
    };
  }
}
