import {Button} from '../../../ui/button/button';
import {useCallback, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {EnvConfig} from '../../../dialog/unique/env-config';
import {dialogActions} from '../../../dialog/slice/dialog-slice';
import {push} from 'connected-react-router';
import { PathParams, RoutingPath } from '../../../../routes/routing-path';
import './retouch.menu.scss';
import {BubbleType, retouchActions, ShowMenuType} from '../../../../slices/retouch-slice';
import { useAppSelector } from '../../../../app/hooks';
import * as lodash from 'lodash';
import { useParams } from 'react-router-dom';
import { localStorageActions } from '../../../../slices/local-storage-slice';

type ByType = 'name' | 'date';
type OrderType = 'asc' | 'dec';

export const RetouchMenu = () => {
  const dispatch = useDispatch();
  const {displayType, sortType, sortState} = useAppSelector((state) => ({
    displayType: state.retouch.bubbleType,
    sortType: state.retouch.sortType,
    sortState: state.storage.data.retouchMenu,
  }), lodash.isEqual);
  const { kijshopCd, shopOrderId, orderId } = useParams<PathParams>();
  // - State -
  // -- コマ表示 --
  // const [bubble, setBubble] = useState<BubbleType>(sortState.bubbleType);
  // -- 並び順種類 --
  const [by, setBy] = useState<ByType>((sortState.sortType.split('-')[1] as any));
  // const [by, setBy] = useState<ByType>('name');
  // -- 昇順/降順 --
  const [order, setOrder] = useState<OrderType>((sortState.sortType.split('-')[0] as any));
  // const [order, setOrder] = useState<OrderType>('asc');
  // -- レタッチメニュー表示 --
  const [isDisplay, setIsDisplay] = useState(true);
  // -- 商品情報表示 --
  const [isDisplayName, setIsDisplayName] = useState(false);
  const [showMenuType, setShowMenuType] = useState<ShowMenuType>(sortState.showMenuType);
  // -- 画像一覧表示 --
  const [isList, setIsList] = useState(false);
  // - Callback -
  // -- 一覧表示 --
  const handlerClickList = useCallback(() => {
    // setBubble('list');
    dispatch(localStorageActions.setData(kijshopCd, 'retouchMenu', {...sortState, bubbleType: 'list'}));
  }, [sortState, kijshopCd]);
  // -- 6コマ表示 --
  const handlerClick6Bubble = useCallback(() => {
    // setBubble('six');
    dispatch(localStorageActions.setData(kijshopCd, 'retouchMenu', {...sortState, bubbleType: 'six'}));
  }, [sortState, kijshopCd]);
  // -- 1コマ表示 --
  const handlerClick1Bubble = useCallback(() => {
    // setBubble('one');
    dispatch(localStorageActions.setData(kijshopCd, 'retouchMenu', {...sortState, bubbleType: 'one'}));
  }, [sortState, kijshopCd]);
  // -- 名前順 --
  const handlerClickByName = useCallback(() => {
    setBy('name');
    dispatch(localStorageActions.setData(kijshopCd, 'retouchMenu', {...sortState, sortType: order === 'asc' ? 'asc-name' : 'dec-name'}));
  }, [order, sortState, kijshopCd]);
  // -- 日付順 --
  const handlerClickByDate = useCallback(() => {
    setBy('date');
    dispatch(localStorageActions.setData(kijshopCd, 'retouchMenu', {...sortState, sortType: order === 'asc' ? 'asc-date' : 'dec-date'}));
  }, [order, sortState, kijshopCd]);
  // -- 昇順 --
  const handlerClickOrderAsc = useCallback(() => {
    setOrder('asc');
    dispatch(localStorageActions.setData(kijshopCd, 'retouchMenu', {...sortState, sortType: by === 'name' ? 'asc-name' : 'asc-date'}));
  }, [by, sortState, kijshopCd]);
  // -- 降順 --
  const handlerClickOrderDec = useCallback(() => {
    setOrder('dec');
    dispatch(localStorageActions.setData(kijshopCd, 'retouchMenu', {...sortState, sortType: by === 'name' ? 'dec-name' : 'dec-date'}));
  }, [by, sortState, kijshopCd]);
  // -- レタッチメニュー表示 --
  const handlerClickDisplayRetouchMenu = useCallback(() => {
    setIsDisplay(prev => !prev);
  }, []);
  // -- 商品情報表示 --
  const handlerClickDisplayGoodsName = useCallback(() => {
    setIsDisplayName(prev => !prev);
  }, []);
  // -- レタッチメニューと商品情報の表示 --
  const handlerClickShowMenuType = useCallback((type: ShowMenuType) => {
    setShowMenuType(type);
    dispatch(localStorageActions.setData(kijshopCd, 'retouchMenu', {...sortState, showMenuType: type}))
  }, [sortState, showMenuType, kijshopCd]);
  // -- 画像一覧表示 --
  const handlerClickDisplayBrandInfo = useCallback(() => {
    setIsList(prev => !prev);
  }, []);

  // -- レタッチ中止 --
  const handlerClickCancelRetouch = useCallback(() => {
    dispatch(dialogActions.pushMessage({
      title: '確認',
      message: ['保存していない内容は失われます。終了してもよろしいですか?'],
      buttons: [
        {
          label: 'いいえ',
          callback: () => {
            dispatch(dialogActions.pop());
          },
        },
        {
          label: 'はい',
          callback: () => {
            dispatch(dialogActions.pop());
            dispatch(push(RoutingPath.cnv.ordersPreparation({ kijshopCd, shopOrderId })));
          },
        },
      ],
    }));
  }, []);
  useEffect(() => {
    return () => {
      dispatch(retouchActions.changeBubbleType('list'));
      dispatch(retouchActions.setShowMenuType('retouch'));
    };
  }, []);

  return (
    <div className="retouch_menu">
      <div>
        <div className="retouch_menu__bubble switches_wrap_bg">
          <Button
            label="一覧"
            onClick={handlerClickList}
            color={sortState.bubbleType === 'list' ? 'primary' : 'dark'}
            size="sm"
            tooltip={{ type: "normal", text: ["一覧表示画像に切り替えます。"] }}
          />
          <Button
            label="6コマ"
            onClick={handlerClick6Bubble}
            color={sortState.bubbleType === 'six' ? 'primary' : 'dark'}
            size="sm"
            tooltip={{ type: "normal", text: ["6コマ表示画面に切り替えます"] }}
          />
          <Button
            label="1コマ"
            onClick={handlerClick1Bubble}
            color={sortState.bubbleType === 'one' ? 'primary' : 'dark'}
            size="sm"
            tooltip={{ type: "normal", text: ["1コマ表示画面に切り替えます"] }}
          />
        </div>

        <div className="retouch_menu__by switches_wrap_bg">
          <Button
            label="名前順"
            onClick={handlerClickByName}
            color={sortState.sortType.split('-')[1] as any === 'name' ? 'primary' : 'dark'}
            size="sm"
          />
          <Button
            label="日付順"
            onClick={handlerClickByDate}
            color={sortState.sortType.split('-')[1] as any === 'date' ? 'primary' : 'dark'}
            size="sm"
          />
        </div>

        <div className="retouch_menu__order switches_wrap_bg">
          <Button
            label="昇順"
            onClick={handlerClickOrderAsc}
            color={sortState.sortType.split('-')[0] as any === 'asc' ? 'primary' : 'dark'}
            size="sm"
          />
          <Button
            label="降順"
            onClick={handlerClickOrderDec}
            color={sortState.sortType.split('-')[0] as any === 'dec' ? 'primary' : 'dark'}
            size="sm"
          />
        </div>
      </div>

      <div className="retouch_menu__display">
        <div className="switches_wrap_bg">
          レタッチメニュー
          <Button
            label="非表示"
            onClick={() => handlerClickShowMenuType('none')}
            color={(sortState.showMenuType === 'name' || sortState.showMenuType === 'none') || sortState.bubbleType === 'one' ? 'primary' : 'dark'}
            size="sm"
            disabled={sortState.bubbleType === 'one'}
          />
          <Button
            label="表示"
            onClick={() => handlerClickShowMenuType('retouch')}
            color={(sortState.showMenuType === 'retouch' && sortState.bubbleType !== 'one') ? 'primary' : 'dark'}
            size="sm"
            disabled={sortState.bubbleType === 'one'}
          />
        </div>
        <div className="switches_wrap_bg">
          商品情報
          <Button
            label="非表示"
            onClick={() => handlerClickShowMenuType('none')}
            color={sortState.showMenuType === 'retouch' || sortState.showMenuType === 'none' ? 'primary' : 'dark'}
            size="sm"
          />
          <Button
            label="表示"
            onClick={() => handlerClickShowMenuType('name')}
            color={sortState.showMenuType === 'name' ? 'primary' : 'dark'}
            size="sm"
          />
        </div>
        {/*<Button*/}
        {/*  label="画像一覧表示"*/}
        {/*  onClick={handlerClickDisplayBrandInfo}*/}
        {/*  color="dark"*/}
        {/*  size="sm"*/}
        {/*/>*/}
      </div>
      <div className="left_border_divider">
        <Button
          label="レタッチ中止"
          onClick={handlerClickCancelRetouch}
          color="danger"
          size="sm"
        />
      </div>
    </div>
  );
};
