import { Table } from '../../ui/table/table';
import { Button } from '../../ui/button/button';
import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { dialogActions } from '../slice/dialog-slice';

export const LoadingFavoriteBrand = () => {
  // - Hooks -
  const dispatch = useDispatch();
  // - Callback -
  // -- 閉じるボタン押下 --
  const handlerClickClose = useCallback(() => {
    dispatch(dialogActions.pop());
  }, []);

  return (
    <div className="dialog_contents loading_favorite_brand">
      <div className="dialog_contents__body">
        <Table head={['お気に入り商品名']} body={[
          ['01_サムネイル更新'],
          ['02_サムネイル更新テスト'],
          ['0３_箔押しテスト_名称変更前'],
          ['04_おまかせ'],
          ['05_簡易レイアウト'],
          ['06_完全レイアウト'],
          ['07_完成画像'],
        ]} />
      </div>
      <div className="dialog_contents__footer">
        <Button
          label="キャンセル"
          onClick={handlerClickClose}
        />
        <Button label="OK" />
      </div>
    </div>
  );
};
