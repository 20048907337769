import { ApiBaseFront } from '../api-base-front';
import { GetAddressResponse } from '../../../models/api/front/get-address';

export class ApiGetAddress extends ApiBaseFront<GetAddressResponse> {
  constructor(_param: { kijshopCd: string, zipCode: string }) {
    const { zipCode, ...param } = _param;
    super(
      'GET',
      `/api/v1/master/address/${zipCode}`,
      'JSON',
      param,
    );
  }
}