import {
  WizardBody,
  WizardBodyList,
  WizardButton,
  WizardButtonList,
  WizardContent,
} from '../../../ui/wizard/wizard-content';
import React from 'react';
import { push } from 'connected-react-router';
import { RoutingPath } from '../../../../routes/routing-path';
import { useDispatch } from 'react-redux';

export const Step4AdminPasswordResetWizard = () => {
  // - Hooks -
  const dispatch = useDispatch();
  return (
    <WizardContent>
      <WizardBodyList>
        <WizardBody body={['店舗のパスワードを変更しました。']} />
      </WizardBodyList>
      <WizardButtonList>
        <WizardButton
          label={'閉じる'}
          onClick={() => dispatch(push(RoutingPath.login))}
        />
      </WizardButtonList>
    </WizardContent>
  );
};