import { OrderCopyPostRequest } from "../../../models/api/front/order-copy";
import { ApiBaseFront } from "../api-base-front";

// コピーして注文
export class ApiOrderCopyPost extends ApiBaseFront {
  constructor(param: OrderCopyPostRequest) {
    super(
      'POST',
      '/api/v1/orderxml/copy',
      'JSON',
      param,
    );
  }
}
