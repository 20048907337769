import { useState, useRef, useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../../app/hooks';
import {
  favoriteTemplateActions,
  FavoriteTemplateItem,
  FavTemplateInfo,
} from '../../../../slices/favorite-template-slice';
import { AddFolder } from '../../../dialog/unique/add-folder';
import { ChangeFolderName } from '../../../dialog/unique/change-folder-name';
import { toolbarActions } from '../../../toolbar/slice/toolbar-slice';
import { dialogActions } from '../../../dialog/slice/dialog-slice';
import { SwitchIcon } from '../../../ui/switch-icon/switch-icon';
import folderOpenOn from '../../../../assets/img/icon/folder-open-on.svg';
import folderCloseOn from '../../../../assets/img/icon/folder-close-on.svg';
import * as lodash from 'lodash';
import { useParams } from 'react-router-dom';
import { PathParams } from '../../../../routes/routing-path';
import { apiActions } from '../../../../slices/api-slice';
import {
  ApiFavoriteTemplateDelete,
  ApiFavoriteTemplateGet,
  ApiFavoriteTemplatePost, ApiFavoriteTemplateUpdate,
} from '../../../../api/front/favorite-template/api-favorite-template';
import {
  layoutActions,
  LayoutItemData,
  LayoutQueryParams,
  LayoutTemplateFolderData,
} from '../../../../slices/layout-slice';
import { getTargetPathUrl } from '../all-template/layout.all-template';
import {
  AddFavoriteTemplateInfo,
  FavoriteTemplateGetResponse,
  FavoriteTemplateListItem,
  FavoriteTemplatePostRequest,
} from '../../../../models/api/front/favorite-template';
import {
  ApiFavoriteFolderDelete,
  ApiFavoriteFolderGet,
  ApiFavoriteFolderPost,
} from '../../../../api/front/favorite-folder/api-favorite-folder';
import { ResponseBase } from '../../../../api/response-base';
import {
  FavoriteFolderGetResponse,
  FavoriteFolderListItem,
  FavoriteFolderPostRequest,
  FavoriteFolderPostResponse,
} from '../../../../models/api/front/favorite-folder';
import { resolve } from 'path';
import { cloneDeep, isEqual } from 'lodash';
import React from 'react';
import { folder } from 'jszip';
import { useDidMount } from '../../../../hooks/life-cycle';
import { SortBoundary } from '../../../ui/sort-boundary/sort-boundary';
import { LoadingPopup } from '../../../ui/loading/loading-popup';
import { TemplateLoaderManager } from '../../../../layout-editor/manager/template-loader/template-loader.manager';

// - フォルダ名に使用する番号を返す -
const availableNumber = (
  count: number,
  usedNumList: number[],
): number => {
  const isUsed = usedNumList.filter((v) => v === count);
  let resultNum: number;
  if (isUsed.length) {
    resultNum = availableNumber(count + 1, usedNumList);
  } else {
    resultNum = count;
  }
  return resultNum;
};

// - フォルダ名の初期値を設定する -
export const createInitValue = (
  allFolders: FavoriteFolderListItem[],
): string => {
  const filterName = allFolders.filter((v) => v.name.match('お気に入り'))
    .filter((v) => !v.parentId || allFolders.map((v2) => v2.folderId)
      .includes(v.parentId)).map((v) => v.name.replace('お気に入り', ''))
    .filter((v) => !isNaN(Number(v))).map((v) => Math.floor(Number(v)));
  filterName.sort((a, b) => b - a);
  let index = 1;
  while (true) {
    if (!filterName.includes(index + 1) && !filterName.includes(index)) {
      break;
    } else {
      index += 1;
    }
  }
  return `お気に入り${index}`;
};

// - お気に入りテンプレートのIDを返す -
const getTemplateTypeChildrenId = (
  folder: FavoriteTemplateItem,
): string[] => {
  let result: string[] = [];
  if (folder.children?.length) {
    for (const child of folder.children) {
      if (child.type === 'template') {
        result.push(child.id);
      } else {
        if (child.children?.length) {
          result = getTemplateTypeChildrenId(child);
        }
      }
    }
  }
  return result;
};

type ToggledFoldersProps = {
  // - 商品ID -
  productNameId: string | undefined,
  // - 店舗ID -
  kijshopCd: string,
  // - フォルダ -
  folder: FavoriteTemplateItem,
  // - 下位層のインデント -
  layerIndent?: number,
  // - 下位層なしアイテム用インデント(ツリーアイコン幅) -
  noChildIndent?: number,
  // - ツリーアイコン幅設定用コールバック(値の共有用) -
  setTreeIconWidth?: (width: number) => void,
  // - ドラッグされているテンプレートパス -
  dragTemp: string,
  // - お気に入りテンプレート一覧の配列（階層になっていないもの）-
  tempInfoList: FavoriteTemplateListItem[],
  // - ドラッグされているテンプレートフォルダ -
  dragFolder: LayoutTemplateFolderData | null,
  // - apiのレスポンスから得た並列のフォルダ情報配列 -
  getResFolderList: FavoriteFolderListItem[],
  // - フォルダドラッグ -
  onDragFolder: (drag: LayoutTemplateFolderData | null, drop: FavoriteTemplateItem) => void;
  selectFolder: FavoriteTemplateItem | null,
  belongsFolderList: FavoriteTemplateItem[];
  getList: () => void;
  allFolders: FavoriteFolderListItem[];
  dummyMode?: boolean,
  loading: (v: boolean) => void,
};

type FolderConstitution = {
  // フォルダレベル
  level: number;
  childTemplate: AddFavoriteTemplateInfo[];
  childFolder?: FolderConstitution;
}
// - データ表示用コンポネント -
const ToggledFolders = (props: ToggledFoldersProps) => {
  const {
    productNameId,
    kijshopCd,
    folder,
    layerIndent,
    setTreeIconWidth,
    noChildIndent,
    dragTemp,
    selectFolder,
    belongsFolderList,
    allFolders,
    tempInfoList,
    dragFolder,
    getResFolderList,
    onDragFolder,
    getList,
    loading,
    dummyMode = false,
  } = props;
  // const templatesXmlArr = useAppSelector((state) => state.layout.templatesXmlList);
  // const layoutList = useAppSelector((v) => v.layout.layoutList);
  // const initOpenType = useAppSelector((v) => v.layout.openTemplateType);
  const {
    list,
    templatesXmlArr,
    layoutList,
    folderList,
    initOpenType,
    draggingFavoriteTemplate,
    // hoverFavoriteTemplate,
    openFavoriteTemplateList,
  } = useAppSelector((state) => ({
    list: state.favoriteTemplate.folderList,
    templatesXmlArr: state.layout.templatesXmlList,
    layoutList: state.layout.layoutList,
    folderList: state.layout.folderList,
    initOpenType: state.layout.openTemplateType,
    draggingFavoriteTemplate: state.favoriteTemplate.draggingFavoriteTemplate,
    // hoverFavoriteTemplate: state.favoriteTemplate.hoverFavoriteTemplate,
    openFavoriteTemplateList: state.favoriteTemplate.openFavoriteTemplateList,
  }), lodash.isEqual);
  const dispatch = useDispatch();
  const treeIconWidth = noChildIndent;
  // - State -
  // -- 第2階層以下表示の有無 -
  const [isOpenFolders, setIsOpenFolders] = useState(false);
  const [hover, setHover] = useState(false);
  // - Ref -
  // -- ツリー構造用アイコンEle --
  const treeIconEle = useRef<HTMLDivElement>(null);
  // -- フォルダーアイコンEle --
  const folderIconEle = useRef<HTMLDivElement>(null);

  const isSelect = useMemo(() => isEqual(folder, selectFolder), [folder, selectFolder]);

  const [side, setSide] = useState<'top' | 'bottom' | ''>('');
  const handlerHoverSide = useCallback((v) => {
    setSide(v);
  }, []);

  // - Effect -
  // -- ツリー(三角)アイコン幅 --
  useEffect(() => {
    const tiw = treeIconEle.current?.getBoundingClientRect().width || 0;
    if (tiw && tiw !== treeIconWidth) {
      setTreeIconWidth && setTreeIconWidth(tiw);
    }
  }, []);
  // -- ホバー時の画像差し替え --
  useEffect(() => {
    folderIconEle.current?.setAttribute('src', isOpenFolders ? folderOpenOn : folderCloseOn);
  }, [hover]);

  /* フォルダリスト取得 */
  const getFolderList = useCallback(() => new Promise<FavoriteFolderListItem[]>((resolve) => {
    dispatch(apiActions.run(
      new ApiFavoriteFolderGet(
        {
          kijshopCd,
          kind: '1',
        },
      ),
      {
        onSuccess: (v: ResponseBase<FavoriteFolderGetResponse>) => resolve(v.body.data?.folderList ?? []),
        onError: () => resolve([]),
      },
    ));
  }), []);


  // - Callback -
  // -- フォルダ押下 --
  const handlerClickFolder = useCallback((e) => {
    e.stopPropagation();
    e.preventDefault();
    setIsOpenFolders((prev) => !prev);
    dispatch(favoriteTemplateActions.setDraggingFavoriteTemplate(''));
    dispatch(favoriteTemplateActions.setSelectFolder(folder));
    const getList = (): LayoutTemplateFolderData[] => {
      const list: LayoutTemplateFolderData[][] = [];
      folderList.forEach((v) => {
        if (v.children.length) {
          v.children.forEach(v2 => {
            if (v2.children.length) {
              list.push(v2.children);
              // const temp = v2.children.find(v3 => v3);
              // if (temp) {
              //   array.push(temp)
              //   console.log(array);
              // }
            }
          })
        }
      })
      return list.flat();
    }
    const tempList = getList();
    window.setTimeout(async () => {
      if (!folder.children.length) {
        dispatch(layoutActions.setTemplateList([]));
        return;
      }
      if (folder.children[0].type === 'template') {
        dispatch(layoutActions.setOpenTemplateType({
          type: 'favorite',
          favoriteFolderID: folder.id,
        }));
        dispatch(layoutActions.setTemplateList([]));
        const arr: LayoutItemData[] = [];
        for (const v of folder.children) {
          let templateName = [v.path, v.name].join('/');
          const loadedTemp = await TemplateLoaderManager.checkNextPath(templateName, '');
          if (loadedTemp.isChange) {
            templateName = loadedTemp.path;
            let lastIndex = templateName.lastIndexOf("/");
            let extractedString = templateName.substring(lastIndex + 1);
            dispatch(apiActions.run(
              new ApiFavoriteTemplateUpdate({
                kijshopCd,
                folderId: folder.id,
                path: v.path ?? '',
                isFolder: '0',
                name: extractedString,
                indexes: v.indexes,
              }, v.id),
              {
                onSuccess: () => getList(),
              },
            ));
          }
          const parentPath = templateName.split('/').filter((vv) => vv.indexOf('_') === -1).join('/');
          const thumbnail = getTargetPathUrl(templateName + '/thumbnail.jpg');
          const templateXmlUrl = getTargetPathUrl(templateName);
          // folder.name !== 'cover'
          //   ? [v.path, v.name].join('/').indexOf('___') !== -1 ? [v.path, v.name].join('/').substring(0, [v.path, v.name].join('/').indexOf('___')) : [v.path, v.name].join('/')
          //   : [v.path, v.name].join('/');
          dispatch(layoutActions.setThumbnailPath(thumbnail));
          const templateItem: LayoutItemData = {
            id: parentPath,
            templateName,
            thumbnail,
            templateXmlUrl,
            targetPath: templateName,
            favTempId: v.id,
          };
          // dispatch(layoutActions.addTemplateList([templateItem]));
          // const find = templatesXmlArr.find((v) => {
          const find = tempList.find((v) => templateItem.id === v.id);
          if (find) {
            arr.push(templateItem);
          }
        }
        // console.log('表示するテンプレート', arr);
        dispatch(layoutActions.setTemplateList(arr));
      }
      dispatch(favoriteTemplateActions.setSelectFolder(folder));
    }, 0);
  }, [folder, templatesXmlArr, folderList]);
  // -- フォルダ追加メニュー --
  const handlerClickAddFolder = useCallback(async () => {
    dispatch(toolbarActions.close());
    if (folder.children.length && folder.children[0].type === 'template') {
      // テンプレートが入っているフォルダの配下にフォルダは作らない（テンプレートとフォルダの共存不可）
      dispatch(dialogActions.pushMessage({
        title: '確認',
        message: [
          'このフォルダの下にフォルダの追加はできません。',
        ],
        buttons: [
          {
            label: 'はい',
            callback: () => dispatch(dialogActions.pop()),
          },
        ],
      }));
    } else if (folder.level === 3) {
      // 3階層以上にならないようにする
      dispatch(dialogActions.pushMessage({
        title: '確認',
        message: [
          '3階層以上になるフォルダ追加はできません',
        ],
        buttons: [
          {
            label: 'はい',
            callback: () => dispatch(dialogActions.pop()),
          },
        ],
      }));
    } else {
      // 追加ダイアログの初期値（フォルダ名が重複しないように）
      const usedNameList = folder.children.map((v) => v.name);
      const initValue = createInitValue(await getFolderList());
      dispatch(dialogActions.push({
        title: 'フォルダ追加',
        element:
          <AddFolder
            allFolders={allFolders}
            type={'template'}
            kijshopCd={kijshopCd}
            initValue={initValue}
            usedNameList={usedNameList}
            level={folder.level ? (folder.level + 1) : undefined}
            parentId={folder.id ?? undefined}
            indexes={folder.children.length ? Math.max(...[...folder.children].map((v, i) => v.indexes || i)) + 1 : 1}
            getList={() => getList()}
          />,
      }));
    }
  }, [folder, getResFolderList, allFolders]);
  //   -- フォルダ名更新メニュー --
  const handlerClickChangeFolderName = useCallback(() => {
    const usedNameList = belongsFolderList.map((v) => v.name);
    dispatch(toolbarActions.close());
    dispatch(dialogActions.push({
      title: 'フォルダ名変更',
      element:
        <ChangeFolderName
          kijshopCd={kijshopCd}
          targetFolder={folder}
          level={folder.level ?? undefined}
          usedNameList={usedNameList}
          parentId={folder.parentId ?? undefined}
          indexes={folder.indexes ?? undefined}
        />,
    }));
  }, [folder, getResFolderList, belongsFolderList]);
  // -- フォルダ削除メニュー --
  const handlerClickDeleteFolder = useCallback(() => {
    dispatch(toolbarActions.close());
    dispatch(dialogActions.pushMessage({
      title: 'フォルダ削除',
      message: [
        `${folder.name}を削除します。`,
      ],
      buttons: [
        {
          label: 'キャンセル',
          callback: () => {
            dispatch(dialogActions.pop());
          },
        },
        {
          label: 'OK',
          callback: () => {
            if (folder.children.length && folder.children?.[0].type === 'template') {
              dispatch(layoutActions.setTemplateList([]));
            }
            dispatch(dialogActions.pop());
            dispatch(favoriteTemplateActions.deleteFolder(folder));
            dispatch(apiActions.run(
              new ApiFavoriteFolderDelete(
                {
                  id: folder.id,
                  kijshopCd,
                  kind: '1',
                },
                folder.id,
              ),
            ));
          },
        },
      ],
    }));
  }, [folder]);
  // -- 右クリック --
  const handlerRightClick = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    if (folder.id === 'bottom') {
      return;
    }
    e.preventDefault();
    e.stopPropagation();
    dispatch(favoriteTemplateActions.setSelectFolder(folder));
    dispatch(toolbarActions.open({
      position: {
        x: e.clientX,
        y: e.clientY,
      },
      list: [
        {
          label: 'フォルダ追加',
          callback: handlerClickAddFolder,
        },
        {
          label: 'フォルダ名変更',
          callback: handlerClickChangeFolderName,
        },
        {
          label: 'フォルダ削除',
          callback: handlerClickDeleteFolder,
        },
      ],
    }));
  }, [folder, handlerClickAddFolder, handlerClickChangeFolderName, handlerClickDeleteFolder]);
  // -- テンプレートがドロップされたとき --
  const handlerDropTemplate = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setHover(false);
    if (folder.children.length && folder.children[0].type !== 'template') {
      // 対象のフォルダの配下にフォルダが存在する場合は追加しない（テンプレートとフォルダの共存不可）
      dispatch(dialogActions.pushMessage({
        title: '確認',
        message: [
          '親フォルダには追加できません',
        ],
        buttons: [
          {
            label: 'はい',
            callback: () => dispatch(dialogActions.pop()),
          },
        ],
      }));
    } else {
      if (!dragFolder && !dragTemp) return;
      if (productNameId) {
        const folderPath = dragTemp.split('/');
        const tempName = folderPath.pop();
        const path = dragFolder?.id || folderPath.join('/');
        const name = dragFolder?.id || tempName || dragTemp;
        const isFolder = dragFolder ? '1' : '0';
        // TODO: フォルダごともってきた場合の処理（dragFolder）
        const templates = [
          {
            path,
            name,
            isFolder,
            productNameId,
            indexes: folder.children.length + 1,
          },
        ];
        if (dragFolder) return;
        dispatch(apiActions.run(
          new ApiFavoriteTemplateGet(
            {
              kijshopCd,
              folderId: folder.id,
            }),
          {
            onSuccess: (res: ResponseBase<FavoriteTemplateGetResponse>) => {
              if (res.body.data) {
                if (res.body.data.templateList.find((v) => v.name === templates[0].name)) {
                  dispatch(dialogActions.pushMessage({
                    title: '確認',
                    message: ['すでに登録されています'],
                    buttons: [{
                      label: 'OK',
                      callback: () => dispatch(dialogActions.pop()),
                    }],
                  }));
                } else {
                  loading(true);
                  dispatch(apiActions.run(
                    new ApiFavoriteTemplatePost({
                      kijshopCd,
                      folderId: folder.id,
                      templates,
                    }),
                    {
                      onSuccess: () => getList(),
                    },
                  ));
                }
              }
            },
          }));
      }
    }
    console.log('ドロップされたテンプレート', dragTemp, folder);
  }, [productNameId, kijshopCd, dragTemp, folder, tempInfoList, dragFolder, getList]);

  const findEndChild = useCallback((drag: LayoutTemplateFolderData | null, count: number = 1): number => {
    const find = drag?.children.find((v) => v.children.length && v.children[0].type !== 'template');
    if (find) {
      return (findEndChild(find, count + 1));
    } else {
      return count;
    }
  }, []);

  const handleDropFolder = useCallback(() => {
    if (!dragFolder || !folder) return;
    if (folder.children.find((v) => v.name === dragFolder.label)) {
      dispatch(dialogActions.pushMessage({
        title: '確認',
        message: [
          // '同一階層に同じ名称のフォルダがあります。',
          // '違う名称を設定してください。',
          'すでに存在するフォルダと同じ名称での追加はできません。',
        ],
        buttons: [
          {
            label: 'はい',
            callback: () => dispatch(dialogActions.pop()),
          },
        ],
      }));
      return;
    }
    if (findEndChild(dragFolder) + (folder.level ?? 0) < 3) {
      onDragFolder(dragFolder, folder);
    } else {
      dispatch(dialogActions.pushMessage({
        title: '確認',
        message: [
          '3階層以上になるフォルダ追加はできません',
        ],
        buttons: [
          {
            label: 'はい',
            callback: () => dispatch(dialogActions.pop()),
          },
        ],
      }));
    }
  }, [folder, dragFolder, onDragFolder, findEndChild]);

  const handlerDragFavoriteTemplate = useCallback((id) => {
    dispatch(favoriteTemplateActions.setDraggingFavoriteTemplate(id));
    const handlerMouseUpDocument = () => {
      document.removeEventListener('mouseup', handlerMouseUpDocument);
      dispatch(favoriteTemplateActions.setDraggingFavoriteTemplate(''));
    };
    document.addEventListener('mouseup', handlerMouseUpDocument);
  }, []);
  const handlerMouseUp = useCallback((draggingId: string, target: FavoriteTemplateItem, side?: 'top' | 'bottom') => {

    loading(true);
    const index = list.findIndex((v) => v === target);
    dispatch(favoriteTemplateActions.changeFolderIndex(kijshopCd, draggingId, target, index, loading, side, getList));
  }, [openFavoriteTemplateList, list, loading]);

  const handleDrop = useCallback((e: any) => {
    e.preventDefault();
    e.stopPropagation();
    // const find = dragFolder?.children.find((v) => v.children.length && v.children[0].type === 'directory');
    const isFolder = dragFolder?.type === 'directory';
    isFolder ? handleDropFolder() : handlerDropTemplate(e);
  }, [folder, dragFolder, handleDropFolder, handlerDropTemplate, findEndChild]);

  const searchTemplate = useCallback((v: typeof folder) => {
    if (v.children.find((v) => v.type === 'template')) {
      layoutList.forEach((v2) => {
        v.children.forEach((v3) => {
          if (v3.path === v2.id && initOpenType.favoriteFolderID === v.id) {
            setIsOpenFolders(true);
          }
        });
      });
    } else if (v.children.length) {
      v.children.forEach((v2) => {
        searchTemplate(v2);
      });
    }
  }, [layoutList, folder, initOpenType.favoriteFolderID]);

  useEffect(() => {
    if (initOpenType.type !== 'favorite') return;
    searchTemplate(folder);
  }, [folder.children]);

  useEffect(() => {
    if (isOpenFolders) {
      dispatch(favoriteTemplateActions.setOpenFavoriteTemplateList(folder.children));
    } else {
      dispatch(favoriteTemplateActions.setCloseFavoriteTemplateList(folder.children));
    }
  }, [isOpenFolders, folder.children.length]);

  return (
    <SortBoundary
      showSide={(!folder.parentId && draggingFavoriteTemplate) ? (side || (hover && draggingFavoriteTemplate !== folder.id ? 'top' : undefined)) : undefined}
      // showSide={draggingFavoriteTemplate && hover && draggingFavoriteTemplate !== folder.id ? 'top' : undefined}
      color={{ border: '#0000FF', background: '#0000FF' }}
      className={`${dummyMode ? 'dummy' : ''}`}
    >
      <>
        <div
          className="detect_hover_h"
          onMouseOver={() => handlerHoverSide('top')}
          onMouseLeave={() => handlerHoverSide('')}
          onMouseUp={() => handlerMouseUp(draggingFavoriteTemplate, folder, 'top')}
        />
        <div
          className="folders_tree"
          style={{ paddingLeft: layerIndent }}
        >
          <div
            className="folders_tree__item"
            onClick={handlerClickFolder}
            onContextMenu={handlerRightClick}
            onMouseOver={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setHover(true);
              dispatch(favoriteTemplateActions.setHoverFavoriteTemplate(folder.id));
            }}
            onMouseLeave={(e) => {
              e.stopPropagation();
              e.preventDefault();
              setHover(false);
              dispatch(favoriteTemplateActions.setHoverFavoriteTemplate(''));
            }}
            // onDragStart={(e) => {
            //   e.stopPropagation();
            //   e.preventDefault();
            //   handlerDragFavoriteTemplate(folder.id);
            // }}
            onMouseDown={() => {
              if (dummyMode) {
                return;
              }
              handlerDragFavoriteTemplate(folder.id);
            }}
            // onMouseUp={() => {
            //   console.group(folder.name);
            //   console.log(folder);
            //   console.log(draggingFavoriteTemplate);
            //   console.groupEnd();
            // }}
            onDragOver={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setHover(true);
              if (dragFolder || dragTemp) {
                setIsOpenFolders(true);
              }
            }}
            // onDrop={handlerDropTemplate}
            onDrop={handleDrop}
            onDragLeave={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setHover(false);
              dispatch(favoriteTemplateActions.setHoverFavoriteTemplate(''));
            }}
            onMouseUp={() => handlerMouseUp(draggingFavoriteTemplate, folder)}
          >
            <div
              className="folders_tree__item__icon"
              onDrag={(e) => e.preventDefault()}
              style={folder.children.length && folder.children[0].type === 'directory' ? {} : { paddingLeft: noChildIndent }}
            >
              {(folder.children.length && folder.children[0].type === 'directory') ? (
                <SwitchIcon
                  isOn={isOpenFolders}
                  tmplMode="tree"
                  ref={treeIconEle}
                />
              ) : (<></>)}
              {folder.type ? (
                <SwitchIcon
                  isOn={folder.children.length && folder.children[0].type === 'directory' ? isOpenFolders : isSelect}
                  tmplMode="folder"
                  ref={folderIconEle}
                  switchEle={hover ? {
                    on: <img
                      src={folderOpenOn}
                      alt=""
                    />,
                    off: <img
                      src={folderCloseOn}
                      alt=""
                    />,
                  } : undefined}
                />
              ) : (<></>)}
            </div>
            <SortBoundary
              showSide={(folder.parentId && draggingFavoriteTemplate) ? (side || (hover && draggingFavoriteTemplate !== folder.id ? 'top' : undefined)) : undefined}
              color={{ border: '#0000FF', background: '#0000FF' }}
              lineLength="7.5em"
              offset={{ x: '-1.5em', y: side === 'top' ? '-0.1em' : side === 'bottom' ? '0.1em' : undefined }}
              className={`${dummyMode ? 'dummy' : ''}`}
            >
              <span className={`folders_tree__item__label ${folder.children.length ? '' : 'lonely'}${hover ? ' hover' : ''}`}>
                {folder.name}
              </span>
            </SortBoundary>
          </div>
          {isOpenFolders ? (
            folder.children.map((v, i) => v.type === 'directory' ? (
              <ToggledFolders
                key={`layout-favorite-template-directory_${v.id}_${i}`}
                folder={v as FavoriteTemplateItem}
                layerIndent={treeIconWidth}
                noChildIndent={noChildIndent}
                kijshopCd={kijshopCd}
                productNameId={productNameId}
                dragTemp={dragTemp}
                tempInfoList={tempInfoList}
                dragFolder={dragFolder}
                getResFolderList={getResFolderList}
                onDragFolder={onDragFolder}
                selectFolder={selectFolder}
                belongsFolderList={folder.children}
                getList={getList}
                allFolders={allFolders}
                loading={loading}
              />
            ) : (
              <React.Fragment key={`template-folder_${v.id}_${i}`} />
            ))
          ) : (<></>)}
        </div>
        <div
          className="detect_hover_h"
          onMouseOver={() => {
            handlerHoverSide('bottom');
          }}
          onMouseLeave={() => handlerHoverSide('')}
          onMouseUp={() => handlerMouseUp(draggingFavoriteTemplate, folder, 'bottom')}
        />
      </>
    </SortBoundary>
  );
};

type Props = {
  onLoad: () => void;
}

export const LayoutFavoriteTemplate = (props: Props) => {
  const { onLoad } = props;
  // - Hooks -
  const {
    list,
    layoutQuery,
    draggingTemplate,
    tempInfoList,
    delTempId,
    draggingFolder,
    getResponseFolderList,
    selectFolder,
    layoutList,
    draggingFavoriteTemplate,
    updateFavoriteTemplate,
  } = useAppSelector((state) => ({
    list: state.favoriteTemplate.folderList,
    layoutQuery: state.layout.queryParams,
    draggingTemplate: state.favoriteTemplate.draggingTemplate,
    tempInfoList: state.favoriteTemplate.templatesInfoList,
    delTempId: state.favoriteTemplate.deleteTemplateId,
    draggingFolder: state.favoriteTemplate.draggingTempFolder,
    getResponseFolderList: state.favoriteTemplate.getResponseFolderList,
    selectFolder: state.favoriteTemplate.selectFolder,
    layoutList: state.layout.layoutList,
    draggingFavoriteTemplate: state.favoriteTemplate.draggingFavoriteTemplate,
    updateFavoriteTemplate: state.favoriteTemplate.updateFavoriteTemplate,
  }), lodash.isEqual);
  const { kijshopCd, shopOrderId, orderId } = useParams<PathParams>();
  const dispatch = useDispatch();

  // - State -
  const [treeIconWidth, setTreeIconWidth] = useState(0);
  const [templateList, setTemplateList] = useState<FavoriteTemplateListItem[]>([]);
  const [isInit, setIsInit] = useState(false);
  const [allFolders, setAllFolders] = useState<FavoriteFolderListItem[]>([]);
  const [loading, setLoading] = useState(false);
  // const [list, setList] = useState(_list);
  useEffect(() => {
    const sortLoop = (data: FavoriteTemplateItem[]) => {
      const arr = [...data];
      if (data.length) {
        data.forEach((v) => sortLoop(v.children));
        arr.sort((a, b) => (a.indexes || 0) - (b.indexes || 0));
        // arr.forEach((v, i) => {
        //   v.indexes = i + 2;
        // });
      }
      return arr;
    };
    // arr.sort((a, b) => (a.indexes || 0) - (b.indexes || 0));
    // sortLoop(sortLoop(_list));
  }, [list]);
  // - Callback -
  /* フォルダリスト取得 */
  const getFolderList = useCallback(() => new Promise<FavoriteFolderListItem[]>((resolve) => {
    dispatch(apiActions.run(
      new ApiFavoriteFolderGet(
        {
          kijshopCd,
          kind: '1',
        },
      ),
      {
        onSuccess: (v: ResponseBase<FavoriteFolderGetResponse>) => resolve(v.body.data?.folderList ?? []),
        onError: () => resolve([]),
      },
    ));
  }), []);

  // -- 右クリック --
  const handlerRightClick = useCallback(async (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    dispatch(favoriteTemplateActions.setSelectFolder(null));
    const initValue = createInitValue(await getFolderList());
    const usedNameList: string[] = [];
    for (const item of getResponseFolderList) {
      usedNameList.push(item.name);
    }
    dispatch(toolbarActions.open({
      position: {
        x: e.clientX,
        y: e.clientY,
      },
      list: [
        {
          label: 'フォルダ追加',
          callback: () => {
            dispatch(toolbarActions.close());
            dispatch(dialogActions.push({
              title: 'フォルダ追加',
              element:
                <AddFolder
                  type={'template'}
                  kijshopCd={kijshopCd}
                  initValue={initValue}
                  usedNameList={usedNameList}
                  level={1}
                  indexes={list.length ? list.length : 0}
                  // indexes={list.length ? Math.max(...[...list].map((v, i) => v.indexes || i)) + 1 : 1}
                  allFolders={allFolders}
                  getList={() => getList()}
                />,
            }));
          },
        },
      ],
    }));
  }, [list, getResponseFolderList, allFolders]);
  // -- テンプレート登録 --
  const postTemplate = useCallback(async (param: {
    templates: AddFavoriteTemplateInfo[],
    folderId?: string;
  }) => new Promise<void>((resolve) => {
    const { templates, folderId } = param;
    if (folderId) {
      dispatch(apiActions.run(
        new ApiFavoriteTemplatePost(
          {
            kijshopCd,
            folderId,
            templates,
          },
        ),
        {
          onSuccess: () => resolve(),
          onError: () => postTemplate({
            templates,
            folderId,
          }).then(() => resolve()),
        },
      ));
    }

  }), []);
  // -- フォルダ登録 --
  const createFolder = useCallback((
    param: {
      level: number,
      parent: LayoutTemplateFolderData,
      indexes: number,
      folderId: string,
    },
  ) => new Promise<{ folderId: string }>((resolve) => {
      const { level, parent, folderId, indexes } = param;
      if (!draggingFolder) return;
      dispatch(apiActions.run(
        new ApiFavoriteFolderPost(
          {
            kijshopCd,
            name: parent.label,
            kind: '1',
            level: level,
            indexes,
            parentId: folderId,
          },
        ),
        {
          onSuccess: (_res: ResponseBase<FavoriteFolderPostResponse>) => {
            resolve({ folderId: cloneDeep(_res.body.data?.folderId ?? '') });
          },
          onError: () => createFolder({
            level,
            parent,
            folderId,
            indexes,
          }).then((_res) => resolve({ folderId: _res.folderId ?? '' })),
        },
      ));
    },
  ), [draggingFolder, getResponseFolderList]);

  const sortingDirectory = useCallback((param: {
    parent: LayoutTemplateFolderData;
    productNameId: string;
    count?: number;
    folderId?: string;
  }): Promise<void> => {
    const { productNameId, count = 1, parent, folderId } = param;
    return new Promise((resolve) => {
      const isDisTemplate = parent.children.find((v) => v.type !== 'template');
      if (isDisTemplate) {
        createFolder({
          level: count,
          parent: parent,
          indexes: parent.children.length + 1,
          folderId: folderId ?? '',
        }).then(async (response) => {
          for (const child of parent.children) {
            await sortingDirectory({
              parent: child,
              productNameId,
              count: count + 1,
              folderId: response.folderId,
            });
          }
          resolve();
        });
      } else {
        createFolder({
          level: count,
          parent: parent,
          indexes: parent.children.length + 1,
          folderId: folderId ?? '',
        }).then((v2) => {
          const templates: AddFavoriteTemplateInfo[] = [];
          parent.children.forEach((v, i) => {
            templates.push({
              path: v.id.split('/').filter((str) => str.indexOf('_') === -1).join('/'),
              name: v.label,
              isFolder: '0',
              productNameId: v.pageType ?? '',
              indexes: i + 1,
            });
          });
          postTemplate({
            templates,
            folderId: v2.folderId,
          }).then(() => resolve());
        });
      }
    });
  }, [createFolder, postTemplate]);

  // -- テンプレート一覧からドロップしたとき --
  const handlerDropTemplateFolder = useCallback((e) => {
    e.preventDefault();
    if (list.find((v) => v.name === draggingFolder?.label)) {
      dispatch(dialogActions.pushMessage({
        title: '確認',
        message: [
          // '同一階層に同じ名称のフォルダがあります。',
          // '違う名称を設定してください。',
          'すでに存在するフォルダと同じ名称での追加はできません。',
        ],
        buttons: [
          {
            label: 'はい',
            callback: () => dispatch(dialogActions.pop()),
          },
        ],
      }));
      return;
    }
    dispatch(favoriteTemplateActions.setSelectFolder(null));
    if (draggingFolder && layoutQuery) {
      sortingDirectory({
        productNameId: layoutQuery.productNameId,
        parent: draggingFolder,
      }).then(() => {
        getList();
      });
    }
  }, [draggingFolder, layoutQuery, kijshopCd, sortingDirectory]);

  // - Effect -
  // -- select-templateでテンプレート削除を選択したとき --
  useEffect(() => {
    if (delTempId.length && layoutQuery) {
      const find = templateList.find((v) => v.templateId === delTempId);
      if (!find) return;
      dispatch(apiActions.run(
        new ApiFavoriteTemplateDelete(
          {
            id: delTempId,
            kijshopCd,
            folderId: find.folderId,
          },
          delTempId,
        ),
        {
          onSuccess: () => {
            // dispatch(layoutActions.setTemplateList(layoutList.filter((v) => v.id !== delTempId)));
            console.log(layoutList);
            dispatch(layoutActions.setTemplateList(cloneDeep(layoutList.filter((v) => v.favTempId !== delTempId))));
            dispatch(favoriteTemplateActions.setDeleteTemplateId(''));
            getList();
          },
        },
      ));
      dispatch(favoriteTemplateActions.setDeleteTemplateId(''));
    }
  }, [delTempId, layoutQuery]);
  // -- 一覧取得 --
  const createTopFolder = useCallback((
    arr: string[],
    index: number,
    children: FavoriteTemplateItem[],
  ) => {
    let child: FavoriteTemplateItem[] = [];
    if (index === arr.length - 1) {
      child = children;
    } else if (index < arr.length) {
      child = [createTopFolder(arr, index + 1, children)];
    }
    const data: FavoriteTemplateItem = {
      id: arr[index],
      name: arr[index],
      type: 'directory',
      children: child,
    };
    return data;
  }, []);

  const getList = useCallback(() => {
    setLoading(true);
    dispatch(apiActions.run(
      new ApiFavoriteFolderGet(
        {
          kijshopCd,
          kind: '1',
        },
      ),
      {
        onSuccess: async (res: ResponseBase<FavoriteFolderGetResponse>) => {
          if (res.body.data) {
            const data = res.body.data;
            const level1 = data.folderList.filter((v) => v.level === 1).sort((a, b) => {
              if (a.indexes! > b.indexes!) {
                return 1;
              } else {
                return -1;
              }
            });
            const level2 = data.folderList.filter((v) => v.level === 2).sort((a, b) => {
              if (a.indexes! > b.indexes!) {
                return 1;
              } else {
                return -1;
              }
            });
            const level3 = data.folderList.filter((v) => v.level === 3).sort((a, b) => {
              if (a.indexes! > b.indexes!) {
                return 1;
              } else {
                return -1;
              }
            });
            let templateList: FavoriteTemplateListItem[] = [];
            const getTemplate = data.folderList.map((v) => new Promise<void>((resolve) => {
              dispatch(apiActions.run(
                new ApiFavoriteTemplateGet(
                  {
                    kijshopCd,
                    folderId: v.folderId,
                  }),
                {
                  onSuccess: (res: ResponseBase<FavoriteTemplateGetResponse>) => {
                    if (res.body.data) {
                      res.body.data.templateList.forEach((data) => {
                        templateList.push(data);
                      });
                      resolve();
                    } else {
                      resolve();
                    }
                  },
                },
              ));
            }));
            Promise.all(getTemplate).then(() => {
              const a: FavoriteTemplateItem[] = level3.map((v) => ({
                id: v.folderId,
                name: v.name,
                type: 'directory',
                level: 3,
                indexes: v.indexes!,
                parentId: v.parentId,
                children: [
                  ...templateList.filter((vv) => vv.folderId === v.folderId).map((vv) => ({
                    id: vv.templateId,
                    name: vv.name,
                    type: 'template',
                    parentId: v.folderId,
                    path: vv.path,
                    indexes: vv.indexes,
                    children: [],
                  })),
                ],
              }));
              const b: FavoriteTemplateItem[] = level2.map((v) => ({
                id: v.folderId,
                name: v.name,
                type: 'directory',
                level: 2,
                indexes: v.indexes!,
                parentId: v.parentId,
                children: [
                  ...a.filter((vv) => vv.parentId === v.folderId),
                  ...templateList.filter((vv) => vv.folderId === v.folderId).map((vv) => ({
                    id: vv.templateId,
                    name: vv.name,
                    type: 'template',
                    parentId: v.folderId,
                    path: vv.path,
                    indexes: vv.indexes,
                    children: [],
                  })),
                ] || [],
              }));
              const c: FavoriteTemplateItem[] = level1.map((v) => ({
                id: v.folderId,
                name: v.name,
                type: 'directory',
                level: 1,
                indexes: v.indexes!,
                children: [
                  ...b.filter((vv) => vv.parentId === v.folderId),
                  ...templateList.filter((vv) => vv.folderId === v.folderId).map((vv) => ({
                    id: vv.templateId,
                    name: vv.name,
                    type: 'template',
                    parentId: v.folderId,
                    path: vv.path,
                    indexes: vv.indexes,
                    children: [],
                  })),
                ],
              }));
              onLoad();
              dispatch(favoriteTemplateActions.setFolderList(c));
              setLoading(false);
            });
          }
        },
        onError: () => {
          setLoading(false);
        },
      },
    ));
  }, [createTopFolder, kijshopCd, onLoad, list]);

  const onDragFolder = useCallback(async (drag: LayoutTemplateFolderData | null, drop: FavoriteTemplateItem) => {
    // if (!drag || !layoutQuery?.productNameId) return;
    // if (drop.children.find((v) => v.name === drag.label)) {
    //   dispatch(dialogActions.pushMessage({
    //     title: '確認',
    //     message: [
    //       // '同一階層に同じ名称のフォルダがあります。',
    //       // '違う名称を設定してください。',
    //       'すでに存在するフォルダと同じ名称での追加はできません。'
    //     ],
    //     buttons: [
    //       {
    //         label: 'はい',
    //         callback: () => dispatch(dialogActions.pop()),
    //       },
    //     ],
    //   }));
    //   return;
    // }
    // await sortingDirectory({
    //   parent: drag,
    //   productNameId: layoutQuery.productNameId,
    //   folderId: drop.id,
    //   count: (drop.level ?? 0) + 1,
    // })
    // getList();
  }, [layoutQuery, sortingDirectory]);

  useEffect(() => {
    if ((layoutQuery && !isInit) || updateFavoriteTemplate) {
      if (updateFavoriteTemplate) {
        dispatch(favoriteTemplateActions.updateFavoriteTemplate(false));
      }
      setIsInit(true);
      getList();
    }
  }, [layoutQuery, updateFavoriteTemplate]);

//   useEffect(() => {
//     dispatch(apiActions.run(
//       new ApiFavoriteFolderDelete(
//         {
//           id: '2a41c61f-e65a-4554-b5ba-8d4b936ea47c',
//           kijshopCd,
//           kind: '1',
//         },
//         '2a41c61f-e65a-4554-b5ba-8d4b936ea47c',
//       )
//     ));
// }, [])

  useEffect(() => {
    dispatch(favoriteTemplateActions.setOpenFavoriteTemplateList(list));
  }, [list]);

  return (
    <div className="layout_category">
      <div className="layout_category__header">お気に入りテンプレート</div>
      <div
        className="layout_category__body"
        onContextMenu={handlerRightClick}
        onDragOver={(e) => e.preventDefault()}
        // onDrop={handlerDropTemplateFolder}
      >
        {list.map((v, i) => v.type === 'directory' ? (
            <ToggledFolders
              key={`layout-favorite-template-folder_${i}`}
              folder={v}
              getList={() => getList()}
              setTreeIconWidth={setTreeIconWidth}
              selectFolder={selectFolder}
              noChildIndent={treeIconWidth}
              kijshopCd={kijshopCd}
              productNameId={layoutQuery?.productNameId}
              dragTemp={draggingTemplate}
              tempInfoList={tempInfoList}
              dragFolder={draggingFolder}
              getResFolderList={getResponseFolderList}
              onDragFolder={onDragFolder}
              belongsFolderList={list}
              allFolders={allFolders}
              loading={(v: boolean) => setLoading(v)}
            />
          ) : (
            <React.Fragment key={`template-folder_${v.id}_${i}`} />
          ),
        )}
        {/*<ToggledFolders*/}
        {/*  key={`layout-favorite-template-folder_bottom-sec`}*/}
        {/*  folder={{*/}
        {/*    id: 'bottom-sec',*/}
        {/*    name: '',*/}
        {/*    type: '',*/}
        {/*    children: [],*/}
        {/*  }}*/}
        {/*  getList={() => getList()}*/}
        {/*  setTreeIconWidth={setTreeIconWidth}*/}
        {/*  selectFolder={selectFolder}*/}
        {/*  noChildIndent={treeIconWidth}*/}
        {/*  kijshopCd={kijshopCd}*/}
        {/*  productNameId={layoutQuery?.productNameId}*/}
        {/*  dragTemp={draggingTemplate}*/}
        {/*  tempInfoList={tempInfoList}*/}
        {/*  dragFolder={draggingFolder}*/}
        {/*  getResFolderList={getResponseFolderList}*/}
        {/*  onDragFolder={onDragFolder}*/}
        {/*  belongsFolderList={list}*/}
        {/*  allFolders={allFolders}*/}
        {/*  dummyMode*/}
        {/*/>*/}
        <ToggledFolders
          key={`layout-favorite-template-folder_bottom`}
          folder={{
            id: 'bottom',
            name: '',
            type: '',
            children: [],
          }}
          getList={() => getList()}
          setTreeIconWidth={setTreeIconWidth}
          selectFolder={selectFolder}
          noChildIndent={treeIconWidth}
          kijshopCd={kijshopCd}
          productNameId={layoutQuery?.productNameId}
          dragTemp={draggingTemplate}
          tempInfoList={tempInfoList}
          dragFolder={draggingFolder}
          getResFolderList={getResponseFolderList}
          onDragFolder={onDragFolder}
          belongsFolderList={list}
          allFolders={allFolders}
          dummyMode
          loading={setLoading}
        />
      </div>
      {loading ? <LoadingPopup label="更新中です" /> : <></>}
    </div>
  );
};
