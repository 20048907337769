import * as lodash from 'lodash';
import { useAppSelector } from '../../../app/hooks';
import './loading-bar.scss';

type LoadingBarProps = {
  message?: string,
  progressNum?: number,
  className?: string,
  dimmerTransparent?: boolean,
}

const loadingAnimationData = {
  rectSize: {
    width: 270,
    height: 20,
  },
  splitNum: 15,
  moveTimeSec: 3,
};

const arr = new Array(loadingAnimationData.splitNum * 2);

export const LoadingBar = (props: LoadingBarProps) => {
  const { message, progressNum = 0, className = '', dimmerTransparent = false } = props;
  const { rectSize, splitNum, moveTimeSec } = loadingAnimationData;
  // - Hooks -
  const { display } = useAppSelector((state) => ({
    display: state.loading.display,
  }), lodash.isEqual);

  return display ? (
    <div className="loading_bar__dimmer" style={{ backgroundColor: dimmerTransparent ? '#00000030' : '#c8c8c8' }}>
      <div className="loading_bar__wrap">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          x="0"
          y="0"
          width={rectSize.width}
          height={rectSize.height}
          viewBox={`0 0 ${rectSize.width} ${rectSize.height}`}
          className={`loading_bar ${className}`}
        >
          <g>
            <rect
              x="0"
              y="0"
              width={rectSize.width}
              height={rectSize.height}
              stroke="#5B5B5B"
              strokeWidth="1"
              fill="#fff"
            />
            <g
              transform="translate(0 0) rotate(30)"
            >
              {[...arr].map((_, i) => (
                <rect
                  key={`rect_${i}`}
                  x={(i - splitNum) * (rectSize.width / splitNum)}
                  y={-rectSize.width}
                  width={rectSize.width / (splitNum * 2)}
                  height={rectSize.width * 2}
                  stroke="#000000"
                  strokeOpacity="0"
                  strokeWidth="1"
                  fill="#5B5B5B"
                >
                  <animate
                    attributeName="x"
                    begin={0}
                    dur={`${moveTimeSec}s`}
                    from={(i - splitNum) * (rectSize.width / splitNum)}
                    to={rectSize.width + ((i - splitNum) * (rectSize.width / splitNum))}
                    repeatCount="indefinite"
                  />
                </rect>
              ))}
            </g>
          </g>
        </svg>

        {message || progressNum ?
          (<div className="loading_bar__message">
            {`${message ? `${message}...` : ''}${progressNum ? `${progressNum}%` : ''}`}
          </div>)
          : (<></>)}
      </div>
    </div >
  ) : <></>;
};
