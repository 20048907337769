import './switch.scss';

type SwitchProps = {
  labels: string[],
  onClickButton: (value: string) => void,
  selectedValue: string,
};

/**
 * スイッチ
 * @param labels 各ボタンのラベル
 * @param onClickButton ボタン押下時処理 
 * @param selectedValue 選択中アイテムの値
 */
export const Switch = (props: SwitchProps) => {
  const { labels, onClickButton, selectedValue } = props;
  return (
    <div className="switch">
      {labels.map((v, i) => {
        return (
          <button
            key={`switch-button_${i}`}
            onClick={() => onClickButton(v)}
            className={`switch__button${i === 0 ? ' first_btn' : i === labels.length - 1 ? ' last_btn' : ''}${selectedValue === v ? ' selected' : ''}`} >
            {v}
          </button>
        );
      })}
    </div>
  );
};