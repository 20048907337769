import { OrdersSliceState } from '../../slices/orders-slice';
import { OrdersDataState } from '../../slices/orders/orders-data-slice';
import { CreateOrderItemData } from './create-order-item-data';
import { isOptionalLeafColor } from '../../components/pages/create-order/cover-order';
import { VisibleConditionValueGetter } from '../../manager/visible-condition-value-getter';
import { checkEditable, checkOptional } from './item-detail-item-data';

const categorize = (v: string) => `商品情報-商品詳細-表紙-${v}`;
export const coverItemData = (order: OrdersSliceState, data: OrdersDataState, visibleCondition: VisibleConditionValueGetter): CreateOrderItemData[] => {
  // const display = coverOrderDisplayItem(order, data);
  const display = visibleCondition.coverOrderDisplay;
  const {
    laminate: _laminate,
    color: _color,
    coverProcessing: _coverProcessing,
    leafText1,
    leafText2,
  } = order.cover;
  const laminate = _laminate.value;
  const color = _color.value;
  const coverProcessing = _coverProcessing.value;
  return ([
    {
      // ラミ選択
      // ラミ選択情報があり（写真アルバムプロフラグが有効もしくは特定の商品IDを持つもしくは特定のカテゴリを選択している）場合に表示
      //  L 商品IDが設定されたら取得
      // 表紙色情報が選択されていて自動ラミ選択情報がある場合にも表示（内部的には分岐）
      //  L 表示色情報が選択された場合に取得？（SQLの条件句にop01_coverColor_pre_select）
      name: 'laminate',
      value: laminate,
      editable: checkEditable(display.laminate),
      optional: checkOptional(display.laminate),
      display: Boolean(display.laminate),
      categorize: categorize('ラミ選択'),
    },
    {
      // 色
      // 色情報が存在して商品選択が特定のもので無く（非フロー変更版注文有無が有効もしくは非写真アルバムプロフラグが有効な場合）に表示 TODO
      //  L 商品IDが設定されたタイミングで色情報取得？（SQLの条件句にop01_goods_id）
      //  L 表紙選択が選択されていて商品選択が選択された場合に非フロー変更版注文有無が有効
      // 商品選択が特定のものと一致する場合にも表示（内部的には分岐）
      name: 'color',
      value: color,
      editable: checkEditable(display.color),
      optional: checkOptional(display.color),
      display: Boolean(display.color),
      categorize: categorize('色'),
    },
    {
      // 表紙加工
      // 後加工情報が有効かつカバーIDが特定の値の場合に表示
      //  L 商品IDが選択された場合に後加工情報を取得
      //  L 商品選択が選択された場合にカバーIDを取得
      // 自動表紙加工情報がある場合に内部的に分岐
      //  L 表紙色情報が設定された時に取得？（SQLの条件句にop01_coverColor_pre_select）
      name: 'leafColor',
      value: coverProcessing,
      editable: checkEditable(display.leafColor),
      optional: checkOptional(display.leafColor),
      // optional: isOptionalLeafColor(order.itemDetail.coverSelect.value),
      display: Boolean(display.leafColor),
      categorize: categorize('表紙加工'),
    },
    {
      // 箔押し文字1
      // 表紙加工が特定の値の場合に表示。表紙加工記載の最初の条件のみ適用
      name: 'leafText1',
      value: leafText1,
      editable: checkEditable(display.leafText1),
      optional: checkOptional(display.leafText1),
      display: Boolean(display.leafText1),
      categorize: categorize('箔押し文字1'),
      validation: (v) => v.length <= 24,
    },
    {
      // 箔押し文字2
      // 表紙加工が特定の値の場合に表示。表紙加工記載の最初の条件のみ適用
      name: 'leafText2',
      value: leafText2,
      editable: checkEditable(display.leafText2),
      optional: checkOptional(display.leafText2),
      display: Boolean(display.leafText2),
      categorize: categorize('箔押し文字2'),
      validation: (v) => v.length <= 24,
    },
  ]);
}
