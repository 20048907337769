import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { DisplayElement } from "../../../models/display-element";
import { Button } from "../../ui/button/button";
import { Checkbox } from "../../ui/input/checkbox";
import { Table } from "../../ui/table/table";
import iconDelete from '../../../assets/img/icon/delete.svg';
import { useDispatch } from "react-redux";
import { dialogActions } from "../../dialog/slice/dialog-slice";
import { OrderDeleteConfirm } from "../../dialog/unique/order-delete-confirm";
import { Header } from "../../ui/header/header";
import { routerActions } from 'connected-react-router';
import './upload-manager.scss';
import { useAppSelector } from '../../../app/hooks';
import * as lodash from 'lodash';
import { PathParams, RoutingPath } from '../../../routes/routing-path';
import { push } from 'connected-react-router';
import { cloneDeep } from "lodash";
import { orderPreparationActions } from '../../../slices/order-preparation-slice';
import { useParams } from 'react-router-dom';
import { LabOrder } from '../../dialog/unique/lab-order';
import { DomContentTooltip } from '../../ui/tooltip/dom-content-tooltip';
import { webPagePath } from '../../../models/web-page-path';

const tableHeader = ['選択', '作成日時', 'お客様名', '注文番号', '状態'];

const getTitle = (arr: string[]) => {
  let str = '';
  arr.forEach((v) => {
    if (str) {
      str += `\n`;
    }
    str += v;
  });
  return str;
};

export const convertErrorStatus = (v: string) => {
  switch (v) {
    case 'xml':
      return 'エラー';
    case 'irregular':
      return '通信エラー';
    case '406':
      return 'エラー(発注済み)';
    default:
      return 'エラー';
  }
};

export const UploadManager = () => {
  const dispatch = useDispatch();
  const { orderList } = useAppSelector((state) => ({
    orderList: state.orderPreparation.errorOrderList,
    kijshopCd: state.orderPreparation.kijshopCd,
  }), lodash.isEqual);
  const { kijshopCd, shopOrderId, orderId } = useParams<PathParams>();
  // - State -
  // -- チェックリスト --
  const [checkList, setCheckList] = useState<string[]>([]);
  // - Callback -
  // -- チェックボックス --
  const handlerClickCheckbox = useCallback((shopOrderId: string) => {
    const arr = cloneDeep(checkList);
    const index = arr.findIndex((v) => v === shopOrderId);
    if (index !== -1) {
      arr.splice(index, 1);
    } else {
      arr.push(shopOrderId);
    }
    setCheckList(arr);
  }, [checkList]);

  // -- すべて選択ボタン --
  const handlerClickAllSelect = useCallback(() => {
    setCheckList(orderList.map((v) => v.shopOrderId));
  }, [orderList]);
  // -- すべて選択解除ボタン --
  const handlerClickAllDeselect = useCallback(() => {
    setCheckList([]);
  }, []);
  // -- 削除ボタン --
  const handlerClickDelete = useCallback(() => {
    const target = orderList.filter((v) => checkList.find((id) => id === v.shopOrderId));
    dispatch(dialogActions.push({
      title: '確認',
      element: <OrderDeleteConfirm
        targetOrder={target}
        isUploadManager={false}
        kijshopCd={kijshopCd}
        callbackDelete={() => {
          const arr = orderList.filter((v) => !checkList.find((id) => id === v.shopOrderId));
          dispatch(orderPreparationActions.setErrorOrderList(arr));
          setCheckList([]);
        }}
      />,
    }));
  }, [orderList, checkList]);
  // -- 終了ボタン --
  const handlerClickEnd = useCallback(() => {
    dispatch(dialogActions.pushMessage({
      title: '確認',
      message: [
        // '送信途中の発注は中断されます。',
        '終了してもよろしいですか？'
      ],
      buttons: [
        {
          label: 'いいえ',
          callback: () => dispatch(dialogActions.pop()),
        },
        {
          label: 'はい',
          callback: () => {
            dispatch(dialogActions.pop());
            dispatch(routerActions.goBack());
          },
        },
      ],
    }));
  }, []);
  // -- リトライボタン --
  const handlerClickRetry = useCallback(() => {
    dispatch(dialogActions.push({
      title: 'ラボ発注',
      element: <LabOrder
        kijshopCd={kijshopCd}
        // orderList={(orderList.map((v) => checkIdArr.find((id) => id === v.shopOrderId)))}
        orderList={[...checkList].map((id) => {
          const data = orderList.find((v) => v.shopOrderId === id);
          return {
            createDate: data?.createDate || '',
            lastName: data?.lastName || '',
            shopOrderId: data?.shopOrderId || id,
            // status: convertErrorStatus(data?.errorStatus.code || ''),
            status: data?.status || '',
            cnt: data?.cnt || 1,
          }
        })}
        callbackEndOrder={() => {
          dispatch(push(RoutingPath.orders));
        }}
        retry={true}
      />,
    }));
  }, [checkList, orderList]);

  // -- チェックボックスの値を更新 --
  const checkboxBody = useMemo(() => {
    return orderList.map((v) => ([
      <Checkbox
        checked={Boolean(checkList.find((id) => id === v.shopOrderId))}
        onClick={() => handlerClickCheckbox(v.shopOrderId)}
      />,
      v.createDate || '',
      v.lastName || '',
      v.shopOrderId,
      // <div title={v.errorStatus.message || undefined} >{convertErrorStatus(v.errorStatus.code)}</div>,
      // <div title={v.errorStatus.message?.length ? getTitle(v.errorStatus.message) : undefined} >{convertErrorStatus(v.errorStatus.code)}</div>,
      <ToolTip label={convertErrorStatus(v.errorStatus.code)} code={v.errorStatus.code} message={v.errorStatus.message} />,
    ]));
  }, [checkList, handlerClickCheckbox, orderList]);

  useEffect(() => {
    dispatch(dialogActions.pushMessage({
      title: '確認',
      message: ['発注に失敗したオーダーがあります'],
      buttons: [{
        label: 'OK',
        callback: () => {
          dispatch(dialogActions.pop());
        },
      }],
    }));
  }, [orderList]);

  // -- エラー内容保持（念の為） --
  // useEffect(() => {
  // }, []);

  return (
    <div className="upload_manager page_base">
      <Header />
      <div className="upload_manager__body">
        <div className="upload_manager__ttl">発注中止</div>
        <div className="upload_manager__contents">
          <div className="upload_manager__table__menu">
            <div className="upload_manager__table__menu__batch">
              <Button
                label="すべて選択"
                color="light"
                onClick={handlerClickAllSelect}
              />
              <Button
                label="すべて選択解除"
                color="light"
                onClick={handlerClickAllDeselect}
              />
            </div>
            <Button
              label="削除"
              color="light"
              icon={<img src={iconDelete} alt="" />}
              onClick={handlerClickDelete}
              disabled={!checkList.length}
              className="upload_manager__table__menu__del"
            />
          </div>
          <div className="upload_manager__table">
            <Table
              head={tableHeader}
              body={checkboxBody}
              minimumNumOfRows={11}
            />
          </div>
          <div className="upload_manager__actions">
            <Button
              label="終了"
              onClick={handlerClickEnd}
            />
            <Button
              label="リトライ"
              onClick={handlerClickRetry}
              disabled={!checkList.length}
            />
          </div>
        </div>
      </div>
    </div>
  )
};

type ToolTipProps = {
  label: string,
  code: string,
  message: string[],
};

const ToolTip = (props: ToolTipProps) => {
  const { label, code, message } = props;
  // - Ref -
  const dataRef = useRef<HTMLDivElement>(null);
  const linkRef = useRef<HTMLAnchorElement>(null);
  // - State -
  // -- オプションホバー時のツールチップ有無 --
  const [show, setShow] = useState(false);
  // - Memo -
  const relativeEle = useMemo(() => {
    return dataRef.current;
  }, [show]);
  useEffect(() => {
    if (linkRef.current) {
      linkRef.current.target = "_blank";
      linkRef.current.href = webPagePath.conflictShopOrderId;
      linkRef.current.rel = "noopener noreferrer";
    }
  }, [show]);
  return (
    <div
      ref={dataRef}
      onMouseLeave={() => setShow(false)}
    >
      <div
        onMouseEnter={() => setShow(true)}
        className="hoverable_ele"
      >
        {label}
      </div>
      {show ? (
        <DomContentTooltip
          onClick={(e) => {
            e.stopPropagation();
          }}
          content={code === '406' ? (
            <div style={{ marginTop: '1rem' }}>
              <div>
                注文番号の重複が発生したため、発注を完了できません。<br />
                以下の手順を参照してエラーの回避をお願いします。<br /><br />
                <a ref={linkRef} >
                  →注文番号の重複エラーが発生する場合
                </a>
              </div>
              <div
                style={{
                  marginTop: '1rem',
                  textAlign: 'center',
                }}
              >
              </div>
            </div>
          ) : (
            <>{message.map((v, i) => <div key={`tooltip-message_${i}`} >{v}</div>)}</>
          )}
          relativeEle={relativeEle}
          autoPos={{ h: "center", v: "bottom" }}
        />
      ) : (<></>)}
    </div>
  )
}

