import React, { useEffect, useRef, useState } from 'react';
import { UiManager } from '../../../../layout-editor/manager/ui/ui.manager';
import './layout.color-dropper-dialog.scss';
import { ColorCodeConverter } from '../../../../utilities/colorcode-converter';
import { useAppDispatch } from '../../../../app/hooks';
import { dialogActions } from '../../../dialog/slice/dialog-slice';

type Props = {
  onChange: (e: { color: string }) => void,
};

export const ColorDropper = (props: Props) => {
  const dispatch = useAppDispatch();
  // - props -
  const { onChange } = props;
  // - state -
  const [textColor, setTextColor] = useState('');
  // - ref -
  const ref = useRef<HTMLDivElement>(null);
  // - effect -
  useEffect(() => {
    if (ref && ref.current) {
      UiManager.ins.emit('r->l:color-dropper:start', {
        container: ref.current,
        onChange: (e) => {
          onChange(e);
          setTextColor(ColorCodeConverter.rgbaToColorCode(e.color).colorCode);
        },
      });
    }
  }, [ref]);
  return (
    <div className="color_dropper">
      <div className="color_dropper__header">
        <div className="color_dropper__color">
          <div className="color_dropper__color__chip" style={{ backgroundColor: textColor }} />
          <div className="color_dropper__color__code">{textColor}</div>
        </div>
      </div>
      <div
        className="color_dropper__canvas"
        ref={ref}
        onClick={() => {
          dispatch(dialogActions.pop());
        }}
      />
    </div>
  );
};
