import { ApiBaseFront } from '../api-base-front';

type OrderLockRequest = {
  kijshopCd: string,
  shopOrderId: string,
}
export type OrderLockCheckResponse = {
  name: string,
};

export class ApiOrderLockCheck extends ApiBaseFront<OrderLockCheckResponse[]> {
  constructor(param: OrderLockRequest) {
    super(
      'GET',
      '/api/v1/lock',
      'JSON',
      param,
    );
  }
}

type PostOrderLockRequest = {
  kijshopCd: string,
  shopOrderId: string,
  name: string
}

export class ApiOrderLock extends ApiBaseFront {
  constructor(param: PostOrderLockRequest) {
    super(
      'POST',
      '/api/v1/lock',
      'JSON',
      {
        kijshopCd: param.kijshopCd,
        shopOrderId: param.shopOrderId,
        name: param.name,
      },
    );
  }
}

export class ApiOrderUnlock extends ApiBaseFront {
  constructor(param: OrderLockRequest) {
    super(
      'DELETE',
      '/api/v1/lock',
      'JSON',
      param,
    );
  }
}
