import { ApiBaseFront } from '../api-base-front';

type ParamType = {
  kijshopCd: string,
  orderId: string,
}
export class ApiGetCloudFolderOrderDetail extends ApiBaseFront {
  constructor(_param: ParamType) {
    const { kijshopCd, orderId } = _param;
    super(
      'GET',
      `/api/v1/admin/c-folder/shop/${kijshopCd}/order/${orderId}`,
      'JSON',
      {}
    );
  }
}
