import { AES, enc } from 'crypto-js';

/**
 * セッショントークンの管理
 * ## 保存 フロー
 *  1. 鍵の生成
 *  2. トークンの暗号化
 *  3. 保存用文字列 (s-token) の生成
 *  4. s-token の保存
 * ## 読込 フロー
 *  1. s-token から日付と暗号文を抽出
 *  2. 鍵の生成
 *  3. 暗号文の復号
 */
export class SessionTokenManager {

  private static SALT = 'ppm-cloud';
  private static STORE_KEY = 's-token';
  private static STORE_KEY_F = 'f-s-token';

  save(sessionToken: string, fSessionToken: string): void {
    const date = new Date();
    // - 暗号化 -
    const sToken = this.encrypt(date, sessionToken);
    const fSToken = this.encrypt(date, fSessionToken);
    // - LocalStorage への保存 -
    localStorage.setItem(SessionTokenManager.STORE_KEY, sToken);
    localStorage.setItem(SessionTokenManager.STORE_KEY_F, fSToken);
  }

  load(): { sToken: string, fSToken: string } {
    // - LocalStorage からの取得 -
    const sToken = localStorage.getItem(SessionTokenManager.STORE_KEY) || '';
    const fSToken = localStorage.getItem(SessionTokenManager.STORE_KEY_F) || '';
    if (!sToken || !fSToken) {
      return { sToken: '', fSToken: '' };
    }
    // - 復号 -
    const sessionToken = this.decrypt(sToken);
    const fSessionToken = this.decrypt(fSToken);
    return { sToken: sessionToken, fSToken: fSessionToken };
  }

  /**
   * セッショントークンの暗号化
   * @param date
   * @param clearText
   * @private
   */
  private encrypt(date: Date, clearText: string) {
    const key = this.createKey(date);
    // - 暗号 -
    const ciphertext = AES.encrypt(clearText, key);
    const sToken = this.encryptSToken(date, ciphertext.toString());
    return sToken;
  }

  /**
   * セッショントークンの復号
   * @private
   * @param sToken
   */
  private decrypt(sToken: string) {
    const { date, ciphertext } = this.decryptSToken(sToken);
    const key = this.createKey(date);
    // - 復号 -
    const cleartextObj = AES.decrypt(ciphertext, key);
    const cleartext = cleartextObj.toString(enc.Utf8);
    // console.group('decrypt()');
    // console.log('AES.decrypt(ciphertext, key) : ', AES.decrypt(ciphertext, key));
    // console.log('key : ', key);
    // console.log('ciphertext : ', ciphertext);
    // console.log('cleartext : ', cleartext);
    // console.groupEnd();
    return cleartext;
  }

  /**
   * s-token の作成
   * @param date
   * @param ciphertext
   * @private
   */
  private encryptSToken(date: Date, ciphertext: string) {
    return btoa(`${this.convertDate(date)}:${ciphertext}`);
  }

  /**
   * s-token の分解
   * @param sToken
   * @private
   */
  private decryptSToken(sToken: string) {
    try {
      const clearText = atob(sToken);
      const dateStr = clearText.split(':')[0] || '';
      const ciphertext = clearText.split(':')[1] || '';
      if (!(dateStr && ciphertext && Number(dateStr))) {
        throw new Error('s-token が不正です !!');
      }
      return {
        date: new Date(Number(dateStr)),
        ciphertext,
      };
    } catch (e) {
      throw new Error('s-token が不正です !!');
    }
  }

  private createKey(date: Date) {
    const salt = SessionTokenManager.SALT;
    const key = `${this.convertDate(date)}:${salt}`;
    return key;
  }

  private convertDate(date: Date) {
    // return `${date.getFullYear()}/${date.getMonth()}/${date.getDate()}`;
    return date.getTime();
  }

}
