import { XmlClass } from '../xml-class';
import { IOrderPageBreak } from '../../interface/order/i-order-page-break';
import { XmlClassName } from '../../model/xml-class-name';
import { TOrderPageBreakViewModel } from '../../model/order/xml-order-page-break-model';

type TView = Partial<TOrderPageBreakViewModel>;

export class OrderPageBreakXml extends XmlClass<IOrderPageBreak> {

  // indexes!: [string];

  name: XmlClassName = 'order-page-break';
  viewModel: TView = {};
  metaModel: {} = {};

  constructor(shopOrderId: string, data: { version: string, indexes: [number | string] }) {
    super(shopOrderId, data);
  }
  protected init(): void {
    this.xmlUniqueName = this.xmlUniqueName || `PPM_${this.shopOrderId}_11_11_orderPageBreak_${this.indexes[0]}.xml`;
    this.xmlParentName = this.xmlParentName || `PPM_${this.shopOrderId}_11_11_orderInfoData_${this.indexes[0]}.xml`;
    this.rootTagModel = {
      ...this.rootTagModel,
      parentXPath: '/lnwOrderInfoData/orderPageBreak',
      parentPath: this.xmlParentName,
    };
    this.docking({}, {});
  }

  protected docking(v: TView, m: {}): void {
    this.xmlModel = {
      lnwOrderPageBreak: {
        $: { ...this.rootTagModel },
        orderPageBreak: v.data?.image.length ? [
          {
            $: {
              count: '1',
            },
            data: [{
              $: {
                count: `${v.data.image.length}`,
                id: '1',
              },
              image: v.data.image.length ? [
                {
                  data: v.data.image.map((img, index) => ({
                    $: {
                      id: `${index + 1}`,
                      selectID: img.selectID,
                      main: '0',
                      ty: '0',
                      rotate: '0',
                    },
                    fileName: [{
                      $: {
                        path: img.path,
                      },
                    }],
                  })),
                },
              ] : undefined,
            }],
          },
        ] : undefined,
      },
    };
  }

  protected split(x: IOrderPageBreak): void {
    if (!x?.lnwOrderPageBreak?.orderPageBreak || !x.lnwOrderPageBreak.$) {
      this.viewModel = {};
      return;
    }
    this.viewModel = {
      data: x.lnwOrderPageBreak.orderPageBreak?.[0]?.data?.[0]?.image?.[0]?.data?.length ? {
        image: x.lnwOrderPageBreak.orderPageBreak[0].data[0].image[0].data.map((image) => ({
          selectID: image.$.selectID || '',
          path: image.fileName?.[0].$.path || '',
        })),
      } : undefined,
      // data: (x.lnwOrderPageBreak.orderPageBreak?.[0].data) ?
      //   x.lnwOrderPageBreak.orderPageBreak[0].data.map((v) => ({
      //     image: {
      //       data: (v.image?.[0].data) ? v.image?.[0].data.map((data) => ({
      //         selectID: data.$.selectID!,
      //         path: data.fileName!.map((path) => (
      //           path.$.path!
      //         )),
      //       })) : [],
      //     },
      //   })) : [],
    };
    this.metaModel = {};
    this.rootTagModel = Object.assign(this.rootTagModel, x.lnwOrderPageBreak.$);
  }

  changeIndexes(indexes: [string]) {
    this.indexes = indexes;
    this.xmlUniqueName = `PPM_${this.shopOrderId}_11_11_orderPageBreak_${this.indexes[0]}.xml`;
    this.xmlParentName = `PPM_${this.shopOrderId}_11_11_orderInfoData_${this.indexes[0]}.xml`;
    this.rootTagModel = {
      ...this.rootTagModel,
      parentPath: this.xmlParentName,
    };
  }

}
