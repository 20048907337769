import {useState} from 'react';
import {Button} from '../ui/button/button';
import {Login} from '../pages/login/login';
import {OrderList} from '../pages/order-list/order-list';
import {OrderPreparation} from '../pages/order-preparation/order-preparation';
import {PrintOrderImage} from '../pages/print-order-image/print-order-image';
import {CreateOrder} from '../pages/create-order/create-order';
import {Layout} from '../pages/layout/layout';
import {SystemConfig} from '../pages/config/system-config/system-config';
import {Thumbnail} from '../ui/thumbnail/thumbnail';
import {Retouch} from '../pages/retouch/retouch';

// - コンポーネントリスト -
type ComponentList = {
  title: string,
  component: JSX.Element,
}

export const DebugPage = () => {
  // - State -
  // -- ページリスト --
  const [pageList, setComponentList] = useState<ComponentList[]>([]);
  // - Callback -
  // -- ページコンポネント開く --
  const handlerOpenPage = (component: JSX.Element, title: string) => {
    setComponentList([...pageList, {title, component}]);
  };
  return (
    <div style={{ height: '100vh', overflowY: 'auto' }}>

      <br />
      <div>
        <Button
          label='ログイン'
          onClick={() => handlerOpenPage(<Login />, 'ログイン画面')}
        />
      </div>

      <br />
      <div>
        <Button
          label='注文一覧'
          onClick={() => handlerOpenPage(<OrderList />, '注文一覧画面')}
        />
      </div>

      <br />
      <div>
        <Button
          label='注文作成準備'
          onClick={() => handlerOpenPage(<OrderPreparation />, '注文作成画面')}
        />
      </div>

      <br />
      <div>
        <Button
          label='注文画像印刷'
          onClick={() => handlerOpenPage(
            <PrintOrderImage />,
            '注文画像印刷画面',
          )}
        />
      </div>

      {/* 注文作成画面と注文編集画面で同じコンポーネント */}
      <br />
      <div>
        <Button
          label='注文作成'
          onClick={() => handlerOpenPage(<CreateOrder />, '注文作成画面')}
        />
      </div>

      <br />
      <div>
        <Button
          label='レイアウト'
          onClick={() => handlerOpenPage(<Layout />, 'レイアウト画面')}
        />
      </div>

      <br />

      <div>
        <Button
          label='レタッチ'
          onClick={() => handlerOpenPage(<Retouch />, 'レタッチ')}
        />
      </div>

      <br />
      <div>
        <Button
          label='システム設定'
          onClick={() => handlerOpenPage(
            <SystemConfig
              shopCode=''
              getShopCode={() => {}}
              isNeedAgreement={true}
              getIsNeedAgreement={() => {}}
              surrogateOrderMode={null}
              getSurrogateOrderMode={() => {}}
              isStaffManagement={true}
              getIsStaffManagement={() => {}}
              isStaffLogin={true}
            />,
            'システム設定画面'
          )}
        />
      </div>

      {/* ページ表示部分 */}
      <div>
        <div>
          {pageList[pageList.length - 1]?.title}
        </div>
        <div>
          {pageList[pageList.length - 1]?.component}
        </div>
      </div>

    </div>
  );
};
