
import {AppThunk} from "../../../../app/store";
import {ordersDataActions} from "../../../../slices/orders/orders-data-slice";
import {apiActions} from "../../../../slices/api-slice";
import {ResponseBase} from "../../../response-base";
import {ApiBaseBack} from "../../api-base-back";
import { OptionItemRequest, OptionItemResponse } from '../../../../models/api/back/create-order/option-item';
import { orderRequestOptional } from '../../../../slices/orders-slice';
import { VisibleConditionValueGetter } from '../../../../manager/visible-condition-value-getter';

export class ApiOptionItem extends ApiBaseBack<OptionItemResponse> {
  constructor(param: OptionItemRequest) {
    /* ページ数更新の都合上 undefined を許容するが実質 pageCountId1 か pageCountId2 が必須な作りのためリクエスト時点で精査する */
    if (param.pageCountId1 === 'undefined') {
      param.pageCountId1 = undefined;
    }
    if (param.pageCountId2 === 'undefined') {
      param.pageCountId2 = 'undefined';
    }
    super(
      'GET',
      '/api/v1/optionProducts',
      'JSON',
      param,
    );
  }
}

export const apiOptionItemThunk = (param: OptionItemRequest, callback?: (res?: OptionItemResponse) => void, isInit?: boolean): AppThunk => async (dispatch, getState) => {
  if (!param.productNameId) {
    dispatch(ordersDataActions.updateOption({
      optionItem: { optionProduct: [] },
    }));
    callback?.();
    return;
  }
  dispatch(apiActions.run(
    new ApiOptionItem({ ...param }),
    {
      onSuccess: (res: ResponseBase<OptionItemResponse>) => {
        callback?.(res?.body?.data);
        const order = getState().order;
        const data = getState().orderData;
        const requestOptional = orderRequestOptional(order, getState().createOrder.visibleCondition, data);
        if (
          !isInit
          && (
            param.productNameId !== getState().order.itemDetail.itemSelect.value
            || (param.coverColorId || '') !== (requestOptional.coverColorId || '')
            || (param.coverId || '') !== (data.data.productDetail.coverTypeId || '')
            || (param.boardInfoId || '') !== (requestOptional.boardInfoId || '')
            || (param.orderMethodId || '') !== (requestOptional.orderMethod || '')
          )
        ) {
          return;
        }
        dispatch(ordersDataActions.updateOption({
          optionItem: { optionProduct: res.body.data?.optionProduct || [] },
        }));
      },
      onError: () => {
        callback?.();
      },
    },
  ))
};
