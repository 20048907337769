import {ResponseCallback} from "../../api/response-callback";
import {AppThunk} from "../../app/store";
import {apiActions} from "../api-slice";
import {ApiTemplate} from "../../api/back/template/api-template";
import { ApiAllTemplate } from "../../api/back/template/api-all-template";
import { AllTemplateRequest } from "../../models/api/back/template";

type ResType = 'templates' | 'templateXml' | 'templateZip' | 'thumbnail';

export const templateActions = {
  AllTemplate: (
    request: AllTemplateRequest,
    response: ResponseCallback,
  ): AppThunk => async (dispatch) => {
    dispatch(apiActions.run(new ApiAllTemplate(request), {
      onSuccess: (res) => {
        response.onSuccess(res);
      },
    }));
  },
  TemplateData: (
    path: string,
    type: ResType,
    response: ResponseCallback,
  ): AppThunk => async (dispatch) => {
    const filePath = path + (
      type === 'templates'
        ? '/templates.xml'
        : type === 'templateXml'
        ? '/template.xml'
        : type === 'templateZip'
        ? '/template.zip'
        : '/thumbnail.jpg'
    );
    dispatch(apiActions.run(new ApiTemplate(filePath, type, {}), {
      onSuccess: (res) => {
        console.log('res: ', res);
        response.onSuccess(res);
      },
    }));
  },
};
