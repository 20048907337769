import { ApiBaseFront } from '../api-base-front';

export type StatusList = {
  createDate: string,
  lastName: string | null,
  lastUpdateDate: string,
  lockUser: string | null,
  note: string | null,
  orderDate: string | null,
  shopOrderId: string,
  status: string,
}[];

export type ShopOrderStatusListGetResponse = {
  statusList: StatusList,
};

// export type ShopOrderListGetResponseStatus = {
//   shopOrderIdList: ShopOrderListGetResponse[],
//   // statusList: ShopOrderListGetResponse[],
// };
//
export class ApiShopOrderStatusListGet extends ApiBaseFront<ShopOrderStatusListGetResponse> {
  constructor(kijShopCd: string) {
    super(
      'GET',
      `/api/v1/orderxml/${kijShopCd}/status`,
      'JSON',
      {},
    );
  }
}

export type ShopOrderListGetResponseStatus = ShopOrderStatusListGetResponse;

export class ApiShopOrderListGet extends ApiBaseFront<ShopOrderListGetResponseStatus> {
  constructor(kijshopCd: string) {
    super(
      'GET',
      `/api/v1/orderxml/${kijshopCd}/status`,
      'JSON',
      // { is_first: '1' },
      {},
    );
  }
}

export type ShopOrderListSortGetRequest = {
  kijshopCd: string,
  date_kind?: string,
  date_start?: string,
  date_end?: string,
  lastName?: string,
  shopOrderId?: string,
  status?: string,
  sort_by?: string,
  highlow?: string,
  is_first?: string,
};

export class ApiShopOrderListSortGet extends ApiBaseFront<ShopOrderListGetResponseStatus> {
  constructor(_param: ShopOrderListSortGetRequest) {
    const { kijshopCd } = _param;
    super(
      'GET',
      `/api/v1/orderxml/${kijshopCd}/status`,
      'JSON',
      {},
    );
  }
}
