import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Input } from '../../ui/input/input';
import { Button } from '../../ui/button/button';
import { dialogActions } from '../slice/dialog-slice';
import { push } from 'connected-react-router';
import { PathParams, RoutingPath } from '../../../routes/routing-path';
import './change-order-page-num.scss';
import { NumUpDown } from '../../ui/input/num-up-down';
import { UiManager } from '../../../layout-editor/manager/ui/ui.manager';
import { ordersActions } from '../../../slices/orders-slice';

type ChangeOrderPageNumProps = {
  kijshopCd: string,
  shopOrderId: string,
  pageCount?: string,
  onGetAfterNum?: (num: string, isChange: boolean) => void,
  minPageCount?: string,
  maxPageCount?: string,
  stepPageCount?: string,
  disabled?: boolean,
  totalPage?: number,
  coverTemps?: any[],
  onDelete?: (temp: any) => void,
};

export const ChangeOrderPageNum = (props: ChangeOrderPageNumProps) => {
  const {
    kijshopCd,
    shopOrderId,
    pageCount,
    onGetAfterNum,
    minPageCount,
    maxPageCount,
    stepPageCount,
    disabled,
    totalPage,
    coverTemps,
    onDelete,
  } = props;
  // - Hooks -
  const dispatch = useDispatch();
  const [afterNum, setAfterNum] = useState(pageCount ? pageCount : '');
  const [isWarning, setIsWarning] = useState(false);
  // - Effect -
  // -- escキー押下 --
  useEffect(() => {
    const listener = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        setIsWarning(false);
        dispatch(dialogActions.pop());
      }
      if (e.key === 'Enter') {
        if (!isWarning) {
          handlerClickDecision();
        }
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [isWarning, afterNum, pageCount]);

  const handlerClickDecision = useCallback(() => {
    if (isWarning) return;
    const checkCover = async () => {
      return new Promise<boolean>((resolve, reject) => {
        if (!coverTemps || !coverTemps.length) {
          return resolve(false);
        }
        const targetCover = coverTemps.find(temp => {
          const min = Number(temp.cover[0].$.minPageCount);
          const max = Number(temp.cover[0].$.maxPageCount);
          return (min <= Number(pageCount) && max >= Number(pageCount));
        });
        const newCover = coverTemps.find(temp => {
          const min = Number(temp.cover[0].$.minPageCount);
          const max = Number(temp.cover[0].$.maxPageCount);
          return (min <= Number(afterNum) && max >= Number(afterNum));
        });
        if (!targetCover) {
          return resolve(false);
        }
        if (Number(targetCover.cover[0].$.minPageCount) > Number(afterNum) || Number(targetCover.cover[0].$.maxPageCount) < Number(afterNum)) {
          dispatch(dialogActions.pushMessage({
            title: '確認',
            message: [
              '表紙の背幅が変更されるため、表紙を再レイアウトする必要があります。',
              '続行しますか？',
            ],
            buttons: [
              {
                label: 'いいえ',
                callback: () => {
                  dispatch(dialogActions.pop());
                  reject(false);
                },
              },
              {
                label: 'はい',
                callback: () => {
                  dispatch(dialogActions.pop());
                  onDelete && onDelete(newCover);
                  resolve(true);
                },
              },
            ],
          }));
        } else {
          return resolve(false);
        }
      });
    };
    // 入力値が枚数下限を下回っている場合
    if (minPageCount && Number(afterNum) === Number(pageCount)) {
      setIsWarning(true);
      dispatch(dialogActions.pushMessage({
        title: '書き出しエラー',
        message: [
          '現在のページ数と同一です。',
        ],
        buttons: [
          {
            label: 'はい',
            callback: () => {
              dispatch(dialogActions.pop());
              setIsWarning(false);
              setAfterNum(afterNum);
              // onGetAfterNum?.(minPageCount);
            },
          },
        ],
      }));
    } else if (minPageCount && Number(afterNum) < Number(minPageCount)) {
      setIsWarning(true)
      dispatch(dialogActions.pushMessage({
        title: '書き出しエラー',
        message: [
          '商材の最小ページ数を下回っています。',
        ],
        buttons: [
          {
            label: 'はい',
            callback: () => {
              dispatch(dialogActions.pop());
              setIsWarning(false);
              setAfterNum(minPageCount);
              // onGetAfterNum?.(minPageCount);
            },
          },
        ],
      }));
    } else if (maxPageCount && Number(afterNum) > Number(maxPageCount)) {
      dispatch(dialogActions.pop());
      checkCover().then((v) => {
        setAfterNum(maxPageCount);
        onGetAfterNum?.(maxPageCount, v);
      });
    } else {
      dispatch(dialogActions.pop());
      checkCover().then((v) => {
        onGetAfterNum?.(afterNum, v);
      });
    }
  }, [afterNum, minPageCount, maxPageCount, pageCount]);

  const btnHandlerChangePage = useCallback((_v) => {
    const up = Number(_v) > Number(afterNum);
    if (maxPageCount && minPageCount && stepPageCount) {
      const max = Number(maxPageCount);
      const min = Number(minPageCount);
      if (up) {
        const p = _v;
        if (p <= max) {
          setAfterNum(String(p));
        }
      } else {
        const p = _v;
        if (p >= min) {
          setAfterNum(String(p));
        }
      }
    }
  }, [afterNum]);
  const handlerBlurPage = useCallback((v: string) => {
    const featurePageCount = Number(v)
    if (isNaN(featurePageCount) || v === '' || featurePageCount < 0) {
      setAfterNum(String(pageCount));
      return;
    }
      if (featurePageCount % Number(stepPageCount) !== 0) {
        // stepが１以外の時に半端なページで発注できないように抑制
        const newPageCount = Math.floor(featurePageCount / Number(stepPageCount)) * Number(stepPageCount) + Number(stepPageCount);
        setAfterNum(String(newPageCount));
      } else {
        setAfterNum(String(featurePageCount));
      }
    // let p = Number(v);
    // if (maxPageCount && minPageCount && stepPageCount) {
    //   const max = Number(maxPageCount);
    //   const min = Number(minPageCount);
    //   const step = Number(stepPageCount);
    //   if (max < p) {
    //     p = max;
    //   } else if (min > p) {
    //     p = min;
    //   } else if (p % step !== 0) {
    //     p = p - (p % step);
    //   }
    //   setAfterNum(String(p));
    // }
    // else {
    //   setAfterNum(String(pageCount));
    // }
  }, [afterNum, pageCount]);

  useEffect(() => {
    UiManager.ins.emit('r->l:stop-keyboard-event', true);
    if (totalPage) {
     if (totalPage % Number(stepPageCount) !== 0) {
       // stepが１以外の時に半端なページで発注できないように抑制
       const newPageCount = Math.floor(totalPage / Number(stepPageCount)) * Number(stepPageCount) + Number(stepPageCount);
       setAfterNum(String(newPageCount));
     } else {
       setAfterNum(String(totalPage));
     }
    }
    return (() => {
      UiManager.ins.emit('r->l:stop-keyboard-event', false);
    });
  }, []);
  return (
    <div className="dialog_contents change_order_page_num">
      <div className="dialog_contents__body">
        <div className="change_order_page_num__comment">
          <p>新しいページ数に変更する事ができます。</p>
          <p
            className="caution"
          >※
            ページ数を変更すると表紙の背幅が変わり、表紙のみ再レイアウトが
            <br />必要となる場合があります。
          </p>
        </div>
        <div className="change_order_page_num__form">
          <div>
            現在
            <Input
              value={pageCount}
              disabled={true}
            />
            ページ
          </div>
          <div className="triangle_icon" />
          <div>
            <NumUpDown
              value={afterNum}
              max={maxPageCount}
              min={minPageCount}
              step={stepPageCount}
              callback={(num) => btnHandlerChangePage(String(num))}
              onBlur={(e) => handlerBlurPage(e.target.value)}
              disabled={disabled}
            />
            ページに変更
          </div>
        </div>
      </div>
      <div className="dialog_contents__footer">
        <Button
          label="キャンセル"
          onClick={() => {
            dispatch(dialogActions.pop());
          }}
        />
        <Button
          label="決定"
          onClick={handlerClickDecision}
        />
      </div>
    </div>
  );
};
