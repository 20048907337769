import { Button } from '../../../ui/button/button';

type Props = {
  closeCallback?: () => void,
  reloadCallback?: () => void,
  callback?: () => void,
  warningMessage?: string[],
};

// 開発用 (使う事はないと思う)
// const url = 'https://chrome.google.com/webstore/detail/lighthouse/jceflfiaicpkobcabhfafnehallgeljc?hl=ja';
// 本番用
const url = 'https://chrome.google.com/webstore/detail/local-font-loader/aajnlgkfbddjplaodolnfemkfemoncfj?hl=ja&authuser=0';
const ButtonStyle = {
  width: '12em',
};

export const LocalFontLoaderErrorDialog = (props: Props) => {
  const { callback, closeCallback, reloadCallback, warningMessage } = props;
  return (
    <div className="dialog_contents">

      <div>ローカルフォントを利用するには「Chrome 拡張」をインストールする必要があります。</div>
      <div>&emsp;</div>
      <div>①「インストール画面へ」をクリックして拡張機能をインストールしてください。</div>
      <div style={{ paddingLeft: 20 }}>※別ウィンドウで開きます。</div>
      <div>&emsp;</div>
      <div>②インストールが完了した方は「再読み込み」をクリックして画面を更新してください。</div>

      {!!warningMessage && (
        <>
          <br />
          {warningMessage.map((message) => (
            <div>
              {message}
            </div>
          ))}
        </>
      )}
      <div className="dialog_contents__footer">
        <Button
          label="キャンセル"
          onClick={() => closeCallback?.()}
          style={{ ...ButtonStyle }}
        />
        {!!reloadCallback && (
          <Button
            label="再読み込み"
            onClick={() => reloadCallback?.()}
            style={{ ...ButtonStyle }}
          />
        )}
        <Button
          label="インストール画面へ"
          onClick={() => {
            window.open(url);
            callback?.();
          }}
          style={{ ...ButtonStyle }}
        />
      </div>
    </div>
  );
};
