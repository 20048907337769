import { Input } from '../../ui/input/input';
import { Button } from '../../ui/button/button';
import { Select } from '../../ui/select/select';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { dialogActions } from '../slice/dialog-slice';
import './delivery-address-info-input.scss';
import { StoreGetResponse } from '../../../models/api/front/store';
import { SendStoreConfig } from './send-store-config';
import { deliveryActions, SendStore } from '../../../slices/orders/delivery-slice';
import { useAppSelector } from '../../../app/hooks';
import { apiActions } from '../../../slices/api-slice';
import { ApiGetAddress } from '../../../api/front/order-form/api-get-address';
import { ResponseBase } from '../../../api/response-base';
import { GetAddressResponse } from '../../../models/api/front/get-address';
import { SendFormDesignationConfig } from './send-form-designation-config';
import * as lodash from 'lodash';
import { xmlActions } from '../../../xml/slice/xml-slice';
import { localStorageActions } from '../../../slices/local-storage-slice';
import { ApiStoreGet } from '../../../api/front/store/api-store';
import { ApiOrderFormGet } from '../../../api/front/order-form/api-order-form';
import { OrderFormGetResponse } from '../../../models/api/front/order-form';
import { ApiDomainCheck } from '../../../api/front/check-domain/api-domain-check';

type DeliveryAddressInfoInputProps = {
  // - 選択した発送店舗情報 -
  storeData?: StoreGetResponse,
  kijshopCd: string,
  shopOrderId: string,
  orderId: string,
};
export const DeliveryAddressInfoInput = (props: DeliveryAddressInfoInputProps) => {
  const {
    storeData,
    kijshopCd,
    shopOrderId,
    orderId,
  } = props;
  // - Hooks -
  const dispatch = useDispatch();
  // - State -
  const { data, xml, isProxyMode, storage, masterShopList } = useAppSelector((state) => ({
    data: state.delivery.address,
    xml: state.xml[shopOrderId!],
    isProxyMode: Boolean(state.systemConfig.metaData.surrogateOrder || state.xml[shopOrderId]?.info.metaModel.dpNumber),
    storage: state.storage.data,
    masterShopList: state.common.data.masterShop?.lnwMasterShop.shop?.[0].shopData,
  }), lodash.isEqual);
  // -- 名前 --
  const [name, setName] = useState('');
  // -- 郵便番号（前半） --
  const [zipCodeBefore, setZipCodeBefore] = useState('');
  // -- 郵便番号（後半） --
  const [zipCodeAfter, setZipCodeAfter] = useState('');
  // -- 都道府県 --
  const [prefecture, setPrefecture] = useState('');
  const prefectureRef = useRef<HTMLInputElement>(null);
  // -- 住所１ --
  const [address1, setAddress1] = useState('');
  // -- 住所２ --
  const [address2, setAddress2] = useState('');
  // -- 電話番号 --
  const [tel, setTel] = useState('');
  const telRef = useRef<HTMLInputElement>(null);
  // -- メールアドレス --
  const [mail, setMail] = useState('');
  const mailRef = useRef<HTMLInputElement>(null);
  // -- 配送時間 --
  const [deliveryTime, setDeliveryTime] = useState('');
  const [dispTimeZone, setDispTimeZone] = useState('');
  const [deliveryTimeStart, setDeliveryTimeStart] = useState('');
  const [deliveryTimeEnd, setDeliveryTimeEnd] = useState('');
  // -- 売上先店舗 --
  const [salesStore, setSalesStore] = useState('');
  // -- 発送店舗 --
  const [sendStore, setSendStore] = useState('');
  // -- 発送帳票 --
  const [sendFormDesignation, setSendFormDesignation] = useState('');
  const salesStoreList = masterShopList?.map((v) => ({
    value: v.$.shopCode!,
    label: v.name?.[0].$.lastName!,
  })) || [];
  // 配送時間選択肢
  const timeList = [
    {
      value: '0',
      label: '指定なし',
      start: '',
      end: '',
    },
    {
      value: '1',
      label: '午前中',
      start: '09',
      end: '12',
    },
    // {
    //   value: '2',
    //   label: '12時～14時',
    //   start: '12',
    //   end: '14',
    // },
    {
      value: '3',
      label: '14時～16時',
      start: '14',
      end: '16',
    },
    {
      value: '4',
      label: '16時～18時',
      start: '16',
      end: '18',
    },
    {
      value: '5',
      label: '18時～20時',
      start: '18',
      end: '20',
    },
    // {
    //   value: '6',
    //   label: '20時～21時',
    //   start: '20',
    //   end: '21',
    // },
    {
      value: '7',
      label: '19時〜21時',
      start: '19',
      end: '21',
    },
  ];
  // - Callback -
  // -- 名前更新 --
  const handlerChangeName = useCallback((v) => {
    setName(v);
  }, []);
  // -- 郵便番号（前半）更新 --
  const handlerChangeZipCodeBefore = useCallback((v) => {
    setZipCodeBefore(v);
  }, []);

  const onKeyUp = (e: any) => {
    if (e.code === 'Enter') {
      onEnterPress();
    }
  };

  const onEnterPress = () => {
    const reg = RegExp(/^[0-9]+$/);
    if (!reg.test(zipCodeBefore)) {
      setZipCodeBefore(zipCodeBefore.replace(/[^0-9]/g, ''));
    }
    if (!reg.test(zipCodeAfter)) {
      setZipCodeAfter(zipCodeAfter.replace(/[^0-9]/g, ''));
    }
  };

  // -- 郵便番号（後半）更新 --
  const handlerChangeZipCodeAfter = useCallback((v) => {
    setZipCodeAfter(v);
  }, []);
  // -- 都道府県更新 --
  const handlerChangePrefecture = useCallback((v) => {
    setPrefecture(v);
  }, []);
  // -- 住所１更新 --
  const handlerChangeAddress1 = useCallback((v) => {
    setAddress1(v);
  }, []);
  // -- 住所２更新 --
  const handlerChangeAddress2 = useCallback((v) => {
    setAddress2(v);
  }, []);
  // -- 電話番号更新 --
  const handlerChangeTel = useCallback((v) => {
    setTel(v);
  }, []);
  // -- メールアドレス更新 --
  const handlerChangeMail = useCallback((v) => {
    setMail(v);
  }, []);
  // -- 配送時間更新 --
  const handlerChangeDeliveryTime = useCallback((v) => {
    setDeliveryTime(v);
    const time = timeList.find((data) => data.value === v);
    if (time) {
      setDispTimeZone(time.label);
      setDeliveryTimeStart(time.start);
      setDeliveryTimeEnd(time.end);
    }
  }, [deliveryTime, dispTimeZone, deliveryTimeStart, deliveryTimeEnd]);
  // -- 売上先店舗更新 --
  const handlerChangeSalesStore = useCallback((v) => {
    setSalesStore(v);
  }, [salesStore]);
  // -- 発送店舗更新 --
  const handlerChangeSendStore = useCallback((v) => {
    setSendStore(v);
  }, [sendStore]);
  // -- 発送帳票更新 --
  const handlerChangeSendFromDesignation = useCallback((v) => {
    setSendFormDesignation(v);
  }, [sendFormDesignation]);
  // ダイアログ表示
  const showDialog = useCallback((message: string[], type?: string) => {
    dispatch(dialogActions.pushMessage({
      title: '確認',
      message: message,
      buttons: [
        {
          label: 'OK',
          callback: () => {
            dispatch(dialogActions.pop());
            if (type === 'mail') mailRef.current!.focus();
            if (type === 'tel') telRef.current!.focus();
            if (type === 'prefecture') prefectureRef.current!.focus();
          },
        },
      ],
    }));
  }, []);
  //都道府県の形式チェック
  const prefectureCheck = useCallback((v: string) => {
    if (v === '') return;
    const pattern: RegExp = /^[\u30a0-\u30ff\u3040-\u309f\u3005-\u3006\u30e0-\u9fcf]+$/;
    if (v !== '' && !v.match(pattern)) {
      showDialog(
        ['都道府県の形式が不正です。', v],
        'prefecture',
      );
    }
    return true;
  }, []);
  //電話番号の形式チェック
  const tellCheck = useCallback((v: string) => {
    if (v === '') return false;
    const pattern: RegExp = /^[0-9\-]+$/;
    if (v !== '' && !v.match(pattern)) {
      showDialog(
        ['電話番号の形式が不正です。', v],
        'tel',
      );
    }
    return true;
  }, []);
  //メールアドレスの形式チェック
  const emailCheck = useCallback((v: string) => {
    if (v === '') return false;
    const pattern: RegExp = /^((|,)([a-zA-Z0-9_.\-]+@[a-zA-Z0-9_.\-]+)){1,2}$/;
    const test = pattern.test(v);
    if (v !== '' && !test) {
      showDialog(
        ['メールアドレスの形式が不正です。', v],
        'mail',
      );
    }
    return true;
  }, []);
  // -- 住所検索押下 --
  const handlerClickSearchAddress = useCallback(() => {
    if (zipCodeBefore === '' || zipCodeAfter === '') {
      showDialog(['郵便番号が入力されていません。']);
      return;
    }
    if ((zipCodeBefore + zipCodeAfter).length !== 7) {
      showDialog(['７桁の郵便番号を入力してください。']);
      return;
    }
    saveState('zipCode');
    dispatch(apiActions.run(
      new ApiGetAddress({ kijshopCd, zipCode: (zipCodeBefore + zipCodeAfter) }),
      {
        onSuccess: (res: ResponseBase<GetAddressResponse>) => {
          const address = res.body.data;
          if (address?.kanji) {
            setPrefecture(address.kanji[0]);
            dispatch(deliveryActions.setAddressState({
              prefecture: address.kanji[0],
            }));
            if (zipCodeAfter === '0000') {
              setAddress1(address.kanji[1]);
              dispatch(deliveryActions.setAddressState({
                address1: address.kanji[1],
              }));
            } else {
              setAddress1((address.kanji[1] + address.kanji[2]));
              dispatch(deliveryActions.setAddressState({
                address1: (address.kanji[1] + address.kanji[2]),
              }));
            }
          } else {
            showDialog(['入力された郵便番号に対応する住所が見つかりませんでした。']);
          }
        },
      },
    ));
  }, [zipCodeBefore, zipCodeAfter]);
  // -- 発送店舗 リスト呼び出し押下 --
  const handlerClickCallStoreList = useCallback(() => {
    dispatch(dialogActions.push({
      title: '発送店舗設定',
      element: <SendStoreConfig kijshopCd={kijshopCd} />,
    }));
  }, []);
  // -- 発送帳票 リスト呼び出し押下 --
  const handlerClickCallFormDesignationList = useCallback(() => {
    dispatch(dialogActions.push({
      title: '発送帳票設定',
      element: <SendFormDesignationConfig
        kijshopCd={kijshopCd}
        shopOrderId={shopOrderId}
      />,
    }));
  }, []);
  // OKボタンを押した際の空欄確認
  const handlerClickOk = () => {
    let check = false;
    const messages: string[] = [];
    if (name !== '' && zipCodeBefore !== '' && zipCodeAfter !== '' && prefecture !== ''
      && address1 !== '' && tel !== '' && deliveryTime !== '' && (isProxyMode ? shopOrderId.substr(0, 7) : salesStore) !== ''
      && sendStore !== '' && ((!isProxyMode && sendFormDesignation !== '') || isProxyMode)) {
      check = true;
    } else {
      messages.push('以下の必須項目が入力されていません。');
      if (name === '') messages.push(' ・ お名前');
      if (zipCodeBefore === '' || zipCodeAfter === '') messages.push(' ・ 郵便番号');
      if (prefecture === '') messages.push(' ・ 都道府県');
      if (address1 === '') messages.push(' ・ 住所１');
      if (tel === '') messages.push(' ・ 電話番号');
      if (deliveryTime === '') messages.push(' ・ 配送時間');
      if (sendStore === '') messages.push(' ・ 発送店舗');
      if ((sendFormDesignation === '')) {
        if (!isProxyMode) {
          messages.push(' ・ 発送帳票');
        } else {
          dispatch(deliveryActions.setAddressState({
            sendFormDesignation: {
              name: '指定なし',
              path: '',
              id: '',
              originalPath: '',
              importPath: '',
            },
          }));
        }
      }
    }
    if (mail !== '') {
      dispatch(apiActions.run(
        new ApiDomainCheck({ kijshopCd, mail_address: mail }),
        {
          onSuccess: (res) => {
            if (res.error.errorCode !== 200) {
              check = false;
              messages.push('', '以下の項目の形式が不正です。', ' ・ メールアドレス', '', 'メールアドレスの有効性が確認できませんでした。', 'ドメインが無効である可能性があります。');
            }
            showMessages({ check, messages });
          },
          onError: () => {
            check = false;
            messages.push('', '以下の項目の形式が不正です。', ' ・ メールアドレス', '', 'メールアドレスの有効性が確認できませんでした。', 'ドメインが無効である可能性があります。');
            showMessages({ check, messages });
          },
        },
      ));
    } else {
      showMessages({ check, messages });
    }
  };

  // -- OKボタン押下 --
  const showMessages = (check: { check: boolean, messages: string[] }) => {
    if (check.check) {
      if (mail === '' || (isProxyMode && (sendFormDesignation === '' || sendFormDesignation === '指定なし'))) {
        let checkMessages = ['未入力の項目があります。', ''];
        if (mail === '') checkMessages.push(' ・ メールアドレス', '　未入力のまま進んだ場合、商品の出荷完了メールがお客様へ送信されません。', '');
        if ((isProxyMode && (sendFormDesignation === '' || sendFormDesignation === '指定なし'))) {
          checkMessages.push(' ・ 発送帳票', '　未入力のまま進んだ場合、商品の梱包時に帳票が同梱されません。', '');
        }
        dispatch(dialogActions.pushMessage({
          title: '確認',
          message: [
            ...checkMessages,
            'このまま進んでよろしいですか？',
          ],
          buttons: [
            {
              label: 'いいえ',
              callback: () => dispatch(dialogActions.pop()),
            },
            {
              label: 'はい',
              callback: () => {
                checkFormAndSendStore();
                dispatch(dialogActions.pop());
                dispatch(dialogActions.pop());
              },
            },
          ],
        }));
      } else {
        checkFormAndSendStore();
      }
    } else {
      showDialog(check.messages);
    }
  };

  // 発送店舗、発送帳票の存在確認（ローカルに保存後、消える可能性があるため）
  const checkFormAndSendStore = () => {
    if (!isProxyMode) {
      dispatch(apiActions.run(
        new ApiStoreGet({ kijshopCd }),
        {
          onSuccess: (sendRes) => {
            if (sendRes.body.data) {
              const arr = sendRes.body.data as SendStore[];
              if (arr.find((v) => v.shippingShopId === data.sendStore.shippingShopId)) {
                dispatch(apiActions.run(
                  new ApiOrderFormGet({ kijshopCd }),
                  {
                    onSuccess: (formRes: ResponseBase<OrderFormGetResponse[]>) => {
                      if (formRes.body.data) {
                        const formArr = formRes.body.data;
                        if (formArr.find((v) => v.path === data.sendFormDesignation.path)) {
                          saveXml();
                        } else {
                          dispatch(dialogActions.pushMessage({
                            title: '確認',
                            message: ['発送帳票が見つかりませんでした。'],
                            buttons: [{ label: 'OK', callback: () => dispatch(dialogActions.pop()) }],
                          }));
                          return;
                        }
                      }
                    },
                  },
                ));
              } else {
                dispatch(dialogActions.pushMessage({
                  title: '確認',
                  message: ['発送店舗が見つかりませんでした。'],
                  buttons: [{ label: 'OK', callback: () => dispatch(dialogActions.pop()) }],
                }));
                return;
              }
            }
          },
        },
      ));
    } else {
      saveXml();
    }
  };
  // DATAをXMLに保存
  const saveXml = () => {
    dispatch(dialogActions.pop());
    if (xml) {
      xml.delivery.viewModel.customerName = name;
      xml.delivery.viewModel.zipCode = `${zipCodeBefore}-${zipCodeAfter}`;
      xml.delivery.viewModel.prefecture = prefecture;
      xml.delivery.viewModel.address1 = address1;
      xml.delivery.viewModel.address2 = address2;
      xml.delivery.viewModel.phoneNumber = tel;
      xml.delivery.viewModel.mailAddress = mail;
      xml.delivery.viewModel.timeZone = deliveryTime;
      xml.delivery.viewModel.start = deliveryTimeStart;
      xml.delivery.viewModel.end = deliveryTimeEnd;
      xml.delivery.viewModel.dispTimeZone = dispTimeZone;
      xml.delivery.viewModel.shopName = data.sendStore.shopName;
      xml.delivery.viewModel.shopCode = data.salesStore;
      xml.delivery.viewModel.deliveryFormGUID = data.sendStore.shippingShopId;
      xml.delivery.viewModel.formName = (isProxyMode && data.sendFormDesignation.name === '') ? '指定なし' : data.sendFormDesignation.name;
      xml.delivery.viewModel.pdfPath = data.sendFormDesignation.path;
      xml.delivery.viewModel.orderFormGUID = data.sendFormDesignation.id;
      xml.delivery.viewModel.originalPath = data.sendFormDesignation.originalPath;
      xml.delivery.viewModel.importPath = data.sendFormDesignation.importPath;
      xml.delivery.viewModel.isBillingShopSelected = isProxyMode ? '0' : '1';
      xml.delivery.build();
      dispatch(xmlActions.shopOrder({ kijshopCd, shopOrderId }).update(xml));
    }
    dispatch(localStorageActions.setData(
      kijshopCd,
      'sendStore',
      {
        shopName: data.sendStore.shopName || '',
        shippingShopId: data.sendStore.shippingShopId || '',
      }));
    dispatch(localStorageActions.setData(
      kijshopCd,
      'sendFormDesignation',
      {
        name: data.sendFormDesignation.name || '',
        path: data.sendFormDesignation.path || '',
        id: data.sendFormDesignation.id || '',
        originalPath: data.sendFormDesignation.originalPath || '',
        importPath: data.sendFormDesignation.importPath || '',
      }));
  };
  // 入力後に値をstateに保存
  const saveState = (type: string) => {
    dispatch(deliveryActions.setAddressState({
      name: (type === 'name') ? name : data.name,
      zipCodeBefore: (type === 'zipCode') ? zipCodeBefore : data.zipCodeBefore,
      zipCodeAfter: (type === 'zipCode') ? zipCodeAfter : data.zipCodeAfter,
      prefecture: (type === 'prefecture') ? prefecture : data.prefecture,
      address1: (type === 'address1') ? address1 : data.address1,
      address2: (type === 'address2') ? address2 : data.address2,
      tel: (type === 'tel') ? tel : data.tel,
      mail: (type === 'mail') ? mail : data.mail,
      deliveryTime: (type === 'deliveryTime') ? deliveryTime : data.deliveryTime,
      dispTimeZone: (type === 'deliveryTime') ? dispTimeZone : data.dispTimeZone,
      start: (type === 'deliveryTime') ? deliveryTimeStart : data.start,
      end: (type === 'deliveryTime') ? deliveryTimeEnd : data.end,
      salesStore: (type === 'salesStore') ? (isProxyMode ? shopOrderId.substr(0, 7) : salesStore) : data.salesStore,
      sendStore: (type === 'sendStore') ? { shopName: sendStore, shippingShopId: '' } : data.sendStore,
      sendFormDesignation: (type === 'sendFormDesignation') ? {
        ...data.sendFormDesignation,
        name: sendFormDesignation,
      } : data.sendFormDesignation,
    }));
  };
  // -- 閉じるボタン押下 --
  const handlerClickClose = useCallback(() => {
    dispatch(dialogActions.pop());
  }, []);
  useEffect(() => {
    if (xml) {
      const delivery = xml.delivery.viewModel;
      const deliveryTime = Boolean(timeList.find((v) => v.value === delivery.timeZone) || delivery.timeZone === undefined);
      dispatch(deliveryActions.setAddressState(
        {
          name: (delivery.customerName) ? delivery.customerName : '',
          zipCodeBefore: (delivery.zipCode) ? delivery.zipCode.substring(0, 3) : '',
          zipCodeAfter: (delivery.zipCode) ? delivery.zipCode?.substring(4, 8) : '',
          prefecture: (delivery.prefecture) ? delivery.prefecture : '',
          address1: (delivery.address1) ? delivery.address1 : '',
          address2: (delivery.address2) ? delivery.address2 : '',
          tel: (delivery.phoneNumber) ? delivery.phoneNumber : '',
          mail: (delivery.mailAddress) ? delivery.mailAddress : '',
          deliveryTime: (delivery.timeZone && deliveryTime) ? delivery.timeZone : '',
          dispTimeZone: (delivery.dispTimeZone && deliveryTime) ? delivery.dispTimeZone : '',
          start: (delivery.start && deliveryTime) ? delivery.start : '',
          end: (delivery.end && deliveryTime) ? delivery.end : '',
          salesStore: (delivery.shopCode) ? delivery.shopCode : '',
          sendStore: (delivery.shopName) ? {
            shopName: delivery.shopName,
            shippingShopId: delivery.deliveryFormGUID,
          } : {
            shopName: storage.sendStore.shopName,
            shippingShopId: storage.sendStore.shippingShopId,
          },
          sendFormDesignation: (delivery.formName) ? {
            name: delivery.formName,
            path: delivery.pdfPath,
            id: delivery.orderFormGUID,
            originalPath: delivery.originalPath,
            importPath: delivery.importPath,
          } : {
            name: (isProxyMode || (!isProxyMode && storage.sendFormDesignation.name !== '指定なし')) ? storage.sendFormDesignation.name : '',
            path: (isProxyMode || (!isProxyMode && storage.sendFormDesignation.name !== '指定なし')) ? storage.sendFormDesignation.path : '',
            id: (isProxyMode || (!isProxyMode && storage.sendFormDesignation.name !== '指定なし')) ? storage.sendFormDesignation.id : '',
            originalPath: (isProxyMode || (!isProxyMode && storage.sendFormDesignation.name !== '指定なし')) ? storage.sendFormDesignation.originalPath : '',
            importPath: (isProxyMode || (!isProxyMode && storage.sendFormDesignation.name !== '指定なし')) ? storage.sendFormDesignation.importPath : '',
          },
        },
      ));
      setName((delivery.customerName) ? delivery.customerName : '');
      setZipCodeBefore((delivery.zipCode) ? delivery.zipCode.substring(0, 3) : '');
      setZipCodeAfter((delivery.zipCode) ? delivery.zipCode?.substring(4, 8) : '');
      setPrefecture((delivery.prefecture) ? delivery.prefecture : '');
      setAddress1((delivery.address1) ? delivery.address1 : '');
      setAddress2((delivery.address2) ? delivery.address2 : '');
      setTel((delivery.phoneNumber) ? delivery.phoneNumber : '');
      setMail((delivery.mailAddress) ? delivery.mailAddress : '');
      setDeliveryTime((delivery.timeZone && deliveryTime) ? delivery.timeZone : '');
      setDispTimeZone((delivery.dispTimeZone && deliveryTime) ? delivery.dispTimeZone : '');
      setDeliveryTimeStart((delivery.start && deliveryTime) ? delivery.start : '');
      setDeliveryTimeEnd((delivery.end && deliveryTime) ? delivery.end : '');
      setSalesStore((delivery.shopCode) ? delivery.shopCode : '');
      setSendStore((delivery.shopName) ? delivery.shopName : storage.sendStore.shopName);
      setSendFormDesignation((delivery.formName) ? delivery.formName : storage.sendFormDesignation.name);
      // 配送時間の選択肢が減ったので、登録してあるものを編集する際に、削除されている選択肢で登録してある場合はダイアログを表示
      if (!deliveryTime) {
        dispatch(dialogActions.pushMessage({
          title: '確認',
          message: ['無効な配送時間が選択されています。', '配送時間をもう一度選択し直してください。'],
          buttons: [{ label: 'OK', callback: () => dispatch(dialogActions.pop()) }],
        }));
      }
    }
    return () => {
      dispatch(deliveryActions.resetAddressState());
    };
  }, [xml]);

  useEffect(() => {
    if (!isProxyMode && data.sendStore.shopName !== sendStore) {
      setSendStore(data.sendStore.shopName || '');
    }
    if (data.sendFormDesignation.name !== sendFormDesignation) {
      setSendFormDesignation(data.sendFormDesignation.name || '');
    }
  }, [data]);

  return (
    <div className="dialog_contents delivery_address_info_input">
      <div className="delivery_address_info_input__inner">
        <div className="dialog_contents__body">
          <div className="bottom_border_header delivery_address_info_input__header">
            <div>配送先</div>
            <div>は必須項目です</div>
          </div>

          <div className="delivery_address_info_input__body">
            <div className="top_label_ui">
              <div className="top_label_ui__label">
                お名前
                <span className="top_label_ui__label__required" />
              </div>
              <Input
                value={name}
                onChange={(e) => handlerChangeName(e.target.value)}
                onBlur={() => {
                  saveState('name');
                }}
                maxLength={32}
              />
            </div>

            <div className="top_label_ui">
              <div className="top_label_ui__label">
                郵便番号
                <span className="top_label_ui__label__required" />
              </div>
              <div className="postal_form">
                <Input
                  value={zipCodeBefore}
                  maxLength={3}
                  handlerEnter={onEnterPress}
                  onKeyUp={(e) => onKeyUp(e)}
                  onChange={(e) => handlerChangeZipCodeBefore(e.target.value)}
                  onBlur={onEnterPress}
                />
                <div className="postal_form__hyphen" />
                <Input
                  value={zipCodeAfter}
                  maxLength={4}
                  handlerEnter={onEnterPress}
                  onKeyUp={(e) => onKeyUp(e)}
                  onChange={(e) => handlerChangeZipCodeAfter(e.target.value)}
                  onBlur={onEnterPress}
                />
                <Button
                  label="住所検索"
                  onClick={handlerClickSearchAddress}
                  className="postal_form__search_btn"
                />
              </div>
            </div>

            <div className="top_label_ui form_prefecture">
              <div className="top_label_ui__label">
                都道府県
                <span className="top_label_ui__label__required" />
              </div>
              <Input
                value={prefecture}
                onChange={(e) => handlerChangePrefecture(e.target.value)}
                maxLength={5}
                onBlur={(e) => {
                  if (prefectureCheck(e.target.value)) {
                    saveState('prefecture');
                  }
                }}
                ref={prefectureRef}
              />
            </div>

            <div className="top_label_ui">
              <div className="top_label_ui__label">
                住所1
                <span className="top_label_ui__label__required" />
              </div>
              <Input
                value={address1}
                onChange={(e) => handlerChangeAddress1(e.target.value)}
                maxLength={32}
                onBlur={() => saveState('address1')}
              />
            </div>

            <div className="top_label_ui">
              <div className="top_label_ui__label">
                住所2
              </div>
              <Input
                value={address2}
                onChange={(e) => handlerChangeAddress2(e.target.value)}
                maxLength={32}
                onBlur={() => saveState('address2')}
              />
            </div>

            <div className="top_label_ui form_tel">
              <div className="top_label_ui__label">
                電話番号
                <span className="top_label_ui__label__required" />
              </div>
              <Input
                value={tel}
                onChange={(e) => handlerChangeTel(e.target.value)}
                maxLength={14}
                onBlur={(e) => {
                  if (tellCheck(e.target.value)) {
                    saveState('tel');
                  }
                }}
                onKeyPress={(e) => {
                  if (!/[0-9,-]/.test(e.key)) {
                    e.preventDefault();
                  }
                }}
                ref={telRef}
              />
            </div>

            <div className="top_label_ui">
              <div className="top_label_ui__label">
                メールアドレス
              </div>
              <Input
                value={mail}
                onChange={(e) => handlerChangeMail(e.target.value)}
                maxLength={300}
                onBlur={(e) => {
                  if (emailCheck(e.target.value)) {
                    saveState('mail');
                  }
                }}
                type={'email'}
                ref={mailRef}
              />
            </div>

            <div className="top_label_ui form_delivery_time">
              <div className="top_label_ui__label">
                配送時間
                <span className="top_label_ui__label__required" />
              </div>
              <Select
                value={deliveryTime || '時間指定'}
                list={(deliveryTime !== '') ? timeList : [{ value: '時間指定', label: '時間指定' }, ...timeList]}
                onChange={(e) => handlerChangeDeliveryTime(e.target.value)}
                onBlur={() => saveState('deliveryTime')}
              />
            </div>

            {!isProxyMode ? (
              <div className="top_label_ui form_sales_store">
                <div className="top_label_ui__label">
                  売上先店舗
                  <span className="top_label_ui__label__required" />
                </div>
                <Select
                  value={salesStore}
                  list={salesStoreList}
                  onChange={(e) => handlerChangeSalesStore(e.target.value)}
                  onBlur={() => saveState('salesStore')}
                />
              </div>
            ) : (<></>)}

            <div className="top_label_ui">
              <div className="top_label_ui__label">
                発送店舗
                <span className="top_label_ui__label__required" />
                （送り状の備考欄に表示される店舗名です）
              </div>
              <div className="flex_box gap_col_8">
                <Input
                  value={sendStore}
                  onChange={(e) => handlerChangeSendStore(e.target.value)}
                  maxLength={50}
                  disabled={!isProxyMode}
                  onBlur={() => saveState('sendStore')}
                />
                {(!isProxyMode) ?
                  <Button
                    label="リスト呼び出し"
                    onClick={handlerClickCallStoreList}
                  /> : <></>}
              </div>
            </div>

            <div className="top_label_ui">
              <div className="top_label_ui__label">
                発送帳票
                {(isProxyMode) ? <></> : <span className="top_label_ui__label__required" />}
                （出荷時に同梱される帳票です）
              </div>
              <div className="flex_box gap_col_8">
                <Input
                  value={sendFormDesignation}
                  onChange={(e) => handlerChangeSendFromDesignation(e.target.value)}
                  onBlur={() => saveState('sendFormDesignation')}
                  maxLength={30}
                  disabled
                />
                <Button
                  label="リスト呼び出し"
                  onClick={handlerClickCallFormDesignationList}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="dialog_contents__footer">
        <Button
          label="閉じる"
          onClick={handlerClickClose}
        />
        <Button
          label="OK"
          onClick={handlerClickOk}
        />
      </div>
    </div>
  );
};
