import { ApiBaseFront } from '../api-base-front';

type ParamType = {
  kijshopCd: string,
};

export class ApiGetCloudFolderShopDetail extends ApiBaseFront {
  constructor(param: ParamType) {
    const { kijshopCd } = param;
    super(
      'GET',
      `/api/v1/admin/c-folder/shop/${kijshopCd}`,
      'JSON',
      {},
    );
  }
}
