import { OrdersSliceState } from '../../slices/orders-slice';
import { OrdersDataState } from '../../slices/orders/orders-data-slice';
import { CreateOrderItemData } from './create-order-item-data';
import { VisibleConditionValueGetter } from '../../manager/visible-condition-value-getter';
import { TContentData } from '../t-visible-condition';

export const checkEditable = (content: TContentData | undefined) => content?.modifiable !== 'false';
export const checkOptional = (content: TContentData | undefined) => content?.level !== 'must';

const categorize = (v: string) => `商品情報-商品詳細-${v}`;
export const itemDetailItemData = (order: OrdersSliceState, data: OrdersDataState, visibleCondition: VisibleConditionValueGetter): (CreateOrderItemData)[] => {
  // const display = itemDetailDisplayItem(order, data);
  const display = visibleCondition.itemDetailOrderDisplay;
  const {
    coverSelect: _coverSelect,
    coverColorSelect: _coverColorSelect,
    itemSelect: _itemSelect,
    boardSelect: _boardSelect,
    orderMethod: _orderMethod,
    designType1: _designType1,
    designType2: _designType2,
    quantity,
  } = order.itemDetail;
  const coverSelect = _coverSelect.value;
  const coverColorSelect = _coverColorSelect.value;
  const itemSelect = _itemSelect.value;
  const boardSelect = _boardSelect.value;
  const orderMethod = _orderMethod.value;
  const designType1 = _designType1.value;
  const designType2 = _designType2.value;
  return ([
    {
      // 表紙選択
      // 写真アルバムプロフラグが有効なら表示
      name: 'cover',
      value: coverSelect,
      editable: checkEditable(display.cover),
      optional: checkOptional(display.cover),
      display: Boolean(display.cover),
      categorize: categorize('表紙選択'),
    },
    {
      // 表紙色選択
      // 写真アルバムプロフラグが有効かつ表紙色情報が存在し表紙選択が選ばれている場合に表示
      //  L 写真アルバムプロフラグが有効な場合に表紙色情報を取得
      name: 'coverColor',
      value: coverColorSelect,
      editable: checkEditable(display.coverColor),
      optional: checkOptional(display.coverColor),
      display: Boolean(display.coverColor),
      categorize: categorize('表紙色選択'),
    },
    {
      // 商品選択
      // 非写真アルバムプロフラグが有効, もしくは表紙選択が選ばれていて(表紙色選択が選ばれているもしくは表紙色情報が存在しない場合)に表示
      name: 'item',
      value: itemSelect,
      editable: checkEditable(display.item),
      optional: checkOptional(display.item),
      display: Boolean(display.item),
      categorize: categorize('商品選択'),
    },
    {
      // 部材選択
      // カテゴリが jp0325 でなく、商品選択が選ばれている場合に表示
      name: 'board',
      value: boardSelect,
      editable: checkEditable(display.board),
      optional: checkOptional(display.board),
      display: Boolean(display.board),
      categorize: categorize('部材選択'),
    },
    {
      // 注文方法
      // 商品選択が選択されている場合に表示。商品種により分岐するが挙動自体は変わらないっぽい。
      //  L 商品選択が選択された場合に商品種を取得
      name: 'orderMethod',
      value: orderMethod,
      editable: checkEditable(display.orderMethod),
      optional: checkOptional(display.orderMethod),
      display: Boolean(display.orderMethod),
      categorize: categorize('注文方法'),
    },
    {
      // デザインタイプ1
      // 商品選択が選択されていて注文方法の値が10(画像を選択しておまかせ)かつ(非フロー変更版注文有無が存在するもしくは部材選択が選択されているもしくは部材情報が存在しない場合)に表示
      //  L 商品選択が選択されて部材選択情報がなく(編集モード(?)もしくはオーダーデフォルトモード(?)が選択されている)場合に非フロー変更版注文有無を取得
      //     L カテゴリが jp0325 でなく商品IDが存在する場合に部材選択情報を取得
      //        L 商品選択が選択された場合に商品IDを取得
      name: 'designType1',
      value: designType1,
      editable: checkEditable(display.designType1),
      optional: checkOptional(display.designType1),
      display: Boolean(display.designType1),
      categorize: categorize('デザインタイプ1'),
    },
    {
      // デザインタイプ2
      // デザインタイプ1が選択されていて注文方法の値が10(画像を選択しておまかせ)の場合に表示
      name: 'designType2',
      value: designType2,
      editable: checkEditable(display.designType2),
      optional: checkOptional(display.designType2),
      display: Boolean(display.designType2),
      categorize: categorize('デザインタイプ2'),
    },
    {
      // 数量
      // 商品が選択されている場合に表示
      // ※表示条件は商品選択のみ。入力範囲がカテゴリと商品で分岐
      name: 'quantity',
      value: String(quantity),
      editable: checkEditable(display.quantity),
      optional: checkOptional(display.quantity),
      display: Boolean(display.quantity),
      categorize: categorize('数量'),
    },
  ]);
}
