import { OrdersSliceState } from '../../slices/orders-slice';
import { OrdersDataState } from '../../slices/orders/orders-data-slice';
import { CreateOrderItemData } from './create-order-item-data';
import { VisibleConditionValueGetter } from '../../manager/visible-condition-value-getter';
import { checkEditable, checkOptional } from './item-detail-item-data';

const categorize = (v: string) => `商品情報-商品詳細-商品記載情報-${v}`;
export const descriptionInfoItemData = (order: OrdersSliceState, data: OrdersDataState, visibleCondition: VisibleConditionValueGetter): CreateOrderItemData[] => {
  const display = visibleCondition.descriptionInfoOrderDisplay;
  const {
    name,
    date,
    photographLocation,
    descriptionLocations,
  } = order.descriptionInfo;
  return ([
    {
      // 名前
      // 名前情報がある場合に表示
      //  L カテゴリと商品IDに依存？（SQLの条件句にgoods_idとo_category）
      name: 'name',
      value: name || '',
      editable: checkEditable(display.name),
      optional: checkOptional(display.name),
      display: Boolean(display.name),
      categorize: categorize('名前'),
    },
    {
      // 名前（半角英数）
      // 名前(半角英数)情報がある場合に表示
      //  L カテゴリと商品IDに依存？（SQLの条件句にgoods_idとo_category）
      name: 'nameRestrict',
      value: name || '',
      editable: checkEditable(display.nameRestrict),
      optional: checkOptional(display.nameRestrict),
      display: Boolean(display.nameRestrict),
      categorize: categorize('名前（半角英数）'),
    },
    {
      // 名前
      // 項目名は「名前」でみてるパラメータは name_nenga なので年賀状情報？
      name: 'nameNenga',
      value: name || '',
      editable: checkEditable(display.nameNenga),
      optional: checkOptional(display.nameNenga),
      display: Boolean(display.nameNenga),
      categorize: categorize('名前'),
    },
    {
      // 日付
      // 日付情報がある場合に表示
      //  L カテゴリと商品IDに依存？（SQLの条件句にgoods_idとo_category）
      name: 'date',
      value: date?.value || '',
      editable: checkEditable(display.date),
      optional: checkOptional(display.date),
      display: Boolean(display.date),
      categorize: categorize('日付'),
      validation: () => !date?.alert,
    },
    // {
    //   // ふりがな
    //   // ふりがな情報がある場合に表示
    //   //  L カテゴリと商品IDに依存？（SQLの条件句にgoods_idとo_category）
    //   // 日付のふりがな
    //   name: 'dateKana',
    //   value: date.value,
    //   editable: true,
    //   optional: true,
    //   display: display.dateKana,
    //   categorize: categorize('ふりがな'),
    // },
    {
      // 場所
      // 場所情報がある場合に表示
      //  L カテゴリと商品IDに依存？（SQLの条件句にgoods_idとo_category）
      name: 'location',
      value: photographLocation || '',
      editable: checkEditable(display.location),
      optional: checkOptional(display.location),
      display: Boolean(display.location),
      categorize: categorize('場所'),
    },
    {
      // 場所
      // 項目名は「名前」で参照は身長情報
      // 身長情報がある場合に表示
      name: 'locationHeight',
      value: photographLocation || '',
      editable: checkEditable(display.locationHeight),
      optional: checkOptional(display.locationHeight),
      display: Boolean(display.locationHeight),
      categorize: categorize('場所'),
    },
    // {
    //   // コメント
    //   // コメント情報がある場合に表示
    //   //  L カテゴリと商品IDに依存？（SQLの条件句にgoods_idとo_category）
    //   name: 'comment',
    //   editable: true,
    //   optional: true,
    //   display: display.comment,
    //   categorize: categorize('コメント'),
    // },
    // {
    //   // 住所
    //   // 住所情報がある場合に表示
    //   //  L カテゴリと商品IDに依存？（SQLの条件句にgoods_idとo_category）
    //   name: 'locationAddress',
    //   value: ,
    //   editable: true,
    //   optional: true,
    //   display: display.locationAddress,
    //   categorize: categorize('住所'),
    // },
  ]);
}
