import React, { useState } from 'react';
import { Header } from '../../ui/header/header';
import { StaffTable } from './staff-table';
import { CommonButton } from '../../ui/button/common-button';
import { useDispatch } from 'react-redux';
import { PathParams, RoutingPath } from '../../../routes/routing-path';
import { push } from 'connected-react-router';
import { useParams } from 'react-router-dom';
import { useDidMount } from '../../../hooks/life-cycle';
import { apiActions } from '../../../slices/api-slice';
import { ApiCheckStaffLogin } from '../../../api/front/wizard/api-staff-management';
import { ResponseBase } from '../../../api/response-base';
import { CheckStaffLoginResponse } from '../../../models/api/front/staff-management';
import { dialogActions } from '../../dialog/slice/dialog-slice';
import lodash from 'lodash';
import { useAppSelector } from '../../../app/hooks';
import { commonActions } from '../../../slices/common-slice';

export const StaffList = () => {
  const { masterAuthority } =  useAppSelector((state) => ({
    ...state.common.data,
  }), lodash.isEqual)
  const dispatch = useDispatch()
  const { kijshopCd } = useParams<PathParams>();
  const [isAdmin, setIsAdmin] = useState(false);
  // - 管理者権限チェック -
  useDidMount(() => {
    dispatch(apiActions.run(
      new ApiCheckStaffLogin({ kijshopCd }),
      {
        onSuccess: (res: ResponseBase<CheckStaffLoginResponse>) => {
          // スタッフログイン判定
          if (String(res?.error?.errorCode) === '200') {
            dispatch(dialogActions.pushMessage({
              title: '確認',
              message: [
                '権限がないためページを表示できません。',
                '「OK」をクリックすると 注文一覧に戻ります。',
              ],
              buttons: [{
                label: 'OK',
                callback: () => {
                  dispatch(push(RoutingPath.cnv.orders({ kijshopCd })));
                  dispatch(dialogActions.pop());
                }
              }]
            }))
          }
          // 店舗管理者判定
          if(String(res?.error?.errorCode) === '404') {
            setIsAdmin(true);
          }
        },
        // エラー
        onBasicError: () => {
          dispatch(dialogActions.pushMessage({
            title: '確認',
            message: [
              '権限の取得に失敗したためページを表示できません',
              '「OK」をクリックすると 注文一覧に戻ります。',
            ],
            buttons: [{
              label: 'OK',
              callback: () => {
                dispatch(push(RoutingPath.cnv.orders({ kijshopCd })));
                dispatch(dialogActions.pop());
              }
            }]
          }))
        }
      },
    ));
    if (!masterAuthority) {
      dispatch(commonActions.getData(kijshopCd));
    }
  })
  return (
    <div className="page_base">
      <Header page="staff" />
      <div className="staff_return">
        <CommonButton
          commonType="return"
          label="注文一覧に戻る"
          onClick={() => {
            dispatch(push(RoutingPath.cnv.orders({ kijshopCd })));
          }}
        />
      </div>
      <div className="list_page_contents">
        <StaffTable isAdmin={isAdmin} />
      </div>
    </div>
  );
}
