import { CjBitmap, CjContainer } from '../../model/cj-factory';
import { AlbumManager } from '../../albam/albam';
import { Env } from '../../../models/env';
import { XmlStructureModel } from '../../../xml/model/xml-structure-model';
import { CjTool } from '../../../utilities/cj-tool';
import { store } from '../../../app/store';
import { EditableImageManager } from '../editable-image/editable-image.manager';
import { LayoutCompURLsType } from './layout-data-type';
import { cloneDeep } from 'lodash';
import { TrimListParam } from './layout-data.manager';
import { ColorCodeConverter } from '../../../utilities/colorcode-converter';
import { OrderPageDataXml } from '../../../xml/class/order/order-page-data-xml';
import { ResultTextData, TextImageManager } from '../text-image/text-image.manager';
import { CjRectXML, CjRect } from '../../model/cj-factory/cj-container';
import { RestorePicData } from '../../model/layout.xml.utile';
import { CJ_DPOBJ_NAME } from '../../model/cj-factory/cj-obj-name.collection';

export type ChangeSelectData = {
  rect: { width: string, height: string },
  selectId: string,
}

export type TextImageData = {
  textData: File[],
  deleteData: string[],
}

export class LayoutDataFormatter {
  // Xmlへの保存処理
  static async jsonToXml(
    kijShopCd: string,
    orderId: string,
    shopOrderId: string,
    xml: XmlStructureModel,
    albumMng: AlbumManager,
    frameContainers: CjContainer[],
    photoContainers: CjContainer[],
    textContainers: CjContainer[],
    freeGraphics: CjContainer[],
    trimList: { pageId: string, list: TrimListParam[] }[],
    editableImgMng: EditableImageManager,
    compFiles: LayoutCompURLsType,
    mode: 'save' | 'comp',
    fitNum: number,
    textMng: TextImageManager,
  ) {
    return new Promise<{
      xml: XmlStructureModel,
      deleteIdList: string[],
      changeOrderSelectData: ChangeSelectData[],
      textData: TextImageData,
      metaData: RestorePicData[],
      isWrongFont: boolean,
    }>(async (resolve) => {
      // - templateのbaseURL -
      const BASE_URL = `${Env.api.back.protocol}://${Env.api.back.host}/files/template`;
      const changeOrderSelectData: ChangeSelectData[] = [];
      const textData: TextImageData = { textData: [], deleteData: [] };
      const _restorePicData = store.getState().layout.metaData;
      // - partsとそこに紐づくpageData -
      const optionList = xml.orderInfo?.infoData?.filter((v) => v.xml.metaModel.parentId === orderId)
        .filter((v) => v.parts?.partsData?.filter((v2) => v2.page?.pageData?.filter?.((v3) => !v3.viewModel.parentID).length).length);
      const optionParts = (optionList?.map((v) => v.parts?.partsData ?? []) ?? []).flat();
      const orderInfo = xml.orderInfo?.infoData?.find((infoData) => infoData.xml.metaModel.id === orderId);
      if (!orderInfo) return;
      const orderParts = orderInfo.parts?.partsData?.concat(optionParts);
      const orderPages = orderParts?.map((elm) => elm.page?.pageData).flat();
      let isWrongFont = false;
      console.group();
      console.log(orderPages);
      console.groupEnd();
      const saveMetas: RestorePicData[] = [];
      for (const parts of orderParts ?? []) {
        for (const page of parts.page?.pageData ?? []) {
          const optionLevel = store.getState().layout.albumPages.option.findIndex((v) => v.xml.viewModel.pageType === page.viewModel.pageType) + 1;
          if (!saveMetas.find((v) => v.optionLevel === optionLevel)) {
            saveMetas.push({
              optionLevel,
              data: [],
            });
          }
          const findOrder = saveMetas.find((v) => v.optionLevel === optionLevel);
          if (!findOrder?.data.find((v) => v.partsId === parts.xml.indexes[1])) {
            findOrder?.data.push({
              partsId: parts.xml.indexes[1],
              picData: [],
            });
          }
        }
      }
      // 削除するPNGのリスト
      const deletePngImages: string[] = [];
      // - pathの整理 -
      const path = {
        base: '',
        ad: '',
        name: '',
      };
      // - orderPageDataの作成 -
      if (!orderPages) return;
      const albumPages = store.getState().layout.albumPages;
      for (const orderPageData of orderPages) {
        if (!orderPageData) continue;
        // - template情報取得 -
        albumMng.pages.sort((a, b) => {
          /* 念の為手前に来るページを先に判定 */
          if (['op_print', 'cover', 'frame'].includes(a.type) || ['op_print', 'cover', 'frame'].includes(b.type)) {
            if (!['op_print', 'cover', 'frame'].includes(b.type)) {
              return 1;
            }
            if (!['op_print', 'cover', 'frame'].includes(a.type)) {
              return -1;
            }
            if (a.type !== b.type) {
              if (a.type.indexOf('op_print') !== -1) {
                return 1;
              }
              if (b.type.indexOf('op_print') !== -1) {
                return -1;
              }
            }
            return Number(a.pageDataId) > Number(b.pageDataId) ? 1 : -1;
          }
          /* 単にオプションは後ろ */
          if (a.pageNo === '***' || b.pageNo === '***') {
            if (b.pageNo !== '***') {
              return 1;
            }
            if (a.pageNo !== '***') {
              return -1;
            }
            return Number(a.pageDataId) > Number(b.pageDataId) ? 1 : -1;
          }
          /* 基本的には pageNo で判定 */
          if (Number(a.pageNo.split('-')[0]) > Number(b.pageNo.split('-')[0])) {
            return 1;
          } else {
            return -1;
          }
        });
        const temps = albumMng.pages.filter((elm) => elm.typeID === orderPageData.viewModel.pageType);
        // if (!temps.length) continue;
        // - template情報とオーダー情報の紐付け -
        const pageIndex = Number(orderPageData.viewModel.id) - albumPages.top.page - 1;
        // const temp = (() => {
        //   if ((store.getState().layout.pageTypeList.page.find(v => v.pageTypeID === orderPageData.viewModel.pageType) &&
        //         store.getState().layout.albumPages.page.albums.find(v => v.typeID === orderPageData.viewModel.pageType))) {
        //     return temps[pageIndex];
        //   } else {
        //     return temps[0];
        //   }
        // })()
        // const temp = temps[
        //   ((store.getState().layout.pageTypeList.page.find(v => v.pageTypeID === orderPageData.viewModel.pageType) &&
        //     store.getState().layout.albumPages.page.albums.find(v => v.typeID === orderPageData.viewModel.pageType)) ||
        //     store.getState().layout.albumPages.option.find(v => v.album?.typeID !== orderPageData.viewModel.pageType)) ? pageIndex : 0];
        // const temp = temps[(orderPageData.viewModel.pageType === 'jptg380014' || orderInfo.xml.viewModel.goods?.productType === 'jptg300164' || orderPageData.viewModel.displayPageType === 'ﾌﾟﾘﾝﾄ') ? pageIndex : 0];
        // const temp = temps[(orderPageData.viewModel.pageType === 'jptg380014' || printList.includes(orderPageData.viewModel.pageType ?? '')) ? pageIndex : 0];
        const isPage = (store.getState().layout.pageTypeList.page.find(v => v.pageTypeID === orderPageData.viewModel.pageType)
          && store.getState().layout.albumPages.page.albums.find(v => v.typeID === orderPageData.viewModel.pageType));
        /* ページ以外で複数レイアウトする場合は別途インデックスを引っ張ってくる必要がある */
        const temp = isPage ? temps[pageIndex] : (() => {
          if (store.getState().layout.pageTypeList.option.find((v) => v.pageTypeID === orderPageData.viewModel.pageType)) {
            const arr = [...store.getState().layout.albumPages.option].sort((a, b) => Number(a.album?.pageDataId) < Number(b.album?.pageDataId) ? -1 : 1);
            const index = arr.findIndex((v) => v.album?.pageDataId === orderPageData.viewModel.id);
            return temps.find((v) => v.pageDataId && v.pageDataId === arr[index]?.album?.pageDataId) || temps[index] || temps[0];
          }
          if (store.getState().layout.pageTypeList.opPrint.find((v) => v.pageTypeID === orderPageData.viewModel.pageType)) {
            const arr = [...store.getState().layout.albumPages.opPrint].sort((a, b) => Number(a.album?.pageDataId) < Number(b.album?.pageDataId) ? -1 : 1);
            const index = arr.findIndex((v) => v.album?.pageDataId === orderPageData.viewModel.id);
            return temps.find((v) => v.pageDataId && v.pageDataId === arr[index]?.album?.pageDataId) || temps[index] || temps[0];
          }
          return temps[0];
        })();
        // temp.indexes = orderPageData._indexes;
        // if (!temp) continue;
        if (temp) temp.indexes = orderPageData.indexes;
        // - サムネに異常があった場合 -
        path.base = temp?.dirName ?? '';
        // - サムネのパスから基準のパスを作る(dirNameはCoverを考慮していないためaddPathを用意) -
        const thumbnailPath = temp?.thumbnail;
        const fullPath = thumbnailPath?.templatePath.substr(0, thumbnailPath?.templatePath.indexOf('/thumbnail.jpg')) ?? path.base;
        // - テンプレートのパス部分だけ抽出 -
        path.ad = fullPath.substr(fullPath.indexOf(BASE_URL) + BASE_URL.length);
        const adPath = path.ad.slice(1);
        // - pathからテンプレート名を抽出 -
        const dirNames = path.ad.split('/');
        const dirNameIndex = dirNames.indexOf('template');
        path.name = dirNames[(dirNameIndex === -1 ? 0 : dirNameIndex) + 3] ?? '';
        // - realとvirtualの値セット -
        const tempReal = temp?.parseData.templateData.size[0].real[0].$;
        const tempVirtual = temp?.parseData.templateData.size[0].virtual[0].$;
        const tempScaleEditorFlag = (() => {
          if (temp?.parseData.templateData?.scaleEditor) {
            const enable = temp?.parseData.templateData?.scaleEditor[0].$.enable;
            return Boolean(Number(enable)) ? enable : undefined;
          } else {
            return undefined;
          }
        })();
        const additionalFrame = temp?.parseData.templateData.additionalFrame || undefined;
        const materials = temp?.parseData.templateData.materials || undefined;
        const orderSelect = xml.orderSelect?.metaModel.imageData ?? [];
        // - shapeFlagセット (ページ回転方向取得 1or2or3)
        const shapeFlag = (): '1' | '2' | '3' => {
          switch (true) {
            case Number(tempReal.height) > Number(tempReal.width) :
              return '1';
            case Number(tempReal.width) > Number(tempReal.height) :
              return '2';
            default:
              return '3';
          }
        };
        // - 写真の合成された写真枠を判別 -
        const checkPhotoSeqCount = async () => {
          const seqList: CjContainer[] = [];
          return new Promise<number>((resolve) => {
            for (const frame of frames) {
              const imageArea = CjTool.getImageAreaContainer(frame);
              // NOTE: imageArea.isCompositedを追加することで現行のバグを踏襲。
              //  一度でも写真を入れると削除してもカウントされる。
              if (!imageArea.logoFlag && imageArea.isComposited) {
                seqList.push(frame);
              }
            }
            resolve(seqList.length);
          });
        };
        // - レイアウト画面で追加された画像判別 -
        const checkAddedPhoto = async () => {
          const saveSelect = store.getState().layout.saveSelect;
          const photos = photoContainers.filter((elm) => elm.pageId === temp.id);
          return new Promise<CjContainer[]>((resolve) => {
            for (const photo of photos) {
              const targetPhoto = saveSelect.find((elm) => elm.editableImageId === photo.editableImageId);
              if (!targetPhoto) continue;
              const isUse = editableImgMng.list.find((elm) => elm.id === targetPhoto.editableImageId);
              // const targetPhoto = editableImgMng.list.find((elm) => elm.id === photo.editableImageId);
              if (!isUse) continue;
              // if (!targetPhoto?.flags.used) continue;
              targetPhoto && (CjTool.getPhotoContainer(photo).selectID = targetPhoto.selectId);
            }
            resolve(photos);
          });
        };
        // - PNG画像のPathの設定 -
        const checkPngPath = async (images: CjContainer[]) => {
          const freeGraphs = images.filter((elm) => elm.pageId === temp.id);
          return new Promise<CjContainer[]>((resolve) => {
            for (const graph of freeGraphs) {
              const targetGraph = editableImgMng.list.find((elm) => elm.id === graph.editableImageId);
              if (targetGraph) {
                graph.realPath = targetGraph.name;
                graph.virtualPath = targetGraph.path;
              }
            }
            resolve(freeGraphs);
          });
        };
        /* ロゴ画像の検索 */
        const findPic = (frame: CjContainer) => editableImgMng.list.find((v) => v.id === CjTool.getPhotoContainer(frame)?.editableImageId);

        /* orderTextImageデータ作成 */
        const createOrderTextImage = (_textData: ResultTextData[], pageData: OrderPageDataXml) => {
          const deletePath: string[] = _textData.length ? pageData.viewModel.orderTextImage?.data?.map((v) => v.path ?? undefined).filter((v) => v) as string[] ?? [] : [];
          const createData: File[] = _textData.map((v) => v.file);
          textData.deleteData.push(...deletePath);
          textData.textData.push(...createData);
          return _textData.map((v) => ({
            path: `Except_Real/${v.file.name}`,
            x: String(v.rect.x),
            y: String(v.rect.y),
            width: String(v.rect.w),
            height: String(v.rect.h),
          }));
        };

        const createDummyLogo = (frame: CjContainer, _temp: any) => {
          const find = _temp.parseData.templateData.photoarea[Number(frame?.frameId) - 1];
          const adjust = (Number(tempVirtual?.width) || 0) / (Number(tempReal?.width) || 0) || 0;
          if (!find) {
            return undefined;
          }
          const virtualX = (Number(find.virtual[0].$.x) || 0);
          const virtualY = (Number(find.virtual[0].$.y) - fitNum) || 0;
          const virtualWidth = Number(find.virtual[0].$.width) || 0;
          const virtualHeight = Number(find.virtual[0].$.height) || 0;
          const reSize = Math.floor(((virtualWidth - virtualHeight) / 2) - fitNum);
          const isResize = virtualWidth >= virtualHeight;
          const virtualSize = CjTool.getImageAreaContainer(frame).logoFlag ? virtualHeight : isResize ? virtualWidth : virtualHeight;
          const resultVirtualX = (virtualX + reSize) || 0;
          const resultRealX = (resultVirtualX / adjust) || 0;
          const realY = (virtualY / adjust) || 0;
          const widthHeight = virtualSize + (fitNum * 2);
          return ({
            real: {
              x: String(Math.floor(resultRealX)),
              y: String(Math.floor(realY)),
              width: String((Math.ceil((widthHeight || 0) / adjust))),
              height: String((Math.ceil((widthHeight || 0) / adjust))),
            },
            virtual: {
              x: String(Math.floor(resultVirtualX)),
              y: String(Math.floor(virtualY)),
              width: String(Math.ceil(widthHeight) || 0),
              height: String(Math.ceil(widthHeight) || 0),
            },
          });
        };
        const formatPicRect = (frame: CjContainer, vector: 'x' | 'y', type: 'real' | 'virtual') => {
          const targetPhoto = CjTool.getPhoto(frame);
          const targetPhotoContainer = CjTool.getPhotoContainer(frame);
          const targetRotate = (() => {
            const imageArea = CjTool.getImageAreaContainer(frame);
            const submaskRotate = () => {
              if (imageArea.postCardType) {
                const angle = imageArea.postCardType === 'left' ? 90 : -90;
                if (((Number(imageArea.originalRotate) ?? 0) + Number(angle)) >= 0) {
                  return Number((Number(imageArea.originalRotate) + Number(angle)) % 360);
                }
              }
              return Number(imageArea.originalRotate ?? 0);
            };
            return (CjTool.getPhotoContainer(frame)?.rotation + submaskRotate()) || 0;
          })()
          const imageOrigin = CjTool.getImageOrigin({
            origin: {x: 0, y: 0},
            move: {x: targetPhotoContainer.x - targetPhotoContainer.defaultPos.x, y: targetPhotoContainer.y - targetPhotoContainer.defaultPos.y},
            subMaskRect: {width: CjTool.getImageAreaContainer(frame).virtual?.width, height: CjTool.getImageAreaContainer(frame).virtual?.height, x: CjTool.getImageAreaContainer(frame).virtual?.x, y: CjTool.getImageAreaContainer(frame).virtual?.y},
            size: {width: targetPhoto.image.width * targetPhotoContainer.scaleX, height: targetPhoto.image.height * targetPhotoContainer.scaleX},
            rotate: targetRotate,
            photoFrameRotate: CjTool.getImageAreaContainer(frame).originalRotate,
          })
          if (vector === 'x') {
            return String((imageOrigin.x * (type === 'real' ? (tempReal.width / tempVirtual.width) : 1)) || 0);
          } else {
            return String((imageOrigin.y * (type === 'real' ? (tempReal.width / tempVirtual.width) : 1)) || 0);
          }
        };
        const formatFreeGraphPos = (image: CjContainer, vector: 'x' | 'y', type: 'real' | 'virtual') => {
          const targetPhoto = image.getChildByName(CJ_DPOBJ_NAME.freeGraphic) as CjBitmap
          const targetPhotoContainer = image;
          const targetRotate = targetPhotoContainer.rotation || 0;
          // const imageOrigin = CjTool.getOriginPos(image, 'png')
          const imageOrigin = CjTool.getImageOrigin({
            origin: {x: targetPhotoContainer.defaultPos.x, y: targetPhotoContainer.defaultPos.y},
            move: {x: targetPhotoContainer.x - targetPhotoContainer.defaultPos.x, y: targetPhotoContainer.y - targetPhotoContainer.defaultPos.y},
            subMaskRect: {width: 0, height: 0, x: 0, y: 0},
            size: {width: targetPhotoContainer.virtual.width * targetPhotoContainer.scaleX, height: targetPhotoContainer.virtual.height * targetPhotoContainer.scaleX},
            rotate: targetRotate
          })
          if (vector === 'x') {
            return String(imageOrigin.x * (type === 'real' ? (tempReal.width / tempVirtual.width) : 1) || 0);
          } else {
            return String(imageOrigin.y * (type === 'real' ? (tempReal.width / tempVirtual.width) : 1) || 0);
          }
        };

        // - page内、各情報の取得。保持。 -
        const frames = frameContainers?.filter((elm) => elm.pageId === temp?.id);
        const texts = textContainers?.filter((elm) => elm.pageId === temp?.id);
        const textImageData: ResultTextData[] = [];
        if (texts && texts.length) {
          const data: any[] = [];
          texts.forEach((v) => {
            const text = cloneDeep(v);
            const textFocus = CjTool.getTextFocus(text);
            text.removeChild(textFocus);
            // const text = cloneDeep(CjTool.getText(v));
            const pos = CjTool.getOriginPos(v, 'text');
            data.push({ text, pos });
          });
          const depth1 = orderPageData.indexes[0] ?? '0';
          const depth2 = orderPageData.indexes[1] ?? '0';
          const depth3 = orderPageData.indexes[2] ?? '0';
          const depth: [string, string, string] = [depth1, depth2, depth3];

          const _data = await textMng.create({ data }, this.exchangeRectToNumber(tempReal), this.exchangeRectToNumber(tempVirtual), depth, {
            kijshopCd: kijShopCd,
            shopOrderId,
          });
          textImageData.push(..._data.files);
          if (mode === 'comp' && _data.wrongFonts.length) {
            isWrongFont = true;
            _data.wrongFonts.forEach((v) => {
              const wrongFont = texts.find((text) => text.id === v.text.id);
              if (wrongFont) {
                wrongFont.fontName = '';
              }
            })
          }
        }
        const _pngImages = freeGraphics?.filter((elm) => elm.pageId === temp?.id).filter((elm) => {
          if (elm.deleteFlag) deletePngImages.push(elm.editableImageId || '');
          return !elm.deleteFlag;
        });
        const photos = temp ? await checkAddedPhoto() : [];
        const photoSeqCount = temp ? await checkPhotoSeqCount() : 0;
        const pngImages = temp ? await checkPngPath(_pngImages) : [];
        const isDelete = Object.values(path).every((v) => !v);
        const additionalHall = trimList.find(v => v.pageId === temp?.id);
        const pushMetaData = frames.map((v) => {
          const photo = CjTool.checkPhotoInFrame(v) ? CjTool.getPhotoContainer(v) : undefined;
          const editorContainer = CjTool.getDefaultContainer(v);
          const scaleContainer = CjTool.getScaleContainer(editorContainer);
          const photoData = {
            x: photo?.x ?? 0,
            y: photo?.y ?? 0,
            isFit: photo?.isFit ?? false,
          };

          return {
            ...photoData,
            pageId: orderPageData.indexes[2],
            photoAreaId: CjTool.getImageAreaContainer(v)?.subMaskID || '',
            photoAreaX: editorContainer.x,
            photoAreaY: editorContainer.y,
            photoAreaZoom: scaleContainer?.scaleY ?? 1,
          };
        }).filter((v) => v);
        if (temp) {
          for (const pushData of pushMetaData) {
            if (pushData) {
              const optionLevel = store.getState().layout.albumPages.option.findIndex((v) => v.xml.viewModel.pageType === temp.typeID) + 1;
              const findOrder = saveMetas.findIndex((v) => v.optionLevel === optionLevel);
              const findParts = saveMetas?.[findOrder]?.data.findIndex((v) => v.partsId === orderPageData.indexes[1]);
              saveMetas?.[findOrder]?.data?.[findParts]?.picData.push(pushData);
            }
          }
        }

        // - viewModelの更新 -
        orderPageData.viewModel = !temp ? {
          ...orderPageData.viewModel,
          additionalFrame: undefined,
          additionalHole: undefined,
          compositeFileName: undefined,
          freeGraphic: undefined,
          materials: undefined,
          orderFrame: undefined,
          orderPicture: undefined,
          orderText: undefined,
          orderTextImage: undefined,
          originalCompositeFileName: undefined,
          pageNoOverlap: undefined,
          parentID: undefined,
          template: undefined,
        } : {
          ...orderPageData.viewModel,
          // -- template更新 --
          template: this.deleteTemplate(isDelete, {
            path: path.base,
            adPath,
            name: path.name,
            shapeFlag: shapeFlag(),
            scaleEditorFlag: tempScaleEditorFlag,
            real: {
              width: tempReal.width,
              height: tempReal.height,
            },
            virtual: {
              width: tempVirtual.width,
              height: tempVirtual.height,
            },
          }),
          compositeFileName: this.deleteTemplate(isDelete, {
            realPath: '',
            ...orderPageData.viewModel.compositeFileName,
          }),
          // -- frameの更新 --
          orderFrame: this.deleteTemplate(isDelete, frames.length ? this.sortAttribute([
            ...frames.map((frame, i) => ({
              /* photoAreaId が 0 始まりのケースがあるため index で設定 */
              id: frame.frameId ?? String(i + 1),
              // id: `${i + 1}`,
              copyFlag: CjTool.getImageAreaContainer(frame).copyFlag ? '1' : '0',
              deleteFlag:
                CjTool.getImageAreaContainer(frame)?.logoFlag
                  ? '0'
                  : mode === 'save'
                    ? CjTool.getImageAreaContainer(frame).deleteFlag ? '1' : '0'
                    : (!CjTool.checkPhotoInFrame(CjTool.getImageAreaContainer(frame))
                      || CjTool.getImageAreaContainer(frame).deleteFlag) ? '1' : '0'
              ,
              real: { x: '0', y: '0' },
              virtual: {
                x: String(frame.x - Number(temp.parseData.templateData.photoarea.find((p: any) => p.$.photoareaID === CjTool.getImageAreaContainer(frame).orgsubMaskID)?.virtual?.[0]?.$.x) || 0),
                y: String(frame.y - Number(temp.parseData.templateData.photoarea.find((p: any) => p.$.photoareaID === CjTool.getImageAreaContainer(frame).orgsubMaskID)?.virtual?.[0]?.$.y) || 0),
              },
              frameRotate: (() => {
                const imageArea = CjTool.getImageAreaContainer(frame);
                const submaskRotate = () => {
                  if (imageArea.postCardType) {
                    const angle = imageArea.postCardType === 'left' ? 90 : -90;
                    if ((Number(imageArea.originalRotate ?? 0) + Number(angle)) >= 0) {
                      return Number((Number(imageArea.originalRotate) + Number(angle)) % 360);
                    }
                  }
                  return imageArea.originalRotate ?? 0;
                };
                return String(CjTool.getRotateContainer(frame).rotation - submaskRotate() || 0);
                // return String(CjTool.getRotateContainer(frame).rotation - CjTool.getImageAreaContainer(frame).originalRotate || 0)
              })(),
              zoom: String(CjTool.getScaleContainer(frame).scaleX || 1),
            })),
          ], 'id') : []),
          // -- photoの更新 --
          orderPicture: this.deleteTemplate(isDelete, {
            data: this.sortAttribute([
              ...frames.map((frame, i) => {
                  const check = CjTool.checkPhotoInFrame(frame);
                  return ({
                    id: frame.frameId ?? String(i + 1),
                    contactNaiFlag: CjTool.getImageAreaContainer(frame)?.copyFlag
                      ? undefined
                      : CjTool.getImageAreaContainer(frame)?.logoFlag
                        ? check
                          ? undefined
                          : '0'
                        : (CjTool.getPhotoContainer(frame)?.setMode === 'in' && !CjTool.getPhotoContainer(frame).isFit) ? '1' : '0',
                    cantactNaiFlag: CjTool.getImageAreaContainer(frame)?.copyFlag ? '' : undefined,
                    copyFlag: CjTool.getImageAreaContainer(frame)?.logoFlag
                      ? check
                        ? undefined
                        : '0'
                      : CjTool.getImageAreaContainer(frame)?.copyFlag ? '1' : '0',
                    deleteFlag: CjTool.getImageAreaContainer(frame)?.logoFlag
                      ? check
                        ? undefined
                        : '0'
                      : (mode === 'save'
                        ? CjTool.getImageAreaContainer(frame).deleteFlag ? '1' : '0'
                        : (!CjTool.checkPhotoInFrame(CjTool.getImageAreaContainer(frame))
                          || CjTool.getImageAreaContainer(frame).deleteFlag) ? '1' : '0'),
                    depth: String(CjTool.getImageAreaContainer(frame)?.originDepth) || '',
                    frame: (() => {
                      if (CjTool.getImageAreaContainer(frame)?.logoFlag) {
                        return '0';
                      } else {
                        return CjTool.getImageAreaContainer(frame)?.frameFlag ? '1' : '0';
                      }
                    })(),
                    // framename: '',
                    logoFlag: CjTool.getImageAreaContainer(frame)?.logoFlag ? findPic(frame) ? '1' : '2' : '0',
                    mask: CjTool.getImageAreaContainer(frame)?.maskFlag && !CjTool.getImageAreaContainer(frame)?.logoFlag ? '1' : '0',
                    maskname: CjTool.getImageAreaContainer(frame)?.maskName || '',
                    orgsubMaskID: CjTool.getImageAreaContainer(frame)?.logoFlag
                      ? check
                        ? undefined
                        : '0'
                      : CjTool.getImageAreaContainer(frame).orgsubMaskID || '0',
                    photoSeqNo: CjTool.getImageAreaContainer(frame)?.logoFlag
                      ? check
                        ? undefined
                        : '0'
                      : CjTool.checkPhotoInFrame(frame)
                        ? String(CjTool.getImageContainer(frame).photoSeqNo || i + 1)
                        : String(frames.indexOf(frame) + 1 || i + 1),
                    selectID: (() => {
                      const photo = CjTool.getPhotoContainer(frame);
                      let selectID: string | undefined | null = photo?.selectID;
                      if (!selectID) selectID = undefined;
                      if (CjTool.getImageAreaContainer(frame)?.logoFlag && check) selectID = 'null';
                      if (photo?.deleteFlag) selectID = undefined;
                      // NOTE: ロゴは画像がなくても正であり、使用画像の配下にないためチェック対象に含めない
                      if (!(CjTool.getImageAreaContainer(frame)?.logoFlag) &&
                        (!editableImgMng.list.find(v => v.selectId === selectID)?.flags.used)) {
                        selectID = undefined;
                      }
                      return selectID;
                    })(),
                    subMaskID: (CjTool.getImageAreaContainer(frame)?.subMaskID || ''),
                    selectImage: (() => {
                      return CjTool.getImageAreaContainer(frame)?.logoFlag || CjTool.getImageAreaContainer(frame).deleteFlag
                        ? undefined
                        : (CjTool.checkPhotoInFrame(frame) ? {
                          width: (String(orderSelect.find((v) => v.selectID === CjTool.getPhotoContainer(frame).selectID)?.width
                            ?? (() => {
                              const editableImg = editableImgMng.list.find((img) => img.id === CjTool.getPhotoContainer(frame).editableImageId);
                              const getImgSelectId = CjTool.getPhotoContainer(frame).selectID ?? '';
                              changeOrderSelectData.push({
                                selectId: getImgSelectId,
                                rect: {
                                  width: editableImg?.originalWidth ?? '',
                                  height: editableImg?.originalHeight ?? '',
                                },
                              });
                              return (editableImgMng.list.find((img) => img.id === CjTool.getPhotoContainer(frame).editableImageId)?.originalWidth || '');
                            })())),
                          height: (String(orderSelect.find((v) => v.selectID === CjTool.getPhotoContainer(frame).selectID)?.height
                            ?? (editableImgMng.list.find((img) => img.id === CjTool.getPhotoContainer(frame).editableImageId)?.originalHeight || ''))),
                        } : undefined);
                    })(),
                    compositeFlag: CjTool.getImageAreaContainer(frame)?.logoFlag && findPic(frame) ? undefined : ['1'],
                    logoFileName: CjTool.getImageAreaContainer(frame)?.logoFlag ? {
                      realPath: (() => {
                        const find = editableImgMng.list.find((v) => v.id === CjTool.getPhotoContainer(frame)?.editableImageId);
                        return find?.name ? `Except_Real/${find?.name}` : '';
                      })(),
                    } : undefined,
                    subMaskRect: {
                      real: {
                        x: String((CjTool.getImageAreaContainer(frame).real?.x + (CjTool.getImageAreaContainer(frame).copyFlag ? 30  * (tempReal.width / tempVirtual.width) : 0))) || '',
                        y: String((CjTool.getImageAreaContainer(frame).real?.y + (CjTool.getImageAreaContainer(frame).copyFlag ? 30  * (tempReal.width / tempVirtual.width) : 0))) || '',
                        width: String(CjTool.getImageAreaContainer(frame).real?.width) || '',
                        height: String(CjTool.getImageAreaContainer(frame).real?.height) || '',
                      },
                      virtual: {
                        x: String(CjTool.getImageAreaContainer(frame).virtual?.x + (CjTool.getImageAreaContainer(frame).copyFlag ? 30 : 0)) || '',
                        y: String(CjTool.getImageAreaContainer(frame).virtual?.y + (CjTool.getImageAreaContainer(frame).copyFlag ? 30 : 0)) || '',
                        width: String(CjTool.getImageAreaContainer(frame).virtual?.width) || '',
                        height: String(CjTool.getImageAreaContainer(frame).virtual?.height) || '',
                      },
                    },
                    subMaskRotate: String(CjTool.getImageAreaContainer(frame).originalRotate || 0),
                    pictureRect: CjTool.getPhotoContainer(frame)?.selectID !== undefined ? {
                      real: {
                        x: formatPicRect(frame, 'x', 'real'),
                        y: formatPicRect(frame, 'y', 'real'),
                        // x: String(Math.ceil(CjTool.getOriginPos(CjTool.getPhotoContainer(frame), 'photo').x - (frame.x - Number(temp.parseData.templateData.photoarea.find((p: any) => p.$.photoareaID === CjTool.getImageAreaContainer(frame).orgsubMaskID)?.virtual?.[0]?.$.x))) || 0),
                        // y: String(Math.ceil(CjTool.getOriginPos(CjTool.getPhotoContainer(frame), 'photo').y - (frame.y - Number(temp.parseData.templateData.photoarea.find((p: any) => p.$.photoareaID === CjTool.getImageAreaContainer(frame).orgsubMaskID)?.virtual?.[0]?.$.y))) || 0),
                        width: String(((CjTool.getPhoto(frame).image.width * CjTool.getPhotoContainer(frame).scaleX) * (tempReal.width / tempVirtual.width)) || 0),
                        height: String(((CjTool.getPhoto(frame).image.height * CjTool.getPhotoContainer(frame).scaleY) * (tempReal.width / tempVirtual.width)) || 0),
                      },
                      virtual: {
                        x: formatPicRect(frame, 'x', 'virtual'),
                        y: formatPicRect(frame, 'y', 'virtual'),
                        // x: String(Math.ceil(CjTool.getOriginPos(CjTool.getPhotoContainer(frame), 'photo').x - (frame.x - Number(temp.parseData.templateData.photoarea.find((p: any) => p.$.photoareaID === CjTool.getImageAreaContainer(frame).orgsubMaskID)?.virtual?.[0]?.$.x))) || 0),
                        // y: String(Math.ceil(CjTool.getOriginPos(CjTool.getPhotoContainer(frame), 'photo').y - (frame.y - Number(temp.parseData.templateData.photoarea.find((p: any) => p.$.photoareaID === CjTool.getImageAreaContainer(frame).orgsubMaskID)?.virtual?.[0]?.$.y))) || 0),
                        width: String((CjTool.getPhoto(frame).image.width * CjTool.getPhotoContainer(frame).scaleX) || 0),
                        height: String((CjTool.getPhoto(frame).image.height * CjTool.getPhotoContainer(frame).scaleY) || 0),
                      },
                    } : !findPic(frame) ?
                      createDummyLogo(frame, temp)
                      : {
                        real: {
                          x: '',
                          y: '',
                          width: '',
                          height: '',
                        },
                        virtual: {
                          x: '',
                          y: '',
                          width: '',
                          height: '',
                        },
                      },
                    pictureRotate: (() => {
                      const imageArea = CjTool.getImageAreaContainer(frame);
                      const submaskRotate = () => {
                        if (imageArea.postCardType) {
                          const angle = imageArea.postCardType === 'left' ? 90 : -90;
                          if (((Number(imageArea.originalRotate) ?? 0) + Number(angle)) >= 0) {
                            return Number((Number(imageArea.originalRotate) + Number(angle)) % 360);
                          }
                        }
                        return Number(imageArea.originalRotate ?? 0);
                      };
                      return String((CjTool.getPhotoContainer(frame)?.rotation + submaskRotate()) || 0);
                      // return String((CjTool.getPhotoContainer(frame)?.rotation + CjTool.getImageAreaContainer(frame).originalRotate) || 0);
                    })(),
                    zoom: [''],
                  });
                },
              ),
            ], 'id'),
            photoSeqCount: (() => {
              const prevSeqCount = Number(orderPageData.viewModel.orderPicture?.photoSeqCount);
              const seqCount = (prevSeqCount > photoSeqCount) ? prevSeqCount : photoSeqCount;
              return String(seqCount);
            })(),
          }),
          // -- textの更新 --
          orderText: this.deleteTemplate(isDelete, texts.length ? {
            data: texts.map((text) => ({
              text: {
                bold: text.bold ? '1' : '0',
                borderColor: ColorCodeConverter.suppressZero((text.borderColor || '').replace('#', '').toLowerCase() || 'ff0000'),
                contactPageNoPos: '0',
                contactType: '0',
                fontColor: ColorCodeConverter.suppressZero((CjTool.getText(text).color || '').replace('#', '').toLowerCase() || '808080'),
                fontName: text.fontName || '',
                horizontalAlignment: text.horizontalAlignment || '0',
                italic: text.italic ? '1' : '0',
                lineHeight: String(CjTool.getText(text).lineHeight - CjTool.getText(text).getMeasuredLineHeight()) || '1',
                strikeOut: '0',
                transparence: String(Math.floor(Number(CjTool.getText(text).alpha) * 100 ?? 100)),
                underLine: '0',
                text: CjTool.getText(text).text || '',
                textSize: (text.textSize || '24px').replace('px', '') || '24',
                borderSize: text.borderSize || '0',
                dropShadow: (() => {
                  const shadowAlpha = ColorCodeConverter.rgbaToColorCode(String(text.dropShadow?.transparence ?? 'rgba(0,0,0,0.65)')).alpha;
                  return {
                    enable: String(text.dropShadow?.enable),
                    transparence: String(Math.floor(Number(shadowAlpha) * 100) ?? 100),
                    virtualSize: String(text.dropShadow?.enable ? (text.dropShadow?.virtualSize || '0') : '0'),
                  };
                })(),
                part: {
                  text_alpha: '-1',
                  text_bold: '-1',
                  text_endPos: '-1',
                  text_fontColor: '',
                  text_fontName: '',
                  text_italic: '-1',
                  text_size: '-1',
                  text_startPos: '-1',
                },
              },
              textRect: {
                real: {
                  x: String(CjTool.getOriginPos(text, 'text').x * (tempReal.width / tempVirtual.width) || 0),
                  y: String(CjTool.getOriginPos(text, 'text').y * (tempReal.width / tempVirtual.width) || 0),
                  width: String(text.getTransformedBounds().width * (tempReal.width / tempVirtual.width) || 0),
                  height: String(text.getTransformedBounds().height * (tempReal.width / tempVirtual.width) || 0),
                },
                virtual: {
                  x: String(CjTool.getOriginPos(text, 'text').x || 0),
                  y: String(CjTool.getOriginPos(text, 'text').y || 0),
                  width: String(text.getTransformedBounds().width || 0),
                  height: String(text.getTransformedBounds().height || 0),
                },
              },
              textRotate: String(text.rotation || 0),
            })),
          } : { data: [] }),
          // -- png画像の更新 --
          freeGraphic: pngImages.length ? {
            data: pngImages.map((graphic) => ({
              real: {
                x: formatFreeGraphPos(graphic, 'x', 'real'),
                y: formatFreeGraphPos(graphic, 'y', 'real'),
                // x: String(Math.floor(((graphic.virtual.x) + (graphic.x - graphic.originalBounds.x) - graphic.differPos.x - graphic.getTransformedBounds().width / 2) * (tempReal.width / tempVirtual.width)) || 0),
                // y: String(Math.floor(((graphic.virtual.y) + (graphic.y - graphic.originalBounds.y) - graphic.differPos.y - graphic.getTransformedBounds().height / 2) * (tempReal.width / tempVirtual.width)) || 0),
                width: String((graphic.virtual.width * graphic.scaleX) * (tempReal.width / tempVirtual.width) || 0),
                height: String((graphic.virtual.height * graphic.scaleY) * (tempReal.width / tempVirtual.width) || 0),
                path: graphic.realPath || '',
              },
              virtual: {
                x: formatFreeGraphPos(graphic, 'x', 'virtual'),
                y: formatFreeGraphPos(graphic, 'y', 'virtual'),
                // x: String(Math.floor(graphic.virtual.x + (graphic.x - graphic.originalBounds.x) - graphic.differPos.x - graphic.getTransformedBounds().width / 2) || 0),
                // y: String(Math.floor(graphic.virtual.y + (graphic.y - graphic.originalBounds.y) - graphic.differPos.y - graphic.getTransformedBounds().height / 2) || 0),
                width: String((graphic.virtual.width * graphic.scaleX) || 0),
                height: String((graphic.virtual.height * graphic.scaleY) || 0),
                path: (() => {
                  const path = graphic.virtualPath
                   ? graphic.virtualPath.indexOf('FreeGraphics/') === -1
                    ? `FreeGraphics/${graphic.virtualPath}`
                    : graphic.virtualPath
                   : '';
                  return path;
                })(),
              },
              rotation: String(graphic.rotation || 0),
            })),
          } : { data: [] },
          pageOutputCount: orderPageData.viewModel.pageOutputCount || (photos.length !== Number(orderPageData.viewModel.pageOutputCount) ? orderPageData.viewModel.pageOutputCount : undefined),
          additionalFrame: this.deleteTemplate(isDelete, additionalFrame ? {
            ...orderPageData.viewModel.additionalFrame,
            name: additionalFrame[0].$.name || undefined,
            type: additionalFrame[0].$.type || '',
            dpi: additionalFrame[0].$.dpi || '',
            frameID: additionalFrame[0].$.frameID || undefined,
            enable: additionalFrame[0].$.enable || '',
            rotate: additionalFrame[0].$.rotate || '0',
            color: {
              r: additionalFrame[0].color[0].$.r || '',
              g: additionalFrame[0].color[0].$.g || '',
              b: additionalFrame[0].color[0].$.b || '',
            },
            real: {
              x: additionalFrame[0].additionalFrameSize[0].real[0].$.x || '',
              y: additionalFrame[0].additionalFrameSize[0].real[0].$.y || '',
              width: additionalFrame[0].additionalFrameSize[0].real[0].$.width || '',
              height: additionalFrame[0].additionalFrameSize[0].real[0].$.height || '',
            },
            virtual: {
              x: additionalFrame[0].additionalFrameSize[0].virtual[0].$.x || '',
              y: additionalFrame[0].additionalFrameSize[0].virtual[0].$.y || '',
              width: additionalFrame[0].additionalFrameSize[0].virtual[0].$.width || '',
              height: additionalFrame[0].additionalFrameSize[0].virtual[0].$.height || '',
            },
            rotatable: {
              r90: additionalFrame[0].rotatable[0].$.r90 || '',
              r180: additionalFrame[0].rotatable[0].$.r180 || '',
              r270: additionalFrame[0].rotatable[0].$.r270 || '',
            },
            windows: additionalFrame[0].windows[0].window.length ? [...(additionalFrame[0].windows[0].window || [])].map((win) => ({
              sizeID: win.$.sizeID || undefined,
              sizeName: win.$.sizeName || undefined,
              circle: win.type[0].$.circle || '',
              default: win.type[0].$.default || '',
              rect: win.type[0].$.rect || '',
              rotation: win.type[0].$.rotation || '',
              real: {
                x: win.windowSize[0].real[0].$.x || '',
                y: win.windowSize[0].real[0].$.y || '',
                width: win.windowSize[0].real[0].$.width || '',
                height: win.windowSize[0].real[0].$.height || '',
              },
              virtual: {
                x: win.windowSize[0].virtual[0].$.x || '',
                y: win.windowSize[0].virtual[0].$.y || '',
                width: win.windowSize[0].virtual[0].$.width || '',
                height: win.windowSize[0].virtual[0].$.height || '',
              },
            })) : [],
          } : undefined),
          additionalHole: additionalFrame && additionalHall ? {
            windows: additionalHall.list.map((trim) => ({
              nakamadoHeight: String(trim.data.windowSize),
              jinsunHeight: String(trim.data.humanScale),
              upperSpace: String(trim.data.topSpace),
              bottomSpace: String(trim.data.bottomSpace),
            })),
          } : undefined,
          materials: this.deleteTemplate(isDelete, materials ? {
            ...orderPageData.viewModel.materials,
            id: materials[0].$.id || '',
            name: materials[0].$.name || '',
            material: [...(materials[0].material || [])].map((material) => ({
              materialID: material.$.materialId || '',
              materialName: material.$.materialName || '',
              shape: material.$.shape || '',
            })),
          } : undefined),
          orderTextImage: { data: this.deleteTemplate(isDelete, createOrderTextImage(textImageData, orderPageData)) ?? [] },
          pageNoOverlap: this.deleteTemplate(isDelete, orderPageData.viewModel.pageNoOverlap) || {
            left: '0',
            right: '0',
          },
          parentID: this.deleteTemplate(isDelete, orderPageData.viewModel.parentID),
        };
        orderPageData.build();
      }
      resolve({ xml, deleteIdList: deletePngImages, changeOrderSelectData, textData, metaData: saveMetas, isWrongFont });
    });
  }

  private static deleteTemplate = <T>(isDelete: boolean, value: T): T | undefined => isDelete ? undefined : value;

  private static sortAttribute = <T>(target: T[], sortKey: keyof T): T[] => {
    const _target = cloneDeep(target);
    _target.sort((a, b) => {
      const numA = Number(a[sortKey] || 0);
      const numB = Number(b[sortKey] || 0);
      if (numA > numB) return 1;
      return -1;
    });
    return _target;
  };

  private static exchangeRectToNumber = (rect?: CjRectXML): CjRect => {
    return { width: Number(rect?.width) || 0, height: Number(rect?.height) || 0 };
  };
}
