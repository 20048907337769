import { OptionSetInfoRequest, OptionSetInfoResponse } from '../../../../models/api/back/create-order/option-set-info';
import {AppThunk} from "../../../../app/store";
import {ordersDataActions} from "../../../../slices/orders/orders-data-slice";
import {apiActions} from "../../../../slices/api-slice";
import {ResponseBase} from "../../../response-base";
import {ApiBaseBack} from "../../api-base-back";

export class ApiOptionSetInfo extends ApiBaseBack<OptionSetInfoResponse> {
  constructor(param: OptionSetInfoRequest) {
    super(
      'GET',
      '/api/v1/optionProductSets',
      'JSON',
      param,
    );
  }
}

export const apiOptionSetInfoThunk = (param: OptionSetInfoRequest, callback?: () => void, isInit?: boolean): AppThunk => async (dispatch, getState) => {
  if (!param.productNameId) {
    dispatch(ordersDataActions.updateOption({
      optionSetInfo: { optionProductName: [] },
    }));
    callback?.();
    return;
  }
  dispatch(apiActions.run(
    new ApiOptionSetInfo(param),
    {
      onSuccess: (res: ResponseBase<OptionSetInfoResponse>) => {
        callback?.();
        if (!isInit && param.productNameId !== getState().order.itemDetail.itemSelect.value) {
          return;
        }
        dispatch(ordersDataActions.updateOption({
          optionSetInfo: { optionProductName: res.body.data?.optionProductName || [] },
        }));
      },
      onError: () => {
        dispatch(ordersDataActions.updateOption({
          optionSetInfo: { optionProductName: [] },
        }));
        callback?.();
      },
    },
    {
      apiName: 'option-set-info',
      ignoreSystemError: true,
    },
  ))
};
