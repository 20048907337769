import { Select } from '../../ui/select/select';
import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { apiActions } from '../../../slices/api-slice';
import { useAppSelector } from '../../../app/hooks';
import { ordersActions } from '../../../slices/orders-slice';
import * as lodash from 'lodash';
import { ApiCategories } from '../../../api/back/create-order/categories/api-categories';
import { CategoriesResponse } from '../../../models/api/back/create-order/categories';
import { ordersDataActions } from '../../../slices/orders/orders-data-slice';
import { ResponseBase } from '../../../api/response-base';
import { dialogActions } from '../../dialog/slice/dialog-slice';
import { HowToOrder, ImgType } from '../../dialog/unique/how-to-order';
import { createDebugList } from '../../../slices/debug-slice';
import { useParams } from 'react-router-dom';
import { PathParams } from '../../../routes/routing-path';
import { LogDecorator } from '@tenryu/log-decorator';
import { orderLockActions } from '../../../slices/lock-order-slice';
import iconHelp from '../../../assets/img/icon/help_icon.svg';
import { Button } from '../../ui/button/button';

export const ItemInfoOrder = (props: { importFav: boolean }) => {
  const dispatch = useDispatch();
  const { kijshopCd, shopOrderId, orderId } = useParams<PathParams>();
  const {
    debug,
    category,
    categoryRes,
    tag,
    tagRes,
    currentOrder,
    categoryInitValue,
    xml,
  } = useAppSelector((state) => ({
    debug: state.debug.debug,
    category: state.order.itemInfo.category,
    categoryRes: state.orderData.itemInfo.category.service,
    tag: state.order.itemInfo.tag,
    tagRes: state.orderData.itemInfo.tag.productTag,
    currentOrder: state.orderPreparation.currentOrder,
    categoryInitValue: state.storage.data.category,
    xml: state.xml[shopOrderId],
  }), lodash.isEqual);
  const { importFav } = props;
  // - Callback -
  // -- カテゴリ --
  const handlerChangeCategory = useCallback((v, label) => {
    dispatch(ordersActions.itemInfo.setCategory(v, label, Boolean(xml?.info.metaModel?.dpNumber)));
  }, []);
  // -- 絞り込み条件（タグ） --
  const handlerChangeTag = useCallback((v, label) => {
    dispatch(ordersActions.itemInfo.setTag(v, label, Boolean(xml?.info.metaModel?.dpNumber)));
  }, []);
  // -- ヘルプボタン --
  const handlerClickHelp = useCallback((type: ImgType) => {
    dispatch(dialogActions.push({
      title: '説明',
      element: <HowToOrder imgType={type}/>,
      closeBtn: true,
    }));
  }, []);
  // - Effect -
  useEffect(() => {
    dispatch(apiActions.run(new ApiCategories({}), {
      onSuccess: (res: ResponseBase<CategoriesResponse>) => {
        dispatch(ordersDataActions.updateItemInfo({
          category: res.body.data,
        }));
        if (!category.value && categoryInitValue && !currentOrder && !orderId) {
          dispatch(ordersActions.itemInfo.setCategory(categoryInitValue, res.body.data?.service.find((v) => v.serviceId === categoryInitValue)?.serviceName, Boolean(xml?.info.metaModel?.dpNumber), true));
        }
      },
    }));
  }, []);
  // -- リスト値精査 --
  useEffect(() => {
    if (tag.value && tagRes.length && !tagRes.find((v) => v.productTagId === tag.value) && !orderId) {
      dispatch(ordersActions.itemInfo.setTag('', '', Boolean(xml?.info.metaModel?.dpNumber)));
    }
    if (tag.value && !tagRes.length && !orderId) {
      dispatch(ordersActions.itemInfo.setCategory(category.value, category.label, Boolean(xml?.info.metaModel?.dpNumber)));
    }
  }, [tagRes]);
  useEffect(() => {
    return () => {
      dispatch(ordersDataActions.updateItemInfo({
        tag: { productTag: [] },
      }));
    };
  }, []);
  // - リストデータ -
  // デバッグ用
  const categoryList = [...categoryRes].map((v) => ({ value: v.serviceId, label: v.serviceName }));
  const tagList = [...tagRes].map((v) => ({ value: v.productTagId, label: v.productTagId }));
  if (debug) {
    // categoryList.sort((a, b) => a.value > b.value ? 1 : -1);
    createDebugList(categoryList);
    // console.group('商品情報');
    LogDecorator.group(`<magenta>商品情報</magenta>`);
    console.group('絞り込み条件');
    console.log('データ: ', tagRes);
    console.groupEnd();
    console.groupEnd();
  }
  return (
    <div className="order_category">
      <div className="bottom_border_header order_category__header">
        商品情報
        <Button
          className="order_help order_category__header__tooltip"
          icon={<img src={iconHelp} alt='' />}
          onClick={() => handlerClickHelp('title-category')}
        />
      </div>
      <div className="top_label_ui order_category__item">
        <div className="top_label_ui__label">
          カテゴリ
        </div>
        <div className="top_label_ui__form">
          <Select
            value={category.value}
            list={categoryList}
            onChange={(e, label) => handlerChangeCategory(e.target.value, label)}
            className="top_label_ui__ui"
            disabled={Boolean(currentOrder) || Boolean(orderId) || importFav}
            // addEmpty={!category.value || category.value === ''}
          />
          <Button
            className="order_help"
            icon={<img src={iconHelp} alt='' />}
            onClick={() => handlerClickHelp('category-category')}
          />
        </div>
      </div>

      {tagRes.length ? (
        <div className="top_label_ui order_category__item">
          <div className="top_label_ui__label">
            絞り込み条件
          </div>
          <div className="top_label_ui__form">
            <Select
              value={tag?.value}
              list={tagList}
              onChange={(e, label) => handlerChangeTag(e.target.value, label)}
              className="top_label_ui__ui"
              disabled={Boolean(currentOrder) || Boolean(orderId) || importFav}
              addEmpty={!tag || tag.value === ''}
            />
            <Button
              className="order_help"
              icon={<img src={iconHelp} alt='' />}
              onClick={() => handlerClickHelp('category-tag')}
            />
          </div>
        </div>
      ) : (<></>)}
    </div>
  );
};
