import { OptionPartsRequest, OptionPartsResponse } from '../../../../models/api/back/create-order/option-parts';
import {AppThunk} from "../../../../app/store";
import {ordersDataActions} from "../../../../slices/orders/orders-data-slice";
import {apiActions} from "../../../../slices/api-slice";
import {ResponseBase} from "../../../response-base";
import {ApiBaseBack} from "../../api-base-back";

export class ApiOptionParts extends ApiBaseBack<OptionPartsResponse> {
  constructor(param: OptionPartsRequest) {
    super(
      'GET',
      '/api/v1/optionParts',
      'JSON',
      param,
    );
  }
}

export class ApiOptionParts1 extends ApiBaseBack<OptionPartsResponse> {
  constructor(param: OptionPartsRequest) {
    super(
      'GET',
      '/api/v1/optionParts1',
      'JSON',
      param,
    );
  }
}

export class ApiOptionParts2 extends ApiBaseBack<OptionPartsResponse> {
  constructor(param: OptionPartsRequest) {
    super(
      'GET',
      '/api/v1/optionParts2',
      'JSON',
      param,
    );
  }
}

export class ApiOptionParts3 extends ApiBaseBack<OptionPartsResponse> {
  constructor(param: OptionPartsRequest) {
    super(
      'GET',
      '/api/v1/optionParts3',
      'JSON',
      param,
    );
  }
}

export const apiOptionPartsThunk = (param: OptionPartsRequest, callback?: () => void): AppThunk => async (dispatch, getState) => {
  if (!param.productNameId) {
    dispatch(ordersDataActions.updateOption({
      optionParts: { items: [] },
    }));
    callback?.();
    return;
  }
  dispatch(apiActions.run(
    new ApiOptionParts1(param),
    {
      onSuccess: (res: ResponseBase<OptionPartsResponse>) => {
        callback?.();
        dispatch(ordersDataActions.updateOption({
          optionParts: { items: res.body.data?.items || [] },
        }));
        },
      onError: () => {
        dispatch(ordersDataActions.updateOption({
          optionParts: { items: [] },
        }));
        callback?.();
      },
    },
    {
      apiName: 'option-parts',
      ignoreSystemError: true,
    },
  ))
};
