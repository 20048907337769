import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import iconReturn from '../../../assets/img/icon/return.svg';
import { apiActions } from '../../../slices/api-slice';
import { newsListActions } from '../../../slices/news-list-slice';
import './news-list.scss';
import {ApiBase} from "../../../api/api-base";
import { useAppSelector } from '../../../app/hooks';
import lodash from 'lodash';

export const NewsList = () => {
  const dispatch = useDispatch();
  const {isSamePath} = useAppSelector((state) => ({
    isSamePath: state.newsList.isSamePath,
  }), lodash.isEqual);
  // - State -
  // -- お知らせ一覧情報 --
  const [newsList, setNewsList] = useState<string[]>([]);
  // - Callback -
  // -- 注文一覧に戻る --
  const handlerClickBackOrderList = useCallback(() => {
    dispatch(newsListActions.close());
  }, []);
  // - Effect -
  // -- 一覧データ取得 --
  useEffect(() => {
    setNewsList([]);
    dispatch(apiActions.onGetXml(
      'news',
      {
        onSuccess: (res: any) => {
          setNewsList(res.body.data.split('\n'));
          // ローカルに保存
          localStorage.setItem('news', res.body.data.slice(0, 50));
        },
      },
    ));
    if (isSamePath) {
      dispatch(newsListActions.setIsSamePath(false));
    }
  }, [isSamePath]);

  return (
    <div className="news_list">
      <div className="news_list__return" >
        <div className="news_list__btn" onClick={handlerClickBackOrderList}>
        <img src={iconReturn} alt="" />
        <div>注文一覧に戻る</div>
        </div>
      </div>
      <div className="news_list__header">
        お知らせ
      </div>
      <div className="news_list__body">
        {newsList.map((v, i) => (
          <div key={`news-list_${i}`} className="news_list__line">
            {v}
          </div>
        ))}
      </div>
    </div>
  );
};
