import lodash from 'lodash';
import { ILayoutEditorManagerBase } from '../layout-editor.manager';
import { UiManager } from '../ui/ui.manager';

export class FontManager implements ILayoutEditorManagerBase {

  private static _ins: FontManager;
  private fontList: string[] = [];
  private uiManager!: UiManager;

  private constructor() {
  }

  static get ins() {
    if (FontManager._ins) {
      return FontManager._ins;
    }
    FontManager._ins = new FontManager();
    return FontManager._ins;
  }

  di(uiManager: UiManager): void {
    this.uiManager = uiManager;
  }

  initialize() {
    this.uiManager.on('l->r:font:check-support', (e) => {
      e.callback({
        supported: this.checkSupported(),
      });
    });
    this.uiManager.on('l->r:font:get-list', (e) => {
      this.getFontList()
        .then((fonts) => {
          e.callback({
            fonts,
          });
        });
    });
  }

  destroy(): void {
    this.uiManager.off('l->r:font:check-support');
    this.uiManager.off('l->r:font:get-list');
  }

  async getFontList(): Promise<string[]> {
    if (this.fontList.length) {
      return this.fontList;
    }
    const supported = this.checkSupported();
    // - check support -
    if (!supported) {
      throw new Error('ローカルフォント読み込み拡張がインストールされていません。');
    }
    // - get font list -
    const fontListDiv = document.getElementById('local-font-list') as HTMLDivElement;
    const list = JSON.parse(fontListDiv.innerHTML) as string[];
    return Promise.resolve(list);
  }

  checkSupported() {
    const fontListDiv = document.getElementById('local-font-list');
    const bool = Boolean(fontListDiv);
    return bool;
  }

}
