
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import * as Orders from './orders/orders';
import * as lodash from 'lodash';
import { AppThunk } from '../app/store';
import { apiTagsThunk } from '../api/back/create-order/tags/api-tags';
import { apiCoversThunk } from '../api/back/create-order/covers/api-covers';
import { apiItemsThunk } from '../api/back/create-order/items/api-items';
import { apiCoverColorThunk } from '../api/back/create-order/cover-colors/api-cover-colors';
import { apiFoilStampingsCoverColorThunk } from '../api/back/create-order/foil-stampings/api-foil-stampings';
import { apiOrderMethodsThunk } from '../api/back/create-order/order-methods/api-order-methods';
import { apiPartsThunk } from '../api/back/create-order/parts/api-parts';
import { apiOptionPartsThunk } from '../api/back/create-order/option-parts/api-option-parts';
import { apiOptionSetInfoThunk } from '../api/back/create-order/option-set-info/api-option-set-info';
import { apiFiltersThunk } from '../api/back/create-order/filters/api-filters';
import { apiPageCountThunk } from '../api/back/create-order/page-count/api-page-count';
import { apiBoardInfosThunk } from '../api/back/create-order/board-info/api-board-infos';
import { apiBoardOptionInfosThunk } from '../api/back/create-order/board-option-info/api-board-option-infos';
import { apiDesignType1Thunk } from '../api/back/create-order/design-type1/api-design-type1';
import { apiDesignType2Thunk } from '../api/back/create-order/design-type2/api-design-type2';
import { apiEndPaperThunk } from '../api/back/create-order/endpaper/api-endpaper';
import { apiChronologiesThunk } from '../api/back/create-order/chronologies/api-chronologies';
import { ApiProductDetail, apiProductDetailThunk } from '../api/back/create-order/product-detail/api-product-detail';
import { apiOptionTagThunk } from '../api/back/create-order/option-tag/api-option-tag';
import { apiOptionItemThunk } from '../api/back/create-order/option-item/api-option-item';
import { apiDescriptionInfoThunk } from '../api/front/description-info/api-description-info';
import { ordersDataActions, OrdersDataState } from './orders/orders-data-slice';
import { apiPageDataTypesThunk } from '../api/back/create-order/page-data-types/api-page-data-types';
import { createOrderActions } from './create-order-slice';
import { apiProcessInfoThunk } from '../api/back/create-order/process-info/api-process-info';
import {
  apiOptionProductGroupRulesThunk
} from '../api/back/create-order/option-product-group-rules/api-option-product-group-rules';
import { VisibleConditionValueGetter } from '../manager/visible-condition-value-getter';
import { PageDataTypesResponseEntity } from '../models/api/back/create-order/page-data-types';
import { apiFoilStampingsCoverThunk } from '../api/back/create-order/foil-stampings/api-foil-stampings';
import { apiFoilStampingsGenuineThunk } from '../api/back/create-order/foil-stampings/api-foil-stampings';

// 商品情報
export type OrdersItemInfo = {
  category: { value: string, label: string },
  tag: { value: string, label: string },
};

// 商品詳細
export type OrdersItemDetail = {
  // 表紙選択
  coverSelect: { value: string, label: string },
  // 表紙色選択
  coverColorSelect: { value: string, label: string },
  // 商品選択
  itemSelect: { value: string, label: string },
  // 部材選択
  boardSelect: { value: string, label: string },
  // 注文方法
  orderMethod: { value: string, label: string },
  // デザインタイプ1
  designType1: { value: string, label: string },
  // デザインタイプ2
  designType2: { value: string, label: string },
  // 数量
  quantity: number,
};

// 商品記載情報
export type OrdersDescriptionInfo = {
  // 名前
  name?: string,
  // 日付
  date?: { value?: string | undefined, alert: boolean },
  // 日付の形式（文字種類）
  formatType: string,
  // 日付の形式（大文字/先頭のみ大文字）
  formatCase: string,
  // ふりがな
  // dateKana: string,
  // 撮影場所
  photographLocation?: string,
  // コメント
  // comment: string,
  // 住所
  // address: string,
  // 記載場所
  descriptionLocations?: string[],
};

// 表紙
export type OrdersCover = {
  // ラミ選択
  laminate: { value: string, label: string },
  // 色
  color: { value: string, label: string },
  // 表紙加工
  coverProcessing: { value: string, label: string },
  // 箔押し文字1
  leafText1: string,
  // 箔押し文字2
  leafText2: string,
};

// 本身
export type OrdersGenuine = {
  // ラミ選択
  laminate: { value: string, label: string },
  // 本身加工
  genuineProcessing: { value: string, label: string },
  // 箔押し文字1
  leafText1: string,
  // 箔押し文字2
  leafText2: string,
  // 見返し選択
  additional: { value: string, label: string },
  // 付加情報
  additionalMulti: string,
  // 年表
  chronologies: { value: string, label: string },
  // ページ数
  page: number,
};

// 使用画像
export type OrdersUseImage = {
  // 使用画像
  image: File[],
};

// オプション
export type OrdersOption = {
  // 商品タグ
  tag: { value: string, label: string },
  // 商品選択
  item: { value: string, label: string, serviceId: string, serviceName: string },
  // 部材選択
  board: { value: string, label: string },
  // 数量
  quantity: number,
  // 表紙
  cover: {
    // ラミ選択
    laminate: { value: string, label: string },
  },
  // 本身
  genuine: {
    // ラミ選択
    laminate: { value: string, label: string },
  },
  // 商品記載情報
  descriptionLocations: string[],
  // レイアウト完了フラグ
  layoutFinish: string,
  // パーツ数
  partsCount: string,
  // 編集フラグ
  edit: boolean,
};

// 備考
type OrdersRemarks = {
  // 備考
  remarks: string,
};

// メール記載情報
type OrderMailRemarks = {
  // メール記載情報
  mailRemarks: string,
};

// 注文情報
type OrderInfo = {
  // 写真アルバムプロフラグ
  proFlg: boolean,
  // 画像枚数
  imageNum: {
    min: number,
    max: number,
  },
};

export type OrdersSliceState = {
  // 商品情報
  itemInfo: OrdersItemInfo,
  // 商品詳細
  itemDetail: OrdersItemDetail,
  // 商品記載情報
  descriptionInfo: OrdersDescriptionInfo,
  // 表紙
  cover: OrdersCover,
  // 本身
  genuine: OrdersGenuine,
  // 使用画像
  useImage: OrdersUseImage,
  // オプション
  option: OrdersOption[],
  // 備考
  remarks: OrdersRemarks,
  // メール記載情報
  mailRemarks: OrderMailRemarks,
  // 注文情報（表示項目に直接関わらないもの）
  orderInfo: OrderInfo,
};

export type PartialOrderSliceState = {
  itemInfo?: Partial<OrdersItemInfo>,
  itemDetail?: Partial<OrdersItemDetail>,
  descriptionInfo?: Partial<OrdersDescriptionInfo>,
  cover?: Partial<OrdersCover>,
  genuine?: Partial<OrdersGenuine>,
  useImage?: Partial<OrdersUseImage>,
  option?: OrdersOption[],
  remarks?: Partial<OrdersRemarks>,
  mailRemarks?: Partial<OrderMailRemarks>,
  orderInfo?: Partial<OrderInfo>,
};

const initialState: OrdersSliceState = {
  itemInfo: {
    category: { value: '', label: '' },
    tag: { value: '', label: '' },
  },
  itemDetail: {
    coverSelect: { value: '', label: '' },
    coverColorSelect: { value: '', label: '' },
    itemSelect: { value: '', label: '' },
    boardSelect: { value: '', label: '' },
    orderMethod: { value: '', label: '' },
    designType1: { value: '', label: '' },
    designType2: { value: '', label: '' },
    quantity: 1,
  },
  descriptionInfo: {
    name: '',
    date: { value: undefined, alert: false },
    formatType: '',
    formatCase: '',
    photographLocation: '',
    descriptionLocations: [],
  },
  cover: {
    laminate: { value: '', label: '' },
    color: { value: '', label: '' },
    coverProcessing: { value: '', label: '' },
    leafText1: '',
    leafText2: '',
  },
  genuine: {
    laminate: { value: '', label: '' },
    genuineProcessing: { value: '', label: '' },
    leafText1: '',
    leafText2: '',
    additional: { value: '', label: '' },
    additionalMulti: '',
    chronologies: { value: '', label: '' },
    page: 0,
  },
  useImage: {
    image: [],
  },
  // option: {
  //   tags: '',
  //   item: '',
  //   quantity: 0,
  //   cover: {
  //     laminate: '',
  //   },
  // },
  option: [],
  remarks: {
    remarks: '',
  },
  mailRemarks: {
    mailRemarks: '',
  },
  orderInfo: {
    proFlg: false,
    imageNum: {
      min: 0,
      max: 0,
    }
  },
};

type RequestParam = {
  // カテゴリ
  serviceId: string,
  // タグ
  productTagId: string,
  // 商品
  productNameId: string,
  // 注文方法
  orderMethod: string,
  // カバー
  coverTypeId: string,
  // デザインタイプ1
  hpsTppathNamePath: string,
  // デザインタイプ2
  dtemplId: string,
  // 部材（親）
  boardInfoId: string,
  // 表紙色
  coverColorId: string,
  // コンテントフラグ(1: 商品, 2: テンプレート) 使用箇所で判定が必要なら消す
  productFlg: '1' | '2',
  // parentFlg
  parentFlg: '1' | '2',
};

// orders: 項目値
// data  : APIデータ
// data がある場合は画面からの更新とみなし非表示項目はリクエストに含めない
// data がない場合は編集状態に遷移したタイミングで非表示の項目もリクエストに含める
const requestParamConverter = (orders: PartialOrderSliceState, visibleCondition?: VisibleConditionValueGetter, data?: OrdersDataState, require?: boolean, isInit?: boolean) => ({
  // カテゴリ
  serviceId: orders?.itemInfo?.category?.value || (require ? '' : undefined),
  // タグ
  productTagId: ((!data || (!isInit && data.itemInfo.tag.productTag.length)) && orders?.itemInfo?.tag?.value) || (require ? '' : undefined),
  // 商品
  productNameId: ((!data || (!isInit && visibleCondition?.itemDetailOrderDisplay.item)) && orders?.itemDetail?.itemSelect?.value) || (require ? '' : undefined),
  // 注文方法
  orderMethod: ((!data || (!isInit && visibleCondition?.itemDetailOrderDisplay.orderMethod)) && orders?.itemDetail?.orderMethod?.value) || (require ? '' : undefined),
  // カバー
  coverTypeId: ((!data || (!isInit && visibleCondition?.itemDetailOrderDisplay.cover)) && orders?.itemDetail?.coverSelect?.value) || (require ? '' : undefined),
  // デザインタイプ1
  hpsTppathNamePath: ((!data || (!isInit && visibleCondition?.itemDetailOrderDisplay.designType1)) && orders?.itemDetail?.designType1?.value) || (require ? '' : undefined),
  // デザインタイプ2
  dtemplId: ((!data || (!isInit && visibleCondition?.itemDetailOrderDisplay.designType2)) && orders?.itemDetail?.designType2?.value) || (require ? '' : undefined),
  // 部材（親）
  boardInfoId: ((!data || (!isInit && visibleCondition?.itemDetailOrderDisplay.board)) && orders?.itemDetail?.boardSelect?.value) || (require ? '' : undefined),
  // 表紙色
  coverColorId: ((!data || (!isInit && visibleCondition?.itemDetailOrderDisplay.coverColor)) && orders?.itemDetail?.coverColorSelect?.value) || (require ? '' : undefined),
  // コンテントフラグ(1: 商品, 2: テンプレート) 使用箇所で判定が必要なら消す
  productFlg: '1',
  // parentFlg
  parentFlg: '1',
});

export const orderRequest = (orders: PartialOrderSliceState, visibleCondition: VisibleConditionValueGetter, data?: OrdersDataState, isInit?: boolean) => requestParamConverter(orders, visibleCondition, data, true, isInit) as RequestParam;
export const orderRequestOptional = (orders: PartialOrderSliceState, visibleCondition: VisibleConditionValueGetter, data?: OrdersDataState, isInit?: boolean) => requestParamConverter(orders, visibleCondition, data, false, isInit) as Partial<RequestParam>;

const assign:<T>(target: T, source: Partial<T>) => T = (target, source) => Object.assign<typeof target, typeof source>(target, source);

/*
 * 各項目更新時のアクションは該当ファイルに記載
 * state 更新はファイルごとに分離したオブジェクト単位で行う
 * state(オブジェクト)更新のアクションは orders の reducers に設置
 * 項目名の重複があったりするので項目名称のみのアクション命名は禁止
 */
export const ordersSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    updateItemInfo: (state, action: PayloadAction<Partial<OrdersItemInfo>>) => {
      state.itemInfo = assign<OrdersItemInfo>(state.itemInfo, action.payload);
    },
    updateItemDetail: (state, action: PayloadAction<Partial<OrdersItemDetail>>) => {
      state.itemDetail = assign<OrdersItemDetail>(state.itemDetail, action.payload);
    },
    updateDescriptionInfo: (state, action: PayloadAction<Partial<OrdersDescriptionInfo>>) => {
      state.descriptionInfo = assign<OrdersDescriptionInfo>(state.descriptionInfo, action.payload);
    },
    updateCover: (state, action: PayloadAction<Partial<OrdersCover>>) => {
      state.cover = assign<OrdersCover>(state.cover, action.payload);
    },
    updateGenuine: (state, action: PayloadAction<Partial<OrdersGenuine>>) => {
      state.genuine = assign<OrdersGenuine>(state.genuine, action.payload);
    },
    updateUseImage: (state, action: PayloadAction<Partial<OrdersUseImage>>) => {
      state.useImage = assign<OrdersUseImage>(state.useImage, action.payload);
    },
    updateOption: (state, action: PayloadAction<{ index: number, data: Partial<OrdersOption> }>) => {
      const arr = [...state.option];
      arr[action.payload.index] = assign<OrdersOption>(arr[action.payload.index] || {}, action.payload.data);
      state.option = arr;
      // state.option = Object.assign(state.option, action.payload);
    },
    clearOption: (state) => {
      const arr = [...state.option].map((v) => ({}));
      state.option = arr as OrdersOption[];
    },
    deleteOption: (state, action: PayloadAction<number>) => {
      const arr = [...state.option];
      if (arr[action.payload]) {
        arr.splice(action.payload, 1);
      }
      if (arr.length) {
        state.option = arr;
      } else {
        state.option = [{
          tag: { value: '', label: '' },
          item: { value: '', label: '', serviceId: '', serviceName: '' },
          board: { value: '', label: '' },
          quantity: 1,
          cover: {
            laminate: { value: '', label: '' },
          },
          genuine: {
            laminate: { value: '', label: '' },
          },
          descriptionLocations: [],
          layoutFinish: '',
          partsCount: '',
          edit: false,
        }];
      }
    },
    setRemarks: (state, action: PayloadAction<string>) => {
      state.remarks.remarks = action.payload;
    },
    setMailRemarks: (state, action: PayloadAction<string>) => {
      state.mailRemarks.mailRemarks = action.payload;
    },
    updateOrderInfo: (state, action: PayloadAction<Partial<OrderInfo>>) => {
      state.orderInfo = assign<OrderInfo>(state.orderInfo, action.payload);
    },
    setParam: (state, action: PayloadAction<PartialOrderSliceState>) => {
      const {
        itemInfo,
        itemDetail,
        descriptionInfo,
        cover,
        genuine,
        useImage,
        remarks,
        orderInfo,
        mailRemarks,
      } = initialState;
      const clone = lodash.cloneDeep;
      state.itemInfo = assign<OrdersItemInfo>(clone(itemInfo), action.payload.itemInfo || {});
      state.itemDetail = assign<OrdersItemDetail>(clone(itemDetail), action.payload.itemDetail || {});
      state.descriptionInfo = assign<OrdersDescriptionInfo>(clone(descriptionInfo), action.payload.descriptionInfo || {});
      state.cover = assign<OrdersCover>(clone(cover), action.payload.cover || {});
      state.genuine = assign<OrdersGenuine>(clone(genuine), action.payload.genuine || {});
      state.useImage = assign<OrdersUseImage>(clone(useImage), action.payload.useImage || {});
      state.option = [...(action.payload.option || [])];
      state.remarks = assign<OrdersRemarks>(clone(remarks), action.payload.remarks || {});
      state.mailRemarks = assign<OrderMailRemarks>(clone(mailRemarks), action.payload.mailRemarks || {});
      state.orderInfo = assign<OrderInfo>(clone(orderInfo), action.payload.orderInfo || {});
    },
  },
});

/* 商品ID と紐付けてデータを削除する必要が出たのでオプションを削除する際はアクション呼び出しようにこのアクションを経由 */
const deleteOptionThunk = (index: number): AppThunk => async (dispatch, getState) => {
  const itemId = getState().order.option[index]?.item?.value;
  if (itemId) {
    dispatch(ordersDataActions.removeOptionData(itemId));
  }
  dispatch(ordersActions.deleteOption(index));
};

// const runAllApi = (param: { orders: PartialOrderSliceState, data?: OrdersDataState, kijshopCd: string }): AppThunk => async (dispatch) => {
//   const { kijshopCd } = param;
//   const request = orderRequest(param.orders, param.data);
//   const requestOptional = orderRequestOptional(param.orders, param.data);

const runAllApi = (param: { orders: PartialOrderSliceState, data?: OrdersDataState }, kijshopCd: string, agent: boolean, isFav?: boolean): AppThunk => async (dispatch, getState) => {
  dispatch(createOrderActions.setLoading(true));
  const visibleCondition = getState().createOrder.visibleCondition;
  visibleCondition.getCreateOrderDisplay(param.orders, param.data);
  const request = orderRequestOptional(param.orders, visibleCondition, param.data, true);
  const masterProductItemInfo = getState().common.data.masterProductItemInfo;
  const callbackFunc = (func: (callback: () => void) => void) => new Promise<void>((resolve) => {
    func(resolve);
  });
  const arr: Promise<void>[] = [];
  // - カテゴリ -
  // -- タグ --
  arr.push(callbackFunc((callback: () => void) => {
    dispatch(apiTagsThunk(
      {
        serviceId: request.serviceId || '',
      },
      callback,
      true,
      agent,
    ));
  }));
  // - タグ -
  // -- カバー --
  arr.push(callbackFunc((callback: () => void) => {
    dispatch(apiCoversThunk(
      {
        serviceId: request.serviceId || '',
        productTagId: request.productTagId,
      },
      callback,
      true,
      agent,
    ));
  }));
  // - 表紙 -
  // -- カバー色 --
  arr.push(callbackFunc((callback: () => void) => {
    dispatch(apiCoverColorThunk(
      {
        serviceId: request.serviceId || '',
        coverTypeId: request.coverTypeId,
      },
      callback,
      true,
      request.coverColorId || '',
      agent,
    ));
  }));
  // - 表紙色 -
  // -- 商品 --
  arr.push(callbackFunc((callback: () => void) => {
    dispatch(apiItemsThunk(
      {
        serviceId: request.serviceId || '',
        productTagId: request.productTagId,
        coverTypeId: request.coverTypeId,
        coverColorId: param.orders.orderInfo?.proFlg ? request.coverColorId : undefined,
      },
      callback,
      true,
    ));
  }));
  // - 商品選択 -
  // -- 注文方法取得 --
  arr.push(callbackFunc((callback: () => void) => {
    dispatch(apiOrderMethodsThunk(
      {
        productNameId: request.productNameId || '',
      },
      callback,
      true,
      agent,
    ));
  }));
  // -- パーツ --
  // arr.push(callbackFunc((callback: () => void) => {
  //   dispatch(apiPartsThunk(
  //     {
  //       productNameId: request.productNameId,
  //     },
  //     callback,
  //   ));
  // }));
  // NOTE: なぜかcoverIdを渡していなかったので渡すように変更。問題ないか要注意。
  arr.push(callbackFunc((callback: () => void) => {
    dispatch(apiPartsThunk(
      {
        productNameId: request.productNameId || '',
      },
      callback,
      request.coverTypeId,
      // true,
    ));
  }));
  arr.push(callbackFunc((callback: () => void) => {
    dispatch(apiPageDataTypesThunk(
      {
        serviceId: request.serviceId || '',
        productNameId: request.productNameId || '',
        parentFlg:'1'
      },
      callback,
      true,
    ));
  }));
  // -- オプションパーツ --
  arr.push(callbackFunc((callback: () => void) => {
    dispatch(apiOptionPartsThunk(
      {
        productNameId: request.productNameId || '',
      },
      callback,
      // true,
    ));
  }));
  // -- セット情報 --
  arr.push(callbackFunc((callback: () => void) => {
    dispatch(apiOptionSetInfoThunk(
      {
        productNameId: request.productNameId || '',
      },
      callback,
      // true,
    ));
  }));
  // -- フィルター --
  arr.push(callbackFunc((callback: () => void) => {
    dispatch(apiFiltersThunk(
      {
        productNameId: request.productNameId || '',
      },
      callback,
      true,
    ));
  }));
  // -- 部材（親） --
  arr.push(callbackFunc((callback: () => void) => {
    dispatch(apiBoardInfosThunk(
      {
        productNameId: request.productNameId || '',
      },
      callback,
      true,
    ));
  }));
  // -- 部材（子） --
  arr.push(callbackFunc((callback: () => void) => {
    dispatch(apiBoardOptionInfosThunk(
      {
        productNameId: request.productNameId || '',
      },
      callback,
      true,
      agent,
    ));
  }));
  // -- 見返し --
  arr.push(callbackFunc((callback: () => void) => {
    dispatch(apiEndPaperThunk(
      {
        productNameId: request.productNameId || '',
        parentFlg: request.parentFlg || '1',
      },
      callback,
      true,
    ));
  }));
  // -- 年表選択 --
  arr.push(callbackFunc((callback: () => void) => {
    dispatch(apiChronologiesThunk(
      {
        productNameId: request.productNameId || '',
      },
      callback,
      true,
    ));
  }));
  // -- 箔押し --
  // arr.push(callbackFunc((callback: () => void) => {
  //   dispatch(apiFoilStampingsThunk(
  //     {
  //       // serviceId: request.serviceId,
  //       productNameId: request.productNameId,
  //       // coverColorId: request.coverColorId,
  //       // productTagId: requestOptional.productTagId,
  //       // coverTypeId: requestOptional.coverTypeId,
  //     },
  //     callback,
  //   ));
  // }));
  // -- 商材取得 --
  arr.push(callbackFunc((_callback: () => void) => {
    const callback = () => {
      const { order, orderData } = getState();
      dispatch(apiOptionItemThunk(
        {
          productNameId: request.productNameId || '',
          coverColorId: request.coverColorId,
          coverId: orderData.data.productDetail.coverTypeId,
          pageCountId1: !orderData.option.optionPageCount || order.itemDetail.orderMethod.value !== '10' ? String(order.genuine.page) : undefined,
          pageCountId2: orderData.option.optionPageCount && order.itemDetail.designType2.value ? String(order.genuine.page) : undefined,
          orderMethodId: request.orderMethod,
          agentMode: agent ? 'true' : undefined,
        },
        _callback,
        true,
      ));
      // - 色 -
      // -- 表紙加工(カバー) --
      arr.push(callbackFunc((callback: () => void) => {
        dispatch(apiFoilStampingsCoverThunk(
          {
            // serviceId: request.serviceId,
            productNameId: request.productNameId || '',
            itemId: orderData.cover.parts.itemId,
            // coverColorId: request.coverColorId,
          },
          callback,
          true,
        ));
      }));
      // -- 加工情報(本身) --
      arr.push(callbackFunc((callback: () => void) => {
        dispatch(apiFoilStampingsGenuineThunk(
          {
            // serviceId: request.serviceId,
            productNameId: request.productNameId || '',
            itemId: orderData.genuine.parts.itemId,
            // coverColorId: request.coverColorId,
          },
          callback,
          true,
        ));
      }));
      // _callback();
    };
    dispatch(apiProductDetailThunk(
      {
        productNameId: request.productNameId || '',
        serviceId: request.serviceId || '',
        parentFlg: '1',
      },
      callback,
      true,
      agent,
    ));
  }));
  // -- オプション 商品 --
  // arr.push(callbackFunc((callback: () => void) => {
  //   dispatch(apiOptionItemThunk(
  //     {
  //       productNameId: request.productNameId,
  //     },
  //     callback,
  //   ));
  // }));
  // -- 商品記載情報 --
  arr.push(callbackFunc((callback: () => void) => {
    dispatch(apiDescriptionInfoThunk(
      {
        serviceId: request.serviceId || '',
        productNameId: request.productNameId || '',
        kijshopCd: kijshopCd,
      },
      masterProductItemInfo,
      callback,
    ));
  }));
  // -- オプション商品ルール --
  arr.push(callbackFunc((callback: () => void) => {
    dispatch(apiOptionProductGroupRulesThunk(
      {
        productNameId: request.productNameId || '',
      },
      callback,
    ));
  }));
  // - 部材（親） -
  // -- デザインタイプ１ --
  arr.push(callbackFunc((callback: () => void) => {
    dispatch(apiDesignType1Thunk(
      {
        productNameId: request.productNameId || '',
        boardInfoId: request.boardInfoId,
      },
      callback,
      true,
    ));
  }));
  // - 注文方法 -
  // -- オプション 商品 --
  // arr.push(callbackFunc((callback: () => void) => {
  //   dispatch(apiOptionItemThunk(
  //     {
  //       productNameId: request.productNameId,
  //       coverColorId: request.coverColorId,
  //       coverId: request.coverTypeId,
  //       // coverId: requestOptional.coverColorId,
  //       // coverId: data.data.productDetail.coverTypeId || undefined, TODO 別APIのレスポンスを設定
  //       orderMethodId: request.orderMethod,
  //     },
  //     callback,
  //     true,
  //   ));
  // }));
  // -- オプション タグ --
  arr.push(callbackFunc((callback: () => void) => {
    dispatch(apiOptionTagThunk(
      {
        productNameId: request.productNameId || '',
        orderMethodId: request.orderMethod || '',
      },
      callback,
      true,
    ));
  }));
  // - デザインタイプ1 -
  // -- デザインタイプ2 --
  arr.push(callbackFunc((callback: () => void) => {
    dispatch(apiDesignType2Thunk(
      {
        productNameId: request.productNameId || '',
        hpsTppathNamePath: request.hpsTppathNamePath || '',
        boardInfoId: request.boardInfoId,
      },
      callback,
      true,
    ));
  }));
  // - デザインタイプ2 -
  // -- ページ数 --
  arr.push(callbackFunc((callback: () => void) => {
    dispatch(apiPageCountThunk(
      {
        productNameId: request.productNameId || '',
        productFlg: '1',
        dtemplId: request.dtemplId,
      },
      callback,
      true,
      agent,
    ));
  }));
  // -- 工程情報 --
  arr.push(callbackFunc((callback: () => void) => {
    dispatch(apiProcessInfoThunk(
      {
        orderMethodId: request.orderMethod || '',
      },
      callback,
      true,
    ));
  }));
  // -- 後加工情報 --
  arr.push(callbackFunc((callback: () => void) => {
    dispatch(apiFoilStampingsCoverColorThunk(
      {
        coverColorId: request.coverColorId || '',
      },
      callback,
    ));
  }));
  // 全てのAPIが呼び終わってからパラメータ設置
  Promise.all(arr)
    .then(() => {
      dispatch(createOrderActions.setLoading(false));
      /* お気に入り商品用に商品記載情報を破棄するが記載箇所はデフォルトのものを適用 */
      if (isFav) {
        const description = getState().order.descriptionInfo;
        param.orders.descriptionInfo = description;
      }
      /* 念の為 store 更新待ちの timeout */
      setTimeout(() => {
        const order = param.orders;
        const data = getState().orderData;
        /* xmlから値を取得できない項目値を設定 */
        if (order.itemDetail) {
          if (order.itemDetail.coverSelect?.value) {
            order.itemDetail.coverSelect.label = data.itemDetail.cover.coverType.find((v) => v.coverTypeId === order.itemDetail?.coverSelect?.value)?.coverTypeName || '';
          }
          if (order.itemDetail.boardSelect?.value) {
            order.itemDetail.boardSelect.label = data.itemDetail.board.boardInfo.find((v) => v.boardInfoId === order.itemDetail?.boardSelect?.value)?.boardInfoName || '';
          }
          if (order.itemDetail.designType1?.value) {
            order.itemDetail.designType1.label = data.itemDetail.designType1.hpsTppath.find((v) => v.hpsTppathNamePath === order.itemDetail?.designType1?.value)?.hpsTppathName || '';
          }
          if (order.itemDetail.designType2?.value) {
            order.itemDetail.designType2.label = data.itemDetail.designType2.hpsTppath.find((v) => v.dtemplId === order.itemDetail?.designType2?.value)?.hpsTpobjName || '';
            visibleCondition.getCreateOrderDisplay(param.orders, getState().orderData);
          }
        }
        visibleCondition.getCreateOrderDisplay(param.orders, getState().orderData);
        dispatch(ordersActions.setParam(param.orders));
      });
    });
};

export const ordersActions = {
  itemInfo: Orders.itemInfo,
  itemDetail: Orders.itemDetail,
  descriptionInfo: Orders.descriptionInfo,
  cover: Orders.cover,
  genuine: Orders.genuine,
  useImage: Orders.useImage,
  option: Orders.option,
  ...ordersSlice.actions,
  runAllApi,
  deleteOptionThunk,
};
export const ordersReducer = ordersSlice.reducer;
