import {ApiBase} from "../api-base";
import {ResponseBase} from "../response-base";
import {Header} from "../../components/ui/header/header";

export class ApiBaseBack<TResponseBody = any> extends ApiBase<ResponseBase<TResponseBody>> {

  private static _session: string = '';
  static set session(value: string) {
    this._session = value;
  }
  static get session() {
    return this._session;
  }

  private readonly appId: string = '2';

  protected get hostname(): string {
    return ApiBaseBack.hostname || '';
  }

  protected get protocol(): string {
    return ApiBaseBack.protocol || '';
  }

  get header(): { [key: string]: string | number | undefined } {
    if (ApiBaseBack._session) {
      return {
        'X-Session-Id': ApiBaseBack._session,
        'X-lnw-App-Id': this.appId,
        // 'Content-Type': this.method === 'GET' ? 'application/json' : 'multipart/form-data',
      };
    } else {
      return {
        'X-lnw-App-Id': this.appId,
      };
    }
  }

}