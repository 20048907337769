import { createSlice } from '@reduxjs/toolkit';
import { ActionType } from '../models/action-type';
import { AlbumPage } from '../layout-editor/albam/albam';
import { AddAlbumParam } from '../components/pages/layout/layout';
import { OrderPageDataXml } from '../xml/class/order/order-page-data-xml';
import { PageDataTypesResponse } from '../models/api/back/create-order/page-data-types';
import { MetaShopOrderGetResponse } from '../api/front/meta/api-meta-shop-order';
import { RestorePicData } from '../layout-editor/model/layout.xml.utile';
import { MetaOrderGetResponse } from '../api/front/meta/api-meta-order';
import { GuideType } from '../components/pages/layout/toolbar/layout.trimming-controller';

export type LayoutItemData = {
  // templateGroup: string,
  // page: string,
  id: string,
  templateName: string,
  thumbnail: string,
  templateXmlUrl: string,
  targetPath: string,
  favTempId?: string,
};

export type LayoutTemplateFolderData = {
  id: string,
  label: string,
  type: 'file' | 'directory' | 'template',
  children: LayoutTemplateFolderData[],
  pageType?: string,
};

export type OpenTemplateType = 'all' | 'favorite' | '';
export type OpenTemplateInfo = {
  type: 'all' | 'favorite' | ''
  favoriteFolderID: string;
}

export type LayoutPageType = 'select' | 'list' | 'edit';

// type AlbumType = 'cover' | 'top' | 'page' | 'end-cover';
type AlbumType = string;

export type AlertPathData = {
  pageType: string,
  pathData: string[]
}

export type AlbumData = {
  page: number,
  albums: AlbumPage[],
  pageCount: number,
  pageTypeId: string,
  displayPageNo?: string,
  displayPageType?: string,
};

type DeleteAlbumData = {
  thumbnailID: string,
  templatePath: string,
}

export type AlbumSettingData = {
  productName: string,
  name: string,
  date: string,
  formatType: string,
  initialType: string,
  location: string,
  pageTypes: string[],
  pageCount: string,
  stepPageCount: string,
  pageCountInfo: {
    step: string,
    max: string,
    min: string,
  },
};

export type PageRequestMemo = {
  page: string,
  memo: string,
};

export type TemplatesInfo = {
  name: string,
  vertical: string,
  horizon: string,
  sq: string,
  pageType: string,
};

export type AcquiredTempInfo =
  {
    // id: string,
    // tempName: string,
    itemData: LayoutItemData,
    templates: { xml: any, pageType: string },
    cover?: { min: string, max: string },
    template: any,
    thumbnail: string,
    zipLevel: {
      isZip: boolean,
      level: number,
    },
  };

export type TemplateSearchRequest = {
  sum: string,
  vertical: string,
  horizon: string,
  sq: string,
  searchTypeNum: string,
};

export type LayoutQueryParams = {
  productNameId: string,
};

export type PreviewSelectItem = {
  pageNum: string,
  album: AlbumPage,
};


export type PresetData = {
  name: string,
  windowSize: string,
  humanScale: string,
  topSpace: string,
  bottomSpace: string,
}

export type AlbumDatas = {
  cover: AlbumData,
  top: AlbumData,
  page: AlbumData,
  endCover: AlbumData,
  option: {
    name: string,
    xml: OrderPageDataXml,
    album: AlbumPage | null,
  }[],
  opPrint: {
    name: string,
    xml: OrderPageDataXml,
    album: AlbumPage | null,
  }[],
}

export type SaveSelectData = {
  selectId: string,
  editableImageId: string,
}

export type PageTypeData = {
  pageTypeID: string,
  type: string,
}

export type PageTypeListData = {
  cover: PageTypeData[],
  top: PageTypeData[],
  page: PageTypeData[],
  endCover: PageTypeData[],
  option: PageTypeData[],
  opPrint: PageTypeData[],
}

export type SortPageTypeDataEntity = {
  $: Partial<SortPageType>;
}
export type SortPageType = {
  pageTypeID: string;
  name: string;
  displayName: string;
}

type LayoutState = {
  layoutList: LayoutItemData[],
  folderList: LayoutTemplateFolderData[],
  selectFolder: LayoutTemplateFolderData,
  page: LayoutPageType,
  thumbnailPath: string,
  albumPages: AlbumDatas,
  currentAlbumPage: AlbumPage | null,
  albumSettingData: AlbumSettingData,
  pageRequestMemo: PageRequestMemo[],
  templatesInfoList: TemplatesInfo[],
  isDropTemplate: boolean,
  isDraggingTemplate: boolean,
  acquiredTemplateList: AcquiredTempInfo[],
  queryParams: LayoutQueryParams | null,
  previewSelectItem: PreviewSelectItem | null,
  presetList: PresetData[],
  preset: PresetData | null,
  templateGroupName: string,
  templatesXmlList: any[],
  deleteAlbumInfos: DeleteAlbumData[],
  openTemplateType: OpenTemplateInfo,
  isStopExifWarning: boolean,
  isStopTextPosWarning: boolean,
  isSaving: boolean,
  restoreTemplates: AddAlbumParam[],
  /* 商品記載情報記載箇所チェックボックス一覧 */
  pageDataTypesRes: PageDataTypesResponse,
  unfindFontList: string[],
  initFontList: { value: string, label: string }[],
  trimmingGuideList: GuideType[],
  metaData: MetaOrderGetResponse | null,
  restorePicData: RestorePicData[],
  alertPathList: AlertPathData[],
  saveSelect: SaveSelectData[],
  pageTypeList: PageTypeListData,
  isSelectIDError: Boolean,
  warningList: string[],
  fitErrorList: string[],
  fontErrorList: string[],
};

const initialState: LayoutState = {
  layoutList: [],
  folderList: [],
  unfindFontList: [],
  selectFolder: {
    id: '',
    label: '',
    type: 'template',
    children: [],
  },
  thumbnailPath: '',
  page: 'select',
  albumPages: {
    cover: {
      page: 0,
      albums: [],
      pageCount: 0,
      pageTypeId: '',
    },
    top: {
      page: 0,
      albums: [],
      pageCount: 0,
      pageTypeId: '',
    },
    page: {
      page: 0,
      albums: [],
      pageCount: 0,
      pageTypeId: '',
    },
    endCover: {
      page: 0,
      albums: [],
      pageCount: 0,
      pageTypeId: '',
    },
    option: [],
    opPrint: [],
  },
  currentAlbumPage: null,
  albumSettingData: {
    productName: '写真アルバムプロ A3SQ HC',
    name: '',
    date: '',
    formatType: '10',
    initialType: '0',
    location: '',
    pageTypes: [],
    pageCount: '6',
    stepPageCount: '2',
    pageCountInfo: {
      step: '2',
      max: '60',
      min: '6',
    },
  },
  pageRequestMemo: [],
  templatesInfoList: [],
  isDropTemplate: false,
  isDraggingTemplate: false,
  acquiredTemplateList: [],
  queryParams: null,
  previewSelectItem: null,
  presetList: [],
  preset: null,
  templateGroupName: '',
  templatesXmlList: [],
  deleteAlbumInfos: [],
  openTemplateType: {
    type: '',
    favoriteFolderID: '',
  },
  isStopExifWarning: false,
  isStopTextPosWarning: false,
  isSaving: false,
  restoreTemplates: [],
  pageDataTypesRes: { pageDataTypes: [] },
  initFontList: [],
  trimmingGuideList: [],
  metaData: null,
  restorePicData: [],
  alertPathList: [],
  saveSelect: [],
  pageTypeList: {
    cover: [],
    top: [],
    page: [],
    endCover: [],
    option: [],
    opPrint: [],
  },
  isSelectIDError: false,
  warningList: [],
  fitErrorList: [],
  fontErrorList: [],
};

const setTemplateList: ActionType<LayoutState, LayoutItemData[]> = (state, action) => {
  state.layoutList = action.payload;
};

const addTemplateList: ActionType<LayoutState, LayoutItemData[]> = (state, action) => {
  state.layoutList = [...state.layoutList, ...action.payload];
};

const setFolderList: ActionType<LayoutState, LayoutTemplateFolderData[]> = (state, action) => {
  state.folderList = action.payload;
};

const addFolderList: ActionType<LayoutState, LayoutTemplateFolderData[]> = (state, action) => {
  state.folderList = [...state.folderList, ...action.payload];
};

const setSelectFolder: ActionType<LayoutState, LayoutTemplateFolderData> = (state, action) => {
  state.selectFolder = action.payload;
};
const setTrimmingGuideList: ActionType<LayoutState, GuideType[]> = (state, action) => {
  state.trimmingGuideList = action.payload;
};
const pushUnFindFontList: ActionType<LayoutState, string | null> = (state, action) => {
  if (action.payload) {
    state.unfindFontList = [...state.unfindFontList, action.payload];
  } else {
    state.unfindFontList = [];
  }
};

const setPage: ActionType<LayoutState, LayoutPageType> = (state, action) => {
  if (state.page !== action.payload) {
    state.page = action.payload;
  }
};
const setInitFontList: ActionType<LayoutState, { value: string, label: string }[]> = (state, action) => {
  state.initFontList = action.payload;
};

const setAlbumData: ActionType<LayoutState, {
  cover: AlbumData,
  top: AlbumData,
  page: AlbumData,
  endCover: AlbumData,
  option: {
    name: string,
    xml: OrderPageDataXml,
    album: AlbumPage | null,
  }[],
  opPrint: {
    name: string,
    xml: OrderPageDataXml,
    album: AlbumPage | null,
  }[],
}> = (state, action) => {
  state.albumPages = action.payload;
  // {
  // cover: {
  //   page: 1,
  //   albums: [],
  // },
  // top: {
  //   page: 1,
  //   albums: [],
  // },
  // page: {
  //   page: 2,
  //   albums: [],
  // },
  // endCover: {
  //   page: 1,
  //   albums: [],
  // },
  // };
};

const setOptionName: ActionType<LayoutState, { optionType: 'option' | 'opPrint', name: string, pageType: string }> = (state, action) => {
  if (action.payload.optionType === 'option') {
    state.albumPages.option.forEach(v => {
      if (v.xml.viewModel.pageType === action.payload.pageType) {
        v.name = action.payload.name;
      }
    });
  } else {
    state.albumPages.opPrint.forEach(v => {
      if (v.xml.viewModel.pageType === action.payload.pageType) {
        v.name = action.payload.name;
      }
    });
  }
}

const setMetaData: ActionType<LayoutState, MetaOrderGetResponse> = (state, action) => {
  state.metaData = action.payload;
};

const sortAlbumPage: ActionType<LayoutState, { dragItem: AlbumPage, dropPosItem: AlbumPage }> = (state, action) => {
  const moveItem = action.payload.dragItem;
  const targetPosItem = action.payload.dropPosItem;
  const page = state.albumPages.page.albums.map((v) => ({ pageNo: v.pageNo, indexes: v.indexes }));
  if (moveItem.type.indexOf('page') !== -1 && targetPosItem.type.indexOf('page') !== -1) {
    const index = state.albumPages.page.albums.findIndex((v) => v.id === moveItem.id);
    const _i = state.albumPages.page.albums.findIndex((v) => v.id === targetPosItem.id);
    if (index !== -1 && _i !== -1) {
      state.albumPages.page.albums.splice(index, 1);
      state.albumPages.page.albums.splice(_i, 0, moveItem);
      for (let i = 0; i < page.length; i++) {
        if (state.albumPages.page.albums[i] && page[i]) {
          state.albumPages.page.albums[i].pageNo = page[i].pageNo;
          state.albumPages.page.albums[i].indexes = page[i].indexes;
        }
      }
    }
  }
};

const addAlbumPage: ActionType<LayoutState, { type: AlbumType, album: AlbumPage }> = (state, action) => {
  if (action.payload.album.isOption) {
    if (state.pageTypeList.opPrint.find(v => v.pageTypeID === action.payload.album.typeID)) {
      const find = (() => {
        for (const opPrint of state.albumPages.opPrint) {
          if (opPrint.xml.viewModel.pageType === action.payload.album.typeID) {
            if (!opPrint.album) {
              return opPrint;
            } else {
              if (opPrint.album.id === action.payload.album.id) {
                return opPrint;
              }
            }
          }
        }
      })();
      if (find) {
        action.payload.album.pageDataId = String(state.albumPages.opPrint.indexOf(find) + 1);
        find.album = action.payload.album;
      }
    } else if (state.pageTypeList.option.find(v =>  v.pageTypeID === action.payload.album.typeID)) {
      const find = state.albumPages.option.find((v) => v.xml.viewModel.pageType === action.payload.album.typeID);
      if (find) {
        action.payload.album.pageDataId = String(state.albumPages.option.indexOf(find) + 1);
        find.album = action.payload.album;
      }
    }
  } else {
    if (state.pageTypeList.cover.find(v => v.pageTypeID === action.payload.album.typeID)) {
      state.albumPages.cover.albums.push(action.payload.album);
    } else if (state.pageTypeList.top.find(v =>  v.pageTypeID === action.payload.album.typeID)) {
      state.albumPages.top.albums.push(action.payload.album);
    } else if (state.pageTypeList.page.find(v =>  v.pageTypeID === action.payload.album.typeID)) {
      state.albumPages.page.albums.push(action.payload.album);
    } else if (state.pageTypeList.endCover.find(v =>  v.pageTypeID === action.payload.album.typeID)) {
      state.albumPages.endCover.albums.push(action.payload.album);
    } else {
      state.albumPages.page.albums.push(action.payload.album);
    }
  }
};

const removeAlbumPage: ActionType<LayoutState, { type: AlbumType, id: string, typeId: string }> = (state, action) => {
  // const isOption = Boolean(state.pageTypeList.option.find(v => v.pageTypeID === action.payload.typeId))
  const arr = (() => {
    // if (action.payload.type === 'cover') {
    //   return state.albumPages.cover.albums;
    // }
    // if (action.payload.type === 'top') {
    //   return state.albumPages.top.albums;
    // }
    // if (action.payload.type === 'page') {
    //   return state.albumPages.page.albums;
    // }
    // if (action.payload.type === 'end-cover') {
    //   return state.albumPages.endCover.albums;
    // }
    if (state.albumPages.cover.albums.find(v => v.id === action.payload.id)) {
    // if (action.payload.type.indexOf('cover') !== -1) {
      return state.albumPages.cover.albums;
    } else if (state.albumPages.top.albums.find(v => v.id === action.payload.id)) {
    // } else if (action.payload.type.indexOf('top') !== -1) {
      return state.albumPages.top.albums;
    } else if (state.albumPages.page.albums.find(v => v.id === action.payload.id)) {
    // } else if (action.payload.type.indexOf('page') !== -1) {
      return state.albumPages.page.albums;
    } else if (state.albumPages.endCover.albums.find(v => v.id === action.payload.id)) {
    // } else if (action.payload.type.indexOf('end') !== -1) {
      return state.albumPages.endCover.albums;
    } else {
      return state.albumPages.page.albums;
    }
  })();
    const index = arr.findIndex((v) => v.id === action.payload.id);
    if (index !== -1) {
      arr.splice(index, 1);
    } else {
      state.albumPages.option.forEach(v => {
        if (v.album && v.album.id === action.payload.id) {
          v.album = null;
        }
      })
      state.albumPages.opPrint.forEach(v => {
        if (v.album && v.album.id === action.payload.id) {
          v.album = null;
        }
      })
    }
};

const setCurrentAlbumPage: ActionType<LayoutState, AlbumPage | null> = (state, action) => {
  state.currentAlbumPage = action.payload;
};

const setThumbnailPath: ActionType<LayoutState, string> = (state, action) => {
  state.thumbnailPath = action.payload;
};

const setAlbumSettingData: ActionType<LayoutState, AlbumSettingData> = (state, action) => {
  state.albumSettingData = action.payload;
};

const addPageRequestMemo: ActionType<LayoutState, PageRequestMemo> = (state, action) => {
  const arr = state.pageRequestMemo.filter((v) => v.page !== action.payload.page);
  state.pageRequestMemo = [...arr, action.payload];
};

const addTemplatesInfoList: ActionType<LayoutState, TemplatesInfo> = (state, action) => {
  state.templatesInfoList = [...state.templatesInfoList, action.payload];
};

const setTemplatesInfoList: ActionType<LayoutState, TemplatesInfo[]> = (state, action) => {
  state.templatesInfoList = action.payload;
};

const setIsDropTemplate: ActionType<LayoutState, boolean> = (state, action) => {
  state.isDropTemplate = action.payload;
};

const setIsDraggingTemplate: ActionType<LayoutState, boolean> = (state, action) => {
  state.isDraggingTemplate = action.payload;
};

const setAcquiredTemplateList: ActionType<LayoutState, AcquiredTempInfo[]> = (state, action) => {
  state.acquiredTemplateList = action.payload;
};

const addAcquiredTemplateList: ActionType<LayoutState, AcquiredTempInfo> = (state, action) => {
  state.acquiredTemplateList = [...state.acquiredTemplateList, action.payload];
};

const setQueryParams: ActionType<LayoutState, LayoutQueryParams | null> = (state, action) => {
  state.queryParams = action.payload;
};

const setPreviewSelectItem: ActionType<LayoutState, PreviewSelectItem | null> = (state, action) => {
  state.previewSelectItem = action.payload;
};
const addPresetList: ActionType<LayoutState, PresetData> = (state, action) => {
  state.presetList = [...state.presetList, action.payload];
};
const removePresetList: ActionType<LayoutState, number> = (state, action) => {
  state.presetList.splice(action.payload, 1);
};
const setPreset: ActionType<LayoutState, PresetData> = (state, action) => {
  state.preset = action.payload;
};

const setTemplateGroupName: ActionType<LayoutState, string> = (state, action) => {
  state.templateGroupName = action.payload;
};

const addTemplatesXmlList: ActionType<LayoutState, any[]> = (state, action) => {
  state.templatesXmlList = [...state.templatesXmlList, ...action.payload];
};

const setTemplatesXmlList: ActionType<LayoutState, any[]> = (state, action) => {
  state.templatesXmlList = action.payload;
};

const setDeleteAlbumData: ActionType<LayoutState, DeleteAlbumData[]> = (state, action) => {
  state.deleteAlbumInfos = action.payload;
};

const setOpenTemplateType: ActionType<LayoutState, OpenTemplateInfo> = (state, action) => {
  state.openTemplateType = action.payload;
};

const setIsStopExifWarning: ActionType<LayoutState, boolean> = (state, action) => {
  state.isStopExifWarning = action.payload;
};

const setIsStopTextPosWarning: ActionType<LayoutState, boolean> = (state, action) => {
  state.isStopTextPosWarning = action.payload;
};

const setIsSaving: ActionType<LayoutState, boolean> = (state, action) => {
  state.isSaving = action.payload;
};

const setRestoreTemplates: ActionType<LayoutState, AddAlbumParam[]> = (state, action) => {
  state.restoreTemplates = action.payload;
};

const setPageDataTypesRes: ActionType<LayoutState, PageDataTypesResponse> = (state, action) => {
  state.pageDataTypesRes = action.payload;
};

const setRestorePicData: ActionType<LayoutState, RestorePicData[]> = (state, action) => {
  state.restorePicData = action.payload;
};

const setAlertPathList: ActionType<LayoutState, AlertPathData[]> = (state, action) => {
  state.alertPathList = action.payload;
};

const setSaveSelect: ActionType<LayoutState, SaveSelectData> = (state, action) => {
  state.saveSelect = [...state.saveSelect, action.payload];
};

const setPageTypeList: ActionType<LayoutState, any[]> = (state, action) => {
  action.payload.forEach((v) => {
    switch (v.$.type) {
      case 'op_print':
        state.pageTypeList.opPrint = [...state.pageTypeList.opPrint, {pageTypeID: v.$.pageTypeID, type: v.$.type}];
        break;
      case 'cover':
      case 'frame':
        state.pageTypeList.cover = [...state.pageTypeList.cover, {pageTypeID: v.$.pageTypeID, type: v.$.type}];
        break;
      case 'top':
        state.pageTypeList.top = [...state.pageTypeList.top, {pageTypeID: v.$.pageTypeID, type: v.$.type}];
        break;
      case 'page':
        state.pageTypeList.page = [...state.pageTypeList.page, {pageTypeID: v.$.pageTypeID, type: v.$.type}];
        break;
      case 'end':
        state.pageTypeList.endCover = [...state.pageTypeList.endCover, {pageTypeID: v.$.pageTypeID, type: v.$.type}];
        break;
      case  'option':
        state.pageTypeList.option = [...state.pageTypeList.option, {pageTypeID: v.$.pageTypeID, type: v.$.type}];
        break;
      default :
        state.pageTypeList.page = [...state.pageTypeList.page, {pageTypeID: v.$.pageTypeID, type: v.$.type}];
        state.pageTypeList.option = [...state.pageTypeList.option, {pageTypeID: v.$.pageTypeID, type: v.$.type}];
    }
  });
};
const setIsSelectIdError: ActionType<LayoutState, boolean> = (state, action) => {
  state.isSelectIDError = action.payload;
}
const setWarningList: ActionType<LayoutState, string> = (state, action) => {
  state.warningList = [...state.warningList, action.payload]
}
const removeWarningList: ActionType<LayoutState, string> = (state, action) => {
  state.warningList = state.warningList.filter(v => v !== action.payload)
}
const setFitErrorList: ActionType<LayoutState, string> = (state, action) => {
  const id = action.payload;
  if (!state.fitErrorList.find((v) => v === id) && id !== '') {
    state.fitErrorList = [...state.fitErrorList, id];
  }
}
const removeFitErrorList: ActionType<LayoutState, string> = (state, action) => {
  const id = action.payload;
  state.fitErrorList = state.fitErrorList.filter((v) => v !== id);
}
const setFontErrorList: ActionType<LayoutState, string> = (state, action) => {
  state.fontErrorList = [...state.fontErrorList, action.payload]
}
const removeFontErrorList: ActionType<LayoutState, string> = (state, action) => {
  state.fontErrorList = state.fontErrorList.filter(v => v !== action.payload)
}
export const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    setTemplateList,
    addTemplateList,
    setFolderList,
    sortAlbumPage,
    addFolderList,
    setSelectFolder,
    setThumbnailPath,
    setPage,
    setOptionName,
    setAlbumData,
    addAlbumPage,
    removeAlbumPage,
    setCurrentAlbumPage,
    setAlbumSettingData,
    addPageRequestMemo,
    addTemplatesInfoList,
    setTemplatesInfoList,
    setIsDropTemplate,
    setIsDraggingTemplate,
    setAcquiredTemplateList,
    addAcquiredTemplateList,
    setQueryParams,
    setPreviewSelectItem,
    addPresetList,
    removePresetList,
    setPreset,
    setTemplateGroupName,
    addTemplatesXmlList,
    setTemplatesXmlList,
    setDeleteAlbumData,
    setOpenTemplateType,
    setIsStopExifWarning,
    setIsStopTextPosWarning,
    setIsSaving,
    setRestoreTemplates,
    setPageDataTypesRes,
    pushUnFindFontList,
    setInitFontList,
    setTrimmingGuideList,
    setRestorePicData,
    setMetaData,
    setAlertPathList,
    setSaveSelect,
    setPageTypeList,
    setIsSelectIdError,
    setWarningList,
    removeWarningList,
    setFitErrorList,
    removeFitErrorList,
    setFontErrorList,
    removeFontErrorList,
  },
});

export const layoutActions = layoutSlice.actions;
export const layoutReducer = layoutSlice.reducer;
