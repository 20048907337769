import {useAppSelector} from '../../app/hooks';
import * as lodash from 'lodash';
import {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {toolbarActions} from './slice/toolbar-slice';
import './toolbar.scss';

export const Toolbar = () => {
  const dispatch = useDispatch();
  // - Hooks -
  const {display, position, list} = useAppSelector((state) => ({
    // -- 表示状態 --
    display: state.toolbar.display,
    // -- 表示位置 --
    position: state.toolbar.position,
    // -- 表示内容 --
    list: state.toolbar.list,
  }), lodash.isEqual);
  // - Effect -
  // -- 範囲外クリック時のメニュークローズ --
  useEffect(() => {
    if (display) {
      const listenerCloseMenu = () => {
        document.removeEventListener('click', listenerCloseMenu);
        dispatch(toolbarActions.close());
      };
      document.addEventListener('click', listenerCloseMenu);
    }
  }, [display]);
  return display && list.length ? (
    <div className="tool_bar__blur_bg">
      <div
        className="tool_bar"
        style={{ left: `${position.x}px`, top: `${position.y}px` }}
      >
        {list.map((v, i) => (
          <div
            className="tool_bar__item"
            key={`toolbar-item_${i}`}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              v.callback();
            }}
          >
            {v.label}
          </div>
        ))}
      </div>
    </div>
  ) : (<></>);
};
