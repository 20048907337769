import {CategoriesResponse} from '../../../../models/api/back/create-order/categories';
import {ApiBaseBack} from "../../api-base-back";

export class ApiCategories extends ApiBaseBack<CategoriesResponse> {
  constructor(param: {}) {
    super(
      'GET',
      '/api/v1/categories',
      'JSON',
      param,
    );
  }
}