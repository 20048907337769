import {ApiBaseFront} from "../api-base-front";
import {
  ProductDetailGetRequest,
  ProductDetailGetResponse,
  ProductDetailPostRequest,
} from "../../../models/api/front/product-detail";
import {isArray} from "lodash";

// 注文情報登録/更新
export class ApiProductDetailPost extends ApiBaseFront {
  constructor(param: ProductDetailPostRequest) {
    super(
      'POST',
      '/api/v1/productDetail',
      'JSON',
      param,
    );
  }
}

// 商品一覧取得
export class ApiProductDetailGet extends ApiBaseFront<ProductDetailGetResponse[]> {
  constructor(param: ProductDetailGetRequest) {
    super(
      'GET',
      '/api/v1/productDetail',
      'JSON',
      param,
    );
  }
}
// 商品削除
export class ApiProductDetailDelete extends ApiBaseFront {
  constructor(orderId: string, param: { kijshopCd: string, shopOrderId: string }) {
    super(
      'DELETE',
      `/api/v1/productDetail/${orderId}`,
      'JSON',
      param,
    );
  }
}
