import { AllTemplateRequest } from "../../../models/api/back/template";
import {ApiBaseBack} from "../api-base-back";

export class ApiAllTemplate extends ApiBaseBack {
  constructor(param: AllTemplateRequest) {
    super(
      'GET',
      `/api/v1/templates`,
      'JSON',
      param,
    );
  }
}
