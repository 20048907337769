import { LogDecorator } from '@tenryu/log-decorator';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FrameFitType, LayoutHeader } from './header/layout.header';
import { LayoutMenu } from './menu/layout.menu';
import { LayoutALlTemplate } from './all-template/layout.all-template';
import { LayoutSearchTemplate } from './search-template/layout.search-template';
import { LayoutSelectTemplate } from './select-template/layout.select-template';
import { LayoutFooter } from './footer/layout.footer';
import { LayoutEditTemplate } from './edit-template/layout.edit-template';
import { LayoutFavoriteTemplate } from './favorite-template/layout.favorite-template';
import './layout.scss';
import { LayoutImagePreview } from './image-preview/layout.image-preview';
import { AdditionalShowType, LayoutToolbar } from './toolbar/layout.toolbar';
import { layoutActions, LayoutItemData } from '../../../slices/layout-slice';
import { useAppSelector } from '../../../app/hooks';
import * as lodash from 'lodash';
import { cloneDeep, isEqual } from 'lodash';
import { useDispatch } from 'react-redux';
import { LayoutEditorManager } from '../../../layout-editor/manager/layout-editor.manager';
import { useLocation, useParams } from 'react-router-dom';
import { PathParams, RoutingPath } from '../../../routes/routing-path';
import { xmlActions, padding } from '../../../xml/slice/xml-slice';
import { UiManager } from '../../../layout-editor/manager/ui/ui.manager';
import { TrimmingController } from './toolbar/layout.trimming-controller';
import { XmlStructureModel, XmlStructureOrderInfoData } from '../../../xml/model/xml-structure-model';
import { orderLockActions } from '../../../slices/lock-order-slice';
import { dialogActions } from '../../dialog/slice/dialog-slice';
import { apiActions } from '../../../slices/api-slice';
import { LoadingPopup } from '../../ui/loading/loading-popup';
import { ApiImagesGet, ApiImagesGetOne, ImagesGetResponse } from '../../../api/front/images/api-images';
import { getTargetPathUrl } from '../layout/all-template/layout.all-template';
import { TemplateLoaderManager } from '../../../layout-editor/manager/template-loader/template-loader.manager';
import { EditableImageManager } from '../../../layout-editor/manager/editable-image/editable-image.manager';
import { Pos } from '../../../models/pos';
import { ResponseBase } from '../../../api/response-base';
import { push } from 'connected-react-router';
import { OrderPageDataXml } from '../../../xml/class/order/order-page-data-xml';
import { LayoutXmlUtile } from '../../../layout-editor/model/layout.xml.utile';
import { store } from '../../../app/store';
import { commonActions } from '../../../slices/common-slice';
import { localStorageActions } from '../../../slices/local-storage-slice';
import { ApiPageDataTypes } from '../../../api/back/create-order/page-data-types/api-page-data-types';
import { PageDataTypesResponse } from '../../../models/api/back/create-order/page-data-types';
import { HashGenerator } from '../../../utilities/hash-generator';
import { ApiMetaOrderGet } from '../../../api/front/meta/api-meta-order';
import { Env } from '../../../models/env';
import { ApiMasterXml } from '../../../api/back/xml/api-master-xml';
import { XmlParser } from '../../../manager/xml-parser';

export type AlbumLayoutType = LayoutItemData & {
  thumbPath?: string,
  indexes: string[],
  pageType: string,
  displayPageNo: string,
  displayPageType: string,
};
export type AddAlbumParam = {
  itemData: {
    id: string,
    templateName: string,
    thumbnail: string,
    templateXmlUrl: string,
    targetPath: string,
  },
  templates: any,
  template: any,
  thumbnail: any,
  zipLevel: any,
  isChange: boolean,
  indexes: string[],
  thumbPath: string,
  kijshopCd: string,
  shopOrderId: string,
  orderId: string,
  memo: string,
  templateBlob?: Blob,
}
type UsedImageListType = {
  path: string;
  name: string;
}

export type MenuType = {
  inOut: 'in' | 'out';
  ableArea: boolean;
  center: boolean;
  fitType: FrameFitType,
  additionalShow: AdditionalShowType,
}

const initMenuType: MenuType = {
  inOut: 'out',
  ableArea: true,
  center: false,
  fitType: '5',
  additionalShow: 'show',
};


export const Layout = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { kijshopCd, shopOrderId, orderId } = useParams<PathParams>();
  const { page, album, orderInfo, xml, tempGroupName, templatesXmlArr, dialogs, version, storage, metaData, albumSetting, pageTypeList } = useAppSelector((state) => ({
    page: state.layout.page,
    album: state.layout.albumPages,
    orderInfo: state.orderPreparation.currentOrder,
    tempGroupName: state.layout.templateGroupName,
    templatesXmlArr: state.layout.templatesXmlList,
    dialogs: state.dialog.dialogs,
    xml: state.xml[shopOrderId],
    version: state.common.data.version,
    storage: state.storage,
    metaData: state.layout.metaData,
    albumSetting: state.layout.albumSettingData,
    pageTypeList: state.layout.pageTypeList,
  }), lodash.isEqual);

  // - State -
  // -- 人寸トリミングメニューの開閉状態 --
  const [trimMenu, setTrimMenu] = useState(false);
  // -- テンプレート一覧表示するpageTypeリスト --
  const [pageTypeIdArr, setPageTypeArr] = useState<any[]>([]);
  // -- クエリパラメータが無駄につかないように（仮）--
  const [count, setCount] = useState(0);
  // const [targetXml, setTargetXml] = useState<XmlStructureOrderInfoData | null>(null);
  // -- 更新があるかどうか --
  const [duplicateCheckedList, setDuplicateCheckedList] = useState<string[]>([]);
  // -- 通信中フラグ --
  const [connectingMessage, setConnectingMessage] = useState('');
  const [connectingMessage2, setConnectingMessage2] = useState('');
  // -- サムネの拡大率 --
  const [tmplThumbSizeRate, setTmplThumbSizeRate] = useState(1);
  const [previewThumbSizeRate, setPreviewThumbSizeRate] = useState(1);
  const [postDrawerList, setPostDrawerList] = useState<string[]>([]);
  const [pngList, setPngList] = useState<string[]>([]);
  const [logoList, setLogoList] = useState<string[]>([]);
  const [useDrawerList, setUseDrawerList] = useState<string[]>();
  const [pngListLength, setPngListLength] = useState(NaN);
  const [logoListLength, setLogoListLength] = useState(NaN);
  const [loadImage, setLoadImage] = useState(false);
  const [loadFavoriteTemplate, setLoadFavoriteTemplate] = useState(false);
  const [loadTemplate, setLoadTemplate] = useState(false);
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [isInit, setIsInit] = useState(false);
  const [isLastLoad, setIsLastLoad] = useState(false);
  const [textPos, setText] = useState<Pos | null>(null);
  const [loadLogo, setLoadLogo] = useState(false);
  const [startRestoreFlag, setStartRestoreFlag] = useState(false);
  const [menuParam, setMenuParam] = useState<MenuType>(initMenuType);
  const [isRestore, setIsRestore] = useState(false);
  const [unUsedImageList, setUnUsedImageList] = useState<any[]>([]);
  const [unUsedEditableList, setUnUsedEditableList] = useState<string[]>([]);
  const [errorCount, setErrorCount] = useState(0);
  const [idInit, setIdInit] = useState(false);
  const [fontList, setFontList] = useState<{ value: string, label: string }[]>([]);
  const [initMeta, setInitMeta] = useState(false);
  const [loadedImageList, _] = useState<string[]>(() => {
    let setList: string[] = [];
    UiManager.ins.emit('l->r:post-editable-image-list', {
      callback: (v) => {
        setList = v.list.filter((v2) => v2.shopOrderId === shopOrderId && v2.kijshopCd === kijshopCd).map((v2) => v2.selectId);
      },
    });
    return setList;
  });

  const targetXml = useMemo(() => {
    return xml?.orderInfo?.infoData?.find((v) => v.xml.metaModel.id === orderId) ?? null;
  }, [xml, orderId]);

  const isLoaded = useMemo(() => {
    const loadItem = [loadFavoriteTemplate, loadImage, loadTemplate];
    return loadItem.every((v) => v);
  }, [loadFavoriteTemplate, loadImage, loadTemplate]);

  const isLiteLayout = useMemo(() => {
    if (!xml) return false;
    const target = xml.orderInfo?.infoData?.find((v) => v.xml.metaModel.id === orderId);
    if (!target) return false;
    return target.xml.viewModel.surrogateProcess?.id === '20';

  }, [xml]);


  // - Callback -
  // Meta情報の取得
  const getMeta = useCallback((_orderId: string[]) => {
    return new Promise<void>((resolve) => {
      dispatch(apiActions.run(
        new ApiMetaOrderGet({
          kijshopCd,
          shopOrderId,
          orderId: padding(Number(orderId), 3),
        }),
        {
          onSuccess: (res => {
            dispatch(layoutActions.setMetaData({
              orderId: res.body.data.orderId ?? '',
              note: res.body.data.note ??'',
              checkChangeTemplateList: res.body.data.checkChangeTemplateList ?? '',
              imageSortType: res.body.data.imageSortType ??'',
              pictureData: res.body.data.pictureData ?? '',
            }));
            resolve();
          }),
        },
      ))
      })
  }, []);
  const clickDisplaySizeBtn = useCallback((isUp: boolean) => {
    const maxRate = 1.5;
    const minRate = 0.5;
    const step = 0.25;
    const fixedNum = 2;
    if (page === 'select') {
      setTmplThumbSizeRate((prev) => {
        const upRate = Number((prev + step).toFixed(fixedNum));
        const lowRate = Number((prev - step).toFixed(fixedNum));
        return isUp ? (upRate > maxRate ? maxRate : upRate) : (lowRate < minRate ? minRate : lowRate);
      });
    } else if (page === 'list') {
      setPreviewThumbSizeRate((prev) => {
        const upRate = Number((prev + step).toFixed(fixedNum));
        const lowRate = Number((prev - step).toFixed(fixedNum));
        return isUp ? (upRate > maxRate ? maxRate : upRate) : (lowRate < minRate ? minRate : lowRate);
      });
    }
  }, [page]);

  // - Effect -
  // -- ログまとめ --
  useEffect(() => {
    if (storage.kijshopCd !== kijshopCd || storage.kijshopCd === '') {
      dispatch(localStorageActions.getLocalData(kijshopCd));
    }
    const listener = () => {
      if (store.getState().layout.restoreTemplates.length) {
        window.location.reload();
      } else {
        window.removeEventListener('popstate', listener);
      }
    };
    window.addEventListener('popstate', listener);
    LogDecorator.group('<green>レイアウト</green>');
    UiManager.ins.on('l->r:open-trim-menu', (e) => {
      setTrimMenu(e.isOpen);
    });
    UiManager.ins.on('l->r:wait-loading', (e) => setConnectingMessage(e.message));
    UiManager.ins.on('l->r:wait-loading2', (e) => setConnectingMessage2(e.message));
    if (!pageTypeList || !pageTypeList.cover.length) {
      dispatch(apiActions.run(
        new ApiMasterXml('master-page-type'),
        {
          onSuccess: (res) => {
            new XmlParser()
              .parse<any>((res.body.data))
              .then((result) => {
                dispatch(layoutActions.setPageTypeList(result.lnwMasterPageType.pageType[0].pageTypeData))
              })
          },
          onError: () => {
            dispatch(dialogActions.pushSeriousError());
          },
        }
      ))
    }
    return () => {
      LogDecorator.groupEnd();
      dispatch(layoutActions.setFolderList([]));
      dispatch(layoutActions.setTemplateList([]));
      dispatch(layoutActions.setTemplatesXmlList([]));
      dispatch(layoutActions.setQueryParams(null));
      dispatch(layoutActions.setOpenTemplateType({
        type: '',
        favoriteFolderID: '',
      }));
      UiManager.ins.off('l->r:open-trim-menu');
      UiManager.ins.off('l->r:wait-loading');
      UiManager.ins.off('l->r:wait-loading2');
    };
  }, []);
  useEffect(() => {
    if (!idInit) {
      setIdInit(true);
    } else {
      window.location.reload();
    }
    ;
  }, [kijshopCd, orderId, shopOrderId]);

  // destroy
  useEffect(() => {
    EditableImageManager.ins.initUse();
    const mng = new LayoutEditorManager();
    mng.initialize();
    return () => {
      EditableImageManager.ins.initUse();
      dispatch(layoutActions.setAlbumData({
        cover: {
          page: 0,
          albums: [],
          pageCount: 0,
          pageTypeId: '',
        },
        top: {
          page: 0,
          albums: [],
          pageCount: 0,
          pageTypeId: '',
        },
        page: {
          page: 0,
          albums: [],
          pageCount: 0,
          pageTypeId: '',
        },
        endCover: {
          page: 0,
          albums: [],
          pageCount: 0,
          pageTypeId: '',
        },
        option: [],
        opPrint: [],
      }));
      mng.destroy();
      dispatch(layoutActions.setPage('select'));
    };
  }, []);

  useEffect(() => {
    if (!xml) {
      dispatch(xmlActions.getXml(kijshopCd, shopOrderId));
    }
  }, [shopOrderId, kijshopCd]);


  useEffect(() => {
    const isTargetParam = new URLSearchParams(location.search).has('productNameId');
    const targetParam = new URLSearchParams(location.search).get('productNameId');
    if (targetParam) {
      dispatch(layoutActions.setQueryParams({
        productNameId: targetParam,
      }));
    }
    if (orderInfo?.products?.productNameId && !isTargetParam && count < 1) {
      const params = {
        productNameId: orderInfo.products.productNameId,
      };
      const layoutQuery = new URLSearchParams(params).toString();
      const url = window.location.href + `?${layoutQuery}`;
      window.history.replaceState('', '', url);
      dispatch(layoutActions.setQueryParams(params));
      setCount(prev => prev + 1);
    } else if (targetXml?.xml.viewModel.goods?.goodsId && !isTargetParam && count < 1) {
      const params = {
        productNameId: targetXml.xml.viewModel.goods?.goodsId,
      };
      const layoutQuery = new URLSearchParams(params).toString();
      const url = window.location.href + `?${layoutQuery}`;
      window.history.replaceState('', '', url);
      dispatch(layoutActions.setQueryParams(params));
      setCount(prev => prev + 1);
    } else if (!isTargetParam) {
      // dispatch(push(RoutingPath.cnv.ordersPreparation({kijshopCd, shopOrderId})));
      // dispatch(layoutActions.setQueryParams({
      //   productNameId: 'jptg300002',
      // }));
    }
  }, [targetXml, count, orderInfo?.products?.productNameId]);

  const changeMessage = useCallback(() => {
    dispatch(dialogActions.pushMessage({
      title: '確認',
      message: ['使用していたテンプレートが更新されました。', '以前の編集状態が初期化されていますので、レイアウト内容を確認してください。'],
      buttons: [{ label: 'OK', callback: () => dispatch(dialogActions.pop()) }],
    }));
  }, []);

  /* ドロワー画像一件取得処理 */
  const getImage = useCallback(async (v: any, isUnused?: boolean) => new Promise<any>((resolve) => {
    const path = `${kijshopCd}/upload/${shopOrderId}/${v.selectFileName?.real.path}`;
    dispatch(apiActions.run(
      new ApiImagesGetOne({
        kijshopCd,
        path,
      }),
      {
        onSuccess: (blob: Blob) => {
          const name = v.originalFileName.real.path ?? '';
          const file = new File(
            [blob],
            name,
            {
              lastModified: new Date().getTime(),
              type: blob.type,
            },
          );
          resolve({ file, selectID: v.selectID ?? '', path, image: v });
        },
        onError: () => {
          if (isUnused) {
            resolve(undefined);
          }
        },
      },
      {
        ignoreSystemError: isUnused,
      },
    ));
  }), []);

  /* 未使用画像ドロワー */
  const getUnUsedImages = useCallback(async () => {
    const pushList: string[] = [];
    let count = 0;
    UiManager.ins.emit('r->l:add-image:restore', {
      kijshopCd,
      shopOrderId,
      orderId,
      list: unUsedImageList,
      onRestoreOne: () => {
        pushList.push(unUsedImageList[count]?.selectID ?? '');
        setUnUsedEditableList([...pushList]);
        count++;
      },
      isDelete: true,
    });
  }, [unUsedImageList]);

  /* ドロワー画像一覧取得処理 → 必要分一件取得 */
  const getImages = useCallback(async () => new Promise<void>((resolve) => {
    dispatch(apiActions.run(
      new ApiImagesGet({
        kijshopCd,
        shopOrderId,
        kind: '6',
      }),
      {
        onSuccess: async (v: ResponseBase<ImagesGetResponse[]>) => {
          const drawerList: any[] = [];
          const unloadData = (xml?.orderSelect?.metaModel.imageData ?? []).filter((data) => data.selectID && !loadedImageList.includes(data.selectID));
          if (!v?.body?.data?.length) {
            setLoadImage(true);
          }
          for (const image of unloadData) {
            const find = v.body.data?.find((v2: ImagesGetResponse) => (v2.selectId && v2.selectId === image.selectID) || v2.filename === image.selectFileName?.real.path);
            if (find) {
              drawerList.push((image));
            }
          }
          const usedDrawerList: any[] = [];
          const unUseDrawerList: any[] = [];
          const targetInfo = xml?.orderInfo?.infoData?.find((v) => v.xml.metaModel.id === orderId);
          const optionInfo = LayoutXmlUtile.getOptionInfoData(orderId, xml);
          const optionParts = LayoutXmlUtile.flatOptionParts(optionInfo);
          for (const parts of (targetInfo?.parts?.partsData ?? []).concat(optionParts)) {
            for (const page of parts.page?.pageData ?? []) {
              for (const picData of page.viewModel.orderPicture?.data ?? []) {
                const selectId = picData.selectID;
                const findSelect = unloadData.find((v2) => v2.selectID === selectId);
                if (findSelect && v.body.data?.find((findData) => (findData.selectId && findData.selectId === findSelect.selectID) || findData.filename === findSelect.selectFileName?.real.path)) {
                  if (!usedDrawerList.find((v) => v.selectID === findSelect.selectID)) {
                    usedDrawerList.push(findSelect);
                  }
                }
              }
            }
          }
          for (const image of unloadData ?? []) {
            if ((!usedDrawerList.find((v2) => v2.selectID === image.selectID) || !v.body.data?.find((findData) => {
              /* ラボ発注済データをコピーした際にセレクトIDを元に画像を割り当てる */
              if (findData.selectId && findData.selectId === image.selectID) {
                if (image.selectFileName?.real) {
                  // image.selectFileName.real.path = `${findData.pathThumb}/${findData.filename}`;
                  image.selectFileName.real.path = `${findData.filename}`;
                }
                return true;
              }
              return findData.filename === image.selectFileName?.real.path;
            }))) {
              unUseDrawerList.push(image);
            }
          }
          setUnUsedImageList(cloneDeep(unUseDrawerList));
          let loadCount = 0;
          const useTotal = usedDrawerList.length;
          // setDrawerLength(useTotal);
          setUseDrawerList([...usedDrawerList].map((v) => v.selectID));
          if (useTotal) {
            setConnectingMessage(`使用画像の読み込み中です。(${0}/${useTotal})`);
          } else {
            setPostDrawerList([]);
          }
          const countList: string[] = [];
          UiManager.ins.emit('r->l:add-image:restore', {
            kijshopCd,
            shopOrderId,
            orderId,
            list: usedDrawerList,
            onRestoreOne: () => {
              countList.push(`${usedDrawerList[loadCount].selectID ?? ''}`);
              loadCount += 1;
              setConnectingMessage(`使用画像の読み込み中です。(${loadCount}/${useTotal})`);
              setPostDrawerList(cloneDeep(countList));
            },
            isDelete: false,
          });
        },
      },
    ));
  }), [xml, getImage, postDrawerList, kijshopCd, shopOrderId, orderId, loadedImageList]);

  const getImageOne = useCallback((data: UsedImageListType, isLogo?: boolean, fileName?: string) => {
    // 0299999/0299999001032/FreeGraphics/20220512014214.png
    return new Promise<{file: File, path: string, hashFile: File}>((resolve) => {
      const path = `${kijshopCd}/${shopOrderId}/${data.path}`;
      dispatch(apiActions.run(
        new ApiImagesGetOne({
          kijshopCd,
          path,
        }),
        {
          onSuccess: (blob: Blob) => {
            if (blob.type === 'application/json') {
              resolve({ file: new File([], ''), path: '', hashFile: new File([], '')});
              return;
            }
            const file = new File(
              [blob],
              fileName ?? data.name,
              {
                lastModified: new Date().getTime(),
                type: blob.type,
              },
            );
            const hashFile = new File(
              [blob],
              'dummy_hash.jpg',
              {
                lastModified: new Date().getTime(),
                type: blob.type,
              },
            );
            resolve({ file, path: data.path, hashFile });
          },
          onError: (e) => {
            resolve({ file: new File([], ''), path: '', hashFile: new File([], '')});
          },
        },
      ));
    });
  }, []);

  const getPngImages = useCallback(async() => {
    const usedPngData: UsedImageListType[] = [];
    const info = xml?.orderInfo?.infoData?.find((v) => v.xml.metaModel.id === orderId);
    const optionInfo = LayoutXmlUtile.getOptionInfoData(orderId, xml);
    const optionParts = LayoutXmlUtile.flatOptionParts(optionInfo);
    const pngList = await new Promise<any>((resolve) => {
      dispatch(apiActions.run(
        new ApiImagesGet({
          kijshopCd,
          shopOrderId,
          kind: '5',
        }),
        {
          onSuccess: (v: any) => {
            resolve(v.body.data);
          },
          onError: () => resolve([]),
        }
      ))
    });
    if (info) {
      for (const parts of (info.parts?.partsData ?? []).concat(optionParts)) {
        for (const page of parts.page?.pageData ?? []) {
          for (const image of page.viewModel.freeGraphic?.data ?? []) {
            // const target = v.body.data.find((v) => )
            const find = pngList.find((v: any) => {
              return (image.virtual?.path ?? '') === `FreeGraphics/${v.orgFilename}`;
            });
            if (!find) continue;
            const data = { path: image.virtual?.path || '', name: image.real?.path || '', fileName: find.orgFilename };
            if (!usedPngData.find((png) => {
              return isEqual(png, data);
            })) {
              usedPngData.push(data);
            }
          }
        }
      }
    }
    setPngListLength(usedPngData.length);
    if (!usedPngData.length) {
      setPngList([]);
    }
    Promise.all(usedPngData.map((data) => getImageOne(data, false, data.name)))
      .then((_images) => {
        const images = _images.filter((v) => v.path);
        for (const img of images) {
          if (img.path && EditableImageManager.ins.list.findIndex((v) => v.path === img.path && v.name === img.file.name) < 0) {
            UiManager.ins.emit('r->l:add-image-png', {
              files: [img.file],
              kijshopCd,
              shopOrderId,
              orderId,
              restore: true,
              path: img.path,
              onCreatedEditableImage: (v2) => {
                UiManager.ins.emit('l->r:post-editable-image-list', {
                  callback: (v) => setPngList(v.list.filter(EditableImageManager.filter(kijshopCd, shopOrderId, orderId)).filter((v) => v.kind === '5').map((v) => v.id)),
                });
              },
            });
          }
        }
      });
  }, [xml, getImageOne, EditableImageManager]);

  const getLogoImages = useCallback(async() => {
    const usedLogoData: UsedImageListType[] = [];
    const info = xml?.orderInfo?.infoData?.find((v) => v.xml.metaModel.id === orderId);
    const optionInfo = LayoutXmlUtile.getOptionInfoData(orderId, xml);
    const optionParts = LayoutXmlUtile.flatOptionParts(optionInfo);
    const getList = (): Promise<ImagesGetResponse[]> => new Promise((resolve) => {
      dispatch(apiActions.run(
        new ApiImagesGet({
          shopOrderId,
          kijshopCd,
          kind: '4'
        }),
        {
          onSuccess: (v: ResponseBase<ImagesGetResponse[]>) => resolve(v.body.data ?? []),
          onError: () => resolve([]),
        }
      ))
    })
    if (info) {
      for (const parts of (info.parts?.partsData ?? []).concat(optionParts)) {
        for (const page of parts.page?.pageData ?? []) {
          for (const image of page.viewModel.orderPicture?.data ?? []) {
            const data = { path: image.logoFileName?.realPath };
            if (data.path) {
              usedLogoData.push({ path: data.path, name: data.path });
            }
          }
        }
      }
    }
    setLogoListLength(usedLogoData.length);
    const list = await getList();
    if (!usedLogoData.length) {
      setLogoList([]);
      return;
    }
    /* 開き直した際に editable-image を生成し直さないとテンプレートに適用されなかったので画像ダウンロード後は生成済の editable-image はセットしないようにする */
    const ignoreLogoData: string[] = [];
    usedLogoData.forEach((logo) => {
      const editableImage = EditableImageManager.ins.list.filter((v) => v.kind === '4').find((v) => `Except_Real/${v.path}` === logo.path && `Except_Real/${v.name}` === logo.name);
      if (editableImage) {
        ignoreLogoData.push(editableImage.id);
      }
    });
    Promise.all(usedLogoData.map(async (v) => {
      const findName = list.find((v2) => `Except_Real/${v2.filename}` === v.name)?.filename;
      return await getImageOne(v, true, findName)
    })).then(async (_images) => {
      const missArr: number[] = [];
      const images = _images.filter((v, i) => {
        if (!v.path) {
          missArr.push(i);
        }
        return v.path;
      });
      if (images.length !== _images.length) {
        setLogoList((prev) => {
          const arr: string[] = [];
          prev.forEach((_, i) => {
            if (!missArr.find((_, index) => i === index)) {
              arr.push(prev[i]);
            }
          });
          return arr;
        });
        setLogoListLength(images.length);
        if (!images.length) {
          setLoadLogo(true);
        }
      }
      for (const img of images) {
        const editableImage = EditableImageManager.ins.list.find((v) => v.path === img.path && v.name === img.file.name);
        if (editableImage) {
          setLogoList((prev) => {
            if (prev.find((v) => v === editableImage.id)) {
              return [...prev];
            }
            return [...prev, editableImage.id];
          });
        } else {
          const hash = await HashGenerator.generate(img.hashFile);
          UiManager.ins.emit('r->l:add-image-logo', {
            file: img.file,
            kijshopCd,
            shopOrderId,
            orderId,
            restore: true,
            path: img.path,
            hashData: { create: false, hash },
            onCreatedEditableImage: (e) => {
              UiManager.ins.emit('l->r:post-editable-image-list', {
                callback: (v) => {
                  setLogoList((prev) => {
                    const logoImageList = v.list.filter(EditableImageManager.filter(kijshopCd, shopOrderId, orderId)).filter((v) => v.kind === '4').map((v) => v.id);
                    const arr = [...prev];
                    logoImageList.forEach((logo) => {
                      if (!ignoreLogoData.find((v) => v === logo)) {
                        const index = arr.findIndex((v) => v === logo);
                        if (index !== -1) {
                          arr.splice(index, 1);
                        }
                        arr.push(logo);
                      }
                    });
                    return arr;
                  });
                },
              });
            },
            callback: () => {
            },
          });
        }
      }
    });
  }, [xml, getImageOne]);

  /* ドロワー画像取得処理完了フラグ */
  useEffect(() => {
    if (useDrawerList && (() => {
      let flag = true;
      useDrawerList.forEach((v) => {
        flag = flag && Boolean(postDrawerList.find((id) => id === v));
      });
      return flag;
    })()) {
      setLoadImage(true);
    }
  }, [postDrawerList]);

  useEffect(() => {
    if (!isNaN(logoListLength) && logoList.length === logoListLength) {
      setLoadLogo(true);
    }
  }, [logoList]);

  useEffect(() => {
    if (!isNaN(pngListLength) && pngList.length === pngListLength && xml && !isLastLoad) {
      setIsLastLoad(true);
      getLogoImages();
    }
  }, [pngList]);

  useEffect(() => {
    if (loadLogo && !startRestoreFlag && xml) {
      const findXmlParts = xml.orderInfo?.infoData?.find((v) => v.xml.metaModel.id === orderId)?.parts;
      const findOptionXmlParts = LayoutXmlUtile.getOptionInfoData(orderId, xml);
      setConnectingMessage('');
      setStartRestoreFlag(true);
      if (!findXmlParts) return;
      const templates: (AlbumLayoutType)[] = [];
      const partsList = (() => {
        let isOpPrint = false;
        for (const flatOptionPart of LayoutXmlUtile.flatOptionParts(findOptionXmlParts)) {
          for (const page of flatOptionPart.page?.pageData ?? []) {
            if (pageTypeList.opPrint.find(v => v.pageTypeID === page.viewModel.pageType)) {
              isOpPrint = true;
            }
          }
        }
        if (isOpPrint) {
          return LayoutXmlUtile.flatOptionParts(findOptionXmlParts).concat(findXmlParts.partsData ?? [])
        } else {
          return (findXmlParts.partsData ?? []).concat(LayoutXmlUtile.flatOptionParts(findOptionXmlParts))
        }
      })()
      for (const parts of partsList) {
      // for (const parts of (findXmlParts.partsData ?? []).concat(LayoutXmlUtile.flatOptionParts(findOptionXmlParts))) {
        for (const page of parts.page?.pageData ?? []) {
          if (page.viewModel.template && (page.viewModel.template.path?.split('/').length || 0) >= 4) {
            const temp = page.viewModel.template;
            const _thumbPath = page.viewModel.compositeFileName?.virtualPath;
            const thumbPath = _thumbPath ? `${kijshopCd}/${shopOrderId}/${_thumbPath}` : undefined;
            const id = temp.path?.split('/').filter((v) => v).filter((_, i) => i <= 3).join('/') || '';
            templates.push({
              id,
              targetPath: temp.adPath || '',
              templateName: temp.path || '',
              templateXmlUrl: getTargetPathUrl(temp.adPath || ''),
              thumbnail: getTargetPathUrl(`${temp.adPath}/thumbnail.jpg`),
              indexes: cloneDeep(page.indexes),
              thumbPath,
              pageType: page.viewModel.pageType || '',
              displayPageNo: page.viewModel.displayPageNo || '',
              displayPageType: page.viewModel.displayPageType || '',
            });
          }
        }
      }
      if (templates.length) {
        setConnectingMessage(`復元処理中です。 (0/${templates.length})`);
        // loopXml(templates).then(() => getUnUsedImages());
        loopXml(templates);
      } else {
        getUnUsedImages();
      }
    }
  }, [loadLogo, getUnUsedImages]);

  const checkCoverPages = useCallback(async (targetPath: string) => {
    const isCover = Boolean(targetPath.indexOf('___') > 0);
    if (!isCover) {
      return targetPath;
    }
    const groupPath = targetPath.substr(0, targetPath.lastIndexOf('/'));
    const templatesXmlList = store.getState().layout.templatesXmlList
    const group = templatesXmlList.find(v => v.$.path === groupPath);
    const pageCount = store.getState().layout.albumSettingData.pageCount;
    const checkCover = async (coverTemps: any[]) => {
      return new Promise<string | undefined>((resolve) => {
        if (!coverTemps || !coverTemps.length) {
          resolve(undefined);
        }
        const newCover = coverTemps.find(temp => {
          const min = Number(temp.cover[0].$.minPageCount);
          const max = Number(temp.cover[0].$.maxPageCount);
          return (min <= Number(pageCount) && max >= Number(pageCount));
        });
        if (newCover) {
          resolve(newCover.$.path)
        }
      });
    };
    if (group) {
      const coverTemps = group.templateGroup[0].templateData.filter((v: any) => v.$.path.substr(0, v.$.path.lastIndexOf('___')) === targetPath.substr(0, targetPath.lastIndexOf('___')));
      const result = await checkCover(coverTemps)
      return result ?? targetPath
    }
    return targetPath
  }, []);

  const getTemplateXML = useCallback<any>(async (targetPath: string, templateName: string, pageType: string, thumbnail: string, isChange?: boolean) => {
    const BASE_URL = `${Env.api.back.protocol}://${Env.api.back.host}/files/template`;
    let path = targetPath;
    if (pageTypeList.cover.find(v2 => v2.pageTypeID === pageType)) {
    // if (COVER_LIST.find(v2 => v2.pageTypeID === pageType)) {
      path = await checkCoverPages(targetPath)
    }
    const nextPathData = await TemplateLoaderManager.checkNextPath(path, pageType);
    const data = await TemplateLoaderManager.ins.onGetTargetFile(nextPathData.path, 'templateXml', templateName);
    const nextThumb = `${BASE_URL}/${nextPathData.path}/thumbnail.jpg`
    if (data) return { temp: data, isChange: nextPathData.isChange, thumbnail: nextThumb };
    // if (nextPathData) {
      // const _data = await getTemplateXML(nextPath, templateName, pageType, isChange);
      // const _data = await getTemplateXML(nextPath, 'templateXML', templateName);
    //   return { temp: nextPathData.path, isChange: nextPathData.isChange };
    // }
    return { temp: undefined, isChange: false };
  }, [pageTypeList]);

  const checkDisplayPageType = useCallback(async (v: AlbumLayoutType) => {
    let result = '';
    if (pageTypeList.cover.find(v2 => v2.pageTypeID === v.pageType)) {
    // if (COVER_LIST.find(v2 => v2.pageTypeID === v.pageType)) {
      result = `・${v.displayPageType}`
    } else if ('jptg380013' === v.pageType) {
      result = `・${v.displayPageType}`
    } else if ('jptg380015' === v.pageType) {
      result = `・${v.displayPageType}`
    } else {
      if ('***' === v.displayPageNo) {
        result = `・${v.displayPageType}`
      } else {
        result = `・${v.displayPageNo}ページ`
      }
    }
    return result
    }, [pageTypeList]);

  /* 復元用テンプレートXML取得処理 */
  const onGetTemplateXml = useCallback(async (v: AlbumLayoutType) => {
    let templatesXmlData: { xml: any, pageType: string } = { xml: '', pageType: '' };
    let templateXmlData: any;
    let thumbnailData: string;
    let zipLevel: { isZip: boolean, level: number };
    const _name = v.templateName.split('/');
    const name = _name[_name.length - 1];
    const data = await getTemplateXML(v.targetPath, name, v.pageType, v.thumbnail);
    const id = v.targetPath.split('/').filter((v) => v).filter((_, i) => i <= 3).join('/');
    thumbnailData = data.thumbnail;
    zipLevel = { isZip: false, level: 0 };
    if (data.temp.templates) {
      const target = data?.temp.templates.find((v: any) => v.$.path === v.id);
      if (target) {
        const dd = target.templateGroup[0].templateData.find((v: any) => v.$.path === v.targetPath);
        if (dd) {
          templatesXmlData = { xml: dd, pageType: target.$.name };
        }
      }
    }
    const [index1, index2, index3] = v.indexes.map((v) => Number(v) - 1);
    const partsData = xml?.orderInfo?.infoData?.[index1].parts?.partsData
    const targetPartsData = xml?.status.metaModel.orderInfoData?.[index1]?.orderPartsData;
    let index = 0
    const statusPartsData = partsData?.map(data => {
      if (data?.page) {
        index++
        return  targetPartsData?.[index - 1]
      } else {
        return undefined
      }
    })
    const memo = (statusPartsData?.[index2]?.orderPageData?.[index3]?.data?.[0].comment ?? '');
    if (data?.temp.thumbnail) {
      thumbnailData = URL.createObjectURL(data.temp.thumbnail as Blob);
    }
    if (data?.temp.zipLevel) {
      zipLevel = data.temp.zipLevel;
    }
    if (data?.temp.template) {
      templateXmlData = data.temp.template;
    }
    const displayPageType = await checkDisplayPageType(v)
    const result = {
      itemData: {
        id,
        templateName: v.templateName,
        thumbnail: data.thumbnail,
        templateXmlUrl: v.templateXmlUrl,
        targetPath: v.targetPath,
      },
      templates: templatesXmlData,
      template: templateXmlData,
      thumbnail: thumbnailData,
      zipLevel,
      isChange: data.isChange,
      indexes: v.indexes,
      thumbPath: data.isChange ? thumbnailData : v.thumbPath,
      kijshopCd,
      orderId,
      shopOrderId,
      memo,
      templateBlob: data.temp.blob,
      displayPageType,
    }
    return result;
  }, [xml]);

  const deleteChangeTemplatePage = useCallback((_xml: XmlStructureModel, deleteList: string[]): XmlStructureModel => {
    return {
      ..._xml,
      orderInfo: !_xml.orderInfo ? undefined : {
        ..._xml.orderInfo,
        infoData: _xml.orderInfo.infoData?.map((infoData) => ({
          ...infoData,
          parts: !infoData.parts ? undefined : {
            ...infoData.parts,
            partsData: !infoData.parts.partsData ? undefined
              : infoData.parts.partsData.map((partsData) => ({
                ...partsData,
                page: !partsData.page ? undefined : {
                  ...partsData.page,
                  pageData: !partsData.page.pageData ? undefined
                    : partsData.page.pageData.map((pageData) => {
                      if (!pageData.viewModel.template) return pageData;
                      const isDelete = pageData.viewModel.template.adPath ? deleteList.includes(pageData.viewModel.template.adPath) : false;
                      const _pageData = cloneDeep(pageData);
                      _pageData.viewModel = {
                        ...pageData.viewModel,
                        additionalFrame: undefined,
                        additionalHole: undefined,
                        compositeFileName: undefined,
                        freeGraphic: undefined,
                        materials: undefined,
                        orderFrame: undefined,
                        orderPicture: undefined,
                        orderText: undefined,
                        orderTextImage: undefined,
                        originalCompositeFileName: undefined,
                        pageNoOverlap: undefined,
                        parentID: undefined,
                        template: undefined,
                      };
                      if (isDelete) {
                        return _pageData;
                      }
                      return pageData;
                    }),
                },
              })),
          },
        })),
      },
    };
  }, []);

  /* 導線 テンプレートXML取得処理ループ → アルバムセット */
  const loopXml = useCallback(async (v: AlbumLayoutType[]) => {
    const xmlList: any[] = [];
    const deleteList: string[] = [];
    for (const data of v) {
      const _xml = await onGetTemplateXml(data);
      xmlList.push(_xml);
      if (_xml.isChange) {
        deleteList.push(data.targetPath);
      }
    }
    if (xmlList.filter((v2) => v2.isChange).length) {
      const _duplicateCheckedList = xmlList.filter((v2) => v2.isChange).map((v3) => {
        return v3.displayPageType;
      });
      setDuplicateCheckedList(_duplicateCheckedList)
      // setDuplicateCheckedList(xmlList.filter((v2) => v2.isChange).map((v3) => {
      //   return v3.displayPageType;
      // }));
      setConnectingMessage('');
      await new Promise<void>((resolve) => {
        dispatch(dialogActions.pushMessage({
          title: '確認',
          message: ['使用していたテンプレートが更新されました。', '以前の編集状態が初期化されていますので、レイアウト内容を確認してください。', '', '【該当のページ】'].concat(_duplicateCheckedList).concat(['', '※画面右上にある「テンプレート変更情報の表示」からご確認いただけます。']),
          buttons: [{
            label: 'OK',
            callback: () => {
              dispatch(dialogActions.pop());
              if (xml) dispatch(xmlActions.setXml({
                shopOrderId,
                xml: cloneDeep(deleteChangeTemplatePage(xml, deleteList)),
              }));
              resolve();
            },
          }],
        }));
      });
      setConnectingMessage(`復元処理中です。(0/${v.length - xmlList.filter((v2) => v2.isChange).length})`);
    }
    setIsRestore(true);
    dispatch(layoutActions.setPage('edit'));
    dispatch(layoutActions.setRestoreTemplates(xmlList));
  }, [onGetTemplateXml, xml]);

  const callbackRestore = useCallback(() => {
    setIsRestore(false);
    dispatch(layoutActions.setPage('select'));
    getUnUsedImages();
  }, [getUnUsedImages]);

  /* メニューコールバック */
  const handlerCallbackMenu = useCallback((v) => {
    setMenuParam({
      ...menuParam,
      ...v,
    });
  }, [menuParam]);
  /* 全テンプレートコールバック */
  const handlerOnLoadAllTemplate = useCallback(() => setLoadTemplate(true), []);
  /* お気に入りテンプレートコールバック */
  const handlerOnLoadFavTemplate = useCallback(() => setLoadFavoriteTemplate(true), []);
  /* trimmingController コールバック */
  const handlerCloseTrimmingController = useCallback(() => {
    UiManager.ins.emit('r->l:close-trim-menu');
    setTrimMenu(false);
  }, []);
  /* preview コールバック */
  const handlerChangeMenuParam = useCallback((v) => {
    setMenuParam(cloneDeep(v));
  }, []);

  /* ロード終了後、復元が必要か分岐 */
  useEffect(() => {
    if (loadTemplate && loadFavoriteTemplate && xml && !loadImage && !isInit && metaData) {
      setIsInit(true);
      setConnectingMessage('使用画像を読み込んでいます。');
      getImages();
      return;
    }
    if (isLoaded && !isImageLoaded && xml) {
      setIsImageLoaded(true);
      getPngImages();
      return;
    }
  }, [isLoaded, xml, loadTemplate, loadFavoriteTemplate, metaData]);

  useEffect(() => {
    if (xml) {
      const target = xml.orderInfo?.infoData?.find((v) => v.xml.metaModel.id === orderId);
      const idList: string[] = [orderId];
      // const coverPageTypeList = store.getState().layout.pageTypeList.cover.map((v) => v.pageTypeID);
      const coverPageTypeList = pageTypeList.cover.map((v) => v.pageTypeID);
      const topPageTypeList = pageTypeList.top.map((v) => v.pageTypeID);
      const pagePageTypeList = pageTypeList.page.map((v) => v.pageTypeID);
      const endPageTypeList = pageTypeList.endCover.map((v) => v.pageTypeID);
      const opPrintTypeList = pageTypeList.opPrint.map((v) => v.pageTypeID);
      // オーダーIDごとXML
      const typeResult: string[] = [];
      const pageNumResult = {
        cover: { page: 0, pageCount: 0, pageTypeId: '' },
        top: { page: 0, pageCount: 0, pageTypeId: '' },
        page: { page: 0, pageCount: 0, pageTypeId: '', displayPageType: '' },
        endCover: { page: 0, pageCount: 0, pageTypeId: '', displayPageNo: '' },
      };
      xml.orderInfo?.infoData?.forEach((v) => {
        if (v.xml._indexes[0] === orderId.padStart(3, '0')) {
          // setTargetXml(v);
          v.parts?.partsData?.forEach((vv) => {
            vv.page?.pageData?.forEach((vvv) => {
              if (vvv.viewModel.pageType && !typeResult.find((vvvv) => vvvv === vvv.viewModel.pageType)) typeResult.push(vvv.viewModel.pageType);
              if (vvv.viewModel.pageType) {
                if (coverPageTypeList.includes(vvv.viewModel.pageType)) {
                  pageNumResult.cover.page += 1;
                  pageNumResult.cover.pageCount = Number(vvv.viewModel.pageCount ?? 0);
                  pageNumResult.cover.pageTypeId = vvv.viewModel.pageType ?? '';
                }
                if (topPageTypeList.includes(vvv.viewModel.pageType)) {
                // if (vvv.viewModel.pageType === 'jptg380013') {
                  pageNumResult.top.page += 1;
                  pageNumResult.top.pageCount = Number(vvv.viewModel.pageCount ?? 0);
                  pageNumResult.top.pageTypeId = vvv.viewModel.pageType ?? '';
                }
                if (pagePageTypeList.includes(vvv.viewModel.pageType)) {
                // if (vvv.viewModel.pageType === 'jptg380014') {
                  pageNumResult.page.page += 1;
                  pageNumResult.page.pageCount = Number(vvv.viewModel.pageCount ?? 0);
                  pageNumResult.page.pageTypeId = vvv.viewModel.pageType ?? '';
                  pageNumResult.page.displayPageType = vvv.viewModel.displayPageType ?? '';
                }
                // if ((targetXml && targetXml.xml.viewModel.goods?.productType === 'jptg300164')
                //   || vvv.viewModel.displayPageType === 'ﾌﾟﾘﾝﾄ') {
                // // if (printList.includes(vvv.viewModel.pageType)) {
                //   pageNumResult.page.page += 1;
                //   pageNumResult.page.pageCount = Number(vvv.viewModel.pageCount ?? 0);
                //   pageNumResult.page.pageTypeId = vvv.viewModel.pageType ?? '';
                //   pageNumResult.page.displayPageType = vvv.viewModel.displayPageType ?? '';
                // }
                if (endPageTypeList.includes(vvv.viewModel.pageType)) {
                // if (vvv.viewModel.pageType === 'jptg380015') {
                  pageNumResult.endCover.page += 1;
                  pageNumResult.endCover.pageCount = Number(vvv.viewModel.pageCount ?? 0);
                  pageNumResult.endCover.pageTypeId = vvv.viewModel.pageType ?? '';
                  pageNumResult.endCover.displayPageNo = vvv.viewModel.displayPageNo ?? '';
                }
              }
              // setPageTypeArr(prev => (
              //   prev.find((vvvv) => vvvv === vvv.viewModel.pageType)
              //     ? prev
              //     : [...prev, vvv.viewModel.pageType]
              // ));
            });
          });
        }
      });
      const optionInfos = xml.orderInfo?.infoData?.filter((v) => v.xml.metaModel.parentId === orderId) ?? [];
      const optionIdList: string[] = [];
      const optionList: OrderPageDataXml[] = [];
      const optionPrintList: OrderPageDataXml[] = [];
      for (const info of optionInfos) {
        for (const parts of info.parts?.partsData ?? []) {
          for (const page of parts.page?.pageData ?? []) {
            if (page.viewModel.pageType && !page.viewModel.parentID) {
              const target = album.option.find((opt) => page.viewModel.pageType === opt.xml.viewModel.pageType);
              const opPrint = album.opPrint.find((opt) => page.viewModel.pageType === opt.xml.viewModel.pageType);
              if (target || opPrint) continue;
              if (info.xml.metaModel.id && !idList.includes(info.xml.metaModel.id)) idList.push(info.xml.metaModel.id);
              if (opPrintTypeList.includes(page.viewModel.pageType)) {
                optionIdList.push(page.viewModel.pageType);
               optionPrintList.push(page);
              } else {
                optionIdList.push(page.viewModel.pageType);
               optionList.push(page);
              }
            }
          }
        }
      }
      if (!initMeta) {
        setInitMeta(true);
        getMeta(idList);
      }
      if (!isInit) {
        setPageTypeArr(cloneDeep(typeResult.concat(optionIdList)));
      }
      dispatch(layoutActions.setAlbumData({
        cover: {
          albums: album.cover.albums,
          page: pageNumResult.cover.page,
          pageCount: pageNumResult.cover.pageCount,
          pageTypeId: pageNumResult.cover.pageTypeId,
        },
        top: {
          albums: album.top.albums,
          page: pageNumResult.top.page,
          pageCount: pageNumResult.top.pageCount,
          pageTypeId: pageNumResult.top.pageTypeId,
        },
        page: {
          albums: album.page.albums,
          page: pageNumResult.page.page,
          pageCount: pageNumResult.page.pageCount,
          pageTypeId: pageNumResult.page.pageTypeId,
          displayPageType: pageNumResult.page.displayPageType,
        },
        endCover: {
          albums: album.endCover.albums,
          page: pageNumResult.endCover.page,
          pageCount: pageNumResult.endCover.pageCount,
          pageTypeId: pageNumResult.endCover.pageTypeId,
          displayPageNo: pageNumResult.endCover.displayPageNo,
        },
        option: [...album.option, ...optionList.map((v) => ({ name: '', xml: v, album: null }))],
        opPrint: [...album.opPrint, ...optionPrintList.map((v) => ({ name: '', xml: v, album: null }))],
      }));
      // PageDataTypeを取得
      const getPageDataTypes = (target:  XmlStructureOrderInfoData, retryCount: number = 1) => {
        if (target?.xml?.viewModel.category?.id && target?.xml.viewModel.item?.id) {
          dispatch(apiActions.run(
            new ApiPageDataTypes({
              serviceId: target.xml.viewModel.category.id,
              productNameId: target.xml.viewModel.item.id,
              parentFlg: '1',
            }),
            {
              onSuccess: (res: ResponseBase<PageDataTypesResponse>) => {
                if (res?.body?.data) {
                  dispatch(layoutActions.setPageDataTypesRes(res.body.data));
                }
              },
              onError: () => {
                if (retryCount > 3) {
                  dispatch(dialogActions.pushSeriousError())
                } else {
                  dispatch(dialogActions.pushMessage({
                    title: 'エラー',
                    message: ['通信エラーが発生しました'],
                    buttons: [
                      {
                        label: 'OK',
                        callback: () => {
                          dispatch(dialogActions.setErrorDialogStatus(''));
                          dispatch(dialogActions.pop());
                          getPageDataTypes(target, retryCount + 1);
                        },
                      },
                    ],
                  }));
                }
              }
            },
            { ignoreSystemError: true },
          ))
        }
      }
      
      if (target) {
        getPageDataTypes(target);
      }
    }
  }, [xml, targetXml, pageTypeList]);

  const setTextPos = useCallback((param: { pos: Pos }) => {
    setText(cloneDeep(param.pos));
  }, []);

  /* DidMount & WillUnMount */
  useEffect(() => {
    setConnectingMessage('テンプレートを読み込んでいます。');
    UiManager.ins.on('l->r: post-text-pos', setTextPos);
    dispatch(orderLockActions.check(kijshopCd, shopOrderId, {
      yes: () => {
        dispatch(orderLockActions.setId(shopOrderId));
      },
      no: () => {
        dispatch(orderLockActions.setId(''));
        dispatch(push(RoutingPath.cnv.orders({ kijshopCd })));
      },
    }));
    UiManager.ins.on('l->r:back-preparation', () => {
      dispatch(push(RoutingPath.cnv.ordersPreparation({ kijshopCd, shopOrderId })));
    })
    return (() => {
      dispatch(layoutActions.pushUnFindFontList(null));
      dispatch(layoutActions.setDeleteAlbumData([]));
      UiManager.ins.off('l->r: post-text-pos', setTextPos);
      UiManager.ins.off('l->r:back-preparation');
      dispatch(layoutActions.setIsStopExifWarning(false));
      dispatch(layoutActions.setIsSaving(false));
    });
  }, []);

  /* xml読み込み(metaに持たせたい) */
  useEffect(() => {
    if (!version) {
      dispatch(commonActions.getData(kijshopCd, { version: true }));
    }
    UiManager.ins.emit(
      'l->r:web-font:get-list',
      {
        callback: (e: { fonts: string[] }) => {
          const fontList = e.fonts.map((v, i) => ({ value: v, label: v }));
          dispatch(layoutActions.setInitFontList(fontList));
          if (fontList[0]) {
            UiManager.ins.emit('l->r:web-font:download', {
              fontFamilyName: fontList[0].label,
              callback: () => {
              },
              error: (msg: string) => {
              },
            });
          }

        },
      },
    );

  }, []);

  return (
    <div className="layout">
      <div className="layout__header_wrap">
        <div>
          {/* ヘッダー */}
          <LayoutHeader
            duplicateCheckedList={duplicateCheckedList}
            initMenu={menuParam}
            callbackMenu={handlerCallbackMenu}
          />
        </div>
        <div>
          {/* メインメニュー */}
          <LayoutMenu
            initMenu={menuParam}
            callbackMenu={handlerCallbackMenu}
            isLiteLayout={isLiteLayout}
          />
        </div>
      </div>


      <div className="layout__body">
        {/* ツールバー */}
        {page === 'edit'
          ? <LayoutToolbar
            textPos={textPos}
            menuParam={menuParam}
            callbackMenu={(v) => {
              handlerCallbackMenu(v);
            }}
            unUsedEditableList={unUsedEditableList}
            unUsedPhotoList={unUsedImageList.filter(v => v.selectCode === '1')}
            errorCount={errorCount}
          />
          : <></>
        }

        {page === 'select' ? (
          <div className="layout__body__templates">
            {/* テンプレート検索 */}
            <LayoutSearchTemplate pageTypeIdArr={pageTypeIdArr} />
            {/* 全テンプレート */}
            <LayoutALlTemplate
              pageTypeIdArr={pageTypeIdArr}
              onLoad={handlerOnLoadAllTemplate}
            />
            {/* お気に入りテンプレート */}
            <LayoutFavoriteTemplate onLoad={handlerOnLoadFavTemplate} />
          </div>
        ) : <></>}

        {/* テンプレート選択 */}
        {page === 'select' ? (
          <div className="layout__body__select">
            <LayoutSelectTemplate pageTypeIdArr={pageTypeIdArr} thumbSizeRate={tmplThumbSizeRate} />
          </div>
        ) : <></>}
        {/* テンプレート編集 */}
        {page === 'edit' ? (
          <div
            id="edit-dummy"
            className="layout__body__edit"
          >
            {trimMenu ? <TrimmingController kijshopCd={kijshopCd} closeCallback={handlerCloseTrimmingController} /> : <></>}
            <LayoutEditTemplate
              initMenu={menuParam}
              isRestore={isRestore}
              callbackRestore={() => {
                callbackRestore()}}
              xml={xml}
              pageTypeIdArr={pageTypeIdArr}
            />
          </div>
        ) : <></>}

        {/* レイアウト一覧 */}
        {/* デザイン確認用テスト配列のため、pageNum が key に利用されてるのでログにエラー出ています。 */}
        <LayoutImagePreview
          isFullWidth={page === 'list'}
          thumbSizeRate={page === 'list' ? previewThumbSizeRate : undefined}
          menuParam={menuParam}
          initCallback={handlerChangeMenuParam}
          viewPageType={pageTypeIdArr}
        />

      </div>

      <div className="layout__footer">
        {/* フッター */}
        <LayoutFooter
          xml={xml}
          targetXml={targetXml}
          clickDisplaySize={page === 'edit' ? undefined : clickDisplaySizeBtn}
        />
      </div>
      {(connectingMessage && !dialogs.length) ? (<LoadingPopup label={connectingMessage} />) : (<></>)}
      {(connectingMessage2 && !dialogs.length) ? (<LoadingPopup label={connectingMessage2} />) : (<></>)}
    </div>
  );
};
