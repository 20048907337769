import { Radiobutton } from '../../../ui/input/radiobutton';
import { useCallback, useState } from 'react';

type LabOrderInfoProps = {
  // - 代理発注モード有無 -
  surrogateOrderMode: boolean | null,
  // - 代理発注モードの有無取得ハンドラ -
  getSurrogateOrderMode: (state: boolean) => void,
};
export const SurrogateOrderModeConfig = (props: LabOrderInfoProps) => {
  const {surrogateOrderMode, getSurrogateOrderMode} = props;
  // - Callback -
  // -- 代理発注モードON/OFF --
  const handlerChangeSurrogateOrderMode = useCallback((v) => {
    getSurrogateOrderMode(v === '0');
  }, []);
  return (
    <div className="surrogate_order_mode_config mt_2rem">
      <div className="bottom_border_header">
        代理発注モード
      </div>
      <div className="top_label_ui">
        <div className="radio_buttons_box">
          <Radiobutton
            value="0"
            onChange={(e) => handlerChangeSurrogateOrderMode(e.target.value)}
            checkState={(surrogateOrderMode)? '0' : '1'}
            label="ON"
          />
          <Radiobutton
            value="1"
            onChange={(e) => handlerChangeSurrogateOrderMode(e.target.value)}
            checkState={(surrogateOrderMode) ? '0' : '1'}
            label="OFF"
          />
        </div>
      </div>
    </div>
  );
};
