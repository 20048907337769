import {useDispatch} from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import {Input} from '../../ui/input/input';
import {Button} from '../../ui/button/button';
import {favoriteTemplateActions, FavoriteTemplateItem} from '../../../slices/favorite-template-slice';
import {dialogActions} from '../slice/dialog-slice';
import { FavoriteBrandConfig } from './favorite-brand-config';
import { SaveFavoriteBrand } from './save-favorite-brand';
import { useParams } from 'react-router-dom';
import { PathParams } from '../../../routes/routing-path';
import { apiActions } from '../../../slices/api-slice';
import { ApiFavoriteFolderPost } from '../../../api/front/favorite-folder/api-favorite-folder';

type ChangeFolderNameProps = {
  kijshopCd: string,
  // - 更新するフォルダ -
  targetFolder: FavoriteTemplateItem,
  // - ボタン押下後に表示するダイアログ種類 -
  dialogType?: 'config' | 'save',
  level?: number,
  usedNameList?: string[],
  parentId?: string,
  indexes?: number,
  callback?: {
    onSuccess: () => void,
    onError: () => void,
  },
}
export const ChangeFolderName = (props: ChangeFolderNameProps) => {
  const { kijshopCd, targetFolder, dialogType, level, parentId, indexes, usedNameList, callback } = props;
  // - Hooks -
  const dispatch = useDispatch();
  // - State -
  // -- フォルダ名 --
  const [name, setName] = useState(targetFolder.name);
  // - Callback -
  // -- フォルダ名更新 --
  const handlerChangeName = useCallback((v) => {
    setName(v);
  }, []);
  // -- OKボタン押下 --
  const handlerClickOk = useCallback(() => {
    dispatch(dialogActions.pop());
    if (targetFolder) {
      if (targetFolder.name === name) {
        return;
      }
      const isUsed = usedNameList?.find((v) => v === name);
      if (!isUsed) {
        dispatch(favoriteTemplateActions.changeName({...targetFolder, ...{name}}));
        dispatch(favoriteTemplateActions.changeName({
          id: targetFolder.id,
          name: name,
          type: 'directory',
          children: targetFolder.children,
          level: targetFolder.level,
          indexes: targetFolder.indexes,
          parentId: targetFolder.parentId,
        }));
        dispatch(apiActions.run(
          new ApiFavoriteFolderPost(
            {
              id: targetFolder.id,
              kijshopCd,
              name,
              kind: dialogType ? '0' : '1',
              level: level ?? 1,
              parentId: parentId ?? '',
              indexes: indexes ?? 1,
            },
            targetFolder.id,
          ),
          {
            onSuccess: callback?.onSuccess,
            onError: callback?.onError,
          },
        ));
      } else {
        dispatch(dialogActions.pushMessage({
          title: '確認',
          message: [
            // '同一階層に同じ名称のフォルダがあります。',
            // '違う名称を設定してください。',
            'すでに存在するフォルダと同じ名称での変更はできません。'
          ],
          buttons: [
            {
              label: 'はい',
              callback: () => dispatch(dialogActions.pop()),
            },
          ],
        }));
      }
    }
  }, [name, targetFolder, usedNameList]);
  // -- キャンセルボタン押下 --
  const handlerClickCancel = useCallback(() => {
    dispatch(dialogActions.pop());
  }, [dialogType]);
  // -- keyEvent --
  useEffect(() => {
    const listener = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        handlerClickCancel();
      }
      if (e.key === 'Enter') {
        handlerClickOk();
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [name, targetFolder, usedNameList]);
  return (
    <div className='dialog_contents change_folder_name'>
      <div className='dialog_contents__body flex_direction_column' style={{margin: '8px 32px'}}>
        <div className='lh_2'>
          新しいフォルダ名を入力してください。
        </div>
        <Input
          value={name}
          onChange={(e) => handlerChangeName(e.target.value)}
          style={{width: 300}}
        />
      </div>
      <div className='dialog_contents__footer'>
        <Button
          label='キャンセル'
          onClick={handlerClickCancel}
        />
        <Button
          label='OK'
          disabled={!name.length}
          onClick={handlerClickOk}
        />
      </div>
    </div>
  );
};
