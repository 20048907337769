import {Table} from '../../ui/table/table';
import {Button} from '../../ui/button/button';
import {Checkbox} from '../../ui/input/checkbox';
import {useDispatch} from 'react-redux';
import {useCallback} from 'react';
import {dialogActions} from '../slice/dialog-slice';

export const EditPreset = () => {
  // - Hooks -
  const dispatch = useDispatch();
  // - Callback -
  // -- 閉じるボタン押下 --
  const handlerClickClose = useCallback(() => {
    dispatch(dialogActions.pop());
  }, []);
  // - テーブルbodyリスト -
  const body = [
    [<Checkbox />, 1, 'test1', 0, 0, 0, 0],
    [<Checkbox />, 2, 'test2', 0, 0, 0, 0],
    [<Checkbox />, 1234, 'test3', 0, 0, 0, 0],
  ];
  return (
    <div>
      <div>
        <Table
          head={['選択', 'ID', '名前', '中高層(mm)', '人寸(mm)', '上空き比', '下空き比']}
          body={body}
        />
      </div>
      <div>
        <div>
          <Button
            label="プリセット削除"
          />
        </div>
        <div>
          <Button
            label="閉じる"
            onClick={handlerClickClose}
          />
        </div>
      </div>
    </div>
  );
};
