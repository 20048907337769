import React, { useCallback, useEffect, useRef, useState } from "react";
import { Thumbnail } from "../thumbnail/thumbnail";
import { EditableImage, Exif } from '../../../layout-editor/manager/image-edit/editable-image';
import { Image as ImageComponent, Image } from '../image/image';
import { useParams } from 'react-router-dom';
import { PathParams } from '../../../routes/routing-path';
import { useDispatch } from 'react-redux';
import { OrderInfoDataXml } from '../../../xml/class/order/order-info-data-xml';
import { apiActions } from '../../../slices/api-slice';
import { ApiImagesGetOne } from '../../../api/front/images/api-images';
import { LoadingPopup } from '../loading/loading-popup';
import { useAppSelector } from '../../../app/hooks';
import * as lodash from 'lodash';
import { orderPreparationActions } from '../../../slices/order-preparation-slice';

type ThumbProps = {
  src: string,
  label: string,
  exif?: Exif,
  uploadStatus?: { uploaded: boolean, uploading: boolean },
}

type LayoutThumbnailZoneProps = {
  orderInfoData: OrderInfoDataXml,
}

export const LayoutThumbnailZone = (props: LayoutThumbnailZoneProps) => {
  const dispatch = useDispatch();
  const { orderInfoData } = props;
  const orderId = orderInfoData.metaModel.id;
  const { thumbImages, selectedOrderIdArr, pageTypeList, reset } = useAppSelector((state) => ({
    thumbImages: state.orderPreparation.thumbImages,
    selectedOrderIdArr: state.orderPreparation.selectedOrderIdArr,
    pageTypeList: state.common.pageTypeList,
    reset: state.orderPreparation.reset,
  }), lodash.isEqual);
  // const [thumbPropsList, setThumbPropsList] = useState<ThumbProps[]>([]);
  const thumbsGap = '1rem';
  // - Params -
  const { kijshopCd, shopOrderId } = useParams<PathParams>();
  // - State -
  const [isDragAccept, setIsDragAccept] = useState(false);
  const [isDragReject, setIsDragReject] = useState(false);
  const [thumbHeight, setThumbHeight] = useState(0);
  const [thumbsWrapMaxHeight, setThumbsWrapMaxHeight] = useState('auto');
  const [imageDataList, setImageDataList] = useState<{ name: string, path: string }[]>([...(thumbImages.find((v) => {
    return v.orderId === orderId;
  })?.images || [])]);
  const [init, setInit] = useState(true);
  // - Ref -
  const thumbEle = useRef<HTMLDivElement>(null);
  // -- サムネ初期高さ --
  useEffect(() => {
    setThumbHeight(thumbEle.current?.getBoundingClientRect().height || 0);
  }, [thumbEle, thumbEle.current]);
  // -- サムネ群のラップ要素の最大高さ設定 --
  useEffect(() => {
    // console.log({ thumbHeight });
    setThumbsWrapMaxHeight(`calc(${thumbHeight * 2}px + ${thumbsGap} + 5px)`);  // +5px スクロールしないところでバーが出る振る舞い阻止
  }, [thumbHeight]);
  useEffect(() => {
    if (init) {
      return;
    }
    if (orderInfoData.allOrderPageDataXml.length && imageDataList.length < orderInfoData.allOrderPageDataXml.length) {
      const arr: { name: string, path: string, typeId: string }[] = orderInfoData.allOrderPageDataXml
        .map((v) => v.viewModel?.compositeFileName?.virtualPath ? ({
          name: v.viewModel.displayPageNo === '***' ? (v.viewModel.displayPageType || '') : `${v.viewModel.displayPageNo || ''}頁`,
          path: v.viewModel.compositeFileName.virtualPath,
          typeId: v.viewModel.pageType,
        }) : undefined)
        .filter((v) => v) as { name: string, path: string, typeId: string }[];
      if (arr.length) {
        let cnt = 0;
        let opPrintCnt = 0;
        const OpPrintId = pageTypeList.opPrint.find(v2 => v2.type === 'op_print')?.pageTypeID ?? '';
        const OpPrintCount = arr.filter(elm => elm.typeId === OpPrintId).length;
        arr.forEach((v, i) => {
          cnt++;
          dispatch(apiActions.run(
            new ApiImagesGetOne({
              kijshopCd,
              path: `${kijshopCd}/${shopOrderId}/${v.path}`,
            }),
            {
              onSuccess: (res) => {
                const isOpPrint = pageTypeList.opPrint.find(v2 => v2.pageTypeID === v.typeId);
                const reader = new FileReader();
                reader.readAsDataURL(res);
                reader.onload = () => {
                  cnt--;
                  setImageDataList((prev) => {
                    if (!isOpPrint) {
                      const j = OpPrintCount ? i + OpPrintCount : i;
                      prev[j] = {
                        name: v.name,
                        path: reader.result as string,
                      };
                      if (!cnt && orderId) {
                        setTimeout(() => {
                          dispatch(orderPreparationActions.setCompImage({ orderId, images: [...prev] }));
                        });
                      }
                    } else {
                      prev[opPrintCnt] = {
                        name: v.name,
                        path: reader.result as string,
                      };
                      if (!cnt && orderId) {
                        setTimeout(() => {
                          dispatch(orderPreparationActions.setCompImage({ orderId, images: prev }));
                        });
                      }
                      opPrintCnt++;
                    }
                      return prev;
                  });
                };
              },
            },
          ))
        });
      }
    }
  }, [orderInfoData, init, pageTypeList]);
  useEffect(() => {
    if (init && selectedOrderIdArr.find((v) => v === orderInfoData?.metaModel?.id)) {
      setInit(false);
    }
  }, [selectedOrderIdArr]);
  /* オーダー削除時の初期化 */
  useEffect(() => {
    if (!init) {
      setInit(true);
      setImageDataList([]);
    }
  }, [reset]);
  return selectedOrderIdArr.find((v) => v === orderInfoData?.metaModel?.id) ? (
    <div className="folder_drop_zone">
      <div className="folder_drop_zone__contents">
        {/* ドロップエリア */}
        {!imageDataList.length ? (
          <div className="file_drop_zone__drop__wrap">
            <div
              className={`file_drop_zone__drop ${isDragAccept ? "drag_accept" : ""} ${isDragReject ? "drag_reject" : ""}`}
            >
              <span>「レイアウト」すると生産画像が表示されます。</span>
            </div>
          </div>
        ) : (<></>)}
        {/* アップロードした画像のリスト */}
        {imageDataList.length > 0 ? (
          <div className="folder_drop_zone__list">
            <div className="folder_drop_zone__list__thumbs">
              {/*{imageDataList.filter((v) => v?.path).map((v, i) => (*/}
              {imageDataList.map((v, i) => (
                <div
                  key={`folder-drop-zone-image_${v?.path || ''}_${i}_${orderInfoData.viewModel.item?.id}`}
                  className="folder_drop_zone__list__thumb"
                >
                  <div className="folder_drop_zone__list__thumb__label">
                    {/*<span>{pageNameConverter(v.name)}</span>*/}
                    <span>{v?.name || ''}</span>
                  </div>
                  <Thumbnail
                    key={`complete-image-thumbnail_${orderInfoData.metaModel.id}_${i}_${v?.path || ''}`}
                    thumbEle={v?.path ? <ImageComponent src={v.path} alt="" containerAspectRatio={1.5} /> : <></>}
                    index={i}
                    xmlParams={{
                      kijshopCd,
                      shopOrderId,
                      orderId: orderInfoData.metaModel.id || '',
                      outputCount: orderInfoData.viewModel.goods?.productType === 'jptg300164'
                      && orderInfoData.orderPageDataArr.length > i
                        ? Number(orderInfoData.orderPageDataArr[i]?.viewModel.pageOutputCount) : undefined,
                    }}
                    // label={v.label}
                  />
                </div>
              ))}
            </div>
          </div>
        ) : <></>}
      </div>
    </div>
  ) : (<></>);
};
