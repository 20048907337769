import { useMemo } from 'react';
import './pagination.scss';

type PaginationType = {
  totalPages: number,
  onPageChange: (itemNum: number) => void,
  activePageNum?: number,
  setActivePageNum?: (page: number) => void,
}

/**
 * @param {number} totalPageNum 合計ページ数
 * @param {(itemNum: number) => void} onPageChange ページ変更時処理
 * @param {number} activePage 表示中ページ番号
 * @param {(page: number) => void} setActivePage 表示中ページ番号の更新処理
 */
export const Pagination = (props: PaginationType) => {

  const { totalPages, onPageChange, activePageNum: _activePageNum, setActivePageNum } = props;
  const displayLength = 5;
  const arr = new Array(totalPages > displayLength ? displayLength : totalPages).fill(null);

  // - Memo -
  const activePageNum = useMemo(()=>{
    // string型で判定されてfalseなる箇所があったためその阻止のための対応
    return Number(_activePageNum);
  },[_activePageNum]);
  // -- 表示ページ番号の内の最初のアイテム番号 --
  const firstItemNum = useMemo(() => {
    if (activePageNum > totalPages || activePageNum < 1) {
      console.error('存在しないページ番号を参照しようとしています。', { activePageNum });
    }
    const diff = totalPages - displayLength;
    if (diff > 0) {
      return activePageNum <= diff ? activePageNum : diff + 1;
    } else {
      return 1;
    }
  }, [activePageNum]);

  return (
    <ul className="pagination">
      {/* pagination__prev, pagination__next デザインなしにつき仮置き */}
      <li
        onClick={() => setActivePageNum && setActivePageNum(activePageNum - 1)}
        className={`pagination__prev ${totalPages > 5 && activePageNum > 1 ? '' : 'hidden'}`}
      >
        {'<'}
      </li>
      {arr.map((v, i) => {
        const itemNum = firstItemNum + i;
        return (
          <li
            key={`pagination-item_${itemNum}`}
            className={`pagination__item ${itemNum === activePageNum ? 'active' : ''} ${!i ? 'first_item' : ''}`}
            onClick={() => {
              setActivePageNum && setActivePageNum(itemNum);
              onPageChange(itemNum);
            }}
          >
            {itemNum}
          </li>
        )
      })}
      <li
        onClick={() => setActivePageNum && setActivePageNum(activePageNum + 1)}
        className={`pagination__next ${totalPages > 5 && activePageNum < totalPages ? '' : 'hidden'}`}
      >
        {'>'}
      </li>
    </ul>
  )
}
