import { ILayoutEditorManagerBase } from '../layout-editor.manager';
import { Pos } from '../../../models/pos';
import { CjContainer, CjStage, CjText } from '../../model/cj-factory';
import { ImageEditManager } from '../image-edit/image-edit.manager';
import Worker from '../../../worker';
import { CjRect } from '../../model/cj-factory/cj-container';
import { padding } from '../../../xml/slice/xml-slice';
import { CjTool } from '../../../utilities/cj-tool';
import { cloneDeep } from 'lodash';
import { sleep } from '../../../utilities/sleep';
import { CjShadow } from '../../model/cj-factory/cj-shadow';
import { store } from '../../../app/store';
import { debugActions } from '../../../slices/debug-slice';
import { CJ_DPOBJ_NAME } from '../../model/cj-factory/cj-obj-name.collection';

type TextData = {
  pos: Pos,
  text: CjContainer,
  // text: CjText,
};

export type CreateProps = {
  data: TextData[],
};

type FileDataEntity = {
  url: string;
  x: number;
  y: number;
  h: number;
  w: number;
}

export type ResultTextData = {
  file: File,
  rect: {
    x: number;
    y: number;
    h: number;
    w: number;
  }
}

type TextSize = {
  height: number,
  width: number,
}

type ResponseData = {
  files: ResultTextData[],
  wrongFonts: TextData[],
}

/**
 *
 * TextImageManager
 *
 *  ### 処理フロー
 *  1. トリガー create()
 *  2. canvas の生成 * 2 (createjs/画像出力)
 *    - createjs : BaseCanvas
 *    - 画像出力 : OutputCanvas
 *  3. text だけ描画する cratejs を生成
 *  4. 「1.」で受け取った text を描画
 *  5. 「4.」を画像出力用 canvas に描画 (アルバムのサイズでクリッピング)
 *  6. 画像出力 (仕様に則り画像を分割)
 */
export class TextImageManager implements ILayoutEditorManagerBase {

  private debug = false;
  private imageDebug = false;
  private wrongFonts: TextData[] = [];

  initialize() {
    // NOTE: 問題があった場合すぐに削除する
    store.dispatch(debugActions.addEvent());
  }

  destroy(): void {
  }

  di(): void {
  }

  async create(props: CreateProps, tempReal: CjRect, tempVirtual: CjRect, orderDepth: [string, string, string], shopData: { kijshopCd: string, shopOrderId: string }): Promise<ResponseData> {
    if (this.debug) {
      console.groupCollapsed('[TextImageManager] create()');
      console.log('props : ', props);
    }
    this.wrongFonts = [];
    const scale = tempReal.width / tempVirtual.width
    const baseCanvas = await this.createBaseCanvas(props.data, scale, tempReal);
    const files = await this.createOutputCanvas(baseCanvas, tempReal, orderDepth, shopData);
    return Promise.resolve({files: files, wrongFonts: this.wrongFonts});
  }

  /**
   * OutputCanvas の生成
   * @private
   */
  private async createOutputCanvas(baseCanvas: HTMLCanvasElement, tempReal: CjRect, orderDepth: [string, string, string], shopData: { kijshopCd: string, shopOrderId: string }) {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    if (!ctx) {
      throw new Error('ctx is null');
    }
    const MAX_W = 2000;
    // const MAX_W = 90;
    const MAX_H = 2000;
    // const MAX_H = 90;
    const canvasW = tempReal.width;
    const canvasH = tempReal.height;
    const urls: FileDataEntity[] = [];
    for (let iY = 0; iY < Math.ceil(canvasH / MAX_H); iY += 1) {
      for (let iX = 0; iX < Math.ceil(canvasW / MAX_W); iX += 1) {
        const x = iX * MAX_W;
        const y = iY * MAX_H;
        const w = (iX + 1) * MAX_W < canvasW ? MAX_W : canvasW - iX * MAX_W;
        const h = (iY + 1) * MAX_H < canvasH ? MAX_H : canvasH - iY * MAX_H;
        canvas.width = w;
        canvas.height = h;
        ctx.drawImage(baseCanvas, x, y, w, h, 0, 0, w, h);
        const url = canvas.toDataURL('image/png');
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        urls.push({ url, x, y, w, h});
      }
    }
    const files: ResultTextData[] = [];
    this.imageDebug = store.getState().debug.debug;
    for (let i = 0; i < urls.length; i += 1) {
      const data = urls[i];
      const name = `ER_PPM_${shopData.shopOrderId}_${orderDepth[0]}_${orderDepth[1]}_${orderDepth[2]}_${padding(i + 1, 2)}.png`;
      const type = 'image/png';
      const file = await new Worker().urlToFile(data.url, name, type);
      files.push({ file, rect: {...data} });
      if (this.imageDebug) {
        await new Promise<void>((resolve) => {
          const u = URL.createObjectURL(file);
          window.open(u);
          URL.revokeObjectURL(u);
          resolve();
        })
      }
    }
    if (this.debug) {
      console.groupCollapsed('createOutputCanvas()');
      console.log('baseCanvas : ', baseCanvas);
      console.log('urls : ', urls);
      console.log('files : ', files);
      // files.forEach((v) => {
      //   fetch(URL.createObjectURL(v));
      // });
      console.groupEnd();
    }
    return files;
  }

  /**
   * BaseCanvas の生成
   * @param data
   * @param scale
   * @private
   */
  private async createBaseCanvas(data: TextData[], scale: number, rect: CjRect) {
    const { canvas, container, stage } = this.initCreateJs(rect);
    const {  container: checkContainer, stage: checkStage } = this.initCreateJs(rect);

    const checkFont = (stage: CjStage, cloneText: CjText, defaultSize: TextSize, retryCount: number = 1): Promise<boolean> => new Promise(async (resolve) => {
      stage.update()
      await sleep(1);
      const cloneSize: TextSize = {
        height: cloneText.getTransformedBounds().height,
        width: cloneText.getTransformedBounds().width,
      };
      if (cloneSize.height === defaultSize.height && cloneSize.width === defaultSize.width) {
        console.error('テキスト生成時のフォントの読み込みに失敗しました');
        if (retryCount >= 3) {
          resolve(false)
        } else {
          checkFont(stage, cloneText, defaultSize, retryCount + 1).then((bool) => resolve(bool))
        }
      } else {
        resolve(true)
      }
    });
    const copyData = await Promise.all(data.map(async(v) => ({ pos: v.pos, text: await this.cloneText(v.text, scale) })));
    // フォーカスのマージン

    for (const v of copyData) {
      const originTextData = cloneDeep(v);
      v.text.x = v.pos.x * scale;
      v.text.y = v.pos.y * scale;
      v.text.scaleX = scale;
      v.text.scaleY = scale;
      console.log(v.text);
      const _origin = v.text.children.find(v => v.name === 'text') as CjText ?? v.text;
      const defaultText = cloneDeep(_origin);
      const cloneText = cloneDeep(_origin);

      const font = `${defaultText.fontData?.isBold} ${defaultText.fontData?.isItalic} ${defaultText.fontData?.size} serif`.trim();
      defaultText.font = font;
      // NOTE: フォントの比較精度を上げるためにダミーで半角英数を設定
      defaultText.text = 'abcdefghijklmnopqrstuvwxyz1234567890';
      cloneText.text = 'abcdefghijklmnopqrstuvwxyz1234567890';
      checkContainer.addChild(defaultText);
      checkStage.update();
      const defaultSize: TextSize = {
        height: defaultText.getTransformedBounds().height,
        width: defaultText.getTransformedBounds().width,
      };
      checkContainer.removeChild(defaultText);
      checkContainer.addChild(cloneText);
      checkStage.update();
      // NOTE: フォントチェックのデバッグをしたい時はここで無理やりフォントサイズを合わせる
      // text.font = font;
      const isCheck = await checkFont(checkStage, cloneText, defaultSize);
      checkContainer.removeChild(cloneText);

      const text = CjTool.getText(v.text);
      container.addChild(v.text);

      /* NOTE: 原因不明でフォーカス枠が残ることがあるため、念の為最後に非表示化を行う */
      const focusContainer = CjTool.getTextFocus(v.text);
      if (focusContainer) {
        v.text.removeChild(focusContainer);
      }
      stage.update();
      if (!isCheck && text.fontData) {
        this.wrongFonts.push(v);
      }
      console.log('isCheck: ', this.wrongFonts);
      // ドロップシャドウはスケール対象外なのでここでoffsetを調整
      if (v.text.dropShadow && v.text.dropShadow.enable) {
        text.shadow = new CjShadow(v.text.dropShadow.transparence, Number(v.text.dropShadow.virtualSize) * scale, Number(v.text.dropShadow.virtualSize) * scale, 0);
      }
    }
    await sleep(0.03);
    stage.update();
    const url = canvas.toDataURL('image/png');
    if (this.debug) {
      console.groupCollapsed('createBaseCanvas()');
      console.log('data : ', data);
      console.log('url : ', url);
      console.groupEnd();
    }
    return canvas;
  }

  /**
   * createjs のインスタンスを生成
   * @private
   */
  private initCreateJs(realRect: CjRect): { container: CjContainer; canvas: HTMLCanvasElement; stage: CjStage } {
    const canvas = document.createElement('canvas');
    canvas.width = realRect.width;
    canvas.height = realRect.height;
    const stage = new CjStage(canvas);
    const container = new CjContainer();
    container.x = 0;
    container.y = 0;
    stage.addChild(container);
    return { canvas, container, stage };
  }

  private cloneText = (originTextContainer: CjContainer, scale: number) => new Promise<CjContainer>((resolve) => {
    const originText = CjTool.getText(originTextContainer);

    const textContainer = new CjContainer();
    /* コンテナの描画要素の指定 */
    textContainer.set({
      y: originTextContainer.y,
      x: originTextContainer.x,
      rotation: originTextContainer.rotation,
      scaleX: originTextContainer.scaleX,
      scaleY: originTextContainer.scaleY,
    });

    const text = new CjText();
    text.set({
      name: CJ_DPOBJ_NAME.text,
    })
    textContainer.addChild(text);
    /* テキストオブジェクトの描画要素の指定 */
    text.font = originText.font;
    text.fontData = cloneDeep(originText.fontData);
    text.text = originText.text;
    text.alpha = originText.alpha;
    text.color = originText.color;
    text.textAlign = originText.textAlign;
    text.lineHeight = originText.lineHeight;

    const textBounds = text.getBounds();
    switch (text.textAlign) {
      case 'center':
        text.x = textBounds.width / 2;
        text.margin = textBounds.width / 2;
        break;
      case 'right':
        text.x = textBounds.width;
        text.margin = textBounds.width;
        break;
      default:
        text.x = 0;
        text.margin = 0;
        break;
    }


    if (CjTool.checkOutline(originTextContainer)) {
      const outlineText = CjTool.getOutline(originTextContainer)?.clone();
      if (originTextContainer.dropShadow?.enable) {
        outlineText.shadow = new CjShadow(originTextContainer.dropShadow.transparence, Number(originTextContainer.dropShadow.virtualSize) * scale, Number(originTextContainer.dropShadow.virtualSize) * scale, 0);
      }
      textContainer.addChild(outlineText);
    }
    if (originText.options && originText.options.isShadow) {
      textContainer.dropShadow = originTextContainer.dropShadow;
    }
    resolve(textContainer);
  });
}
