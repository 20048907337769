import * as createjs from 'createjs-module';
import { PostCardType } from '../../../components/pages/layout/select-template/layout.select-template';

export type CategoryType =
  'scale'
  | 'rotate'
  | 'frame'
  | 'image'
  | 'mask'
  | 'focus:rotate'
  | 'focus:scale'
  | 'focus:move'
  | undefined;

export type CjRectXML = {
  width: string;
  height: string;
}

export type CjRect = {
  width: number;
  height: number;
}
export class CjContainer extends createjs.Container {
  // - base -
  pageId?: string;
  photoAreaId?: string;
  editableImageId?: string;
  category?: CategoryType;
  pageType?: '';
  path?: string;
  width = 0;
  height = 0;
  isChecked = false;
  isComposited = false;
  isFit = false;
  isRotate = false;
  copyFlag = false;
  deleteFlag = false;
  initResizeScale?: number;
  setMode?: 'in' | 'out' = 'out';
  real = {
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  };
  virtual = {
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  };
  movePos = { x: 0, y: 0 };
  defaultPos = { x: 0, y: 0 };
  restorePos = { x: 0, y: 0 };
  originalBounds = { x: 0, y: 0, width: 0, height: 0 };
  originalRotate = 0;
  postCardType?: PostCardType;
  // - picture -
  depth = 0;
  originDepth = 0;
  frameFlag?: boolean;
  logoFlag?: boolean;
  maskFlag?: boolean;
  maskName?: string;
  orgsubMaskID?: string;
  photoSeqNo?: number;
  frameId?: string;
  selectID?: string;
  subMaskID?: string;

  // - text -
  textId?: string;
  bold?: string;
  borderColor?: string;
  contactPageNoPos?: string;
  contactType?: string;
  fontColor?: string;
  fontName?: string;
  horizontalAlignment?: '0' | '1' | '2' | null;
  italic?: string;
  lineHeight?: string;
  transparence?: string;
  underLine?: string;
  text?: string;
  textSize?: string;
  borderSize?: string;
  dropShadow?: {
    enable: boolean;
    transparence: string;
    virtualSize: number;
  };

  trimFlag = false;
  marginFlag = false;
  trimPosY = 0;
  trimScale = 0;
  trimMargin = 0;
  trimSpace = 0;
  // path周りの保存用
  realPath = '';
  virtualPath = '';

  selectImageSize = {
    width: 0,
    height: 0,
  };

  differPos = {
    x: 0,
    y: 0,
  };

  addDifferPos = {
    x: 0,
    y: 0,
  };

  constructor(pageId?: string, photoAreaId?: undefined, category?: CategoryType, path?: undefined, textId?: string) {
    super();
    this.pageId = pageId;
    this.photoAreaId = pageId;
    this.category = category;
    this.path = path;
    this.textId = textId;
  }
}
