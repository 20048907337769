import {useCallback, useState} from 'react';
import {useDispatch} from 'react-redux';
import {ChangeOrderPageNum} from '../../../dialog/unique/change-order-page-num';
import {dialogActions} from '../../../dialog/slice/dialog-slice';
import ppmFavicon from '../../../../assets/img/logo/ppmfavicon.png';
import './retouch.header.scss';

type EditOpenType = 'none' | 'zoom' | 'frame-fit' | 'guide' | 'shift-key-conf';

const MenuEdit = () => {
  // - State -
  // -- メニュー表示状態管理 --
  const [openType, setOpenType] = useState<EditOpenType>('none');
  // - Callback -
  // -- 表示メニュー更新 --
  const handlerChangeOpenType = useCallback((v: EditOpenType) => {
    setOpenType(v);
  }, []);
  return (
    <div className="pull_down_menu_list menu_edit">
      <div
        className="pull_down_menu_list__item"
        onMouseOver={() => handlerChangeOpenType('zoom')}
      >
        サイズ変更
        {openType === 'zoom' ? (<></>) : (<></>)}
      </div>
      <div
        className="pull_down_menu_list__item"
        onMouseOver={() => handlerChangeOpenType('frame-fit')}
      >
        写真枠フィット設定
        {openType === 'frame-fit' ? <></> : <></>}
      </div>
      <div
        className="pull_down_menu_list__item"
        onMouseOver={() => handlerChangeOpenType('shift-key-conf')}
      >
        写真編集Shiftキー設定
        {openType === 'shift-key-conf' ? <></> : <></>}
      </div>
    </div>
  );
};

// ファイル
const MenuFile = () => {
  const dispatch = useDispatch();
  // - Callback -
  // -- デザイン中止 --
  const handlerClickDesignCancel = useCallback(() => {
    dispatch(
      dialogActions.pushMessage({
        title: 'デザイン中止',
        message: [
          '編集を中断して前のページに戻ります。全て',
          'の内容が保存されてませんが、よろしいですか？',
        ],
        buttons: [
          {
            label: 'いいえ',
            callback: () => {
              dispatch(dialogActions.pop());
            },
          },
          {
            label: 'はい',
            callback: () => {
              dispatch(dialogActions.pop());
            },
          },
        ],
      }),
    );
  }, []);
  // -- ページ数変更 --
  const handlerClickChangePageNum = useCallback(() => {
    dispatch(
      dialogActions.push({
        title: '注文ページ数変更',
        element: <ChangeOrderPageNum kijshopCd="" shopOrderId="" />,
      }),
    );
  }, []);
  // -- Inw.ppm.pageBreaker起動 --
  const handlerClickLaunchPageBreaker = useCallback(() => {
  }, []);
  // -- png画像の配置 --
  const handlerClickSetPngImage = useCallback(() => {
  }, []);
  // -- 一時保存 --
  const handlerClickSaveTemp = useCallback(() => {
    dispatch(
      dialogActions.pushMessage({
        title: '書き出し:最終確認',
        message: [`商品名:${'デザインアルバム DX2面'}`, 'で書き出しを行います。'],
        buttons: [
          {
            label: 'いいえ',
            callback: () => {
              dispatch(dialogActions.pop());
            },
          },
          {
            label: 'はい',
            callback: () => {
              dispatch(dialogActions.pop());
            },
          },
        ],
      }),
    );
  }, []);
  return (
    <div className="pull_down_menu_list menu_file">
      <div
        className="pull_down_menu_list__item"
        onClick={handlerClickDesignCancel}
      >
        デザイン中止
      </div>
      <div
        className="pull_down_menu_list__item"
        onClick={handlerClickChangePageNum}
      >
        ページ数変更
      </div>
      <div
        className="pull_down_menu_list__item"
        onClick={handlerClickLaunchPageBreaker}
      >
        Inw.ppm.pageBreaker起動
      </div>
      <div
        className="pull_down_menu_list__item"
        onClick={handlerClickSetPngImage}
      >
        png画像の配置
      </div>
      <div
        className="pull_down_menu_list__item"
        onClick={handlerClickSaveTemp}
      >
        一時保存
      </div>
    </div>
  );
};

type OpenType = 'none' | 'file' | 'edit' | 'window' | 'info' | 'conf' | 'help';

export const RetouchHeader = () => {
  return (
    <div className="retouch_header">
      <div className="retouch_header__left">
        <div className="retouch_header__logo">
          <img src={ppmFavicon} alt="" />
        </div>
      </div>
    </div>
  );
};
