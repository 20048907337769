import { forwardRef, useRef } from 'react';
import './tooltip.scss';

export type TooltipProps = {
  messages: string[],
  error?: boolean,
  balloonDirection?: "top" | "bottom",
} & React.HTMLAttributes<HTMLDivElement>;

/** 
* @param {string[]} messages ツールチップ内メッセージ。
* @param {boolean} error エラーモードにするか
* @param {"top" | "bottom"} balloonDirection 吹き出しのツノの向き(上下)。エラーモードにしていなければ不要。
*/

export const Tooltip = forwardRef((props: TooltipProps, ref: React.LegacyRef<HTMLDivElement>) => {
  const { messages, error, balloonDirection = "top", ...defaultProps } = props;

  return (
    <div
      {...defaultProps}
      className={`tooltip${error ? ' error' : ''}${defaultProps.className ? ` ${defaultProps.className}` : ''}`}
      ref={ref}
    >
      {messages.map((v, i) => (<div key={`tooltip-message-row_${i}`} className="tooltip__message_row">{v}</div>))}
      {error ? <div className={`triangle_icon ${balloonDirection}`} /> : (<></>)}
    </div>
  );
});