import { useDispatch } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import { push } from 'connected-react-router';
import { PathParams, RoutingPath } from '../../../routes/routing-path';
import { SystemConfig } from './system-config/system-config';
import { CommonButton } from '../../ui/button/common-button';
import { Header } from '../../ui/header/header';
import { useParams } from 'react-router-dom';
import { dialogActions } from '../../dialog/slice/dialog-slice';
import { useAppSelector } from '../../../app/hooks';
import * as lodash from 'lodash';
import { systemConfigActions } from '../../../slices/system-config-slice';
import { commonActions } from '../../../slices/common-slice';

// - 設定種類 -
type ConfigProps = {
  type: 'system',
};

export const Config = (props: ConfigProps) => {
  const { type } = props;
  const { kijshopCd, shopOrderId, orderId } = useParams<PathParams>();
  const { initShopCode, initMetaData, version, isStaffManagementAuth, isStaffLogin,staffInfo } = useAppSelector((state) => ({
    initShopCode: state.systemConfig.shopCode,
    initMetaData: state.systemConfig.metaData,
    version: state.common.data.version,
    isStaffManagementAuth: state.common.isStaffManagementAuth,
    isStaffLogin: !!state?.common?.staffInfo?.staffId || false,
    staffInfo: state.common.staffInfo,
  }), lodash.isEqual);

  // - Hooks -
  const dispatch = useDispatch();

  // - State -
  // -- 店舗コード --
  const [shopCode, setShopCode] = useState(initShopCode);
  // -- 校正承認 --
  const [isNeedAgreement, setIsNeedAgreement] = useState<boolean>(initMetaData.isNeedAgreement);
  // -- 代理発注モード --
  const [surrogateOrder, setSurrogateOrder] = useState<boolean | null>(initMetaData.surrogateOrder);
  // -- スタッフ管理機能 --
  const [isStaffManagement, setIsStaffManagement] = useState<boolean>(isStaffManagementAuth && !staffInfo?.staffId);
  // - Callback -
  // -- 保存して終了 --
  const handlerClickSaveToFinish = useCallback(() => {
    dispatch(dialogActions.pushMessage({
      title: '確認',
      message: [
        '現在設定されている内容を保存して終了します。',
        'よろしいですか？',
      ],
      buttons: [
        {
          label: 'いいえ',
          callback: () => dispatch(dialogActions.pop()),
        },
        {
          label: 'はい',
          callback: () => {
            dispatch(systemConfigActions.setShopCode(shopCode));
            dispatch(systemConfigActions.post({
              kijshopCd: kijshopCd,
              version: version,
              isNeedAgreement: isNeedAgreement,
              surrogateOrder: surrogateOrder,
            }, () => {
            }));
            dispatch(dialogActions.pop());
            dispatch(push(RoutingPath.cnv.orders({ kijshopCd })));
          },
        },
      ],
    }));
  }, [shopCode, isNeedAgreement, surrogateOrder, isStaffManagement]);
  // -- 保存しないで終了 --
  const handlerClickFinish = useCallback(() => {
    if (initMetaData.isNeedAgreement !== isNeedAgreement || initMetaData.surrogateOrder !== surrogateOrder) {
      dispatch(dialogActions.pushMessage({
        title: '確認',
        message: [
          '保存していない内容は失われます。',
          '終了してもよろしいですか。',
        ],
        buttons: [
          {
            label: 'いいえ',
            callback: () => dispatch(dialogActions.pop()),
          },
          {
            label: 'はい',
            callback: () => {
              dispatch(dialogActions.pop());
              dispatch(push(RoutingPath.cnv.orders({ kijshopCd })));
            },
          },
        ],
      }));
    } else {
      dispatch(push(RoutingPath.cnv.orders({ kijshopCd })));
    }
  }, [isNeedAgreement, surrogateOrder]);
  useEffect(() => {
    if (!initShopCode) {
      setShopCode(kijshopCd);
    }
  }, [initShopCode, kijshopCd]);
  useEffect(() => {
    dispatch(systemConfigActions.get(kijshopCd));
  }, []);
  useEffect(() => {
    setIsNeedAgreement(initMetaData.isNeedAgreement);
    setSurrogateOrder(initMetaData.surrogateOrder);
  }, [initMetaData]);
  useEffect(() => {
    dispatch(commonActions.checkStaffManagementAuth(kijshopCd));
    dispatch(commonActions.getStaffLoginInfo(kijshopCd));
    setIsStaffManagement(isStaffManagementAuth && !staffInfo?.staffId);
  }, [isStaffManagementAuth,staffInfo?.staffId]);
  return (
    <div>
      <Header />
      <div className="px_2rem">
        <div className="flex_space_between mt_1rem mb_5">
          <CommonButton
            commonType="return"
            label="注文一覧に戻る"
            onClick={handlerClickFinish}
          />
          <CommonButton
            commonType="save"
            onClick={handlerClickSaveToFinish}
          />
        </div>
        <div>
          {type === 'system' ? (
            <SystemConfig
              shopCode={shopCode}
              getShopCode={(code) => setShopCode(code)}
              isNeedAgreement={isNeedAgreement}
              getIsNeedAgreement={(state) => setIsNeedAgreement(state)}
              surrogateOrderMode={surrogateOrder}
              getSurrogateOrderMode={(state) => setSurrogateOrder(state)}
              isStaffManagement={isStaffManagement}
              getIsStaffManagement={(state) => setIsStaffManagement(state)}
              isStaffLogin={!!staffInfo?.staffId}
            />
          ) : (<></>)}
        </div>
      </div>

    </div>
  );
};
