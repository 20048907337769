import React, { useCallback, useState } from 'react';
import { Button } from '../../ui/button/button';
import { Switch } from '../../ui/switch/switch';
import './select-img-layout-order.scss';

type SortType = "名前順" | "撮影日付順";
type HighLow = "昇順" | "降順";
type SelectImgLayoutOrderProps = {
  onClickDetermine: () => void,
  setSort?: (v: SortType) => void,
  setHighLow?: (v: HighLow) => void,
};

export const SelectImgLayoutOrder = (props: SelectImgLayoutOrderProps) => {
  const { onClickDetermine, setSort, setHighLow: _setHightLow } = props;
  const sortTypes: SortType[] = ["名前順", "撮影日付順"]
  const heighLowStrs: HighLow[] = ["昇順", "降順"]
  // - State -
  // -- ソートの種類 --
  const [sortType, setSortType] = useState(sortTypes[0]);
  // -- 昇順降順 --
  const [highLow, setHighLow] = useState(heighLowStrs[0]);
  // - Callback -
  const onClickSort = useCallback((v: SortType) => {
    setSort && setSort(v);
    setSortType(v);
  }, []);
  const onClickHighLow = useCallback((v: HighLow) => {
    _setHightLow && _setHightLow(v);
    setHighLow(v);
  }, []);

  return (
    <div className="dialog_contents select_img_layout_order">
      <div className="dialog_contents__body">
        <div>画像のレイアウト順を選択し、「決定」ボタンを押してください。</div>
        <div className="select_img_layout_order__switches">
          <Switch
            labels={sortTypes}
            onClickButton={(v) => onClickSort(v as SortType)}
            selectedValue={sortType}
          />
          <Switch
            labels={heighLowStrs}
            onClickButton={(v) => onClickHighLow(v as HighLow)}
            selectedValue={highLow}
          />
        </div>
      </div>

      <div className="dialog_contents__footer">
        <Button
          label="決定"
          onClick={onClickDetermine}
        />
      </div>
    </div>
  );
}