import { ApiBaseFront } from '../api-base-front';

export type MetaShopData = {
  kijshopCd: string,
  version: string,
  isNeedAgreement: boolean,
  surrogateOrder: boolean | null,
}
export type MetaShopDataResponse = {
  kijshopCd?: string,
  version?: string,
  isNeedAgreement: 'true' | 'false',
  surrogateOrder: 'true' | 'false' | 'null',
}


export class ApiGetMetaShop extends ApiBaseFront<MetaShopDataResponse> {
  constructor(kijshopCd: string) {
    super(
      'GET',
      `/api/v1/meta/shop/${kijshopCd}`,
      'JSON',
      {},
    );
  }
}

export class ApiPostMetaShop extends ApiBaseFront<any> {
  constructor(kijshopCd: string, param: MetaShopData) {
    super(
      'POST',
      `/api/v1/meta/shop/${kijshopCd}`,
      'JSON',
      {
        data: {
          kijshopCd: param.kijshopCd,
          version: param.version,
          isNeedAgreement: String(param.isNeedAgreement),
          surrogateOrder: String(param.surrogateOrder),
        }
      },
    );
  }
  test = (param: MetaShopData) => {
    const isNeedAgreement = param.isNeedAgreement;
    const surrogateOrder = param.surrogateOrder;
    return {
      isNeedAgreement: isNeedAgreement,
      surrogateOrder: surrogateOrder,
    };
  };

}