import './c-folder-order-list.scss';
import React, { useCallback } from 'react';
import { Checkbox } from '../../ui/input/checkbox';
import { PurposeInfo } from '../../../api/back/purpose/api-get-purpose';

type Props = {
  purposeList: PurposeInfo[],
  selectPurpose: string[],
  setPurposeFilter: (list: string[]) => void,
};

export const PurposeFilter = (props: Props) => {

  const { purposeList, selectPurpose, setPurposeFilter } = props;

  const checkSelect = useCallback((value: string): boolean => {
    if (value === 'all') {
      return purposeList.length <= selectPurpose.length;
    }
    return selectPurpose.find((v) => v === value) !== undefined;
  }, [selectPurpose]);

  const handlerSelectTarget = useCallback((check: boolean, value: string) => {
    let arr: string[] = [];
    if (value === 'all') {
      if (!check) {
        arr = [...purposeList.map((v) => v.purposeId), ''];
      }
    } else {
      if (check) {
        arr = selectPurpose.filter((v) => v !== value);
      } else {
        arr = [...selectPurpose, value];
      }
    }
    setPurposeFilter(arr);
  }, [selectPurpose, setPurposeFilter]);

  return (
    <>
      <div className="purpose_filter">
        <div className={'purpose_filter__content'}>

          <Checkbox
            value={'all'}
            checked={checkSelect('all')}
            label={'すべて'}
            onClick={() => handlerSelectTarget(checkSelect('all'), 'all')}
          />
        </div>
        {
          purposeList.map((v) => (
            <div
              className={'purpose_filter__content'}
              key={`autoSaveSelect-${v.purposeId}`}
            >
              <Checkbox
                value={v.purposeId}
                checked={checkSelect(v.purposeId)}
                label={v.purposeName}
                onClick={() => handlerSelectTarget(checkSelect(v.purposeId), v.purposeId)}
              />
            </div>
          ))
        }
        <div className={'target_filter__content'}>
          <Checkbox
            value={''}
            checked={checkSelect('')}
            label={'未設定'}
            onClick={() => handlerSelectTarget(checkSelect(''), '')}
          />
        </div>
      </div>
    </>
  );
};
