import { XmlStructureModel } from '../xml/model/xml-structure-model';
import { store } from '../app/store';
import { apiActions } from '../slices/api-slice';
import { ApiUploadXml } from '../api/front/xml/api-upload-xml';
import { UiManager } from '../layout-editor/manager/ui/ui.manager';
import { dialogActions } from '../components/dialog/slice/dialog-slice';
import { layoutActions } from '../slices/layout-slice';
import { xmlActions } from '../xml/slice/xml-slice';

export class SelectIdChecker {
  static check = async (_xml: XmlStructureModel, kijshopCd: string, shopOrderId: string, orderId: string, isComp: boolean = false ): Promise<void> => new Promise((resolve) => {
    let xml = _xml
    store.dispatch(xmlActions.getXml(kijshopCd, shopOrderId, (res) => {
      if (res) {
        xml = res;
      }
    }))
    const optionList = xml.orderInfo?.infoData?.filter((v) => v.xml.metaModel.parentId === orderId)
      .filter((v) => v.parts?.partsData?.filter((v2) => v2.page?.pageData?.filter?.((v3) => !v3.viewModel.parentID).length).length);
    const optionParts = (optionList?.map((v) => v.parts?.partsData ?? []) ?? []).flat();
    const orderInfo = xml.orderInfo?.infoData?.find((infoData) => infoData.xml.metaModel.id === orderId);
    if (!orderInfo) {
      resolve();
      return;
    };
    if (!isComp && orderInfo.xml.metaModel.layoutFinish !== '3') {
      resolve();
      return;
    }
    const orderParts = orderInfo.parts?.partsData?.concat(optionParts);
    const orderPages = orderParts?.map((elm) => elm.page?.pageData).flat();
    const orderSelectIds = xml.orderSelect?.metaModel.imageData?.map(v => v.selectID);
    if (orderPages) {
      const messages: string[] = [];
      for (const orderPage of orderPages) {
        const pictures = orderPage?.viewModel.orderPicture?.data;
        if (!pictures) continue;
        for (const picture of pictures) {
          if (picture.deleteFlag !== '1' && picture.logoFlag === "0") {
            if (Boolean(picture.selectID)) {
              const check = orderSelectIds?.find(v => v === picture.selectID);
              if (check) {
                break;
              }
            }
          const isPage = store.getState().layout.pageTypeList.page.find(v => v.pageTypeID === orderPage!.viewModel.pageType)
          store.dispatch(layoutActions.setWarningList(`${orderPage!.viewModel.displayPageType} : ${orderPage!.viewModel.displayPageNo}`));
          let message: string;
          if (isPage) {
            message = `・ページ種: ${orderPage!.viewModel.displayPageType ?? '頁'} ページ: ${orderPage!.viewModel.displayPageNo ?? '-'}`;
          } else {
            message = `・ページ種: ${orderPage!.viewModel.displayPageType ?? 'その他'}`;
          }
          messages.push(message);
          }
        }
      }
      if (messages.length) {
        orderInfo.xml.metaModel.layoutFinish = '3';
        orderInfo.xml.build();
        const files: {
          filename: string,
          body: string,
          is_create: '0' | '1'
        }[] = [{
          filename: orderInfo.xml.xmlUniqueName,
          body: orderInfo.xml.xml,
          is_create: '0',
        }]
        store.dispatch(apiActions.run(new ApiUploadXml(kijshopCd, shopOrderId, { files }), {
          onSuccess: () => {
            if (isComp) {
              store.dispatch(layoutActions.setIsSelectIdError(true));
              resolve();
            } else {
              messages.unshift(``);
              messages.unshift(`下記のページを確認してください。`);
              messages.unshift(`写真の連携に失敗したページがあります。`);
            store.dispatch(dialogActions.pushMessage({
              title: '確認',
              message: messages,
              buttons: [
                {
                  label: 'OK',
                  callback: () => {
                    store.dispatch(dialogActions.pop());
                    resolve();
                  },
                },
              ],
            }))
          }
          },
        }));
      } else {
        resolve();
      }
    } else {
      resolve();
    }
  })
}
