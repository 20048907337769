export const EditableEventCollection = [
  'r->l:dis-select',
  'r->l:image-resize-in/out',
  'r->l:image-guide-on/off',
  'r->l:shift-move',
  'r->l:resize',
  'r->l:rotate',
  'r->l:delete',
  'r->l:clone',
  'r->l:move-mode',
  'r->l:insert-text:start',
  'r->l:reload-text',
  'r->l:insert-text:end',
  'r->l:delete-text',
  'r->l:finish-text-edit',
  'r->l:center-line',
  'r->l:ruler',
  'r->l:add-editable-image',
  'r->l:change-operation-cursor',
  'r->l:reset',
  'r->l:change-image/frame',
  'r->l:editable-image:drag:check',
  'r->l:editable-image:drop',
  'r->l:editable-image:drop:direct',
  'r->l:editable-image:drop:temp',
  'r->l:rotate-additional',
  'r->l:transform-additional',
  'r->l:transparent-additional',
  'r->l:change-middle-frame',
  'r->l:check:open-trim-menu',
  'r->l:trim:start',
  'r->l:show-trim-line',
  'r->l:show-guide-line',
  'r->l:show-select-guide',
  'r->l:create-guide:start',
  'r->l:delete-guide',
  'r->l:close-trim-menu',
  'r->l:shortcut:save-layout:focus-out',
];