// $ npm i transform-parser

import { parse, stringify, TransformObject } from 'transform-parser';

export class TransformParser {

  parse(transform: string) {
    return parse(transform);
  }

  build(transform: TransformObject) {
    return stringify(transform);
  }

}
