import { OrderInfoXml } from '../class/order/order-info-xml';
import { OrderInfoDataXml } from '../class/order/order-info-data-xml';
import { XmlClassName } from '../model/xml-class-name';
import { CustomerXml } from '../class/customer-xml';
import { DeliveryXml } from '../class/delivery-xml';
import { InfoXml } from '../class/info-xml';
import { ShopXml } from '../class/shop-xml';
import { StatusXml } from '../class/status-xml';
import { SummaryXml } from '../class/summary-xml';
import { OrderPageXml } from '../class/order/order-page-xml';
import { OrderPageDataXml } from '../class/order/order-page-data-xml';
import { OrderPageBreakXml } from '../class/order/order-page-break-xml';
import { OrderPartsDataXml } from '../class/order/order-parts-data-xml';
import { OrderPartsXml } from '../class/order/order-parts-xml';
import { OrderSelectXml } from '../class/order/order-select-xml';
import { ApiMasterXml } from '../../api/back/xml/api-master-xml';
import { XmlParser } from '../../manager/xml-parser';
import { commonActions } from '../../slices/common-slice';

type ReturnType<T> =
  T extends 'customer' ? CustomerXml :
  T extends 'delivery' ? DeliveryXml :
  T extends 'order' ? InfoXml :
  T extends 'shop' ? ShopXml :
  T extends 'status' ? StatusXml :
  T extends 'summary' ? SummaryXml :
  T extends 'order-info' ? OrderInfoXml :
  T extends 'order-info-data' ? OrderInfoDataXml :
  T extends 'order-page' ? OrderPageXml :
  T extends 'order-page-data' ? OrderPageDataXml :
  T extends 'order-page-break' ? OrderPageBreakXml :
  T extends 'order-parts' ? OrderPartsXml :
  T extends 'order-parts-data' ? OrderPartsDataXml :
  T extends 'order-select' ? OrderSelectXml : null

type TIndexes<T> =
  T extends 'customer' ? undefined :
  T extends 'delivery' ? undefined :
  T extends 'order' ? undefined :
  T extends 'shop' ? undefined :
  T extends 'status' ? undefined :
  T extends 'summary' ? undefined :
  T extends 'order-info' ? undefined :
  T extends 'order-info-data' ? [number | string] :
  T extends 'order-page' ? [number | string, number | string] :
  T extends 'order-page-data' ? [number | string, number | string, number | string] :
  T extends 'order-page-break' ? [number | string] :
  T extends 'order-parts' ? [number | string] :
  T extends 'order-parts-data' ? [number | string, number | string] :
  T extends 'order-select' ? undefined : undefined;

export class XmlFactory {

  private static _version: string = '';
  static set version(v: string) {
    this._version = v;
  }

  static async checkVersion(): Promise<boolean> {
    return Boolean(this._version) || await new ApiMasterXml('master').do()
      .then((res) => {
        if (!(res as any)?.body?.data) {
          return false;
        }
        return new XmlParser()
          .parse<any>(((res as any).body.data))
          .then((result) => {
            if (result?.lnwMaster?.$?.version) {
              this._version = result.lnwMaster.$.version;
              return true;
            }
            return false;
          })
          .catch(() => false);
      })
      .catch(() => false);
  }

  static createFromIndex<T extends XmlClassName>(props: { shopOrderId: string, data: { name: T, indexes?: TIndexes<T> }}): ReturnType<T> {
    const { shopOrderId, data } = props;
    const { name, indexes } = data;
    const version = XmlFactory._version;
    switch (name) {
      case 'customer':
        return new CustomerXml(shopOrderId, { version }) as ReturnType<T>;
      case 'delivery':
        return new DeliveryXml(shopOrderId, { version }) as ReturnType<T>;
      case 'order':
        return new InfoXml(shopOrderId, { version }) as ReturnType<T>;
      case 'shop':
        return new ShopXml(shopOrderId, { version }) as ReturnType<T>;
      case 'status':
        return new StatusXml(shopOrderId, { version }) as ReturnType<T>;
      case 'summary':
        return new SummaryXml(shopOrderId, { version }) as ReturnType<T>;
      case 'order-info':
        return new OrderInfoXml(shopOrderId, { version }) as ReturnType<T>;
      case 'order-info-data':
        return new OrderInfoDataXml(shopOrderId, { version, indexes: [...(indexes as TIndexes<'order-info-data'>)] }) as ReturnType<T>;
      case 'order-page':
        return new OrderPageXml(shopOrderId, { version, indexes: [...(indexes as TIndexes<'order-page'>)] }) as ReturnType<T>;
      case 'order-page-data':
        return new OrderPageDataXml(shopOrderId, { version, indexes: [...(indexes as TIndexes<'order-page-data'>)] }) as ReturnType<T>;
      case 'order-page-break':
        return new OrderPageBreakXml(shopOrderId, { version, indexes: [...(indexes as TIndexes<'order-page-break'>)] }) as ReturnType<T>;
      case 'order-parts-data':
        return new OrderPartsDataXml(shopOrderId, { version, indexes: [...(indexes as TIndexes<'order-parts-data'>)] }) as ReturnType<T>;
      case 'order-parts':
        return new OrderPartsXml(shopOrderId, { version, indexes: [...(indexes as TIndexes<'order-parts'>)] }) as ReturnType<T>;
      case 'order-select':
        return new OrderSelectXml(shopOrderId, { version }) as ReturnType<T>;
      default:
        return null as ReturnType<T>;
    }
  }

  // 生成時点で親のXMLから index を判定するため使用しない予定
  // static createFromName<T extends XmlClassName>(props: { shopOrderId: string, data: { name: T, uniqueName: string, parentName: string }}): ReturnType<T> {
  //   const { shopOrderId, data: _data } = props;
  //   const { name, ...data } = _data;
  //   switch (name) {
  //     case 'customer':
  //       return new CustomerXml(shopOrderId, data ) as ReturnType<T>;
  //     case 'delivery':
  //       return new DeliveryXml(shopOrderId, data) as ReturnType<T>;
  //     case 'order':
  //       return new InfoXml(shopOrderId, { uniqueName: data.uniqueName, parentName: '' }) as ReturnType<T>;
  //     case 'shop':
  //       return new ShopXml(shopOrderId, data) as ReturnType<T>;
  //     case 'status':
  //       return new StatusXml(shopOrderId, data) as ReturnType<T>;
  //     case 'summary':
  //       return new SummaryXml(shopOrderId, data) as ReturnType<T>;
  //     case 'order-info':
  //       return new OrderInfoXml(shopOrderId, data) as ReturnType<T>;
  //     case 'order-info-data':
  //       return new OrderInfoDataXml(shopOrderId, data) as ReturnType<T>;
  //     case 'order-page':
  //       return new OrderPageXml(shopOrderId, data) as ReturnType<T>;
  //     case 'order-page-data':
  //       return new OrderPageDataXml(shopOrderId, data) as ReturnType<T>;
  //     case 'order-page-break':
  //       return new OrderPageBreakXml(shopOrderId, data) as ReturnType<T>;
  //     case 'order-parts-data':
  //       return new OrderPartsDataXml(shopOrderId, data) as ReturnType<T>;
  //     case 'order-parts':
  //       return new OrderPartsXml(shopOrderId, data) as ReturnType<T>;
  //     case 'order-select':
  //       return new OrderSelectXml(shopOrderId, data) as ReturnType<T>;
  //     default:
  //       return null as ReturnType<T>;
  //   }
  // }

}
