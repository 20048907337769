import { Checkbox } from '../../../ui/input/checkbox';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Image } from '../../../ui/image/image';
import './retouch.use-image.scss';
import iconAdjust2Screen from '../../../../assets/img/icon/adjust2screen.svg';
import { useAppSelector } from '../../../../app/hooks';
import * as lodash from 'lodash';
import { UiManager } from '../../../../layout-editor/manager/ui/ui.manager';
import { EditableImage } from '../../../../layout-editor/manager/image-edit/editable-image';
import { EditableImageManager } from '../../../../layout-editor/manager/editable-image/editable-image.manager';
import { DynamicTooltip } from '../../../ui/tooltip/dynamic-tooltip';
import { useDispatch } from 'react-redux';
import { apiActions } from '../../../../slices/api-slice';
import { ApiRetouchGet, RetouchGetResponse, SimpleRetouchInfo } from '../../../../api/back/retouch/api-retouch';
import { ResponseBase } from '../../../../api/response-base';
import { ApiImagesGetOne } from '../../../../api/front/images/api-images';
import { useParams } from 'react-router-dom';
import { PathParams } from '../../../../routes/routing-path';
import { ImageCheckItem, retouchActions, ShowMenuType } from '../../../../slices/retouch-slice';
import { cloneDeep } from 'lodash';
import { XmlStructureModel } from '../../../../xml/model/xml-structure-model';
import { InitRetouchCheck } from '../retouch';
import { OrderSelectImageData } from '../../../../xml/model/order/xml-order-select-model';
import { LoadingPopup } from '../../../ui/loading/loading-popup';
import { LayoutXmlUtile } from '../../../../layout-editor/model/layout.xml.utile';
import { DateFormatter } from '../../../../utilities/date-formatter';

type ItemData = {
  name: string,
  used: boolean,
  garbage: boolean,
  dirt: boolean,
  img: string,
  date: Date,
};

const getFormatDate = (date: Date): string => {
  const fullDate = new Intl.DateTimeFormat('jp').format(date);
  const time = new Intl.DateTimeFormat('jp', { hour: '2-digit', minute: '2-digit' }).format(date);
  return fullDate + ' ' + time;
};

type OneProps = {
  // - 画像リスト -
  imageList: EditableImage[],
  // - 選択中画像のリストのindex -
  oneIndex: number,
  // - 画像のindexを取得するハンドラ -
  onGetIndex: (index: number) => void,
  // - 画像のごみフラグを取得するハンドラ -
  onGetCcdFlag: (garbage: boolean, dirt: boolean) => void,
  // // - 一括変更するgarbage値 -
  // iniGarbage: boolean,
  // // - 一括変更するdirt値 -
  // iniDirt: boolean,
  // - 画像一覧からごみチェック操作したフラグ -
  isToOne: boolean,
  // - 画像一覧からごみチェック操作したフラグ更新ハンドラ -
  changeToOneFlag: () => void,
  // - レタッチ項目一覧 -
  retouchList: SimpleRetouchInfo[],
  // - 一括でチェック操作された項目の状態 -
  retouchItemState: AllCheckItem | undefined,
  // - 「CCDゴミ」か「CCDゴミ＋バック紙よごれ（ゴミ）」か -
  garbageOrDirtId: string,
  xml: XmlStructureModel | null,
  limitEle: HTMLElement | null,
}
type ScaleBtnType = 'plus' | 'minus' | 'fit';
const One = (props: OneProps) => {
  const {
    imageList,
    oneIndex,
    onGetIndex,
    onGetCcdFlag,
    isToOne,
    changeToOneFlag,
    retouchList,
    retouchItemState,
    garbageOrDirtId,
    xml,
    limitEle,
  } = props;
  const _imageCheckList = useAppSelector((v) => v.retouch.imageCheckItemList, lodash.isEqual);
  const dispatch = useDispatch();
  // - State -
  const initScale = 1;
  const [scale, setScale] = useState(initScale);
  const [index, setIndex] = useState(oneIndex);
  const [formatDate, setFormatDate] = useState('');
  const [garbage, setGarbage] = useState(imageList[index].flags.retouchCcd);
  const [dirt, setDirt] = useState(imageList[index].flags.retouchCcdPack);
  // const [prevIniGarbage, setPrevIniGarbage] = useState(iniGarbage);
  // const [prevIniDirt, setPrevIniDirt] = useState(iniDirt);
  const [centerAspectRatio, setCenterAspectRatio] = useState(0);
  const [scaleBtnTooltipType, setScaleBtnTooltipType] = useState<ScaleBtnType | 'none'>('none');
  // - Memo -
  const imageCheckList = useMemo(() => {
    return _imageCheckList?.find((v) => v.id === imageList[index].id)?.checkList || [];
  }, [_imageCheckList, imageList, index]);
  // - Ref -
  const thumbEle = useRef<HTMLDivElement>(null);
  const scaleBtnEle = useRef<React.RefObject<HTMLDivElement>[]>(new Array(3).fill(React.createRef()));

  const itemName = useMemo(() => {
    // const find = xml?.orderSelect?.metaModel?.imageData?.find((v) => v.selectFileName?.real.path === imageList[index].name);
    // return find?.originalFileName?.real.path ?? '';
    return imageList[index].name;
  }, [imageList, index, xml]);

  const itemCreateDate = useMemo(() => {
    return imageList[index].exif.createDate ? DateFormatter.date2str(imageList[index].exif.createDate) : '';
  }, [imageList, index]);

  // - Callback -
  const handlerChangeScale = useCallback((v) => {
    if (v < 0.1 || v > 4) {
      return;
    }
    setScale(v);
  }, []);
  const handlerChangeImage = useCallback((v) => {
    if (v < 0) {
      setIndex(imageList.length - 1);
      onGetIndex(imageList.length - 1);
    } else if (v >= imageList.length) {
      setIndex(0);
      onGetIndex(0);
    } else {
      setIndex(v);
      onGetIndex(v);
    }
  }, [onGetIndex]);
  const handlerChangeGarbage = useCallback((v) => {
    setGarbage(v);
    UiManager.ins.emit('r->l:change-editable-image-ccd-flags', {
      editableImageId: imageList[index].id,
      flag: v,
      isPack: false,
    });
  }, [index]);
  const handlerChangeDirt = useCallback((v) => {
    setDirt(v);
    UiManager.ins.emit('r->l:change-editable-image-ccd-flags', {
      editableImageId: imageList[index].id,
      flag: v,
      isPack: true,
    });
  }, [index]);
  const handlerHoverScaleButton = useCallback((v: {
    mouseEnter: boolean,
    buttonType?: ScaleBtnType,
  }) => {
    setScaleBtnTooltipType(v.mouseEnter ? v.buttonType || 'none' : 'none');
  }, []);

  const handlerChangeChecked = useCallback((item: SimpleRetouchInfo, checked: boolean) => {
    if (!imageCheckList) return;
    if (!_imageCheckList) return;
    if (!imageCheckList?.includes(item.productNameId)) {
      dispatch(retouchActions.setImageCheckItemList(
        _imageCheckList.map((v) => {
          const _list = cloneDeep(v.checkList);
          if (v.id === imageList[index].id) {
            _list.push(item.productNameId);
            if (item.productNameId === garbageId || item.productNameId === dirtId) {
              const filterId = item.productNameId === garbageId ? dirtId : garbageId;
              return { ...v, checkList: _list.filter((v2) => v2 !== filterId) };
            }
            return { ...v, checkList: _list };
          } else {
            return v;
          }
        }),
      ));
    } else {
      dispatch(retouchActions.setImageCheckItemList(
        _imageCheckList.map((v) => {
          if (v.id === imageList[index].id) {
            return { ...v, checkList: v.checkList.filter((v) => v !== item.productNameId) };
          } else {
            return v;
          }
        }),
      ));
    }
    // dispatch(retouchActions.changeImageCheckItemList({id: editableImage.id, checkItem: item.productNameId, isCheck: checked}));
  }, [imageCheckList, imageList, index, _imageCheckList]);

  // - Effect -
  useEffect(() => {
    setIndex(oneIndex);
    // if (imageList.length) {
      // setFormatDate(getFormatDate(imageList[index].exif.createDate));
    // }
  }, [oneIndex]);
  useEffect(() => {
    onGetCcdFlag(garbage, dirt);
  }, [garbage, dirt]);
  useEffect(() => {
    if (isToOne) {
      changeToOneFlag();
    }
  }, [isToOne]);
  useEffect(() => {
    const width = thumbEle.current?.getBoundingClientRect().width || 0;
    const height = thumbEle.current?.getBoundingClientRect().height || 0;
    setCenterAspectRatio(width / height);
  }, [thumbEle]);
  useEffect(() => {
    const reCalcAspect = () => {
      const width = thumbEle.current?.getBoundingClientRect().width || 0;
      const height = thumbEle.current?.getBoundingClientRect().height || 0;
      setCenterAspectRatio(width / height);
    };
    window.addEventListener('resize', reCalcAspect);
    return () => window.removeEventListener('resize', reCalcAspect);
  }, []);
  // -- 選択画像が変わった時拡大率を戻す --
  useEffect(() => {
    setScale(initScale);
  }, [index]);

  return (
    <div className="one_conts">
      {/* ズーム系ツール */}
      <div className="zoom_menu">
        <div
          className="zoom_menu__in rect_icon plus_icon"
          onClick={() => handlerChangeScale(scale + 0.1)}
          onMouseEnter={() => handlerHoverScaleButton({ mouseEnter: true, buttonType: 'plus' })}
          onMouseLeave={() => handlerHoverScaleButton({ mouseEnter: false })}
          ref={scaleBtnEle.current[0]}
        >
          {scaleBtnTooltipType === 'plus'
            ? <DynamicTooltip
              messages={['拡大']}
              relativeEle={scaleBtnEle.current[0].current}
              onClick={(e) => e.stopPropagation()}
            />
            : <></>}
        </div>
        <div
          className="zoom_menu__out rect_icon minus_icon"
          onClick={() => handlerChangeScale(scale - 0.1)}
          onMouseEnter={() => handlerHoverScaleButton({ mouseEnter: true, buttonType: 'minus' })}
          onMouseLeave={() => handlerHoverScaleButton({ mouseEnter: false })}
          ref={scaleBtnEle.current[1]}
        >
          {scaleBtnTooltipType === 'minus'
            ? <DynamicTooltip
              messages={['縮小']}
              relativeEle={scaleBtnEle.current[1].current}
              onClick={(e) => e.stopPropagation()}
            />
            : <></>}
        </div>
        <div
          className="zoom_menu__screen rect_icon"
          onClick={() => handlerChangeScale(initScale)}
          onMouseEnter={() => handlerHoverScaleButton({ mouseEnter: true, buttonType: 'fit' })}
          onMouseLeave={() => handlerHoverScaleButton({ mouseEnter: false })}
          ref={scaleBtnEle.current[2]}
        >
          <img
            src={iconAdjust2Screen}
            alt=""
          />
          {scaleBtnTooltipType === 'fit'
            ? <DynamicTooltip
              messages={['画面に合わせる']}
              relativeEle={scaleBtnEle.current[2].current}
              onClick={(e) => e.stopPropagation()}
              limitEle={limitEle}
            />
            : <></>}
        </div>
      </div>
      {/* 次の画像 */}
      <div
        className="rect_icon next_btn triangle_icon"
        onClick={() => handlerChangeImage(index + 1)}
      />
      {/* 前の画像 */}
      <div
        className="rect_icon pre_btn triangle_icon triangle_icon_left"
        onClick={() => handlerChangeImage(index - 1)}
      />

      <div className="one_conts__body">
        <div className="one_conts__body__left">
          <div className="checks">
            {retouchList.map((v) => (
              <RetouchCheckbox
                key={`retouch-item-list_solo_${v.productNameId}`}
                retouchInfo={v}
                callback={handlerChangeChecked}
                checkValue={imageCheckList.includes(v.productNameId)}
                garbageOrDirtId={garbageOrDirtId}
                isGarbageOrDirt={v.productNameId === garbageId || v.productNameId === dirtId}
              />
            ))}
          </div>
        </div>
        {imageList.length ?
          <div className="one_conts__body__center">
            <div
              className="one_conts__thumb"
              ref={thumbEle}
            >
              <Image
                key={`retouch-image_${imageList[index].id}`}
                src={imageList[index].thumbnailBase64}
                alt="画像エリア"
                // scale={scale}
                containerAspectRatio={centerAspectRatio || undefined}
                retouchScale={scale}
                style={{
                  transformOrigin: scale > 1 ? 'top left' : undefined,
                  // transform: scale ? `scale(${scale})` : undefined,
                }}
              />
            </div>
            <div className="one_conts__thumb__name">
              <span>{itemName}</span>
              <span>{itemCreateDate}</span>
              {/*<span>{formatDate}</span>*/}
            </div>
          </div>
          : <></>}
        <div className="one_conts__body__right" />
      </div>
    </div>
  );
};

type ListItemProps = {
  editableImage: EditableImage,
  // - 画像リストのindex -
  listIndex?: number,
  // - 画像のindexを取得するハンドラ -
  onGetIndex?: (index: number) => void,
  // - refを取得するハンドラ -
  onGetRef?: (ref: HTMLDivElement) => void,
  // - 選択されているかフラグ -
  isSelect?: boolean,
  // - 画像のごみフラグを取得するハンドラ -
  onGetCcdFlag?: (type: 'garbage' | 'dirt', flag: boolean) => void,
  // // - 一括変更するgarbage値 -
  // iniGarbage: boolean,
  // // - 一括変更するdirt値 -
  // iniDirt: boolean,
  // - 1コマで操作したgarbage -
  oneGarbage?: boolean,
  // - 1コマで操作したdirt -
  oneDirt?: boolean,
  // - 画像一覧からごみチェック操作したフラグ更新ハンドラ -
  changeToOneFlag?: () => void,
  // - レタッチ項目一覧 -
  retouchList: SimpleRetouchInfo[],
  // - 一括でチェック操作された項目の状態 -
  retouchItemState: AllCheckItem | undefined,
  // - 「CCDゴミ」か「CCDゴミ＋バック紙よごれ（ゴミ）」か -
  garbageOrDirtId: string,
  targetCheckList: ImageCheckItem | undefined,
  // - レタッチメニューと商品情報表示の状態 -
  showMenuType: ShowMenuType,
  xml: XmlStructureModel | null;
  onChangeCheck?: (v: any) => void;
  isOne?: boolean;
};
const ListItem = (props: ListItemProps) => {
  const {
    editableImage,
    listIndex,
    onGetIndex,
    onGetRef,
    isSelect,
    onGetCcdFlag,
    oneGarbage,
    oneDirt,
    changeToOneFlag,
    retouchList,
    retouchItemState,
    garbageOrDirtId,
    targetCheckList,
    showMenuType,
    onChangeCheck,
    xml,
    isOne,
  } = props;
  const dispatch = useDispatch();
  // - Ref -
  const itemRef = useRef<HTMLDivElement>(null);
  const imageCheckList = useAppSelector((v) => v.retouch.imageCheckItemList);
  // - State -
  const [formatDate, setFormatDate] = useState('');
  const [isHover, setIsHover] = useState(false);

  const goodsName = useMemo(() => {
    const _id = editableImage.orderId;
    const find = xml?.orderInfo?.infoData?.find((v) => v.xml?.metaModel?.id === _id);
    return find ? find.xml.viewModel?.goods?.shortName ?? '' : '';
  }, [editableImage, xml]);

  const itemName = useMemo(() => {
    const find = xml?.orderSelect?.metaModel?.imageData?.find((v) => v.selectFileName?.real.path === editableImage.name);
    return find?.originalFileName?.real.path ?? '';
  }, [editableImage, xml]);

  // const [garbage, setGarbage] = useState(editableImage.flags.retouchCcd);
  // const [dirt, setDirt] = useState(editableImage.flags.retouchCcdPack);
  // const [prevIniGarbage, setPrevIniGarbage] = useState(iniGarbage);
  // const [prevIniDirt, setPrevIniDirt] = useState(iniDirt);
  // - Effect -
  // useEffect(() => {
  //   setPrevIniGarbage(iniGarbage);
  //   setPrevIniDirt(iniDirt);
  //   if (prevIniGarbage !== iniGarbage) {
  //     setGarbage(iniGarbage);
  //     onGetCcdFlag?.('garbage', iniGarbage);
  //     UiManager.ins.emit('r->l:change-editable-image-ccd-flags', {
  //       editableImageId: editableImage.id,
  //       flag: iniGarbage,
  //       isPack: false,
  //     });
  //   } else if (prevIniDirt !== iniDirt) {
  //     setDirt(iniDirt);
  //     onGetCcdFlag?.('dirt', iniDirt);
  //     UiManager.ins.emit('r->l:change-editable-image-ccd-flags', {
  //       editableImageId: editableImage.id,
  //       flag: iniDirt,
  //       isPack: true,
  //     });
  //   }
  // }, [iniGarbage, iniDirt, prevIniGarbage, prevIniDirt]);
  // useEffect(() => {
  //   if (oneGarbage !== undefined && oneDirt !== undefined) {
  //   setGarbage(oneGarbage);
  //   setDirt(oneDirt);
  //   }
  // }, [oneGarbage, oneDirt]);
  useEffect(() => {
    if (onGetRef && itemRef.current) {
      onGetRef(itemRef.current);
    }
    // setFormatDate(editableImage.exif.createDate);
    // setFormatDate(getFormatDate(editableImage.exif.createDate));
  }, []);
  // - Callback -
  // // -- ゴミチェックハンドラ --
  // const handlerChangeGarbage = useCallback((v) => {
  //   // setGarbage(v);
  //   UiManager.ins.emit('r->l:change-editable-image-ccd-flags', {
  //     editableImageId: editableImage.id,
  //     flag: v,
  //     isPack: false,
  //   });
  //   changeToOneFlag?.();
  // }, []);
  // // -- 紙パックチェックハンドラ --
  // const handlerChangeDirt = useCallback((v) => {
  //   // setDirt(v);
  //   UiManager.ins.emit('r->l:change-editable-image-ccd-flags', {
  //     editableImageId: editableImage.id,
  //     flag: v,
  //     isPack: true,
  //   });
  //   changeToOneFlag?.();
  // }, []);
  const handlerChangeChecked = useCallback((item: SimpleRetouchInfo, checked: boolean) => {
    if (!imageCheckList) return;
    if (!targetCheckList) return;
    if (!targetCheckList?.checkList.includes(item.productNameId)) {
      dispatch(retouchActions.setImageCheckItemList(
        imageCheckList.map((v) => {
          const _list = cloneDeep(v.checkList);
          if (v.selectId === editableImage.selectId) {
            _list.push(item.productNameId);
            if (item.productNameId === garbageId || item.productNameId === dirtId) {
              const filterId = item.productNameId === garbageId ? dirtId : garbageId;
              return { ...v, checkList: _list.filter((v2) => v2 !== filterId) };
            }
            return { ...v, checkList: _list };
          } else {
            return v;
          }
        }),
      ));
    } else {
      dispatch(retouchActions.setImageCheckItemList(
        imageCheckList.map((v) => {
          if (v.selectId === editableImage.selectId) {
            return { ...v, checkList: v.checkList.filter((v) => v !== item.productNameId) };
          } else {
            return v;
          }
        }),
      ));
    }
    // dispatch(retouchActions.changeImageCheckItemList({id: editableImage.id, checkItem: item.productNameId, isCheck: checked}));
  }, [imageCheckList, targetCheckList, editableImage]);

  return (
    <div
      className={`retouch_list_item${isSelect ? ' selected' : ''}${isOne ? ' one_list_item' : ''}`}
      ref={itemRef}
    >
      <div className="retouch_list_item__body__wrap">
        <div
          className="retouch_list_item__body"
          onClick={() => {
            onGetIndex?.(listIndex ? listIndex : 0);
          }}
        >
          <div className="retouch_list_item__body__inner">
            {/* 選択時タグ */}
            {editableImage.selectId && !isOne ? (
              <div className="retouch_list_item__selected_tag">
                <span>使用画像</span>
              </div>
            ) : (<></>)}

            {/* チェックボックスコンテンツ */}
            <div
              className="retouch_list_item__header"
              style={showMenuType === 'name' ? { height: 'initial' } : {}}
            >
              {(!isOne && (showMenuType === 'retouch')) &&
                <div className="checks">
                  {retouchList.map((v, i) => (
                    <RetouchCheckbox
                      key={`retouch-item-list_one_${v.productNameId}`}
                      retouchInfo={v}
                      callback={handlerChangeChecked}
                      checkValue={!!targetCheckList?.checkList.includes(v.productNameId)}
                      garbageOrDirtId={garbageOrDirtId}
                      isGarbageOrDirt={v.productNameId === garbageId || v.productNameId === dirtId}
                    />
                  ))}
                </div>}
              {/* 商品情報表示 */}
              {showMenuType === 'name' ? (
                <div className="goods_name">{goodsName}</div>
              ) : (<></>)}
            </div>


            {/* 画像 */}
            <div
              className="retouch_list_item__thumb"
              onClick={() => {
                // onGetIndex?.(listIndex ? listIndex : 0);
              }}
              onMouseOver={() => setIsHover(true)}
              onMouseLeave={() => setIsHover(false)}
            >
              <Image
                src={editableImage.thumbnailBase64}
                containerAspectRatio={1.19}
              />
            </div>

            {/* 画像ファイル名 */}
            <div className="retouch_list_item__name">
              <div>{editableImage.name}</div>
              {/*<div>{itemName}</div>*/}
            </div>

            {/* {isHover ? (
              <StaticTooltip
                messages={[
                  `サイズ：`,
                  `撮影日時：${formatDate}`,
                ]}
                pos={{x: '0', y: '0'}}
              />
            ) : (<></>)} */}

          </div>
        </div>
      </div>
    </div>
  );
};

type ListSixProps = {
  imageList: EditableImage[],
  // iniGarbage: boolean,
  // iniDirt: boolean,
  retouchList: SimpleRetouchInfo[],
  retouchItemState: AllCheckItem | undefined,
  garbageOrDirtId: string,
  imageCheckList: ImageCheckItem[],
  showMenuType: ShowMenuType,
}
const List = (props: ListSixProps) => {
  const { imageList, retouchList, retouchItemState, garbageOrDirtId, imageCheckList, showMenuType } = props;
  return (
    <>
      {/* {imageList.map((v, i) => (
        <ListItem
          key={`use-image-item_${v.id}`}
          editableImage={v}
          // iniGarbage={iniGarbage}
          // iniDirt={iniDirt}
          retouchList={retouchList}
          retouchItemState={retouchItemState}
          garbageOrDirtId={garbageOrDirtId}
          targetCheckList={imageCheckList.find((vv) => vv.id === v.id)}
          showMenuType={showMenuType}
        />
      ))} */}
    </>
  );
};

const Six = (props: ListSixProps) => {
  const { imageList, retouchList, retouchItemState, garbageOrDirtId, imageCheckList, showMenuType } = props;
  return (
    <>
      {/* {imageList.map((v, i) => (
        <ListItem
          key={`use-image-item_${v.id}`}
          editableImage={v}
          // iniGarbage={iniGarbage}
          // iniDirt={iniDirt}
          retouchList={retouchList}
          retouchItemState={retouchItemState}
          garbageOrDirtId={garbageOrDirtId}
          targetCheckList={imageCheckList.find((vv) => vv.id === v.id)}
          showMenuType={showMenuType}
        />
      ))} */}
    </>
  );
};

type AllCheckItem = {
  item: SimpleRetouchInfo,
  checked: boolean,
}
type RetouchCheckboxProps = {
  // - レタッチ項目の情報 -
  retouchInfo: SimpleRetouchInfo,
  // - チェック状態の更新ハンドラ -
  callback: (item: SimpleRetouchInfo, isCheck: boolean) => void,
  // - チェック状態を設定する -
  checkValue: boolean | 'else',
  // - 「CCDゴミ」か「CCDゴミ＋バック紙よごれ（ゴミ）」どちらを選択中か -
  garbageOrDirtId: string,
  // - 「CCDゴミ」か「CCDゴミ＋バック紙よごれ（ゴミ）」か -
  isGarbageOrDirt: boolean,
}
const RetouchCheckbox = (props: RetouchCheckboxProps) => {
  const { retouchInfo, callback, checkValue, garbageOrDirtId, isGarbageOrDirt } = props;
  const [isChecked, setIsChecked] = useState(false);
  // useEffect(() => {
  //   if (isGarbageOrDirt && garbageOrDirtId.length && garbageOrDirtId === retouchInfo.productNameId) {
  //     setIsChecked(true);
  //   } else if (isGarbageOrDirt && garbageOrDirtId.length && garbageOrDirtId !== retouchInfo.productNameId) {
  //     setIsChecked(false);
  //   }
  // }, [garbageOrDirtId]);
  return (
    <>
      <Checkbox
        label={retouchInfo.productName}
        // onClick={() => setIsChecked(prev => !prev)}
        onClick={() => callback(retouchInfo, checkValue === 'else' ? false : !checkValue)}
        checked={checkValue === 'else' ? false : checkValue}
        stopPropagation
      />
    </>
  );
};

type PhotoImageInfo = {
  orgFilename: string,
  filename: string,
  path: string,
  file: File,
}

type Props = {
  callbackRetouch: (v: RetouchGetResponse) => void;
  xml: XmlStructureModel | null,
  initRetouchCheck: InitRetouchCheck,
  isLoading: boolean,
  callbackLoad: (v: boolean) => void;
  isSaving: boolean,
  callbackChangeImageCount: (num: number) => void,
}

const garbageId = 'jptg300181';
const dirtId = 'jptg300182';
export const RetouchUseImage = (props: Props) => {
  const { callbackRetouch, xml, initRetouchCheck, isLoading, callbackLoad, isSaving, callbackChangeImageCount } = props;
  const { kijshopCd, shopOrderId, orderId } = useParams<PathParams>();
  const {
    displayType,
    sortType,
    retouchItemList,
    imageCheckList,
    showMenuType,
    noJudgeItem,
  } = useAppSelector((state) => ({
    displayType: state.storage.data.retouchMenu.bubbleType,
    sortType: state.storage.data.retouchMenu.sortType,
    retouchItemList: state.retouch.retouchItemList,
    imageCheckList: state.retouch.imageCheckItemList,
    showMenuType: state.storage.data.retouchMenu.showMenuType,
    noJudgeItem: state.common.data.masterProductNoJudgeItem,
  }), lodash.isEqual);
  const dispatch = useDispatch();
  // - State -
  const bodyRef = useRef<HTMLDivElement>(null);
  // - State -
  // -- ゴミチェック --
  const [garbage, setGarbage] = useState(false);
  // -- パック紙よごれチェック --
  const [dirt, setDirt] = useState(false);
  // -- 1コマ時の画像一覧開閉状態 --
  const [oneListOpen, setOneListOpen] = useState(true);
  // -- 画像リスト --
  const [imageList, setImageList] = useState<EditableImage[]>([]);
  // -- 1コマ表示する画像index --
  const [oneIndex, setOneIndex] = useState(0);
  // -- 画像一覧のrefリスト --
  const [oneRefList, setOneRefList] = useState<HTMLDivElement[]>([]);
  // -- 1コマ時のごみフラグ --
  const [oneGarbage, setOneGarbage] = useState(false);
  const [oneDirt, setOneDirt] = useState(false);
  // - 画像一覧からごみチェックしたフラグ -
  const [isToOne, setIsToOne] = useState(false);
  // - 6コマ表示の列数 -
  const [sixFrameRows, setSixFrameRows] = useState(2);
  // -- レタッチ項目のリスト --
  const [retouchList, setRetouchList] = useState<SimpleRetouchInfo[]>([]);
  // -- 一括チェックした項目 --
  const [allCheckItem, setAllCheckItem] = useState<AllCheckItem>();
  // -- CCDゴミまたはCCDゴミ＋バック紙よごれ（ゴミ）のときにID --
  const [garbageOrDirtId, setGarbageOrDirtId] = useState('');
  // -- 画像一覧の取得時に入れておく配列 --
  const [photoList, setPhotoList] = useState<PhotoImageInfo[]>([]);
  // -- change-emitをonしたかフラグ --
  const [isOnEmit, setIsOnEmit] = useState(false);
  // -- ローディングメッセージ --
  const [connectingMessage, setConnectingMessage] = useState('画像情報を取得しています...');

  // - Callback -
  // // -- ゴミチェック切り替え --
  // const handlerChangeGarbage = useCallback((v) => {
  //   setGarbage(v);
  // }, []);
  // // -- パック紙よごれチェック切り替え --
  // const handlerChangeDirt = useCallback((v) => {
  //   setDirt(v);
  // }, []);

  const imageCount = useMemo(() => {
    const layoutRetouch: string[] = ['20', '30'];
    const originInfos = xml?.orderInfo?.infoData ?? [];
    const originDatas = xml?.orderSelect?.metaModel?.imageData ?? [];
    const targetIds: string[] = [];

    const InfoIdDatas = originInfos.map((v) => {
      /* レタッチ可能商品精査 */
      if (
        noJudgeItem?.lnwMasterProductNoJudgeItem.category?.[0]?.data?.find((item) => item.$.id === v.xml.viewModel.category?.id)
        || noJudgeItem?.lnwMasterProductNoJudgeItem.goods?.[0]?.data?.find((item) => item.$.id === v.xml.viewModel.item?.id)
      ) {
        return [];
      }
      if (v.xml.viewModel.surrogateProcess?.id === '10') {
        return (v.pageBreak?.viewModel.data?.image.map((v2) => ({
          selectId: v2.selectID,
          orderId: v.xml.metaModel.id,
        })) ?? []);
      } else if (layoutRetouch.includes(v.xml.viewModel.surrogateProcess?.id ?? '')) {
        const resultPages = [];
        for (const pages of v.parts?.partsData ?? []) {
          for (const page of pages.page?.pageData ?? []) {
            const resultPage = page.viewModel?.orderPicture?.data?.map((v2) => ({
              selectId: v2.selectID,
              orderId: v.xml.metaModel.id,
            })) ?? [];
            v.xml.metaModel.id && !targetIds.includes(v.xml.metaModel.id) && targetIds.push(v.xml.metaModel.id);
            resultPages.push(...resultPage);
          }
        }
        return resultPages;
      } else {
        return [];
      }
    }).filter((v) => v.length);
    const arr = [] as typeof originDatas;
    originDatas.forEach((v) => {
      if (!arr.find((data) => data.selectID === v.selectID)) {
        arr.push(v);
      }
    });

    const result = arr.map((v) => {
      const parentFind = InfoIdDatas.find((v2) => v2.find((v3) => v3.selectId === v.selectID));
      const find = parentFind?.find((v2) => v2.selectId === v.selectID);
      return find ? { data: v, orderId: find.orderId } : undefined;
    }).filter((v) => v);
    const optResult = [] as typeof originDatas;
    for (const targetId of targetIds) {
      const targetInfo = xml?.orderInfo?.infoData?.find((v) => {
        return v.xml.metaModel.id === targetId;
      });
      const optionInfo = LayoutXmlUtile.getOptionInfoData(targetId, xml);
      const optionParts = LayoutXmlUtile.flatOptionParts(optionInfo);
      for (const parts of optionParts) {
        for (const page of parts.page?.pageData ?? []) {
          for (const picData of page.viewModel.orderPicture?.data ?? []) {
            const selectId = picData.selectID;
            const targetImage = originDatas.find(img => img.selectID === selectId);
            const find = result.find((v) => v?.data.selectID === selectId);
            if (targetImage && !find) {
              optResult.push(targetImage);
            }
          }
        }
      }
    }
    return result.length + optResult.length;
  }, [xml, noJudgeItem]);
  // -- レタッチ項目一括チェック --
  const handlerChangeChecked = useCallback((item: SimpleRetouchInfo, checked: boolean) => {
    const afList = imageCheckList.map((v) => {
      const _list = cloneDeep(v.checkList);
      if (!checked) {
        return {
          ...v,
          checkList: _list.filter((v) => v !== item.productNameId),
        };
      }

      if (!_list.includes(item.productNameId)) {
        _list.push(item.productNameId);
      }
      if (item.productNameId === garbageId || item.productNameId === dirtId) {
        const filterId = item.productNameId === garbageId ? dirtId : garbageId;
        return {
          ...v,
          checkList: _list.filter((v2) => v2 !== filterId),
        };
      }
      return {
        ...v,
        checkList: _list,
      };
    });
    dispatch(retouchActions.setImageCheckItemList(cloneDeep(afList)));
  }, [imageCheckList]);

  // - Effect -
  // -- 画像一覧のref取得 --
  useEffect(() => {
    if (displayType !== 'one') return;
    const targetRef = oneRefList.find((ref) => ref.className.indexOf('selected') !== -1);
    if (targetRef) {
      targetRef.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'center' });
    }
  }, [oneRefList, oneIndex, sortType]);

  // -- sortTypeでリストsetする --
  useEffect(() => {
    setImageList(prev => {
      return (
        sortType === 'asc-name' ? prev.sort((a, b) => a.name > b.name ? 1 : -1) :
          sortType === 'dec-name' ? prev.sort((a, b) => a.name < b.name ? 1 : -1) :
            sortType === 'asc-date' ? prev.sort((a, b) => a.exif.createDate > b.exif.createDate ? 1 : -1) :
              prev.sort((a, b) => a.exif.createDate < b.exif.createDate ? 1 : -1)
      );
    });
  }, [sortType]);
  // -- ソート別のリスト作成 --
  useEffect(() => {
    UiManager.ins.emit('l->r:post-editable-image-list', {
      callback: (e: { list: EditableImage[] }) => {
        // setImageList(
        //   sortType === 'asc-name' ? e.list.concat().sort((a, b) => a.name > b.name ? 1 : -1) :
        //     sortType === 'dec-name' ? e.list.concat().sort((a, b) => a.name < b.name ? 1 : -1) :
        //       sortType === 'asc-date' ? e.list.concat().sort((a, b) => a.exif.createDate > b.exif.createDate ? 1 : -1) :
        //         e.list.concat().sort((a, b) => a.exif.createDate < b.exif.createDate ? 1 : -1),
        // );
        let editableImageList: EditableImage[] = [];
        setImageList((prev) => {
          const arr = [...prev];
          e.list.forEach((data) => {
            if (!arr.find((v) => v.selectId === data.selectId)) {
              arr.push(data);
            }
          });
          sortType === 'asc-name' ? arr.sort((a, b) => a.name > b.name ? 1 : -1) :
            sortType === 'dec-name' ? arr.sort((a, b) => a.name < b.name ? 1 : -1) :
              sortType === 'asc-date' ? arr.sort((a, b) => a.exif.createDate > b.exif.createDate ? 1 : -1) :
                arr.sort((a, b) => a.exif.createDate < b.exif.createDate ? 1 : -1);
          editableImageList = arr;
          return arr;
        });
        dispatch(retouchActions.setImageCheckItemList(
          // e.list.map((v) => ({
          editableImageList.map((v) => ({
            id: v.id,
            selectId: v.selectId,
            checkList: !imageCheckList
              ? []
              : (imageCheckList.find((v2) => v2.id === v.id)?.checkList || []),
          })),
        ));
      },
    });
    const changeFlag = (v: { editableImageId: string, flag: boolean, isPack: boolean }) => {
      EditableImageManager.ins.toCcd(v.editableImageId, v.flag, v.isPack);
    };
    UiManager.ins.addListener('r->l:change-editable-image-ccd-flags', changeFlag);
    return () => {
      UiManager.ins.removeListener('r->l:change-editable-image-ccd-flags', changeFlag);
    };
  }, []);

  // const getImage = useCallback(() => new Promise<>)

  const getImage = useCallback((v: Partial<OrderSelectImageData>, _orderId?: string) => (
      // const getImage = useCallback((v: Partial<OrderSelectImageData>) => (
      new Promise<{ file: File, selectId?: string, path: string, orderId?: string }>((resolve) => {
        console.log('%cTEST', 'color:hotpink');
        const path = `${kijshopCd}/upload/${shopOrderId}/${v.selectFileName?.real.path}`;
        dispatch(apiActions.run(
          new ApiImagesGetOne({
            kijshopCd,
            path,
          }),
          {
            onSuccess: (blob: Blob) => {
              const name = v.originalFileName?.real.path ?? '';
              const file = new File(
                [blob],
                name,
                {
                  lastModified: new Date().getTime(),
                  type: blob.type,
                },
              );

              // const data: PhotoImageInfo = {
              //   filename: v.filename,
              //   orgFilename: v.orgFilename,
              //   path: v.path,
              //   file,
              // }
              // setPhotoList(prev => [...prev, data]);
              // console.log(res)
              // const url = URL.createObjectURL(res);
              // let imageB: string;
              // const reader = new FileReader();
              // reader.readAsDataURL(res);
              // reader.onload = () => {
              //   // setImageDataList((prev) => {
              //   imageB = reader.result as string;
              //     // return [...prev];
              //   // });
              //   const data: PhotoImageInfo = {
              //     orgFilename: v.orgFilename,
              //     filename: v.filename,
              //     path: v.path,
              //     image: imageB,
              //   };
              //   // result.push(data);
              //   setPhotoList(prev => [...prev, data]);
              // };
              // result.push(url);
              resolve({ file, selectId: v.selectID, path, orderId: _orderId });
            },
            onError: () => {
              getImage(v)
                .then((v2) => resolve(
                  { file: v2.file, selectId: v2.selectId, path, orderId: _orderId }));
            },
          },
        ));
      }))
    , [kijshopCd, orderId, shopOrderId]);

  const changeList = useCallback((e: { list: EditableImage[] }) => {
    const list: EditableImage[] = [];
    e.list.forEach((v) => {
      if (!list.find((v2) => v2.selectId === v.selectId)) {
        list.push(v);
      }
    });
    // setImageList(
    //   sortType === 'asc-name' ? list.sort((a, b) => a.name > b.name ? 1 : -1) :
    //     sortType === 'dec-name' ? list.sort((a, b) => a.name < b.name ? 1 : -1) :
    //       sortType === 'asc-date' ? list.sort((a, b) => a.exif.createDate > b.exif.createDate ? 1 : -1) :
    //         list.concat().sort((a, b) => a.exif.createDate < b.exif.createDate ? 1 : -1),
    // );
    let editableImageList: EditableImage[] = [];
    setImageList((prev) => {
      const arr = [...prev];
      e.list.forEach((data) => {
        if (!arr.find((v) => v.selectId === data.selectId)) {
          arr.push(data);
        }
      });
      sortType === 'asc-name' ? arr.sort((a, b) => a.name > b.name ? 1 : -1) :
        sortType === 'dec-name' ? arr.sort((a, b) => a.name < b.name ? 1 : -1) :
          sortType === 'asc-date' ? arr.sort((a, b) => a.exif.createDate > b.exif.createDate ? 1 : -1) :
            arr.sort((a, b) => a.exif.createDate < b.exif.createDate ? 1 : -1);
      editableImageList = arr;
      return arr;
    });
    dispatch(retouchActions.setImageCheckItemList(
      // e.list.map((v) => {
      editableImageList.map((v) => {
        const target = initRetouchCheck.find((vv) => vv.selectId === v.selectId);
        if (target) {
          return {
            id: v.id,
            selectId: v.selectId,
            checkList: target.retouchId,
          };
        } else {
          return {
            id: v.id,
            selectId: v.selectId,
            checkList: imageCheckList?.find((v2) => v2.id === v.id)?.checkList ?? [],
          };
        }
      }),
    ));
  }, [imageCheckList, sortType, initRetouchCheck]);

  const getImages = useCallback(() => {
    if (!isLoading) callbackLoad(true);
    const layoutRetouch: string[] = ['20', '30'];
    const originInfos = xml?.orderInfo?.infoData ?? [];
    const originDatas = xml?.orderSelect?.metaModel?.imageData ?? [];
    const targetIds: string[] = [];
    const InfoIdDatas = originInfos.map((v) => {
      /* レタッチ可能商品精査 */
      if (
        noJudgeItem?.lnwMasterProductNoJudgeItem.category?.[0]?.data?.find((item) => item.$.id === v.xml.viewModel.category?.id)
        || noJudgeItem?.lnwMasterProductNoJudgeItem.goods?.[0]?.data?.find((item) => item.$.id === v.xml.viewModel.item?.id)
      ) {
        return [];
      }
      if (v.xml.viewModel.surrogateProcess?.id === '10') {
        return (v.pageBreak?.viewModel.data?.image.map((v2) => ({
          selectId: v2.selectID,
          orderId: v.xml.metaModel.id,
        })) ?? []);
      } else if (layoutRetouch.includes(v.xml.viewModel.surrogateProcess?.id ?? '')) {
        const resultPages = [];
        for (const pages of v.parts?.partsData ?? []) {
          for (const page of pages.page?.pageData ?? []) {
            const resultPage = page.viewModel?.orderPicture?.data?.map((v2) => ({
              selectId: v2.selectID,
              orderId: v.xml.metaModel.id,
            })) ?? [];
            v.xml.metaModel.id && !targetIds.includes(v.xml.metaModel.id) && targetIds.push(v.xml.metaModel.id);
            resultPages.push(...resultPage);
          }
        }
        return resultPages;
      } else {
        return [];
      }
    }).filter((v) => v.length);
    let totalCount = 0;
    let uploadedCount = 0;
    const retouchImgList = [];
    const arr = [] as typeof originDatas;
    originDatas.forEach((v) => {
      if (!arr.find((data) => data.selectID === v.selectID)) {
        arr.push(v);
      }
    });
    for (const originData of arr) {
      const parentFind = InfoIdDatas.find((v2) => v2.find((v3) => v3.selectId === originData.selectID));
      const find = parentFind?.find((v2) => v2.selectId === originData.selectID);
      if (find) {
        totalCount++;
        const targetData = retouchImgList.find(v3 => v3.orderId === find.orderId);
        if (targetData) {
          targetData.list.push(originData);
        } else {
          retouchImgList.push({ orderId: find.orderId ?? '', list: [originData] });
        }
      }
    }
    for (const targetId of targetIds) {
      const targetInfo = xml?.orderInfo?.infoData?.find((v) => {
        return v.xml.metaModel.id === targetId;
      });
      const optionInfo = LayoutXmlUtile.getOptionInfoData(targetId, xml);
      const optionParts = LayoutXmlUtile.flatOptionParts(optionInfo);
      for (const parts of (targetInfo?.parts?.partsData ?? []).concat(optionParts)) {
        for (const page of parts.page?.pageData ?? []) {
          for (const picData of page.viewModel.orderPicture?.data ?? []) {
            const selectId = picData.selectID;
            const flat = retouchImgList.map((v) => v.list).flat();
            const find = flat.find((v) => v.selectID === picData.selectID);
            if (!find) {
              const targetImage = arr.find(img => img.selectID === selectId);
              if (targetImage) {
                totalCount++;
                retouchImgList.push({ orderId: targetId, list: [targetImage] });
              }
            }
            // for (const retouchImgListElement of retouchImgList) {
            //   const find = retouchImgListElement.list.find(elm => elm.selectID === selectId);
            //   if (!find) {
            //     const targetImage = arr.find(img => img.selectID === selectId);
            //     if (targetImage) {
            //       totalCount++;
            //       retouchImgList.push({ orderId: targetId, list: [targetImage] });
            //     }
            //   }
            // }
          }
        }
      }
    }
    if (!retouchImgList.length) {
      changeList({ list: [] });
      callbackLoad(false);
    }
    for (const retouchImg of retouchImgList) {
      UiManager.ins.emit(
        'r->l:add-image:restore',
        {
          kijshopCd,
          shopOrderId,
          orderId: retouchImg?.orderId ?? null,
          list: retouchImg?.list,
          onRestoreOne: () => {
            uploadedCount++;
            setConnectingMessage(`使用画像の読み込み中です。(${uploadedCount}/${totalCount})`);
            UiManager.ins.emit('l->r:post-editable-image-list', {
              callback: (e) => {
                changeList(e);
              },
            });
          },
          isDelete: false,
        },
      );
    }
  }, [getImage, kijshopCd, shopOrderId, xml, changeList, noJudgeItem, orderId]);

  // -- 6コマ表示の列数後進 --
  useEffect(() => {
    const minRowCount = Math.floor(imageList.length / 3);
    setSixFrameRows(imageList.length % 3 === 0 ? minRowCount : minRowCount + 1);
  }, [imageList.length]);
  // -- レタッチ項目と画像一覧の取得 --
  useEffect(() => {
    if (!isOnEmit && initRetouchCheck.length) {
      UiManager.ins.on('l->r:change-editable-image-list', (v) => {
        changeList(v);
        callbackLoad(false);
      });
      setIsOnEmit(true);
    }

    if (retouchItemList.length) {
      setRetouchList(retouchItemList);
    }
    if (initRetouchCheck.length) {
      dispatch(apiActions.run(
        new ApiRetouchGet({}),
        {
          onSuccess: (res: ResponseBase<RetouchGetResponse>) => {
            const arr = res.body.data?.[0];
            if (res.body.data) callbackRetouch(res.body.data);
            const _list = cloneDeep(arr?.simpleRetouchInfo ?? []);
            setRetouchList(_list);
            if (!imageList.length && !isOnEmit) {
              getImages();
            }
          },
        },
      ));
    }
  }, [retouchItemList, xml, initRetouchCheck]);

  useEffect(() => {
    // UiManager.ins.on('l->r:change-editable-image-list', changeList);
    EditableImageManager.ins.clear('list');
    return () => {
      EditableImageManager.ins.clear('list');
      UiManager.ins.off('l->r:change-editable-image-list', changeList);
    };
  }, []);
  useEffect(() => {
    if (!(isLoading || isSaving || !imageCount || (imageCount !== imageList.length))) {
      setConnectingMessage('');
    }
  }, [imageList]);
  useEffect(() => {
    if (imageCount) {
      callbackChangeImageCount(imageCount);
    }
  }, [imageCount]);

  return (
    <div className="retouch_use_image">
      {imageList.length ?
        <>
          <div className="retouch_use_image__inner">
            <div className="retouch_use_image__inner__header">
              <div className="retouch_use_image__inner__header__left">
                <div>一括選択（すべて選択）</div>
                <div className="check_contents">
                  {retouchList.map((v) => (
                    <RetouchCheckbox
                      key={`retouch-item-list_all_${v.productNameId}`}
                      retouchInfo={v}
                      callback={handlerChangeChecked}
                      checkValue={imageCheckList.every((v2) => v2.checkList.includes(v.productNameId))}
                      garbageOrDirtId={garbageOrDirtId}
                      isGarbageOrDirt={v.productNameId === garbageId || v.productNameId === dirtId}
                    />
                  ))}
                  {/* <Checkbox
                    label="CCDゴミ"
                    onClick={() => handlerChangeGarbage(!garbage)}
                    checked={garbage}
                  />
                  <Checkbox
                    label="CCDゴミ+パック紙汚れ（ゴミ）"
                    onClick={() => handlerChangeDirt(!dirt)}
                    checked={dirt}
                  /> */}
                </div>
              </div>
              <div className="retouch_use_image__inner__header__right">
                <div className="bordered_left_label_item">
                  <span>利用画像</span>
                  <div>{imageList.length}</div>
                </div>
              </div>
            </div>
            <div
              className={`retouch_use_image__inner__body ${displayType}`}
              style={displayType === 'six' ? {
                gridTemplateRows: `repeat(${sixFrameRows}, calc(50% - 1.75em))`,
              } : {}}
              ref={bodyRef}
            >
              {displayType === 'list' ? (
                // <List
                //   imageList={imageList}
                //   // iniGarbage={garbage}
                //   // iniDirt={dirt}
                //   retouchList={retouchList}
                //   retouchItemState={allCheckItem}
                //   garbageOrDirtId={garbageOrDirtId}
                //   imageCheckList={imageCheckList}
                // />
                imageList.map((v, i) => (
                  <ListItem
                    xml={xml}
                    key={`use-image-item_${v.id}`}
                    editableImage={v}
                    onGetIndex={() => {
                      setOneIndex(i);
                    }}
                    isSelect={i === oneIndex}
                    // iniGarbage={iniGarbage}
                    // iniDirt={iniDirt}
                    retouchList={retouchList}
                    retouchItemState={allCheckItem}
                    garbageOrDirtId={garbageOrDirtId}
                    targetCheckList={imageCheckList.find((vv) => vv.id === v.id)}
                    showMenuType={showMenuType}
                  />
                ))
              ) : (<></>)}
              {displayType === 'six' ? (
                // <Six
                //   imageList={imageList}
                //   // iniGarbage={garbage}
                //   // iniDirt={dirt}
                //   retouchList={retouchList}
                //   retouchItemState={allCheckItem}
                //   garbageOrDirtId={garbageOrDirtId}
                //   imageCheckList={imageCheckList}
                // />
                imageList.map((v, i) => (
                  <ListItem
                    key={`use-image-item_${v.id}`}
                    xml={xml}
                    editableImage={v}
                    onGetIndex={() => {
                      setOneIndex(i);
                    }}
                    isSelect={i === oneIndex}
                    // iniGarbage={iniGarbage}
                    // iniDirt={iniDirt}
                    retouchList={retouchList}
                    retouchItemState={allCheckItem}
                    garbageOrDirtId={garbageOrDirtId}
                    targetCheckList={imageCheckList.find((vv) => vv.id === v.id)}
                    showMenuType={showMenuType}
                  />
                ))
              ) : (<></>)}
              {displayType === 'one' ? (
                <One
                  imageList={imageList}
                  oneIndex={oneIndex}
                  onGetIndex={(index) => setOneIndex(index)}
                  onGetCcdFlag={(g, d) => {
                    setOneGarbage(g);
                    setOneDirt(d);
                  }}
                  // iniGarbage={garbage}
                  // iniDirt={dirt}
                  isToOne={isToOne}
                  changeToOneFlag={() => setIsToOne(false)}
                  retouchList={retouchList}
                  retouchItemState={allCheckItem}
                  garbageOrDirtId={garbageOrDirtId}
                  xml={xml}
                  limitEle={bodyRef?.current}
                />
              ) : (<></>)}
            </div>
          </div>
          {displayType === 'one' ? (
            <div className={`retouch_use_image__inner one_list ${oneListOpen ? '' : 'closed'}`}>
              <div className="row_list__header">
                画像一覧
                <div
                  className={`rect_icon ${oneListOpen ? 'minus_icon' : 'plus_icon'}`}
                  onClick={() => setOneListOpen(!oneListOpen)}
                />
              </div>
              {oneListOpen ?
                (<div className="row_list__body">
                  {imageList.map((v, i) => (
                    <ListItem
                      xml={xml}
                      key={`use-image-item_${v.id}`}
                      editableImage={v}
                      listIndex={i}
                      onGetIndex={(index) => setOneIndex(index)}
                      onGetRef={(ref) => setOneRefList(prev => {
                        if (!i) {
                          return [ref];
                        }
                        return [...prev, ref];
                      })}
                      isSelect={oneIndex === i}
                      onGetCcdFlag={(type, flag) => type === 'garbage' ? setOneGarbage(flag) : setOneDirt(flag)}
                      // iniGarbage={garbage}
                      // iniDirt={dirt}
                      oneGarbage={oneIndex === i ? oneGarbage : undefined}
                      oneDirt={oneIndex === i ? oneDirt : undefined}
                      changeToOneFlag={() => setIsToOne(true)}
                      retouchList={retouchList}
                      retouchItemState={allCheckItem}
                      garbageOrDirtId={garbageOrDirtId}
                      targetCheckList={imageCheckList.find((vv) => vv.id === v.id)}
                      showMenuType={showMenuType}
                      isOne
                    />
                  ))}
                </div>) : (<></>)}
            </div>
          ) : (<></>)}
        </>
        : (!isLoading && !imageCount) && (<div className="retouch_use_image__none">レタッチ可能な画像がありません</div>)}
      {(isLoading || isSaving || (imageCount !== imageList.length)) && connectingMessage ? (
        <LoadingPopup label={connectingMessage} />) : (<></>)}
    </div>
  );
};
