import {PageDataTypesRequest, PageDataTypesResponse} from '../../../../models/api/back/create-order/page-data-types';
import {AppThunk} from "../../../../app/store";
import {ordersDataActions} from "../../../../slices/orders/orders-data-slice";
import {apiActions} from "../../../../slices/api-slice";
import {ResponseBase} from "../../../response-base";
import {ApiBaseBack} from "../../api-base-back";
import { ordersActions } from '../../../../slices/orders-slice';

export class ApiPageDataTypes extends ApiBaseBack<PageDataTypesResponse> {
  constructor(param: PageDataTypesRequest) {
    super(
      'GET',
      '/api/v1/pageDataTypes',
      'JSON',
      param,
    );
  }
}

export const apiPageDataTypesThunk = (param: PageDataTypesRequest, callback?: () => void, isInit?: boolean): AppThunk => async (dispatch) => {
  if (!param.productNameId || !param.parentFlg || (param.parentFlg === '1' && !param.serviceId)) {
    dispatch(ordersDataActions.updateDescriptionInfo({
      pageDataType: { pageDataTypes: [] },
    }));
    callback?.();
    return;
  }
  dispatch(apiActions.run(
    new ApiPageDataTypes(param),
    {
      onSuccess: (res: ResponseBase<PageDataTypesResponse>) => {
        callback?.();
        dispatch(ordersDataActions.updateDescriptionInfo({
          pageDataType: { pageDataTypes: res.body.data?.pageDataTypes || [] },
        }));
        const pageDataTypeRes = res.body.data?.pageDataTypes;
        if(pageDataTypeRes) {
          if (isInit) {
            // dispatch(ordersActions.descriptionInfo.setDescriptionLocations([]));
          } else {
            const pageDatatypeId = pageDataTypeRes[0]?.pageDataTypeId;
            const pageData = pageDataTypeRes.find((v) => v.pageDataTypeId === 'jptg380014' && v.pageDataTypeId !== pageDatatypeId)?.pageDataTypeId;
            if (pageDatatypeId) {
              dispatch(ordersActions.descriptionInfo.setDescriptionLocations(pageData ? [pageDatatypeId, pageData] : [pageDatatypeId]));
            } else {
              dispatch(ordersActions.descriptionInfo.setDescriptionLocations([]));
            }
          }
        }
      },
      onError: () => {
        callback?.();
      }
    },
    {
      apiName: 'page-data-types',
      ignoreSystemError: true,
    },
  ))
};
