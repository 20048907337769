import {
  FoilStampingsCoverColorRequest,
  FoilStampingsCoverColorResponse,
  FoilStampingsRequest,
  FoilStampingsResponse,
} from '../../../../models/api/back/create-order/foil-stampings';
import {ordersDataActions} from "../../../../slices/orders/orders-data-slice";
import {AppThunk} from "../../../../app/store";
import {apiActions} from "../../../../slices/api-slice";
import {ResponseBase} from "../../../response-base";
import {ApiBaseBack} from "../../api-base-back";
import { ordersActions } from '../../../../slices/orders-slice';

export class ApiFoilStampings extends ApiBaseBack<FoilStampingsResponse> {
  constructor(param: FoilStampingsRequest | FoilStampingsCoverColorRequest) {
    super(
      'GET',
      '/api/v1/leafColors',
      'JSON',
      param,
    );
  }
}

export const apiFoilStampingsCoverThunk = (param: FoilStampingsRequest, callback?: () => void, isInit?: boolean): AppThunk => async (dispatch, getState) => {
  if (!param?.productNameId) {
    dispatch(ordersDataActions.updateCover({
      foilStamping: { leafColor: [] },
    }));
    callback?.();
    return;
  }
  dispatch(apiActions.run(
    // new ApiFoilStampings(param),
    new ApiFoilStampings({
      productNameId: param.productNameId,
      itemId: param.itemId,
    }),
    {
      onSuccess: (res: ResponseBase<FoilStampingsResponse>) => {
        callback?.();
        if (!isInit && param.productNameId !== getState().order.itemDetail.itemSelect.value) {
          return;
        }
        dispatch(ordersDataActions.updateCover({
          foilStamping: { leafColor: res.body.data?.leafColor || [] },
        }));
        const leafColorCover = getState().order.cover.coverProcessing;
        if (leafColorCover.value && res.body.data?.leafColor?.find((v) => v.leafColorId === leafColorCover.value)) {
          dispatch(ordersActions.cover.setCoverProcessing(leafColorCover.value, leafColorCover.label));
        }
      },
      onError: () => {
        dispatch(ordersDataActions.updateCover({
          foilStamping: { leafColor: [] },
        }));
        callback?.();
      },
    },
    {
      apiName: 'foil-stamping-cover',
      ignoreSystemError: true,
    },
  ))
};
export const apiFoilStampingsGenuineThunk = (param: FoilStampingsRequest, callback?: () => void, isInit?: boolean): AppThunk => async (dispatch, getState) => {
  dispatch(apiActions.run(
    // new ApiFoilStampings(param),
    new ApiFoilStampings({
      productNameId: param.productNameId,
      itemId: param.itemId,
    }),
    {
      onSuccess: (res: ResponseBase<FoilStampingsResponse>) => {
        callback?.();
        if (!isInit && param.productNameId !== getState().order.itemDetail.itemSelect.value) {
          return;
        }
        dispatch(ordersDataActions.updateGenuine({
          foilStamping: { leafColor: res.body.data?.leafColor || [] },
        }));
        const leafColorGenuine = getState().order.genuine.genuineProcessing;
        if (leafColorGenuine.value && res.body.data?.leafColor?.find((v) => v.leafColorId === leafColorGenuine.value)) {
          dispatch(ordersActions.genuine.setGenineProcessing(leafColorGenuine.value, leafColorGenuine.label));
        }
      },
      onError: () => {
        dispatch(ordersDataActions.updateGenuine({
          foilStamping: { leafColor: [] },
        }));
        callback?.();
      },
    },
    {
      apiName: 'foil-stamping-genuine',
      ignoreSystemError: true,
    },
  ))
};

export const apiFoilStampingsCoverColorThunk = (param: FoilStampingsCoverColorRequest, callback?: () => void): AppThunk => async (dispatch) => {
  if (!param.coverColorId) {
    dispatch(ordersDataActions.updateCover({
      foilStampingAfterProcessing: { coverColorPostPrintRel: [] },
    }));
    callback?.();
    return;
  }
  dispatch(apiActions.run(
    new ApiFoilStampings(param),
    {
      onSuccess: (res: ResponseBase<FoilStampingsCoverColorResponse>) => {
        callback?.();
        dispatch(ordersDataActions.updateCover({
          foilStampingAfterProcessing: { coverColorPostPrintRel: res.body.data?.coverColorPostPrintRel || []},
        }));
      },
      onError: (e) => {
        callback?.();
        dispatch(ordersDataActions.updateCover({
          foilStampingAfterProcessing: { coverColorPostPrintRel: [] },
        }));
      },
    },
    {
      ignoreSystemError: true,
    },
  ));
};
