import { Table } from '../../ui/table/table';
import { Button } from '../../ui/button/button';
import { useDispatch } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import { dialogActions } from '../slice/dialog-slice';
import { EditFormDesignation } from './edit-form-designation';
import { SelectFormDesignation } from './select-form-designation';
import './send-form-designation-config.scss';
import iconEdit from '../../../assets/img/icon/edit.svg';
import iconDelete from '../../../assets/img/icon/delete.svg';
import { apiActions } from '../../../slices/api-slice';
import { ApiOrderFormDelete, ApiOrderFormFileGet, ApiOrderFormGet } from '../../../api/front/order-form/api-order-form';
import { DisplayElement } from '../../../models/display-element';
import { ResponseBase } from '../../../api/response-base';
import { OrderFormFileGetResponse, OrderFormGetResponse } from '../../../models/api/front/order-form';
import { deliveryActions } from '../../../slices/orders/delivery-slice';
import { useAppSelector } from '../../../app/hooks';
import lodash, { forEach } from 'lodash';
import { PdfPreview } from './pdf-preview';
import { xmlActions } from '../../../xml/slice/xml-slice';

interface SendFormDesignationConfigProps {
  kijshopCd: string,
  shopOrderId: string
}
export const SendFormDesignationConfig = (props: SendFormDesignationConfigProps) => {
  // - Hooks -
  const dispatch = useDispatch();
  // const { kijshopCd } = useParams<PathParams>();
  const { kijshopCd, shopOrderId } = props;
  // - State -
  const { orderFormState, xml, isProxyMode } = useAppSelector((state) => ({
    orderFormState: state.delivery.orderForm,
    xml: state.xml[shopOrderId],
    isProxyMode: Boolean(state.xml[shopOrderId]?.info.metaModel.dpNumber),
  }), lodash.isEqual);
  // -- apiからの発注帳票データ --
  const [listData, setListData] = useState<OrderFormGetResponse[]>([]);
  // -- 選択した帳票データ --
  const [selectData, setSelectData] = useState<OrderFormGetResponse>();
  // -- 選択したテーブルの行 --
  const [selectTable, setSelectTable] = useState(-1);
  // -- 一覧取得中フラグ --
  const [isConnecting, setIsConnecting] = useState(true);
  // -- PDF名前一覧 --
  const [nameList, setNameList] = useState<{formDesignationName: string, fileName: string}[]>([]);
  // - Callback -
  // -- 修正ボタン押下 --
  const handlerClickChange = useCallback((data: OrderFormGetResponse) => {
    dispatch(dialogActions.push({
      title: '発送帳票編集',
      element: <EditFormDesignation
        type="edit"
        data={data}
        kijshopCd={kijshopCd}
        nameList={nameList.filter((v) => v.formDesignationName !== data.name)}
      />,
    }));
  }, [nameList]);
  // -- 削除ボタン押下 --
  const handlerClickDelete = useCallback((id: string) => {
    dispatch(dialogActions.pushMessage({
      title: '確認',
      message: [
        '選択した発注帳票をリストから削除します。',
        'よろしいですか？',
      ],
      buttons: [
        {
          label: 'いいえ',
          callback: () => {
            dispatch(dialogActions.pop());
          },
        },
        {
          label: 'はい',
          callback: () => {
            dispatch(apiActions.run(
              new ApiOrderFormDelete(id, kijshopCd, {}),
              {
                onSuccess: () => {
                  setSelectData(undefined);
                  setSelectTable(-1);
                  if (xml && xml.delivery.viewModel.orderFormGUID === id) {
                    xml.delivery.viewModel.formName = undefined;
                    xml.delivery.viewModel.pdfPath = undefined;
                    xml.delivery.viewModel.originalPath = undefined;
                    xml.delivery.viewModel.importPath = undefined;
                    xml.delivery.viewModel.orderFormGUID = undefined;
                    xml.delivery.build();
                    dispatch(xmlActions.shopOrder({ kijshopCd, shopOrderId }).update(xml));
                    dispatch(deliveryActions.setAddressState({
                      sendFormDesignation: {},
                    }))
                  }
                  dispatch(dialogActions.pop());
                  dispatch(apiActions.run(
                    new ApiOrderFormGet({ kijshopCd }),
                    {
                      onSuccess: (res: ResponseBase<OrderFormGetResponse[]>) => {
                        setIsConnecting(false);
                        dispatch(deliveryActions.setOrderFormState(res.body.data || []));
                        setNameList(res.body.data?.map((v) => ({
                          formDesignationName: v.name,
                          fileName: v.orgFilename,
                        }))|| []);
                      },
                    },
                  ));
                },
              },
            ));
          },
        },
      ],
    }));
  }, []);
  // -- 新規登録ボタン押下 --
  const handlerClickRegister = useCallback(() => {
    dispatch(dialogActions.push({
      title: '発送帳票登録',
      element: <EditFormDesignation
        type="register"
        kijshopCd={kijshopCd}
        nameList={nameList}
      />,
    }));
  }, [nameList]);
  // -- OKボタン押下 --
  const handlerClickOk = useCallback(() => {
    dispatch(dialogActions.pop());
    if (isProxyMode && selectTable === 0) {
      dispatch(deliveryActions.setAddressState(
        {
          sendFormDesignation: {
            name: '指定なし',
            path: '',
            id: '',
            originalPath: '',
            importPath: '',
          },
        },
      ));
    } else {
      dispatch(deliveryActions.setAddressState(
        {
          sendFormDesignation: {
            name: selectData!.name,
            path: selectData!.path,
            id: selectData!.orderformId,
            originalPath: selectData!.orgFilename,
            importPath: selectData!.filename,
          },
        },
      ));
    }
  }, [selectData, selectTable]);
  // -- 閉じるボタン押下 --
  const handlerClickClose = useCallback(() => {
    dispatch(dialogActions.pop());
  }, []);
  // -- テーブル押下 --
  const handlerClickTableRow = useCallback((i) => {
    if (i === selectTable) {
      setSelectTable(-1);
      setSelectData(undefined);
    } else {
      if (isProxyMode){
        if (i > 0) {
          setSelectData(listData[i - 1]);
        }
      } else {
        setSelectData(listData[i]);
      }
      setSelectTable(i);
    }
  }, [selectTable, listData]);
  // -- pdf展開 --
  const handlerOpenPDF = useCallback((v: OrderFormGetResponse) => {
    dispatch(apiActions.run(
      new ApiOrderFormFileGet({
        kijshopCd: kijshopCd,
        orderfromId: v.orderformId,
      }),
      {
        onSuccess: (res: File) => {
          if (res) {
            const file: File = new File([res], v.orgFilename, { type: 'application/pdf' });
            dispatch(dialogActions.push({
              title: 'プレビュー',
              element: <PdfPreview
                file={file}
              />,
            }));
          }
        },
      },
    ));
  }, []);
  // - Effect -
  // -- 発注帳票一覧取得 --
  useEffect(() => {
    dispatch(apiActions.run(
      new ApiOrderFormGet({ kijshopCd }),
      {
        onSuccess: (res: ResponseBase<OrderFormGetResponse[]>) => {
          setIsConnecting(false);
          dispatch(deliveryActions.setOrderFormState(res.body.data || []));
          setNameList(res.body.data?.map((v) => ({
            formDesignationName: v.name,
            fileName: v.orgFilename,
          }))|| []);
        },
      },
    ));
  }, []);
  useEffect(() => {
    if (listData !== orderFormState) {
      setListData(orderFormState);
    }
  }, [orderFormState, listData]);
  // - テーブルbodyリスト -
  const tableBody = [...listData].map((v) => ([
    <div className="shipping_form_item">
      <div className="shipping_form_item__ttl">
        {v.name.replaceAll(' ', '\u00A0')}
      </div>
      <div className="shipping_form_item__btns">
        <Button label="プレビュー" color="light" size="sm" onClick={() => handlerOpenPDF(v)} />
        <Button
          label="編集"
          onClick={() => handlerClickChange(v)}
          color="light"
          size="sm"
          icon={<img src={iconEdit} alt="" />}
        />
        <Button
          label="削除"
          onClick={() => handlerClickDelete(v.orderformId)}
          color="light"
          size="sm"
          icon={<img src={iconDelete} alt="" />}
        />
      </div>
    </div>,
  ]));
  const unspecified = (
    [
      <div className="shipping_form_item">
      <div className="shipping_form_item__ttl">
        指定なし
      </div>
    </div>
    ]
  );

  return (
    <div className="dialog_contents send_form_designation_config">
      <div className="dialog_contents__body">
        {tableBody.length || isConnecting ? (<></>) : (
          <div className="alert_message">
            リストがありません。新規登録ボタンより、使用する帳票を登録してください。
          </div>
        )}
        <Table
          head={['発送帳票リスト']}
          body={(isProxyMode) ? [ unspecified,...tableBody] : tableBody}
          minimumNumOfRows={30}
          className="send_form_designation_config__table"
          handlerSelect={handlerClickTableRow}
          selected={selectTable}
        />
      </div>
      <div className="dialog_contents__footer send_form_designation_config__footer">
        <div className="send_form_designation_config__footer__left">
          <Button
            label="新規登録"
            onClick={handlerClickRegister}
          />
        </div>
        <div className="send_form_designation_config__footer__right">
          <Button
            label="閉じる"
            onClick={handlerClickClose}
          />
          <Button
            label="OK"
            onClick={handlerClickOk}
            disabled={selectTable === -1}
          />
        </div>
      </div>
    </div>
  );
};
