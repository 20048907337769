import {
  FavoriteTemplateDeleteRequest,
  FavoriteTemplateGetRequest,
  FavoriteTemplatePostRequest,
  FavoriteTemplateUpdateRequest,
} from '../../../models/api/front/favorite-template';
import { ApiBaseFront } from "../api-base-front";

// お気に入りテンプレート一覧取得
export class ApiFavoriteTemplateGet extends ApiBaseFront {
  constructor(param: FavoriteTemplateGetRequest) {
    super(
      'GET',
      '/api/v1/favtemplate',
      'JSON',
      param,
    );
  }
}

// お気に入りテンプレート追加・更新（更新の場合はid必要）
export class ApiFavoriteTemplatePost extends ApiBaseFront {
  constructor(param: FavoriteTemplatePostRequest, id?: string) {
    super(
      'POST',
      `/api/v1/favtemplate${id ? `/${id}` : ''}`,
      'JSON',
      param,
    );
  }
}
// お気に入りテンプレート追加・更新（更新の場合はid必要）
export class ApiFavoriteTemplateUpdate extends ApiBaseFront {
  constructor(param: FavoriteTemplateUpdateRequest, id: string) {
    super(
      'POST',
      `/api/v1/favtemplate/${id}`,
      'JSON',
      param,
    );
  }
}

// お気に入りテンプレート削除
export class ApiFavoriteTemplateDelete extends ApiBaseFront {
  constructor(param: FavoriteTemplateDeleteRequest, id: string,) {
    super(
      'DELETE',
      `/api/v1/favtemplate/${id}`,
      'JSON',
      param,
    )
  }
}
