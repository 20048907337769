import EventEmitter from 'eventemitter3';
import { ILayoutEditorManagerBase } from '../layout-editor.manager';
import { ImageEditManager } from '../image-edit/image-edit.manager';
import { UiManager } from '../ui/ui.manager';
import iconDropper from '../../../assets/img/icon/dropper.svg';
import { AlbumManager } from '../../albam/albam';

type EventType = {
  'change': (param: { color: string }) => void,
};

export class ColorDropperManager extends EventEmitter<EventType> implements ILayoutEditorManagerBase {

  private imageEditManager!: ImageEditManager;
  private uiManager!: UiManager;
  private albumManager!: AlbumManager;
  private onMouseMove = (e: MouseEvent) => {};
  private pastColor = '';
  private container: HTMLDivElement | null = null;
  private canvas: HTMLCanvasElement | null = null;

  constructor() {
    super();
  }

  di(
    imageEditManager: ImageEditManager,
    uiManager: UiManager,
    albumManager: AlbumManager,
  ): void {
    this.imageEditManager = imageEditManager;
    this.uiManager = uiManager;
    this.albumManager = albumManager;
  }

  initialize() {
    this.uiManager.on('r->l:color-dropper:start', (e) => this.start(e.container, e.onChange));
    this.uiManager.on('r->l:color-dropper:stop', () => this.stop());
  }

  destroy() {
    if (!this.uiManager) return;
    this.uiManager.off('r->l:color-dropper:start')
    this.uiManager.off('r->l:color-dropper:stop')
    // this.removeAllListeners();
  }

  start(container: HTMLDivElement | string, callback: (p: { color: string }) => void) {
    if (container instanceof HTMLDivElement) {
      this.container = container;
    } else {
      if (document.getElementById(container)) {
        this.container = document.getElementById(container) as HTMLDivElement;
      } else {
        throw new Error(`${container} が見つかりません !!`);
      }
    }
    const w = this.albumManager.currentAlbum?.parseData.templateData.size[0].virtual[0].$.width;
    const h = this.albumManager.currentAlbum?.parseData.templateData.size[0].virtual[0].$.height
    const originalCanvas = this.imageEditManager.getCanvasEle();
    const originalCtx = originalCanvas.getContext('2d');
    this.canvas = document.createElement('canvas');
    this.canvas.width = w;
    this.canvas.height = h;
    this.canvas.id = 'color-dropper-dialog-canvas';
    const ctx = this.canvas.getContext('2d');
    if (!ctx) {
      throw new Error('ctx is null or undefined !!');
    }
    if (!originalCtx) {
      throw new Error('originalCtx is null or undefined !!');
    }
    this.container.appendChild(this.canvas);
    const originalCanvasImage = new Image();
    originalCanvasImage.src = originalCanvas.toDataURL();
    originalCanvasImage.onload = () => {
      const w = this.albumManager.currentAlbum?.parseData.templateData.size[0].virtual[0].$.width;
      const h = this.albumManager.currentAlbum?.parseData.templateData.size[0].virtual[0].$.height;
      ctx.drawImage(
        originalCanvasImage,
        ImageEditManager.STAGE_CONTAINER_X, ImageEditManager.STAGE_CONTAINER_Y, w, h,
        0, 0, w, h,
      );
      if (!this.canvas) {
        throw new Error('this.container is null !!');
      }
      // - cursor 変更 -
      this.canvas.style.cursor = `url(${iconDropper}) 0 14, crosshair`;
      this.onMouseMove = (e: MouseEvent) => {
        if (!this.canvas) {
          throw new Error('canvas is null !!');
        }
        const rect = this.canvas.getBoundingClientRect();
        const fixRatio = this.canvas.width / rect.width;
        const pos = {
          x: (e.clientX - rect.left) * fixRatio,
          y: (e.clientY - rect.top) * fixRatio,
        };
        const imageData = ctx.getImageData(pos.x, pos.y, 1, 1);
        const rgba = {
          r: imageData.data[0],
          g: imageData.data[1],
          b: imageData.data[2],
          a: imageData.data[3],
        };
        const color = `rgba(${rgba.r}, ${rgba.g}, ${rgba.b}, ${rgba.a})`;
        if (this.pastColor === color) {
          return;
        }
        this.pastColor = color;
        callback({ color });
      };
      this.canvas.addEventListener('mousemove', this.onMouseMove);
    };
  }

  stop() {
    // - cursor 変更 -
    if (!this.canvas) {
      throw new Error('this.container is null !!');
    }
    this.canvas.style.cursor = 'auto';
    this.canvas.removeEventListener('mousemove', this.onMouseMove);
  }

}
