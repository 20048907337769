import React from 'react';
import { Button } from '../button/button';
import { Input } from '../input/input';

type WizardContentProps = {
  children: React.ReactElement[] | React.ReactElement;
}

type WizardInputListProps = {
  children: React.ReactElement[] | React.ReactElement;
}

type WizardInputProps = {
  label: string,
  value: string,
  disabled: boolean,
  isPassword: boolean,
  maxLength: number,
  error?: boolean,
  tooltip?: { messages: string[], error?: boolean },
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void,
  onMouseOver?: () => void,
  onMouseLeave?: () => void,
  descTop?: string,
  descTopUrl?: string,
  descBottom?: string | React.ReactElement,
  descBottomUrl?: string,
}

type WizardBodyListProps = {
  children: React.ReactElement[] | React.ReactElement;
}

type WizardBodyProps = {
  body: string[],
}

type WizardButtonListProps = {
  children: React.ReactElement[] | React.ReactElement;
}

type WizardButtonProps = {
  label: string,
  onClick: () => void,
  desc?: string,
}

// メール送信しましたメッセージ
export const WizardMailSentForPassword = ['info@dioss.jp から送信されたメールをご確認ください', '届いたメールを開いて、パスワード変更の手続きを進めてください'];
export const WizardMailSentForEmail = ['info@dioss.jp から送信されたメールをご確認ください', '届いたメールを開いて、メールアドレス変更の手続きを進めてください'];
// メールが届かない場合
export const WizardMailNotice = ['メールが届かない場合','　', '・迷惑メールフォルダ・フィルタ・メール転送などの', '　設定をご確認ください', '・メールが送信されるまでに時間がかかることがあります。'];

export type WizardContentModel = {
  // タイトル
  title?: string,
  subTitle?: string,
  // 本文
  body?: string[][],
  // 注意書き
  notice?: string[][],
  // 入力項目
  input?: {
    label: string,
    initialValue: string,
    disabled: boolean,
    isPassword: boolean,
    maxLength: number,
    isError?: boolean,
    tooltip?: string,
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void,
    onMouseOver?: () => void,
    onMouseLeave?: () => void,
    descTop?: string,
    descTopUrl?: string,
    descBottom?: string,
    descBottomUrl?: string,
  }[],
  // ボタン
  button: {
    label: string,
    onClick: () => void
  }[],
}

export const WizardInputList: React.FC<WizardInputListProps> = (props) => {
  const { children } = props;
  return (
    <div className="wizard__contents__inner__input">
      {children}
    </div>
  );
};

export const WizardInput = (props: WizardInputProps) => {
  const {
    label,
    value,
    disabled,
    isPassword,
    maxLength,
    error,
    tooltip,
    onChange,
    onMouseOver,
    onMouseLeave,
    descTop,
    descTopUrl,
    descBottom,
    descBottomUrl,
  } = props;
  return (
    <div
      className={'wizard__contents__inner__input__wrapper'}
    >
      {descTop && <div
        className={'wizard__contents__inner__input__wrapper__desc_top'}
      >
        {descTopUrl && <a
          href={descTopUrl}
          target="_blank"
          rel="noopener noreferrer"
        >{descTop}</a>}
        {!descTopUrl && descTop}
      </div>}
      <div className={'wizard__contents__inner__input__wrapper__label'}>{label}</div>
      <Input
        type={isPassword ? 'password' : 'text'}
        value={value}
        uiSize="lg"
        error={error}
        tooltip={error && tooltip ? tooltip : undefined}
        maxLength={maxLength}
        disabled={disabled}
        onChange={onChange}
        onMouseOver={onMouseOver}
        onMouseLeave={onMouseLeave}
      />
      {descBottom && <div
        className={'wizard__contents__inner__input__wrapper__desc_bottom'}
      >
        {descBottomUrl && <a
          href={descBottomUrl}
          target="_blank"
          rel="noopener noreferrer"
        >{descBottom}</a>}
        {!descBottomUrl && descBottom}
      </div>}
    </div>
  );
};

export const WizardButtonList: React.FC<WizardButtonListProps> = (props) => {
  const { children } = props;
  return (
    <div className={'wizard__contents__inner__button'}>
      {children}
    </div>
  );
};

export const WizardButton = (props: WizardButtonProps) => {
  const { label, onClick } = props;
  return (
    <Button
      label={label}
      onClick={onClick}
    />
  );
};

export const WizardBodyList: React.FC<WizardBodyListProps> = (props) => {
  const { children } = props;
  return (
    <div className="wizard__contents__inner__body">
      {children}
    </div>
  );
};

export const WizardBody = (props: WizardBodyProps) => {
  const { body } = props;
  return (
    <div>
      {body.map((item, i) => (
        <div
          key={i}
        >
          {item}
        </div>
      ))}
    </div>
  );
};

export const WizardNotice = (props: WizardBodyProps) => {
  const { body } = props;
  return (
    <div className="wizard__contents__inner__notice">
      {body.map((item, i) => (
        <div
          key={i}
        >
          {item}
        </div>
      ))}
    </div>
  );
}

export const WizardContent: React.FC<WizardContentProps> = ({ children }) => {
  return (
    <>
      {children}
    </>
  );
};