import { ApiBaseFront } from '../api-base-front';

export type GetXmlResponse = {
  fileinfo: {
    filename: string,
    body: string,
  }[],
};

export class ApiAllGetXml extends ApiBaseFront<GetXmlResponse> {
  constructor(kijShopCd: string, shopOrderId: string) {
    super(
      'GET',
      `/api/v1/orderxml/${kijShopCd}/${shopOrderId}`,
      'JSON',
      {},
    );
  }
}