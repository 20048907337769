import { ApiBaseBack } from '../../api-base-back';
import { ChronologiesRequest, ChronologiesResponse } from '../../../../models/api/back/create-order/chronologies';
import { AppThunk } from '../../../../app/store';
import { ordersDataActions } from '../../../../slices/orders/orders-data-slice';
import { apiActions } from '../../../../slices/api-slice';
import { ResponseBase } from '../../../response-base';
import { ordersActions } from '../../../../slices/orders-slice';

export class ApiChronologies extends ApiBaseBack<ChronologiesResponse> {
  constructor(param: ChronologiesRequest) {
    super(
      'GET',
      '/api/v1/chronologies',
      'JSON',
      param,
    );
  }
}

export const apiChronologiesThunk = (param: ChronologiesRequest, callback?: () => void, isInit?: boolean): AppThunk => async (dispatch, getState) => {
  if (!param.productNameId) {
    dispatch(ordersDataActions.updateGenuine({
      chronologies: { chronologies: [] },
    }));
    callback?.();
    return;
  }
  dispatch(apiActions.run(
    new ApiChronologies(param),
    {
      onSuccess: (res: ResponseBase<ChronologiesResponse>) => {
        callback?.();
        if (!isInit && param.productNameId !== getState().order.itemDetail.itemSelect.value) {
          return;
        }
        dispatch(ordersDataActions.updateGenuine({
          chronologies: { chronologies: res.body.data?.chronologies || [] },
        }));
        const chronologies = getState().order.genuine.chronologies;
        if (chronologies.value && res.body.data?.chronologies?.find((v) => v.chronologyId === chronologies.value)) {
          dispatch(ordersActions.genuine.setChronology(chronologies.value, chronologies.label));
        }
      },
      onError: () => {
        callback?.();
      },
    },
  ));
};