import { ILayoutEditorManagerBase } from '../layout-editor.manager';
import { UiManager } from '../ui/ui.manager';
import { TransformParser } from '../../../utilities/transform-parser';
import { ImageEditManager } from '../image-edit/image-edit.manager';
import { AlbumManager } from '../../albam/albam';

const getMostNearNumFromArr = (arr: number[], num: number) => {
  return arr
    .map((v, i) => ({ v, diff: Math.abs(num - v), i }))
    .sort((a, b) => a.diff - b.diff)
    [0];
};

const getMostNearIndexFromArr = (arr: number[], num: number) => {
  return getMostNearNumFromArr(arr, num).i;
};

export class PanZoomManager implements ILayoutEditorManagerBase {

  private static TARGET_ELEMENT_CLASS_NAME = 'panzoom-target-ele';
  private prevTarget: HTMLElement | null = null;
  private operationMode: 'pan-zoom' | 'select' = 'select';

  // - flag -
  private isDidReset = false;
  // - active -
  private active = false;
  // - target -
  private target: HTMLElement | null = null;
  // - zoom -
  private readonly scales = [0.1, 0.25, 0.5, 0.75, 1, 2, 4];
  private origin = { x: 0, y: 0 };
  scale: number = 1;
  private lv: number = 4;
  // - pan -
  diff = { x: 0, y: 0 };
  private adjustPos = { x: 0, y: 0 };
  // - flag -
  private isFit = true;
  // - listener -
  private scrollEvt?: (e: WheelEvent) => void
  // - manager -
  private uiManager!: UiManager;
  private albumManager!: AlbumManager;
  // - callback -
  // -- UiManager 用 --
  private onChangeOperationMode: (v: { mode: 'pan-zoom' | 'select' }) => void = (v: { mode: 'pan-zoom' | 'select' }) => {
    switch (v.mode) {
      case 'pan-zoom':
        this.toggleActive(true);
        this.operationMode = 'pan-zoom';
        break;
      default:
        this.toggleActive(false);
        this.operationMode = 'select';
        break;
    }
    this.uiManager.emit('r->l:change-operation-cursor', { mode: v.mode });
  };
  private onZoom: (v: { lv: number }) => void = (v: { lv: number }) => {
    this.changeLv(v.lv);
    this.isFit = false;
  };
  private onZoomLvUp: (v: {}) => void = (v: {}) => {
    // lv の近似処理
    this.isFit = false;
    this.lv = getMostNearIndexFromArr(this.scales, this.scale);
    this.changeLv(this.lv += 1);
  };
  private onZoomLvDown: (v: {}) => void = (v: {}) => {
    // lv の近似処理
    this.isFit = false;
    this.lv = getMostNearIndexFromArr(this.scales, this.scale);
    this.changeLv(this.lv -= 1);
  };
  private onZoomFit: (v: {}) => void = (v: {}) => {
    if (this.target) {
      this.isFit = true;
      this.fit(this.target);
    }
  };
  private onZoomLvGet: (v: { callback: (p: { lv: number }) => void }) => void = (v: { callback: (p: { lv: number }) => void }) => {
    v.callback({ lv: this.lv });
  };
  // -- DOM 用 --
  private onMousedownForPanZoom = (e: MouseEvent) => {
    if (!this.active) {
      return;
    }

    const target = e.currentTarget as HTMLElement;
    const prevDiffPos = { x: this.diff.x, y: this.diff.y };
    const startPos = { x: 0, y: 0 };
    const diffPos = { x: 0, y: 0 };
    let dragging = false;
    dragging = true;
    startPos.x = e.clientX;
    startPos.y = e.clientY;
    const onMouseMove = (e: MouseEvent) => {
      if (!dragging) {
        return;
      }
      this.isFit = false;
      diffPos.x = e.clientX - startPos.x + prevDiffPos.x;
      diffPos.y = e.clientY - startPos.y + prevDiffPos.y;
      const transformObj = {
        translate: [diffPos.x, diffPos.y],
      };
      this.setTransform(target, transformObj, this.origin);
      this.diff.x = diffPos.x;
      this.diff.y = diffPos.y;
      this.didPan();
    };
    target.addEventListener('mousemove', onMouseMove);
    target.addEventListener('mouseup', () => {
      target.removeEventListener('mousemove', onMouseMove);
      dragging = false;
    }, { once: true });
    target.addEventListener('mouseleave', () => {
      target.removeEventListener('mousemove', onMouseMove);
      dragging = false;
    }, { once: true });
  };

  /**
   * マウスホイールでのスクロール機能
   */
  // private scrollCanvas(e: WheelEvent) {
  //   const target = e.currentTarget as HTMLElement;
  //   const prevDiffPos = { x: this.diff.x, y: this.diff.y };
  //   const diffPos = { x: 0, y: 0 };
  //   if (e.shiftKey) {
  //   diffPos.x = e.deltaY + prevDiffPos.x;
  //   diffPos.y = prevDiffPos.y;
  //   } else {
  //   diffPos.x = prevDiffPos.x;
  //   diffPos.y = e.deltaY + prevDiffPos.y;
  //   }
  //   const transformObj = {
  //     translate: [diffPos.x, diffPos.y],
  //   };
  //   this.diff.x = diffPos.x;
  //   this.diff.y = diffPos.y;
  //   this.setTransform(target, transformObj, this.origin);
  // }

  di(
    uiMng: UiManager,
    albumMng: AlbumManager,
  ): void {
    this.uiManager = uiMng;
    this.albumManager = albumMng;
  }

  initialize(): void {
    this.scale = 1;
    this.lv = 4;
    // - UiManager -
    this.uiManager.on('r->l:change-operation-mode', this.onChangeOperationMode);
    this.uiManager.on('r->l:zoom', this.onZoom);
    this.uiManager.on('r->l:zoom:lv:up', this.onZoomLvUp);
    this.uiManager.on('r->l:zoom:lv:down', this.onZoomLvDown);
    this.uiManager.on('r->l:zoom:fit', this.onZoomFit);
    this.uiManager.on('l->r:zoom:lv:get', this.onZoomLvGet);
  }

  destroy(): void {
    // - UiManager -
    this.uiManager.off('r->l:change-operation-mode', this.onChangeOperationMode);
    this.uiManager.off('r->l:zoom', this.onZoom);
    this.uiManager.off('r->l:zoom:lv:up', this.onZoomLvUp);
    this.uiManager.off('r->l:zoom:lv:down', this.onZoomLvDown);
    this.uiManager.off('r->l:zoom:fit', this.onZoomFit);
    this.uiManager.off('l->r:zoom:lv:get', this.onZoomLvGet);
    // - DOM -
    if (!this.target) {
      return;
    }
    this.target.removeEventListener('mousedown', this.onMousedownForPanZoom);
    // if (this.scrollEvt) {
    //   this.target.removeEventListener('wheel', this.scrollEvt);
    // }
  }

  /**
   * Panzoom の起動
   * @param target
   */
  boot(target: HTMLElement | null) {
    this.target = target;
    if (!target) {
      throw new Error('target is null !!');
    }
    if (this.prevTarget === target && this.scale) return;
    // - initialize -
    this.lv = 4;
    this.adjustPos = {
      x: ImageEditManager.STAGE_CONTAINER_X,
      y: ImageEditManager.STAGE_CONTAINER_Y,
    };
    target.classList.add(PanZoomManager.TARGET_ELEMENT_CLASS_NAME);
    // - reset -
    this.reset(target);
    // - add event -
    target.addEventListener('mousedown', this.onMousedownForPanZoom);
    // this.scrollEvt = (e: WheelEvent) => {
    //   e.preventDefault();
    //   this.scrollCanvas(e);
    // }
      // target.addEventListener('wheel', this.scrollEvt);
    // - panzoom -
    this.prevTarget = target;
    this.operationMode === 'pan-zoom' ?
      this.onChangeOperationMode({ mode: 'pan-zoom' })
      : this.onChangeOperationMode({ mode: 'select' });
  }

  /**
   * ズーム
   * @param target
   * @param scale
   * @private
   */
  private zoom(target: HTMLElement, scale: number) {
    const s = scale;
    if (!target.parentElement) {
      throw new Error('target.parentElement is undefined !!!');
    }

    const templateW = Number(this.albumManager.currentAlbum?.parseData.templateData.size[0].virtual[0].$.width);
    const templateH = Number(this.albumManager.currentAlbum?.parseData.templateData.size[0].virtual[0].$.height);

    this.origin = {
      x: this.adjustPos.x + templateW / 2,
      y: this.adjustPos.y + templateH / 2,
    };

    if (this.scales.find((v) => v === this.scale)) {
      this.setTransform(
        target,
        {
          scale: s,
        },
        this.origin,
      );
    } else {
      this.diff = {
        x: (templateW * this.scale - templateW) / 2 + ((templateW <= templateH) ? this.diff.x : 0),
        y: (templateH * this.scale - templateH) / 2 + ((templateW >= templateH) ? this.diff.y : 0),
      };
      this.setTransform(
        target,
        {
          translate: [this.diff.x, this.diff.y],
          scale: s,
        },
        this.origin,
      );
    }
  }

  /**
   * フィット
   * target の親要素のサイズにフィットする
   * @param target
   * @private
   */
  private fit(target: HTMLElement) {
    const margin = 1 - 0.01;
    const parent = target.parentElement;
    if (!parent) {
      return;
    }
    const parentRect = parent.getBoundingClientRect();
    const templateW = this.albumManager.currentAlbum?.parseData.templateData.size[0].virtual[0].$.width;
    const templateH = this.albumManager.currentAlbum?.parseData.templateData.size[0].virtual[0].$.height;
    const ratio = {
      w: (parentRect.width * margin) / templateW,
      h: (parentRect.height * margin) / templateH,
    };
    this.scale = ratio.w < ratio.h ? ratio.w : ratio.h;
    this.origin = {
      x: this.adjustPos.x,
      y: this.adjustPos.y,
    };
    const translate = [
      (parentRect.width - (templateW * this.scale)) / 2,
      (parentRect.height - (templateH * this.scale)) / 2,
    ];
    this.diff.x = translate[0];
    this.diff.y = translate[1];
    this.setTransform(
      target,
      {
        translate,
        scale: this.scale,
      },
      this.origin,
    );
    this.didPanAndZoom();
  }

  /**
   * リセット処理
   * - 初期座標の調整は setTransform の内部で吸収してる
   * - 初回は Fit 初回以外は 前回のページでの diff/scale を使って pan/zoom する
   * @param target
   * @private
   */
  private reset(target: HTMLElement) {
    if (this.isDidReset && this.scale && !this.isFit) {
      const transformObj = {
        translate: [this.diff.x, this.diff.y],
        scale: this.scale,
      };
      this.setTransform(target, transformObj, this.origin);
      // this.zoom(target, this.scale);
    } else {
      this.fit(target);
    }
    this.didPanAndZoom();
    this.isDidReset = true;
  }

  /**
   * zoom 実行後のメソッド
   * @private
   */
  private didZoom() {
    this.didPanAndZoom();
  }

  /**
   * pan 実行後のメソッド
   * @private
   */
  private didPan() {
    this.didPanAndZoom();
  }

  /**
   * pan/zoom 実行後のメソッド
   * @private
   */
  private didPanAndZoom() {
    if (!this.target) {
      return;
    }
    const targetRect = this.target?.getBoundingClientRect();
    const scale = this.scale;
    const x = targetRect.x + (this.adjustPos.x * this.scale);
    const y = targetRect.y + (this.adjustPos.y * this.scale);
    this.uiManager.emit(
      'l->r:zoom:change',
      { scale, x, y },
    );
  }

  /**
   * transform 設定処理
   * - translate/scale を変更する
   * - translate の初期座標調整はこのメソッド内部で行う
   * @param target
   * @param transformObj
   * @param origin
   * @private
   */
  private setTransform(target: HTMLElement, transformObj: any, origin: { x: number, y: number }) {
    const parser = new TransformParser();
    const prevTransformObj = parser.parse(target.style.transform);
    if (transformObj.translate) {
      if (transformObj.translate[0] || transformObj.translate[0] === 0) {
        transformObj.translate[0] = transformObj.translate[0] - this.adjustPos.x;
      }
      if (transformObj.translate[1] || transformObj.translate[1] === 0) {
        transformObj.translate[1] = transformObj.translate[1] - this.adjustPos.y;
      }
    }
    const mergedObj = {
      ...prevTransformObj,
      ...transformObj,
    };
    target.setAttribute(
      'style',
      `transform: ${parser.build(mergedObj)}; transform-origin: ${origin.x}px ${origin.y}px`,
    );
  }

  /**
   * lv による scale の変更処理
   * @param lv
   * @private
   */
  private changeLv(lv: number) {
    if (!this.target) {
      return;
    }
    let _lv = lv;
    if (lv < 0) {
      _lv = 0;
    } else if (lv >= this.scales.length) {
      _lv = this.scales.length - 1;
    }
    const s = this.scales[_lv];
    this.lv = _lv;
    this.zoom(this.target, s);
    this.scale = s;
    this.uiManager.emit('l->r:zoom:lv:change', { lv: _lv });
    this.didZoom();
  }

  /**
   * Panzoom 機能の有効無効設定
   * @param active
   * @private
   */
  private toggleActive(active: boolean) {
    this.active = active;
  }

}
