import React from 'react';
import './angle-icon.scss';

type Props = {
  color?: string;
  direction?: 'top' | 'right' | 'bottom' | 'left';
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export const AngleIcon = (props: Props) => {
  const { color = '#000000', direction, ...defaultProps } = props;

  return (
    <div
      {...defaultProps}
      className={`angle_icon ${direction || 'left'}`}
      style={{ color }}
    />
  );
};
