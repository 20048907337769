// noinspection JSVoidFunctionReturnValueUsed

import {
  orderRequest,
  orderRequestOptional,
  ordersActions,
} from '../../orders-slice';
import {AppThunk} from '../../../app/store';
import {apiTagsThunk} from '../../../api/back/create-order/tags/api-tags';
import {apiItemsThunk} from '../../../api/back/create-order/items/api-items';
import {apiCoversThunk} from '../../../api/back/create-order/covers/api-covers';
import {apiCoverColorThunk} from "../../../api/back/create-order/cover-colors/api-cover-colors";
import * as lodash from 'lodash';
import { ordersDataActions } from '../orders-data-slice';

// カテゴリ更新
export const setCategory = (value: string, label: string = '', agent: boolean, isInit?: boolean): AppThunk => async (dispatch, getState)  => {
  const order = lodash.cloneDeep(getState().order);
  const data = getState().orderData;
  const visibleCondition = getState().createOrder.visibleCondition;
  let flag = false;
  // - 該当のカテゴリの場合、写真アルバムプロフラグを有効にする -
  // switch (value) {
  //   case 'jp0299':
  //   case 'jp0306':
  //   case 'jp0337':
  //   case 'jp0343':
  //   case 'jp0350':
  //   case 'jp0374':
  //   case 'jp0386':
  //   case 'jp0390':
  //   case 'jp0395':
  //   case 'jp0399':
  //   case 'jp0410':
  //   case 'jp0411':
  //   case 'jp0425':
  //   case 'jp0426':
  //   case 'jp0427':
  //   case 'jp0444':
  //   case 'jp0453':
  //   case 'jp0454':
  //   case 'jp0506':
  //   case 'jp0539':
  //   case 'jp0550':
  //   case 'jp0559':
  //   case 'jp0570':
  //   case 'jp0573':
  //     order.orderInfo.proFlg = true;
  //     flag = true;
  //     dispatch(ordersActions.updateOrderInfo({
  //       proFlg: true,
  //     }));
  //     break;
  //   default:
  //     order.orderInfo.proFlg = false;
  //     flag = false;
  //     dispatch(ordersActions.updateOrderInfo({
  //       proFlg: false,
  //     }));
  //     break;
  // }
  if (order.itemInfo.category.value !== value) {
    dispatch(ordersDataActions.updateItemDetail({
      item: { products: [] },
    }));
  }
  order.itemInfo.category.value = value;
  if (visibleCondition?.checkContentVisible(order, data, 'o_isCategorySap', visibleCondition.visibleData.orderInfo.o_isCategorySap, { hidden: true })) {
    order.orderInfo.proFlg = true;
    flag = true;
    dispatch(ordersActions.updateOrderInfo({
      proFlg: true,
    }));
  } else {
    order.orderInfo.proFlg = false;
    flag = false;
    dispatch(ordersActions.updateOrderInfo({
      proFlg: false,
    }));
  }
  const requestOptional = orderRequestOptional(order, getState().createOrder.visibleCondition, data);
  const request = orderRequest(order, getState().createOrder.visibleCondition, data);
  dispatch(ordersActions.updateItemInfo({
    category: { value, label },
  }));
  // - カテゴリ更新時のアクション -
  // -- タグ --
  dispatch(apiTagsThunk(
    {
      serviceId: value,
    },
    () => {},
    false,
    agent,
  ));
  // -- 商品 --
  if (!flag) {
    dispatch(apiItemsThunk({
      serviceId: value,
      productTagId: requestOptional.productTagId,
      // coverTypeId: (flag) ? requestOptional.coverTypeId : undefined,
      // coverColorId: (flag) ? requestOptional.coverColorId : undefined,
    }));
  }
  // -- カバー --
  dispatch(apiCoversThunk(
    {
      serviceId: value,
      productTagId: requestOptional.productTagId,
    },
    () => {},
    false,
    agent,
  ));
  // -- ページ種 --
  // dispatch(apiPageDataTypesThunk({
  //   productNameId: request.productNameId,
  //   parentFlg: request.parentFlg,
  //   serviceId: value,
  // }));
  // -- 箔押し --
  // dispatch(apiFoilStampingsThunk({
  //   serviceId: value,
  //   productNameId: request.productNameId,
  //   coverColorId: request.coverColorId,
  //   productTagId: requestOptional.productTagId,
  //   coverTypeId: requestOptional.coverTypeId,
  // }));
};

// 絞り込み条件（タグ）更新
export const setTag = (value: string, label: string = '', agent: boolean): AppThunk => async (dispatch, getState)  => {
  const order = getState().order;
  const data = getState().orderData;
  const visibleCondition = getState().createOrder.visibleCondition;
  const requestOptional = orderRequestOptional(order, visibleCondition, data);
  const request = orderRequest(order, visibleCondition, data);
  dispatch(ordersActions.updateItemInfo({
    tag: { value, label },
  }));
  // - タグ更新時のアクション -
  // -- カバー --
  dispatch(apiCoversThunk(
    {
      serviceId: request.serviceId,
      productTagId: value || undefined,
    },
    () => {},
    false,
    agent,
  ));
  // -- カバー色 --
  dispatch(apiCoverColorThunk(
    {
      serviceId: requestOptional.serviceId,
      coverTypeId: requestOptional.coverTypeId,
      productNameId: requestOptional.productNameId,
      productTagId: value || undefined,
    },
    () => {},
    false,
    '',
    agent,
  ));
  // -- 商品 --
  if (
    !visibleCondition?.checkContentVisible(order, data, 'o_isCategorySap', visibleCondition.visibleData.orderInfo.o_isCategorySap, { hidden: true })
    || requestOptional.coverTypeId
  ) {
    dispatch(apiItemsThunk({
      serviceId: request.serviceId,
      productTagId: value || undefined,
      coverTypeId: requestOptional.coverTypeId,
      coverColorId: requestOptional.coverColorId,
      // coverTypeId: requestOptional.coverColorId,
      // coverColorId: requestOptional.coverTypeId,
    }));
  }
  // -- 箔押し --
  // dispatch(apiFoilStampingsThunk({
  //   // serviceId: request.serviceId,
  //   productNameId: request.productNameId,
  //   // coverColorId: request.coverColorId,
  //   // productTagId: value || undefined,
  //   // coverTypeId: requestOptional.coverTypeId,
  // }));
};
