import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OrderPageDataXml } from '../xml/class/order/order-page-data-xml';
import { XmlStructureOrderInfoData } from '../xml/model/xml-structure-model';
import { VisibleConditionValueGetter } from '../manager/visible-condition-value-getter';
import { TVisibleCondition } from '../models/t-visible-condition';
import { IMasterProduct } from '../models/i-master-product';

type FavData = {
  parent: {
    id: string,
    pageDataList: OrderPageDataXml[],
  },
  option: XmlStructureOrderInfoData[],
};
type CreateOrderSliceState = {
  visibleCondition: VisibleConditionValueGetter,
  pageStatus: 'in' | 'out',
  loading: boolean,
  favData: FavData | null,
  /* focus-out でAPIが走って保存ボタンの押下が不可になるので focus の時点で押下不可にしておく */
  isFocusPageCount: boolean,
};

const initialState: CreateOrderSliceState = {
  visibleCondition: new VisibleConditionValueGetter(),
  pageStatus: 'out',
  loading: false,
  favData: null,
  isFocusPageCount: false,
};

export const createOrderSlice = createSlice({
  name: 'create-order',
  initialState,
  reducers: {
    changePageStatus: (state, action: PayloadAction<'in' | 'out'>) => {
      state.pageStatus = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setFavData: (state, action: PayloadAction<FavData | null>) => {
      state.favData = action.payload;
    },
    setIsFocusPageCount: (state, action: PayloadAction<boolean>) => {
      if (state.isFocusPageCount !== action.payload) {
        state.isFocusPageCount = action.payload;
      }
    },
    diVisibleConditionValueGetter: (state, action: PayloadAction<{ visibleCondition?: TVisibleCondition, kijshopCd?: string }>) => {
      state.visibleCondition.di(action.payload);
    },
  },
});

export const createOrderActions = createOrderSlice.actions;
export const createOrderReducer = createOrderSlice.reducer;
