import { ILayoutEditorManagerBase } from '../layout-editor.manager';
import { UiManager } from '../ui/ui.manager';
import { AlbumPage } from '../../albam/albam';
import { ImageEditManager } from '../image-edit/image-edit.manager';
import { LayoutDataManager } from '../data/layout-data.manager';

export class PreviewManager implements ILayoutEditorManagerBase {

  private uiManager!: UiManager;
  private layoutDataManager!: LayoutDataManager;
  private intervalTimerId = -1;
  private outCanvas: HTMLCanvasElement;

  constructor() {
    this.outCanvas = document.createElement('canvas');
  }

  initialize(): void {
  }

  destroy(): void {
  }

  di(
    uiManager: UiManager,
    layoutDataMng: LayoutDataManager,
  ): void {
    this.uiManager = uiManager;
    this.layoutDataManager = layoutDataMng;
  }

  start(canvas: HTMLCanvasElement | null, album: AlbumPage) {
    const interval = 3000;
    this.intervalTimerId = window.setInterval(() => {
      if (canvas) {
        this.makePreviewImage(canvas, album);
      }
    }, interval);
  }

  stop() {
    window.clearInterval(this.intervalTimerId);
  }

  makePreviewImage(canvas: HTMLCanvasElement, album: AlbumPage) {
    const quality = 0.8;
    const scale = 0.2;
    const format = 'image/jpeg';
    const ctx: CanvasRenderingContext2D = this.outCanvas.getContext('2d') as CanvasRenderingContext2D;
    if (!ctx) {
      return;
    }
    const w = album.parseData.templateData.size[0].virtual[0].$.width;
    const h = album.parseData.templateData.size[0].virtual[0].$.height
    this.outCanvas.width = w * scale;
    this.outCanvas.height = h * scale;
    ctx.drawImage(
      canvas,
      ImageEditManager.STAGE_CONTAINER_X, ImageEditManager.STAGE_CONTAINER_Y, w, h,
      0, 0, w * scale, h * scale,
    );
    const url = this.outCanvas.toDataURL(format, quality);
    this.uiManager.emit(
      'r->l:preview:make',
      {
        url,
        album,
        callback: (list) => {
          this.layoutDataManager.dispatch('setPreviewList', { list, target: album });
        },
      },
    );
  }

}
