import {
  WizardButton,
  WizardButtonList,
  WizardContent,
  WizardInput,
  WizardInputList,
} from '../../../ui/wizard/wizard-content';
import { useParams } from 'react-router-dom';
import { PathParams, RoutingPath } from '../../../../routes/routing-path';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { useCallback, useState } from 'react';
import { apiActions } from '../../../../slices/api-slice';
import { dialogActions } from '../../../dialog/slice/dialog-slice';

type Props = {
  setStep: (step: number) => void;
}

type TooltipType = 'password' | 'none';
export const Step1StaffMngEnableWizard = (props: Props) => {
  const { setStep } = props;
  // - Hooks-
  const { kijshopCd } = useParams<PathParams>();
  const dispatch = useDispatch();
  // - State -
  const [password, setPassword] = useState('');
  const [isPasswordError, setIsPasswordError] = useState(false);
  // - Handlers -
  const handlerValidation = useCallback(() => {
    return password !== '';
  },[password]);
  const handlerChangePassword = useCallback((value: string) => {
    setPassword(value);
  }, [password]);
  // -- キャンセルボタン --
  const handlerClickCancel = useCallback(() => {
    dispatch(push(RoutingPath.cnv.configSystem({ kijshopCd })));
  }, []);
  // -- 次へボタン --
  const handlerClickNext = useCallback(() => {
    if (!handlerValidation()) {
      dispatch(dialogActions.pushMessage({
        title: '確認',
        message: ['入力内容に不備があります。'],
        buttons: [
          {
            label: 'OK',
            callback: () => {
              dispatch(dialogActions.pop());
            },
          },
        ],
      }));
      return;
    }
    dispatch(apiActions.login({
      kijshopCd,
      password,
    }, {
      onSuccess: () => setStep(2),
      onBasicError: (res) => {
        setIsPasswordError(true);
        dispatch(dialogActions.pushMessage({
          title: '確認',
          message: res.messages,
          buttons: [
            {
              label: 'OK',
              callback: () => {
                dispatch(dialogActions.pop());
              },
            },
          ],
        }));
        return
      },
    }));
  }, [password]);

  return (
    <WizardContent>
      <WizardInputList>
        <WizardInput
          label="店舗コード"
          value={kijshopCd}
          disabled={true}
          isPassword={false}
          maxLength={7}
          descTop={'店鋪用のパスワードを入力してください'}
        />
        <WizardInput
          label="パスワード"
          value={password}
          disabled={false}
          isPassword={true}
          maxLength={100}
          error={isPasswordError}
          onChange={(e) => handlerChangePassword(e.target.value)}
        />
      </WizardInputList>
      <WizardButtonList>
        <WizardButton
          label="キャンセル"
          onClick={() => handlerClickCancel()}
        />
        <WizardButton
          label="次へ"
          onClick={() => handlerClickNext()}
        />
      </WizardButtonList>
    </WizardContent>
  );
};