import { Button, ButtonRectType } from '../../../ui/button/button';
import { useCallback, useEffect, useRef, useState } from 'react';
import iconViewList from '../../../../assets/img/icon/view-list.svg';
import iconSelect from '../../../../assets/img/icon/select.svg';
import iconInsertImg from '../../../../assets/img/icon/insert-image.svg';
import iconFreeText from '../../../../assets/img/icon/text.svg';
import iconCenter from '../../../../assets/img/icon/center-line.svg';
import iconRuler from '../../../../assets/img/icon/ruler.svg';
import iconHand from '../../../../assets/img/icon/hand.svg';
import iconTrimming from '../../../../assets/img/icon/trimming.svg';
import iconRotateFrame from '../../../../assets/img/icon/rotate_middle_frame.svg';
import iconChangeFrame from '../../../../assets/img/icon/change_middle_frame.svg';
import iconShowFrame from '../../../../assets/img/icon/show_middle_frame.svg';
import iconSemiTransparentFrame from '../../../../assets/img/icon/semitransparent_middle_frame.svg';
import iconTransparentFrame from '../../../../assets/img/icon/transparent_middle_frame.svg';
import iconUndo from '../../../../assets/img/icon/undo.svg';
import iconRedo from '../../../../assets/img/icon/redo.svg';
import './layout.toolbar.scss';
import { SwitchIcon } from '../../../ui/switch-icon/switch-icon';
import { UiManager } from '../../../../layout-editor/manager/ui/ui.manager';
import { FreeText, TextInfo } from './layout.free-text';
import { LayoutImageList } from './layout.image-list';
import { EditableImage } from '../../../../layout-editor/manager/image-edit/editable-image';
import { useParams } from 'react-router-dom';
import { PathParams } from '../../../../routes/routing-path';
import { useDispatch } from 'react-redux';
import { dialogActions } from '../../../dialog/slice/dialog-slice';
import { MenuType } from '../layout';
import { useAppSelector } from '../../../../app/hooks';
import * as lodash from 'lodash';
import { Pos } from '../../../../models/pos';
import { layoutActions } from '../../../../slices/layout-slice';

export type AdditionalShowType = 'show' | 'semi' | 'hide'

type ToolButtonProps = {
  // アイコン
  icon: string,
  // ラベル
  label: string,
  // クリックハンドラ
  onClick: () => void,
  // 簡易表示フラグ
  isSimple: boolean,
  // 選択状態
  isSelected: boolean,
  // 矩形情報の取得コールバック
  getRect?: (rect: ButtonRectType) => void,
  divider?: boolean,
  disabled?: boolean,
};

const ToolButton = (props: ToolButtonProps) => {
  const { icon, label, onClick, isSimple, getRect, isSelected, divider, disabled } = props;
  return (
    <>
      {divider ? <div className="horizon_divider" /> : (<></>)}
      <Button
        icon={<img src={icon} />}
        label={isSimple ? undefined : label}
        onClick={onClick}
        color="dark"
        className={`tool_button${isSimple ? ' simple' : ''}${isSelected ? ' selected' : ''}`}
        size="sm"
        getRect={getRect}
        disabled={disabled}
        // style={isSelected ? {
        //   opacity: '0.5',
        // } : {}}
      />
    </>
  );
};

type ToolType =
  'view-list'
  | 'select'
  | 'edit-image'
  | 'insert-image'
  | 'insert-text'
  | 'edit-text'
  | 'rotate-right'
  | 'rotate-left'
  | 'center'
  | 'ruler'
  | 'trimming'
  | 'rotate-middle-frame'
  | 'change-middle-frame'
  | 'show-middle-frame'
  | 'redo'
  | 'undo'

const _toolList: {
  type: ToolType,
  icon: string,
  label: string,
  DAIFlag?: boolean,
  divider?: boolean,
}[] = [
  {
    type: 'view-list',
    icon: iconViewList,
    label: '画像一覧',
  },
  {
    type: 'select',
    icon: iconSelect,
    label: '選択ツール',
  },
  {
    type: 'insert-image',
    icon: iconInsertImg,
    label: 'pngの配置',
  },
  {
    type: 'insert-text',
    icon: iconFreeText,
    label: 'テキスト追加',
  },
  {
    type: 'center',
    icon: iconCenter,
    label: 'センター',
  },
  {
    type: 'ruler',
    icon: iconRuler,
    label: 'ルーラー',
  },
  {
    type: 'trimming',
    icon: iconTrimming,
    label: '人寸トリミング',
    DAIFlag: true,
    divider: true,
  },
  {
    type: 'rotate-middle-frame',
    icon: iconRotateFrame,
    DAIFlag: true,
    label: '中枠の回転',
  },
  {
    type: 'change-middle-frame',
    icon: iconChangeFrame,
    DAIFlag: true,
    label: '中枠の切替',
  },
  {
    type: 'show-middle-frame',
    icon: iconShowFrame,
    DAIFlag: true,
    label: '中枠の表示',
  },
  {
    type: 'undo',
    icon: iconUndo,
    label: '元に戻す',
    divider: true,
  },
  {
    type: 'redo',
    icon: iconRedo,
    label: 'やり直し',
  },
  // /* 現状ラボモード保留のため一部排除 */
  // {
  //   type: 'edit-image',
  //   icon: iconHand,
  //   label: '手のひらツール',
  // },
  // {
  //   type: 'edit-text',
  //   icon: sample,
  //   label: 'テキスト編集',
  // },
  // {
  //   type: 'rotate-right',
  //   icon: sample,
  //   label: '右へ倒す',
  // },
  // {
  //   type: 'rotate-left',
  //   icon: sample,
  //   label: '左へ倒す',
  // },

];
type LayoutToolBarProps = {
  textPos: Pos | null,
  callbackMenu: (v: MenuType) => void;
  menuParam: MenuType;
  unUsedEditableList: string[],
  unUsedPhotoList: any[],
  errorCount: number,
}
export const LayoutToolbar = (props: LayoutToolBarProps) => {
  const { textPos, menuParam, callbackMenu, unUsedEditableList, unUsedPhotoList, errorCount } = props;
  const dispatch = useDispatch();
  const { album, isStopTextPosWarning } = useAppSelector((state) => ({
    album: state.layout.currentAlbumPage,
    isStopTextPosWarning: state.layout.isStopTextPosWarning,
  }), lodash.isEqual);
  const isDai = album?.parseData.templateData?.additionalFrame;
  // - Params -
  const { kijshopCd, shopOrderId, orderId } = useParams<PathParams>();
  // - State -
  const [toolList, setToolList] = useState(_toolList);
  // -- 選択状態 --
  const [selectTool, setSelectTool] = useState<ToolType>('select');
  // -- 画像選択モード --
  const [operationMode, setOperationMode] = useState<'pan-zoom' | 'select'>('select');
  // -- テキスト追加ボタンの矩形情報 --
  const [insTxtBtnRect, setInsTxtBtnRect] = useState<ButtonRectType>({});
  // -- ツールバーコンテンツのy軸オフセット値 --
  const [offsetTop, setOffsetTop] = useState(0);
  // -- 簡易表示 --
  const [isSimple, setIsSimple] = useState(false);
  const [isViewList, setIsViewList] = useState(true);
  const [isCenter, setIsCenter] = useState(false);
  const [isRuler, setIsRuler] = useState(true);
  const [isTrimMenu, setIsTrimMenu] = useState(false);
  const [isOpenTextTool, setIsOpenTextTool] = useState(false);
  const [disabledTool, setDisabledTool] = useState<'redo' | 'undo' | 'both' | 'none'>('both');
  const [transparentFrame, setTransparentFrame] = useState<'show' | 'semi' | 'hide'>('show');

  // -- ツールバーデータ --
  const [info, setInfo] = useState<TextInfo>({
    id: '',
    isBold: false,
    isItalic: false,
    textColor: '#808080',
    isOnDropper: false,
    fontType: '',
    font: '0',
    fontSize: '11',
    textTransparency: 100,
    dropShadow: false,
    transparency: 65,
    distance: 7,
    outlineWidth: 0,
    outlineColor: '#ff0000',
    textAlign: '0',
    textRotation: 0,
    textSelectRotation: 0,
    lineSpacingNum: 1,
    textAreaContents: '',
    height: 0,
  });
  // テキストイメージ
  // - Ref -
  // -- ツールバー要素 --
  const toolbarEle = useRef<HTMLDivElement>(null);
  const imagesize = (file: File) => {
    return new Promise<{ width: number, height: number }>((resolve, reject) => {
      const img = new Image();

      img.onload = () => {
        const size = {
          width: img.naturalWidth,
          height: img.naturalHeight,
        };

        URL.revokeObjectURL(img.src);
        resolve(size);
      };

      img.onerror = (error) => {
        reject(error);
      };

      img.src = URL.createObjectURL(file);
    });
  };
  // - Callback -
  const onClickImageSelect = useCallback(() => {
    if (!album) return;
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/png';
    input.multiple = false;
    input.onchange = async (e) => {
      if (input.files) {
        const size = await imagesize(input.files[0]);
        if ((size.width >= 500 && size.height >= 500) && (size.width <= 2000 && size.height <= 2000)) {
          UiManager.ins.emit('l->r:wait-loading', { message: 'PNG画像を生成中です...' });
          UiManager.ins.emit(
            'r->l:add-image:direct',
            {
              file: input.files[0],
              kijshopCd,
              shopOrderId,
              orderId: orderId || null,
              onCreatedEditableImage: (p) => {
                UiManager.ins.emit('r->l:add-editable-image', {
                  editableImage: p.editableImage,
                  file: input.files![0],
                });
              },
              callback: (p: { editableImage: EditableImage }) => {
                // png 時は order-select は更新しない
                // dispatch(xmlActions.uploadImage({
                //   kijshopCd,
                //   shopOrderId,
                // }).layout(orderId || '').add(p.editableImage, () => {
                //   // if (String(p.editableImage.exif.colorSpace) !== '1') {
                //   //   dispatch(dialogActions.pushMessage({
                //   //     title: '確認',
                //   //     message: !p.editableImage.exif.colorSpace ? [
                //   //       'Exifヘッダ情報に色空間の情報がありません。',
                //   //       '色空間がsRGBでない場合、色が変わる可能性があります。',
                //   //     ] : [
                //   //       'sRGB以外の色空間が指定されています。',
                //   //     ],
                //   //     buttons: [{
                //   //       label: 'OK',
                //   //       callback: () => {
                //   //         dispatch(dialogActions.pop());
                //   //       },
                //   //     }],
                //   //   }));
                //   // }
                // }));
              },
            },
          );
        } else if (size.width < 500 || size.height < 500) {
          dispatch(
            dialogActions.pushMessage({
              title: '確認',
              message: [
                `PNG画像のサイズが小さすぎます(幅${size.width} x 高さ${size.height}ピクセル)。`,
                '幅500 x 高さ500ピクセル以上にしてください。',
              ],
              buttons: [
                {
                  label: 'OK',
                  callback: () => dispatch(dialogActions.pop()),
                },
              ],
            }),
          );
        } else {
          dispatch(
            dialogActions.pushMessage({
              title: '確認',
              message: [
                `PNG画像のサイズが大きすぎます(幅${size.width} x 高さ${size.height}ピクセル)。`,
                '幅2000 x 高さ2000ピクセル以内にしてください。',
              ],
              buttons: [
                {
                  label: 'OK',
                  callback: () => dispatch(dialogActions.pop()),
                },
              ],
            }),
          );
        }
      }
    };
    input.click();
  }, [album]);

  // -- 選択状態変更 --
  const handlerChangeTool = useCallback((v: ToolType) => {
    if (v !== 'view-list' && !album) return;
    if (v === 'redo' || v === 'undo' || v === 'ruler' || v === 'center' || v === 'insert-image' || v === 'view-list') {
      // 「元に戻す」「やり直し」「センター」「ルーラー」「pngの配置」「画像一覧」は変更なし状態
      // 「センター」「ルーラー」「画像一覧」のアクティブ状態はそれぞれ isCenter, isRuler, isViewList で管理
    } else if (selectTool === v) {
      setSelectTool('select');
    } else {
      setSelectTool(v);
    }
    switch (v) {
      case 'view-list':
        setIsViewList(!isViewList);
        break;
      case 'select':
        const mode = selectTool === 'select'
          ? operationMode === 'pan-zoom' ? 'select' : 'pan-zoom'
          : operationMode;
        UiManager.ins.emit('r->l:change-operation-mode', { mode });
        setOperationMode(mode);
        UiManager.ins.emit('l->r:shortcut:change-operation-mode-reset', { isSelect: mode !== 'select' });
        break;
      case 'insert-image':
        onClickImageSelect();
        // UiManager.ins.emit('r->l:insert-image');
        break;
      case 'insert-text':
        setOperationMode('select');
        const _mode = selectTool === 'insert-text' ? 'select' : 'insert-text';
        setSelectTool(_mode);
        UiManager.ins.emit('r->l:change-operation-mode', { mode: 'select' });
        if (_mode === 'insert-text') {
          const isCover = album?.thumbnail.templatePath.includes('___') ?? false;
          if (isCover && !isStopTextPosWarning) {
            dispatch(dialogActions.pushMessage({
              title: '確認',
              message: [
                `【ご注意ください！】`,
                '背表紙に掛かるフリーテキストは、仕上がり時に削除されます。',
                'フリーテキストは背表紙に配置しない、または掛からないようにして下さい。',
              ],
              buttons: [{
                label: 'OK',
                callback: () => {
                  dispatch(dialogActions.pop());
                  UiManager.ins.emit('r->l:insert-text:start', {
                    callback: (isOpen: boolean, info: TextInfo) => {
                      setInfo(info);
                      setIsOpenTextTool(isOpen);
                    },
                  });
                },
              }],
              remind: {
                callback: (v) => dispatch(layoutActions.setIsStopTextPosWarning(v)),
              },
            }));
          } else {
            UiManager.ins.emit('r->l:insert-text:start', {
              callback: (isOpen: boolean, info: TextInfo) => {
                setInfo(info);
                setIsOpenTextTool(isOpen);
              },
            });
          }
        } else {
          UiManager.ins.emit('r->l:insert-text:end', {});
          // UiManager.ins.emit('r->l:change-operation-cursor', { mode: 'select' });
        }
        break;
      case 'center':
        const _isCenter = !isCenter;
        setIsCenter(!isCenter);
        callbackMenu({
          ...menuParam,
          center: !isCenter,
        });
        UiManager.ins.emit('r->l:center-line', { display: _isCenter });
        break;
      case 'ruler':
        const _isRuler = !isRuler;
        setIsRuler(!isRuler);
        UiManager.ins.emit(('r->l:ruler'), { display: _isRuler });
        break;
      case 'trimming':
        // const _isTrimMenu = !isTrimMenu;
        setIsTrimMenu(!isTrimMenu);
        setOperationMode('select');
        UiManager.ins.emit('r->l:change-operation-mode', { mode: 'select' });
        UiManager.ins.emit('r->l:check:open-trim-menu', { isOpen: !isTrimMenu });
        // UiManager.ins.emit('l->r:open-trim-menu', {isOpen: !isTrimMenu});
        break;
      case 'rotate-middle-frame':
        setOperationMode('select');
        UiManager.ins.emit('r->l:rotate-additional', {});
        break;
      case 'change-middle-frame':
        setOperationMode('select');
        UiManager.ins.emit('r->l:transform-additional', {});
        break;
      case 'show-middle-frame':
        let resultFrame: AdditionalShowType = 'show';
        let setIcon = iconShowFrame;
        switch (transparentFrame) {
          case 'show':
            resultFrame = 'semi';
            setIcon = iconSemiTransparentFrame;
            break;
          case 'semi':
            resultFrame = 'hide';
            setIcon = iconTransparentFrame;
            break;
          case 'hide':
            resultFrame = 'show';
            setIcon = iconShowFrame;
            break;
          default:
            break;
        }
        setTransparentFrame(resultFrame);
        const targetIndex = toolList.findIndex((v) => v.type === 'show-middle-frame');
        setToolList((prev) => {
          prev[targetIndex].icon = setIcon;
          return prev;
        });
        UiManager.ins.emit('r->l:transparent-additional', {
          type: resultFrame, callback: () => {
          },
        });
        callbackMenu({
          ...menuParam,
          additionalShow: resultFrame,
        });
        break;
      case 'redo':
        UiManager.ins.emit('r->l:redo');
        break;
      case 'undo':
        UiManager.ins.emit('r->l:undo');
        break;
      // /* 現状ラボモード保留のため一部排除 */
      // case 'edit-image':
      //   break;
      // case 'edit-text':
      //   break;
      // case 'rotate-right':
      //   UiManager.ins.emit('r->l:rotate', { angle: 90 });
      //   break;
      // case 'rotate-left':
      //   UiManager.ins.emit('r->l:rotate', { angle: -90 });
      //   break;
      default:
        break;
    }
  }, [isCenter, isRuler, isViewList, isTrimMenu, operationMode, isOpenTextTool, selectTool, menuParam, callbackMenu, isStopTextPosWarning, album]);
  // -- 表示状態変更 --
  const handlerClickViewMode = useCallback((v) => {
    setIsSimple(v);
  }, []);

  // - Effect -
  useEffect(() => {
    // -- 画像一覧のドロワー表示時ツールバーを簡易表示に --
    const onChangeDisable = (v: { listLength: number, nowIndex: number }) => {
      if (!v.listLength) {
        setDisabledTool('both');
      } else if (v.listLength === v.nowIndex) {
        setDisabledTool('redo');
      } else if (v.nowIndex === 0) {
        setDisabledTool('undo');
      } else {
        setDisabledTool('none');
      }
    };
    UiManager.ins.on('r->l:command-list:get', onChangeDisable);
    UiManager.ins.on('l->r:error-close-trim-menu', () => {
      setIsTrimMenu(false);
      setSelectTool('select');
    })
    UiManager.ins.on('r->l:close-trim-menu', () => {
      setIsTrimMenu(false);
      setSelectTool('select');
    });
    UiManager.ins.on('l->r:push-dialog', (v) => {
      dispatch(dialogActions.pushMessage({
        title: v.title,
        message: v.message,
        buttons: v.buttons.map((v2) => ({
          ...v2,
          callback: () => {
            v2.callback();
            dispatch(dialogActions.pop());
          },
        })),
        remind: v.remind,
      }));
    });
    UiManager.ins.on('l->r:show-text-tool', (v) => {
      setInfo(v.textInfo);
      setIsOpenTextTool(v.isOpen);
    });
    UiManager.ins.on('r->l:shortcut:add-image-png', () => {
      onClickImageSelect();
    });
    UiManager.ins.on('r->l:shortcut:change-operation-mode', (v) => {
      UiManager.ins.emit('r->l:change-operation-mode', { mode: v.mode });
      setOperationMode(v.mode);
    });
    if (isDai) {
      UiManager.ins.on('r->l:shortcut:open-trim-menu', () => {
        handlerChangeTool('trimming');
      });
      UiManager.ins.on('l->r:change-template', () => {
        setIsTrimMenu(false)
      });
    }
    return () => {
      UiManager.ins.off('r->l:close-trim-menu');
      UiManager.ins.off('l->r:error-close-trim-menu');
      UiManager.ins.off('l->r:push-dialog');
      UiManager.ins.off('r->l:command-list:get', onChangeDisable);
      UiManager.ins.off('l->r:show-text-tool');
      UiManager.ins.off('r->l:shortcut:add-image-png');
      UiManager.ins.off('r->l:shortcut:change-operation-mode');
      UiManager.ins.emit('l->r:shortcut:change-operation-mode-reset', { isSelect: false });
      if (isDai) {
        UiManager.ins.off('r->l:shortcut:open-trim-menu');
        UiManager.ins.off('l->r:change-template');
      }
    };
  }, []);

  useEffect(() => {
    UiManager.ins.emit('r->l:shortcut:trim-flag-reset', { isTrimMenu: isTrimMenu });
  }, [isTrimMenu]);


  useEffect(() => {
    setOffsetTop(toolbarEle.current?.offsetTop || 0);
  }, [toolbarEle]);
  // -- textModeの終了を検知 --
  useEffect(() => {
    if (selectTool !== 'insert-text' && !isOpenTextTool) {
      UiManager.ins.emit('r->l:insert-text:end', ({}));
    }
  }, [selectTool, isOpenTextTool]);
  // -- albumの変更 --
  useEffect(() => {
    // - 台紙分岐 -
    if (!isDai) {
      setToolList(_toolList.filter((list) => !list.DAIFlag));
    }
    UiManager.ins.emit('r->l:change-operation-mode', { mode: 'select' });
    UiManager.ins.emit('l->r:shortcut:change-operation-mode-reset', { isSelect: false });
    setOperationMode('select');
    if (selectTool === 'insert-text' || selectTool === 'trimming') {
      setSelectTool('select');
    }
  }, [album]);

  return (
    <div
      className="layout_toolbar"
      ref={toolbarEle}
      onClick={() => UiManager.ins.emit('r->l:dis-select')}
    >
      <div className={`layout_toolbar__tools${isSimple ? ' simple' : ''}`}>
        <Button
          icon={
            <SwitchIcon
              isOn={isSimple}
              tmplMode="open_x"
            />}
          onClick={() => handlerClickViewMode(!isSimple)}
          color="dark"
          className="open_x_btn"
        />
        {toolList.map((v, i) => (
          <ToolButton
            key={`tool-button_${v.type}_${i}_${isSimple}`}
            icon={v.type === 'select' && operationMode === 'pan-zoom' ? iconHand : v.icon}
            label={v.label}
            onClick={() => handlerChangeTool(v.type)}
            isSimple={isSimple}
            isSelected={
              (!(v.type === 'view-list' || v.type === 'select' || v.type === 'ruler' || v.type === 'center') && selectTool === v.type)
              || (isViewList && v.type === 'view-list')
              || (isRuler && v.type === 'ruler')
              || (isCenter && v.type === 'center')
            }
            // isSelected={selectTool === 'view-list' && v.type === 'view-list'}
            getRect={v.type === 'insert-text' ? setInsTxtBtnRect : undefined}
            divider={v.divider}
            disabled={(() => {
              if (!album) {
                if (v.type !== 'view-list') {
                  return true;
                } else {
                  return false;
                }
              }
              return v.type === disabledTool || disabledTool === 'both' ? v.type === 'redo' || v.type === 'undo' : false;
            })()}
          />
        ))}
      </div>

      {isViewList ?
        <LayoutImageList
          unUsedEditableList={unUsedEditableList}
          unUsedPhotoList={unUsedPhotoList}
          errorCount={errorCount}
        />
        : (<></>)
      }
      {/* {selectTool === 'view-list' ? <LayoutImageList /> : (<></>)} */}
      {/*{selectTool === 'insert-text' ? (*/}
      {
        isOpenTextTool ? (
          <FreeText
            info={info}
            // onGetTextInfo={(info) => {
            //   // console.log(info)
            // }}
            style={{
              top: textPos ? `calc(${info.adjustedPos?.y ?? textPos.y}px + ${info.height ? 0 : Number(info.fontSize) * (info.pos?.scale ?? 1)}px)` : offsetTop + (insTxtBtnRect.top || 0),
              left: textPos ? textPos.x : `calc(${insTxtBtnRect.width}px + 2em)`,
            }}
            popCallback={() => {
              setOperationMode('select');
              setSelectTool('select');
              setIsOpenTextTool(false);
            }}
            // onBlur={true}
          />
        ) : <></>
      }
    </div>
  );
};
