import * as createjs from 'createjs-module';

export class CjBitmap extends createjs.Bitmap {

  timerId: NodeJS.Timeout | undefined;
  width?: number;
  height?: number;

  constructor(imageOrUrl: HTMLImageElement | HTMLCanvasElement | HTMLVideoElement | Object | string) {
    super(imageOrUrl);
  }
}