import { PageCountRequest, PageCountResponse } from '../../../../models/api/back/create-order/page-count';
import { AppThunk } from '../../../../app/store';
import { ordersDataActions } from '../../../../slices/orders/orders-data-slice';
import { ResponseBase } from '../../../response-base';
import { apiActions } from '../../../../slices/api-slice';
import { ApiBaseBack } from '../../api-base-back';
import { ordersActions } from '../../../../slices/orders-slice';

export class ApiPageCount extends ApiBaseBack<PageCountResponse> {
  constructor(param: PageCountRequest) {
    super(
      'GET',
      '/api/v1/pageCount',
      'JSON',
      param,
    );
  }
}

// productFlg=2 のパターンも同タイミングで実施
export const apiPageCountThunk = (param: PageCountRequest, callback: () => void, isInit: boolean, agent: boolean): AppThunk => async (dispatch, getState) => {
  if ((param.productFlg === '1' && !param.productNameId) || (param.productFlg === '2' && !param.dtemplId)) {
    dispatch(ordersDataActions.updateData({
      pageCount: {
        maxPageNum: '',
        minPageNum: '',
        stepPageNum: '',
      },
    }));
    dispatch(ordersDataActions.updateOption({
      optionPageCount: null,
    }));
    callback?.();
    return;
  }
  dispatch(apiActions.run(
    new ApiPageCount(param),
    {
      onSuccess: (res: ResponseBase<PageCountResponse>) => {
        callback?.();
        if (!isInit && param.productNameId !== getState().order.itemDetail.itemSelect.value) {
          return;
        }
        if (res.error.errorCode !== '404') {
          dispatch(ordersDataActions.updateData({
            pageCount: res.body.data,
          }));
          const min = res.body.data?.minPageNum;
          const max = res.body.data?.maxPageNum;
          const step = res.body.data?.stepPageNum;
          const current = getState().order.genuine.page;
          if (!current || (min && max && (Number(min) > current || Number(max) < current)) || ((current - Number(min)) % Number(step || 1) !== 0)) {
            dispatch(ordersActions.genuine.setPage(Number(min), agent));
          }
        } else {
          dispatch(apiActions.run(
            new ApiPageCount({
              productFlg: '1',
              productNameId: param.productNameId!,
            }),
            {
              onSuccess: (res: ResponseBase<PageCountResponse>) => {
                dispatch(ordersDataActions.updateData({
                  pageCount: res.body.data,
                }));
                dispatch(ordersActions.genuine.setPage(Number(res.body.data!.minPageNum), agent));
              },
            },
          ));
        }
        if (param.dtemplId) {
          dispatch(ordersDataActions.updateOption({
            optionPageCount: (res.body.data?.minPageNum || res.body.data?.maxPageNum || res.body.data?.stepPageNum) ? res.body.data : null,
          }));
        }
      },
      onError: () => {
        callback?.();
      },
    },
    {
      apiName: 'page-count',
      ignoreSystemError: true,
    },
  ));
};