import { AwaitingEllipsis } from '../awaiting/awaiting-ellipsis';
import './loading-popup.scss';
import { useAppSelector } from '../../../app/hooks';

type LoadingCircleProps = {
  dotSize?: string,
  lap?: number,
  label?: string,
}

/** 
 * 円状にドットが並んだローディング
 * @param {string} dotSize ドットの大きさ。デフォルトは'1rem'。
 * @param {number} lap １周にかかる秒数時間（単位：s）。デフォルトは0.8。
 */

export const LoadingPopup = (props: LoadingCircleProps) => {
  const { dotSize = '0.5rem', lap = 0.8, label = 'データ取得中です...' } = props;
  const { isDisplay } = useAppSelector((state) => ({
    isDisplay: state.dialog.errorDialogStatus !== 'unexpectedError',
  }), (a, b) => a.isDisplay === b.isDisplay);
  return isDisplay ? (
    <div className="loading_popup__dimmer">
      <div className="loading_popup">
        {/* <span className="loading_popup__message">
          データ取得中です
          <AwaitingEllipsis color="#00000080" size="0.5rem" />
        </span> */}
        {/*<span className="loading_popup__message">データ取得中です...</span>*/}
        <span className="loading_popup__message">{label}</span>
        <div
          className="loading_popup__dots"
          style={{ fontSize: dotSize, animationDuration: `${lap}s`, WebkitAnimationDuration: `${lap}s` }}
        />
      </div>
    </div >
  ) : (<></>)
};
