import { Input } from '../../ui/input/input';
import { Button } from '../../ui/button/button';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { dialogActions } from '../slice/dialog-slice';
import './save-favorite-brand.scss';
import { FavoriteBrandConfig } from './favorite-brand-config';
import { apiActions } from '../../../slices/api-slice';
// import { ApiFavoritePost } from '../../../api/front/favorite/api-favorite';
import { useAppSelector } from '../../../app/hooks';
import * as lodash from 'lodash';
import { favoriteBrandActions } from '../../../slices/favorite-brand-slice';
import { useParams } from 'react-router-dom';
import { PathParams } from '../../../routes/routing-path';
import { ApiFavoriteGet, ApiFavoritePostRegister } from '../../../api/front/favorite/api-favorite';
import { XmlStructureOrderInfoData } from '../../../xml/model/xml-structure-model';
import { ResponseBase } from '../../../api/response-base';
import { FavoriteGetResponse } from '../../../models/api/front/favorite';
import { OrderPageDataXml } from '../../../xml/class/order/order-page-data-xml';
import { ApiImagesGetOne } from '../../../api/front/images/api-images';
import { LoadingPopup } from '../../ui/loading/loading-popup';
import { XmlFactory } from '../../../xml/factory/xml-factory';

export const SaveFavoriteBrand = (props: { kijshopCd: string, orderInfoDataStructureModel: XmlStructureOrderInfoData }) => {
  const { kijshopCd, orderInfoDataStructureModel } = props;
  // - Hooks -
  const dispatch = useDispatch();
  const { selectBrand } = useAppSelector((state) => ({
    selectBrand: state.favoriteBrand.selectBrand,
  }), lodash.isEqual);
  // - State -
  // -- 名称 --
  const [name, setName] = useState('');
  const [registering, setRegistering] = useState(false);
  // - Callback -
  // -- 名称更新 --
  const handlerChangeName = useCallback((v) => {
    setName(v);
  }, []);
  // -- OKボタン押下 --
  const handlerClickOk = useCallback(() => {
    if (!selectBrand || !orderInfoDataStructureModel.xml) {
      dispatch(dialogActions.pushMessage({
        title: '確認',
        message: ['商品情報の取得に失敗しました'],
        buttons: [{
          label: 'OK',
          callback: () => {
            dispatch(dialogActions.pop());
          },
        }],
      }));
      return;
    }
    if (!name) {
      // dispatch(dialogActions.pop());
      dispatch(dialogActions.pushMessage({
        title: '確認',
        message: [
          'お気に入り商品名称を入力してください。',
        ],
        buttons: [
          {
            label: 'OK',
            callback: () => {
              dispatch(dialogActions.pop());
              // dispatch(dialogActions.push({
              //   title: 'お気に入り商品保存',
              //   element: <SaveFavoriteBrand kijshopCd={kijshopCd} />,
              // }));
            },
          },
        ],
      }));
    } else {
      setRegistering(true);
      const folderId = selectBrand?.type === 'brand' ? selectBrand.parentId : selectBrand.id;
      dispatch(apiActions.run(
        new ApiFavoriteGet({ kijshopCd, folederId: folderId }),
        {
          onSuccess: async (res: ResponseBase<FavoriteGetResponse>) => {
            if (res?.body?.data?.favProductList?.find((v) => v.name === name)) {
              setRegistering(false);
              dispatch(dialogActions.pushMessage({
                title: '確認',
                message: [
                  '入力されたお気に入り商品名称は既に存在します。',
                  '別のお気に入り商品名称を入力してください。',
                ],
                buttons: [
                  {
                    label: 'OK',
                    callback: () => {
                      dispatch(dialogActions.pop());
                    },
                  },
                ],
              }));
            } else {
              const pageDataArr: OrderPageDataXml[] = [];
              const arr: { filename: string, body: string }[] = [];
              const orderInfoData = XmlFactory.createFromIndex({ shopOrderId: orderInfoDataStructureModel.xml.shopOrderId, data: { name: 'order-info-data', indexes: orderInfoDataStructureModel.xml.indexes }});
              const orderInfoDataViewModel = { ...orderInfoDataStructureModel.xml.viewModel };
              if (orderInfoDataViewModel.descriptionInfo) {
                if (orderInfoDataViewModel.descriptionInfo.name !== undefined) {
                  orderInfoDataViewModel.descriptionInfo.name = '';
                }
                if (orderInfoDataViewModel.descriptionInfo.date !== undefined) {
                  orderInfoDataViewModel.descriptionInfo.date = undefined;
                }
                if (orderInfoDataViewModel.descriptionInfo.location !== undefined) {
                  orderInfoDataViewModel.descriptionInfo.location = '';
                }
              }
              orderInfoData.build(orderInfoDataViewModel, orderInfoDataStructureModel.xml.metaModel);
              const orderMethod = orderInfoData.viewModel.orderMethod?.id;
              arr.push({ filename: orderInfoData.xmlUniqueName, body: orderInfoData.xml });
              // arr.push({ filename: orderInfoDataStructureModel.xml.xmlUniqueName, body: orderInfoDataStructureModel.xml.xml });
              if (orderInfoDataStructureModel.parts?.xml) {
                arr.push({ filename: orderInfoDataStructureModel.parts.xml.xmlUniqueName, body: orderInfoDataStructureModel.parts.xml.xml });
                orderInfoDataStructureModel.parts.partsData?.forEach((partsData) => {
                  arr.push({ filename: partsData.xml.xmlUniqueName, body: partsData.xml.xml });
                  if (partsData.page?.xml) {
                    arr.push({ filename: partsData.page.xml.xmlUniqueName, body: partsData.page.xml.xml });
                    partsData.page.pageData?.forEach((pageData) => {
                      /* 完成画像は引き継がない */
                      if (orderMethod === '40' && pageData.viewModel.compositeFileName?.realPath) {
                        pageData.viewModel.compositeFileName = undefined;
                        pageData.build();
                      }
                      arr.push({ filename: pageData.xmlUniqueName, body: pageData.xml });
                      pageDataArr.push(pageData);
                    });
                  }
                });
              }
              // オプション商品の情報を持っているのがクラスになるためオプション商品はクラスを元に生成している
              orderInfoDataStructureModel.xml.optionOrderInfoDataXml.forEach((orderInfoData) => {
                const optionOrderInfoData = XmlFactory.createFromIndex({ shopOrderId: orderInfoData.shopOrderId, data: { name: 'order-info-data', indexes: orderInfoData.indexes }});
                const optionOrderInfoDataViewModel = { ...orderInfoData.viewModel };
                if (optionOrderInfoDataViewModel.descriptionInfo) {
                  if (optionOrderInfoDataViewModel.descriptionInfo.name !== undefined) {
                    optionOrderInfoDataViewModel.descriptionInfo.name = '';
                  }
                  if (optionOrderInfoDataViewModel.descriptionInfo.date !== undefined) {
                    optionOrderInfoDataViewModel.descriptionInfo.date = '';
                  }
                  if (optionOrderInfoDataViewModel.descriptionInfo.location !== undefined) {
                    optionOrderInfoDataViewModel.descriptionInfo.location = '';
                  }
                  optionOrderInfoDataViewModel.descriptionInfo.pageType = undefined;
                }
                optionOrderInfoData.build(optionOrderInfoDataViewModel, { ...orderInfoData.metaModel });
                arr.push({ filename: optionOrderInfoData.xmlUniqueName, body: optionOrderInfoData.xml });
                // arr.push({ filename: orderInfoData.xmlUniqueName, body: orderInfoData.xml });
                if (orderInfoData.orderParts) {
                  arr.push({ filename: orderInfoData.orderParts.xmlUniqueName, body: orderInfoData.orderParts.xml });
                  orderInfoData.orderParts.orderPartsDataArr.forEach((partsData) => {
                    arr.push({ filename: partsData.xmlUniqueName, body: partsData.xml });
                    if (partsData.orderPage) {
                      arr.push({ filename: partsData.orderPage.xmlUniqueName, body: partsData.orderPage.xml });
                      partsData.orderPage.orderPageDataArr.forEach((pageData) => {
                        /* 完成画像は引き継がない */
                        if (orderMethod === '40' && pageData.viewModel.compositeFileName?.realPath) {
                          pageData.viewModel.compositeFileName = undefined;
                          pageData.build();
                        }
                        arr.push({ filename: pageData.xmlUniqueName, body: pageData.xml });
                        pageDataArr.push(pageData);
                      });
                    }
                  });
                }
              });
              // TODO 使用画像
              const files: {
                kind: '1' | '2' | '3' | '4' | '5',
                data: File,
                filename: string,
              }[] = [];
              for (let i = 0; i < pageDataArr.length; i++) {
                const pageData = pageDataArr[i];
                // サムネ: Comp_Virtual(2) ※完成画像は含めない
                const compositeFilePath = pageData.viewModel?.compositeFileName?.virtualPath;
                await new Promise<void>((resolve) => {
                  if (compositeFilePath) {
                    dispatch(apiActions.run(
                      new ApiImagesGetOne({
                        kijshopCd,
                        path: `${kijshopCd}/${pageData.shopOrderId}/${compositeFilePath}`,
                      }),
                      {
                        onSuccess: (res) => {
                          const sp = compositeFilePath.split('/');
                          const name = sp[sp.length - 1] ? `FV_${sp[sp.length - 1].substr(3)}` : compositeFilePath;
                          const file = new File([res], name, { type: 'application/octet-stream' });
                          if (sp[sp.length - 1] && pageData.viewModel?.compositeFileName?.virtualPath) {
                            let str = '';
                            sp.forEach((v, i) => {
                              if (str) {
                                str += '/';
                              }
                              if (i === sp.length - 1) {
                                str += name;
                              } else {
                                str += v;
                              }
                            });
                            pageData.viewModel.compositeFileName.virtualPath = str;
                            pageData.build();
                            const index = arr.findIndex((v) => v.filename === pageData.xmlUniqueName);
                            if (index !== -1) {
                              arr[index].body = pageData.xml;
                            }
                          }
                          files.push({
                            kind: '2',
                            data: file,
                            filename: name,
                          });
                          resolve();
                        },
                        onError: resolve,
                      },
                      { ignoreSystemError: true },
                    ));
                  } else {
                    resolve();
                  }
                });
                // png: FreeGraphics(5)
                const freeGraphicDataList = pageData.viewModel?.freeGraphic?.data;
                if (freeGraphicDataList) {
                  for (let j = 0; j < freeGraphicDataList.length; j++) {
                    const freeGraphicPath = freeGraphicDataList[j]?.virtual?.path;
                    if (freeGraphicPath) {
                      await new Promise<void>((resolve) => {
                        dispatch(apiActions.run(
                          new ApiImagesGetOne({
                            kijshopCd,
                            path: `${kijshopCd}/${pageData.shopOrderId}/${freeGraphicPath}`,
                          }),
                          {
                            onSuccess: (res) => {
                              const sp = freeGraphicPath.split('/');
                              const name = sp[sp.length - 1] || freeGraphicPath;
                              const file = new File([res], name, { type: 'application/octet-stream' });
                              files.push({
                                kind: '5',
                                data: file,
                                filename: name,
                              });
                              resolve();
                            },
                            onError: resolve,
                          },
                          { ignoreSystemError: true },
                        ));
                      });
                    }
                  }
                }
                // テキスト: Except_Real(4)
                // const textImageList = pageData.viewModel?.orderTextImage?.data;
                // if (textImageList) {
                //   for (let j = 0; j < textImageList.length; j++) {
                //     const textImagePath = textImageList[j]?.path;
                //     if (textImagePath) {
                //       await new Promise<void>((resolve) => {
                //         dispatch(apiActions.run(
                //           new ApiImagesGetOne({
                //             kijshopCd,
                //             path: `${kijshopCd}/${pageData.shopOrderId}/${textImagePath}`,
                //           }),
                //           {
                //             onSuccess: (res) => {
                //               const sp = textImagePath.split('/');
                //               const name = sp[sp.length - 1] || textImagePath;
                //               const file = new File([res], name, { type: 'application/octet-stream' });
                //               files.push({
                //                 kind: '4',
                //                 data: file,
                //                 filename: name,
                //               });
                //               resolve();
                //             },
                //             onError: resolve,
                //           },
                //           { ignoreSystemError: true },
                //         ));
                //       });
                //     }
                //   }
                // }
              }
              // png: FreeGraphics(5)
              dispatch(apiActions.run(
                new ApiFavoritePostRegister(
                  {
                    kijshopCd,
                    folderId,
                    name,
                    level: selectBrand.level,
                    xml: arr,
                    files,
                  },
                ),
                {
                  onSuccess: () => {
                    setRegistering(false);
                    dispatch(dialogActions.pop());
                    dispatch(dialogActions.pushMessage({
                      title: '確認',
                      message: ['登録が完了しました'],
                      buttons: [{
                        label: 'OK',
                        callback: () => {
                          dispatch(dialogActions.pop());
                        },
                      }],
                    }));
                  },
                  onError: () => {
                    setRegistering(false);
                    dispatch(dialogActions.pushMessage({
                      title: '確認',
                      message: ['お気に入り商品の登録に失敗しました'],
                      buttons: [{
                        label: 'OK',
                        callback: () => {
                          dispatch(dialogActions.pop());
                        },
                      }],
                    }));
                  },
                }
              ));
            }
          },
          onError: () => {
            setRegistering(false);
            dispatch(dialogActions.pushMessage({
              title: '確認',
              message: ['お気に入り商品の登録に失敗しました'],
              buttons: [{
                label: 'OK',
                callback: () => {
                  dispatch(dialogActions.pop());
                },
              }],
            }));
          },
        },
      ));
    }
  }, [name, selectBrand]);
  // -- キャンセルボタン押下 --
  const handlerClickCancel = useCallback(() => {
    dispatch(dialogActions.pop());
    dispatch(favoriteBrandActions.setSelectBrand(undefined));
  }, []);
  const ref = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (ref.current) {
      ref.current.focus();
    }
  }, []);
  return (
    <div className="dialog_contents save_favorite_brand">
      <div className="dialog_contents__body">
        <div className="save_favorite_brand__top">
          <span>登録する事で、次回から簡単に呼び出せます。</span>
          <span>お気に入り保存する名称を入力してください</span>
          <Input
            ref={ref}
            value={name}
            onChange={(e) => handlerChangeName(e.target.value)}
            maxLength={40}
          />
          <span className="emphasis_red">※ 40文字以内で入力してください</span>
        </div>
        <div className="save_favorite_brand__bottom">
          <span>保存先フォルダを選択してください</span>
          <FavoriteBrandConfig
            closeButtonLabel='キャンセル'
            closeButtonCallback={handlerClickCancel}
            okButtonCallback={handlerClickOk}
            isSaveDialog={true}
            kijshopCd={kijshopCd}
            disabled={!name}
          />
        </div>
      </div>
      {registering ? (<LoadingPopup label="商品情報を登録中です..." />) : (<></>)}
    </div>
  );
};
