import { XmlClass } from './xml-class';
import { ICustomer } from '../interface/i-customer';
import { XmlClassName } from '../model/xml-class-name';
import { TCustomerViewModel } from '../model/customer/xml-customer-model';

type TView = Partial<TCustomerViewModel>;

export class CustomerXml extends XmlClass<ICustomer, TView, {}> {

  name: XmlClassName = 'customer';

  viewModel: TView = {};
  metaModel: {} = {};

  protected init(): void {
    this.xmlUniqueName = this.xmlUniqueName || `PPM_${this.shopOrderId}_11_11_customer.xml`;
    this.xmlParentName = this.xmlParentName || `PPM_${this.shopOrderId}_11_11.xml`;
    this.rootTagModel = {
      ...this.rootTagModel,
      parentXPath: '/lnwOrder/order/customer',
      parentPath: this.xmlParentName,
    };
    this.docking({}, {});
  }

  protected docking(v: TView, m: {}): void {
    this.xmlModel = {
      lnwCustomer: {
        $: { ...this.rootTagModel },
        customer: [
          {
            $: {
              count: '1',
            },
            data: [
              {
                $: {
                  id: '1',
                },
                name: v.lastName ? [
                  {
                    $: {
                      // お客様名
                      lastName: v.lastName,
                    },
                  },
                ] : undefined,
              },
            ],
          },
        ],
      },
    };
  }

  protected split(x: ICustomer): void {
    this.viewModel = {
      lastName: x.lnwCustomer.customer?.[0]?.data?.[0]?.name?.[0]?.$.lastName,
    };
    this.rootTagModel = Object.assign(this.rootTagModel, x.lnwCustomer.$);
  }

}
