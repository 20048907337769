import { DetailedHTMLProps, ImgHTMLAttributes, useEffect, useRef, useState } from 'react';
import loading from './loading.gif';
import { cloneDeep } from 'lodash';

type Props = {
  observer: IntersectionObserver | null,
  src: string,
} & DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>;

export class IntersectionObserverFactory {
  static make(rootClassName: string): IntersectionObserver {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((v) => {
          if (v.isIntersecting) {
            // -- in view --
            v.target.classList.remove('out-view');
            v.target.classList.add('in-view');
            (v.target as any).src = v.target.getAttribute('data-src') || '';
          } else {
            // -- out view --
            v.target.classList.remove('in-view');
            v.target.classList.add('out-view');
            (v.target as any).src = loading;
          }
        });
      },
      {
        root: document.querySelector(`.${rootClassName}`),
        rootMargin: '50px',
      },
    );
    return observer;
  }
}

export const LazyLoadImage = (props: Props) => {
  // - ref -
  const imageEle = useRef<HTMLImageElement>(null);
  // - props -
  const {
    observer,
    src,
    ...otherProps
  } = props;
  const [_ob, setOb] = useState<IntersectionObserver | null>(null);
  const [init, setInit] = useState(true);
  // - effect -
  useEffect(() => {
    if (src && imageEle && imageEle.current) {
      imageEle.current.setAttribute('data-src', src);
    }
  }, [src]);
  // - life cycle -
  // -- did mount --
  useEffect(() => {
    if (observer && imageEle && imageEle.current) {
      observer.observe(imageEle.current);
    }
  }, []);
  useEffect(() => {
    if (init && observer && imageEle && imageEle.current) {
      setInit(false);
      setOb(cloneDeep(observer))
      observer.observe(imageEle.current);
    }
  }, [observer])
  return (
    <img
      {...otherProps}
      src={''}
      ref={imageEle}
    />
  );
};
