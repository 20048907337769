import { Table } from '../../ui/table/table';
import { Button } from '../../ui/button/button';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { dialogActions } from '../slice/dialog-slice';
import './send-store-config.scss';
import iconEdit from '../../../assets/img/icon/edit.svg';
import iconDelete from '../../../assets/img/icon/delete.svg';
import { apiActions } from '../../../slices/api-slice';
import { ResponseBase } from '../../../api/response-base';
import { ApiStoreDelete, ApiStoreGet } from '../../../api/front/store/api-store';
import { StoreGetResponse } from '../../../models/api/front/store';
import { RegisterDeliveryStore } from './register-delivery-store';
import { DeliveryAddressInfoInput } from './delivery-address-info-input';
import { RepairDeliveryStore } from './repair-delivery-store';
import { useAppSelector } from '../../../app/hooks';
import { deliveryActions } from '../../../slices/orders/delivery-slice';
import lodash from 'lodash';

interface SendStoreConfigProps {
  kijshopCd: string;
}
export const SendStoreConfig = (props: SendStoreConfigProps) => {
  // - Hooks -
  const dispatch = useDispatch();
  const { kijshopCd } = props;
  // - State -
  const shopData = useAppSelector((state) => {
    return state.delivery.sendStore;
  }, lodash.isEqual);
  // -- apiからの発送店舗一覧データ --
  const [listData, setListData] = useState<StoreGetResponse[]>([]);
  // -- 選択したテーブルの行 --
  const [selectTable, setSelectTable] = useState(-1);
  // -- 一覧取得中フラグ --
  const [isConnecting, setIsConnecting] = useState(true);
  // -- 選択した発送店舗データ --
  const [selectData, setSelectData] = useState<StoreGetResponse>();
  // - Callback -
  // -- 編集ボタン --
  const handlerClickEdit = useCallback((v) => {
    dispatch(dialogActions.push({
      title: '発送店舗編集',
      element: <RepairDeliveryStore kijshopCd={kijshopCd} storeData={v} />,
    }));
  }, []);
  // -- 削除ボタン --
  const handlerClickDelete = useCallback((v: StoreGetResponse) => {
    dispatch(dialogActions.pushMessage({
      title: '確認',
      message: [
        '選択した発送店舗をリストから削除します。',
        'よろしいですか？',
      ],
      buttons: [
        {
          label: 'いいえ',
          callback: () => {
            dispatch(dialogActions.pop());
          },
        },
        {
          label: 'はい',
          callback: () => {
            setIsConnecting(true);
            dispatch(apiActions.run(
              new ApiStoreDelete(kijshopCd, v.shippingShopId, {}),
              {
                onSuccess: () => {
                  setIsConnecting(false);
                  if (shopData) {
                    const ary = shopData.filter((data) => data.shippingShopId !== v.shippingShopId);
                    dispatch(deliveryActions.setSendStoreState(ary));
                  }
                  setSelectTable(-1);
                  dispatch(dialogActions.pop());
                },
              },
            ));
          },
        },
      ],
    }));
  }, [shopData, listData]);
  // -- 新規登録ボタン押下 --
  const handlerClickRegister = useCallback(() => {
    dispatch(dialogActions.push({
      title: '発送店舗登録',
      element: <RegisterDeliveryStore kijshopCd={kijshopCd} />,
    }));
  }, []);
  // -- OKボタン押下 --
  const handlerClickOk = useCallback(() => {
    dispatch(dialogActions.pop());
    if (selectData) {
      dispatch(deliveryActions.setAddressState({
        sendStore: selectData,
      }));
    }
  }, [selectData]);
  // -- 閉じるボタン押下 --
  const handlerClickClose = useCallback(() => {
    dispatch(dialogActions.pop());
  }, []);
  // -- テーブル押下 --
  const handlerClickTableRow = useCallback((i) => {
    if (i === selectTable) {
      setSelectTable(-1);
      setSelectData(undefined);
    } else {
      setSelectTable(i);
      setSelectData(listData[i]);
    }
  }, [selectTable, listData]);
  // - Effect -
  // -- 発送店舗一覧取得 --
  useEffect(() => {
    dispatch(apiActions.run(
      new ApiStoreGet({ kijshopCd }),
      {
        onSuccess: (res) => {
          setIsConnecting(false);
          if (res.body.data) {
            const data = res.body.data;
            if (Array.isArray(data)) {
              dispatch(deliveryActions.setSendStoreState(res.body.data as StoreGetResponse[]));
              setListData(res.body.data as StoreGetResponse[]);
            } else {
              const ary: StoreGetResponse[] =
                Object.keys(data).map((key) => ({
                  shippingShopId: data[key].shippingShopId,
                  shopName: data[key].shopName,
                }));
              dispatch(deliveryActions.setSendStoreState(ary));
              setListData(ary);
            }
          }
        },
      },
    ));
  }, []);
  useEffect(() => {
    if (shopData !== listData) {
      setListData(shopData);
    }
  }, [shopData, listData]);
  // - テーブルbodyリスト -
  const tableBody = [...listData].map((v) => ([
    <div className="send_store_conf_item">
      <div className="send_store_conf_item__ttl"> {v?.shopName}</div>
      <div className="send_store_conf_item__btns">
        <Button
          label="編集"
          icon={<img
            src={iconEdit}
            alt=""
          />}
          onClick={() => handlerClickEdit(v)}
          color="light"
          size="sm"
        />
        <Button
          label="削除"
          icon={<img
            src={iconDelete}
            alt=""
          />}
          onClick={() => handlerClickDelete(v)}
          color="light"
          size="sm"
        />
      </div>
    </div>,
  ]));
  return (
    <div className="dialog_contents send_store_config">
      <div className="dialog_contents__body">
        {tableBody.length || isConnecting ? (<></>) : (
          <div className="alert_message">
            リストがありません。新規登録ボタンより、発送店舗を登録してください。
          </div>
        )}
        <Table
          head={['発送店舗リスト']}
          body={tableBody}
          minimumNumOfRows={14}
          className=" send_store_config__table"
          handlerSelect={handlerClickTableRow}
          selected={selectTable}
        />
      </div>
      <div className=" dialog_contents__footer send_store_config__footer">
        <div>
          <Button
            label=" 新規登録"
            onClick={handlerClickRegister}
          />
        </div>
        <div className="send_store_config__footer__right">
          <Button
            label=" 閉じる"
            onClick={handlerClickClose}
          />
          <Button
            label=" OK"
            onClick={handlerClickOk}
            disabled={selectTable === -1}
          />
        </div>
      </div>
    </div>
  );
};
