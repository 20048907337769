import {
  OrderFormFileGetResponse,
  OrderFormGetResponse,
  OrderFormPostRequest,
} from '../../../models/api/front/order-form';
import { ApiBaseFront } from "../api-base-front";
import { ApiBaseBack } from '../../back/api-base-back';

// 発注帳票一覧取得
export class ApiOrderFormGet extends ApiBaseFront<OrderFormGetResponse[]> {
  constructor(param: { kijshopCd: string }) {
    super(
      'GET',
      `/api/v1/orderform/${param.kijshopCd}`,
      'JSON',
      {},
    );
  }
}


// 発送帳票pdf取得
export class ApiOrderFormFileGet extends ApiBaseFront<OrderFormFileGetResponse[]> {
  constructor(param: { kijshopCd: string, orderfromId: string }) {
    super(
      'GET',
      `/api/v1/orderform/${param.kijshopCd}/${param.orderfromId}`,
      'BLOB',
      {},
    );
  }
}

// 発注帳票新規登録
export class ApiOrderFormPost extends ApiBaseFront {
  constructor(kijshopCode: string,param: OrderFormPostRequest) {
    super(
      'POST',
      `/api/v1/orderform/${kijshopCode}`,
      'JSON',
      param,
    );
  }
}

// 発注帳票変更
export class ApiOrderFormEdit extends ApiBaseFront {
  constructor(kijshopCode: string, orderFormId: string,param: OrderFormPostRequest) {
    super(
      'POST',
      `/api/v1/orderform/${kijshopCode}/${orderFormId}`,
      'JSON',
      param,
    );
  }
}

// 発注帳票削除
export class ApiOrderFormDelete extends ApiBaseFront {
  constructor(orderformId: string, kijshopCd: string,param: {}) {
    super(
      'DELETE',
      `/api/v1/orderform/${kijshopCd}/${orderformId}`,
      'JSON',
      param,
    );
  }
}

type OrderFormCopyRequest = {
  kijshopCd: string,
  shopOrderId: string,
  orderformId: string,
};
// ラボ発注時の帳票コピー
export class ApiOrderFormCopy extends ApiBaseFront {
  constructor(_param: OrderFormCopyRequest) {
    const { kijshopCd, ...param } = _param;
    super(
      'POST',
      `/api/v1/orderform/${kijshopCd}/copy`,
      'JSON',
      param,
    );
  }
}
