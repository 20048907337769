import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '../app/store';
import { apiActions } from './api-slice';
import {
  ApiGetMetaShop,
  ApiPostMetaShop,
  MetaShopData,
  MetaShopDataResponse,
} from '../api/front/system-config/api-meta-shop';
import { ResponseBase } from '../api/response-base';

type SystemConfigState = {
  shopCode: string,
  metaData: MetaShopData
};

const initialState: SystemConfigState = {
  shopCode: '',
  metaData: {
    kijshopCd: '',
    version: '',
    isNeedAgreement: true,
    surrogateOrder: null,
  },
};
// MEMO 店舗設定はシステム設定以外にも使われているので、common.slice.tsで管理してる
// 代理発注可能店舗一覧
const surrogateShopList = ['0000543', '0000544', '0005371', '0005746', '0006804', '0057288', '0057394', '0299999', '9900015', '9900016'];

const actions = {
  get: (kijshopCd: string): AppThunk => async (dispatch, getState) => {
    dispatch(systemConfigActions.setShopCode(kijshopCd));
    const version = getState().common.data.version;
    dispatch(apiActions.run(
      new ApiGetMetaShop(kijshopCd),
      {
        onSuccess: (res: ResponseBase<MetaShopDataResponse>) => {
          if (res.body.data) {
            if (Number(res.error.errorCode) === 404) {
              const obj = {
                kijshopCd: kijshopCd,
                version: version,
                isNeedAgreement: true,
                surrogateOrder: (surrogateShopList.includes(kijshopCd)) ? false : null,
              };
              dispatch(systemConfigActions.post(obj, () => dispatch(systemConfigActions.get(kijshopCd))));
            } else {
              const data: MetaShopData = {
                kijshopCd: (res.body.data.kijshopCd) ? res.body.data.kijshopCd : kijshopCd,
                version: (res.body.data.version) ? res.body.data.version : version,
                isNeedAgreement: res.body.data.isNeedAgreement ? JSON.parse(res.body.data.isNeedAgreement.toString()) : true,
                surrogateOrder: (res.body.data.surrogateOrder && res.body.data.surrogateOrder !== 'null') ? JSON.parse(res.body.data.surrogateOrder.toString()) : null,
              };
              const state = getState().systemConfig.metaData;
              if (data !== state) {
                dispatch(systemConfigActions.setMetaData(data));
              }
              if (surrogateShopList.includes(kijshopCd) && data.surrogateOrder === null) {
                const obj = {
                  kijshopCd: kijshopCd,
                  version: version,
                  isNeedAgreement: data.isNeedAgreement,
                  surrogateOrder: false,
                };
                dispatch(systemConfigActions.post(obj, () => dispatch(systemConfigActions.get(kijshopCd))));
              }
            }
          }
        },
      },
      { ignoreSystemError: true },
    ));
  },

  post: (data: MetaShopData, callback: () => void): AppThunk => async (dispatch, getState) => {
    dispatch(systemConfigActions.setMetaData(data));
    dispatch(apiActions.run(
      new ApiPostMetaShop(getState().systemConfig.shopCode, data),
      {
        onSuccess: () => callback(),
      },
      { ignoreSystemError: true },
    ));
  },
};

export const systemConfigSlice = createSlice({
  name: 'system-config',
  initialState,
  reducers: {
    setShopCode: (state, action) => {
      state.shopCode = action.payload;
    },
    setMetaData: (state, action: PayloadAction<MetaShopData>) => {
      state.metaData = action.payload;
    },
  },
});

export const systemConfigActions = Object.assign(systemConfigSlice.actions, actions);
export const systemConfigReducer = systemConfigSlice.reducer;
