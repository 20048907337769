import EventEmitter from 'eventemitter3';
import { ILayoutEditorManagerBase } from '../layout-editor.manager';
import { Env } from '../../../models/env';
import { UiManager } from '../ui/ui.manager';
import { EditableImageManager } from '../editable-image/editable-image.manager';
import { ApiProductImageGet } from '../../../api/front/product-image/api-product-image';
import { ApiImagesGet, ApiImagesGetOne, ImagesGetResponse } from '../../../api/front/images/api-images';
import { ResponseBase } from '../../../api/response-base';
import { OrderSelectXml } from '../../../xml/class/order/order-select-xml';
import { Orientation } from 'get-orientation/browser';

type EventType = {};

type RestoreData = {
  path: string,
  name: string,
  kijshopCd: string,
  shopOrderId: string,
  orderId: string,
  selectId: string,
  orientation?: Orientation,
  cfImageId?: string | null,
};

export class RestoreEditableImageManager extends EventEmitter<EventType> implements ILayoutEditorManagerBase {

  private static _ins: RestoreEditableImageManager;

  private constructor() {
    super();
  }

  static get ins() {
    if (RestoreEditableImageManager._ins) {
      return RestoreEditableImageManager._ins;
    }
    RestoreEditableImageManager._ins = new RestoreEditableImageManager();
    return RestoreEditableImageManager._ins;
  }

  initialize(): void {
  }

  destroy(): void {
  }

  di(): void {
  }

  async restoreAll(kijshopCd: string, shopOrderId: string, orderId: string, orderSelect?: OrderSelectXml) {
    // const restoreDataList = await new ApiProductImageGet({
    //   kijshopCd,
    //   shopOrderId,
    //   orderId,
    // })
    const restoreDataList = await new ApiImagesGet({
      kijshopCd,
      shopOrderId,
      kind: '6',
    })
      .do()
      .then((v) => {
        const res: ImagesGetResponse[] = (v as ResponseBase<ImagesGetResponse[]>).body.data || [];
        // res.forEach((v) => {
        //   new ApiImagesGetOne({
        //     kijshopCd,
        //     path: `${v.path}/${v.filename}`,
        //   })
        //     .do()
        //     .then((res) => console.log(res));
        // });
        return res.map((v) => ({
          // path: `${v.path}/${v.filename}`,
          path: `${v.pathThumb}/${v.filename}`, // 読みこむ画像をサムネ画像に変更
          name: v.orgFilename,
          kijshopCd,
          shopOrderId,
          orderId,
          selectId: orderSelect?.metaModel.imageData?.find((image) => image.selectFileName?.real.path === v.filename)?.selectID || '',
          cjImageId: v.cfImageId,
        }));
        // return (v as any).body.data.orderSelect?.filter((v: any) => v.selectFileName.path)
        //   .map((v: any) => ({
        //     path: v.selectFileName.path,
        //     name: v.selectFileName.path.split('/')[v.selectFileName.path.split('/').length - 1],
        //     kijshopCd,
        //     shopOrderId,
        //     orderId,
        //   }));
      });
    for (const restoreData of (restoreDataList || [])) {
      await this.restore(restoreData);
    }
  }

  async restore(restoreData: RestoreData, isUseCheck?: boolean) {
    const { path, name, kijshopCd, shopOrderId, orderId, selectId, orientation, cfImageId } = restoreData;
    // - すでに restore 済みかのチェック -
    const foundIndex = EditableImageManager.ins.list.findIndex((v) => v.path === path);
    if (foundIndex !== -1) {
      return Promise.resolve();
    }
    // const url = `${Env.api.image.protocol}://${Env.api.image.host}/${path}`;
    // await fetch(url, { mode: 'cors' })
    // .then((res) => res.blob())
    await new ApiImagesGetOne({
      kijshopCd,
      path,
    })
      .do()
      .then((blob: Blob) => {
        if (blob.type === 'application/json') {
          return;
        }
        const file = new File(
          [blob],
          name,
          {
            lastModified: new Date().getTime(),
            type: blob.type,
          },
        );
        UiManager.ins.emit(
          'r->l:add-image',
          {
            files: [file],
            kijshopCd,
            shopOrderId,
            orderId,
            restore: true,
            path: path,
            isUseCheck: isUseCheck,
            selectId,
            orientation,
            cfImageId,
          },
        );
      })
      .catch(() => {
        // ダウンロード失敗
      });
  }

}

RestoreEditableImageManager.ins.initialize();
