import {
  WizardButton,
  WizardButtonList,
  WizardContent,
  WizardInput,
  WizardInputList,
} from '../../../ui/wizard/wizard-content';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { RoutingPath } from '../../../../routes/routing-path';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { apiActions } from '../../../../slices/api-slice';
import { ApiShopPasswordReset } from '../../../../api/front/wizard/api-shop-password-reset';
import { dialogActions } from '../../../dialog/slice/dialog-slice';
import { webPagePath } from '../../../../models/web-page-path';
import { Button } from '../../../ui/button/button';
import iconHelp from '../../../../assets/img/icon/help.svg';

type Props = {
  setStep: (step: number) => void;
}
type TooltipType = 'shopCd' | 'none';

export const Step1AdminPasswordResetWizard = (props: Props) => {
  const { setStep } = props;
  // - Hooks -
  const dispatch = useDispatch();
  // - State -
  const [shopCd, setShopCd] = useState('');
  const [isShopCdError, setIsShopCdError] = useState(false);
  const [showTooltip, setShowTooltip] = useState<TooltipType>('none');
  // - Handlers -
  // -- バリデーション --
  const handlerValidation = useCallback(() => {
    const isShopCdInvalid = shopCd === '' || shopCd.length !== 7;
    setIsShopCdError(isShopCdInvalid);
    return !isShopCdInvalid;
  },[shopCd]);
  const handlerChangeShopCd = useCallback((value: string) => {
    setShopCd(value);
  }, [shopCd]);
  const handlerToggleShowTooltip = useCallback((type: TooltipType) => {
    setShowTooltip(type);
  }, []);
  const handlerClickCancel = useCallback(() => {
    dispatch(push(RoutingPath.login));
  }, []);
  const handlerClickSendMail = useCallback(() => {
    if (!handlerValidation()) {
      dispatch(dialogActions.push({
        title: '確認',
        element: <ShopCdError />
      }))
      return;
    }
    dispatch(apiActions.run(
      new ApiShopPasswordReset(
        {
          kijshopCd: shopCd,
        },
      ),
      {
        onSuccess: (res) => {
          if (res.error.errorCode === 404) {
            dispatch(dialogActions.push({
              title: '確認',
              element: <ShopCdError />
            }))
            return
          }
          setStep(2);
        },
      },
    ));
  }, [shopCd]);
  return (
    <WizardContent>
      <WizardInputList>
        <WizardInput
          label="店舗コード"
          value={shopCd}
          disabled={false}
          isPassword={false}
          maxLength={7}
          error={isShopCdError}
          tooltip={isShopCdError && showTooltip === 'shopCd' ?
            { messages: ['半角数値7桁'] } : undefined}
          onChange={(e) => handlerChangeShopCd(e.target.value)}
          onMouseOver={() => handlerToggleShowTooltip('shopCd')}
          onMouseLeave={() => handlerToggleShowTooltip('none')}
        />
      </WizardInputList>
      <WizardButtonList>
        <WizardButton
          label="キャンセル"
          onClick={handlerClickCancel}
        />
        <WizardButton
          label="次へ"
          onClick={handlerClickSendMail}
        />
      </WizardButtonList>
    </WizardContent>
  );
};

const ShopCdError = () => {
  const dispatch = useDispatch();
  const handlerCLickButton = useCallback(() => {
    dispatch(dialogActions.pop());
  }, []);
  const ref = useRef<HTMLAnchorElement>(null);
  useEffect(() => {
    if (ref.current) {
      ref.current.target = '_blank';
      ref.current.href = webPagePath.forgotPassword;
      ref.current.rel = 'noopener noreferrer';
    }
  }, []);
  return (
    <div>
      <div
        style={{
          // textAlign: 'center',
        }}
      >
        この店鋪コードはパスワード再発行が利用できません<br /><br />
        考えられる理由<br />
        ・スタッフ管理機能が有効化されていない<br />
        ・店鋪コードの入力に誤りがある<br /><br />
        <a ref={ref}>
          {<img
            src={iconHelp}
            alt=""
            style={{
              width: '1rem',
              height: '1rem',
              verticalAlign: 'text-bottom',
              marginRight: '0.2rem',
            }}
          />}
          店鋪コードに関するお問い合わせはこちら
        </a>
      </div>
      <div
        style={{
          marginTop: '2rem',
          textAlign: 'center',
        }}
      >
        <Button
          label="OK"
          onClick={handlerCLickButton}
          style={{
            display: 'inline-block',
            textAlign: 'left',
          }}
        />
      </div>
    </div>
  );
};
