import { ILayoutEditorManagerBase } from '../layout-editor.manager';

export type AdditionalType = {
  x: number;
  y: number;
  w: number;
  h: number;
};

export class AdditionalManager implements ILayoutEditorManagerBase {
  // - canvas -
  private readonly outCanvas: HTMLCanvasElement;
  private readonly ctx: CanvasRenderingContext2D | null = null;

  constructor() {
    this.outCanvas = document.createElement('canvas');
    if (this.outCanvas) this.ctx = this.outCanvas.getContext('2d');
  }

  initialize() {
  }

  destroy() {
  }

  di() {
  }

  drawBase(_base: AdditionalType, color: string) {
    if (!this.ctx) return;
    const base = {
      x: _base.x < 0 ? 0 : _base.x,
      y: _base.y < 0 ? 0 : _base.y,
      w: _base.x < 0 ? _base.w + Math.abs(_base.x) : _base.w,
      h: _base.y < 0 ? _base.h + Math.abs(_base.y) : _base.h,
    }
    this.outCanvas.width = base.w;
    this.outCanvas.height = base.h;
    this.ctx.fillStyle = color;
    this.ctx.fillRect(base.x, base.y, _base.w, _base.h);
  }

  clipWindow(base: AdditionalType, window: AdditionalType, rotate: number, type: 'rect' | 'circle') {
    const basePos = {x: Math.abs(base.x), y: Math.abs(base.y)}
    if (!this.ctx) return;
    this.ctx.save();
    this.ctx.globalCompositeOperation = 'destination-out';
    this.ctx.translate(window.x + window.w / 2 + basePos.x, window.y + window.h / 2 + basePos.y);
    this.ctx.rotate((rotate * Math.PI) / 180);
    this.ctx.translate(-(window.x + window.w / 2 + basePos.x), -(window.y + window.h / 2 + basePos.y));
    this.ctx.beginPath();
    if (type === 'rect') {
      this.ctx.rect(window.x + basePos.x, window.y + basePos.y, window.w, window.h);
    } else {
      this.ctx.ellipse(
        window.x + window.w / 2 + basePos.x,
        window.y + window.h / 2 + basePos.y,
        window.w / 2,
        window.h / 2,
        0,
        0,
        360,
      );
    }
    this.ctx.fill();
    this.ctx.globalCompositeOperation = 'source-over';
    this.ctx.restore();
  }

  makeAdditional() {
    // -- format: 画像形式 quality: 画質 0 < 1 --
    const format = 'jpg';
    const quality = 0.1;
    if (!this.ctx) throw new Error('canvas context is not found !!');
    this.ctx.drawImage(
      this.outCanvas,
      0, 0, this.outCanvas.width, this.outCanvas.height,
    );
    // -- 画像URLを返す --
    return this.outCanvas.toDataURL(format, quality);
  }
}
