import React, { DetailedHTMLProps, InputHTMLAttributes, useState, useCallback, useEffect, useRef } from 'react';
import { Select } from '../select/select';
import { Input } from './input';
import { DateFormatter } from '../../../utilities/date-formatter';
import { Tooltip } from '../tooltip/tooltip';
import { isNaN } from 'lodash';
import { useDispatch } from 'react-redux';
import { dialogActions } from '../../dialog/slice/dialog-slice';
import { ordersActions } from '../../../slices/orders-slice';
import { useAppSelector } from '../../../app/hooks';
import * as lodash from 'lodash';
import { Button } from '../../ui/button/button';
import iconHelp from '../../../assets/img/icon/help_icon.svg';
import { HowToOrder, ImgType } from '../../dialog/unique/how-to-order';

type CalendarProps = {
  // - 日付取得 -
  getFormatDate: (date: string) => void,
  callbackClickHelp: (type: ImgType) => void,
} & DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

// - フォーマットされた日付リスト -
type FormatDate = {
  value: string,
  label: string,
}

// - 月（英語表記）リスト -
const enMonth = ['', 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
// - 日にち（英語表記）リスト -
const enDate = ['th', 'st', 'nd', 'rd'];
// - 文字タイプリスト -
const initialTypeList = [{ value: '0', label: '先頭のみ大文字' }, { value: '1', label: '全て大文字' }];

export const getFormat: (value: string) => {
  initType: '0' | '1',
  formatType: string,
  date: Date | null,
} = (value: string) => {
  if (value || value === '') {
    const arr1 = value.split(' ');
    const arr2 = value.split('/');
    const arr3 = value.split('.');
    if (arr1.length === 3) {
      const case01 = enMonth.findIndex((v) => v === arr1[0]);                                       // 最初に月　小文字
      const case02 = enMonth.findIndex((v) => v.toUpperCase() === arr1[0]);                         // 最初に月　大文字
      const case03 = !Boolean(Number(arr1[1].slice(0, -1)));                                              // 2番目が数字でない(th とかがつく)
      const case04 = enMonth.findIndex((v) => v.slice(0, 3) === arr1[0].slice(0, 3));               // 最初に月(略)　小文字
      const case05 = enMonth.findIndex((v) => v.slice(0, 3).toUpperCase() === arr1[0].slice(0, 3)); // 最初に月(略)　大文字
      const case06 = !Boolean(Number(arr1[0]));                                                           // 最初が数字でない(th とかがつく)
      const case07 = enMonth.findIndex((v) => v === arr1[1].slice(0, -1));                          // 2番目に月　小文字
      const case08 = enMonth.findIndex((v) => v.toUpperCase() === arr1[1].slice(0, -1));            // 2番目に月　大文字
      const case09 = enMonth.findIndex((v) => v.slice(0, 3) === arr1[1].slice(0, 3));               // 2番目に月(略)　小文字
      const case10 = enMonth.findIndex((v) => v.slice(0, 3).toUpperCase() === arr1[1].slice(0, 3)); // 2番目に月(略)　大文字
      if ((case01 > 0 || case02 > 0) && case03) {
        return { formatType: '0', initType: (case01 > 0) ? '0' : '1', date: getDate(value, '0') };
      }
      if ((case01 > 0 || case02 > 0) && !case03) {
        return { formatType: '1', initType: (case01 > 0) ? '0' : '1', date: getDate(value, '1') };
      }
      if (case04 > 0) {
        return { formatType: '2', initType: '0', date: getDate(value, '2') };
      }
      if (case05 > 0) {
        return { formatType: '2', initType: '1', date: getDate(value, '2') };
      }
      if (case06 && (case07 > 0 || case08 > 0)) {
        return { formatType: '3', initType: (case07 > 0) ? '0' : '1', date: getDate(value, '3') };
      }
      if (!case06 && (case07 > 0 || case08 > 0)) {
        return { formatType: '4', initType: (case07 > 0) ? '0' : '1', date: getDate(value, '4') };
      }
      if (!case06 && (case09 > 0 || case10 > 0)) {
        return { formatType: '5', initType: (case09 > 0) ? '0' : '1', date: getDate(value, '5') };
      }
    }
    if (arr2.length === 3) {
      return {
        formatType: '6', initType: '0', date: getDate(value, '6'),
      };
    }
    if (arr3.length === 3 && Boolean(Number(arr3[1])) && Boolean(Number(arr3[2]))) {
      return { formatType: '7', initType: '0', date: getDate(value, '7') };
    }
    if (value.match(/^(?=.*年)(?=.*月)(?=.*日).*$/)) {
      if (Number(value.slice(0, 2))) {
        return { formatType: '8', initType: '0', date: getDate(value, '8') };
      } else {
        return { formatType: '9', initType: '0', date: getDate(value, '9') };
      }
    }
    return { formatType: '10', initType: '0', date: getDate(value, '10') };
  } else {
    return { formatType: '0', initType: '0', date: null };
  }
};

export const convertMonth = (v?: string) => {
  switch (v?.substr(0, 3).toUpperCase()) {
    case 'JANUARY'.substr(0, 3):
      return 0;
    case 'FEBRUARY'.substr(0, 3):
      return 1;
    case 'MARCH'.substr(0, 3):
      return 2;
    case 'APRIL'.substr(0, 3):
      return 3;
    case 'MAY'.substr(0, 3):
      return 4;
    case 'JUNE'.substr(0, 3):
      return 5;
    case 'JULY'.substr(0, 3):
      return 6;
    case 'AUGUST'.substr(0, 3):
      return 7;
    case 'SEPTEMBER'.substr(0, 3):
      return 8;
    case 'OCTOBER'.substr(0, 3):
      return 9;
    case 'NOVEMBER'.substr(0, 3):
      return 10;
    case 'DECEMBER'.substr(0, 3):
      return 11;
    default:
      return 9999;
  }
};
export const convertDate = (value: string) => {
  let str = value;
  enDate.forEach((v) => {
    str = str?.toUpperCase().replace(v.toUpperCase(), '');
  });
  return str?.replace(',', '') || '';
};

export const getDate = (value: string, format: string) => {
  const arr = value.split(' ');
  const arr2 = value.split('/');
  let year = 0;
  let month = 0;
  let date = 0;
  switch (format) {
    case '0':
      year = Number(arr[2]);
      month = convertMonth(arr[0]);
      date = Number(convertDate(arr[1]));
      return new Date(year, month, date);
    case '1':
      year = Number(arr[2]);
      month = convertMonth(arr[0]);
      date = Number(convertDate(arr[1]));
      return new Date(year, month, date);
    case '2':
      year = Number(arr[2]);
      month = convertMonth(arr[0]);
      date = Number(convertDate(arr[1]));
      return new Date(year, month, date);
    case '3':
      year = Number(arr[2]);
      month = convertMonth(arr[1]);
      date = Number(convertDate(arr[0]));
      return new Date(year, month, date);
    case '4':
      year = Number(arr[2]);
      month = convertMonth(arr[1]);
      date = Number(convertDate(arr[0]));
      return new Date(year, month, date);
    case '5':
      year = Number(arr[2]);
      month = convertMonth(arr[1]);
      date = Number(convertDate(arr[0]));
      return new Date(year, month, date);
    case '6':
      return new Date(value);
    case '7':
      return new Date(value);
    case '8':
      const arr3 = value.split('年');
      year = Number(arr3[0]);
      month = Number(arr3[1].split('月')[0]) - 1;
      date = Number(arr3[1].split('月')[1].split('日')[0]);
      year = Number(value.slice(0, 4));
      return new Date(year, month, date);
    case '9':
      const arr4 = value.split('年');
      const _year = Number(arr4[0].replace('元', '1').split('').filter((v) => !isNaN(Number(v))).join(''));
      if (value.match(/令和/)) {
        year = ((_year || 0) + 2018);
      } else if (value.match(/平成/)) {
        year = ((_year || 0) + 1988);
      } else if (value.match(/昭和/)) {
        year = ((_year || 0) + 1925);
      } else if (value.match(/大正/)) {
        year = ((_year || 0) + 1911);
      } else if (value.match(/明治/)) {
        year = ((_year || 0) + 1867);
      }
      month = (() => {
        const result = Number(arr4?.[1]?.split('月')[0]) - 1
        return result > -1 ? result : NaN;
      })();
      date = (() => {
        const result = Number(arr4?.[1]?.split('月')[1].split('日')[0]) ?? NaN;
        return result > 0 ? result : NaN;
      })();
      return new Date(year, month, date);
    default:
      return null;
  }
};

export const Calendar = (props: CalendarProps) => {
  const { getFormatDate, callbackClickHelp, ...defaultProps } = props;
  const { dateValue, _formatType, _formatCase } = useAppSelector((state) => ({
    dateValue: state.order.descriptionInfo.date?.value,
    _formatType: state.order.descriptionInfo.formatType,
    _formatCase: state.order.descriptionInfo.formatCase,
  }), lodash.isEqual);
  const dispatch = useDispatch();
  // - State -
  // - 日付入力時のevent -
  const [eventInput, setEventInput] = useState<any>();
  // -- フォーマットされた日付リスト -
  const [formatDateList, setFormatDateList] = useState<FormatDate[]>([]);
  // - フォーマットタイプ -
  const [formatType, setFormatType] = useState(_formatType || '0');
  // - 文字タイプ -
  const [initialType, setInitialType] = useState(_formatCase || '0');
  // -- 自由入力 --
  const [freeInputDate, setFreeInputDate] = useState<string>('');
  // フォーマットアラート
  const [alert, setAlert] = useState(false);
  // - Callback -
  // -- 日付入力 --
  const handleInputChange = useCallback((e, initType, fType) => {
    if (e?.target?.value) {
      setAlert(false);
      setEventInput(e);
      const fullDate = new Date(e.target.value);
      const year = fullDate.getFullYear();
      const month = fullDate.getMonth() + 1;
      const date = fullDate.getDate();
      const formatList: any[] = [];
      let enMonthList: string[];
      let enDateList: string[];
      if (initType === '0') {
        enMonthList = enMonth;
        enDateList = enDate;
      } else {
        enMonthList = enMonth.map((v) => v.toUpperCase());
        enDateList = enDate.map((v) => v.toUpperCase());
      }
      if (!isNaN(year) && !isNaN(month) && !isNaN(date)) {
        const en1 = {
          value: '0',
          label: enMonthList[month] + ' ' +
            (date === 1 || date === 21 || date === 31 ? date + enDateList[1] :
              date === 2 || date === 22 ? date + enDateList[2] :
                date === 3 || date === 23 ? date + enDateList[3] : date + enDateList[0]) + ', ' +
            year,
        };
        const en2 = {
          value: '1',
          label: enMonthList[month] + ' ' + date + ', ' + year,
        };
        const en3 = {
          value: '2',
          label: enMonthList[month].substring(0, 3) + '. ' + date + ', ' + year,
        };
        const en4 = {
          value: '3',
          label: (date === 1 || date === 21 || date === 31 ? date + enDateList[1] :
              date === 2 || date === 22 ? date + enDateList[2] :
                date === 3 || date === 23 ? date + enDateList[3] : date + enDateList[0]) + ' ' +
            enMonthList[month] + ', ' +
            year,
        };
        const en5 = {
          value: '4',
          label: date + ' ' + enMonthList[month] + ', ' + year,
        };
        const en6 = {
          value: '5',
          label: date + ' ' + enMonthList[month].substring(0, 3) + '. ' + year,
        };
        const slash = {
          value: '6',
          label: year + '/' +
            (String(month).length === 1 ? ('0' + month) : month) + '/' +
            (String(date).length === 1 ? ('0' + date) : date),
        };
        const dot = {
          value: '7',
          label: year + '.' + month + '.' + date,
        };
        const ja1 = {
          value: '8',
          label: year + '年' + month + '月' + date + '日',
        };
        const ja2 = {
          value: '9',
          label: new Intl.DateTimeFormat('ja-JP-u-ca-japanese', { era: 'long', year: 'numeric' })
            .format(fullDate) + month + '月' + date + '日',
        };
        const free = {
          value: '10',
          label: '自由入力',
        };
        formatList.push(en1, en2, en3, en4, en5, en6, slash, dot, ja1, ja2, free);
        if (e.currentTarget) {
          switch (fType) {
            case '0':
              setFreeInputDate(en1.label);
              break;
            case '1':
              setFreeInputDate(en2.label);
              break;
            case '2':
              setFreeInputDate(en3.label);
              break;
            case '3':
              setFreeInputDate(en4.label);
              break;
            case '4':
              setFreeInputDate(en5.label);
              break;
            case '5':
              setFreeInputDate(en6.label);
              break;
            case '6':
              setFreeInputDate(slash.label);
              break;
            case '7':
              setFreeInputDate(dot.label);
              break;
            case '8':
              setFreeInputDate(ja1.label);
              break;
            case '9':
              setFreeInputDate(ja2.label);
              break;
            case '10':
              setFreeInputDate(free.label);
              break;
          }
        }
        setFormatDateList(formatList);
      }
    }
  }, [initialType, freeInputDate, formatDateList]);
  const handlerFocusOutDateInput = useCallback(() => {
    const inputDate = getDate(freeInputDate || '', formatType);
    if (!inputDate || !freeInputDate) {
      setAlert(false);
      return;
    }
    const year = inputDate.getFullYear();
    const month = inputDate.getMonth() + 1;
    const date = inputDate.getDate();
    if (isNaN(year) || isNaN(month) || isNaN(date)) {
      dispatch(dialogActions.pushMessage({
        title: '確認',
        message: [
          '入力形式が正しくありません。',
          '商品情報ー商品詳細ー商品記載情報ー日付',
        ],
        buttons: [
          {
            label: 'OK',
            callback: () => {
              dispatch(dialogActions.pop());
            },
          },
        ],
      }));
      setAlert(true);
      return;
    }
    const formatList: any[] = [];
    let enMonthList = enMonth;
    let enDateList = enDate;
    const en1 = {
      value: '0',
      label: enMonthList[month] + ' ' +
        (date === 1 || date === 21 || date === 31 ? date + enDateList[1] :
          date === 2 || date === 22 ? date + enDateList[2] :
            date === 3 || date === 23 ? date + enDateList[3] : date + enDateList[0]) + ', ' +
        year,
    };
    const en2 = {
      value: '1',
      label: enMonthList[month] + ' ' + date + ', ' + year,
    };
    const en3 = {
      value: '2',
      label: enMonthList[month].substring(0, 3) + '. ' + date + ', ' + year,
    };
    const en4 = {
      value: '3',
      label: (date === 1 || date === 21 || date === 31 ? date + enDateList[1] :
          date === 2 || date === 22 ? date + enDateList[2] :
            date === 3 || date === 23 ? date + enDateList[3] : date + enDateList[0]) + ' ' +
        enMonthList[month] + ', ' +
        year,
    };
    const en5 = {
      value: '4',
      label: date + ' ' + enMonthList[month] + ', ' + year,
    };
    const en6 = {
      value: '5',
      label: date + ' ' + enMonthList[month].substring(0, 3) + '. ' + year,
    };
    const slash = {
      value: '6',
      label: year + '/' +
        (String(month).length === 1 ? ('0' + month) : month) + '/' +
        (String(date).length === 1 ? ('0' + date) : date),
    };
    const dot = {
      value: '7',
      label: year + '.' + month + '.' + date,
    };
    const ja1 = {
      value: '8',
      label: year + '年' + month + '月' + date + '日',
    };
    const ja2 = {
      value: '9',
      label: new Intl.DateTimeFormat('ja-JP-u-ca-japanese', { era: 'long', year: 'numeric' })
        .format(inputDate) + month + '月' + date + '日',
    };
    const free = {
      value: '10',
      label: '自由入力',
    };
    if (initialType === '1') {
      en1.label = en1.label.toUpperCase();
      en2.label = en2.label.toUpperCase();
      en3.label = en3.label.toUpperCase();
      en4.label = en4.label.toUpperCase();
      en5.label = en5.label.toUpperCase();
      en6.label = en6.label.toUpperCase();
    }
    formatList.push(en1, en2, en3, en4, en5, en6, slash, dot, ja1, ja2, free);
    let flag = true;
    switch (formatType) {
      case '0':
        flag = freeInputDate === en1.label;
        // setFreeInputDate(en1.label);
        break;
      case '1':
        flag = freeInputDate === en2.label;
        break;
      case '2':
        flag = freeInputDate === en3.label;
        break;
      case '3':
        flag = freeInputDate === en4.label;
        break;
      case '4':
        flag = freeInputDate === en5.label;
        break;
      case '5':
        flag = freeInputDate === en6.label;
        break;
      case '6':
        flag = freeInputDate === slash.label;
        break;
      case '7':
        flag = freeInputDate === dot.label;
        break;
      case '8':
        flag = freeInputDate === ja1.label;
        break;
      case '9':
        const formatInputDate = getFormat(freeInputDate);
        console.log(formatInputDate);
        if (formatInputDate.date) {
          const month = formatInputDate.date.getMonth() + 1;
          const date = formatInputDate.date.getDate();
          const format = new Intl.DateTimeFormat('ja-JP-u-ca-japanese', { era: 'long', year: 'numeric' })
            .format(formatInputDate.date) + month + '月' + date + '日';
          console.log(format);
          if (format) {
            dispatch(ordersActions.descriptionInfo.setDate(format, false));
            setFreeInputDate(format);
            return format === ja2.label;
          }
        }
        flag = freeInputDate === ja2.label;
        break;
    }
    setAlert(!flag);
    if (flag) {
      setFormatDateList(formatList);
    } else {
      dispatch(dialogActions.pushMessage({
        title: '確認',
        message: [
          '入力形式が正しくありません。',
          '商品情報ー商品詳細ー商品記載情報ー日付',
        ],
        buttons: [
          {
            label: 'OK',
            callback: () => {
              dispatch(dialogActions.pop());
            },
          },
        ],
      }));
    }
  }, [freeInputDate, initialType, formatType]);
  // -- フォーマットされた日付 --
  const handleFormatChange = useCallback((e) => {
    setAlert(false);
    setFormatType(e.target.value);
    dispatch(ordersActions.descriptionInfo.setFormatType(e.target.value));
    if (e.target.value !== '10' && formatDateList[e.target.value]) {
      setFreeInputDate(formatDateList[e.target.value].label);
    }
    if (e.target.value === '10') {
      if (dateValue === undefined) {
        dispatch(ordersActions.descriptionInfo.setDate('', alert));
      }
    }
  }, [formatDateList, freeInputDate, handleInputChange]);
  // -- 文字タイプ --
  const handleInitialChange = useCallback((e) => {
    setAlert(false);
    setInitialType(e.target.value);
    dispatch(ordersActions.descriptionInfo.setFormatCase(e.target.value));
    handleInputChange(Object.assign(eventInput, { currentTarget: true }), e.target.value, formatType);
  }, [eventInput, formatType, handleInputChange]);
  // -- 自由入力 --
  const handlerFreeInputChange = useCallback((e) => {
    setFreeInputDate(e.target.value);
    dispatch(ordersActions.descriptionInfo.setDate(e.target.value, alert));
  }, []);

  const checkDate = useCallback((e: any, v: string) => {
    if (e.code === 'Enter') {
      const data = delete4byte(v);
      dispatch(ordersActions.descriptionInfo.setDate(data, alert));
      setFreeInputDate(data);
    }
  }, [freeInputDate]);

  const handlerClickHelp = useCallback((type: ImgType) => {
    dispatch(dialogActions.push({
      title: '説明',
      element: <HowToOrder imgType={type}/>,
      closeBtn: true,
    }));
  }, []);

  const delete4byte = useCallback((v: string): string => {
    const stringToArr = (str: string) => str.match(/[\uD800-\uDBFF][\uDC00-\uDFFF]|[^\uD800-\uDFFF]/g) || [];
    const base = stringToArr(v);
    let txt: string = '';
    base.forEach((vv) => {
      const char = vv.charCodeAt(1);
      if (!char) {
        txt += vv;
      }
    });
    return txt;
  }, []);

  useEffect(() => {
    if ((dateValue || dateValue === '') && formatType !== '10') {
      const data = getFormat(dateValue);
      setFormatType(data.formatType);
      setInitialType(data.initType);
      setFreeInputDate(dateValue);
      if (data.date) {
        handleInputChange({ target: { value: DateFormatter.date2str(data.date) } } as any, data.initType, data.formatType);
      } else if (!data.date && (dateValue || dateValue === '')) {
        handleInputChange({ target: { value: DateFormatter.date2str(new Date()) } } as any, data.initType, data.formatType);
      }
    } else {
      handleInputChange({ target: { value: DateFormatter.date2str(new Date()) } } as any, '0', '0');
    }
  }, [dateValue]);

  useEffect(() => {
    if (freeInputDate) {
      dispatch(ordersActions.descriptionInfo.setDate(freeInputDate, alert));
    }
  }, [freeInputDate, alert]);

  useEffect(() => {
    if (freeInputDate && !formatType && !initialType) {
      const data = getFormat(freeInputDate);
      setFormatType(data.formatType);
      setInitialType(data.initType || '0');
      handleInputChange({ target: { value: DateFormatter.date2str(new Date()) } } as any, '0', '0');
    }
  }, [freeInputDate, formatDateList]);

  return (
    <>

      <div className="top_label_ui order_category__item">
        <div className="top_label_ui__label">
          日付
        </div>
        <div className="top_label_ui__form">
          <Input
            value={freeInputDate}
            onChange={formatType === '10' ? handlerFreeInputChange : (e) => handleInputChange(e, initialType, formatType)}
            onKeyUp={(e) => (formatType === '10') ? checkDate(e, freeInputDate || '') : null}
            customDateMode={formatType !== '10'}
            className={formatType === '10' ? 'custom_date' : ''}
            error={formatType !== '10' && alert}
            tooltip={formatType !== '10' && alert ? {
              messages: ['入力形式が正しくありません。'],
              error: true,
            } : undefined}
            onChangeTextArea={formatType !== '10' ? (v) => setFreeInputDate(v) : undefined}
            onBlur={() => {
              if (formatType !== '10') {
                handlerFocusOutDateInput();
              } else {
                dispatch(ordersActions.descriptionInfo.setDate(delete4byte(freeInputDate), alert));
                setFreeInputDate(delete4byte(freeInputDate));
              }
            }}
            maxLength={30}
            date={getDate(freeInputDate || '', formatType)}
          />
          <Button
            className="order_help"
            icon={<img src={iconHelp} />}
            onClick={() => callbackClickHelp('description-date')}
          />
        </div>
      </div>

      <div className="top_label_ui order_category__item">
        <div className="top_label_ui__label">
          日付の書式
        </div>
        <div className="top_label_ui__form">
          <div className="item_date_format">
            <Select
              value={formatType}
              list={formatDateList}
              onChange={handleFormatChange}
            />
            <Select
              value={initialType}
              list={initialTypeList}
              onChange={handleInitialChange}
              disabled={
                formatType !== '0'
                && formatType !== '1'
                && formatType !== '2'
                && formatType !== '3'
                && formatType !== '4'
                && formatType !== '5'
              }
            />
          </div>
          <Button
            className="order_help"
            icon={<img src={iconHelp} alt="" />}
            onClick={() => handlerClickHelp('description-dateFormat')}
          />
        </div>
      </div>

    </>
  );
};
