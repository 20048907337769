import { createSlice } from '@reduxjs/toolkit';
import { ActionType } from '../models/action-type';

type SidebarState = {
  date: string,
  orderNumber: string,
  // name: string,
  deliveryType: string,
  deliveryStore: string,
  memo: string,
};

type SidebarSliceState = {
  param: SidebarState,
};

const initialState: SidebarSliceState = {
  param: {
    date: '',
    orderNumber: '',
    deliveryType: '',
    deliveryStore: '',
    memo: '',
  },
};

const setSidebarState: ActionType<SidebarSliceState, Partial<SidebarState>> = (state, action) => {
  state.param = Object.assign(state.param, action.payload);
};

export const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    setSidebarState,
  },
});

export const sidebarActions = sidebarSlice.actions;
export const sidebarReducer = sidebarSlice.reducer;
