import {LaminatesRequest, LaminatesResponse} from '../../../../models/api/back/create-order/laminates';
import {AppThunk} from "../../../../app/store";
import {ordersDataActions} from "../../../../slices/orders/orders-data-slice";
import {apiActions} from "../../../../slices/api-slice";
import {ResponseBase} from "../../../response-base";
import {ApiBaseBack} from "../../api-base-back";
import { ordersActions } from '../../../../slices/orders-slice';

export class ApiLaminates extends ApiBaseBack<LaminatesResponse> {
  constructor(param: LaminatesRequest) {
    super(
      'GET',
      '/api/v1/laminates',
      'JSON',
      param,
    );
  }
}

export const apiLaminatesCoverThunk = (param: LaminatesRequest): AppThunk => async (dispatch, getState) => {
  if (!param.productNameId || !param.itemId) {
    // cover or genuine
    dispatch(ordersDataActions.updateCover({
      laminate: { laminate: [] },
    }));
    return;
  }
  dispatch(apiActions.run(
    new ApiLaminates(param),
    {
      onSuccess: (res: ResponseBase<LaminatesResponse>) => {
        dispatch(ordersDataActions.updateCover({
          laminate: { laminate: res.body.data?.laminate || [] },
        }));
        const laminate = getState().order.cover.laminate;
        if (laminate.value && res.body.data?.laminate?.find((v) => v.laminateId === laminate.value)) {
          dispatch(ordersActions.cover.setLaminate(laminate.value, laminate.label));
        }
      },
    },
    {
      apiName: 'cover-laminate',
      ignoreSystemError: true,
    },
  ))
};

export const apiLaminatesGenuineThunk = (param: LaminatesRequest): AppThunk => async (dispatch, getState) => {
  if (!param.productNameId || !param.itemId) {
    // cover or genuine
    dispatch(ordersDataActions.updateGenuine({
      laminate: { laminate: [] },
    }));
    return;
  }
  dispatch(apiActions.run(
    new ApiLaminates(param),
    {
      onSuccess: (res: ResponseBase<LaminatesResponse>) => {
        dispatch(ordersDataActions.updateGenuine({
          laminate: { laminate: res.body.data?.laminate || [] },
        }));
        const laminate = getState().order.genuine.laminate;
        if (laminate.value && res.body.data?.laminate?.find((v) => v.laminateId === laminate.value)) {
          dispatch(ordersActions.genuine.setLaminate(laminate.value, laminate.label));
        }
      },
    },
    {
      apiName: 'genuine-laminate',
      ignoreSystemError: true,
    },
  ))
};

// export const apiOptionLaminatesThunk = (param: LaminatesRequest): AppThunk => async (dispatch) => {
//   if (!param.productNameId || !param.itemId) {
//     // cover or genuine
//     dispatch(ordersDataActions.updateOption({
//       optionLaminate: { laminate: [] },
//     }));
//     return;
//   }
//   dispatch(apiActions.run(
//     new ApiLaminates(param),
//     {
//       onSuccess: (res: ResponseBase<LaminatesResponse>) => {
//         dispatch(ordersDataActions.updateOption({
//           optionLaminate: { laminate: res.body.data?.laminate || [] },
//         }));
//       },
//     },
//   ))
// };
