import {BoardInfosRequest, BoardInfosResponse} from '../../../../models/api/back/create-order/board-infos';
import {AppThunk} from "../../../../app/store";
import {apiActions} from "../../../../slices/api-slice";
import {ResponseBase} from "../../../response-base";
import {ordersDataActions} from "../../../../slices/orders/orders-data-slice";
import {ApiBaseBack} from "../../api-base-back";

export class ApiBoardInfos extends ApiBaseBack<BoardInfosResponse> {
  constructor(param: BoardInfosRequest) {
    super(
      'GET',
      '/api/v1/boardInfos',
      'JSON',
      param,
    );
  }
}

export const apiBoardInfosThunk = (param: BoardInfosRequest, callback?: () => void, isInit?: boolean): AppThunk => async (dispatch,getState) => {
  if (!param.productNameId) {
    dispatch(ordersDataActions.updateItemDetail({
      board: { boardInfo: [] },
    }));
    callback?.();
    return;
  }
  dispatch(apiActions.run(
    new ApiBoardInfos(param),
    {
      onSuccess: (res: ResponseBase<BoardInfosResponse>) => {
        callback?.();
        if (!isInit && param.productNameId !== getState().order.itemDetail.itemSelect.value) {
          return;
        }
        dispatch(ordersDataActions.updateItemDetail({
          board: { boardInfo: res.body.data?.boardInfo || [] },
        }));
      },
      onError: () => {
        dispatch(ordersDataActions.updateItemDetail({
          board: { boardInfo: [] },
        }));
        callback?.();
      },
    },
    {
      apiName: 'board',
      ignoreSystemError: true,
    },
  ));
};
