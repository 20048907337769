import {RetouchMenu} from './menu/retouch.menu';
import {RetouchPreview} from './preview/retouch.preview';
import {RetouchFooter} from './footer/retouch.footer';
import {RetouchUseImage} from './use-image/retouch.use-image';
import './retouch.scss';
import {RetouchHeader} from './header/retouch.header';
import { useEffect, useState } from 'react';
import { orderLockActions } from '../../../slices/lock-order-slice';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { PathParams, RoutingPath } from '../../../routes/routing-path';
import { xmlActions } from '../../../xml/slice/xml-slice';
import { useAppSelector } from '../../../app/hooks';
import * as lodash from 'lodash';
import { RetouchGetResponse } from '../../../api/back/retouch/api-retouch';
import { push } from 'connected-react-router';
import { localStorageActions } from '../../../slices/local-storage-slice';

export type InitRetouchCheck = {selectId: string, retouchId: string[]}[];
export const Retouch = () => {
  const dispatch = useDispatch();
  const { kijshopCd, shopOrderId, orderId } = useParams<PathParams>();
  const { xml } = useAppSelector((state) => ({
    xml: state.xml[shopOrderId],
  }), lodash.isEqual);
  // State
  const [retouchInfo, setRetouchInfo] = useState<RetouchGetResponse | undefined>(undefined);
  const [initRetouchCheck, setInitRetouchCheck] = useState<InitRetouchCheck>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [imageCount, setImageCount] = useState(0);

  useEffect(() => {
      dispatch(xmlActions.getXml(kijshopCd, shopOrderId));
  }, []);

  useEffect(() => {
    dispatch(orderLockActions.check(kijshopCd,shopOrderId,{
      yes:()=> {
        dispatch(orderLockActions.setId(shopOrderId));
      },
      no: () => {
        dispatch(orderLockActions.setId(''));
        dispatch(push(RoutingPath.cnv.orders({ kijshopCd })));
      }
    }));
  }, []);

  useEffect(() => {
    dispatch(localStorageActions.getLocalData(kijshopCd));
  }, [kijshopCd])

  useEffect(() => {
    if (xml) {
      let parentId = '0';
      const parentXml = xml.orderInfo?.infoData?.find((v) => v.xml.viewModel.surrogateProcess?.id === '2010');
      if (parentXml?.xml.metaModel.id) parentId = parentXml.xml.metaModel.id;
      const retouchList: InitRetouchCheck = [];
      xml.orderInfo?.infoData?.forEach((v) => {
        if (v.xml.metaModel.parentId === parentId) {
          const retouchId = v.xml.viewModel.goods?.goodsId;
          const selectId: string[] = [];
          v.parts?.partsData?.forEach((vv) => {
            vv.page?.pageData?.forEach((vvv) => {
              vvv.viewModel.orderPicture?.data?.forEach((vvvv) => {if (vvvv.selectID) selectId.push(vvvv.selectID)});
            })
          })
          // console.log(retouchId, selectId)
          if (retouchId && selectId) {
            selectId.forEach((vv) => {
              const isExist = retouchList.find((vvv) => vvv.selectId === vv);
              if (isExist) {
                isExist.retouchId.push(retouchId);
              } else {
                retouchList.push({
                  selectId: vv,
                  retouchId: [retouchId],
                });
              }
            })
          }
        }
      });
      // console.log(retouchList)
      setInitRetouchCheck(retouchList.length ? retouchList : [{selectId: '', retouchId: []}]);
    }
  }, [xml]);
  return (
    <div className="retouch">
      <div className="retouch__header_wrap">
        <div>
          {/* ヘッダー */}
          <RetouchHeader />
        </div>
        <div>
          {/* メインメニュー */}
          <RetouchMenu />
        </div>
      </div>

      <div className="retouch__body">
        <div className="retouch__body__list">
          <RetouchUseImage 
            callbackRetouch={setRetouchInfo}
            isLoading={isLoading}
            callbackLoad={(v) => setIsLoading(v)}
            xml={xml}
            initRetouchCheck={initRetouchCheck}
            isSaving={isSaving}
            callbackChangeImageCount={(num) => setImageCount(num)}
          />
        </div>

        {/* <RetouchPreview /> */}
      </div>

      <div className="retouch__footer">
        <RetouchFooter
          retouchInfo={retouchInfo}
          xml={xml}
          callbackSave={(v) => setIsSaving(v)}
          imageCount={imageCount}
        />
      </div>
    </div>
  );
};
