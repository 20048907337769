import {createSlice} from '@reduxjs/toolkit';
import {ActionType} from '../models/action-type';
import { FavoriteGetResponse } from '../models/api/front/favorite';

export type FavoriteBrandItem = {
	id: string,
	name: string,
	type: 'folder' | 'brand',
	level: number, // 階層（1~3）
	parentId: string,
	children: FavoriteBrandItem[],
};

type FavoriteBrandSliceState = {
	brandList: FavoriteBrandItem[],
	selectBrand: FavoriteBrandItem | undefined,
	brandDataList: FavoriteGetResponse[],
};

const initialState: FavoriteBrandSliceState = {
	brandList: [
    // {
    //   id: '0',
    //   name: 'ベーシック',
		// 	type: 'folder',
    //   children: [
    //     {
    //       id: '1',
    //       name: '注文ベース',
		// 			type: 'folder',
    //       children: [],
    //     },
    //     {
    //       id: '2',
    //       name: 'オプション付き注文ベース',
		// 			type: 'folder',
    //       children: [],
    //     }
    //   ],
    // },
    // {
    //   id: '3',
    //   name: 'アドバンス',
		// 	type: 'folder',
    //   children: [
    //     {
    //       id: '4',
    //       name: '更新',
		// 			type: 'folder',
    //       children: [
    //         {
    //           id: '5',
    //           name: 'サムネイル',
		// 					type: 'folder',
    //           children: [
    //             {
    //               id: '6',
    //               name: '01_サムネイル更新',
		// 							type: 'brand',
    //               children: [],
    //             },
    //             {
    //               id: '7',
    //               name: '02_サムネイル更新リスト',
		// 							type: 'brand',
    //               children: [],
    //             },
    //           ],
    //         },
    //       ],
    //     },
    //   ],
    // },
	],
	selectBrand: undefined,
	brandDataList: [],
};

const setFolder: ActionType<FavoriteBrandSliceState, FavoriteBrandItem[]> = (state, action) => {
	state.brandList = action.payload;
};

const addFolder: ActionType<FavoriteBrandSliceState, FavoriteBrandItem> = (state, action) => {
	state.brandList.push(action.payload);
};

const createChildrenArray = (
	searchRow: FavoriteBrandItem,
	targetItem: FavoriteBrandItem,
	isUpdate: boolean
) => {
	let rowChildren: FavoriteBrandItem[] = [];
	const children: FavoriteBrandItem[] = [];
	searchRow.children?.forEach((childNode) => {
		if (childNode.id !== targetItem.id && childNode.children && childNode.children.length > 0) {
			rowChildren.push(childNode as FavoriteBrandItem);
			children.push(childNode);
			createChildrenArray(childNode, targetItem, isUpdate ? true : false);
		} else if (childNode.id !== targetItem.id && childNode.children && childNode.children.length === 0) {
			rowChildren.push(childNode as FavoriteBrandItem);
			children.push(childNode);
		} else if (isUpdate) {
			childNode = Object.assign(childNode, targetItem);
			rowChildren.push(childNode as FavoriteBrandItem);
			children.push(childNode);
		}
		searchRow.children = children;
	});
	return rowChildren;
};

const changeName: ActionType<FavoriteBrandSliceState, FavoriteBrandItem> = (state, action) => {
	const result: FavoriteBrandItem[] = [];
	while (state.brandList.length > 0) {
		let row = state.brandList.shift();
		if (row?.children && row.children.length > 0 && row.id !== action.payload.id) {
			const rowChildren: FavoriteBrandItem[] = createChildrenArray(row, action.payload, true);
			row.children = rowChildren;
			result.push(row);
		} else if (row?.children?.length === 0 && row.id !== action.payload.id) {
			result.push(row);
		} else {
			row = Object.assign(row, action.payload);
			result.push(row);
		}
	}
	state.brandList = Object.assign(state.brandList, result);
};

const deleteFolder: ActionType<FavoriteBrandSliceState, FavoriteBrandItem> = (state, action) => {
	const result: FavoriteBrandItem[] = [];
	while (state.brandList.length > 0) {
		const row = state.brandList.shift();
		if(row?.children && row.children.length > 0 && row.id !== action.payload.id) {
			const rowChildren: FavoriteBrandItem[] = createChildrenArray(row, action.payload, false);
			row.children = rowChildren;
			result.push(row);
		} else if (row?.children?.length === 0 && row.id !== action.payload.id) {
			result.push(row);
		}
	}
	state.brandList = Object.assign(state.brandList, result);
};

const setSelectBrand: ActionType<FavoriteBrandSliceState, FavoriteBrandItem | undefined> = (state, action) => {
	state.selectBrand = action.payload;
};

const setBrandDataList: ActionType<FavoriteBrandSliceState, FavoriteGetResponse[]> = (state, action) => {
	state.brandDataList = action.payload;
};

const addBrandDataList: ActionType<FavoriteBrandSliceState, FavoriteGetResponse[]> = (state, action) => {
	state.brandDataList = [...state.brandDataList, ...action.payload];
}

export const favoriteBrandSlice = createSlice({
  name: 'favorite-brand',
  initialState,
  reducers: {
		setFolder,
		addFolder,
		changeName,
		deleteFolder,
		setSelectBrand,
		setBrandDataList,
		addBrandDataList,
	},
});

export const favoriteBrandActions = favoriteBrandSlice.actions;
export const favoriteBrandReducer = favoriteBrandSlice.reducer;
