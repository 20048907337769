import {
  WizardButton,
  WizardButtonList,
  WizardContent,
  WizardInput,
  WizardInputList,
} from '../../../ui/wizard/wizard-content';
import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { apiActions } from '../../../../slices/api-slice';
import { useParams } from 'react-router-dom';
import { PathParams, RoutingPath } from '../../../../routes/routing-path';
import { ApiCheckStaffLogin, ApiPostStaffPasswordRegister } from '../../../../api/front/wizard/api-staff-management';
import { dialogActions } from '../../../dialog/slice/dialog-slice';
import { push } from 'connected-react-router';
import { useDidMount } from '../../../../hooks/life-cycle';
import { ResponseBase } from '../../../../api/response-base';
import { CheckStaffLoginResponse } from '../../../../models/api/front/staff-management';

type Props = {
  setStep: (step: number) => void;
}

type TooltipType = 'password' | 'passwordConfirm' | 'none';

export const Step1StaffPasswordRegisterWizard = (props: Props) => {
  // - Props -
  const { setStep } = props;
  // - Hooks -
  const dispatch = useDispatch();
  const { kijshopCd } = useParams<PathParams>();
  // - State -
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [isPasswordError, setIsPasswordError] = useState(false);
  const [isPasswordConfirmError, setIsPasswordConfirmError] = useState(false);
  const [showTooltip, setShowTooltip] = useState<TooltipType>('none');
  const [staffPkId, setStaffPkId] = useState('');
  // - Handlers -
  const handlerValidation = useCallback(() => {
    // 大小英文字かつ数字または記号含む8文字以上15文字以内
    const regex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9!@#$%^&*])[A-Za-z0-9!@#$%^&*]{8,15}$/;
    const isPasswordError = password === '' || !regex.test(password);
    const isPasswordConfirmError = passwordConfirm === '' || password !== passwordConfirm || !regex.test(passwordConfirm);
    setIsPasswordError(isPasswordError);
    setIsPasswordConfirmError(isPasswordConfirmError);
    return !isPasswordError && !isPasswordConfirmError;
  },[password, passwordConfirm]);
  const handlerPasswordChange = useCallback((value: string) => {
    setPassword(value);
  }, [password]);
  const handlerPasswordConfirmChange = useCallback((value: string) => {
    setPasswordConfirm(value);
  }, [passwordConfirm]);
  const handlerToggleShowTooltip = useCallback((type: TooltipType) => {
    setShowTooltip(type);
  }, []);
  const handlerClickCancel = useCallback(() => {
    dispatch(push(RoutingPath.staffLogin));
  },[]);
  const handlerClickRegister = useCallback(() => {
    if(!handlerValidation()){
      dispatch(dialogActions.pushMessage({
        title: '確認',
        message: ['入力内容に不備があります。'],
        buttons: [
          {
            label: 'OK',
            callback: () => {
              dispatch(dialogActions.pop());
            },
          },
        ],
      }));
      return;
    }
    dispatch(apiActions.run(
      new ApiPostStaffPasswordRegister(staffPkId, {
        kijshopCd,
        password,
        password_confirmation: passwordConfirm,
      }),
      {
        onSuccess: () => {
          dispatch(dialogActions.pop());
          setStep(2);
        },
        onBasicError: (error) => {
          dispatch(dialogActions.pushMessage({
              title: '確認',
              message: error.messages,
              buttons: [
                {
                  label: 'OK',
                  callback: () => {
                    dispatch(dialogActions.pop());
                  },
                },
              ],
            }),
          );
        },
      },
    ));
  }, [password, passwordConfirm, staffPkId]);

  useDidMount(() => {
    dispatch(apiActions.run(
      new ApiCheckStaffLogin({ kijshopCd }),
      {
        onSuccess: (res: ResponseBase<CheckStaffLoginResponse>) => {
          // スタッフログイン判定
          if (String(res?.error?.errorCode) === '200') {
            if (res.body.data && !res.body.data.status) {
              setStaffPkId(res.body.data.id)
            } else {
              dispatch(push(RoutingPath.staffLogin));
            }
          }
          // 店舗管理者判定
          if(String(res?.error?.errorCode) === '404') {
            dispatch(push(RoutingPath.staffLogin));
          }
        },
        // エラー
        onBasicError: () => {
          dispatch(push(RoutingPath.staffLogin));
        }
      },
    ));
  })
  return (
    <WizardContent>
      <WizardInputList>
        <WizardInput
          label="新しいパスワード（8文字以上15文字以内）"
          value={password}
          disabled={false}
          isPassword={true}
          maxLength={15}
          error={isPasswordError}
          descBottom={'※英大文字と英小文字かつ数字または記号含む8文字以上15文字以内'}
          tooltip={isPasswordError && showTooltip === 'password' ?
            { messages: ['大小英文字かつ数字または記号含む8文字以上15文字以内'] } : undefined}
          onChange={(e) => handlerPasswordChange(e.target.value)}
          onMouseOver={() => handlerToggleShowTooltip('password')}
          onMouseLeave={() => handlerToggleShowTooltip('none')}
        />
        <WizardInput
          label="新しいパスワード（再入力）"
          value={passwordConfirm}
          disabled={false}
          isPassword={true}
          maxLength={15}
          error={isPasswordConfirmError}
          tooltip={isPasswordConfirmError && showTooltip === 'passwordConfirm' ?
            { messages: ['パスワードが一致しません。'] } : undefined}
          onChange={(e) => handlerPasswordConfirmChange(e.target.value)}
          onMouseOver={() => handlerToggleShowTooltip('passwordConfirm')}
          onMouseLeave={() => handlerToggleShowTooltip('none')}
        />
      </WizardInputList>
      <WizardButtonList>
        <WizardButton
          label="キャンセル"
          onClick={handlerClickCancel}
        />
        <WizardButton
          label="登録"
          onClick={handlerClickRegister}
        />
      </WizardButtonList>
    </WizardContent>
  );
}
