import {ResponseCallback} from "../../api/response-callback";
import {AppThunk} from "../../app/store";
import {apiActions} from "../api-slice";
import {ApiMasterXml} from "../../api/back/xml/api-master-xml";

type VersionCheckRequest = {
  xmlVersion: string,
  xml: string,
  version: string,
};

// xmlファイルの種類
type xmlFileType = 'news';

// ファイル種類別ファイル名の取得
const getFileName = (fileType: xmlFileType): string => {
  switch (fileType) {
    case 'news':
      return 'PPM_masterAlert.txt';
  }
};

export const xmlVersionActions = {
  versionCheck: (
    request: VersionCheckRequest,
    response: ResponseCallback,
  ): AppThunk => async (dispatch) => {
    response.onSuccess();
  },
  onGetXml: (
    fileType: xmlFileType,
    response: ResponseCallback,
  ): AppThunk => async (dispatch) => {
    // const fileName = getFileName(fileType);
    dispatch(apiActions.run(new ApiMasterXml(fileType, {}), {
      onSuccess: (res) => {
        // console.log('res: ', res);
        response.onSuccess(res);
      },
      onBasicError: () => {},
    }));
  },
};
