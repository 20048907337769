import {createSlice} from '@reduxjs/toolkit';
import { SimpleRetouchInfo } from '../api/back/retouch/api-retouch';
import { ActionType } from '../models/action-type';

export type BubbleType = 'list' | 'one' | 'six';
export type SortType = 'asc-name' | 'dec-name' | 'asc-date' | 'dec-date';
export type ShowMenuType = 'retouch' | 'name' | 'none';

export type ImageCheckItem = {
  id: string,
  selectId: string,
  checkList: string[],
};
export type RetouchSliceState = {
  bubbleType: BubbleType,
  sortType: SortType,
  retouchItemList: SimpleRetouchInfo[],
  imageCheckItemList: ImageCheckItem[],
  showMenuType: ShowMenuType,
};

const initialState: RetouchSliceState = {
  bubbleType: 'list',
  sortType: 'asc-name',
  retouchItemList: [],
  imageCheckItemList: [],
  showMenuType: 'retouch',
};

const changeBubbleType: ActionType<RetouchSliceState, BubbleType> = (state, action) => {
  state.bubbleType = action.payload;
};

const changeSortType: ActionType<RetouchSliceState, SortType> = (state, action) => {
  state.sortType = action.payload;
};

const setRetouchItemList: ActionType<RetouchSliceState, SimpleRetouchInfo[]> = (state, action) => {
  state.retouchItemList = action.payload;
};

const changeImageCheckItemList: ActionType<RetouchSliceState, {id: string, checkItem: string, isCheck: boolean}> = (state, action) => {
  const target = state.imageCheckItemList.find((v) => v.id === action.payload.id);
  if (action.payload.isCheck) {
    if (target && target?.checkList.indexOf(action.payload.checkItem) === -1) {
      target?.checkList.push(action.payload.checkItem);
    }
  } else {
    if (target && target?.checkList.indexOf(action.payload.checkItem) !== -1) {
      target.checkList.splice(target.checkList.indexOf(action.payload.checkItem), 1);
    }
  }
};

const setImageCheckItemList: ActionType<RetouchSliceState, ImageCheckItem[]> = (state, action) => {
  state.imageCheckItemList = action.payload;
};

const setShowMenuType: ActionType<RetouchSliceState, ShowMenuType> = (state, action) => {
  state.showMenuType = action.payload;
};

export const retouchSlice = createSlice({
  name: 'retouch',
  initialState,
  reducers: {
    changeBubbleType,
    changeSortType,
    setRetouchItemList,
    changeImageCheckItemList,
    setImageCheckItemList,
    setShowMenuType,
  },
});

export const retouchActions = retouchSlice.actions;
export const retouchReducer = retouchSlice.reducer;
