import './warning-icon.scss';
import worningIcon from '../../../assets/img/icon/warning_icon.svg'

type Props = {
  isShow: boolean,
} & React.HTMLAttributes<HTMLDivElement>;

export const WarningIcon = (props: Props) => {
  const { isShow,...defaultProps } = props;

  return (
    <>
      { isShow &&
        <div className="warning_icon__dimmer" style={defaultProps.style}>
          <div className="warning_icon">
            <img
              onDragStart={e => e.stopPropagation()}
              src={worningIcon}
              alt=""
            />
          </div>
        </div>
      }
    </>
  )
};
