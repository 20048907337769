import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Wizard } from '../../../ui/wizard/wizard';
import { Step1AdminPasswordResetWizard } from './step1.admin-password-reset.wizard';
import { Step2AdminPasswordResetWizard } from './step2.admin-password-reset.wizard';
import { Step3AdminPasswordResetWizard } from './step3.admin-password-reset.wizard';
import { Step4AdminPasswordResetWizard } from './step4.admin-password-reset.wizard';

const TITLE = '店舗 パスワード再発行';

export const AdminPasswordResetWizard = () => {
  // - Hooks -
  const location = useLocation();
  // - State -
  const [step, setStep] = useState(0);
  const [token, setToken] = useState('');
  // - effect -
  useEffect(() => {
    const isToken = new URLSearchParams(location.search).has('token');
    if (isToken) {
      setStep(3);
      setToken(new URLSearchParams(location.search).get('token') || '');
      return;
    }
    setStep(1);
  }, []);
  return (
    <Wizard
      title={TITLE}
      step={step}
    >
      <Step1AdminPasswordResetWizard setStep={setStep} />
      <Step2AdminPasswordResetWizard setStep={setStep} />
      <Step3AdminPasswordResetWizard
        setStep={setStep}
        token={token}
      />
      <Step4AdminPasswordResetWizard />
    </Wizard>
  );
};