import { ApiBaseFront } from '../api-base-front';

type PramType = {
  kijshopCd: string,
  purposeId: string,
};

export type CloudFolderPurposeDetail = {
  id: string,
  categoryId: number,
  categoryName: string,
  isUsePpm: 0 | 1,
  isUseResult: 0 | 1,
  isRemark: 0 | 1,
}

export class ApiGetCloudFolderPurposeDetail extends ApiBaseFront {
  constructor(_param: PramType) {
    const { kijshopCd, purposeId, ...param } = _param;
    super(
      'GET',
      `/api/v1/c-folder/shop/${kijshopCd}/purpose/${purposeId}`,
      'JSON',
      param,
    );
  }
}
